import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { errorToast } from "util/general";
import storage from "util/storage";
import style from "../QuestionBuilder.module.scss";
import AddCategoryModal from "./AddCategoryModal/AddCategoryModal";

export default function QuestionRadio(props) {
  const campaignType = storage.get("campaignType") ? storage.get("campaignType") : null;
  const { questionTemplate, templateCategoryList } = useSelector((state) => state.performance);
  const [saveFlag, setSaveFlag] = useState(false);
  const [options, setChoice] = useState(props.questionDetail.options);
  const [disabledDelete, setDisabledDelete] = useState(true);
  const [otherOption, setOtherOption] = useState({
    isOther: props.questionDetail.isOther,
    otherText: props.questionDetail.otherText,
    isRequired: props.questionDetail.isRequired,
    optionalCommentBox: props.questionDetail.optionalCommentBox,
    description: props.questionDetail.description,
    descriptionFlag: props.questionDetail.descriptionFlag,
    commentResponse: props.questionDetail.commentResponse,
    // "overAllScore": props.questionDetail.overAllScore,
    questionCategory: props.questionDetail.questionCategory,
    respondToManager: props.questionDetail.respondToManager,
    respondToEmployee: props.questionDetail.respondToEmployee,
    respondToAll: props.questionDetail.respondToAll,
  });
  const getCategoryList =
    templateCategoryList &&
    templateCategoryList.map((obj) => {
      return {
        value: obj.name,
        label: obj.name,
        categoryId: obj.categoryId,
      };
    });
  /*for category modal */
  const [isModalCategory, setIsModalCategory] = useState(false);
  const hideCategoryModal = () => {
    setIsModalCategory(!isModalCategory);
  };

  function setOption(e, optionId, type) {
    let temp = [...options];
    let newArr = temp.map((arr) => {
      if (type !== "inputText") arr.isCorrect = false;
      if (arr.optionId === optionId) {
        if (type === "inputText") {
          arr.option = e.target.value;
          if (e.target.value === "") {
            // setSaveFlag(true)
          }
        } else if (type === "radiobutton") {
          arr.isCorrect = e.target.checked;
        }
      }
      return arr;
    });
    setChoice(newArr);
    let data = {
      type: "options",
      questionTitle: props.questionDetail.questionTitle,
      options: newArr,
      dropdown: "RADIOBUTTON",
    };
    props.setTemplateData(data);
  }

  useEffect(() => {
    let flag = false;
    if (props.questionDetail.questionName === "") {
      flag = true;
    }
    if (questionTemplate.templateName === "") {
      flag = true;
    }
    if (
      props.respondOptionVisible &&
      !(otherOption.respondToAll || otherOption.respondToManager || otherOption.respondToEmployee)
    ) {
      flag = true;
    }
    if (props.questionDetail.descriptionFlag) {
      if (props.questionDetail.description === "") {
        flag = true;
      }
    }
    if (props.questionDetail.type !== "TEXTBOX") {
      props.questionDetail.options &&
        props.questionDetail.options.map((o) => {
          if (o.option === "" || questionTemplate.templateName === "") {
            flag = true;
          }
        });
    }
    flag ? setSaveFlag(true) : setSaveFlag(false);
    if (questionTemplate.questions.length === 1) {
      setDisabledDelete(true);
    } else {
      if (questionTemplate.questions[1].questionId === undefined) {
        setDisabledDelete(true);
      } else {
        setDisabledDelete(false);
      }
    }
  }, [questionTemplate]);

  const handleSave = () => {
    if (campaignType === "Quiz") {
      if (options.some((value) => value.isCorrect === true)) {
        props.saveQuestion(props.questionDetail);
      } else {
        errorToast("Please Select Answer For Question");
      }
    } else {
      props.saveQuestion(props.questionDetail);
    }
  };

  return (
    <div className={style.answer_l_wrapper}>
      {options &&
        options.map((obj, i) => {
          return (
            <div className={style.radio_wrapper} key={i}>
              <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm no_mar">
                <input
                  id={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + obj.optionId
                      : props.questionDetail.questionId + obj.optionId
                  }
                  checked={obj.isCorrect}
                  type="radio"
                  onChange={(e) => {
                    setOption(e, obj.optionId, "radiobutton");
                  }}
                />
                <label
                  htmlFor={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + obj.optionId
                      : props.questionDetail.questionId + obj.optionId
                  }
                ></label>
              </div>
              <Form.Group className={style.input_text} controlId="">
                <Form.Control
                  type="text"
                  placeholder="Enter an answer choice"
                  value={obj.option}
                  onChange={(e) => {
                    setOption(e, obj.optionId, "inputText");
                  }}
                ></Form.Control>
              </Form.Group>
              <div className={style.action}>
                <span
                  id={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_" + i
                      : props.questionDetail.questionId + "_" + i
                  }
                  className={style.action_plus}
                  onClick={() => {
                    let temp = [...options];
                    temp.push({
                      optionId: options.length + 1 + Math.floor(100000 + Math.random() * 900000),
                      option: "",
                      isCorrect: false,
                    });
                    setChoice(temp);
                    setSaveFlag(true);
                  }}
                ></span>
                <span
                  id={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_" + i
                      : props.questionDetail.questionId + "_" + i
                  }
                  className={style.action_minus}
                  onClick={(e) => {
                    let _temp = [...options];
                    if (_temp.length !== 2) {
                      if (i > -1) {
                        _temp.splice(i, 1);
                      }
                    }

                    let newArr = _temp.map((arr, i) => {
                      arr.optionId = i + 1;
                      return arr;
                    });
                    let flag = false;
                    newArr.map((o) => {
                      if (o.option === "") {
                        flag = true;
                      }
                    });
                    flag ? setSaveFlag(true) : setSaveFlag(false);
                    setChoice(newArr);
                    let data = {
                      type: "options",
                      questionTitle: props.questionDetail.questionTitle,
                      options: newArr,
                      dropdown: "RADIOBUTTON",
                    };
                    props.setTemplateData(data);
                  }}
                ></span>
              </div>
            </div>
          );
        })}
      <div className={style.quesion_category_wrapper}>
        <div className={style.category_title_wrapper}>
          <span className={style.dd_title}>Category</span>
          <span className={style.modal_text} onClick={() => hideCategoryModal()}>
            Add New Category
          </span>
        </div>
        <div className="card_action">
          <Select
            options={getCategoryList}
            className="custom-default-dd-box"
            classNamePrefix="select"
            isSearchable={true}
            isClearable={true}
            placeholder="Select the Category"
            value={
              otherOption.questionCategory === null || otherOption.questionCategory === ""
                ? null
                : {
                    value: otherOption.questionCategory.name,
                    label: otherOption.questionCategory.name,
                    name: otherOption.questionCategory.name,
                  }
            }
            onChange={(e) => {
              setOtherOption({
                ...otherOption,
                questionCategory:
                  e === null
                    ? null
                    : {
                        value: e.value,
                        label: e.value,
                        name: e.value,
                      },
              });
              let oP = {
                ...otherOption,
                questionCategory:
                  e === null
                    ? null
                    : {
                        value: e.value,
                        label: e.value,
                        name: e.value,
                      },
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                otherOption: oP,
                dropdown: "RADIOBUTTON",
              };
              props.setTemplateData(data);
            }}
          />
        </div>
      </div>
      <div className={style.other_field_wrapper}>
        <div className="custom-checkbox no_mar">
          <input
            id={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 77
                : props.questionDetail.questionId + "_checkbox" + 77
            }
            type="checkbox"
            checked={otherOption.descriptionFlag}
            onChange={(e) => {
              setOtherOption({
                ...otherOption,
                descriptionFlag: e.target.checked,
                description: e.target.checked ? otherOption.description : "",
              });
              let oP = {
                ...otherOption,
                descriptionFlag: e.target.checked,
                description: e.target.checked ? otherOption.description : "",
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                otherOption: oP,
                dropdown: "RADIOBUTTON",
              };
              props.setTemplateData(data);
            }}
          />
          <label
            htmlFor={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 77
                : props.questionDetail.questionId + "_checkbox" + 77
            }
          >
            Add help text/description to the question
          </label>
        </div>
        {otherOption.descriptionFlag && (
          <Form.Group className={style.other_text_box} controlId="">
            <div className="textarea-wrapper">
              <Form.Control
                as="textarea"
                maxLength="1000"
                placeholder="Add a comment"
                value={otherOption.description}
                className={otherOption.descriptionFlag ? (otherOption.description === "" ? "error" : "") : ""}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, description: e.target.value });
                  let oP = {
                    ...otherOption,
                    description: e.target.value,
                  };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "RADIOBUTTON",
                  };
                  props.setTemplateData(data);
                }}
              ></Form.Control>
            </div>
          </Form.Group>
        )}
      </div>
      {campaignType !== "Quiz" && (
        <div className={style.other_field_wrapper}>
          <div className="custom-checkbox no_mar">
            <input
              id={
                props.questionDetail.questionTitle
                  ? props.questionDetail.questionTitle + "_checkbox" + 33
                  : props.questionDetail.questionId + "_checkbox" + 33
              }
              type="checkbox"
              checked={otherOption.isOther}
              onChange={(e) => {
                setOtherOption({ ...otherOption, isOther: e.target.checked });
                let oP = {
                  ...otherOption,
                  isOther: e.target.checked,
                };
                let data = {
                  type: "otherOption",
                  questionTitle: props.questionDetail.questionTitle,
                  otherOption: oP,
                  dropdown: "RADIOBUTTON",
                };
                props.setTemplateData(data);
              }}
            />
            <label
              htmlFor={
                props.questionDetail.questionTitle
                  ? props.questionDetail.questionTitle + "_checkbox" + 33
                  : props.questionDetail.questionId + "_checkbox" + 33
              }
            >
              Add an "Other" answer option
            </label>
          </div>
          {otherOption.isOther && (
            <Form.Group className={style.other_text_box} controlId="">
              <Form.Label>Label</Form.Label>
              <Form.Control
                type="text"
                placeholder="Other(please specify)"
                value={otherOption.otherText}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, otherText: e.target.value });
                  let oP = {
                    ...otherOption,
                    otherText: e.target.value,
                  };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "RADIOBUTTON",
                  };
                  props.setTemplateData(data);
                }}
              ></Form.Control>
            </Form.Group>
          )}
        </div>
      )}

      {campaignType !== "Quiz" && (
        <div className={style.mandtory_field_wrapper}>
          <div className="custom-checkbox no_mar">
            <input
              id={
                props.questionDetail.questionTitle
                  ? props.questionDetail.questionTitle + "_checkbox" + 45
                  : props.questionDetail.questionId + "_checkbox" + 45
              }
              type="checkbox"
              checked={otherOption.isRequired}
              onChange={(e) => {
                setOtherOption({ ...otherOption, isRequired: e.target.checked });
                let oP = {
                  ...otherOption,
                  isRequired: e.target.checked,
                };
                let data = {
                  type: "otherOption",
                  questionTitle: props.questionDetail.questionTitle,
                  otherOption: oP,
                  dropdown: "RADIOBUTTON",
                };
                props.setTemplateData(data);
              }}
            />
            <label
              htmlFor={
                props.questionDetail.questionTitle
                  ? props.questionDetail.questionTitle + "_checkbox" + 45
                  : props.questionDetail.questionId + "_checkbox" + 45
              }
            >
              Mark the field as required(<span className="text-danger">*</span>)
            </label>
          </div>
        </div>
      )}
      {campaignType !== "Quiz" && (
        <div className={style.cmt_with_attach_field_wrapper}>
          <div className="custom-checkbox no_mar">
            <input
              id={
                props.questionDetail.questionTitle
                  ? props.questionDetail.questionTitle + "_checkbox" + 51
                  : props.questionDetail.questionId + "_checkbox" + 51
              }
              type="checkbox"
              checked={otherOption.optionalCommentBox}
              onChange={(e) => {
                setOtherOption({ ...otherOption, optionalCommentBox: e.target.checked });
                let oP = {
                  ...otherOption,
                  optionalCommentBox: e.target.checked,
                };
                let data = {
                  type: "otherOption",
                  questionTitle: props.questionDetail.questionTitle,
                  otherOption: oP,
                  dropdown: "RADIOBUTTON",
                };
                props.setTemplateData(data);
              }}
            />
            <label
              htmlFor={
                props.questionDetail.questionTitle
                  ? props.questionDetail.questionTitle + "_checkbox" + 51
                  : props.questionDetail.questionId + "_checkbox" + 51
              }
            >
              {campaignType === "Survey" ? "Add comment box" : "Add comment box where we can also attach files"}
            </label>
          </div>
          {otherOption.optionalCommentBox && (
            <div className={style.attchment_wrapper}>
              <h5>Comment on response is</h5>
              <div className={style.cb_box_wrapper}>
                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1">
                  <input
                    id={
                      props.questionDetail.questionTitle
                        ? props.questionDetail.questionTitle + "_checkbox_optional"
                        : props.questionDetail.questionId + "_checkbox_optional"
                    }
                    type="radio"
                    value="Optional"
                    checked={otherOption.commentResponse === "Optional"}
                    onClick={() => {
                      setOtherOption({ ...otherOption, commentResponse: "Optional" });
                      let oP = {
                        ...otherOption,
                        commentResponse: "Optional",
                      };
                      let data = {
                        type: "otherOption",
                        questionTitle: props.questionDetail.questionTitle,
                        otherOption: oP,
                        dropdown: "RADIOBUTTON",
                      };
                      props.setTemplateData(data);
                    }}
                  />
                  <label
                    htmlFor={
                      props.questionDetail.questionTitle
                        ? props.questionDetail.questionTitle + "_checkbox_optional"
                        : props.questionDetail.questionId + "_checkbox_optional"
                    }
                  >
                    Optional
                  </label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 ">
                  <input
                    id={
                      props.questionDetail.questionTitle
                        ? props.questionDetail.questionTitle + "_checkbox_required"
                        : props.questionDetail.questionId + "_checkbox_required"
                    }
                    type="radio"
                    value="Required"
                    checked={otherOption.commentResponse === "Required"}
                    onClick={() => {
                      setOtherOption({ ...otherOption, commentResponse: "Required" });
                      let oP = {
                        ...otherOption,
                        commentResponse: "Required",
                      };
                      let data = {
                        type: "otherOption",
                        questionTitle: props.questionDetail.questionTitle,
                        otherOption: oP,
                        dropdown: "RADIOBUTTON",
                      };
                      props.setTemplateData(data);
                    }}
                  />
                  <label
                    htmlFor={
                      props.questionDetail.questionTitle
                        ? props.questionDetail.questionTitle + "_checkbox_required"
                        : props.questionDetail.questionId + "_checkbox_required"
                    }
                  >
                    Required
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {props.respondOptionVisible && (
        <div className={style.multi_cb_wrapper}>
          <h5>
            Who do you want to respond to this Question?<span className="text-danger">*</span>
          </h5>
          <div className={style.cb_box_wrapper}>
            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_manager"
                    : props.questionDetail.questionId + "_checkbox_manager"
                }
                type="checkbox"
                name="respond-question"
                value="Managers"
                checked={otherOption.respondToManager}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, respondToManager: e.target.checked });
                  let oP = { ...otherOption, respondToManager: e.target.checked };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "RADIOBUTTON",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_manager"
                    : props.questionDetail.questionId + "_checkbox_manager"
                }
              >
                Managers
              </label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_employee"
                    : props.questionDetail.questionId + "_checkbox_employee"
                }
                type="checkbox"
                name="respond-question"
                value="Employee"
                checked={otherOption.respondToEmployee}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, respondToEmployee: e.target.checked });
                  let oP = { ...otherOption, respondToEmployee: e.target.checked };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "RADIOBUTTON",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_employee"
                    : props.questionDetail.questionId + "_checkbox_employee"
                }
              >
                Employee
              </label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_all"
                    : props.questionDetail.questionId + "_checkbox_all"
                }
                type="checkbox"
                name="respond-question"
                value="All"
                checked={otherOption.respondToAll}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, respondToAll: e.target.checked });
                  let oP = { ...otherOption, respondToAll: e.target.checked };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "RADIOBUTTON",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_all"
                    : props.questionDetail.questionId + "_checkbox_all"
                }
              >
                Both
              </label>
            </div>
          </div>
        </div>
      )}
      <div className={style.que_btn_group}>
        <Button variant="primary" disabled={saveFlag} onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="secondary"
          disabled={disabledDelete}
          onClick={() => {
            props.removeQuestion(props.questionDetail);
          }}
        >
          Delete
        </Button>
      </div>
      <AddCategoryModal show={isModalCategory} hide={hideCategoryModal} />
    </div>
  );
}
