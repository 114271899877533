import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import style from "./CustomNotification.module.scss";
import * as actions from "../../store/actions/index";
import FilterBox from "../../shared/AllFilterBox/FilterBox";
import Spinner from "../../shared/Spinner/Spinner";
import { errorToast, filterOptions, pleaseSelectValue, requiredFieldMissing, successToast } from "../../util/general";
import BotNotificationList from "./BotNotificationList";
import EmployeeListModalWithFilter from "../EmployeeListPopup/EmployeeListModalWithFilter";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

function CustomNotification() {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const {
    notificationFilters,
    apiResponse,
    isLoading,
    employeesList,
    bandOption,
    locationOption,
    projectOption,
    departmentOption,
    memberTypeOption,
    designationOption,
    clientList,
    employeeCount,
    notificationEmployeeList,
    previewPopup,
    subGroupClient,
  } = useSelector((state) => state.admin);
  const {
    filterBand,
    filterDepartment,
    filterLocation,
    filterMemberType,
    message,
    filterEmployee,
    filterSubGroups,
    filterDesignation,
    filterProject,
    filterClient,
    title,
    subject,
  } = notificationFilters;

  useEffect(() => {
    dispatch(actions.getSubGroupClient());
  }, [dispatch]);

  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.success) {
        successToast(apiResponse.message);
        dispatch({ type: "CLEAR_NOTIFICATION_FILTER" });
      } else {
        errorToast(apiResponse.message);
      }
      dispatch({ type: "CLEAR_ROLE_ASSIGN_RESPONSE" });
    }
  }, [apiResponse, dispatch]);

  const getEmployeeNameList = (value) => {
    if (value.length > 2) {
      dispatch(actions.getEmployeeLists(value));
    }
  };

  const handleFilterData = (variable, e) => {
    let key;
    if (variable === "filterMemberType") key = "employeeType";
    else if (variable === "filterDepartment") key = "department";
    else if (variable === "filterProject") key = "projectId";
    else if (variable === "filterLocation") key = "location";
    else if (variable === "filterEmployee") key = "employeeId";
    else if (variable === "filterDesignation") key = "designation";
    else if (variable === "filterClient") key = "clientName";
    else if (variable === "filterSubGroups") key = "subGroupId";

    let body = {
      compositeEmployeeIds:
        filterEmployee?.length > 0
          ? filterEmployee.map((emp) => emp.value + " " + emp.parentGroupId + " " + emp.subGroupId)
          : undefined,
      location: filterLocation?.length > 0 ? filterLocation.map((location) => location.value) : undefined,
      projectId: filterProject?.length > 0 ? filterProject.map((project) => project.value) : undefined,
      department: filterDepartment?.length > 0 ? filterDepartment.map((department) => department.value) : undefined,
      designation:
        filterDesignation?.length > 0 ? filterDesignation.map((designation) => designation.value) : undefined,
      employeeType: filterMemberType?.length > 0 ? filterMemberType.map((memberType) => memberType.value) : undefined,
      clientName: filterClient?.length > 0 ? filterClient.map((memberType) => memberType.value) : undefined,
      subGroupId: filterSubGroups?.length > 0 ? filterSubGroups.map((sub) => sub.value) : undefined,
      [key === "employeeId" ? "compositeEmployeeIds" : key]:
        Array.isArray(e) && e.length > 0 && key === "employeeId"
          ? Array.isArray(e) &&
            e.length > 0 &&
            e.map((emp) => emp.value + " " + emp.parentGroupId + " " + emp.subGroupId)
          : Array.isArray(e) && e.length > 0
          ? e.map((v) => v.value)
          : undefined,
    };
    if (variable === "filterSubGroups" && e?.length > 0) {
      dispatch(actions.getNotificationFilterList(e?.map((v) => v.value)));
    }
    dispatch(actions.notificationEmployeeCount(body));
    dispatch({
      type: "SET_NOTIFICATION_FILTER",
      payload: {
        [variable]: e,
      },
    });
  };

  const handleComment = (name, value) => {
    let newVal;
    if (name === "message") {
      newVal = value.replace(/(<img("[^"]*"|[^\/">])*)>/gi, "$1></img>");
    }
    newVal = value;
    dispatch({ type: "SET_NOTIFICATION_FILTER", payload: { [name]: newVal } });
    if (!value?.trim()) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  const handleSendNotification = () => {
    if (!message?.trim() || !title?.trim() || !subject?.trim()) {
      let defaultError = {};
      if (!message?.trim()) defaultError = { ...defaultError, message: true };
      if (!title?.trim()) defaultError = { ...defaultError, title: true };
      if (!subject?.trim()) defaultError = { ...defaultError, subject: true };
      setErrors(defaultError);
      requiredFieldMissing();
    } else {
      let body = {
        compositeEmployeeIds:
          filterEmployee?.length > 0
            ? filterEmployee.map((emp) => {
                return {
                  employeeId: emp.value,
                  subGroupId: emp.subGroupId,
                  parentGroupId: emp.parentGroupId,
                };
              })
            : undefined,
        clientName: filterClient?.length > 0 ? filterClient.map((memberType) => memberType.value) : undefined,
        message: `<pre>${message}</pre>`,
        title,
        subject,
        subGroupId: filterSubGroups?.length > 0 ? filterSubGroups.map((sub) => sub.value) : undefined,
        location: filterLocation?.length > 0 ? filterLocation.map((location) => location.value) : undefined,
        projectId: filterProject?.length > 0 ? filterProject.map((project) => project.value) : undefined,
        department: filterDepartment?.length > 0 ? filterDepartment.map((department) => department.value) : undefined,
        designation:
          filterDesignation?.length > 0 ? filterDesignation.map((designation) => designation.value) : undefined,
        employeeType: filterMemberType?.length > 0 ? filterMemberType.map((memberType) => memberType.value) : undefined,
      };
      if (Object.values(body).filter((value) => value).length > 1) {
        dispatch(actions.sendNotification(body));
      } else {
        pleaseSelectValue();
      }
    }
  };

  const handleClear = () => {
    dispatch({ type: "CLEAR_NOTIFICATION_FILTER" });
  };

  const filter = {
    value: ["NOTIFIED", "FAILED"],
    name: ["Success Sent", "Failed"],
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
  };

  return (
    <div>
      <SubNavigation type="Notification" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">Notification</span>
          </Col>
          <Col md={12}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Custom Notification</h2>
                  <h3>Select appropriate value to send notification</h3>
                </div>
              </div>
              <div className="box-light-gradient"></div>
              <div className={style.notification_filter_wrapper}>
                <div className={[style.title_text_wrapper, "col-md-8"].join(" ")}>
                  <Form.Group>
                    <Form.Label>
                      Notification Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength="200"
                      placeholder="Enter notification name"
                      name="title"
                      className={[errors && errors.title && "limit-reached"].join(" ")}
                      value={title ?? ""}
                      onChange={(e) => {
                        handleComment(e.target.name, e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className={[style.subject_text_wrapper, "col-md-6"].join(" ")}>
                  <Form.Group>
                    <Form.Label>
                      Subject<span className="text-danger">*</span>
                      <InfoTooltip heading="Added subject will be display as a notification header in Bot" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength="200"
                      placeholder="Enter subject"
                      name="subject"
                      className={[errors && errors.subject && "limit-reached"].join(" ")}
                      value={subject ?? ""}
                      onChange={(e) => {
                        handleComment(e.target.name, e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <FilterBox
                  displayMemberType={memberTypeOption?.length > 0 ? true : false}
                  displayProject={projectOption?.length > 0 ? true : false}
                  displaySubgroups={subGroupClient?.length > 0 ? true : false}
                  displayDesignation={designationOption?.length > 0 ? true : false}
                  displayClient={clientList?.length > 0 ? true : false}
                  displayDepartment={departmentOption?.length > 0 ? true : false}
                  displayLocation={locationOption?.length > 0 ? true : false}
                  subGroupClient={subGroupClient}
                  filterSubGroups={filterSubGroups}
                  designationOption={designationOption}
                  filterDesignation={filterDesignation}
                  employeeNameList={employeesList}
                  filterEmployee={filterEmployee}
                  memberTypeList={memberTypeOption}
                  filterMemberType={filterMemberType}
                  bandList={bandOption}
                  filterBand={filterBand}
                  locationList={locationOption}
                  getEmployeeNameList={getEmployeeNameList}
                  filterOptions={filterOptions}
                  filterLocation={filterLocation}
                  departmentList={departmentOption}
                  filterDepartment={filterDepartment}
                  clientList={clientList}
                  filterClient={filterClient}
                  handleFilterData={handleFilterData}
                  searchEmployeeBox={true}
                  projectList={projectOption}
                  filterProject={filterProject}
                />
                <div className={style.text_box_wrapper}>
                  <span className={style.dd_title}>
                    Notification Text (Write the custom text you want to send as a notification)
                  </span>
                  <ReactQuill
                    theme="snow"
                    value={message ?? ""}
                    onChange={(content) => handleComment("message", content)}
                    className={["rich-text-box", errors?.message && "quill-error"].join(" ")}
                    placeholder="Add custom text here"
                    modules={modules}
                  />
                </div>
                {(employeeCount || employeeCount === 0) && (
                  <div className="vd-obj-description mt-4 mb-0">
                    Total eligible employees based on apply filter(s): <label>{employeeCount}</label>
                  </div>
                )}
                <div className={style.filter_btn_group}>
                  <button
                    type="button"
                    className={["btn-round save_box_btn btn btn-primary mr-3", style.create_new_btn].join(" ")}
                    onClick={handleSendNotification}
                  >
                    <strong>Send</strong>
                  </button>
                  <button
                    type="button"
                    className={["btn-round save_box_btn btn btn-secondary", style.create_new_btn].join(" ")}
                    onClick={handleClear}
                  >
                    <strong>Clear</strong>
                  </button>
                </div>
              </div>
            </div>
          </Col>

          <Col className="col-12">
            <BotNotificationList />
          </Col>
        </Row>
      </Container>
      <EmployeeListModalWithFilter
        employees={notificationEmployeeList}
        isExpandVisible={true}
        openPopup={previewPopup}
        closePopup={() => {
          dispatch({ type: "REMOVE_NOTIFICATION_PREVIEW_DATA" });
        }}
        searchPlaceholder="Search Employee"
        modalHeading="Employee List"
        modalDescription={`Given the name of employee who have received notification or not `}
        departmentShow={true}
        handleEmployeeClick={() => {}}
        secondColum="Band"
        thirdColum="Location"
        plusIcon={false}
        filter={filter}
        defaultStatusValue={"NOTIFIED"}
      />
    </div>
  );
}

export default CustomNotification;
