import React from "react";
import { Button } from "react-bootstrap";
import style from "./savebox.module.scss";
import moment from "moment";

/**
 * PROPS
 * reviewedDate
 * submitedDate
 * status
 * managerView
 * saveAnswerHandler
 * onReviewed
 * draftSavedDate (updatedDate) ???
 */

const Savebox = (props) => {
  // const { checkInDetails } = useSelector((state) => state.teamHistory.checkInDetails);

  let userDisplay,
    managerDisplay = null;

  if (props.reviewedDate || props.status === "REVIEWED") {
    userDisplay = (
      <div className={["box box-flex-center", style.saveBox].join(" ")}>
        <div className={style.save_box_center}>
          Reviewed On:{" "}
          <span>
            {moment(props.reviewedDate).format("ddd D MMM h:mm a")}{" "}
            {props.reviewedBy && `by ${props.reviewedBy.empName}`}
          </span>
        </div>
      </div>
    );

    managerDisplay = (
      <div className={["box box-flex-center", style.saveBox].join(" ")}>
        <div className={style.save_box_center}>
          Reviewed On:{" "}
          <span>
            {moment(props.reviewedDate).format("ddd D MMM h:mm a")}{" "}
            {props.reviewedBy && `by ${props.reviewedBy.empName}`}
          </span>
        </div>
      </div>
    );
  } else if (props.submitedDate || props.status === "PENDING_FOR_REVIEW") {
    userDisplay = (
      <div className={["box box-flex-center", style.saveBox].join(" ")}>
        <div className={style.save_box_center}>
          Submitted On: <span>{moment(props.submitedDate).format("ddd D MMM h:mm a")}</span>
        </div>
      </div>
    );

    managerDisplay = (
      <div className={["box box-flex-center", style.saveBox].join(" ")}>
        <div className={style.save_box_left}>
          <Button
            type="button"
            variant={"primary"}
            className={["btn-round", style.btn_reviewed].join(" ")}
            onClick={(e) => props.onReviewed(e, props.surveyCycleId)}
          >
            <strong>Mark as Reviewed</strong>
          </Button>
        </div>
        {props.submittedDate && (
          <div className={style.save_box_right}>
            Submitted on: <span>{moment(props.submittedDate).format("ddd D MMM h:mm a")}</span>
          </div>
        )}
      </div>
    );
  } else {
    userDisplay = (
      <div className={["box box-flex-center", style.saveBox].join(" ")}>
        <div className={style.save_box_left}>
          <Button
            type="button"
            variant={"primary"}
            className={["btn-round", style.btn].join(" ")}
            onClick={props.saveAnswersHandler}
          >
            <strong>
              {props.managerView === true ? (props.status === "REVIEWED" ? "null" : "Mark as Reviewed") : "Submit"}
            </strong>
          </Button>
        </div>
        {props.updatedDate ? (
          <div className={style.save_box_right}>
            Draft Saved: <span>{moment(props.updatedDate).format("ddd D MMM h:mm a")}</span>
          </div>
        ) : null}
      </div>
    );
  }

  return props.managerView ? managerDisplay : userDisplay;
};

export default Savebox;
