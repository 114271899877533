import React, { Component } from "react";
import "./nodatafound.module.scss";
import {
  IMG_FOLDER_OUTER,
  IMG_FOLDER_INNER,
  IMG_FOLDER_PAPER,
  IMG_FOLDER_LINE,
  IMG_FOLDER_BACKGROUND,
} from "../../const/imgCost";

class NoDataFound extends Component {
  render() {
    return (
      <div className={["container", "noDataOuterWrapper"].join(" ")}>
        <div className="folder-wrapper">
          <div className="folder-wrapper-overlay"></div>
          <div className="folder">
            <div className="main-folder">
              <img src={IMG_FOLDER_OUTER} alt="folder" />
            </div>
            <div className="folder-inner">
              <img src={IMG_FOLDER_INNER} alt="folder-inner" />
            </div>
            <div className="folder-paper">
              <img src={IMG_FOLDER_PAPER} alt="paper" />
              <img src={IMG_FOLDER_PAPER} alt="paper" />
              <img src={IMG_FOLDER_PAPER} alt="paper" />
            </div>
          </div>
          <div className="folder-line">
            <img src={IMG_FOLDER_LINE} alt="line" />
          </div>
          <div className="back-bubble">
            <img src={IMG_FOLDER_BACKGROUND} alt="bg" />
          </div>
        </div>
        <h4>{this?.props?.header ? this?.props?.header : "No Records Found"}</h4>
        <p>{this.props.description}</p>
      </div>
    );
  }
}
export default NoDataFound;
