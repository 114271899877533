import React from "react";
import ReactDatePicker from "react-datepicker";
import MultiSelect from "react-multi-select-component";
import Select from "react-select";
import { Multiselect } from "multiselect-react-dropdown";
import style from "./FilterBox.module.scss";
import { filterOptions } from "util/general";
import { monthOptions, timelineOptions } from "const/options";

function FilterBox({
  displayBand,
  displayEndDate,
  displayMemberType,
  displayProject,
  displayStatus,
  displayStartDate,
  displayClient,
  employeeNameList,
  filterEmployee,
  memberTypeList,
  filterMemberType,
  bandList,
  filterBand,
  locationList,
  getEmployeeNameList,
  filterOptions,
  filterLocation,
  departmentList,
  filterDepartment,
  statusWiseOptions,
  filterStatus,
  startDate,
  endDate,
  handleFilterData,
  searchEmployeeBox,
  projectList,
  filterProject,
  filterClient,
  clientList,
  maxEndDate,
  maxStartDate,
  displaySubgroups,
  subGroupClient,
  filterSubGroups,
  displayDesignation,
  filterDesignation,
  designationOption,
  displayDepartment,
  displayLocation,
  isSubGroupSingleSelect,
  displayTenure,
  filterTenure,
  displayType,
  filterType,
  filterIndiaDD,
  indiaDDOption,
  displayIndiaDD,
  displayManager,
  filterManager,
  managerOptions,
}) {
  return (
    <div className={style.filter_top_bar}>
      {displayStartDate && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Start Date</span>
          <div className="date_picker_time_wrapper">
            <div className="calendar_wrapper form-group">
              <ReactDatePicker
                disabled={false}
                className="form-control"
                dateFormat="MMMM d, yyyy"
                placeholderText="Select Start Date"
                selected={startDate}
                // minDate={minDate}
                maxDate={maxStartDate}
                onChange={(date) => {
                  handleFilterData("startDate", date);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {displayEndDate && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>End Date</span>
          <div className="date_picker_time_wrapper">
            <div className="calendar_wrapper form-group">
              <ReactDatePicker
                disabled={false}
                className="form-control"
                dateFormat="MMMM d, yyyy"
                placeholderText="Select End Date"
                selected={endDate}
                minDate={startDate}
                maxDate={maxEndDate}
                onChange={(date) => {
                  handleFilterData("endDate", date);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {displaySubgroups && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Sub Group</span>
          <div className="tags_wrapper">
            {isSubGroupSingleSelect ? (
              <Select
                options={subGroupClient}
                className="custom-default-dd-box"
                classNamePrefix="select"
                placeholder="Select..."
                value={filterSubGroups || ""}
                onChange={(e) => {
                  handleFilterData("filterSubGroups", e);
                }}
              />
            ) : (
              <MultiSelect
                options={subGroupClient}
                value={filterSubGroups || []}
                onChange={(e) => {
                  handleFilterData("filterSubGroups", e);
                }}
                filterOptions={filterOptions}
              />
            )}
          </div>
        </div>
      )}
      {displayMemberType && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Employee Type</span>
          <div className="tags_wrapper">
            <MultiSelect
              options={memberTypeList || []}
              value={filterMemberType}
              onChange={(e) => {
                handleFilterData("filterMemberType", e);
              }}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      )}
      {displayBand && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Band</span>
          <div className="tags_wrapper">
            <MultiSelect
              options={bandList || []}
              value={filterBand}
              onChange={(e) => {
                handleFilterData("filterBand", e);
              }}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      )}
      {displayDesignation && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Designation</span>
          <div className="tags_wrapper">
            <MultiSelect
              options={designationOption || []}
              value={filterDesignation}
              onChange={(e) => {
                handleFilterData("filterDesignation", e);
              }}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      )}
      {displayLocation && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Location</span>
          <div className="tags_wrapper">
            <MultiSelect
              options={locationList || []}
              value={filterLocation}
              onChange={(e) => {
                handleFilterData("filterLocation", e);
              }}
              labelledBy={"Select"}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      )}
      {searchEmployeeBox ? (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Employee Name</span>
          <div className="tags_wrapper">
            <Multiselect
              id={"multi-select-search-dd-box"}
              options={employeeNameList || []} // Options to display in the dropdown
              selectedValues={filterEmployee} // Preselected value to persist in dropdown
              onSelect={(list) => handleFilterData("filterEmployee", list)} // Function will trigger on select event
              onRemove={(list) => handleFilterData("filterEmployee", list)} // Function will trigger on remove event
              displayValue="label" // Property name to display in the dropdown options
              onSearch={getEmployeeNameList}
              closeOnSelect={false}
              showCheckbox={true}
              placeholder="Search"
            />
          </div>
        </div>
      ) : (
        employeeNameList &&
        employeeNameList.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Employee</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={employeeNameList || []}
                value={filterEmployee}
                onChange={(e) => {
                  handleFilterData("filterEmployee", e);
                }}
                filterOptions={filterOptions}
              />
            </div>
          </div>
        )
      )}
      {displayDepartment && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Group</span>
          <div className="tags_wrapper">
            <MultiSelect
              options={departmentList || []}
              value={filterDepartment}
              onChange={(e) => {
                handleFilterData("filterDepartment", e);
              }}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      )}
      {displayProject && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Project</span>
          <div className="tags_wrapper">
            <MultiSelect
              options={projectList || []}
              value={filterProject}
              onChange={(e) => {
                handleFilterData("filterProject", e);
              }}
              labelledBy="Select"
              filterOptions={filterOptions}
            />
          </div>
        </div>
      )}
      {displayClient && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Client</span>
          <div className="tags_wrapper">
            <MultiSelect
              options={clientList || []}
              value={filterClient}
              onChange={(e) => {
                handleFilterData("filterClient", e);
              }}
              labelledBy="Select"
              filterOptions={filterOptions}
            />
          </div>
        </div>
      )}
      {displayStatus && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Status</span>
          <div className="tags_wrapper">
            <Select
              options={statusWiseOptions}
              className="custom-default-dd-box"
              classNamePrefix="select"
              placeholder="Select..."
              value={filterStatus || ""}
              onChange={(e) => {
                handleFilterData("filterStatus", e);
              }}
            />
          </div>
        </div>
      )}
      {displayType && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Option</span>
          <div className="tags_wrapper">
            <Select
              options={timelineOptions}
              className="custom-default-dd-box"
              classNamePrefix="select"
              placeholder="Select..."
              value={filterType || ""}
              onChange={(e) => {
                handleFilterData("filterType", e);
              }}
            />
          </div>
        </div>
      )}
      {displayIndiaDD && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>India DD</span>
          <div className="tags_wrapper">
            <MultiSelect
              className="multi-select"
              classNamePrefix="select"
              isSearchable={true}
              value={filterIndiaDD || []}
              options={indiaDDOption || []}
              onChange={(e) => {
                handleFilterData("filterIndiaDD", e);
              }}
            />
          </div>
        </div>
      )}
      {displayManager && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Manager</span>
          <div className="tags_wrapper">
            <MultiSelect
              className="multi-select"
              classNamePrefix="select"
              isSearchable={true}
              value={filterManager || []}
              options={managerOptions || []}
              onChange={(e) => {
                handleFilterData("filterManager", e);
              }}
            />
          </div>
        </div>
      )}
      {displayTenure && (
        <div className={style.filter_box}>
          <span className={style.dd_title}>Tenure</span>
          <div className="tags_wrapper">
            <Select
              options={monthOptions}
              className="custom-default-dd-box"
              classNamePrefix="select"
              placeholder="Select..."
              value={filterTenure || ""}
              onChange={(e) => {
                handleFilterData("filterTenure", e);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterBox;

FilterBox.defaultProps = {
  displayBand: false,
  displayEndDate: false,
  displayMemberType: false,
  displayProject: false,
  displayStatus: false,
  displayStartDate: false,
  displayTenure: false,
  displayType: false,
  searchEmployeeBox: false,
  maxEndDate: null,
  maxStartDate: null,
  filterOptions: filterOptions,
};
