import React from "react";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import moment from "moment";
import XLSX from "xlsx";
import config from "util/config";
import SlideToast from "components/Toast/Toast";
import {
  IC_OBJ_RANGE,
  IC_OBJ_ARROW_UP,
  IC_OBJ_ARROW_DOWN,
  GREEN_UP_ARROW,
  RED_DOWN_ARROW,
  YELLOW_STABLE_ARROW,
} from "const/imgCost";
import { blinkMenu,blinkMenuQuiz } from "const/constValue";
import { capabilityTemplateMapping } from "const/mappingValue";

function formatCycleData(cycle, isCurrent) {
  let cycleInfo = cycle;

  if (isCurrent) {
    cycleInfo = cycle?.filter((item) => {
      return item.isCurrent;
    })[0];
  }

  if (cycleInfo) {
    let checkinInfo = {
      due_date: "- -",
      submit_date: "- -",
      review_date: "- -",
      status: "- -",
    };

    if (cycle) {
      checkinInfo["due_date"] = cycleInfo.endDate
        ? moment(cycleInfo.endDate).format(config.dates.shortMonthNameDateAndDay)
        : "- -";

      checkinInfo["submit_date"] = cycleInfo.submitedDate
        ? moment(cycleInfo.submitedDate).format(config.dates.shortMonthNameDateAndDay)
        : "- -";

      checkinInfo["review_date"] = cycleInfo.reviewedDate
        ? moment(cycleInfo.reviewedDate).format(config.dates.shortMonthNameDateAndDay)
        : "- -";

      checkinInfo["status"] = cycleInfo.status;

      if (!cycleInfo.submitedDate) {
        let currentDate = new Date();
        checkinInfo["delayed_days"] = moment(currentDate).diff(cycleInfo.endDate, "days");
      } else if (cycleInfo.submitedDate > cycleInfo.endDate) {
        checkinInfo["delayed_days"] = moment(cycleInfo.submitedDate).diff(cycleInfo.endDate, "days");
      } else {
        checkinInfo["on_time"] = true;
      }
    }

    return checkinInfo;
  }

  return cycleInfo;
}

function getRatingClass(rating, style) {
  switch (rating) {
    case "1":
      return style.one;
    case "2":
      return style.two;
    case "3":
      return style.three;
    case "4":
      return style.four;
    case "5":
      return style.five;
    default:
      return "";
  }
}

function getStatusInformation(status, style) {
  let statusData = {
    style: "- -",
    status: "- -",
  };

  switch (status) {
    case "PENDING_FROM_MEMBER":
      statusData["style"] = style.pending;
      statusData["status"] = "Pending From Member";
      return statusData;
    case "PENDING_FOR_REVIEW":
      statusData["style"] = style.ready;
      statusData["status"] = "Ready For Review";
      return statusData;
    case "REVIEWED":
      statusData["style"] = style.reviewed;
      statusData["status"] = "Reviewed";
      return statusData;
    case "LAPSED":
      statusData["style"] = style.lapsed;
      statusData["status"] = "OKR Progress Lapsed";
      return statusData;
    default:
      return statusData;
  }
}

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
function filterArray(array, filters) {
  const filterKeys = Object.keys(filters);

  return array.filter((item) => {
    return Object.keys(item).every(() => {
      // validates all filter criteria
      return filterKeys.every((filterKey) => {
        // ignores an empty filter
        if (!filters[filterKey].length) return true;
        if (!item[filterKey]?.toString()) return true;
        return getValue(item[filterKey].toString()).includes(getValue(filters[filterKey]));
      });
    });
  });
}

/**
 * Filters an array of objects (one level-depth) with multiple criteria including boolean comparison.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
function filterArrayWithBoolean(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    return Object.keys(item).every(() => {
      // validates all filter criteria
      return filterKeys.every((filterKey) => {
        // ignores an empty filter
        if (filters[filterKey] === true || filters[filterKey] === false) {
          if (getValue(item[filterKey]) === getValue(filters[filterKey])) {
            return true;
          } else {
            return false;
          }
        } else {
          if (!filters[filterKey].length) return true;
          if (!item[filterKey]) return true;
          return getValue(item[filterKey]).includes(getValue(filters[filterKey]));
        }
      });
    });
  });
}

// Ignores case-sensitive
const getValue = (value) => (typeof value === "string" ? value.toUpperCase() : value);

function scrollToTop(scrollDuration) {
  var scrollStep = -window.scrollY / (scrollDuration / 15),
    scrollInterval = setInterval(function () {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else clearInterval(scrollInterval);
    }, 15);
}

function keyPointType(value) {
  if (value === "up") {
    return 8;
  } else if (value === "down") {
    return 8;
  } else if (value === "between") {
    return 8;
  } else if (value === "%") {
    return 5;
  }
}

function mergeArray(array1, array2, key = "id") {
  if (array1 && array2) {
    return array1.filter((elem) => !array2.find((subElem) => subElem[key] === elem[key])).concat(array2);
  }
}

function getShortName(name) {
  return name?.split(" ")[0] + " " + name?.split(" ")[1]?.split("")[0] + ".";
}

function keyValue(keyPoint) {
  if (keyPoint.type === "?") {
    return "Complete/Incomplete";
  } else if (keyPoint.type === "up") {
    return (
      <>
        <ReactSVG className="gray-svg d-inline-block" src={`${IC_OBJ_ARROW_UP}`} />
        {keyPoint.start + " - " + keyPoint.end}
      </>
    );
  } else if (keyPoint.type === ":") {
    return keyPoint.hours + " hours";
  } else if (keyPoint.type === "down") {
    return (
      <>
        <ReactSVG className="gray-svg d-inline-block" src={`${IC_OBJ_ARROW_DOWN}`} />
        {keyPoint.start + " - " + keyPoint.end}
      </>
    );
  } else if (keyPoint.type === "between") {
    return (
      <>
        <ReactSVG className="gray-svg d-inline-block" src={`${IC_OBJ_RANGE}`} />
        {keyPoint.start + " - " + keyPoint.end}
      </>
    );
  } else if (keyPoint.type === "%") {
    return keyPoint.start + keyPoint.type + " - " + keyPoint.end + keyPoint.type;
  } else {
    return keyPoint.start + " - " + keyPoint.end;
  }
}

export function keyValueAchieved(data) {
  if (data.type === "?" && data.percentage !== 0) {
    return "Complete";
  } else if (data.type === "?" && data.percentage === 0) {
    return "Incomplete";
  } else if (data.type === "up" && data.percentage !== 0) {
    return (
      <>
        <ReactSVG className="gray-svg d-inline-block" src={`${IC_OBJ_ARROW_UP}`} />
        {data.progress}
      </>
    );
  } else if (data.type === ":" && data.percentage !== 0) {
    return data.progress + " hours";
  } else if (data.type === "down" && data.percentage !== 0) {
    return (
      <>
        <ReactSVG className="gray-svg d-inline-block" src={`${IC_OBJ_ARROW_DOWN}`} />
        {data.progress}
      </>
    );
  } else if (data.type === "between" && data.percentage !== 0) {
    return (
      <>
        <ReactSVG className="gray-svg d-inline-block" src={`${IC_OBJ_RANGE}`} />
        {data.progress}
      </>
    );
  } else if (data.type === "%" && data.percentage !== 0) {
    return data.progress + data.type;
  } else {
    return "0";
  }
}

function exportTeamObjectives(createdObjective) {
  var data = [];
  createdObjective.forEach((object) => {
    object.keyPoints.forEach((key, i) => {
      let ele = {
        objective_name: i === 0 ? object.title : "",
        objective_weightage: i === 0 ? object.weightage : "",
        objective_description: i === 0 ? object.description : "",
        key_areas: key.description,
        key_area_weightage: key.weightage,
        unit: key.type === "%" ? "%" : key.type || "",
        base_value: key.start === 0 ? "0" : key.start || "",
        target_value: key.end === 0 ? "0" : key.end || "",
        tags: i === 0 ? (object.tags ? object.tags.map((arr) => arr.name).toString() : "") : "",
        stretch_keyresult: key.stretch === true ? "Yes" : "No",
      };
      data.push(ele);
    });
  });
  var worksheet = XLSX.utils.json_to_sheet(data);

  var range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (var r = range.s.r; r <= range.e.r; r++) {
    for (var c = range.s.c; c <= range.e.c; c++) {
      var cellName = XLSX.utils.encode_cell({ c, r });
      worksheet[cellName].z = "@";
    }
  }

  var workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Teams Objective");

  var wbout = XLSX.write(workbook, { type: "array", bookType: "xlsx" });
  saveAs(new Blob([wbout], { type: "application/octet-stream" }), "ObjectiveFormat.xlsx");
}

export function requiredFieldMissing() {
  if (!toast.isActive("ERROR")) {
    toast.error(<SlideToast title="Error!" message="Required field missing" error={true} />, { toastId: "ERROR" });
    return;
  }
}

export function errorToast(message) {
  if (!toast.isActive("ERROR" + message)) {
    toast.error(<SlideToast title="Error!" message={message} error={true} />, { toastId: "ERROR" + message });
    return;
  }
  return;
}

export function successToast(message) {
  toast.success(<SlideToast title="Awesome!" message={message} error={false} />);
}

export function infoToast(message) {
  toast.info(<SlideToast title="Information!" message={message} error={false} />);
}

export function submittedSuccessfully() {
  toast.success(<SlideToast title="Awesome!" message="Submitted successfully!" error={false} />);
}

export function pleaseSelectValue() {
  if (!toast.isActive("FILTER_ERROR"))
    toast.error(<SlideToast title="Error!" message="Please select value" error={true} />, {
      toastId: "FILTER_ERROR",
    });
}

export function pleaseSelectDate() {
  if (!toast.isActive("DATE_ERROR")) {
    toast.error(<SlideToast title="Error!" message="Please Select Date" error={true} />, { toastId: "DATE_ERROR" });
    return;
  }
}

export function maximumFileSize() {
  toast.error(<SlideToast title="Error!" message="Maximum file size is 2MB" error={true} />);
}

export function maximumFiveFile() {
  toast.error(<SlideToast title="Error!" message="You can upload maximum 5 files" error={true} />);
}

export function pleaseSelectCheckBox() {
  if (!toast.isActive("CHECK_BOX_SELECT_ERROR")) {
    toast.error(<SlideToast title="Error" message="Please select checkbox" error={true} />, {
      toastId: "CHECK_BOX_SELECT_ERROR",
    });
  }
}

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    // eslint-disable-next-line no-undef
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function getPerformanceGrade(data) {
  if (data?.toFixed(2) > 0 && data?.toFixed(2) <= 2) {
    return "C";
  } else if (data?.toFixed(2) >= 2.01 && data?.toFixed(2) <= 2.99) {
    return "B";
  } else if (data?.toFixed(2) >= 3 && data?.toFixed(2) <= 3.99) {
    return "B+";
  } else if (data?.toFixed(2) >= 4 && data?.toFixed(2) <= 4.49) {
    return "A";
  } else if (data?.toFixed(2) >= 4.5 && data?.toFixed(2) <= 5) {
    return "A+";
  } else return "-";
}

export function sortAlphanumerics(list, key) {
  if (list && list.length > 0) {
    if (key) {
      list.sort((p1, p2) => {
        if (!p1[key]) {
          if (!p2[key]) {
            return 0;
          } else {
            return -1;
          }
        } else if (!p2[key]) {
          return 1;
        } else if (p1[key].toLowerCase() > p2[key].toLowerCase()) {
          return 1;
        } else if (p2[key].toLowerCase() > p1[key].toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  } else {
    list.sort((p1, p2) => {
      if (!p1) {
        return -1;
      } else if (!p2) {
        return 1;
      } else if (p1.toLowerCase() > p2.toLowerCase()) {
        return 1;
      } else if (p2.toLowerCase() > p1.toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  return list;
}

export function sortNumeric(list, key) {
  if (list && list.length > 0) {
    if (key) {
      list.sort((i1, i2) => {
        if (+i1[key] > +i2[key]) {
          return 1;
        } else if (+i1[key] < +i2[key]) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      list.sort((i1, i2) => {
        if (+i1 > +i2) {
          return 1;
        } else if (+i1 < +i2) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }
  return list;
}

export function objectiveStatus(value) {
  if (value === "INPROGRESS") {
    return "In progress";
  } else if (value === "COMPLETED") {
    return "Completed";
  } else if (value === "INCOMPLETED") {
    return "Incompleted";
  } else if (value === "ASSIGNED") {
    return "In Progress";
  } else if (value === "PENDING_APPROVAL") {
    return "In Progress";
  } else if (value === "DRAFTED") {
    return "Drafted";
  } else if (value === "EXPIRED") {
    return "Expired";
  } else if (value === "INVALID") {
    return "Invalid";
  }
}

export function displayStatus(value) {
  if (value === "SUBMIT") {
    return "Submitted";
  } else if (value === "PENDING") {
    return "Pending";
  } else if (value === "INVALIDATE") {
    return "Submitted-(NA)";
  } else {
    return "-";
  }
}

export function getCompositeIds(data) {
  return data?.length > 0
    ? data.map((v) => {
        return {
          employeeId: v.employeeId ? v.employeeId : v.value,
          subGroupId: v.subGroupId,
          parentGroupId: v.parentGroupId,
        };
      })
    : undefined;
}

export function getListOfValue(data) {
  return data?.length > 0 ? data.map((v) => v.value) : undefined;
}

export function keyValueAchievedTooltip(keyPoint) {
  if (keyPoint.type === "?") {
    return "Complete/Incomplete";
  } else if (keyPoint.type === "up") {
    return (
      <>
        <ReactSVG src={`${IC_OBJ_ARROW_UP}`} />
        <span>{keyPoint.start + " - " + keyPoint.end}</span>
      </>
    );
  } else if (keyPoint.type === ":") {
    return keyPoint.hours + " hours";
  } else if (keyPoint.type === "down") {
    return (
      <>
        <ReactSVG src={`${IC_OBJ_ARROW_DOWN}`} />
        <span>{keyPoint.start + " - " + keyPoint.end}</span>
      </>
    );
  } else if (keyPoint.type === "between") {
    return (
      <>
        <ReactSVG src={`${IC_OBJ_RANGE}`} />
        <span>{keyPoint.start + " - " + keyPoint.end}</span>
      </>
    );
  } else if (keyPoint.type === "%") {
    return keyPoint.start + keyPoint.type + " - " + keyPoint.end + keyPoint.type;
  } else {
    return keyPoint.start + " - " + keyPoint.end;
  }
}

export function getYearsAndMonths(data) {
  if (data) {
    if (data.split(".")[0] && data.split(".")[1]) {
      let months = (data.split(".")[1] * 12) / 10;
      return data.split(".")[0] + " years " + Math.round(months) + " months";
    } else return data.split(".")[0] + " years";
  }
}

export function flagCheck(percentage, rating) {
  if (rating === 1) {
    if (percentage > 20) return true;
    else return false;
  } else if (rating === 2) {
    if (!(percentage >= 21 && percentage <= 45)) return true;
    else return false;
  } else if (rating === 3) {
    if (!(percentage >= 46 && percentage <= 80)) return true;
    else return false;
  } else if (rating === 4) {
    if (!(percentage >= 81 && percentage <= 95)) return true;
    else return false;
  } else if (rating === 5) {
    if (!(percentage >= 96 && percentage <= 100)) return true;
    else return false;
  }
}

export function getOverAllRating(objectives, qStart, qEnd) {
  let singleObjRating,
    daysDiff,
    totalDayDiff = 0;
  let newObject = [];
  for (let i = 0; i < objectives.length; i++) {
    let a, b;
    let obj = {};
    if (objectives[i].rating) {
      if (moment(objectives[i].endDate) >= moment(qStart)) {
        if (moment(objectives[i].startDate) >= moment(qStart) && moment(objectives[i].endDate) <= moment(qEnd)) {
          a = moment(objectives[i].startDate);
          b = moment(objectives[i].endDate);
          daysDiff = b.diff(a, "days") + 1;
          obj.daysDiff = daysDiff;
          obj.rating = objectives[i].rating;
        } else if (moment(objectives[i].startDate) >= moment(qStart) && moment(objectives[i].endDate) >= moment(qEnd)) {
          a = moment(objectives[i].startDate);
          b = moment(qEnd);
          daysDiff = b.diff(a, "days") + 1;
          obj.daysDiff = daysDiff;
          obj.rating = objectives[i].rating;
        } else if (moment(objectives[i].startDate) <= moment(qStart) && moment(objectives[i].endDate) >= moment(qEnd)) {
          a = moment(qStart);
          b = moment(qEnd);
          daysDiff = b.diff(a, "days") + 1;
          obj.daysDiff = daysDiff;
          obj.rating = objectives[i].rating;
        } else if (moment(objectives[i].startDate) <= moment(qStart) && moment(objectives[i].endDate) <= moment(qEnd)) {
          a = moment(qStart);
          b = moment(objectives[i].endDate);
          daysDiff = b.diff(a, "days") + 1;
          obj.daysDiff = daysDiff;
          obj.rating = objectives[i].rating;
        }
        newObject.push(obj);
        totalDayDiff += daysDiff;
      }
    }
  }
  let overAllRating = 0;
  newObject.forEach((obj) => {
    singleObjRating = (obj.daysDiff * obj.rating) / totalDayDiff;
    overAllRating += singleObjRating;
  });
  return overAllRating;
}

export function getGradeName(grade) {
  switch (grade) {
    case "A+":
      return "(Outstanding)";
    case "A":
      return "(Exceed Expectations)";
    case "B+":
      return "(Meeting Expectations)";
    case "B":
      return "(Needs Improvement)";
    case "C":
      return "(Poor)";
    default:
      return null;
  }
}

export function decryptedDataBase64(data) {
  let decode = new Buffer(data, "base64");
  return decode.toString();
}

export function ratingValue(value) {
  if (value === "Poor" || value === 1) {
    return { value: 1, label: "Poor" };
  } else if (value === "Needs Improvement" || value === 2) {
    return { value: 2, label: "Needs Improvement" };
  } else if (value === "Meeting Expectations" || value === 3) {
    return { value: 3, label: "Meeting Expectations" };
  } else if (value === "Exceed Expectations" || value === 4) {
    return { value: 4, label: "Exceed Expectations" };
  } else if (value === "Outstanding" || value === 5) {
    return { value: 5, label: "Outstanding" };
  } else {
    return null;
  }
}

export function newRatingValue(value) { 
  if (value === "Need Development" || value === 1) {
    return { value: 1, label: "Need Development" };
  } else if (value === "Competent" || value === 2) {
    return { value: 2, label: "Competent" };
  } else if (value === "Exemplary" || value === 3) {
    return { value: 3, label: "Exemplary" };  
  } else {
    return null;
  }
}

export function oneToFourRatingValue(value) { 
  if (value === "Need Development" || value === 1) {
    return { value: 1, label: "Need Development" };
  } else if (value === "Competent" || value === 2) {
    return { value: 2, label: "Competent" };
  } else if (value === "Exemplary" || value === 3) {
    return { value: 3, label: "Exemplary" };
  } else if (value === "Outstanding" || value === 4) {
    return { value: 4, label: "Outstanding" };    
  } else {
    return null;
  }
}

export function gradeValue(grade) {
  if (grade === "A+") {
    return 5;
  } else if (grade === "A") {
    return 4;
  } else if (grade === "B+") {
    return 3;
  } else if (grade === "B") {
    return 2;
  } else if (grade === "C") {
    return 1;
  } else {
    return 0;
  }
}

export function gradeValuePerfomance(grade) {
  if (grade === "A+" || grade === "a+") {
    return 5.0;
  } else if (grade === "A" || grade === "a") {
    return 4.49;
  } else if (grade === "B+" || grade === "b+") {
    return 3.99;
  } else if (grade === "B" || grade === "b") {
    return 2.99;
  } else if (grade === "C" || grade === "c") {
    return 1.99;
  } else return grade;
}

export function getCurrentTimezone() {
  let timeOffset = new Date().getTimezoneOffset();
  let h = parseInt(timeOffset / 60);
  let m = timeOffset % 60;
  if (timeOffset < 0) {
    return `UTC+${h}:${m}`;
  } else {
    return `UTC-${h}:${m}`;
  }
}

function twoWord(value) {
  if (typeof value === "number") {
    if (value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  }
}

export function getHourAndMinString(date) {
  return `${twoWord(date.getHours())}:${twoWord(date.getMinutes())}`;
}

export function lastWord(string) {
  let array = string.split(" ");
  return array[array.length - 1];
}

export function firstWord(string) {
  return string?.split(" ")[0];
}

export function camelCase(string) {
  let str;
  if (string?.length > 0) {
    str = string[0].toUpperCase();
    str += string.substring(1).toLowerCase();
  }
  return str;
}

export function canManagerDoRating(isPage, allManager, loggedInUserEmpId, objective) {
  let objectiveManager = objective?.managerData.employeeId;
  if (loggedInUserEmpId === objectiveManager) {
    return true;
  } else if (objective.status === "COMPLETED") {
    return false;
  } else if (allManager.find((manager) => manager.employeeId !== objectiveManager)) {
    //if assign objective manager is past manager
    if (isPage === "REPORTING" && objective.updatedByManager) {
      return objective?.updatedByManager?.employeeId === loggedInUserEmpId;
    } else {
      return true;
    }
  } else if (allManager.find((manager) => manager.employeeId === objectiveManager)) {
    let isObjectiveManager = allManager.find((v) => v.type === "REPORTING" && v.employeeId === objectiveManager)
      ? "REPORTING"
      : "REVIEWING";
    if (objective.updatedByManager) {
      return objective?.updatedByManager?.employeeId === loggedInUserEmpId;
    } else if (isObjectiveManager === "REPORTING") {
      return loggedInUserEmpId === objectiveManager;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function getEmployeeIds(id) {
  return {
    employeeId: id?.split(config.employeeIdSplit)[0],
    parentGroupId: id?.split(config.employeeIdSplit)[1],
    subGroupId: id?.split(config.employeeIdSplit)[2],
  };
}

export function getPager(totalItems, currentPage, pageSize) {
  currentPage = Number(currentPage) || 1;
  pageSize = pageSize || 10;
  let totalPages = Math.ceil(totalItems / pageSize);
  let startPage, endPage;
  if (totalPages <= 10) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }

  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  let pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);

  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
}
export const filterOptions = (options, filter) => {
  if (!filter) {
    return options;
  }
  const re = new RegExp(filter, "i");
  return options.filter(({ label }) => label && label.match(re));
};

export const joinSpace = (str) => {
  if (str) {
    let lowerCase = str.toLowerCase();
    return lowerCase.split(" ").join("-");
  } else return "";
};

export const isBlinkSubMenu = (str,highlight) => {
  if(highlight){
    let quizArr = blinkMenuQuiz.includes(str);
    return quizArr
  } else return blinkMenu.includes(str);
};

export const setOneOnOneCol = (value) => {
  let col;
  if (value === "UPCOMING") col = "upcomingOneOnOne";
  else if (value === "PENDING") col = "isPendingOneOnOne";
  else if (value === "LAPSED") col = "lapsedOneOnOne";
  else if (value === "PREVIOUS") col = "previousOneOnOne";
  else if (value === "ALL") col = "ALL";
  return col;
};

export const checkArrow = (data) => {
  const competencyLevel = {
    Unexplored: 0,
    Beginner: 1,
    Intermediate: 2,
    Mastered: 3,
  };

  const yesNoCompetencyLevel = {
    Yes: 1,
    No: 0,
  };

  let expectedValue = data?.find((v) => v.isExpected)?.option;
  let managerValue = data?.find((v) => v.isManagerSelected)?.option;
  if (data?.length === 2 && data.find((value) => value.option === "Yes")) {
    expectedValue = "Yes";
    if (yesNoCompetencyLevel[expectedValue] < yesNoCompetencyLevel[managerValue]) {
      return GREEN_UP_ARROW;
    } else if (yesNoCompetencyLevel[expectedValue] > yesNoCompetencyLevel[managerValue]) {
      return RED_DOWN_ARROW;
    } else if (yesNoCompetencyLevel[expectedValue] === yesNoCompetencyLevel[managerValue]) {
      return YELLOW_STABLE_ARROW;
    } else return null;
  }

  if (competencyLevel[expectedValue] < competencyLevel[managerValue]) {
    return GREEN_UP_ARROW;
  } else if (competencyLevel[expectedValue] > competencyLevel[managerValue]) {
    return RED_DOWN_ARROW;
  } else if (competencyLevel[expectedValue] === competencyLevel[managerValue]) {
    return YELLOW_STABLE_ARROW;
  } else return null;
};

export const getCapabilityQUestionTooltip = (designation, questionName) => {
  return (
    capabilityTemplateMapping.find(
      (map) => questionName.includes(map.questionName) && map.designation.includes(designation)
    )?.toolTip || ""
  );
};

export const getStringArray = (array, key = "value") => {
  if (array?.length > 0) {
    return array.map((v) => v[key]);
  } else {
    return undefined;
  }
};

export const getEmployeeIdsArray = (array) => {
  if (array?.length > 0) {
    return array.map((value) => {
      return {
        employeeId: value.employeeId || value.value,
        parentGroupId: value.parentGroupId,
        subGroupId: value.subGroupId,
      };
    });
  } else return undefined;
};

export const checkYesAnswer = (array) => {
  if (array?.find((value) => value.option === "Yes" && value.isSelected)) {
    return true;
  } else return false;
};

export const getYesValue = (array) => {
  // only for radio button with YES and NO value
  if (array?.length === 2 && array.find((value) => value.option === "Yes" || value.option === "yes")) {
    return "Yes";
  } else return null;
};

export const setDropdownOption = (data) => {
  if (data?.length > 0) {
    return data?.map((v) => {
      return { value: v, label: v };
    });
  } else return [];
};

export const hightOfMainMenu = (data) => {
  if (data === "subGroupClient") {
    return "90px";
  } else if (data === "memberTypeList") {
    return "130px";
  } else if (data === "locationOption") {
    return "174px";
  } else if (data === "departmentList") {
    return "217px";
  } else if (data === "projectList") {
    return "257px";
  } else if (data === "indiaDDOption") {
    return "297px";
  } else if (data === "managerOptions") {
    return "340px";
  }
};

export const setArrayToObject = (data, ans) => {
  let obj = {};
  if (data?.length > 0) {
    data.map((value) => {
      obj[value.value + config.idSplit + value.label] = ans;
      return value;
    });
  }
  return obj;
};

export const setArrayToObjectWithOnlyValue = (data, ans) => {
  let obj = {};
  if (data?.length > 0) {
    data.map((value) => {
      obj[value.value] = ans;
      return value;
    });
  }
  return obj;
};

export const getKeyFromObject = (data) => {
  if (typeof data === "object" && Object.keys(data).length > 0) {
    return Object.entries(data)
      .filter(([, value]) => value)
      .map(([key]) => {
        return key.split(config.idSplit)[0];
      });
  } else {
    return undefined;
  }
};

export const getKeyFromObjectWithCompositeId = (data) => {
  if (typeof data === "object" && Object.keys(data).length > 0) {
    return Object.entries(data)
      .filter(([, value]) => value)
      .map(([key]) => {
        return {
          employeeId: key?.split(config.employeeIdSplit)[0],
          parentGroupId: key?.split(config.employeeIdSplit)[1],
          subGroupId: key?.split(config.employeeIdSplit)[2]?.split(config.idSplit)?.[0],
        };
      });
  } else {
    return undefined;
  }
};

export const filterNameFromOption = (data) => {
  if (data === "subGroupClient") {
    return "filterSubGroups";
  } else if (data === "memberTypeList") {
    return "filterMemberType";
  } else if (data === "locationOption") {
    return "filterLocation";
  } else if (data === "departmentList") {
    return "filterDepartment";
  } else if (data === "projectList") {
    return "filterProject";
  } else if (data === "indiaDDOption") {
    return "filterIndiaDD";
  } else if (data === "managerOptions") {
    return "filterManager";
  }
};

export const filterOptionFromName = (data) => {
  if (data === "filterSubGroups") {
    return "subGroupClient";
  } else if (data === "filterMemberType") {
    return "memberTypeList";
  } else if (data === "filterLocation") {
    return "locationOption";
  } else if (data === "filterDepartment") {
    return "departmentList";
  } else if (data === "filterProject") {
    return "projectList";
  } else if (data === "filterIndiaDD") {
    return "indiaDDOption";
  } else if (data === "filterManager") {
    return "managerOptions";
  }
};

export const setDropdownOptionForEmployee = (data) => {
  if (data?.length > 0) {
    return data?.map((value) => {
      return {
        value:
          value.employeeId + config.employeeIdSplit + value.subGroupId + config.employeeIdSplit + value.parentGroupId,
        employeeId: value.employeeId,
        label: value.empName,
        subGroupId: value.subGroupId,
        parentGroupId: value.parentGroupId,
      };
    });
  } else return [];
};

export const getFullMonthName = (period) => {
  const monthKey = period?.split(" ")[0];
  const months = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };
  return months[monthKey];
};

export {
  formatCycleData,
  getStatusInformation,
  getRatingClass,
  filterArray,
  getValue,
  scrollToTop,
  keyPointType,
  mergeArray,
  getShortName,
  keyValue,
  exportTeamObjectives,
  filterArrayWithBoolean,
};
