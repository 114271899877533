import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import style from "./CycleMemberProfile.module.scss";
import * as actions from "store/actions/index";
import { errorToast, maximumFileSize, maximumFiveFile, requiredFieldMissing, successToast } from "util/general";
import { moduleView, toFixedNumber, useQuery } from "util/utils";
import withLink from "hoc/withLink/withLink";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import BoxHeading from "shared/Box/BoxHeading";
import UserProfile from "../../user_profile/user_profile";
import ReviewerProfile from "../../reviewer-profile/reviewer-profile";
import Rewards from "../../Rewards/Rewards";
import MemberProfileObjective from "./MemberProfileObjecive/MemberProfileObjecive";
import SidebarCheckInActivity from "../../sidebar_checkins/sidebar_checkins";
import QuestionTemplate from "./QuestionTemplate/QuestionTemplate";
import SidebarWinsChallenges from "../../SidebarWinsChallenges/SidebarWinsChallenges";
import SubmitBox from "../../SubmitBox/SubmitBox";
import GlobalComment from "../GlobalComment/GlobalComment";
import GlobalReadOnlyComment from "../GlobalComment/GlobalReadOnlyComment";
import SubmittedDateBox from "../../SubmitBox/SubmittedDateBox";
import GradeInfoCard from "../../GradeInfoCard/GradeInfoCard";
import Assessment from "./SelfAssessmentRating/Assessment";
import config from "util/config";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import { successMessage } from "const/constValue";

const UserProfileWithLink = withLink(UserProfile);
export default function ReviewingMemberProfile(props) {
  const loggedInUser = storage.getUser();
  const loggedInUserEmpId = loggedInUser?.employeeId;
  const cycleId = props.match.params.id;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const isPageOther = useQuery().get("isPage") === "OTHER";
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const managerIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [ratingErrors, setRatingErrors] = useState([]);
  const {
    getPrivateAssessmentData,
    isFileLoading,
    isPerformanceResponseLoading,
    isSubmitted,
    fileResponse,
    updatedDate,
    submittedDate,
    paSubmitResponse,
    isPrivateAssessmentLoading,
    questionTemplateErrors,
    privateAssessmentObjectiveErrors,
    globalRVMComment,
    allManagerDetails,
    managerList,
    managerWiseGrade,
    globalRPMComment,
    globalSELFComment,
    globalSELFCommentUserData,
    globalFeedbackFileResponse,
    quaterlyCycleData,
  } = useSelector((state) => state.performance);
  const { user } = useSelector((state) => state.myTeam);
  const { winResponse, challengeResponse } = useSelector((state) => state.winChallenge);
  const { filterSurveyData } = useSelector((state) => state.myHistory);
  const count = useSelector((state) => state.madeMyDay.appreciateCount);
  const subMenuAvailable = moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.REPORTING_ASSESSMENT);
  useEffect(() => {
    dispatch({ type: "CLEAR_MY_TEAM_DATA" });
    dispatch(actions.GetAssessmentGlobalComment(employeeIds, cycleId));
    dispatch(actions.GetRPMAssessmentGlobalComment(employeeIds, cycleId));
    dispatch(actions.GetSelfAssessmentGlobalComment(employeeIds, cycleId));
    dispatch(actions.GetUser(employeeIds));
    dispatch(actions.GetAppreciateDurationCount(employeeIds, 6)); //by default show 6 months data
    dispatch(actions.FetchWinChallenge(employeeIds, managerIds, 3, "WIN"));
    dispatch(actions.FetchWinChallenge(employeeIds, managerIds, 3, "CHALLENGE"));
    dispatch(actions.GetFilterSurveyHistory({ ...employeeIds, filterByMonth: 12 }));
    dispatch(actions.GetPrivateAssessmentData(employeeIds, cycleId, isPageOther ? null : "REVIEWING", managerIds));
    dispatch(actions.getQuarterlyCycle(cycleId)); 
  }, [dispatch, cycleId]);

  useEffect(() => {
    if (getPrivateAssessmentData) {
      let selfRatingQuestion = getPrivateAssessmentData.selfQuestionTemplate?.questions?.filter(
        (v) => v.type === "SCALED" && v.respondToAll && v.notShowScaleToEmployee
      );
      if (selfRatingQuestion?.length > 0) {
        let errors = [];
        selfRatingQuestion.map((value) => {
          let managerRating = value.ratingCommentByRvm?.find((v) => v.employeeId === loggedInUserEmpId);
          if (managerRating?.scaleValue && !managerRating.comment) {
            errors.push({
              error: true,
              queId: value.questionId,
              type: "COMMENT",
            });
          }
          return value;
        });
        setRatingErrors(errors);
      }
    }
  }, [getPrivateAssessmentData, loggedInUserEmpId]);

  useEffect(() => {
    if (globalFeedbackFileResponse) {
      if (globalFeedbackFileResponse.success) successToast(globalFeedbackFileResponse.message);
      else errorToast(globalFeedbackFileResponse.message);
    }
  }, [globalFeedbackFileResponse]);

  const handleAppreciateDuration = (select) => dispatch(actions.GetAppreciateDurationCount(employeeIds, select.value));
  const gradeOrRatingOrBoth = getPrivateAssessmentData?.gradeOrRatingOrBoth;
  const isHideGradeInfoCard = getPrivateAssessmentData?.showRatingDropDown;
  let questionsAnswerTemplate = getPrivateAssessmentData?.rvmQuestionTemplate;
  if (questionsAnswerTemplate) questionsAnswerTemplate.type = "REVIEWING";
  const selfQuestionTemplate = getPrivateAssessmentData?.selfQuestionTemplate;
  const privateAssessmentId = getPrivateAssessmentData?.privateAssessmentId;
  const questionTemplateId = questionsAnswerTemplate?.questionTemplateId;
  const selfQuestionTemplateId = selfQuestionTemplate?.questionTemplateId;
  const selfPrivateAssessmentId = getPrivateAssessmentData?.selfPrivateAssessmentId;
  let selfAssessmentGrade = getPrivateAssessmentData?.selfAssessmentGrade;
  let overAllAggregateText = "Aggregate Grade";
  if (gradeOrRatingOrBoth === "BOTH") {
    selfAssessmentGrade = getPrivateAssessmentData?.selfAssessmentGrade
      ? getPrivateAssessmentData?.selfAssessmentGrade +
        " / " +
        toFixedNumber(getPrivateAssessmentData.selfAssessmentAvgRating, 2)
      : "";
    overAllAggregateText = "Aggregate Progress";
  } else if (gradeOrRatingOrBoth === "RATING") {
    selfAssessmentGrade = toFixedNumber(getPrivateAssessmentData.selfAssessmentAvgRating, 2);
    overAllAggregateText = "Aggregate Rating";
  }
  const selAssessmentRPMGrade =
    getPrivateAssessmentData?.rpmRatings?.map((manager) => {
      return {
        ...manager,
        grade:
          gradeOrRatingOrBoth === "BOTH"
            ? manager.grade
              ? manager.grade + "/" + toFixedNumber(manager.rating, 2)
              : ""
            : gradeOrRatingOrBoth === "RATING"
            ? toFixedNumber(manager.rating, 2)
            : manager.grade,
        type: "RPM",
      };
    }) || [];
  const selAssessmentRVMGrade =
    getPrivateAssessmentData?.autoSaveGrade || getPrivateAssessmentData?.autoSaveRating
      ? [
          {
            employeeId: loggedInUser?.employeeId,
            empName: loggedInUser?.displayName,
            grade:
              gradeOrRatingOrBoth === "BOTH"
                ? getPrivateAssessmentData?.autoSaveGrade +
                  "/" +
                  toFixedNumber(getPrivateAssessmentData?.autoSaveRating, 2)
                : gradeOrRatingOrBoth === "RATING"
                ? toFixedNumber(getPrivateAssessmentData?.autoSaveRating, 2)
                : getPrivateAssessmentData?.autoSaveGrade,
            type: "RVM",
          },
        ]
      : getPrivateAssessmentData?.rvmRatings?.map((manager) => {
          return {
            ...manager,
            grade:
              gradeOrRatingOrBoth === "BOTH"
                ? manager.grade
                  ? manager.grade + "/" + manager.rating
                  : ""
                : gradeOrRatingOrBoth === "RATING"
                ? manager.rating
                : manager.grade,
            type: "RVM",
          };
        }) || [];

  let questionTemplates = [];
  if (questionsAnswerTemplate || selfQuestionTemplate || getPrivateAssessmentData?.allRpmQuestionTemplate?.length > 0) {
    if (selfQuestionTemplate) {
      //if HR select respondToManager or respondToAll then manager can see this question
      selfQuestionTemplate.questions = selfQuestionTemplate.questions.filter(
        (v) => v.respondToManager || v.respondToAll
      );
      selfQuestionTemplate.name = "Self Assessment";
      selfQuestionTemplate.nameDOM = (
        <div>
          Self Assessment
          {/* <div className="manager_rating_wrapper">
            {selfAssessmentGrade ? <span>Aggregate Grade : {selfAssessmentGrade}</span> : ""}
          </div> */}
        </div>
      );
      selfQuestionTemplate.type = "SELF";
      selfQuestionTemplate.privateAssessmentId = selfPrivateAssessmentId;
      selfQuestionTemplate && questionTemplates.push(selfQuestionTemplate);
    }
    if (getPrivateAssessmentData?.allRpmQuestionTemplate?.length > 0) {
      getPrivateAssessmentData.allRpmQuestionTemplate.map((value) => {
        if (value.questionTemplate) {
          let rpmQuestionTemplate = value.questionTemplate;
          rpmQuestionTemplate.nameDOM = (
            <div>
              Reporting Assessment: <span style={{ fontWeight: "normal" }}>{value.userDetails?.empName}</span>
            </div>
          );
          rpmQuestionTemplate.privateAssessmentId = value.rpmPrivateAssessmentId;
          rpmQuestionTemplate.questionTemplateId = value.questionTemplate?.questionTemplateId;
          rpmQuestionTemplate.employeeId = value.userDetails?.employeeId;
          rpmQuestionTemplate.name = `Reporting Assessment ${value.userDetails?.empName}`;
          rpmQuestionTemplate.type = "REPORTING";
          questionTemplates.push(rpmQuestionTemplate);
        }
        return value;
      });
    }
    if (questionsAnswerTemplate) {
      questionsAnswerTemplate.name = "Reviewing Assessment";
      questionsAnswerTemplate.nameDOM = "Reviewing Assessment";
      questionsAnswerTemplate.type = "REVIEWING";
      questionsAnswerTemplate.privateAssessmentId = privateAssessmentId;
      questionsAnswerTemplate && questionTemplates.push(questionsAnswerTemplate);
    }
  }

  let isReportingRateInSelf = false;
  let isSelfAssessmentRating = false;
  if (selfQuestionTemplate) {
    if (selfQuestionTemplate.questions.find((value) => value.respondToManager || value.respondToAll)) {
      isSelfAssessmentRating = true;
      isReportingRateInSelf = true;
    }
  }
  if (getPrivateAssessmentData?.allRpmQuestionTemplate?.filter((v) => v.questionTemplate).length > 0) {
    isReportingRateInSelf = true;
  }

  //before submit comment
  const employeeComment =
    globalRVMComment?.length > 0 && globalRVMComment?.find((value) => value.user?.employeeId === loggedInUserEmpId);
  const otherComment =
    globalRVMComment?.length > 0 && globalRVMComment?.filter((value) => value.user?.employeeId !== loggedInUserEmpId);

  const saveAnswersHandler = () => {
    if (isSelfAssessmentRating) {
      let setError = ratingErrors.map((value) => {
        return {
          ...value,
          isSubmit: true,
        };
      });

      setRatingErrors(setError);
      if (ratingErrors.find((v) => v.error)) {
        errorToast("Fill comment in self assessment");
      }
    }
    dispatch({ type: "SET_QUESTION_TEMPLATE_VALIDATION", data: questionTemplateErrors });
    dispatch({
      type: "SET_PRIVATE_ASSESSMENT_OBJECTIVE_VALIDATION",
      data: privateAssessmentObjectiveErrors,
    });
    if (
      privateAssessmentObjectiveErrors.length > 0 &&
      quaterlyCycleData?.showRatingDropDown &&
      privateAssessmentObjectiveErrors.filter((value) => value.objectiveId).length > 0
    ) {
      errorToast("Please select objective rating");
    } else if (
      questionTemplateErrors.type === "REVIEWING" &&
      questionTemplateErrors?.errors?.length > 0 &&
      questionTemplateErrors?.errors.filter((value) => value.error || value.commentBoxError).length > 0
    ) {
      requiredFieldMissing();
      return;
    } else dispatch(actions.SavePrivateAssessmentAnswers(privateAssessmentId, employeeIds, "REVIEWING"));
  };
  //response useEffect
  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);

      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
    if (paSubmitResponse) {
      if (paSubmitResponse.success) {
        successToast(paSubmitResponse.message);
        const previousState = storage.get("reviewingAssesmentCurrentData");
        if (previousState) {
          storage.remove("reviewingAssesmentCurrentData");
          history.push(`${routePath.REVIEWING_ASSESSMENT}/${cycleId}`, {
            data: JSON.parse(previousState),
          });
        } else {
          history.push(`${routePath.REVIEWING_ASSESSMENT}/${cycleId}`);
        }
      } else errorToast(paSubmitResponse.message);

      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
  }, [fileResponse, paSubmitResponse, dispatch, cycleId, history]);

  const sendManagerComment = (comment, item) => {
    if (ratingErrors?.length > 0) {
      let errors = ratingErrors.filter((v) => v.queId !== item.questionId);
      setRatingErrors(errors);
    }
    let data = item;
    data = {
      questionId: item.questionId,
      managerComment: comment.trim(),
    };
    dispatch(
      actions.AutoSavePrivateAssessmentTemplate(
        data,
        selfPrivateAssessmentId,
        selfQuestionTemplateId,
        "SELF",
        employeeIds,
        cycleId,
        managerIds,
        "REVIEWING"
      )
    );
    successToast(successMessage.SAVE_COMMENT);
  };

  const sendRating = (rating, item, reporteeEmployeeId) => {
    let errors = ratingErrors;
    errors.push({ error: true, queId: item.questionId, isSubmit: false, reporteeEmployeeId: reporteeEmployeeId });
    setRatingErrors(errors);
    let data = item;
    data = {
      questionId: item.questionId,
      scaleValue: rating.value,
    };
    dispatch(
      actions.AutoSavePrivateAssessmentTemplate(
        data,
        selfPrivateAssessmentId,
        selfQuestionTemplateId,
        "SELF",
        employeeIds,
        cycleId,
        managerIds,
        "REVIEWING"
      )
    );
  };

  const downloadFile = (fileId, questionId, questionTemplateId, privateAssessmentId, assessmentType) => {
    let params = {
      fileId,
      questionId,
      privateAssessmentId,
      questionTemplateId,
      assessmentType,
    };
    dispatch(actions.DownloadFilePrivateAssessment(params));
  };

  const uploadFile = (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("file", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("questionId", id);
      formData.append("privateAssessmentId", privateAssessmentId);
      formData.append("questionTemplateId", questionTemplateId);
      formData.append("assessmentType", "REVIEWING");
      dispatch(actions.UploadFilePrivateAssessment(formData, employeeIds, cycleId, "REVIEWING"));
    } else maximumFiveFile();
  };

  const fileUploadByManager = (e, id, queTemplateId, paId, type) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("file", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("questionId", id);
      formData.append("isRVM", true);
      formData.append("privateAssessmentId", paId);
      formData.append("questionTemplateId", queTemplateId);
      formData.append("assessmentType", type);
      dispatch(actions.UploadFilePrivateAssessment(formData, employeeIds, cycleId, "REVIEWING"));
    } else maximumFiveFile();
  };

  const UploadFileGlobalFeedback = (e, files) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5 && files?.length + i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("files", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("assessmentType", "REVIEWING");
      formData.append("quarterlyCycleId", cycleId);
      formData.append("privateAssessmentId", privateAssessmentId);
      dispatch(actions.uploadFileGlobalFeedback(formData, employeeIds, cycleId, "REVIEWING"));
    } else maximumFiveFile();
  };

  const downloadFileGlobalFeedback = (id, privateAssessment_Id) => {
    const params = {
      fileId: id,
      privateAssessmentId: privateAssessment_Id !== undefined && typeof privateAssessment_Id !== 'object' ? privateAssessment_Id : privateAssessmentId ,
      // privateAssessmentId: privateAssessmentId,
    };
    dispatch(actions.DownloadFileGlobalFeedback(params));
  };

  const deleteFileGlobalFeedback = (id) => {
    const data = {
      fileId: id,
      privateAssessmentId: privateAssessmentId,
    };
    dispatch(actions.DeleteFileGlobalFeedback(data, employeeIds, cycleId, "REVIEWING"));
  };

  const deleteFile = (fileId, queId, queTemplateId, paId, type) => {
    const data = {
      fileId,
      questionId: queId,
      privateAssessmentId: paId,
      assessmentType: type,
      isRVM: type === "SELF" ? true : undefined,
    };
    dispatch(actions.DeleteFilePrivateAssessment({ data: data }, employeeIds, cycleId, "REVIEWING"));
  };

  const saveQuestionAnswer = (data) =>
    dispatch(actions.AutoSavePrivateAssessmentTemplate(data, privateAssessmentId, questionTemplateId, "REVIEWING"));

  const sendGlobalComment = (e) => {
    if (e.target.value?.trim())
      dispatch(
        actions.SetAssessmentGlobalComment(
          e.target.value.trim(),
          privateAssessmentId,
          cycleId,
          employeeIds,
          "REVIEWING"
        )
      );
  };

  let reviewers = null;
  if (getPrivateAssessmentData?.allRM || getPrivateAssessmentData?.allRVM) {
    let allManager = (getPrivateAssessmentData?.allRM || []).concat(getPrivateAssessmentData?.allRVM || []);
    // eslint-disable-next-line no-undef
    const uniqueManager = Array.from(new Set(allManager.map((a) => a.employeeId))).map((id) => {
      return allManager.find((a) => a.employeeId === id);
    });
    reviewers = {
      managersList: uniqueManager,
    };
  }

  let winsChallengesPath = `/okrprogress/my-team/member-okrprogress/dashboard/${employeeIds.employeeId}${config.employeeIdSplit}${employeeIds.parentGroupId}${config.employeeIdSplit}${employeeIds.subGroupId}`;

  let winsAndChallenges = [];
  if (winResponse || challengeResponse) {
    if (winResponse && winResponse.length > 0 && challengeResponse && challengeResponse.length > 0) {
      winsAndChallenges = winResponse.concat(challengeResponse);
    } else if (winResponse && winResponse.length > 0) {
      winsAndChallenges = winResponse;
    } else if (challengeResponse && challengeResponse.length > 0) {
      winsAndChallenges = challengeResponse;
    }
  }

  if (isPrivateAssessmentLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.cycleTeamWrapper}>
      <SubNavigation type="Performance" />
      {(isFileLoading || isPerformanceResponseLoading) && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Performance</h1>
            <span className="st-breadcrumb">
              {subMenuAvailable ? "Assessment" : <Link to={routePath.PERFORMANCE_DASHBOARD}>Dashboard</Link>}
              {subMenuAvailable && (
                <span>
                  <Link to={routePath.REVIEWING_ASSESSMENT}>Reviewing Assessment</Link>
                </span>
              )}

              <span>
                <Link to={`${routePath.REVIEWING_ASSESSMENT}/${cycleId}`}>{getPrivateAssessmentData?.cycle}</Link>
              </span>
              <span>{user && user.displayName}</span>
            </span>
          </Col>
          {getPrivateAssessmentData?.cycle && (
            <Col className="col-12">
              <BoxHeading heading={getPrivateAssessmentData.cycle} description={getPrivateAssessmentData.period} />
            </Col>
          )}
        </Row>
        {gradeOrRatingOrBoth !== "RATING" && isHideGradeInfoCard && <GradeInfoCard title="Performance Grade" />}
        <Row>
          <Col lg={8}>
            {user && <UserProfileWithLink user={user} />}
            {getPrivateAssessmentData?.objectiveTracking && (
              <MemberProfileObjective
                setAssessmentError={[]}
                managerList={managerList}
                managerWiseGrade={managerWiseGrade}
                assessmentError={[]}
                reviewer={reviewers}
                isPage="REVIEWING"
                isSubmitted={isSubmitted}
                employeeData={user}
                user={false}
                hrView={false}
                rowData={getPrivateAssessmentData}
                allManagerDetails={allManagerDetails}
                managerId={managerIds.employeeId}
              />
            )}
            {isReportingRateInSelf ? (
              <Assessment
                isPage="REVIEWING"
                selfAssessmentAvgGrade={selfAssessmentGrade}
                overAllAggregateText={overAllAggregateText}
                selfAssessmentManagerViseGrade={selAssessmentRPMGrade.concat(selAssessmentRVMGrade)}
                ratingErrors={ratingErrors}
                saveQuestionAnswer={saveQuestionAnswer}
                questionTemplateErrors={questionTemplateErrors}
                isSubmitted={isSubmitted}
                questionsAnswerTemplate={questionsAnswerTemplate}
                uploadFile={uploadFile}
                fileUploadByManager={fileUploadByManager}
                downloadFile={downloadFile}
                deleteFile={deleteFile}
                questionTemplates={questionTemplates}
                sendManagerComment={sendManagerComment}
                sendRating={sendRating}
                isRatingDisable={!quaterlyCycleData?.showRatingDropDown}
              />
            ) : (
              questionsAnswerTemplate && (
                <QuestionTemplate
                  viewTemplateName={true}
                  handleTextBox={saveQuestionAnswer}
                  handleRating={saveQuestionAnswer}
                  handleRatingComment={saveQuestionAnswer}
                  handleRadioComment={saveQuestionAnswer}
                  handleRadio={saveQuestionAnswer}
                  handleRadioOther={saveQuestionAnswer}
                  handleCheckBox={saveQuestionAnswer}
                  handleCheckBoxOther={saveQuestionAnswer}
                  handleCheckBoxComment={saveQuestionAnswer}
                  handleDropDown={saveQuestionAnswer}
                  handleDropDownComment={saveQuestionAnswer}
                  handleDropDownOther={saveQuestionAnswer}
                  handleScale={saveQuestionAnswer}
                  handleScaleComment={saveQuestionAnswer}
                  handleRankComment={saveQuestionAnswer}
                  handleRank={saveQuestionAnswer}
                  handleNps={saveQuestionAnswer}
                  handleNpsComment={saveQuestionAnswer}
                  questionTemplateErrors={questionTemplateErrors}
                  isSubmitted={isSubmitted}
                  questionsAnswerTemplate={questionsAnswerTemplate}
                  uploadFile={uploadFile}
                  fileUploadByManager={fileUploadByManager}
                  downloadFile={(fileName, qId) =>
                    downloadFile(fileName, qId, questionTemplateId, privateAssessmentId, "REVIEWING")
                  }
                  deleteFile={deleteFile}
                />
              )
            )}
            {getPrivateAssessmentData?.selfAssessmentStatus?.status === "Submit" && (
              <GlobalReadOnlyComment
                heading="Global Feedback"
                description="Here is what your team member want to say to you"
                comment={globalSELFCommentUserData}
                message="Employee haven't shared any view"
                downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                  downloadFileGlobalFeedback(id, privateAssessmentId)
                }
              />
            )}
            <GlobalReadOnlyComment
              heading="Reporting Manager's Global Feedback"
              description="Here is what Reporting manager has given their views"
              comment={globalRPMComment?.length > 0 ? globalRPMComment.filter((v) => v.status === "Submit") : []}
              message="Reporting manager haven't shared any view"
              downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                downloadFileGlobalFeedback(id, privateAssessmentId)
              }
            />
            {isSubmitted ? (
              <GlobalReadOnlyComment
                heading="Reviewing Manager's Global Feedback"
                description="Here is what Reviewing manager has given their views"
                comment={globalRVMComment?.length > 0 ? globalRVMComment.filter((v) => v.status === "Submit") : []}
                message="You haven't shared any view"
                downloadFileGlobalFeedback={(id, privateAssessment_Id) =>
                  downloadFileGlobalFeedback(id, privateAssessment_Id)
                }
              />
            ) : (
              <GlobalComment
                heading="Reviewing Manager's Global Feedback"
                optional={true}
                loggedInUser={loggedInUser}
                employeeComment={employeeComment}
                comments={otherComment?.length > 0 ? otherComment.filter((v) => v.status === "Submit") : []}
                sendGlobalComment={sendGlobalComment}
                description="Here is what you want to say to team member"
                isMultipleFile={true}
                isPageRVM={true}
                obj={globalRVMComment.length > 0 ? globalRVMComment.filter((v) => v.status === "SAVED") : []}
                UploadFileGlobalFeedback={(e) => UploadFileGlobalFeedback(e, globalRVMComment.filter((v) => v.user.employeeId === managerIds.employeeId && v.user.parentGroupId === managerIds.parentGroupId && v.user.subGroupId === managerIds.subGroupId)[0]?.files)}
                downloadFileGlobalFeedback={(id, privateAssessment_Id) =>
                  downloadFileGlobalFeedback(id, privateAssessment_Id)
                }
                deleteFileGlobalFeedback={(id, privateAssessmentId) =>
                  deleteFileGlobalFeedback(id, privateAssessmentId)
                }
              />
            )}
            {isSubmitted ? (
              <SubmittedDateBox submittedDate={submittedDate} />
            ) : (
              <SubmitBox handleSubmit={saveAnswersHandler} updatedDate={updatedDate} name="Submit" />
            )}
          </Col>
          <Col lg={4}>
            {filterSurveyData && <SidebarCheckInActivity filterSurveyData={filterSurveyData} />}
            {winsAndChallenges && winsAndChallenges.length > 0 && (
              <SidebarWinsChallenges winsAndChallenges={winsAndChallenges} pathName={winsChallengesPath} />
            )}
            {count && <Rewards count={count} handleAppreciateDuration={handleAppreciateDuration} />}
            {reviewers?.managersList?.length > 0 && <ReviewerProfile managerList={reviewers.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
