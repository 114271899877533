import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import MultipleFileUpload from "../../../MultipleFileUpload/MulitpleFileUpload";

function CommentBox({
  image,
  blurHandler,
  comment,
  selectedColor,
  commentLength,
  error,
  handleMultipleFileUpload,
  isMultipleFile,
}) {
  return (
    <div className="view_comment_box_row flex-column">
      <div className="comment_box_wrapper pb-0">
        <div className="user_pics" style={{ backgroundColor: selectedColor }}>
          {image && image.length > 2 ? <img src={image} alt="pic" /> : image}
        </div>
        <div className="comment_area_wrapper">
          <Form.Group className="form_group_comment mb-0">
            <div className="textarea-wrapper">
              <Form.Control
                as="textarea"
                maxLength={commentLength}
                defaultValue={comment}
                onBlur={blurHandler}
                placeholder="Add a comment"
                className={["manager_comments", error && "limit-reached"].join(" ")}
              />
              {isMultipleFile && <MultipleFileUpload handleMultipleFileUpload={handleMultipleFileUpload} />}
            </div>
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

CommentBox.propTypes = {
  comment: PropTypes.string,
  image: PropTypes.string,
  blurHandler: PropTypes.func,
  commentLength: PropTypes.number,
};

export default CommentBox;
