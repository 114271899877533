import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import style from "./PMSDashboard.module.scss";
import * as actions from "store/actions/index";
import * as actionTypes from "store/actions/actionTypes";
import { errorToast, filterArray, firstWord, lastWord, requiredFieldMissing, successToast } from "util/general";
import { moduleRights } from "util/utils";
import { errorMessage, noData } from "const/constValue";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "../../NodataFound/NoDataFound";
import AssessmentEmployeeView from "../Dashboard/AssessmentEmployeeView/AssessmentEmployeeView";
import TwoHeaderTable from "../../DynamicTable/TwoHeaderTable";
import EmployeeListPopup from "../../EmployeeListPopup/EmployeeListPopup";
import PMSFilterBox from "../../PMSFilterBox/PMSFilterBox";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

function PMSDashboard() {
  const dispatch = useDispatch();
  const [tableView, setTableView] = useState();
  const [criteria, setCriteria] = useState();
  const [cycleName, setCycleName] = useState();
  const [selectedCycleName, setSelectedCycleName] = useState();
  const [filterType, setFilterType] = useState();
  const [filterGrade, setFilterGrade] = useState([]);
  const [filterPARating, setFilterPARating] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);
  const {
    assessmentFilterData,
    bandOption,
    locationOption,
    indiaDDOption,
    departmentOption,
    criteriaOption,
    rateTypeOption,
    pmOption,
    dmOption,
    ddOption,
    subGroupOption,
    projectList,
    cycleListOption,
    tableHeader,
    filteredViewComparison,
    employeesViewComparison,
    tableData,
    isLoading,
    openPopup,
    employeeViewInPopup,
    popupBody,
    HREmployeeNameList,
    privateAssessmentDataExport,
  } = useSelector((state) => state.hrDashboardReport);

  const {
    filterBand,
    filterDepartment,
    filterIndiaDD,
    filterProject,
    filterLocation,
    filterEmployeeList,
    filterSubGroup,
    filterDD,
    filterPM,
    filterDM,
  } = assessmentFilterData;
  useEffect(() => {
    dispatch(actions.getCycleListOptions());
  }, [dispatch]);

  useEffect(() => {
    if (privateAssessmentDataExport) {
      if (privateAssessmentDataExport.success) successToast(privateAssessmentDataExport.message);
      else errorToast(privateAssessmentDataExport.message);
      dispatch({ type: "CLEAR_NOTIFICATION" });
    }
  }, [privateAssessmentDataExport]);

  const handleClickValue = (criteriaValue, colValue) => {
    const subHeaderLength = tableHeader[1]?.subHeaderName?.length;
    const subIndex = colValue % subHeaderLength;
    let selectedGrade = tableHeader[1].subHeaderName[subIndex === 0 ? subHeaderLength - 1 : subIndex - 1];
    const mainIndex = parseInt((colValue - 1) / subHeaderLength);
    const selectedCycle = tableHeader[mainIndex + 1]?.headerName;
    setSelectedCycleName(selectedCycle);
    //if criteria value select then this criteria value filter not passed but if criteriaValue is Total then we need to pass filter

    let body = {
      cycleId: cycleName?.find((v) => v.label === selectedCycle)?.value,
      criteria: criteria?.value,
      criteriaValue,
      rating: filterType?.value?.endsWith("Rating") ? selectedGrade : undefined,
      grade: filterType?.value?.endsWith("Grade") ? selectedGrade : undefined,
      ratingList: filterPARating?.length > 0 ? filterPARating.map((v) => v.value) : undefined,
      type: lastWord(filterType?.value),
      gradeList: filterGrade?.length > 0 ? filterGrade.map((v) => v.value) : undefined,
      subGroupId: filterSubGroup?.length > 0 ? filterSubGroup.map((v) => v.value) : undefined,
      cycleGraphId: cycleName?.map((v) => v.value),
      location: filterLocation?.length > 0 ? filterLocation.map((v) => v.value) : undefined,
      department:
        criteria?.value === "Department" && criteriaValue !== "Total"
          ? undefined
          : filterDepartment?.length > 0
          ? filterDepartment.map((v) => v.value)
          : undefined,
      band:
        criteria?.value === "Band" && criteriaValue !== "Total"
          ? undefined
          : filterBand?.length > 0
          ? filterBand.map((v) => v.value)
          : undefined,
      indiaDD:
        criteria?.value === "IndiaDD" && criteriaValue !== "Total"
          ? undefined
          : filterIndiaDD?.length > 0
          ? filterIndiaDD.map((v) => v.label)
          : undefined,
      projectName:
        criteria?.value === "Project" && criteriaValue !== "Total"
          ? undefined
          : filterProject?.length > 0
          ? filterProject.map((v) => v.value)
          : undefined,
      pm:
        criteria?.value === "ProjectManager" && criteriaValue === "Total" && filterPM?.length > 0
          ? filterPM.map((v) => v.value)
          : undefined,
      dm:
        criteria?.value === "ProjectDeliveryManager" && criteriaValue === "Total" && filterDM?.length > 0
          ? filterDM.map((v) => v.value)
          : undefined,
      dd:
        criteria?.value === "ProjectDeliveryDirector" && criteriaValue === "Total" && filterDD?.length > 0
          ? filterDD.map((v) => v.value)
          : undefined,
    };
    if (filterType?.value?.includes("Objective")) {
      dispatch(actions.getEmployeeViewForPopup(body, "OBJECTIVE"));
    } else if (!filterType?.value?.includes("Objective")) {
      body = {
        ...body,
        assessmentType: firstWord(filterType.value),
      };
      dispatch(actions.getEmployeeViewForPopup(body, "ASSESSMENT"));
    }
  };

  const handlePopUpExport = () => {
    dispatch(actions.downloadPrivateAssessTableEmployeeView(filterType?.value, popupBody));
  };

  const getObjectiveGradeData = (cycle, criteria, others) => {
    let body = {
      cycleId: cycle.map((v) => v.value),
      criteria,
      type: lastWord(filterType?.value),
      ...others,
    };
    dispatch(actions.getHRDashboardAssessmentTable("OBJECTIVE", body));
  };

  const getPrivateAssessmentData = (cycle, criteria, others) => {
    let body = {
      //type is GRADE OR RATING assessmentTYPE is SELF REPORTING AND REVIEWING
      cycleId: cycle.map((v) => v.value),
      criteria,
      assessmentType: firstWord(filterType?.value),
      type: lastWord(filterType?.value),
      ...others,
    };
    dispatch(actions.getHRDashboardAssessmentTable("ASSESSMENT", body));
  };

  const handleFilter = () => {
    setTableView(false);
    let others = {
      projectName: filterProject?.length > 0 ? filterProject.map((v) => v.value) : undefined,
      department: filterDepartment?.length > 0 ? filterDepartment.map((v) => v.value) : undefined,
      band: filterBand?.length > 0 ? filterBand.map((v) => v.value) : undefined,
      indiaDD: filterIndiaDD?.length > 0 ? filterIndiaDD.map((v) => v.value) : undefined,
      location: filterLocation?.length > 0 ? filterLocation.map((v) => v.value) : undefined,
      subGroupId: filterSubGroup?.length > 0 ? filterSubGroup.map((v) => v.value) : undefined,
      grade:
        filterType?.value?.endsWith("Grade") && filterGrade?.length > 0 ? filterGrade.map((v) => v.value) : undefined,
      paRating:
        filterType?.value?.endsWith("Rating") && filterPARating?.length > 0
          ? filterPARating.map((v) => v.value)
          : undefined,
      pm: criteria?.value === "ProjectManager" && filterPM?.length > 0 ? filterPM.map((v) => v.value) : undefined,
      dm:
        criteria?.value === "ProjectDeliveryManager" && filterDM?.length > 0 ? filterDM.map((v) => v.value) : undefined,
      dd:
        criteria?.value === "ProjectDeliveryDirector" && filterDD?.length > 0
          ? filterDD.map((v) => v.value)
          : undefined,
    };
    if (!filterType) {
      errorToast(errorMessage.SELECT_FILTER_TYPE);
      return;
    }
    if (filterType?.value?.includes("Objective")) {
      if (cycleName && criteria) {
        getObjectiveGradeData(cycleName, criteria.value, others);
      } else {
        requiredFieldMissing();
      }
    } else if (!filterType?.value?.includes("Objective")) {
      if (cycleName && criteria) {
        getPrivateAssessmentData(cycleName, criteria.value, others);
      } else {
        requiredFieldMissing();
      }
    }
  };

  const handleClear = () => {
    dispatch({
      type: "CLEAR_HR_DASHBOARD_ASSESSMENT_FILTER_DATA",
    });
    setCycleName();
    setFilterGrade();
    setFilterPARating();
    setFilterType("");
    setCriteria();
  };

  const handleFilterData = (variable, e) => {
    dispatch({
      type: "SET_HR_DASHBOARD_ASSESSMENT_FILTER_DATA",
      payload: {
        [variable]: e,
      },
    });
  };

  const handleEmployeeView = () => {
    let body;
    if (cycleName && cycleName.length === 1) {
      errorToast(errorMessage.MINIMUM_TWO_CYCLE);
      return;
    }
    if (cycleName?.length > 1 && filterType) {
      setTableView(filterType?.value);
      if (filterEmployeeList?.length > 0) {
        body = {
          //type is GRADE OR RATING assessmentTYPE is SELF REPORTING AND REVIEWING
          cycleId: cycleName.map((v) => v.value),
          employeeId: filterEmployeeList.map((v) => v.value),
          type: lastWord(filterType?.value),
          assessmentType: filterType?.value?.includes("Objective") ? undefined : firstWord(filterType?.value),
        };
      } else {
        body = {
          //type is GRADE OR RATING assessmentTYPE is SELF REPORTING AND REVIEWING
          cycleId: cycleName.map((v) => v.value),
          location: filterLocation?.length > 0 ? filterLocation.map((v) => v.value) : undefined,
          subGroupId: filterSubGroup?.length > 0 ? filterSubGroup.map((v) => v.value) : undefined,
          department: filterDepartment?.length > 0 ? filterDepartment.map((v) => v.value) : undefined,
          band: filterBand?.length > 0 ? filterBand.map((v) => v.value) : undefined,
          indiaDD: filterIndiaDD?.length > 0 ? filterIndiaDD.map((v) => v.label) : undefined,
          projectName: filterProject?.length > 0 ? filterProject.map((v) => v.value) : undefined,
          type: lastWord(filterType?.value),
          assessmentType: filterType?.value?.includes("Objective") ? undefined : firstWord(filterType.value),
          pm: criteria?.value === "ProjectManager" && filterPM?.length > 0 ? filterPM.map((v) => v.value) : undefined,
          dm:
            criteria?.value === "ProjectDeliveryManager" && filterDM?.length > 0
              ? filterDM.map((v) => v.value)
              : undefined,
          dd:
            criteria?.value === "ProjectDeliveryDirector" && filterDD?.length > 0
              ? filterDD.map((v) => v.value)
              : undefined,
        };
      }
      dispatch(actions.getEmployeeViewAssessmentComparison(body));
    } else {
      requiredFieldMissing();
    }
  };

  //Employee Search
  const getEmployeeNameList = (value) => {
    if (value.length > 2) {
      dispatch(actions.getHREmployeeNameList(value));
    }
  };

  const handleAllCountExport = () => {
    //type is GRADE OR RATING assessmentTYPE is SELF REPORTING AND REVIEWING
    let body = {
      cycleId: cycleName?.map((v) => v.value),
      criteria: criteria?.value,
      location: filterLocation?.length > 0 ? filterLocation.map((v) => v.value) : undefined,
      subGroupId: filterSubGroup?.length > 0 ? filterSubGroup.map((v) => v.value) : undefined,
      department: filterDepartment?.length > 0 ? filterDepartment.map((v) => v.value) : undefined,
      band: filterBand?.length > 0 ? filterBand.map((v) => v.value) : undefined,
      indiaDD: filterIndiaDD?.length > 0 ? filterIndiaDD.map((v) => v.label) : undefined,
      projectName: filterProject?.length > 0 ? filterProject.map((v) => v.value) : undefined,
      assessmentType: !filterType?.value?.includes("Objective") ? firstWord(filterType?.value) : undefined,
      type: lastWord(filterType?.value),
      grade:
        filterType?.value?.endsWith("Grade") && filterGrade?.length > 0 ? filterGrade.map((v) => v.value) : undefined,
      paRating:
        filterType?.value?.endsWith("Rating") && filterPARating?.length > 0
          ? filterPARating.map((v) => v.value)
          : undefined,
      pm: criteria?.value === "ProjectManager" && filterPM?.length > 0 ? filterPM.map((v) => v.value) : undefined,
      dm:
        criteria?.value === "ProjectDeliveryManager" && filterDM?.length > 0 ? filterDM.map((v) => v.value) : undefined,
      dd:
        criteria?.value === "ProjectDeliveryDirector" && filterDD?.length > 0
          ? filterDD.map((v) => v.value)
          : undefined,
    };
    dispatch(actions.downloadPrivateAssessTable(filterType.value, body));
  };

  const handleEmployeeViewExport = () => {
    let body;
    if (cycleName?.length > 1 && filterType) {
      if (filterEmployeeList?.length > 0) {
        body = {
          cycleId: cycleName.map((v) => v.value),
          employeeId: filterEmployeeList.map((v) => v.value),
          type: lastWord(filterType.value),
          assessmentType: filterType?.value?.includes("Objective") ? undefined : firstWord(filterType.value),
        };
      } else {
        body = {
          cycleId: cycleName.map((v) => v.value),
          location: filterLocation?.length > 0 ? filterLocation.map((v) => v.value) : undefined,
          subGroupId: filterSubGroup?.length > 0 ? filterSubGroup.map((v) => v.value) : undefined,
          department: filterDepartment?.length > 0 ? filterDepartment.map((v) => v.value) : undefined,
          band: filterBand?.length > 0 ? filterBand.map((v) => v.value) : undefined,
          indiaDD: filterIndiaDD?.length > 0 ? filterIndiaDD.map((v) => v.label) : undefined,
          projectName: filterProject?.length > 0 ? filterProject.map((v) => v.value) : undefined,
          type: lastWord(filterType.value),
          assessmentType: filterType?.value?.includes("Objective") ? undefined : firstWord(filterType.value),
        };
      }
      dispatch(actions.getEmployeeViewAssessmentExport(body));
    }
  };

  const handleCycleList = (value) => {
    dispatch(actions.getDropdownOptions(value.map((cycle) => cycle.value)));
    setCycleName(value);
  };

  const handleLineChart = (cycleId, employeeIds) => {
    const win = window.open(
      `${routePath.CYCLE_MEMBER_PROFILE}/${cycleId}?empId=${employeeIds?.employeeId}&pgId=${employeeIds?.parentGroupId}&sgId=${employeeIds?.subGroupId}&assessmentType=HR`,
      "_blank"
    );
    win.focus();
  };

  //Employee assessment compare filter
  const search = (name) => {
    let filteredData = null;
    const filters = {
      empName: name,
      upOrDown: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(employeesViewComparison, filters);
    dispatch({
      type: actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON,
      payload: { filteredViewComparison: filteredData },
    });
    setSearchFilter(name);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
      upOrDown: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(employeesViewComparison, filters);
    dispatch({
      type: actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON,
      payload: { filteredViewComparison: filteredData },
    });
    setSearchFilter("");
  };

  const handleStatusChange = (value) => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      upOrDown: value,
    };
    filteredData = filterArray(employeesViewComparison, filters);
    setStatusFilter(value);
    dispatch({
      type: actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON,
      payload: { filteredViewComparison: filteredData },
    });
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      upOrDown: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(employeesViewComparison, filters);
    dispatch({
      type: actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON,
      payload: { filteredViewComparison: filteredData },
    });
    setSearchFilter(searchFilter);
  };

  const upDownFilter = {
    value: ["", "UP", "DOWN"],
    name: ["All", "Up", "Down"],
  };
  //employee assessment view compare filter End

  return (
    <div>
      <SubNavigation type="Performance" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">
              Performance<span>PMS Dashboard</span>
            </span>
          </Col>
        </Row>
        <PMSFilterBox
          criteriaOption={criteriaOption}
          setCycleName={handleCycleList}
          handleFilter={handleFilter}
          handleClear={handleClear}
          handleEmployeeView={handleEmployeeView}
          filterType={filterType}
          filterGrade={filterGrade}
          setFilterGrade={setFilterGrade}
          filterPARating={filterPARating}
          setFilterPARating={setFilterPARating}
          departmentOption={departmentOption}
          rateTypeOption={rateTypeOption}
          filterDepartment={filterDepartment}
          handleFilterData={handleFilterData}
          bandOption={bandOption}
          filterBand={filterBand}
          indiaDDOption={indiaDDOption}
          filterIndiaDD={filterIndiaDD}
          filterPM={filterPM}
          filterDM={filterDM}
          filterDD={filterDD}
          filterSubGroup={filterSubGroup}
          pmOption={pmOption}
          dmOption={dmOption}
          ddOption={ddOption}
          subGroupOption={subGroupOption}
          projectList={projectList}
          filterProject={filterProject}
          locationOption={locationOption}
          filterLocation={filterLocation}
          cycleListOption={cycleListOption}
          cycleName={cycleName}
          criteria={criteria}
          setCriteria={setCriteria}
          setFilterType={setFilterType}
          HREmployeeNameList={HREmployeeNameList}
          filterEmployeeList={filterEmployeeList}
          getEmployeeNameList={getEmployeeNameList}
        />
        {tableView && (
          <AssessmentEmployeeView
            isExport={moduleRights(config.hrModule.PERFORMANCE, config.subModule.PMS_DASHBOARD)?.includes("EXPORT")}
            handleEmployeeViewExport={handleEmployeeViewExport}
            current={{
              label: `Current ${lastWord(tableView)}`,
              cycleName: filteredViewComparison[0]?.gradeHistory[0]?.cycle,
              period: filteredViewComparison[0]?.gradeHistory[0]?.period,
            }}
            previous={{
              label: `Previous ${lastWord(tableView)}`,
              cycleName: filteredViewComparison[0]?.gradeHistory[1]?.cycle,
              period: filteredViewComparison[0]?.gradeHistory[1]?.period,
            }}
            filteredViewComparison={filteredViewComparison}
            employeesViewComparison={employeesViewComparison}
            handleLineChart={handleLineChart}
            pageOfItems={pageOfItems}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            setPageOfItems={setPageOfItems}
            search={search}
            resetSearch={resetSearch}
            handleStatusChange={handleStatusChange}
            resetData={resetData}
            onChangePage={onChangePage}
            filter={upDownFilter}
          />
        )}
        {!tableView && (
          <div className="box pb-0 mb-0">
            <div className="box-heading-wrapper">
              <div className="box-heading">
                <h2>Employees Data</h2>
                <h3>Detail view of employees performance</h3>
              </div>
            </div>
            <div className={[style.dynamic_table_wrapper].join(" ")}>
              <div className="box-light-gradient"></div>
              <div className={[style.custom_table, "table-theme-1"].join(" ")}>
                {tableHeader && tableHeader.length > 0 ? (
                  <div>
                    <TwoHeaderTable
                      tableHeader={tableHeader}
                      tableData={tableData}
                      handleClickValue={handleClickValue}
                      infoIcon={false}
                    />
                    {moduleRights(config.hrModule.PERFORMANCE, config.subModule.PMS_DASHBOARD)?.includes("EXPORT") && (
                      <Button
                        type="button"
                        variant={"primary"}
                        className={["btn-round", style.table_export_btn].join(" ")}
                        onClick={() => handleAllCountExport()}
                      >
                        <strong>Export</strong>
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap120"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
      {employeeViewInPopup?.length > 0 && openPopup && (
        <EmployeeListPopup
          selectedCycleName={selectedCycleName}
          secondColum={filterType?.value}
          employees={employeeViewInPopup}
          openPopup={openPopup}
          closePopup={() => {
            dispatch({ type: "CLOSE_PMS_EMPLOYEE_VIEW_POPUP" });
          }}
          handleExport={handlePopUpExport}
          handleLineChart={handleLineChart}
          isExport={
            moduleRights(config.hrModule.PERFORMANCE, config.subModule.PMS_DASHBOARD)?.includes("EXPORT") ? true : false
          }
        />
      )}
    </div>
  );
}

export default PMSDashboard;
