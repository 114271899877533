import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import NoDataFound from "../NodataFound/NoDataFound";
import EmployeeListRow from "./EmployeeListRow";
import style from "./EmployeeListPopup.module.scss";
import { Form } from "react-bootstrap";
import { filterArray } from "../../util/general";
import { IC_COLLAPSE, IC_EXPAND, IC_INPUT_SEARCH } from "../../const/imgCost";
import { useDispatch, useSelector } from "react-redux";
import CampaignQuestionAndAnswer from "../Campaign/CampaignView/CampaignQuestionAndAnswer";
import { noData } from "const/constValue";

function EmployeeListModal({
  employees,
  openPopup,
  closePopup,
  modalHeading,
  modalDescription,
  secondColum,
  thirdColum,
  departmentShow,
  handleEmployeeClick,
  handleSave,
  saveBtn,
  plusIcon,
  searchPlaceholder,
  modalSize,
  isExpandVisible,
}) {
  let [employee, setEmployee] = useState(employees);
  useEffect(() => {
    setEmployee(employees);
    return () => {
      setEmployee([]);
    };
  }, []);
  let [searchFilter, setSearchFilter] = useState("");
  let className = "form-control-rounded form-search-item";
  if (searchFilter.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }
  const search = (e) => {
    let filteredData = null;
    const filters = {
      empName: e.target.value,
    };
    filteredData = filterArray(employees, filters);
    setEmployee(filteredData);
    setSearchFilter(e.target.value);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
    };
    filteredData = filterArray(employees, filters);
    setEmployee(filteredData);
    setSearchFilter("");
  };
  const dispatch = useDispatch();
  const { multiExpanded } = useSelector((state) => state.managerDashboard);
  const toggleExpander = (id) => {
    multiExpanded.includes(id)
      ? dispatch({ type: "REMOVE_MULTI_EXPAND", payload: id })
      : dispatch({ type: "MULTI_EXPAND", payload: id });
  };

  return (
    <>
      <Modal
        size={modalSize}
        show={openPopup}
        onHide={closePopup}
        animation={false}
        className={style.assigneepopup_wrapper}
      >
        <div className={style.modal_header}>
          <div className="row">
            <div className="col-xl-8 col-lg-6 col-md-12 col-12">
              <h1>{modalHeading}</h1>
              <h2>{modalDescription}</h2>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 col-12">
              <div className={style.search_bar}>
                <Form className={style.allobjective_searchbar}>
                  <Form.Group controlId="" className="form-postfix mb-0 w-100">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder={searchPlaceholder}
                      name="searchBox"
                      className={className}
                      onChange={search}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <Button className="close-button" onClick={resetSearch} type="reset">
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body>
          <div className="box-light-gradient"></div>
          <div className="col-xl-12">
            <div className={style.data_container}>
              {employee && employee.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <td className={style.name}>Name</td>
                      {secondColum && <td className={style.objective_assigned}>{secondColum}</td>}
                      {thirdColum && <td className={style.status}>{thirdColum}</td>}
                    </tr>
                  </thead>
                  <tbody>
                    {employee.map((value, index) => (
                      <React.Fragment key={value.employeeId}>
                        <EmployeeListRow
                          toggleExpander={() => toggleExpander(value.employeeId)}
                          toggleIcon={
                            plusIcon ? (multiExpanded.includes(value.employeeId) ? IC_COLLAPSE : IC_EXPAND) : null
                          }
                          index={index}
                          displayPicture={value.displayPicture || value.empInitial}
                          secondColum={secondColum && value[secondColum.toLowerCase()]}
                          thirdColum={thirdColum && value[thirdColum.toLowerCase()]}
                          department={departmentShow && value.department}
                          designation={departmentShow && value.designation}
                          empName={value.empName}
                          employeeData={value}
                          handleEmployeeClick={handleEmployeeClick}
                          id={value.id}
                        />
                        {isExpandVisible && multiExpanded.includes(value.employeeId) && (
                          <tr className={style.inside_row}>
                            <td colSpan="3">
                              <CampaignQuestionAndAnswer
                                questions={value.questions}
                                isCapability={value.isCapability}
                              />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.assigned_modal_footer}>
          {saveBtn && (
            <Button
              type="button"
              variant={"primary"}
              className={["btn-round", style.btn_save].join(" ")}
              onClick={handleSave}
            >
              <strong>{saveBtn}</strong>
            </Button>
          )}
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_cancel].join(" ")}
            onClick={closePopup}
          >
            <strong>Close</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmployeeListModal;
EmployeeListModal.defaultProps = {
  modalSize: "lg",
  isExpandVisible: false,
};
