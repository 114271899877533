import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Collapse, Button, Form } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import style from "./TabEndroseByMe.module.scss";
import * as actions from "store/actions/index";
import {
  IMG_ENDORSEMENT_1,
  IMG_PM,
  IMG_SME,
  IMG_UNI,
  IC_COLLAPSE,
  IC_EXPAND,
  IC_INPUT_SEARCH,
  IC_INFO,
} from "const/imgCost";
import { errorToast, filterArray, successToast } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";
import NoDataFound from "../../../NodataFound/NoDataFound";
import { competencyLevel, noData } from "const/constValue";

export default function TabEndroseByMe() {
  const [open, setOpen] = useState({
    flag: false,
    id: null,
  });
  const [disbleSkillDropdown, setDisableSkillDropdown] = useState(true);
  const [disbleTypeDropdown, setDisableTypeDropdown] = useState(true);
  const [employees, setEmployeeList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [employee, selectEmployee] = useState(null);
  const [skill, selectSkill] = useState(null);
  const [endorsement, setEndorsementType] = useState([]);
  const dispatch = useDispatch();
  const [endorseType, setEndorseType] = useState(null);
  const [disable, setDisable] = useState(true);
  const {
    isLoading,
    endorsementSkill,
    employeeList,
    endorsementType,
    endorseEmployeeSuccessResponse,
    endorseEmployeeErrorResponse,
    getEndorseByMe,
    size,
  } = useSelector((state) => state.skillMatrix);
  const [searchText, setSearchText] = useState("");
  const [pageOfItems, setPageOfItems] = useState([]);
  const [typeArr, setFilterType] = useState(["ALL"]);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };
  const [endorseData, setEndorseData] = useState([]);

  useEffect(() => {
    if (skill && employee && endorseType) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [skill, employee, endorseType]);

  useEffect(() => {
    const array = [];
    if (getEndorseByMe && Object.keys(getEndorseByMe).length > 0) {
      Object.keys(getEndorseByMe).map((sk) => {
        const obj = {
          [sk]: getEndorseByMe[sk],
        };
        array.push(obj);
        return sk;
      });
      setEndorseData(array);
      setFilteredEmployeeData(array);
    }
  }, [getEndorseByMe]);

  useEffect(() => {
    if (employeeList && employeeList.length > 0) {
      const array = [];
      employeeList.forEach((emp) => {
        const obj = {};
        obj["value"] = emp.employeeId;
        obj["label"] = emp.displayName;
        obj["parentGroupId"] = emp.parentGroupId;
        obj["subGroupId"] = emp.subGroupId;
        array.push(obj);
      });
      setEmployeeList(array);
    }
  }, [endorsementSkill, employeeList]);

  useEffect(() => {
    const getEndorsementsByMe = () => dispatch(actions.getEndorsementByMe);
    const getEmployeeList = () => dispatch(actions.employeeList);

    getEndorsementsByMe();
    getEmployeeList();
  }, [dispatch]);

  useEffect(() => {
    if (endorsementType && endorsementType.length > 0) {
      setDisableTypeDropdown(false);
      const array = [];
      endorsementType.forEach((emp) => {
        const obj = {};
        obj["value"] = emp.endorseTypeId;
        obj["label"] = emp.endorseTypeName;
        array.push(obj);
      });
      setEndorsementType(array);
    }
  }, [endorsementType]);

  useEffect(() => {
    if (endorsementSkill && endorsementSkill.length > 0) {
      setDisableSkillDropdown(false);
      const array = [];
      endorsementSkill.forEach((sk) => {
        const obj = {};
        obj["value"] = sk.skillIdNameId;
        obj["label"] = sk.skillIdName;
        array.push(obj);
      });
      setSkills(array);
    }
  }, [endorsementSkill]);

  const setSkill = (select) => {
    if (select) {
      selectSkill(select);
      setEndorseType(null);
      setDisableTypeDropdown(true);
    } else {
      setTimeout(() => {
        setDisableTypeDropdown(true);
      }, 1000);
      selectSkill(null);
      setEndorseType(null);
    }
    if (select && employee) {
      dispatch(
        actions.getEndorsementTypes({
          skillId: select.value,
          endorseToId: employee.value,
          endorseToSubGroupId: employee.subGroupId,
          endorseToParentGroupId: employee.parentGroupId,
        })
      );
    }
  };

  const setEmployee = (select) => {
    if (select) {
      selectEmployee(select);
      setEndorseType(null);
      selectSkill(null);
      setDisableTypeDropdown(true);
      setDisableSkillDropdown(true);
      dispatch(
        actions.getEndorsementSkill({
          employeeId: select.value,
          parentGroupId: select.parentGroupId,
          subGroupId: select.subGroupId,
        })
      );
    } else {
      setTimeout(() => {
        setDisableTypeDropdown(true);
        setDisableSkillDropdown(true);
      }, 1000);
      selectEmployee(null);
      selectSkill(null);
      setEndorseType(null);
    }
  };

  useEffect(() => {
    if (endorseEmployeeSuccessResponse && !endorseEmployeeSuccessResponse.error) {
      successToast(endorseEmployeeSuccessResponse.message);
      selectSkill(null);
      setEndorseType(null);
      selectEmployee(null);
      setDisableTypeDropdown(true);
      setDisableSkillDropdown(true);
      setSearchText("");
      setFilterType(["ALL"]);
    }
    if (endorseEmployeeErrorResponse?.error) errorToast(endorseEmployeeErrorResponse.message);
  }, [endorseEmployeeSuccessResponse, endorseEmployeeErrorResponse]);

  const submit = () => {
    const data = {
      endorsedTypeId: endorseType.value,
      endorsedTypeName: endorseType.label,
      endorsedToId: employee.value,
      endorsedToSubGroupId: employee.subGroupId,
      endorsedToParentGroupId: employee.parentGroupId,
      endorsedToName: employee.label,
      endorsedSkill: {
        skillIdName: skill.label,
        skillIdNameId: skill.value,
      },
    };
    dispatch(actions.endorseEmployee(data));
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    const filterData = [];
    if (getEndorseByMe && Object.keys(getEndorseByMe).length > 0) {
      if (searchText.length > 0) {
        Object.keys(getEndorseByMe).map((emp) => {
          if (getEndorseByMe[emp].employeeName.toLowerCase().includes(searchText.toLowerCase().trim())) {
            filterData.push({ [emp]: getEndorseByMe[emp] });
          }
          return emp;
        });
      } else {
        Object.keys(getEndorseByMe).map((emp) => {
          filterData.push({ [emp]: getEndorseByMe[emp] });
          return emp;
        });
      }
      setEndorseData(filterData);
      setFilteredEmployeeData(filterData);
    }
  }, [getEndorseByMe, searchText]);

  const reset = () => {
    setSearchText("");
    setFilterType(["ALL"]);
  };

  useEffect(() => {
    const array = [];
    if (getEndorseByMe && Object.keys(getEndorseByMe).length > 0) {
      Object.keys(getEndorseByMe).map((sk) => {
        const obj = {
          [sk]: getEndorseByMe[sk],
        };
        array.push(obj);
        return sk;
      });
    }
    filteredData.length > 0
      ? setEndorseData(filteredData)
      : null
      ? setEndorseData(filteredData)
      : setEndorseData(array);
  }, [filteredData, getEndorseByMe]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (endorseData && endorseData.length > 0) {
      filteredData = filterArray(endorseData, filters);
      setFilteredData(filteredData);
    }
  };

  const filterByEndorseType = (e) => {
    setOpen({
      flag: false,
      id: null,
    });
    const value = e.target.value;
    setFilterType((prevState) => prevState.filter((type) => type !== "ALL"));
    if (e.target.checked) {
      if (value === "ALL") {
        setFilterType(["ALL"]);
      } else {
        setFilterType((prevState) => [...prevState, value]);
      }
    } else {
      setFilterType((prevState) => prevState.filter((type) => type !== value));
    }
  };

  useEffect(() => {
    const array = [];
    let skillArr = [];
    let value;
    let isExist = false;
    let filterArr = [];
    let employeeEndorsement = JSON.parse(JSON.stringify(filteredEmployeeData));

    typeArr.map((type) => {
      value = type === "UNIVERSITY" ? 1 : type === "PEER" ? 4 : type === "MANAGER" ? 3 : type === "SME" ? 2 : 0;
      employeeEndorsement.map((endorsedData) => {
        Object.keys(endorsedData).map((emp) => {
          if (value === 0) {
            array.push({ [emp]: endorsedData[emp] });
          } else {
            skillArr = endorsedData[emp].allEndorsedSkill.filter((sk) => +sk.endorsedTypeId === value);
            if (skillArr.length > 0) {
              const data = JSON.parse(JSON.stringify(endorsedData));
              data[emp].allEndorsedSkill = [...skillArr];

              array.map((empData, index) => {
                Object.keys(empData).map((id) => {
                  if (emp === id) {
                    isExist = true;
                    skillArr.map((sk) => array[index][id].allEndorsedSkill.push(sk));
                  }
                  return id;
                });
                return empData;
              });
              if (!isExist) {
                array.push({ [emp]: data[emp] });
              }
            }
          }
          return emp;
        });
        isExist = false;
        return endorseData;
      });
      isExist = false;
      return type;
    });
    if (searchText.length > 0) {
      array.map((arrItem) => {
        Object.keys(arrItem).map((key) => {
          if (arrItem[key].employeeName.toLowerCase().includes(searchText.toLowerCase().trim())) {
            filterArr.push({ [key]: arrItem[key] });
          }
          return key;
        });
        return arrItem;
      });
    } else {
      filterArr = array;
    }

    if (filterArr.length === 0) {
      setPageOfItems([]);
    }
    setEndorseData(filterArr);
  }, [filteredEmployeeData, getEndorseByMe, searchText, typeArr]);

  return (
    <>
      <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
        {isLoading && <Spinner />}
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Endorsement</h2>
            <h3>Endorse your colleagues for their skills</h3>
          </div>
        </div>
        <div className="box-inner-pad">
          <Row>
            <Col lg={4} md={6}>
              <span className={style.dd_title}>Endorse To</span>
              <div className="card_action">
                <Select
                  options={employees}
                  className="custom-default-dd-box"
                  classNamePrefix="select"
                  isSearchable={true}
                  placeholder="Select Endorse to"
                  isClearable={true}
                  onChange={(select) => setEmployee(select)}
                  value={employee}
                />
              </div>
            </Col>
            <Col lg={4} md={6}>
              <span className={style.dd_title}>Skill</span>
              <div className="card_action">
                <Select
                  options={skills}
                  className="custom-default-dd-box"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable={true}
                  placeholder="Select Skill"
                  isDisabled={disbleSkillDropdown}
                  onChange={(select) => setSkill(select)}
                  value={skill}
                />
              </div>
            </Col>
            <Col lg={4} md={6}>
              <span className={style.dd_title}>Endorsement Type</span>
              <div className="card_action">
                <Select
                  options={endorsement}
                  isDisabled={disbleTypeDropdown}
                  className="custom-default-dd-box"
                  classNamePrefix="select"
                  isSearchable={true}
                  placeholder="Select Endorsement Type"
                  isClearable={true}
                  onChange={(select) => {
                    if (select) {
                      setEndorseType(select);
                    } else {
                      setEndorseType(null);
                    }
                  }}
                  value={endorseType}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="box-inner-pad">
          <button
            type="button"
            className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
            onClick={submit}
            disabled={disable}
          >
            <strong>Endorse</strong>
          </button>
        </div>
      </div>
      <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Endorsements given by You</h2>
            <h3>List of Colleagues whom you have enndorsed</h3>
          </div>
        </div>
        <div className="tab_filter">
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="check_in_search_bar">
                <Form>
                  <Form.Group controlId="" className="form-postfix mb-0">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Search by Employee"
                      name="searchBox"
                      className={className}
                      onChange={handleSearchInputChange}
                      value={searchText}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <Button className="close-button" type="reset" onClick={reset}>
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={8} xl={8}>
              <div className="tab_check_in_status">
                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="all1"
                    type="checkbox"
                    name="status-check"
                    value="ALL"
                    checked={typeArr.includes("ALL")}
                    onChange={(e) => filterByEndorseType(e)}
                  />
                  <label htmlFor="all1">All</label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g11"
                    type="checkbox"
                    name="status-check"
                    value="UNIVERSITY"
                    onChange={(e) => filterByEndorseType(e)}
                    checked={typeArr.includes("UNIVERSITY")}
                  />
                  <label htmlFor="g11">University</label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g22"
                    type="checkbox"
                    name="status-check"
                    value="MANAGER"
                    onChange={(e) => filterByEndorseType(e)}
                    checked={typeArr.includes("MANAGER")}
                  />
                  <label htmlFor="g22">Manager</label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g33"
                    type="checkbox"
                    name="status-check"
                    value="PEER"
                    onChange={(e) => filterByEndorseType(e)}
                    checked={typeArr.includes("PEER")}
                  />
                  <label htmlFor="g33">Peer</label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g44"
                    type="checkbox"
                    name="status-check"
                    value="SME"
                    onChange={(e) => filterByEndorseType(e)}
                    checked={typeArr.includes("SME")}
                  />
                  <label htmlFor="g44">SME</label>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="box-light-gradient h38"></div>
        {pageOfItems && pageOfItems.length > 0 ? (
          <div className={[style.skill_table, "custom-table", "table-theme-1"].join(" ")}>
            <div className={[style.endorsement_skill_table, "position-relative"].join(" ")}>
              <table className="table">
                <thead>
                  <tr>
                    <th className={[style.skill_name, "w-100"].join(" ")}>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItems.map((data) => {
                    return Object.keys(data).map((emp, empIndex) => {
                      return (
                        <React.Fragment key={empIndex}>
                          <tr>
                            <td>
                              <div
                                className={[style.skill_name, "w-100"].join(" ")}
                                onClick={() => {
                                  if (open.id !== emp) {
                                    setOpen({ flag: true, id: emp });
                                  } else {
                                    setOpen({ flag: !open.flag, id: emp });
                                  }
                                }}
                                aria-controls={open.id}
                              >
                                <i className={style.expand_collapse_wrapper}>
                                  <img
                                    src={open.id === emp && open.flag ? IC_COLLAPSE : IC_EXPAND}
                                    className={open.id === emp ? style.collapse : style.expand}
                                    alt="Collapse"
                                  />
                                </i>
                                <span className={style.user_img}>
                                  <img src={data[emp].employeePic} alt="User" />
                                </span>
                                <div className={style.user_info}>
                                  <span className={style.username}>{data[emp].employeeName}</span>
                                  <span className={style.department}>
                                    {data[emp].employeeDesignation} | {data[emp].employeeDepartment}{" "}
                                    <i className={style.counter}>({data[emp].allEndorsedSkill.length})</i>
                                  </span>
                                </div>
                              </div>
                              <Collapse in={open.id === emp && open.flag ? true : false} id={emp}>
                                <div id={open.id} className={style.endrosement_info}>
                                  <div className="box-light-gradient h38"></div>
                                  <ul className={style.skills_head}>
                                    <li>
                                      <span className={style.skillsname}>Skill Name</span>
                                      <span className={style.endorsement_type}>Endorse Type</span>
                                      <span className={style.cmp_lvl}>Endorsed Skill Competency</span>
                                      <span className={style.rel_exp}>Rel. Experience</span>
                                      <span className={style.cmp_lvl}>Current Competency Level</span>
                                      <span className={style.last_used_on}>Endorsement date</span>
                                    </li>
                                  </ul>
                                  <ul className={style.skills_body}>
                                    {data[emp].allEndorsedSkill.map((skill) => {
                                      const skillName = skill.skillName;

                                      const currentCompetencyLevel =
                                        competencyLevel[skill.currentCompatencyLevel] || "-";
                                      const endorseLevel = competencyLevel[skill.endorsedCompetencyLevel] || "-";
                                      return (
                                        <li key={`${emp}${skillName}`}>
                                          {/* <span className={style.skillsname}>{skillName}</span> */}
                                          <div className={style.skillsname}>
                                            <span>{skillName}</span>
                                            {skill["endorseRequestByDepartmentName"] && (
                                              <div
                                                className={[
                                                  style.mmg_tooltip,
                                                  "custom-tooltip",
                                                  "mmg_table_tooltip",
                                                ].join(" ")}
                                              >
                                                <div className="box-info">
                                                  <span>
                                                    <img className="svg" src={IC_INFO} alt="box-info" />
                                                  </span>
                                                </div>
                                                <div className="info-attach-file">
                                                  <p>
                                                    Requested by <b>WMG ({skill["endorseRequestByName"]})</b>
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className={[style.endorsement, style.endorsement_type].join(" ")}>
                                            {skill.endorsedTypeId === "4" && (
                                              <span className={style.endorse_tooltip}>
                                                <img src={IMG_ENDORSEMENT_1} alt="PEER" />
                                                <span className={style.tool_tip_info}>Peer</span>
                                              </span>
                                            )}
                                            {skill.endorsedTypeId === "3" && (
                                              <span className={style.endorse_tooltip}>
                                                <img src={IMG_PM} alt="PM" />
                                                <span className={style.tool_tip_info}>Manager</span>
                                              </span>
                                            )}
                                            {skill.endorsedTypeId === "2" && (
                                              <span className={style.endorse_tooltip}>
                                                <img src={IMG_SME} alt="SME" />
                                                <span className={style.tool_tip_info}>SME</span>
                                              </span>
                                            )}
                                            {skill.endorsedTypeId === "1" && (
                                              <span className={style.endorse_tooltip}>
                                                <img src={IMG_UNI} alt="University" />
                                                <span className={style.tool_tip_info}>University</span>
                                              </span>
                                            )}
                                          </div>
                                          <div className={[style.cmp_lvl, style.current].join(" ")}>
                                            <span className={[style.req_comp_level, endorseLevel].join(" ")}>
                                              {endorseLevel}
                                            </span>
                                          </div>
                                          <span className={style.rel_exp}>{skill.relExperience}</span>
                                          <div className={[style.cmp_lvl, style.current].join(" ")}>
                                            <span className={[style.req_comp_level, currentCompetencyLevel].join(" ")}>
                                              {currentCompetencyLevel}
                                            </span>
                                          </div>
                                          <span className={style.last_used_on}>
                                            {moment(skill.endorsementDate).format("MMMM D, YYYY")}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </Collapse>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            <div className="gap120"></div>
          </div>
        )}
      </div>
      {endorseData && endorseData.length !== 0 && (
        <Pagination pageSize={size} items={endorseData} onChangePage={onChangePage} resetData={resetData} />
      )}
    </>
  );
}
