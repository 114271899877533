import moment from "moment";
import React from "react";
import style from "./SubmitBox.module.scss";

const SubmittedDateBox = ({ submittedDate, reviewedBy, submittedText }) => {
  return (
    <div className={["box box-flex-center", style.saveBox].join(" ")}>
      <div className={style.save_box_center}>
        {submittedDate ? (
          <>
            {submittedText}:{" "}
            <span>
              {moment(submittedDate).format("ddd D MMM h:mm a")}
              {reviewedBy && ` by ${reviewedBy}`}
            </span>
          </>
        ) : (
          "Not submitted yet"
        )}
      </div>
    </div>
  );
};

SubmittedDateBox.defaultProps = {
  reviewedBy: null,
  submittedDate: null,
  submittedText: "Submitted On",
};

export default SubmittedDateBox;
