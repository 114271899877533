import React from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";

const ConfirmationPopup = (props) => {
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} className="custom_modal_popup">
        <div className="custom_modal_container">
          <Row>
            <Col md={12}>
              <Modal.Body>
                <h1>Are you sure you want to preview this Template?</h1>
                <p>If you have not save all the questions please save it or it will discard all the changes done.</p>
              </Modal.Body>
            </Col>
            <Col className="custom_modal_button">
              <Button
                variant="primary"
                onClick={() => {
                  props.submit();
                }}
              >
                Submit
              </Button>

              <Button variant="secondary" className="btn-border" onClick={() => props.hide()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationPopup;
