import React from "react"

export default function HideAndShow(props){

  return (
    <div className="card_action">
      <span onClick={() => props.setHide(!props.hide)}>
        {props.hide ? "Show" : "Hide"}
      </span>
    </div>
  )
}

