import React from "react";
import { Collapse } from "react-bootstrap";
import Comment from "components/CommentBox/Comment";
import CommentBox from "components/CommentBox/CommentBox";
import CommentsTop from "components/CommentBox/CommentsTop";
import { getColorByIndex } from "util/color";

function CollapseCommentBox({
  comments,
  isOpen,
  deleteComment,
  questionId,
  managerCommentHandle,
  managerPrivateCommentHandle,
  changeHandle,
  comment,
  employee,
  readOnly,
}) {
  return (
    <Collapse in={isOpen}>
      <div className="view_comment_box_wrapper">
        <CommentsTop heading="Share your view(s)" />
        {comments?.length > 0 &&
          comments
            .filter((item) => !item.reply)
            .map((answer, index) => {
              return (
                <Comment
                  selectedColor={getColorByIndex(index)}
                  key={index}
                  deleteComment={() => deleteComment(answer.commentId, answer.private, answer.reply)}
                  deletable={!readOnly}
                  image={answer.commentedBy && answer.commentedBy.displayPicture}
                  empName={answer.commentedBy && answer.commentedBy.empName}
                  commentedOn={answer.commentedOn}
                  comment={answer.comment}
                  isPrivate={answer.private}
                  privateMsg={`Private message, only visible to you`}
                />
              );
            })}
        {!readOnly && (
          <CommentBox
            id={questionId}
            employee={employee}
            style={{ idp_info_comment: {} }}
            selectedColor={"#FF5479"}
            managerCommentHandle={managerCommentHandle}
            managerPrivateCommentHandle={managerPrivateCommentHandle}
            changeHandle={(event) => changeHandle(event.target.value)}
            comment={comment}
            // chars={chars}
          />
        )}
      </div>
    </Collapse>
  );
}

export default CollapseCommentBox;
