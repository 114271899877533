import ReviewerProfile from "components/reviewer-profile/reviewer-profile";
import UserProfile from "components/user_profile/user_profile";
import * as actions from "store/actions/index";
import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import storage from "util/storage";
import style from "./EmployeeViewQuizResult.module.scss";
import EmployeeViewQuizPerformance from "./EmployeeViewQuizPerformance";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { noData } from "const/constValue";
import NoDataFound from "components/NodataFound/NoDataFound";
import Spinner from "shared/Spinner/Spinner";
import { useHistory } from "react-router-dom";

export default function EmployeeViewQuizResult(props) {
  const loggedInUser = storage.getUser();
  const dispatch = useDispatch();
  const campaignID = props.match.params.id;
  const employeeId = loggedInUser?.employeeId;
  const employeeIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  // const {isFinish} = useSelector((state) => state.campaign);
  const { reviewer } = useSelector((state) => state.checkIn);
  const { employeeViewQuizResult, isEmployeeViewQuizResultLoading } = useSelector(
    (state) => state.campaign
  );

  const history = useHistory();

  useEffect(() => {
    /** redirecting user on quiz list page when click on back button*/
    return () => {
      if (history.action === "POP" || history.action === "REPLACE") {
        history.replace(`/okrprogress/quiz`/* the new state */);
      }
    };
  }, [history]);

  const user = loggedInUser
    ? {
        employeeId: loggedInUser?.employeeId,
        employeeInitial: loggedInUser?.empInitial,
        displayName: loggedInUser?.displayName,
        designation: loggedInUser?.designation,
        department: loggedInUser?.department,
        dispArr: loggedInUser?.displayPicture,
        band: loggedInUser?.band,
        firstName: loggedInUser?.fname,
        totalExp: loggedInUser?.totalExp,
        parentGroupName: loggedInUser?.parentGroupName,
        expBeforeJoining: loggedInUser?.expBeforeJoining,
        isExp: loggedInUser?.isExp,
        email: loggedInUser?.email,
        location: loggedInUser?.location,
        country: loggedInUser?.country,
      }
    : null;
  useEffect(() => {
    dispatch(actions.GetReviewer(employeeIds));
  }, [dispatch, employeeId]);

  // useEffect(() => {
  //   if (apiResponse) {
  //     apiResponse.success ? successToast(apiResponse.message) : errorToast(apiResponse.message);
  //   }
  // }, [apiResponse, dispatch]);
  // commenting this for back button issue
  // useEffect(() => {
  //   if (isFinish) {
  //     dispatch({ type: "ISFINISH", payload: false });
  //   }
  // }, []);

  useEffect(() => {
    if (campaignID) {
      dispatch(actions.getQuizResultForEmployee(campaignID));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.getQuizTemplateEmployeeViewOngoingData({ status: "Ongoing" }));
    if (campaignID) {
      dispatch(actions.getQuizResultForEmployee(campaignID));
    }
    const isTimer = window.sessionStorage.getItem("delay-timer");

    if (isTimer >= 0) {
      window.sessionStorage.removeItem("delay-timer");
    }

    if (window.sessionStorage.getItem("isRefreshed")) {
      window.sessionStorage.removeItem("isRefreshed");
    }
    window.sessionStorage.removeItem("started");
    dispatch({ type: "IS_BACK_DISABLE", payload: true });
  }, []);

  const [defaultKey, setDefaultKey] = useState(employeeViewQuizResult?.attemptResultList?.length);

  useEffect(() => {
    setDefaultKey(employeeViewQuizResult?.attemptResultList?.length);
  }, [employeeViewQuizResult?.attemptResultList?.length]);

  return (
    <>
      <SubNavigation type="OKR Progress" />
      <Container>
        <Row>
          <Col lg={8} className={style.quiz_result_wrapper}>
            {user && <UserProfile user={user} />}
            {isEmployeeViewQuizResultLoading && <Spinner />}
            {employeeViewQuizResult?.attemptResultList === null ||
            employeeViewQuizResult?.attemptResultList === undefined ? (
              <div className={["box", style.quiz_not_attempted].join(" ")}>
                <NoDataFound description={noData.QUIZ_NOT_ATTEMPTED_DONT_HAVE_THE_DATA} />
              </div>
            ) : (
              <></>
            )}
            <div
              className={[
                "custom-tabs",
                "custom-tabs-full-width",
                employeeViewQuizResult?.attemptResultList?.length === 1 ? style.firstAttemptTab : style.home_tabs,
              ].join(" ")}
            >
              <Tabs
                id="Tabs-navigation"
                activeKey={defaultKey == undefined ? employeeViewQuizResult?.attemptResultList?.length : defaultKey}
                onSelect={(key) => setDefaultKey(key)}
              >
                {employeeViewQuizResult &&
                  employeeViewQuizResult?.attemptResultList?.length > 0 &&
                  employeeViewQuizResult?.attemptResultList?.map((item, index) => (
                    <Tab title={"Attempt " + ++index} eventKey={index} key={index}>
                      <EmployeeViewQuizPerformance
                        heading={"My Quiz Performance"}
                        item={item}
                        index={index}
                        campaignID={campaignID}
                        legnth={employeeViewQuizResult?.attemptResultList?.length == index ? true : false}
                        remainingAttempt={employeeViewQuizResult.remainingAttempt}
                        isStartEnable={
                          props?.location?.state?.hideStart === undefined ? true : props.location.state.hideStart
                        }
                      />
                    </Tab>
                  ))}
              </Tabs>
            </div>
          </Col>
          <Col lg={4}>
            {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer.managersList} />}
          </Col>
        </Row>
      </Container>
    </>
  );
}
