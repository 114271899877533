import React from "react";
import { Col, Row } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import Select from "react-select";
import { useDispatch } from "react-redux";
import * as actionTypes from "store/actions/actionTypes";
import { filterOptions } from "util/general";
import { btnName, MonthlyCheckinTexts } from "const/constValue";
import style from "./FiltersSection.module.scss";

export default function FiltersSection({
  bandOptions,
  cycleAndPeriodOptions,
  groupOptions,
  employeeTypeOptions,
  locationOptions,
  managerOptions,
  statusOptions,
  subGroupOptions,
  feedbackFilterData,
  verticalHeadOptions,
  handleFilter,
  handleClear,
}) {
  const {
    filterCheckInPeriod,
    filterGroup,
    filterSubGroup,
    filterManagerName,
    filterEmployeeType,
    filterBandWise,
    filterStatusWise,
    filterLocationWise,
    filterVerticalHead, } = feedbackFilterData;
  const dispatch = useDispatch();

  const handleFilterData = (variable, e) => {
    dispatch({
      type: actionTypes.MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_FILTER_DATA,
      payload: {
        [variable]: e,
      },
    });
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12} className="col-12">
          <h1 className="page-title pb-0">{MonthlyCheckinTexts.MONTHLY_CHECK_IN_HR_TITLE}</h1>
          <span className="st-breadcrumb">
            Feedback<span>{MonthlyCheckinTexts.MONTHLY_CHECK_IN_HR_TITLE}</span>
          </span>
        </Col>
      </Row>
      <div className={`box ${style.filters_section_container}`}>
        <div className="box-heading-wrapper">
          <div className={`box-heading ${style.inner_header}`}>
            <h2>Filter(s)</h2>
            <h6>
              <span className={style.greyText}>
                Select appropriate values for view Feedback
              </span>
            </h6>
          </div>
        </div>
        <div className="box-light-gradient"></div>
        <div className="box-inner-pad no-border">
          <Row>
            {cycleAndPeriodOptions && cycleAndPeriodOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Check-in Period<span className="pl-1 text-danger">*</span></span>
                  <div className="tags_wrapper">
                    <Select
                      className="custom-default-dd-box"
                      classNamePrefix="select"
                      placeholder="Select"
                      options={cycleAndPeriodOptions}
                      value={filterCheckInPeriod || ""}
                      onChange={(e) => { handleFilterData("filterCheckInPeriod", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
            {subGroupOptions && subGroupOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Sub Group</span>
                  <div className="tags_wrapper">
                    <MultiSelect
                      options={subGroupOptions}
                      value={filterSubGroup || ""}
                      onChange={(e) => { handleFilterData("filterSubGroup", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
            {locationOptions && locationOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Location</span>
                  <div className="tags_wrapper">
                    <MultiSelect
                      options={locationOptions}
                      value={filterLocationWise || ""}
                      onChange={(e) => { handleFilterData("filterLocationWise", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
            {bandOptions && bandOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Band</span>
                  <div className="tags_wrapper">
                    <MultiSelect
                      options={bandOptions}
                      value={filterBandWise || ""}
                      onChange={(e) => { handleFilterData("filterBandWise", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
            {groupOptions && groupOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Group</span>
                  <div className="tags_wrapper">
                    <MultiSelect
                      options={groupOptions}
                      value={filterGroup || ""}
                      onChange={(e) => { handleFilterData("filterGroup", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
            {managerOptions && managerOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Manager</span>
                  <div className="tags_wrapper">
                    <MultiSelect
                      options={managerOptions}
                      value={filterManagerName || ""}
                      onChange={(e) => { handleFilterData("filterManagerName", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
            {employeeTypeOptions && employeeTypeOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Employee Type</span>
                  <div className="tags_wrapper">
                    <MultiSelect
                      options={employeeTypeOptions}
                      value={filterEmployeeType || ""}
                      onChange={(e) => { handleFilterData("filterEmployeeType", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
            {verticalHeadOptions && verticalHeadOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Vertical Head</span>
                  <div className="tags_wrapper">
                    <MultiSelect
                      options={verticalHeadOptions}
                      value={filterVerticalHead || ""}
                      onChange={(e) => { handleFilterData("filterVerticalHead", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
            {statusOptions && statusOptions.length > 0 &&
              <Col md="4" lg="4" sm="6">
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Status</span>
                  <div className="tags_wrapper">
                    <Select
                      className="custom-default-dd-box"
                      classNamePrefix="select"
                      placeholder="Select"
                      options={statusOptions}
                      value={filterStatusWise || ""}
                      onChange={(e) => { handleFilterData("filterStatusWise", e) }}
                      filterOptions={filterOptions}
                    />
                  </div>
                </div>
              </Col>
            }
          </Row>
          <div className={style.buttons_wrapper}>
            <button
              type="button"
              data-test="submit"
              onClick={handleFilter}
              className={`btn btn-primary ${style.submit_button}`}
            >
              <strong>{btnName.SEARCH}</strong>
            </button>
            <button
              type="button"
              data-test="clear"
              onClick={handleClear}
              className={`btn btn-secondary ${style.clear_button}`}
            >
              <strong>{btnName.CLEAR}</strong>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
