import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import style from "./PreviewTemplate.module.scss";
import UploadFile from "../../Performance/CycleMemberProfile/QuestionAnswer/UploadFile";

export default function DropDownType(props) {
  const { isManagerView, question } = props;
  let options = question.options.map((obj) => {
    return {
      value: obj.option,
      label: obj.option,
    };
  });
  if (question.isOther) {
    options.push({ value: "Other", label: "Other" });
  }
  let responseText = "There would be employee’s comment over here";
  return (
    <div className={[style.question_list, "box-inner-pad", style.disabledClick].join(" ")}>
      <span className={style.question_sr_no}>Q{props.no + 1}</span>
      <div className={style.que_ans_wrapper}>
        <div className={style.question_name}>
          <h4>
            {question.questionName}
            {question.isRequired && <span className="text-danger">*</span>}
          </h4>
        </div>
        {question.descriptionFlag && (
          <p className={[style.answer_description, "mt-1"].join(" ")}>{question.description}</p>
        )}
        <div className={style.ans_wrapper}>
          <div className={style.txt_box_wrapper}>
            <Form.Group controlId="">
              <Select
                options={options}
                className="custom-default-dd-box"
                classNamePrefix="select"
                isSearchable={false}
                defaultValue={options[0]}
              />
              {question.isOther && !isManagerView && (
                <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm no_mar">
                  <Form.Control as="textarea" placeholder="Add a other answer"></Form.Control>
                </div>
              )}
              {isManagerView ? (
                <p className={[style.answer_description, "mt-3"].join(" ")}>{responseText}</p>
              ) : question.optionalCommentBox ? (
                <div className="textarea-wrapper mt-3">
                  <UploadFile questionId="QUESTION_ID" uploadFile={() => {}} />
                  <Form.Control
                    as="textarea"
                    maxLength="5000"
                    placeholder={`Add a new answer (${question.commentResponse})`}
                  />
                </div>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
