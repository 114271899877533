import React from "react";

function ClickableNameList({ data, handleEmployeeClick, displayAll }) {
  let length = data.length;
  function renderName() {
    if (length === 1) {
      return <span onClick={() => handleEmployeeClick(data[0], data[0].id)}>{data[0].empName}</span>;
    } else if (length === 2) {
      return (
        <>
          <span onClick={() => handleEmployeeClick(data[0], data[0].id)}>{data[0].empName}</span> and{" "}
          <span onClick={() => handleEmployeeClick(data[1], data[1].id)}>{data[1].empName}</span>
        </>
      );
    } else if (length > 2) {
      return (
        <>
          <span onClick={() => handleEmployeeClick(data[0], data[0].id)}>{data[0].empName}</span>,{" "}
          <span onClick={() => handleEmployeeClick(data[1], data[1].id)}>{data[1].empName}</span> and{" "}
          <span onClick={displayAll}>{length - 2 + " more"}</span>
        </>
      );
    }
  }
  return <>{renderName()}</>;
}

export default ClickableNameList;
