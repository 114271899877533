import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import { VIEW_CYCLE,PAST_CHECKIN, SURVEY_CYCLE_HISTORY, MANAGER_DATA, FILTER_SURVEY_CYCLE_HISTORY } from "../../util/endpoints";
import config from "../../util/config";
import moment from "moment";


export const FetchPastCheckIn = (surveyCycleId) => (dispatch) => {
  let params = {
    surveyCycleId: surveyCycleId
  };
  http
    .get(PAST_CHECKIN, params)
    .then((response) => response.data.data)
    .then((response) => {
      const pastCheckIn = { start: "", end: "" };
      pastCheckIn["start"] = moment(response.startDate).format(config.dates.shortMonthNameAndDate);
      pastCheckIn["end"] = moment(response.endDate).format(config.dates.shortMonthNameAndDate);
      const rating = response.questionsAnswer.filter((rating) => rating.type === "RATING");
      const moodoMeter = response.questionsAnswer.filter((rating) => rating.type === "MOODOMETER");
      // dispatch(setCurrentCheckIn(response, pastCheckIn, rating, moodoMeter));
      dispatch({
        type: actionTypes.SET_QUESTIONS,
        payload: { data: response, pastCheckIn, rating, moodoMeter },
      });
    })
    .catch((error) => {
      if (error.response)
        dispatch({
          type: actionTypes.MY_HISTORY_FAILURE,
          apiError: error.response,
        });
    });
};

export const HandleSurveyClick = (data) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
    surveyCycleId: data.surveyCycleId,
  };
  return (dispatch) => {
    dispatch({
      type: actionTypes.SURVEY_CYCLE_CLICKED,
      payload: {
        isLoading: true,
      },
    });
    setTimeout(() => {
      http
        .get(VIEW_CYCLE, {}, { params })
        .then((response) => response.data.data)
        .then((response) => {
          const currentCheckIn = { start: "", end: "" };
          currentCheckIn["start"] = moment(response.startDate).format(config.dates.shortMonthNameAndDate);
          currentCheckIn["end"] = moment(response.endDate).format(config.dates.shortMonthNameAndDate);
          const rating = response.questionsAnswer.filter((rating) => rating.type === "RATING");
          const moodoMeter = response.questionsAnswer.filter((rating) => rating.type === "MOODOMETER");

          dispatch({
            type: actionTypes.SET_MY_HISTORY,
            payload: {
              data: response,
              currentCheckIn,
              rating,
              moodoMeter,
              isLoading: false,
            },
          });
          if (response.status !== "PENDING_FROM_MEMBER") {
            dispatch({
              type: actionTypes.SET_QUESTIONS,
              payload: {
                data: response,
                currentCheckIn,
                rating,
                moodoMeter,
                isLoading: false,
                selectedRating: rating[0].answer,
                submitCyle: true,
              },
            });
          }
        })
        .catch((error) => {
          if (error.response)
            dispatch({
              type: actionTypes.MY_HISTORY_FAILURE,
              apiError: error.response,
            });
        });
    }, 500);
  };
};

export const GetMySurveyHistory = (currentUser, data, routeSurveyCycleId) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(SURVEY_CYCLE_HISTORY, {}, { params })
      .then((response) => response.data.data)
      .then(
        (response) => {
          if (response && response.length > 0) {
            const surveyData = response;
            const firstId = response[0].surveyCyclesId;
            dispatch({
              type: actionTypes.SET_MY_HISTORY,
              payload: { surveyData },
            });
            let params2 = null;
            if (routeSurveyCycleId) {
              params2 = {
                surveyCycleId: routeSurveyCycleId,
                ...params,
              };
            } else params2 = { surveyCycleId: firstId, ...params };
            // HandleSurveyClick(currentUser, { employeeId, surveyCycleId });
            // ???????????????
            http
              .get(VIEW_CYCLE, {}, { params: params2 })
              .then((response) => response.data.data)
              .then((response) => {
                const user = {
                  surveyCycle: response,
                  employeeInitial: response.employeeProfile.employeeInitial,
                  name: currentUser.displayName,
                  designation: currentUser.designation,
                  department: currentUser.department,
                  image: currentUser.displayPicture,
                  band: currentUser.band,
                  firstName: currentUser.fname,
                  totalExp: currentUser.totalExp,
                  expBeforeJoining: currentUser.expBeforeJoining,
                  isExp: currentUser.isExp,
                  due_date: response.endDate,
                  completed: 5, // TODO: need to replace this values once we get from Service Resposne
                  total: 10, // TODO: need to replace this values once we get from Service Resposne
                };
                const currentCheckIn = { start: "", end: "" };
                currentCheckIn["start"] = moment(response.startDate).format(config.dates.shortMonthNameAndDate);
                currentCheckIn["end"] = moment(response.endDate).format(config.dates.shortMonthNameAndDate);
                const rating = response.questionsAnswer.filter((rating) => rating.type === "RATING");
                const moodoMeter = response.questionsAnswer.filter((rating) => rating.type === "MOODOMETER");
                dispatch({
                  type: actionTypes.SET_MY_HISTORY,
                  payload: {
                    data: response,
                    user,
                    currentCheckIn,
                    rating,
                    moodoMeter,
                    isLoading: false,
                    selectedRating: rating[0].answer,
                  },
                });
                if (response.status !== "PENDING_FROM_MEMBER") {
                  dispatch({
                    type: actionTypes.SET_QUESTIONS,
                    payload: {
                      data: response,
                      user,
                      currentCheckIn,
                      rating,
                      moodoMeter,
                      isLoading: false,
                      selectedRating: rating[0].answer,
                      submitCyle: true,
                    },
                  });
                }
              })
              .catch((error) => {
                if (error.response)
                  dispatch({
                    type: actionTypes.MY_HISTORY_FAILURE,
                    apiError: error.response,
                  });
              });
          } else {
            dispatch({
              type: actionTypes.SET_MY_HISTORY,
              payload: { surveyData: [], isLoading: false },
            });
          }
        },
        (error) => {
          if (error.response)
            dispatch({
              type: actionTypes.MY_HISTORY_FAILURE,
              apiError: error.response,
            });
        }
      );
  };
};

export const GetFilterSurveyHistory = (data) => {
  let params = {
    filterByMonth: data.filterByMonth,
    employeeId: data.employeeId,
    employeeParentGroupId: data.parentGroupId,
    employeeSubGroupId: data.subGroupId,
  };
  return (dispatch) => {
    http
      .get(FILTER_SURVEY_CYCLE_HISTORY, {}, { params })
      .then((response) => response.data.data)
      .then((data) => {
        if (data?.length > 0) {
          const filterSurveyData = data.map((v) => {
            if (v.status === "PENDING_FROM_MEMBER") {
              return {
                ...v,
                status: "LAPSED",
              };
            } else return v;
          });
          let submittedCheckin = data.filter((v) => v.status === "PENDING_FOR_REVIEW").length;
          let pendingCheckin = data.filter((v) => v.status === "PENDING_FROM_MEMBER").length;
          let reviewedCheckin = data.filter((v) => v.status === "REVIEWED").length;
          let submittedPr =
            submittedCheckin || reviewedCheckin ? ((submittedCheckin + reviewedCheckin) / data.length) * 100 : 0;
          let pendingPr = pendingCheckin ? (pendingCheckin / data.length) * 100 : 0;
          let reviewed = reviewedCheckin ? (reviewedCheckin / data.length) * 100 : 0;
          let checkinStatusForEmployee = [
            {
              name: "Submitted",
              order: 1,
              value: submittedPr,
              count: submittedCheckin + reviewedCheckin,
            },
            {
              name: "Lapsed",
              order: 2,
              value: pendingPr,
              count: pendingCheckin,
            },
          ];
          let managerCheckinStatusForEmployee = [
            {
              name: "Reviewed by Manager",
              order: 1,
              value: reviewed,
              count: reviewedCheckin,
            },
            {
              name: "EMPTY",
              order: 2,
              value: 100 - reviewed,
              count: 100 - reviewedCheckin,
            },
          ];
          dispatch({
            type: actionTypes.SET_FILTER_HISTORY,
            payload: { filterSurveyData, checkinStatusForEmployee, managerCheckinStatusForEmployee },
          });
        } else {
          dispatch({
            type: actionTypes.SET_FILTER_HISTORY,
            payload: {},
          });
        }
      })
      .catch(() => {});
  };
};

export const GetMyHistoryReviewer = (data) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(MANAGER_DATA, {}, { params })
      .then((response) => response.data.data)
      .then(
        (data) => {
          if (data)
            dispatch({
              type: actionTypes.MY_HISTORY_REVIEWER,
              reviewer: data,
              isLoading: false,
            });
        },
        (error) => {
          if (error.response)
            dispatch({
              type: actionTypes.MY_HISTORY_FAILURE,
              apiError: error.response,
            });
        }
      );
  };
};
