import * as actionTypes from "../actions/actionTypes";
import { createBrowserHistory } from "history";
import storage from "../../util/storage";

const initialState = {
  currentCheckIn: null,
  apiError: false,
  isLoading: true,
  submitedDate: null,
  reviewedDate: null,
  updatedDate: null,
  status: null,
  questionsAnswer: null,
  validationErrors: [],
  helpToGrow: [],
  questionError: false,
  fileResponse: null,
  deleteFileResponse: null,
  reviewedBy: null,
  data: null,
  checkinExtendedHistory: null,
  plans: [],
  isHistoryLoading: true,
  previousPlans: [],
  objectives: [],
  employeeObjectives: [],
  objective: null,
  isObjectiveLoading: true,
  submitable: [],
  submitCycleResponse: null,
  helpToGrowCommentList: [],
  errorFlag: false,
  isCycleChange: false,
  isLoadingObjective: true,
  isCheckInSubmitLoading: false,
  objectiveReviewer: [],
  reviewer: {},
  planOnChangeError: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_CHECKIN: {
      const data = action.payload.data;
      if (data)
        return {
          ...state,
          data: data,
          isCheckInSubmitLoading: false,
          surveyCyclesId: data.surveyCyclesId,
          submitedDate: data.submitedDate,
          reviewedDate: data.reviewedDate,
          updatedDate: data.updatedDate,
          status: data.status,
          reviewedBy: data.reviewedBy,
          isSubmitted: data.status !== "PENDING_FROM_MEMBER",
          questionsAnswer: data.questionsAnswer,
          currentCheckIn: action.payload.currentCheckIn,
          selectedRating: action.payload.rating,
          moodoMeter: action.payload.moodoMeter,
          error: false,
          isLoading: false,
          // ...action.payload
        };
      else return { ...state, ...action.payload };
    }
    case actionTypes.FETCH_CURRENT_CHECKIN_FAILED:
      return {
        ...state,
        apiError: true,
        isLoading: false,
      };
    case actionTypes.UPDATE_CURRENT_CHECKIN:
      return {
        ...state,
        updatedDate: action.updateCheckin,
        error: false,
        isLoading: false,
      };
    case actionTypes.UPDATE_CURRENT_CHECKIN_FAILED:
      return {
        ...state,
        apiError: true,
        isLoading: false,
      };
    case actionTypes.SET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.validationErrors,
      };
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        fileResponse: { success: true, message: action.message },
      };
    case actionTypes.UPLOAD_FILE_ERROR:
      return {
        ...state,
        fileResponse: { success: false, message: action.message },
      };
    case actionTypes.FILE_DELETE_SUCCESS:
      return {
        ...state,
        deleteFileResponse: { success: true, message: action.message },
      };
    case actionTypes.FILE_DELETE_ERROR:
      return {
        ...state,
        deleteFileResponse: { success: false, message: action.message },
      };
    case actionTypes.CLEAR_FILE_RESPONSE:
      return { ...state, fileResponse: null, deleteFileResponse: null };
    case actionTypes.SET_SUBMIT:
      return {
        ...state,
        submitedDate: action.submitedDate,
      };
    case actionTypes.SET_STREAK:
      return {
        ...state,
        streak: action.streak,
      };
    case actionTypes.SET_REVIEWER:
      return { ...state, reviewer: action.reviewer };
    case actionTypes.SET_EXTEND_ADMIN_HISTORY:
      return {
        ...state,
        checkinExtendedHistory: action?.payload?.data,
        isHistoryLoading: false,
        ...action.payload,
      };
      case actionTypes.SET_EXTEND_ADMIN_HISTORY_LOADING:
        return {
          ...state,
          isHistoryLoading: true,
        };
    case actionTypes.SET_EXTEND_BY_ADMIN:
      return {
        ...state,
        extendedData: action?.payload?.data,
        ...action.payload,
      };
    case "CLEAR_REVIEWER":
      return { ...state, reviewer: {} };
    case actionTypes.HELP_TO_GROW_ANSWER: {
      let newHelpToGrow = [...state.helpToGrow];
      newHelpToGrow.push(action.helpToGrow);
      return {
        ...state,
        currentCheckIn: { ...state.currentCheckIn, helpToGrow: newHelpToGrow },
      };
    }
    case actionTypes.DELETE_HELP_TO_GROW: {
      let deletedHelpToGrow = [...state.helpToGrow];
      deletedHelpToGrow.splice(action.deleteHelpToGrow, 1);
      return {
        ...state,
        currentCheckIn: {
          ...state.currentCheckIn,
          helpToGrow: deletedHelpToGrow,
        },
      };
    }
    case actionTypes.LOGOUT: {
      const history = createBrowserHistory();
      storage.clear();
      history.go("/login");
      return state;
    }
    case actionTypes.CARRY_FORWARD: {
      const forwardedPlans = [...state.previousPlans];
      forwardedPlans.splice(action.index, 1);
      return {
        ...state,
        previousPlans: forwardedPlans,
        updatedDate: action.modifiedDate,
      };
    }
    case actionTypes.SET_PLAN: {
      let newPriorities = [...state.plans];
      newPriorities.push(action.priority);
      return {
        ...state,
        plans: newPriorities,
        updatedDate: action.modifiedDate,
      };
    }
    case actionTypes.SET_PLAN_ONCHANGE:
      return {
        ...state,
        planOnChangeError: action.planOnChangeError,
      };
    case actionTypes.EDIT_PLAN: {
      let tempPriorities = [...state.plans];
      tempPriorities[action.index] = action.priority;
      return {
        ...state,
        plans: tempPriorities,
        updatedDate: action.modifiedDate,
      };
    }
    case actionTypes.DELETE_PLAN: {
      let deletedPlans = [...state.plans];
      deletedPlans.splice(action.index, 1);
      return {
        ...state,
        plans: deletedPlans,
        updatedDate: action.modifiedDate,
      };
    }
    case actionTypes.DONE_PLAN:
      return {
        ...state,
        updatedDate: action.modifiedDate,
      };
    case actionTypes.SET_PREVIOUS_PLANS:
      return {
        ...state,
        previousPlans: action.data,
      };
    case actionTypes.FETCH_PLAN:
      return {
        ...state,
        plans: action.data,
        updatedDate: action.modifiedDate,
      };
    case actionTypes.GET_PLAN:
      return {
        ...state,
        plans: action.data,
      };
    case actionTypes.GET_OBJECTIVES:
      return {
        ...state,
        objectives: action.data,
        isLoadingObjective: action.isLoadingObjective,
      };
    case actionTypes.GET_EMPLOYEE_OBJECTIVES:
      return {
        ...state,
        employeeObjectives: action.data,
        isLoadingObjective: action.isLoadingObjective,
      };
    case actionTypes.GET_OBJECTIVE:
      return {
        ...state,
        objective: action.data,
        isObjectiveLoading: action.isLoading,
      };
    case "CLEAR_OBJECTIVE_DETAILS":
      return {
        ...state,
        isObjectiveLoading: true,
        isLoadingObjective: true,
        objective: null,
        employeeObjectives: [],
      };
    case actionTypes.SELECT_OBJECTIVE: {
      let objectives = [...state.objectives];
      let newObj = objectives.map((obj) => {
        if (action.data.userObjectiveId === obj.userObjectiveId) {
          obj.keyPoints.map((keypoint) => {
            if (action.data.keyPoints.keyId === keypoint.keyId) {
              keypoint.keyId = action.data.keyPoints.keyId;
              keypoint.description = action.data.keyPoints.description;
              keypoint.type = action.data.keyPoints.type;
              keypoint.start = action.data.keyPoints.start;
              keypoint.end = action.data.keyPoints.end;
              keypoint.progress = action.data.keyPoints.progress;
              keypoint.isCompleted = action.data.keyPoints.isCompleted;
              keypoint.weightage = action.data.keyPoints.weightage;
              keypoint.mark = action.data.keyPoints.mark;
            }
            return keypoint;
          });
        }
        return obj;
      });

      return {
        ...state,
        objectives: newObj,
      };
    }
    case actionTypes.IN_SUBMIT_VALIDATION:
      return {
        ...state,
        submitable: action.submitable,
      };
    case actionTypes.CHECKIN_SUBMIT_RESPONSE:
      return {
        ...state,
        submitCycleResponse: { success: action.success, message: action.message },
        isCheckInSubmitLoading: false,
      };
    case "CHECKIN_LOADER":
      return {
        ...state,
        isCheckInSubmitLoading: true,
      };
    case "REMOVE_CHECKIN_RESPONSE":
      return {
        ...state,
        submitCycleResponse: null,
      };
      case "IS_CYCLE_CHANGE":
      return {
        ...state,
        isCycleChange: action.payload,
      };
    case actionTypes.GET_ALL_GROW_COMMENT:
      return {
        ...state,
        helpToGrowCommentList: action.data,
        isLoading: action.isLoading,
      };
    case actionTypes.SET_ERRORS_FLAG:
      return {
        ...state,
        errorFlag: action.errorFlag,
      };
    case actionTypes.SET_OBJECTIVE_REVIEWER:
      return { ...state, objectiveReviewer: action.objectiveReviewer };
    default:
      return state;
  }
};

export default reducer;
