import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import style from "./CreateAndReplace.module.scss";
import * as actions from "store/actions/index";
import {
  CREATE_MANAGER_ASSESSMENT,
  DELETE_ASSESSMENT_FOR_EMPLOYEE,
  DELETE_MANAGER_ASSESSMENT,
  REPLACE_MANAGER,
} from "util/endpoints";
import { camelCase, errorToast, requiredFieldMissing, successToast } from "util/general";
import { CREATE_SINGLE_ASSESSMENT } from "util/endpoints";
import http from "util/http";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import SlideToast from "../../Toast/Toast";
import ReplaceManager from "./ReplaceManager";
import InlineDropdown from "shared/Dropdown/FlexDropdown/InlineDropdown";
import { errorMessage, status, successMessage } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

const filter = {
  REPLACE: "Replace Manager",
  CREATE: "Create Assessment",
  DELETE: "Delete Assessment",
};
export default function CreateAndReplace() {
  const dispatch = useDispatch();
  const loggedInUser = storage.getUser();
  const [viewFilter, setViewFilter] = useState("REPLACE");
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedEmployees, setSelectedEmployees] = useState();
  const [oldRPM, setOldRPM] = useState();
  const [oldRVM, setOldRVM] = useState();
  const [newRPM, setNewRPM] = useState();
  const [newRVM, setNewRVM] = useState();
  const [cycleList, setCycleList] = useState();
  const [successResponse, setSuccess] = useState(false);

  const { HREmployeeNameList, HRQuarterlyCycleList, currentRPMList, currentRVMList, managerList } = useSelector(
    (state) => state.hrDashboardReport
  );

  useEffect(() => {
    dispatch(actions.getHRQuarterlyCycleList());
    dispatch(actions.getNewManagersList());
  }, [dispatch]);

  let heading;
  if (viewFilter) {
    heading = filter[viewFilter];
  }

  const getManagers = (data) => {
    const employeeIds = {
      employeeId: data.value,
      parentGroupId: data.parentGroupId,
      subGroupId: data.subGroupId,
    };
    dispatch(actions.getCurrentManagersList(employeeIds, cycleList.value));
  };

  const handleEmployee = (e) => {
    setSelectedEmployee(e);
    getManagers(e);
  };

  const getEmployeeNameList = (value) => {
    if (value.length > 2) {
      dispatch(actions.getHREmployeeNameList(value));
    }
  };

  const onSelect = (list, item) => {
    setSelectedEmployees(list);
    viewFilter === "DELETE" && getManagers(item);
  };

  const onRemove = (list) => {
    setSelectedEmployees(list);
  };

  const submitReplaceManager = () => {
    if (oldRPM && !newRPM) {
      errorToast(errorMessage.SELECT_NEW_RPM);
      return;
    } else if (oldRVM && !newRVM) {
      errorToast(errorMessage.SELECT_NEW_RVM);
      return;
    } else if (!(oldRVM || oldRPM)) {
      errorToast(errorMessage.SELECT_MANAGER);
      return;
    } else {
      setSuccess(true);
      let body = {
        employeeId: selectedEmployee?.value,
        parentGroupId: selectedEmployee?.parentGroupId,
        subGroupId: selectedEmployee?.subGroupId,
        cycleId: cycleList?.value,
        currentRPM: oldRPM
          ? [
              {
                employeeId: oldRPM.value,
                parentGroupId: oldRPM.parentGroupId,
                subGroupId: oldRPM.subGroupId,
              },
            ]
          : undefined,
        currentRVM: oldRVM
          ? [
              {
                employeeId: oldRVM.value,
                parentGroupId: oldRVM.parentGroupId,
                subGroupId: oldRVM.subGroupId,
              },
            ]
          : undefined,
        newRPM: newRPM
          ? {
              ...newRPM,
              value: undefined,
              label: undefined,
            }
          : undefined,
        newRVM: newRVM
          ? {
              ...newRVM,
              value: undefined,
              label: undefined,
            }
          : undefined,
      };

      http
        .put(REPLACE_MANAGER, null, body)
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            setSuccess(false);
            setSelectedEmployee();
            setCycleList("");
            setOldRPM();
            setOldRVM();
            setNewRPM();
            setNewRVM();
            toast.success(<SlideToast title="Awesome!" message="Manger replaced successfully" error={false} />);
          } else {
            setSuccess(false);
          }
        })
        .catch(() => {
          setSuccess(false);
          errorToast(errorMessage.REPLACE_MANAGER);
        });
    }
  };

  const createDeleteAssessment = () => {
    setSuccess(true);
    if (viewFilter === "CREATE") {
      if (cycleList && selectedEmployees && selectedEmployees.length > 0) {
        let createBody = {
          employeeIds: selectedEmployees.map((v) => {
            return {
              employeeId: v.value,
              parentGroupId: v.parentGroupId,
              subGroupId: v.subGroupId,
            };
          }),
          cycleId: cycleList.value,
          createdBy: {
            employeeId: loggedInUser?.employeeId,
            empName: loggedInUser?.displayName,
            displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
            designation: loggedInUser?.designation,
            department: loggedInUser?.department,
            parentGroupId: loggedInUser?.parentGroupId,
            subGroupId: loggedInUser?.subGroupId,
          },
        };
        let url = newRPM || newRVM ? CREATE_MANAGER_ASSESSMENT : CREATE_SINGLE_ASSESSMENT;
        let body =
          newRPM || newRVM
            ? {
                ...createBody,
                rpm: newRPM
                  ? {
                      employeeId: newRPM.value,
                      parentGroupId: newRPM.parentGroupId,
                      subGroupId: newRPM.subGroupId,
                    }
                  : undefined,
                rvm: newRVM
                  ? {
                      employeeId: newRVM.value,
                      parentGroupId: newRVM.parentGroupId,
                      subGroupId: newRVM.subGroupId,
                    }
                  : undefined,
              }
            : createBody;

        http
          .post(url, {}, body)
          .then((response) => response.data)
          .then((response) => {
            if (response.status === status.SUCCESS) {
              setSuccess(false);
              setSelectedEmployees();
              setCycleList("");
              setNewRVM();
              setNewRPM();
              successToast(successMessage.ASSESSMENT_CREATED);
            } else {
              setSuccess(false);
              errorToast(response.message.message);
            }
          })
          .catch(() => {
            setSuccess(false);
            errorToast(errorMessage.CREATE_ASSESSMENT);
          });
      } else {
        requiredFieldMissing();
      }
    } else if (viewFilter === "DELETE") {
      if (cycleList && selectedEmployees && selectedEmployees.length > 0) {
        let employeeIdsData = selectedEmployees.map((v) => {
          return {
            employeeId: v.value,
            parentGroupId: v.parentGroupId,
            subGroupId: v.subGroupId,
          };
        });
        let deleteBody = {
          employeeId: employeeIdsData,
        };
        let url = oldRPM || oldRVM ? DELETE_MANAGER_ASSESSMENT : DELETE_ASSESSMENT_FOR_EMPLOYEE;
        let body =
          oldRPM || oldRVM
            ? {
                employeeIds: employeeIdsData,
                cycleId: cycleList.value,
                rpm: oldRPM
                  ? {
                      employeeId: oldRPM.value,
                      parentGroupId: oldRPM.parentGroupId,
                      subGroupId: oldRPM.subGroupId,
                    }
                  : undefined,
                rvm: oldRVM
                  ? {
                      employeeId: oldRVM.value,
                      parentGroupId: oldRVM.parentGroupId,
                      subGroupId: oldRVM.subGroupId,
                    }
                  : undefined,
              }
            : deleteBody;
        http
          .delete(url, { data: body }, {}, { cycleId: cycleList.value })
          .then((response) => response.data)
          .then((response) => {
            if (response.status === status.SUCCESS) {
              setSuccess(false);
              setSelectedEmployees();
              setOldRVM();
              setOldRPM();
              setCycleList("");
              successToast(successMessage.ASSESSMENT_DELETED);
            } else {
              setSuccess(false);
              errorToast(response.message.message);
            }
          })
          .catch(() => {
            setSuccess(false);
            errorToast(errorMessage.DELETE_ASSESSMENT);
          });
      } else {
        requiredFieldMissing();
      }
    }
  };

  const handleView = (e) => {
    setSelectedEmployee();
    setSelectedEmployees();
    setCycleList("");
    setOldRPM();
    setOldRVM();
    setNewRPM();
    setNewRVM();
    setViewFilter(e.target.value);
  };

  return (
    <div className={style.cycle_per_wrapper}>
      <SubNavigation type="Performance" />
      {successResponse && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">
              Performance<span>{heading}</span>
            </span>
          </Col>
          <Col md={12}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <div className={style.box_heading_wrapper}>
                    <div className={style.box_heading_left}>
                      <h2>{heading}</h2>
                    </div>
                    <div className={style.box_heading_right}>
                      <div className="tab_check_in_status">
                        <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                          <input
                            id="replace"
                            type="radio"
                            name="status-check"
                            value="REPLACE"
                            onChange={handleView}
                            defaultChecked
                          />
                          <label htmlFor="replace">{filter.REPLACE}</label>
                        </div>
                        <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                          <input id="create" type="radio" name="status-check" value="CREATE" onChange={handleView} />
                          <label htmlFor="create">{filter.CREATE}</label>
                        </div>
                        <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                          <input id="delete" type="radio" name="status-check" value="DELETE" onChange={handleView} />
                          <label htmlFor="delete">{filter.DELETE}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Form name="Cycle Form">
                <div className={[style.custom_form_ctrl_wrapper, "box-inner-pad"].join(" ")}>
                  <div className="cycle-list-wrapper d-flex dropdown-multiselect">
                    <Form.Group controlId="">
                      <Form.Label>
                        Cycle List<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="tags_wrapper">
                        <Select
                          options={HRQuarterlyCycleList}
                          className="custom-default-dd-box"
                          classNamePrefix="select"
                          isSearchable={false}
                          value={cycleList}
                          onChange={(e) => setCycleList(e)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  {cycleList && (
                    <div className="radio-wrapper d-flex dropdown-multiselect">
                      <Form.Group controlId="">
                        <Form.Label>
                          Select Employee{viewFilter === "CREATE" && "s"}
                          <span className="text-danger">*</span>
                          <div className={style.textNote}>To get list of employees, enter minimum three alphabets</div>
                        </Form.Label>
                        <div className="tags_wrapper">
                          {viewFilter === "CREATE" || viewFilter === "DELETE" ? (
                            <Multiselect
                              id={"multi-select-search-dd-box"}
                              options={HREmployeeNameList} // Options to display in the dropdown
                              selectedValues={selectedEmployees} // Preselected value to persist in dropdown
                              onSelect={(list, item) => onSelect(list, item)} // Function will trigger on select event
                              onRemove={(list, item) => onRemove(list, item)} // Function will trigger on remove event
                              displayValue="label" // Property name to display in the dropdown options
                              onSearch={(e) => getEmployeeNameList(e)}
                              closeOnSelect={false}
                              showCheckbox={true}
                              placeholder="Search"
                              keepSearchTerm={true}
                              loading={HREmployeeNameList?.length > 0 ? false : true}
                            />
                          ) : (
                            <Select
                              className="custom-default-dd-box"
                              classNamePrefix="select"
                              isSearchable={true}
                              options={HREmployeeNameList}
                              value={selectedEmployee}
                              onChange={handleEmployee}
                              onInputChange={(e) => getEmployeeNameList(e)}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                  {cycleList &&
                    ((viewFilter === "CREATE" && selectedEmployees && selectedEmployees.length > 0) ||
                      (viewFilter === "DELETE" && selectedEmployees && selectedEmployees.length === 1)) && (
                      <InlineDropdown
                        labelLeft="Select Reporting Manager"
                        labelRight="Select Reviewing Manager"
                        optionsLeft={viewFilter === "CREATE" ? managerList : currentRPMList}
                        valueLeft={viewFilter === "CREATE" ? newRPM : oldRPM}
                        handleChangeLeft={viewFilter === "CREATE" ? setNewRPM : setOldRPM}
                        optionsRight={viewFilter === "CREATE" ? managerList : currentRVMList}
                        valueRight={viewFilter === "CREATE" ? newRVM : oldRVM}
                        handleChangeRight={viewFilter === "CREATE" ? setNewRVM : setOldRVM}
                      />
                    )}
                  {viewFilter === "REPLACE" && cycleList && selectedEmployee && (
                    <ReplaceManager
                      oldRPM={oldRPM}
                      setOldRPM={setOldRPM}
                      HREmployeeNameList={HREmployeeNameList}
                      oldRVM={oldRVM}
                      setOldRVM={setOldRVM}
                      newRPM={newRPM}
                      newRVM={newRVM}
                      currentRPMList={currentRPMList}
                      currentRVMList={currentRVMList}
                      setNewRPM={setNewRPM}
                      setNewRVM={setNewRVM}
                      managerList={managerList}
                    />
                  )}
                  <button
                    type="button"
                    disabled={
                      viewFilter === "REPLACE"
                        ? selectedEmployee
                          ? false
                          : true
                        : selectedEmployees && selectedEmployees.length > 0
                        ? false
                        : true
                    }
                    onClick={viewFilter === "REPLACE" ? submitReplaceManager : createDeleteAssessment}
                    className={["btn-round save_box_btn btn btn-primary", style.cycle_btn_submit].join(" ")}
                  >
                    <strong>{camelCase(viewFilter)}</strong>
                  </button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
