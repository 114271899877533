import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import style from "./CampaignList.module.scss";
import * as actions from "store/actions/index";
import { camelCase } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "../../NodataFound/NoDataFound";
import { noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import CustomDropdown from "shared/Dropdown/CustomDropdown";
import { dateFormat2 } from "util/dateUtils";
import { moduleView } from "util/utils";
import config from "util/config";
import PaginationComponent from "shared/Pagination/PaginationComponent";

function CapabilityTemplateList() {
  const dispatch = useDispatch();
  const { capabilityTemplateList, capabilityListLoader, capabilityListForAdmin } = useSelector(
    (state) => state.campaign
  );
  const isCampaignAdmin = moduleView(config.hrModule.CAMPAIGN_DETAILS)?.includes(config.hrModule.CAMPAIGN_DETAILS);

  useEffect(() => {
    dispatch(actions.getCapabilityTemplateList());
    dispatch(actions.getCapabilityListForAdmin());
    dispatch({
      type: "CLEAR_CAMPAIGN_VIEW_FILTER_DATA",
    });
    dispatch({ type: "RESET_QUESTION_MAP" });
  }, [dispatch]);

  const editCampaignPath = (id) => {
    if (isCampaignAdmin) return `/hr/campaign-details/hr-edit?id=${id}`;
    else return `/wmg/capability-development/campaign-list/edit?id=${id}`;
  };

  const cloneCampaignPath = (id) => {
    if (isCampaignAdmin) return `/hr/campaign-details/hr-clone?id=${id}`;
    else return `/wmg/capability-development/campaign-list/clone?id=${id}`;
  };

  const viewCampaignDetailsPath = (id) => {
    if (isCampaignAdmin) return `/hr/campaign-details/admin/view/${id}`;
    else return `/wmg/capability-development/campaign-list/view/${id}`;
  };

  const handleSetParPage = (page) => {
    dispatch({
      type: "SET_CAMPAIGN_PAGINATION_PAGE_SIZE",
      payload: { name: "capabilityTemplateList", pageSize: page },
    });
  };
  const handleSetPage = (page) => {
    dispatch({
      type: "SET_CAMPAIGN_PAGINATION_CURRENT_PAGE",
      payload: { name: "capabilityTemplateList", currentPage: page },
    });
  };

  const handleSetParPage2 = (page) => {
    dispatch({
      type: "SET_CAMPAIGN_PAGINATION_PAGE_SIZE",
      payload: { name: "capabilityListForAdmin", pageSize: page },
    });
  };

  const handleSetPage2 = (page) => {
    dispatch({
      type: "SET_CAMPAIGN_PAGINATION_CURRENT_PAGE",
      payload: { name: "capabilityListForAdmin", currentPage: page },
    });
  };

  if (capabilityListLoader) {
    return <Spinner />;
  }

  return (
    <div className={style.campaign_list_wrapper}>
      <SubNavigation type="WMG" />
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">Capability Development</span>
          </Col>
          <Col className="col-12">
            <div className="box pb-0 mb-0">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>My Skills Proficiency</h2>
                  <h3>Select following list to complete the process</h3>
                </div>
              </div>
            </div>
            <div className="outer-table-wrapper mb-5">
              <div className="box-light-gradient"></div>
              <div className={[style.custom_table, "table-theme-1"].join(" ")}>
                {capabilityTemplateList?.filterList && capabilityTemplateList?.filterList.length > 0 ? (
                  <Table hover responsive className={style.tab_table_responsive}>
                    <thead>
                      <tr>
                        <th>Skill</th>
                        <th className={style.date}>Starts On</th>
                        <th className={style.date}>Ends On</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {capabilityTemplateList?.filterList.map((list) => {
                        let status = list.status;
                        return (
                          <tr key={list.campaignId}>
                            <td className={style.campaignName}>
                              <Link to={`/wmg/capability-development/${list.campaignId}`}>
                                <span>{list.campaignName}</span>
                              </Link>
                            </td>
                            <td className={style.date}>
                              <span>{dateFormat2(list.startDate) ?? "-"}</span>
                            </td>
                            <td className={style.date}>
                              <span>{dateFormat2(list.endDate) ?? "-"}</span>
                            </td>
                            <td>
                              <span className={["span-status", status?.toLowerCase()].join(" ")}>{status}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap120"></div>
                  </div>
                )}
              </div>
              {capabilityTemplateList?.totalItem > 5 && (
                <PaginationComponent
                  totalItem={capabilityTemplateList?.totalItem}
                  pageSize={capabilityTemplateList?.pageSize}
                  setPerPage={handleSetParPage}
                  currentPage={capabilityTemplateList?.currentPage}
                  setPage={handleSetPage}
                />
              )}
            </div>
            {capabilityListForAdmin?.filterList && capabilityListForAdmin?.filterList.length > 0 && (
              <>
                <div className="box pb-0 mb-0">
                  <div className="box-heading-wrapper">
                    <div className="box-heading">
                      <h2>Capability Development List</h2>
                      <h3>Select following list to complete the process</h3>
                    </div>
                  </div>
                </div>
                <div className="outer-table-wrapper">
                  <div className="box-light-gradient"></div>
                  <div className={[style.custom_table, style.hr_table, "table-theme-1"].join(" ")}>
                    {capabilityListForAdmin?.filterList && capabilityListForAdmin?.filterList.length > 0 ? (
                      <Table hover responsive className={style.tab_table_responsive}>
                        <thead>
                          <tr>
                            <th>Skill</th>
                            <th>Starts On</th>
                            <th>Ends On</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {capabilityListForAdmin?.filterList.map((list) => (
                            <tr key={list.campaignId}>
                              <td className={style.campaignName}>
                                <span>{list.campaignName}</span>
                              </td>
                              <td className={style.date}>
                                <span>{dateFormat2(list.startDate) ?? "-"}</span>
                              </td>
                              <td className={style.date}>
                                <span>{dateFormat2(list.endDate) ?? "-"}</span>
                              </td>
                              <td>
                                <span className={["span-status", list.status?.toLowerCase()].join(" ")}>
                                  {camelCase(list.status)}
                                </span>
                              </td>
                              <td className="">
                                <CustomDropdown
                                  editPath={() => editCampaignPath(list.campaignId)}
                                  clonePath={() => cloneCampaignPath(list.campaignId)}
                                  viewPath={() => viewCampaignDetailsPath(list.campaignId)}
                                  editText={list.status === "Completed" ? null : "Edit Capability"}
                                  viewText={list.status !== "DRAFTED" ? "View Details" : null}
                                  deleteText={null}
                                  cloneText={list.status !== "DRAFTED" ? "Clone Capability" : null}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <div>
                        <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                        <div className="gap120"></div>
                      </div>
                    )}
                  </div>
                  {capabilityListForAdmin?.totalItem > 5 && (
                    <PaginationComponent
                      totalItem={capabilityListForAdmin?.totalItem}
                      pageSize={capabilityListForAdmin?.pageSize}
                      setPerPage={handleSetParPage2}
                      currentPage={capabilityListForAdmin?.currentPage}
                      setPage={handleSetPage2}
                    />
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CapabilityTemplateList;
