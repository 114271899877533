import * as actionTypes from "../actions/actionTypes";

const initialState = {
  roleData: null,
  employeesList: [],
  bandOption: [],
  locationOption: [],
  projectOption: [],
  departmentOption: [],
  memberTypeOption: [],
  designationOption: [],
  indiaDDOption: [],
  modulesOption: [],
  rightsOption: [],
  roleRightsList: [],
  roleOptions: [],
  apiResponse: null,
  isLoading: true,
  userList: [],
  employeeCount: null,
  notificationFilters: {},
  clientList: [],
  botNotificationList: [],
  notificationEmployeeList: [],
  previewPopup: false,
  organizationList: [],
  moduleList: [],
  clientAdminModuleList: [],
  subGroupClient: [],
  surveyConfigData: {
    surveyCycleTemplateList: [],
    surveyConfigList: [],
    locationList: [],
    locations: [],
    timeFrame: null,
    subGroupId: null,
    questionTemplateId: null,
    questionTemplateName: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ROLES_DATA_IN_ADMIN":
      return { ...state, roleData: { ...state.roleData, ...action.payload }, ...action.loading };
    case "CLEAR_ROLES_DATA_IN_ADMIN":
      return {
        ...state,
        roleData: null,
        bandOption: [],
        locationOption: [],
        indiaDDOption: [],
        departmentOption: [],
        designationOption: [],
        modulesOption: [],
        rightsOption: [],
      };
    case "CLEAR_PREDEFINED_OBJECTIVE_DATA_IN_ADMIN":
      return {
        ...state,
        predefinedObjectiveAdminViewList: [],
      };
    case actionTypes.SET_EMPLOYEE_NAME_FOR_ADMIN:
      return {
        ...state,
        employeesList: action.data,
      };
    case actionTypes.SET_SUBGROUP_NAME:
      return {
        ...state,
        subGroupClient: action.data,
        isLoading: false,
      };
    case actionTypes.SET_DROPDOWN_DATA_FOR_ROLE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_ROLE_RIGHTS_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case "ROLE_ASSIGN_RESPONSE":
      return {
        ...state,
        ...action.payload,
      };
    case "CLEAR_ROLE_ASSIGN_RESPONSE":
      return {
        ...state,
        isLoading: false,
        apiResponse: null,
      };
    case "SET_ROLE_ASSIGN_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SET_USER_ROLES_RIGHTS_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_NOTIFICATION_FILTER:
      return {
        ...state,
        notificationFilters: {
          ...state.notificationFilters,
          ...action.payload,
        },
      };
    case actionTypes.SET_BOT_NOTIFICATION_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_SUPER_ADMIN_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_SURVEY_CONFIG_DATA:
      return {
        ...state,
        surveyConfigData: {
          ...state.surveyConfigData,
          ...action.payload,
        },
        isLoading: false,
      };
    case actionTypes.REMOVE_SURVEY_CONFIG_DATA:
      return {
        ...state,
        surveyConfigData: {
          ...state.surveyConfigData,
          locations: [],
          timeFrame: null,
          subGroupId: null,
          questionTemplateId: null,
          questionTemplateName: null,
        },
        isLoading: false,
      }; 
    case "REMOVE_NOTIFICATION_PREVIEW_DATA":
      return {
        ...state,
        notificationEmployeeList: [],
        previewPopup: false,
      };
    case actionTypes.CLEAR_NOTIFICATION_FILTER:
      return {
        ...state,
        notificationFilters: {},
        employeeCount: null,
      };
    default:
      return state;
  }
};

export default reducer;
