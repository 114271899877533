import React from "react";

function BoxHeading({ heading, description }) {
  return (
    <div className="box pb-0">
      <div className="box-heading-wrapper border-bottom-0">
        <div className="box-heading">
          <h2>{heading}</h2>
          <h3>{description}</h3>
        </div>
      </div>
    </div>
  );
}

export default BoxHeading;

BoxHeading.defaultProps = {
  heading: "",
  description: "",
};
