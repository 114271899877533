import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PieChart, Pie, ResponsiveContainer, Cell, Label, BarChart, XAxis, YAxis, Bar, Tooltip } from "recharts";
import DatePicker from "react-datepicker";
import _ from "lodash";
import style from "../Dashboard.module.scss";
import * as actions from "store/actions/index";
import { btnName, locationOrder } from "const/constValue";
import { moduleRights, pathValidate } from "util/utils";
import { dateFormat3 } from "util/dateUtils";
import config from "util/config";
import { errorToast, successToast } from "util/general";
import routePath from "const/routePath";

export default function EmployeeObjectiveCharts({ filterValue }) {
  const [pieChartData, setPieChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const history = useHistory();
  const dispatch = useDispatch();
  const [employeeObjectiveChartData, setEmployeeObjectiveChartData] = useState([]);
  const { employeeObjectiveData, employeeObjectiveDataExport } = useSelector((state) => state.hrDashboardReport);

  useEffect(() => {
    if (employeeObjectiveDataExport?.success) successToast(employeeObjectiveDataExport.message);
    if (employeeObjectiveDataExport?.error) errorToast(employeeObjectiveDataExport.message);
  }, [employeeObjectiveDataExport]);

  useEffect(() => {
    if (employeeObjectiveData) {
      setEmployeeObjectiveChartData(employeeObjectiveData.employeeObjectiveChart);
    } else setEmployeeObjectiveChartData([]);
  }, [employeeObjectiveData]);

  useEffect(() => {
    let dated = new Date();
    let quarter = Math.floor(dated.getMonth() / 3);
    let firstDate = new Date(dated.getFullYear(), quarter * 3, 1);
    let lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    setStartDate(firstDate);
    setEndDate(lastDate);
    let param = {
      startDate: formatDate(firstDate),
      endDate: formatDate(lastDate),
    };
    const getEmployeeObjectiveData = () => {
      dispatch(actions.getEmployeeObjectiveChartData(param));
    };
    getEmployeeObjectiveData();
  }, [dispatch]);

  useEffect(() => {
    const PieChartsArray = [];
    const BarChartArray = [];

    if (employeeObjectiveChartData && employeeObjectiveChartData.pieChartDataList) {
      employeeObjectiveChartData.pieChartDataList.map((checking) => {
        PieChartsArray.push({
          name: checking.status,
          value: checking.count,
          order: checking.status,
        });
        return checking;
      });
      setPieChartData(_.sortBy(PieChartsArray, ["order"], ["asc"]));

      employeeObjectiveChartData.barChartList.map((barChecking) => {
        BarChartArray.push({
          name: barChecking.location,
          Assigned: barChecking.assigned,
          "Not-Assigned": barChecking.notassigned,
          order: locationOrder[barChecking.location],
        });
        return barChecking;
      });
      setBarChartData(_.sortBy(BarChartArray, ["order"], ["asc"]));
    } else {
      setPieChartData([]);
      setBarChartData([]);
    }
  }, [employeeObjectiveChartData]);

  const COLORS = {
    ASSIGNED: "#7033FF",
    "NOT-ASSIGNED": "#FD8C61",
  };

  const [currentVal, setCurrentVal] = useState(null);

  function CustomLabel({ viewBox }) {
    const { cx, cy } = viewBox;
    let totalValue = 0;
    pieChartData.forEach((dt) => {
      totalValue += dt.value;
    });
    const total = `${totalValue}`;
    return (
      <>
        <text
          x={cx}
          y={cy - 12}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            Total
          </tspan>
        </text>
        <text
          x={cx}
          y={cy + 15}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            {total}
          </tspan>
        </text>
      </>
    );
  }

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    let countStatusLocactionwise;
    if (payload) {
      payload.forEach((data) => {
        if (currentVal && currentVal.toLowerCase() === data.dataKey.toLowerCase()) {
          Object.keys(data.payload).map((key) => {
            if (key !== "name" && key !== "order") {
              totalVal += data.payload[key];
            }
            return key;
          });
          countStatusLocactionwise = data.value;
          pr = (data.value / totalVal) * 100;
        }
      });
    }

    if (active) {
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper">
              <p className="recharts-tooltip-label">{currentVal}</p>
              {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
              <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${countStatusLocactionwise}`}</span>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomTooltipForPieChart = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    if (active) {
      pieChartData.forEach((data) => {
        totalVal += data.value;
      });
      pr = (payload[0]["value"] / totalVal) * 100;
      return (
        <>
          <div className="recharts-tooltip-custom-wrapper">
            <p className="recharts-tooltip-label">{payload[0]["name"]}</p>
            {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
            <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${
              payload[0]["value"]
            }`}</span>
          </div>
        </>
      );
    }

    return null;
  };
  /** end bar chart*/

  const handleExport = () => {
    successToast("Your files would be downloaded in background");
    let param = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(actions.getEmployeeObjectiveExportReport(param, filterValue));
  };

  const handleApply = () => {
    let param = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(actions.getEmployeeObjectiveChartData(param));
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [month, day, year].join("-");
  }
  const handlePieChartClick = (entry) => {
    //if User have HR Module and Employees Objectives sub module right then user can redirect otherwise isn't clickable
    if (pathValidate(config.roles.HR_ROLE, routePath.HR_EMPLOYEE_OBJECTIVE)) {
      history.push({
        pathname: routePath.HR_EMPLOYEE_OBJECTIVE,
        search: `startDate=${dateFormat3(startDate)}&endDate=${dateFormat3(endDate)}&subGroup=${
          filterValue?.filterSubGroups
        }`,
        state: entry.name === "ASSIGNED" ? "true" : "false",
      });
    }
  };

  return (
    <div className={["box", style.graph_wrapper, style.date_range_wrapper].join(" ")}>
      <div className="box-heading-wrapper border-bottom">
        <div className="box-heading">
          <h2>Employee Objective Status</h2>
        </div>
        <div className="date_picker_time_wrapper p-0">
          <div className="cl_t_wrapper">
            <div className="calendar_wrapper form-group date_range_calendar_wrapper">
              <div className="d-flex">
                <div className="calendar_wrapper form-group">
                  <DatePicker
                    className="form-control"
                    placeholderText="Start Date"
                    selected={startDate}
                    maxDate={endDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MMMM d, yyyy"
                  />
                </div>
                <div className="calendar_wrapper form-group">
                  <DatePicker
                    className="form-control"
                    placeholderText="End Date"
                    minDate={startDate}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="MMMM d, yyyy"
                  />
                </div>
              </div>
              <button type="button" className="btn btn-secondary date-range-apply-btn mr-3" onClick={handleApply}>
                <strong>{btnName.APPLY}</strong>
              </button>
              {moduleRights(config.hrModule.ANALYSIS, config.subModule.MODULE_ANALYSIS)?.includes("EXPORT") && (
                <button
                  type="button"
                  className="btn btn-primary date-range-apply-btn"
                  onClick={handleExport}
                  disabled={!pieChartData}
                >
                  <strong>{btnName.EXPORT}</strong>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="box-inner-pad pb-0">
        {pieChartData && pieChartData.length > 0 ? (
          <>
            <div className="d-flex">
              <div className="graph-item width50">
                <h5>Aggregate Objective Report</h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="45%"
                        cy="50%"
                        cursor="pointer"
                        innerRadius={100}
                        outerRadius={125}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                      >
                        <Label width={30} position="center" content={<CustomLabel value1={pieChartData} />}></Label>
                        {pieChartData.map((entry, index) => {
                          return (
                            <Cell key={index} fill={COLORS[entry["name"]]} onClick={() => handlePieChartClick(entry)} />
                          );
                        })}
                      </Pie>
                      <Tooltip content={<CustomTooltipForPieChart />} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="graph-item width70">
                <h5 className={["pl-4", style.right_text_title].join(" ")}>Location Wise Objective report</h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={barChartData}
                      margin={{ top: 70, right: 0, left: 0, bottom: 0 }}
                      barSize={25}
                      barGap={1}
                    >
                      <XAxis dataKey="name" axisLine={false} />
                      <YAxis axisLine={false} />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar
                        dataKey="Assigned"
                        fill={COLORS["ASSIGNED"]}
                        onMouseOver={() => setCurrentVal("ASSIGNED")}
                        onMouseLeave={() => setCurrentVal(null)}
                      ></Bar>
                      <Bar
                        dataKey="Not-Assigned"
                        stackId="a"
                        fill={COLORS["NOT-ASSIGNED"]}
                        onMouseOver={() => {
                          setCurrentVal("NOT-ASSIGNED");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      ></Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className={style.legent_wrapper}>
              <ul className={style.lenegd_list}>
                {pieChartData.map((entry, index) => {
                  return (
                    <li className={style.legend_item} key={index}>
                      <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                      {entry["name"]}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        ) : (
          <div className="no-record-found-wrpr mt-3">No Data Available</div>
        )}
      </div>
    </div>
  );
}
