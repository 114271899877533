import * as actionTypes from "../actions/actionTypes";

const initialState = {
  rating: null,
  questionsAnswer: null,
  questionTemplate: null,
  existWinChallenge: null,
  user: null,
  employee: null,
  reviewer: null,
  surveyCycle: null,
  surveyCycleId: null,
  isSubmited: null,
  status: null,
  currentCheckIn: null,
  checkInDetails: null,
  helpToGrow: null,
  reviewedBy: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_QUESTIONS:
      if (action.payload.data) {
        let data = action.payload.data;
        return {
          ...state,
          surveyCycle: data,
          surveyCycleId: data.surveyCyclesId,
          status: data.status,
          isSubmited: data.status === ("PENDING_FOR_REVIEW" || "REVIEWED") && true,
          isReviewed: data.status === ("PENDING_FOR_REVIEW" && "REVIEWED") && true,
          submitedDate: data.submitedDate,
          reviewedDate: data.reviewedDate,
          updatedDate: data.updatedDate,
          questionsAnswer: data.questionsAnswer || null,
          questionTemplate: data.questionTemplate || null,
          helpToGrow: data.helpToGrow,
          reviewedBy: data.reviewedBy,
          ...action.payload,
        };
      } else return { ...state, ...action.payload };
    
    case actionTypes.QUESTIONS_ADD_HELP_TO_GROW: {
      let newHelpToGrow = state.helpToGrow ? [...state.helpToGrow] : [];
      newHelpToGrow.push(action.data);
      return { ...state, helpToGrow: newHelpToGrow };
    }
    case actionTypes.QUESTIONS_DELETE_HELP_TO_GROW: {
      let deletedHelpToGrow = [...state.helpToGrow];
      deletedHelpToGrow.splice(action.data, 1);
      return { ...state, helpToGrow: deletedHelpToGrow };
    }
    case "REMOVE_SURVEY_CYCLE_ID":
      return {
        ...state,
        surveyCycleId: null,
      };
    default:
      return state;
  }
};

export default reducer;
