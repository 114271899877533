import React from "react";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { IC_CLOSE_ROUNDED, IC_LOCK_PRIVACY_1 } from "const/imgCost";

const Comment = ({
  selectedColor,
  image,
  empName,
  commentedOn,
  comment,
  isPrivate,
  deletable,
  deleteComment,
  privateMsg,
}) => {
  return (
    <div className="view_comment_box_row">
      <div className="user_pics" style={{ backgroundColor: selectedColor }}>
        {image?.length > 2 ? <img alt="DP" src={image} /> : image}
      </div>
      <div className="user_comment_details">
        <div className="user_name">
          {empName}
          {commentedOn && <span className="comment_time">{moment(commentedOn).fromNow()}</span>}
        </div>
        <div className="user_comment text-break">
          <pre>{comment}</pre>
        </div>
        {isPrivate && (
          <div className="private-comment-selected fill-svg-icon">
            <ReactSVG src={`${IC_LOCK_PRIVACY_1}`} alt="lock" />
            <span>{privateMsg}</span>
          </div>
        )}
        {deletable && (
          <div className="remove_user_comment" onClick={deleteComment}>
            <img className="svg" src={`${IC_CLOSE_ROUNDED}`} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
