import { IC_FAIL, IC_MINUS, IC_PASS, IC_PLUS } from "const/imgCost";
import React from "react";
import { ReactSVG } from "react-svg";
import { getColorByIndex } from "util/color";
import style from "./CampaignQuizEmployeeTable.module.scss";
function CampaignQuizEmployeeRow({
  index,
  name,
  image,
  designation,
  department,
  location,
  attempts,
  result,
  onExpand,
  isExpanded,
  toggleExpander,
  showExpand,
  attempted,
  attemptNumber,
  handleResultClick,
}) {
  return (
    <>
      {!onExpand ? (
        <tr className={style.normal_row}>
          <td>
            <div className={style.tab_team_member_info}>
              <div className={[style.team_member_info].join(" ")}>
                <div className={style.team_member_img} style={{ background: getColorByIndex(index) }}>
                  {image?.length > 2 ? <img src={image} alt="background image" /> : image}
                </div>
                <div className={style.team_member_details}>
                  <div className={style.team_member_name}>{name}</div>
                  <div className={style.team_member_designation}>
                    {designation} | {department}
                  </div>
                </div>
                {showExpand && (
                  <div
                    onClick={toggleExpander}
                    className={[style.team_member_info_collapse, isExpanded ? style.collapseClose : ""].join(" ")}
                  >
                    <ReactSVG src={isExpanded ? IC_MINUS : IC_PLUS} alt="" className={style.minus} />
                  </div>
                )}
              </div>
            </div>
          </td>
          <td className={style.employee_location}>{location}</td>
          <td className={style.employee_attempts}>{attempts ? attempts : "-"}</td>
          <td className={[style.action_wrapper, attempted ? "" : "quiz_result_align"].join("")}>
            {attempted ? (
              <div className={style.endorse_tooltip} key={index}>
                <span>
                  <ReactSVG src={result === "Pass" ? `${IC_PASS}` : `${IC_FAIL}`} className={style.passIcon} />
                  <span onClick={() => handleResultClick()} className="resultLink">
                    {result}
                  </span>
                </span>
                <div className={style.tool_tip_info}>
                  <h6>Attempt {attemptNumber}</h6>
                </div>
              </div>
            ) : (
              "-"
            )}
          </td>
          <td>
            <span className={["span-status text_capitalize", attempted ? "attempted" : "notattempted"].join(" ")}>
              {attempted ? "Attempted" : "Not Attempted"}
            </span>
          </td>
        </tr>
      ) : (
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td className={style.action_wrapper}>
            <div className={style.endorse_tooltip} key={index}>
              <span>
                <ReactSVG src={result === "Pass" ? `${IC_PASS}` : `${IC_FAIL}`} className={style.passIcon} />
                <span onClick={() => handleResultClick()} className="resultLink">
                  {result}
                </span>
              </span>
              <div className={style.tool_tip_info}>
                <h6>Attempt {attemptNumber}</h6>
              </div>
            </div>
          </td>
          <td></td>
        </tr>
      )}
    </>
  );
}
export default CampaignQuizEmployeeRow;
