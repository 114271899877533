import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { RatingAnimation } from "util/utils";
import * as actions from "store/actions/index";
import RatingBubble from "shared/RatingBubble";
import RatingBubble0 from "shared/RatingBubble0";
import RatingBubble1 from "shared/RatingBubble1";
import RatingBubble2 from "shared/RatingBubble2";
import "./feelingbox.scss";

const ratings = {
  one: {
    count: 1,
    text: "Really Terrible!",
    question1: "Happens to all of us. Anything you need?",
  },
  two: {
    count: 2,
    text: "Somewhat Bad!",
    question1: "Got it. Anything that would have you feel better? ",
  },
  three: {
    count: 3,
    text: "Completely Okay!",
    question1: "Care to elaborate?",
  },
  four: {
    count: 4,
    text: "Pretty Good!",
    question1: "Anything that would kick it up a notch?",
  },
  five: {
    count: 5,
    text: "Awesome!",
    question1: "That IS amazing! What’s your secret?",
  },
};

export default function FeelingBox(props) {
  const dispatch = useDispatch();
  const [currentMood, setCurrentMood] = useState(null);
  const [currentError, setCurrentError] = useState(null);
  const { moodoMeter, surveyCyclesId, validationErrors, isCycleChange } = useSelector((state) => state.checkIn);
  const [prevMood, setPrevMood] = useState(null);

  useEffect(() => RatingAnimation(), []);

  const moodoMeterCurrent = props?.moodoMeter;
  const surveyCyclesIdCurrent = props?.currentSurveyCycleID;

  useEffect(() => {
    if (moodoMeterCurrent && moodoMeterCurrent.length > 0) setCurrentMood(moodoMeterCurrent && moodoMeterCurrent.length && moodoMeterCurrent[0].answer);
  }, [props?.moodoMeter, props?.currentSurveyCycleID]);

  useEffect(() => {
    if (moodoMeterCurrent && moodoMeterCurrent.length > 0)
      setCurrentError(validationErrors.filter((err) => err.qId === moodoMeterCurrent[0].queId)[0]);
  }, [validationErrors, moodoMeterCurrent]);

  const onReviewComment = (e) => {
    const questionsAnswer = {
      queId: moodoMeterCurrent && moodoMeterCurrent[0]?.queId,
      employeeComment: e.target.value.trim(),
      employeeId: {
        employeeId: props.user?.employeeId,
        parentGroupId: props.user?.parentGroupId,
        subGroupId: props.user?.subGroupId,
      },
    };
    const params = { surveyCycleId: surveyCyclesIdCurrent };
    dispatch(actions.HandleMoodChange(params, questionsAnswer, moodoMeterCurrent));
  };

  const handlerMoodClick = (selectedRating, flag) => {
    if (moodoMeterCurrent && moodoMeterCurrent.length > 0) {
      setCurrentMood(selectedRating);
      let errors = validationErrors.filter((item) => item.qId !== moodoMeterCurrent[0].queId);
      dispatch(actions.SetValidationErrors(errors));
      const params = { surveyCycleId: surveyCyclesIdCurrent };
      let questionsAnswer;
      if ((!prevMood || +prevMood === +selectedRating) && currentMood) {
        setPrevMood(selectedRating);
        questionsAnswer = {
          queId: moodoMeterCurrent[0].queId,
          answer: selectedRating,
        };
      } else {
        setPrevMood(selectedRating);
        questionsAnswer = {
          queId: moodoMeterCurrent[0].queId,
          answer: selectedRating,
          employeeComment: "",
          employeeId: {
            employeeId: props.user?.employeeId,
            parentGroupId: props.user?.parentGroupId,
            subGroupId: props.user?.subGroupId,
          },
        };
        Object.keys(ratings).map((key) => (document.getElementById("rate_comment_" + key).value = ""));
        dispatch(actions.HandleMoodChange(params, questionsAnswer, moodoMeterCurrent));
      }
    }
    if (flag === "singleBuble") {
      dispatch({ type: "IS_CYCLE_CHANGE", payload: true });
    } else if (flag === "allBuble") {
      dispatch({ type: "IS_CYCLE_CHANGE", payload: false });
    }
  };

  return (
    <div className="feeling-box-wrapper">
      {currentError && currentError.showError ? (
        <div className="feeling-error-box">
          <span>Action Required!</span>
        </div>
      ) : null}
      <div className="box feeling-box">
        <h2 className="main_title">Hey {props.user && props.user.firstName}! How are you feeling?</h2>
        <div>
          {currentMood === null ?
            <Form className="ratingform">
              <ul className="rating">
                {Object.keys(ratings).map((key) => {
                  let activeClass = "";
                  return (
                    <RatingBubble0
                      key={key}
                      currentMood={currentMood}
                      mood={moodoMeterCurrent && moodoMeterCurrent[0]}
                      rating={ratings[key]}
                      title={key}
                      currentClass={activeClass}
                      onCountClick={handlerMoodClick}
                      onReviewComment={onReviewComment}
                    />
                  )
                })}
              </ul>
            </Form> :
            <Form className="ratingform">
              {isCycleChange === false && currentMood !== undefined ?
                <ul className="rating">
                  {Object.keys(ratings).map((key) => {
                    let activeClass = "";
                    if (currentMood)
                      ratings[key].count === +currentMood
                        ? (activeClass = "active opened staticActive")
                        : (activeClass = "opacity1");
                    return (
                      <RatingBubble1
                        key={key}
                        currentMood={currentMood}
                        mood={moodoMeterCurrent && moodoMeterCurrent[0]}
                        rating={ratings[key]}
                        title={key}
                        currentClass={activeClass}
                        onCountClick={handlerMoodClick}
                        onReviewComment={onReviewComment}
                      />
                    )
                  })}
                </ul> :
                <ul className="rating">
                  {Object.keys(ratings).map((key) => {
                    let activeClass = "";
                    if (currentMood)
                      ratings[key].count === +currentMood
                        ? (activeClass = "active opened")
                        : (activeClass = "opacity1");
                    return (
                      <RatingBubble2
                        key={key}
                        mood={currentMood}
                        // rating={ratings[key].count === +currentMood ? ratings[key] : ratings[key]}
                        rating={ratings[key].count === +currentMood ? ratings[key] : ratings[key]}
                        title={key}
                        currentClass={activeClass}
                        onCountClick={handlerMoodClick}
                        onReviewComment={onReviewComment}
                      />
                    )
                  })}
                </ul>}
            </Form>}
        </div>
      </div>
    </div>
  );
}
