import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import style from "./MySkill.module.scss";
import RollBasedSkill from "../RollBasedSkill/RollBasedSkill";
import MyAdditionalSkill from "../MyAdditionalSkill/MyAdditionalSkill";
import MyResume from "../MyResume/MyResume";

export default function MySkill() {
  return (
    <div className={style.myskill_wrapper}>
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">
              Skill Matrix <span>My Skills</span>
            </span>
          </Col>
          <Col lg={12}>
            <MyResume />
            <RollBasedSkill />
            <MyAdditionalSkill />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
