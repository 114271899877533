import React, { useEffect, useState } from "react";
import { Card, Button, Accordion } from "react-bootstrap";
import style from "./RollBasedSkill.module.scss";
import { ReactSVG } from "react-svg";
import { IC_INFO, IMG_ENDORSEMENT_1, IMG_PM, IMG_SME, IMG_UNI } from "../../../const/imgCost";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import { useHistory } from "react-router-dom";
import NoDataFound from "../../NodataFound/NoDataFound";
import { competencyLevel, noData } from "const/constValue";
import routePath from "const/routePath";

export default function RollBasedSkill(props) {
  const dispatch = useDispatch();
  const [roleBaseSkill, setRoleBaseSkill] = useState([]);
  const { employeeRequiredSkillList } = useSelector((state) => state.skillMatrix);
  const history = useHistory();

  useEffect(() => {
    const roleBaseSkillList = () => dispatch(actions.employeeRequiredSkill);
    roleBaseSkillList();
  }, [dispatch]);

  useEffect(() => {
    if (employeeRequiredSkillList && employeeRequiredSkillList.length > 0) {
      setRoleBaseSkill(employeeRequiredSkillList);
    }
  }, [employeeRequiredSkillList]);

  const techRole =
    roleBaseSkill && roleBaseSkill.length > 0 ? `${" ("}${roleBaseSkill[0]["technicalRoleName"]}${")"}` : null;
  return (
    <>
      <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>My Role Based Skills{techRole}</h2>
          </div>
          <button
            type="button"
            className={["btn-round save_box_btn btn btn-secondary", style.create_new_btn].join(" ")}
            onClick={() => history.push(routePath.MY_SKILL_REQUIRED)}
          >
            <strong>Role Based Skills</strong>
          </button>
        </div>
        <div className={[style.rolled_based_skill_title, "box-light-gradient", "h38"].join(" ")}>
          <h6 className={style.categorise_skill_title}>Category wise Skills</h6>
          <h6 className={style.categorise_skill_title}>Skill Gaps</h6>
        </div>
        <div className="box-inner-pad">
          <Accordion className="custom-accordion-2">
            {roleBaseSkill.length > 0 ? (
              roleBaseSkill.map((rbs) => {
                return rbs.category.map((cat, index) => {
                  return (
                    <Card key={index}>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                          {cat.categoryName}
                        </Accordion.Toggle>
                        <span className={style.skill_gap_count}>{cat.categoryGapCount}</span>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index}>
                        <Card.Body>
                          <div className={style.skill_gaps_table}>
                            <ul className={style.skills_head}>
                              <li>
                                <span className={style.skill_name}>Skill Name</span>
                                <span className={style.endorsement}>Endorsement</span>
                                <span className={style.current}>Current</span>
                                <div className={style.th_info_wrapper}>
                                  <span>Expected</span>
                                  <div className={[style.th_info_tooltip, "custom-tooltip d-none"].join(" ")}>
                                    <div className="box-info">
                                      <ReactSVG src={IC_INFO} title="" alt="info" />
                                    </div>
                                    <div className="info-attach-file">
                                      <span className="attachment-title">Content Will Update</span>
                                      <ul>
                                        <li>Lorem ipsum</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <span className={style.relevant_exp}>Rel. Experience</span>
                                <span className={style.last_used}>Last Used On</span>
                              </li>
                            </ul>
                            {cat.requiredSkill.skillset.map((skillSet, index) => {
                              return (
                                <div className={style.skill_require_set_wrapper} key={index}>
                                  {skillSet.skill.length > 1 &&
                                  (skillSet.shouldKnowAtleast > 0 || skillSet.shouldKnowAtleast === 0) ? (
                                    <span
                                      className={[
                                        style.skill_required_txt,
                                        skillSet.gapFound === -1 && "text-danger",
                                      ].join(" ")}
                                    >
                                      *Required {skillSet.shouldKnowAtleast === 0 ? 1 : skillSet.shouldKnowAtleast}{" "}
                                      skills out of {skillSet.skill.length}
                                    </span>
                                  ) : (
                                    <span
                                      className={[
                                        style.skill_required_txt,
                                        skillSet.gapFound === -1 && "text-danger",
                                      ].join(" ")}
                                    >
                                      *Required
                                    </span>
                                  )}
                                  <ul className={style.skills_body}>
                                    {skillSet.skill.map((sk) => {
                                      let relExperience;
                                      let lastUsed;

                                      if (
                                        (sk.experienceInYears && sk.experienceInMonth) ||
                                        sk.experienceInYears === 0 ||
                                        sk.experienceInMonth === 0
                                      ) {
                                        relExperience = sk.experienceInYears + "." + sk.experienceInMonth + " Years";
                                      } else {
                                        relExperience = "-";
                                      }

                                      if (
                                        (sk.lastUsedMonth && sk.lastUsedYear) ||
                                        sk.lastUsedMonth === 0 ||
                                        sk.lastUsedYear === 0
                                      ) {
                                        lastUsed = sk.lastUsedMonth + " " + sk.lastUsedYear;
                                      } else {
                                        lastUsed = "-";
                                      }
                                      sk.endorsementList = sk.endorsementList.filter((ed) => ed.count > 0);
                                      return (
                                        <li key={sk.skillIdNameId}>
                                          <div data-title="Skill Name :-" className={style.skill_name}>
                                            <span>{sk.skillIdName}</span>
                                            <div className={style.skill_gap_tooltip}>
                                              {sk.gapFound === -1 && skillSet.gapFound === -1 && (
                                                <div>
                                                  <i className={[style.skill_gap_icon, "red"].join(" ")}></i>
                                                  <span className={style.skill_gap_info}>Gap Found</span>
                                                </div>
                                              )}
                                              {sk.gapFound === 1 && (
                                                <div>
                                                  {" "}
                                                  <i className={[style.skill_gap_icon, "green"].join(" ")}></i>
                                                  <span className={style.skill_gap_info}>
                                                    Exceeding Expectation
                                                  </span>{" "}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div data-title="Endorsement :-" className={style.endorsement}>
                                            {sk.endorsementList.length > 0
                                              ? sk.endorsementList.map((ed, index) => {
                                                  let imgSrc =
                                                    ed.endorsedTypeId === "4"
                                                      ? IMG_ENDORSEMENT_1
                                                      : ed.endorsedTypeId === "3"
                                                      ? IMG_PM
                                                      : ed.endorsedTypeId === "2"
                                                      ? IMG_SME
                                                      : IMG_UNI;

                                                  let endorseType =
                                                    ed.endorsedTypeId === "4"
                                                      ? "Peer"
                                                      : ed.endorsedTypeId === "3"
                                                      ? "PM"
                                                      : ed.endorsedTypeId === "2"
                                                      ? "SME"
                                                      : "University";
                                                  if (ed.count > 0) {
                                                    return (
                                                      <div className={style.endorse_tooltip} key={index}>
                                                        <img src={imgSrc} alt="PEER" />
                                                        <div className={style.tool_tip_info}>
                                                          <h6>{endorseType}</h6>
                                                          <span>Beginner ({ed["beginner"]})</span>
                                                          <span>Intermediate ({ed["intermediate"]})</span>
                                                          <span>Advanced ({ed["advance"]})</span>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                  return ed;
                                                })
                                              : "-"}
                                          </div>

                                          <div
                                            data-title="Current :-"
                                            className={[style.cmp_lvl, style.current].join(" ")}
                                          >
                                            <span
                                              className={[
                                                style.req_comp_level,
                                                competencyLevel[sk.currentCompetencyLevel],
                                              ].join(" ")}
                                            >
                                              {competencyLevel[sk.currentCompetencyLevel]}
                                            </span>
                                          </div>
                                          <div
                                            data-title="Expected :-"
                                            className={[style.cmp_lvl, style.th_info_wrapper].join(" ")}
                                          >
                                            <span
                                              className={[
                                                style.req_comp_level,
                                                competencyLevel[sk.competencyLevel],
                                              ].join(" ")}
                                            >
                                              {competencyLevel[sk.competencyLevel]}
                                            </span>
                                          </div>
                                          <span data-title="Rel. Experience :-" className={style.relevant_exp}>
                                            {relExperience}
                                          </span>
                                          <span data-title="Last Used On :-" className={style.last_used}>
                                            {lastUsed}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            })}
                            {cat.niceToHaveSkill && cat.niceToHaveSkill.skill.length > 0 && (
                              <div className={style.skill_require_set_wrapper}>
                                <span className={style.skill_required_txt}>Nice To Have</span>
                                <ul className={style.skills_body}>
                                  {cat.niceToHaveSkill &&
                                    cat.niceToHaveSkill.skill.map((sk) => {
                                      let relExperience;
                                      let lastUsed;

                                      if (
                                        (sk.experienceInYears && sk.experienceInMonth) ||
                                        sk.experienceInYears === 0 ||
                                        sk.experienceInMonth === 0
                                      ) {
                                        relExperience = sk.experienceInYears + "." + sk.experienceInMonth + " Years";
                                      } else {
                                        relExperience = "-";
                                      }

                                      if (
                                        (sk.lastUsedMonth && sk.lastUsedYear) ||
                                        sk.lastUsedMonth === 0 ||
                                        sk.lastUsedYear === 0
                                      ) {
                                        lastUsed = sk.lastUsedMonth + " " + sk.lastUsedYear;
                                      } else {
                                        lastUsed = "-";
                                      }
                                      sk.endorsementList = sk.endorsementList.filter((ed) => ed.count > 0);
                                      return (
                                        <li key={sk.skillIdNameId}>
                                          <span data-title="Skill Name :-" className={style.skill_name}>
                                            {sk.skillIdName}
                                          </span>
                                          <div data-title="Endorsement :-" className={style.endorsement}>
                                            {sk.endorsementList && sk.endorsementList.length > 0
                                              ? sk.endorsementList.map((ed, index) => {
                                                  let imgSrc =
                                                    ed.endorsedTypeId === "4"
                                                      ? IMG_ENDORSEMENT_1
                                                      : ed.endorsedTypeId === "3"
                                                      ? IMG_PM
                                                      : ed.endorsedTypeId === "2"
                                                      ? IMG_SME
                                                      : IMG_UNI;

                                                  let endorseType =
                                                    ed.endorsedTypeId === "4"
                                                      ? "Peer"
                                                      : ed.endorsedTypeId === "3"
                                                      ? "PM"
                                                      : ed.endorsedTypeId === "2"
                                                      ? "SME"
                                                      : "University";
                                                  if (ed.count > 0) {
                                                    return (
                                                      <div className={style.endorse_tooltip} key={index}>
                                                        <img src={imgSrc} alt="PEER" />
                                                        <div className={style.tool_tip_info}>
                                                          <h6>{endorseType}</h6>
                                                          <span>Beginner ({ed["beginner"]})</span>
                                                          <span>Intermediate ({ed["intermediate"]})</span>
                                                          <span>Advanced ({ed["advance"]})</span>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                  return <></>;
                                                })
                                              : "-"}
                                          </div>
                                          <div
                                            data-title="Current :-"
                                            className={[style.cmp_lvl, style.current].join(" ")}
                                          >
                                            <span
                                              className={[
                                                style.req_comp_level,
                                                competencyLevel[sk.currentCompetencyLevel],
                                              ].join(" ")}
                                            >
                                              {competencyLevel[sk.currentCompetencyLevel]}
                                            </span>
                                          </div>
                                          <div
                                            data-title="Expected :-"
                                            className={[style.cmp_lvl, style.th_info_wrapper].join(" ")}
                                          >
                                            <span
                                              className={[
                                                style.req_comp_level,
                                                competencyLevel[sk.competencyLevel],
                                              ].join(" ")}
                                            >
                                              {competencyLevel[sk.competencyLevel]}
                                            </span>
                                          </div>
                                          <span data-title="Rel. Experience :-" className={style.relevant_exp}>
                                            {relExperience}
                                          </span>
                                          <span data-title="Last Used On :-" className={style.last_used}>
                                            {lastUsed}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                });
              })
            ) : (
              <div>
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap120"></div>
              </div>
            )}
          </Accordion>
        </div>

        {/* <SkillsGapFound /> */}
      </div>
    </>
  );
}
