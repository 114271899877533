import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "../CycleMemberProfile.module.scss";
import { Form } from "react-bootstrap";
import InputRange from "react-input-range";
import OptionalComment from "../QuestionAnswer/OptionalComment";

const count = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function NpsQuestion({
  isSubmitted,
  item,
  NpsRatingHandlerTemplate,
  NpsCommentHandlerTemplate,
  errors,
  downloadFile,
  deleteFile,
  uploadFile,
  isSurvey,
}) {
  const [rating, setRating] = useState(item.scaleValue || 10);
  let commentError = errors?.find((v) => v.qId === item.questionId && v.commentBoxError && v.isSubmit);

  function clickHandler(value) {
    setRating(value);
    if (value !== rating) {
      NpsRatingHandlerTemplate(value, item);
    }
  }
  return (
    <Form.Group controlId="">
      <div className={["two_column", style.workload_rating_guide].join(" ")}>
        {/* <span>Strongly Disagree</span>
        <span>Strongly Agree</span> */}
      </div>
      <div className="custom-range-slider nps-range-slider">
        <InputRange
          disabled={isSubmitted}
          maxValue={10}
          minValue={0}
          id={item.questionId}
          value={rating}
          onChange={(value) => clickHandler(value)}
        />
        <ul className="rating-number">
          {count.map((value, index) => (
            <li key={index}>{value}</li>
          ))}
        </ul>
      </div>
      {item.optionalCommentBox && (
        <OptionalComment
          item={item}
          uploadFile={(e) => uploadFile(e, item.questionId)}
          commentError={commentError ? true : false}
          handleComment={(e) => NpsCommentHandlerTemplate(e, item)}
          isSubmitted={isSubmitted}
          downloadFile={downloadFile}
          commentPlaceHolder={
            item.commentResponse === "Required" ? "Add a new answer (Required)" : "Add a new answer (Optional)"
          }
          deleteFile={deleteFile}
          isSurvey={isSurvey}
        />
      )}
    </Form.Group>
  );
}

NpsQuestion.propTypes = {
  isSubmitted: PropTypes.bool,
  item: PropTypes.object,
  uploadFile: PropTypes.func,
  NpsCommentHandlerTemplate: PropTypes.func,
  NpsRatingHandlerTemplate: PropTypes.func,
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
};

export default NpsQuestion;
