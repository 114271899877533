import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import style from "./CertificationOverview.module.scss";
import * as actions from "store/actions/index";
import { IC_INPUT_SEARCH, IC_COLLAPSE, IC_EXPAND } from "const/imgCost";
import { filterArray } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../Pagination/Pagination";
import NoDataFound from "../NodataFound/NoDataFound";
import { noData, title } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function CertificationOverview() {
  const dispatch = useDispatch();
  const { isLoading, certificateOverviewList, size } = useSelector((state) => state.university);
  const [searchText, setSearchText] = useState("");
  const [pageOfItems, setPageOfItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [CertificateList, setCertificateList] = useState([]);
  const [open, setOpen] = useState({
    flag: false,
    id: null,
  });

  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  useEffect(() => {
    const array = [];
    if (certificateOverviewList && Object.keys(certificateOverviewList).length > 0) {
      Object.keys(certificateOverviewList).map((cr) => {
        const obj = {
          [cr]: certificateOverviewList[cr],
        };
        array.push(obj);
        return cr;
      });
      setCertificateList(array);
    }
  }, [certificateOverviewList]);

  //to get data from api
  useEffect(() => {
    const getCertificateList = () => {
      dispatch(actions.getCertificateOverviewList);
    };
    getCertificateList();
  }, [dispatch]);

  //handle search input
  const handleSearchInputChange = (e) => {
    setOpen({
      flag: false,
      id: null,
    });
    setSearchText(e.target.value);
  };

  useEffect(() => {
    const filterData = [];
    if (certificateOverviewList && Object.keys(certificateOverviewList).length > 0) {
      if (searchText.length > 0) {
        Object.keys(certificateOverviewList).map((cr) => {
          if (cr.toLowerCase().includes(searchText.toLowerCase().trim())) {
            filterData.push({ [cr]: certificateOverviewList[cr] });
          }
          return cr;
        });
      } else {
        Object.keys(certificateOverviewList).map((cr) => {
          filterData.push({ [cr]: certificateOverviewList[cr] });
          return cr;
        });
      }
      if (filterData.length === 0) {
        setPageOfItems(filterData);
      }
      setCertificateList(filterData);
    }
  }, [certificateOverviewList, searchText]);

  // to clear input search field & set data to defaul
  const reset = () => {
    setOpen({
      flag: false,
      id: null,
    });
    setSearchText("");
  };

  useEffect(() => {
    const array = [];
    if (certificateOverviewList && Object.keys(certificateOverviewList).length > 0) {
      Object.keys(certificateOverviewList).map((sk) => {
        const obj = {
          [sk]: certificateOverviewList[sk],
        };
        array.push(obj);
        return sk;
      });
    }
    filteredData.length > 0
      ? setCertificateList(filteredData)
      : null
      ? setCertificateList(filteredData)
      : setCertificateList(array);
  }, [filteredData, certificateOverviewList]);

  const resetData = () => {
    setOpen({
      flag: false,
      id: null,
    });
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (CertificateList && CertificateList.length > 0) {
      filteredData = filterArray(CertificateList, filters);
      setFilteredData(filteredData);
    }
  };

  const onChangeCertificatePagePage = (item) => {
    setPageOfItems(item);
  };

  return (
    <>
      <div className={style.member_certificate_wrapper}>
        <SubNavigation type="University" />
        <Container>
          <Row className="align-items-center">
            <Col md={12}>
              <h1 className="page-title pb-0">Certification Overview</h1>
              <span className="st-breadcrumb">
                To have a glance into list of member’s professional certification details
              </span>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12}>
              <div className={[style.certficate_overview_box, "box"].join(" ")}>
                <div className="box-heading-wrapper">
                  <div className="check_in_search_bar">
                    <Form>
                      <Form.Group controlId="" className="form-postfix mb-0">
                        <Form.Control
                          type="text"
                          placeholder="Search by certification"
                          name="searchBox"
                          className={className}
                          value={searchText}
                          onChange={handleSearchInputChange}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") e.preventDefault();
                          }}
                        />
                        <Button className="close-button" type="reset" onClick={reset}>
                          <span className="close-icon"></span>
                        </Button>
                        <div className="form-postfix-icon">
                          <img src={IC_INPUT_SEARCH} title="" alt="" />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                {isLoading ? (
                  <Spinner />
                ) : pageOfItems && pageOfItems.length > 0 ? (
                  <>
                    <div className="box-light-gradient h38"></div>
                    <div className="box-inner-pad pb-2">
                      <div className={style.certificate_count_wrapper}>
                        <div className={style.certificate_head}>
                          <span>{title.CERTIFICATION_NAME}</span>
                          <span>Count</span>
                        </div>
                        <div className={style.certificate_list_wrapper}>
                          {pageOfItems &&
                            pageOfItems.map((certificate) => {
                              return Object.keys(certificate).map((cr) => {
                                return (
                                  <div className={style.certificate_item} key={cr}>
                                    <div
                                      className={[style.item_topbar].join(" ")}
                                      onClick={() => {
                                        if (open.id !== cr) {
                                          setOpen({ flag: true, id: cr });
                                        } else {
                                          setOpen({ flag: !open.flag, id: cr });
                                        }
                                      }}
                                    >
                                      <div className={style.certificate_info}>
                                        <i className={style.expand_collapse_wrapper}>
                                          <img
                                            src={open.id === cr && open.flag ? IC_COLLAPSE : IC_EXPAND}
                                            className={open.id === cr ? style.collapse : style.expand}
                                            alt="Collapse"
                                          />
                                        </i>
                                        <span className={style.certificate_name}>{cr}</span>
                                      </div>
                                      <span className={style.count_text}>{certificate[cr].length}</span>
                                    </div>
                                    {open.id === cr && open.flag && (
                                      <div className={style.item_body}>
                                        <div
                                          className={[
                                            style.member_certificate_table,
                                            "custom-table",
                                            "table-theme-1",
                                          ].join(" ")}
                                        >
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th>Emp Id</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {certificate[cr].map((emp) => {
                                                return (
                                                  <tr key={emp.id}>
                                                    <td>{emp.employeeId}</td>
                                                    <td>{emp.employeeName}</td>
                                                    <td>
                                                      {emp.status.toLowerCase() === "valid" && (
                                                        <span className={[style.req_comp_level, "purple"].join(" ")}>
                                                          Valid
                                                        </span>
                                                      )}
                                                      {emp.status.toLowerCase() === "expired" && (
                                                        <span className={[style.req_comp_level, "orange"].join(" ")}>
                                                          Expired
                                                        </span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              });
                            })}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap120"></div>
                  </div>
                )}
              </div>
              {CertificateList && CertificateList.length !== 0 && (
                <Pagination
                  pageSize={size}
                  items={CertificateList}
                  onChangePage={onChangeCertificatePagePage}
                  resetData={resetData}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
