import React from "react";
import style from "./SubmitBox.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import { Button } from "react-bootstrap";

function SubmitBox({ handleSubmit, updatedDate, name, handleSecond, secondBtnName }) {
  return (
    <div className={["box box-flex-center", style.saveBox].join(" ")}>
      <div className={style.save_box_left}>
        <Button type="button" variant={"primary"} className={["btn-round", style.btn].join(" ")} onClick={handleSubmit}>
          <strong>{name}</strong>
        </Button>
        {secondBtnName && (
          <Button
            type="button"
            onClick={handleSecond}
            className={["btn-round save_box_btn btn btn-secondary ml-3", style.btn].join(" ")}
          >
            <strong>{secondBtnName}</strong>
          </Button>
        )}
      </div>
      {updatedDate && (
        <div className={style.save_box_right}>
          Draft Saved: <span>{moment(updatedDate).format("ddd D MMM h:mm a")}</span>
        </div>
      )}
    </div>
  );
}

SubmitBox.propTypes = {
  updatedDate: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SubmitBox;
