import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { IC_NOTIFY } from "../../const/imgCost";
import style from "./InfoCard.module.scss";

function InfoCardWithOl({ heading, description }) {
  const [hide, setHide] = useState(false);
  return (
    <div className={style.note_info_wrapper}>
      <span className={style.notify_icon}>
        <ReactSVG src={IC_NOTIFY} />
      </span>
      <p>{heading}</p>
      <span>
        <div className={style.note_txt}>{description}</div>
        {hide && (
          <ol>
            <li>
              Click on the following link:{" "}
              <a
                href="https://teams.microsoft.com/l/chat/0/0?users=28:407f9393-6dc6-4fb6-9999-fc73d997b8a8"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://teams.microsoft.com/
              </a>{" "}
              and Open with Microsoft Teams where Engage Bot screen will open up.
            </li>
            <li>Now, type Hi in the chat box of Engage Bot and send the message.</li>
            <li>The Proactive Engage Bot will respond to you and is now ready to use.</li>
          </ol>
        )}
        <div className="card_action">
          <span onClick={() => setHide(!hide)}>{hide ? "See Less" : "See More"}</span>
        </div>
      </span>
    </div>
  );
}

export default InfoCardWithOl;
