import React from "react";
import { ReactSVG } from "react-svg";
import style from "./CustomSwitch.module.scss";

function CustomSwitch({ img1, img2, checked, handleChecked }) {
  return (
    <div className={style.switch_wrapper}>
      <div
        className={[style.left_side_switch, checked === "LEFT" && style.active].join(" ")}
        onClick={() => handleChecked("LEFT")}
      >
        <ReactSVG src={img1} />
      </div>
      <div
        className={[style.right_side_switch, checked === "RIGHT" && style.active].join(" ")}
        onClick={() => handleChecked("RIGHT")}
      >
        <ReactSVG src={img2} />
      </div>
    </div>
  );
}

export default CustomSwitch;
