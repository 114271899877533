import CommentsTop from "components/CommentBox/CommentsTop";
import MultipleFile from "components/MultipleFileUpload/MultipleFile";
import React, { useState } from "react";
import { backGroundColor } from "../../../const/constValue";
import Comment from "../../CommentBox/Comment";
import style from "./GlobalComment.module.scss";

export default function GlobalReadOnlyComment({
  heading,
  description,
  comment,
  message,
  obj,
  downloadFileGlobalFeedback,
}) {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className={["box", style.global_comment_main_wrapper].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>{heading}</h2>
          <h3>{description}</h3>
        </div>
      </div>
      <div className={["view_comment_box_wrapper", style.glabal_comment_box].join(" ")}>
        {comment?.length > 0 ? (
          <>
            <CommentsTop heading="Share your view(s)" />
            {comment?.length > 0 &&
              comment
                .filter((value, index) => showMore || index < 2)
                .map((value, index) => (
                  <>
                    <Comment
                      key={index}
                      selectedColor={backGroundColor[1]}
                      image={value.user?.dispArr || value.user?.displayPicture || value.user?.empInitial}
                      placeholder="Add a comment"
                      empName={value.user?.empName}
                      commentedOn={value.commentDate}
                      comment={value.comment}
                    />

                    {value?.files?.length > 0 && (
                      <div className="multiple_uploaded_file">
                        <MultipleFile
                          files={value?.files}
                          isDownloadable={true}
                          downloadFile={(id) => downloadFileGlobalFeedback(id, value.privateAssessmentId)}
                        />
                      </div>
                    )}
                  </>
                ))}
            {comment?.length > 2 && (
              <span className="viewall" onClick={() => setShowMore(!showMore)}>
                {showMore ? "View Less" : "View More"}
              </span>
            )}
          </>
        ) : (
          <div className="no-record-found">{message}</div>
        )}
      </div>
    </div>
  );
}
