import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import style from "../CycleMemberProfile.module.scss";
import OptionalComment from "../QuestionAnswer/OptionalComment";
import { checkYesAnswer } from "util/general";

function RadioQuestion(props) {
  const {
    errors,
    isSubmitted,
    item,
    radioOtherHandlerTemplate,
    radioButtonHandlerTemplate,
    radioCommentHandlerTemplate,
    downloadFile,
    deleteFile,
    uploadFile,
    name,
    isCapability,
    isSurvey,
  } = props;
  const [open, setOpen] = useState(item.otherTextAnswer ? true : false);
  const [required, setRequired] = useState(false);
  let lengthOfOptions = item.options.map((v) => v.option).toString().length;
  let error = errors?.find((v) => v.qId === item.questionId && v.error && v.isSubmit);
  let commentError = errors?.find((v) => v.qId === item.questionId && v.commentBoxError && v.isSubmit);

  const radioHandlerOtherTemplate = (e) => {
    setOpen(e.target.checked);
  };
  return (
    <div className="radio-wrapper">
      <Form.Group controlId="">
        <ul className={[style.form_option_list, error && "limit-reached"].join(" ")}>
          {item.options &&
            item.options.length > 0 &&
            item.options.map((optionsData, indexopt) => (
              <li className={lengthOfOptions > 65 ? "w-100" : ""} key={"RADIO" + optionsData.optionId}>
                <div className="custom-checkbox custom-checkbox-rounded">
                  <input
                    disabled={isSubmitted}
                    id={item.questionId + "_" + optionsData.optionId + "_" + indexopt + "_" + name}
                    type="radio"
                    name={item.questionName + name}
                    defaultChecked={optionsData.isSelected}
                    // checked={optionsData.isSelected}
                    onChange={(value) => {
                      optionsData.option === "Yes" && isCapability && setRequired(true);
                      optionsData.option === "No" && isCapability && setRequired(false);
                      setOpen(false);
                      radioButtonHandlerTemplate(value, item, optionsData.optionId);
                    }}
                  />
                  <label htmlFor={item.questionId + "_" + optionsData.optionId + "_" + indexopt + "_" + name}>
                    {optionsData.option}
                  </label>
                </div>
              </li>
            ))}
          {item?.correctOptionList?.length > 0 && (
            <ul className="common_list_items">
              <li className="mb-0 mt-2">{`Option ${item?.correctOptionList?.join(", ")}`}</li>
            </ul>
          )}
          {item.isOther && (
            <li>
              <div className="custom-checkbox custom-checkbox-rounded">
                <input
                  disabled={isSubmitted}
                  id={"OTHER_RADIO_BUTTON" + item.questionId}
                  type="radio"
                  name={item.questionName + name}
                  defaultChecked={open || item.otherTextAnswer}
                  // checked={open || item.otherTextAnswer}
                  onChange={(e) => radioHandlerOtherTemplate(e)}
                />
                <label htmlFor={"OTHER_RADIO_BUTTON" + item.questionId} className="word-break">
                  Others ({item.otherText ? item.otherText : "please specify"})
                </label>
              </div>
            </li>
          )}
        </ul>
        {open &&
          (isSubmitted ? (
            <span className="w-100 d-block mt-2 word-break">{item.otherTextAnswer}</span>
          ) : (
            <Form.Control
              as="textarea"
              className="mt-3"
              placeholder="Add a new answer"
              maxLength="500"
              defaultValue={item.otherTextAnswer}
              onBlur={(e) => radioOtherHandlerTemplate(e, item)}
            />
          ))}
        {item.optionalCommentBox && (
          <OptionalComment
            item={item}
            uploadFile={isCapability ? null : (e) => uploadFile(e, item.questionId)}
            commentError={commentError ? true : false}
            handleComment={(e) => radioCommentHandlerTemplate(e, item)}
            isSubmitted={isSubmitted}
            downloadFile={downloadFile}
            commentPlaceHolder={
              isCapability
                ? required
                  ? `(Required)`
                  : checkYesAnswer(item?.options)
                  ? `(Required)`
                  : `(${item.commentResponse})`
                : `Add a new answer (${item.commentResponse})`
            }
            deleteFile={deleteFile}
            isSurvey={isSurvey}
          />
        )}
      </Form.Group>
    </div>
  );
}

RadioQuestion.propTypes = {
  item: PropTypes.object,
  errors: PropTypes.array,
  isSubmitted: PropTypes.bool,
  radioOtherHandlerTemplate: PropTypes.func,
  radioButtonHandlerTemplate: PropTypes.func,
  radioCommentHandlerTemplate: PropTypes.func,
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
  uploadFile: PropTypes.func,
};

export default RadioQuestion;
