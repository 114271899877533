import React from "react";
import style from "./Endrosement.module.scss";
import { Container, Row, Tab, Tabs, Col } from "react-bootstrap";
import TabMyEndrosement from "./TabMyEndrosement/TabMyEndrosement";
import TabEndroseByMe from "./TabEndroseByMe/TabEndroseByMe";
import TabEndroseRequest from "./TabEndroseRequest/TabEndroseRequest";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function Endrosement() {
  return (
    <div className={style.myskill_wrapper}>
      <SubNavigation type="WMG" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">
              Skill Matrix
              <span>Endorse the skills</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div
              className={[
                "custom-tabs",
                "custom-tabs-full-width",
                "tab-content-dashboard",
                style.endrosement_tabs,
              ].join(" ")}
            >
              <Tabs defaultActiveKey="my-endrosement" id="endrosement-tab" unmountOnExit={true}>
                <Tab eventKey="my-endrosement" title="My Endorsements">
                  <TabMyEndrosement />
                </Tab>
                <Tab eventKey="endrosed-by-me" title="Endorsed by Me" unmountOnExit={true}>
                  <TabEndroseByMe />
                </Tab>
                <Tab eventKey="endrosed-requests" title="Requests">
                  <TabEndroseRequest />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
