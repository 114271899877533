import React from "react";
import { ReactSVG } from "react-svg";
import { IC_INFO } from "../../const/imgCost";
import TooltipInfoLi from "../../shared/Tooltip/TooltipInfoLi";
import style from "./InfoTooltipWithLi.module.scss";

function InfoTooltipWithLi({ listItem, heading }) {
  return (
    <div className={[style.filter_info_tooltip, "custom-tooltip"].join(" ")}>
      <div className="box-info">
        <ReactSVG src={IC_INFO} title="" alt="info" />
      </div>
      <TooltipInfoLi heading={heading} listItem={listItem} />
    </div>
  );
}

export default InfoTooltipWithLi;
