import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import moment from "moment";
import style from "./employeeObjectives.module.scss";
import { IC_ACC_ARROW_DOWN, IC_NOTIFY, IC_OBJ_ARROW_UP, IC_OBJ_ARROW_DOWN, IC_OBJ_RANGE } from "const/imgCost";
import ObjectiveUserStatus from "./objectiveUserStatus";
import EmployeeObjectiveComment from "./employeeObjectiveComment";

export default function EmployeeObjectiveHTML(props) {
  const targetUserName = useRef(null);

  function addCharacter(keypoint) {
    let inputValue = "";
    if (keypoint.type === "%") {
      inputValue =
        keypoint.progress === ""
          ? "%"
          : keypoint.progress === 0
          ? keypoint.end === 0
            ? 0
            : keypoint.start + "%"
          : keypoint.progress + "%";
    } else if (keypoint.type === "$") {
      inputValue =
        keypoint.progress === ""
          ? "$"
          : keypoint.progress === 0
          ? keypoint.end === 0
            ? 0
            : "$" + keypoint.start
          : "$" + keypoint.progress;
    } else if (keypoint.type === ":") {
      inputValue =
        keypoint.progress === ""
          ? "h"
          : keypoint.progress === 0
          ? keypoint.end === 0
            ? 0
            : "0h"
          : keypoint.progress + "h";
    } else if (keypoint.type === "down") {
      inputValue = keypoint.progress === "" ? 0 : keypoint.progress === 0 ? 0 : keypoint.progress;
    } else if (keypoint.type === "between") {
      inputValue = keypoint.progress === "" ? 0 : keypoint.progress === 0 ? 0 : keypoint.progress;
    } else {
      inputValue =
        keypoint.progress === ""
          ? ""
          : keypoint.progress === 0
          ? keypoint.end === 0
            ? 0
            : keypoint.start
          : keypoint.progress;
    }
    return inputValue;
  }
  function removeCharacter(text) {
    let value;
    if (text.indexOf("$") === 0) {
      value = text.replace(/\$/g, "");
    } else if (text.indexOf("%") !== -1) {
      value = text.replace(/%/g, "");
    } else if (text.indexOf("h") !== -1) {
      value = text.replace(/h/g, "");
    } else {
      value = text;
    }
    return value;
  }

  function setObj(event, value, obj, keypoint) {
    let isCompleted;
    if (keypoint.type === ":") {
      isCompleted = parseFloat(value) === keypoint.hours ? true : false;
    } else if (keypoint.type === "between") {
      isCompleted =
        parseFloat(value) === 0
          ? false
          : parseFloat(value) <= keypoint.end && parseFloat(value) >= keypoint.start
          ? true
          : false;
    } else if (keypoint.type === "up") {
      isCompleted = parseFloat(value) >= keypoint.end ? true : false;
    } else if (keypoint.type === "down") {
      isCompleted = parseFloat(value) === 0 ? true : parseFloat(value) <= keypoint.end ? true : false;
    } else {
      isCompleted = parseFloat(value) === keypoint.end ? true : false;
    }
    let val = "";
    if (keypoint.type === "%") {
      val = value.split(".")[1] === "" ? value : parseFloat(value);
    } else if (keypoint.type === "?") {
      val = value;
      isCompleted = value;
    } else {
      if (value.indexOf(".") !== -1) {
        val = value.split(".")[1] === "" ? value : parseFloat(value);
      } else {
        val = parseFloat(value);
      }
    }
    let progress =
      event.target.value === "h" ||
      event.target.value === "%" ||
      event.target.value === "$" ||
      event.target.value === "NaN" ||
      event.target.value === ""
        ? ""
        : val;
    let data = {
      userObjectiveId: obj.userObjectiveId,
      employeeId: props.user.employeeId,
      employeeData: obj.employeeData,
      keyPoints: {
        keyId: keypoint.keyId,
        description: keypoint.description,
        type: keypoint.type,
        start: keypoint.start,
        end: keypoint.end,
        progress: progress,
        isCompleted: isCompleted,
        hours: keypoint.hours,
        weightage: keypoint.weightage,
        mark: keypoint.type === "?" ? val : null,
      },
    };
    props.userInputData(data);
  }
  const objectiveComponent = props.objectiveData.map((obj, index) => {
    let disableEndObjective = false; //Math.sign(moment(obj.endDate).subtract(1, "days").diff({}, "days")) === -1;

    const innerHTML =
      obj?.keyPoints?.length > 0 ? (
        obj.keyPoints.map((keypoint) => {
          let typeTooltip = null;
          if (keypoint.type === "$") {
            typeTooltip = keypoint.type + "" + keypoint.start + " - " + keypoint.type + "" + keypoint.end;
          } else if (keypoint.type === "%") {
            typeTooltip = keypoint.start + "" + keypoint.type + " - " + keypoint.end + "" + keypoint.type;
          } else if (keypoint.type === "up") {
            typeTooltip = (
              <span>
                <img src={IC_OBJ_ARROW_UP} alt="up" /> {keypoint.start} - {keypoint.end}
              </span>
            );
          } else if (keypoint.type === "down") {
            typeTooltip = (
              <span>
                <img src={IC_OBJ_ARROW_DOWN} alt="down" /> {keypoint.start} - {keypoint.end}
              </span>
            );
          } else if (keypoint.type === "between") {
            typeTooltip = (
              <span>
                <img src={IC_OBJ_RANGE} alt="between" /> {keypoint.start} - {keypoint.end}
              </span>
            );
          } else if (keypoint.type === ":") {
            typeTooltip = keypoint.hours + " hours";
          } else if (keypoint.type === "?") {
            typeTooltip = "Completed/Incomplete";
          }
          let error = "";
          if (keypoint.isCompleted) {
            error = "form-control";
          }
          if (keypoint.start < keypoint.end) {
            if (keypoint.progress <= keypoint.start || keypoint.progress >= keypoint.end) {
              error = "form-control";
            } else if (keypoint.type === "up") {
              if (keypoint.progress >= keypoint.end) {
                error = "form-control";
              }
            } else if (keypoint.type === "down") {
              if (keypoint.progress >= keypoint.end || keypoint.progress === 0) {
                error = "form-control";
              }
            }
          } else if (keypoint.start > keypoint.end) {
            if (keypoint.progress >= keypoint.start || keypoint.progress <= keypoint.end) {
              error = "form-control";
            } else if (keypoint.type === "up") {
              if (keypoint.progress >= keypoint.end) {
                error = "form-control";
              }
            } else if (keypoint.type === "down") {
              if (keypoint.progress >= keypoint.end || keypoint.progress === 0) {
                error = "form-control";
              }
            }
          } else {
            if (keypoint.type === ":") {
              if (keypoint.progress === 0) {
                error = "form-control";
              }
            } else if (keypoint.type === "up") {
              if (keypoint.progress >= keypoint.end) {
                error = "form-control";
              }
            } else if (keypoint.type === "down") {
              if (keypoint.progress >= keypoint.end || keypoint.progress === 0) {
                error = "form-control";
              }
            }
          }
          return (
            <div className={style.employeeObjectives_info_block} key={keypoint.keyId}>
              <div className={style.employeeObjectives_info_title}>
                <div className="custom-checkbox custom-checkbox-rounded">
                  <input
                    id={keypoint.keyId + "KEYPOINT" + obj.userObjectiveId}
                    type="checkbox"
                    checked={keypoint.progress === keypoint.end ? true : keypoint.isCompleted}
                    disabled={
                      obj.completionDate !== null || obj.isNotify
                        ? true
                        : props.editable
                        ? true
                        : disableEndObjective
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (props.editable) {
                        return false;
                      }
                      let progress;
                      if (keypoint.type === ":") {
                        progress =
                          e.target.checked === false ? 0 : !keypoint.isCompleted ? keypoint.hours : keypoint.progress;
                      } else if (keypoint.type === "between") {
                        progress =
                          e.target.checked === false
                            ? 0
                            : keypoint.progress === 0
                            ? keypoint.end
                            : e.target.checked
                            ? keypoint.end
                            : keypoint.progress;
                      } else {
                        progress =
                          e.target.checked === false
                            ? keypoint.start
                            : !keypoint.isCompleted
                            ? keypoint.end
                            : keypoint.progress;
                      }

                      let isCompleted;
                      isCompleted =
                        keypoint.type === ":"
                          ? keypoint.progress === keypoint.hours
                            ? e.target.checked === false
                              ? false
                              : true
                            : !keypoint.isCompleted
                          : keypoint.progress === keypoint.end
                          ? e.target.checked === false
                            ? false
                            : true
                          : !keypoint.isCompleted;

                      let data = {
                        userObjectiveId: obj.userObjectiveId,
                        employeeId: props.user.employeeId,
                        employeeData: obj.employeeData,
                        subGroupId: obj.subGroupId,
                        subGroupName: obj.subGroupName,
                        parentGroupId: obj.parentGroupId,
                        parentGroupName: obj.parentGroupName,
                        keyPoints: {
                          keyId: keypoint.keyId,
                          description: keypoint.description,
                          type: keypoint.type,
                          start: keypoint.start,
                          end: keypoint.end,
                          progress: progress,
                          isCompleted: isCompleted,
                          hours: keypoint.hours,
                          weightage: keypoint.weightage,
                          mark: keypoint.type === "?" ? isCompleted : null,
                        },
                      };
                      let elements = document.getElementsByClassName("form-control error");
                      elements.forEach((ele) => {
                        if (ele.getAttribute("id") === keypoint.keyId) {
                          ele.classList.remove("error");
                        }
                      });
                      props.selectRadio(data);
                    }}
                  />
                  <label htmlFor={keypoint.keyId + "KEYPOINT" + obj.userObjectiveId}>{keypoint.description}</label>
                </div>
                <div className={["d-flex", "employeeObjectives_info_action"].join(" ")}>
                  <Form.Group>
                    {keypoint.type === "?" ? (
                      <div className="cmp-in-comp-info">
                        Completed?
                        <div className="custom-checkbox custom-checkbox-sm">
                          <input
                            id={obj.userObjectiveId + "Checked" + keypoint.keyId}
                            type="checkbox"
                            disabled={
                              obj.completionDate !== null || obj.isNotify
                                ? true
                                : props.editable
                                ? true
                                : disableEndObjective
                                ? true
                                : false
                            }
                            checked={keypoint.mark ? keypoint.mark : false}
                            label=""
                            onChange={(event) => {
                              let data = {
                                userObjectiveId: obj.userObjectiveId,
                                employeeId: props.user.employeeId,
                                employeeData: obj.employeeData,
                                keyPoints: {
                                  keyId: keypoint.keyId,
                                  description: keypoint.description,
                                  type: keypoint.type,
                                  start: keypoint.start,
                                  end: keypoint.end,
                                  progress: null,
                                  isCompleted: event.target.checked,
                                  hours: keypoint.hours,
                                  weightage: keypoint.weightage,
                                  mark: keypoint.type === "?" ? event.target.checked : null,
                                },
                              };
                              props.selectRadio(data);
                            }}
                          />
                          <label htmlFor={obj.userObjectiveId + "Checked" + keypoint.keyId}></label>
                        </div>
                      </div>
                    ) : (
                      <Form.Control
                        ref={targetUserName}
                        type="text"
                        id={keypoint.keyId}
                        objid={obj.userObjectiveId}
                        value={addCharacter(keypoint)}
                        disabled={
                          obj.completionDate !== null || obj.isNotify
                            ? true
                            : props.editable
                            ? true
                            : disableEndObjective
                            ? true
                            : false
                        }
                        onFocus={(event) => {
                          if (event.currentTarget) {
                            event.currentTarget.removeAttribute("readonly");
                          }
                        }}
                        className={error}
                        onBlur={(event) => {
                          if (props.editable) {
                            return false;
                          }
                          let value = removeCharacter(event.target.value);
                          if (keypoint.type === ":") {
                            if (parseFloat(value) > keypoint.hours) {
                              event.currentTarget.classList.add("error");
                            } else if (parseFloat(value) < 0) {
                              event.currentTarget.classList.add("error");
                            } else {
                              event.currentTarget.classList.remove("error");
                            }
                          } else if (keypoint.type === "up") {
                            if (parseFloat(value) < keypoint.start) {
                              event.currentTarget.classList.add("error");
                            } else {
                              event.currentTarget.classList.remove("error");
                            }
                          } else if (keypoint.type === "down") {
                            if (parseFloat(value) > keypoint.start) {
                              event.currentTarget.classList.add("error");
                            } else {
                              event.currentTarget.classList.remove("error");
                            }
                          } else if (keypoint.type === "between") {
                            if (keypoint.start < keypoint.end) {
                              if (parseFloat(value) < keypoint.start || parseFloat(value) > keypoint.end) {
                                event.currentTarget.classList.add("error");
                              } else {
                                event.currentTarget.classList.remove("error");
                              }
                            } else {
                              if (parseFloat(value) > keypoint.start || parseFloat(value) < keypoint.end) {
                                event.currentTarget.classList.add("error");
                              } else {
                                event.currentTarget.classList.remove("error");
                              }
                            }
                          } else {
                            if (keypoint.start < keypoint.end) {
                              if (parseFloat(value) < keypoint.start) {
                                event.currentTarget.classList.add("error");
                              } else if (parseFloat(value) > keypoint.end) {
                                event.currentTarget.classList.add("error");
                              } else {
                                event.currentTarget.classList.remove("error");
                              }
                            } else {
                              if (parseFloat(value) > keypoint.start) {
                                event.currentTarget.classList.add("error");
                              } else if (parseFloat(value) < keypoint.end) {
                                event.currentTarget.classList.add("error");
                              } else {
                                event.currentTarget.classList.remove("error");
                              }
                            }
                          }
                          let data = {
                            objId: event.currentTarget.getAttribute("objid"),
                            id: event.currentTarget.id,
                          };
                          props.setCurrentInputText(data);
                          if (event.currentTarget) {
                            event.currentTarget.setAttribute("readonly", "");
                          }
                        }}
                        onKeyPress={(event) => {
                          var charC = event.which ? event.which : event.keyCode;
                          if (charC === 46) {
                            if (event.currentTarget.value.indexOf(".") === -1) {
                              return true;
                            } else {
                              return false;
                            }
                          } else {
                            if (charC > 31 && (charC < 48 || charC > 57)) {
                              return false;
                            } else {
                              return true;
                            }
                          }
                        }}
                        onChange={(event) => {
                          if (props.editable) {
                            return false;
                          }
                          let value = removeCharacter(event.target.value);
                          if (keypoint.type === ":") {
                            if (parseFloat(value) > keypoint.hours) {
                              event.currentTarget.classList.add("error");
                            } else if (parseFloat(value) < 0) {
                              event.currentTarget.classList.add("error");
                            } else {
                              event.currentTarget.classList.remove("error");
                            }
                          } else if (keypoint.type === "up") {
                            if (parseFloat(value) < keypoint.start) {
                              event.currentTarget.classList.add("error");
                            } else {
                              event.currentTarget.classList.remove("error");
                            }
                          } else if (keypoint.type === "down") {
                            if (parseFloat(value) > keypoint.start) {
                              event.currentTarget.classList.add("error");
                            } else {
                              event.currentTarget.classList.remove("error");
                            }
                          } else if (keypoint.type === "between") {
                            if (keypoint.start < keypoint.end) {
                              if (parseFloat(value) < keypoint.start && parseFloat(value) > keypoint.end) {
                                event.currentTarget.classList.add("error");
                              } else {
                                event.currentTarget.classList.remove("error");
                              }
                            } else {
                              if (parseFloat(value) > keypoint.start && parseFloat(value) < keypoint.end) {
                                event.currentTarget.classList.add("error");
                              } else {
                                event.currentTarget.classList.remove("error");
                              }
                            }
                          } else {
                            if (keypoint.start < keypoint.end) {
                              if (parseFloat(value) < keypoint.start) {
                                event.currentTarget.classList.add("error");
                              } else if (parseFloat(value) > keypoint.end) {
                                event.currentTarget.classList.add("error");
                              } else {
                                event.currentTarget.classList.remove("error");
                              }
                            } else {
                              if (parseFloat(value) > keypoint.start) {
                                event.currentTarget.classList.add("error");
                              } else if (parseFloat(value) < keypoint.end) {
                                event.currentTarget.classList.add("error");
                              } else {
                                event.currentTarget.classList.remove("error");
                              }
                            }
                          }

                          if (keypoint.type === "%") {
                            if (parseFloat(value) >= 0 || value === "") {
                              if (value.length > 5) {
                                event.currentTarget.classList.remove("error");
                                return false;
                              }
                              setObj(event, value, obj, keypoint);
                            } else {
                              event.preventDefault();
                            }
                          } else if (
                            keypoint.type === "up" ||
                            keypoint.type === "down" ||
                            keypoint.type === "between"
                          ) {
                            if (parseFloat(value) >= -1 || value === "") {
                              if (value.length > 11) {
                                event.currentTarget.classList.remove("error");
                                return false;
                              }
                              setObj(event, value, obj, keypoint);
                            } else {
                              event.preventDefault();
                            }
                          } else if (keypoint.type === "$") {
                            if (parseFloat(value) >= 0 || value === "") {
                              if (value.length > 9) {
                                event.currentTarget.classList.remove("error");
                                return false;
                              }
                              setObj(event, value, obj, keypoint);
                            } else {
                              event.preventDefault();
                            }
                          } else if (keypoint.type === ":") {
                            if (parseFloat(value) >= 0 || value === "") {
                              if (value.length > 5) {
                                event.currentTarget.classList.remove("error");
                                return false;
                              }
                              setObj(event, value, obj, keypoint);
                            } else {
                              event.preventDefault();
                            }
                          }
                        }}
                      ></Form.Control>
                    )}
                    <div
                      className={
                        keypoint.type === "%" ||
                        keypoint.type === "$" ||
                        keypoint.type === "up" ||
                        keypoint.type === "down" ||
                        keypoint.type === "between"
                          ? keypoint.start.toString().length > 3
                            ? "info-attach-file dollarTooltip"
                            : "info-attach-file"
                          : "info-attach-file"
                      }
                    >
                      <h5>{typeTooltip}</h5>
                    </div>
                  </Form.Group>
                </div>
              </div>
              {keypoint.weightage && (
                <div className={[style.weightage_detail, "d-inline-block"].join(" ")}>
                  Weightage : {keypoint.weightage}
                </div>
              )}
              {keypoint.stretch && (
                <div className={[style.weightage_detail, "d-inline-block ml-3"].join(" ")}>
                  This is the stretch key result
                </div>
              )}
            </div>
          );
        })
      ) : (
        <h3>No data found.</h3>
      );

    let flag = false;
    if (obj.percentage === 100 && !props.editable && obj.completionDate === null) {
      flag = true;
    }

    let indicatorflag = true;
    if (props.page === "checkin") {
      if (props.editable) {
        indicatorflag = false;
      } else {
        indicatorflag = true;
      }
    } else {
      indicatorflag = false;
    }
    return (
      <Card key={index} className="overflow-visible">
        <Card.Header>
          <div className={style.employeeObjectives_process_indicate}>
            <ObjectiveUserStatus objective={obj} page={props.page} editable={props.editable} />
          </div>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={`${index}`}
            onClick={(e) => {
              props.setEventValue(index);
              if (e.currentTarget.classList.contains("show")) {
                e.currentTarget.classList.remove("show");
              } else {
                e.currentTarget.classList.add("show");
              }
            }}
            className={[props.eventValue === index && "show", !indicatorflag && "indicator_manager_view"].join(" ")}
          >
            <div className="accordion-heading">
              <div className="accordion-heading-left">
                <div className={style.employeeObjectives_heading_wrapper}>
                  <h3>{obj.title}</h3>
                  {/* here we need to provide dynamic weightage which we required from BE side */}
                  <h4>{obj?.keyPoints?.length} Key Results are aligned | weightage: {obj.weightage? obj.weightage + "%" : "-"}</h4>
                  {obj.stretchGoal === "Yes" && <h4>This is a Stretch Objective</h4>}
                </div>
              </div>
              <div className="accordion-heading-right">
                <div className={style.employeeObjectives_process_complete}>
                  {props.page !== "checkin" && (
                    <div
                      className={[
                        style.system_objective_status,
                        obj.percentage >= 66
                          ? style.green
                          : obj.percentage <= 65 && obj.percentage > 33
                          ? style.yellow
                          : style.red,
                      ].join(" ")}
                    ></div>
                  )}
                  {obj.percentage}%
                </div>
                <div className={style.employeeObjectives_end_days}>
                  {obj.completionDate ? (
                    <div>Completed</div>
                  ) : (
                    <div>
                      {" "}
                      {Math.sign(moment(obj.endDate).subtract(1, "days").diff({}, "days")) === -1
                        ? `Ended ${moment().diff(moment(new Date(obj.endDate)).subtract(1, "days"), "days")} days ago`
                        : moment(obj.endDate).diff({}, "days") === 0
                        ? "Ends in today"
                        : `Ends in ${moment(obj.endDate).diff({}, "days")} Days`}{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="accordion-icon">
              <ReactSVG src={`${IC_ACC_ARROW_DOWN}`} />
            </div>
          </Accordion.Toggle>
          {props.errorFlag && obj.progressStatus === null ? (
            <div className={style.objective_error_box}>
              <span>Action Required!</span>
            </div>
          ) : null}
        </Card.Header>
        <Accordion.Collapse eventKey={`${index}`}>
          <Card.Body>
            <div className={style.employeeObjectives_info_list}>{innerHTML}</div>
            <EmployeeObjectiveComment
              user={props.user}
              objective={obj}
              page={props.page}
              surveyCycle={props.surveyCycle}
              style={style}
              managerView={props.managerView}
              managerRole={props.managerRole}
              editable={props.editable}
            />
          </Card.Body>
        </Accordion.Collapse>
        {flag && (
          <div className={style.complete_objective_notify}>
            <span>
              <ReactSVG src={`${IC_NOTIFY}`} />
            </span>
            <p>
              It&apos;s great that you have completed this Objective. Your manager {obj.managerData.empName} will be
              Notified when you Submit your okr progress.
            </p>
          </div>
        )}
      </Card>
    );
  });
  return objectiveComponent;
}
