import React from "react";
import PropTypes from "prop-types";
import DateAndTimeSelection from "../../Components/DateAndTimeSelection";
import ThirdPersonSelect from "../../Components/ThirdPersonSelect";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import { Button } from "react-bootstrap";
import InfoCardWithOl from "../../../InfoCard/InfoCardWithOl";

function UserTakeAction({
  isEdit,
  handleStartTime,
  blockTimes,
  handleEndTime,
  startTime,
  endTime,
  handleStartDate,
  startDate,
  selectedThirdPerson,
  loggedInUser,
  oneOnOneAgendaId,
  managerList,
  scheduleOneOnOne,
  isPage,
  oneOnOne,
  timezoneList,
  timezone,
  setTimezone,
  isRegisterInTeams,
}) {
  return (
    <div>
      <div className={["box pt-0 pb-0 radio-wrapper"].join(" ")}>
        <DateAndTimeSelection
          blockTimes={blockTimes}
          timezone={timezone}
          setTimezone={setTimezone}
          timezoneList={timezoneList}
          selectQuestion="discuss"
          oneOnOne={oneOnOne}
          isPage={isPage}
          isEdit={isEdit}
          handleStartTime={handleStartTime}
          handleEndTime={handleEndTime}
          startTime={startTime}
          endTime={endTime}
          handleStartDate={handleStartDate}
          startDate={startDate}
        />
        {managerList && (
          <ThirdPersonSelect
            isEdit={isEdit}
            selectedThirdPerson={selectedThirdPerson}
            loggedInUser={loggedInUser}
            oneOnOneAgendaId={oneOnOneAgendaId}
            managerList={managerList}
          />
        )}
      </div>
      {!isRegisterInTeams && (
        <InfoCardWithOl
          heading={`Hi ${loggedInUser?.displayName}, We have observed that you have not register yourself in Engage Bot. To make your journey easy we
        recommend you to register yourself in Bot. So in future, you can get update of your scheduled 1-on-1 in
        Microsoft Teams & Outlook`}
          description="In order to receive the Notification, kindly follow the steps mentioned below"
        />
      )}
      <div className={["box box-flex-center", style.saveBox].join(" ")}>
        <div className={style.save_box_left}>
          <Button className="btn btn-primary" onClick={scheduleOneOnOne}>
            {isPage === "previous" ? "Re-Schedule" : "Schedule"}
          </Button>
        </div>
      </div>
    </div>
  );
}

UserTakeAction.propTypes = {
  isPage: PropTypes.string,
};

export default UserTakeAction;
