import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import moment from "moment";
import * as actions from "store/actions/index";
import { moduleView } from "util/utils";
import config from "util/config";
import AuthContext from "context/authContext";
import Spinner from "shared/Spinner/Spinner";
import GrowQuestion from "components/GrowQuestion/GrowQuestion";
import Savebox from "../save-box/savebox";
import Plans from "../Plans/Plans";
import style from "./questions.module.scss";
import EmployeeObjectives from "../employeeObjectives/employeeObjectives";
import CheckInQuestion from "./CheckInQuestion";
import { errorToast, requiredFieldMissing, successToast } from "util/general";
import FeelingBox from "components/feeling-box/feelingboxPastHistory";
import Pulse from "components/Pulse/pulse";

export default function Questions(props) {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const employeeId = context.state.user.employeeId;
  const employeeIds = {
    employeeId: context.state.user?.employeeId,
    parentGroupId: context.state.user?.parentGroupId,
    subGroupId: context.state.user?.subGroupId,
  };
  const [errors, setErrors] = useState([]);
  const { questionTemplateErrors } = useSelector((state) => state.performance);
  const {
    questionsAnswer,
    questionTemplate,
    surveyCycleId,
    reviewedBy,
    submitedDate,
    reviewedDate,
    surveyCycle,
    submitCycle,
    isSubmited,
    status,
  } = useSelector((state) => state.questions);

  const validationErrors = useSelector((state) => state.checkIn.validationErrors);
  const { plans, updatedDate, submitCycleResponse, objectives, isCheckInSubmitLoading, planOnChangeError } =
    useSelector((state) => state.checkIn);

  const managerRole = moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.MY_TEAM) ? true : false;
  // componentDidMount
  useEffect(() => {
    let errorsArr = [...errors];
    let error = null;
    dispatch(actions.SetValidationErrors([]));
    if (surveyCycleId) {
      dispatch(actions.GetPlans(surveyCycleId));
      dispatch(actions.FetchPreviousPlans(surveyCycleId));
      // dispatch(actions.GetOneOnOneAgenda(surveyCycleId))
    }
    questionsAnswer &&
      questionsAnswer.forEach((item) => {
        if (item.type !== "RATING" && !item.answer && item.required) {
          error = { qId: item.queId, showError: false };
          errorsArr.push(error);
          setErrors(errorsArr);
          dispatch(actions.SetValidationErrors(errorsArr));
        }
      });
  }, [questionsAnswer]);

  useEffect(() => {
    if (surveyCycleId) dispatch(actions.GetOneOnOneAgenda(surveyCycleId));
  }, [surveyCycleId]);

  useEffect(() => {
    if (submitCycleResponse) {
      if (submitCycleResponse.success) {
        successToast("Data has been submitted.");
        dispatch({ type: "REMOVE_CHECKIN_RESPONSE" });
      }
    }
  }, [submitCycleResponse]);

  const textAnswerHandler = (qAnswer, qId, required) => {
    let errors = [...validationErrors];
    let error = null;
    if (required && !qAnswer) {
      error = { qId: qId, showError: true };
      let index = errors.findIndex((x) => x.qId === qId);
      index === -1 ? errors.push(error) : (errors[index].showError = true);
      setErrors(errors);
      dispatch(actions.SetValidationErrors(errors));
      dispatch(actions.UpdateCurrentCheckIn(qAnswer, qId, employeeIds, surveyCycleId ? surveyCycleId : props.currentSurveyCycleID, false));
    } else {
      error = validationErrors.filter((item) => item.qId !== qId);
      setErrors(error);
      dispatch(actions.SetValidationErrors(error));
      dispatch(actions.UpdateCurrentCheckIn(qAnswer, qId, employeeIds, surveyCycleId ? surveyCycleId : props.currentSurveyCycleID, false));
    }
  };

  const saveQuestionAnswer = (data) => {
    dispatch(actions.AutoSaveCheckinTemplate(data, surveyCycleId, questionTemplate.questionTemplateId));
  };

  const ratingAnswerHandler = (rating, qId) => {
    const error = validationErrors.filter((item) => item.qId !== qId);
    setErrors(error);
    dispatch(actions.SetValidationErrors(error));
    dispatch(actions.UpdateCurrentCheckIn(rating, qId, employeeIds, surveyCycleId, false));
  };

  const ratingCommentHandler = (event, qId) =>
    dispatch(actions.UpdateCurrentCheckIn(event.target.value.trim(), qId, employeeIds, surveyCycleId, true));

  const saveAnswersHandler = () => {
    dispatch({
      type: "SET_QUESTION_TEMPLATE_VALIDATION",
      data: questionTemplateErrors,
    });
    if (validationErrors.length > 0) {
      const submitedErrors = validationErrors.map((err) => ({
        ...err,
        showError: true,
      }));
      dispatch(actions.SetValidationErrors(submitedErrors));
      requiredFieldMissing();
      return;
    } else if (plans && plans.length === 0 && planOnChangeError) {
      errorToast("Minimum one plan is required");
      return;
    } else if (objectives && objectives.length !== 0) {
      let flag = false;
      let objectiveData = objectives
        .filter((obj) => obj.status !== "DRAFTED" && obj.status !== "REJECTED")
        .filter((obj) => {
          // let disableEndObjective = Math.sign(moment(obj.endDate).subtract(1, "days").diff({}, "days")) === -1
          if (surveyCycle) {
            if (obj.percentage === 100 && obj.completionDate !== null) {
              if (
                moment(obj.completionDate) <= moment(surveyCycle.endDate) &&
                moment(obj.completionDate) >= moment(surveyCycle.startDate)
              ) {
                if (moment(obj.completionDate) <= moment(surveyCycle.startDate)) {
                  return false;
                } else {
                  return true;
                }
              } else {
                if (moment(obj.completionDate) <= moment(surveyCycle.startDate)) {
                  return false;
                } else {
                  return true;
                }
              }
            } else {
              if (moment(surveyCycle.startDate) <= moment(obj.endDate)) {
                return true;
              } else {
                return false;
              }
            }
          } else {
            return false;
          }
        });
      objectiveData.map((obj) => {
        if (obj.progressStatus === null) {
          flag = true;
        }
        return obj;
      });
      dispatch(actions.SetObjectiveErrors(flag));
      if (flag) {
        errorToast("Please select progress indicator for every objective");
        return;
      }
    } else if (
      questionTemplateErrors?.errors?.length > 0 &&
      questionTemplateErrors.errors.filter((value) => value.error || value.commentBoxError).length > 0
    ) {
      requiredFieldMissing();
      return;
    }
    if (props.isSubmitAPI === "checkinFromHistroy") {
      dispatch(actions.SaveCheckInAnswersForHistory(props?.currentSurveyCycleID, employeeIds));
    }
    else {
      dispatch(actions.SaveCheckInAnswers(surveyCycleId, employeeIds));
    }
  };

  const onPrivateMessage = (value, queId) => {
    let error;
    let errors = [...validationErrors];

    let optionValue = document.getElementById("reviewer_select_" + queId).value;
    let selectedReviewer = optionValue ? JSON.parse(optionValue) : null;
    if (!selectedReviewer) return true;
    let whoCanView = [
      {
        employeeId: selectedReviewer.employeeId,
        empName: selectedReviewer.empName,
        displayPicture: selectedReviewer.displayPicture || selectedReviewer.empInitial,
        parentGroupId: selectedReviewer.parentGroupId,
        subGroupId: selectedReviewer.subGroupId,
      },
    ];
    let commentedBy = {
      employeeId,
      empName: context.state.user.displayName,
      displayPicture: context.state.user.displayPicture || context.state.user.empInitial,
      parentGroupId: context.state.user.parentGroupId,
      subGroupId: context.state.user.subGroupId,
    };
    let data = {
      surveyCycleId,
      employeeId: {
        ...employeeIds,
      },
      updateQuestionAnswer: {
        queId,
        privateMessage: {
          comment: value.trim(),
          commentedBy,
          whoCanView,
          private: true,
        },
      },
    };
    if (value.trim().length < 1) {
      error = { cId: queId, showError: true };
      let index = errors.findIndex((x) => x.cId === queId);
      index === -1 ? errors.push(error) : (errors[index].showError = true);
      setErrors(errors);
      dispatch(actions.SetValidationErrors(errors));
      dispatch(actions.SendPrivateMessage(data));
      return;
    }
    error = validationErrors.filter((item) => item.cId !== queId);
    setErrors(error);
    dispatch(actions.SetValidationErrors(error));
    if (!optionValue || optionValue.length === 0) errorToast("Please select reviewer");

    dispatch(actions.SendPrivateMessage(data));
  };

  const getQuestions = (isManager) => (
    <Form>
      {isCheckInSubmitLoading && <Spinner />}
      {!isSubmited && !props.isCurrentCheckin && !props.isPastHistory && <FeelingBox isPastHistory={true} user={props.user} moodoMeter={props.moodoMeter} currentSurveyCycleID={props.currentSurveyCycleID} />}
      {isSubmited && !props.isCurrentCheckin && !props.isPastHistory &&
        <Pulse
          managerView={false}
          selectedColor={props.selectedColor}
          status={status}
          question={questionsAnswer && questionsAnswer.filter((value) => value.type === "MOODOMETER")[0]}
        />
      }
      <EmployeeObjectives
        surveyCycleId={surveyCycleId ? surveyCycleId : props.currentSurveyCycleID}
        page={props.isPage}
        submitedDate={submitedDate}
        managerView={isManager}
        managerRole={managerRole}
      />
      <CheckInQuestion
        questionTemplate={questionTemplate}
        questionsAnswer={questionsAnswer}
        saveQuestionAnswer={saveQuestionAnswer}
        heading="Progress &amp; Problem"
        description={
          isManager
            ? "Have a quick look at what your Team Member have answered for this OKR Progress."
            : isSubmited || reviewedDate
              ? "Have a quick look at what you have answered for this OKR Progress."
              : "Answer all required questions with proper data points."
        }
        isManager={isManager}
        managerRole={managerRole}
        isPage={props.isPage}
        submitedDate={submitedDate}
        submitCycle={submitCycle}
        childEmployee={props.childEmployee}
        textAnswerHandler={textAnswerHandler}
        onPrivateMessage={onPrivateMessage}
        ratingAnswerHandler={ratingAnswerHandler}
        ratingCommentHandler={ratingCommentHandler}
        questionTemplateErrors={questionTemplateErrors}
        isSubmitted={submitedDate ? true : false}
        uploadFile={() => { }}
        downloadFile={() => { }}
        deleteFile={() => { }}
        checkinQuestionData={props.checkinQuestionData}
        managerCommentHandle={props.managerCommentHandle}
        deleteComment={props.deleteComment}
      />
      {isManager ? (
        <GrowQuestion edit={true} selectedColor={props.selectedColor} />
      ) : (
        status === "REVIEWED" && <GrowQuestion edit={false} />
      )}
      <Plans
        employeeId={employeeId}
        employeeIds={employeeIds}
        managerView={false}
        isPage={props.isPage}
        edit={props.isPage === "checkin" && !submitedDate ? true : false}
        surveyCycleId={surveyCycleId ? surveyCycleId : props.currentSurveyCycleID}
        isPast={props?.isPast}
      />
      {props.isPage === "checkin" && !submitedDate &&
        <div className={["box", style.noteBox].join(" ")}>
          <span>Note: &nbsp; </span>
          <span>
            Once monthly OKR Progress is submitted to the manager for review, it cannot be modified or re-opened for current month.
          </span>
        </div>
      }
      {!isManager && (
        <Savebox
          managerView={false}
          draftSavedDate={context.draftSavedDate}
          updatedDate={updatedDate}
          submitedDate={submitedDate}
          reviewedDate={reviewedDate}
          surveyCycleId={surveyCycleId}
          saveAnswersHandler={() => saveAnswersHandler()}
          reviewedBy={reviewedBy}
        />
      )}
    </Form>
  );

  return getQuestions(props.managerView);
}
