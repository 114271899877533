import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./DeallocationFeedback.module.scss";
import { backGroundColor } from "const/constValue";
import { IC_COLLAPSE, IC_EXPAND, IC_EYE, IMG_CHECKINS_LAPSED } from "const/imgCost";
import { stringToDateFormat1 } from "util/dateUtils";
import { getShortName } from "util/general";
import QuestionTemplate from "../../Performance/CycleMemberProfile/QuestionTemplate/QuestionTemplate";
import CommentsTop from "../../CommentBox/CommentsTop";
import Comment from "../../CommentBox/Comment";
import NoRecordFound from "../../NodataFound/NoRecordFound";

function DeallocationMemberRow({
  empInfo,
  index,
  handleView,
  isOneEmployee,
  isOneEmpFromMultipleProject,
  downloadFile,
}) {
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => state.objectives);
  useEffect(() => {
    dispatch({ type: "EXPANDED", expanded: null });
  }, [dispatch]);

  const toggleExpander = (id) => {
    expanded === id ? dispatch({ type: "EXPANDED", expanded: null }) : dispatch({ type: "EXPANDED", expanded: id });
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>Marked as Non Applicable</Popover.Content>
    </Popover>
  );

  return (
    <>
      <li className={style.list_item}>
        <div className={style.plus_space} onClick={() => toggleExpander(empInfo.projectDeAllocationId)}>
          {empInfo.other || isOneEmpFromMultipleProject ? (
            expanded === empInfo.projectDeAllocationId ? (
              <ReactSVG src={`${IC_COLLAPSE}`} />
            ) : (
              <ReactSVG src={`${IC_EXPAND}`} />
            )
          ) : (
            ""
          )}
        </div>
        <div className={style.tab_team_member_info}>
          <div className={style.team_member_info}>
            <div
              className={style.team_member_img}
              style={{
                backgroundColor: backGroundColor[index % backGroundColor.length],
              }}
            >
              {empInfo.employee.displayPicture ? (
                <img src={empInfo.employee.displayPicture} alt={empInfo.employee.empInitial} />
              ) : (
                empInfo.employee.empInitial
              )}
            </div>
            <div className={style.team_member_details}>
              <div className={style.team_member_name}>{empInfo.employee.empName}</div>
              <div className={style.team_member_designation}>
                {empInfo.employee.designation} | {empInfo.employee.department}
              </div>
            </div>
          </div>
        </div>
        <div className={[style.tab_manager_name, style.managerTooltip].join(" ")}>
          <span>{empInfo.projectManager?.empName && getShortName(empInfo.projectManager?.empName)}</span>
          <div className={style.managerTooltipInfo}>{empInfo.projectManager?.empName}</div>
        </div>
        <div className={style.tab_project_name}>
          <div className={style.project_name}>
            {empInfo.projectName?.length > 0 && empInfo.projectName.map((v) => v.projectName).join(", ")}
          </div>
          <div className={style.duration}>
            {empInfo.projectName?.length > 0 &&
              empInfo.projectName.map((v) => v.projectWorkingDays).join(", ") + " Days"}
          </div>
        </div>
        <div className={style.tab_location}>{empInfo.location}</div>
        <div className={style.tab_date}>
          {empInfo.projectName?.length > 0 &&
            empInfo.projectName[0].deallocationDate &&
            stringToDateFormat1(empInfo.projectName[0].deallocationDate)}
        </div>
        <div className={style.st_wrapper}>
          {(() => {
            switch (empInfo.status) {
              case "PENDING":
                return <span className={style.status_pending}>Pending</span>;
              case "SUBMIT":
                return <span className={style.status_submitted}>Submitted</span>;
              case "INVALIDATE":
                return (
                  <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
                    <span className={style.status_submitted_NA}>Submitted</span>
                  </OverlayTrigger>
                );
              default:
                return <></>;
            }
          })()}
        </div>
        {!isOneEmployee && (
          <div className={style.action_wrapper} onClick={() => handleView(empInfo)}>
            <ReactSVG src={`${IC_EYE}`} />
          </div>
        )}
      </li>
      {/* if one employee deallocate from two time or more time in same project then below part work */}
      {expanded === empInfo.projectDeAllocationId &&
        empInfo.other &&
        empInfo.other.map((emp) => (
          <li className={style.internal_list_item} key={emp.projectDeAllocationId}>
            <div className={style.plus_space}></div>
            <div className={style.tab_team_member_info}></div>
            <div className={[style.tab_manager_name, style.managerTooltip].join(" ")}>
              <span>{emp.projectManager?.empName && getShortName(emp.projectManager?.empName)}</span>
              <div className={style.managerTooltipInfo}>{emp.projectManager?.empName}</div>
            </div>
            <div className={style.tab_project_name}>
              <div className={style.project_name}>
                {emp.projectName?.length > 0 && emp.projectName.map((v) => v.projectName).join(", ")}
              </div>
              <div className={style.duration}>
                {emp.projectName?.length > 0 && emp.projectName.map((v) => v.projectWorkingDays).join(", ") + " Days"}
              </div>
            </div>
            <div className={style.tab_location}>{emp.location}</div>
            <div className={style.tab_date}>
              {emp.projectName?.length > 0 &&
                emp.projectName[0].deallocationDate &&
                stringToDateFormat1(emp.projectName[0].deallocationDate)}
            </div>
            <div className={style.st_wrapper}>
              {(() => {
                switch (emp.status) {
                  case "PENDING":
                    return <span className={style.status_pending}>Pending</span>;
                  case "SUBMIT":
                    return <span className={style.status_submitted}>Submitted</span>;
                  case "INVALIDATE":
                    return (
                      <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
                        <span className={style.status_submitted}>Submitted</span>
                      </OverlayTrigger>
                    );
                  default:
                    return <></>;
                }
              })()}
            </div>
            <div className={style.action_wrapper} onClick={() => handleView(emp)}>
              <ReactSVG src={`${IC_EYE}`} />
            </div>
          </li>
        ))}

      {/* When MMG search single person then display below part direct to view part */}
      {((isOneEmpFromMultipleProject && expanded === empInfo.projectDeAllocationId) ||
        (!isOneEmpFromMultipleProject && isOneEmployee)) &&
        (empInfo.status === "PENDING" ? (
          <div className={style.no_record_found_box}>
            <div className={style.mHeader}>
              <div className={style.mTitle}>
                Project Feedback -{" "}
                {empInfo.projectName?.length > 0 && empInfo.projectName.map((v) => v.projectName).join(", ")}
                <span>
                  {empInfo.projectName?.length > 0 &&
                    empInfo.projectName
                      .map((v) => {
                        return v.startDate && v.endDate
                          ? `${stringToDateFormat1(v.startDate)} - ${stringToDateFormat1(v?.endDate)}`
                          : "";
                      })
                      .join(", ")}
                </span>
              </div>
            </div>

            <div className="box-light-gradient"></div>
            <div className={style.mBody}>
              <div className={style.no_record_wrapper}>
                <NoRecordFound
                  title="Oops! No records found"
                  description="The data is not yet found"
                  image={IMG_CHECKINS_LAPSED}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            {empInfo?.comment && (
              <li className={style.not_applicable_comment_wrapper}>
                <div className={[style.comment_wrapper, "view_comment_box_wrapper", "bg-white"].join(" ")}>
                  <CommentsTop heading="Reviewer's Comment(s)" />
                  <Comment
                    selectedColor={backGroundColor[5]}
                    image={empInfo.projectManager.displayPicture || empInfo.projectManager.empInitial}
                    empName={empInfo.projectManager.empName}
                    commentedOn={empInfo.updatedDate}
                    comment={empInfo.comment}
                    isPrivate={false}
                    deletable={false}
                  />
                </div>
              </li>
            )}
            <li className={style.question_template_wrapper}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>
                    Project Feedback:{" "}
                    {empInfo.projectName?.length > 0 && empInfo.projectName.map((v) => v.projectName).join(", ")}
                  </h2>
                  <h3>
                    {empInfo.projectName?.length > 0 &&
                      empInfo.projectName
                        .map((v) => {
                          return v.startDate && v.endDate
                            ? `${stringToDateFormat1(v.startDate)} - ${stringToDateFormat1(v.endDate)}`
                            : "";
                        })
                        .join(", ")}
                  </h3>
                </div>
              </div>
              <QuestionTemplate
                isManagerSubmitted={true}
                viewTemplateName={false}
                handleTextBox={() => {}}
                handleRating={() => {}}
                handleRatingComment={() => {}}
                handleRadioComment={() => {}}
                handleRadio={() => {}}
                handleRadioOther={() => {}}
                handleCheckBox={() => {}}
                handleCheckBoxOther={() => {}}
                handleCheckBoxComment={() => {}}
                handleDropDown={() => {}}
                handleDropDownComment={() => {}}
                handleDropDownOther={() => {}}
                handleScale={() => {}}
                handleScaleComment={() => {}}
                handleRankComment={() => {}}
                handleRank={() => {}}
                handleNps={() => {}}
                handleNpsComment={() => {}}
                isSubmitted={true}
                questionsAnswerTemplate={empInfo.deallocationquestionTemplate}
                uploadFile={() => {}}
                downloadFile={(fileId, questionId) => downloadFile(fileId, questionId, empInfo.projectDeAllocationId)}
                deleteFile={() => {}}
              />
            </li>
          </>
        ))}
    </>
  );
}

export default DeallocationMemberRow;
