import React, { useState } from 'react'
import style from "../AssignObjectives/AssignObjectives.module.scss"
import { Row  } from "react-bootstrap";
import ActivityRow from "./ActivityRow"

export default function ObjectiveActivities(props){
  const [hideActivities, setHideActivities] = useState(false)
  const currentCount = props.activities? props.activities.length :0;

  const [showScroll, setShowScroll] = useState(false);
  const showScrollClass = showScroll ? "show-scroll" : "";

  const loadMore = () => {
    setShowScroll(!showScroll);
  };
  return (
    <div className={["box pb-0", style.activites_wrapper].join(" ")}>
      <div className="box-heading-wrapper">
          <div className="box-heading">
              <h2>Activities</h2>
              {/* <h3>Some text goes here, will be updated soon</h3> */}
          </div>
          <div className="box-info">
              <span onClick={() => setHideActivities(!hideActivities)}>{hideActivities ? "Show" :  "Hide"}</span>
          </div>
      </div>
      {!hideActivities  && <div className="box-light-gradient h38"></div>}
      
      {!hideActivities 
      && <div className="box-inner-pad">
          <Row>
          <div className="col-xl-12">
            <div className="form-group">
              <div className={style.activites_data_wrapper}>
                <div className={["activities",showScrollClass].join(" ")}>
                {props.activities 
                  && props.activities.filter((activity, index) => showScroll || index < 4)
                            .map((activity) => <ActivityRow activity={activity} key={activity.activityId} user={props.user} handleRejectMessagePopup={props.handleRejectMessagePopup} />)}
                </div>
                {currentCount > 4 ? (
                  <span className="viewall" onClick={loadMore}>
                    {showScroll ? "Show Less" :  `Show More(${currentCount - 4})`}
                  </span>
                ) : (
                  ""
                )}
                </div>    
            </div>
          </div>
          </Row>
      </div>
      }
  </div>
  )
}