import React from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import { IC_SUBMIT_BTN } from "../../../const/imgCost";
import { ReactSVG } from "react-svg";

function AddTalkingPointBox(props) {
  const { image, placeholder, comment, handleComment, sendTalkingPoint, disabled } = props;
  return (
    <div className="view_comment_box_row">
      <div className="comment_box_wrapper fg-comment-wrapper">
        <div className="user_img">{image && image.length > 2 ? <img src={image} alt="" /> : image}</div>
        <div className="comment_area_wrapper">
          <Form.Group className="form_group_comment mb-0">
            <div className="textarea-wrapper">
              <Form.Control
                disabled={disabled}
                as="textarea"
                maxLength="1000"
                placeholder={placeholder}
                className="manager_comments"
                value={comment}
                onChange={(e) => handleComment(e)}
              />
            </div>
            <Button>
              <ReactSVG className="svg" src={`${IC_SUBMIT_BTN}`} alt="info icon" onClick={sendTalkingPoint} />
            </Button>
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

AddTalkingPointBox.propTypes = {
  image: PropTypes.string,
  placeholder: PropTypes.string,
  comment: PropTypes.string,
  handleComment: PropTypes.func,
  sendTalkingPoint: PropTypes.func,
  disabled: PropTypes.bool,
};

export default AddTalkingPointBox;
