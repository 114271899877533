import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "store/actions/index";
import { CLEAR_DRAG_DROP_RESPONSE } from "store/actions/actionTypes";
import AuthContext from "context/authContext";
import Wins from "./Wins/Wins";
import Challenges from "./Challenges/Challenges";
import Collectives from "./Collectives/Collectives";
import ModalsPopup from "../ModalsPopup/ModalsPopup";
import { errorToast, successToast } from "util/general";

const WinChallenges = (props) => {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const managerId = context.state.user.employeeId;
  const [filterWin, setFilterWin] = useState(3);
  const [filterChallenge, setFilterChallenge] = useState(3);
  const [filterCollective, setFilterCollective] = useState(3);
  const { winResponse, challengeResponse, collectiveResponse, dragDropWinChallengeResponse } = useSelector(
    (state) => state.winChallenge
  );
  const [dialog, setDialog] = useState(false);
  const [winChallenge, setWinChallenge] = useState(false);

  const onMoveWinChallenge = (winChallengeId, challengeType, fromType = null) => {
    const manager = context.state.user;
    const updatedBy = {
      employeeId: managerId,
      empName: manager.displayName,
      displayPicture: manager.displayPicture || manager.empInitial,
      designation: manager.designation,
      parentGroupId: manager.parentGroupId,
      subGroupId: manager.subGroupId,
    };
    const fetchData = {
      managerId,
      filterByMonth: 3,
      ...props.employeeIds,
    };
    dispatch(actions.dragDropWinChallenge(winChallengeId, updatedBy, fetchData, challengeType, fromType));
  };

  useEffect(() => {
    if (dragDropWinChallengeResponse) {
      let { status, message } = dragDropWinChallengeResponse;
      if (status === "SUCCESS") {
        successToast(message);
      } else {
        errorToast(message);
      }
      dispatch({ type: CLEAR_DRAG_DROP_RESPONSE });
    }
  }, [dragDropWinChallengeResponse]);

  useEffect(() => {
    dispatch(actions.FetchWinChallenge(props.employeeIds, {}, filterWin, "WIN"));
  }, [filterWin]);

  useEffect(() => {
    dispatch(actions.FetchWinChallenge(props.employeeIds, {}, filterChallenge, "CHALLENGE"));
  }, [filterChallenge]);

  useEffect(() => {
    dispatch(actions.FetchWinChallenge(props.employeeIds, {}, filterCollective, "COLLECTIVE"));
  }, [filterCollective]);

  let dragStart, mouseDown, dragEnter, dragLeave, dragOver, dragEnd;

  const addDragAndDropEvents = () => {
    if (!document.querySelectorAll || !("draggable" in document.createElement("span")) || window.opera) return;
    for (
      var targets = document.querySelectorAll('[data-draggable="target"]'), len = targets.length, i = 0;
      i < len;
      i++
    ) {
      targets[i].setAttribute("aria-dropeffect", "none");
    }
    for (
      var items = document.querySelectorAll('[data-draggable="item"]'), itemLen = items.length, k = 0;
      k < itemLen;
      k++
    ) {
      items[k].setAttribute("draggable", "true");
      items[k].setAttribute("aria-grabbed", "false");
      items[k].setAttribute("tabindex", i);
    }

    var selections = { items: [], owner: null, droptarget: null };

    //function for selecting an item
    function addSelection(item) {
      if (!selections.owner) selections.owner = item.parentNode;
      else if (selections.owner !== item.parentNode) return;

      //set this item's grabbed state
      item.setAttribute("aria-grabbed", "true");

      //add it to the items array
      selections.items.push(item);
    }
    //function for resetting all selections
    function clearSelections() {
      if (selections.items.length) {
        selections.owner = null;

        for (var len = selections.items.length, i = 0; i < len; i++) {
          selections.items[i].setAttribute("aria-grabbed", "false");
        }

        selections.items = [];
      }
    }

    //shorctut function for testing whether a selection modifier is pressed
    function hasModifier(e) {
      return e.ctrlKey || e.metaKey || e.shiftKey;
    }

    //function for applying dropeffect to the target containers
    function addDropeffects() {
      for (var len = targets.length, i = 0; i < len; i++) {
        if (targets[i] !== selections.owner && targets[i].getAttribute("aria-dropeffect") === "none") {
          targets[i].setAttribute("aria-dropeffect", "move");
          targets[i].setAttribute("tabindex", i);
        }
      }

      //remove aria-grabbed and tabindex from all items inside those containers
      for (var itemsLen = items.length, j = 0; i < itemsLen; j++) {
        if (items[j].parentNode !== selections.owner && items[j].getAttribute("aria-grabbed")) {
          items[j].removeAttribute("aria-grabbed");
          items[j].removeAttribute("tabindex");
        }
      }
    }

    //function for removing dropeffect from the target containers
    function clearDropeffects() {
      if (selections.items.length) {
        for (let len = targets.length, i = 0; i < len; i++) {
          if (targets[i].getAttribute("aria-dropeffect") !== "none") {
            targets[i].setAttribute("aria-dropeffect", "none");
            targets[i].removeAttribute("tabindex");
          }
        }

        for (let len = items.length, i = 0; i < len; i++) {
          if (!items[i].getAttribute("aria-grabbed")) {
            items[i].setAttribute("aria-grabbed", "false");
            items[i].setAttribute("tabindex", i);
          } else if (items[i].getAttribute("aria-grabbed") === "true") {
            items[i].setAttribute("tabindex", i);
          }
        }
      }
    }

    //shortcut function for identifying an event element's target container
    function getContainer(element) {
      do {
        if (element.nodeType === 1 && element.getAttribute("aria-dropeffect")) return element;
      } while ((element = element.parentNode));

      return null;
    }

    //mousedown event to implement single selection
    document.addEventListener(
      "mousedown",
      (mouseDown = function (e) {
        if (e.target.getAttribute("draggable")) {
          clearDropeffects();
          if (!hasModifier(e) && e.target.getAttribute("aria-grabbed") === "false") {
            clearSelections();
            addSelection(e.target);
          }
        } else if (!hasModifier(e)) {
          clearDropeffects();
          clearSelections();
        } else clearDropeffects();
      }),
      false
    );

    //dragstart event to initiate mouse dragging
    document.addEventListener(
      "dragstart",
      (dragStart = function (e) {
        if (selections.owner !== e.target.parentNode) return;
        if (hasModifier(e) && e.target.getAttribute("aria-grabbed") === "false") addSelection(e.target);
        e.dataTransfer.setData("text", "");
        addDropeffects();
      }),
      false
    );

    var related = null;
    document.addEventListener(
      "dragenter",
      (dragEnter = function (e) {
        related = e.target;
      }),
      false
    );

    document.addEventListener(
      "dragleave",
      (dragLeave = function () {
        var droptarget = getContainer(related);
        if (droptarget === selections.owner) droptarget = null;

        if (droptarget !== selections.droptarget) {
          if (selections.droptarget)
            selections.droptarget.className = selections.droptarget.className.replace(/ dragover/g, "");
          if (droptarget) droptarget.className += " dragover";
          selections.droptarget = droptarget;
        }
      }),
      false
    );

    //dragover event to allow the drag by preventing its default
    document.addEventListener(
      "dragover",
      (dragOver = function (e) {
        if (selections.items.length) {
          for (let i = 0; i < selections.items.length; i++) {
            e.preventDefault();
          }
        }
      }),
      false
    );

    //dragend event to implement items being validly dropped into targets,
    document.addEventListener(
      "dragend",
      (dragEnd = function (e) {
        if (selections.items.length > 0) {
          clearDropeffects();
          if (selections.droptarget && selections.droptarget != selections.owner) {
            let targetUl = selections.droptarget;
            let winChallengeId = selections.items[0].id;
            let toType = targetUl.id;
            let fromType = selections.owner ? selections.owner.id : "one";
            setWinChallenge({ id: winChallengeId, fromType, toType });
            setDialog(true);
            selections.droptarget.className = selections.droptarget.className.replace(/ dragover/g, "");
            selections.droptarget = null;
            clearSelections();
          }
          e.preventDefault();
        }
      }),
      false
    );
  };

  useEffect(() => {
    try {
      if (props.isPage === "myteam") {
        addDragAndDropEvents();
      }
      return () => {
        document.removeEventListener("mousedown", mouseDown);
        document.removeEventListener("dragstart", dragStart);
        document.removeEventListener("dragenter", dragEnter);
        document.removeEventListener("dragleave", dragLeave);
        document.removeEventListener("dragover", dragOver);
        document.removeEventListener("dragend", dragEnd);
      };
    } catch (error) {
      return () => {
        console.warn("error", error);
      };
    }
  }, [winResponse, challengeResponse, collectiveResponse, props.isPage]);

  return (
    <div className="p30">
      <div className="wins-wrapper" id="WIN_BUCKET">
        <Wins
          winsBucket={winResponse}
          isPage={props.isPage}
          onMoveWinChallenge={onMoveWinChallenge}
          filter={setFilterWin}
          employeeId={props.employeeId}
        />
      </div>
      <div className="challenges-wrapper" id="CHALLENGE_BUCKET">
        <Challenges
          challengesBucket={challengeResponse}
          isPage={props.isPage}
          onMoveWinChallenge={onMoveWinChallenge}
          filter={setFilterChallenge}
          employeeId={props.employeeId}
        />
      </div>
      <div className="collective-wrapper" id="COLLECTIVE_BUCKET">
        <Collectives
          collectiveBucket={collectiveResponse}
          isPage={props.isPage}
          onMoveWinChallenge={onMoveWinChallenge}
          filter={setFilterCollective}
          employeeId={props.employeeId}
        />
      </div>
      <ModalsPopup
        show={dialog}
        hide={() => {
          setDialog(false);
          setWinChallenge(null);
        }}
        fromType={winChallenge && winChallenge.fromType}
        toType={winChallenge && winChallenge.toType}
        submit={() => {
          onMoveWinChallenge(winChallenge.id, winChallenge.toType, winChallenge.fromType);
          setDialog(false);
          setWinChallenge(null);
        }}
      />
    </div>
  );
};

export default WinChallenges;
