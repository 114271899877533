import React from "react";
import CampaignList from "./CampaignList";
import style from "./CampaignList.module.scss";

function CampaignListForSubAdmin() {
  return (
    <div className={style.campaign_list_wrapper}>
      <CampaignList />
    </div>
  );
}

export default CampaignListForSubAdmin;
