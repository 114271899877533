import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import config from "../../util/config";
import { MonthlyCheckinTexts } from "../../const/constValue";
import style from "./MonthlyCheckInPending.module.scss";

export default function MonthlyCheckInPending({ isEmployee,countDurationStatusData }) {
  const history = useHistory();
  const [currentCheckIn,setCurrentCheckIn] = useState();
  const { 
    count,
    period,
    employeeStartDate, 
    employeeEndDate,
    managerStartDate,
    managerEndDate, 
    submissionStatus 
  } = countDurationStatusData;

  useEffect(() => {
    const currentCheckIn = moment(isEmployee ? employeeStartDate : managerStartDate).format(config.dates.shortMonthNameAndDate) + " - " + moment(isEmployee ? employeeEndDate : managerEndDate).format(config.dates.shortMonthNameAndDate) + ", " + moment(isEmployee ? employeeEndDate : managerEndDate).format("YYYY");
    setCurrentCheckIn(currentCheckIn);
  }, [countDurationStatusData]);

  const handleStartButton = () => {
    if(isEmployee){
      history.push("/monthly-checkin/monthly-checkin")
    } else {
      history.push("/monthly-checkin/teams-checkin")
    }
  }

  const renderStatus = (status) => {
    switch (status) {
      case "TOTAL_PENDING":
        return <span>TOTAL PENDING</span>;
      case "PARTIALLY_PENDING":
        return <span>PARTIALLY PENDING</span>;
      case "COMPLETED":
        return <span>COMPLETED</span>;
      default:
        return <></>;
    }
  };

  let styles = null;
  if (submissionStatus === "TOTAL_PENDING") {
    styles = "TOTAL_PENDING";
  } else if (submissionStatus === "PARTIALLY_PENDING") {
    styles = "PARTIALLY_PENDING";
  } else {
    styles = "COMPLETED";
  }
  return (
    <div>
      <div className={"box box-flex-center"}>
        <div className={style.mainWrapper}>
          <div className={style.heading}>
            Monthly check-in pending{" "}
            <span className={style.count}>({count})</span>
          </div>
          <div className={style.textContent}>
            <span className={style.gretTextColor}>{period}&nbsp;{MonthlyCheckinTexts.CHECK_IN_PERIOD}&nbsp;</span>
          </div>
          <div className={style.textContent}>
            <span className={style.gretTextColor}>{MonthlyCheckinTexts.SUBMISSION_DATE}&nbsp;</span>
            &nbsp;{currentCheckIn}
          </div>
          <div className={`${style.team_checkin_status} ${styles} ` }>
          {renderStatus(submissionStatus)}
          </div>
          <div className={style.description}>
            {MonthlyCheckinTexts.NOTE}
          </div>
          {count > 0 && <div className={style.startButton}>
            <button
              type="button"
              onClick={handleStartButton}
              className={"btn btn-primary"}
            >
              <strong>Start</strong>
            </button>
          </div>}
        </div>
      </div>
    </div>
  );
}
