import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export default function RatingBubble(props) {
  const [chars, setChars] = useState(200);
  const [mood, setMood] = useState(props.rating.count);
  const [open, setOpen] = useState(false);
  const [textboxdata, setTextboxdata] = useState(props.mood ? props.mood.employeeComment : "");
  // const { moodoMeter } = useSelector(state => state.checkIn);

  // useEffect(() => setMood(moodoMeter[0]), [moodoMeter]);
  // useEffect(() => {
  //   let leftChars = mood && mood.employeeComment ? 200 - mood.employeeComment.length : 200;
  //   setChars(leftChars);
  // }, [mood]);

  useEffect(() => {
    setMood(props.rating.count);
  }, [props.rating.count]);

  useEffect(() => {
    setTextboxdata(props.mood ? props.mood.employeeComment : "");
  }, [props.mood])

  const onChangeComment = (e, rating) => {
    let input = e.target.value;
    setTextboxdata(input);
    setChars(200 - input.length);
  };

  const openComment = () => {
    setOpen(true);
  };

  return (
    <li key={mood} className={props.currentClass}>
      <Form.Check type="radio">
        <Form.Check.Input type="radio" id={mood} name="rating" />
        <Form.Check.Label htmlFor={mood}>
          <div data-title={props.title} className={"count-wrapper " + props.title}>
            <span className="count" onClick={() => props.onCountClick(mood, "allBuble")}>
              {mood}
            </span>
            <span className="count-text">{props.rating.text}</span>
          </div>

          <div className="outer-panel">
            <div className="panel-body">
              <div className="questions" onClick={openComment}>{props.rating.question1}</div>
              <div className="questions">{props.rating.question2}</div>
              <div className="question-form" style={{ display: open ? 'block' : 'none' }}>
                <div className="form-group">
                  <div className="textarea-wrapper">
                    <textarea
                      id={"rate_comment_" + props.title}
                      className="form-control"
                      placeholder="Add a comment (optional)"
                      onChange={(e) => onChangeComment(e, mood)}
                      onBlur={(e) => props.onReviewComment(e, mood)}
                      maxLength="200"
                      // defaultValue={props.mood ? props.mood.employeeComment : ""}
                      value={textboxdata}
                    ></textarea>
                  </div>
                  <div className="checkin-question-info mt-2"> {chars} Characters Left</div>
                </div>
              </div>
            </div>
          </div>
        </Form.Check.Label>
      </Form.Check>
    </li>
  );
}
