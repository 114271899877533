import React from "react";
import { Link } from "react-router-dom";
import { backGroundColor } from "../../../../const/constValue";
import style from "./ManagerTeamMemberList.module.scss";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { stringToDateFormat1 } from "../../../../util/dateUtils";

function MemberRow({ image, empName, department, designation, status, projectDay, pathName, projectName, date }) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>Marked as Non Applicable</Popover.Content>
    </Popover>
  );

  return (
    <li className={style.list_item}>
      <div className={style.tab_team_member_info}>
        <div className={style.team_member_info}>
          <div
            className={style.team_member_img}
            style={{ backgroundColor: backGroundColor[Math.floor(Math.random() * 6)] }}
          >
            {image.length > 2 ? <img src={image} alt="Display Pic" /> : image}
          </div>
          <div className={style.team_member_details}>
            <Link to={pathName}>
              <div className={style.team_member_name}>{empName}</div>
            </Link>
            <div className={style.team_member_designation}>{designation + " | " + department}</div>
          </div>
        </div>
      </div>
      <div className={style.tab_project_name}>
        <div className={style.project_name}>{projectName}</div>
        <div className={style.duration}>{projectDay && projectDay + " Days"}</div>
      </div>
      <div className={style.tab_date}>{stringToDateFormat1(date)}</div>
      <div className={style.st_wrapper}>
        {(() => {
          switch (status) {
            case "PENDING":
              return <span className={style.status_pending}>Pending</span>;
            case "SUBMIT":
              return <span className={style.status_submitted}>Submitted</span>;
            case "INVALIDATE":
              return (
                <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
                  <span className={style.status_submitted}>Submitted</span>
                </OverlayTrigger>
              );
            default:
              return <></>;
          }
        })()}
      </div>
    </li>
  );
}

export default MemberRow;
