import * as actionTypes from "../actions/actionTypes";

const initialState = {
  addWinChallengeResponse: null,
  winResponse: [],
  challengeResponse: [],
  collectiveResponse: [],
  dragDropWinChallengeResponse: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADD_WIN_CHALLENGE:
      return {
        ...state,
        addWinChallengeResponse: action.addWinChallengeResponse,
      };
    case actionTypes.SET_FETCH_WIN:
      return {
        ...state,
        winResponse: action.wins,
      };
    case actionTypes.SET_FETCH_CHALLENGE:
      return {
        ...state,
        challengeResponse: action.challenges,
      };
    case actionTypes.SET_FETCH_COLLECTIVE:
      return {
        ...state,
        collectiveResponse: action.collectives,
      };
    case actionTypes.DELETE_WIN_CHALLENGE:
      switch (action.challenge.type) {
        case "WIN": {
          let newWinResponse = state.winResponse.filter(
            (item) => item.winAndChallengeId !== action.challenge.winChallengeId
          );
          return { ...state, winResponse: newWinResponse };
        }
        case "CHALLENGE": {
          let newChallengeResponse = state.challengeResponse.filter(
            (item) => item.winAndChallengeId !== action.challenge.winChallengeId
          );
          return { ...state, challengeResponse: newChallengeResponse };
        }
        case "COLLECTIVE": {
          let newCollectiveResponse = state.collectiveResponse.filter(
            (item) => item.winAndChallengeId !== action.challenge.winChallengeId
          );
          return { ...state, collectiveResponse: newCollectiveResponse };
        }
        default:
          return { ...state };
      }
    case actionTypes.SET_DRAG_DROP_WIN_CHALLENGE:
      return { ...state, dragDropWinChallengeResponse: action.dragDropWinChallengeResponse };
    case actionTypes.CLEAR_DRAG_DROP_RESPONSE:
      return { ...state, dragDropWinChallengeResponse: null };
    case actionTypes.CLEAR_ADD_WIN_CHALLENGE_RESPONSE:
      return { ...state, addWinChallengeResponse: null };
    default:
      return state;
  }
};

export default reducer;
