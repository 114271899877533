import { Col, Container, Row } from "react-bootstrap";
import OKRTemplateCreateEdit from "./OKRTemplateCreateEdit";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import OKRTemplateList from "./OKRTemplateList";
import Spinner from "shared/Spinner/Spinner";
import style from "./OKRTemplate.module.scss";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { createPredefinedObjectiveAdminView } from "store/actions";
import storage from "util/storage";
import { updatePredefinedObjective } from "store/actions/okrTemplate";
import { menuText } from "const/constValue";

export default function OKRTemplate() {
  const { isLoading } = useSelector((state) => state.okrTemplate);
  const { pageSize, currentPage } = useSelector((state) => state.myTeam);
  const history = useHistory();
  const isHrView = history.location.pathname.includes("hr");
  let breadCrumbText;
  if (isHrView) {
    breadCrumbText = menuText.HR;
  } else {
    breadCrumbText = menuText.ADMIN;
  }

  const dispatch = useDispatch();
  const logInUser = storage.getUser();
  const listElement = useRef(null);

  const [OKRToEdit, setOKRToEdit] = useState();

  const handleEdit = (data) => {
    setOKRToEdit(data);
  };

  const handleCreate = (data) => {
    dispatch(createPredefinedObjectiveAdminView(data));
  };

  const handleUpdate = (data) => {
    const requestData = {
      title: data.title,
      band: data.band,
      weightage: data.weightage,
      description: data.description,
      objectiveType: data.objectiveType.value
        ? data.objectiveType.value
        : data.objectiveType,
      updatedBy: {
        displayName: logInUser.displayName,
      },
      updatedDate: new Date().toISOString(),
      managerListDTO: data.managerListDTO,
    };
    dispatch(
      updatePredefinedObjective(
        data.bandWiseObjectiveId,
        requestData,
        pageSize,
        currentPage
      )
    );

    if (listElement.current) {
      listElement.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  const handleSubmit = (isEditMode, data) => {
    if (isEditMode) {
      handleUpdate(data);
    } else {
      handleCreate(data);
    }
  };

  return (
    <>
      {isHrView ? (
        <SubNavigation type="Employee Objective" />
      ) : (
        <SubNavigation type="OKR Progress" />
      )}
      <div className={style.okr_template_wrapper}>
        {isLoading && <Spinner />}
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="page-title pb-0 mt-3">{breadCrumbText}</h1>
              <span className="st-breadcrumb">
                OKR Progress
                <span>OKR Template</span>
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <OKRTemplateCreateEdit
                editData={OKRToEdit}
                onSubmit={handleSubmit}
                onClear={() => {
                  setOKRToEdit();
                }}
              />
              <OKRTemplateList onEdit={handleEdit} ref={listElement} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
