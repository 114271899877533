import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Form, Button, Container, Col } from "react-bootstrap";
import moment from "moment";
import style from "./TechnicalRoleChangeRequest.module.scss";
import * as actions from "store/actions/index";
import { IC_ENDORSEMENT_APPROVE, IC_ENDORSEMENT_DECLINE } from "const/imgCost";
import Spinner from "shared/Spinner/Spinner";
import RequestsList from "./RequestsList/RequestsList";
import NoDataFound from "../../NodataFound/NoDataFound";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { errorToast, successToast } from "util/general";

export default function TechnicalRoleChangeRequest() {
  const dispatch = useDispatch();
  const {
    isLoading,
    listRequestChangeForMmg,
    approveRejectRequestForTechnicalRoleChangeMMGSuccess,
    approveRejectRequestForTechnicalRoleChangeMMGError,
  } = useSelector((state) => state.skillMatrix);

  const [requestList, setRequestList] = useState([]);
  const [approveItem, setApproveItem] = useState(false);
  const [declineItem, setDeclineItem] = useState(false);
  const [comment, setUserComment] = useState({ id: null, value: null });
  const [cardId, setCardId] = useState({ id: null, approveflag: false, declineflag: false });
  const setComment = (e, id) => {
    setUserComment({ id: id, value: e.target.value });
  };
  const [otherRequestList, setOtherRequestList] = useState([]);

  useEffect(() => {
    if (listRequestChangeForMmg && listRequestChangeForMmg.length > 0) {
      let pendingRequest = [];
      let otherRequest = [];
      listRequestChangeForMmg.forEach((item) => {
        if (item.status === "PENDING") {
          pendingRequest.push(item);
        } else {
          otherRequest.push(item);
        }
      });
      setRequestList(pendingRequest);
      setOtherRequestList(otherRequest);
    }
  }, [listRequestChangeForMmg]);

  useEffect(() => {
    const getRequestList = () => {
      dispatch(actions.listChangeRequestForMmg);
    };
    getRequestList();
  }, [dispatch]);

  useEffect(() => {
    if (approveRejectRequestForTechnicalRoleChangeMMGSuccess?.success)
      successToast(approveRejectRequestForTechnicalRoleChangeMMGSuccess.message);
    if (approveRejectRequestForTechnicalRoleChangeMMGError?.error)
      errorToast(approveRejectRequestForTechnicalRoleChangeMMGError.message);
  }, [approveRejectRequestForTechnicalRoleChangeMMGSuccess, approveRejectRequestForTechnicalRoleChangeMMGError]);

  const approveHandler = (id) => {
    if (cardId.id !== id) {
      setCardId({ approveflag: true, id: id, declineflag: false });
      setApproveItem(true);
    } else {
      setCardId({ approveflag: !cardId.approveflag, id: id, declineflag: false });
      setApproveItem(!approveItem);
    }
    setUserComment({ id: null, value: null });
    setDeclineItem(false);
  };

  const declineHandler = (id) => {
    if (cardId.id !== id) {
      setCardId({ declineflag: true, id: id, approveflag: false });
      setDeclineItem(true);
    } else {
      setCardId({ declineflag: !cardId.declineflag, id: id, approveflag: false });
      setDeclineItem(!declineItem);
    }
    setUserComment({ id: null, value: null });
    setApproveItem(false);
  };

  const onEndorsementRequest = (changeRequestId, type) => {
    setApproveItem(false);
    setDeclineItem(false);
    setUserComment({ id: null, value: null });
    setCardId({ id: null, approveflag: false, declineflag: false });

    let data = {
      status: type,
      changeRequestId: changeRequestId,
      comment: comment.id === changeRequestId ? comment.value.trim() : "",
    };
    dispatch(actions.approveRejectRequestForTechnicalRoleChangeMMG(data));
  };

  return (
    <div className={style.role_change_req_outer_wrapper}>
      <SubNavigation type="Role Change" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">
              Role Change
              <span>Technical Role Change Requests</span>
            </span>
          </Col>
        </Row>
      </Container>
      <div className={style.role_change_req_wrapper}>
        <Container>
          <Row>
            <Col md={12}>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
                    <div className="box-heading-wrapper">
                      <div className="box-heading">
                        <h2>Request(s)</h2>
                        <h3>Member Requests for Technical Role Change</h3>
                      </div>
                    </div>
                    <div className="box-inner-pad pb-0">
                      <div
                        className={[style.endrose_req_wrapper, approveItem && "active", declineItem && "active"].join(
                          " "
                        )}
                      >
                        {requestList && requestList.length > 0 ? (
                          requestList.map((item) => {
                            return (
                              <div
                                key={item.changeRequestId}
                                className={[
                                  style.card_item,
                                  approveItem &&
                                    cardId.id === item.changeRequestId &&
                                    cardId.approveflag &&
                                    "approve-item",
                                  declineItem &&
                                    cardId.id === item.changeRequestId &&
                                    cardId.declineflag &&
                                    "decline-item",
                                ].join(" ")}
                              >
                                <div className={style.card_item_inner}>
                                  <div className={style.card_top}>
                                    <span className={style.user_img}>
                                      <img src={item.requestedBy.displayPicture} alt="User" />
                                    </span>
                                    <div className={style.user_info}>
                                      <span className={style.username}>{item.requestedBy.empName}</span>
                                      <span
                                        className={style.department}
                                        title={`${item.currentTechnicalRoleName} | ${item.currentDepartmentName}`}
                                      >
                                        <span>Current - </span>
                                        {item.currentTechnicalRoleName} | {item.currentDepartmentName}
                                      </span>
                                      <span
                                        className={style.department}
                                        title={`${item.requestedTechnicalRoleName} | ${item.requestedDepartmentName}`}
                                      >
                                        <span>New - </span>
                                        {item.requestedTechnicalRoleName} | {item.requestedDepartmentName}
                                      </span>
                                      <span className={style.end_date}>
                                        {moment(item.createdDate).format("MMM DD, YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={style.card_bottom}>
                                    <span
                                      className={style.end_approved}
                                      onClick={() => approveHandler(item.changeRequestId)}
                                    >
                                      <img src={IC_ENDORSEMENT_APPROVE} alt="Endrosement Approved" />
                                    </span>
                                    <span
                                      className={style.end_decline}
                                      onClick={() => declineHandler(item.changeRequestId)}
                                    >
                                      <img src={IC_ENDORSEMENT_DECLINE} alt="Endrosement Decline" />
                                    </span>
                                  </div>
                                  <div className={style.approve_decline_wrapper}>
                                    {approveItem && (
                                      <div className={[style.ad_wrapper, "approved-item"].join(" ")}>
                                        <Form.Group>
                                          <Form.Control
                                            as="textarea"
                                            maxLength="200"
                                            placeholder="Add Comment (optional)"
                                            onChange={(e) => setComment(e, item.changeRequestId)}
                                            value={comment.id === item.changeRequestId ? comment.value : null}
                                          />
                                        </Form.Group>
                                        <Button
                                          variant="secondary"
                                          onClick={() => onEndorsementRequest(item.changeRequestId, "APPROVED")}
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    )}
                                    {declineItem && (
                                      <div className={[style.ad_wrapper, "decline-item"].join(" ")}>
                                        <Form.Group>
                                          <Form.Control
                                            as="textarea"
                                            maxLength="200"
                                            placeholder="Add Comment (Required)"
                                            onChange={(e) => setComment(e, item.changeRequestId)}
                                            value={comment.id === item.changeRequestId ? comment.value : null}
                                          />
                                        </Form.Group>
                                        <Button
                                          variant="secondary"
                                          onClick={() => onEndorsementRequest(item.changeRequestId, "REJECTED")}
                                          disabled={!comment.value}
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <NoDataFound />
                            <div className="gap120"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <RequestsList data={otherRequestList} />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
