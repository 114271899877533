import React, { useState } from "react";
import PropTypes from "prop-types";

const ViewMoreText = ({ text, length }) => {
  const [toggle, setToggle] = useState(true);
  const [viewMoreText, setViewMoreText] = useState(text.slice(0, length));

  const toggleText = (text) => {
    if (toggle) {
      setViewMoreText(text);
      setToggle(false);
    } else {
      setViewMoreText(text.slice(0, length));
      setToggle(true);
    }
  };

  return (
    <>
      {text && (
        <div>
          <span className="viewMoreText">{viewMoreText}</span>
          <span className="a-tag" onClick={() => toggleText(text)}>
            {text.length > length ? (toggle ? "(View More)" : "(View Less)") : null}
          </span>
        </div>
      )}
    </>
  );
};

ViewMoreText.propTypes = {
  text: PropTypes.string,
  length: PropTypes.number,
};

ViewMoreText.defaultProps = {
  length: 250,
};

export default ViewMoreText;
