import * as actionTypes from "../actions/actionTypes";

const initialState = {
  getHrDashboardReport: null,
  ErrorInGetReports: false,
  isLoading: false,
  hrDashboardExcelError: {},
  hrDashboardExcelSuccess: {},
  allEmployeeObjectiveData: [],
  employeeObjectiveData: [],
  employeeObjectiveDataExport: null,
  privateAssessmentDataExport: null,
  globalFeedbackList: [],
  HREmployeeNameList: [],
  currentRPMList: [],
  currentRVMList: [],
  managerList: [],
  projectList: [],
  bandOption: [],
  locationOption: [],
  indiaDDOption: [],
  departmentOption: [],
  rateTypeOption: [],
  pmOption: [],
  criteriaOption: [],
  dmOption: [],
  ddOption: [],
  cycleListOption: [],
  objectiveFilterStartDate: null,
  objectiveFilterEndDate: null,
  assessmentFilterData: {},
  tableHeader: [],
  tableData: [],
  employeeViewInPopup: [],
  popupBody: null,
  employeesViewComparison: [],
  filteredViewComparison: [],
  assessmentReportChartData: [],
  HRQuarterlyCycleList: [],
  allQuarterlyCycleList: [],
  dashboardDataLoading: true,
  dashboardReportFilter: {},
  dashboardFilters: {},
  dashboardOption: {},
  mainMenuItem: [
    { value: 1, label: "Sub Group", name: "subGroupClient", count: 0 },
    { value: 2, label: "Employee Type", name: "memberTypeList", count: 0 },
    { value: 3, label: "Location", name: "locationOption", count: 0 },
    { value: 4, label: "Group", name: "departmentList", count: 0 },
    { value: 5, label: "Project", name: "projectList", count: 0 },
    { value: 6, label: "India DD", name: "indiaDDOption", count: 0 },
    { value: 7, label: "Manager", name: "managerOptions", count: 0 },
  ],
  employeeMemberTypeList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HR_DASHBOARD_REPORT:
      return {
        ...state,
        isLoading: false,
        dashboardDataLoading: false,
        getHrDashboardReport: action.payload,
      };
    case actionTypes.GET_HR_DASHBOARD_FILTER_OPTION:
      return {
        ...state,
        isLoading: false,
        dashboardDataLoading: false,
        dashboardOption: action.payload,
      };
      case actionTypes.GET_HR_DASHBOARD_EMPLOYEE_TYPE_FILTER_OPTION:
        return {
          ...state,
          employeeMemberTypeList: action.payload,
        };
    case actionTypes.SET_HR_DASHBOARD_MENU_COUNT:
      return {
        ...state,
        mainMenuItem: state.mainMenuItem.map((value) => {
          return {
            ...value,
            count: action.data[value.name] === 0 ? action.data[value.name] : action.data[value.name] || value.count,
          };
        }),
      };
    case actionTypes.SET_HR_DASHBOARD_MENU_CHECKED:
      return {
        ...state,
        mainMenuItem: state.mainMenuItem.map((value) => {
          // eslint-disable-next-line no-prototype-builtins
          if (action.data.hasOwnProperty(value.name)) {
            return {
              ...value,
              checked: action.data[value.name],
            };
          } else return value;
        }),
      };
    case actionTypes.ERROR_GET_HR_DASHBOARD_REPORT:
      return {
        ...state,
        isLoading: false,
        dashboardDataLoading: false,
        getHrDashboardReport: null,
      };
    case actionTypes.SHOW_HR_LOADER:
      return {
        ...state,
        isLoading: true,
        allEmployeeObjectiveData: [],
      };
    case actionTypes.GET_HR_DASHBOARD_EXCEL_REPORT:
      return {
        ...state,
        isLoading: false,
        dashboardDataLoading: false,
        hrDashboardExcelSuccess: action.payload,
      };
    case actionTypes.GET_HR_DASHBOARD_EXCEL_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        dashboardDataLoading: false,
        hrDashboardExcelError: action.payload,
      };
    case actionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        isLoading: false,
        hrDashboardExcelError: {},
        dashboardDataLoading: false,
        hrDashboardExcelSuccess: {},
        employeeObjectiveDataExport: null,
        privateAssessmentDataExport: null,
      };
    case actionTypes.SET_ALL_EMPLOYEE_OBJECTIVE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_HR_QUARTERLY_CYCLE_LIST:
      return {
        ...state,
        HRQuarterlyCycleList: action.data,
      };
    case actionTypes.SET_ALL_HR_QUARTERLY_CYCLE_LIST:
      return {
        ...state,
        allQuarterlyCycleList: action.data,
      };
    case actionTypes.SET_HR_EMPLOYEE_NAME_LIST:
      return {
        ...state,
        HREmployeeNameList: action.data,
      };
    case actionTypes.SET_DASHBOARD_REPORT_FILTER_DATA:
      return {
        ...state,
        dashboardReportFilter: {
          ...state.dashboardReportFilter,
          ...action.payload,
        },
      };
    case actionTypes.SET_DASHBOARD_FILTERS:
      return {
        ...state,
        dashboardFilters: {
          ...state.dashboardFilters,
          ...action.payload,
        },
        dashboardReportFilter: {
          ...state.dashboardReportFilter,
          ...action.payload,
        },
      };
    case actionTypes.CLEAR_DASHBOARD_REPORT_FILTER_DATA:
      return {
        ...state,
        dashboardReportFilter: { ...action.payload },
        dashboardOption: {},
        dashboardFilters: {},
      };
    case actionTypes.CLEAR_ALL_DASHBOARD_REPORT_FILTER_DATA:
      return {
        ...state,
        dashboardReportFilter: {},
        dashboardOption: {},
        mainMenuItem: state.mainMenuItem.map((value) => {
          if (value.name === "subGroupClient") {
            return {
              ...value,
              checked: false,
            };
          }
          return {
            ...value,
            checked: false,
            count: 0,
          };
        }),
        dashboardFilters: {},
      };
    case actionTypes.SET_CURRENT_RPM_LIST:
      return {
        ...state,
        currentRPMList: action.data,
      };
    case actionTypes.SET_CURRENT_RVM_LIST:
      return {
        ...state,
        currentRVMList: action.data,
      };
    case actionTypes.SET_NEW_MANAGER_LIST:
      return {
        ...state,
        managerList: action.data,
      };
    case actionTypes.SET_PROJECT_LIST:
      return {
        ...state,
        projectList: action.payload,
      };
    case actionTypes.SET_PMS_DROPDOWN_OPTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CYCLE_LIST_OPTIONS:
      return {
        ...state,
        cycleListOption: action.payload,
        isLoading: false,
        bandOption: [],
        pmOption: [],
        dmOption: [],
        ddOption: [],
        criteriaOption: [],
        locationOption: [],
        indiaDDOption: [],
        departmentOption: [],
        projectList: [],
        rateTypeOption: [],
      };
    case actionTypes.SET_HR_DASHBOARD_ASSESSMENT_TABLE_CONTENT:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        employeesViewComparison: [],
        filteredViewComparison: [],
      };
    case actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        tableData: [],
        tableHeader: [],
      };
    case actionTypes.SET_PMS_EMPLOYEE_VIEW_IN_POPUP:
      return {
        ...state,
        ...action.payload,
        openPopup: true,
        isLoading: false,
      };
    case actionTypes.CLOSE_PMS_EMPLOYEE_VIEW_POPUP:
      return {
        ...state,
        employeeViewInPopup: [],
        openPopup: false,
      };
    case "START_HR_DASHBOARD_LOADING":
      return {
        ...state,
        isLoading: true,
        dashboardDataLoading: true,
      };
    case actionTypes.SET_GLOBAL_FEEDBACK_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.EMPLOYEE_OBJECTIVE_CHART_DATA:
      return {
        ...state,
        isLoading: false,
        employeeObjectiveData: action.payload,
      };
    case actionTypes.EMPLOYEE_OBJECTIVE_CHART_EXPORT_REPORT:
      return {
        ...state,
        isLoading: false,
        employeeObjectiveDataExport: action.payload,
      };
    case actionTypes.EMPLOYEE_ASSESSMENT_REPORT:
      return {
        ...state,
        isLoading: false,
        privateAssessmentDataExport: action.payload,
      };
    case "SET_EMPLOYEE_OBJECTIVE_FILTERED_DATE":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_HR_DASHBOARD_ASSESSMENT_FILTER_DATA":
      return {
        ...state,
        assessmentFilterData: {
          ...state.assessmentFilterData,
          ...action.payload,
        },
      };
    case "CLEAR_HR_DASHBOARD_ASSESSMENT_FILTER_DATA":
      return {
        ...state,
        assessmentFilterData: {},
        tableHeader: [],
        tableData: [],
        employeesViewComparison: [],
        filteredViewComparison: [],
        employeeViewInPopup: [],
      };

    case actionTypes.SET_ASSESSMENT_REPORT_CHART_DATA:
      return {
        ...state,
        assessmentReportChartData: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default reducer;
