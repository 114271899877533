import React from "react";
import style from "./DeallocationQuesitonModal.module.scss";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import QuestionTemplate from "../../../Performance/CycleMemberProfile/QuestionTemplate/QuestionTemplate";
import { dateFormat2 } from "../../../../util/dateUtils";
import CommentsTop from "../../../CommentBox/CommentsTop";
import Comment from "../../../CommentBox/Comment";
import { backGroundColor } from "../../../../const/constValue";

function DeallocationQuestionModal({ show, hide, employeeInfo, downloadFile }) {
  return (
    <Modal show={show} onHide={hide} size="lg" animation={false} className="custom_modal_popup">
      <div className="custom_modal_container custom_lg_modal_container">
        <Modal.Header className={style.mHeader}>
          <Modal.Title>
            Project Feedback -{" "}
            {employeeInfo.projectName &&
              employeeInfo.projectName.length > 0 &&
              employeeInfo.projectName.map((v) => v.projectName).join(", ")}
            <span>All the questions are answered with proper data points.</span>
          </Modal.Title>
        </Modal.Header>
        <div className="box-light-gradient"></div>
        <Modal.Body className={style.mBody}>
          {employeeInfo?.comment && (
            <div className={style.not_applicable_comment_wrapper}>
              <div className={[style.comment_wrapper, "view_comment_box_wrapper", "bg-white"].join(" ")}>
                <CommentsTop heading="Reviewer's Comment(s)" />
                <Comment
                  selectedColor={backGroundColor[1]}
                  image={employeeInfo.projectManager?.displayPicture || employeeInfo.projectManager.empInitial}
                  empName={employeeInfo.projectManager?.empName}
                  commentedOn={employeeInfo.updatedDate}
                  comment={employeeInfo.comment}
                  isPrivate={false}
                  deletable={false}
                />
              </div>
            </div>
          )}
          <div className={style.question_template_wrapper}>
            {employeeInfo.projectName &&
              employeeInfo.projectName.length > 0 &&
              employeeInfo.projectName.find((v) => v.startDate && v.endDate) && (
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2>
                      {employeeInfo.projectName
                        .map((v) => {
                          return v.startDate && v.endDate
                            ? `${dateFormat2(v.startDate)} - ${dateFormat2(v?.endDate)}`
                            : "";
                        })
                        .join(", ")}
                    </h2>
                  </div>
                </div>
              )}
            <QuestionTemplate
              isManagerSubmitted={true}
              viewTemplateName={false}
              handleTextBox={() => {}}
              handleRating={() => {}}
              questionTemplateErrors={{}}
              handleRatingComment={() => {}}
              handleRadioComment={() => {}}
              handleRadio={() => {}}
              handleRadioOther={() => {}}
              handleCheckBox={() => {}}
              handleCheckBoxOther={() => {}}
              handleCheckBoxComment={() => {}}
              handleDropDown={() => {}}
              handleDropDownComment={() => {}}
              handleDropDownOther={() => {}}
              handleScale={() => {}}
              handleScaleComment={() => {}}
              handleRankComment={() => {}}
              handleRank={() => {}}
              handleNps={() => {}}
              handleNpsComment={() => {}}
              isSubmitted={true}
              questionsAnswerTemplate={employeeInfo?.deallocationquestionTemplate}
              uploadFile={() => {}}
              downloadFile={downloadFile}
              deleteFile={() => {}}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="custom_modal_button justify-content-start">
          <Button variant={"primary"} className={"fs-500"} onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default DeallocationQuestionModal;
