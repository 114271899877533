import React from "react";
import { Container } from "react-bootstrap";
import style from "./ObjectiveUserStatus.module.scss";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions/index";

export default function ObjectiveUserStatus(props) {
  const dispatch = useDispatch();
  const isActive = props.objective.progressStatus;
  let disableEndObjective = false; //Math.sign(moment(props.objective.endDate).subtract(1, "days").diff({}, "days")) === -1;
  let status = "";
  let _class = "";
  if (isActive === "GREEN") {
    status = <div>On&nbsp;Track</div>;
    _class = style.step1;
  } else if (isActive === "ORANGE") {
    status = <div>Needs&nbsp;Attention</div>;
    _class = style.step2;
  } else if (isActive === null || isActive === "RED") {
    status = <div>Running&nbsp;Behind</div>;
    _class = style.step3;
  }
  let flag = true;
  if (props.page === "checkin") {
    if (props.editable) {
      flag = false;
    } else {
      flag = true;
    }
  } else {
    flag = false;
  }
  return (
    <div>
      {/* <div className="gap30"></div> */}
      <Container>
        {flag ? (
          <div className={style.objectiveuserstatus}>
            <div className={style.employeeObjectives_process_indicate}>
              <div className="custom-tooltip custom-tooltip-top">
                <div className="box-info">
                  <div className={style.employeeObjectives_step}>
                    <div
                      className={[style.step, style.step1, isActive === "GREEN" && style.active].join(" ")}
                      onClick={() => {
                        if (props.objective.completionDate) return;
                        if (props.editable) return;
                        if (disableEndObjective) return;
                        dispatch(
                          actions.objectiveProgressStatus({
                            userObjectiveId: props.objective.userObjectiveId,
                            progressStatus: "GREEN",
                            employeeIds: props.objective.employeeData,
                          })
                        );
                      }}
                    ></div>
                  </div>
                </div>
                <div className={["info-attach-file", style.emp_objective_tooltip].join(" ")}>
                  <div className="row">
                    <div className="col">
                      <div>On&nbsp;Track</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Yellow */}

            <div className={style.employeeObjectives_process_indicate}>
              <div className="custom-tooltip custom-tooltip-top">
                <div className="box-info">
                  <div className={style.employeeObjectives_step}>
                    <div
                      className={[style.step, style.step2, isActive === "ORANGE" && style.active].join(" ")}
                      onClick={() => {
                        if (props.objective.completionDate) return;
                        if (props.editable) return;
                        if (disableEndObjective) return;
                        dispatch(
                          actions.objectiveProgressStatus({
                            userObjectiveId: props.objective.userObjectiveId,
                            progressStatus: "ORANGE",
                            employeeIds: props.objective.employeeData,
                          })
                        );
                      }}
                    ></div>
                  </div>
                </div>
                <div className={["info-attach-file", style.emp_objective_tooltip].join(" ")}>
                  <div className="row">
                    <div className="col">
                      <div>Needs&nbsp;Attention</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Red  */}

            <div className={style.employeeObjectives_process_indicate}>
              <div className="custom-tooltip custom-tooltip-top">
                <div className="box-info">
                  <div className={style.employeeObjectives_step}>
                    <div
                      className={[style.step, style.step3, isActive === "RED" && style.active].join(" ")}
                      onClick={() => {
                        if (props.objective.completionDate) return;
                        if (props.editable) return;
                        if (disableEndObjective) return;
                        dispatch(
                          actions.objectiveProgressStatus({
                            userObjectiveId: props.objective.userObjectiveId,
                            progressStatus: "RED",
                            employeeIds: props.objective.employeeData,
                          })
                        );
                      }}
                    ></div>
                  </div>
                </div>
                <div className={["info-attach-file", style.emp_objective_tooltip].join(" ")}>
                  <div className="row">
                    <div className="col">
                      <div>Running&nbsp;Behind</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={style.objectiveuserstatus}>
            <div className={style.employeeObjectives_process_indicate}>
              <div className="custom-tooltip custom-tooltip-top">
                <div className="box-info">
                  <div className={style.employeeObjectives_step}>
                    <div
                      className={[style.step, _class, style.active, isActive === null ? style.step3 : ""].join(" ")}
                    ></div>
                  </div>
                </div>
                <div className={["info-attach-file", style.emp_objective_tooltip].join(" ")}>
                  <div className="row">
                    <div className="col">
                      <div>{status}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
