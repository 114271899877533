import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import Select from "react-select";
import style from "../AssignObjectives/AssignObjectives.module.scss";
import * as actions from "store/actions/index";
import { IC_NOTIFY } from "const/imgCost";
import { status } from "const/constValue";
import { ratingOptions } from "const/options";
import { requiredFieldMissing } from "util/general";
import { UPDATE_OBJECTIVE_KEY_RESULT } from "util/endpoints";
import http from "util/http";
import SlideToast from "../../Toast/Toast";
import CommentsTop from "../../CommentBox/CommentsTop";

function RatingKeyResults(props) {
  const { objective } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();

  const saveAcceptComment = (e, keyId, rating, acceptComment) => {
    let detData = {};
    if (acceptComment.trim()) {
      detData = {
        userObjectiveId: objective.userObjectiveId,
        employeeId: objective.employeeId,
        managerId: objective.managerData.employeeId,
        isDraft: true,
        rating: rating ? rating : objective.rating,
        comment: acceptComment.trim(),
        subGroupId: objective.employeeData.subGroupId,
        subGroupName: objective.employeeData.subGroupName,
        parentGroupId: objective.employeeData.parentGroupId,
        parentGroupName: objective.employeeData.parentGroupName,
        managerSubGroupId: objective.managerData.subGroupId,
        managerSubGroupName: objective.managerData.subGroupName,
        managerParentGroupId: objective.managerData.parentGroupId,
        managerParentGroupName: objective.managerData.parentGroupName,
      };
    } else {
      detData = {
        userObjectiveId: objective.userObjectiveId,
        employeeId: objective.employeeId,
        managerId: objective.managerData.employeeId,
        isDraft: true,
        rating: rating,
        subGroupId: objective.employeeData.subGroupId,
        subGroupName: objective.employeeData.subGroupName,
        parentGroupId: objective.employeeData.parentGroupId,
        parentGroupName: objective.employeeData.parentGroupName,
        managerSubGroupId: objective.managerData.subGroupId,
        managerSubGroupName: objective.managerData.subGroupName,
        managerParentGroupId: objective.managerData.parentGroupId,
        managerParentGroupName: objective.managerData.parentGroupName,
      };
      setSelectedOption(e);
    }

    http
      .put(UPDATE_OBJECTIVE_KEY_RESULT, { definedBy: objective.definedBy }, detData)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(
            actions.GetAssignedUserObjective(objective.userObjectiveId, objective.employeeData, objective.managerData)
          );
        }
      })
      .catch((error) => console.warn("ENGAGE", error));
  };

  const submitAcceptComment = () => {
    let detData = {
      userObjectiveId: objective.userObjectiveId,
      employeeId: objective.employeeId,
      managerId: objective.managerData.employeeId,
      isDraft: false,
      rating: objective.rating,
      subGroupId: objective.employeeData.subGroupId,
      subGroupName: objective.employeeData.subGroupName,
      parentGroupId: objective.employeeData.parentGroupId,
      parentGroupName: objective.employeeData.parentGroupName,
      managerSubGroupId: objective.managerData.subGroupId,
      managerSubGroupName: objective.managerData.subGroupName,
      managerParentGroupId: objective.managerData.parentGroupId,
      managerParentGroupName: objective.managerData.parentGroupName,
    };
    if (!objective.rating) {
      requiredFieldMissing();
    } else {
      http
        .put(UPDATE_OBJECTIVE_KEY_RESULT, { definedBy: objective.definedBy }, detData)
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            toast.success(<SlideToast title="Awesome!" message="Submitted successfully!" error={false} />);
            dispatch(
              actions.GetAssignedUserObjective(objective.userObjectiveId, objective.employeeData, objective.managerData)
            );
            dispatch(actions.GetObjectiveActivity(objective.userObjectiveId, objective.employeeData));
          }
        })
        .catch((error) => console.warn("ENGAGE", error));
    }
  };
  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className={[style.key_result_list_wrapper, style.mng_obj_notification, "mt-0"].join(" ")}>
            {objective.isSubmitted ? (
              <span style={{ float: "right", fontSize: "18px", fontWeight: "500", paddingBottom: "15px" }}>
                Aggregate Rating : {objective.grade || "-"}
              </span>
            ) : (
              <>
                <span className={style.notify_icon}>
                  <ReactSVG src={`${IC_NOTIFY}`} />
                </span>
                <p>You have accepted this objective, kindly rate this objective now.</p>
              </>
            )}
            <div className={[style.key_result_item, "border-0"].join(" ")}>
              <div className={[style.key_result_item_content, "pr-0 pt-0 pb-0"].join(" ")}>
                <div className={[style.key_result_rate_wrapper, "mt-1"].join(" ")}>
                  <div className={style.card_ft_wrapper}>
                    <span>Rate this progress by selecting a reasonable value</span>
                    <div className={style.card_action}>
                      <Select
                        options={ratingOptions}
                        className="custom-default-dd-box"
                        classNamePrefix="select"
                        value={
                          selectedOption ? selectedOption : objective.rating ? ratingOptions[objective.rating - 1] : ""
                        }
                        isDisabled={objective.isSubmitted}
                        onChange={(value) => saveAcceptComment(value, "1", value.value, "")}
                      />
                    </div>
                  </div>
                </div>
                {objective.isSubmitted && objective.ratingComment == null ? null : (
                  <div className={["view_comment_box_wrapper", objective.isSubmitted && "bg-white"].join(" ")}>
                    <CommentsTop heading={objective.isSubmitted ? "Reviewer's Comment(s)" : "Share your view(s)"} />
                    <div className="view_comment_box_row">
                      <div className="comment_box_wrapper fg-comment-wrapper">
                        <div className="user_img" style={{ backgroundColor: "#CC54FF" }}>
                          {objective.isSubmitted ? (
                            objective.managerData && objective.managerData.displayPicture.length > 2 ? (
                              <img src={objective.managerData && objective.managerData.displayPicture} alt="pic" />
                            ) : (
                              objective.managerData && objective.managerData.displayPicture
                            )
                          ) : props.loggedInUser.displayPicture && props.loggedInUser.displayPicture.length > 2 ? (
                            <img src={props.loggedInUser.displayPicture} alt="pic" />
                          ) : (
                            props.loggedInUser.empInitial
                          )}
                        </div>
                        {objective.isSubmitted ? (
                          <div className="user_comment_details">
                            <div className="user_name">{objective.managerData.empName}</div>
                            <div className="user_comment text-break">{objective.ratingComment || ""}</div>
                          </div>
                        ) : (
                          <div className="comment_area_wrapper">
                            <Form.Group className="form_group_comment mb-0">
                              <div className="textarea-wrapper">
                                <Form.Control
                                  as="textarea"
                                  disabled={objective.isSubmitted}
                                  maxLength="200"
                                  placeholder="Add a comment"
                                  className="manager_comments"
                                  defaultValue={objective.ratingComment}
                                  onBlur={(value) => saveAcceptComment(value, "1", "", value.target.value)}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {objective.isSubmitted === false && (
              <div className={[style.btn_group].join(" ")}>
                <Button
                  type="button"
                  variant={"primary"}
                  className={["btn-round", style.btn_save].join(" ")}
                  onClick={(e) => submitAcceptComment(e)}
                >
                  <strong>Submit</strong>
                </Button>
                <Button
                  type="button"
                  variant={"secondary"}
                  className={["btn-round", style.btn_cancel].join(" ")}
                  onClick={props.cancelHandler}
                >
                  <strong>Cancel</strong>
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

RatingKeyResults.propTypes = {};

export default RatingKeyResults;
