import React from "react";
import PropTypes from "prop-types";
import TalkingPointInputDisplay from "./TalkingPointInputDisplay";
import { Form } from "react-bootstrap";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";

function TalkingPointLists(props) {
  const {
    point,
    index,
    deletePoint,
    loggedInUser,
    allSelect,
    markTalkingPoint,
    managerId,
    userId,
    deletable,
    disabled,
    isEnded,
  } = props;

  return (
    <div className={style.tp_list_wrapper}>
      <div className={["box mb-0 pt-0 pb-0"].join(" ")}>
        <div className="box-inner-pad p-0">
          <Form.Group controlId="">
            <ul className={style.form_option_list}>
              <TalkingPointInputDisplay
                allSelect={allSelect}
                disabled={disabled}
                isEnded={isEnded}
                deletable={deletable}
                userId={userId}
                managerId={managerId}
                markTalkingPoint={markTalkingPoint}
                loggedInUser={loggedInUser}
                deletePoint={deletePoint}
                style={style}
                point={point}
                index={index}
              />
            </ul>
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

TalkingPointLists.propTypes = {
  point: PropTypes.object,
  index: PropTypes.number,
  deletePoint: PropTypes.func,
  loggedInUser: PropTypes.object,
  markTalkingPoint: PropTypes.func,
  managerId: PropTypes.string,
  userId: PropTypes.string,
  deletable: PropTypes.bool,
  isEnded: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TalkingPointLists;
