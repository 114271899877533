import React, { useState, useRef, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Tooltip, Overlay, Row, Col } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "components/questions/questions.module.scss";
import * as actions from "store/actions/index";
import {
  IC_LOCK_PRIVACY,
  IC_ERROR,
  IC_FILE_UPLOAD_1,
  IC_FILE_ATTACHMENT,
  IC_SEARCH_RESET,
  IMG_TOAST_CLOSE,
  IC_LOCK_PRIVACY_1,
} from "const/imgCost";
import AuthContext from "context/authContext";
import ManagerView from "components/CommentBox/ManagerView";
import { errorToast, successToast } from "util/general";

export default function QuestionTextBox(props) {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const { fileResponse, deleteFileResponse, reviewer, validationErrors } =
    useSelector((state) => state.checkIn);
  const { surveyCycleId, existWinChallenge } = useSelector(
    (state) => state.questions
  );
  const [privateCommentError, setPrivateCommentError] = useState(null);
  const [currentError, setCurrentError] = useState(
    validationErrors &&
      validationErrors.filter((item) => item.qId === props.question.queId)[0]
  );
  const [chars, setChars] = useState(
    props.question.answer ? 500 - props.question.answer.length : 500
  );
  const [isPrivate, togglePrivate] = useState(false);
  const [privateComment, setPrivateComment] = useState();
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState("");
  const [reviewerName, setReviewerName] = useState(false);
  const [textboxdata, setTextboxdata] = useState(props.question.answer);
  const [selectedReviewerId, setSelectedReviewer] = useState(null);
  const targetQuestion = useRef(null);
  const targetMessage = useRef(null);
  const employeeIds = {
    employeeId: context.state.user?.employeeId,
    parentGroupId: context.state.user?.parentGroupId,
    subGroupId: context.state.user?.subGroupId,
  };

  const changeHandle = (qAnswer) => {
    setTextboxdata(qAnswer);
    setChars(500 - qAnswer.length);
    if (qAnswer.length > 0) setCurrentError(null);
    if (qAnswer.length === 0)
      setCurrentError(
        validationErrors &&
          validationErrors.filter(
            (item) => item.qId === props.question.queId
          )[0]
      );
  };

  const privateCommentHandle = (comment) => {
    setComment(comment);
    if (comment.length > 0) setPrivateCommentError(null);
    if (comment.length === 0)
      setPrivateCommentError(
        validationErrors &&
          validationErrors.filter(
            (item) => item.cId === props.question.queId
          )[0]
      );
  };

  useEffect(() => {
    setCurrentError(
      validationErrors &&
        validationErrors.filter((item) => item.qId === props.question.queId)[0]
    );
    setPrivateCommentError(
      validationErrors &&
        validationErrors.filter((item) => item.cId === props.question.queId)[0]
    );
  }, [validationErrors]);

  useEffect(() => {
    let errors = [...validationErrors];
    if (comment) {
      setPrivateCommentError(null);
    } else {
      if (!isPrivate) {
        let error = validationErrors.filter(
          (item) => item.cId !== props.question.queId
        );
        dispatch(actions.SetValidationErrors(error));
        setPrivateCommentError(null);
      } else {
        let error = { cId: props.question.queId, showError: false };
        let index = validationErrors.findIndex(
          (x) => x.cId === props.question.queId
        );
        index === -1 ? errors.push(error) : (errors[index].showError = false);
        dispatch(actions.SetValidationErrors(errors));
      }
    }
  }, [isPrivate]);

  useEffect(() => {
    if (props.question.file) {
      setFile(props.question.file);
    } else {
      setFile(null);
    }

    if (props.question.privateMessage) {
      let comment = props.question.privateMessage;
      if (comment) {
        setPrivateComment(comment);
        setPrivateCommentError(null);
        setComment(comment.comment);
        let whoCanView = comment.whoCanView && comment.whoCanView[0];
        if (whoCanView) {
          setReviewerName(whoCanView.empName);
          setSelectedReviewer(whoCanView.employeeId);
        }
        togglePrivate(true);
      }
    } else {
      setPrivateComment(null);
      setPrivateCommentError(null);
      setComment("");
      setSelectedReviewer(null);
      setReviewerName(null);
      togglePrivate(false);
    }
  }, [props.question]);


  useEffect(()=>{
    setTextboxdata(props.question.answer);
  },[props.question.answer])

  // getting 2 times toaster at time of upload or delete so instead useEffect directly shows the message from action file

  // useEffect(() => {
  //   if (reviewer && reviewer.managersList && reviewer.managersList.length) setReviewerName(reviewer.managersList[0].empName);
  // }, [reviewer]);

  // useEffect(() => {
  //   if (fileResponse) {
  //     if (fileResponse.success) {
  //       successToast(fileResponse.message);
  //     } else {
  //       setFile(null);
  //       if (fileResponse.message === "OTHER_FILE_ERROR") {
  //         errorToast("Select valid file");
  //       } else {
  //         errorToast(fileResponse.message);
  //       }
  //     }
  //     dispatch({ type: "CLEAR_FILE_RESPONSE" });
  //   }
  // }, [fileResponse]);

  // useEffect(() => {
  //   if (deleteFileResponse) {
  //     if (deleteFileResponse.success) {
  //       successToast(deleteFileResponse.message);
  //     } else {
  //       errorToast(deleteFileResponse.message);
  //     }
  //     dispatch({ type: "CLEAR_FILE_RESPONSE" });
  //   }
  // }, [deleteFileResponse]);

  useEffect(() => {
    if (fileResponse) {
      !fileResponse.success && setFile(null);
    }
  }, [fileResponse]);

  const downloadFile = (qId, surveyCycleId) => {
    const params = {
      qId: qId,
      surveyCycleId: surveyCycleId,
    };

    dispatch(actions.DownloadFile(params));
  };

  const uploadFile = (e, id) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file.size > 2097152) {
      setFile();
      document.getElementById(`FILE_${id}`).value = "";
      errorToast("Maximum file size is 2MB");
    } else {
      const formData = new FormData();
      formData.append("questionId", id);
      formData.append("surveyCycleId", surveyCycleId);
      formData.append("file", file);
      setFile(file);
      dispatch(actions.UploadFile(formData));
    }
  };

  const deleteFile = () => {
    setFile();
    const data = {
      questionId: props.question.queId,
      surveyCycleId: surveyCycleId,
    };

    dispatch(actions.DeleteFile({ data: data }));
  };

  const onSelectReviewer = () => {
    let optionValue = JSON.parse(
      document.getElementById("reviewer_select_" + props.question.queId).value
    );
    setSelectedReviewer(optionValue.employeeId);
    setReviewerName(optionValue.empName);
    if (comment.trim().length > 0)
      props.onPrivateMessage(comment, props.question.queId);
  };

  const deletePrivateMessage = (queId) => {
    let data = { ...employeeIds, surveyCycleId, questionId: queId };
    dispatch(actions.DeletePrivateEmpMessage(data));
    togglePrivate(false);
    setReviewerName("");
    setSelectedReviewer(null);
  };

  if (props.edit === false) {
    let winChallenge =
      existWinChallenge &&
      existWinChallenge.filter((i) => i.queId === props.question.queId);
    return (
      <ManagerView
        isManager={props.isManager}
        style={style}
        isPage={props.isPage}
        edit={props.edit}
        submitCycle={props.submitCycle}
        surveyCycleId={surveyCycleId}
        managerRole={props.managerRole}
        question={props.question}
        downloadFile={downloadFile}
        selectedColor={props.selectedColor}
        winChallenge={
          winChallenge && winChallenge.length > 0 ? winChallenge[0] : null
        }
        childEmployee={props.childEmployee}
        currentSurveyCycleID={props.currentSurveyCycleID}
      />
    );
  } else
    return (
      <div className="box-inner-pad">
        <Form.Group>
          <Form.Label>
            {props.question.queTitle}
            {props.question.required && (
              <span className="text-danger"> * </span>
            )}
          </Form.Label>
          <div className={"textarea-wrapper " + (isPrivate ? "locked" : "")}>
            {!file && (
              <div className="attached-pin-icon">
                <div className="custom-tooltip">
                  <div className="box-info">
                    <label className="custom-file-upload mb-0 mt-1">
                      <input
                        type="file"
                        id={`FILE_${props.question.queId}`}
                        name="file"
                        onChange={(e) => uploadFile(e, props.question.queId)}
                        accept="image/png, .jpeg, .jpg, image/gif, application/pdf, .doc, .docx, .odf"
                      />
                      <span>
                        <ReactSVG
                          src={`${IC_FILE_UPLOAD_1}`}
                          title=""
                          alt="Choose File"
                        />
                      </span>
                    </label>
                  </div>
                  <div className="info-attach-file">
                    <span className="attachment-title">Attachments</span>
                    <ul>
                      <li>You can upload a single file only</li>
                      <li>Maximum file size is 2MB</li>
                      <li>
                        Supported files &#34;pdf, png, jpg, doc, docx, ppt,
                        pptx, xls, xlsx, zip&#34;
                      </li>
                    </ul>
                    <span className="attachment-note">Note:</span>
                    <p>
                      We always encourage Apexon Associates to upload large files on
                      Microsoft OneDrive and share link here.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="private-lock-icon">
              <div className="custom-tooltip">
                <div className={style.lock_icon_wrapper}>
                  {isPrivate ? (
                    <ReactSVG
                      className="svg lock-icon-privacy"
                      src={`${IC_LOCK_PRIVACY_1}`}
                      title=""
                      alt="unlock"
                      onClick={() => {
                        setComment("");
                        setPrivateCommentError({
                          ...privateComment,
                          comment: "",
                        });
                        setPrivateCommentError(null);
                        deletePrivateMessage(props.question.queId);
                        togglePrivate(false);
                      }}
                    />
                  ) : (
                    <ReactSVG
                      className="svg "
                      src={`${IC_LOCK_PRIVACY}`}
                      title=""
                      alt="lock"
                      onClick={() => {
                        togglePrivate(true);
                      }}
                    />
                  )}
                </div>

                <div className={style.info_lock_tooltip}>
                  <span className="attachment-title">Private Message</span>
                  <ul>
                    <li>You can send private message to your reviewer</li>
                  </ul>
                </div>
              </div>
            </div>
            <Form.Control
              as="textarea"
              maxLength="500"
              className={[
                currentError && currentError.showError && "limit-reached",
                "private-comment",
              ].join(" ")}
              // defaultValue={testdata}
              value={textboxdata === null ? "" : textboxdata}
              onBlur={props.onBlur}
              placeholder="Add a new answer"
              onChange={(e) => changeHandle(e.target.value)}
              ref={targetQuestion}
            />
          </div>
          <div>
            <Row>
              <Col
                xl={8}
                lg={8}
                md={8}
                className="col-12 order-2  order-xl-1 order-lg-1 order-md-1  order-sm-2"
              >
                {/* {currentError && currentError.showError && (
                  <Overlay target={targetQuestion} show={true} placement="bottom">
                    {(props) => (
                      <Tooltip id="overlay-error-question" {...props} show={props.show.toString() || "true"}>
                        <span className="error-icon">
                          <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                        </span>
                        <span className="error-bubble-txt">This field is required</span>
                      </Tooltip>
                    )}
                  </Overlay>
                )} */}
                {privateCommentError && privateCommentError.showError && (
                  <Overlay
                    target={targetMessage}
                    show={true}
                    placement="bottom"
                  >
                    {(props) => (
                      <Tooltip
                        id="overlay-private-comment"
                        {...props}
                        show={props.show.toString() || "true"}
                      >
                        <span className="error-icon">
                          <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                        </span>
                        <span className="error-bubble-txt">
                          This field is required
                        </span>
                      </Tooltip>
                    )}
                  </Overlay>
                )}

                {file && (
                  <ul className="file-listing w-100">
                    <li>
                      <a
                        target="_blank"
                        onClick={() =>
                          downloadFile(props.question.queId, surveyCycleId)
                        }
                      >
                        <ReactSVG
                          className="file-attachment-icon"
                          src={`${IC_FILE_ATTACHMENT}`}
                          title=""
                          alt=""
                        />
                        {file.name}
                      </a>
                      <span className="delete-item" onClick={deleteFile}>
                        <ReactSVG src={`${IC_SEARCH_RESET}`} title="" alt="" />
                      </span>
                    </li>

                    {/* {pictures.filter(value => value).map(value=> <li><ReactSVG className="file-attachment-icon" src={IC_FILE_ATTACHMENT} title="" alt="" />{value.name}<span className="delete-item" onClick={deleteFile}><ReactSVG src={IC_SEARCH_RESET} title="" alt="" /></span></li>)} */}
                  </ul>
                )}
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                className="col-12 order-1 order-xl-2 order-lg-2 order-md-2  order-sm-1"
              >
                <div className="checkin-question-info mt-1">
                  {" "}
                  {chars} Characters Left
                </div>
              </Col>
            </Row>
          </div>
          {isPrivate && (
            <div className="private-parent-wrapper">
              <div
                className="close_icon"
                onClick={() => {
                  setComment("");
                  setPrivateCommentError({ ...privateComment, comment: "" });
                  setPrivateCommentError(null);
                  deletePrivateMessage(props.question.queId);
                }}
              >
                <ReactSVG src={`${IMG_TOAST_CLOSE}`} title="" alt="" />
              </div>
              <div className="manage_comment_view private-comment-wrapper border-bottom-wrapper">
                <span className="manager_comment_text">
                  <ReactSVG
                    className="svg lock"
                    src={`${IC_LOCK_PRIVACY_1}`}
                    title=""
                    alt="lock"
                  />{" "}
                  Private message to{" "}
                </span>
                <div className="manage_comment_visible_to">
                  <Form.Group className="no_mar">
                    <Form.Control
                      id={"reviewer_select_" + props.question.queId}
                      as="select"
                      size="md"
                      // value={selectedReviewer}
                      onChange={(e) => onSelectReviewer(e)}
                    >
                      <option
                        key={"reviewer_option_null"}
                        value={""}
                        selected={selectedReviewerId ? false : true}
                        disabled
                      >
                        Choose a reviewer
                      </option>
                      {reviewer &&
                        reviewer.managersList &&
                        reviewer.managersList.map((item, index) => {
                          let option = {
                            employeeId: item.employeeId,
                            empName: item.empName,
                            displayPicture:
                              item.displayPicture || item.empInitial,
                            parentGroupId: item.parentGroupId,
                            subGroupId: item.subGroupId,
                          };
                          let selected = selectedReviewerId === item.employeeId;
                          return (
                            <option
                              key={"reviewer_option_" + index}
                              value={JSON.stringify(option)}
                              selected={selected}
                            >
                              {item.empName}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </div>
                {reviewerName && (
                  <span className="manage_comment_help">
                    only you and {reviewerName} can see the message
                  </span>
                )}
              </div>
              <div className="private-msg-wrapper">
                <div className="textarea-wrapper ">
                  <Form.Control
                    as="textarea"
                    maxLength="500"
                    placeholder="Add a private message"
                    value={comment}
                    onBlur={(e) =>
                      props.onPrivateMessage(
                        e.target.value,
                        props.question.queId
                      )
                    }
                    onChange={(e) => privateCommentHandle(e.target.value)}
                    ref={targetMessage}
                    className={
                      privateCommentError &&
                      privateCommentError.showError &&
                      "limit-reached"
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Form.Group>
      </div>
    );
}
