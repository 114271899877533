import InfoTooltip from "components/InfoTooltip/InfoTooltip";
import { IC_COMMENT } from "const/imgCost";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import CollapseCommentBox from "shared/CommentBox/CollapseCommentBox";

export default function Questions({
  item,
  renderComponent,
  name,
  ratingAndComment,
  checkinQuestionData,
  managerCommentHandle,
  managerDeleteComment,
  managerResponse,
  toolTip,
}) {
  const [isOpen, setIsOpen] = useState(checkinQuestionData?.defaultOpen || false);
  const [comment, setComment] = useState("");
  const handleMessageOpen = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleComment = () => {
    managerCommentHandle(comment);
    setComment("");
  };

  return (
    <>
      <div className="box-inner-pad ratingBarBox">
        <Form.Group controlId="" className="d-flex justify-content-between">
          <Form.Label>
            {item.questionNumber ? `Q${item.questionNumber}. ` : ""} {item.questionName}
            {(item.isRequired || item.commentResponse === "Required") && <span className="text-danger">*</span>}
            {toolTip && <InfoTooltip heading={toolTip} />}
            {item.description && <Form.Text>{item["description"]}</Form.Text>}
          </Form.Label>
          {checkinQuestionData?.viewCommentIcon && (
            <div className="items_action_box">
              <div onClick={handleMessageOpen} className="comment_svg_icon">
                <ReactSVG className="svg" src={`${IC_COMMENT}`} alt="comment" />
              </div>
            </div>
          )}
        </Form.Group>
        {renderComponent(item, name)}
        {checkinQuestionData && (!checkinQuestionData?.readOnly || item.managerComments?.length > 0) && (
          <CollapseCommentBox
            comments={item.managerComments || []}
            isOpen={isOpen}
            employee={checkinQuestionData?.employee}
            deleteComment={managerDeleteComment}
            questionId={item.questionId}
            managerCommentHandle={handleComment}
            changeHandle={setComment}
            chars={200}
            comment={comment}
            readOnly={checkinQuestionData?.readOnly}
          />
        )}
        {ratingAndComment}
      </div>
      {managerResponse}
    </>
  );
}
