import React from "react";
import { Table } from "react-bootstrap";
import style from "./DynamicTable.module.scss";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

function TwoHeaderTable({ tableHeader, tableData, handleClickValue, infoIcon, projectList }) {
  return (
    <div className={style.two_table_wrapper}>
      <Table hover responsive className={style.two_table_wrapper}>
        <thead>
          <tr>
            {tableHeader.map((v, i) => {
              return (
                <th
                  key={i + "HEADER"}
                  colSpan={v.subHeaderName ? v.subHeaderName.length : 0}
                  className={style.main_heading}
                >
                  {v.headerName}
                </th>
              );
            })}
          </tr>
          <tr>
            {tableHeader.map((v) => {
              if (v.subHeaderName)
                return v.subHeaderName?.map((subHeader, index) => {
                  return (
                    <th
                      key={index + "SUB_HEADER"}
                      className={tableHeader[1]?.subHeaderName?.length === index + 1 ? style.main_heading : ""}
                    >
                      {subHeader}
                    </th>
                  );
                });
              else return <th className={style.main_heading} key={"OTHER_HEADER"}></th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData.length > 0 &&
            tableData.map((item, mainIndex) => {
              return (
                <tr key={mainIndex}>
                  {item.map((value, itemIndex) => (
                    <td
                      className={[
                        "cursor-pointer",
                        itemIndex % tableHeader[1]?.subHeaderName?.length === 0
                          ? style.left_heading
                          : itemIndex === 0
                          ? style.border_left
                          : "",
                      ].join(" ")}
                      key={itemIndex + "_" + value}
                      onClick={() => {
                        if (value !== "0" && itemIndex !== 0) {
                          handleClickValue(item[0], itemIndex);
                        }
                      }}
                    >
                      {value}
                      {/* for project details table data we need to add info icon .only for project name column*/}
                      {itemIndex === 0 &&
                        mainIndex !== tableData.length - 1 &&
                        infoIcon &&
                        tableHeader[0]?.headerName === "Project" &&
                        !projectList?.includes(value) && <InfoTooltip heading="Past reportee's current project" />}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default TwoHeaderTable;
