import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import style from "./CycleMemberProfile.module.scss";
import * as actions from "store/actions/index";
import { toFixedNumber, useQuery } from "util/utils";
import Spinner from "shared/Spinner/Spinner";
import BoxHeading from "shared/Box/BoxHeading";
import UserProfile from "../../user_profile/user_profile";
import ReviewerProfile from "../../reviewer-profile/reviewer-profile";
import MemberProfileObjective from "./MemberProfileObjecive/MemberProfileObjecive";
import SidebarCheckInActivity from "../../sidebar_checkins/sidebar_checkins";
import SubmittedDateBox from "../../SubmitBox/SubmittedDateBox";
import Assessment from "./SelfAssessmentRating/Assessment";
import GlobalReadOnlyComment from "../GlobalComment/GlobalReadOnlyComment";
import Rewards from "../../Rewards/Rewards";
import CardSideValue from "../../CardSideValue/CardSideValue";
import Acknowledgement from "../../Acknowledgement/Acknowledgement";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import { errorToast, successToast } from "util/general";

export default function CycleMemberProfile(props) {
  const cycleId = props.match.params.id;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const assessmentType = useQuery().get("assessmentType");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const dispatch = useDispatch();
  const {
    getPrivateAssessmentData,
    allManagerStatus,
    globalRVMComment,
    globalRPMComment,
    globalSELFComment,
    globalFeedbackFileResponse,
    globalSELFCommentUserData,
    isSubmitted,
    isFileLoading,
    allManagerDetails,
    submittedDate,
    managerList,
    managerWiseGrade,
    isPrivateAssessmentLoading,
    fileResponse,
    quaterlyCycleData,
  } = useSelector((state) => state.performance);
  const { user } = useSelector((state) => state.myTeam);
  const { filterSurveyData } = useSelector((state) => state.myHistory);
  const count = useSelector((state) => state.madeMyDay.appreciateCount);

  useEffect(() => {
    dispatch({ type: "CLEAR_MY_TEAM_DATA" });
    if (employeeIds) {
      dispatch(actions.GetAssessmentGlobalComment(employeeIds, cycleId));
      dispatch(actions.GetRPMAssessmentGlobalComment(employeeIds, cycleId));
      dispatch(actions.GetSelfAssessmentGlobalComment(employeeIds, cycleId));
      dispatch(actions.GetUser(employeeIds));
      dispatch(actions.GetAppreciateDurationCount(employeeIds, 6)); //by default show 6 months data
      dispatch(actions.GetFilterSurveyHistory({ ...employeeIds, filterByMonth: 12 }));
      dispatch(actions.GetPrivateAssessmentData(employeeIds, cycleId, "HR"));
    }
  }, [dispatch, cycleId]);

  //response useEffect
  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);

      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
  }, [fileResponse, dispatch]);
  
  useEffect(() => {
    if (globalFeedbackFileResponse) {
      if (globalFeedbackFileResponse.success) successToast(globalFeedbackFileResponse.message);
      else errorToast(globalFeedbackFileResponse.message);
    }
  }, [globalFeedbackFileResponse]);

  const handleAppreciateDuration = (select) => dispatch(actions.GetAppreciateDurationCount(employeeIds, select.value));
  const gradeOrRatingOrBoth = getPrivateAssessmentData?.gradeOrRatingOrBoth;
  const selfQuestionTemplate = getPrivateAssessmentData?.selfQuestionTemplate;
  const privateAssessmentId = getPrivateAssessmentData?.privateAssessmentId;
  const acknowledgement = getPrivateAssessmentData?.gradeAcknowledgement;
  const isRatingDisable =  quaterlyCycleData?.showRatingDropDown;
  const employeeData = getPrivateAssessmentData?.employeeData;
  let selfAssessmentGrade = getPrivateAssessmentData?.selfAssessmentGrade;
  let selfAssessmentAvgRating =
    getPrivateAssessmentData?.selfAssessmentAvgRating ??
    toFixedNumber(getPrivateAssessmentData?.selfAssessmentAvgRating, 2);
  let overAllAggregateText = "Aggregate Grade";
  if (gradeOrRatingOrBoth === "BOTH") {
    selfAssessmentGrade = getPrivateAssessmentData?.selfAssessmentGrade
      ? getPrivateAssessmentData?.selfAssessmentGrade +
        "/" +
        toFixedNumber(getPrivateAssessmentData.selfAssessmentAvgRating, 2)
      : "";
    overAllAggregateText = "Aggregate Progress";
    selfAssessmentAvgRating = selfAssessmentGrade;
  } else if (gradeOrRatingOrBoth === "RATING") {
    selfAssessmentGrade = toFixedNumber(getPrivateAssessmentData.selfAssessmentAvgRating, 2);
    overAllAggregateText = "Aggregate Rating";
  }
  const selAssessmentRPMGrade =
    getPrivateAssessmentData?.rpmRatings?.map((manager) => {
      return {
        ...manager,
        grade:
          gradeOrRatingOrBoth === "BOTH"
            ? manager.grade
              ? manager.grade + "/" + toFixedNumber(manager.rating, 2)
              : ""
            : gradeOrRatingOrBoth === "RATING"
            ? toFixedNumber(manager.rating, 2)
            : manager.grade,
        type: "RPM",
      };
    }) || [];
  const selAssessmentRVMGrade =
    getPrivateAssessmentData?.rvmRatings?.map((manager) => {
      return {
        ...manager,
        grade:
          gradeOrRatingOrBoth === "BOTH"
            ? manager.grade
              ? manager.grade + "/" + toFixedNumber(manager.rating, 2)
              : ""
            : gradeOrRatingOrBoth === "RATING"
            ? toFixedNumber(manager.rating, 2)
            : manager.grade,
        type: "RVM",
      };
    }) || [];

  let questionTemplates = [];
  if (
    selfQuestionTemplate ||
    getPrivateAssessmentData?.allRpmQuestionTemplate?.length > 0 ||
    getPrivateAssessmentData?.allRpmQuestionTemplate?.length > 0
  ) {
    if (selfQuestionTemplate) {
      selfQuestionTemplate.nameDOM = <div>Self Assessment</div>;
      selfQuestionTemplate.name = "Self Assessment";
      selfQuestionTemplate && questionTemplates.push(selfQuestionTemplate);
      selfQuestionTemplate.privateAssessmentId = getPrivateAssessmentData?.selfPrivateAssessmentId;
      selfQuestionTemplate.type = "SELF";
    }
    if (getPrivateAssessmentData?.allRpmQuestionTemplate?.length > 0) {
      getPrivateAssessmentData.allRpmQuestionTemplate.map((value) => {
        if (value.questionTemplate) {
          let rpmQuestionTemplate = value.questionTemplate;
          rpmQuestionTemplate.nameDOM = (
            <CardSideValue
              title="Reporting Assessment : "
              name={value.userDetails?.empName}
              sideView={true}
              rating={
                value.assessmentStatus.status === "Submit" &&
                value.assessmentStatus.rating !== 0 &&
                value.assessmentStatus.rating
              }
              status={value.assessmentStatus.status === "Submit" ? "Submitted" : value.assessmentStatus.status}
            />
          );
          rpmQuestionTemplate.type = "REPORTING";
          rpmQuestionTemplate.rating = value.assessmentStatus.rating && value.assessmentStatus.rating?.toFixed(2);
          rpmQuestionTemplate.name = `Reporting Assessment ${value.userDetails?.empName}`;
          rpmQuestionTemplate.employeeId = value.userDetails?.employeeId;
          rpmQuestionTemplate.privateAssessmentId = value.rpmPrivateAssessmentId;
          questionTemplates.push(rpmQuestionTemplate);
        }
        return value;
      });
    }
    if (getPrivateAssessmentData?.allRvmQuestionTemplate?.length > 0) {
      getPrivateAssessmentData.allRvmQuestionTemplate.map((value) => {
        if (value.questionTemplate) {
          let rvmQuestionTemplate = value.questionTemplate;
          rvmQuestionTemplate.nameDOM = (
            <CardSideValue
              title="Reviewing Assessment : "
              name={value.userDetails?.empName}
              sideView={true}
              rating={
                value.assessmentStatus.status === "Submit" &&
                value.assessmentStatus.rating !== 0 &&
                value.assessmentStatus.rating
              }
              status={value.assessmentStatus.status === "Submit" ? "Submitted" : value.assessmentStatus.status}
            />
          );
          rvmQuestionTemplate.type = "REVIEWING";
          rvmQuestionTemplate.rating = value.assessmentStatus.rating && value.assessmentStatus.rating?.toFixed(2);
          rvmQuestionTemplate.name = `Reviewing Assessment ${value.userDetails?.empName}`;
          rvmQuestionTemplate.employeeId = value.userDetails?.employeeId;
          rvmQuestionTemplate.privateAssessmentId = privateAssessmentId;
          questionTemplates.push(rvmQuestionTemplate);
        }
        return value;
      });
    }
  }

  let reviewers = null;
  if (getPrivateAssessmentData?.allRM || getPrivateAssessmentData?.allRVM) {
    let allManager = (getPrivateAssessmentData?.allRM || []).concat(getPrivateAssessmentData?.allRVM || []);
    // eslint-disable-next-line no-undef
    const uniqueManager = Array.from(new Set(allManager.map((a) => a.employeeId))).map((id) => {
      return allManager.find((a) => a.employeeId === id);
    });
    reviewers = {
      managersList: uniqueManager,
    };
  }
  const downloadFile = (fileId, questionId, questionTemplateId, privateAssessmentId, type) => {
    let params = {
      fileId,
      questionId,
      privateAssessmentId,
      questionTemplateId,
      assessmentType: type,
    };
    dispatch(actions.DownloadFilePrivateAssessment(params));
  };

  let url,
    breadCrumbTitle = null;
  if (assessmentType === "REPORTING") {
    breadCrumbTitle = "Reporting Manager List";
    url = `/hr/performance/performance-cycle/reporting-manager-list/${cycleId}`;
  } else if (assessmentType === "REVIEWING") {
    breadCrumbTitle = "Reviewing Manager List";
    url = `/hr/performance/performance-cycle/reviewing-manager-list/${cycleId}`;
  } else if (assessmentType === "SELF") {
    breadCrumbTitle = "Employee Self Assessment List";
    url = `/hr/performance/performance-cycle/employee-assessment-list/${cycleId}`;
  } else if (assessmentType === "HR") {
    breadCrumbTitle = "Employee List";
    url = `/hr/performance/performance-cycle/employee-list/${cycleId}`;
  }

  const downloadFileGlobalFeedback = (id, privateAssessment_Id) => {
    const params = {
      fileId: id,
      privateAssessmentId: privateAssessment_Id !== undefined && typeof privateAssessment_Id !== 'object' ? privateAssessment_Id : privateAssessmentId ,
      // privateAssessmentId: privateAssessmentId,
    };
    dispatch(actions.DownloadFileGlobalFeedback(params));
  };

  if (isPrivateAssessmentLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.cycleTeamWrapper}>
      <SubNavigation type="Performance" />
      {isFileLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">
              Performance
              <span>
                <Link to={routePath.PMS_CYCLE}>Performance Cycle</Link>
              </span>
              <span>
                <Link to={url}>{breadCrumbTitle}</Link>
              </span>
              <span>
                <Link to={url}>{getPrivateAssessmentData.cycle}</Link>
              </span>
              <span>{user && user.displayName}</span>
            </span>
          </Col>
          {getPrivateAssessmentData?.cycle && (
            <Col className="col-12">
              <BoxHeading heading={getPrivateAssessmentData.cycle} description={getPrivateAssessmentData.period} />
            </Col>
          )}
        </Row>

        <Row>
          <Col lg={8}>
            {user && <UserProfile user={user} />}
            {getPrivateAssessmentData?.objectiveTracking === null && selfQuestionTemplate === null && (
              <div className="box pb-0">
                <div className="box-heading-wrapper border-bottom-0">
                  <div className="box-heading">
                    <h2>
                      Aggregate Grade/Rating -{" "}
                      {gradeOrRatingOrBoth === "BOTH"
                        ? getPrivateAssessmentData?.selfAssessmentGrade /
                          getPrivateAssessmentData?.selfAssessmentAvgRating
                        : gradeOrRatingOrBoth === "GRADE"
                        ? getPrivateAssessmentData?.selfAssessmentGrade
                        : getPrivateAssessmentData?.selfAssessmentAvgRating}{" "}
                    </h2>
                  </div>
                </div>
              </div>
            )}
            {getPrivateAssessmentData.objectiveTracking && (
              <MemberProfileObjective
                isSubmitted={true}
                managerList={managerList}
                managerWiseGrade={managerWiseGrade}
                questionTemplateErrors={[]}
                allManagerDetails={allManagerDetails}
                employeeData={user}
                user={false}
                hrView={true}
                rowData={getPrivateAssessmentData}
                allManagerStatus={allManagerStatus}
              />
            )}
            {questionTemplates?.length > 0 && (
              <Assessment
                selfAssessmentAvgGrade={selfAssessmentGrade}
                overAllAggregateText={overAllAggregateText}
                selfAssessmentManagerViseGrade={selAssessmentRPMGrade.concat(selAssessmentRVMGrade)}
                isPage="HR"
                isRatingDisable={!isRatingDisable}
                selfAssessmentAvgRating={selfAssessmentAvgRating}
                allManagerStatus={allManagerStatus}
                saveQuestionAnswer={() => {}}
                downloadFile={downloadFile}
                questionTemplateErrors={{}}
                isSubmitted={isSubmitted}
                uploadFile={() => {}}
                deleteFile={() => {}}
                questionTemplates={questionTemplates}
                sendManagerComment={() => {}}
              />
            )}

            <GlobalReadOnlyComment
              heading="Global Feedback"
              description="Here is what employee want to say to your manager"
              comment={globalSELFCommentUserData}
              // comment={globalSELFComment}
              message="Employee haven't shared any view"
              downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                downloadFileGlobalFeedback(id, privateAssessmentId)
              }
            />
            <GlobalReadOnlyComment
              heading="Reporting Manager's Global Feedback"
              description="Here is what Reporting manager has given their views"
              comment={globalRPMComment?.length > 0 ? globalRPMComment.filter((v) => v.status === "Submit") : []}
              message="Manager haven't shared any view"
              downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                downloadFileGlobalFeedback(id, privateAssessmentId)
              }
            />
            <GlobalReadOnlyComment
              heading="Reviewing Manager's Global Feedback"
              description="Here is what Reviewing manager has given their views"
              comment={globalRVMComment?.length > 0 ? globalRVMComment.filter((v) => v.status === "Submit") : []}
              message="Manager haven't shared any view"
              downloadFileGlobalFeedback={(id, privateAssessment_Id) =>
                downloadFileGlobalFeedback(id, privateAssessment_Id)
              }
            />
            {acknowledgement?.isSubmit && (
              <Acknowledgement
                check={acknowledgement.isAcknowledged}
                handleSubmit={() => {}}
                setComment={() => {}}
                comment={acknowledgement.comment}
                isSubmitted={true}
                image={employeeData?.dispArr || employeeData?.empInitial}
                empName={employeeData.empName}
                commentDate={acknowledgement?.submittedDate}
              />
            )}
            {isSubmitted && <SubmittedDateBox submittedDate={submittedDate} />}
          </Col>
          <Col lg={4}>
            {/* <SidebarTopFiveSkills />
							<SidebarJourney /> 
							<SidebarAnalytics />
							<SidebarAwards />*/}
            {filterSurveyData && <SidebarCheckInActivity filterSurveyData={filterSurveyData} />}
            {count && <Rewards count={count} handleAppreciateDuration={handleAppreciateDuration} />}
            {reviewers?.managersList?.length > 0 && <ReviewerProfile managerList={reviewers.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
