import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import style from "./TabEndroseRequest.module.scss";
import * as actions from "store/actions/index";
import { IC_ENDORSEMENT_APPROVE, IC_ENDORSEMENT_DECLINE, IC_STAR_PURPLE } from "const/imgCost";
import Spinner from "shared/Spinner/Spinner";
import DeclineRequests from "./DeclineRequests/DeclineRequests";
import NoDataFound from "../../../NodataFound/NoDataFound";
import { noData } from "const/constValue";
import { competency_level_options } from "const/options";
import { errorToast, successToast } from "util/general";

export default function TabEndroseRequest() {
  const { isLoading, getEndorseReqForEmp, approveEndorseReqResponse, approveEndorseReqErrorResponse } = useSelector(
    (state) => state.skillMatrix
  );
  const dispatch = useDispatch();
  const [approveItem, setApproveItem] = useState(false);
  const [declineItem, setDeclineItem] = useState(false);
  const [endorseType, setEndorseType] = useState({ id: null, value: null, label: null });
  const [currentCompatencyLevel, setCompantencyLevel] = useState({ id: null, value: null, label: null });
  const [comment, setUserComment] = useState({ id: null, value: null });
  const [cardId, setCardId] = useState({ id: null, approveflag: false, declineflag: false });

  useEffect(() => {
    const getEndorseReq = () => dispatch(actions.getEndorseReqForEmployee);
    getEndorseReq();
  }, [dispatch]);

  const onEndorsementRequest = (data, type) => {
    const reqData = {
      id: data.id,
      status: type === "APPROVE" ? "Endorsed" : "Decline",
      endorsedTypeId: endorseType.value && endorseType.id === data.id ? endorseType.value : data.endorsedTypeId,
      comment: comment.id === data.id ? comment.value : null,
      endorsedCompetencyLevel:
        currentCompatencyLevel.value && currentCompatencyLevel.id === data.id
          ? currentCompatencyLevel.value
          : data.currentCompatencyLevel,
      endorsedSkill: {
        skillIdName: data.skillIdName,
        skillIdNameId: data.skillIdNameId,
        currentCompetencyLevel: data.currentCompatencyLevel,
      },
      endorsedToId: data.endorseToId, //ADDed endorseToId as per requirement of beckendteam
      endorseRequestByDepartmentName: data.endorseRequestByDepartmentName, //ADD endorseRequestByDepartmentName as per requeirement of beckendteam
      endorseRequestById: data.endorseRequestById, //ADDed endorseRequestById as per requirement of beckendteam
      endorsedToSubGroupId: data.endorsedToSubGroupId,
      endorsedToParentGroupId: data.endorsedToParentGroupId,
      endorseRequestBySubGroupId: data.endorseRequestBySubGroupId,
      EndorseRequestByParentGroupId: data.EndorseRequestByParentGroupId,
    };
    dispatch(actions.approveEndorseReq(reqData));
  };

  useEffect(() => {
    if (approveEndorseReqResponse && !approveEndorseReqResponse.error) successToast(approveEndorseReqResponse.message);
    if (approveEndorseReqErrorResponse?.error) errorToast(approveEndorseReqErrorResponse.message);
  }, [approveEndorseReqErrorResponse, approveEndorseReqResponse]);

  const approveHandler = (id) => {
    if (cardId.id !== id) {
      setCardId({ approveflag: true, id: id, declineflag: false });
      setApproveItem(true);
    } else {
      setCardId({ approveflag: !cardId.approveflag, id: id, declineflag: false });
      setApproveItem(!approveItem);
    }
    setUserComment({ id: null, value: null });
    setDeclineItem(false);
  };

  const declineHandler = (id) => {
    if (cardId.id !== id) {
      setCardId({ declineflag: true, id: id, approveflag: false });
      setDeclineItem(true);
    } else {
      setCardId({ declineflag: !cardId.declineflag, id: id, approveflag: false });
      setDeclineItem(!declineItem);
    }
    setUserComment({ id: null, value: null });
    setApproveItem(false);
  };

  const selectEndorseType = (data, id) => {
    setEndorseType({ id: id, value: data.value, label: data.label });
  };
  const selectLevel = (data, id) => {
    setCompantencyLevel({ id: id, value: data.value, label: data.label });
  };
  const setComment = (e, id) => {
    setUserComment({ id: id, value: e.target.value });
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Requests</h2>
            <h3>Colleague Requests for Endorsment</h3>
          </div>
        </div>
        <div className="box-inner-pad pb-0">
          <div
            className={[
              style.endrose_req_wrapper,
              approveItem && "active",
              declineItem && "active",
              getEndorseReqForEmp && getEndorseReqForEmp.length > 5 && "overflow_y_visible",
            ].join(" ")}
            id="dem"
          >
            {getEndorseReqForEmp && getEndorseReqForEmp.length > 0 ? (
              getEndorseReqForEmp.map((data) => {
                const compatencyLevel = competency_level_options.find((cl) => cl.value === data.currentCompatencyLevel);
                let endorseTypeArr = [];
                let userEndorseType;
                let flag = false;
                data.endorseType.map((type) => {
                  const obj = {};
                  obj["value"] = type.endorseTypeId;
                  obj["label"] = type.endorseTypeName;
                  endorseTypeArr.push(obj);
                  return type;
                });

                if (data.endorseRequestByDepartmentName) {
                  flag = true;
                  userEndorseType = endorseTypeArr.find((type) => +type.value === +data.endorsedTypeId);
                } else {
                  flag = endorseType.value ? true : false;
                }
                return (
                  <div
                    className={[
                      style.card_item,
                      approveItem && cardId.id === data.id && cardId.approveflag && "approve-item",
                      declineItem && cardId.id === data.id && cardId.declineflag && "decline-item",
                    ].join(" ")}
                    id={data.id}
                    key={`${data.id}${data.skillIdName}`}
                  >
                    <div className={style.card_item_inner} id={data.id}>
                      <div className={style.card_top}>
                        <span className={style.user_img}>
                          <img src={data.employeePic} alt="User" />
                        </span>
                        <div className={style.user_info}>
                          <span className={style.username}>{data.endorseToName}</span>
                          <span className={style.department} title={data.designation}>
                            {data.designation}
                          </span>
                          <span className={style.department} title={data.department}>
                            {data.department}
                          </span>
                        </div>
                      </div>
                      <div className={style.card_text}>
                        <h5 title={`${data.skillIdName} - ${compatencyLevel?.label}`}>
                          {data.skillIdName} - {compatencyLevel?.label}
                        </h5>
                        <span className={style.end_date}>{moment(data.endorsedReqDate).format("MMMM D, YYYY")}</span>
                        <div className={[style.card_dd, "card_action"].join(" ")}>
                          <Select
                            options={endorseTypeArr}
                            className="custom-default-dd-box"
                            classNamePrefix="select"
                            placeholder="Select Endorsement Type"
                            onChange={(select) => selectEndorseType(select, data.id)}
                            value={
                              endorseType.value && endorseType.id === data.id
                                ? { value: endorseType.value, label: endorseType.label }
                                : userEndorseType
                            }
                          />
                        </div>
                        <div className={[style.card_dd, "card_action"].join(" ")}>
                          <Select
                            id={data.id}
                            options={competency_level_options}
                            className="custom-default-dd-box"
                            classNamePrefix="select"
                            placeholder="Select Competency Level"
                            value={
                              currentCompatencyLevel.value && currentCompatencyLevel.id === data.id
                                ? { value: currentCompatencyLevel.value, label: currentCompatencyLevel.label }
                                : compatencyLevel
                            }
                            onChange={(select) => selectLevel(select, data.id)}
                          />
                        </div>
                      </div>
                      <div className={style.card_bottom}>
                        <span className={style.end_approved} onClick={() => approveHandler(data.id)}>
                          <img src={IC_ENDORSEMENT_APPROVE} alt="Endrosement Approved" />
                        </span>
                        <span className={style.end_decline} onClick={() => declineHandler(data.id)}>
                          <img src={IC_ENDORSEMENT_DECLINE} alt="Endrosement Decline" />
                        </span>
                      </div>
                      <div className={style.approve_decline_wrapper}>
                        {approveItem && (
                          <div className={[style.ad_wrapper, "approved-item"].join(" ")}>
                            <Form.Group>
                              <Form.Control
                                as="textarea"
                                maxLength="200"
                                placeholder="Add Comment (optional)"
                                onChange={(e) => setComment(e, data.id)}
                                value={comment.id === data.id ? comment.value : null}
                              />
                            </Form.Group>
                            <Button
                              variant="secondary"
                              id={data.id}
                              onClick={() => onEndorsementRequest(data, "APPROVE")}
                              disabled={!flag}
                            >
                              Endorse
                            </Button>
                          </div>
                        )}
                        {declineItem && (
                          <div className={[style.ad_wrapper, "decline-item"].join(" ")}>
                            <Form.Group>
                              <Form.Control
                                as="textarea"
                                maxLength="200"
                                placeholder="Add Comment (Required)"
                                onChange={(e) => setComment(e, data.id)}
                                value={comment.id === data.id ? comment.value : null}
                              />
                            </Form.Group>
                            <Button
                              variant="secondary"
                              id={data.id}
                              onClick={() => onEndorsementRequest(data, "DECLINE")}
                              disabled={!flag || !comment.value}
                            >
                              Decline
                            </Button>
                          </div>
                        )}
                      </div>
                      {data.endorseRequestByDepartmentName && (
                        <div className={[style.mmg_tooltip, "custom-tooltip"].join(" ")}>
                          <div className="box-info">
                            <span>
                              <img className="svg" src={IC_STAR_PURPLE} alt="box-info" />
                            </span>
                          </div>
                          <div className="info-attach-file">
                            <p>
                              Requested by <b> WMG ({data.endorseRequestByName})</b>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap120"></div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeclineRequests />
    </>
  );
}
