import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import MultiSelect from "react-multi-select-component";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import style from "./CreatePerformanceCycle.module.scss";
import * as actions from "store/actions/index";
import { IC_SEARCH_RESET } from "const/imgCost";
import { requiredFieldMissing, pleaseSelectDate, successToast, errorToast } from "util/general";
import { endOfDay } from "util/dateUtils";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import QuestionTemplateModal from "./QuestionTemplateModal/QuestionTemplateModal";
import StartAndEndDatePicker from "./StartAndEndDatePicker";
import SlideToast from "../../Toast/Toast";
import InfoTooltipWithLi from "../../InfoTooltip/InfoTooltipWithLi";
import InfoTooltip from "../../InfoTooltip/InfoTooltip";
import config from "util/config";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

export default function CreatePerformanceCycle() {
  const dispatch = useDispatch();
  const [isSelectModal, setIsSelectModal] = useState("");
  const [isRatingPermit, setIsRatingPermit] = useState(false);
  const [submitClick, setSubmitClick] = useState(false);
  const [errors, setErrors] = useState({});
  const [isModal, setIsModal] = useState(false);
  const history = useHistory();
  const user = storage.getUser();
  storage.set("cycle", "create");
  const {
    isPerformanceLoading,
    employeeNameList,
    createSuccessResponse,
    templateList,
    locationList,
    isTemplateLoading,
    createCycleData,
    employeeTypeList,
  } = useSelector((state) => state.performance);
  const { subGroupClient } = useSelector((state) => state.admin);
  storage.remove("cId");
  let templates = templateList;
  if (isSelectModal === "SELF") templates = templates.filter((temp) => temp.category === "SELF_ASSESSMENT");
  else if (isSelectModal === "REPORTING" || isSelectModal === "REVIEWING")
    templates = templates.filter((temp) => temp.category === "MANAGER_ASSESSMENT");

  useEffect(() => {
    dispatch(actions.GetTemplateList());
    dispatch(actions.GetLocationList());
    dispatch(actions.getSubGroupClient());
    // dispatch(actions.GetEmployeeNameList(locationList.map((v) => v.value)));
  }, [dispatch]);

  useEffect(() => {
    if (createSuccessResponse) {
      if (createSuccessResponse.success) {
        successToast(createSuccessResponse.message);
        history.push(routePath.PMS_CYCLE);
      } else {
        errorToast(createSuccessResponse.message);
      }
      dispatch({ type: "CLEAR_PERFORMANCE_RESPONSE" });
    }
  }, [createSuccessResponse, dispatch, history]);

  const createPerformance = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmitClick(true);
    let error = errors;
    if (!createCycleData.cycle || !createCycleData.cycle.trim()) error.cycle = true;
    if (!createCycleData?.subGroups || createCycleData?.subGroups?.length == 0) {
      setErrors({ ...errors, subGroups: true });
      requiredFieldMissing();
    }
    if (!createCycleData.locations || createCycleData.locations?.length === 0) {
      setErrors({ ...errors, locations: true });
      toast.success(<SlideToast title="Error!" message="Please select locations" error={true} />);
      return;
    }
    setErrors({ ...error });
    if (errors.cycle || errors.groups) {
      requiredFieldMissing();
      return;
    }
    if (createCycleData?.ratingVisibleDate && !createCycleData?.acknowledgementEndDate) {
      setErrors({ ...errors, acknowledgementEndDate: true });
      pleaseSelectDate();
      return;
    }
    if (!createCycleData.quarterStartDate || !createCycleData.quarterEndDate) {
      pleaseSelectDate();
      return;
    }
    if (createCycleData.selfStartDate || createCycleData.selfEndDate)
      if (!createCycleData.selfEndDate || !createCycleData.selfStartDate) {
        setErrors({ ...errors, selfDate: true });
        pleaseSelectDate();
        return;
      }
    if (createCycleData.reportingStartDate || createCycleData.reportingEndDate)
      if (!createCycleData.reportingStartDate || !createCycleData.reportingEndDate) {
        setErrors({ ...errors, reporteeDate: true });
        pleaseSelectDate();
        return;
      }
    if (createCycleData.reviewingStartDate || createCycleData.reviewingEndDate)
      if (!createCycleData.reviewingStartDate || !createCycleData.reviewingEndDate) {
        setErrors({ ...errors, reviewerDate: true });
        pleaseSelectDate();
        return;
      }

    let data = {
      ...createCycleData,
      cutOffDate: createCycleData.cutOffDate ? createCycleData.cutOffDate : undefined,
      locations: createCycleData.locations?.map((v) => v.label),
      isRMTemplateVisibleToRVM: createCycleData.isRMTemplateVisibleToRVM,
      quarterStartDate: createCycleData.quarterStartDate ? createCycleData.quarterStartDate : undefined,
      quarterEndDate: endOfDay(createCycleData.quarterEndDate),
      acknowledgementEndDate: createCycleData.acknowledgementEndDate
        ? endOfDay(createCycleData.acknowledgementEndDate)
        : undefined,
      excludeGroup:
        createCycleData.selected && createCycleData.selected.length > 0
          ? createCycleData.selected.map((value) => value.label)
          : null,
      employeeIds:
        createCycleData.employeeList && createCycleData.employeeList.length > 0
          ? createCycleData.employeeList.map((value) => {
              return {
                employeeId: value.value?.split(config.employeeIdSplit)[0],
                parentGroupId: value.value?.split(config.employeeIdSplit)[1],
                subGroupId: value.value?.split(config.employeeIdSplit)[2],
                displayName: value.label,
              };
            })
          : null,
      subGroups: createCycleData.subGroups?.map((value) => value.value),
      showRatingDropDown: isRatingPermit,
      createdBy: {
        empName: user.displayName,
        employeeId: user.employeeId,
        dispArr: user.displayPicture || user.empInitial,
        designation: user.designation,
        department: user.department,
        country: user.country,
        parentGroupId: user.parentGroupId,
        subGroupId: user.subGroupId,
      },
      period: moment(createCycleData.quarterEndDate).format("MMM YYYY"),
    };
    dispatch(actions.CreatePerformanceCycle(data));
  };

  const selectTemplate = (id, value, select) => {
    hide();
    if (select === "SELF") {
      dispatch({
        type: "SET_CREATE_CYCLE_DATA",
        payload: {
          selectQuestion: {
            ...createCycleData.selectQuestion,
            SELF: {
              id: id,
              name: value,
            },
          },
        },
      });
    } else if (select === "REPORTING") {
      dispatch({
        type: "SET_CREATE_CYCLE_DATA",
        payload: {
          selectQuestion: {
            ...createCycleData.selectQuestion,
            REPORTING: {
              id: id,
              name: value,
            },
          },
        },
      });
    } else if (select === "REVIEWING") {
      dispatch({
        type: "SET_CREATE_CYCLE_DATA",
        payload: {
          selectQuestion: {
            ...createCycleData.selectQuestion,
            REVIEWING: {
              id: id,
              name: value,
            },
          },
        },
      });
    }
  };

  //for modal closing and opening
  const hide = (value) => {
    setIsSelectModal(value);
    setIsModal(!isModal);
    if (value === "SELF") storage.set("templateType", "SELF_ASSESSMENT");
    else if (value === "REPORTING") storage.set("templateType", "MANAGER_ASSESSMENT");
    else if (value === "REVIEWING") storage.set("templateType", "MANAGER_ASSESSMENT");
  };

  if (isTemplateLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.cycle_per_wrapper}>
      <SubNavigation type="Performance" />
      {isPerformanceLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">
              Performance
              <span>
                <Link to={routePath.PMS_CYCLE}>Performance Cycle</Link>
              </span>
              <span>Create Cycle</span>
            </span>
          </Col>
          <Col md={12}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Create Cycle</h2>
                </div>
              </div>
              <Form name="Cycle Form">
                <div className={[style.custom_form_ctrl_wrapper, "box-inner-pad"].join(" ")}>
                  <Form.Group>
                    <Form.Label>
                      Cycle Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength="200"
                      className={[errors && errors.cycle && "limit-reached"].join(" ")}
                      placeholder="Cycle Name"
                      value={createCycleData.cycle}
                      onChange={(e) => {
                        dispatch({
                          type: "SET_CREATE_CYCLE_DATA",
                          payload: {
                            cycle: e.target.value,
                          },
                        });
                        if (e.target.value.trim()) {
                          setErrors({ ...errors, cycle: false });
                        } else setErrors({ ...errors, cycle: true });
                      }}
                    />
                  </Form.Group>
                  <div className="radio-wrapper d-flex dropdown-multiselect">
                    <Form.Group controlId="">
                      <Form.Label className={style.dd_title}>
                        Sub Group<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          options={subGroupClient}
                          className={errors?.subGroups ? "limit-reached" : "multi-select"}
                          value={createCycleData?.subGroups || ""}
                          onChange={(value) => {
                            setErrors({ ...errors, subGroups: false });
                            dispatch({
                              type: "SET_CREATE_CYCLE_DATA",
                              payload: {
                                subGroups: value,
                              },
                            });
                            dispatch(
                              actions.GetEmployeeNameList(
                                createCycleData.selected?.map((v) => v.value),
                                createCycleData.locations?.map((v) => v.value),
                                value?.map((v) => v.value)
                              )
                            );
                            dispatch(actions.GetEmployeeTypeList(value?.map((v) => v.value)));
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="" className="mr-5">
                      <Form.Label>
                        Location<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          className={errors?.locations ? "limit-reached" : "multi-select"}
                          options={locationList}
                          value={createCycleData.locations}
                          onChange={(e) => {
                            setErrors({ ...errors, locations: false });
                            dispatch({
                              type: "SET_CREATE_CYCLE_DATA",
                              payload: {
                                locations: e,
                              },
                            });
                            dispatch(
                              actions.GetEmployeeNameList(
                                e?.map((v) => v.value),
                                createCycleData.selected?.map((v) => v.value),
                                createCycleData.subGroups?.map((v) => v.value)
                              )
                            );
                          }}
                          labelledBy={"name"}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="radio-wrapper d-flex dropdown-multiselect mb-3">
                    <Form.Group controlId="" className="mr-5">
                      <Form.Label>Exclude Groups</Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          options={employeeTypeList}
                          value={createCycleData.selected}
                          onChange={(e) => {
                            dispatch({
                              type: "SET_CREATE_CYCLE_DATA",
                              payload: {
                                selected: e,
                              },
                            });
                            dispatch(
                              actions.GetEmployeeNameList(
                                createCycleData.locations?.map((v) => v.value),
                                e?.map((v) => v.value),
                                createCycleData.subGroups?.map((v) => v.value)
                              )
                            );
                          }}
                          labelledBy={"name"}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="">
                      <Form.Label>Exclude Employees</Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          options={employeeNameList}
                          value={createCycleData.employeeList}
                          onChange={(e) => {
                            dispatch({
                              type: "SET_CREATE_CYCLE_DATA",
                              payload: {
                                employeeList: e,
                              },
                            });
                          }}
                          labelledBy={"name"}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className={[style.custom_form_ctrl_wrapper, style.active_cycle_wrapper].join(" ")}>
                    <div className={[style.cycle_calendars, "date_picker_time_wrapper", "p-0"].join(" ")}>
                      <Form.Group className="calendar_outer_wrapper">
                        <Form.Label>Cut Off Date</Form.Label>
                        <div className="calendar_wrapper">
                          <ReactDatePicker
                            className={["form-control"].join(" ")}
                            dateFormat="MMMM d, yyyy"
                            selected={createCycleData.cutOffDate}
                            onChange={(date) => {
                              dispatch({
                                type: "SET_CREATE_CYCLE_DATA",
                                payload: {
                                  cutOffDate: date,
                                },
                              });
                            }}
                            placeholderText="Select Date"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className={[style.custom_form_ctrl_wrapper, "box-inner-pad"].join(" ")}>
                  <div className={[style.cycle_calendars, "date_picker_time_wrapper", "p-0"].join(" ")}>
                    <Form.Group className="calendar_outer_wrapper">
                      <Form.Label>
                        Cycle Assessment Start Date &amp; End Date
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <StartAndEndDatePicker
                        click={submitClick}
                        error={true}
                        startDate={createCycleData.quarterStartDate}
                        endDate={createCycleData.quarterEndDate}
                        handleStartDate={(date) => {
                          dispatch({
                            type: "SET_CREATE_CYCLE_DATA",
                            payload: {
                              quarterStartDate: date,
                            },
                          });
                        }}
                        handleEndDate={(date) => {
                          dispatch({
                            type: "SET_CREATE_CYCLE_DATA",
                            payload: {
                              quarterEndDate: date,
                            },
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="calendar_outer_wrapper">
                      <Form.Label>Self Assessment - Please Select Start Date &amp; End Date</Form.Label>
                      <StartAndEndDatePicker
                        click={submitClick}
                        error={errors.selfDate}
                        minDate={createCycleData.quarterStartDate}
                        startDate={createCycleData.selfStartDate}
                        minEndDate={new Date(createCycleData.selfStartDate) < new Date() ? new Date() : null}
                        endDate={createCycleData.selfEndDate}
                        handleStartDate={(date) => {
                          dispatch({
                            type: "SET_CREATE_CYCLE_DATA",
                            payload: {
                              selfStartDate: date,
                            },
                          });
                        }}
                        handleEndDate={(date) => {
                          dispatch({
                            type: "SET_CREATE_CYCLE_DATA",
                            payload: {
                              selfEndDate: date,
                            },
                          });
                        }}
                      />
                      {createCycleData?.selectQuestion?.SELF ? (
                        <span className={style.file_info}>
                          {createCycleData.selectQuestion?.SELF?.name}
                          <span
                            className={style.delete_item}
                            onClick={() =>
                              dispatch({
                                type: "SET_CREATE_CYCLE_DATA",
                                payload: {
                                  selectQuestion: {
                                    ...createCycleData.selectQuestion,
                                    SELF: "",
                                  },
                                },
                              })
                            }
                          >
                            <ReactSVG src={IC_SEARCH_RESET} title="" alt="" />
                          </span>
                        </span>
                      ) : (
                        <button
                          type="button"
                          onClick={() => hide("SELF")}
                          className={["btn-round save_box_btn btn btn-secondary", style.select_que_btn].join(" ")}
                        >
                          <strong> Select Question Template</strong>
                        </button>
                      )}
                    </Form.Group>
                    <Form.Group className="calendar_outer_wrapper">
                      <Form.Label>Reporting Manager Assessment - Please Select Start Date &amp; End Date</Form.Label>
                      <StartAndEndDatePicker
                        click={submitClick}
                        error={errors.reporteeDate}
                        minDate={createCycleData.quarterStartDate}
                        startDate={createCycleData.reportingStartDate}
                        endDate={createCycleData.reportingEndDate}
                        minEndDate={new Date(createCycleData.reportingStartDate) < new Date() ? new Date() : null}
                        handleStartDate={(date) => {
                          dispatch({
                            type: "SET_CREATE_CYCLE_DATA",
                            payload: {
                              reportingStartDate: date,
                            },
                          });
                        }}
                        handleEndDate={(date) => {
                          dispatch({
                            type: "SET_CREATE_CYCLE_DATA",
                            payload: {
                              reportingEndDate: date,
                            },
                          });
                        }}
                      />
                      {createCycleData?.selectQuestion?.REPORTING ? (
                        <span className={style.file_info}>
                          {createCycleData.selectQuestion.REPORTING?.name}
                          <span
                            className={style.delete_item}
                            onClick={() =>
                              dispatch({
                                type: "SET_CREATE_CYCLE_DATA",
                                payload: {
                                  selectQuestion: {
                                    ...createCycleData.selectQuestion,
                                    REPORTING: "",
                                  },
                                },
                              })
                            }
                          >
                            <ReactSVG src={IC_SEARCH_RESET} title="" alt="" />
                          </span>
                        </span>
                      ) : (
                        <button
                          type="button"
                          onClick={() => hide("REPORTING")}
                          className={["btn-round save_box_btn btn btn-secondary", style.select_que_btn].join(" ")}
                        >
                          <strong> Select Question Template</strong>
                        </button>
                      )}
                      <div className="custom-checkbox custom-checkbox-sm mt-2">
                        <input
                          id="isRMTemplateVisibleToRVM"
                          type="checkbox"
                          name="mandatoryAssessment"
                          checked={!!createCycleData.isRMTemplateVisibleToRVM}
                          onChange={(e) => {
                            dispatch({
                              type: "SET_CREATE_CYCLE_DATA",
                              payload: {
                                isRMTemplateVisibleToRVM: e.target.checked,
                              },
                            });
                          }}
                        />
                        <label htmlFor="isRMTemplateVisibleToRVM">
                          Provide visibility of RM Question Template to RVM
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className="calendar_outer_wrapper">
                      <Form.Label>Reviewing Manager Assessment - Please Select Start Date &amp; End Date</Form.Label>
                      <StartAndEndDatePicker
                        click={submitClick}
                        error={errors.reviewerDate}
                        minDate={createCycleData.quarterStartDate}
                        startDate={createCycleData.reviewingStartDate}
                        minEndDate={new Date(createCycleData.reviewingStartDate) < new Date() ? new Date() : null}
                        endDate={createCycleData.reviewingEndDate}
                        handleStartDate={(date) => {
                          dispatch({
                            type: "SET_CREATE_CYCLE_DATA",
                            payload: {
                              reviewingStartDate: date,
                            },
                          });
                        }}
                        handleEndDate={(date) => {
                          dispatch({
                            type: "SET_CREATE_CYCLE_DATA",
                            payload: {
                              reviewingEndDate: date,
                            },
                          });
                        }}
                      />
                      {createCycleData?.selectQuestion?.REVIEWING ? (
                        <span className={style.file_info}>
                          {createCycleData.selectQuestion.REVIEWING?.name}
                          <span
                            className={style.delete_item}
                            onClick={() =>
                              dispatch({
                                type: "SET_CREATE_CYCLE_DATA",
                                payload: {
                                  selectQuestion: {
                                    ...createCycleData.selectQuestion,
                                    REVIEWING: "",
                                  },
                                },
                              })
                            }
                          >
                            <ReactSVG src={IC_SEARCH_RESET} title="" alt="" />
                          </span>
                        </span>
                      ) : (
                        <button
                          type="button"
                          onClick={() => hide("REVIEWING")}
                          className={["btn-round save_box_btn btn btn-secondary", style.select_que_btn].join(" ")}
                        >
                          <strong> Select Question Template</strong>
                        </button>
                      )}
                    </Form.Group>

                    <Form.Group className="calendar_outer_wrapper">
                    <div className="custom-checkbox custom-checkbox-sm mt-2">
                        <input
                          id="isPermitRating"
                          type="checkbox"
                          name="mandatoryRating"
                          checked={isRatingPermit}
                          onChange={(e) => isRatingPermit? setIsRatingPermit(false) : setIsRatingPermit(true)}
                        />
                        <label htmlFor="isPermitRating">
                          Would you like to permit rating?
                          </label>
                      </div>
                    </Form.Group>
                    
                    <Form.Group className="calendar_outer_wrapper">
                      <div className={[style.aggregate_response, "radio-wrapper"].join(" ")}>
                        <Form.Group controlId="">
                          <Form.Label>
                            Show Aggregate response for the following
                            <InfoTooltipWithLi
                              heading="Selection Criteria"
                              listItem={[
                                "If you select Grade it follows following System: A+, A, B+, B, C",
                                "If you select Rating it follows following System: 5, 4, 3.2, 2.5, 1.2 etc",
                                "Selecting Both will enable Grading and Rating system for the cycle",
                              ]}
                            />
                          </Form.Label>
                          <ul className={style.form_option_list}>
                            <li>
                              <div className="custom-checkbox custom-checkbox-rounded">
                                <input
                                  id="question_33"
                                  type="radio"
                                  name="question_3"
                                  value="GRADE"
                                  defaultChecked={true}
                                  checked={createCycleData.gradeOrRatingOrBoth === "GRADE"}
                                  onChange={(e) => {
                                    dispatch({
                                      type: "SET_CREATE_CYCLE_DATA",
                                      payload: {
                                        gradeOrRatingOrBoth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                                <label htmlFor="question_33">Grade</label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-checkbox custom-checkbox-rounded">
                                <input
                                  id="question_34"
                                  type="radio"
                                  name="question_3"
                                  value="RATING"
                                  checked={createCycleData.gradeOrRatingOrBoth === "RATING"}
                                  onChange={(e) => {
                                    dispatch({
                                      type: "SET_CREATE_CYCLE_DATA",
                                      payload: {
                                        gradeOrRatingOrBoth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                                <label htmlFor="question_34">Rating</label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-checkbox custom-checkbox-rounded">
                                <input
                                  id="question_35"
                                  type="radio"
                                  name="question_3"
                                  value="BOTH"
                                  checked={createCycleData.gradeOrRatingOrBoth === "BOTH"}
                                  onChange={(e) => {
                                    dispatch({
                                      type: "SET_CREATE_CYCLE_DATA",
                                      payload: {
                                        gradeOrRatingOrBoth: e.target.value,
                                      },
                                    });
                                  }}
                                />
                                <label htmlFor="question_35">Both</label>
                              </div>
                            </li>
                          </ul>
                        </Form.Group>
                      </div>
                      <div className={style.offline_upload_wrapper}>
                        <h6 className={style.offline_header}>Would you like to upload Offline Rating</h6>
                        <span className={["text-danger d-flex", style.upload_note].join(" ")}>
                          Note: Please upload a file only after completion of manager's assessment thoroughly.
                        </span>
                        <button
                          type="button"
                          className={["btn-round btn btn-primary", style.upload_btn].join(" ")}
                          disabled={true}
                        >
                          <strong>Upload</strong>
                        </button>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div
                  className={[style.custom_form_ctrl_wrapper, "box-inner-pad", style.active_cycle_wrapper].join(" ")}
                >
                  <div
                    className={[
                      style.cycle_calendars,
                      "date_picker_time_wrapper",
                      "p-0",
                      createCycleData?.ratingVisibleDate && "mb-0",
                    ].join(" ")}
                  >
                    <div className="radio-wrapper mb-2">
                      <Form.Group controlId="">
                        <Form.Label>Do you want to activate this cycle?</Form.Label>
                        <span className="text-danger d-flex">
                          Note: Cycle will be active for all participant once you select option &quot;Yes&quot;
                        </span>
                        <ul className={style.form_option_list}>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded">
                              <input
                                id={3}
                                type="radio"
                                name="question_2"
                                value="YES"
                                checked={createCycleData.isActive === "YES"}
                                onChange={(e) => {
                                  dispatch({
                                    type: "SET_CREATE_CYCLE_DATA",
                                    payload: {
                                      isActive: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label htmlFor={3}>Yes</label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded">
                              <input
                                id={4}
                                type="radio"
                                name="question_2"
                                value="NO"
                                checked={createCycleData.isActive === "NO"}
                                onChange={(e) => {
                                  dispatch({
                                    type: "SET_CREATE_CYCLE_DATA",
                                    payload: {
                                      isActive: e.target.value,
                                      ratingVisibleDate: "",
                                      acknowledgementEndDate: "",
                                    },
                                  });
                                }}
                              />
                              <label htmlFor={4}>No</label>
                            </div>
                          </li>
                        </ul>
                      </Form.Group>
                    </div>

                    {createCycleData.isActive === "YES" && (
                      <Form.Group className="calendar_outer_wrapper pr-5 pl-5">
                        <Form.Label className="mb-0">Date when Rating will be Publish</Form.Label>
                        <span className="text-danger d-flex mb-2">
                          On selected Date, cycle will be visible to a User in Review tab and cycle status will change
                          to &apos;Completed&apos;.
                        </span>
                        <div className="calendar_wrapper">
                          <ReactDatePicker
                            className={["form-control"].join(" ")}
                            dateFormat="MMMM d, yyyy"
                            selected={createCycleData.ratingVisibleDate}
                            minDate={
                              createCycleData.reviewingEndDate ||
                              createCycleData.reportingEndDate ||
                              createCycleData.selfEndDate ||
                              new Date()
                            }
                            onChange={(date) => {
                              dispatch({
                                type: "SET_CREATE_CYCLE_DATA",
                                payload: {
                                  ratingVisibleDate: date,
                                },
                              });
                            }}
                            placeholderText="Select Date"
                          />
                        </div>
                      </Form.Group>
                    )}
                  </div>
                  {createCycleData?.ratingVisibleDate && (
                    <div className={[style.cycle_calendars, "date_picker_time_wrapper", "p-0"].join(" ")}>
                      <Form.Group className="calendar_outer_wrapper">
                        <Form.Label>
                          Reminder notification of acknowledgement till <span className="text-danger">*</span>
                          <InfoTooltip heading="Employee will receive a reminder notification everyday till the date selected in Date picker for submitting acknowledgement" />
                        </Form.Label>
                        <div className="calendar_wrapper">
                          <ReactDatePicker
                            className={[
                              "form-control",
                              !createCycleData?.acknowledgementEndDate &&
                                errors?.acknowledgementEndDate &&
                                "limit-reached",
                            ].join(" ")}
                            dateFormat="MMMM d, yyyy"
                            minDate={moment(createCycleData?.ratingVisibleDate).add("days", 1)?._d}
                            selected={createCycleData?.acknowledgementEndDate}
                            onChange={(date) => {
                              dispatch({
                                type: "SET_CREATE_CYCLE_DATA",
                                payload: {
                                  acknowledgementEndDate: date,
                                },
                              });
                            }}
                            placeholderText="Select Date"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  )}
                  <button
                    type="submit"
                    onClick={createPerformance}
                    className={["btn-round save_box_btn btn btn-primary", style.cycle_btn_submit].join(" ")}
                  >
                    <strong>Create</strong>
                  </button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <QuestionTemplateModal
        hide={hide}
        previewPathname={routePath.PREVIEW_TEMPLATE_ROUTE}
        isSelectModal={isSelectModal}
        list={templates}
        selectTemplate={selectTemplate}
        show={isModal}
      />
    </div>
  );
}
