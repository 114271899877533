import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import style from "./Toast.module.scss";
import { IMG_TOAST_SUCCESS, IMG_TOAST_OOPS } from "../../const/imgCost";

class SlideToast extends Component {
  render() {
    const { title, message, error } = this.props;
    return (
      <div className={style.toastwrapper}>
        <span className={style.tostimage}>
          <ReactSVG src={error ? IMG_TOAST_OOPS : IMG_TOAST_SUCCESS} title="" alt="" />
        </span>
        <div className={style.toastcontent}>
          <p className={style.toastheader}>{title || (error ? "Error!" : "Awesome!")}</p>
          <p className={style.toasttext}>{message}</p>
        </div>
      </div>
    );
  }
}
export default SlideToast;
