import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { ONE_ON_ONE_AGENDA } from "util/endpoints";
import { status } from "const/constValue";
import http from "util/http";
import SlideToast from "components/Toast/Toast";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";

function ThirdPersonSelect({ managerList, oneOnOneAgendaId, selectedThirdPerson, isEdit }) {
  const onselectInvite = (e) => {
    let body,
      reviewer = null;
    if (e.target.value === "none") {
      body = {
        oneOnOneAgendaId: oneOnOneAgendaId,
        thirdPerson: null,
        none: "NONE",
      };
    } else {
      reviewer = JSON.parse(document.getElementById("reviewer_select").value);
      body = {
        oneOnOneAgendaId: oneOnOneAgendaId,
        thirdPerson: {
          employeeId: reviewer.employeeId,
          empName: reviewer.empName,
          displayPicture: reviewer.displayPicture,
          designation: reviewer.designation,
          department: reviewer.department,
          email: reviewer.email,
          subGroupId: reviewer.subGroupId,
          parentGroupId: reviewer.parentGroupId,
          subGroupName: reviewer.subGroupName,
          parentGroupName: reviewer.parentGroupName,
        },
      };
    }
    http
      .put(ONE_ON_ONE_AGENDA, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (!body.none) {
          if (response.status === status.SUCCESS) {
            toast.success(
              <SlideToast title="Awesome!" message="Invitation will be send after schedule" error={false} />
            );
          } else {
            toast.success(<SlideToast title="Error!" message={response.message.message} error={true} />);
          }
        }
      })
      .catch(() => {
        console.warn("in ERROR");
      });
  };

  return (
    <div className={[style.invite_view, "manage_comment_view"].join(" ")}>
      <span className="manage_comment_help">Would also like to invite</span>
      <div className="manage_comment_visible_to">
        <Form.Group className="no_mar">
          <Form.Control disabled={!isEdit} id={"reviewer_select"} as="select" size="md" onChange={onselectInvite}>
            <option key="rev-opt_none" value="" disabled selected={selectedThirdPerson ? "" : "selected"}>
              Choose option
            </option>
            {managerList.map((item) => {
              let selected =
                selectedThirdPerson && selectedThirdPerson.employeeId === item.employeeId ? " selected" : "";
              let option = {
                employeeId: item.employeeId,
                empName: item.empName,
                displayPicture: item.displayPicture || item.empInitial,
                department: item.dept,
                designation: item.designation,
                email: item.emailId,
                subGroupId: item.subGroupId,
                parentGroupId: item.parentGroupId,
                subGroupName: item.subGroupName,
                parentGroupName: item.parentGroupName,
              };
              return (
                <option selected={selected} key={"INVITE" + item.employeeId} value={JSON.stringify(option)}>
                  {item.empName}
                </option>
              );
            })}
            <option key="None_Person" value="none">
              None
            </option>
          </Form.Control>
        </Form.Group>
      </div>
      <span className="manage_comment_help">for this 1-on-1</span>
    </div>
  );
}

ThirdPersonSelect.propTypes = {
  oneOnOneAgendaId: PropTypes.string.isRequired,
};

export default ThirdPersonSelect;
