import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

export default function ReplaceManager({
  oldRPM,
  setOldRPM,
  oldRVM,
  setOldRVM,
  newRPM,
  newRVM,
  setNewRPM,
  setNewRVM,
  currentRPMList,
  currentRVMList,
  managerList,
}) {
  return (
    <>
      <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
        <Form.Group controlId="">
          <Form.Label>Select Current Reporting Manager</Form.Label>
          <div className="tags_wrapper">
            <Select
              className="custom-default-dd-box"
              classNamePrefix="select"
              isSearchable={true}
              options={currentRPMList}
              value={oldRPM}
              onChange={(e) => setOldRPM(e)}
            />
          </div>
        </Form.Group>
        <Form.Group controlId="">
          <Form.Label>Select New Reporting Manager</Form.Label>
          <div className="tags_wrapper">
            <Select
              className="custom-default-dd-box"
              classNamePrefix="select"
              isSearchable={true}
              options={managerList}
              value={newRPM}
              onChange={(e) => setNewRPM(e)}
            />
          </div>
        </Form.Group>
      </div>
      <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
        <Form.Group controlId="">
          <Form.Label>Select Current Reviewing Manager</Form.Label>
          <div className="tags_wrapper">
            <Select
              className="custom-default-dd-box"
              classNamePrefix="select"
              isSearchable={true}
              options={currentRVMList}
              value={oldRVM}
              onChange={(e) => setOldRVM(e)}
            />
          </div>
        </Form.Group>
        <Form.Group controlId="">
          <Form.Label>Select New Reviewing Manager</Form.Label>
          <div className="tags_wrapper">
            <Select
              className="custom-default-dd-box"
              classNamePrefix="select"
              isSearchable={true}
              options={managerList}
              value={newRVM}
              onChange={(e) => setNewRVM(e)}
            />
          </div>
        </Form.Group>
      </div>
    </>
  );
}
