import React from "react";
import PropTypes from "prop-types";

export default function KeyResults({ keyPoint }) {
  return (
    <li>
      <span className="title_kr">{keyPoint.description}</span>
      <span className="weightage_kr">Weightage: {keyPoint.weightage || "-"}</span>
      <span className="progress_kr">{keyPoint.percentage}%</span>
    </li>
  );
}

KeyResults.propTypes = {
  keyPoint: PropTypes.shape({
    description: PropTypes.string,
    percentage: PropTypes.number,
    weightage: PropTypes.number,
  }),
};

KeyResults.defaultProps = {
  keyPoint: PropTypes.shape({
    description: "",
    percentage: 0,
  }),
};
