import React from "react";
import { ReactSVG } from "react-svg";
import { checkArrow, getYesValue } from "util/general";
import ViewMoreText from "../../ViewMoreText/ViewMoreText";
import style from "./CampaignView.module.scss";

function CampaignQuestionAndAnswer({ questions, isCapability }) {
  return (
    <div className={style.campaign_question_answer_table}>
      <div className="custom-table">
        <div className={style.thead}>
          <div className={[style.question_name, isCapability ? "w-55" : ""].join(" ")}>
            <div className={style.question_name_heading}>Question List</div>
            <div className={style.question_name_description}>
              List of other questions and their answer are as follows
            </div>
          </div>
          <div className={[style.question_answer, isCapability ? "w-15" : ""].join(" ")}>Member Response</div>
          {isCapability && (
            <div className={[style.question_answer, isCapability ? "w-15" : ""].join(" ")}>Manager Response</div>
          )}
          {isCapability && (
            <div className={[style.question_answer, isCapability ? "w-15" : ""].join(" ")}>Expectancy</div>
          )}
        </div>
        <div className={style.tbody}>
          {questions?.length &&
            questions
              .filter((value) => value.type !== "RANK")
              ?.map((question) => {
                let answer, renderAnswer, managerAnswer, expected;
                if (question.type === "TEXTBOX") {
                  answer = question.answer;
                } else if (question.type === "SCALED" || question.type === "NPS") {
                  answer = question.scaleValue;
                } else if (question.type === "CHECKBOX") {
                  answer = question.options
                    .filter((answer) => answer.isSelected)
                    ?.map((answer) => answer.option)
                    .join(", ")
                    ? question.options
                        .filter((answer) => answer.isSelected)
                        ?.map((answer) => answer.option)
                        .join(", ") +
                      (question.otherTextAnswer //if user select other option from radio, checkbox and dropdown then other TextA
                        ? ", " + question.otherTextAnswer
                        : "")
                    : question.otherTextAnswer;
                } else {
                  answer = question.otherTextAnswer //if user select other option from radio, checkbox and dropdown then other TextA
                    ? question.otherTextAnswer
                    : question.options
                        .filter((answer) => answer.isSelected)
                        ?.map((answer) => answer.option)
                        .join(", ");
                  managerAnswer = isCapability && (
                    <div className={[style.td_question_answer, "w-15 pl-2"].join(" ")}>
                      {question.options.find((value) => value.isManagerSelected)?.option || "-"}
                    </div>
                  );
                  expected = isCapability && (
                    <div className={[style.td_question_answer, "w-15"].join(" ")}>
                      {question.options?.find((value) => value.isExpected)?.option ||
                        getYesValue(question.options) ||
                        "-"}
                      {(question.options?.find((value) => value.isExpected)?.option ||
                        getYesValue(question.options)) && (
                        <ReactSVG src={checkArrow(question.options)} title="" alt="info" className={style.arrow_svg} />
                      )}
                    </div>
                  );
                }
                if (question.type === "TEXTBOX") {
                  renderAnswer = (
                    <div className={style.td_question_answer}>
                      <ViewMoreText text={answer} length={140} />
                    </div>
                  );
                } else {
                  renderAnswer = (
                    <div
                      className={[style.td_question_answer, style.answerTooltip, isCapability ? "w-15 pl-2" : ""].join(
                        " "
                      )}
                    >
                      {answer && answer.length > 30 ? (
                        <>
                          <span>
                            <div className="truncate-250">{answer}</div>
                          </span>
                          <div className={style.answerTooltipInfo}>{answer}</div>
                        </>
                      ) : (
                        answer
                      )}
                    </div>
                  );
                }

                return (
                  <div className={style.td_wrapper} key={question.questionId}>
                    <div className={[style.td_question_name, isCapability ? "w-55" : ""].join(" ")}>
                      {question.questionName}
                    </div>
                    {renderAnswer}
                    {managerAnswer}
                    {expected}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}

export default CampaignQuestionAndAnswer;
