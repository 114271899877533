import React from "react";
import style from "./SearchAndClearFilter.module.scss";
import FilterBox from "../../shared/AllFilterBox/FilterBox";

export default function SearchAndClearFilter(props) {
  const {
    description,
    filterLocation,
    filterEmployee,
    filterDepartment,
    filterStatus,
    startDate,
    endDate,
    handleClear,
    handleFilter,
    filterOptions,
    departmentList,
    employeeNameList,
    handleFilterData,
    locationList,
    isDisplayStartDate,
    isDisplayEndDate,
    isDisplayMemberType,
    memberTypeList,
    filterMemberType,
    statusWiseOptions,
    filterBand,
    bandList,
    isDisplayDesignation,
    designationList,
    isDisplayBandDropdown,
    searchEmployeeBox,
    getEmployeeNameList,
    maxEndDate,
    filterDesignation,
    maxStartDate,
    displayStatus,
    isDisplaySubGroup,
    subGroupClient,
    filterSubGroups,
    isSubGroupSingleSelect,
    displayLocation,
    displayDepartment,
  } = props;
  return (
    <div className={style.feedback_wrapper}>
      <div className={style.feedback_inner_wrapper}>
        <div className={[style.employee_list_filter_wrapper, "box"].join(" ")}>
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>Filter(s)</h2>
              <h3>{description}</h3>
            </div>
          </div>
          <div className="box-light-gradient"></div>
          <div className={style.filter_info}>
            <FilterBox
              displayBand={isDisplayBandDropdown}
              displaySubgroups={isDisplaySubGroup}
              displayStartDate={isDisplayStartDate}
              displayEndDate={isDisplayEndDate}
              displayMemberType={isDisplayMemberType}
              displayLocation={displayLocation}
              displayDepartment={displayDepartment}
              employeeNameList={employeeNameList}
              filterEmployee={filterEmployee}
              memberTypeList={memberTypeList}
              filterMemberType={filterMemberType}
              bandList={bandList}
              subGroupClient={subGroupClient}
              isSubGroupSingleSelect={isSubGroupSingleSelect}
              filterSubGroups={filterSubGroups}
              displayDesignation={isDisplayDesignation}
              designationOption={designationList}
              filterBand={filterBand}
              locationList={locationList}
              getEmployeeNameList={getEmployeeNameList}
              filterOptions={filterOptions}
              searchEmployeeBox={searchEmployeeBox}
              filterLocation={filterLocation}
              departmentList={departmentList}
              filterDepartment={filterDepartment}
              statusWiseOptions={statusWiseOptions}
              filterStatus={filterStatus}
              displayStatus={displayStatus}
              startDate={startDate}
              endDate={endDate}
              handleFilterData={handleFilterData}
              maxEndDate={maxEndDate}
              maxStartDate={maxStartDate}
              filterDesignation={filterDesignation}
            />
            <div className={style.filter_btn_group}>
              <button
                type="button"
                className={["btn-round save_box_btn btn btn-primary mr-3", style.create_new_btn].join(" ")}
                onClick={handleFilter}
              >
                <strong>Search</strong>
              </button>
              <button
                type="button"
                className={["btn-round save_box_btn btn btn-secondary", style.create_new_btn].join(" ")}
                onClick={handleClear}
              >
                <strong>Clear</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchAndClearFilter.defaultProps = {
  maxEndDate: null,
  maxStartDate: null,
};
