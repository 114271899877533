import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";

const CurrentDraftCheckIn = (props) => {
    return (
        <Container>
            <Row className={["align-items-center"]}>
                <Col className={["col-12"]} md={6}>
                    <div className="currrentCheckInTime">
                    <span className="label">Current OKR Progress:</span>
                    <span className="data">
                        { props.startDate } – {" "} { props.endDate }
                    </span>
                    </div>
                </Col>
                <Col className={["col-12"]} md={6}>
                   {props.dateDisplay.date && <div className="draftSaved">
                                                <span className="label">{props.dateDisplay.text}</span>
                                                <span className="data">{props.dateDisplay.date && moment(props.dateDisplay.date).format("ddd D MMM h:mm a")}</span>
                                            </div>} 
                </Col>
            </Row>
        </Container>
    )
}

export default CurrentDraftCheckIn;