import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as actions from "store/actions/index";
import { getRandomColor } from "util/color";
import { scrollToTop, mergeArray } from "util/general";
import AuthContext from "context/authContext";
import Spinner from "shared/Spinner/Spinner";
import PreviousCheckIns from "shared/PreviousCheckIns";
import Questions from "components/questions/questions";
import ReviewerProfile from "components/reviewer-profile/reviewer-profile";
import Waitingforsubmition from "components/WaitingforSubmition/Waitingforsubmition";
import Pulse from "components/Pulse/pulse";
import NoDataFound from "components/NodataFound/NoDataFound";
import storage from "util/storage";
import { noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function MyPage(props) {
  const context = useContext(AuthContext);
  const loggedInUser = storage.getUser();
  const dispatch = useDispatch();
  const [selectedColor, setSelectedColor] = useState(getRandomColor());
  const [currentSurveyCycle, setCurrentSurveyCycle] = useState(0);
  const [currentSurveyCycleID, setCurrentSurveyCycleID] = useState(0);
  const [userCurrent, setUserCurrent] = useState();
  const [isExtendedDate, setIsExtendedDate] = useState(true);
  const [isFlag, setIsFlag] = useState(false);
  const [isLapse, setIsLapse] = useState(false);
  const { user, currentCheckIn, data, surveyData, moodoMeter, isLoading } = useSelector((state) => state.myHistory);
  const [prevSurveyCycle, setPrevSurveyCycle] = useState(data && data.surveyCyclesId);
  const employeeIds = {
    employeeId: context.state.user?.employeeId,
    parentGroupId: context.state.user?.parentGroupId,
    subGroupId: context.state.user?.subGroupId,
  };

  // componentDidUpdate (user) ???
  useEffect(() => {
    let currentUser = context.state.user;
    setUserCurrent({
      employeeId: currentUser.employeeId,
      employeeInitial: currentUser.empInitial,
      name: currentUser.displayName,
      designation: currentUser.designation,
      department: currentUser.department,
      image: currentUser.displayPicture,
      band: currentUser.band,
      firstName: currentUser.fname,
      totalExp: currentUser.totalExp,
      expBeforeJoining: currentUser.expBeforeJoining,
      isExp: currentUser.isExp,
      due_date: currentUser.endDate,
      completed: 5, // TODO: need to replace this values once we get from Service Response
      total: 10, // TODO: need to replace this values once we get from Service Response
      subGroupName: currentUser.subGroupName,
      subGroupId: currentUser.subGroupId,
      parentGroupName: currentUser.parentGroupName,
      parentGroupId: currentUser.parentGroupId,
    });
  }, [context.state.user]);

  useEffect(() => {
    if (!prevSurveyCycle) {
      dispatch({ type: "REMOVE_SURVEY_CYCLE_ID" });
      dispatch({ type: "REMOVE_IS_LOADING_OBJECTIVE" });
    }
  });
  useEffect(() => {
    setSelectedColor(getRandomColor());
    dispatch(actions.GetMyHistoryReviewer(employeeIds));
    setPrevSurveyCycle("");
    scrollToTop(500);
    if (props.location.state) {
      let Id = props.location.state;
      setCurrentSurveyCycle(Id);
      setPrevSurveyCycle(Id);
      dispatch(actions.GetMySurveyHistory(context.state.user, employeeIds, Id));
      // dispatch(actions.HandleSurveyClick({ employeeId, surveyCycleId: Id }));
    } else dispatch(actions.GetMySurveyHistory(context.state.user, employeeIds));
  }, [props.location.state, dispatch]);

  useEffect(() => {
    //  if(new Date(checkExtensionDate?.[0]?.extendedDate) > new Date()){
    currentSurveyCycleID && dispatch(actions.FetchPastCheckIn(currentSurveyCycleID));
    //  }
    const checkExtensionDate = surveyData?.filter((value) => value.surveyCyclesId === currentSurveyCycleID);
    setIsFlag(new Date(checkExtensionDate?.[0]?.extendedDate) > new Date());
    if (checkExtensionDate?.[0]?.extendedDate === null) {
      setIsExtendedDate(false);
    } else {
      setIsExtendedDate(true);
    }
  }, [currentSurveyCycleID,surveyData]);

  useEffect(() => {
    let cycleEndDate = moment(moment(data?.endDate).add(10, 'd').format('YYYY/MM/DD HH:mm:ss'));
    let todayDate = moment(moment(new Date()).format('YYYY/MM/DD HH:mm:ss'));

    if (cycleEndDate < todayDate) {
      setIsLapse(true);
    } else {
      setIsLapse(false);
    }
  }, [data])

  useEffect(() => {
    setCurrentSurveyCycleID(surveyData?.[0]?.surveyCyclesId);
  }, [surveyData]);

  const surveyClick = (surveyCycleId) => {
    dispatch({ type: "IS_CYCLE_CHANGE" ,payload: false});
    if (surveyCycleId !== prevSurveyCycle) {
      dispatch(actions.HandleSurveyClick({ ...employeeIds, surveyCycleId }));
      dispatch(actions.GetPlans(surveyCycleId));
      setPrevSurveyCycle(surveyCycleId);
      setCurrentSurveyCycleID(surveyCycleId);
    }
  };

  let displayDate = null;
  let employeeCycle = null;

  let initialManagerList = [];
  let allocationManagers = [];
  let managersUnique = [];
  let reviewers = {
    managersList: [],
  };

  if (data && data.reviewerList && data.reviewerList.managersList && data.reviewerList.managersList.length > 0) {
    reviewers = data.reviewerList;
  } else {
    if (data) {
      initialManagerList = mergeArray(data.rpm, data.rvm, "employeeId");

      if (data && data.projectAllocation) {
        data.projectAllocation.map((value) => {
          if (value.projectManager) {
            allocationManagers.push(...value.projectManager);
          }

          if (value.projectDeliveryManager) {
            allocationManagers.push(...value.projectDeliveryManager);
          }

          if (value.projectReportingManager) {
            allocationManagers.push(...value.projectReportingManager);
          }

          if (value.projectReviewingManager) {
            allocationManagers.push(...value.projectReviewingManager);
          }
          return null;
        });
      }

      managersUnique = allocationManagers.filter(
        // eslint-disable-next-line no-undef
        (
          (set) => (f) =>
            !set.has(f.employeeId) && set.add(f.employeeId)
        )(
          // eslint-disable-next-line no-undef
          new Set()
        )
      );

      reviewers.managersList = mergeArray(initialManagerList, managersUnique, "employeeId");
    }
  }

  if (data) {
    if (data.status === "PENDING_FROM_MEMBER" && user) {
      employeeCycle = (
        <Col lg={8}>
          {!isLapse || isFlag && isExtendedDate ?
            <Questions
              isPage="checkin"
              isSubmitAPI="checkinFromHistroy"
              data={data}
              managerView={false}
              currentSurveyCycleID={currentSurveyCycleID}
              checkinQuestionData={{ employee: user, readOnly: true, defaultOpen: true, viewCommentIcon: false }}
              user={userCurrent}
              isPast={true}
              moodoMeter={moodoMeter}
            /> :
            <Waitingforsubmition
              image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
              name={loggedInUser?.displayName}
              designation={loggedInUser?.designation}
              department={loggedInUser?.department}
              date={data.endDate}
            />}
        </Col>
      );
    } else {
      employeeCycle = (
        <Col lg={8}>
          {data.questionsAnswer?.length > 0 && (
            <Pulse
              managerView={false}
              selectedColor={selectedColor}
              status={data.status}
              question={moodoMeter[0]}
              surveyCycleID={data.surveyCyclesId}
            />
          )}
          <Questions
            isPage="myhistory"
            isPastHistory={true}
            currentSurveyCycleID={currentSurveyCycleID}
            selectedColor={selectedColor}
            managerView={false}
            checkinQuestionData={{ employee: user, readOnly: true, defaultOpen: true, viewCommentIcon: false }}
          />
        </Col>
      );
    }
    if (data.status !== "PENDING_FROM_MEMBER") {
      if (data.reviewedDate) {
        displayDate = (
          <div className="draftSaved mt-2">
            <span className="label">Reviewed On :</span>
            <span className="data">{moment(data.reviewedDate).format("ddd D MMM h:mm a")}</span>
          </div>
        );
      } else {
        displayDate = (
          <div className="draftSaved mt-2">
            <span className="label">Submitted On :</span>
            <span className="data">{moment(data.submitedDate).format("ddd D MMM h:mm a")}</span>
          </div>
        );
      }
    }
  }


  return (
    <div>
      <SubNavigation type="OKR Progress" />
      {!surveyData ? (
        <Spinner />
      ) : surveyData && surveyData.length > 0 ? (
        <>
          <Container>
            <Row>
              <Col md={6} className="col-12">
                <h1 className="page-title pb-0">OKR Progress</h1>
                <span className="st-breadcrumb">
                OKR Progress
                  <span>My History</span>
                  <span>
                    {currentCheckIn && currentCheckIn.start} – {currentCheckIn && currentCheckIn.end}
                  </span>
                </span>
              </Col>
              <Col className="col-12" md={6}>
                {displayDate}
              </Col>
              <Col className={["col-12"]}>
                {surveyData && (
                  <PreviousCheckIns
                    currentSurveyCycle={currentSurveyCycle}
                    surveyClick={surveyClick}
                    setCurrentSurveyCycleID={setCurrentSurveyCycleID}
                    data={surveyData}
                  />
                )}
              </Col>
            </Row>
          </Container>
          <Container>
            {isLoading ? <Spinner /> : null}

            <Row>
              {employeeCycle}
              <Col lg={4}>
                {reviewers?.managersList?.length > 0 && <ReviewerProfile managerList={reviewers.managersList} />}
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <Container>
          <Row>
            <Col md={12} className="col-12">
              <h1 className="page-title pb-0">OKR Progress</h1>
              <span className="st-breadcrumb">
              OKR Progress
                <span>My History</span>
              </span>
            </Col>
            <Col md={12}>
              <div className="box">
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap80"></div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
