import React from "react";
import { Link } from "react-router-dom";
import config from "util/config";

const withLink = (WrappedComponent) => (props) => {
  const newProps = {
    user: {
      ...props.user,
      displayName: (
        <Link
          style={{ color: "#322E46" }}
          to={`/okrprogress/my-team/member-okrprogress/dashboard/${props.user.employeeId}${config.employeeIdSplit}${props.user?.parentGroupId}${config.employeeIdSplit}${props.user?.subGroupId}`}
        >
          {props.user.displayName}
        </Link>
      ),
    },
  };
  return <WrappedComponent {...newProps} />;
};

export default withLink;
