import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import {
  ADD_WIN_CHALLENGE,
  VIEW_WIN_CHALLENGE,
  DRAG_DROP_WIN_CHALLENGE,
  CHECK_WIN_CHALLENGE,
  DELETE_WIN_CHALLENGE,
} from "../../util/endpoints";
import { status } from "const/constValue";

export const setFetchWinChallenge = (challengeType, response) => {
  switch (challengeType) {
    case "WIN":
      return {
        type: actionTypes.SET_FETCH_WIN,
        wins: response,
      };
    case "CHALLENGE":
      return {
        type: actionTypes.SET_FETCH_CHALLENGE,
        challenges: response,
      };
    case "COLLECTIVE":
      return {
        type: actionTypes.SET_FETCH_COLLECTIVE,
        collectives: response,
      };
    default:
      return {};
  }
};

export const FetchWinChallenge = (empIds, managerIds, filterByMonth, challengeType) => {
  const params = {
    employeeId: empIds?.employeeId,
    // managerId: managerIds?.managerId,
    filterByMonth: filterByMonth,
    type: challengeType,
    employeeSubGroupId: empIds?.subGroupId,
    employeeParentGroupId: empIds?.parentGroupId,
    // managerParentGroupId: managerIds?.parentGroupId,
    // managerSubGroupId: managerIds?.subGroupId,
  };

  return (dispatch) => {
    http
      .get(VIEW_WIN_CHALLENGE, {}, { params })
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          dispatch(setFetchWinChallenge(challengeType, response));
        } else {
          dispatch(setFetchWinChallenge(challengeType, []));
        }
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          dispatch(setFetchWinChallenge(challengeType, []));
        }
      });
  };
};

export const CheckWinChallenge = (surveyCycleId, employeeIds) => {
  const params = {
    surveyCycleId: surveyCycleId,
    employeeParentGroupId: employeeIds.parentGroupId,
    employeeSubGroupId: employeeIds.subGroupId,
    employeeId: employeeIds.employeeId,
  };

  return (dispatch) => {
    http
      .get(CHECK_WIN_CHALLENGE, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.data) dispatch({ type: actionTypes.SET_QUESTIONS, payload: { existWinChallenge: response.data } });
        else dispatch({ type: actionTypes.SET_QUESTIONS, payload: { existWinChallenge: [] } });
      })
      .catch(() => {});
  };
};

export const addWinChallenge = (surveyCycleId, employeeIds, questionId, type) => {
  const params = {
    surveyCyclesId: surveyCycleId,
    employeeId: employeeIds,
    questionId: questionId,
    type: type,
  };

  return (dispatch) => {
    http
      .post(ADD_WIN_CHALLENGE, {}, params)
      .then((response) => response.data)
      .then((response) => {
        if (response) {
          let addMessage =
            response.status === status.SUCCESS ? `Successfully added to ${type}` : `Unable to add this to ${type}`;
          dispatch({
            type: actionTypes.SET_ADD_WIN_CHALLENGE,
            addWinChallengeResponse: { status: response.status, message: addMessage },
          });
          dispatch(CheckWinChallenge(surveyCycleId, employeeIds));
        }
      })
      .catch(() => {});
  };
};

export const onDeleteWinChallenge = (winChallengeId, type) => {
  return (dispatch) => {
    http
      .delete(DELETE_WIN_CHALLENGE, {}, {}, { winChallengeId })
      .then((response) => {
        if (response) {
          dispatch({ type: actionTypes.DELETE_WIN_CHALLENGE, challenge: { winChallengeId, type } });
        }
      })
      .catch(() => {});
  };
};

export const dragDropWinChallenge = (winChallengeId, updatedBy, fetchData, toType, fromType) => {
  const bodyData = {
    type: toType,
    updatedBy,
    winAndChallengeId: winChallengeId,
  };
  return (dispatch) => {
    http
      .put(DRAG_DROP_WIN_CHALLENGE, {}, bodyData)
      .then((response) => response.data)
      .then((response) => {
        if (response) {
          let message =
            response.status === status.SUCCESS
              ? `Successfully moved this ${fromType} into ${toType}`
              : `Unable to move this into ${fromType} into ${toType}`;
          dispatch({
            type: actionTypes.SET_DRAG_DROP_WIN_CHALLENGE,
            dragDropWinChallengeResponse: { status: response.status, message },
          });
          if (fetchData) {
            dispatch(FetchWinChallenge(fetchData, {}, fetchData.filterByMonth, fromType));
            dispatch(FetchWinChallenge(fetchData, {}, fetchData.filterByMonth, toType));
          }
        }
      })
      .catch(() => {});
  };
};
