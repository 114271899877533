import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { IC_INPUT_SEARCH } from "const/imgCost";
import { noData } from "const/constValue";
import { filterArray } from "util/general";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import MyTeamRow from "./MyTeamRow";
import Pagination from "../../Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";

export default function MyTeamTab({ previous, upComing, lapsed, pending, filter }) {
  const [teamData, setTeamData] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const { reporteeList } = useSelector((state) => state.oneOnOne);
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  let className = "form-control-rounded form-search-item";
  if (searchFilter && searchFilter.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  useEffect(() => {
    let displayEmployee = [];
    if (filter && filter !== "ALL") displayEmployee = reporteeList.filter((v) => v[filter]);
    else displayEmployee = reporteeList;
    setTeamData(displayEmployee);
    setSearchFilter("");
  }, [reporteeList, filter]);

  useEffect(() => {
    filteredData.length > 0
      ? setTeamData(filteredData)
      : searchFilter
      ? setTeamData(filteredData)
      : setTeamData(filter && filter !== "ALL" ? reporteeList.filter((v) => v[filter]) : reporteeList);
  }, [filteredData, reporteeList, searchFilter]);

  const search = (e) => {
    let filteredData = null;
    const filters = {
      empName: e.target.value,
    };
    filteredData = filterArray(reporteeList, filters);
    setFilteredData(filteredData);
    setSearchFilter(e.target.value);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
    };
    filteredData = filterArray(reporteeList, filters);
    setFilteredData(filteredData);
    setSearchFilter("");
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
    };
    if (reporteeList.length > 0) {
      filteredData = filterArray(teamData, filters);
      setFilteredData(filteredData);
    }
  };

  return (
    <Fragment>
      <div className={style.one_on_one_info_wrapper}>
        <div className={style.one_on_one_filter}>
          <Row className="align-items-center">
            <Col xs={12} sm={12} md={6} lg={6} xl={8}>
              <div className={style.viewer_info}>
                <strong>My Team({(teamData && teamData.length) || 0}) </strong>
                <span>Tap on a member name to know more about 1-on-1s</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
              <div className={style.search_bar}>
                <Form>
                  <Form.Group className="form-postfix mb-0">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Search for team members"
                      name="searchBox"
                      className={className}
                      onChange={search}
                      value={searchFilter}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <Button className="close-button" type="reset" onClick={resetSearch}>
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
        <div className="box-light-gradient"></div>
        <div className={style.one_on_one_table}>
          <div className="custom-table table-theme-1 pt40">
            {pageOfItems && pageOfItems.length > 0 ? (
              <Table hover responsive className={style.tab_table_responsive}>
                <thead>
                  <tr>
                    <th>
                      <span className={style.name}>Name</span>
                    </th>
                    {previous && <th className={style.center}>Previous 1-on-1</th>}
                    {upComing && <th className={style.center}>Upcoming 1-on-1</th>}
                    {lapsed && <th className={style.center}>Lapsed 1-on-1</th>}
                    {pending && <th className={style.center}>Pending 1-on-1</th>}
                  </tr>
                </thead>
                <tbody>
                  {pageOfItems &&
                    pageOfItems.length > 0 &&
                    pageOfItems.map((value, index) => (
                      <MyTeamRow
                        key={value.employeeData.employeeId}
                        index={index}
                        team={value.employeeData}
                        previous={previous}
                        upComing={upComing}
                        lapsed={lapsed}
                        pending={pending}
                        lapsedOneOnOne={value.lapsedOneOnOne}
                        previousOneOnOne={value.previousOneOnOne}
                        upcomingOneOnOne={value.upcomingOneOnOne}
                        isPendingOneOnOne={value.isPendingOneOnOne}
                      />
                    ))}
                </tbody>
              </Table>
            ) : (
              <div>
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap120"></div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Pagination pageSize={"10"} items={teamData} resetData={resetData} onChangePage={onChangePage} />
      </div>
    </Fragment>
  );
}
