import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import {
  GET_HR_DASHBOARD_REPORT,
  GET_HR_DASHBOARD_EXCEL_REPORT,
  GET_FILTERED_EMPLOYEES_OBJECTIVES,
  EMPLOYEE_OBJECTIVE_CHART_DATA,
  EMPLOYEE_OBJECTIVE_CHART_EXPORT,
  GLOBAL_FEEDBACK_LIST_FOR_HR,
  GLOBAL_FEEDBACK_REPORT,
  GET_HR_QUARTERLY_CYCLE_LIST,
  GET_HR_EMPLOYEE_NAME_LIST,
  GET_CURRENT_MANAGERS_LIST,
  GET_NEW_MANAGERS_LIST,
  PROJECT_LIST,
  GET_ALL_CYCLE_NAME,
  OBJECTIVE_GRADE_COUNT,
  PRIVATE_ASSESSMENT_COUNT,
  EMPLOYEE_VIEW_ASSESSMENT_COMPARISON,
  EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_EXPORT,
  OBJECTIVE_GRADE_REPORT,
  PRIVATE_ASSESSMENT_COUNT_REPORT,
  PRIVATE_ASSESSMENT_POPUP_REPORT,
  OBJECTIVE_GRADE_POPUP_REPORT,
  GET_ASSESSMENT_REPORT_CHART_DATA,
  DROPDOWN_OPTIONS,
  PRIVATE_ASSESSMENT_POPUP,
  OBJECTIVE_GRADE_POPUP,
  GET_QUARTERLY_CYCLE_LIST,
  HR_DASHBOARD_FILTER_OPTION,
} from "../../util/endpoints";
import { saveAs } from "file-saver";
import { status } from "const/constValue";
import {
  getKeyFromObject,
  getKeyFromObjectWithCompositeId,
  getListOfValue,
  setDropdownOption,
  setDropdownOptionForEmployee,
} from "util/general";

export const getHrDashboardReport = (dateValue, filterValue) => {
  let body = {
    ...dateValue,
    subGroupIds: getKeyFromObject(filterValue?.filterSubGroups),
    indiaDDs: getKeyFromObjectWithCompositeId(filterValue?.filterIndiaDD),
    managers: getKeyFromObjectWithCompositeId(filterValue?.filterManager),
    locations: getKeyFromObject(filterValue?.filterLocation),
    projectIds: getKeyFromObject(filterValue?.filterProject),
    departments: getKeyFromObject(filterValue?.filterDepartment),
    employeeTypes: getKeyFromObject(filterValue?.filterMemberType),
  };
  return (dispatch) => {
    dispatch({ type: "START_HR_DASHBOARD_LOADING" });
    dispatch({
      type: actionTypes.SHOW_HR_LOADER,
    });
    http
      .post(GET_HR_DASHBOARD_REPORT, {}, body)
      .then((response) => response)
      .then((response) => {
        if (response.data.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.GET_HR_DASHBOARD_REPORT,
            payload: response["data"]["data"],
          });
        } else {
          dispatch({
            type: actionTypes.GET_HR_DASHBOARD_REPORT,
            payload: [],
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ERROR_GET_HR_DASHBOARD_REPORT,
        });
      });
  };
};

export const getHrDashboardFilterOptions = (subGroups, type) => {
  let body = {
    subGroupIds: getKeyFromObject(subGroups),
  };
  return (dispatch) => {
    dispatch({ type: "START_HR_DASHBOARD_LOADING" });
    http
      .post(HR_DASHBOARD_FILTER_OPTION, {}, body)
      .then((response) => response)
      .then((response) => {
        if (response.data.status === status.SUCCESS) {
          if (response.data?.data) {
            const isEmpty = getKeyFromObject(subGroups)?.length > 0 || type === "HR_ONE_ON_ONE" ? false : true;
            dispatch({
              type: actionTypes.GET_HR_DASHBOARD_FILTER_OPTION,
              payload: {
                managerOptions: isEmpty ? [] : setDropdownOptionForEmployee(response.data.data?.managers),
                memberTypeList: isEmpty ? [] : setDropdownOption(response.data.data?.employeeType),
                indiaDDOption: isEmpty ? [] : setDropdownOptionForEmployee(response.data?.data?.indiaDDs),
                departmentList: isEmpty ? [] : setDropdownOption(response.data?.data?.departments),
                locationOption: isEmpty ? [] : setDropdownOption(response.data?.data?.locations),
                projectList: isEmpty ? [] : response.data.data?.projects,
              },
            });
            if (isEmpty) {
              dispatch({
                type: actionTypes.SET_HR_DASHBOARD_MENU_CHECKED,
                data: {
                  subGroupClient: false,
                  memberTypeList: false,
                  locationOption: false,
                  departmentList: false,
                  projectList: false,
                  indiaDDOption: false,
                  managerOptions: false,
                },
              });
            }
            dispatch({
              type: actionTypes.SET_HR_DASHBOARD_MENU_COUNT,
              data: {
                managerOptions: isEmpty ? 0 : response.data.data?.managers.length,
                memberTypeList: isEmpty ? 0 : response.data.data?.employeeType.length,
                indiaDDOption: isEmpty ? 0 : response.data.data?.indiaDDs.length,
                departmentList: isEmpty ? 0 : response.data.data?.departments.length,
                locationOption: isEmpty ? 0 : response.data.data?.locations.length,
                projectList: isEmpty ? 0 : response.data.data?.projects.length,
              },
            });
          } else {
            dispatch({
              type: actionTypes.GET_HR_DASHBOARD_FILTER_OPTION,
              payload: {},
            });
          }
        } else {
          dispatch({
            type: actionTypes.GET_HR_DASHBOARD_FILTER_OPTION,
            payload: {},
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ERROR_GET_HR_DASHBOARD_REPORT,
        });
      });
  };
};

export const getHrDashboardEmployeeTypeOptions = (config) => {
  return (dispatch) => {
    http
      .post(HR_DASHBOARD_FILTER_OPTION, {}, {}, config)
      .then((response) => response)
      .then((response) => {
        if (response.data.status === status.SUCCESS) {
          if (response.data?.data) {
            dispatch({
              type: actionTypes.GET_HR_DASHBOARD_EMPLOYEE_TYPE_FILTER_OPTION,
              payload: response.data.data?.employeeType ? setDropdownOption(response.data.data?.employeeType) : [],    
            });
          } else {
            dispatch({
              type: actionTypes.GET_HR_DASHBOARD_EMPLOYEE_TYPE_FILTER_OPTION,
              payload: {},
            });
          }
        } 
      })
      .catch(() => {
        dispatch({
          type: actionTypes.GET_HR_DASHBOARD_EMPLOYEE_TYPE_FILTER_OPTION,
        });
      });
  };
};

export const getHrDashboardExportReport = (dateValue, filterValue) => {
  let body = {
    subGroupIds: getKeyFromObject(filterValue?.filterSubGroups),
    indiaDDs: getKeyFromObjectWithCompositeId(filterValue?.filterIndiaDD),
    managers: getKeyFromObjectWithCompositeId(filterValue?.filterManager),
    locations: getKeyFromObject(filterValue?.filterLocation),
    projectIds: getKeyFromObject(filterValue?.filterProject),
    departments: getKeyFromObject(filterValue?.filterDepartment),
    employeeTypes: getKeyFromObject(filterValue?.filterMemberType),
  };
  return (dispatch) => {
    // dispatch({ type: "START_HR_DASHBOARD_LOADING" });
    dispatch({ type: "START_FILE_DOWNLOAD_IN_BACKGROUND" });
    http
      .post(GET_HR_DASHBOARD_EXCEL_REPORT, dateValue, body, { responseType: "arraybuffer" })
      .then((response) => {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let filename = "HR_DashBoard.xlsx";
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.GET_HR_DASHBOARD_EXCEL_REPORT,
          payload: { success: true, message: "Downloading file ." },
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.GET_HR_DASHBOARD_EXCEL_REPORT_ERROR,
          payload: { error: true, message: "Error Downloading ." },
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getEmployeeObjectiveForHR = (startDate, endDate, isObjectiveAssigned, filterValue) => {
  return (dispatch) => {
    let body = {
      startDate,
      endDate,
      isObjectiveAssigned: isObjectiveAssigned ? isObjectiveAssigned : undefined,
      subGroupId: filterValue?.filterSubGroups ? filterValue?.filterSubGroups?.value || undefined : undefined,
      location: getListOfValue(filterValue?.filterLocation),
    };
    dispatch({
      type: actionTypes.SHOW_HR_LOADER,
    });

    http
      .post(GET_FILTERED_EMPLOYEES_OBJECTIVES, {}, body)
      .then((response) => response.data)
      .then((data) => {
        let employeeData = [];
        if (data.data) {
          employeeData = data.data;
        }
        dispatch({
          type: actionTypes.SET_ALL_EMPLOYEE_OBJECTIVE,
          payload: {
            allEmployeeObjectiveData: employeeData,
            isLoading: false,
          },
        });
      });
  };
};

export const getEmployeeObjectiveChartData = (dateValue, filterValue) => {
  let body = {
    ...dateValue,
    subGroupIds: getKeyFromObject(filterValue?.filterSubGroups),
    indiaDDs: getKeyFromObjectWithCompositeId(filterValue?.filterIndiaDD),
    managers: getKeyFromObjectWithCompositeId(filterValue?.filterManager),
    locations: getKeyFromObject(filterValue?.filterLocation),
    projectIds: getKeyFromObject(filterValue?.filterProject),
    departments: getKeyFromObject(filterValue?.filterDepartment),
    employeeTypes: getKeyFromObject(filterValue?.filterMemberType),
  };
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_HR_LOADER,
    });
    http
      .post(EMPLOYEE_OBJECTIVE_CHART_DATA, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: actionTypes.EMPLOYEE_OBJECTIVE_CHART_DATA,
            payload: response.data,
          });
        } else {
          dispatch({
            type: actionTypes.EMPLOYEE_OBJECTIVE_CHART_DATA,
            payload: {},
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EMPLOYEE_OBJECTIVE_CHART_DATA,
          payload: {},
        });
      });
  };
};

export const getAssessmentReportChartData = (cycleId, filterValue) => {
  let body = {
    cycleId,
    subGroupIds: getKeyFromObject(filterValue?.filterSubGroups),
    indiaDDs: getKeyFromObjectWithCompositeId(filterValue?.filterIndiaDD),
    managers: getKeyFromObjectWithCompositeId(filterValue?.filterManager),
    locations: getKeyFromObject(filterValue?.filterLocation),
    projectIds: getKeyFromObject(filterValue?.filterProject),
    departments: getKeyFromObject(filterValue?.filterDepartment),
    employeeTypes: getKeyFromObject(filterValue?.filterMemberType),
  };
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_LOADER,
    });
    let assessmentReportChartData = [];
    http
      .post(GET_ASSESSMENT_REPORT_CHART_DATA, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.data) {
          assessmentReportChartData = response.data.map((value) => {
            return {
              ...value,
              name: value.type,
              Submitted: value.submittedCount,
              Pending: value.pendingCount,
            };
          });
          dispatch({
            type: actionTypes.SET_ASSESSMENT_REPORT_CHART_DATA,
            payload: assessmentReportChartData,
          });
        } else {
          dispatch({
            type: actionTypes.SET_ASSESSMENT_REPORT_CHART_DATA,
            payload: [],
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_ASSESSMENT_REPORT_CHART_DATA,
          payload: [],
        });
      });
  };
};

export const getEmployeeObjectiveExportReport = (dateValue, filterValue) => {
  let body = {
    ...dateValue,
    subGroupIds: getKeyFromObject(filterValue?.filterSubGroups),
    indiaDDs: getKeyFromObjectWithCompositeId(filterValue?.filterIndiaDD),
    managers: getKeyFromObjectWithCompositeId(filterValue?.filterManager),
    locations: getKeyFromObject(filterValue?.filterLocation),
    projectIds: getKeyFromObject(filterValue?.filterProject),
    departments: getKeyFromObject(filterValue?.filterDepartment),
    employeeTypes: getKeyFromObject(filterValue?.filterMemberType),
  };
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_HR_LOADER,
    });
    http
      .post(EMPLOYEE_OBJECTIVE_CHART_EXPORT, {}, body, { responseType: "arraybuffer" })
      .then((response) => {
        if (response.data) {
          // eslint-disable-next-line no-undef
          var byteArray = new Uint8Array(response.data);
          var blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let filename = "HR_DashBoard_Employee_Objective_Status.xlsx";
          saveAs(blob, filename);
          dispatch({
            type: actionTypes.EMPLOYEE_OBJECTIVE_CHART_EXPORT_REPORT,
            payload: { success: true, message: "File downloaded successfully ." },
          });
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EMPLOYEE_OBJECTIVE_CHART_EXPORT_REPORT,
          payload: { error: true, message: "Error Downloading file ." },
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getGlobalFeedBackListForHR = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_HR_LOADER,
  });
  http
    .get(GLOBAL_FEEDBACK_LIST_FOR_HR, params)
    .then((response) => response.data)
    .then((data) => {
      if (data.data) {
        dispatch({
          type: actionTypes.SET_GLOBAL_FEEDBACK_LIST,
          payload: {
            globalFeedbackList: data.data,
            isLoading: false,
          },
        });
      }
    });
};

export const downloadGlobalFeedBackReport = (param) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_HR_LOADER,
  });
  http
    .get(GLOBAL_FEEDBACK_REPORT, param, { responseType: "arraybuffer" })
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = "Global Feedback History.xlsx";
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.EMPLOYEE_OBJECTIVE_CHART_EXPORT_REPORT,
          payload: { success: true, message: "File downloaded successfully ." },
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.EMPLOYEE_OBJECTIVE_CHART_EXPORT_REPORT,
        payload: { error: true, message: "Error Downloading file ." },
      });
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    });
};

export const getHREmployeeNameList = (name) => (dispatch) => {
  http
    .get(GET_HR_EMPLOYEE_NAME_LIST, { name: name })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let employeeList = [];
        if (response.data) {
          employeeList = response.data.map((value) => {
            return {
              value: value.employeeId,
              label: `${value.empName} (${value.employeeId})`,
              ...value,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_HR_EMPLOYEE_NAME_LIST,
          data: employeeList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getCurrentManagersList = (employeeIds, cycleId) => (dispatch) => {
  let params = {
    employeeId: employeeIds?.employeeId,
    subGroupId: employeeIds?.subGroupId,
    parentGroupId: employeeIds?.parentGroupId,
    cycleId: cycleId,
  };
  http
    .get(GET_CURRENT_MANAGERS_LIST, {}, { params })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let currentRPM = [];
        let currentRVM = [];
        if (response.data) {
          currentRPM =
            response.data.currentRPM?.length > 0
              ? response.data.currentRPM.map((value) => {
                  return {
                    ...value,
                    value: value.employeeId,
                    label: value.empName,
                    parentGroupId: value.parentGroupId,
                    subGroupId: value.subGroupId,
                  };
                })
              : [];
          currentRVM =
            response.data.currentRVM?.length > 0
              ? response.data.currentRVM.map((value) => {
                  return {
                    ...value,
                    value: value.employeeId,
                    label: value.empName,
                    parentGroupId: value.parentGroupId,
                    subGroupId: value.subGroupId,
                  };
                })
              : [];
        }
        dispatch({
          type: actionTypes.SET_CURRENT_RPM_LIST,
          data: currentRPM,
        });
        dispatch({
          type: actionTypes.SET_CURRENT_RVM_LIST,
          data: currentRVM,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getNewManagersList = () => (dispatch) => {
  http
    .get(GET_NEW_MANAGERS_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let managerList = [];
        if (response.data?.length > 0) {
          managerList = response.data.map((manager) => {
            return {
              ...manager,
              label: manager.empName,
              value: manager.employeeId,
              parentGroupId: manager.parentGroupId,
              subGroupId: manager.subGroupId,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_NEW_MANAGER_LIST,
          data: managerList,
        });
      } else {
        dispatch({
          type: actionTypes.SET_NEW_MANAGER_LIST,
          data: [],
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

//hr assessment dashboard
export const getProjectList = () => (dispatch) => {
  http
    .get(PROJECT_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let projectList = [];
        if (response.data) {
          projectList = response.data.map((v) => {
            return {
              value: v,
              label: v,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_PROJECT_LIST,
          payload: projectList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

//Only completed Cycle List
export const getCycleListOptions = () => (dispatch) => {
  http
    .get(GET_ALL_CYCLE_NAME)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let cycleList = [];
        if (response.data?.length > 0) {
          cycleList = response.data.map((v) => {
            return {
              value: v.cycleId,
              label: v.cycle,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_CYCLE_LIST_OPTIONS,
          payload: cycleList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

//Only current Cycle List
export const getHRQuarterlyCycleList = () => (dispatch) => {
  http
    .get(GET_HR_QUARTERLY_CYCLE_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let HRQuarterlyCycleList = response.data.map((value) => {
          return {
            label: value.cycle,
            value: value.cycleId,
          };
        });
        dispatch({
          type: actionTypes.SET_HR_QUARTERLY_CYCLE_LIST,
          data: HRQuarterlyCycleList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

//All Cycle List
export const getALLQuarterlyCycleList = () => (dispatch) => {
  http
    .get(GET_QUARTERLY_CYCLE_LIST, {}, { params: { role: "HR" } })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let allCycle = response.data.map((value) => {
          return {
            label: value.cycle,
            value: value.cycleId,
          };
        });
        dispatch({
          type: actionTypes.SET_ALL_HR_QUARTERLY_CYCLE_LIST,
          data: allCycle?.length > 0 ? allCycle : [],
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getDropdownOptions = (cycleId) => (dispatch) => {
  http
    .post(DROPDOWN_OPTIONS, {}, { cycleId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_PMS_DROPDOWN_OPTIONS,
            payload: {
              criteriaOption: response.data?.criteria?.map((v) => {
                if (v === "Band") {
                  return { value: v, label: v };
                } else if (v === "Department") {
                  return { value: v, label: v };
                } else if (v === "India DD") {
                  return { label: v, value: "IndiaDD" };
                } else if (v === "Project Manager") {
                  return { label: v, value: "ProjectManager" };
                } else if (v === "Delivery Manager") {
                  return { label: v, value: "ProjectDeliveryManager" };
                } else if (v === "Delivery Director") {
                  return { label: v, value: "ProjectDeliveryDirector" };
                } else if (v === "Project") {
                  return { label: v, value: v };
                }
              }),
              bandOption: setDropdownOption(response.data?.band),
              pmOption: setDropdownOption(response.data?.pm),
              dmOption: setDropdownOption(response.data?.dm),
              ddOption: setDropdownOption(response.data?.dd),
              locationOption: setDropdownOption(response.data?.location),
              indiaDDOption: setDropdownOption(response.data?.indiaDD),
              subGroupOption: response.data?.subGroupName?.map((v) => ({
                value: v.subGroupId,
                label: v.subGroupDisplayName,
              })),
              departmentOption: setDropdownOption(response.data?.department),
              projectList: setDropdownOption(response.data?.project),
              rateTypeOption: setDropdownOption(response.data?.type),
              isLoading: false,
            },
          });
          dispatch({ type: "CLEAR_HR_DASHBOARD_ASSESSMENT_FILTER_DATA" });
        } else {
          dispatch({
            type: actionTypes.SET_PMS_DROPDOWN_OPTIONS,
            payload: {
              bandOption: [],
              pmOption: [],
              dmOption: [],
              ddOption: [],
              criteriaOption: [],
              locationOption: [],
              indiaDDOption: [],
              departmentOption: [],
              projectList: [],
              rateTypeOption: [],
              isLoading: false,
            },
          });
          dispatch({ type: "CLEAR_HR_DASHBOARD_ASSESSMENT_FILTER_DATA" });
        }
      } else {
        dispatch({
          type: actionTypes.SET_PMS_DROPDOWN_OPTIONS,
          payload: {
            isLoading: false,
          },
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getEmployeeViewForPopup = (body, type) => (dispatch) => {
  let url = type === "OBJECTIVE" ? OBJECTIVE_GRADE_POPUP : PRIVATE_ASSESSMENT_POPUP;
  dispatch({ type: "START_HR_DASHBOARD_LOADING" });
  http
    .post(url, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_PMS_EMPLOYEE_VIEW_IN_POPUP,
            payload: {
              employeeViewInPopup: response.data,
              popupBody: body,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_PMS_EMPLOYEE_VIEW_IN_POPUP,
            payload: {
              employeeViewInPopup: [],
            },
          });
        }
      }
    })
    .catch(() =>
      dispatch({
        type: actionTypes.SET_HR_DASHBOARD_ASSESSMENT_TABLE_CONTENT,
        payload: {
          tableHeader: [],
          tableData: [],
        },
      })
    );
};

export const getHRDashboardAssessmentTable = (type, body) => (dispatch) => {
  let url = type === "OBJECTIVE" ? OBJECTIVE_GRADE_COUNT : PRIVATE_ASSESSMENT_COUNT;
  dispatch({ type: "START_HR_DASHBOARD_LOADING" });
  http
    .post(url, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data?.tableContent?.length > 0) {
          dispatch({
            type: actionTypes.SET_HR_DASHBOARD_ASSESSMENT_TABLE_CONTENT,
            payload: {
              tableHeader: response.data.tableHeaders,
              tableData: response.data.tableContent,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_HR_DASHBOARD_ASSESSMENT_TABLE_CONTENT,
            payload: {
              tableHeader: [],
              tableData: [],
            },
          });
        }
      }
    })
    .catch(() =>
      dispatch({
        type: actionTypes.SET_HR_DASHBOARD_ASSESSMENT_TABLE_CONTENT,
        payload: {
          tableHeader: [],
          tableData: [],
        },
      })
    );
};

export const getEmployeeViewAssessmentComparison = (body) => (dispatch) => {
  dispatch({ type: "START_HR_DASHBOARD_LOADING" });
  http
    .post(EMPLOYEE_VIEW_ASSESSMENT_COMPARISON, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON,
            payload: {
              employeesViewComparison: response.data,
              filteredViewComparison: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON,
            payload: {
              employeesViewComparison: [],
              filteredViewComparison: [],
            },
          });
        }
      }
    })
    .catch((error) => console.warn(error));
};

export const getEmployeeViewAssessmentExport = (body) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_HR_LOADER,
  });
  http
    .post(EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_EXPORT, {}, body, { responseType: "arraybuffer" })
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = "Performance Deviation.xlsx";
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.EMPLOYEE_ASSESSMENT_REPORT,
          payload: { success: true, message: "File downloaded successfully ." },
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.EMPLOYEE_ASSESSMENT_REPORT,
        payload: { error: true, message: "Error Downloading file ." },
      });
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    });
};

//Export assessment
export const downloadPrivateAssessTable = (type, body) => (dispatch) => {
  let url = type?.includes("Objective") ? OBJECTIVE_GRADE_REPORT : PRIVATE_ASSESSMENT_COUNT_REPORT;
  dispatch({
    type: actionTypes.SHOW_HR_LOADER,
  });
  http
    .post(url, {}, { ...body }, { responseType: "arraybuffer" })
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = `${type}.xlsx`;
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.EMPLOYEE_ASSESSMENT_REPORT,
          payload: { success: true, message: "File downloaded successfully ." },
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.EMPLOYEE_ASSESSMENT_REPORT,
        payload: { error: true, message: "Error Downloading file ." },
      });
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    });
};

export const downloadPrivateAssessTableEmployeeView = (type, body) => (dispatch) => {
  let url = type.includes("Objective") ? OBJECTIVE_GRADE_POPUP_REPORT : PRIVATE_ASSESSMENT_POPUP_REPORT;
  dispatch({
    type: actionTypes.SHOW_HR_LOADER,
  });
  http
    .post(url, {}, { ...body }, { responseType: "arraybuffer" })
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = `${type}.xlsx`;
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.EMPLOYEE_ASSESSMENT_REPORT,
          payload: { success: true, message: "File downloaded successfully ." },
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.EMPLOYEE_ASSESSMENT_REPORT,
        payload: { success: false, message: "Error Downloading file ." },
      });
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    });
};
