import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import style from "./nodatafound.module.scss";
import NoRecordFound from "./NoRecordFound";
import { IMG_CHECKINS_LAPSED } from "const/imgCost";

function NoRecordFoundModal({ title, titleDescription, hide, show }) {
  return (
    <>
      <Modal show={show} onHide={() => hide()} size="lg" animation={false} className="custom_modal_popup">
        <div className="custom_modal_container custom_lg_modal_container">
          <Modal.Header className={style.mHeader}>
            <Modal.Title>
              {title}
              <span>{titleDescription}</span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="box-light-gradient"></div>
            <div className={style.no_record_wrapper}>
              <NoRecordFound
                title="Oops! No records found"
                description="The data is not yet found"
                image={IMG_CHECKINS_LAPSED}
              />
            </div>
          </Modal.Body>

          <Modal.Footer className={["custom_modal_button", "justify-content-start", style.mFooter].join(" ")}>
            <Button variant={"primary"} className="fs-500" onClick={() => hide()}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

NoRecordFoundModal.propTypes = {
  title: PropTypes.string.isRequired,
  titleDescription: PropTypes.string,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default NoRecordFoundModal;
