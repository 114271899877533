import React from "react";
import PropTypes from "prop-types";
import { IC_CLOSE_ROUNDED } from "../../../const/imgCost";
import moment from "moment";
import { backGroundColor } from "../../../const/constValue";

function CommentAnswer(props) {
  const { noteId, commentedOn, commentedBy, comment } = props.point;

  return (
    <div className="view_comment_box_row">
      <div className="user_pics" style={{ backgroundColor: backGroundColor[0] }}>
        {commentedBy.displayPicture && commentedBy.displayPicture.length > 2 ? (
          <img src={commentedBy.displayPicture} alt="user pics" />
        ) : (
          commentedBy.displayPicture
        )}
      </div>
      <div className="user_comment_details">
        <div className="user_name">
          {commentedBy.empName}
          <span className="comment_time">{moment(commentedOn).fromNow()}</span>
        </div>
        <div className="user_comment text-break">{comment}</div>
        <div className="remove_user_comment">
          {props.loggedInUser?.employeeId === commentedBy?.employeeId && !props.isEnded && (
            <img className="svg" src={IC_CLOSE_ROUNDED} alt="" onClick={() => props.deleteNote(noteId)} />
          )}
        </div>
      </div>
    </div>
  );
}

CommentAnswer.propTypes = {
  point: PropTypes.shape({
    noteId: PropTypes.number.isRequired,
    commentedOn: PropTypes.string.isRequired,
    commentedBy: PropTypes.shape({
      empName: PropTypes.string.isRequired,
      displayPicture: PropTypes.string.isRequired,
      employeeId: PropTypes.string.isRequired,
    }).isRequired,
    comment: PropTypes.string.isRequired,
  }),
  isEnded: PropTypes.bool,
};

export default CommentAnswer;
