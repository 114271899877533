import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

function TextBoxQuestion(props) {
  const { errors, isSubmitted, textBoxHandlerTemplate, item } = props;
  let error = errors?.find((v) => v.qId === item.questionId && v.error && v.isSubmit);
  let placeholder = item.isRequired ? "Add a new answer" : "Add a new answer (Optional)";
  return (
    <div className="radio-wrapper">
      {isSubmitted ? (
        <ul className="common_list_items mt-3">
          <li>{item.answer || "-"}</li>
        </ul>
      ) : (
        <Form.Group controlId="">
          <div className="textarea-wrapper mt-3">
            <Form.Control
              className={error && "limit-reached"}
              as="textarea"
              maxLength={"5000"}
              defaultValue={item.answer}
              placeholder={placeholder}
              onBlur={(e) => textBoxHandlerTemplate(e, item)}
            />
          </div>
        </Form.Group>
      )}
    </div>
  );
}

TextBoxQuestion.propTypes = {
  textBoxHandlerTemplate: PropTypes.func,
};

export default TextBoxQuestion;
