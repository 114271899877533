import React from "react";
import config from "util/config";
import { filterOptionFromName } from "util/general";
import style from "./DashboardFilterSelection.module.scss";
import FilterSelectionWrapper from "./FilterSelectionWrapper";

const filterLabelFromName = (data, type) => {
  if (data === "filterSubGroups") {
    return `SubGroup${type}`;
  } else if (data === "filterMemberType") {
    return `EmployeeType${type}`;
  } else if (data === "filterLocation") {
    return `Location${type}`;
  } else if (data === "filterDepartment") {
    return `Group${type}`;
  } else if (data === "filterProject") {
    return `Project${type}`;
  } else if (data === "filterIndiaDD") {
    return `IndiaDD${type}`;
  } else if (data === "filterManager") {
    return `Manager${type}`;
  }
};

function DashboardFilterSelection({ dashboardReportFilter, dashboardOption, handleRemove, handleSelectAllFilterData }) {
  return (
    <div className={style.filter_selection_wrapper}>
      <div className={style.filter_selection}>
        {Object.entries(dashboardReportFilter).map(([filter, filterOptions]) => {
          const isSelectAll =
            Object.entries(filterOptions).filter(([, filterValue]) => filterValue)?.length ===
            dashboardOption[filterOptionFromName(filter)]?.length;
          if (Object.entries(filterOptions).filter(([, filterValue]) => filterValue).length > 0) {
            return (
              <FilterSelectionWrapper
                filterOptions={isSelectAll ? { [`${config.idSplit}All`]: true } : filterOptions}
                labelName={filterLabelFromName(filter, ":")}
                handleRemove={(filterKey) =>
                  isSelectAll
                    ? handleSelectAllFilterData(filterOptionFromName(filter), false)
                    : handleRemove(filter, filterKey)
                }
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default DashboardFilterSelection;
