import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import Select from "react-select";
import Pagination from "shared/Pagination/Pagination";
import CheckInDetailsViewPopup from "components/CheckInDetailsViewPopup/CheckInDetailsViewPopup";
import NoDataFound from "components/NodataFound/NoDataFound";
import config from "util/config";
import moment from "moment";
import { dateFormat2 } from "util/dateUtils";
import { IC_EXPAND, IC_COLLAPSE, IC_EYE } from "const/imgCost";
import { backGroundColor, noData, MonthlyCheckinTexts } from "const/constValue";
import { ReactSVG } from "react-svg";
import style from "./PastCheckins.module.scss";

const monthsOptions = MonthlyCheckinTexts?.months?.map((month) => {
  return { label: month, value: month };
});

export default function PastCheckins({
  showYearListData,
  feedbackHistoryManager,
  totalCount,
  getFeedbackHistoryData,
  historyDetails,
  getHistoryDetails,
}) {
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => state.objectives);

  const currentYear = (new Date()).getFullYear();
  const [selectedYear, setSelectedYear] = useState({ label: currentYear, value: currentYear });
  const [selectedMonth, setSelectedMonth] = useState(monthsOptions);
  const [openPopup, setOpenPopup] = useState(false);
  const [period, setPeriod] = useState("");

  useEffect(() => {
    dispatch({ type: "EXPANDED", expanded: null });
  }, [dispatch]);

  useEffect(() => {
    handleChangePage(1, 10);
  }, [selectedYear, selectedMonth]);

  const yearsOptions = showYearListData?.map((year) => {
    return { label: year, value: year };
  });

  const renderStatus = (status) => {
    switch (status) {
      case "TOTAL_PENDING":
        return <span className={style.TOTAL_PENDING}>TOTAL PENDING</span>;
      case "PARTIALLY_PENDING":
        return <span className={style.PARTIALLY_PENDING}>PARTIALLY PENDING</span>;
      case "COMPLETED":
        return <span className={style.COMPLETED}>COMPLETED</span>;
      case "PENDING_FROM_MEMBER":
        return <span className={style.PARTIALLY_PENDING}>PENDING FROM MEMBER</span>;
      case "Pending_from_employee":
        return <span className={style.PARTIALLY_PENDING}>PENDING FROM EMPLOYEE</span>;
      case "Pending_from_manager":
        return <span className={style.PARTIALLY_PENDING}>PENDING FROM MANAGER</span>;
      case "Completed":
        return <span className={style.COMPLETED}>COMPLETED</span>;
      default:
        return <></>;
    }
  };

  const toggleExpander = (id) => {
    expanded === id
      ? dispatch({ type: "EXPANDED", expanded: null })
      : dispatch({ type: "EXPANDED", expanded: id });
  };

  const handleChangePage = (pageNumber, offSet) => {
    const periods = selectedMonth?.map(month => {
      return `${month?.value} ${selectedYear?.value}`
    })
    const body = {
      offSet,
      pageNumber,
      periods,
    };
    getFeedbackHistoryData(body);
  }

  const handleShow = (period, employeeProfile, isActionIconDisable) => {
    if (!isActionIconDisable) {
      setOpenPopup(!openPopup);
      setPeriod(period);
      getHistoryDetails(period, employeeProfile);
    }
  }

  return (
    <>
      <div className={`box  past-checkins-box ${style.box_wrapper}`}>
        <div className={`${style.heading_wrapper} row`}>
          <div className="col-md-6 col-sm-12">
            <h2>Past check-in's</h2>
          </div>
          <div className={`${style.date_wrapper}`}>
            <div>
              <Select
                options={yearsOptions}
                data-test='select_year'
                classNamePrefix="select_year"
                className={`custom-default-dd-box ${style.select_wrapper}`}
                placeholder="Select year"
                value={selectedYear}
                isSearchable={false}
                onChange={(year) => setSelectedYear(year)}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div>
              <MultiSelect
                data-test='select_month'
                options={monthsOptions}
                value={selectedMonth}
                disableSearch={true}
                onChange={(list) => setSelectedMonth(list)}
                labelledBy={"Select month"}
                hasSelectAll={true}
                overrideStrings={{ "selectSomeItems": "Select month", "allItemsAreSelected": "Select All" }}
              />
            </div>
          </div>
        </div>
        {feedbackHistoryManager?.length > 0 ? (
          <div className={style.main_table_wrapper}>
            <Table className={style.tab_table_responsive}>
              <thead>
                <tr>
                  <th className={style.check_in_period}>Check-in Period</th>
                  <th className={style.status}>Status</th>
                </tr>
              </thead>
              <tbody>
                {feedbackHistoryManager.map((checkInPeriod, index) => {
                  const { startDate, endDate, submissionManagerStatus, employeeManagerCheckinFeedbackHistoryDTO } = checkInPeriod;
                  const cyclePeriod = moment(startDate).format(config.dates.shortMonthNameAndDate) + " - " + moment(endDate).format(config.dates.shortMonthNameAndDate) + ", " + moment(endDate).format("YYYY");
                  return (
                    <Fragment key={`checkInPeriod${index}`}>
                      <tr
                        className={
                          expanded === index
                            ? [style.active, "tr_expanded"].join(" ")
                            : ""
                        }
                      >
                        <td
                          className={`text-break a_name d-inline-flex border-0 ${style.check_in_period}`}
                          colSpan={5}
                        >
                          <div>
                            <span
                              className={style.expand}
                              onClick={() => toggleExpander(index)}
                            >
                              {expanded === index ? (
                                <ReactSVG src={`${IC_COLLAPSE}`} />
                              ) : (
                                <ReactSVG src={`${IC_EXPAND}`} />
                              )}
                            </span>
                            {cyclePeriod}
                          </div>
                        </td>
                        <td className={style.status}>{renderStatus(submissionManagerStatus)}</td>
                      </tr>
                      {expanded === index && (
                        <tr>
                          <td colSpan="5" className={style.table_cell}>
                            <div className={style.inner_table_wrapper}>
                              <Table className={style.inner_table}>
                                <thead>
                                  <tr>
                                    <th className={style.name}>Name</th>
                                    <th className={style.submitted_date}>
                                      Submitted Date (Employee) | Status
                                    </th>
                                    <th className={style.submitted_date}>
                                      Submitted Date (Manager) | Status
                                    </th>
                                    {/* <th className={style.status}>Status</th> */}
                                    <th className={style.action}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {employeeManagerCheckinFeedbackHistoryDTO.map((pastCheckIn, index) => {
                                    const {
                                      checkinFeedBackId,
                                      employeeProfile,
                                      submittedDateEmployee,
                                      submittedDateManager,
                                      employeeStatus,
                                      managerStatus,
                                      isActionIconDisable,
                                      period,
                                    } = pastCheckIn;
                                    return (
                                      <tr key={checkinFeedBackId}>
                                        <td className={style.name}>
                                          <div className={style.tab_team_member_info}>
                                            <div className={style.team_member_info}>
                                              <div
                                                className={style.team_member_img}
                                                style={{
                                                  backgroundColor:
                                                    backGroundColor[
                                                    index % backGroundColor.length
                                                    ],
                                                }}
                                              >
                                                {employeeProfile?.displayPicture &&
                                                  employeeProfile?.displayPicture?.length > 2 ? (
                                                  <img
                                                    src={employeeProfile?.displayPicture}
                                                    alt={employeeProfile?.displayName}
                                                  />
                                                ) : (
                                                  employeeProfile?.empInitial
                                                )}
                                              </div>
                                              <div
                                                className={style.team_member_details}
                                              >
                                                <div
                                                  className={style.team_member_name}
                                                >
                                                  {employeeProfile?.displayName}
                                                </div>
                                                <div
                                                  className={
                                                    style.team_member_designation
                                                  }
                                                >
                                                  {employeeProfile?.designation}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className={style.submitted_date_value}>
                                          {dateFormat2(submittedDateEmployee) ?? "-"} {"|"}{" "}
                                          {renderStatus(employeeStatus)}
                                        </td>
                                        <td className={style.submitted_date_value}>
                                          {dateFormat2(submittedDateManager) ?? "-"} {"|"}{" "}
                                          {renderStatus(managerStatus)}
                                        </td>
                                        {/* <td className={style.status_value}>
                                          {renderStatus(managerStatus)}
                                        </td> */}
                                        <td className={style.action_icon}>
                                          <span
                                            data-test='handleShow'
                                            onClick={() => handleShow(period, employeeProfile, isActionIconDisable, startDate, endDate)}
                                          >
                                            <ReactSVG
                                              src={`${IC_EYE}`}
                                              className={style.eye_icon}
                                              style={{
                                                cursor: isActionIconDisable
                                                  ? "not-allowed"
                                                  : "pointer",
                                              }}
                                            />
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>) : (
          <div>
            <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            <div className="gap120"></div>
          </div>
        )}
      </div>
      {totalCount > 0 && (
        <Pagination totalItem={totalCount} onChangePage={handleChangePage} />
      )}
      {openPopup && (
        <CheckInDetailsViewPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          historyDetails={historyDetails}
          period={period}
        />
      )}
    </>
  );
}
