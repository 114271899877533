import React from "react";
import { PieChart, Pie, Label, Tooltip, Cell, ResponsiveContainer } from "recharts";
import style from "./PieChart.module.scss";

function PieChartComponent({ pieChartData, title, handlePieChartClick, COLORS, isExportBtn, handleExport }) {
  function CustomLabel({ viewBox }) {
    const { cx, cy } = viewBox;
    let totalValue = 0;
    pieChartData.forEach((dt) => {
      totalValue += dt.value;
    });
    const total = `${totalValue}`;
    return (
      <>
        <text
          x={cx}
          y={cy - 12}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            Total
          </tspan>
        </text>
        <text
          x={cx}
          y={cy + 15}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            {total}
          </tspan>
        </text>
      </>
    );
  }

  const CustomTooltipForPieChart = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    if (active) {
      pieChartData.forEach((data) => {
        totalVal += data.value;
      });
      pr = (payload[0]["value"] / totalVal) * 100;
      return (
        <>
          <div className="recharts-tooltip-custom-wrapper">
            <p className="recharts-tooltip-label">{payload[0]["name"]}</p>
            {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
            <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${
              payload[0]["value"]
            }`}</span>
          </div>
        </>
      );
    }

    return null;
  };
  return (
    <div className="box pt-1 pb-1">
      <div className={style.pir_chart_wrapper}>
        <div className="graph-item">
          {isExportBtn && (
            <div className="export-btn" onClick={handleExport}>
              <span>Export</span>
            </div>
          )}
          <h5 data-test="aggregate">{title}</h5>
          <div style={{ width: "auto", height: "auto" }}>
            <ResponsiveContainer minHeight={300} minWidth={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  cursor="pointer"
                  innerRadius={90}
                  outerRadius={110}
                  height={300}
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="value"
                >
                  <Label width={30} position="center" content={<CustomLabel value1={pieChartData} />}></Label>
                  {pieChartData.map((entry, index) => (
                    <Cell key={index} fill={COLORS[entry["name"]]} onClick={() => handlePieChartClick(entry)} />
                  ))}
                </Pie>
                <Tooltip content={CustomTooltipForPieChart} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={style.legend_wrapper}>
          <ul className={style.legend_list} data-test="Dashboard_lenegd_list">
            {pieChartData.map((entry, index) => {
              return (
                <li className={style.legend_item} key={index}>
                  <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                  {entry["name"]}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PieChartComponent;
