import React from "react";
import { Link } from "react-router-dom";
import style from "./CampaignEmployeeTable.module.scss";

function CampaignEmployeeRow({
  backgroundColor,
  image,
  pathName = "",
  empName,
  designation,
  department,
  status,
  location,
  managerStatus,
}) {
  return (
    <tr>
      <td>
        <div className={style.tab_team_member_info}>
          <div className={style.team_member_info}>
            <div className={style.team_member_img} style={{ backgroundColor }}>
              {image && image.length > 2 ? <img src={image} alt="" /> : image}
            </div>
            <div className={style.team_member_details}>
              <div className={style.team_member_name}>
                <Link to={pathName}>{empName}</Link>
              </div>
              <div className={style.team_member_designation}>
                {designation} | {department}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className={style.tab_location}>{location}</td>
      <td>
        <span className={status === "Pending" ? style.ended : style.submitted}>
          {status === "Pending" ? "Pending" : "Submitted"}
        </span>
      </td>
      {managerStatus && (
        <td>
          <span className={managerStatus === "Pending" ? style.ended : style.submitted}>
            {managerStatus === "Pending" ? "Pending" : "Submitted"}
          </span>
        </td>
      )}
    </tr>
  );
}

export default React.memo(CampaignEmployeeRow);
