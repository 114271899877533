import React from "react";
import PropTypes from "prop-types";
import style from "./FeedbackNotApplicableComment.module.scss";
import { ReactSVG } from "react-svg";
import { IC_NOTIFY } from "../../../../../const/imgCost";
import CommentsTop from "../../../../CommentBox/CommentsTop";
import CommentTextArea from "../../../../CommentBox/CommentTextArea";
import { backGroundColor } from "../../../../../const/constValue";
import Comment from "../../../../CommentBox/Comment";

function FeedbackNotApplicableComment(props) {
  const {
    displayPicture,
    textHeading,
    commentBoxHeadingText,
    placeholder,
    textLength,
    dangerText,
    isSubmitted,
    displayName,
    submittedDate,
    invalidateComment,
    notApplicable,
    setNotApplicable,
    comment,
    setComment,
  } = props;

  return (
    <div className={style.not_applicable_comment_wrapper}>
      <div className={style.not_applicable_top}>
        <span className={style.notify_icon}>
          <ReactSVG src={IC_NOTIFY} />
        </span>
        <div>
          <p>{textHeading}</p>
          <p className="text-danger">{dangerText}</p>
          <div className="custom-checkbox custom-checkbox-sm">
            <input
              id={"CHECK_NOT_APPLICABLE"}
              type="checkbox"
              value={notApplicable}
              disabled={isSubmitted}
              defaultChecked={isSubmitted}
              onChange={(e) => setNotApplicable(e.target.checked)}
            />
            <label htmlFor={"CHECK_NOT_APPLICABLE"}>Please mark this feedback Not Applicable</label>
          </div>
          {(notApplicable || isSubmitted) && (
            <div className={[style.comment_wrapper, "view_comment_box_wrapper", isSubmitted && "bg-white"].join(" ")}>
              <CommentsTop heading={commentBoxHeadingText} />
              {isSubmitted ? (
                <Comment
                  selectedColor={backGroundColor[0]}
                  image={displayPicture}
                  empName={displayName}
                  commentedOn={submittedDate}
                  comment={invalidateComment}
                  isPrivate={false}
                  deletable={false}
                />
              ) : (
                <CommentTextArea
                  selectedColor={backGroundColor[0]}
                  image={displayPicture}
                  value={comment}
                  changeHandle={(e) => setComment(e.target.value)}
                  placeholder={placeholder}
                  maxLength={textLength}
                  submitButton={false}
                  showChars={false}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {/* {!isSubmitted && (
        <div className={style.btn_group}>
          <Button
            type="button"
            disabled={!notApplicable}
            variant={"primary"}
            className={["btn-round", style.btn_save].join(" ")}
            onClick={() => submitComment(comment)}
          >
            <strong>Submit</strong>
          </Button>
        </div>
      )} */}
    </div>
  );
}

FeedbackNotApplicableComment.propTypes = {
  displayPicture: PropTypes.string.isRequired,
  textHeading: PropTypes.string.isRequired,
  commentBoxHeadingText: PropTypes.string,
  submitComment: PropTypes.func,
  cancelHandler: PropTypes.func,
  placeholder: PropTypes.string,
  textLength: PropTypes.number,
};

export default FeedbackNotApplicableComment;
