import TooltipText from "components/TooltipText/TooltipText";
import React from "react";
import { Link } from "react-router-dom";
import { dateFormat2 } from "util/dateUtils";
import Badge from "shared/Badge/Badge";
import style from "./CycleTeam.module.scss";

function EmployeeRow({
  backgroundColor,
  employeeId,
  image,
  pathName,
  empName,
  designation,
  department,
  grade,
  submittedDate,
  selfAssessmentStatus,
  rmSubmittedByManagerNames,
  showRmNameColum,
  status,
  // showRatingDropDown
}) {
  return (
    <tr key={employeeId}>
      <td>
        <div className={style.tab_team_member_info}>
          <div className={style.team_member_info}>
            <div className={style.team_member_img} style={{ backgroundColor }}>
              {image && image.length > 2 ? <img src={image} alt="" /> : image}
            </div>
            <div className={style.team_member_details}>
              <div className={style.team_member_name}>
                <Link to={pathName}>{empName}</Link>
              </div>
              <div className={style.team_member_designation}>
                {designation} | {department}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <span>{grade || "-"}</span>
      </td>
      <td>
        <span>{dateFormat2(submittedDate)}</span>
      </td>
      <td>
        <Badge status={selfAssessmentStatus} />
      </td>
      <td>
        <Badge status={status} />
      </td>
      {showRmNameColum && (
        <td className={style.manager_name}>
          {rmSubmittedByManagerNames ? (
            <TooltipText len={13} array={[rmSubmittedByManagerNames]} tooltipWidth="160px" />
          ) : (
            "-"
          )}
        </td>
      )}
    </tr>
  );
}

export default EmployeeRow;
