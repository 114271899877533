import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import style from "./HRDeallocationMemberDetails.module.scss";
import * as actions from "store/actions/index";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import UserProfile from "../../../user_profile/user_profile";
import SubmittedDateBox from "../../../SubmitBox/SubmittedDateBox";
import ReviewerProfile from "../../../reviewer-profile/reviewer-profile";
import SidebarProjectDetails from "../../../SidebarProjectDetails/SidebarProjectDetails";
import QuestionTemplate from "../../../Performance/CycleMemberProfile/QuestionTemplate/QuestionTemplate";
import HRDeallocationObjectiveTracking from "./HRDeallocationObjectiveTracking";
import ListViewModal from "../../../ListViewModal/ListViewModal";
import { useQuery } from "util/utils";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { errorToast, successToast } from "util/general";
import routePath from "const/routePath";

export default function HRDeallocationMemberDetails(props) {
  const projectDeAllocationId = props.match.params.id;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const managerEmployeeId = useQuery().get("managerEmpId");
  const managerParentGroupId = useQuery().get("managerPgId");
  const managerSubGroupId = useQuery().get("managerSgId");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const managerIds = {
    employeeId: managerEmployeeId,
    parentGroupId: managerParentGroupId,
    subGroupId: managerSubGroupId,
  };
  const loggedInUser = storage.getUser();
  const dispatch = useDispatch(); // using dispatch method
  const [viewProject, setViewProject] = useState(false);
  const { empDeallocationData, objectives, isLoadingHRDetailScreen, isSubmitted, isFileLoading, fileResponse } =
    useSelector((state) => state.deAllocation);
  const { questionTemplateErrors } = useSelector((state) => state.performance);
  const { user } = useSelector((state) => state.myTeam); // get current user
  const { reviewer } = useSelector((state) => state.checkIn); // get current user
  const questionsAnswerTemplate = empDeallocationData?.deallocationquestionTemplate;
  const submittedDate = empDeallocationData?.submittedDate;
  const employeeName = empDeallocationData?.empName || "";
  const isLargeName =
    empDeallocationData?.currentprojects?.length > 0 &&
    empDeallocationData.currentprojects.map((v) => v.projectName).join(", ").length > 250;

  const currentProjectName =
    empDeallocationData?.currentprojects?.length > 0
      ? isLargeName
        ? empDeallocationData.currentprojects
            .map((v) => v.projectName)
            .join(", ")
            .slice(0, 250) + "..."
        : empDeallocationData.currentprojects.map((v) => v.projectName).join(", ")
      : "NA";

  useEffect(() => {
    dispatch(actions.GetEmployeeDeallocationDataForHR(projectDeAllocationId, managerIds));
    dispatch(actions.GetReviewer(employeeIds));
    dispatch(actions.GetUser(employeeIds)); //get current user
  }, [dispatch, projectDeAllocationId]);

  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);
      dispatch({ type: "CLEAR_DEALLOCATION_FILE_RESPONSE" });
    }
  }, [fileResponse, dispatch]);

  const downloadFile = (fileId, questionId) => {
    let params = {
      questionId: questionId,
      fileId,
      projectDeAllocationId,
    };
    dispatch(actions.DownloadFileInDeallocation(params));
  };

  if (isLoadingHRDetailScreen) {
    return <Spinner />;
  }

  return (
    <div className={style.emp_detail_wrapper}>
      <SubNavigation type="Feedback" />
      {isFileLoading && <Spinner />}
      <div className={style.feedback_inner_wrapper}>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="page-title pb-0">HR</h1>
              <span className="st-breadcrumb">
                Feedback
                <span>
                  <Link to={routePath.HR_DEALLOCATION_FEEDBACK}>Deallocation Feedback</Link>
                </span>
                <span>{employeeName}</span>
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              {user && <UserProfile user={user} />}
              <div className={style.emp_proj_list_wrapper}>
                <div className={style.emp_proj_detail_Wrapper}>
                  {objectives?.length > 0 && (
                    <HRDeallocationObjectiveTracking
                      isSubmitted={isSubmitted}
                      employeeId={employeeIds.employeeId}
                      employeeIds={employeeIds}
                      loggedInUser={loggedInUser}
                      objectives={objectives}
                      managersList={reviewer?.managersList}
                    />
                  )}
                  {questionsAnswerTemplate && (
                    <QuestionTemplate
                      handleTextBox={() => {}}
                      handleRating={() => {}}
                      handleRatingComment={() => {}}
                      handleRadioComment={() => {}}
                      handleRadio={() => {}}
                      handleRadioOther={() => {}}
                      handleCheckBox={() => {}}
                      handleCheckBoxOther={() => {}}
                      handleCheckBoxComment={() => {}}
                      handleDropDown={() => {}}
                      handleDropDownComment={() => {}}
                      handleDropDownOther={() => {}}
                      handleScale={() => {}}
                      handleScaleComment={() => {}}
                      handleRankComment={() => {}}
                      handleRank={() => {}}
                      handleNps={() => {}}
                      handleNpsComment={() => {}}
                      employeeId={employeeIds.employeeId}
                      questionTemplateErrors={questionTemplateErrors}
                      isSubmitted={true}
                      questionsAnswerTemplate={questionsAnswerTemplate}
                      uploadFile={() => {}}
                      viewTemplateName={true}
                      downloadFile={downloadFile}
                      deleteFile={() => {}}
                    />
                  )}
                </div>
              </div>
              <SubmittedDateBox submittedDate={submittedDate} />
            </Col>
            <Col lg={4}>
              <SidebarProjectDetails
                previousProject={
                  empDeallocationData?.projectName?.length > 0
                    ? empDeallocationData.projectName.map((v) => v.projectName).join(", ")
                    : "NA"
                }
                currentProject={currentProjectName}
                isLargeName={isLargeName}
                handleViewMore={() => setViewProject(true)}
              />
              {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer.managersList} />}
            </Col>
          </Row>
        </Container>
      </div>
      {empDeallocationData?.currentprojects?.length > 0 && viewProject && (
        <ListViewModal
          show={viewProject}
          hide={() => setViewProject(false)}
          title={"Project Lists"}
          lists={empDeallocationData.currentprojects.map((v) => v.projectName)}
        />
      )}
    </div>
  );
}
