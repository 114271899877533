import React from "react";
import style from "./KeyResultTable.module.scss";
import KeyResultRow from "./KeyResultRow";

export default function KeyResultTable({
  keypoints,
  edit,
  clickCommentModal,
  handleKeyRating,
  objectiveError,
  isDisabled,
  isHideRatingDropDown,
  isOldRatingOption,
  isOneToFourRatings,
}) {
  const CheckKeyPointError = (keyPoint) => {
    const indexError =
      objectiveError &&
      objectiveError.findIndex(
        (error) => error.keyId && error.keyId === keyPoint.keyId && error.isSubmit && error.error
      );
    if (indexError !== -1) return true;
    else return false;
  };
  return (
    <div className="custom-table">
      <div className={style.key_result_table_wrapper}>
        <table>
          <thead>
            <tr>
              <th>Key Results</th>
              <th>Total Weightage</th>
              <th>% Completed</th>
              <th>Achieved Key Value</th>
              {edit && <th  className={isHideRatingDropDown === true && style.hideRatingBoxLastChild}>{isHideRatingDropDown === true && "Key Comment"}</th>}
            </tr>
          </thead>
          <tbody>
            {keypoints.map((value) => (
              <KeyResultRow
              isHideRatingDropDown={isHideRatingDropDown}
                isDisabled={isDisabled}
                error={CheckKeyPointError(value)}
                isOldRatingOption={isOldRatingOption}
                isOneToFourRatings={isOneToFourRatings}
                point={value}
                edit={edit}
                handleKeyRating={handleKeyRating}
                key={value.keyId}
                clickCommentModal={() => clickCommentModal(value)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
