import React from "react";
import style from "./PercentageViewBox.module.scss";

function PercentageViewBox({
  boxHeading,
  boxDescription,
  title,
  percentageData,
  eligibleEmployeeCount,
  totalEligibleText,
}) {
  return (
    <div className={style.percentage_view_box_wrapper}>
      <div className="box">
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>{boxHeading}</h2>
            <h3>{boxDescription}</h3>
          </div>
        </div>
        <div className="box-light-gradient"></div>
        <div className={style.percentage_info_wrapper}>
          <div className={style.title}> {title}</div>
          {eligibleEmployeeCount !== 0 && (
            <div className="vd-obj-description mt-3 mb-2">
              {totalEligibleText} <label>{eligibleEmployeeCount}</label>
            </div>
          )}
          <ul>
            {percentageData.map((value, index) => {
              return (
                <li key={index + "PERCENTAGE"} className={style.percentage_li}>
                  <span className={style.percentage_dot} style={{ background: value.color }}></span>
                  <div>
                    <div className={style.percentage_info_name}>{value.name}</div>
                    <div className={style.percentage_info_number}>
                      {value.percentage}%{" (" + value.count + ")"}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PercentageViewBox;
