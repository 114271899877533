const moment = require("moment");

let REFERENCE = moment();
let TODAY = REFERENCE.clone().startOf("day");
let YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
let A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");
export function isToday(date) {
  return moment(date).isSame(TODAY, "d");
}
export function isYesterday(date) {
  return moment(date).isSame(YESTERDAY, "d");
}
export function isWithinAWeek(date) {
  return moment(date).isAfter(A_WEEK_OLD);
}
export function getYears(date) {
  return moment(date).format("YYYY");
}
export function endOfDay(date) {
  // added 86,399,999 ms for end of the day
  if (date) {
    let d = new Date(date).getTime();
    if (new Date(date).getSeconds() !== 59) {
      d += 86399999;
    }
    return new Date(d);
  } else return undefined;
}
export function dateFormat1(date) {
  if (date && typeof date === "string") {
    return moment(date).format("MMM DD, YYYY");
  } else return "-";
}
export function dateFormat2(date) {
  if (date && typeof date === "string") {
    return moment(date).format("DD MMM, YYYY");
  } else return "-";
}
export function dateFormat22(date) {
  if (date) {
    return moment(date).format("DD MMM, YYYY");
  } else return "-";
}
export function dateFormat3(date) {
  if (date && typeof date === "string") {
    return moment(date).format("MM-DD-YYYY");
  } else return moment(date).format("MM-DD-YYYY");
}
export function dateFormat4(date) {
  if (date && typeof date === "string") {
    return moment(date).format("MMM DD");
  } else return moment(date).format("MMM DD");
}
export function dateFormatDDMMMYYYY(date) {
  if (date && typeof date === "string") {
    return moment(date).format("DD-MMM-YYYY");
  } else return moment(date).format("DD-MMM-YYYY");
}
export function dateFormatYMD(date) {
  if (date && typeof date === "string") {
    return moment(date).format("YYYY-MM-DD");
  } else if (date && Object.prototype.toString.call(date).includes("Date")) {
    return moment(date).format("YYYY-MM-DD");
  } else return "-";
}
export function dateFormatDMY(date) {
  if (date && typeof date === "string") {
    return moment(date).format("DD-MM-YYYY");
  } else if (date && Object.prototype.toString.call(date).includes("Date")) {
    return moment(date).format("DD-MM-YYYY");
  } else {
    return "-";
  }
}
export function dayDiff(date) {
  if (typeof date === "string") {
    if (moment(date).diff({}, "days") > 0) {
      return moment(date).diff({}, "days");
    } else return 0;
  }
}

export function endedDays(date) {
  if (Math.sign(moment(date).diff({}, "days")) === -1) {
    return `Ended ${moment().diff(new Date(date), "days")} days ago`;
  } else if (moment(date).format("MMM Do YY") === moment().format("MMM Do YY")) {
    return `Ends today`;
  } else {
    return `Ends in ${moment(date).diff({}, "days")} Days`;
  }
}

export function stringToDateFormat1(date, format = "MMM DD, YYY") {
  //convert 26-Feb-2021 to MMM DD, YYYY
  if (date) {
    let dd = date.split("-")[0];
    let mm = date.split("-")[1];
    let yy = date.split("-")[2];
    if (format === "MMM DD, YYYY") {
      return mm + " " + dd + ", " + yy;
    } else if (format === "DD MMM, YYYY") {
      return dd + " " + mm + ", " + yy;
    } else {
      return mm + " " + dd + ", " + yy;
    }
  } else {
    return "-";
  }
}

export function stringToDate1(date) {
  //convert 07-01-2021 to 07/01/2021
  if (date) {
    let mm = date.split("-")[0];
    let dd = date.split("-")[1];
    let yy = date.split("-")[2];
    return mm + "/" + dd + "/" + yy;
  } else {
    return "-";
  }
}

export function toDate(date) {
  //for safari support
  if (typeof date === "string") {
    return new Date(date.substring(0, 23) + "Z");
  } else return null;
}

export function getStringMonth(month) {
  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return "";
  }
}

export function getNearestQuarterMonth(month) {
  //quarter month is
  // March 2
  // June 5
  // Sept 8
  // Dec 11
  switch (month) {
    case 11:
    case 0:
    case 1:
      return 11;
    case 2:
    case 3:
    case 4:
      return 2;
    case 5:
    case 6:
    case 7:
      return 5;
    case 8:
    case 9:
    case 10:
      return 8;
  }
}

export function getMonthAndYearFromDateString(date) {
  if (date && typeof date === "string") {
    let month = date.split("-")[1];
    let year = date.split("-")[2];
    return `${month} ${year}`;
  }
  return "";
}

export function getMonthAndYearFromDate(date) {
  if (date && typeof date === "object") {
    let month = getStringMonth(date.getMonth());
    let year = date.getFullYear();
    return `${month} ${year}`;
  }
  return "";
}
