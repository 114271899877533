import React from "react";
import { ReactSVG } from "react-svg";
import style from "./Button.module.scss";
import { IC_SEARCH_RESET } from "const/imgCost";

function TemplateSelectionButton({ name, isDelete, handleRemove, disabled, handleClick, handleSelect, btnText }) {
  return (
    <>
      {name ? (
        <div className={style.template_selection_btn}>
          <span onClick={handleClick}>{name}</span>
          {isDelete && (
            <span className={style.delete_item} onClick={handleRemove}>
              <ReactSVG src={`${IC_SEARCH_RESET}`} title="" alt="" />
            </span>
          )}
        </div>
      ) : (
        <button
          type="button"
          disabled={disabled}
          onClick={handleSelect}
          className={["btn-round btn btn-secondary", style.select_template_btn].join(" ")}
        >
          <strong>{btnText}</strong>
        </button>
      )}
    </>
  );
}

export default TemplateSelectionButton;
