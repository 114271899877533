import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { backGroundColor } from "../../const/constValue";
import { IC_COLLAPSE, IC_EXPAND } from "../../const/imgCost";
import LineGraph from "../LineGraph/LineGraph";
import style from "./EmployeeList.module.scss";

function EmployeeList({
  employee,
  index,
  secondColum,
  current,
  previous,
  gradeHistory,
  graphYAxis,
  graphLineValue,
  graphXAxis,
  graphType,
  handleLineChart,
}) {
  const dispatch = useDispatch();
  const { multiExpanded } = useSelector((state) => state.managerDashboard);
  const toggleExpander = (id) => {
    multiExpanded.includes(id)
      ? dispatch({ type: "REMOVE_MULTI_EXPAND", payload: id })
      : dispatch({ type: "MULTI_EXPAND", payload: id });
  };

  return (
    <>
      <tr className={style.employee_list}>
        <td>
          <div className={style.user_profile}>
            <div className={style.plus_space} onClick={() => toggleExpander(employee.employeeId)}>
              {multiExpanded.includes(employee.employeeId) ? (
                <ReactSVG src={`${IC_COLLAPSE}`} />
              ) : (
                <ReactSVG src={`${IC_EXPAND}`} />
              )}
            </div>
            <div
              className={style.user_img}
              style={{ backgroundColor: backGroundColor[index % backGroundColor.length] }}
            >
              {employee.displayPicture?.length > 2 ? (
                <img src={employee.displayPicture} alt="pic" />
              ) : (
                employee.displayPicture
              )}
            </div>
            <div className={style.user_name}>
              <h1>{employee.empName}</h1>
              <h2>
                {employee.department} | {employee.designation}
              </h2>
            </div>
          </div>
        </td>
        {secondColum && <td className={style.table_data}>{previous}</td>}
        <td className={style.table_data}>{current}</td>
        <td className={style.table_data}>{employee.location}</td>
      </tr>
      {multiExpanded.includes(employee.employeeId) && (
        <tr>
          <td colSpan={secondColum ? "4" : "3"}>
            <LineGraph
              data={gradeHistory}
              handleLineChart={(cycleId) => handleLineChart(cycleId, employee)}
              lineValue={graphLineValue}
              xAxis={graphXAxis}
              yAxis={graphYAxis}
              type={graphType}
            />
          </td>
        </tr>
      )}
    </>
  );
}

export default EmployeeList;
