import React from "react";
import { Button } from "react-bootstrap";
import style from "./AssessmentEmployeeView.module.scss";
import EmployeeViewTable from "./EmployeeViewTable";
import FilterTeam from "../../../FilterTeam/FilterTeam";
import Pagination from "../../../Pagination/Pagination";

function AssessmentEmployeeView({
  filteredViewComparison,
  current,
  previous,
  handleEmployeeViewExport,
  handleLineChart,
  isExport,
  pageOfItems,
  search,
  resetSearch,
  handleStatusChange,
  resetData,
  onChangePage,
  searchFilter,
  filter,
}) {
  return (
    <>
      <div className="box pb-0 mb-0">
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Employees Data</h2>
            <h3>Detail view of employees performance</h3>
          </div>
        </div>

        <div className={[style.dynamic_table_wrapper].join(" ")}>
          <div className="box-light-gradient"></div>
          <FilterTeam
            placeholder="Search for team members"
            filter={filter}
            filterValue={""}
            value={searchFilter ? searchFilter : ""}
            teamData={filteredViewComparison}
            onSearch={(e) => search(e.target.value)}
            onRadioChange={(e) => handleStatusChange(e.target.value)}
            onReset={resetData}
            onResetSearch={resetSearch}
          />
          <div className="box-light-gradient"></div>
          <div className="mb-3">
            <div className="col-xl-12">
              <EmployeeViewTable
                filteredViewComparison={pageOfItems}
                handleLineChart={handleLineChart}
                current={current}
                previous={previous}
              />
              {pageOfItems?.length > 0 && isExport && (
                <div>
                  <Button
                    type="button"
                    variant={"primary"}
                    className={["btn-round", style.export_button].join(" ")}
                    onClick={handleEmployeeViewExport}
                  >
                    <strong>Export</strong>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Pagination pageSize={5} items={filteredViewComparison} resetData={resetData} onChangePage={onChangePage} />
    </>
  );
}

export default AssessmentEmployeeView;
