import React from "react"
import moment from "moment";
import { NavLink } from "react-router-dom";
import style from "./AppericiateColleagues.module.scss";

export default function PreviousAppreciateHistory({history}){
  return (
    <div className={["box-inner-pad", "box-light-gradient", "breakall", style.made_my_day_box].join(" ")}>
      <h5 className="semi-bold">History</h5>
      <div className={style.made_my_day_items}>
        {history.map((message, index) => {
          let msg = message.message;
          let arr = msg.split("@" + message.receiver.empName);
          return (
            <div className={style.made_my_day_item} key={index + `appriciate`}>
              <div className={style.made_my_day_info}>
                {arr[0]}
                <span>@{message.receiver.empName}</span>
                {arr[1]}
              </div>
              <span className="message-time">{moment(message.createdDate).fromNow()}</span>
            </div>
          );
        })}
      </div>
      <div className={style.made_my_day_history}>
        <NavLink
          to={{
            pathname: "/home",
            state: {
              type: "APPRECIATE"
            }
          }}
          exact
        >
          View All
        </NavLink>
      </div>
    </div>    
  )
}
