import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import style from "../AssignObjectives/AssignObjectives.module.scss";
import * as actions from "store/actions/index";
import { scrollToTop } from "util/general";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import ObjectiveHeading from "../AssignObjectives/ObjectiveHeading";
import ObjectiveKeyResults from "../AssignObjectives/ObjectiveKeyResults";
import ObjectiveOwner from "../AssignObjectives/ObjectiveOwner";
import ObjectiveActivities from "./ObjectiveActivities";
import ConfirmationPopup from "../ConfirmationPopup";
import SideBarTags from "../../Sidebar-tags/SidebarTags";
import ObjectiveDescription from "./ObjectiveDescription";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { useQuery } from "util/utils";
import routePath from "const/routePath";

export default function CreatedObjective(props) {
  const employeeIds = storage.getEmployeeIds();
  const type = useQuery().get("type");
  const id = props.match.params.id;
  const [display, setDisplay] = useState(false);
  const [objectiveId, setObjectiveId] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { objective, isLoading, activities } = useSelector((state) => state.objectives);
  const headerText = "Sure you want to delete this objective?";
  const subText = "We recommend editing the objective. Once deleted, you won't be able to undo it.";
  const loggedInUser = storage.getUser();

  useEffect(() => {
    if (
      loggedInUser?.employeeId &&
      objective?.createdBy?.employeeId &&
      loggedInUser?.employeeId !== objective?.createdBy?.employeeId
    ) {
      history.push(routePath.HOME);
    }
  }, [loggedInUser?.employeeId, objective?.createdBy?.employeeId]);

  useEffect(() => {
    dispatch({ type: "CLEAR_OBJECTIVES_STATE" });
    dispatch(actions.GetObjective(id));
    dispatch(actions.GetManagerObjectiveActivity(id, employeeIds));
    scrollToTop(500);
  }, [dispatch, id]);

  const deleteObjective = (id) => {
    setDisplay(true);
    setObjectiveId(id);
  };

  const ConfirmDeleteObjective = () => {
    dispatch(actions.DeleteObjective(objectiveId));
    history.push({
      pathname: routePath.ALL_OBJECTIVES,
      state: { type: "TEAM_OBJECTIVE" },
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.assignobjectives_wrapper}>
      <SubNavigation type="Objectives" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Objectives</h1>
            <span className="st-breadcrumb">
              Objectives
              <span>
                <Link
                  to={{
                    pathname: `/objectives/all-objectives`,
                    query: {
                      tab: `MyCreatedObjective`,
                    },
                  }}
                >
                  All Objectives
                </Link>
              </span>
              <span>Team Objectives</span>
            </span>
          </Col>
          {objective && (
            <ObjectiveHeading
              deleteObjective={deleteObjective}
              objective={objective}
              created={type === "service_line" ? false : true}
            />
          )}
          <Col xl={8} lg={8}>
            {objective && objective.description && <ObjectiveDescription description={objective.description} />}
            {objective && <ObjectiveKeyResults keyPoints={objective.keyPoints} />}
            {activities && <ObjectiveActivities activities={activities} />}
          </Col>
          <Col xl={4} lg={4}>
            {objective && objective.createdBy && <ObjectiveOwner owner={objective.createdBy} />}
            {objective && objective.tags && objective.tags.length > 0 && <SideBarTags tags={objective.tags} />}
            <ConfirmationPopup
              show={display}
              submit={() => ConfirmDeleteObjective()}
              hide={() => setDisplay(false)}
              headerText={headerText}
              subText={subText}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
