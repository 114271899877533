import React, { useEffect, useState } from "react";
import { Col, Row, Collapse } from "react-bootstrap";
import _ from "lodash";
import { ReactSVG } from "react-svg";
import { MonthlyCheckinTexts } from "const/constValue";
import { IC_EYE } from "const/imgCost";
import style from "./RecentCheckins.module.scss";
import RecentCheckInDetailsViewPopup from "../RecentCheckInDetailsViewPopup/RecentCheckInDetailsViewPopup";
import { getFullMonthName } from "util/general";

const RecentCheckins = ({recentCheckIns, period}) => {
  const [show, setShow] = useState(false);
  const [checkInDetails, setCheckInDetails] = useState();
  const [subMissionData, setSubMissionData] = useState();
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(()=>{
    const groups = _.groupBy(recentCheckIns,  'colour');
    setSubMissionData(groups);
  },[recentCheckIns]);

  return (
    <>
      <div className={`card ${style.profile_card}`}>
        <div className={`card_info ${style.profile_card_info}`}>
          <div className={`card-title ${style.profile_card_title}`}>
            <h2>{getFullMonthName(period)} {MonthlyCheckinTexts.CHECK_IN_SUBMISSIONS}</h2>
            <Row className={style.details_wrapper}>
              <Col lg="4" md="6">
                <h6>
                  <span className={style.greyText}>
                    {MonthlyCheckinTexts.TOTAL_SUBMISSIONS}&nbsp;&nbsp;
                  </span>
                  {recentCheckIns.length}
                </h6>
              </Col>
            </Row>
          </div>
          <div className="card_action">
            <span onClick={() => setShow(!show)}>
              {!show ? "Show" : "Hide"}
            </span>
          </div>
        </div>
        <Collapse in={show}>
          <div className="box-inner-pad box-light-gradient no-border">
            <div className="box-inner-pad box-light-gradient no-border">
              <div className={style.recent_checkin_card_wrapper}>
                {subMissionData && Object.keys(subMissionData).map((key,value) => {
                  return (
                    <div
                      className={style.submission_card_wrapper}
                      key={`recentCheckin${key}`}
                    >
                      <div className={style.employee_names_wrapper}>
                        {key.yellow}
                        {subMissionData[key].map((employee, index) => {
                          return (
                            <div key={`employee${index}`}>{employee?.employeeProfile?.displayName}</div>
                          );
                        })}
                      </div>
                      <div
                        className={style.view_feedback}
                        onClick={() => {
                          setCheckInDetails(subMissionData[key]);
                          setOpenPopup(true);
                        }}
                      >
                        <ReactSVG src={`${IC_EYE}`} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <RecentCheckInDetailsViewPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        checkInDetails={checkInDetails}
      />
    </>
  );
};

export default RecentCheckins;
