import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import moment from "moment";
import PropTypes from "prop-types";
import * as actions from "store/actions/index";
import * as actionTypes from "store/actions/actionTypes";
import { IC_DOWNLOAD, IC_EMAIL, IC_LINKEDIN, IC_TWITTER } from "const/imgCost";
import { backGroundColor } from "const/constValue";
import { firstWord } from "util/general";
import SlideToast from "../Toast/Toast";
import style from "./user_profile.module.scss";

export default function UserProfile({ user }) {
  const {
    dispArr,
    employeeInitial,
    displayName,
    designation,
    location,
    country,
    email,
    band,
    totalExp,
    expBeforeJoining,
    isExp,
    department,
    parentGroupName,
  } = user;
  const loc = useLocation();
  const dispatch = useDispatch();
  const { downloadResumeEmployeeSkillListing } = useSelector((state) => state.skillMatrix);
  const userResumeData = loc.state && loc.state.userResume ? loc.state.userResume : null;

  useEffect(() => {
    if (downloadResumeEmployeeSkillListing) {
      if (downloadResumeEmployeeSkillListing.success) {
        toast.success(
          <SlideToast title="Success" message={downloadResumeEmployeeSkillListing.message} success={true} />
        );
        dispatch({ type: actionTypes.CLEAR_NOTIFICATION });
      }
    }
  }, [downloadResumeEmployeeSkillListing, dispatch]);

  return (
    <div className={["box", style.user_profile_box].join(" ")}>
      {/* <div className="box-edit">
        <Link to="/">
          <ReactSVG src={IC_PENCIL} />
        </Link>
      </div> */}
      <div className={style.user_data}>
        <div className={style.user_img} style={{ backgroundColor: backGroundColor[0] }}>
          {dispArr ? <img src={dispArr} alt="" /> : employeeInitial}
        </div>
        <div className={style.user_details}>
          <div className={style.leftSide}>
            <div className={style.userName}>{displayName}</div>
            <div className={style.user_designation}>
              {designation}
              </div>
            {/* <div className={style.user_degree}>{degree || " -degree- "}</div> */}
            <div className={style.user_address}>
              {location || " - "}, {country || " - "}
              {/* {state || ""}, delete,because of this is not received from backend data*/}
            </div>
            <ul className={[style.socialBox, "d-none"].join(" ")}>
              <li className="d-none">
                <Link to="/">
                  <ReactSVG src={`${IC_LINKEDIN}`} />
                </Link>
              </li>
              <li className="d-none">
                <Link to="/">
                  <ReactSVG src={`${IC_TWITTER}`} />
                </Link>
              </li>
              <li className={style.resume}>
                <Link to="/">
                  <span>Resume</span> <ReactSVG src={`${IC_DOWNLOAD}`} />
                </Link>
              </li>
            </ul>
            {userResumeData && (
              <div className={style.resume_box}>
                <button
                  type="button"
                  onClick={() => dispatch(actions.downloadResumeEmployeeSkillListing(userResumeData))}
                  className={[
                    "btn-round save_box_btn btn btn-secondary",
                    userResumeData.resume ? style.create_new_btn : style.disabled_btn,
                  ].join(" ")}
                  disabled={!userResumeData.resume}
                >
                  <span>
                    <strong>Resume </strong>
                  </span>
                  <ReactSVG src={`${IC_DOWNLOAD}`} />
                </button>
                {!userResumeData.resume && <span className={style.description_tooltip}>Resume Not Added</span>}
                {userResumeData.resume && (
                  <span className={style.file_time}>
                    Last updated on {moment(new Date(userResumeData.resume.updatedDate)).format("DD MMMM, YYYY")}
                  </span>
                )}
              </div>
            )}
            {/* <div className="gap40"></div> */}
            {email && (
              <div className={style.email}>
                <img src={IC_EMAIL} title="" alt="email" /> <span>{email}</span>
              </div>
            )}
          </div>
          <div className={style.rightSide}>
            <div className={style.userInformation}>
              <Row>
                <Col>
                  <div className={style.label}>Band</div>
                  <div className={style.value}>
                    {band ?? "-"}
                    </div>
                </Col>
                <Col>
                  <div className={["text-nowrap", style.label].join(" ")}>Total Experience</div>
                  <div className={style.value}>{totalExp ? totalExp + " year(s)" : "-"}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={["text-nowrap", style.label].join(" ")}>Experience Prior</div>
                  <div className={style.value}>{expBeforeJoining ? expBeforeJoining + " year(s)" : "-"}</div>
                </Col>
                <Col>
                  {/* <div className={style.label}>With {firstWord(parentGroupName)}</div>*/}
                  {/* changed to static value for parent group   */}
                  <div className={style.label}>With Apexon</div>
                  <div className={style.value}>{isExp ? isExp + " year(s)" : "-"}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={style.label}>Department</div>
                  <div className={style.value}>{department}</div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  user: PropTypes.shape({
    dispArr: PropTypes.string,
    employeeInitial: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    band: PropTypes.string,
    totalExp: PropTypes.string,
    expBeforeJoining: PropTypes.string,
    isExp: PropTypes.string,
    department: PropTypes.string.isRequired,
  }),
};

UserProfile.defaultProps = {
  user: {
    dispArr: "",
    employeeInitial: "",
    designation: "",
    location: "",
    country: "",
    email: "",
    band: "",
    totalExp: "",
    expBeforeJoining: "",
    isExp: "",
    department: "",
  },
};
