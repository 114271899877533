import * as actionTypes from "./actionTypes";
import http from "util/http";
import _ from "lodash";
import * as actions from "./index";
import moment from "moment";
import {
  GET_QUARTERLY_CYCLE_LIST,
  GET_ALL_REPORTEE_ASSESSMENT_LIST,
  GET_PRIVATE_ASSESSMENT_DATA,
  AUTO_SAVE_PRIVATE_ASSESSMENT,
  STREAK,
  DOWNLOAD_FILE_PRIVATE_ASSESSMENT_TEMPLATE,
  UPLOAD_FILE_PRIVATE_ASSESSMENT_TEMPLATE,
  DELETE_FILE_PRIVATE_ASSESSMENT_TEMPLATE,
  AUTO_SAVE_PRIVATE_ASSESSMENT_TEMPLATE,
  SUBMIT_PRIVATE_ASSESSMENT,
  CREATE_ASSESSMENT_CYCLE,
  GET_ASSESSMENT_CYCLE,
  GET_QUARTERELY_CYCLE,
  TEMPLATE_LIST,
  RE_OPEN_ASSESSMENT,
  EMPLOYEE_LIST_FOR_HR,
  PRIVATE_ASSESSMENT_RATING,
  GET_USER_PRIVATE_ASSESSMENT_DATA,
  DOWNLOAD_ASSESSMENT,
  DOWNLOAD_EMPLOYEE_LIST,
  DOWNLOAD_EMPLOYEE_OBJECTIVE,
  DOWNLOAD_EMPLOYEE_SELF_ASSESSMENT_LIST,
  DOWNLOAD_SELF_ASSESSMENT,
  EMPLOYEE_NAME_LIST,
  GET_TEMPLATE_CATEGORY,
  TEMPLATE_SCALE,
  UPDATE_USER_SELF_OBJECTIVE,
  ASSESSMENT_COMMENT,
  ASSESSMENT_GLOBAL_COMMENT,
  UPLOAD_OBJECTIVE_ASSESSMENT_FILES,
  DOWNLOAD_OBJECTIVE_ASSESSMENT_FILE,
  DELETE_OBJECTIVE_ASSESSMENT_FILE,
  ASSESSMENT_RPM_GLOBAL_COMMENT,
  ASSESSMENT_SELF_GLOBAL_COMMENT,
  DROPDOWN_OPTIONS_FOR_MANAGER,
  PRIVATE_ASSESSMENT_POPUP_FOR_MANAGER,
  OBJECTIVE_GRADE_POPUP_FOR_MANAGER,
  PRIVATE_ASSESSMENT_COUNT_FOR_MANAGER,
  OBJECTIVE_GRADE_COUNT_FOR_MANAGER,
  EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_FOR_MANAGER,
  EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_EXPORT_FOR_MANAGER,
  PRIVATE_ASSESSMENT_POPUP_REPORT_FOR_MANAGER,
  OBJECTIVE_GRADE_POPUP_REPORT_FOR_MANAGER,
  PRIVATE_ASSESSMENT_COUNT_REPORT_FOR_MANAGER,
  OBJECTIVE_GRADE_REPORT_FOR_MANAGER,
  GET_QUARTERLY_CYCLE_LIST_FOR_MANAGER,
  LOCATION_LIST,
  ASSESSMENT_ACKNOWLEDGEMENT,
  DOWNLOAD_ASSESSMENT_STATUS,
  MANAGER_TEAM_ASSESSMENT_LIST,
  EMPLOYEE_TYPE_LIST,
  EMPLOYEE_TIMELINE,
  TEAM_ASSESSMENT,
  EMPLOYEE_FILTER_LIST_FOR_HR_TIMELINE,
  GET_EMPLOYEE_NAME_LIST,
  UPLOAD_FILE_GLOBAL_FEEDBACK,
  DELETE_FILE_GLOBAL_FEEDBACK,
  DOWNLOAD_FILE_GLOBAL_FEEDBACK,
} from "util/endpoints";
import { status as constStatus,errorMessage } from "const/constValue";
import { saveAs } from "file-saver";
import { endOfDay, getStringMonth, getNearestQuarterMonth } from "util/dateUtils";
import { toFixedNumber } from "util/utils";
import storage from "util/storage";
import config from "util/config";
import { errorToast, gradeValue, infoToast } from "util/general";

export const setCurrentCheckIn = (response, currentCheckIn, rating, moodoMeter) => {
  return {
    type: actionTypes.SET_CURRENT_CHECKIN,
    payload: {
      currentCheckIn: currentCheckIn,
      data: response,
      rating,
      moodoMeter,
    },
  };
};

export const fetchCurrentCheckInFailed = (errCode = null) => {
  // errCode === 7002 ? { type: actionTypes.LOGOUT } :
  return { type: actionTypes.FETCH_CURRENT_CHECKIN_FAILED, errorCode: errCode };
};

export const GetQuarterlyCycleList = (role, type) => {
  return (dispatch) => {
    dispatch({ type: "START_IS_PAGE_LOADING_IN_ASSESSMENT" });
    http
      .get(GET_QUARTERLY_CYCLE_LIST, {}, { params: { role } })
      .then((response) => response.data.data)
      .then((response) => {
        let optionsData = [];
        if (response?.length > 0) {
          if (type === "DROPDOWN") {
            response.map((value) => {
              if (value.cycle) {
                optionsData.push({ label: value.cycle, value: value.cycleId });
              }
              return value;
            });
            dispatch({
              type: actionTypes.SET_QUARTERLY_CYCLE_LIST,
              role: role,
              data: optionsData || [], //need to for fetching data pass response
            });
          } else {
            dispatch({
              type: actionTypes.SET_QUARTERLY_CYCLE_LIST,
              role: role,
              data: response || [], //need to for fetching data pass response
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_QUARTERLY_CYCLE_LIST,
            role: role,
            data: [],
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetQuarterlyCycleListForEmployee = (role, assessmentCount, type) => {
  let params = {
    role,
    assessmentCount: assessmentCount || 4,
  };
  return (dispatch) => {
    dispatch({ type: "START_IS_PAGE_LOADING_IN_ASSESSMENT" });
    http
      .get(GET_QUARTERLY_CYCLE_LIST, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          if (response.data && role === "USER") {
            let optionsData = [];
            let isRatingGraph = response.data?.some((value) => value.gradeOrRatingOrBoth === "RATING");
            let graphSet = response.data?.map((value) => ({
              name: value.period,
              cycleId: value.cycleId,
              cycle: value.cycle,
              area: isRatingGraph ? value.userRating || 0 : gradeValue(value.userGrade) || 0,
              gradeOrRatingOrBoth: value.gradeOrRatingOrBoth,
              isRatingGraph: isRatingGraph,
              grade: value.userGrade,
            }));
            if (response.data?.length > 0) {
              if (type === "DROPDOWN") {
                response.data.map((value) => {
                  if (value.cycle) {
                    optionsData.push({ label: value.cycle, value: value.cycleId });
                  }
                  return value;
                });
                dispatch({
                  type: actionTypes.SET_QUARTERLY_CYCLE_LIST,
                  role: role,
                  data: optionsData || [], //need to for fetching data pass response
                });
              } else {
                dispatch({
                  type: actionTypes.SET_QUARTERLY_CYCLE_LIST,
                  role: role,
                  data: graphSet || [], //need to for fetching data pass response
                });
              }
            } else {
              dispatch({
                type: actionTypes.SET_QUARTERLY_CYCLE_LIST,
                role: role,
                data: [],
              });
            }
          } else {
            dispatch({
              type: actionTypes.SET_QUARTERLY_CYCLE_LIST,
              role: role,
              data: [],
            });
          }
        }
      })
      .catch((error) => {
        console.warn(error);
        dispatch({
          type: actionTypes.SET_QUARTERLY_CYCLE_LIST,
          role: role,
          data: [],
        });
      });
  };
};

export const getEmployeeTimeline = (year) => {
  let params;
  let isOldCycle = false;
  let is2023Cycle = false;

  if(year === "endDate"){
    isOldCycle = true;
    is2023Cycle = false;
    params = {
      role: "USER",
      endDate: "2022-12-31",
    };

  } else if(year === "startDate"){
    isOldCycle = false;
    is2023Cycle = true;
    params = {
      role: "USER",
      startDate:"2022-12-31",
      endDate:"2023-12-31",
    };
    
  } else {
    isOldCycle = false;
    is2023Cycle = false;
    params = {
      role: "USER",
      is2024Cycle:true,
      startDate: "2023-12-31",
    };

  }

  return (dispatch) => {
    dispatch({ type: "START_IS_PAGE_LOADING_IN_ASSESSMENT" });
    http
      .get(EMPLOYEE_TIMELINE, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.data) {
          let { assessments, currentProjects, deallocationFeedbacks, promotion, rewards } = response.data;
          const { assessmentGraphData, currentProjectLineGraph, projectLineVisible, assessmentAreaVisible } =
          getYearLine(assessments, currentProjects, deallocationFeedbacks, promotion, rewards, isOldCycle, is2023Cycle);

          dispatch({
            type: actionTypes.SET_TIMELINE_DATA_LIST,
            timelineGraph: assessmentGraphData,
            currentProjectLineGraph: currentProjectLineGraph || [],
            projectLineVisible: projectLineVisible,
            assessmentAreaVisible: assessmentAreaVisible,
          });
        } else {
          dispatch({
            type: actionTypes.SET_TIMELINE_DATA_LIST,
            timelineGraph: [],
            currentProjectLineGraph: [],
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_TIMELINE_DATA_LIST,
          timelineGraph: [],
          currentProjectLineGraph: [],
        });
      });
  };
};

export const GetAllReporteeAssessmentList = (cycleId, type, data) => (dispatch) => {
  let body = {
    name: data?.name || "",
    offset: data?.offset | 10,
    pageNumber: data?.pageNumber || 1,
    status: data?.status || "",
  };
  if (data.allocation) {
    body = { ...body, allocation: data.allocation };
  }
  dispatch({ type: "REPORTING_TEAM_MEMBER_LOADING" });
  http
    .post(GET_ALL_REPORTEE_ASSESSMENT_LIST, { cycleId, managerType: type }, body)
    .then((response) => response.data)
    .then((response) => {
      let reportingCycleTeam = [];
      let pending, submitted, cycleName, period, gradeOrRatingOrBoth, totalCount;
      if (response.data) {
        gradeOrRatingOrBoth = response.data?.gradeOrRatingOrBoth;
        cycleName = response.data?.cycleName;
        period = response.data?.cycleDuration;
        totalCount = response.data?.totalCount;
        reportingCycleTeam =
          response.data?.reporteeList?.length > 0
            ? response.data?.reporteeList?.map((v) => {
              return {
                cycle: v.cycle,
                period: v.period,
                cycleId: v.cycleId,
                status: v.status,
                dispArr: v.employeeData.dispArr,
                displayPicture: v.employeeData.displayPicture,
                employeeInitial: v.employeeData.empInitial,
                employeeId: v.employeeData.employeeId,
                parentGroupId: v.employeeData.parentGroupId,
                subGroupId: v.employeeData.subGroupId,
                submittedDate: v.submittedDate,
                empName: v.employeeData.empName,
                designation: v.employeeData.designation,
                department: v.employeeData.department,
                privateAssessmentRatings: v.privateAssessmentRatings,
                objectiveRatings: v.objectiveRatings,
                rmSubmittedByManagerNames: v.rmSubmittedByManagerNames,
                //first check, if selfAssessmentGrade available then self assessment grade visible if not then grade display
                grade:
                  gradeOrRatingOrBoth === "BOTH"
                    ? v.selfAssessmentGrade
                      ? v.selfAssessmentGrade + "/" + toFixedNumber(v.selfAssessmentAvgRating, 2)
                      : v.grade
                        ? v.grade + "/" + toFixedNumber(v.objectiveRatings, 2)
                        : ""
                    : gradeOrRatingOrBoth === "RATING"
                      ? v.selfAssessmentAvgRating
                        ? toFixedNumber(v.selfAssessmentAvgRating, 2)
                        : toFixedNumber(v.objectiveRatings, 2)
                      : v.selfAssessmentGrade || v.grade,
                selfAssessmentStatus: v.selfAssessmentStatus?.status,
                rpmAssessmentStatus: v.rpmSubmittedStatus || "Pending",
              };
            })
            : [];
        pending = response.data?.pending === "NaN" ? 0 : response.data.pending.toFixed(2);
        submitted = response.data.submitted === "NaN" ? 0 : response.data.submitted.toFixed(2);
      }
      dispatch({
        type: actionTypes.SET_ALL_REPORTEE_ASSESSMENT_LIST,
        payload: {
          data: reportingCycleTeam,
          pending,
          submitted,
          type,
          cycleName,
          period,
          gradeOrRatingOrBoth,
          totalCount,
          isBenchFilter: response.data?.isBenchEmployeePresent,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_ALL_REPORTEE_ASSESSMENT_LIST,
        payload: {
          data: [],
          pending: 0,
          submitted: 0,
          type,
          cycleName: "",
          period: "",
          gradeOrRatingOrBoth: "",
          totalCount: 0,
        },
      });
    });
};

export const GetPrivateAssessmentData = (employeeIds, cycleId, privateAssessmentType, managerIds) => (dispatch) => {
  let params = managerIds
    ? managerIds === "OTHER"
      ? {
        employeeId: employeeIds.employeeId,
        employeeParentGroupId: employeeIds.parentGroupId,
        employeeSubGroupId: employeeIds.subGroupId,
        cycleId,
      }
      : {
        employeeId: employeeIds.employeeId,
        employeeParentGroupId: employeeIds.parentGroupId,
        employeeSubGroupId: employeeIds.subGroupId,
        cycleId,
        // managerId: managerIds.employeeId,
        // managerParentGroupId: managerIds.parentGroupId,
        // managerSubGroupId: managerIds.subGroupId,
        privateAssessmentType,
      }
    : {
      employeeId: employeeIds.employeeId,
      employeeParentGroupId: employeeIds.parentGroupId,
      employeeSubGroupId: employeeIds.subGroupId,
      cycleId,
      privateAssessmentType,
    };
  http
    .get(GET_PRIVATE_ASSESSMENT_DATA, {}, { params })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        let isSubmitted = null,
          allManagerStatus = [],
          allManagerDetails = [],
          managerList = [],
          lookupObject = {};
        if (response.data) {
          //check private assessment submitted or not
          if (response.data.status === "Submit") isSubmitted = true;
          else if (privateAssessmentType === "REPORTING") {
            if ((response.data.rpmAssessmentEndOn && new Date(response.data.rpmAssessmentEndOn)) < new Date()) {
              if (
                response.data.reOpen &&
                response.data.rpmAssessmentStatus.extendedDate &&
                new Date(response.data.rpmAssessmentStatus.extendedDate) > new Date()
              )
                isSubmitted = false;
              else isSubmitted = true;
            } else if (
              (response.data.selfAssessmentEndOn && new Date(response.data.selfAssessmentEndOn)) > new Date()
            ) {
              if (response.data.selfAssessmentStatus?.status === "Submit") isSubmitted = false;
              else if (response.data.reOpen) isSubmitted = false;
              else isSubmitted = true;
            } else isSubmitted = false;
          } else if (privateAssessmentType === "REVIEWING" || privateAssessmentType === null) {
            if ((response.data.rvmAssessmentEndOn && new Date(response.data.rvmAssessmentEndOn)) < new Date()) {
              if (
                response.data.reOpen &&
                response.data.rvmAssessmentStatus.extendedDate &&
                new Date(response.data.rvmAssessmentStatus.extendedDate) > new Date()
              )
                isSubmitted = false;
              else isSubmitted = true;
            } else if (
              (response.data.selfAssessmentEndOn && new Date(response.data.selfAssessmentEndOn)) > new Date()
            ) {
              if (response.data.selfAssessmentStatus?.status === "Submit") isSubmitted = false;
              else isSubmitted = true;
            } else isSubmitted = false;
          } else if (privateAssessmentType === "SELF") {
            if ((response.data.selfAssessmentEndOn && new Date(response.data.selfAssessmentEndOn)) < new Date()) {
              if (
                response.data.reOpen &&
                response.data.selfAssessmentStatus.extendedDate &&
                new Date(response.data.selfAssessmentStatus.extendedDate) > new Date()
              )
                isSubmitted = false;
              else isSubmitted = true;
            } else isSubmitted = false;
          }
          if (response.data.allRvmQuestionTemplate || response.data.allRpmQuestionTemplate) {
            let rvmData =
              response.data.allRvmQuestionTemplate?.length > 0
                ? response.data.allRvmQuestionTemplate.map((v) => {
                  return {
                    status: v.assessmentStatus.status,
                    privateAssessmentId: v.rvmPrivateAssessmentId,
                    employeeId: v.userDetails.employeeId,
                    type: "REVIEWING",
                  };
                })
                : [];
            let rpmData =
              response.data.allRpmQuestionTemplate?.length > 0
                ? response.data.allRpmQuestionTemplate.map((v) => {
                  return {
                    status: v.assessmentStatus.status,
                    privateAssessmentId: v.rpmPrivateAssessmentId,
                    employeeId: v.userDetails.employeeId,
                    type: "REPORTING",
                  };
                })
                : [];
            allManagerStatus = rpmData.concat(rvmData);
          }
          //SET ALL REPORTING MANAGER AND REVIEWING MANAGER FOR EMPLOYEE
          //In reporting manager list we did't get other reporting manager in allRpmQuestionTemplate
          //So backend send allRM and allRVM
          if (response.data.allRM || response.data.allRVM) {
            let rvm =
              response.data.allRVM.length > 0
                ? response.data.allRVM.map((v) => {
                  return {
                    employeeId: v.employeeId,
                    type: "REVIEWING",
                  };
                })
                : [];
            let rpm =
              response.data.allRM.length > 0
                ? response.data.allRM.map((v) => {
                  return {
                    employeeId: v.employeeId,
                    type: "REPORTING",
                  };
                })
                : [];
            allManagerDetails = rpm.concat(rvm);
          }
        }
        //MANAGER WISE OBJECTIVES
        if (response.data?.objectiveTracking?.length > 0) {
          let objectives = response.data?.objectiveTracking;
          let temp = objectives.map((value) => value.managerData);
          for (var i in temp) {
            lookupObject[temp[i]["employeeId"]] = temp[i];
          }
          for (i in lookupObject) {
            managerList.push(lookupObject[i]);
          }
          managerList.forEach((manager) => {
            let arr = [];
            objectives.forEach((v) => {
              if (manager?.employeeId === v.managerData?.employeeId) {
                arr.push(v);
              }
            });
            if (allManagerDetails.length > 0) {
              //check ismanager current or not (comparing with current all manager)
              if (allManagerDetails.find((v) => v.employeeId === manager?.employeeId)) manager.isCurrentManager = true;
              else manager.isCurrentManager = false;
              //is manager is reporting or reviewing
              manager.isRPM =
                response.data.allRM.length > 0 && response.data.allRM.find((v) => v.employeeId === manager.employeeId)
                  ? true
                  : false;
              manager.isRVM =
                response.data.allRVM.length > 0 && response.data.allRVM.find((v) => v.employeeId === manager.employeeId)
                  ? true
                  : false;
            }
            manager.objectives = arr;
          });
        }
        dispatch({
          type: actionTypes.SET_PRIVATE_ASSESSMENT_DATA,
          data: response.data || [],
          managerList: managerList,
          managerWiseGrade: response.data?.managerWiseGrade,
          isPrivateAssessmentLoading: false,
          isSubmitted: isSubmitted,
          submittedDate: response.data?.submittedDate,
          updatedDate: response.data?.updatedDate,
          allManagerStatus: allManagerStatus,
          allManagerDetails: allManagerDetails,
        });
      } else {
        dispatch({
          type: actionTypes.SET_PRIVATE_ASSESSMENT_DATA,
          data: response.data || [],
          managerList: [],
          managerWiseGrade: "",
          isPrivateAssessmentLoading: false,
          isSubmitted: null,
          submittedDate: "",
          updatedDate: "",
          allManagerStatus: [],
          allManagerDetails: [],
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    // eslint-disable-next-line no-undef
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const DownloadFilePrivateAssessment = (params) => (dispatch) => {
  let url = DOWNLOAD_FILE_PRIVATE_ASSESSMENT_TEMPLATE;
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .get(url, params)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          success: true,
          message: "File downloaded successfully",
        });
        const blob = b64toBlob(response.data.binary.data, response.data.file.contentType);
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", response.data.file.name);
        document.body.appendChild(link);
        link.click();
      } else {
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          success: false,
          message: response.message.message,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
        success: false,
        message: "Something wrong",
      });
    });
};

export const UploadFilePrivateAssessment = (formData, employeeIds, cycleId, assessmentType) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .post(UPLOAD_FILE_PRIVATE_ASSESSMENT_TEMPLATE, null, formData)
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.status === "FAILURE") {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        } else {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            message: "File uploaded successfully!",
            success: true,
            updatedDate: response.data,
          });
          dispatch(GetPrivateAssessmentData(employeeIds, cycleId, assessmentType));
        }
      },
      () => {
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          message: "File not supported",
          success: false,
        });
      }
    );
};

export const DeleteFilePrivateAssessment = (params, employeeIds, cycleId, type) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });

  http
    .delete(DELETE_FILE_PRIVATE_ASSESSMENT_TEMPLATE, params)
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            message: "File deleted successfully!",
            success: true,
            updatedDate: response.data,
          });
          dispatch(GetPrivateAssessmentData(employeeIds, cycleId, type));
        } else {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        }
      },
      () => {
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          message: "Issue deleting file",
          success: false,
        });
      }
    );
};

export const GetStreak = (data) => (dispatch) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
  };
  http
    .get(STREAK, {}, { params })
    .then((response) => response.data.data)
    .then((response) => dispatch({ type: actionTypes.SET_STREAK, streak: response }))
    .catch(() => {});
};

export const SavePrivateAssessmentAnswers = (privateAssessmentId, employeeIds, assessmentType, cycleId) => {
  const response = { submit: "true" };
  const params = { privateAssessmentId, assessmentType };
  return (dispatch) => {
    dispatch({ type: "START_PERFORMANCE_SUBMIT_LOADING" });
    http
      .put(SUBMIT_PRIVATE_ASSESSMENT, params, response)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          if (assessmentType === "SELF") {
            let params = {
              employeeId: employeeIds.employeeId,
              employeeParentGroupId: employeeIds.parentGroupId,
              employeeSubGroupId: employeeIds.subGroupId,
              cycleId,
              privateAssessmentType: "SELF",
            };
            dispatch(GetSelfAssessmentGlobalComment(employeeIds, cycleId));
            http
              .get(GET_PRIVATE_ASSESSMENT_DATA, {}, { params })
              .then((res) => res.data)
              .then((res) => {
                if (res.status === constStatus.SUCCESS) {
                  dispatch({
                    type: actionTypes.SET_PRIVATE_ASSESSMENT_DATA,
                    data: res.data || [],
                    isPrivateAssessmentLoading: false,
                    isSubmitted: true,
                    submittedDate: res.data.submittedDate,
                    updatedDate: res.data.updatedDate,
                  });
                  dispatch({
                    type: actionTypes.PRIVATE_ASSESSMENT_SUBMIT_RESPONSE,
                    message: "Submitted successfully!",
                    success: true,
                    isSubmitted: true,
                  });
                }
              });
          } else {
            dispatch({
              type: actionTypes.PRIVATE_ASSESSMENT_SUBMIT_RESPONSE,
              message: "Submitted successfully!",
              success: true,
              isSubmitted: false,
              updatedDate: response.data,
            });
          }
        } else {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_SUBMIT_RESPONSE,
            message: response.message.message,
            success: false,
            isSubmitted: false,
            isPerformanceResponseLoading: false,
          });
        }
      })
      .catch(() => {
        // Handle Error Here
      });
  };
};

export const AutoSavePrivateAssessment = (data, employeeIds, cycleId) => {
  return (dispatch) => {
    http
      .put(AUTO_SAVE_PRIVATE_ASSESSMENT, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch(GetPrivateAssessmentData(employeeIds, cycleId));
          dispatch({ type: "SET_UPDATED_DATE", updatedDate: response.data });
        }
      })
      .catch(() => {});
  };
};

//AutoSavePrivateAssessmentTemplate
export const AutoSavePrivateAssessmentTemplate = (
  data,
  privateAssessmentId,
  questionTemplateId,
  type,
  employeeIds,
  cycleId,
  managerIds,
  newType
) => {
  let qna = {
    questions: data,
    privateAssessmentId: privateAssessmentId,
    questionTemplateId: questionTemplateId,
    type,
  };

  if (newType === "REPORTING") qna.isRPM = true;
  if (newType === "REVIEWING") qna.isRVM = true;

  return (dispatch) => {
    http
      .put(AUTO_SAVE_PRIVATE_ASSESSMENT_TEMPLATE, {}, qna)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          if (newType) dispatch(GetPrivateAssessmentData(employeeIds, cycleId, newType, managerIds));
          else if (cycleId) dispatch(GetPrivateAssessmentData(employeeIds, cycleId, type));
          dispatch({ type: "SET_UPDATED_DATE", updatedDate: response.data });
        }
      })
      .catch(() => {});
  };
};

export const SetAssessmentGlobalComment = (
  comment,
  privateAssessmentId,
  cycleId,
  employeeIds,
  privateAssessmentType
) => {
  let params = {
    employeeId: employeeIds.employeeId,
    subGroupId: employeeIds.subGroupId,
    parentGroupId: employeeIds.parentGroupId,
    privateAssessmentId,
    cycleId,
    privateAssessmentType,
  };
  const body = { comment };

  return (dispatch) => {
    http
      .put(ASSESSMENT_GLOBAL_COMMENT, {}, body, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({ type: "SET_UPDATED_DATE", updatedDate: response.data });
        }
      })
      .catch(() => {});
  };
};

export const GetAssessmentGlobalComment = (employeeId, cycleId) => {
  let params = {
    employeeId: employeeId.employeeId,
    subGroupId: employeeId.subGroupId,
    parentGroupId: employeeId.parentGroupId,
    cycleId,
  };
  return (dispatch) => {
    http
      .get(ASSESSMENT_GLOBAL_COMMENT, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          let comments = response.data.map((comment) => {
            return {
              comment: comment.comment,
              user: comment.userDetails,
              status: comment.status,
              commentDate: comment.commentDate,
              files: comment.files === null ? [] : comment.files,
              privateAssessmentId: comment.privateAssessmentId,
            };
          });
          dispatch({
            type: actionTypes.SET_ASSESSMENT_GLOBAL_COMMENT,
            payload: _.sortBy(comments, "commentDate"),
          });
        }
      })
      .catch(() => {});
  };
};

export const GetRPMAssessmentGlobalComment = (employeeIds, cycleId) => {
  let params = {
    employeeId: employeeIds.employeeId,
    subGroupId: employeeIds.subGroupId,
    parentGroupId: employeeIds.parentGroupId,
    cycleId,
  };
  return (dispatch) => {
    http
      .get(ASSESSMENT_RPM_GLOBAL_COMMENT, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          let comments = response.data.map((comment) => {
            return {
              comment: comment.comment,
              user: comment.rpm,
              status: comment.status,
              commentDate: comment.commentDate,
              files: comment.files === null ? [] : comment.files,
              privateAssessmentId: comment.privateAssessmentId,
            };
          });
          dispatch({
            type: actionTypes.SET_RPM_ASSESSMENT_GLOBAL_COMMENT,
            payload: _.sortBy(comments, "commentDate"),
          });
        }
      })
      .catch(() => {});
  };
};

export const GetRPMAssessmentGlobalCommentPreviousManager = (
  assessments,
  cycleId,
  loggedInUser,
  type,
  history
) => {
  let params = {
    employeeId: assessments.employeeId.employeeId,
    subGroupId: assessments.employeeId.subGroupId,
    parentGroupId: assessments.employeeId.parentGroupId,
    cycleId,
  };
  let isReviewedByCurrentManager;
  return (dispatch) => {
    http
      .get(ASSESSMENT_RPM_GLOBAL_COMMENT, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          if (response?.data?.length > 0) {
            const even = (element) => element.rpm.employeeId + element.rpm.subGroupId + element.rpm.parentGroupId === loggedInUser.employeeId + loggedInUser.subGroupId + loggedInUser.parentGroupId;
            isReviewedByCurrentManager = response.data.some(even);

            if (isReviewedByCurrentManager) {
              if (type === "TEAM_EMPLOYEE") {
                if (assessments.isRPM) {
                  history.push(
                    `/performance/reporting-assessment/reporting-member/${cycleId}?empId=${assessments?.employeeId?.employeeId}&pgId=${assessments?.employeeId?.parentGroupId}&sgId=${assessments?.employeeId?.subGroupId}`
                  );
                } else if (assessments.isRVM) {
                  history.push(
                    `/performance/reviewing-assessment/reviewing-member/${cycleId}?empId=${assessments?.employeeId?.employeeId}&pgId=${assessments?.employeeId?.parentGroupId}&sgId=${assessments?.employeeId?.subGroupId}`
                  );
                } else if (!assessments?.isRPM && !assessments?.isRVM) {
                  history.push(
                    `/performance/reviewing-assessment/reviewing-member/${cycleId}?empId=${assessments?.employeeId?.employeeId}&pgId=${assessments?.employeeId?.parentGroupId}&sgId=${assessments?.employeeId?.subGroupId}&isPage=OTHER`
                  );
                }
              } else {
                history.push(`/performance/dashboard/cycle-detail/${cycleId}`);
              }
            } else {
              dispatch(GetRVMDetailsForRedirection(assessments, cycleId, loggedInUser, type, history));
            }
          }
        }
      })
      .catch(() => {
        errorToast(errorMessage.SOMETHING_WRONG);
      });
  };
};

export const GetRVMDetailsForRedirection = (
  assessments,
  cycleId,
  loggedInUser,
  type,
  history
) => {
  let params = {
    employeeId: assessments.employeeId.employeeId,
    subGroupId: assessments.employeeId.subGroupId,
    parentGroupId: assessments.employeeId.parentGroupId,
    cycleId,
  };
  let isReviewedByReviewingManager;
  return (dispatch) => {
    http
      .get(ASSESSMENT_GLOBAL_COMMENT, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          if (response?.data?.length > 0) {
            const even = (element) => element.userDetails.employeeId + element.userDetails.subGroupId + element.userDetails.parentGroupId === loggedInUser.employeeId + loggedInUser.subGroupId + loggedInUser.parentGroupId;
            isReviewedByReviewingManager = response.data.some(even);

            if (isReviewedByReviewingManager) {
              if (type === "TEAM_EMPLOYEE") {
                if (assessments.isRPM) {
                  history.push(
                    `/performance/reporting-assessment/reporting-member/${cycleId}?empId=${assessments?.employeeId?.employeeId}&pgId=${assessments?.employeeId?.parentGroupId}&sgId=${assessments?.employeeId?.subGroupId}`
                  );
                } else if (assessments.isRVM) {
                  history.push(
                    `/performance/reviewing-assessment/reviewing-member/${cycleId}?empId=${assessments?.employeeId?.employeeId}&pgId=${assessments?.employeeId?.parentGroupId}&sgId=${assessments?.employeeId?.subGroupId}`
                  );
                } else if (!assessments?.isRPM && !assessments?.isRVM) {
                  history.push(
                    `/performance/reviewing-assessment/reviewing-member/${cycleId}?empId=${assessments?.employeeId?.employeeId}&pgId=${assessments?.employeeId?.parentGroupId}&sgId=${assessments?.employeeId?.subGroupId}&isPage=OTHER`
                  );
                }
              } else {
                history.push(`/performance/dashboard/cycle-detail/${cycleId}`);
              }
            } else {
              infoToast("Previous Manager Assessment");
            }
          }
        }
      })
      .catch(() => {
        errorToast(errorMessage.SOMETHING_WRONG);
      });
  };
};

export const GetSelfAssessmentGlobalComment = (employeeIds, cycleId) => {
  let params = {
    employeeId: employeeIds.employeeId,
    subGroupId: employeeIds.subGroupId,
    parentGroupId: employeeIds.parentGroupId,
    cycleId,
  };
  return (dispatch) => {
    http
      .get(ASSESSMENT_SELF_GLOBAL_COMMENT, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS && response.data) {
          dispatch({
            type: actionTypes.SET_SELF_ASSESSMENT_GLOBAL_COMMENT,
            payload: {
              globalSELFComment: response.data[0]?.comment ? _.sortBy(response.data, "commentDate") : [],
              globalSELFCommentUserData: response.data ? response.data : [],
            },
          });
        }
      })
      .catch(() => {});
  };
};

export const SetAssessmentComment = (
  comment,
  loggedInUser,
  privateAssessmentId,
  userObjectiveId,
  type,
  cycleId,
  employeeIds,
  managerIds
) => {
  const body = {
    comment: {
      comment: comment,
      commentedBy: {
        employeeId: loggedInUser?.employeeId,
        empName: loggedInUser?.displayName,
        parentGroupId: loggedInUser?.parentGroupId,
        subGroupId: loggedInUser?.subGroupId,
        displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
      },
    },
    type,
    privateAssessmentId,
    userObjectiveId,
  };

  return (dispatch) => {
    http
      .put(ASSESSMENT_COMMENT, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch(GetPrivateAssessmentData(employeeIds, cycleId, type, managerIds));
          dispatch({ type: "SET_UPDATED_DATE", updatedDate: response.data });
        }
      })
      .catch(() => {});
  };
};

export const SelfObjectiveManipulate = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SELECT_SELF_OBJECTIVE, data: data });
  };
};

export const SetSelfAssessmentObjective = (obj) => {
  let _data = {
    userObjectiveId: obj.userObjectiveId,
    employeeId: obj.employeeId,
    keyPoints: obj.keyPoints,
    privateAssessmentId: obj.privateAssessmentId,
  };
  let employeeIds = {
    employeeId: obj.employeeId,
    subGroupId: obj.employeeData?.subGroupId || obj.subGroupId,
    subGroupName: obj.employeeData?.subGroupName || obj.subGroupName,
    parentGroupId: obj.employeeData?.parentGroupId || obj.parentGroupId,
    parentGroupName: obj.employeeData?.parentGroupName || obj.parentGroupName,
  };
  return (dispatch) => {
    http
      .put(UPDATE_USER_SELF_OBJECTIVE, {}, _data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch(GetPrivateAssessmentData(employeeIds, obj.cycleId, "SELF"));
        }
      })
      .catch(() => {
        // dispatch(actions.GetUserObjectives(obj.employeeId));
      });
  };
};
export const DownloadFileObjectiveAssessment = (params) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .get(DOWNLOAD_OBJECTIVE_ASSESSMENT_FILE, params)
    .then((response) => response.data.data)
    .then((response) => {
      dispatch({
        type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
        message: "File Downloaded successfully",
        success: true,
      });
      const blob = b64toBlob(response.binary.data, response.file.contentType);
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", response.file.name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(() => {});
};

export const UploadFileObjectiveAssessment = (formData, employeeIds, cycleId, assessmentType) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .post(UPLOAD_OBJECTIVE_ASSESSMENT_FILES, null, formData)
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.status === "FAILURE") {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        } else {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            message: "File uploaded successfully!",
            success: true,
            updatedDate: response.data,
          });
          dispatch(GetPrivateAssessmentData(employeeIds, cycleId, assessmentType));
        }
      },
      () => {
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          message: "Please select valid file",
          success: false,
        });
      }
    );
};

export const DeleteFilObjectiveAssessment = (params, employeeIds, cycleId, type) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .delete(DELETE_OBJECTIVE_ASSESSMENT_FILE, params)
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            message: "File deleted successfully!",
            success: true,
            updatedDate: response.data,
          });
          dispatch(GetPrivateAssessmentData(employeeIds, cycleId, type));
        } else {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        }
      },
      () => {
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          message: "Issue in deleting file",
          success: false,
        });
      }
    );
};

export const GetTemplateList = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_TEMPLATE_LIST,
      templateList: null,
      isTemplateLoading: true,
    });
    http
      .get(TEMPLATE_LIST)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.SET_TEMPLATE_LIST,
            templateList: response.data?.length > 0 ? response.data.reverse() : [],
            isTemplateLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.SET_TEMPLATE_LIST,
            templateList: [],
            isTemplateLoading: false,
          });
        }
      })
      .catch(() => {});
  };
};

export const GetLocationList = () => {
  return (dispatch) => {
    http
      .get(LOCATION_LIST)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.SET_LOCATION_LIST,
            locationList:
              response.data?.length > 0
                ? response.data?.map((v) => {
                  return {
                    value: v,
                    label: v,
                  };
                })
                : [],
          });
        }
      })
      .catch(() => {});
  };
};

export const GetEmployeeOptionsListForHRTimeline = (config) => {
  return (dispatch) => {
    http
      .post(EMPLOYEE_FILTER_LIST_FOR_HR_TIMELINE, {}, {}, config)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_FILTER_OPTIONS,
            payload: {
              displayEmployeesList:
                response.data?.employees?.length > 0
                  ? response.data?.employees.map((v) => {
                    return {
                      value: v.employeeId,
                      label: v.empName + " (" + v.employeeId + ")",
                      parentGroupId: v.parentGroupId,
                      subGroupId: v.subGroupId,
                    };
                  })
                  : [],
              designationList:
                response.data?.designations?.length > 0
                  ? response.data?.designations.map((v) => {
                    return {
                      value: v,
                      label: v,
                    };
                  })
                  : [],
              subGroupList:
                response.data?.subGroups?.length > 0
                  ? response.data?.subGroups.map((v) => {
                    return {
                      value: v.subGroupId,
                      label: v.subGroupDisplayName,
                    };
                  })
                  : [],
              locationList:
                response.data?.locations?.length > 0
                  ? response.data?.locations.map((v) => {
                    return {
                      value: v,
                      label: v,
                    };
                  })
                  : [],
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_FILTER_OPTIONS,
            payload: {
              displayEmployeesList: [],
              designationList: [],
              subGroupList: [],
              locationList: [],
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_FILTER_OPTIONS,
          payload: {
            displayEmployeesList: [],
            designationList: [],
            subGroupList: [],
            locationList: [],
          },
        });
      });
  };
};

export const GetOptionsListForHRTimeline = (subgroups, locations) => {
  let body = {
    locations: locations?.length > 0 ? locations.map((v) => v.value) : null,
    subGroupIds: subgroups?.value ? [subgroups.value] : null,
  };
  return (dispatch) => {
    http
      .post(EMPLOYEE_FILTER_LIST_FOR_HR_TIMELINE, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.SET_HR_TIMELINE_FILTER_OPTIONS,
            payload: {
              locationList:
                response.data?.locations?.length > 0
                  ? response.data?.locations.map((v) => {
                    return {
                      value: v,
                      label: v,
                    };
                  })
                  : [],
              employeeList:
                response.data?.employees?.length > 0
                  ? response.data?.employees.map((v) => {
                    return {
                      value: v.employeeId,
                      label: v.empName + " (" + v.employeeId + ")",
                    };
                  })
                  : [],
              displayEmployeesList:
                response.data?.employees?.length > 0
                  ? response.data?.employees.map((v) => {
                    return {
                      value: v.employeeId,
                      label: v.empName + " (" + v.employeeId + ")",
                      parentGroupId: v.parentGroupId,
                      subGroupId: v.subGroupId,
                    };
                  })
                  : [],
              subGroupList:
                response.data?.subGroups?.length > 0
                  ? response.data?.subGroups.map((v) => {
                    return {
                      value: v.subGroupId,
                      label: v.subGroupDisplayName,
                    };
                  })
                  : [],
              projectList: response.data.projects,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_HR_TIMELINE_FILTER_OPTIONS,
            payload: {
              locationList: [],
              employeeList: [],
              displayEmployeesList: [],
              subGroupList: [],
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_HR_TIMELINE_FILTER_OPTIONS,
          payload: {
            locationList: [],
            employeeList: [],
            displayEmployeesList: [],
            subGroupList: [],
          },
        });
      });
  };
};

export const GetEmployeeListForHRTimeline = (subgroups, locations, type, monthFilter, filterEmployee) => {
  let body = {
    locations: locations?.length > 0 ? locations.map((v) => v.value) : null,
    subGroupIds: subgroups?.value ? [subgroups.value] : null,
    type: type?.value ? type.value : null,
    monthFilter: monthFilter?.value ? monthFilter.value : null,
  };
  return (dispatch) => {
    dispatch({ type: "START_DISPLAY_LOADING_IN_HR_TIMELINE" });
    http
      .post(EMPLOYEE_FILTER_LIST_FOR_HR_TIMELINE, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          if (response.data?.employees.length > 0) {
            dispatch({
              type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_LIST,
              payload:
                filterEmployee?.length > 0
                  ? [...response.data?.employees, ...filterEmployee]
                  : response.data?.employees,
            });
          } else if (filterEmployee?.length > 0) {
            dispatch({
              type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_LIST,
              payload: filterEmployee,
            });
          } else {
            dispatch({
              type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_LIST,
              payload: [],
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_LIST,
            payload: [],
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_LIST,
          payload: [],
        });
      });
  };
};

export const ReOpenPrivateAssessment = (data) => {
  return (dispatch) => {
    // dispatch({type: "START_REOPEN_LOADING"})
    http
      .put(RE_OPEN_ASSESSMENT, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.REOPEN_RESPONSE,
            success: true,
            message: "Re-open successfully",
          });
        }
      })
      .catch(console.warn);
  };
};

export const CreatePerformanceCycle = (data) => {
  if (data.selfStartDate && data.selfEndDate) {
    data.selfAssessment = {
      questionTemplateId: data.selectQuestion && data.selectQuestion.SELF && data.selectQuestion.SELF.id,
      assessmentStartOn: data.selfStartDate || undefined,
      assessmentEndOn: endOfDay(data.selfEndDate),
      questionTemplateName: data.selectQuestion && data.selectQuestion.SELF && data.selectQuestion.SELF.name,
    };
    delete data.selfStartDate;
    delete data.selfEndDate;
  }
  if (data.reviewingStartDate && data.reviewingEndDate) {
    data.rvmAssessment = {
      questionTemplateId: data.selectQuestion && data.selectQuestion.REVIEWING && data.selectQuestion.REVIEWING.id,
      assessmentStartOn: data.reviewingStartDate || undefined,
      assessmentEndOn: endOfDay(data.reviewingEndDate),
      questionTemplateName: data.selectQuestion && data.selectQuestion.REVIEWING && data.selectQuestion.REVIEWING.name,
    };
    delete data.reviewingStartDate;
    delete data.reviewingEndDate;
  }
  if (data.reportingStartDate && data.reportingEndDate) {
    data.rpmAssessment = {
      questionTemplateId: data.selectQuestion && data.selectQuestion.REPORTING && data.selectQuestion.REPORTING.id,
      assessmentStartOn: data.reportingStartDate || undefined,
      assessmentEndOn: endOfDay(data.reportingEndDate),
      questionTemplateName: data.selectQuestion && data.selectQuestion.REPORTING && data.selectQuestion.REPORTING.name,
    };
    delete data.reportingStartDate;
    delete data.reportingEndDate;
  }
  if (data.isActive === "EDIT_YES") {
    data.isActive = true;
    data.status = "In Progress";
  } else if (data.isActive === "YES") {
    data.create = true;
    data.isActive = true;
    data.status = "In Progress";
  } else if (data.isActive === "NO") {
    data.isActive = false;
    data.status = "In Active";
  } else if (data.isActive === "EDIT_NO") {
    data.isActive = false;
  } else {
    data.status = "In Active";
  }
  if (data.ratingVisibleDate) {
    if (moment(data.ratingVisibleDate).format("DD MM YYYY") === moment().format("DD MM YYYY"))
      data.status = "Completed";
    else data.status = "In Progress";

    data.ratingVisibleDate = data.ratingVisibleDate || undefined;
  }

  delete data.selectQuestion;
  return (dispatch) => {
    dispatch({ type: "CREATE_PERFORMANCE_CYCLE_LOADING" });
    http
      .post(CREATE_ASSESSMENT_CYCLE, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.CREATE_ASSESSMENT_CYCLE,
            success: true,
            message: "Successfully created cycle",
          });
        } else {
          dispatch({
            type: actionTypes.CREATE_ASSESSMENT_CYCLE,
            success: false,
            message: response.message.message,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.CREATE_ASSESSMENT_CYCLE,
          success: false,
          message: "Something wrong!",
        });
      });
  };
};

export const GetCreatedAssessmentCycle = (id) => {
  return (dispatch) => {
    dispatch({ type: "CREATE_PERFORMANCE_CYCLE_LOADING" });
    http
      .get(GET_ASSESSMENT_CYCLE, { cycleId: id })
      .then((response) => response.data.data)
      .then((response) => {
        if (response.subGroups?.length > 0) {
          let subGroupId = response.subGroups.map((v) => v.subGroupId);
          if (response.locations?.length > 0 || response.excludeGroup?.length > 0) {
            dispatch(GetEmployeeNameList(response.locations, response.excludeGroup, subGroupId));
          }
          dispatch(GetEmployeeTypeList(subGroupId));
        }
        dispatch({
          type: actionTypes.SET_CREATED_ASSESSMENT_CYCLE,
          cycleData: response,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const getQuarterlyCycle = (id) => {
  return (dispatch) => {
    http
      .get(GET_QUARTERELY_CYCLE, { cycleId: id })
      .then((response) => response.data.data)
      .then((response) => {
        dispatch({
          type: actionTypes.SET_QUARTERLY_CYCLE_DATA,
          quaterlyCycleData: response,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetEmployeeSelfAssessmentForHR = (id, currentPage, pageSize, status, name, type) => {
  let body = {
    cycleId: id,
    assessmentType: "EMPLOYEE",
    pageNumber: currentPage,
    offset: pageSize,
    status,
    name: name?.length > 3 ? name : undefined,
  };
  return (dispatch) => {
    dispatch({ type: "START_FILTER_LOADING" });
    http
      .post(EMPLOYEE_LIST_FOR_HR, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          let count =
            type === "PAGE_CHANGE"
              ? {}
              : {
                employeeCount: response.data.assessmentDetailsCount,
                pendingPercentage: response.data.pending,
                submittedPercentage: response.data.submitted,
              };
          dispatch({
            type: actionTypes.SET_EMPLOYEE_SELF_ASSESSMENT_LIST,
            employeeSelfAssessmentData: response.data?.assessmentDetails || [],
            cycleName: response.data.cycleName,
            period: response.data.period,
            isEmployeeListLoading: false,
            filterLoading: false,
            ...count,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetEmployeeForHR = (id, currentPage, pageSize, status, name, filterValue) => {
  let body = {
    cycleId: id,
    assessmentType: "EMPLOYEE",
    subGroupId: filterValue?.filterSubGroups ? filterValue?.filterSubGroups?.value : undefined,
    location: filterValue?.filterLocation?.length > 0 ? filterValue?.filterLocation.map((v) => v.value) : undefined,
    pageNumber: currentPage ? currentPage : undefined,
    offset: pageSize ? pageSize : undefined,
    status: status ? status : undefined,
    name: name ? name : undefined,
  };
  return (dispatch) => {
    dispatch(actions.ChangeCurrentPage(currentPage));
    dispatch(actions.ChangePageSize(pageSize));
    dispatch({ type: "START_FILTER_LOADING" });
    http
      .post(EMPLOYEE_LIST_FOR_HR, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          let isSelfAssessmentAvailable;
          if (response.data) {
            isSelfAssessmentAvailable = response.data?.containsSelfAssessmentRating;
            let count =
              currentPage > 1
                ? {}
                : {
                  employeeCount: response.data.assessmentDetailsCount,
                  pendingPercentage: response.data.pending,
                  submittedPercentage: response.data.submitted,
                };
            dispatch({
              type: actionTypes.SET_EMPLOYEE_LIST,
              employeeData: response.data?.assessmentDetails,
              cycleName: response.data.cycleName,
              period: response.data.period,
              isEmployeeListLoading: false,
              isSelfAssessmentAvailable: isSelfAssessmentAvailable,
              filterLoading: false,
              ...count,
            });
          }
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetReportingManagerForHR = (id, currentPage, pageSize, status, name, filterValue) => {
  let body = {
    cycleId: id,
    assessmentType: "REPORTING",
    subGroupId: filterValue?.filterSubGroups ? filterValue?.filterSubGroups?.value : undefined,
    location: filterValue?.filterLocation?.length > 0 ? filterValue?.filterLocation.map((v) => v.value) : undefined,
    pageNumber: currentPage ? currentPage : undefined,
    offset: pageSize ? pageSize : undefined,
    status: status ? status : undefined,
    name: name ? name : undefined,
  };
  return (dispatch) => {
    dispatch(actions.ChangeCurrentPage(currentPage));
    dispatch(actions.ChangePageSize(pageSize));
    dispatch({ type: "START_FILTER_LOADING" });
    http
      .post(EMPLOYEE_LIST_FOR_HR, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          let isSelfAssessmentAvailable = false;
          isSelfAssessmentAvailable = response.data?.containsSelfAssessmentRating;
          let rpmManagerList = response.data.assessmentDetailsForRpmorRvm.map((manager) => {
            return {
              managerId: manager.rpm.employeeId,
              managerData: manager.rpm,
              employees: manager.assessmentDetails.map((employee) => {
                return {
                  employeeId: employee.employeeData.employeeId,
                  employeeData: {
                    ...employee.employeeData,
                    image:
                      employee.employeeData?.dispArr ||
                      employee.employeeData?.displayPicture ||
                      employee.employeeData?.empInitial,
                  },
                  acknowledgementStatus: employee.acknowledgementStatus,
                  flagged: employee.flagged,
                  managerRating: employee.managerRating,
                  privateAssessmentRatings: employee.privateAssessmentRatings,
                  grade: employee.grade,
                  objectiveRatings: employee.objectiveRatings,
                  privateAssessmentId: employee.privateAssessmentId,
                  rpmRatings: employee.rpmRatings,
                  selfAssessmentGrade: employee.selfAssessmentGrade,
                  selfAssessmentAvgRating: employee.selfAssessmentAvgRating,
                  submittedDate: employee.rpmAssessmentStatus?.submittedDate,
                };
              }),
            };
          });
          let count =
            currentPage > 1
              ? {}
              : {
                employeeCount: response.data.assessmentDetailsCount,
                pendingPercentage: response.data.pending,
                submittedPercentage: response.data.submitted,
              };

          dispatch({
            type: actionTypes.SET_REPORTING_MANAGER_LIST,
            reportingManagerData: rpmManagerList,
            cycleName: response.data.cycleName,
            period: response.data.period,
            isSelfAssessmentAvailable,
            isEmployeeListLoading: false,
            filterLoading: false,
            ...count,
          });
        } else {
          dispatch({
            type: actionTypes.SET_REPORTING_MANAGER_LIST,
            reportingManagerData: [],
            cycleName: response.data?.cycleName,
            period: response.data?.period,
            isSelfAssessmentAvailable: false,
            isEmployeeListLoading: false,
            filterLoading: false,
            employeeCount: 0,
            pendingPercentage: 0,
            submittedPercentage: 0,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetReviewingManagerForHR = (id, currentPage, pageSize, status, name, filterValue) => {
  let body = {
    cycleId: id,
    assessmentType: "REVIEWING",
    subGroupId: filterValue?.filterSubGroups ? filterValue?.filterSubGroups?.value : undefined,
    location: filterValue?.filterLocation?.length > 0 ? filterValue?.filterLocation.map((v) => v.value) : undefined,
    pageNumber: currentPage ? currentPage : undefined,
    offset: pageSize ? pageSize : undefined,
    status: status ? status : undefined,
    name: name ? name : undefined,
  };
  return (dispatch) => {
    dispatch(actions.ChangeCurrentPage(currentPage));
    dispatch(actions.ChangePageSize(pageSize));
    dispatch({ type: "START_FILTER_LOADING" });
    http
      .post(EMPLOYEE_LIST_FOR_HR, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          let isSelfAssessmentAvailable = false;
          isSelfAssessmentAvailable = response.data?.containsSelfAssessmentRating;
          let rvmManagerList = response.data.assessmentDetailsForRpmorRvm.map((manager) => {
            return {
              managerId: manager.rvm.employeeId,
              managerData: manager.rvm,
              employees: manager.assessmentDetails.map((employee) => {
                return {
                  employeeId: employee.employeeData.employeeId,
                  employeeData: {
                    ...employee.employeeData,
                    image:
                      employee.employeeData?.dispArr ||
                      employee.employeeData?.displayPicture ||
                      employee.employeeData?.empInitial,
                  },
                  acknowledgementStatus: employee.acknowledgementStatus,
                  flagged: employee.flagged,
                  managerRating: employee.managerRating,
                  privateAssessmentRatings: employee.privateAssessmentRatings,
                  grade: employee.grade,
                  objectiveRatings: employee.objectiveRatings,
                  privateAssessmentId: employee.privateAssessmentId,
                  rpmRatings: employee.rpmRatings,
                  selfAssessmentGrade: employee.selfAssessmentGrade,
                  selfAssessmentAvgRating: employee.selfAssessmentAvgRating,
                  submittedDate: employee.rvmAssessmentStatus?.submittedDate,
                };
              }),
            };
          });
          let count =
            currentPage > 1
              ? {}
              : {
                employeeCount: response.data.assessmentDetailsCount,
                pendingPercentage: response.data.pending,
                submittedPercentage: response.data.submitted,
              };

          dispatch({
            type: actionTypes.SET_REVIEWING_MANAGER_LIST,
            reviewingManagerData: rvmManagerList,
            cycleName: response.data.cycleName,
            submitted: response.data.submitted,
            isSelfAssessmentAvailable,
            isEmployeeListLoading: false,
            filterLoading: false,
            ...count,
          });
        } else {
          dispatch({
            type: actionTypes.SET_REVIEWING_MANAGER_LIST,
            reviewingManagerData: [],
            cycleName: response.data?.cycleName,
            submitted: response.data?.submitted,
            isSelfAssessmentAvailable: false,
            isEmployeeListLoading: false,
            filterLoading: false,
            employeeCount: 0,
            pendingPercentage: 0,
            submittedPercentage: 0,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const SavePrivateAssessmentObjectiveRating = (data) => {
  let body = {};
  if (data.type === "teams_objective") {
    body = {
      type: data.type,
      privateAssessmentId: data.privateAssessmentId,
      userObjectiveId: data.userObjectiveId,
      rating: data.rating,
      keyId: data.keyId,
    };
  } else if (data.type === "university_objective") {
    body = {
      type: data.type,
      privateAssessmentId: data.privateAssessmentId,
      userObjectiveId: data.userObjectiveId,
      rating: data.rating,
      flagged: data.flagged,
    };
  }
  
      return (dispatch) => {
    http
      .put(PRIVATE_ASSESSMENT_RATING, { assessmentType: data.privateAssessmentType }, body)
      .then((response) => {
        if (response.data.status === constStatus.SUCCESS) {
          dispatch(GetPrivateAssessmentData(data.employeeIds, data.cycleId, data.privateAssessmentType));
        }
      })
      .catch(console.warn);
  };
};

export const SavePrivateAssessmentObjectiveDate = (body, privateAssessmentType) => {
  return () => {
    http
      .put(PRIVATE_ASSESSMENT_RATING, { assessmentType: privateAssessmentType }, body)
      .then((response) => {
        if (response.data.status === constStatus.SUCCESS) {
          // dispatch(GetPrivateAssessmentData(data.employeeId, data.cycleId, data.privateAssessmentType));
        }
      })
      .catch(() => {
        // Handle Error Here
      });
  };
};

export const SavePrivateAssessmentObjectiveRatingComment = (data) => {
  let body = {};
  if (data.type === "teams_objective") {
    body = {
      type: data.type,
      privateAssessmentId: data.privateAssessmentId,
      userObjectiveId: data.userObjectiveId,
      comment: data.keyComment,
      keyId: data.keyId,
    };
  } else if (data.type === "university_objective") {
    body = {
      type: data.type,
      privateAssessmentId: data.privateAssessmentId,
      userObjectiveId: data.userObjectiveId,
      objectiveComment: data.objectiveComment,
    };
  }

  return (dispatch) => {
    http
      .put(PRIVATE_ASSESSMENT_RATING, { assessmentType: data.privateAssessmentType }, body)
      .then((response) => {
        if (response.data.status === constStatus.SUCCESS) {
          dispatch(GetPrivateAssessmentData(data.employeeData, data.cycleId, data.privateAssessmentType));
        }
      })
      .catch(() => {
        // Handle Error Here
      });
  };
};

export const GetUserPrivateAssessmentData = (cycleId) => (dispatch) => {
  http
    .get(GET_USER_PRIVATE_ASSESSMENT_DATA, { cycleId: cycleId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        let managerList = [],
          lookupObject = {};
        if (response.data?.objectiveList?.length > 0) {
          let objectives = response.data?.objectiveList;
          let temp = objectives.map((value) => value.managerData);
          for (var i in temp) {
            lookupObject[temp[i]["employeeId"]] = temp[i];
          }
          for (i in lookupObject) {
            managerList.push(lookupObject[i]);
          }
          managerList.forEach((manager) => {
            let arr = [];
            objectives.forEach((v) => {
              if (manager?.employeeId === v.managerData?.employeeId) {
                arr.push(v);
              }
            });
            manager.objectives = arr;
          });
        }
        dispatch({
          type: actionTypes.SET_USER_PRIVATE_ASSESSMENT_DATA,
          data: response.data || [],
          isUserPrivateAssessmentLoading: false,
          managerList: managerList,
          managerWiseGrade: response.data?.managerWiseGrade,
        });
      }
    })
    .catch(() => {});
};

export const DownloadPrivateAssessmentData = (type, cycleId, assessmentType) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWNLOAD_IN_BACKGROUND" });
  let params = {
    cycleId,
    assessmentType,
  };
  http
    .get(type, params, { responseType: "arraybuffer" })
    .then((response) => response.data)
    .then((response) => {
      if (response) {
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          message: "File downloaded successfully",
          success: true,
        });
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = "";
        if (type === DOWNLOAD_ASSESSMENT) {
          filename = "Private Assessment.xlsx";
        } else if (type === DOWNLOAD_EMPLOYEE_LIST) {
          filename = "Rating Report.xlsx";
        } else if (type === DOWNLOAD_EMPLOYEE_OBJECTIVE) {
          filename = "Objective Wise Rating Report.xlsx";
        } else if (type === DOWNLOAD_EMPLOYEE_SELF_ASSESSMENT_LIST) {
          filename = "Employee SelfAssessment Report.xlsx";
        } else if (type === DOWNLOAD_SELF_ASSESSMENT) {
          filename = "Self Assessment Rating Report.xlsx";
        } else if (type === DOWNLOAD_ASSESSMENT_STATUS) {
          filename = "Assessment Status Report.xlsx";
        }
        saveAs(blob, filename);
      } else {
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          message: response?.message?.message,
          success: false,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
        message: "Error in file downloading!",
        success: false,
      });
    });
};

export const GetEmployeeNameList = (locations, group, subGroupId) => (dispatch) => {
  if (subGroupId?.length) {
    http
      .post(EMPLOYEE_NAME_LIST, {}, { locations: locations || [], excludeGroup: group || [], subGroupId })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          let employeeList = response.data.map((value) => {
            return {
              value:
                value.employeeId +
                config.employeeIdSplit +
                value.parentGroupId +
                config.employeeIdSplit +
                value.subGroupId,
              label: value.displayName,
              ...value,
            };
          });
          dispatch({
            type: actionTypes.SET_EMPLOYEE_NAME_LIST,
            data: employeeList,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }
};

export const GetEmployeeTypeList = (subGroupId) => (dispatch) => {
  if (subGroupId?.length) {
    http
      .post(EMPLOYEE_TYPE_LIST, {}, { subGroupId })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_TYPE_LIST,
            data: response.data?.length ? response.data.map((type) => ({ value: type, label: type })) : [],
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }
};

export const getTemplateCategory = () => (dispatch) => {
  const campaignId = storage.get("cId");
  let params = {
    campaignId,
  };
  http
    .get(GET_TEMPLATE_CATEGORY, {}, { params })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        dispatch({
          type: actionTypes.GET_TEMPLATE_CATEGORY,
          data: response.data || [],
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getTemplateScale = () => (dispatch) => {
  const campaignId = storage.get("cId");
  let config = campaignId
    ? {
      params: {
        campaignId,
      },
    }
    : {};
  http
    .get(TEMPLATE_SCALE, {}, config)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        dispatch({
          type: actionTypes.TEMPLATE_SCALE,
          data: response.data?.reverse(),
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

//PMS DASHBOARD
export const getDropdownOptionsForManager = (cycleId) => (dispatch) => {
  http
    .post(DROPDOWN_OPTIONS_FOR_MANAGER, {}, { cycleId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_PMS_DROPDOWN_OPTIONS_FOR_MANAGER,
            payload: {
              managerViewOption: response.data?.managerView?.map((v) => {
                if (v === "Delivery Manager") {
                  return { value: "projectDeliveryManager", label: v };
                } else if (v === "Project Manager") {
                  return { value: "projectManager", label: v };
                } else if (v === "Delivery Director") {
                  return { value: "projectDeliveryDirector", label: v };
                } else if (v === "India Delivery Director") {
                  return { value: "indiadeliveryDirector", label: v };
                } else if (v === "Reporting Manager") {
                  return { value: "reportingManager", label: v };
                } else if (v === "Reviewing Manager") {
                  return { value: "reviewingManager", label: v };
                } else {
                  return { value: v, label: v };
                }
              }),
              bandOption: response.data?.band?.map((v) => {
                return { value: v, label: v };
              }),
              pmOption: response.data?.pm?.map((v) => {
                return { value: v, label: v };
              }),
              dmOption: response.data?.dm?.map((v) => {
                return { value: v, label: v };
              }),
              ddOption: response.data?.dd?.map((v) => {
                return { value: v, label: v };
              }),
              locationOption: response.data?.location?.map((v) => {
                return { value: v, label: v };
              }),
              indiaDDOption: response.data?.indiaDD?.map((v) => {
                return { value: v, label: v };
              }),
              departmentOption: response.data?.department?.map((v) => {
                return { value: v, label: v };
              }),
              projectList: response.data?.project?.map((v) => {
                return { value: v, label: v };
              }),
              employeeNameOption: response.data?.employeeName?.map((v) => {
                return { value: v.employeeId, label: v.empName };
              }),
              rateTypeOption: response.data?.type?.map((v) => {
                return { value: v, label: v };
              }),
              isLoading: false,
            },
          });
          dispatch({ type: "CLEAR_MANAGER_DASHBOARD_ASSESSMENT_FILTER_DATA" });
        } else {
          dispatch({
            type: actionTypes.SET_PMS_DROPDOWN_OPTIONS_FOR_MANAGER,
            payload: {
              managerViewOption: [],
              bandOption: [],
              pmOption: [],
              dmOption: [],
              ddOption: [],
              locationOption: [],
              indiaDDOption: [],
              departmentOption: [],
              projectList: [],
              employeeNameOption: [],
              rateTypeOption: [],
              isLoading: false,
            },
          });
          dispatch({ type: "CLEAR_MANAGER_DASHBOARD_ASSESSMENT_FILTER_DATA" });
        }
      } else {
        dispatch({
          type: actionTypes.SET_PMS_DROPDOWN_OPTIONS_FOR_MANAGER,
          payload: {
            isLoading: false,
          },
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getCycleListOptionsForManager = () => (dispatch) => {
  http
    .get(GET_QUARTERLY_CYCLE_LIST_FOR_MANAGER)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        dispatch({ type: "CLEAR_MANAGER_DASHBOARD_ASSESSMENT_FILTER_DATA" });
        let cycleList = [];
        if (response.data?.length > 0) {
          cycleList = response.data.map((v) => {
            return {
              value: v.cycleId,
              label: v.cycle,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_CYCLE_LIST_OPTIONS_FOR_MANAGER,
          payload: cycleList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getEmployeeViewForManagerInPopup = (body, type) => (dispatch) => {
  let url = type?.includes("Objective") ? OBJECTIVE_GRADE_POPUP_FOR_MANAGER : PRIVATE_ASSESSMENT_POPUP_FOR_MANAGER;
  dispatch({ type: "START_MANAGER_PMS_DASHBOARD_LOADING" });
  http
    .post(url, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_PMS_EMPLOYEE_VIEW_IN_POPUP_FOR_MANAGER,
            payload: {
              employeeViewInPopup: response.data,
              popupBody: body,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_PMS_EMPLOYEE_VIEW_IN_POPUP_FOR_MANAGER,
            payload: {
              employeeViewInPopup: [],
            },
          });
        }
      }
    })
    .catch(() =>
      dispatch({
        type: actionTypes.SET_PMS_EMPLOYEE_VIEW_IN_POPUP_FOR_MANAGER,
        payload: {
          employeeViewInPopup: [],
        },
      })
    );
};

export const getManagerDashboardAssessmentTable = (type, body) => (dispatch) => {
  let url = type === "OBJECTIVE" ? OBJECTIVE_GRADE_COUNT_FOR_MANAGER : PRIVATE_ASSESSMENT_COUNT_FOR_MANAGER;
  dispatch({ type: "START_MANAGER_PMS_DASHBOARD_LOADING" });
  http
    .post(url, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        if (response.data?.tableContent?.length > 0) {
          dispatch({
            type: actionTypes.SET_MANAGER_DASHBOARD_ASSESSMENT_TABLE_CONTENT,
            payload: {
              tableHeader: response.data.tableHeaders,
              tableData: response.data.tableContent,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_MANAGER_DASHBOARD_ASSESSMENT_TABLE_CONTENT,
            payload: {
              tableHeader: [],
              tableData: [],
            },
          });
        }
      }
    })
    .catch(() =>
      dispatch({
        type: actionTypes.SET_MANAGER_DASHBOARD_ASSESSMENT_TABLE_CONTENT,
        payload: {
          tableHeader: [],
          tableData: [],
        },
      })
    );
};

export const getEmployeeViewAssessmentComparisonForManager = (body) => (dispatch) => {
  dispatch({ type: "START_MANAGER_PMS_DASHBOARD_LOADING" });
  http
    .post(EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_FOR_MANAGER, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_FOR_MANAGER,
            payload: {
              employeesViewComparison: response.data,
              filteredViewComparison: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_FOR_MANAGER,
            payload: {
              employeesViewComparison: [],
              filteredViewComparison: [],
            },
          });
        }
      }
    })
    .catch((error) => console.warn(error));
};

export const getEmployeeViewAssessmentExportForManager = (body) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .post(EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_EXPORT_FOR_MANAGER, {}, body, { responseType: "arraybuffer" })
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = "Performance Deviation.xlsx";
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          success: true,
          message: "File downloaded successfully.",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
        success: false,
        message: "Error Downloading file .",
      });
    });
};

//Export assessment
export const downloadPrivateAssessTableForManager = (type, body) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  let url = type?.includes("Objective")
    ? OBJECTIVE_GRADE_REPORT_FOR_MANAGER
    : PRIVATE_ASSESSMENT_COUNT_REPORT_FOR_MANAGER;
  http
    .post(url, {}, { ...body }, { responseType: "arraybuffer" })
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = `${type}.xlsx`;
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
          success: true,
          message: "File downloaded successfully.",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
        success: false,
        message: "Error Downloading file .",
      });
    });
};

export const downloadPrivateAssessTablePopupForManager = (type, body) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  let url = type?.includes("Objective")
    ? OBJECTIVE_GRADE_POPUP_REPORT_FOR_MANAGER
    : PRIVATE_ASSESSMENT_POPUP_REPORT_FOR_MANAGER;
  http
    .post(url, {}, { ...body }, { responseType: "arraybuffer" })
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        if (byteArray[0] === 123) {
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            success: false,
            message: "Record Not Found.",
          });
        } else {
          var blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let filename = `${type}.xlsx`;
          saveAs(blob, filename);
          dispatch({
            type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
            success: true,
            message: "File downloaded successfully.",
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
        success: false,
        message: "Error Downloading file .",
      });
    });
};

export const sendAcknowledgement = (data) => {
  return (dispatch) => {
    http
      .post(ASSESSMENT_ACKNOWLEDGEMENT, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.SET_ACKNOWLEDGEMENT,
            success: true,
            message: "Acknowledgement sent successfully",
            data: {
              comment: data.comment,
              isSubmit: true,
              submittedDate: new Date(),
              isAcknowledged: data.isAcknowledged,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_ACKNOWLEDGEMENT,
            success: false,
            message: response.message.message,
            data: null,
          });
        }
      })
      .catch(() => {
        console.warn();
      });
  };
};

export const getManagerTeamAssessmentList = (currentPage, pageSize, name, assessmentCount, ratingOrder) => {
  let params = {
    pageNumber: currentPage ? currentPage : 1,
    offset: pageSize ? pageSize : 5,
    name: name ? name : undefined,
    assessmentCount,
    ratingOrder: ratingOrder ? (ratingOrder === "ORDER_BY" ? undefined : ratingOrder) : undefined,
  };
  return (dispatch) => {
    !name && dispatch({ type: "START_EMPLOYEE_LIST_LOADING" });
    http
      .get(MANAGER_TEAM_ASSESSMENT_LIST, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS) {
          let count = currentPage > 1 ? {} : { assessmentCount: response.data?.assessmentCount };
          dispatch({
            type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_LIST,
            payload: {
              managerTeamMemberList: response.data?.assessmentDetails || [],
              ...count,
              isTeamLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_LIST,
            payload: {
              isTeamLoading: false,
            },
          });
        }
      })
      .catch((error) => {
        console.warn(error);
        dispatch({
          type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_LIST,
          payload: {
            isTeamLoading: false,
          },
        });
      });
  };
};

export const getEmployeeTimelineForManager = (year, employeeIds) => {

  let params;
  let isOldCycle = false;
  let is2023Cycle = false;

  if(year === "endDate"){
    isOldCycle = true;
    is2023Cycle = false;
    params = {
      role: "MANAGER",
        employeeId: employeeIds?.employeeId,
        parentGroupId: employeeIds?.employeeParentGroupId,
        subGroupId: employeeIds?.employeeSubGroupId,
        endDate: "2022-12-31",
    };

  } else if(year === "startDate"){
    isOldCycle = false;
    is2023Cycle = true;
    params = {
      role: "MANAGER",
        employeeId: employeeIds?.employeeId,
        parentGroupId: employeeIds?.employeeParentGroupId,
        subGroupId: employeeIds?.employeeSubGroupId,
        startDate:"2022-12-31",
        endDate: "2023-12-31",  
    };

  }else {
    isOldCycle = false;
    is2023Cycle = false;
    params = {
      role: "MANAGER",
        employeeId: employeeIds?.employeeId,
        parentGroupId: employeeIds?.employeeParentGroupId,
        subGroupId: employeeIds?.employeeSubGroupId,
        is2024Cycle:true,
        startDate: "2023-12-31",
    };

  }
 
  return (dispatch) => {
    dispatch({ type: "START_IS_PAGE_LOADING_IN_ASSESSMENT" });
    http
      .get(EMPLOYEE_TIMELINE, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.data) {
          let { assessments, currentProjects, deallocationFeedbacks, promotion, rewards } = response.data;
          const { assessmentGraphData, currentProjectLineGraph, projectLineVisible, assessmentAreaVisible } =
          getYearLine(assessments, currentProjects, deallocationFeedbacks, promotion, rewards, isOldCycle, is2023Cycle);

          dispatch({
            type: actionTypes.SET_TIMELINE_DATA_LIST_FOR_MANAGER,
            employeeId: employeeIds?.employeeId,
            timelineGraph: assessmentGraphData,
            currentProjectLineGraph: currentProjectLineGraph || [],
            projectLineVisible: projectLineVisible,
            assessmentAreaVisible: assessmentAreaVisible,
          });
        } else {
          dispatch({
            type: actionTypes.SET_TIMELINE_DATA_LIST_FOR_MANAGER,
            employeeId: employeeIds?.employeeId,
            timelineGraph: [],
            currentProjectLineGraph: [],
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_TIMELINE_DATA_LIST_FOR_MANAGER,
          employeeId: employeeIds?.employeeId,
          timelineGraph: [],
          currentProjectLineGraph: [],
        });
      });
  };
};

export const getEmployeeTimelineForHR = (year, employeeIds) => {
  
  let params;
  let isOldCycle = false;
  let is2023Cycle = false;

  if(year === "endDate"){
    isOldCycle = true;
    is2023Cycle = false;
    params = {
      role: "HR",
      employeeId: employeeIds?.employeeId,
      parentGroupId: employeeIds?.employeeParentGroupId,
      subGroupId: employeeIds?.employeeSubGroupId,   
      endDate: "2022-12-31",
    };

  } else if(year === "startDate"){
      isOldCycle = false;
      is2023Cycle = true;
      params = {
        role: "HR",    
        employeeId: employeeIds?.employeeId,
        parentGroupId: employeeIds?.employeeParentGroupId,
        subGroupId: employeeIds?.employeeSubGroupId,   
        startDate:"2022-12-31",
        endDate: "2023-12-31",
      };

  } else {
    isOldCycle = false;
    is2023Cycle = false;
    params = {
      role: "HR",    
      employeeId: employeeIds?.employeeId,
      parentGroupId: employeeIds?.employeeParentGroupId,
      subGroupId: employeeIds?.employeeSubGroupId,
      is2024Cycle:true, 
      startDate: "2023-12-31",
    };
  }
 
  return (dispatch) => {
    dispatch({ type: "START_IS_PAGE_LOADING_IN_ASSESSMENT" });
    http
      .get(EMPLOYEE_TIMELINE, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.data) {
          let { assessments, currentProjects, deallocationFeedbacks, promotion, rewards } = response.data;
          const { assessmentGraphData, currentProjectLineGraph, projectLineVisible, assessmentAreaVisible } =
          getYearLine(assessments, currentProjects, deallocationFeedbacks, promotion, rewards, isOldCycle, is2023Cycle);

          dispatch({
            type: actionTypes.SET_TIMELINE_DATA_LIST_FOR_HR,
            employeeId: employeeIds?.employeeId,
            timelineGraph: assessmentGraphData,
            currentProjectLineGraph: currentProjectLineGraph || [],
            projectLineVisible: projectLineVisible,
            assessmentAreaVisible: assessmentAreaVisible,
          });
        } else {
          dispatch({
            type: actionTypes.SET_TIMELINE_DATA_LIST_FOR_HR,
            employeeId: employeeIds?.employeeId,
            timelineGraph: [],
            currentProjectLineGraph: [],
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_TIMELINE_DATA_LIST_FOR_HR,
          employeeId: employeeIds?.employeeId,
          timelineGraph: [],
          currentProjectLineGraph: [],
        });
      });
  };
};

export const getTeamAssessmentSearchByManager = (assessmentType, assessmentCount, name) => {
  let params = {
    gradeOrRating: assessmentType,
    assessmentCount,
    name: name ? name : undefined,
  };
  return (dispatch) => {
    dispatch({ type: "START_IS_PAGE_LOADING_IN_ASSESSMENT" });
    http
      .get(TEAM_ASSESSMENT, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS && response.data && response.data?.length > 0) {
          let teamAssessmentList = response.data.map((value) => {
            return {
              ...value.employeeDetails,
            };
          });
          dispatch({
            type: actionTypes.SET_ASSESSMENT_TEAM_BY_SEARCH_NAME,
            payload: {
              assessmentTeam: teamAssessmentList,
              isPageLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_ASSESSMENT_TEAM_BY_SEARCH_NAME,
            payload: {
              assessmentTeam: [],
              isPageLoading: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_ASSESSMENT_TEAM_BY_SEARCH_NAME,
          payload: {
            assessmentTeam: [],
            isTeamLoading: false,
          },
        });
      });
  };
};

export const getGradeTeamAssessmentForManager = (assessmentCount = 4) => {
  let params = {
    gradeOrRating: "GRADE",
    assessmentCount,
  };
  return (dispatch) => {
    dispatch({ type: "START_IS_PAGE_LOADING_IN_ASSESSMENT" });
    http
      .get(TEAM_ASSESSMENT, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS && response.data && response.data?.length > 0) {
          let teamAssessmentGraph = response.data.map((value) => {
            return {
              ...value,
              name: value.cycle,
              a: 4,
              b: 4,
              c: 4,
              a_plus: 4,
              b_plus: 4,
              a_teamMember: value.quarterlyCycleHistoryTimelineMap["A"]?.length || 0,
              b_teamMember: value.quarterlyCycleHistoryTimelineMap["B"]?.length || 0,
              c_teamMember: value.quarterlyCycleHistoryTimelineMap["C"]?.length || 0,
              a_plus_teamMember: value.quarterlyCycleHistoryTimelineMap["A+"]?.length || 0,
              b_plus_teamMember: value.quarterlyCycleHistoryTimelineMap["B+"]?.length || 0,
            };
          });
          dispatch({
            type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_GRAPH,
            payload: {
              gradeTeamAssessmentGraph: teamAssessmentGraph,
              gradeTeamAssessmentData: response.data,
              isPageLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_GRAPH,
            payload: {
              gradeTeamAssessmentGraph: [],
              gradeTeamAssessmentData: [],
              isPageLoading: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_GRAPH,
          payload: {
            gradeTeamAssessmentGraph: [],
            gradeTeamAssessmentData: [],
            isTeamLoading: false,
          },
        });
      });
  };
};

export const getRatingTeamAssessmentForManager = (assessmentCount = 4) => {
  let params = {
    gradeOrRating: "RATING",
    assessmentCount,
  };
  return (dispatch) => {
    dispatch({ type: "START_IS_PAGE_LOADING_IN_ASSESSMENT" });
    http
      .get(TEAM_ASSESSMENT, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === constStatus.SUCCESS && response.data && response.data?.length > 0) {
          let teamAssessmentGraph = response.data.map((value) => {
            return {
              ...value,
              name: value.cycle,
              a: 4,
              b: 4,
              c: 4,
              a_plus: 4,
              b_plus: 4,
              a_teamMember: value.quarterlyCycleHistoryTimelineMap["A"]?.length || 0,
              b_teamMember: value.quarterlyCycleHistoryTimelineMap["B"]?.length || 0,
              c_teamMember: value.quarterlyCycleHistoryTimelineMap["C"]?.length || 0,
              a_plus_teamMember: value.quarterlyCycleHistoryTimelineMap["A+"]?.length || 0,
              b_plus_teamMember: value.quarterlyCycleHistoryTimelineMap["B+"]?.length || 0,
            };
          });
          dispatch({
            type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_GRAPH,
            payload: {
              ratingTeamAssessmentGraph: teamAssessmentGraph,
              ratingTeamAssessmentData: response.data,
              isPageLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_GRAPH,
            payload: {
              ratingTeamAssessmentGraph: [],
              ratingTeamAssessmentData: [],
              isPageLoading: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_MANAGER_TEAM_ASSESSMENT_GRAPH,
          payload: {
            ratingTeamAssessmentGraph: [],
            ratingTeamAssessmentData: [],
            isTeamLoading: false,
          },
        });
      });
  };
};

export const getEmployeeNameList = (name) => (dispatch) => {
  http
    .get(GET_EMPLOYEE_NAME_LIST, { name: name })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === constStatus.SUCCESS) {
        let employeeList = [];
        if (response.data) {
          employeeList = response.data.map((value) => {
            return {
              value: value.employeeId,
              label: `${value.empName} (${value.employeeId})`,
              ...value,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_EMPLOYEE_NAME,
          data: employeeList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

//Timeline graph
const getTimeline = (assessments, currentProjects, deallocationFeedbacks, promotion, rewards, monthFilter) => {
  let assessmentGraphData = [];
  let currentProjectLineGraph;
  var d = new Date();
  var d2 = new Date();
  d.setDate(1);
  d2.setDate(1);
  if (monthFilter >= 12) {
    let currentMonth = d.getMonth();
    if (currentMonth < 2) {
      d.setFullYear(d.getFullYear() - 1, getNearestQuarterMonth(currentMonth));
      d2.setFullYear(d2.getFullYear() - 1, getNearestQuarterMonth(currentMonth));
    } else {
      d.setFullYear(d.getFullYear(), getNearestQuarterMonth(currentMonth));
      d2.setFullYear(d2.getFullYear(), getNearestQuarterMonth(currentMonth));
    }
  }

  if (monthFilter < 12) {
    for (let i = 1; i <= monthFilter; i++) {
      assessmentGraphData.push({
        name: getStringMonth(d.getMonth()) + " " + d.getFullYear(),
        date: d.setMonth(d.getMonth()),
      });
      d.setMonth(d.getMonth() - 1);
    }
  } else {
    for (let i = 1; i <= monthFilter / 3; i++) {
      let dates = [];
      let dateMonths = [];
      for (let j = 1; j <= 3; j++) {
        dateMonths.push(getStringMonth(d2.getMonth()) + " " + d2.getFullYear());
        dates.push(d2.setMonth(d2.getMonth()));
        d2.setMonth(d2.getMonth() - 1);
      }
      assessmentGraphData.push({
        name: getStringMonth(d.getMonth()) + " " + d.getFullYear(),
        date: d.setMonth(d.getMonth()),
        dates: dates,
        dateMonths: dateMonths,
      });
      d.setMonth(d.getMonth() - 3);
    }
  }
  //ASSESSMENT
  if (assessments?.length > 0) {
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findAssessment;
      if (monthFilter < 12) {
        findAssessment = assessments?.find((value) => value.period === month.name);
      } else {
        findAssessment = assessments?.find((value) => month.dateMonths.includes(value.period));
      }

      if (findAssessment) {
        return {
          ...month,
          assessments: findAssessment,
          assessment:
            findAssessment.gradeOrRatingOrBoth === "RATING"
              ? findAssessment.userRating
              : gradeValue(findAssessment.userGrade),
          gradeOrRatingOrBoth: findAssessment.gradeOrRatingOrBoth,
          grade: findAssessment.userGrade,
          cycle: findAssessment.cycle,
          cycleId: findAssessment.cycleId,
        };
      }
      //default return months
      return month;
    });
  }
  //PROJECTS
  if (currentProjects?.length > 0) {
    currentProjectLineGraph = currentProjects.map((value, index) => `project${index + 1}`);
    currentProjects = currentProjects?.map((project, index) => {
      if (new Date(project.startDate) < new Date(assessmentGraphData[assessmentGraphData.length - 1].date)) {
        return {
          ...project,
          dummyEndDate: new Date(project.endDate),
          dummyStartDate: new Date(assessmentGraphData[assessmentGraphData.length - 1].date),
          projectGraphValue: index + 1,
        };
      }
      if (new Date(project.endDate) > new Date(assessmentGraphData[0].date)) {
        return {
          ...project,
          dummyEndDate: new Date(assessmentGraphData[0].date),
          dummyStartDate: new Date(project.startDate),
          projectGraphValue: index + 1,
        };
      }
    });

    assessmentGraphData = assessmentGraphData.map((month) => {
      let findProjects = currentProjects.filter(
        (project) =>
          // (project.startDate &&  getMonthAndYearFromDate(project.dummyStartDate) === month.name) ||
          // (project.endDate && getMonthAndYearFromDate(project.dummyEndDate) === month.name)
          project.startDate &&
          project.endDate &&
          new Date(month.date) >= project.dummyStartDate &&
          new Date(month.date) <= project.dummyEndDate
      );
      if (findProjects && findProjects.length > 0) {
        let map = {};
        findProjects.map((value, index) => {
          map[`projects${index + 1}`] = value;
          map[`project${index + 1}`] = index + 1;
        });
        return {
          ...month,
          ...map,
        };
      }

      //default return months
      return month;
    });
  }

  //REWARDS
  if (rewards?.length > 0) {
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findRewards;
      if (monthFilter < 12) {
        findRewards = rewards?.filter(
          (value) =>
            getStringMonth(new Date(value.rewardDate).getMonth()) + " " + new Date(value.rewardDate).getFullYear() ===
            month.name
        );
      } else {
        findRewards = rewards?.filter((value) =>
          month.dateMonths.includes(
            getStringMonth(new Date(value.rewardDate).getMonth()) + " " + new Date(value.rewardDate).getFullYear()
          )
        );
      }
      if (findRewards?.length > 0) {
        return {
          ...month,
          rewards: findRewards,
          reward: 2,
        };
      }
      //default return months
      return month;
    });
  }

  //PROMOTION
  if (promotion?.length > 0) {
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findPromotion;
      if (monthFilter < 12) {
        findPromotion = promotion?.find(
          (value) =>
            getStringMonth(new Date(value.createdDate).getMonth()) + " " + new Date(value.createdDate).getFullYear() ===
            month.name
        );
      } else {
        findPromotion = promotion?.find((value) =>
          month.dateMonths.includes(
            getStringMonth(new Date(value.createdDate).getMonth()) + " " + new Date(value.createdDate).getFullYear()
          )
        );
      }
      if (findPromotion) {
        return {
          ...month,
          promotions: findPromotion,
          promotionValue: findPromotion.designation,
          promotion: 1,
        };
      }
      //default return months
      return month;
    });
  }

  //deallocation
  if (deallocationFeedbacks?.length > 0) {
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findDeallocation = deallocationFeedbacks?.filter(
        (value) =>
          getStringMonth(new Date(value.createdDate).getMonth()) + " " + new Date(value.createdDate).getFullYear() ===
          month.name
      );
      if (findDeallocation && findDeallocation.length > 0) {
        let map = {};
        findDeallocation.map((value, index) => {
          let i = index + 1;
          map["deallocation" + i] = 3 + i * 0.25;
          map["deallocations" + i] = value;
          map["projectDeAllocationId" + i] = value.projectDeAllocationId;
        });
        return {
          ...month,
          ...map,
        };
      }
      //default return months
      return month;
    });
  }
  //check what we have display in graph line or dot for current project
  let projectLineVisible = [];
  if (currentProjectLineGraph?.length > 0) {
    if (currentProjectLineGraph.length >= 1) {
      if (assessmentGraphData.filter((value) => value.project1)?.length > 1) {
        projectLineVisible.push("project1");
      }
      if (assessmentGraphData.filter((value) => value.project2)?.length > 1) {
        projectLineVisible.push("project2");
      }
      if (assessmentGraphData.filter((value) => value.project3)?.length > 1) {
        projectLineVisible.push("project3");
      }
    }
  }

  //check what we have to display in graph area or dot for assessment
  let assessmentAreaVisible = false;
  if (assessmentGraphData.filter((value) => value.assessments).length > 1) {
    assessmentAreaVisible = true;
  }

  return {
    assessmentGraphData,
    currentProjectLineGraph,
    projectLineVisible,
    assessmentAreaVisible,
  };
};

const getYearLine = (assessments, currentProjects, deallocationFeedbacks, promotion, rewards, isOld, is2023Cycle) => {
  // let monthFilter = isOld ? 24 : ((new Date().getMonth() > 11 ? new Date().getMonth() : new Date().getMonth() + 1) + 12);  
  // let monthFilter = isOld ? 24 : is2023Cycle ? 12 : ((new Date().getMonth() > 11 ? new Date().getMonth() + 12 : new Date().getMonth() + 1));
  let monthFilter = isOld ? 24 : is2023Cycle ? 12 : ((new Date().getFullYear() > 2024 ? new Date().getMonth() + 12 : new Date().getMonth() + 1));
  let assessmentGraphData = [];
  let currentProjectLineGraph;
  var d = isOld ?  new Date(2023, 0, 24) : is2023Cycle ? new Date(2024, 0, 24) : new Date();
  var d2 = isOld ?  new Date(2023, 0, 24) : is2023Cycle ? new Date(2024, 0, 24) : new Date();
  d.setDate(1);
  d2.setDate(1);
  if (monthFilter >= 12) {
    let currentMonth = d.getMonth();
    if (currentMonth < 2) {
      d.setFullYear(d.getFullYear() - 1, getNearestQuarterMonth(currentMonth));
      d2.setFullYear(d2.getFullYear() - 1, getNearestQuarterMonth(currentMonth));
    } else {
      d.setFullYear(d.getFullYear(), getNearestQuarterMonth(currentMonth));
      d2.setFullYear(d2.getFullYear(), getNearestQuarterMonth(currentMonth));
    }
  }
  if (monthFilter < 12) {
    for (let i = 1; i <= monthFilter; i++) {
      assessmentGraphData.push({
        name: getStringMonth(d.getMonth()) + " " + d.getFullYear(),
        date: d.setMonth(d.getMonth()),
      });
      d.setMonth(d.getMonth() - 1);
    }
  } else {
    for (let i = 1; i <= monthFilter / 3; i++) {
      let dates = [];
      let dateMonths = [];
      for (let j = 1; j <= 3; j++) {
        dateMonths.push(getStringMonth(d2.getMonth()) + " " + d2.getFullYear());
        dates.push(d2.setMonth(d2.getMonth()));
        d2.setMonth(d2.getMonth() - 1);
      }
      assessmentGraphData.push({
        name: getStringMonth(d.getMonth()) + " " + d.getFullYear(),
        date: d.setMonth(d.getMonth()),
        dates: dates,
        dateMonths: dateMonths,
      });
      d.setMonth(d.getMonth() - 3);
    }
  }
  //ASSESSMENT
  if (assessments?.length > 0) {
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findAssessment;
      if (monthFilter < 12) {
        findAssessment = assessments?.find((value) => value.period === month.name);
      } else {
        findAssessment = assessments?.find((value) => month.dateMonths.includes(value.period));
      }
      if (findAssessment) {
        return {
          ...month,
          assessments: findAssessment,
          assessment:
            findAssessment.gradeOrRatingOrBoth === "RATING"
              ? findAssessment.userRating
              : gradeValue(findAssessment.userGrade),
          gradeOrRatingOrBoth: findAssessment.gradeOrRatingOrBoth,
          grade: findAssessment.userGrade,
          cycle: findAssessment.cycle,
          cycleId: findAssessment.cycleId,
        };
      }
      //default return months
      return month;
    });
  }
  //PROJECTS
  if (currentProjects?.length > 0) {
    currentProjectLineGraph = currentProjects.map((value, index) => `project${index + 1}`);
    currentProjects = currentProjects?.map((project, index) => {
      // new block added for new logic
      if (new Date(project.startDate) > new Date(assessmentGraphData[assessmentGraphData.length - 1].date)) {
        return {
          ...project,
          dummyEndDate: new Date(project.endDate),
          dummyStartDate: new Date(project.startDate),
          projectGraphValue: index + 1,
        };
      }
      if (new Date(project.startDate) < new Date(assessmentGraphData[assessmentGraphData.length - 1].date)) {
        return {
          ...project,
          dummyEndDate: new Date(project.endDate),
          dummyStartDate: new Date(assessmentGraphData[assessmentGraphData.length - 1].date),
          projectGraphValue: index + 1,
        };
      }
      if (new Date(project.endDate) > new Date(assessmentGraphData[0].date)) {
        return {
          ...project,
          dummyEndDate: new Date(assessmentGraphData[0].date),
          dummyStartDate: new Date(project.startDate),
          projectGraphValue: index + 1,
        };
      }
    });
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findProjects = currentProjects.filter(
        (project) =>
          // (project.startDate &&  getMonthAndYearFromDate(project.dummyStartDate) === month.name) ||
          // (project.endDate && getMonthAndYearFromDate(project.dummyEndDate) === month.name)
          project.startDate &&
          project.endDate &&
          new Date(month.date) >= project.dummyStartDate &&
          new Date(month.date) <= project.dummyEndDate
      );
      if (findProjects && findProjects.length > 0) {
        let map = {};
        findProjects.map((value, index) => {
          map[`projects${index + 1}`] = value;
          map[`project${index + 1}`] = index + 1;
        });
        return {
          ...month,
          ...map,
        };
      }
      //default return months
      return month;
    });
  }
  //REWARDS
  if (rewards?.length > 0) {
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findRewards;
      if (monthFilter < 12) {
        findRewards = rewards?.filter(
          (value) =>
            getStringMonth(new Date(value.rewardDate).getMonth()) + " " + new Date(value.rewardDate).getFullYear() ===
            month.name
        );
      } else {
        findRewards = rewards?.filter((value) =>
          month.dateMonths.includes(
            getStringMonth(new Date(value.rewardDate).getMonth()) + " " + new Date(value.rewardDate).getFullYear()
          )
        );
      }
      if (findRewards?.length > 0) {
        return {
          ...month,
          rewards: findRewards,
          reward: 2,
        };
      }
      //default return months
      return month;
    });
  }
  //PROMOTION
  if (promotion?.length > 0) {
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findPromotion;
      if (monthFilter < 12) {
        findPromotion = promotion?.find(
          (value) =>
            getStringMonth(new Date(value.createdDate).getMonth()) + " " + new Date(value.createdDate).getFullYear() ===
            month.name
        );
      } else {
        findPromotion = promotion?.find((value) =>
          month.dateMonths.includes(
            getStringMonth(new Date(value.createdDate).getMonth()) + " " + new Date(value.createdDate).getFullYear()
          )
        );
      }
      if (findPromotion) {
        return {
          ...month,
          promotions: findPromotion,
          promotionValue: findPromotion.designation,
          promotion: 1,
        };
      }
      //default return months
      return month;
    });
  }
  //deallocation
  if (deallocationFeedbacks?.length > 0) {
    assessmentGraphData = assessmentGraphData.map((month) => {
      let findDeallocation = deallocationFeedbacks?.filter(
        (value) =>
          getStringMonth(new Date(value.createdDate).getMonth()) + " " + new Date(value.createdDate).getFullYear() ===
          month.name
      );
      if (findDeallocation && findDeallocation.length > 0) {
        let map = {};
        findDeallocation.map((value, index) => {
          let i = index + 1;
          map["deallocation" + i] = 3 + i * 0.25;
          map["deallocations" + i] = value;
          map["projectDeAllocationId" + i] = value.projectDeAllocationId;
        });
        return {
          ...month,
          ...map,
        };
      }
      //default return months
      return month;
    });
  }
  //check what we have display in graph line or dot for current project
  let projectLineVisible = [];
  if (currentProjectLineGraph?.length > 0) {
    if (currentProjectLineGraph.length >= 1) {
      if (assessmentGraphData.filter((value) => value.project1)?.length > 1) {
        projectLineVisible.push("project1");
      }
      if (assessmentGraphData.filter((value) => value.project2)?.length > 1) {
        projectLineVisible.push("project2");
      }
      if (assessmentGraphData.filter((value) => value.project3)?.length > 1) {
        projectLineVisible.push("project3");
      }
    }
  }
  //check what we have to display in graph area or dot for assessment
  let assessmentAreaVisible = false;
  if (assessmentGraphData.filter((value) => value.assessments).length > 1) {
    assessmentAreaVisible = true;
  }
  return {
    assessmentGraphData,
    currentProjectLineGraph,
    projectLineVisible,
    assessmentAreaVisible,
  };
};


export const uploadFileGlobalFeedback = (formData, employeeIds, cycleId, type) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .post(UPLOAD_FILE_GLOBAL_FEEDBACK, null, formData)
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.status === "FAILURE") {
          dispatch({
            type: actionTypes.GLOBAL_FEEDBACK_FILE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        } else {
          dispatch({
            type: actionTypes.GLOBAL_FEEDBACK_FILE_RESPONSE,
            message: "File uploaded successfully!",
            success: true,
            globalFeedbackUpdatedDate: response.data,
          });
          if (type === "SELF") {
            dispatch(GetSelfAssessmentGlobalComment(employeeIds, cycleId));
          } else if (type === "REPORTING") {
            dispatch(GetRPMAssessmentGlobalComment(employeeIds, cycleId));
          } else if (type === "REVIEWING") {
            dispatch(GetAssessmentGlobalComment(employeeIds, cycleId));
          }
        }
      },
      () => {
        dispatch({
          type: actionTypes.GLOBAL_FEEDBACK_FILE_RESPONSE,
          message: "Please select valid file",
          success: false,
        });
      }
    );
};

export const DownloadFileGlobalFeedback = (params) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .get(DOWNLOAD_FILE_GLOBAL_FEEDBACK, params)
    .then((response) => response.data.data)
    .then((response) => {
      dispatch({
        type: actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE,
        message: "File Downloaded successfully",
        success: true,
      });
      const blob = b64toBlob(response.binary.data, response.file.contentType);
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", response.file.name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(() => {});
};

export const DeleteFileGlobalFeedback = (params, employeeIds, cycleId, type) => (dispatch) => {
  dispatch({ type: "START_FILE_DOWN_UP_DELETE_LOADING" });
  http
    .delete(DELETE_FILE_GLOBAL_FEEDBACK, null, null, params)
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.status === constStatus.SUCCESS) {
          dispatch({
            type: actionTypes.GLOBAL_FEEDBACK_FILE_RESPONSE,
            message: "File deleted successfully!",
            success: true,
            updatedDate: response.data,
          });
          if (type === "SELF") {
            dispatch(GetSelfAssessmentGlobalComment(employeeIds, cycleId));
          } else if (type === "REPORTING") {
            dispatch(GetRPMAssessmentGlobalComment(employeeIds, cycleId));
          } else if (type === "REVIEWING") {
            dispatch(GetAssessmentGlobalComment(employeeIds, cycleId));
          }
        } else {
          dispatch({
            type: actionTypes.GLOBAL_FEEDBACK_FILE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        }
      },
      () => {
        dispatch({
          type: actionTypes.GLOBAL_FEEDBACK_FILE_RESPONSE,
          message: "Issue in deleting file",
          success: false,
        });
      }
    );
};
