import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import style from "./AddCategoryModal.module.scss";
import * as actions from "../../../../store/actions/index";
import { GET_TEMPLATE_CATEGORY } from "../../../../util/endpoints";
import SlideToast from "../../../../components/Toast/Toast";
import http from "../../../../util/http";
import storage from "../../../../util/storage";

const AddCategoryModal = (props) => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const campaignId = storage.get("cId");
  let config = campaignId && {
    params: {
      campaignId,
    },
  };

  const handleChange = (e) => {
    if (e.target.value.startsWith(" ")) {
      e.target.value.replace(/^\s/, "");
    } else {
      setCategory(e.target.value);
    }
  };

  function saveCategory() {
    let data = {
      name: category,
    };
    http
      .post(GET_TEMPLATE_CATEGORY, {}, data, config)
      .then((response) => response.data)
      .then(() => {
        setCategory("");
        toast.success(<SlideToast title="Awesome!" message={"Category Save successfully"} error={false} />, {
          toastId: "CATEGORY_SAVE",
        });
        dispatch(actions.getTemplateCategory());
        props.hide();
      })
      .catch(console.error);
  }
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} size="lg" className="custom_modal_popup">
        <div className={[style.category_modal, "custom_modal_container"].join(" ")}>
          <Modal.Header className={style.mHeader}>
            <Modal.Title> New Category </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="form-group">
              <span className={style.dd_title}>Add Category</span>
              <div className="card_action">
                <Form.Group controlId="">
                  <Form.Control type="text" placeholder="Title" value={category} onChange={handleChange}></Form.Control>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={() => saveCategory()}>
              Add
            </Button>
            <Button variant="secondary" className="fs-500" onClick={() => props.hide()}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
