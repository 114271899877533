import React, { useContext, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Button, Col, Container, Form, Row, Tooltip, Overlay } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import useForm from "react-hook-form";
import * as yup from "yup";
import * as CryptoJS from "crypto-js";
import * as actions from "store/actions/index";
import { IMG_LOGIN_ENGAGE, IC_LOGIN_PASS, IC_LOGIN_USER, IC_ERROR, IMG_POWERED_BY_APEXON } from "const/imgCost";
import AuthContext, { ActionType } from "context/authContext";
import { LOGIN } from "util/endpoints";
import http from "util/http";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import LoginFooter from "../login-footer/LoginFooter";
import style from "./login.module.scss";
import routePath from "const/routePath";

export default function Login() {
  const history = useHistory();
  const schema = yup.object().shape({
    userName: yup.string().required("Username is a required field"),
    password: yup.string().required("Password is a required field"),
  });
  const { handleSubmit, register, errors } = useForm({
    validationSchema: schema,
  });
  const targetUserName = useRef(null);
  const targetPassword = useRef(null);
  const target = useRef(null);
  const dispatch = useDispatch();
  const context = useContext(AuthContext);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = () => {
    setError(null);
  };

  const onSubmit = (data) => {
    setDisabled(true);

    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_PRIVATE_KEY);
    var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_PRIVATE_KEY);

    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data.password.toString()), key, {
      iv: iv,
    });

    const password = encrypted.toString();

    const loginData = {
      email: data.userName,
      password: password,
    };

    setShowLoader(true);

    http
      .post(LOGIN, {}, loginData)
      .then((response) => response.data.data)
      .then((response) => {
        setShowLoader(false);
        let userEncrypted = CryptoJS.AES.encrypt(
          JSON.stringify(response.profile),
          process.env.REACT_APP_CRYPTO_PRIVATE_KEY
        );
        let moduleEncrypted = CryptoJS.AES.encrypt(
          JSON.stringify(response.defaultView),
          process.env.REACT_APP_CRYPTO_PRIVATE_KEY
        );
        let pageViewEncrypted = CryptoJS.AES.encrypt(
          JSON.stringify(response.roleRightsView),
          process.env.REACT_APP_CRYPTO_PRIVATE_KEY
        );

        storage.set("_Vu__", userEncrypted.toString());
        if (response.defaultView) {
          storage.set("_MO_Vu__", moduleEncrypted.toString());
        }
        if (response.defaultView) {
          storage.set("_MO_NaVu__", moduleEncrypted.toString());
        }
        if (response.roleRightsView?.length > 0) {
          storage.set("_PV_Vu__", pageViewEncrypted.toString());
        }
        // storage.setJSON("roles", response.roles);
        storage.set("token", response.token);
        response.supportContactDetails && storage.set("supportContactDetails", response.supportContactDetails);
        storage.set("refreshToken", response.refreshToken);
        response.isManager && storage.set("isManager", response.isManager);
        context.dispatch({
          type: ActionType.LOGIN,
          payload: {
            user: response.profile,
            isLoggedIn: true,
            navigation: response.defaultView,
            pageView: response.roleRightsView,
          },
        });
        dispatch(actions.FetchNotification(response.profile));
        if (
          response.defaultView
            ?.find((view) => view.moduleName === "OKR Progress")
            ?.subModuleAndRights?.find((sub) => sub.subModuleName === "Campaign")
        ) {
          storage.set("cRole", "CAMPAIGN");
          history.push(routePath.CAMPAIGN);
          return;
        } else {
          history.push(routePath.HOME);
        }
      })
      .catch(() => {
        setShowLoader(false);
        setError("Invalid Username or Password");
        setDisabled(false);
      });
  };

  return (
    <div>
      <Router>
        <div className="contentArea">
          <Container>
            {showLoader && <Spinner />}
            <Row className={["align-items-center", "justify-content-center", "custom-height"].join(" ")}>
              <Col xs={12}>
                <div className={style.loginviz_container}>
                  <div className={style.login_logo}>
                    <ReactSVG src={IMG_LOGIN_ENGAGE} title="" alt="" />
                  </div>

                  <div className={style.loginviz}>
                    {/* <div className={style.loginviz_icon}>
                    <ReactSVG src={IC_LOGINVIZ} title="" alt="" />
                  </div> */}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <div className={style.loginform} ref={target}>
                        <div className={style.loginform_cont}>
                          <div className={style.popoverone}>
                            {errors.userName && (
                              <Overlay target={targetUserName} show={true} placement="right">
                                {(props) => (
                                  <Tooltip id="overlay-example" {...props}>
                                    <span className="error-icon">
                                      <ReactSVG src={IC_ERROR} title="" alt="" />
                                    </span>
                                    <span className="error-bubble-txt">{errors.userName.message}</span>
                                  </Tooltip>
                                )}
                              </Overlay>
                            )}
                          </div>
                          <div className={style.username_cont}>
                            <div className={style.ic_username}>
                              <ReactSVG src={IC_LOGIN_USER} title="" alt="" />
                            </div>
                            <div className={style.input_group} ref={targetUserName}>
                              <input type="text" name="userName" ref={register} onChange={handleChange} />
                              <span className={style.highlight}></span>
                              <span className={style.bar}></span>
                              <label>Username</label>
                            </div>
                          </div>
                        </div>
                        <div className={style.loginform_cont}>
                          <div className={style.username_cont}>
                            {errors.password && (
                              <Overlay target={targetPassword} show={true} placement="right">
                                {(props) => (
                                  <Tooltip id="overlay-example" {...props}>
                                    <span className="error-icon">
                                      <ReactSVG src={IC_ERROR} title="" alt="" />
                                    </span>
                                    <span className="error-bubble-txt">{errors.password.message}</span>
                                  </Tooltip>
                                )}
                              </Overlay>
                            )}
                            <div className={style.ic_username}>
                              <ReactSVG src={IC_LOGIN_PASS} title="" alt="" />
                            </div>
                            <div className={style.input_group} ref={targetPassword}>
                              <input type="password" name="password" ref={register} onChange={handleChange} />
                              <span className={style.highlight}></span>
                              <span className={style.bar}></span>
                              <label>Password</label>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <Row className={style.formbottom}>
                          {/* <Col xs={6} className="pr-0">
                          <div className="custom-checkbox custom-checkbox-rounded">
                            <input id="111" type="checkbox" />
                            <label htmlFor="111" className={style.remember_me}>
                              Remember Me
                            </label>
                          </div>
                        </Col> */}
                          {error && (
                            <Overlay target={target} show={true} placement="top">
                              {(props) => (
                                <Tooltip id="overlay-all" {...props} className="tooltiptop">
                                  <span className="error-icon">
                                    <ReactSVG src={IC_ERROR} title="" alt="" />
                                  </span>
                                  <span className="error-bubble-txt">{error}</span>
                                </Tooltip>
                              )}
                            </Overlay>
                          )}
                        </Row>
                        <div className="clearfix"></div>
                        <Col xs={12}>
                          <Button variant="primary" type="submit" className="d_b w-100" disabled={disabled}>
                            Login
                          </Button>
                        </Col>
                        <Col xs={12} className={["t text-center", style.forgotpass].join(" ")}>
                          <a target="_blank" rel="noopener noreferrer" href="https://passwordreset.microsoftonline.com/">
                            Reset Password
                          </a>
                        </Col>
                      </div>
                    </Form>
                  </div>
                  <div className={style.poweredby_icon}>
                    <ReactSVG src={IMG_POWERED_BY_APEXON} title="" alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <LoginFooter />
      </Router>
      {/* <SlideToast /> */}
    </div>
  );
}
