import * as CryptoJS from "crypto-js";

class Storage {
  get(key) {
    return localStorage.getItem(key) || null;
  }

  set(key, data) {
    localStorage.setItem(key, data);
    return true;
  }

  getJSON(key) {
    return JSON.parse(this.get(key)) || null;
  }

  setJSON(key, data) {
    this.set(localStorage.setItem(key, JSON.stringify(data)));
    return true;
  }

  getUser() {
    let bytes,
      decryptedData = null;
    if (this.get("_Vu__")) {
      //Stored user data in _Vu__ name
      bytes = CryptoJS.AES.decrypt(this.get("_Vu__"), process.env.REACT_APP_CRYPTO_PRIVATE_KEY);
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return decryptedData;
    // return this.getJSON("_Vu__") || null;
  }

  getEmployeeIds() {
    return {
      employeeId: this.getUser()?.employeeId,
      parentGroupId: this.getUser()?.parentGroupId,
      subGroupId: this.getUser()?.subGroupId,
    };
  }

  getModules() {
    let bytes,
      decryptedData = null;
    if (this.get("_MO_Vu__")) {
      //Stored modules data in _MO_Vu__ name
      bytes = CryptoJS.AES.decrypt(this.get("_MO_Vu__"), process.env.REACT_APP_CRYPTO_PRIVATE_KEY);
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return decryptedData;
  }
  getDefaultMenu() {
    let bytes,
      decryptedData = null;
    if (this.get("_MO_NaVu__")) {
      //Stored modules data in _MO_Vu__ name
      bytes = CryptoJS.AES.decrypt(this.get("_MO_NaVu__"), process.env.REACT_APP_CRYPTO_PRIVATE_KEY);
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return decryptedData;
  }
  getPageView() {
    let bytes,
      decryptedData = null;
    if (this.get("_PV_Vu__")) {
      //Stored page View data in _PV_Vu__ name
      bytes = CryptoJS.AES.decrypt(this.get("_PV_Vu__"), process.env.REACT_APP_CRYPTO_PRIVATE_KEY);
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return decryptedData;
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  clear() {
    const popup = this.getJSON("showpopup") || {};
    localStorage.clear();
    this.setJSON("showpopup", popup);
  }
}

export default new Storage();
