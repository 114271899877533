import React from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";

export default function CertificateDeleteModal(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.hide} animation={false} className="custom_modal_popup">
        <div className="custom_modal_container">
          <Row>
            <Col md={12}>
              <Modal.Body>
                <h1>{props.title || "Sure you want to delete ?"}</h1>
                <p>Once deleted, you won't be able to undo it.</p>
              </Modal.Body>
            </Col>
            <Col className="custom_modal_button">
              <Button variant="primary" onClick={props.submit}>
                <strong>{props.accept_button_name || "Submit"}</strong>
              </Button>
              <Button variant="secondary" className="btn-border" onClick={props.hide}>
                <strong>Cancel</strong>
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
