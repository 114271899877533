import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import {
  ONE_ON_ONE_AGENDA,
  CURRENT_ONE_ON_ONE_DATA,
  PREVIOUS_ONE_ON_ONE_DATA,
  GET_ONE_ON_ONE_AGENDA_COMMENT,
  USER_PROFILE,
  GET_USER_ONE_ON_ONE,
  DELETE_PRIVATE_NOTE,
  DELETE_SHARED_NOTE,
  DELETE_TALKING_POINT,
  MARK_TALKING_POINT,
  SCHEDULE_ONE_ON_ONE,
  END_ONE_ON_ONE,
  ONE_ON_ONE_MANAGERS,
  ONE_ON_ONE_REPORTEE,
  MANAGER_SCHEDULE_ONE_ON_ONE,
  GLOBAL_FEEDBACK,
  GET_TEAM_FEEDBACK,
  GET_SCHEDULE_TIME,
  TIMEZONE,
  ONE_ON_ONE_MEMBERS,
  ONE_ON_ONE_TEAM_LIST_FOR_HR,
} from "../../util/endpoints";
import * as actions from "./index";
import { getCompositeIds, getHourAndMinString, getListOfValue } from "../../util/general";
import { status } from "const/constValue";

export const setOneOnOneAgenda = (body, surveyCycleId) => {
  return (dispatch) => {
    http
      .post(ONE_ON_ONE_AGENDA, {}, body)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          dispatch({
            type: actionTypes.SEND_AGENDA_SUCCESS,
            message: "1-on-1 sent successfully",
          });
          http
            .get(GET_ONE_ON_ONE_AGENDA_COMMENT, { surveyCycleId })
            .then((response) => response.data.data)
            .then((response) => {
              if (response) {
                dispatch({
                  type: actionTypes.GET_ONE_ON_ONE_AGENDA_COMMENT,
                  data: response,
                });
              } else {
                dispatch({
                  type: actionTypes.GET_ONE_ON_ONE_AGENDA_COMMENT,
                  data: null,
                });
              }
            })
            .catch((error) => {
              console.warn(error);
            });
        }
      })
      .catch(() => {
        // Handle Error Here
      });
  };
};

export const GetOneOnOneAgenda = (surveyCycleId) => {
  return (dispatch) => {
    http
      .get(GET_ONE_ON_ONE_AGENDA_COMMENT, { surveyCycleId })
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE_AGENDA_COMMENT,
            data: response,
          });
        } else {
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE_AGENDA_COMMENT,
            data: null,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetCurrentOneOnOneData = (employeeIds) => {
  let params = {
    employeeId: employeeIds.employeeId,
    subGroupId: employeeIds.subGroupId,
    parentGroupId: employeeIds.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(CURRENT_ONE_ON_ONE_DATA, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.GET_CURRENT_ONE_ON_ONE_DATA,
            data: response.data?.reverse() || null,
            isCurrentLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.GET_CURRENT_ONE_ON_ONE_DATA,
            data: null,
            isCurrentLoading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const GetPreviousOneOnOneData = (employeeIds) => {
  let params = {
    employeeId: employeeIds.employeeId,
    subGroupId: employeeIds.subGroupId,
    parentGroupId: employeeIds.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(PREVIOUS_ONE_ON_ONE_DATA, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.GET_PREVIOUS_ONE_ON_ONE_DATA,
            data: response.data?.reverse() || null,
            isPreviousLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.GET_PREVIOUS_ONE_ON_ONE_DATA,
            data: null,
            isPreviousLoading: false,
          });
        }
      })
      .catch(() => {
        //error
      });
  };
};

export const GetOneOnOneUser = (data) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(USER_PROFILE, {}, { params })
      .then((response) => response.data)
      .then((data) => {
        if (data.data) {
          dispatch({ type: actionTypes.SET_ONE_ON_ONE_USER, user: data.data, isLoading: false });
        }
      })
      .catch((error) => console.warn(error));
  };
};

export const SendTalkingPoint = (data) => {
  return (dispatch) => {
    dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
    http
      .post(ONE_ON_ONE_AGENDA, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE,
            data: response.data,
            isOneOnOneLoading: false,
          });
        }
      });
  };
};

export const GetEmployeeListForHROneOnOne = (data) => {
  let body = {
    groups: getListOfValue(data?.filterDepartment),
    locations: getListOfValue(data?.filterLocation),
    startDate: data?.startDate ? data.startDate : undefined,
    endDate: data?.endDate ? data.endDate : undefined,
    status: data?.filterStatus ? data?.filterStatus?.value || "" : "",
    subGroupIds: getListOfValue(data?.filterSubGroups),
    name: data.name || "",
    pageNumber: data.currentPage || 1,
    offset: data.pageSize || 10,
    compositeEmployeeIds: data.filterEmployee?.length > 0 ? getCompositeIds(data.filterEmployee) : [],
  };
  return (dispatch) => {
    dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
    http
      .post(ONE_ON_ONE_TEAM_LIST_FOR_HR, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SET_ONE_ON_ONE_HR_TEAM_LIST,
            managerDetails: response.data?.managerDetails,
            employeeCount: response.data?.managerCount,
          });
        } else {
          dispatch({
            type: actionTypes.SET_ONE_ON_ONE_HR_TEAM_LIST,
            managerDetails: [],
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_ONE_ON_ONE_HR_TEAM_LIST,
        });
      });
  };
};

export const GetOneOnOne = (id, employeeId) => {
  return (dispatch) => {
    http
      .get(GET_USER_ONE_ON_ONE, { id })
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          //if employeeId not pass as argument then only call get one on one
          let dummyId,
            managerId,
            userId = null;
          if (response.createdBy.isManager) {
            managerId = response.createdBy.employeeId;
            userId = response.addOneOnOnewith.employeeId;
          } else if (response.addOneOnOnewith.isManager) {
            managerId = response.addOneOnOnewith.employeeId;
            userId = response.createdBy.employeeId;
          }
          if (employeeId === response.createdBy?.employeeId) {
            let oneOnOneWithEmployee = {
              employeeId: response.addOneOnOnewith.employeeId,
              subGroupId: response.addOneOnOnewith.subGroupId,
              parentGroupId: response.addOneOnOnewith.parentGroupId,
              subGroupName: response.addOneOnOnewith.subGroupName,
              parentGroupName: response.addOneOnOnewith.parentGroupName,
            };
            dispatch(GetOneOnOneUser(oneOnOneWithEmployee));
          }
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE,
            data: response,
            managerId: managerId,
            userId: userId,
            isOneOnOneLoading: false,
          });

          if (employeeId) {
            if (response.createdBy.employeeId === employeeId) {
              dummyId = response.addOneOnOnewith;
            } else {
              dummyId = response.createdBy;
            }
            dispatch(GetPreviousOneOnOneData(dummyId));
            // http
            //   .get(USER_PROFILE, { id: dummyId })
            //   .then((response) => response.data)
            //   .then((data) => {
            //     if (data.data) {
            //       dispatch({ type: actionTypes.SET_ONE_ON_ONE_USER, user: data.data, isLoading: false });
            //     }
            //   })
            //   .catch((error) => console.warn(error));
          }
        } else {
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE,
            oneOnOneResponse: { success: false, message: "Talking points deleted successfully!" },
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetOneOnOneDataForHR = (id, role) => {
  return (dispatch) => {
    http
      .get(GET_USER_ONE_ON_ONE, { id }, { params: { role } })
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          //if employeeId not pass as argument then only call get one on one
          let manager,
            user = null;
          if (response.createdBy.isManager) {
            manager = response.createdBy;
            user = response.addOneOnOnewith;
          } else if (response.addOneOnOnewith.isManager) {
            manager = response.addOneOnOnewith;
            user = response.createdBy;
          }
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE_FOR_HR,
            data: response,
            manager: manager,
            user: user,
            isOneOnOneLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE_FOR_HR,
            data: null,
            isOneOnOneLoading: false,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.GET_ONE_ON_ONE_FOR_HR,
          data: null,
          isOneOnOneLoading: false,
        });
      });
  };
};

export const GetTimezoneList = (id) => {
  return (dispatch) => {
    http
      .get(TIMEZONE, { id })
      .then((response) => response.data.data)
      .then((response) => {
        if (response.timeZones?.length > 0) {
          dispatch({
            type: actionTypes.GET_TIMEZONE,
            currentTimezone: response.userTimeZoneAlias,
            timezoneList: response.timeZones.map((value) => {
              return {
                value: value.alias,
                label: value.displayName,
              };
            }),
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const UpdateOneOnOne = (data, id, employeeId) => {
  return (dispatch) => {
    dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
    http
      .put(ONE_ON_ONE_AGENDA, {}, data)
      .then((response) => response.data.data)
      .then(() => {
        dispatch(GetOneOnOne(id, employeeId));
      })
      .catch(() => {
        //
      });
  };
};

export const DeletePrivateNote = (noteId, id) => {
  let data = {
    oneOnOneAgendaId: id,
    noteId: noteId,
  };
  return (dispatch) => {
    http
      .delete(DELETE_PRIVATE_NOTE, { data: data })
      .then((response) => response.data.data)
      .then(() => {
        dispatch(GetOneOnOne(id));
      })
      .catch(() => {});
  };
};

export const DeleteSharedNote = (noteId, id) => {
  let data = {
    oneOnOneAgendaId: id,
    noteId: noteId,
  };
  return (dispatch) => {
    http
      .delete(DELETE_SHARED_NOTE, { data: data })
      .then((response) => response.data.data)
      .then(() => {
        dispatch(GetOneOnOne(id));
      })
      .catch(() => {});
  };
};

export const DeleteTalkingPoint = (data, id) => {
  return (dispatch) => {
    dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
    http
      .delete(DELETE_TALKING_POINT, { data: data })
      .then((response) => response.data.data)
      .then(() => {
        dispatch(GetOneOnOne(id));
      })
      .catch(() => {});
  };
};

export const MarkTalkingPoint = (talkingId, id, isCompleted) => {
  let data = {
    oneOnOneAgendaId: id,
    talkingPointId: talkingId,
    isCompleted: isCompleted,
  };
  return (dispatch) => {
    dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
    http
      .put(MARK_TALKING_POINT, {}, data)
      .then((response) => response.data.data)
      .then(() => {
        dispatch(GetOneOnOne(id));
      })
      .catch(() => {
        //
      });
  };
};

export const ScheduleOneOnOne = (id, date, from, to, timeZone) => {
  let start,
    end = null;
  if (from && to) {
    start = new Date(from);
    end = new Date(to);
    start = getHourAndMinString(start);
    end = getHourAndMinString(end);
  }
  let params = {
    id,
    date,
    from: start,
    to: end,
    timeZone,
  };

  return (dispatch) => {
    dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
    http
      .put(SCHEDULE_ONE_ON_ONE, params)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          http
            .get(GET_USER_ONE_ON_ONE, { id })
            .then((response) => response.data.data)
            .then((response) => {
              if (response) {
                dispatch({
                  type: actionTypes.GET_ONE_ON_ONE,
                  // data: response,
                  isOneOnOneLoading: false,
                });
              } else {
                dispatch({
                  type: actionTypes.GET_ONE_ON_ONE,
                  data: null,
                });
              }
            })
            .catch((error) => {
              console.warn(error);
            });
          dispatch({
            type: actionTypes.RESPONSE_SCHEDULE,
            message: "1-on-1 schedule successfully",
          });
        }
        // dispatch(GetOneOnOne(id));
      })
      .catch(() => {
        //
      });
  };
};

export const ManagerScheduleOneOnOne = (id, date, from, to, timeZone) => {
  let end,
    start = null;
  if (from && to) {
    start = new Date(from);
    end = new Date(to);
    start = getHourAndMinString(start);
    end = getHourAndMinString(end);
  }
  let params = {
    id: id,
    date: date,
    from: start,
    to: end,
    timeZone,
  };
  return (dispatch) => {
    http
      .put(MANAGER_SCHEDULE_ONE_ON_ONE, params)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(GetOneOnOne(id));
          dispatch({
            type: actionTypes.RESPONSE_SCHEDULE,
            message: "1-on-1 schedule successfully",
          });
        }
        // dispatch(GetOneOnOne(id));
      })
      .catch(() => {
        //
      });
  };
};

export const EndOneOnOne = (id, date, from, to, flag, timeZone) => {
  let end,
    start = null;
  if (from && to) {
    start = new Date(from);
    end = new Date(to);
    start = getHourAndMinString(start);
    end = getHourAndMinString(end);
  }

  let params = {
    id: id,
    date: date,
    from: start,
    to: end,
    timeZone,
  };
  return (dispatch) => {
    if (flag) {
      http
        .put(SCHEDULE_ONE_ON_ONE, params)
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            dispatch(EndOneOnOne(id, date, from, to, false));
          }
          // dispatch(GetOneOnOne(id));
        })
        .catch(() => {
          //
        });
    } else {
      http
        .post(END_ONE_ON_ONE, { id: id })
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            dispatch(GetOneOnOne(id));
            dispatch({
              type: actionTypes.RESPONSE_END_ONE_ON_ONE,
              message: "Ended 1-on-1 successfully",
            });
          }
        });
    }
  };
};

export const GetOneOnOneMember = (month) => {
  return (dispatch) => {
    dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
    http
      .get(ONE_ON_ONE_MEMBERS, { month })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE_MEMBER_LIST,
            managerList: response.data.managers,
            reporteeList: response.data.reportees,
            isLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.GET_ONE_ON_ONE_MEMBER_LIST,
            managerList: [],
            reporteeList: [],
            isLoading: false,
          });
        }
      })
      .catch(() => {
        //
      });
  };
};

export const GetOneOnOneManager = () => {
  return (dispatch) => {
    dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
    http
      .get(ONE_ON_ONE_MANAGERS)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.GET_MANAGER_LIST,
            data: response.data,
            isLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.GET_MANAGER_LIST,
            data: [],
            isLoading: true,
          });
        }
      })
      .catch(() => {
        //
      });
  };
};

export const GetOneOnOneReportee = () => {
  return (dispatch) => {
    http
      .get(ONE_ON_ONE_REPORTEE)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.GET_REPORTEE_LIST,
            data: response.data,
            isLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.GET_REPORTEE_LIST,
            data: [],
            isLoading: false,
          });
        }
      })
      .catch(() => {
        //
      });
  };
};

export const getTeamFeedback = (dispatch) => {
  dispatch({ type: "ONE_ON_ONE_SCHEDULE_LOADING" });
  http
    .get(GET_TEAM_FEEDBACK)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.GET_TEAM_FEEDBACK_LIST,
          data: response.data,
          isLoading: false,
        });
      } else {
        dispatch({
          type: actionTypes.GET_TEAM_FEEDBACK_LIST,
          data: [],
          isLoading: false,
        });
      }
    })
    .catch(() => {
      //
    });
};
export const postGlobalFeedBack = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SHOW_LOADER_GLOBAL_FEEDBACK });
    http
      .post(GLOBAL_FEEDBACK, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.POST_GLOBAL_FEEDBACK,
            payload: { message: response.message.message, success: true },
          });
          dispatch(actions.getGlobalFeedBack);
        }
        if (response.status === "FAILURE") {
          dispatch({
            type: actionTypes.POST_GLOBAL_FEEDBACK,
            payload: { message: response.message.message, error: true },
          });
        }
        dispatch({ type: actionTypes.CLEAR_NOTIFICATION });
      })
      .catch(() => {});
  };
};

export const getGlobalFeedBack = (dispatch) => {
  http
    .get(GLOBAL_FEEDBACK)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.GET_GLOBAL_FEEDBACK,
          payload: response.data,
        });
      }
    })
    .catch(() => {});
};

export const getScheduleTime = (date, id, timeZone) => (dispatch) => {
  http
    .get(GET_SCHEDULE_TIME, { date, id, timeZone })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data?.oneOnOneEventTimeDTO?.length > 0) {
          let disabledTime = [];
          let d = new Date();
          for (let date of response.data?.oneOnOneEventTimeDTO) {
            let min = parseInt(date.startTime.slice(0, 2) * 60) + parseInt(date.startTime.slice(3));
            let endMin = parseInt(date.endTime.slice(0, 2) * 60) + parseInt(date.endTime.slice(3));
            for (let i = min; i < endMin; i = i + 15) {
              disabledTime.push(d.setHours(parseInt(i / 60), parseInt(i % 60)));
            }
          }
          dispatch({
            type: actionTypes.GET_SCHEDULE_TIME,
            payload: disabledTime,
          });
        }
      }
    })
    .catch(() => {});
};
