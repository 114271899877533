import React, { useRef } from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { IC_CALENDAR } from "../../const/imgCost";
import moment from "moment";
import "./Patterns.module.scss";

const SliderDot = (props) => {
  const ref = useRef(null);

  const renderTooltip = (
    <Popover className="Theme2Tooltip">
      <Popover.Content>
        <Row>
          <Col>
            <div className="tTitle">Submission&nbsp;Date</div>
            <div className="tDateBox">
              <div className="tCalendar">
                <img src={IC_CALENDAR} title="" alt="Calendar Icon" />
              </div>
              <div className="tDate">
                {props.value.submitedDate ? moment(props.value.submitedDate).format("MMM DD, YYYY") : "-"}
              </div>
            </div>
          </Col>
          <Col>
            <div className="tTitle">Reviewed&nbsp;Date</div>
            <div className="tDateBox">
              <div className="tCalendar">
                <img src={IC_CALENDAR} title="" alt="Calendar Icon" />
              </div>
              <div className="tDate">
                {props.value.reviewedDate ? moment(props.value.reviewedDate).format("MMM DD, YYYY") : "-"}
              </div>
            </div>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  let styles = null;
  if (props.value.status === "PENDING_FROM_MEMBER") {
    styles = "pending-from-member";
  } else if (props.value.status === "REVIEWED") {
    styles = "reviewed";
  } else {
    styles = "pending-for-review";
  }

  return (
    <ButtonToolbar className="my-history-item">
      <OverlayTrigger
        placement="bottom"
        overlay={renderTooltip}
        container={ref.current}
        trigger={["focus", "hover"]}
        // defaultShow="true"
      >
        <div className={styles}></div>
      </OverlayTrigger>
    </ButtonToolbar>
  );
};

export default SliderDot;
