import React from "react";
import { Col, Row } from "react-bootstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import style from "./sidebar_checkins.module.scss";

export default function SidebarCheckInActivity(props) {
  const { filterSurveyData } = props
  const totalCheckIn = filterSurveyData ? filterSurveyData.length : 0;
  const submittedCheckIn = filterSurveyData ? filterSurveyData.filter((value) => value.submitedDate).length : 0;

  const percentage = (submittedCheckIn / totalCheckIn) * 100;
  
  return (
    <div className={["box", style.checkin_activites].join(" ")}>
      <div className="box-heading-wrapper mb10 noborder no_pad">
        <div className="box-heading">
          <Row>
            <Col md={12}>
              <h2>OKR Progress Activities</h2>
            </Col>
          </Row>
        </div>
      </div>
      <div className="card_details">
        <div className={style.to_do_row}>
          <div className={style.details}>
            <Row>
              <Col xs={12} className={style.meter_wrapper}>
                <div className={style.ceckin_meter}>
                  <CircularProgressbar
                    value={`${Math.floor(percentage) || 0}`}
                    text={`${Math.floor(percentage) || 0}%`}
                    circleRatio={0.75}
                    styles={buildStyles({
                      rotation: 1 / 2 + 1 / 8,
                      strokeLinecap: "butt",
                      trailColor: "#eee"
                    })}
                  />

                  <div className={style.meter_inertext}>
                    of possible check-ins submitted in the last 12 months
                  </div>
                  <div className={style.submition_time}>
                    {" "}
                    <span>
                      {submittedCheckIn} out of {totalCheckIn}{" "}
                    </span>
                    okr progress submitted on time
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

