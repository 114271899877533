import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./UserBox.module.scss";
import { firstWord, getRatingClass, getStatusInformation } from "../../util/general";
import ManagerView from "../CommentBox/ManagerView";
import moment from "moment";
import { getRandomColor } from "../../util/color";
import { Link } from "react-router-dom";
import config from "util/config";

/**
 * PROPS
 * isPage
 * managerView
 * user
 * reviewer
 * employee
 * data
 * isReviewed
 * edit
 * @param {*} props
 */
export default function Userbox(props) {
  const user = props.user;

  const [selectedColor, setSelectedColor] = useState("");
  let statusInfo = null;
  let manager = null;
  if (props.data) statusInfo = getStatusInformation(props.data.status, style);

  useEffect(() => {
    setSelectedColor(getRandomColor());
  }, []);

  if (props.managerView && props.data.questionsAnswer) {
    props.data.questionsAnswer
      .filter((item) => item.type === "MOODOMETER")
      .map((item) => {
        const managerView = {
          style: style,
          question: item,
          isReviewed: props.isReviewed,
          surveyCycle: props.data,
          reviewer: props.reviewer,
          isManager: !props.edit,
          user: props.user,
          employee: props.employee,
          ratingClass: getRatingClass(item.answer, style),
          totalComments: item.managerComments ? item.managerComments.length : 0,
          isPage: props.isPage,
          selectedColor: props.selectedColor,
        };
        manager = <ManagerView surveyCycleId={props.data.surveyCyclesId} {...managerView} />;
        return item;
      });
  }

  if (!user) return <div>Loading...</div>;

  return (
    <div className={["box", !props.managerView ? "" : "myPageView pb-0"].join(" ")}>
      <div className={style.user_info}>
        <div className={style.user_img} style={{ backgroundColor: selectedColor }}>
          {user.image ? <img src={user.image} alt="" /> : user.employeeInitial}
        </div>
        <div className={style.user_details}>
          <div className={style.user_details_left}>
            {props.managerView ? (
              <Link
                to={
                  "/okrprogress/my-team/member-okrprogress/dashboard/" +
                  user.id +
                  config.employeeIdSplit +
                  user.parentGroupId +
                  config.employeeIdSplit +
                  user.subGroupId
                }
              >
                <h2 className={style.user_name}>{user.name}</h2>
              </Link>
            ) : (
              <h2 className={style.user_name}>{user.name}</h2>
            )}
            <h2 className={style.user_designation}>
              {user.designation} | {user.department}
            </h2>
            {props.managerView && (
              <h5 className={style.user_submission_ontime}>
                Submission on time: <span className="semi-bold">100%</span>
              </h5>
            )}
          </div>
          {props.managerView && (
            <div className={style.user_details_right}>
              {props.data.status === "REVIEWED" || props.isReviewed ? (
                <div className={style.user_date_of_submission}>
                  Reviewed on:{" "}
                  <span>
                    {props.data.reviewedDate
                      ? moment(props.data.reviewedDate).format("ddd, MMM Do YY")
                      : moment().format("ddd, MMM Do YY")}
                  </span>
                </div>
              ) : (
                <div className={style.user_date_of_submission}>
                  Submitted on:{" "}
                  <span>
                    {props.data.submitedDate
                      ? moment(props.data.submitedDate).format("ddd, MMM Do YY")
                      : moment().format("ddd, MMM Do YY")}
                  </span>
                </div>
              )}
              <div className={[style.user_checkin_status, statusInfo && statusInfo.style].join(" ")}>
                {statusInfo && (props.isReviewed ? "Reviewed" : statusInfo.status)}
              </div>
            </div>
          )}
        </div>
        <div className={style.userBandWrapper}>
          <span className={style.userBandTitle}>Band</span>
          <h5 className={style.userBand}>
            {user.band}
            </h5>
        </div>
      </div>
      <div className={style.userInfoText}>
        <Row>
          <Col md={4} className={["col-6"]}>
            <div className={style.userExpDetails}>
              <span className={style.userExpDetailTitle}>Experience Prior</span>
              <h5 className={style.userExpDetailYears}>
                {user?.expBeforeJoining ? user.expBeforeJoining + " year(s)" : "-"}
              </h5>
            </div>
          </Col>
          <Col md={4} className={["col-6"]}>
            <div className={style.userExpDetails}>
              {/* <span className={style.userExpDetailTitle}>With {firstWord(user.parentGroupName)}</span> */}
                {/* changed to static value for parent group   */}
                <span className={style.userExpDetailTitle}>With Apexon</span>
              <h5 className={style.userExpDetailYears}>{user?.isExp ? user.isExp + " year(s)" : "-"}</h5>
            </div>
          </Col>
          <Col md={4} className={["col-6"]}>
            <div className={style.userExpDetails}>
              <span className={style.userExpDetailTitle}>Total Experience</span>
              <h5 className={style.userExpDetailYears}>{user?.totalExp ? user.totalExp + " year(s)" : "-"}</h5>
            </div>
          </Col>
        </Row>
      </div>
      {manager}
    </div>
  );
}
