import { IC_INPUT_SEARCH } from "const/imgCost";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import config from "util/config";
import { filterArray } from "util/general";

const filterNameFromOption = (data) => {
  if (data === "subGroupClient") {
    return "filterSubGroups";
  } else if (data === "memberTypeList") {
    return "filterMemberType";
  } else if (data === "locationOption") {
    return "filterLocation";
  } else if (data === "departmentList") {
    return "filterDepartment";
  } else if (data === "projectList") {
    return "filterProject";
  } else if (data === "indiaDDOption") {
    return "filterIndiaDD";
  } else if (data === "managerOptions") {
    return "filterManager";
  }
};

function SubMenuListItem({ menuItems, handleFilterData, firstMenu, dashboardReportFilter }) {
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(menuItems);

  let className = "form-control-rounded form-search-item";
  if (searchValue.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  useEffect(() => {
    setFilteredData(menuItems);
    setSearchValue("");
  }, [menuItems]);

  const onSearch = (e) => {
    let filteredData = null;
    const filters = {
      label: e.target.value,
    };
    filteredData = filterArray(menuItems, filters);
    setFilteredData(filteredData);
    setSearchValue(e.target.value);
  };

  const onEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // searchMember && searchMember(event);
    }
  };
  const onResetSearch = () => {
    setFilteredData(menuItems);
    setSearchValue("");
  };
  return (
    <div>
      <Form>
        <Form.Group controlId="" className="form-postfix mb-0">
          <Form.Control
            size="sm"
            value={searchValue}
            type="text"
            placeholder={"Search"}
            name="searchBox"
            className={className}
            onChange={onSearch}
            onKeyPress={onEnter}
          />
          <Button className="close-button" type="reset" onClick={onResetSearch}>
            <span className="close-icon"></span>
          </Button>
          <div className="form-postfix-icon">
            <img src={`${IC_INPUT_SEARCH}`} title="" alt="" />
          </div>
        </Form.Group>
      </Form>
      <ul>
        {filteredData?.length > 0 ? (
          filteredData.map((value) => (
            <li key={value.value}>
              <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-2">
                <input
                  id={value.label}
                  type="checkbox"
                  onChange={(e) => handleFilterData(filterNameFromOption(firstMenu), value, e.target.checked)}
                  checked={
                    dashboardReportFilter[filterNameFromOption(firstMenu)]
                      ? dashboardReportFilter[filterNameFromOption(firstMenu)][
                          value.value + config.idSplit + value.label
                        ]
                      : false
                  }
                />
                <label htmlFor={value.label}>{value.label}</label>
              </div>
            </li>
          ))
        ) : (
          <li className="justify-content-center pt-2 pb-2">No record found</li>
        )}
      </ul>
    </div>
  );
}

export default SubMenuListItem;
