import React from "react";
import style from "../AssignObjectives/AssignObjectives.module.scss";
import storage from "../../../util/storage";
import moment from "moment";
import { getShortName } from "../../../util/general";
import { dateFormatDMY } from "../../../util/dateUtils";

export default function ActivityRow(props) {
  let user = storage.getUser();

  const { whatChanged, createdBy, objectiveDetails, createdDate, activityBy } = props.activity;
  let text,
    image,
    name = null;
  if (whatChanged === "CREATE") {
    text = ` has created objective "${objectiveDetails.objectiveTitle}"`;
  } else if (whatChanged === "KEYPOINTS_UPDATE") {
    if (activityBy === "EMPLOYEE") {
      if (objectiveDetails.keyPoints.type === "$")
        text = ` has updated key result "${objectiveDetails.keyPoints.description}" ${objectiveDetails.keyPoints.type}${objectiveDetails.keyPoints.progress}`;
      else if (objectiveDetails.keyPoints.type === ":")
        text = ` has updated key result "${objectiveDetails.keyPoints.description}" ${objectiveDetails.keyPoints.hours} hours `;
      else
        text = ` has updated key result "${objectiveDetails.keyPoints.description}" ${objectiveDetails.keyPoints.progress}`;
    } else {
      if (objectiveDetails.keyPoints.type === ":")
        text = ` has updated key result "${objectiveDetails.keyPoints.description}" ${objectiveDetails.keyPoints.hours} hours and ${objectiveDetails.keyPoints.weightage}% weightage`;
      else if (objectiveDetails.keyPoints.type === "$")
        text = ` has updated key result "${objectiveDetails.keyPoints.description}" from ${objectiveDetails.keyPoints.start}${objectiveDetails.keyPoints.type} to ${objectiveDetails.keyPoints.end}${objectiveDetails.keyPoints.type} and ${objectiveDetails.keyPoints.weightage}% weightage`;
      else if (objectiveDetails.keyPoints.type === "%")
        text = ` has updated key result "${objectiveDetails.keyPoints.description}" from ${objectiveDetails.keyPoints.start}${objectiveDetails.keyPoints.type} to ${objectiveDetails.keyPoints.end}${objectiveDetails.keyPoints.type} and ${objectiveDetails.keyPoints.weightage}% weightage`;
      else if (objectiveDetails.keyPoints.type === "?")
        text = ` has updated key result "${objectiveDetails.keyPoints.description}" Complete/Incomplete and ${objectiveDetails.keyPoints.weightage}% weightage`;
      else
        text = ` has updated key result "${objectiveDetails.keyPoints.description}" from ${objectiveDetails.keyPoints.type} ${objectiveDetails.keyPoints.start} to ${objectiveDetails.keyPoints.end} and ${objectiveDetails.keyPoints.weightage}% weightage`;
    }
  } else if (whatChanged === "KEYPOINTS_ADDED") {
    if (objectiveDetails.keyPoints.type === ":")
      text = ` has added key result "${objectiveDetails.keyPoints.description}" ${objectiveDetails.keyPoints.hours} hours `;
    else if (objectiveDetails.keyPoints.type === "$") {
      text = ` has added key result "${objectiveDetails.keyPoints.description}" from ${objectiveDetails.keyPoints.type}${objectiveDetails.keyPoints.start} to ${objectiveDetails.keyPoints.type}${objectiveDetails.keyPoints.end}`;
    } else if (objectiveDetails.keyPoints.type === "%") {
      text = ` has added key result "${objectiveDetails.keyPoints.description}" from ${objectiveDetails.keyPoints.start}${objectiveDetails.keyPoints.type} to ${objectiveDetails.keyPoints.end}${objectiveDetails.keyPoints.type}`;
    } else if (objectiveDetails.keyPoints.type === "?") {
      text = ` has added key result "${objectiveDetails.keyPoints.description}" Complete/Incomplete`;
    } else
      text = ` has added key result "${objectiveDetails.keyPoints.description}" from ${objectiveDetails.keyPoints.type} ${objectiveDetails.keyPoints.start} to ${objectiveDetails.keyPoints.end} `;
  } else if (whatChanged === "HOURS") {
    text = ` has updated duration hours "${objectiveDetails.hours}"`;
  } else if (whatChanged === "MINUTES") {
    text = ` has updated duration minutes "${objectiveDetails.minutes}"`;
  } else if (whatChanged === "STARTDATE") {
    text = ` has modified start date "${dateFormatDMY(objectiveDetails.startDate)}"`;
  } else if (whatChanged === "ENDDATE") {
    text = ` has modified end date "${dateFormatDMY(objectiveDetails.endDate)}"`;
  } else if (whatChanged === "KEYPOINTS_DELETE") {
    text = ` has deleted key result "${objectiveDetails.keyPoints && objectiveDetails.keyPoints.description}"`;
  } else if (whatChanged === "DESCRIPTION_UPDATE") {
    text = ` has updated objective description "${objectiveDetails.objectiveDescription}"`;
  } else if (whatChanged === "TITLE_UPDATE") {
    text = ` has updated objective title "${objectiveDetails.objectiveTitle}"`;
  } else if (whatChanged === "WEIGHTAGE_UPDATE"){
    text = ` has updated objective weightage "${objectiveDetails.weightage}"`;
  } else if (whatChanged === "USER_OBJECTIVE_WEIGHTAGE_UPDATE"){
    text = ` has updated user assigned objective weightage "${objectiveDetails.weightage}"`;
  } else if (whatChanged === "OBJ_FEEDBACK_STATUS") {
    text = ` has marked objective as "${objectiveDetails.objfeedbackStatus}"`;
  } else if (whatChanged === "OBJ_MANAGER_CHANGE") {
    text = ` has changed ownership to "${objectiveDetails.changedManagerName}"`;
  } else if (whatChanged === "OBJECTIVE_STATUS_UPDATE") {
    if (objectiveDetails.progressStatus === "GREEN") text = ` has updated objective status On Track`;
    else if (objectiveDetails.progressStatus === "ORANGE") text = ` has updated objective status Needs Attention`;
    else text = ` has updated objective status Running Behind`;
  } else if (whatChanged === "REJECT") {
    text = ` has rejected your submitted objective `;
  } else if (whatChanged === "ACCEPT") {
    text = ` has accepted your submitted objective`;
  } else if (whatChanged === "SELF_OBJECTIVE_ACCEPTED") {
    text = ` has accepted your Self Assigned objective "${objectiveDetails && objectiveDetails.objectiveTitle}"`;
  } else if (whatChanged === "SELF_OBJECTIVE_REJECTED") {
    text = ` has rejected your Self Assigned objective`;
  } else if (whatChanged === "TAG_ADDED") {
    text = ` has added new tags "${objectiveDetails.tags.map((value) => value.name)}"`;
  } else if (whatChanged === "TAG_DELETE") {
    text = ` has deleted tags "${objectiveDetails.tags.map((value) => value.name)}"`;
  } else if (whatChanged === "STRETCH_GOAL") {
    text = ` has changed Stretch Objective to "${objectiveDetails.stretchGoal}"`;
  }

  if (activityBy === "EMPLOYEE") {
    if (props.user) {
      image = props.user.dispArr || props.user.employeeInitial;
      name = getShortName(props.user.displayName);
    } else {
      image = user.displayPicture || user.empInitial;
      name = getShortName(user.displayName);
    }
  } else if (createdBy) {
    image = createdBy.displayPicture;
    name = getShortName(createdBy.empName);
  }
  return (
    <div className={style.activites_item}>
      <div className={style.user_icon}>{image && image.length > 2 ? <img src={image} alt="img" /> : image}</div>
      <div className={style.activites_data}>
        <span>{name}</span>
        {text}
        <div className={style.activites_hours}>
          {moment(createdDate).fromNow()}
          {(whatChanged === "REJECT" ||
            whatChanged === "SELF_OBJECTIVE_REJECTED" ||
            (whatChanged === "OBJ_FEEDBACK_STATUS" && objectiveDetails.objfeedbackStatus === "INVALID")) && (
            <span className={style.view_detail_link} onClick={() => props.handleRejectMessagePopup(props.activity)}>
              Click to view details
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
