import { OKR_TEMPLATE_ACTIONS } from "store/actions/actionTypes";

const initialState = {
  isLoading: false,
  isWeightage: false,
  responseStatus: null,
  message: null,
  predefinedObjectives: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OKR_TEMPLATE_ACTIONS.LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case OKR_TEMPLATE_ACTIONS.SET_LIST_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case OKR_TEMPLATE_ACTIONS.SET_MANAGER_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case OKR_TEMPLATE_ACTIONS.SET_TOAST_MESSAGE:
      const {isWeightage, responseStatus, message} = action.payload;
      return {
        ...state,
        isWeightage,
        responseStatus,
        message
      };
    default:
      return state;
  }
};

export default reducer;
