import React from "react";
import UpComingItems from "../TabPendingApprovals/UpComingItems.js";
import CheckInActivity from "./CheckInActivity/CheckInActivity.js";
import YouMadeMyDayActivity from "./YouMadeMyDayActivity/YouMadeMyDayActivity";
import TeamUnassignedObjective from "../TabPendingApprovals/TeamUnassignedObjective.js";

export default function TabEngagement(props) {
  const percentage = props.streak && props.streak.completionRate;
  const totalCheckIn = props.surveyData && props.surveyData.length;
  const submittedCheckIn = props.surveyData && props.surveyData.filter((value) => value.submitedDate).length;
  return (
    <div>
      <CheckInActivity
        isPage="Home"
        surveyData={props.surveyData}
        percentage={percentage}
        totalCheckIn={totalCheckIn}
        submittedCheckIn={submittedCheckIn}
        handleSurveyDataFilter={props.handleSurveyDataFilter}
        filterSurveyDataMonth={props.filterSurveyDataMonth}
        pendingCheckinReview={props.pendingCheckinReview}
        checkinPendingFromEmployees={props.checkinPendingFromEmployees}
        currentAndPreviousCheckin={props.currentAndPreviousCheckin}
        handleTeamsChartOptions={props.handleTeamsChartOptions}
        streak={props.streak}
        checkinStatusForEmployee={props.checkinStatusForEmployee}
        managerCheckinStatusForEmployee={props.managerCheckinStatusForEmployee}
      />
      <UpComingItems
        pendingOneOnOnes={props.pendingOneOnOnes}
        previousOneOnOnes={props.previousOneOnOnes}
        upcomingOneOnOnes={props.upcomingOneOnOnes}
        missedOneOnOnes={props.missedOneOnOnes}
        displayAll={props.displayAll}
        handleEmployeeClick={props.handleEmployeeClick}
        handleOneOnOneMonths={props.handleOneOnOneMonths}
      />
      {props.unassignedObjectiveEmployees?.length > 0 && (
        <TeamUnassignedObjective
          employees={props.unassignedObjectiveEmployees}
          displayAll={() => props.displayAll("UNASSIGNED")}
          handleEmployeeClick={props.handleEmployeeClick}
        />
      )}
      <YouMadeMyDayActivity
        handleAppreciateFilter={props.handleAppreciateFilter}
        history={props.history}
        user={props.user}
      />
    </div>
  );
}
