import React from "react"
import { Row } from "react-bootstrap"
import style from "../AssignObjectives/AssignObjectives.module.scss";

export default function ObjectiveDescription({ description }){
  //commented for showing line break when we received /n in string from API
  // let text = description;
  // if(description.split("\n").length >0) {
  //   text = description.split("\n").map((element,index) => {
  //     return(
  //       <span key={index}>{element}</span>
  //     )
  //   })
  // }
  return (
    <div className="box pb-0">
      <div className="box-heading-wrapper">
          <div className="box-heading">
              <h2>Description</h2>
              {/* <h3>Some text goes here, will be updated soon</h3> */}
          </div>
      </div>
    <div className="box-light-gradient h38"></div>
    <div className="box-inner-pad">
        <Row>
          <div className="col-xl-12">
            <p className={style.objectiveDescription}>
              {description}
            </p>
          </div>
        </Row>
    </div>
  </div>
  )
}