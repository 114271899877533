import React from "react";
import { Form } from "react-bootstrap";
import UploadFile from "../../Performance/CycleMemberProfile/QuestionAnswer/UploadFile";
import style from "./PreviewTemplate.module.scss";

export default function TextboxType(props) {
  const { isManagerView } = props;
  let responseText = "There would be employee’s comment over here";
  return (
    <div className={[style.question_list, "box-inner-pad", style.disabledClick].join(" ")}>
      <span className={style.question_sr_no}>Q{props.no + 1}</span>
      <div className={style.que_ans_wrapper}>
        <div className={style.question_name}>
          <h4>
            {props.question.questionName}
            {props.question.isRequired && <span className="text-danger">*</span>}
          </h4>
        </div>
        {props.question.descriptionFlag && (
          <p className={[style.answer_description, "mt-1"].join(" ")}>{props.question.description}</p>
        )}

        {isManagerView ? (
          <p className={[style.answer_description, "mt-3"].join(" ")}>{responseText}</p>
        ) : (
          <div className={style.ans_wrapper}>
            <div className={style.txt_box_wrapper}>
              <Form.Group controlId="">
                <div className="textarea-wrapper mt-3">
                  <UploadFile questionId="QUESTION_ID" uploadFile={() => {}} />
                  <Form.Control
                    as="textarea"
                    maxLength="5000"
                    placeholder={props.question.isRequired ? `Add a new answer` : "Add a new answer (Optional)"}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
