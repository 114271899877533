import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import { useSelector, useDispatch } from "react-redux";
import { Collapse } from "react-bootstrap";
import moment from "moment";
import * as actions from "store/actions/index";
import { IC_LOCK_PRIVACY_1 } from "const/imgCost";
import { getRatingClass } from "util/general";
import { getRandomColor } from "util/color";
import { UPDATE_MANAGER_COMMENT, DELETE_COMMENT, PRIVATE_MANAGER_COMMENT } from "util/endpoints";
import http from "util/http";
import AuthContext from "context/authContext";
import Answer from "../Answer/Answer";
import RatingAnswer from "../Answer/RatingAnswer";
import MoodAnswer from "../Answer/MoodAnswer";
import Comment from "./Comment";
import CommentBox from "./CommentBox";
import CommentsTop from "./CommentsTop";
import OneOnOneCommentBox from "../OneOnOne/OneOnOneCommentBox/OneOnOneCommentBox";
import OneOnOneCommentAnswer from "../OneOnOne/OneOnOneCommentBox/OneOnOneCommentAnswer";
import SlideToast from "../Toast/Toast";

export default function ManagerView(props) {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();

  const { user, employee, surveyCycle, status, isReviewed, reviewedDate } = useSelector((state) => state.questions);

  const { submitable, reviewer } = useSelector((state) => state.checkIn);

  const reviewDays = reviewedDate != null ? Math.abs(moment(reviewedDate).diff({}, "days")) : 1;

  const configReviewedDays = process.env.REACT_APP_MANAGER_COMMENTS_DAYS;

  /*  STATES   */
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOneOnOne, setIsOpenOneOnOne] = useState(false);
  const [chars, setChars] = useState(200);
  const [selectedColor, setSelectedColor] = useState("");
  const [comment, setComment] = useState("");
  const [privateComment, setPrivateComment] = useState("");
  const [managerComments, setManagerComments] = useState([]);
  const [privateMessage, setPrivateMessage] = useState(null);

  const { questionComment, sendAgendaResponse } = useSelector((state) => state.oneOnOne);

  useEffect(() => setSelectedColor(props.selectedColor || getRandomColor()), []);
  useEffect(() => {
    reviewDays > configReviewedDays ? setIsOpen(true) : setIsOpen(false);
  }, [reviewedDate]);

  useEffect(() => {
    if (sendAgendaResponse) {
      if (sendAgendaResponse.success) {
        if (!toast.isActive("AGENDA_SUCCESS_SEND"))
          toast.success(<SlideToast title="Awesome!" message={sendAgendaResponse.message} error={false} />, {
            toastId: "AGENDA_SUCCESS_SEND",
          });
        dispatch({ type: "CLEAR_SEND_AGENDA_RESPONSE" });
      }
    }
    setIsOpenOneOnOne(false);
  }, [sendAgendaResponse]);

  useEffect(() => {
    props.question.managerComments ? setManagerComments(props.question.managerComments) : setManagerComments([]);
    props.question.privateMessage ? setPrivateMessage(props.question.privateMessage) : setPrivateMessage(null);
    // dispatch(actions.GetOneOnOneAgenda(props.surveyCycleId,props.question.queId))
  }, [props.question]);

  const oneOnOnecomment =
    questionComment && questionComment.filter((value) => value.questionId === props.question.queId);
  const changeHandle = (qAnswer, isPrivate) => {
    if (qAnswer) {
      let errors = [...submitable];
      let error = { key: props.question.queId };

      let index = errors.findIndex((x) => x.key === props.question.queId);
      index === -1 && errors.push(error);

      dispatch(actions.validationInSubmit(errors));
    } else {
      let errors = null;
      errors = submitable.filter((item) => item.key !== props.question.queId);
      dispatch(actions.validationInSubmit(errors));
    }

    setChars(200 - qAnswer.length);
    !isPrivate ? setComment(qAnswer) : setPrivateComment(qAnswer);
  };

  const managerCommentHandle = () => {
    if (comment.trim().length < 1) return true;
    setChars(200);
    setComment("");
    const commentToSend = {
      queId: props.question.queId,

      employeeId: {
        employeeId: user.id,
        parentGroupId: user.parentGroupId,
        subGroupId: user.subGroupId,
      },
      managerComment: {
        comment: comment.trim(),
        commentedBy: {
          employeeId: employee.id,
          empName: employee.name,
          parentGroupId: employee.parentGroupId,
          subGroupId: employee.subGroupId,
          displayPicture: employee.image ? employee.image : employee.empInitial,
        },
      },
    };
    http
      .put(UPDATE_MANAGER_COMMENT, {}, commentToSend, { params: { surveyCycleId: surveyCycle.surveyCyclesId } })
      .then((response) => response.data)
      .then((response) => updateComment(response.data))
      .catch(() => {});

    let errors = null;
    errors = submitable.filter((item) => item.key !== props.question.queId);
    dispatch(actions.validationInSubmit(errors));
  };

  const managerPrivateCommentHandle = (val, whoCanView, reply) => {
    if (val.trim().length < 1) return true;
    const employeeIds = {
      employeeId: user.id,
      parentGroupId: user.parentGroupId,
      subGroupId: user.subGroupId,
    };
    let commentedBy = {
      employeeId: employee.id,
      empName: context.state.user.displayName,
      displayPicture: context.state.user.displayPicture || context.state.user.empInitial,
      parentGroupId: context.state.user.parentGroupId,
      subGroupId: context.state.user.subGroupId,
    };
    let data = {
      updateQuestionAnswer: {
        queId: props.question.queId,
        managerComment: {
          comment: val.trim(),
          commentedBy,
          whoCanView,
          private: true,
          reply,
        },
      },
      surveyCycleId: surveyCycle.surveyCyclesId,
      employeeId: {
        ...employeeIds,
      },
    };
    http
      .put(PRIVATE_MANAGER_COMMENT, {}, data)
      .then((response) => response.data)
      .then((response) => updatePrivateComment(response.data, reply))
      .catch(console.error);

    let errors = null;
    errors = submitable.filter((item) => item.key !== props.question.queId);
    dispatch(actions.validationInSubmit(errors));
  };

  const updateComment = (commentObject) => {
    let comments = [...managerComments];
    comments.push(commentObject);
    setManagerComments(comments);
    setPrivateComment("");
    setComment("");
  };

  const updatePrivateComment = (commentObject) => {
    let comments = [...managerComments];
    comments.push(commentObject);
    setManagerComments(comments);
    setPrivateComment("");
    setComment("");
  };

  const deleteComment = (commentId, isPrivate, isReply) => {
    const comment = {
      questionId: props.question.queId,
      commentId: commentId,
      surveyCycleId: surveyCycle.surveyCyclesId,
      private: isPrivate,
      reply: isReply,
    };
    let comments = [...managerComments];
    comments = comments.filter((c) => c.commentId != commentId);
    setManagerComments(comments);
    http
      .delete(DELETE_COMMENT, { data: comment })
      .then((response) => response.data.message)
      .then(() => setComment(""))
      .catch(() => {});
  };

  const openOneOnOne = (value) => {
    setIsOpenOneOnOne(value);
  };

  return (
    <div className="box-inner-pad">
      <div className={props.style.questions_conversion_box}>
        {props.question.type === "TEXT" ? (
          <Answer
            oneOnOnecomment={oneOnOnecomment}
            style={props.style}
            isPage={props.isPage}
            status={status}
            reviewDays={reviewDays}
            question={props.question}
            isReviewed={isReviewed}
            title={props.question.queTitle}
            answer={props.question.answer}
            isOpen={() => setIsOpen(!isOpen)}
            downloadFile={props.downloadFile}
            surveyCycle={surveyCycle}
            surveyCycleId={props.surveyCycleId}
            isAdded={props.winChallenge && props.winChallenge.isAdded}
            childEmployee={props.childEmployee}
            openOneOnOne={(value) => openOneOnOne(value)}
          />
        ) : props.question.type === "RATING" ? (
          <RatingAnswer
            oneOnOnecomment={oneOnOnecomment}
            style={props.style}
            isPage={props.isPage}
            status={status}
            reviewDays={reviewDays}
            isReviewed={isReviewed}
            question={props.question}
            surveyCycle={surveyCycle}
            surveyCycleId={surveyCycle.surveyCyclesId}
            isOpen={() => setIsOpen(!isOpen)}
            isAdded={props.winChallenge && props.winChallenge.isAdded}
            childEmployee={props.childEmployee}
            openOneOnOne={(value) => openOneOnOne(value)}
          />
        ) : props.question.type === "MOODOMETER" ? (
          <MoodAnswer
            style={props.style}
            isPage={props.isPage}
            status={status}
            reviewDays={reviewDays}
            question={props.question}
            isReviewed={isReviewed}
            isOpen={() => setIsOpen(!isOpen)}
            ratingClass={getRatingClass(props.question.answer, props.style)}
          />
        ) : null}

        {/* Private Comment Section */}
        {props.question && privateMessage && (
          <div className="view_comment_box_wrapper private_comment_box_wrapper">
            <div className="view_comment_box_overview justify-content-between">
              <div className="d-flex">
                <div className="view_comment_box_overview_icon">
                  <ReactSVG className="svg" src={`${IC_LOCK_PRIVACY_1}`} alt="" />
                </div>
                <div className="view_comment_box_heading">
                  <strong className="semi-bold">
                    {"Private message, only visible to you and " +
                      (props.isPage === "myteam"
                        ? privateMessage.commentedBy.empName
                        : privateMessage.whoCanView.length && privateMessage.whoCanView[0].empName)}
                  </strong>
                </div>
              </div>
            </div>
            <Comment
              selectedColor={selectedColor}
              deletable={false}
              image={privateMessage.commentedBy && privateMessage.commentedBy.displayPicture}
              empName={privateMessage.commentedBy && privateMessage.commentedBy.empName}
              commentedOn={privateMessage.commentedOn}
              comment={privateMessage.comment}
              isPrivate={false}
              deleteComment={() =>
                deleteComment(privateMessage.commentId, privateMessage.private, privateMessage.reply)
              }
            />
            {managerComments.length > 0 &&
              managerComments
                .filter((answer) => answer.reply)
                .map((answer, index) => {
                  return (
                    <Comment
                      key={"private_reply" + index}
                      image={answer.commentedBy && answer.commentedBy.displayPicture}
                      empName={answer.commentedBy && answer.commentedBy.empName}
                      commentedOn={answer.commentedOn}
                      comment={answer.comment}
                      isPrivate={answer.private}
                      privateMsg={`Private message, only visible to you and
                                ${
                                  props.isPage === "myteam" && answer.private
                                    ? ` ${answer.whoCanView[0].empName}`
                                    : ` ${answer.commentedBy.empName}`
                                }`}
                      selectedColor={selectedColor}
                      deleteComment={() => deleteComment(answer.commentId, answer.private, answer.reply)}
                      deletable={
                        reviewDays <= configReviewedDays &&
                        props.managerRole &&
                        employee &&
                        answer.commentedBy.employeeId === employee.id
                      }
                    />
                  );
                })}

            {props.isPage === "myteam" &&
              (props.isManager || props.managerRole) &&
              reviewDays <= configReviewedDays && (
                <CommentBox
                  privateThread={true}
                  id={props.question.queId}
                  employee={employee}
                  style={props.style}
                  selectedColor={selectedColor}
                  managerCommentHandle={managerCommentHandle}
                  managerPrivateCommentHandle={managerPrivateCommentHandle}
                  changeHandle={(event) => changeHandle(event.target.value, true)}
                  comment={privateComment || ""}
                  chars={chars}
                />
              )}
          </div>
        )}

        {/* After Reviewed: Only Comment Display */}
        {props.isPage !== "myteam" &&
          status === "REVIEWED" &&
          isReviewed &&
          managerComments &&
          managerComments.filter((answer) => !answer.reply).length > 0 && (
            <div className="view_comment_box_wrapper bg-white">
              <CommentsTop heading={props.isPage === "myteam" ? "Share your view(s)" : "Your manager's view"} />
              {managerComments
                .filter((answer) => !answer.reply)
                .map((answer, index) => (
                  <Comment
                    key={index}
                    selectedColor={selectedColor}
                    deleteComment={() => deleteComment(answer.commentId, answer.private, answer.reply)}
                    deletable={
                      reviewDays <= configReviewedDays &&
                      props.isManager &&
                      answer.commentedBy.employeeId === employee.id
                    }
                    image={answer.commentedBy && answer.commentedBy.displayPicture}
                    empName={answer.commentedBy && answer.commentedBy.empName}
                    commentedOn={answer.commentedOn}
                    comment={answer.comment}
                    isPrivate={answer.private}
                    privateMsg={`Private message, only visible to you and
                                ${
                                  props.isPage === "myteam" && answer.private
                                    ? ` ${answer.whoCanView[0].empName}`
                                    : ` ${answer.commentedBy.empName}`
                                }`}
                  />
                ))}
            </div>
          )}

        {/* Normal Comment Section */}
        {props.isPage === "myteam" &&
          managerComments &&
          (reviewDays <= configReviewedDays || managerComments.length > 0) && (
            <Collapse in={isOpen}>
              <div className="view_comment_box_wrapper">
                {(reviewDays <= configReviewedDays || managerComments.filter((answer) => !answer.reply).length > 0) && (
                  <CommentsTop heading={props.isPage === "myteam" ? "Share your view(s)" : "Your manager's view"} />
                )}
                {managerComments.length > 0 &&
                  managerComments
                    .filter((item) => !item.reply)
                    .map((answer, index) => {
                      return (
                        <Comment
                          selectedColor={selectedColor}
                          key={index}
                          deleteComment={() => deleteComment(answer.commentId, answer.private, answer.reply)}
                          deletable={
                            (props.isManager || props.managerRole) &&
                            reviewDays <= configReviewedDays &&
                            employee &&
                            answer.commentedBy.employeeId === employee.id
                          }
                          image={answer.commentedBy && answer.commentedBy.displayPicture}
                          empName={answer.commentedBy && answer.commentedBy.empName}
                          commentedOn={answer.commentedOn}
                          comment={answer.comment}
                          isPrivate={answer.private}
                          privateMsg={`Private message, only visible to you and
                                    ${
                                      props.isPage === "myteam" && answer.private
                                        ? ` ${answer.whoCanView[0].empName}`
                                        : ` ${answer.commentedBy.empName}`
                                    }`}
                        />
                      );
                    })}

                {(props.isManager || props.managerRole) && reviewDays <= configReviewedDays && (
                  <CommentBox
                    id={props.question.queId}
                    employee={employee}
                    style={props.style}
                    selectedColor={selectedColor}
                    managerCommentHandle={managerCommentHandle}
                    managerPrivateCommentHandle={managerPrivateCommentHandle}
                    changeHandle={(event) => changeHandle(event.target.value, false)}
                    comment={comment}
                    chars={chars}
                  />
                )}
              </div>
            </Collapse>
          )}
        {oneOnOnecomment &&
          oneOnOnecomment.map((value) => <OneOnOneCommentAnswer comment={value} key={value.oneOnOneAgendaId} />)}
        {isOpenOneOnOne && (
          <OneOnOneCommentBox
            employeeProfile={surveyCycle && surveyCycle.employeeProfile}
            page={props.isPage}
            surveyCycleStartDate={surveyCycle && surveyCycle.startDate}
            surveyCycleEndDate={surveyCycle && surveyCycle.endDate}
            oneOnOnecomment={oneOnOnecomment}
            setCloseOneOnOne={() => setIsOpenOneOnOne(false)}
            employee={context.state.user}
            reviewer={reviewer}
            surveyCycleId={props.surveyCycleId}
            questionId={props.question.queId}
            queTitle={props.question.queTitle}
            answer={props.question.answer}
          />
        )}
      </div>
    </div>
  );
}
