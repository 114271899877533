import React, { useState } from "react";
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Tooltip, Label, CartesianGrid } from "recharts";
import NoDataFound from "components/NodataFound/NoDataFound";
import Rectangle from "recharts/lib/shape/Rectangle";
import style from "./Chart.module.scss";

const COLORS = {
  a_plus: "#00C987",
  a: "#71DCC9",
  b_plus: "#FFCF3E",
  b: "#FF9E5E",
  c: "#F07575",
};

function TeamAssessmentGraph({ teamAssessmentGraphData, handleClick, graphType = "GRADE" }) {
  const [currentVal, setCurrentVal] = useState(null);
  
  let graphHeading = "Quarterly Team Performance Status";
  if (graphType === "RATING") graphHeading = "Team Performance Status";

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    if (active && currentVal) {
      const teamMember = payload?.length > 0 && payload.find((value) => value.name === currentVal?.key);
      const teamMemberCount = graphType === "MASK" && teamMember?.payload?.showRatingDropDown === false ? teamMember?.payload?.employees.length : teamMember?.payload[currentVal?.key + "_teamMember"];
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper d-flex">
              <span className="recharts-tooltip-value">Team member -</span>
              <p className="recharts-tooltip-label ml-2">{teamMemberCount}</p>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomizedTick = ({ x, y, payload }) => {
    let tickValue = payload.value?.replace(/-/g, " ").replace(/\s+/g, " ");
    let first = tickValue.split(" ").slice(0, 2).join(" ");
    let second = tickValue.split(" ").slice(2, 4).join(" ");
    let third = tickValue.split(" ").slice(4).join(" ");
    return (
      <g transform={`translate(${x},${y - 8})`}>
        <text x={0} y={0} dy={5} fill="#666" className="customized-axis-tick-text" textAnchor="end">
          {first}
        </text>
        <text x={0} y={0} dy={15} fill="#666" className="customized-axis-tick-text-span" textAnchor="end">
          {second}
        </text>
        {third && (
          <text x={0} y={0} dy={25} fill="#666" className="customized-axis-tick-text-span" textAnchor="end">
            {third}
          </text>
        )}
      </g>
    );
  };

  const CustomizedXTick = ({ x, y, payload }) => {
    let label;
    let xMargin = 0;
    if (payload.value === 0) {
      label = graphType === "RATING" ? "4-5" : "A+";
      xMargin = 30;
    } else if (payload.value === 5) {
      label = graphType === "RATING" ? "3-4" : "A";
      xMargin = 15;
    } else if (payload.value === 10) {
      label = graphType === "RATING" ? "2-3" : "B+";
      xMargin = 10;
    } else if (payload.value === 15) {
      label = graphType === "RATING" ? "1-2" : "B";
      xMargin = -5;
    } else if (payload.value === 20) {
      label = graphType === "RATING" ? "0-1" : "C";
      xMargin = -14;
    }
    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text x={xMargin} y={0} fill="#666" className="customized-axis-tick-text" textAnchor="end">
          {label}
        </text>
      </g>
    );
  };

  return (
    <div className={["graph-item", style.teams_assessment_chart].join(" ")}>
      {teamAssessmentGraphData?.length > 0 ? <>
        <div className="graph-heading">{graphHeading}</div>
        <div style={{ width: "100%", height: "auto" }}>
          <ResponsiveContainer width="100%" aspect={3.0 / 2.0} height="auto">
            {graphType === "MASK" ?
              <BarChart
                data={teamAssessmentGraphData}
                layout="vertical"
                barSize={8}
                barGap={10}
                cursor="pointer"
                margin={{ top: 0, right: 20, bottom: 10, left: 120 }}
              >
                {/* <XAxis type="number" tick={<CustomizedXTick />}>
              <Label
                value={graphType === "GRADE" ? "Grading Bar" : "Rating Bar"}
                offset={0}
                position="insideBottom"
                fill="#928fa0"
              />
            </XAxis> */}
                {/* <CartesianGrid strokeDasharray="6 3" vertical={false} horizontalPoints={[74]} /> */}
                <YAxis
                  dataKey="name"
                  reversed
                  type="category"
                  axisLine={false}
                  barGap={10}
                  label=""
                  tick={<CustomizedTick />}
                  minwidth={105}
                  maxwidth={180}
                >
                  <Label value="Cycle" offset={0} position="insideTopLeft" fill="#928fa0" />
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="a_plus"
                  stackId="a"
                  fill={COLORS["a_plus"]}
                  shape={(props) => {
                    props.radius = [20, 20, 20, 20];
                    if (props.name === currentVal?.name && currentVal?.key === "a_plus") {
                      props.height = props.height + 2;
                      props.y = props.y - 1;
                    }
                    return <Rectangle {...props} />;
                  }}
                  onClick={(data) => handleClick(data, "A+")}
                  onMouseOver={(e) => setCurrentVal({ key: "a_plus", name: e.name })}
                  onMouseLeave={() => setCurrentVal(null)}
                />
              </BarChart> :
              <BarChart
                data={teamAssessmentGraphData}
                layout="vertical"
                barSize={8}
                barGap={10}
                cursor="pointer"
                margin={{ top: 0, right: 20, bottom: 10, left: 40 }}
              >
                <XAxis type="number" tick={<CustomizedXTick />}>
                  <Label
                    value={graphType === "GRADE" ? "Grading Bar" : "Rating Bar"}
                    offset={0}
                    position="insideBottom"
                    fill="#928fa0"
                  />
                </XAxis>
                {/* <CartesianGrid strokeDasharray="6 3" vertical={false} horizontalPoints={[74]} /> */}
                <YAxis
                  dataKey="name"
                  reversed
                  type="category"
                  axisLine={false}
                  barGap={10}
                  label=""
                  tick={<CustomizedTick />}
                  width={105}
                >
                  <Label value="Cycle" offset={0} position="insideTopLeft" fill="#928fa0" />
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="a_plus"
                  stackId="a"
                  fill={COLORS["a_plus"]}
                  shape={(props) => {
                    props.radius = [20, 0, 0, 20];
                    if (props.name === currentVal?.name && currentVal?.key === "a_plus") {
                      props.height = props.height + 2;
                      props.y = props.y - 1;
                    }
                    return <Rectangle {...props} />;
                  }}
                  onClick={(data) => handleClick(data, "A+")}
                  onMouseOver={(e) => setCurrentVal({ key: "a_plus", name: e.name })}
                  onMouseLeave={() => setCurrentVal(null)}
                />
                <Bar
                  dataKey="a"
                  stackId="a"
                  fill={COLORS["a"]}
                  onClick={(data) => handleClick(data, "A")}
                  onMouseOver={(e) => setCurrentVal({ key: "a", name: e.name })}
                  onMouseLeave={() => setCurrentVal(null)}
                  shape={(props) => {
                    if (props.name === currentVal?.name && currentVal?.key === "a") {
                      props.height = props.height + 2;
                      props.y = props.y - 1;
                    }
                    return <Rectangle {...props} />;
                  }}
                />
                <Bar
                  dataKey="b_plus"
                  stackId="a"
                  fill={COLORS["b_plus"]}
                  onClick={(data) => handleClick(data, "B+")}
                  onMouseOver={(e) => setCurrentVal({ key: "b_plus", name: e.name })}
                  onMouseLeave={() => setCurrentVal(null)}
                  shape={(props) => {
                    if (props.name === currentVal?.name && currentVal?.key === "b_plus") {
                      props.height = props.height + 2;
                      props.y = props.y - 1;
                    }
                    return <Rectangle {...props} />;
                  }}
                />
                <Bar
                  dataKey="b"
                  stackId="a"
                  fill={COLORS["b"]}
                  onClick={(data) => handleClick(data, "B")}
                  onMouseOver={(e) => setCurrentVal({ key: "b", name: e.name })}
                  onMouseLeave={() => setCurrentVal(null)}
                  shape={(props) => {
                    if (props.name === currentVal?.name && currentVal?.key === "b") {
                      props.height = props.height + 2;
                      props.y = props.y - 1;
                    }
                    return <Rectangle {...props} />;
                  }}
                />
                <Bar
                  dataKey="c"
                  stackId="a"
                  fill={COLORS["c"]}
                  onClick={(data) => handleClick(data, "C")}
                  shape={(props) => {
                    props.radius = [0, 20, 20, 0];
                    if (props.name === currentVal?.name && currentVal?.key === "c") {
                      props.height = props.height + 2;
                      props.y = props.y - 1;
                    }
                    return <Rectangle {...props} />;
                  }}
                  onMouseOver={(e) => setCurrentVal({ key: "c", name: e.name })}
                  onMouseLeave={() => setCurrentVal(null)}
                />
              </BarChart>}
          </ResponsiveContainer>
        </div> </> : <NoDataFound />}
    </div>
  );
}

export default TeamAssessmentGraph;
