import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as actions from "store/actions/index";
import { getGraphColor } from "util/color";
import { IC_GRAPH, IC_LIST } from "const/imgCost";
import {
  errorToast,
  filterOptions,
  getEmployeeIdsArray,
  getStringArray,
  pleaseSelectValue,
  scrollToTop,
  successToast,
} from "util/general";
import { moduleView } from "util/utils";
import { endOfDay } from "util/dateUtils";
import config from "util/config";
import PercentageViewBox from "shared/PercentageViewBox/PercentageViewBox";
import CustomSwitch from "shared/CustomSwitch/CustomSwitch";
import Spinner from "shared/Spinner/Spinner";
import PieChartComponent from "../../PieChart/PieChartComponent";
import SearchAndClearFilter from "../../SearchAndClearFilter/SearchAndClearFilter";
import CampaignEmployeeTable from "./CampaignEmployeeTable/CampaignEmployeeTable";
import EmployeeListModal from "../../EmployeeListPopup/EmployeeListModal";
import SubmitBox from "../../SubmitBox/SubmitBox";
import NoDataFound from "../../NodataFound/NoDataFound";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { menuText } from "const/constValue";
import CampaignQuizEmployeeTable from "./CampaignQuizViewDetails/CampaignQuizEmployeeTable";
import storage from "util/storage";
import EmployeeQuizResultCharts from "./CampaignQuizGraphDetails/EmployeeQuizResultCharts";
import routePath from "const/routePath";

function CampaignFilterView(props) {
  const [pageOfItems, setPageOfItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [popupQuestion, setPopupQuestion] = useState();
  const dispatch = useDispatch();
  const campaignId = props.match.params.id;
  const pageSize = useSelector((state) => state.myTeam.pageSize);
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);
  const {
    employeeList,
    locationList,
    designationList,
    isFilterViewLoading,
    departmentList,
    employeeGroupList,
    employeeFilterList,
    campaignAdmins,
    campaignFilterSearch,
    campaignTabSwitch,
    questionsGraph,
    questionsPopup,
    previewPopup,
    apiResponse,
    submittedPercentage,
    submittedCount,
    pendingCount,
    pendingPercentage,
    quizResultGraphData,
    employeesDetailsRoleRightsIndependent,
  } = useSelector((state) => state.campaign);
  const { HREmployeeNameList } = useSelector(
    (state) => state.hrDashboardReport
  );
  const isCampaignAdmin = moduleView(
    config.hrModule.CAMPAIGN_DETAILS
  )?.includes(config.hrModule.CAMPAIGN_DETAILS);
  const isCapability = props.location.pathname.includes(
    "capability-development"
  );
  const campaignCategory = storage.get("campaign-category");
  const {
    filterEmployee,
    startDate,
    endDate,
    filterDepartment,
    filterLocation,
    filterDesignation,
    filterMemberType,
    filterStatus,
    filterSubGroups,
  } = campaignFilterSearch;

  const { detailView } = campaignTabSwitch;
  const history = useHistory();
  const user = storage.getUser();

  useEffect(() => {
    if (user?.employeeId) {
      let adminEmployeeIds = [];
      if (quizResultGraphData?.campaignAdmins?.length > 0) {
        adminEmployeeIds = quizResultGraphData?.campaignAdmins.map(
          (admin) => admin.employeeId
        );
        if (!adminEmployeeIds.includes(user.employeeId)) {
          history.push(routePath.HOME);
        }
      } else if (campaignAdmins?.length > 0) {
        adminEmployeeIds = campaignAdmins.map((admin) => admin.employeeId);
        if (!adminEmployeeIds.includes(user.employeeId)) {
          history.push(routePath.HOME);
        }
      }
    }
  }, [quizResultGraphData?.campaignAdmins, user?.employeeId, campaignAdmins]);

  useEffect(() => {
    scrollToTop(500);
    dispatch({ type: "START_CAMPAIGN_TEMPLATE_VIEW_LOADING" });
    if (
      !(employeeList && employeeList.length > 0) &&
      campaignCategory !== "Quiz"
    ) {
      dispatch(actions.getEmployeeListForCampaign(campaignId));
      dispatch(actions.getQuestionChartData({ params: { campaignId } }));
      dispatch(actions.getFilterForCampaign(campaignId));
    }
    dispatch(actions.getAllEmployeesDetailsIndependentOfRoleRights(campaignId));
  }, []);

  useEffect(() => {
    if (employeeList && employeeList.length > 0) {
      setEmployees(employeeList);
    } else {
      setEmployees([]);
      setPageOfItems([]);
    }
  }, [employeeList]);

  useEffect(() => {
    if (apiResponse) {
      apiResponse.success
        ? successToast(apiResponse.message)
        : errorToast(apiResponse.message);
      dispatch({ type: "CLEAR_CAMPAIGN_RESPONSE" });
    }
  }, [apiResponse, dispatch]);

  useEffect(() => {
    if (campaignCategory === "Quiz") {
      if (detailView === "LEFT") {
        dispatch(actions.getQuizResultGraphData(campaignId, {}));
      } else if (detailView === "RIGHT") {
        dispatch(actions.getEmployeeCampaignQuizDetail(campaignId, {}));
      }
      dispatch({ type: "CLEAR_PAGINATION_DATA" });
      dispatch({ type: "CLEAR_CAMPAIGN_VIEW_FILTER_DATA" });
    }
  }, [detailView]);

  const resetData = () => {
    setEmployees(
      employeeList.map((v) => {
        return {
          ...v,
          modifiedData: true,
        };
      })
    );
  };

  //Employee Search
  const getEmployeeNameList = (value) => {
    if (value.length > 2) {
      dispatch(actions.getHREmployeeNameList(value));
    }
  };

  const handleFilterData = (variable, e) => {
    dispatch({
      type: "SET_CAMPAIGN_VIEW_FILTER_DATA",
      payload: {
        [variable]: e,
      },
    });
  };

  const handleTabSwitch = (variable, e) => {
    dispatch({
      type: "SET_CAMPAIGN_TAB_SWITCH_DATA",
      payload: {
        [variable]: e,
      },
    });
  };

  const handleClear = () => {
    dispatch({
      type: "CLEAR_CAMPAIGN_VIEW_FILTER_DATA",
    });
    if (campaignCategory === "Quiz") {
      dispatch(actions.getEmployeeCampaignQuizDetail(campaignId, {}));
      dispatch(actions.getQuizResultGraphData(campaignId, {}));
    } else {
      dispatch(actions.getEmployeeListForCampaign(campaignId));
      dispatch(actions.getQuestionChartData({ params: { campaignId } }));
    }
  };

  const handleFilter = () => {
    if (
      filterEmployee?.length > 0 ||
      filterDepartment?.length > 0 ||
      filterLocation?.length > 0 ||
      filterDesignation?.length > 0 ||
      filterMemberType?.length > 0 ||
      filterSubGroups?.length > 0 ||
      filterStatus ||
      startDate ||
      endDate
    ) {
      let body;
      if (campaignCategory === "Quiz") {
        body = {
          employeeIds: getEmployeeIdsArray(filterEmployee),
          department: getStringArray(filterDepartment, "label"),
          locations: getStringArray(filterLocation, "label"),
          designation: getStringArray(filterDesignation, "label"),
          employeeType: getStringArray(filterMemberType, "label"),
          status: filterStatus ? filterStatus.value : undefined,
          subGroups: filterSubGroups
            ? getStringArray(filterSubGroups, "value")
            : null,
          startDate: startDate || undefined,
          endDate: endDate || undefined,
        };
      } else {
        body = {
          employeeIds: getEmployeeIdsArray(filterEmployee),
          department: getStringArray(filterDepartment, "label"),
          location: getStringArray(filterLocation, "label"),
          designation: getStringArray(filterDesignation, "label"),
          employeeType: getStringArray(filterMemberType, "label"),
          status: filterStatus ? [filterStatus.value] : undefined,
          subGroupIds: filterSubGroups
            ? getStringArray(filterSubGroups, "value")
            : null,
          submittedDateFrom: startDate || undefined,
          submittedDateTo: endOfDay(endDate),
        };
      }
      let config = {
        params: {
          campaignId: campaignId,
        },
      };
      if (campaignCategory === "Quiz") {
        dispatch(actions.getEmployeeCampaignQuizDetail(campaignId, body));
        dispatch(actions.getQuizResultGraphData(campaignId, body));
      } else {
        dispatch(actions.getEmployeeListForCampaign(campaignId, body));
        dispatch(actions.getQuestionChartData(config, body));
      }
    } else {
      pleaseSelectValue();
    }
  };

  const handlePieChartClick = (data, questionId, question) => {
    setPopupQuestion({
      questionName: question.questionName,
      answer: data.name,
    });
    let body = {
      employeeIds: getEmployeeIdsArray(filterEmployee),
      department: getStringArray(filterDepartment, "label"),
      location: getStringArray(filterLocation, "label"),
      designation: getStringArray(filterDesignation, "label"),
      employeeType: getStringArray(filterMemberType, "label"),
      status: filterStatus ? [filterStatus.value] : undefined,
      submittedDateFrom: startDate || undefined,
      submittedDateTo: endOfDay(endDate),
    };
    let config = {
      params: {
        optionId: data.orderId,
        campaignId,
        questionId,
      },
    };
    dispatch(actions.getCampaignQuestionPopup(config, body));
  };

  const handleExport = (questionId) => {
    let body = {
      employeeIds: getEmployeeIdsArray(filterEmployee),
      department: getStringArray(filterDepartment, "value"),
      location: getStringArray(filterLocation, "value"),
      designation: getStringArray(filterDesignation, "value"),
      employeeType: getStringArray(filterMemberType, "value"),
      status: filterStatus ? [filterStatus.value] : undefined,
      submittedDateFrom: startDate || undefined,
      submittedDateTo: endOfDay(endDate),
    };
    let config = {
      responseType: "arraybuffer",
      params: {
        questionId,
        campaignId,
      },
    };
    dispatch(
      actions.downloadCampaignQuestion(
        config,
        body,
        employees?.[0]?.campaignName
      )
    );
  };

  let subNavType = "OKR Progress";
  let backURL = "/okrprogress/campaign-list";
  let breadCrumbText = menuText.CAMPAIGN;
  let PAGE_NAME = "Campaign";
  let subMenuText = "View Campaign Details";
  if (isCapability) {
    subNavType = "WMG";
    PAGE_NAME = "Capability Development";
    backURL = "/wmg/capability-development";
    breadCrumbText = menuText.MMG;
    subMenuText = "View Capability Details";
  } else if (isCampaignAdmin) {
    subNavType = "Campaign Details";
    backURL = "/hr/campaign-details";
  }

  const statusSurveyOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Submitted", label: "Submitted" },
  ];

  const statusQuizOptions = [
    { value: "Attempted", label: "Attempted" },
    { value: "Not Attempted", label: "Not Attempted" },
  ];

  return (
    <div>
      <SubNavigation type={subNavType} />
      {isFilterViewLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">{breadCrumbText}</h1>
            <span className="st-breadcrumb">
              <Link to={backURL}>Admin</Link>
              <span>{subMenuText}</span>
            </span>
          </Col>
          <Col md={12}>
            <SearchAndClearFilter
              description={
                "Select appropriate value to view the details of employees"
              }
              filterLocation={filterLocation}
              filterEmployee={filterEmployee}
              filterDepartment={filterDepartment}
              filterSubGroups={filterSubGroups}
              // isDisplaySubGroup={campaignCategory === "Quiz" && detailView === "LEFT" && true}
              isDisplaySubGroup={detailView === "LEFT" && true}
              subGroupClient={
                // campaignCategory === "Quiz" &&
                detailView === "LEFT" &&
                employeesDetailsRoleRightsIndependent?.subGroupList
              }
              displayLocation={
                campaignCategory === "Quiz"
                  ? detailView === "LEFT"
                    ? true
                    : false
                  : true
              }
              statusWiseOptions={
                campaignCategory === "Quiz"
                  ? statusQuizOptions
                  : statusSurveyOptions
              }
              filterStatus={filterStatus}
              startDate={startDate}
              endDate={endDate}
              maxEndDate={new Date()}
              maxStartDate={new Date()}
              departmentList={
                employeesDetailsRoleRightsIndependent?.departmentList
              }
              locationList={
                campaignCategory === "Quiz"
                  ? detailView === "LEFT"
                    ? employeesDetailsRoleRightsIndependent?.locationList
                    : null
                  : employeesDetailsRoleRightsIndependent?.locationList
              }
              employeeNameList={
                detailView === "LEFT"
                  ? null
                  : campaignCategory === "Quiz"
                  ? employeesDetailsRoleRightsIndependent?.employeesList
                  : employeesDetailsRoleRightsIndependent?.employeesList
              }
              handleClear={handleClear}
              handleFilter={handleFilter}
              filterOptions={filterOptions}
              handleFilterData={handleFilterData}
              isDisplayStartDate={true}
              isDisplayEndDate={true}
              isDisplayMemberType={true}
              displayStatus={
                campaignCategory === "Quiz"
                  ? true
                  : startDate ||
                    endDate ||
                    !campaignCategory ||
                    detailView === "LEFT"
                  ? false
                  : true
              }
              memberTypeList={
                campaignCategory === "Quiz"
                  ? employeesDetailsRoleRightsIndependent?.employeeTypeList
                  : employeesDetailsRoleRightsIndependent?.employeeTypeList
              }
              filterMemberType={filterMemberType}
              isDisplayBandDropdown={false}
              isDisplayDesignation={
                campaignCategory === "Quiz"
                  ? detailView === "LEFT"
                    ? false
                    : true
                  : true
              }
              designationList={
                campaignCategory === "Quiz"
                  ? employeesDetailsRoleRightsIndependent?.designationList
                  : employeesDetailsRoleRightsIndependent?.designationList
              }
              filterDesignation={filterDesignation}
              searchEmployeeBox={campaignCategory === "Quiz" ? false : false}
              getEmployeeNameList={getEmployeeNameList}
            />
          </Col>
          {campaignCategory === "Quiz" ? (
            <>
              <Col md={12}>
                <CustomSwitch
                  img1={IC_GRAPH}
                  img2={IC_LIST}
                  checked={detailView}
                  handleChecked={(data) => handleTabSwitch("detailView", data)}
                />
              </Col>
              {detailView === "LEFT" && (
                <>
                  <Col>
                    <EmployeeQuizResultCharts
                      quizResultGraphData={quizResultGraphData}
                      campaignId={campaignId}
                    />
                  </Col>
                </>
              )}
              {detailView === "RIGHT" && (
                <CampaignQuizEmployeeTable campaignId={campaignId} />
              )}
            </>
          ) : (
            <>
              <Col md={12}>
                <CustomSwitch
                  img1={IC_GRAPH}
                  img2={IC_LIST}
                  checked={detailView}
                  handleChecked={(data) => handleTabSwitch("detailView", data)}
                />
              </Col>
              {detailView === "LEFT" && (
                <>
                  <Col md={12}>
                    <PercentageViewBox
                      totalEligibleText={`Total eligible employees for this ${PAGE_NAME}:`}
                      boxHeading="Statistical Representation"
                      boxDescription="Graphical analysis  as per questions"
                      title="Given below are the count and the percentage details of employees regarding the submission of the campaign"
                      eligibleEmployeeCount={submittedCount + pendingCount}
                      percentageData={[
                        {
                          name: "Pending",
                          color: "#F07575",
                          percentage: pendingPercentage,
                          count: pendingCount,
                        },
                        {
                          name: "Submitted",
                          color: "#6DD400",
                          percentage: submittedPercentage,
                          count: submittedCount,
                        },
                      ]}
                    />
                  </Col>
                  <>
                    {questionsGraph &&
                    questionsGraph.length > 0 &&
                    questionsGraph.filter((question) =>
                      question.labelAndCount.find((opt) => opt.count)
                    )?.length > 0 ? (
                      questionsGraph
                        .filter((question) =>
                          question.labelAndCount.find((opt) => opt.count)
                        )
                        ?.map((question, index) => {
                          let pieChart = question.labelAndCount.map(
                            (option) => {
                              return {
                                name: option.option,
                                value: option.count,
                                orderId: option.optionId,
                              };
                            }
                          );
                          return (
                            <Col
                              md={6}
                              className="col-12"
                              key={question.questionId}
                            >
                              <PieChartComponent
                                isExportBtn={true}
                                handleExport={() =>
                                  handleExport(question.questionId)
                                }
                                COLORS={getGraphColor(pieChart, index)}
                                handlePieChartClick={(cellData) =>
                                  handlePieChartClick(
                                    cellData,
                                    question.questionId,
                                    question
                                  )
                                }
                                pieChartData={pieChart}
                                title={`Q${index + 1}. ${
                                  question.questionName
                                }`}
                              />
                            </Col>
                          );
                        })
                    ) : (
                      <div className="col-md-12">
                        <div className="box">
                          <div className="no_record_wrapper">
                            <NoDataFound description="No match found for applied filter" />
                            <div className="gap80"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  {submittedPercentage !== 0 && (
                    <Col md={12}>
                      <SubmitBox
                        handleSubmit={() => handleExport()}
                        name="Export"
                      />
                    </Col>
                  )}
                </>
              )}

              {detailView === "RIGHT" && (
                <CampaignEmployeeTable
                  tableHeading={`${PAGE_NAME} Details`}
                  pageOfItems={pageOfItems}
                  pageSize={pageSize}
                  employeeList={employees}
                  resetData={resetData}
                  onChangePage={onChangePage}
                  viewQuestionPathname={(employeeCampaignId, employeeIds) =>
                    employeeCampaignId
                      ? isCapability
                        ? `/wmg/capability-development/question/${employeeCampaignId}?empId=${employeeIds.employeeId}&pgId=${employeeIds.parentGroupId}&sgId=${employeeIds.subGroupId}`
                        : isCampaignAdmin
                        ? `/hr/campaign-details/admin/question/${employeeCampaignId}?empId=${employeeIds.employeeId}&pgId=${employeeIds.parentGroupId}&sgId=${employeeIds.subGroupId}`
                        : `/okrprogress/campaign-list/question/${employeeCampaignId}?empId=${employeeIds.employeeId}&pgId=${employeeIds.parentGroupId}&sgId=${employeeIds.subGroupId}`
                      : props.location.pathname
                  }
                  isCapability={isCapability}
                />
              )}
              {questionsPopup?.length > 0 && (
                <EmployeeListModal
                  employees={questionsPopup.map((emp) => {
                    return {
                      questions: emp.questionResponse.questions,
                      isCapability: emp.isCapabilityDevelopmentFramework,
                      ...emp.employeeDetails,
                    };
                  })}
                  isExpandVisible={true}
                  openPopup={previewPopup}
                  closePopup={() => {
                    dispatch({ type: "REMOVE_CAMPAIGN_PREVIEW_DATA" });
                    setPopupQuestion();
                  }}
                  searchPlaceholder="Search Employee"
                  modalHeading={popupQuestion.questionName}
                  modalDescription={`Given the name of employee who have answered '${popupQuestion.answer}'`}
                  departmentShow={true}
                  handleEmployeeClick={() => {}}
                  // secondColum={questionsPopup[0].employeeDetails?.band ? "Band" : null}
                  thirdColum="Location"
                  plusIcon={true}
                  modalSize="xl"
                />
              )}
            </>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default CampaignFilterView;
