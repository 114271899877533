import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { IC_FILE_UPLOAD_1 } from "const/imgCost";
import { supportedFiles } from "const/constValue";
import TooltipInfoLi from "shared/Tooltip/TooltipInfoLi";

function MultipleFileUpload({ handleMultipleFileUpload }) {
  return (
    <div className="only-attached-icon">
      <div className="custom-tooltip">
        <div className="box-info">
          <label className="custom-file-upload mb-0 mt-1">
            <input type="file" name="file" multiple onChange={handleMultipleFileUpload} onClick={(event) => {event.target.value = null}} accept={supportedFiles} />
            <span>
              <ReactSVG src={`${IC_FILE_UPLOAD_1}`} title="" alt="Choose File" />
            </span>
          </label>
        </div>
        <TooltipInfoLi
          heading="Attachments"
          listItem={[
            "You can upload a multiple files",
            "You can upload a maximum 5 files",
            "Maximum size is 2MB for each file",
            "Supported files 'pdf, png, jpg, doc, docx, ppt, pptx, xls, xlsx, zip, txt, rtf, eml, csv, rar, msg'",
          ]}
        />
      </div>
    </div>
  );
}

MultipleFileUpload.propTypes = {
  handleMultipleFileUpload: PropTypes.func,
};

export default MultipleFileUpload;
