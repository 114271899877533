import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import MultiSelect from "react-multi-select-component";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import * as actions from "store/actions/index";
import { IC_EDIT_OBJECTIVE } from "const/imgCost";
import { errorToast, requiredFieldMissing, scrollToTop, successToast } from "util/general";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "components/NodataFound/NoDataFound";
import TooltipText from "components/TooltipText/TooltipText";
import CustomSwitch from "components/CustomSwitch/customSwitch";
import ConfirmationPopup from "components/Objectives/ConfirmationPopup";
import style from "./SuperAdmin.module.scss";
import { noData } from "const/constValue";
import routePath from "const/routePath";

function SuperAdmin() {
  const dispatch = useDispatch();
  const loggedInUser = storage.getUser();
  const [selectedEmployees, setSelectedEmployees] = useState();
  const [modulesValue, setModulesValue] = useState();
  const [clientValue, setClientValue] = useState();
  const [clientData, setClientData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(false);
  const [statusChecked, setStatusChecked] = useState();
  const { moduleList, organizationList, isLoading, clientAdminModuleList, apiResponse } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    dispatch(actions.getOrganizationList());
    dispatch(actions.getModuleList());
    dispatch(actions.getClientAdminModuleList());
  }, [dispatch]);

  useEffect(() => {
    scrollToTop(500);
  }, [isEdit]);

  //Component did update
  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.success) {
        successToast(apiResponse.message);
        setSelectedEmployees();
        setModulesValue();
        setClientValue("");
        setIsEdit(false);
        setClientData();
      } else {
        errorToast(apiResponse.message);
      }
      dispatch({ type: "CLEAR_ROLE_ASSIGN_RESPONSE" });
    }
  }, [dispatch, apiResponse]);

  const handleAdminAssign = (type) => {
    if (clientValue && selectedEmployees && modulesValue?.length > 0) {
      let body = {
        clientId: clientValue.value,
        employeeId: {
          employeeId: selectedEmployees.value?.split("&&")[0],
          parentGroupId: clientValue.value,
          subGroupId: selectedEmployees.value?.split("&&")[1],
        },
        modules: modulesValue.map((v) => v.value),
      };
      setError(false);
      if (type === "EDIT") {
        dispatch(actions.updateAdminForClient(body));
        setIsEdit(false);
      } else dispatch(actions.createAdminForClient(body));
    } else {
      setError(true);
      requiredFieldMissing();
    }
  };

  const handleStatusChange = (data) => {
    let body = {
      clientId: data.clientId,
      employeeId: {
        employeeId: data.admin.employeeId,
        parentGroupId: data.admin.parentGroupId,
        subGroupId: data.admin.subGroupId,
      },
      modules: data.modules,
      isActive: !data.isActive,
    };
    if (data.isActive) {
      setClientData({ ...body, isActive: false });
      setStatusChecked(false);
      setShowPopup(true);
    } else {
      setStatusChecked(data.clientId);
      dispatch(actions.updateAdminForClient(body));
    }
  };

  const confirmInActive = () => {
    setShowPopup(false);
    setStatusChecked(false);
    setError(false);
    dispatch(actions.updateAdminForClient(clientData));
  };

  const handleCancel = () => {
    setShowPopup(false);
    setStatusChecked(clientData.clientId);
  };

  const loadOptions = (inputValue) => {
    if (inputValue?.length > 2) return actions.getEmployeeNameFromClient(inputValue, clientValue.value);
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setClientValue({ value: data.clientId, label: data.clientName });
    setSelectedEmployees({ value: data.admin?.employeeId + "&&" + data.admin?.subGroupId, label: data.admin?.empName });
    setModulesValue(
      data.modules.map((v) => {
        return {
          value: v,
          label: v,
        };
      })
    );
  };

  return (
    <div className={style.super_admin_wrapper}>
      {isLoading && <Spinner />}
      <div className="gap30"></div>
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0">Super Admin</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.HOME}>Home</Link>
              <span>Super Admin </span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className={[style.define_admin_roles_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Create Client and Admin</h2>
                </div>
              </div>
              <div className="box-inner-pad">
                <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
                  <Form.Group controlId="">
                    <Form.Label className={style.dd_title}>
                      Client<span className="text-danger">*</span>
                    </Form.Label>
                    <div className={["tags_wrapper", style.role_dropdown].join(" ")}>
                      <Select
                        options={organizationList}
                        isDisabled={isEdit}
                        className={["custom-default-dd-box", error && !clientValue && "error-box"].join(" ")}
                        classNamePrefix="select"
                        placeholder="Select..."
                        value={clientValue || ""}
                        onChange={setClientValue}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group controlId="">
                    <Form.Label className={style.dd_title}>
                      Module<span className="text-danger">*</span>
                    </Form.Label>
                    <div className="tags_wrapper">
                      <MultiSelect
                        className={error && !(modulesValue && modulesValue.length > 0) ? "error-box" : ""}
                        options={moduleList}
                        value={modulesValue}
                        onChange={setModulesValue}
                        labelledBy="Select"
                      />
                    </div>
                  </Form.Group>
                  {clientValue && (
                    <Form.Group controlId="">
                      <Form.Label className={style.dd_title}>
                        Select Admin<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="tags_wrapper">
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadOptions}
                          className={["custom-default-dd-box", error && !selectedEmployees ? "error-box" : ""].join(
                            " "
                          )}
                          classNamePrefix="select"
                          placeholder="Select..."
                          defaultOptions
                          value={selectedEmployees}
                          onChange={setSelectedEmployees}
                        />
                      </div>
                    </Form.Group>
                  )}
                </div>

                <div className={style.save_box_left}>
                  <Button
                    type="button"
                    variant={"primary"}
                    onClick={() => handleAdminAssign(isEdit ? "EDIT" : "CREATE")}
                  >
                    <strong>{isEdit ? "Edit" : "Submit"}</strong>
                  </Button>
                </div>
              </div>
            </div>
            <div className={[style.organization_history_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Clients</h2>
                </div>
              </div>
              <div className="box-light-gradient"></div>
              <div className="box-inner-pad p-0">
                <div className={[style.defined_roles_table, "custom-table", "table-theme-1"].join(" ")}>
                  {clientAdminModuleList && clientAdminModuleList.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className={style.organization_name}>Client</th>
                          <th className={style.admin}>Admin</th>
                          <th className={style.modules}>Module</th>
                          <th className={style.action}>Edit</th>
                          <th className={style.action}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientAdminModuleList.map((item) => (
                          <tr key={item.clientName + item.clientId}>
                            <td>{item.clientName}</td>
                            <td>{item.admin?.empName}</td>
                            <td>
                              <TooltipText len={75} array={item.modules} />
                            </td>
                            <td className={style.action}>
                              <span onClick={() => handleEdit(item)}>
                                <ReactSVG src={`${IC_EDIT_OBJECTIVE}`} alt="" title="edit" />
                              </span>
                            </td>
                            <td className={style.action}>
                              <CustomSwitch
                                checked={statusChecked === item.clientId ? true : item.isActive}
                                id={item.clientName + item.clientId}
                                disabled={loggedInUser?.parentGroupId === item.clientId}
                                defaultChecked={item.isActive}
                                handleChange={() => handleStatusChange(item)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                      <div className="gap80"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmationPopup
        show={showPopup}
        hide={handleCancel}
        submit={confirmInActive}
        headerText="Sure you want to in-active this client?"
        subText="Below impact will create after in-activating a client"
        listItem={[
          "Employee will be unable to Login into Engage",
          "No More activities will create under Engage for this client",
        ]}
        submitBtnText="Confirm"
      />
    </div>
  );
}

export default SuperAdmin;
