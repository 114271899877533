import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import style from "./PreviewTemplate.module.scss";
import * as actions from "store/actions/index";
import { moduleView } from "util/utils";
import storage from "util/storage";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import RadioType from "./radioType";
import CheckboxType from "./checkboxType";
import SliderType from "./SliderType";
import TextboxType from "./textboxType";
import DropDownType from "./dropDownType";
import ScaledType from "./ScaledType";
import ScaleType from "./ManagerQuestions/ScaleType";
import RankType from "./RankType";
import NPSType from "./NPSType";

const TabEventKeys = {
  EMPLOYEE_VIEW: "Employee View",
  MANAGER_VIEW: "Manager View",
};

export default function PreviewTemplate(props) {
  const dispatch = useDispatch();
  const [questionData, setQuestionData] = useState(props?.location?.state?.questionData);
  const [employeeQuestions, setEmployeeQuestions] = useState(props?.location?.state?.questionData?.questions);
  const [templateName, setTemplateName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const cycleType = storage.get("cycle") || "";
  const redirectionId = storage.get("cId") || "";
  const isCampaignAdmin = moduleView(config.hrModule.CAMPAIGN_DETAILS)?.includes(config.hrModule.CAMPAIGN_DETAILS);

  const backPathname =
    cycleType === "campaign"
      ? isCampaignAdmin
        ? `/hr/campaign-details/create?id=${redirectionId}`
        : `/okrprogress/campaign-list/edit?id=${redirectionId}`
      : cycleType === "configuration"
        ? "/admin/okrprogress-config"
        : `/hr/performance/performance-cycle/${cycleType}`;
  const [defaultKey, setDefaultKey] = useState(TabEventKeys.EMPLOYEE_VIEW);


  useEffect(() => {
    setEmployeeQuestions(props.location.state.questionData.questions);
  }, [])


  const employeeQuestionList =
    employeeQuestions?.length > 0 &&
    employeeQuestions
      .filter((quest) => quest.questionCategory.name === undefined)
      .map((quest, i) => {
        return setQuestion(quest, i);
      });

  let employeeCategoryArray = [];

  let templateList = [];
  employeeQuestions.map((it) => {
    if (!templateList.includes(it?.questionCategory?.name)) {
      templateList.push(it.questionCategory.name);

    }
  })
  templateList &&
    templateList.forEach((category) => {
      let arr =
        employeeQuestions?.length > 0 &&
        employeeQuestions
          .filter((quest) => quest.questionCategory && quest.questionCategory.name === category && quest.questionCategory.name !== undefined)
          .map((quest) => {
            return quest;
          });
      arr?.length > 0 && employeeCategoryArray.push(arr);
    });
  function setQuestion(quest, i, view) {
    switch (quest.type) {
      case "CHECKBOX":
        return <CheckboxType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "RADIOBUTTON":
        return <RadioType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "TEXTBOX":
        return <TextboxType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "DROPDOWN":
        return <DropDownType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "SLIDER":
        return <SliderType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "SCALED":
        if (view === "MANAGER") return <ScaleType question={quest} no={i} />;
        else return <ScaledType question={quest} no={i} />;
      case "RANK":
        return <RankType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "NPS":
        return <NPSType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      default:
        return <></>;
    }
  }

  const handleProceed = async (questionData) => {
      await dispatch(actions.importQuizQuestion(questionData));
      history.goBack();
  }

  return (
    <div className={style.preview_template_wrapper}>
      <div className="gap30"></div>
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Question Builder</h1>
            <span className="st-breadcrumb">Preview Template</span>
          </Col>
          <Col className={["col-md-12"]}>
            <div
              className={["custom-tabs", "custom-tabs-full-width", "tab-content-dashboard", style.preview_tabs].join(
                " "
              )}
            >
              <Tabs
                activeKey={employeeQuestions?.length > 0 ? defaultKey : TabEventKeys.MANAGER_VIEW}
                onSelect={(key) => setDefaultKey(key)}
                id="Tabs-navigation"
              >
                {employeeQuestions?.length > 0 && (
                  <Tab eventKey={TabEventKeys.EMPLOYEE_VIEW} title="Employee View">
                    <div className="box">
                      <div className="box-heading-wrapper">
                        <div className="box-heading">
                          <h2>{templateName}</h2>
                          <h3>Preview this Question Template for Employees</h3>
                        </div>
                      </div>

                      {employeeQuestionList}
                      {employeeCategoryArray?.length > 0 &&
                        employeeCategoryArray.map((cat) => {
                          if (cat.length === 0) return null;
                          return (
                            <>
                              <div className="box-light-gradient h38"></div>
                              <h5 className={style.categoryTitle}>
                                {cat[0] && cat[0].questionCategory && cat[0].questionCategory.name}
                              </h5>
                              {cat.map((quest, i) => {
                                return setQuestion(quest, i);
                              })}
                            </>
                          );
                        })}
                      {!props.location.state.hideButton && (
                        <div className={style.save_btn}>
                          <Button variant="primary" onClick={() => handleProceed(questionData)}>
                            Proceed
                          </Button>
                          <Button variant="secondary" onClick={() => history.goBack()}>
                            Back
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
