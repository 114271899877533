import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import style from "./CycleListManagerEmp.module.scss";
import * as actions from "store/actions/index";
import { pleaseSelectCheckBox, filterOptions, successToast, errorToast } from "util/general";
import { moduleRights, toFixedNumber } from "util/utils";
import { dateFormatYMD } from "util/dateUtils";
import { getColorByIndex } from "util/color";
import { noData } from "const/constValue";
import config from "util/config";
import FilterBox from "shared/AllFilterBox/FilterBox";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "shared/Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";
import FilterTeam from "../../FilterTeam/FilterTeam";
import ManagerCycleListRow from "./ManagerCycleListRow";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function ReviewingManagerList(props) {
  const cycleID = props.match.params.id;
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState();
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const {
    reviewingManagerData,
    cycleName,
    reopenResponse,
    period,
    isFileLoading,
    filterLoading,
    employeeCount,
    pendingPercentage,
    submittedPercentage,
    isEmployeeListLoading,
    isSelfAssessmentAvailable,
    locationList,
    filterValue,
  } = useSelector((state) => state.performance);
  const { expanded, currentPage } = useSelector((state) => state.myTeam);
  const { subGroupClient } = useSelector((state) => state.admin);

  useEffect(() => {
    currentPage === 1 && dispatch(actions.GetReviewingManagerForHR(cycleID));
    dispatch(actions.getSubGroupClient());
    dispatch(actions.GetLocationList());
    dispatch({ type: "REMOVE_PRIVATE_ASSESSMENT_DATA" });
  }, [dispatch, cycleID]);

  useEffect(() => {
    if (reopenResponse) {
      if (reopenResponse.success) successToast(reopenResponse.message);
      else errorToast(reopenResponse.message);

      dispatch({ type: "CLEAR_REOPEN_RESPONSE" });
    }
  }, [reopenResponse, dispatch]);

  const search = (event) => {
    setSearchFilter(event.target.value);
  };

  const searchMember = (event) => {
    dispatch(actions.GetReviewingManagerForHR(cycleID, 1, 10, statusFilter, event.target.value, filterValue));
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
    dispatch(actions.GetReviewingManagerForHR(cycleID, 1, 10, event.target.value, searchFilter, filterValue));
  };

  const resetSearch = () => {
    setSearchFilter("");
    dispatch(actions.GetReviewingManagerForHR(cycleID, 1, 10, statusFilter, ""));
  };

  const handleFilter = (name, value) => {
    dispatch(
      actions.GetReviewingManagerForHR(cycleID, 1, 10, statusFilter, searchFilter, { ...filterValue, [name]: value })
    );
    dispatch({ type: "SET_SUBGROUP_AND_LOCATION_FILTER", payload: { ...filterValue, [name]: value } });
  };

  const onChangePage = (currentPage, pageSize) => {
    dispatch(actions.GetReviewingManagerForHR(cycleID, currentPage, pageSize, statusFilter, searchFilter, filterValue));
  };

  const toggleCheckboxChange = (event) => {
    reviewingManagerData.map((value) => {
      if (value.managerId === event.target.value) {
        value.isChecked = event.target.checked;
        value.employees.map((value) => (value.isChecked = event.target.checked));
      }
      return value;
    });
    dispatch({ type: "TOGGLE_ALL_CHANGE", data: reviewingManagerData });
  };

  const toggleHandleReportee = (event, id) => {
    reviewingManagerData.forEach((value) => {
      if (expanded === value.managerId) {
        value.employees.map(
          (value) => value.employeeData.employeeId === id && (value.isChecked = event.target.checked)
        );
      }
    });
    dispatch({ type: "TOGGLE_REPORTEE_CHECK", data: reviewingManagerData });
  };

  const handleReOpen = () => {
    let privateAssessmentId = reviewingManagerData.filter(
      (value) => value.employees.filter((value) => value.isChecked).length > 0
    );
    let id = privateAssessmentId.map((value) =>
      value.employees.filter((value) => value.isChecked).map((value) => value.privateAssessmentId)
    );
    let data = {
      privateAssessmentIds: id.join().split(","),
    };
    if (data.privateAssessmentIds.length <= 0 || data.privateAssessmentIds[0] === "") {
      pleaseSelectCheckBox();
    } else {
      if (endDate) {
        let extendDateWithData = data;
        extendDateWithData.extendedDate = dateFormatYMD(endDate);
        dispatch(actions.ReOpenPrivateAssessment(extendDateWithData));
        setEndDate();
      } else dispatch(actions.ReOpenPrivateAssessment(data));
    }
  };

  if (isEmployeeListLoading) {
    return <Spinner />;
  }

  const filter = {
    value: ["", "Pending", "Submit"],
    name: ["All", "Pending", "Submitted"],
  };

  return (
    <div className={style.cycleListWrapper}>
      <SubNavigation type="Performance" />
      {(isFileLoading || filterLoading) && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">
              Performance
              <span>
                <Link to="/hr/performance/performance-cycle">Performance Cycle</Link>
              </span>
              <span>Reviewing Manager List</span>
            </span>
          </Col>
          <Col className="col-12">
            {(subGroupClient?.length > 1 || locationList?.length > 1) && (
              <div className={[style.box_btn_grp, "box"].join(" ")}>
                <FilterBox
                  displaySubgroups={subGroupClient?.length > 1 ? true : false}
                  subGroupClient={subGroupClient}
                  filterSubGroups={filterValue?.filterSubGroups}
                  handleFilterData={handleFilter}
                  filterOptions={filterOptions}
                  displayLocation={locationList?.length > 1 ? true : false}
                  filterLocation={filterValue?.filterLocation}
                  locationList={locationList}
                  isSubGroupSingleSelect={true}
                />
              </div>
            )}
          </Col>
          <Col className="col-12">
            <div className={[style.box_2, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>{cycleName}</h2>
                  <h3>{period}</h3>
                </div>
                <div className={style.pending_submit_info}>
                  <span className={style.pending}>
                    Pending
                    <small>{toFixedNumber(pendingPercentage, 2)}%</small>
                  </span>
                  <span className={style.submitted}>
                    Submitted
                    <small>{toFixedNumber(submittedPercentage, 2)}%</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="outer-table-wrapper">
              <FilterTeam
                filterValue={statusFilter}
                placeholder="Search for team members"
                filter={filter}
                value={searchFilter ? searchFilter : ""}
                style={style}
                teamData={reviewingManagerData}
                onSearch={search}
                searchMember={searchMember}
                onRadioChange={handleStatusChange}
                onResetSearch={resetSearch}
              />
              <div className="box-light-gradient"></div>
              <div className={[style.custom_table, "table-theme-1"].join(" ")}>
                {reviewingManagerData && reviewingManagerData.length > 0 ? (
                  <Table hover responsive className={style.tab_table_responsive}>
                    <thead>
                      <tr>
                        <th className={style.cb_box}></th>
                        <th className={style.emp_id}>Emp Id</th>
                        <th className={style.emp_name}>Name</th>
                        <th className={style.private_assessment}>PA-Rating</th>
                        {isSelfAssessmentAvailable && (
                          <>
                            <th className={style.private_assessment}>SA-Rating</th>
                            <th className={style.private_assessment}>SA-Grade</th>
                          </>
                        )}
                        <th className={style.rating}>
                          Objective <br /> Rating
                        </th>
                        <th className={style.grades}>
                          Objective <br />
                          Aggregate Grade
                        </th>
                        <th className={style.submitted_on}>Submitted On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviewingManagerData.map((item, index) => (
                        <ManagerCycleListRow
                          backgroundColor={getColorByIndex(index)}
                          isPage="REVIEWING"
                          cycleID={cycleID}
                          data={item}
                          key={index + "REVIEWING" + item.employeeId}
                          id={item.employeeId}
                          toggleCheckboxChange={toggleCheckboxChange}
                          toggleHandleReportee={toggleHandleReportee}
                          isSelfAssessmentAvailable={isSelfAssessmentAvailable}
                        />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap80"></div>
                  </div>
                )}
              </div>
            </div>
            {reviewingManagerData &&
              reviewingManagerData.length > 0 &&
              moduleRights(config.hrModule.PERFORMANCE, config.subModule.PERFORMANCE_CYCLE)?.includes("EDIT") && (
                <div className={["box", style.saveBox].join(" ")}>
                  <div className={style.upper_box_wrapper}>
                    <div className={style.heading}>Please Select Expiration Date</div>
                    <div className={style.datepicker_wrapper}>
                      <div className="date_picker_time_wrapper p-0">
                        <div className="calendar_wrapper form-group">
                          <ReactDatePicker
                            disabled={false}
                            className="form-control"
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Select Extended Date"
                            selected={endDate}
                            minDate={new Date()}
                            // maxDate={endDate}
                            onChange={(date) => setEndDate(date)}
                          />
                        </div>
                      </div>
                      <div className={style.optionalText}>*Use expiration date for exceptional cases</div>
                    </div>
                  </div>
                  <div className={style.save_box_left}>
                    <Button type="button" variant={"primary"} onClick={handleReOpen}>
                      <strong>Re-Open</strong>
                    </Button>
                  </div>
                </div>
              )}
            {employeeCount > 0 && <Pagination totalItem={employeeCount} onChangePage={onChangePage} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
