const routePath = {
  MAP_CAPABILITY_VIEW: "/wmg/question-builder/map-template",
  PREVIEW_TEMPLATE_ROUTE: "/question-builder/preview-template",
  IMPORT_PREVIEW_TEMPLATE_ROUTE: "/question-builder/import-preview-template",
  HR_TIMELINE: "/hr/analysis/timeline",
  PROJECT_DEALLOCATION: "/performance/project-deallocation",
  CURRENT_CHECKIN: "/okrprogress/current-okrprogress",
  ONE_ON_ONE_IN_TEAMS: "/one-on-one-agenda/teams",
  HOME: "/home",
  MY_HISTORY: "/okrprogress/my-history",
  NOTIFICATION: "/notifications",
  PERFORMANCE_DASHBOARD: "/performance/dashboard",
  QUESTION_BUILDER: "/question-builder",
  REVIEWS: "/performance/reviews",
  SELF_ASSESSMENT: "/performance/self-assessment",
  CYCLE_DETAIL: "/performance/dashboard/cycle-detail",
  REPORTING_ASSESSMENT: "/performance/reporting-assessment",
  REVIEWING_ASSESSMENT: "/performance/reviewing-assessment",
  REPORTING_MEMBER: "/performance/reporting-assessment/reporting-member",
  REVIEWING_MEMBER: "/performance/reviewing-assessment/reviewing-member",
  PMS: "/performance/pms",
  CYCLE_MEMBER_PROFILE: "/hr/performance/performance-cycle/cycle-member-profile",
  CREATE_PMS_CYCLE: "/hr/performance/performance-cycle/create",
  PMS_CYCLE: "/hr/performance/performance-cycle",
  EDIT_PMS_CYCLE: "/hr/performance/performance-cycle/edit",
  PMS_CYCLE_EMPLOYEE_LIST: "/hr/performance/performance-cycle/employee-list",
  PMS_CYCLE_EMPLOYEE_ASSESSMENT_LIST: "/hr/performance/performance-cycle/employee-assessment-list",
  PMS_CYCLE_RPM_LIST: "/hr/performance/performance-cycle/reporting-manager-list",
  PMS_CYCLE_RVM_LIST: "/hr/performance/performance-cycle/reviewing-manager-list",
  ONE_ON_ONE: "/okrprogress/one-on-one",
  MY_SKILLS: "/wmg/my-skills",
  MY_TECHNICAL_ROLE_CHANGE: "/wmg/my-technical-role-change",
  MY_SKILL_REQUIRED: "/wmg/my-skills/skill-required",
  ADD_SKILL: "/wmg/my-skills/add-skill",
  ADDITIONAL_SKILL: "/wmg/my-skills/additional-skill",
  SKILLS_ENDORSEMENT: "/wmg/skills-endorsement",
  MMG_SKILL_MASTER: "/wmg-view/skill-matrix/skill-master",
  MMG_UNIVERSITY_REQUEST: "/wmg-view/endorsement/university-requests",
  MMG_DEFINE_SKILLS: "/wmg-view/skill-matrix/define-skills",
  MMG_ADD_SKILL_MASTER: "/wmg-view/skill-matrix/skill-master/add",
  MMG_ADD_DEFINE_SKILLS: "/wmg-view/skill-matrix/define-skills/add",
  MMG_EMPLOYEE_SKILL_LISTING: "/wmg-view/skill-matrix/employee-skill-listing",
  MMG_ENDORSEMENT: "/wmg-view/endorsement/wmg-endorsement",
  MMG_TECHNICAL_ROLE_CHANGE_REQUEST: "/wmg-view/role-change/technical-role-change-request",
  MY_OBJECTIVES: "/objectives/my-objectives",
  SELF_OBJECTIVES: "/objectives/self-objective",
  ASSIGN_OBJECTIVE: "/objectives/all-objectives/assign-objective",
  TEAMS_OBJECTIVE: "/objectives/teams-objective",
  CURRENT_ONE_ON_ONE: "/okrprogress/one-on-one/current",
  PREVIOUS_ONE_ON_ONE: "/okrprogress/one-on-one/previous",
  TEAM_FEEDBACK: "/okrprogress/team-feedback",
  CREATE_ONE_ON_ONE: "/okrprogress/one-on-one/create",
  UNIVERSITY_OBJECTIVE: "/objectives/my-objectives/universityObjective",
  ASSIGNED_OBJECTIVE: "/objectives/all-objectives/assigned-objective",
  ALL_OBJECTIVES: "/objectives/all-objectives",
  CREATE_OBJECTIVES: "/objectives/all-objectives/create",
  CREATED_OBJECTIVES: "/objectives/all-objectives/created-objective",
  EDIT_OBJECTIVES: "/objectives/edit-objective",
  MY_TEAM: "/okrprogress/my-team",
  MEMBER_CHECKIN: "/okrprogress/my-team/member-okrprogress",
  MEMBER_CHECKIN_DASHBOARD: "/okrprogress/my-team/member-okrprogress/dashboard",
  MMG_MEMBER_CERTIFICATION: "/wmg-view/university/member-certification",
  MMG_MEMBER_CERTIFICATION_OVERVIEW: "/wmg-view/university/certification-overview",
  MMG_DEALLOCATION_FEEDBACK: "/wmg-view/feedback/wmg-deallocation-feedback",
  HR_DEALLOCATION_FEEDBACK: "/hr/feedback/deallocation-feedback",
  HR_MODULE_ANALYSIS: "/hr/analysis/module-analysis",
  HR_ONE_ON_ONE: "/hr/one-on-one",
  HR_EMPLOYEE_OBJECTIVE: "/hr/employee-objective/employee-objective",
  HR_PMS_DASHBOARD: "/hr/performance/pms-dashboard",
  HR_ASSESSMENT: "/hr/performance/assessment",
  HR_EMPLOYEE_FEEDBACK_HISTORY: "/hr/feedback/employee-feedback-history",
  HR_METRICS_DASHBOARD: "/hr/analysis/metrics-dashboard",
  HR_CHECKIN_OKR_TEMPLATE: "/hr/employee-objective/okr-template",
  SUPER_ADMIN: "/super-admin",
  ADMIN_ROLES: "/admin/roles",
  ADMIN_CREATE_ROLE: "/admin/create-role",
  ADMIN_USER_LIST: "/admin/user-list",
  ADMIN_CHECKIN_CONFIG: "/admin/okrprogress/okrprogress-config",
  ADMIN_CHECKIN_EXTENSION_DATE: "/admin/okrprogress/extension-date",
  ADMIN_CHECKIN_OKR_TEMPLATE: "/admin/okrprogress/okr-template",
  CAMPAIGN: "/okrprogress/campaign",
  QUIZ: "/okrprogress/quiz",
  START_QUIZ: "/okrprogress/quiz/start-quiz",
  VIEW_FEEDBACK: "/view-feedback",
  PAGE_NOT_FOUND: "/page-not-found",
  GLOBAL_FEEDBACK: "/okrprogress/global-feedback",
  MMG_CAPABILITY_DEVELOPMENT: "/wmg/capability-development",
  MMG_CAMPAIGN_LIST_CLONE: "/wmg/capability-development/campaign-list/clone",
  CHECKINS_CAMPAIGN_LIST_CLONE: "/okrprogress/campaign-list/clone",
  MMG_CAMPAIGN_LIST_EDIT: "/wmg/capability-development/campaign-list/edit",
  CHECKINS_CAMPAIGN_LIST_EDIT: "/okrprogress/campaign-list/edit",
  CUSTOM_NOTIFICATION: "/hr/custom-notification",
  MY_CERTIFICATIONS: "/wmg/my-certifications",
  HR_CAMPAIGN_DETAILS: "/hr/campaign-details",
  HR_CAMPAIGN_DETAILS_CLONE: "/hr/campaign-details/hr-clone",
  HR_CAMPAIGN_DETAILS_EDIT: "/hr/campaign-details/hr-edit",
  HR_CAMPAIGN_DETAILS_CREATE: "/hr/campaign-details/create",
  HR_CAMPAIGN_DETAILS_VIEW: "/hr/campaign-details/admin/view",
  CHECKINS_CAMPAIGN_LIST: "/okrprogress/campaign-list",
  CHECKINS_CAMPAIGN_LIST_VIEW: "/okrprogress/campaign-list/view",
  MMG_CAMPAIGN_LIST_VIEW: "/wmg/capability-development/campaign-list/view",
  HR_CAMPAIGN_DETAILS_QUESTION: "/hr/campaign-details/admin/question",
  MMG_CAPABILITY_QUESTION: "/wmg/capability-development/question",
  CHECKINS_CAMPAIGN_LIST_QUESTION: "/okrprogress/campaign-list/question",
  NEST_LOGIN: "/private/nest-login",
  QUIZ_EMPLOYEE_RESULT: "/hr/campaign-details/view/quiz-result",
  QUIZ_EMPLOYEE_RESULT_SUB_ADMIN: "/okrprogress/campaign-list/view/quiz-result",
  ONGOING_QUIZ: "/okrprogress/quiz/start-quiz/ongoing",
  QUIZ_RESULT: "/okrprogress/quiz/result",
  INFORMATION_HUB: "/information-hub",
  MONTHLY_CHECKIN: "/monthly-checkin/monthly-checkin",
  TEAMS_CHECKIN: "/monthly-checkin/teams-checkin",
  HR_FEEDBACK: "/hr/feedback/monthly-check-in-feedback",
};
export default routePath;
