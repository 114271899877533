import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import {
  ADD_SKILL,
  GET_SKILLS,
  DRAFT_SKILL,
  ADDITIONAL_SKILL_LIST,
  DOWNLOAD_RESUME,
  DOWNLOAD_RESUME_TEMPLATE,
  UPLOAD_MY_RESUME,
  GET_SKILL,
  UPDATE_SKILL_MASTER,
  DELETE_ADDITIONAL_SKILL,
  GET_DEPARTMENT_LIST,
  GET_CATEGORY_LIST,
  GET_ROLE_BY_DEPARTMENT,
  GET_TECHNICAL_ROLE,
  SAVE_REQUIRED_SKILL,
  GET_REQUIRED_SKILL_CONFIG,
  SAVE_MY_REQUIRED_SKILL,
  GET_MY_DEPARTMENT_TECH_ROLE,
  LIST_ROLE_WISE_SKILL_REQUIRED_FOR_MMG,
  LIST_EMPLOYEE_REQUIRED_SKILL,
  GET_REQUIRED_SKILL_DETAILS,
  GET_ENDORSEMENT_SKILL,
  GET_EMPLOYEE_LIST,
  GET_ENDORSEMENT_TYPE,
  GET_TECHNICAL_ROLE_LIST,
  GET_BAND,
  GET_EMPLOYEE_SKILL_LISTING_FILTER_DATA,
  GET_CERTIFICATE_NAME,
  ENDORSE_EMPLOYEE,
  ENDORSE_REQ,
  GET_ENDORSEMENT_BY_ME,
  GET_ENDORSE_REQ_FOR_EMP,
  GET_MY_ENDORSEMENT,
  GET_PENDING_DECLINE_REQ,
  APPROVE_ENDORSE_REQ,
  GET_DECLINE_ENDORSE_REQ,
  GET_MMG_ENDORSER_NAME,
  SEND_MMG_ENDORSE_REQ,
  GET_MMG_ENDORSE_REQ,
  GET_UNIVERSITY_REQ,
  GET_UNIVERSITY_DECLINE_APPROVE_REQ,
  EXPORT_LIST,
  APPROVE_DECLINE_UNIVERSITY_REQ,
  GET_DEFINE_SKILL_DRAFT,
  GET_SKILL_MASTER_CATEGORY,
  CATEGORY_WISE_SKILL_MASTER,
  ADD_CATEGORY_IN_SKILL_MASTER,
  UPDATE_TECHNICAL_ROLE,
  CHECK_RESUME_UPDATE_REMINDER,
  LIST_TECHNICAL_ROLE_CHANGE_FOR_MMG,
  APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG,
  RESUME_UPDATED_CONFIRMATION,
  MY_TECHNICAL_ROLE_CHANGE_REQUEST_LIST,
  GET_EMPLOYEE_NAME_FOR_EMPLOYEE_SKILL_LISTING,
} from "../../util/endpoints";
import * as actions from "./index";
import { b64toBlob } from "./checkIn";
import { saveAs } from "file-saver";
import { status } from "const/constValue";

export const sendForApproval = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(ADD_SKILL, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.ERROR_IN_ADD_Skill,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.ADD_Skill,
            payload: response.message.message,
          });
          dispatch({
            type: actionTypes.CLEAR_DRAFT_SKILL,
          });
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ADD_SKILL_ERROR,
          payload: "Issue in add skill.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getSkills = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_SKILLS)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_SKILLS,
        payload: response.data,
        isLoading: false,
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.ERROR_GET_SKILLS,
        isLoading: false,
      });
    });
};

export const draftSkill = (dispatch) => {
  http
    .get(DRAFT_SKILL)
    .then((response) => response.data)
    .then((response) => {
      if (response && response.status === status.FAILURE) {
        dispatch({
          type: actionTypes.FAILURE_DRAFT_RESPONSE,
        });
      } else {
        dispatch({
          type: actionTypes.DRAFT_SKILL,
          payload: response.data,
        });
      }
    })
    .catch(() => {});
};

export const updateDraftSkill = (data) => {
  return (dispatch) => {
    http
      .post(ADD_SKILL, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response && response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.FAILURE_DRAFT_RESPONSE,
          });
        } else {
          dispatch({
            type: actionTypes.DRAFT_SKILL,
            payload: response.data,
          });
        }
      })
      .catch(() => {});
  };
};

export const getAdditionalSkillList = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(ADDITIONAL_SKILL_LIST)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.ADDITIONAL_SKILL_LIST,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getEmployeeResume = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(DOWNLOAD_RESUME)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_EMPLOYEE_RESUME,
        payload: response,
      });
    })
    .catch(() => {});
};

export const uploadMyResume = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(UPLOAD_MY_RESUME, {}, data)
      .then((response) => {
        if (response.data.status === status.FAILURE) {
          dispatch({
            type: actionTypes.ERROR_IN_UPLOAD_RESUME,
            payload: response.data.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.UPLOAD_RESUME_SUCCESS,
            payload: response.data.message.message,
          });
          dispatch(actions.getEmployeeResume);
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ERROR_IN_UPLOAD_RESUME,
          payload: "Issue in upload resume.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const downloadResume = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(DOWNLOAD_RESUME)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.FAILURE) {
        dispatch({
          type: actionTypes.DOWNLOAD_RESUME_ERROR,
          payload: response.message.message,
        });
      } else {
        const blob = b64toBlob(response.data.binary.data, response.data.file.contentType);
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", response.data.file.name);
        document.body.appendChild(link);
        link.click();
        dispatch({
          type: actionTypes.DOWNLOAD_RESUME,
          payload: "File downloaded successfully",
        });
      }
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.DOWNLOAD_RESUME_ERROR,
        payload: "Issue in downloading file",
      });
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    });
};

export const downloadResumeTemplate = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(DOWNLOAD_RESUME_TEMPLATE)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.FAILURE) {
        dispatch({
          type: actionTypes.DOWNLOAD_RESUME_TEMPLATE_ERROR,
          payload: response.message.message,
        });
      } else {
        const blob = b64toBlob(response.data.binary.data, response.data.file.contentType);
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", response.data.file.name);
        document.body.appendChild(link);
        link.click();
        dispatch({
          type: actionTypes.DOWNLOAD_RESUME_TEMPLATE,
          payload: "File downloaded successfully",
        });
      }
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.DOWNLOAD_RESUME_TEMPLATE_ERROR,
        payload: "Issue in downloading file",
      });
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    });
};

export const getSkill = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .get(GET_SKILL, params)
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_SKILL,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const updateAdditionalSkill = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(ADD_SKILL, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.ERROR_IN_ADD_Skill,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.UPDATE_ADDITIONAL_SKILL,
            payload: response.message.message,
          });
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ADD_SKILL_ERROR,
          payload: "Issue in update skill.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const addSkillInMaster = (data, fromMaster = true) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(GET_SKILLS, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SKILL_ADDED_IN_MASTER,
            payload: response.message.message,
          });
          dispatch({
            type: actionTypes.CLEAR_SKILL_ADDED_IN_MASTER,
          });
          dispatch({
            type: actionTypes.ADD_SKILL_MASTER,
            payload: response.message.message,
          });
          if (fromMaster) {
            dispatch(actions.getSkills);
          }
          dispatch(actions.getSkillMaster(false));
        } else {
          dispatch({
            type: actionTypes.ALREADY_EXIST,
            payload: response.message.message,
          });
          dispatch({
            type: actionTypes.ALREADY_EXIST_NOTIFICATION_OFF,
          });
          dispatch({
            type: actionTypes.ADD_SKILL_MASTER_ERROR,
            payload: response.message.message,
          });
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ADD_SKILL_MASTER_ERROR,
          payload: "Issue in add skill",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const updateSkillMaster = (data) => {
  return (dispatch) => {
    http
      .put(UPDATE_SKILL_MASTER, {}, data)
      .then((response) => {
        if (response.data.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.UPDATE_SKILL_MASTER,
            payload: response.data.message.message,
          });
          dispatch({
            type: actionTypes.CLEAR_UPDATE_SKILL_MASTER_NOTIFICATION,
          });
          dispatch(actions.getSkillMaster(false));
        } else {
          dispatch({
            type: actionTypes.ALREADY_EXIST,
          });
          dispatch({
            type: actionTypes.ALREADY_EXIST_NOTIFICATION_OFF,
          });
        }
      })
      .catch(() => {});
  };
};

export const deleteAdditionalSkill = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
    });
    http
      .delete(DELETE_ADDITIONAL_SKILL, null, null, { id: id })
      .then((response) => response.data)
      .then(
        (response) => {
          if (response.status === status.FAILURE) {
            dispatch({
              type: actionTypes.FAIL_DELETE_ADDITIONAL_SKILL,
              payload: response.message.message,
            });
          } else {
            dispatch({
              type: actionTypes.DELETE_ADDITIONAL_SKILL,
              payload: {
                message: response.message.message,
                id: id,
              },
            });
            dispatch({
              type: actionTypes.CLEAR_NOTIFICATION,
            });
          }
        },
        () => {
          dispatch({
            type: actionTypes.ADDITIONAL_SKILL_DELETE_ERROR,
            payload: "Issue in deleting skill",
          });
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
          });
        }
      );
  };
};

export const getDepartmentList = (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_NOTIFICATION,
  });
  http
    .get(GET_DEPARTMENT_LIST)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_DEPARTMENT_LIST,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getCategoryList = (dispatch) => {
  http
    .get(GET_CATEGORY_LIST)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_CATEGORY_LIST,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getRoleByDepartment = (id) => {
  return (dispatch) => {
    http
      .get(GET_ROLE_BY_DEPARTMENT, { id })
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ROLE_BY_DEPARTMENT,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const getTechnicalRole = (param) => {
  return (dispatch) => {
    http
      .get(GET_TECHNICAL_ROLE, param)
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_TECHNICAL_ROLE,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const saveRequiredSkill = (data, param) => {
  const method = param === "UPDATE" ? "put" : "post";
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http[method](SAVE_REQUIRED_SKILL, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.SAVE_REQUIRED_SKILL_ERROR,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.SAVE_REQUIRED_SKILL,
            payload: response.message.message,
          });
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SAVE_REQUIRED_SKILL_ERROR,
          payload: "Issue in save required skill.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getRequiredSkillConfig = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .get(GET_REQUIRED_SKILL_CONFIG, { id })
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_REQUIRED_SKILL_CONFIG,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const saveMyRequiredSkill = (data, param) => {
  const method = param === "UPDATE" ? "put" : "post";

  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http[method](SAVE_MY_REQUIRED_SKILL, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.SAVE_MY_REQUIRED_SKILL_ERROR,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.SAVE_MY_REQUIRED_SKILL_SUCCESS,
            payload: response.message.message,
          });
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SAVE_MY_REQUIRED_SKILL_ERROR,
          payload: "Issue in save required skill.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getMyDepartmentTechRole = (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_NOTIFICATION,
  });
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_MY_DEPARTMENT_TECH_ROLE)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_MY_DEPARTMENT_TECH_ROLE,
        payload: response.data,
      });
    })
    .catch(() => {});
};
export const getRoleWiseSkillList = (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_NOTIFICATION,
  });
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(LIST_ROLE_WISE_SKILL_REQUIRED_FOR_MMG)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST_ROLE_WISE_SKILL_REQUIRED_FOR_MMG,
        payload: response.data.data,
      });
    })
    .catch(() => {});
};

export const employeeRequiredSkill = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(LIST_EMPLOYEE_REQUIRED_SKILL)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST_EMPLOYEE_REQUIRED_SKILL,
        payload: response.data.data,
      });
    })
    .catch(() => {});
};

export const getRequiredSkillDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .get(GET_REQUIRED_SKILL_DETAILS, { id })
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_REQUIRED_SKILL_DETAILS,
          payload: response.data,
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {});
  };
};

export const getEndorsementSkill = (employeeIds) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .get(GET_ENDORSEMENT_SKILL, {}, { params: employeeIds })
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ENDORSEMENT_SKILL,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const employeeList = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_EMPLOYEE_LIST)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_EMPLOYEE_LIST,
        payload: response.data,
      });
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    })
    .catch(() => {});
};

export const getEndorsementTypes = (data) => {
  let params = {
    ...data,
  };
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .get(GET_ENDORSEMENT_TYPE, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ENDORSEMENT_TYPE,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const endorseEmployee = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(ENDORSE_EMPLOYEE, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.ENDORSE_EMPLOYEE_ERROR,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.ENDORSE_EMPLOYEE,
            payload: response.message.message,
          });
          dispatch(actions.getEndorsementByMe);
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ENDORSE_EMPLOYEE_ERROR,
          payload: "Issue in Endorse skill.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const endorseReq = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(ENDORSE_REQ, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.ENDORSE_REQ_ERROR,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.ENDORSE_REQ,
            payload: response.message.message,
          });
          dispatch(actions.getPendingDeclineRequest);
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ENDORSE_REQ_ERROR,
          payload: "Issue in Endorse Request.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getTechnicalRoleList = (dispatch) => {
  http
    .get(GET_TECHNICAL_ROLE_LIST)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_TECHNICAL_ROLE_LIST,
        payload: response.data.data,
      });
    })
    .catch(() => {});
};

export const getBandList = (dispatch) => {
  http
    .get(GET_BAND)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_BAND_LIST,
        payload: response.data.data,
      });
    })
    .catch(() => {});
};

export const getFilterData = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
    });
    http
      .post(GET_EMPLOYEE_SKILL_LISTING_FILTER_DATA, {}, data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_FILTER_DATA,
          payload: response.data.data,
        });
      })
      .catch(() => {});
  };
};

export const getCertificateName = (dispatch) => {
  http
    .get(GET_CERTIFICATE_NAME)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_CERTIFICATE_NAME,
        payload: response.data.data,
      });
    })
    .catch(() => {});
};

export const exportList = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
    });
    http
      .post(EXPORT_LIST, {}, data, { responseType: "arraybuffer" })
      .then((response) => {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let filename = "Employee_Skill_listing.xlsx";
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.EMPLOYEE_SKILL_LISTING_EXPORT,
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EMPLOYEE_SKILL_LISTING_EXPORT_ERROR,
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getEndorsementByMe = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_ENDORSEMENT_BY_ME)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_ENDORSEMENT_BY_ME,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getEndorseReqForEmployee = (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_NOTIFICATION,
  });
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_ENDORSE_REQ_FOR_EMP)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_ENDORSE_REQ_FOR_EMP,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getMyEndorsement = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_MY_ENDORSEMENT)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_MY_ENDORSEMENT,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getPendingDeclineRequest = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_PENDING_DECLINE_REQ)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_PENDING_DECLINE_REQ,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const approveEndorseReq = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .put(APPROVE_ENDORSE_REQ, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.APPROVE_ENDORSE_REQ_ERROR,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.APPROVE_ENDORSE_REQ,
            payload: response.message.message,
          });
          dispatch(actions.getEndorseReqForEmployee);
          dispatch(actions.getDeclineReq);
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.APPROVE_ENDORSE_REQ_ERROR,
          payload: "Issue in approve Endorse Request.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getDeclineReq = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_DECLINE_ENDORSE_REQ)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_DECLINE_ENDORSE_REQ,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getMMGEndorserName = (paramsData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .get(GET_MMG_ENDORSER_NAME, {}, { params: paramsData })
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_MMG_ENDORSER_NAME,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const sendMMGEndorseReq = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(SEND_MMG_ENDORSE_REQ, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.SEND_MMG_ENDORSE_REQ_ERROR,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.SEND_MMG_ENDORSE_REQ,
            payload: response.message.message,
          });
          dispatch(actions.getMMGEndorseRequest);
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SEND_MMG_ENDORSE_REQ_ERROR,
          payload: "Issue in Endorse Request.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getMMGEndorseRequest = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_MMG_ENDORSE_REQ)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_MMG_ENDORSE_REQ,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getUniversityRequest = (showLoader) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_NOTIFICATION,
    });
    if (showLoader) {
      dispatch({
        type: actionTypes.DISPLAY_LOADER,
        isLoading: true,
      });
    }
    http
      .get(GET_UNIVERSITY_REQ)
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_UNIVERSITY_REQ,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const getUniversityDeclineApproveRequest = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_UNIVERSITY_DECLINE_APPROVE_REQ)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_UNIVERSITY_DECLINE_APPROVE_REQ,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const approveDeclineUniversityEndorseReq = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(APPROVE_DECLINE_UNIVERSITY_REQ, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.APPROVE_ENDORSE_REQ_ERROR,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.APPROVE_ENDORSE_REQ,
            payload: response.message.message,
          });
          dispatch(actions.getUniversityRequest(false));
          dispatch(actions.getUniversityDeclineApproveRequest);
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.APPROVE_ENDORSE_REQ_ERROR,
          payload: "Issue in approve Endorse Request.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getDraftForDefineSkill = (showLoader) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.DISPLAY_LOADER,
        isLoading: true,
      });
    }

    http
      .get(GET_DEFINE_SKILL_DRAFT)
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_DEFINE_SKILL_DRAFT,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const updateReqSkill = (data) => {
  return (dispatch) => {
    http
      .post(SAVE_REQUIRED_SKILL, {}, data)
      .then((response) => response.data)
      .then(() => {
        dispatch(actions.getDraftForDefineSkill(false));
      })
      .catch(() => {});
  };
};

export const getSkillMasterCategory = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_SKILL_MASTER_CATEGORY)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_SKILL_MASTER_CATEGORY,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getSkillMaster = (showLoader) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.DISPLAY_LOADER,
      });
    }
    http
      .get(CATEGORY_WISE_SKILL_MASTER)
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.CATEGORY_WISE_SKILL_MASTER,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const addCategorySkillMaster = (data) => {
  return (dispatch) => {
    http
      .post(ADD_CATEGORY_IN_SKILL_MASTER, {}, data)
      .then((response) => {
        if (response.data.status === status.FAILURE) {
          dispatch({
            type: actionTypes.ERROR_IN_ADD_CATEGORY,
            payload: response.data.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.ADD_CATEGORY_SUCCESS,
            payload: response.data.message.message,
          });
          dispatch(actions.getSkillMaster(false));
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ERROR_IN_ADD_CATEGORY,
          payload: "Issue in add category.",
        });
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
        });
      });
  };
};

export const getPageNumber = (page) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PAGE_NUMBER, payload: page });
  };
};

export const downloadResumeEmployeeSkillListing = (data) => {
  return (dispatch) => {
    if (data.resume) {
      const blob = b64toBlob(data.resume.binary.data, data.resume.file.contentType);
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", data.resume.file.name);
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: actionTypes.RESUME_DOWNLOADING_EMPLOYEE_SKILL_LISTING_SUCCESS,
        payload: "Downloading Resume",
      });
    } else {
      dispatch({
        type: actionTypes.RESUME_DOWNLOADING_EMPLOYEE_SKILL_LISTING_ERROR,
        payload: "Resume not available",
      });
    }
  };
};

export const updateTechnicalRole = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(UPDATE_TECHNICAL_ROLE, {}, data)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.UPDATE_TECHNICAL_ROLE_ERROR,
            payload: response.message.message,
          });
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
          });
        } else {
          dispatch({
            type: actionTypes.UPDATE_TECHNICAL_ROLE_SUCCESS,
            payload: response.message.message,
          });
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
          });
        }
      })
      .catch(() => {});
  };
};

export const listChangeRequestForMmg = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(LIST_TECHNICAL_ROLE_CHANGE_FOR_MMG)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST_CHANGE_REQUEST_FOR_MMG,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const approveRejectRequestForTechnicalRoleChangeMMG = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .put(APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG, {}, data)
      .then((response) => response.data)
      .then((response) => {
        dispatch(actions.listChangeRequestForMmg);
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG_SUCCESS,
            payload: { message: response.message.message, title: data.status === "APPROVED" ? "Approved" : "Rejected" },
          });
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
          });
        } else {
          dispatch({
            type: actionTypes.APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG_ERROR,
            payload: response.message.message,
          });
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
          });
        }
      })
      .catch(() => {});
  };
};

export const checkResumeUpdateReminder = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .get(CHECK_RESUME_UPDATE_REMINDER)
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.RESUME_UPDATE_REMINDER,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const resumeAcknowledgementConfirmation = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .post(RESUME_UPDATED_CONFIRMATION, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.RESUME_UPDATE_ACKNOWLEDGE_SUCCESS,
            payload: response.message.message,
          });
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
            payload: response.data,
          });
          dispatch(actions.checkResumeUpdateReminder());
        } else {
          dispatch({
            type: actionTypes.RESUME_UPDATE_ACKNOWLEDGE_ERROR,
            payload: response.message.message,
          });
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
            payload: response.data,
          });
          dispatch(actions.checkResumeUpdateReminder());
        }
      })
      .catch(() => {});
  };
};

export const myTechnicalRoleChangeRequestList = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISPLAY_LOADER,
      isLoading: true,
    });
    http
      .get(MY_TECHNICAL_ROLE_CHANGE_REQUEST_LIST)
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.MY_TECHNICAL_ROLE_CHANGE_REQUEST_LIST,
          payload: response.data,
        });
      })
      .catch(() => {});
  };
};

export const employeeListForEmployeeSkillListing = (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_LOADER,
    isLoading: true,
  });
  http
    .get(GET_EMPLOYEE_NAME_FOR_EMPLOYEE_SKILL_LISTING)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_EMPLOYEE_NAME_FOR_EMPLOYEE_SKILL_LISTING,
        payload: response.data,
      });
    })
    .catch(() => {});
};
