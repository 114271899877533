import * as actionTypes from "./actionTypes";
import http from "util/http";
import {
  DEALLOCATION_LIST_FOR_MANAGER,
  DEALLOCATION_EMPLOYEE_DETAILS,
  UPDATE_DEALLOCATION_FEEDBACK,
  DEALLOCATION_REPORTEE_LIST,
  DEALLOCATION_QUESTION_DOWNLOAD_FILE,
  DEALLOCATION_QUESTION_DELETE_FILE,
  DEALLOCATION_QUESTION_UPLOAD_FILE,
  VALIDATE_OBJECTIVE_FOR_DEALLOCATION,
  DOWNLOAD_DEALLOCATION_DATA,
  DEALLOCATION_FEEDBACK_DATA_LIST,
} from "util/endpoints";
import { saveAs } from "file-saver";
import { status } from "const/constValue";

export const getDeallocationListForManager = () => (dispatch) => {
  dispatch({ type: "REMOVE_DEALLOCATION_FEEDBACK_DETAILS" });
  http
    .get(DEALLOCATION_LIST_FOR_MANAGER)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({ type: actionTypes.DEALLOCATION_LIST_FOR_MANAGER, payload: response.data || [] });
      } else {
        dispatch({ type: actionTypes.DEALLOCATION_LIST_FOR_MANAGER, payload: [] });
      }
    })
    .catch(() => {});
};

export const GetEmployeeDeallocationData = (projectDeAllocationId) => {
  return (dispatch) => {
    http
      .get(DEALLOCATION_EMPLOYEE_DETAILS, { projectDeAllocationId })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data.projectDeAllocation) {
            dispatch({
              type: actionTypes.EMP_DEALLOCATION_DATA,
              payload: {
                isSubmitted:
                  response.data.projectDeAllocation?.status === status.SUBMIT ||
                  response.data.projectDeAllocation?.status === status.INVALIDATE
                    ? true
                    : false,
                empDeallocationData: response.data.projectDeAllocation,
                objectives:
                  response.data?.objectiveList?.length > 0 &&
                  response.data.objectiveList.map((v) => {
                    return {
                      ...v,
                      changeManager: { value: v.managerData?.employeeId, label: v.managerData?.empName },
                    };
                  }),
                isLoadingDetailscreen: false,
                updatedDate: response.data.projectDeAllocation?.updatedDate,
              },
            });
          } else {
            dispatch({
              type: actionTypes.EMP_DEALLOCATION_DATA,
              payload: {
                empDeallocationData: {}, //need to for fetching data pass response
                isLoadingDetailscreen: false,
              },
            });
          }
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetEmployeeDeallocationDataForHR = (projectDeAllocationId, managerIds) => {
  let params = {
    managerId: managerIds.employeeId,
    parentGroupId: managerIds.parentGroupId,
    subGroupId: managerIds.subGroupId,
  };
  return (dispatch) => {
    http
      .get(DEALLOCATION_EMPLOYEE_DETAILS, { projectDeAllocationId }, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data.projectDeAllocation) {
            dispatch({
              type: actionTypes.EMP_DEALLOCATION_DATA_FOR_HR,
              payload: {
                isSubmitted: true,
                empDeallocationData: response.data.projectDeAllocation,
                objectives: response.data?.objectiveList,
              },
            });
          } else {
            dispatch({
              type: actionTypes.EMP_DEALLOCATION_DATA_FOR_HR,
              payload: {
                empDeallocationData: {}, //need to for fetching data pass response
                objectives: [],
              },
            });
          }
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const SetValueInUserObjective = (value, userObjectiveId, objectives, key) => {
  let modifiedData = objectives.map((objective) => {
    if (objective.userObjectiveId === userObjectiveId) {
      return {
        ...objective,
        [key]: value,
      };
    } else return objective;
  });
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMP_DEALLOCATION_DATA,
      payload: {
        objectives: modifiedData,
      },
    });
  };
};

export const SetObjectiveInUserObjective = (updatedObjective, userObjectiveId, objectives) => {
  let modifiedData = objectives.map((objective) => {
    if (objective.userObjectiveId === userObjectiveId) {
      return {
        ...updatedObjective,
      };
    } else return objective;
  });
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMP_DEALLOCATION_DATA,
      payload: {
        objectives: modifiedData,
      },
    });
  };
};

export const SetKeyPointRatingAndComment = (key, value, objectives, keyId, userObjectiveId) => {
  let modifiedData = objectives.map((objective) => {
    if (objective.userObjectiveId === userObjectiveId) {
      let updatedKeypoints = objective.keyPoints.map((kp) => {
        if (kp.keyId === keyId) {
          return {
            ...kp,
            [key]: value,
          };
        } else return kp;
      });
      return {
        ...objective,
        keyPoints: updatedKeypoints,
      };
    } else return objective;
  });
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMP_DEALLOCATION_DATA,
      payload: {
        objectives: modifiedData,
      },
    });
  };
};

export const SetObjectiveError = (objectives) => {
  let modifiedData = objectives.map((objective) => {
    return {
      ...objective,
      isSubmitError: true,
    };
  });
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMP_DEALLOCATION_DATA,
      payload: {
        objectives: modifiedData,
      },
    });
  };
};

export const UpdateDeallocationFeedback = (body) => {
  return (dispatch) => {
    if (body.status) dispatch({ type: "START_DEALLOCATION_FEEDBACK_LOADER" });
    http
      .put(UPDATE_DEALLOCATION_FEEDBACK, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          let payload = {};
          if (response.data) {
            payload = {
              updatedDate: response.data,
              submitFeedbackLoader: false,
            };
          }
          if (body.status) {
            payload = {
              deallocationResponse: {
                message: "Submitted successfully!",
                success: true,
              },
              isSubmitted: true,
              submitFeedbackLoader: false,
            };
          }
          dispatch({
            type: actionTypes.MAKE_IT_UPDATE_DEALLOCATION,
            payload,
          });
        }
      })
      .catch(() => {});
  };
};

export const ValidateObjectiveForDeallocation = (body, statusType, projectDeAllocationId) => {
  return (dispatch) => {
    dispatch({ type: "START_DEALLOCATION_FEEDBACK_LOADER" });
    http
      .post(VALIDATE_OBJECTIVE_FOR_DEALLOCATION, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(UpdateDeallocationFeedback({ status: statusType, projectDeAllocationId }));
        }
      })
      .catch(() => {});
  };
};

export const GetDeallocationReporteeList = (body, isSingleEmpSearch) => (dispatch) => {
  dispatch({ type: "START_LOADING_DEALLOCATION_REPORTEE_LIST" });
  http
    .post(DEALLOCATION_REPORTEE_LIST, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let data = [];
        if (response.data) {
          response.data.forEach((empInfo) => {
            let otherInfo = [];
            let count = 0;
            response.data.forEach((otherEmp) => {
              if (
                empInfo.employee?.employeeId === otherEmp.employee?.employeeId &&
                (empInfo.projectName?.length > 0 && empInfo.projectName[0].projectName) ===
                  (otherEmp.projectName?.length > 0 && otherEmp.projectName[0].projectName)
              ) {
                count++;
                if (count > 1) {
                  otherInfo.push(otherEmp);
                }
              }
            });
            if (
              !(
                otherInfo.length > 0 && otherInfo.find((v) => v.projectDeAllocationId === empInfo.projectDeAllocationId)
              )
            ) {
              if (otherInfo.length > 0) {
                data.push({
                  ...empInfo,
                  other: otherInfo,
                });
              } else data.push(empInfo);
            }
          });
          if (data.length === 1 && data.find((v) => v.other)) {
            data.map((v) => {
              delete v.other;
              return v;
            });
          }
        }
        dispatch({
          type: actionTypes.DEALLOCATION_REPORTEE_LIST,
          payload: (isSingleEmpSearch ? response.data : data) || [],
        });
      } else {
        dispatch({ type: actionTypes.DEALLOCATION_REPORTEE_LIST, payload: [] });
      }
    })
    .catch(() => {
      dispatch({ type: actionTypes.DEALLOCATION_REPORTEE_LIST, payload: [] });
    });
};

export const DownloadDeallocationData = () => (dispatch) => {
  dispatch({ type: "START_FILE_LOADING_IN_DEALLOCATION" });

  http
    .get(DOWNLOAD_DEALLOCATION_DATA, {}, { responseType: "arraybuffer" })
    .then((response) => response.data)
    .then((response) => {
      if (response) {
        dispatch({
          type: actionTypes.DEALLOCATION_FILE_RESPONSE,
          success: true,
          message: "Report Downloaded successfully!",
        });
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = "Deallocation Report.xlsx";
        saveAs(blob, filename);
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.DEALLOCATION_FILE_RESPONSE,
      });
    });
};

export const DownloadFileInDeallocation = (params) => (dispatch) => {
  dispatch({ type: "START_FILE_LOADING_IN_DEALLOCATION" });
  http
    .get(DEALLOCATION_QUESTION_DOWNLOAD_FILE, params)
    .then((response) => response.data.data)
    .then((response) => {
      if (response.status === status.FAILURE) {
        dispatch({
          type: actionTypes.DEALLOCATION_FILE_RESPONSE,
          message: response.message.message,
          success: false,
        });
      } else {
        dispatch({
          type: actionTypes.DEALLOCATION_FILE_RESPONSE,
          success: true,
          message: "File downloaded successfully!",
        });
        const blob = b64toBlob(response.binary.data, response.file.contentType);
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", response.file.name);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.DEALLOCATION_FILE_RESPONSE,
      });
    });
};

export const UploadFileInDeallocation = (formData, projectDeAllocationId) => (dispatch) => {
  dispatch({ type: "START_FILE_LOADING_IN_DEALLOCATION" });
  http
    .post(DEALLOCATION_QUESTION_UPLOAD_FILE, null, formData)
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.status === status.FAILURE) {
          dispatch({
            type: actionTypes.DEALLOCATION_FILE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        } else {
          dispatch({
            type: actionTypes.DEALLOCATION_FILE_RESPONSE,
            message: "File uploaded successfully!",
            success: true,
            updatedDate: response.data,
          });
          dispatch(GetEmployeeDeallocationData(projectDeAllocationId));
        }
      },
      () => {
        dispatch({
          type: actionTypes.DEALLOCATION_FILE_RESPONSE,
          message: "File not supported",
          success: false,
        });
      }
    );
};

export const DeleteFileInDeallocation = (params, projectDeAllocationId) => (dispatch) => {
  dispatch({ type: "START_FILE_LOADING_IN_DEALLOCATION" });

  http
    .delete(DEALLOCATION_QUESTION_DELETE_FILE, params)
    .then((response) => response.data)
    .then(
      (response) => {
        dispatch({
          type: actionTypes.DEALLOCATION_FILE_RESPONSE,
          message: "File deleted successfully!",
          success: true,
          updatedDate: response.data,
        });
        dispatch(GetEmployeeDeallocationData(projectDeAllocationId));
      },
      () => {
        dispatch({
          type: actionTypes.DEALLOCATION_FILE_RESPONSE,
          message: "Issue deleting file",
          success: false,
        });
      }
    );
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    // eslint-disable-next-line no-undef
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const GetDeallocationFilterDataList = () => (dispatch) => {
  http
    .get(DEALLOCATION_FEEDBACK_DATA_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let departmentList = [];
        let employeeNameList = [];
        let locationList = [];
        if (response.data) {
          departmentList = response.data?.department?.map((department) => {
            return { value: department, label: department };
          });
          locationList = response.data.location?.map((location) => {
            return { value: location, label: location };
          });
          employeeNameList = response.data.employeeList?.map((emp) => {
            return {
              value: emp.employeeId,
              label: emp.displayName,
              parentGroupId: emp.parentGroupId,
              subGroupId: emp.subGroupId,
            };
          });
          dispatch({
            type: actionTypes.SET_DEALLOCATION_FILTER_DATA_LIST,
            payload: { departmentList, employeeNameList, locationList },
          });
        }
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};
