import React, { useState } from "react";
import PropTypes from "prop-types";
import CommentsTop from "../../../CommentBox/CommentsTop";
import CommentBox from "./CommentBox";
import Comment from "../../../CommentBox/Comment";
import { backGroundColor } from "../../../../const/constValue";
import MultipleFile from "../../../MultipleFileUpload/MultipleFile";

function SelfAssessmentComment({
  obj,
  isSubmit,
  style,
  loggedInUser,
  error,
  blurHandler,
  uploadFileObjective,
  downloadFileObjective,
  removeFileObjective,
}) {
  const [viewMoreFlag, setViewMoreFlag] = useState(false);

  return (
    <div className={["view_comment_box_wrapper", isSubmit && "bg-white"].join(" ")}>
      <CommentsTop heading="Share your view(s)" />
      {obj.selfAssessmentComment && isSubmit && (
        <Comment
          selectedColor={backGroundColor[0]}
          deletable={false}
          image={obj.selfAssessmentComment?.commentedBy?.displayPicture}
          empName={obj.selfAssessmentComment?.commentedBy?.empName}
          commentedOn={obj.selfAssessmentComment?.commentedOn}
          comment={obj.selfAssessmentComment?.comment}
          isPrivate={false}
        />
      )}
      {obj.comment &&
        obj.comment.length > 0 &&
        obj.comment
          .filter(() => viewMoreFlag)
          .map((comment, index) => (
            <Comment
              selectedColor={backGroundColor[1]}
              key={index}
              deletable={false}
              image={comment.commentedBy?.displayPicture}
              empName={comment.commentedBy?.empName}
              commentedOn={comment.commentedOn}
              comment={comment.comment}
              isPrivate={comment.private}
              privateMsg={`Private message, only visible to you and
                  ${
                    loggedInUser?.employeeId === comment.commentedBy?.employeeId
                      ? ` ${comment.whoCanView && comment.whoCanView.length > 0 && comment.whoCanView[0].empName}`
                      : ` ${comment.commentedBy?.empName}`
                  }`}
            />
          ))}
      {!isSubmit && (
        <CommentBox
          selectedColor={backGroundColor[2]}
          error={error ? (error.comment ? false : true) : false}
          blurHandler={blurHandler}
          image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
          comment={obj.selfAssessmentComment?.comment || ""}
          commentLength={5000}
          handleMultipleFileUpload={uploadFileObjective}
          isMultipleFile={obj.files.length > 4 ? false : true}
        />
      )}
      {obj.files?.length > 0 && (
        <div className="multiple_uploaded_file">
          <MultipleFile
            files={obj.files}
            removeFile={removeFileObjective}
            isDeletable={!isSubmit}
            isDownloadable={true}
            downloadFile={downloadFileObjective}
          />
        </div>
      )}
      {obj.comment && obj.comment.length > 0 && (
        <div className={style.view_all_objective_comment}>
          <span onClick={() => setViewMoreFlag(!viewMoreFlag)}>{viewMoreFlag ? "View Less" : "View More"}</span>
        </div>
      )}
    </div>
  );
}

SelfAssessmentComment.propTypes = {
  obj: PropTypes.object,
  isSubmit: PropTypes.bool,
  style: PropTypes.object,
  loggedInUser: PropTypes.object,
};

export default SelfAssessmentComment;
