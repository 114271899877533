import React from "react";
import PropTypes from "prop-types";
import style from "../CycleMemberProfile.module.scss";
import { Form } from "react-bootstrap";
import OptionalComment from "../QuestionAnswer/OptionalComment";

function ScaleQuestion({
  isSubmitted,
  scaleCommentHandlerTemplate,
  item,
  errors,
  downloadFile,
  deleteFile,
  uploadFile,
  scaleRatingHandlerTemplate,
  name,
  isSurvey,
}) {
  let scaleValue = null;
  let error = errors?.find((v) => v.qId === item.questionId && v.error && v.isSubmit);
  let commentError = errors?.find((v) => v.qId === item.questionId && v.commentBoxError && v.isSubmit);
  scaleValue = item.scaleObj && item.scaleObj.name.split("/");
  if (item.reverseScale) scaleValue.reverse();

  return (
    <div className="radio-wrapper">
      <Form.Group controlId="">
        {!item.notShowScaleToEmployee && (
          <ul className={[style.form_option_list, error && "limit-reached"].join(" ")}>
            {scaleValue &&
              scaleValue.map((optionsData, index_opt) => (
                <li className={scaleValue.length > 2 ? "w-100" : ""} key={"RADIO" + index_opt}>
                  <div className="custom-checkbox custom-checkbox-rounded">
                    <input
                      disabled={isSubmitted}
                      id={item.questionId + item.questionName + index_opt + name}
                      type="radio"
                      name={item.questionName + name}
                      value={optionsData}
                      defaultChecked={item.scaleValue === optionsData}
                      // checked={optionsData.isSelected}
                      onChange={(e) => scaleRatingHandlerTemplate(e.target.value, item)}
                    />
                    <label htmlFor={item.questionId + item.questionName + index_opt + name}>{optionsData}</label>
                  </div>
                </li>
              ))}
          </ul>
        )}
        {item.optionalCommentBox && (
          <OptionalComment
            item={item}
            uploadFile={(e) => uploadFile(e, item.questionId)}
            commentError={commentError ? true : false}
            handleComment={(e) => scaleCommentHandlerTemplate(e, item)}
            isSubmitted={isSubmitted}
            downloadFile={downloadFile}
            commentPlaceHolder={
              item.commentResponse === "Required" ? "Add a new answer (Required)" : "Add a new answer (Optional)"
            }
            deleteFile={deleteFile}
            isSurvey={isSurvey}
          />
        )}
      </Form.Group>
    </div>
  );
}

ScaleQuestion.propTypes = {
  isSubmitted: PropTypes.bool,
  item: PropTypes.object,
  uploadFile: PropTypes.func,
  scaleCommentHandlerTemplate: PropTypes.func,
  scaleRatingHandlerTemplate: PropTypes.func,
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
};

export default ScaleQuestion;
