import React, { Fragment, useState, useEffect } from "react";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import ManagerTeamRow from "./ManagerTeamRow";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { IC_INPUT_SEARCH } from "../../../const/imgCost";
import { filterArray } from "../../../util/general";
import NoDataFound from "../../NodataFound/NoDataFound";
import { noData } from "const/constValue";

export default function MyManagerTab({ managerList, previous, upComing, lapsed, pending, filter }) {
  const [teamData, setTeamData] = useState(managerList);
  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if (filter && filter !== "ALL") {
      setTeamData(managerList.filter((v) => v[filter]));
    } else {
      setTeamData(managerList);
    }
  }, [managerList, filter]);

  let className = "form-control-rounded form-search-item";
  if (searchFilter && searchFilter.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }
  const search = (e) => {
    let filteredData = null;
    const filters = {
      empName: e.target.value,
    };
    filteredData = filterArray(managerList, filters);
    setTeamData(filteredData);
    setSearchFilter(e.target.value);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
    };
    filteredData = filterArray(managerList, filters);
    setTeamData(filteredData);
    setSearchFilter("");
  };

  return (
    <Fragment>
      <div className={style.one_on_one_info_wrapper}>
        <div className={style.one_on_one_filter}>
          <Row className="align-items-center">
            <Col xs={12} sm={12} md={6} lg={6} xl={8}>
              <div className={style.viewer_info}>
                <strong>My Manager({teamData && teamData.length})</strong>
                <span>Tap on a manager name to know more about 1-on-1s</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
              <div className={style.search_bar}>
                <Form>
                  <Form.Group className="form-postfix mb-0">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Search for a manager"
                      name="searchBox"
                      className={className}
                      onChange={search}
                      value={searchFilter}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <Button className="close-button" type="reset" onClick={resetSearch}>
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
        <div className="box-light-gradient"></div>
        <div className={style.one_on_one_table}>
          <div className="custom-table table-theme-1 pt-4">
            {teamData && teamData.length > 0 ? (
              <Table hover responsive className={style.tab_table_responsive}>
                <thead>
                  <tr>
                    <th>
                      <span className={style.name}>Name</span>
                    </th>
                    {previous && <th className={style.center}>Previous 1-on-1</th>}
                    {upComing && <th className={style.center}>Upcoming 1-on-1</th>}
                    {lapsed && <th className={style.center}>Lapsed 1-on-1</th>}
                    {pending && <th className={style.center}>Pending 1-on-1</th>}
                  </tr>
                </thead>
                <tbody>
                  {teamData &&
                    teamData.map((value, index) => (
                      <ManagerTeamRow
                        index={index}
                        team={value.employeeData}
                        key={value.employeeData.employeeId}
                        previous={previous}
                        upComing={upComing}
                        lapsed={lapsed}
                        pending={pending}
                        lapsedOneOnOne={value.lapsedOneOnOne}
                        previousOneOnOne={value.previousOneOnOne}
                        upcomingOneOnOne={value.upcomingOneOnOne}
                        isPendingOneOnOne={value.isPendingOneOnOne}
                      />
                    ))}
                </tbody>
              </Table>
            ) : (
              <div>
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap120"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
