import { status } from "const/constValue";
import { MONTHLY_CHECKIN_FEEDBACK_ENDPOINT } from "util/endpoints";
import { MONTHLY_CHECKIN_FEEDBACK_ACTION } from "./actionTypes";
import * as actions from "store/actions/index";
import http from "util/http";
import moment from "moment";
import config from "util/config";
import { saveAs } from "file-saver";
import { errorToast, successToast } from "util/general";

export const getCountDurationStatusEmployeeAndManager = (role) => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_COUNT_DURATION_STATUS_EMPLOYEE_AND_MANAGER, { role })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (role === "employee") {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_COUNT_DURATION_STATUS_EMPLOYEE,
            payload: {
              countDurationStatusEmployee: response.data,
              isLoading: false,
            },
          });
        } else if (role === "manager") {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_COUNT_DURATION_STATUS_EMPLOYEE,
            payload: {
              countDurationStatusManager: response.data,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Issue getting monthly checkin data")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_COUNT_DURATION_STATUS_EMPLOYEE,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const getCurrentfeedbackEmployee = () => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_CURRENT_FEEDBACK_DATA_EMPLOYEE, {}, {}, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data && response.data.length > 0) {
          const data = response.data[0];
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_CURRENT_FEEDBACK_EMPLOYEE,
            payload: {
              currentFeedbackDataEmployee: data,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_CURRENT_FEEDBACK_EMPLOYEE,
            payload: {
              currentFeedbackDataEmployee: [],
              currentFeedbackDataEmployeeMessage: response.message.message,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Issue getting current feedback data")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_CURRENT_FEEDBACK_EMPLOYEE,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const showYearList = () => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.SHOW_YEAR_LIST, {}, {}, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_SHOW_YEAR_LIST,
            payload: {
              showYearListData: response.data,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_SHOW_YEAR_LIST,
            payload: {
              showYearListData: response.data,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Error while getting Year List")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_SHOW_YEAR_LIST,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const getCurrentfeedbackManager = () => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_CURRENT_FEEDBACK_DATA_MANAGER, {}, {}, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          const { employeeManagerCheckinFeedbackDTO, managerData } = response.data;
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_CURRENT_FEEDBACK_MANAGER,
            payload: {
              employeeManagerCheckinFeedbackDTO,
              managerData,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_CURRENT_FEEDBACK_MANAGER,
            payload: {
              employeeManagerCheckinFeedbackDTO: [],
              managerData: [],
              currentFeedbackDataManagerMessage: response.message.message,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Error while getting current feedback manager data")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_CURRENT_FEEDBACK_MANAGER,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const feedbackInteractionEmployeeAndManager = (body, role, isClear) => (dispatch) => {
  if (!body?.message) {
    dispatch({
      type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
      payload: {
        isLoading: true,
      },
    });
  }
  http
    .post(
      MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.FEEDBACK_INTERACTION_EMPLOYEE_AND_MANAGER,
      { role },
      body
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (role === "employee") {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_INTERACTION_EMPLOYEE,
            payload: {
              setFeedbackInteractionEmployee: response,
            },
          });
          dispatch(getCurrentfeedbackEmployee());
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_INTERACTION_MANAGER,
            payload: {
              setFeedbackInteractionManager: response,
            },
          });
          dispatch(getCurrentfeedbackManager());
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else if (isClear) {
        errorToast("You can not clear the data at this time")
      } else {
        errorToast("You can not send the feedback at this time")
      }
    })
    .finally(() => {
      const type =
        role === "employee"
          ? MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_INTERACTION_EMPLOYEE
          : MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_INTERACTION_MANAGER;
      dispatch({
        type,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const submitFeedbackForEmployeeandManager = (body, role, index) => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER, { role }, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER,
          payload: {
            submitFeedbackEmployeeManager: response,
            isLoading: false,
          },
        });
        successToast("Awesome! Data has been Submitted");
        if (role === "employee") {
          dispatch(getCurrentfeedbackEmployee());
        } else {
          dispatch(getCurrentfeedbackManager());
        }
      } else if (response.status === status.FAILURE) {
        dispatch({
          type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER,
          payload: {
            submitFeedbackEmployeeManager: response,
            questionIndex: index,
            isLoading: false,
          },
        });
        errorToast(response.message.message)
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("You can not submit the feedback at this time")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const getFeedbackHistoryEmployee = (body, parent_group_id, sub_group_id, userId) => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.FEEDBACK_HISTORY_EMPLOYEE, { parent_group_id, sub_group_id, userId }, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_EMPLOYEE,
            payload: {
              feedbackHistoryEmployee: response.data.showFeedBackHistoryEmployeeResponseDTOs,
              totalCount: response.data.totalCountHistory,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_EMPLOYEE,
            payload: {
              feedbackHistoryEmployee: [],
              totalCount: 0,
              isLoading: false,
            },
          });
        }
      } else if (response.status === status.FAILURE) {
        dispatch({
          type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_EMPLOYEE,
          payload: {
            feedbackHistoryEmployee: response,
            isLoading: false,
          },
        });
        errorToast(response.message.message)
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Issue while getting the feedback history for employee")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_EMPLOYEE,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const getFeedbackHistoryManager = (body, parent_group_id, sub_group_id, userId) => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.FEEDBACK_HISTORY_MANAGER, { parent_group_id, sub_group_id, userId }, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_MANAGER,
            payload: {
              feedbackHistoryManager: response.data.managerCheckinHistoryResponseDTO,
              totalCount: response.data.totalCountHistory,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_MANAGER,
            payload: {
              feedbackHistoryManager: [],
              totalCount: 0,
              isLoading: false,
            },
          });
        }
      } else if (response.status === status.FAILURE) {
        dispatch({
          type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_MANAGER,
          payload: {
            feedbackHistoryManager: response,
            isLoading: false,
          },
        });
        errorToast(response.message.message)
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Issue while getting the feedback history for employee")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_MANAGER,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const getManagerListEmployee = (period) => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_MANAGER_LIST_EMPLOYEE, { period })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_MANAGER_LIST_EMPLOYEE,
            payload: {
              managerList: response.data,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_MANAGER_LIST_EMPLOYEE,
            payload: {
              managerList: response.data,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Error while getting manager list")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_MANAGER_LIST_EMPLOYEE,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const getHistoryDetailsEmployeeAndManager = (employeeId, parentGroupId, subGroupId, period, role) => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_HISTORY_DETAILS_EMPLOYEE_AND_MANAGER, { employeeId, parentGroupId, subGroupId, period, role })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_HISTORY_DETAILS_EMPLOYEE_AND_MANAGER,
            payload: {
              historyDetails: response.data,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_HISTORY_DETAILS_EMPLOYEE_AND_MANAGER,
            payload: {
              historyDetails: response.data,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Error while getting History Details")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_HISTORY_DETAILS_EMPLOYEE_AND_MANAGER,
        payload: {
          isLoading: false,
        },
      });
    });
};


export const getFiltersDetailsHr = (body) => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_FILTERS_DETAILS_HR,{}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response?.status === status.SUCCESS) {
        let bandOptions = [];
        let cycleAndPeriodOptions = [];
        let groupOptions = [];
        let employeeTypeOptions = [];
        let locationOptions = [];
        let managerOptions = [];
        let statusOptions = [];
        let subGroupOptions = [];
        let latestCycleDasboard;
        if (response?.data) {
          bandOptions = response?.data?.band.map(b => {
            return { label: b, value: b };
          });
          cycleAndPeriodOptions = response?.data?.cycleAndPeriod?.map(cycle => {
            const cyclePeriod = moment(cycle?.startDate).format(config?.dates?.shortMonthNameAndDate) + " - " + moment(cycle?.endDate).format(config?.dates?.shortMonthNameAndDate) + ", " + moment(cycle?.endDate).format("YYYY");
            return { label: cyclePeriod, value: cyclePeriod, cyclePeriodShort: cycle?.period }
          });
          latestCycleDasboard = response?.data?.currentCycle;
          groupOptions = response?.data?.departments?.map(d => {
            return { label: d, value: d }
          });
          employeeTypeOptions = response?.data?.employeeType?.map(type => {
            return { label: type, value: type }
          });
          locationOptions = response?.data?.location?.map(l => {
            return { label: l, value: l }
          });
          managerOptions = response?.data?.managers?.map(manager => {
            return {
              label: manager?.empName,
              value: manager?.empName,
              employeeId: manager?.employeeId,
              parentGroupId: manager?.parentGroupId,
              subGroupId: manager?.subGroupId,
            }
          });
          statusOptions = response?.data?.status?.map(s => {
            return { label: s, value: s }
          });
          subGroupOptions = response?.data?.subGroups?.map(subGroup => {
            return { label: subGroup?.subGroupDisplayName, value: subGroup?.subGroupDisplayName, subGroupId: subGroup?.subGroupId }
          });
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FILTERS_DETAILS_HR,
            payload: {
              bandOptions,
              cycleAndPeriodOptions,
              groupOptions,
              employeeTypeOptions,
              locationOptions,
              managerOptions,
              statusOptions,
              subGroupOptions,
              filtersDetailsHr: response.data,
              latestCycleDasboard,
              isLoading: false
            },
          });
          dispatch(getDashboardDataHR(latestCycleDasboard, 1, 10, ""));
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FILTERS_DETAILS_HR,
            payload: {
              filtersDetailsHr: null,
              isLoading: false,
            },
          });
        }
      } else if (response.status === status.FAILURE) {
        dispatch({
          type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FILTERS_DETAILS_HR,
          payload: {
            filtersDetailsHr: response,
            isLoading: false,
          },
        });
        errorToast(response.message.message)
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Issue while getting the filters details for HR")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FILTERS_DETAILS_HR,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const checkinFeedbackExport = (latestCycleDasboard, feedbackFilterData) => (dispatch) => {
  let body = {
    period: feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort.length > 0 ? feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort : latestCycleDasboard,
    groups: feedbackFilterData?.filterGroup?.length > 0 ? feedbackFilterData?.filterGroup.map((v) => v.label) : undefined,
    subGroupIds: feedbackFilterData?.filterSubGroup?.length > 0 ? feedbackFilterData?.filterSubGroup.map((v) => v.subGroupId) : undefined,
    employeeTypes: feedbackFilterData?.filterEmployeeType?.length > 0 ? feedbackFilterData?.filterEmployeeType.map((v) => v.label) : undefined,
    bands: feedbackFilterData?.filterBandWise?.length > 0 ? feedbackFilterData?.filterBandWise.map((v) => v.label) : undefined,
    status: feedbackFilterData?.filterStatusWise?.label?.length > 0 ? feedbackFilterData?.filterStatusWise?.label : undefined,
    locations: feedbackFilterData?.filterLocationWise?.length > 0 ? feedbackFilterData?.filterLocationWise.map((v) => v.label) : undefined,
    filterVerticalHead: feedbackFilterData?.filterVerticalHead?.length > 0 ? feedbackFilterData?.filterVerticalHead.map((v) => v.label) : undefined,
    managerIds:
      feedbackFilterData?.filterManagerName?.length > 0
        ? feedbackFilterData?.filterManagerName.map((emp) => {
          return {
            employeeId: emp.employeeId,
            subGroupId: emp.subGroupId,
            parentGroupId: emp.parentGroupId,
          };
        })
        : undefined,
  };
  dispatch({ type: MONTHLY_CHECKIN_FEEDBACK_ACTION.START_FILE_DOWNLOAD_IN_BACKGROUND });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_CHECKIN_FEEDBACK_EXPORT, {}, body, { responseType: "arraybuffer" })
    .then((response) => response.data)
    .then((response) => {
      if (response) {
        dispatch({
          type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_CHECKIN_FEEDBACK_EXPORT,
          success: true,
          message: "Report Downloaded successfully!",
        });
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = "monthly-check-in-feedback.xlsx";
        saveAs(blob, filename);
      }
    })
    .catch(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_CHECKIN_FEEDBACK_EXPORT,
      });
    })
};

export const getDashboardDataHR = (latestCycleDasboard, currentPage, pageSize, name, feedbackFilterData) => (dispatch) => {
  let body = {
    period: feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort.length > 0 ? feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort : latestCycleDasboard,
    groups: feedbackFilterData?.filterGroup?.length > 0 ? feedbackFilterData?.filterGroup.map((v) => v.label) : undefined,
    subGroupIds: feedbackFilterData?.filterSubGroup?.length > 0 ? feedbackFilterData?.filterSubGroup.map((v) => v.subGroupId) : undefined,
    employeeTypes: feedbackFilterData?.filterEmployeeType?.length > 0 ? feedbackFilterData?.filterEmployeeType.map((v) => v.label) : undefined,
    bands: feedbackFilterData?.filterBandWise?.length > 0 ? feedbackFilterData?.filterBandWise.map((v) => v.label) : undefined,
    status: feedbackFilterData?.filterStatusWise?.label?.length > 0 ? feedbackFilterData?.filterStatusWise?.label : undefined,
    locations: feedbackFilterData?.filterLocationWise?.length > 0 ? feedbackFilterData?.filterLocationWise.map((v) => v.label) : undefined,
    filterVerticalHead: feedbackFilterData?.filterVerticalHead?.length > 0 ? feedbackFilterData?.filterVerticalHead.map((v) => v.label) : undefined,
    managerIdForDashBoard:
      feedbackFilterData?.filterManagerName?.length > 0
        ? feedbackFilterData?.filterManagerName.map((emp) => {
          return emp.employeeId;
        })
        : undefined,
    pageNumber: currentPage?.toString(),
    offSet: pageSize?.toString(), name
  };
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.IS_DASHBOARD_LOADING,
    payload: {
      isDashboardDataLoding: true,
    },
  });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_DASHBOARD_DATA_IN_HR_VIEW, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_DASHBOARD_DATA_HR,
            payload: {
              feedbackDashboardData: response.data.feedBackDashBoardDTOs,
              feedbackDashboardPeriod: response.data.period,
              totalCount: response.data.totalCount,
              isDashboardDataLoding: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_DASHBOARD_DATA_HR,
            payload: {
              feedbackDashboardData: response.data,
              totalCount: 0,
              isDashboardDataLoding: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Error while getting feedback dashboard data")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_DASHBOARD_DATA_HR,
        payload: {
          isDashboardDataLoding: false,
          isLoading: false,
        },
      });
    });
};

export const showFeedbackDetailsInHRView = (employeeId, managerId, parent_group_id, period, sub_group_id, userId, user_role) => (dispatch) => {
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.SHOW_FEEDBACK_DETAILS_IN_HR_VIEW, { employeeId, managerId, parent_group_id, period, sub_group_id, userId, user_role })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_DETAILS_IN_HR_VIEW,
            payload: {
              feedbackDetailsInHRView: response.data,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_DETAILS_IN_HR_VIEW,
            payload: {
              feedbackDetailsInHRView: response.data,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Error while getting History Details")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_DETAILS_IN_HR_VIEW,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const graphCheckinFeedbackHR = (latestCycleDasboard, feedbackFilterData) => (dispatch) => {
  let body = {
    period: feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort.length > 0 ? feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort : latestCycleDasboard,
    groups: feedbackFilterData?.filterGroup?.length > 0 ? feedbackFilterData?.filterGroup.map((v) => v.label) : undefined,
    subGroupIds: feedbackFilterData?.filterSubGroup?.length > 0 ? feedbackFilterData?.filterSubGroup.map((v) => v.subGroupId) : undefined,
    employeeTypes: feedbackFilterData?.filterEmployeeType?.length > 0 ? feedbackFilterData?.filterEmployeeType.map((v) => v.label) : undefined,
    bands: feedbackFilterData?.filterBandWise?.length > 0 ? feedbackFilterData?.filterBandWise.map((v) => v.label) : undefined,
    status: feedbackFilterData?.filterStatusWise?.label?.length > 0 ? feedbackFilterData?.filterStatusWise?.label : undefined,
    locations: feedbackFilterData?.filterLocationWise?.length > 0 ? feedbackFilterData?.filterLocationWise.map((v) => v.label) : undefined,
    filterVerticalHead: feedbackFilterData?.filterVerticalHead?.length > 0 ? feedbackFilterData?.filterVerticalHead.map((v) => v.label) : undefined,
    managerIds:
      feedbackFilterData?.filterManagerName?.length > 0
        ? feedbackFilterData?.filterManagerName.map((emp) => {
          return {
            employeeId: emp.employeeId,
            subGroupId: emp.subGroupId,
            parentGroupId: emp.parentGroupId,
          };
        })
        : undefined,
  };
  dispatch({
    type: MONTHLY_CHECKIN_FEEDBACK_ACTION.IS_GRAPH_LOADING,
    payload: {
      isGraphDataLoding: true,
    },
  });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_GRAPH_CHECKIN_FEEDBACK_HR, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_GRAPH_CHECKIN_FEEDBACK_HR,
            payload: {
              feedbackGraphData: response.data,
              isGraphDataLoding: false,
            },
          });
        } else {
          dispatch({
            type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_GRAPH_CHECKIN_FEEDBACK_HR,
            payload: {
              feedbackGraphData: response.data,
              isGraphDataLoding: false,
            },
          });
        }
      }
    })
    .catch((response) => {
      if (response?.message?.message) {
        errorToast(response.message.message)
      } else {
        errorToast("Error while getting graph data")
      }
    })
    .finally(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_GRAPH_CHECKIN_FEEDBACK_HR,
        payload: {
          isGraphDataLoding: false,
          isLoading: false,
        },
      });
    });
};

export const questionBasedExportEmployee = (questionID, latestCycleDasboard, feedbackFilterData) => (dispatch) => {
  let body = {
    period: feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort.length > 0 ? feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort : latestCycleDasboard,
    groups: feedbackFilterData?.filterGroup?.length > 0 ? feedbackFilterData?.filterGroup.map((v) => v.label) : undefined,
    subGroupIds: feedbackFilterData?.filterSubGroup?.length > 0 ? feedbackFilterData?.filterSubGroup.map((v) => v.subGroupId) : undefined,
    employeeTypes: feedbackFilterData?.filterEmployeeType?.length > 0 ? feedbackFilterData?.filterEmployeeType.map((v) => v.label) : undefined,
    bands: feedbackFilterData?.filterBandWise?.length > 0 ? feedbackFilterData?.filterBandWise.map((v) => v.label) : undefined,
    status: feedbackFilterData?.filterStatusWise?.label?.length > 0 ? feedbackFilterData?.filterStatusWise?.label : undefined,
    locations: feedbackFilterData?.filterLocationWise?.length > 0 ? feedbackFilterData?.filterLocationWise.map((v) => v.label) : undefined,
    filterVerticalHead: feedbackFilterData?.filterVerticalHead?.length > 0 ? feedbackFilterData?.filterVerticalHead.map((v) => v.label) : undefined,
    managerIds:
      feedbackFilterData?.filterManagerName?.length > 0
        ? feedbackFilterData?.filterManagerName.map((emp) => {
          return {
            employeeId: emp.employeeId,
            subGroupId: emp.subGroupId,
            parentGroupId: emp.parentGroupId,
          };
        })
        : undefined,
  };
  dispatch({ type: MONTHLY_CHECKIN_FEEDBACK_ACTION.START_FILE_DOWNLOAD_IN_BACKGROUND });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_QUESTION_BASED_EXPORT_EMPLOYEE, {questionID}, body, { responseType: "arraybuffer" })
    .then((response) => response.data)
    .then((response) => {
      if (response) {
        dispatch({
          type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_QUESTION_BASED_EXPORT_EMPLOYEE,
          success: true,
          message: "Report Downloaded successfully!",
        });
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = "monthly-check-in-feedback.xlsx";
        saveAs(blob, filename);
      }
    })
    .catch(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_QUESTION_BASED_EXPORT_EMPLOYEE,
      });
    })
};

export const getQuestionBasedExportManager = (questionID, latestCycleDasboard, feedbackFilterData) => (dispatch) => {
  let body = {
    period: feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort.length > 0 ? feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort : latestCycleDasboard,
    groups: feedbackFilterData?.filterGroup?.length > 0 ? feedbackFilterData?.filterGroup.map((v) => v.label) : undefined,
    subGroupIds: feedbackFilterData?.filterSubGroup?.length > 0 ? feedbackFilterData?.filterSubGroup.map((v) => v.subGroupId) : undefined,
    employeeTypes: feedbackFilterData?.filterEmployeeType?.length > 0 ? feedbackFilterData?.filterEmployeeType.map((v) => v.label) : undefined,
    bands: feedbackFilterData?.filterBandWise?.length > 0 ? feedbackFilterData?.filterBandWise.map((v) => v.label) : undefined,
    status: feedbackFilterData?.filterStatusWise?.label?.length > 0 ? feedbackFilterData?.filterStatusWise?.label : undefined,
    locations: feedbackFilterData?.filterLocationWise?.length > 0 ? feedbackFilterData?.filterLocationWise.map((v) => v.label) : undefined,
    filterVerticalHead: feedbackFilterData?.filterVerticalHead?.length > 0 ? feedbackFilterData?.filterVerticalHead.map((v) => v.label) : undefined,
    managerIds:
      feedbackFilterData?.filterManagerName?.length > 0
        ? feedbackFilterData?.filterManagerName.map((emp) => {
          return {
            employeeId: emp.employeeId,
            subGroupId: emp.subGroupId,
            parentGroupId: emp.parentGroupId,
          };
        })
        : undefined,
  };
  dispatch({ type: MONTHLY_CHECKIN_FEEDBACK_ACTION.START_FILE_DOWNLOAD_IN_BACKGROUND });
  http
    .post(MONTHLY_CHECKIN_FEEDBACK_ENDPOINT.GET_QUESTION_BASED_EXPORT_MANAGER, {questionID}, body, { responseType: "arraybuffer" })
    .then((response) => response.data)
    .then((response) => {
      if (response) {
        dispatch({
          type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_QUESTION_BASED_EXPORT_MANAGER,
          success: true,
          message: "Report Downloaded successfully!",
        });
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = "monthly-check-in-feedback.xlsx";
        saveAs(blob, filename);
      }
    })
    .catch(() => {
      dispatch({
        type: MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_QUESTION_BASED_EXPORT_MANAGER,
      });
    })
};