import React, { useState, useEffect } from "react";
import { Col, Table } from "react-bootstrap";
import TeamTableRow from "../../shared/TeamTableRow";
import style from "./TabCurrentCheckIn.module.scss";
import FilterTeam from "../FilterTeam/FilterTeam";
import Pagination from "../../components/Pagination/Pagination";
import { filterArray } from "../../util/general";
import { useSelector } from "react-redux";
import NoDataFound from "../NodataFound/NoDataFound";
import { noData } from "const/constValue";

export default function CurrentCheckIn({ teamResponse, filterValue }) {
  const [pageOfItems, setPageOfItems] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  // const data = useSelector((state) => state.myTeam.teamData);
  const pageSize = useSelector((state) => state.myTeam.pageSize);
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);
  useEffect(() => {
    setTeamData(teamResponse);
    setSearchFilter("");
    setStatusFilter("");
  }, [teamResponse]);

  useEffect(() => {
    setStatusFilter(filterValue);
    if (statusFilter) {
      handleStatusChange(statusFilter);
    }
  }, [teamResponse]);
  const search = (event) => {
    let filteredData = null;
    const filters = {
      empName: event.target.value,
      currentCycleStatus: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(teamResponse, filters);
    let filterReportee = null;
    let data = [];
    if (filteredData.length === 0) {
      teamResponse.map((value) => {
        if (value.reportees && value.reportees.length > 0) {
          data = data.concat(value.reportees);
        }
      });
    }
    filterReportee = filterArray(data, filters);
    setFilteredData(filteredData.length === 0 ? filterReportee : filteredData);
    setSearchFilter(event.target.value);
  };

  const handleStatusChange = (value) => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      currentCycleStatus: value,
    };
    filteredData = filterArray(teamResponse, filters);
    setStatusFilter(value);
    setFilteredData(filteredData);
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      currentCycleStatus: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(teamResponse, filters);

    setFilteredData(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter(searchFilter);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
      currentCycleStatus: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(teamResponse, filters);
    setFilteredData(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter("");
  };

  useEffect(() => {
    filteredData.length > 0
      ? setTeamData(filteredData)
      : statusFilter || searchFilter
      ? setTeamData(filteredData)
      : setTeamData(teamResponse);
  }, [filteredData]);

  const filter = {
    value: ["", "PENDING_FOR_REVIEW", "PENDING_FROM_MEMBER", "REVIEWED"],
    name: ["All", "Pending for Review", "Pending from Member", "Reviewed"],
  };
  return (
    <>
      <div className="outer-table-wrapper my-team-check-in-table-wrapper">
        {teamResponse && (
          <FilterTeam
            placeholder="Search for team members"
            filter={filter}
            value={searchFilter ? searchFilter : ""}
            style={style}
            teamData={teamResponse}
            onSearch={search}
            onRadioChange={(e) => handleStatusChange(e.target.value)}
            onReset={resetData}
            onResetSearch={resetSearch}
            filterValue={filterValue}
          />
        )}

        <div className="box-light-gradient"></div>
        <div>
          <Col xs={12}>
            <div className="custom-table table-theme-1 pt40">
              {pageOfItems && pageOfItems.length > 0 ? (
                <Table hover responsive className={style.tab_table_responsive}>
                  <thead>
                    <tr>
                      <th width="320px">Name</th>
                      <th width="120px">Due Date</th>
                      <th width="140px">Submit Date</th>
                      <th width="165px">OKR Progress Patterns</th>
                      <th width="180px">Status</th>
                      {/* {<th width="80px">Actions</th> } */}
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItems &&
                      pageOfItems.length > 0 &&
                      pageOfItems.map((item) => (
                        <TeamTableRow data={item} key={item.employeeId} id={item.employeeId} />
                      ))}
                  </tbody>
                </Table>
              ) : (
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              )}
            </div>
          </Col>
        </div>
      </div>

      <Pagination pageSize={pageSize} items={teamData} resetData={resetData} onChangePage={onChangePage} />
    </>
  );
}
