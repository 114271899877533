import React from "react";
import { LOADER } from "../../const/imgCost";

function Spinner() {
  return (
    <div className="loader">
      <img src={LOADER} alt="loader" />
    </div>
  );
}

export default Spinner;
