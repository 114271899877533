import React from "react";
import AccordianPanel from "./AccordianPanel";

export default function InformationHubWrapper(props) {

  return (
    <>
    <AccordianPanel/>
    </>
  );
}
