import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { btnName, backGroundColor, MonthlyCheckinTexts, noData } from "const/constValue";
import { Button, Form, Row, Table } from "react-bootstrap";
import * as actions from "store/actions/index";
import { IC_EXPAND, IC_COLLAPSE, IC_EYE, IC_INPUT_SEARCH } from "const/imgCost";
import { dateFormat2 } from "util/dateUtils";
import Pagination from "shared/Pagination/Pagination";
import CheckInDetailsViewPopup from "components/CheckInDetailsViewPopup/CheckInDetailsViewPopup";
import NoDataFound from "components/NodataFound/NoDataFound";
import style from "./CheckInDetailsTable.module.scss";
import Spinner from "shared/Spinner/Spinner";

export default function CheckInDetailsTable({
  feedbackDetailsInHRView,
  feedbackDashboardData,
  getHistoryDetails,
  feedbackFilterData,
  totalCount,
  latestCycleDasboard,
  feedbackDashboardPeriod,
  isDashboardDataLoding,
}) {
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => state.objectives);
  const { currentPage } = useSelector((state) => state.myTeam);
  const [searchText, setSearchText] = useState("");
  const [openPopup, setOpenPopup] = useState(false);

  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  // useEffect(() => {
  //   currentPage === 1 && dispatch(actions.getDashboardDataHR(latestCycleDasboard, 1, 10, "", feedbackFilterData));
  // }, [dispatch, latestCycleDasboard]);

  useEffect(() => {
    dispatch({ type: "EXPANDED", expanded: null });
  }, [dispatch]);

  const toggleExpander = (id) => {
    expanded === id
      ? dispatch({ type: "EXPANDED", expanded: null })
      : dispatch({ type: "EXPANDED", expanded: id });
  };

  const renderStatus = (status) => {
    switch (status) {
      case "TOTAL_PENDING":
        return <span className={style.TOTAL_PENDING}>TOTAL PENDING</span>;
      case "PARTIALLY_PENDING":
        return <span className={style.PARTIALLY_PENDING}>PARTIALLY PENDING</span>;
      case "COMPLETED":
        return <span className={style.COMPLETED}>COMPLETED</span>;
      case "PENDING_FROM_MEMBER":
        return <span className={style.PARTIALLY_PENDING}>PENDING FROM MEMBER</span>;
      case "Pending_from_employee":
        return <span className={style.PARTIALLY_PENDING}>PENDING FROM EMPLOYEE</span>;
      case "Pending_from_manager":
        return <span className={style.PARTIALLY_PENDING}>PENDING FROM MANAGER</span>;
      case "Completed":
        return <span className={style.COMPLETED}>COMPLETED</span>;
      default:
        return <></>;
    }
  };

  const handleShow = (employeeId, managerId, period, isActionIconDisable) => {
    if (!isActionIconDisable) {
      setOpenPopup(!openPopup);
      getHistoryDetails(employeeId, managerId, period);
    }
  }

  const handleChangePage = (currentPage, pageSize) => {
    dispatch(actions.getDashboardDataHR(latestCycleDasboard, currentPage, pageSize, searchText, feedbackFilterData));
  };

  const handleSearchInputChange = (e) => {
    if (e.target.value.length > 0) {
      setSearchText(e.target.value);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setSearchText("");
    dispatch(actions.getDashboardDataHR(latestCycleDasboard, 1, 10, "", feedbackFilterData));
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      dispatch(actions.getDashboardDataHR(latestCycleDasboard, 1, 10, searchText, feedbackFilterData));
    }
  };

  return (
    <React.Fragment>
      {isDashboardDataLoding && <Spinner />}
      <div className="box pb-0">
        <div className={style.header_wrapper}>
          <div className={style.heading}>
            <h2>Check-in's</h2>
            <h6>
              <span className={style.greyText}>{feedbackDashboardPeriod}&nbsp;{MonthlyCheckinTexts.CHECK_IN_PERIOD}&nbsp;</span>
            </h6>
          </div>
          <button
            type="button"
            data-test='exportBtn'
            className="btn btn-primary date-range-apply-btn"
            onClick={() => dispatch(actions.checkinFeedbackExport(latestCycleDasboard, feedbackFilterData))}
          >
            <strong>{btnName.EXPORT}</strong>
          </button>
        </div>
        <div className="box-light-gradient"></div>
        <div className="box-inner-pad pb-0 px-2">
          <div className={style.search_bar}>
            <Row>
              <div className="d-flex col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form className={style.searchbar_wrapper}>
                  <Form.Group className="form-postfix mb-0 w-100">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Search for manager / team members"
                      name="searchBox"
                      data-test='searchBox'
                      className={className}
                      onChange={handleSearchInputChange}
                      value={searchText}
                      onKeyPress={handleOnKeyPress}
                    />
                    <Button
                      className="close-button"
                      data-test='handleClose'
                      type="reset"
                      onClick={handleClose}
                    >
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Row>
          </div>
          <div className={style.main_table_wrapper}>
            {feedbackDashboardData?.length > 0 ?
              <Table className={style.tab_table_responsive}>
                <thead>
                  <tr>
                    <th className={style.name}>Name</th>
                    <th className={style.submitted_date}>
                      Submitted Date (Employee) | Status
                    </th>
                    <th className={style.submitted_date}>
                      Submitted Date (Manager) | Status
                    </th>
                    <th className={style.action}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {feedbackDashboardData?.map((manager, index) => {
                    const {
                      managerData,
                      employees,
                    } = manager;
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className={style.name}>
                            <div className={style.tab_team_member_info}>
                              <span
                                className={style.expand_image_wrapper}
                                onClick={() => toggleExpander(index)}
                              >
                                {expanded === index ? (
                                  <ReactSVG src={`${IC_COLLAPSE}`} />
                                ) : (
                                  <ReactSVG src={`${IC_EXPAND}`} />
                                )}
                              </span>
                              <div className={style.team_member_info}>
                                <div
                                  className={style.team_member_img}
                                  style={{
                                    backgroundColor:
                                      backGroundColor[
                                      index % backGroundColor.length
                                      ],
                                  }}
                                >
                                  {managerData?.dispArr && managerData?.dispArr?.length > 2 ? (
                                    <img src={managerData?.dispArr} alt={managerData?.empName} />
                                  ) : (
                                    managerData?.empInitial
                                  )}
                                </div>
                                <div className={style.team_member_details}>
                                  <div className={style.team_member_name}>
                                    {managerData?.empName}
                                  </div>
                                  <div className={style.team_member_designation}>
                                    {managerData?.designation}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        {expanded === index && (
                          <tr>
                            <td colSpan="5" className={style.table_cell}>
                              <div className={style.inner_table_wrapper}>
                                <Table className={style.inner_table}>
                                  <tbody>
                                    {employees.map((emp, index) => {
                                      const {
                                        employeeDetailsCustomDTO,
                                      } = emp;
                                      return (
                                        <tr key={index}>
                                          <td className={style.name}>
                                            <div className={style.tab_team_member_info}>
                                              <div className={style.team_member_info}>
                                                <div
                                                  className={style.team_member_img}
                                                  style={{
                                                    backgroundColor:
                                                      backGroundColor[
                                                      index % backGroundColor.length
                                                      ],
                                                  }}
                                                >
                                                  {employeeDetailsCustomDTO?.employeeProfile?.displayPicture &&
                                                    employeeDetailsCustomDTO?.employeeProfile?.displayPicture?.length > 2 ? (
                                                    <img
                                                      src={employeeDetailsCustomDTO?.employeeProfile?.displayPicture}
                                                      alt={employeeDetailsCustomDTO?.employeeProfile?.displayName}
                                                    />
                                                  ) : (
                                                    employeeDetailsCustomDTO?.employeeProfile?.empInitial
                                                  )}
                                                </div>
                                                <div
                                                  className={style.team_member_details}
                                                >
                                                  <div
                                                    className={style.team_member_name}
                                                  >
                                                    {employeeDetailsCustomDTO?.employeeProfile?.displayName}
                                                  </div>
                                                  <div
                                                    className={
                                                      style.team_member_designation
                                                    }
                                                  >
                                                    {employeeDetailsCustomDTO?.employeeProfile?.designation}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className={style.submitted_date_value}>
                                            {dateFormat2(employeeDetailsCustomDTO?.submittedDateEmployee) ?? "-"} | {" "}
                                            {renderStatus(employeeDetailsCustomDTO?.employeeStatus)}
                                          </td>
                                          <td className={style.submitted_date_value}>
                                            {dateFormat2(employeeDetailsCustomDTO?.submittedDateManager) ?? "-"} | {" "}
                                            {renderStatus(employeeDetailsCustomDTO?.managerStatus)}
                                          </td>
                                          <td className={style.action_icon}>
                                            <span
                                              data-test='handleShow'
                                              onClick={() => handleShow(employeeDetailsCustomDTO?.employeeProfile?.employeeId, managerData?.employeeId, feedbackDashboardPeriod, employeeDetailsCustomDTO?.employeeStatus === "PENDING_FROM_MEMBER" && employeeDetailsCustomDTO?.managerStatus === "PENDING_FROM_MEMBER")}
                                            >
                                              <ReactSVG
                                                src={`${IC_EYE}`}
                                                className={style.eye_icon}
                                                style={{
                                                  cursor: employeeDetailsCustomDTO?.employeeStatus === "PENDING_FROM_MEMBER" && employeeDetailsCustomDTO?.managerStatus === "PENDING_FROM_MEMBER"
                                                    ? "not-allowed"
                                                    : "pointer",
                                                }}
                                              />
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table> : (!isDashboardDataLoding ?
              <div className="pb-5">
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              </div> : null) }
          </div>
        </div>

        {openPopup && (
          <CheckInDetailsViewPopup
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            historyDetails={feedbackDetailsInHRView}
          />
        )}
      </div>
      {totalCount > 0 && <Pagination totalItem={totalCount} onChangePage={handleChangePage} />}
    </React.Fragment>
  );
}
