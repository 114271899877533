import React, { useState, useEffect } from "react";
import { Col, Row, Collapse } from "react-bootstrap";
import CommentTextArea from "components/CommentBox/CommentTextArea";
import CommentsTop from "components/CommentBox/CommentsTop";
import Comment from "components/CommentBox/Comment";
import storage from "util/storage";
import style from "./MultiManagerWrapper.module.scss";
import { MonthlyCheckinTexts, btnName, backGroundColor } from "../../../../const/constValue";

export default function MultiManagerWrapper({ reportingManagerList,
  period, 
  currentCheckIn,
  isCleared, 
  isMandatory,
  questionTitleIndex,
  handleChangeResult,
  handleSubmitMessage,
  handleSubmit,
  handleClear }) {
  const loggedInUser = storage.getUser();
  const [show, setShow] = useState(null);
  const [commentMessages, setCommentMessages] = useState([]);
  const [activeCommentIndex, setActiveCommentIndex] = useState(null);

  useEffect(() => {
    // first accordian should open
    const accordianData = { 0: true };
    for (let i = 1; i < reportingManagerList?.length; i++) {
      accordianData[i] = false;
    }
    setShow(accordianData);
  }, []);

  const handleShow = (id) => {
    setShow((prev) => {
      return { ...prev, [id]: !prev[id] };
    });
  };

  useEffect(() => {
    if(reportingManagerList && reportingManagerList?.length > 0){
      let filteredComments = reportingManagerList.map(item => {
        const { employeeComment, managerData, submittedDateEmployee} = item;
        return {
          employeeComment,
          managerData,
          submittedDateEmployee,
        }
      });
      setCommentMessages(filteredComments);
      setActiveCommentIndex(null);
    }
  }, [reportingManagerList]);

  useEffect(() => {
    if (commentMessages && commentMessages.length > 0 && activeCommentIndex !== null) {
      const timer = setTimeout(() => {
        const { employeeComment, managerData, submittedDateEmployee } =
          commentMessages[activeCommentIndex];
        if (!submittedDateEmployee) {
          handleSubmitMessage(managerData, employeeComment);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [commentMessages, activeCommentIndex]);

  const handleCommentMessage = (index, value) => {
    const messages = commentMessages;
    messages[index].employeeComment = value;
    setCommentMessages([...messages]);
    setActiveCommentIndex(index);
  }

  return (
    <div className={style.multimanager_wrapper}>
      {show &&
        reportingManagerList?.map((item, index) => {
          const {
            managerData,
            questionAnswerListEmployee
          } = item;
          return (
            <div key={index} className={`card ${style.profile_card} ${index === 0 ? style.profile_card_top_radius : index === reportingManagerList.length - 1 && style.profile_card_bottom_radius}`}>
              <div className={`card_info ${style.profile_card_info}`}>
                <div className={`card-title ${style.profile_card_title}`}>
                  <h2>{MonthlyCheckinTexts.MONTHLY_CHECK_IN.replace(':', '')}</h2>
                  <Row className={style.details_wrapper}>
                    <Col lg="3" md="12" className="pr-0">
                      <h6>
                        <span className={style.greyText}>{period}&nbsp;{MonthlyCheckinTexts.CHECK_IN_PERIOD}&nbsp;</span>
                      </h6>
                    </Col>
                    <Col lg="5" md="12" className="pr-0">
                      <h6>
                        <span className={style.greyText}>{MonthlyCheckinTexts.SUBMISSION_DATE}&nbsp;</span>
                        {currentCheckIn}
                      </h6>
                    </Col>
                    <Col lg="4" md="12" className="pr-0">
                      <h6 className={style.empname_wrapper}>
                        <span className={style.greyText}>
                          {item?.employeeStatus === "PENDING_FOR_REVIEW" || item?.employeeStatus === "COMPLETED" && item?.submittedDateEmployee !== null ? MonthlyCheckinTexts.SUBMITTED_TO : MonthlyCheckinTexts.PENDING_TO}&nbsp;&nbsp;
                        </span>
                        <span>{managerData?.empName}</span>
                      </h6>
                    </Col>
                  </Row>
                </div>
                <div className="card_action">
                  <span data-test='handleAccordian' onClick={() => handleShow(index)}>
                    {!show[index] ? "Show" : "Hide"}
                  </span>
                </div>
              </div>
              <Collapse in={show[index]}>
                <div className="box-inner-pad box-light-gradient no-border">
                  <h6 className={style.note_text}>
                    {MonthlyCheckinTexts.NOTE}
                  </h6>
                  <div className={style.inner_container}>
                    <div className={style.questions_main_wrapper}>
                      <div className="box-heading-wrapper">
                        <div className={`box-heading ${style.inner_header}`}>
                          <h6>{MonthlyCheckinTexts.SUBMIT_YOUR_FEEDBACK}</h6>
                          <h6>
                            <span className={style.greyText}>{MonthlyCheckinTexts.QUESTIONS}</span>
                            &nbsp;&nbsp;{questionAnswerListEmployee?.length}
                          </h6>
                        </div>
                      </div>
                      <div className={`box-inner-pad box-light-gradient no-border ${style.question_inner_box}`}>
                        {questionAnswerListEmployee?.map((question, questionIndex) => {
                          const {
                            questionId,
                            questionTitle,
                            answer,
                            required
                          } = question;
                          return (
                            <div className={`row ${style.question_wrapper}`} key={questionIndex}>
                              <div className={`col-md-8 ${isMandatory && !isCleared && questionTitleIndex == index && answer === null ? style.question_text_error : style.question_text}`}>
                                {questionTitle}
                                {required && <span className="text-danger"> *</span>}
                              </div>
                              <div className="col-md-4">
                                <ul className={style.question_options_list}>
                                  <li>
                                    <div className="custom-checkbox custom-checkbox-rounded">
                                      <input
                                        id={`yes${questionId + index + questionIndex}`}
                                        data-test='redioYes'
                                        checked={
                                          answer === "YES"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleChangeResult(managerData, questionId, "YES")
                                        }
                                        type="radio"
                                        value={answer || ""}
                                        className={item?.employeeStatus === "PENDING_FOR_REVIEW" || item?.employeeStatus === "COMPLETED" && item?.submittedDateEmployee !== null && style.readonly}
                                      />
                                      <label
                                        htmlFor={`yes${questionId + index + questionIndex}`}
                                        className={item?.employeeStatus === "PENDING_FOR_REVIEW" || item?.employeeStatus === "COMPLETED" && item?.submittedDateEmployee !== null && style.readonly}>
                                        Yes
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-checkbox custom-checkbox-rounded">
                                      <input
                                        id={`no${questionId + index + questionIndex}`}
                                        data-test='redioNo'
                                        checked={
                                          answer === "NO"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleChangeResult(managerData, questionId, "NO")
                                        }
                                        type="radio"
                                        value={answer || ""}
                                        className={item?.employeeStatus === "PENDING_FOR_REVIEW" || item?.employeeStatus === "COMPLETED" && item?.submittedDateEmployee !== null && style.readonly}
                                      />
                                      <label htmlFor={`no${questionId + index + questionIndex}`}
                                        className={item?.employeeStatus === "PENDING_FOR_REVIEW" || item?.employeeStatus === "COMPLETED" && item?.submittedDateEmployee !== null && style.readonly}>
                                        No
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {item?.employeeStatus === "PENDING_FROM_MEMBER" && item?.submittedDateEmployee === null &&
                      <div className="view_comment_box_wrapper collapse show mt-0 mb-0">
                        <CommentsTop heading="Share your view(s)" />
                        <CommentTextArea
                          value={commentMessages[index].employeeComment|| ""}
                          maxLength={500}
                          changeHandle={(e) => handleCommentMessage(index, e.target.value)}
                          image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                          placeholder={"Please add your view(s) here"}
                        />
                      </div>}
                    {(item?.employeeStatus === "COMPLETED"  || item?.submittedDateManager !== null) && item?.submittedDateEmployee !== null && item?.employeeComment !== null && item?.employeeComment !== "" &&
                      <div className="view_comment_box_wrapper collapse show mt-0 mb-0">
                        <CommentsTop heading="Share your view(s)" />
                        <Comment
                          selectedColor={backGroundColor[1]}
                          image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                          empName={loggedInUser?.displayName}
                          commentedOn={item?.submittedDateEmployee}
                          comment={item?.employeeComment}
                        />
                      </div>}
                    {item?.employeeStatus === "PENDING_FROM_MEMBER" && item?.submittedDateEmployee === null &&
                      <div className={style.buttons_wrapper}>
                        <button
                          type="button"
                          data-test='submit'
                          onClick={() => handleSubmit(managerData,index)}
                          className={`btn btn-primary ${style.submit_button}`}>
                          <strong>{btnName.SUBMIT}</strong>
                        </button>
                        <button
                          type="button"
                          onClick={() => handleClear(managerData)}
                          className={`btn btn-secondary ${style.clear_button}`}>
                          <strong>{btnName.CLEAR}</strong>
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </Collapse>
            </div>
          );
        })}
    </div>
  );
}
