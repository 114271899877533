const defaultColors = ["#FF5479", "#CC54FF", "#54A1FF", "#F9C03C", "#13BB8D", "#5C59E3", "#75BA30"];

const graphColorList = [
  "#22C1AE",
  "#0061CA",
  "#FF8D24",
  "#FF5291",
  "#22C124",
  "#C933FF",
  "#FFC400",
  "#FB545E",
  "#00B7C8",
  "#FF8D24",
  "#C3C607",
  "#7033FF",
  "#FD8C61",
  "#FD6E3B", //Difficult
  "#00D7A1",
  "#7033FF",
  "#FB545E",
  "#FFA500", //Hard
  "#548FFB",
  "#CC0000", //Exhausted
  "#60B000", //Needs Attention
  "#71DCC9",
  "#FFFF00", //Demanding
  "#FF0000", //Nearly Exhausted
  "#FFC400",
  "#3CB371", //Reasonable
  "#FF9066",
  "#FD9F59", //Severe
  "#FD8C61",
  "#005E51",
  "#E72799",
  "#D99904",
  "#8E09BE",
  "#4C4B5E",
  "#8A6203",
  "#FFD700", //Bit Tough
  "#7033FF",
  "#FB545E",
  "#008B8B", //Smooth
];

export function getRandomColor(bgColor = defaultColors) {
  var item = bgColor[Math.floor(Math.random() * bgColor.length)];
  return item;
}
export function getColorByIndex(index, bgColor = defaultColors) {
  return bgColor[index % bgColor.length];
}

export function getGraphColor(data, index) {
  // eslint-disable-next-line no-undef
  const allValue = [...new Set(data.map((value) => value.name))];
  // let randomColorIndex = Math.floor(Math.random() * (graphColorList.length - allValue.length));
  let objMap = {};
  for (let i = 0; i < allValue.length; i++) {
    if (allValue[i] === "Unexplored") {
      objMap[allValue[i]] = "rgb(249 130 130)";
    } else if (allValue[i] === "Beginner") {
      objMap[allValue[i]] = "rgb(204 235 137)";
    } else if (allValue[i] === "Intermediate") {
      objMap[allValue[i]] = graphColorList[0];
    } else if (allValue[i] === "Mastered") {
      objMap[allValue[i]] = "rgb(34 121 193)";
    } else objMap[allValue[i]] = graphColorList[index + i];
  }
  return objMap;
}
