import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import style from "./RoleWiseUserList.module.scss";

function EditUserRolesmodal({ show, hide, handleEdit, roleOptions, assignee, roles, setRoles }) {
  return (
    <Modal show={show} onHide={hide} size="lg" animation={false} className="custom_modal_popup">
      <div className="custom_modal_container custom_lg_modal_container p-0">
        <Modal.Header className={style.mHeader}>
          <Modal.Title>
            Roles and Responsibilities
            <span>Edit roles for the following assignee.</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={style.mBody}>
          <div className="box-light-gradient"></div>
          <div className={style.modal_body_wrapper}>
            <div className="mb-4">
              <div className={style.dd_title}>
                Assignee: <span>{assignee?.label || "-"}</span>
              </div>
            </div>
            <div className="radio-wrapper d-flex dropdown-multiselect">
              <Form.Group controlId="">
                <Form.Label className={style.dd_title}>Select Roles</Form.Label>
                <div className={["tags_wrapper"].join(" ")}>
                  <MultiSelect
                    className="multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    options={roleOptions}
                    value={roles}
                    onChange={(e) => setRoles(e)}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={["custom_modal_button justify-content-start mt-0", style.mFooter].join(" ")}>
          <Button variant="primary" className="fs-500" onClick={() => handleEdit(assignee, roles)}>
            Edit
          </Button>
          <Button variant="secondary" className="fs-500" onClick={hide}>
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default EditUserRolesmodal;
