import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Container, Dropdown, DropdownButton, Nav, Navbar } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { MAIN_LOGO, FEEDBACK_ICON_2 } from "const/imgCost";
import AuthContext, { ActionType } from "context/authContext";
import * as CryptoJS from "crypto-js";
import storage from "util/storage";
import style from "./Header.module.scss";
import Navigation from "./Navigation/Navigation";
import Notification from "../Notification/Notification";
import MobileNavigation from "./MobileNavigation/MobileNavigation";
// import NoNotification from "../NoNotification/NoNotification";
import FeedbackPopup from "../Feedback/FeedbackPopup";
import AuthoriseFeedbackPopup from "../Feedback/AuthoriseFeedbackPopup";
import routePath from "const/routePath";
import { blinkMenu } from "const/constValue";
import * as actions from "store/actions/index";

export default function Header() {
  const context = useContext(AuthContext);
  const history = useHistory();
  const current_url = useLocation();
  const dispatch = useDispatch();
  const pageView = storage.getPageView();
  const [dialog, setDialog] = useState(false);
  const [authoriseFeedback, setAuthoriseFeedback] = useState(false);
  const quizData = storage.getJSON("employeeQuizData");
  const { viewQuizListOngoingCount } = useSelector((state) => state.campaign);
  
  useEffect(() => {
    let prev_url = window.sessionStorage.getItem("location");
    let flag_ongoing = window.sessionStorage.getItem("started");
    dispatch({ type: "IS_REDIRECT_POPUP", payload: false });
    if (prev_url && current_url.pathname !== prev_url && flag_ongoing) {
      dispatch({ type: "IS_BACK_DISABLE", payload: false });
      history.push(`/okrprogress/quiz/start-quiz/ongoing/${quizData?.campaignId}`);
      dispatch({ type: "IS_REDIRECT_POPUP", payload: true });
      window.sessionStorage.setItem("second_location", current_url.pathname);
    }
    window.sessionStorage.removeItem("started");
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("location", current_url.pathname);
  }, [current_url.pathname]);
  useEffect(() => {
    let throttle = false;
    document.getElementById("feedback").addEventListener("click", function (evt) {
      var o = this,
        ot = this.textContent;
      if (!throttle && evt.detail === 3) {
        setAuthoriseFeedback(true);
        this.textContent = "Triple-clicked!";
        throttle = true;
        setTimeout(function () {
          o.textContent = ot;
          throttle = false;
        }, 1000);
      }
    });
  });

  useEffect(() => {
    dispatch(actions.getQuizTemplateEmployeeViewOngoingData({ status: "Ongoing" }));
  }, []);

  useEffect(() => {
    if (viewQuizListOngoingCount > 0) {
      if (!blinkMenu.includes("Quiz")) {
        blinkMenu.push("Quiz");
      }
    } else if (viewQuizListOngoingCount === null) {
      if (blinkMenu.includes("Quiz")) {
        blinkMenu.pop("Quiz");
      }
    }

    return () => {
      if (blinkMenu.includes("Quiz")) {
        blinkMenu.pop("Quiz");
      }
    };
  }, [viewQuizListOngoingCount]);

  const onLogout = () => {
    storage.clear();
    dispatch({ type: "USER_LOGOUT" });
    context.dispatch({ type: ActionType.LOGOUT });
    history.push("/");
  };
  const feedbackToggle = () => {
    setDialog(!dialog);
  };
  const handlePageView = (data) => {
    let modules;
    if (data === "DEFAULT") {
      history.push(routePath.HOME);
      modules = storage.getDefaultMenu();
    } else {
      history.push(data.url || "/");
      modules = pageView.find((page) => page.pageView === data.pageView)?.modulesAndRights;
    }
    let moduleEncrypted = CryptoJS.AES.encrypt(JSON.stringify(modules), process.env.REACT_APP_CRYPTO_PRIVATE_KEY);
    if (modules?.length > 0) {
      storage.set("_MO_Vu__", moduleEncrypted.toString());
      context.dispatch({ type: ActionType.CHANGE_PAGE, payload: modules });
    }
  };
  let navHTML = "";
  if (context.state.isLoggedIn) {
    navHTML = (
      <>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Navigation />
          </Nav>
        </Navbar.Collapse> */}
        <Nav className="ml-auto">
          <span className={style.btn_feedback} onClick={feedbackToggle}>
            <ReactSVG src={FEEDBACK_ICON_2} title="" alt="" />
          </span>
          <Notification />
          <FeedbackPopup
            show={dialog}
            hide={() => {
              setDialog(false);
            }}
            submit={() => {
              setDialog(false);
            }}
          />
          <DropdownButton
            alignRight
            title={
              <div className={style.user_icon}>
                {context.state.user.displayPicture ? (
                  <img src={context.state.user.displayPicture} title="" alt="" />
                ) : (
                  context.state.user.empInitial
                )}
              </div>
            }
            id="dropdown-menu-align-right"
          >
            {pageView?.length > 0 && (
              <>
                <Dropdown.Item eventKey="default" onClick={() => handlePageView("DEFAULT")}>
                  My View
                </Dropdown.Item>
                {pageView.map((page, index) => (
                  <Dropdown.Item eventKey={index} onClick={() => handlePageView(page)} key={index}>
                    {page.pageView}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
              </>
            )}
            <Dropdown.Item eventKey="4" onClick={onLogout}>
              Sign Out
            </Dropdown.Item>
          </DropdownButton>
          <span id="feedback" className="invisible-feedback">
            Open Feedback
          </span>
          <AuthoriseFeedbackPopup
            show={authoriseFeedback}
            hide={() => {
              setAuthoriseFeedback(false);
            }}
            submit={() => {
              setAuthoriseFeedback(false);
            }}
          />
        </Nav>
      </>
    );
  }
  //remove sticky="top" from Navbar.
  return (
    <div>
      <Navbar expand="lg" className="navbar-dark">
        <Container>
          <MobileNavigation />
          <Navbar.Brand>
            <NavLink to={context.state.isLoggedIn ? "/" : ""} onClick={() => handlePageView("DEFAULT")} exact>
              <img src={MAIN_LOGO} title="" alt="" />
            </NavLink>
          </Navbar.Brand>
          {navHTML}
        </Container>
      </Navbar>
      <div className={style.main_navigation}>
        <Container>
          <Navigation />
        </Container>
      </div>
    </div>
  );
}
