import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import style from "./EditObjective/EditObjective.module.scss";

export default function ObjectiveInfoSideBar({ objective, type }) {
  const { createdDate, updatedDate, managerData, createdBy } = objective;
  return (
    <div>
      <div className={["box", "p_b_n", style.objective_right_box].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Other Details</h2>
          </div>
        </div>

        <div className={["box-inner-pad", "box-light-gradient", "pb-2", style.made_my_day_box].join(" ")}>
          <ul className="list-item-2info">
            <li>
              <h4>Owner</h4>
              <span>{type ? managerData && managerData.empName : createdBy && createdBy.empName}</span>
            </li>
            <li>
              <h4>Created On</h4>
              <span>{createdDate ? moment(createdDate).format("ddd D MMM h:mm a") : "-"}</span>
            </li>
            <li>
              <h4>Last Modified On</h4>
              <span>{updatedDate ? moment(updatedDate).format("ddd D MMM h:mm a") : "-"}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

ObjectiveInfoSideBar.propTypes = {
  objective: PropTypes.shape({
    createdDate: PropTypes.string,
    updatedDate: PropTypes.string,
    managerData: PropTypes.shape({
      empName: PropTypes.string,
    }),
    createdBy: PropTypes.shape({
      empName: PropTypes.string,
    }),
  }),
};

ObjectiveInfoSideBar.defaultProps = {
  objective: PropTypes.shape({
    createdDate: "",
    updatedDate: "",
    managerData: {
      empName: "",
    },
    createdBy: {
      empName: "",
    },
  }),
};
