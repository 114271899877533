import React from "react";
import style from "./TooltipText.module.scss";

function TooltipText({ len, array, tooltipWidth }) {
  return (
    <div className={[style.tooltip_wrapper].join(" ")}>
      <span>{array && array.join(", ").length > len ? array.join(", ").slice(0, len) + "..." : array.join(", ")}</span>
      <div className={style.tooltip_info} style={{ width: tooltipWidth }}>
        {array.join(", ")}
      </div>
    </div>
  );
}

TooltipText.defaultProps = {
  tooltipWidth: "280px",
};
export default TooltipText;
