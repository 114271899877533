import React from "react";
import Slider from "react-slick";
import { dateFormat1 } from "../../util/dateUtils";
import style from "./SliderText.module.scss";

function SliderText({ currentAndPreviousCheckin, swipeChange }) {
  const settings = {
    speed: 500,
    infinite: false,
    initialSlide: currentAndPreviousCheckin?.length,
    beforeChange: swipeChange,
  };

  return (
    <div className={style.slider_date_wrapper}>
      <span>Showing cycle wise data</span>
      <Slider {...settings}>
        {currentAndPreviousCheckin?.map((checkin, index) => (
          <div key={index}>
            <span>
              {dateFormat1(checkin.startDate)} - {dateFormat1(checkin.endDate)}
            </span>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SliderText;
