import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import style from "../TabHighlights.module.scss";
import { IC_LIGHT } from "const/imgCost";
import HideAndShow from "../../HideAndShow/HideAndShow";
import TransformingComment from "./TransformingComment";

export default function HighlightTransforming({ helpToGrowCommentList }) {
  const [hide, setHide] = useState(false);
  const totalComment = helpToGrowCommentList.length;
  return (
    <div>
      <div className="card transforming_better">
        <div className="card_info">
          <div className="card_title">
            <div className="d-flex">
              <ReactSVG src={`${IC_LIGHT}`} className="icon-svg" />
              <div className="card_title_text">
                Transforming Yourself Better <span>({totalComment})</span>
              </div>
            </div>
          </div>
          <HideAndShow hide={hide} setHide={setHide} />
        </div>
        {!hide && (
          <div className="card_details p20">
            <div className="box-light-gradient"></div>
            {totalComment > 0 ? (
              helpToGrowCommentList
                .filter((data, index) => index < 2)
                .map((data) => <TransformingComment data={data} key={"TRANSFORMATION_" + data.commentId} />)
            ) : (
              <div className={style.to_do_row}>
                <div className="noObjective">No data found</div>
              </div>
            )}

            {totalComment > 0 && (
              <Link to="" className={["viewall", "mt-0", "disabled"].join(" ")}>
                View all feedback
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
