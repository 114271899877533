import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";

function StartAndEndDatePicker({
  startDate,
  endDate,
  minDate,
  maxDate,
  handleStartDate,
  handleEndDate,
  click,
  error,
  disabled,
  minEndDate,
}) {
  return (
    <>
      <div className="calendar_wrapper">
        <ReactDatePicker
          disabled={disabled}
          className={[click && !startDate && error && "limit-reached", "form-control"].join(" ")}
          dateFormat="MMMM d, yyyy"
          placeholderText="Start Date"
          selected={startDate}
          minDate={minDate}
          maxDate={endDate}
          onChange={handleStartDate}
        />
      </div>
      <div className="calendar_wrapper">
        <ReactDatePicker
          disabled={disabled}
          className={[click && !endDate && error && "limit-reached", "form-control"].join(" ")}
          dateFormat="MMMM d, yyyy"
          placeholderText="End Date"
          selected={endDate}
          minDate={minEndDate || startDate}
          maxDate={maxDate}
          onChange={handleEndDate}
        />
      </div>
    </>
  );
}

StartAndEndDatePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  handleStartDate: PropTypes.func,
  handleEndDate: PropTypes.func,
  click: PropTypes.bool,
  error: PropTypes.bool,
};

export default StartAndEndDatePicker;
