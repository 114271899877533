import React, { useState, useEffect ,useMemo} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import style from "./TeamsObjectives.module.scss";
import * as actions from "../../../store/actions/index";
import { filterArray } from "../../../util/general";
import { backGroundColor, noData } from "../../../const/constValue";
import storage from "../../../util/storage";
import FilterTeam from "../../FilterTeam/FilterTeam";
import Pagination from "../../Pagination/Pagination";
import TeamRows from "./TeamRows";
import NoDataFound from "../../NodataFound/NoDataFound";
import ConfirmationPopup from "../ConfirmationPopup";
import OKRDetailsPopup from "./OKRDetailsPopup/OKRDetailsPopup";
import Spinner from "../../../shared/Spinner/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { threeDotIcon } from "const/imgCost";
import { ReactSVG } from "react-svg";

const TeamsObjectives = (props) => {
  const loggedInUser = storage.getUser();
  const dispatch = useDispatch();
  const [pageOfItems, setPageOfItems] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [projectStatusFilter, setProjectStatusFilter] = useState("");
  const [display, setDisplay] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [popupTeam, setPopupTeam] = useState();
  const [openPopup, setOpenPopup] = useState(true);
  const pageSize = useSelector((state) => state.myTeam.pageSize);
  const [id, setId] = useState(null);
  const filterValue = props.location.state?.filterValue || "";
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);
  const {
    teamObjectiveData,
    teamObjectiveLoading,
    teamObjectiveDeleteLoading,
  } = useSelector((state) => state.objectives);

  useEffect(() => {
    dispatch(actions.GetTeamObjectiveData());
    dispatch({ type: "CLEAR_OBJECTIVE_DETAILS" });
  }, [dispatch]);

  useEffect(() => {
    if (filterValue === "false") {
      handleStatusChange("false");
    }
  }, [teamObjectiveData]);


  useEffect(() => {
    if (teamObjectiveData.length > 0) {
      filteredData.length > 0
        ? setTeamData(filteredData)
        : statusFilter || searchFilter || projectStatusFilter?.value
        ? setTeamData(filteredData)
        : setTeamData(
            filterValue === "true"
              ? teamObjectiveData
                  .filter((employee) => {
                    if (
                      employee?.userObjectives?.find(
                        (objective) => objective?.status === "PENDING_APPROVAL"
                      )
                    ) {
                      return true;
                    } else return false;
                  })
                  .map((employee) => {
                    return {
                      ...employee,
                      userObjectives: employee?.userObjectives.filter(
                        (objective) => objective?.status === "PENDING_APPROVAL"
                      ),
                    };
                  })
              : teamObjectiveData
          );
    }
  }, [filteredData, teamObjectiveData]);

  useEffect(() => {
    const filteredTeamData = teamObjectiveData?.filter((value) => value.totalWeightageOfManager < 100);
    setPopupTeam(filteredTeamData);
  }, [teamObjectiveData]);

  const search = (value) => {
    let filteredData = null;
    const filters = {
      empName: value,
      assigned: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(teamObjectiveData, filters);
    setFilteredData(filteredData);
    setSearchFilter(value);
  };

  const handleStatusChange = (value) => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      assigned: value,
      isOnBench:projectStatusFilter?.value || "",
    };
    filteredData = filterArray(teamObjectiveData, filters);
    setStatusFilter(value);
    setFilteredData(filteredData);
  };
  const handleProjectStatus = (data) => {
    const ProjectStatusFilter = data; 
    const StatusFilters = {
    empName: searchFilter ? searchFilter : "",
    assigned: statusFilter || "",
    isOnBench: ProjectStatusFilter.value,
  };
  const newFilteredData = filterArray(teamObjectiveData, StatusFilters);
  setProjectStatusFilter(ProjectStatusFilter);
  setFilteredData(newFilteredData);
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      assigned: statusFilter ? statusFilter : "",
      isOnBench: projectStatusFilter?.value ? projectStatusFilter?.value : "" ,
    };
    filteredData = filterArray(teamObjectiveData, filters);
    setFilteredData(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter(searchFilter);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
      assigned: statusFilter ? statusFilter : "",
      isOnBench: projectStatusFilter?.value ? projectStatusFilter?.value : "" ,
    };
    filteredData = filterArray(teamObjectiveData, filters);
    setFilteredData(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter("");
  };

  const deleteObjective = (objectiveId, employeeId, pgId, sgId) => {
    setDisplay(true);
    setId({
      objectiveId,
      employeeIds: {
        employeeId,
        parentGroupId: pgId,
        subGroupId: sgId,
      },
    });
  };

  const ConfirmDeleteObjective = () => {
    dispatch(
      actions.DeleteUserObjective(
        id.objectiveId,
        id.employeeIds,
        "TEAMS_OBJECTIVE"
      )
    );
    setDisplay(false);
  };

  const filter = {
    value: ["", "true", "false"],
    name: ["All", "Assigned", "Not Assigned"],
  }; 
  const showBenchFilter = useMemo(()=>teamObjectiveData.some((item) => item.isOnBench === true),[teamObjectiveData]);
  
  return (
    <div>
      <SubNavigation type="Objectives" />
      {teamObjectiveDeleteLoading && <Spinner />}
      {teamObjectiveLoading ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col md={12} className="col-12">
              <h1 className="page-title pb-0">Objectives</h1>
              <span className="st-breadcrumb">
                Objectives
                <span>Team&apos;s Objectives</span>
              </span>
            </Col>
          </Row>
          <div className="box pt-0">
            {teamObjectiveData && (
              <FilterTeam
                placeholder="Search for team members"
                filter={filter}
                filterValue={filterValue}
                value={searchFilter ? searchFilter : ""}
                teamData={teamObjectiveData}
                onSearch={(e) => search(e.target.value)}
                onRadioChange={(e) => handleStatusChange(e.target.value)}
                onResetSearch={resetSearch}
                showBenchFilter={showBenchFilter}
                projectStatusFilter={projectStatusFilter}
                onProjectStatusChange={handleProjectStatus}
              />
            )}
            <div className="box-inner-pad box-light-gradient border-bottom-0 pb-0">
              <div className={["box", style.noteBox].join(" ")}>
                <span>Note: &nbsp; </span>
                <span className={style.noteTextArea}>
                  Action button (
                  <ReactSVG
                    src={`${threeDotIcon}`}
                    alt=""
                    className={style.noteBoxImg}
                  />
                  ) will be disable if you have already assigned 100% OKR to a
                  member.
                </span>
              </div>
              <div className={style.teamsobjective__outer_wrapper}>
                {pageOfItems && pageOfItems.length > 0 ? (
                  <div className={style.teamsobjective_wrapper}>
                    <ul className={style.tHead}>
                      <li className={style.plus_space}></li>
                      <li className={style.name}>Name</li>
                      <li className={style.objective_status}>
                        Objectives Status
                      </li>
                      <li className={style.status}>Status</li>
                      <li className={style.progress}>Progress</li>
                      <li className={style.action}></li>
                    </ul>
                    <ul className={style.tBody} id="team-objective-data">
                      {pageOfItems &&
                        pageOfItems.map((teamRow, index) => (
                          <TeamRows
                            selectedColor={
                              backGroundColor[index % backGroundColor.length]
                            }
                            key={"TEAM_ROW_" + teamRow.employeeId + index}
                            teamRow={teamRow}
                            isPage={"MANAGER"}
                            deleteObjective={deleteObjective}
                            loginEmployee={loggedInUser?.employeeId}
                          />
                        ))}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap40"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Pagination
            pageSize={pageSize}
            items={teamData}
            resetData={resetData}
            onChangePage={onChangePage}
          />
        </Container>
      )}
      {popupTeam && popupTeam.length > 0 && (
        <OKRDetailsPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          teamData={popupTeam}
        />
      )}
      <ConfirmationPopup
        show={display}
        submit={() => ConfirmDeleteObjective()}
        hide={() => setDisplay(false)}
        headerText="Sure you want to delete this Objective?"
        subText="Since the objective has weightage attached to it, we advise altering it. You cannot undo a deletion once it has been made."
      />
    </div>
  );
};

export default TeamsObjectives;
