import React, { useState } from "react";

const SingleComponent = props => {
  const [toggle, setToggle] = useState(true);
  const toggleText = (id, comment) => {
    if (toggle) {
      document.getElementById("comment " + id).innerText = comment;
      document.getElementById("toggleButton " + id).innerText = " (View Less)";
      setToggle(false);
    } else {
      document.getElementById(
        "comment " + id
      ).innerText = comment.slice(0, 250);
      document.getElementById("toggleButton " + id).innerText = " (View More)";
      setToggle(true);
    }
  };
  return (
    <>
        {props.comment &&   <li id={["textArea", props.id].join(" ")}><span id={["comment", props.id].join(" ")}>{props.comment.slice(0, 250)}</span>
         <span
          className="link"
          id={["toggleButton", props.id].join(" ")}
          onClick={() => toggleText(props.id, props.comment)}>
            (View More)
          </span></li>}
    </>
  );
};

export default SingleComponent;
