import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'

function PreviouslyHeldOneOnOne({data, employeeId}) {
  let length = data && data.length > 0 && data.length
  
  return (
    <div className={["box"].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Previously Held 1-on-1s</h2>
        </div>
      </div>
      <div className="box-inner-pad box-light-gradient pb-0">
          <ul className="list-item-2info">
            {data && data.length > 0 ? data.filter((value,index) => index < 2).map(value => 
              <li key={value.oneOnOnAgendaId}>
                <h4>{moment(value.meetingDate).format("MMM DD, YYYY")}</h4>
                <span>{value.fromTime} - {value.toTime}</span>
              </li>): <li className="no-1-on-1">No 1-on-1 found!</li>}
          </ul>
          {length > 2 && <Link className="fs-500" to={`/okrprogress/one-on-one/${employeeId}`}>View All</Link>}
      </div>
    </div>            
  )
}

PreviouslyHeldOneOnOne.propTypes = {
  data: PropTypes.array, 
  employeeId: PropTypes.string
}

export default PreviouslyHeldOneOnOne

