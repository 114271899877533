import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CommentTextArea from "components/CommentBox/CommentTextArea";
import CommentsTop from "components/CommentBox/CommentsTop";
import Comment from "components/CommentBox/Comment";
import storage from "util/storage";
import style from "./SingleManagerWrapper.module.scss";
import { MonthlyCheckinTexts, btnName, backGroundColor } from "../../../../const/constValue";

export default function SingleManagerWrapper({ 
  reportingManagerList, 
  period,
  currentCheckIn,
  isCleared,
  isMandatory, 
  handleChangeResult,
  handleSubmitMessage,
  handleSubmit,
  handleClear }) {
  const loggedInUser = storage.getUser();
  const [comment, setComment] = useState(reportingManagerList[0]?.employeeComment);
  const [isCommentModified, setIsCommentModified] = useState(false);

  useEffect(() => {
    if(isCommentModified){
      const timer = setTimeout(()=> {
        handleSubmitMessage(reportingManagerList[0]?.managerData, comment);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [comment, isCommentModified]);

  useEffect(()=> {
    if(isCleared && !reportingManagerList[0]?.employeeComment){
      setComment("");
      setIsCommentModified(false);
    }
  }, [isCleared, reportingManagerList]);

  const handleChangeComment = (value) => {
    setComment(value);
    if(!isCommentModified){
      setIsCommentModified(true);
    }
  }

  return (
    <div className={`box reviewer-profile-box `}>
      <div className="box-heading-wrapper pb-1">
        <div className="box-heading">
          <h2>{MonthlyCheckinTexts.MONTHLY_CHECK_IN.replace(':', '')}</h2>
          <Row className={style.details_wrapper}>
            <Col lg="3" md="6" className="pr-0">
              <h6>
                <span className={style.greyText}>{period}&nbsp;{MonthlyCheckinTexts.CHECK_IN_PERIOD}&nbsp;</span>
              </h6>
            </Col>
            <Col lg="5" md="6" className="pr-0">
              <h6>
                <span className={style.greyText}>{MonthlyCheckinTexts.SUBMISSION_DATE}&nbsp;</span>
                {currentCheckIn}
              </h6>
            </Col>
            <Col lg="4" md="6" className="pr-0 pl-0">
              <h6 className={style.empname_wrapper}>
                <span className={style.greyText}>
                  {reportingManagerList[0]?.employeeStatus === "PENDING_FOR_REVIEW" || reportingManagerList[0]?.employeeStatus === "COMPLETED" && reportingManagerList[0]?.submittedDateEmployee !== null ? MonthlyCheckinTexts.SUBMITTED_TO : MonthlyCheckinTexts.PENDING_TO}&nbsp;
                  </span>
                <span>{reportingManagerList[0]?.managerData?.empName}</span>
              </h6>
            </Col>
          </Row>
        </div>
      </div>
      <div className="box-inner-pad box-light-gradient no-border">
        <h6 className={style.note_text}>
          {MonthlyCheckinTexts.NOTE}
        </h6>
        <div className={style.inner_container}>
          <div className={style.questions_main_wrapper}>
            <div className="box-heading-wrapper">
              <div className={`box-heading ${style.inner_header}`}>
                <h6>{MonthlyCheckinTexts.SUBMIT_YOUR_FEEDBACK}</h6>
                <h6>
                  <span className={style.greyText}>{MonthlyCheckinTexts.QUESTIONS}</span>
                  &nbsp;&nbsp;{reportingManagerList[0]?.questionAnswerListEmployee?.length}
                </h6>
              </div>
            </div>
            <div className="box-inner-pad box-light-gradient no-border">
              {reportingManagerList[0]?.questionAnswerListEmployee?.map((item, index) => {
                const {
                  questionId,
                  questionTitle,
                  answer,
                  required
                } = item;
                return (
                  <div className={`row ${style.question_wrapper}`} key={questionId}>
                    <div className={`col-md-8 ${isMandatory && !isCleared && answer === null ? style.question_text_error : style.question_text}`}>
                      {questionTitle}
                      {required && <span className="text-danger"> *</span>}
                    </div>
                    <div className="col-md-4">
                      <ul className={style.question_options_list}>
                        <li>
                          <div className="custom-checkbox custom-checkbox-rounded">
                            <input
                              id={`yes${index}`}
                              data-test='redioYes'
                              checked={
                                answer === "YES" ? true : false
                              }
                              onChange={() => handleChangeResult(reportingManagerList[0]?.managerData,questionId, "YES")}
                              type="radio"
                              value={answer}
                              className={reportingManagerList[0]?.employeeStatus === "PENDING_FOR_REVIEW" || reportingManagerList[0]?.employeeStatus === "COMPLETED" && reportingManagerList[0]?.submittedDateEmployee !== null && style.readonly}
                            />
                            <label
                              htmlFor={`yes${index}`}
                              className={reportingManagerList[0]?.employeeStatus === "PENDING_FOR_REVIEW" || reportingManagerList[0]?.employeeStatus === "COMPLETED" && reportingManagerList[0]?.submittedDateEmployee !== null && style.readonly}>
                              Yes
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-checkbox custom-checkbox-rounded">
                            <input
                              id={`no${index}`}
                              data-test='redioNo'
                              checked={
                                answer === "NO" ? true : false
                              }
                              onChange={() => handleChangeResult(reportingManagerList[0].managerData,questionId, "NO")}
                              type="radio"
                              value={answer}
                              className={reportingManagerList[0]?.employeeStatus === "PENDING_FOR_REVIEW" || reportingManagerList[0]?.employeeStatus === "COMPLETED" && reportingManagerList[0]?.submittedDateEmployee !== null && style.readonly}
                            />
                            <label
                              htmlFor={`no${index}`}
                              className={reportingManagerList[0]?.employeeStatus === "PENDING_FOR_REVIEW" || reportingManagerList[0]?.employeeStatus === "COMPLETED" && reportingManagerList[0]?.submittedDateEmployee !== null && style.readonly}>
                              No
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {reportingManagerList[0]?.employeeStatus === "PENDING_FROM_MEMBER" && reportingManagerList[0]?.submittedDateEmployee === null &&
            <div className="view_comment_box_wrapper collapse show mt-0 mb-0">
              <CommentsTop heading="Share your view(s)" />
              <CommentTextArea
                value={comment || ""}
                maxLength={500}
                changeHandle={(e) => handleChangeComment(e?.target?.value)}
                image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                placeholder={"Please add your view(s) here"}
              />
            </div>}
          {(reportingManagerList[0]?.employeeStatus === "COMPLETED" || reportingManagerList[0]?.submittedDateManager !== null) && reportingManagerList[0]?.submittedDateEmployee !== null && reportingManagerList[0]?.employeeComment !== null && reportingManagerList[0]?.employeeComment !== "" &&
            <div className="view_comment_box_wrapper collapse show mt-0 mb-0">
              <CommentsTop heading="Share your view(s)" />
              <Comment
                selectedColor={backGroundColor[1]}
                image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                empName={loggedInUser?.displayName}
                commentedOn={reportingManagerList[0]?.submittedDateEmployee}
                comment={reportingManagerList[0]?.employeeComment}
              />
            </div>
            }
          {reportingManagerList[0]?.employeeStatus === "PENDING_FROM_MEMBER" && reportingManagerList[0]?.submittedDateEmployee === null &&
            <div className={style.buttons_wrapper}>
              <button
                type="button"
                data-test='submit'
                onClick={() => handleSubmit(reportingManagerList[0]?.managerData)}
                className={`btn btn-primary ${style.submit_button}`}
              >
                <strong>{btnName.SUBMIT}</strong>
              </button>
              <button
                type="button"
                onClick={() => handleClear(reportingManagerList[0]?.managerData)}
                className={`btn btn-secondary ${style.clear_button}`}
              >
                <strong>{btnName.CLEAR}</strong>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
