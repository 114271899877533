import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import style from "./AssigneePopup.module.scss";
import { IC_INPUT_SEARCH } from "../../../../const/imgCost";
import TeamRow from "./TeamRow";
import * as actions from "../../../../store/actions/index";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { filterArray } from "../../../../util/general";
import storage from "util/storage";
import NoDataFound from "../../../NodataFound/NoDataFound";
import { noData, placeHolder } from "const/constValue";

const AssigneePopup = (props) => {
  let [bands, setBands] = useState();
  let [teamData, setTeamData] = useState();

  useEffect(() => {
    const allBands = [...props.band];
    allBands.push({ label: "ALL", value: "ALL", id: 0 });
    setBands(allBands.sort((a, b) => a.id - b.id));
  }, [props.band]);

  useEffect(() => {
    if(props.projectStatusFilter === "" || props.projectStatusFilter.value === ""){
      setTeamData(props.team);
    } else if(props.projectStatusFilter.value === "true"){
      let filterAllocation = props.team.filter((data) => data.isOnBench === true);
      setTeamData(filterAllocation);
    } else if(props.projectStatusFilter.value === "false"){
      let filterAllocation = props.team.filter((data) => data.isOnBench === false);
      setTeamData(filterAllocation);
    }
  }, [props.team || props.projectStatusFilter]);
  /* select all issue fix */

  const dispatch = useDispatch();
  const saveAssignees = () => {
    // setTeam(props.teamData)
    props.resetSearch();
    // if (props.team.length !== props.teamData.length)
    //   dispatch(actions.SetAssigneeTeam(props.team));
    // else dispatch(actions.SetAssigneeTeam(props.team));
    let filterNotAssigned = props.teamData.filter((data) => data.objectiveAssigned === false);
    dispatch(actions.SetAssigneeTeam(filterNotAssigned));
    // const updatedAssignees = selectAllEmployees ? team : props.teamData;
    // dispatch(actions.SetAssigneeTeam(updatedAssignees));

    props.setOpenPopup(false);
  };

  let className = "form-control-rounded form-search-item";
  if (props.searchFilter.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }



  // const handleBandChange = (item) => {
  //   let filteredData = null;
  //   const filters = {
  //     empName: searchFilter ? searchFilter : "",
  //     band: item.value === "ALL" ? "" : item.value,
  //   };
  //   if (item.value !== "ALL" && selectedBand !== "") {
  //     filteredData = props?.teamData?.filter(
  //       (value) => value.band === item.value
  //     );
  //   } else {
  //     filteredData = props?.teamData;
  //   }
  //   filteredData = filterArray(filteredData, filters);
  //   filteredData = filteredData?.filter(
  //     (value) => 100 - value.totalWeightageOfManager >= props.objectiveWeightag
  //   );
  //   setTeam(filteredData);
  //   setSelectedBand(item.value);
  // };
/* select all issue fix starts*/
  const selectedOption = storage.get("selectedOption") || undefined;

  const dropdownOptions1 = [
    { value: selectedOption === "undefined" ? "" : selectedOption === "false" ? "false" : "true", label: selectedOption === "undefined" ? "ALL" : selectedOption === "false" ? "Project" : "OnBench" },
  ];
  /* select all issue fix end*/

  const dropdownOptions = [
    { value: "", label: "All" },
    { value: "false", label: "Project" },
    { value: "true", label: "OnBench" },
  ];



  return (
    <>
      <Modal
        size="lg"
        show={props.openPopup}
        onHide={() => props.setOpenPopup(true)}
        animation={false}
        className={style.assigneepopup_wrapper}
      >
        <div className={style.modal_header}>
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-md-12 col-12">
              <h1>Team Members ({teamData && teamData.length})</h1>
              <h2>Click on a member name to mark/unmark</h2>
            </div>
          </div>
        </div>
        <Modal.Body>
          <div className="box-light-gradient"></div>
          <div className={style.filterRow}>
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className={style.search_bar}>
                <Form className={style.allobjective_searchbar}>
                  <Form.Group controlId="" className="form-postfix mb-0 w-100">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder={placeHolder.SEARCH_TEAM}
                      name="searchBox"
                      className={className}
                      onChange={props.search}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <Button
                      className="close-button"
                      onClick={props.resetSearch}
                      type="reset"
                    >
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className={style.search_bar}>
                <Form className={style.allobjective_searchbar}>
                  <Form.Group controlId="" className="form-postfix mb-0 w-100">
                    <div className="radio-wrapper d-flex dropdown-multiselect">
                      <div className="tags_wrapper">
                        <Select
                          options={dropdownOptions}
                          isSearchable={false}
                          className="custom-default-dd-box"
                          classNamePrefix="select"
                          placeholder="Allocation Status"
                          // value={{label : selectedBand === "" ? "ALL" : selectedBand || ""}}
                          defaultValue={props.projectStatusFilter || dropdownOptions1[0]}
                          onChange={props.onProjectStatusChange}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          <div className={["box", style.noteBox].join(" ")}>
            <span>Note: &nbsp; </span>
            <span>
              {
                "Showing list of eligible employee whose summation of OKR weightage is < 100."
              }
            </span>
          </div>
          <div className="col-xl-12">
            <div className={style.data_container}>
              {teamData && teamData.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                    <td className={style.selectAll}>
                        <input
                          type="checkbox"
                          checked={props.selectAllEmployees}
                          disabled={props.selectAllDisable}
                          onChange={props.selectAll}
                          className={style.checkSelectAll}
                        />
                         All
                       </td>
                      <td className={style.name}>Name</td>
                      <td className={style.band}>Band</td>
                      <td className={style.objective_assigned}>
                        {" "}
                        Objective Assigned
                      </td>
                      <td className={style.status}>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {teamData.map((value, index) => (
                      <TeamRow
                        index={index}
                        closePopup={props.openPopup}
                        setTeam={props.setTeam}
                        teamData={teamData}
                        team={value}
                        key={"TEAM_" + value.employeeId}
                        checkBoxClick={props.checkBoxClick}
                        setCheckBoxClick={props.setCheckBoxClick}
                        selectAllDisable={props.selectAllDisable}
                        selectAllEmployees={props.selectAllEmployees} 
                        setSelectAllEmployees={props.setSelectAllEmployees} 
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.assigned_modal_footer}>
          <Button
            type="button"
            variant={"primary"}
            className={["btn-round", style.btn_save].join(" ")}
            onClick={saveAssignees}
          >
            <strong>Select</strong>
          </Button>
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_cancel].join(" ")}
            onClick={() => {
              props.resetSearch();
              props.setOpenPopup(false);
            }}
          >
            <strong>Close</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssigneePopup;
