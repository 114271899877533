import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import style from "../home/home.module.scss";
import * as actions from "store/actions/index";
import { scrollToTop } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import ReviewerProfile from "components/reviewer-profile/reviewer-profile";
import UserProfile from "components/user_profile/user_profile";
import Rewards from "components/Rewards/Rewards";
import CheckInActivity from "components/TabEngagement/CheckInActivity/CheckInActivity";
import WinChallenges from "components/WinsChallenges/WinsChallenges";
import config from "util/config";

export default function ReporteeDashboard(props) {
  const id = props.match.params.id;
  const employeeIds = {
    employeeId: id.split(config.employeeIdSplit)[0],
    parentGroupId: id.split(config.employeeIdSplit)[1],
    subGroupId: id.split(config.employeeIdSplit)[2],
  };
  const dispatch = useDispatch();
  const [filterSurveyDataMonth, setFilterSurveyDataMonth] = useState(6);
  const { reviewer } = useSelector((state) => state.teamHistory);
  const { filterSurveyData, checkinStatusForEmployee, managerCheckinStatusForEmployee } = useSelector(
    (state) => state.myHistory
  );
  const count = useSelector((state) => state.madeMyDay.appreciateCount);
  const { user, isLoading } = useSelector((state) => state.myTeam);
  const { streak } = useSelector((state) => state.checkIn);

  const handleAppreciateDuration = (select) => {
    dispatch(actions.GetAppreciateDurationCount(employeeIds, select.value));
  };

  const handleSurveyDataFilter = (select) => {
    setFilterSurveyDataMonth(select.value);
    dispatch(actions.GetFilterSurveyHistory({ ...employeeIds, filterByMonth: select.value }));
  };

  useEffect(() => {
    scrollToTop(500);
    dispatch(actions.GetFilterSurveyHistory({ ...employeeIds, filterByMonth: 6 }));
    dispatch(actions.GetMemberReviewer(employeeIds));
    dispatch(actions.GetAppreciateDurationCount(employeeIds, 6)); //bydefault show 6 months data
    dispatch({ type: "CLEAR_MY_TEAM_DATA" });
    dispatch(actions.GetUser(employeeIds));
    dispatch(actions.GetStreak(employeeIds));
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="gap30"></div>
      {
        /* showAlert */ false && (
          <Container>
            <Alert className="alert_welcome d-none" onClose={() => {}} variant="primary" dismissible>
              <h2 className="title">Welcome to your new homepage [[username]]</h2>
              <p>
                A new way to get rich insights from your okr progress data in a single place. You can quickly see your
                highest leverage to-dos for the two weeks and monitor how you’re doing and feeling at [[CompanyName]].
                You’ll also find new sections designed to unlock your potential and help you on your journey to being
                your best-self at work.
              </p>
              <Link to="/">Tell me more about homepage and its features </Link>
            </Alert>
          </Container>
        )
      }
      <Container>
        <Row>
          <Col lg={8}>
            {user && <UserProfile user={user} />}
            <div
              className={["custom-tabs", "custom-tabs-full-width", "tab-content-dashboard", style.home_tabs].join(" ")}
            >
              <Tabs defaultActiveKey="Engagements" id="">
                <Tab eventKey="Engagements" className="p30" title="Engagements">
                  <CheckInActivity
                    isPage="Reportee"
                    employeeId={employeeIds.employeeId}
                    parentGroupId={employeeIds.parentGroupId}
                    subGroupId={employeeIds.subGroupId}
                    surveyData={filterSurveyData}
                    streak={streak}
                    handleSurveyDataFilter={handleSurveyDataFilter}
                    filterSurveyDataMonth={filterSurveyDataMonth}
                    checkinStatusForEmployee={checkinStatusForEmployee}
                    managerCheckinStatusForEmployee={managerCheckinStatusForEmployee}
                  />
                </Tab>
                <Tab eventKey="Wins &amp; Challenges" title="Wins &amp; Challenges">
                  <WinChallenges employeeIds={employeeIds} isPage="myteam" />
                </Tab>
              </Tabs>
            </div>
          </Col>
          <Col lg={4}>
            {count && <Rewards count={count} handleAppreciateDuration={handleAppreciateDuration} />}
            {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
