import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Table } from "react-bootstrap";
import { menuText, noData } from "const/constValue";
import * as actions from "store/actions/index";
import FilterTeam from "components/FilterTeam/FilterTeam";
import NoDataFound from "components/NodataFound/NoDataFound";
import OneOnOneMemberRow from "./OneOnOneMemberRow";
import Pagination from "shared/Pagination/Pagination";
import style from "./OneOnOne.module.scss";
import routePath from "const/routePath";

function OneOnOneMemberTable({ hrOneOnOneFilter }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState(null);
  const { expanded } = useSelector((state) => state.myTeam);
  const { oneOnOneTeamList, employeeCount } = useSelector((state) => state.oneOnOne);

  useEffect(() => {
    getEmployeeList();
  }, [dispatch]);

  const getEmployeeList = (data) => {
    dispatch(actions.GetEmployeeListForHROneOnOne({ ...hrOneOnOneFilter, ...data }));
  };

  const search = (event) => {
    setSearchFilter(event.target.value);
  };

  const resetSearch = () => {
    setSearchFilter("");
    getEmployeeList({ name: "" });
    dispatch({ type: "CLEAR_PAGINATION_DATA" });
  };

  const searchMember = (event) => {
    getEmployeeList({ name: event.target.value });
    dispatch({ type: "CLEAR_PAGINATION_DATA" });
  };

  const onChangePage = (currentPage, pageSize) => {
    getEmployeeList({ currentPage, pageSize, name: searchFilter });
  };

  const renderUniqEmployee = (data) => {
    let checkId = [];
    let array = [];
    //modify array
    data.forEach((value) => {
      if (checkId.includes(value.employeeId)) {
        array = array.map((arrayValue) => {
          if (arrayValue.employeeId === value.employeeId) {
            return {
              ...arrayValue,
              oneOnOneList: [...arrayValue.oneOnOneList, value],
            };
          }
          return arrayValue;
        });
      } else {
        checkId.push(value.employeeId);
        let newObj = {
          ...value,
          oneOnOneList: [value],
        };
        array.push(newObj);
      }
    });

    return array.map((value) => {
      return (
        <OneOnOneMemberRow
          key={value.employeeId}
          isSubTeam={true}
          toggleExpander={() => {}}
          image={value.displayPicture || value.empInitial}
          name={value.empName}
          designation={value.designation}
          department={value.department}
          pattern={value.oneOnOneList || []}
          handlePatternClick={(id) => history.push(`${routePath.HR_ONE_ON_ONE}/${id}`)}
        />
      );
    });
  };

  function toggleExpander(empId) {
    expanded === empId
      ? dispatch({ type: "EXPANDED", expanded: null })
      : dispatch({ type: "EXPANDED", expanded: empId });
  }

  return (
    <div>
      <div className="box pb-0 mb-0">
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>{menuText.ONE_ON_ONE}</h2>
          </div>
        </div>
        <FilterTeam
          placeholder="Search for team members"
          filter={[]}
          value={searchFilter ? searchFilter : ""}
          teamData={[]}
          onSearch={search}
          searchMember={searchMember}
          onResetSearch={resetSearch}
        />
        <div className="box-light-gradient"></div>
        <div>
          <Col xs={12}>
            <div className="custom-table table-theme-1 pt40">
              {oneOnOneTeamList && oneOnOneTeamList.length > 0 ? (
                <Table hover responsive className={style.tab_table_responsive}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>{menuText.ONE_ON_ONE} Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {oneOnOneTeamList.map((value, index) => (
                      <React.Fragment key={value.managerId}>
                        <OneOnOneMemberRow
                          showExpand={true}
                          index={index}
                          isExpanded={expanded === value.managerId}
                          toggleExpander={() => toggleExpander(value.managerId)}
                          image={value.displayPicture || value.empInitial}
                          name={value.empName}
                          designation={value.designation}
                          department={value.department}
                        />

                        {expanded === value.managerId && renderUniqEmployee(value.reportees)}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <>
                  <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                  <div className="gap80"></div>
                </>
              )}
            </div>
          </Col>
        </div>
      </div>
      {employeeCount > 0 && <Pagination totalItem={employeeCount} onChangePage={onChangePage} />}
    </div>
  );
}

export default React.memo(OneOnOneMemberTable);
