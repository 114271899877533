import React from "react";
import style from "./AssignObjectives.module.scss";
import { keyValue, keyValueAchieved } from "../../../util/general";
import PropTypes from "prop-types";

export default function KeyResult({ keyPoint, assign }) {
  const { description, percentage, weightage, stretch } = keyPoint;
  return (
    <div className={[style.keyresult_wrapper, "text-break"].join(" ")}>
      <div className={style.keyresult_item}>
        <p className={style.key_description}>{description}</p>
        {assign ? (
          <div className={style.percentage_weightage_wrapper}>
            <span className={style.key_percentage}>{percentage}%</span>
            <span className={style.weightage}>Weightage: {weightage}</span>
          </div>
        ) : (
          <div className={style.percentage_weightage_wrapper}>
            <span className={style.key_percentage} style={{opacity : 0, visibility: "hidden"}}>%</span>
            <span className={style.weightage}>Weightage: {weightage}</span>
          </div>
        )}
        <div className={[style.key_values, style.Key_n_value].join(" ")}>Key Value Assigned : {keyValue(keyPoint)}</div>
        {assign && (
          <div className={[style.key_values, style.Key_n_value].join(" ")}>
            Key Value achieved : {keyValueAchieved(keyPoint)}
          </div>
        )}
        {stretch && (
          <div className={[style.key_values, style.Key_n_value].join(" ")}>This is the stretch key result</div>
        )}
      </div>
    </div>
  );
}

KeyResult.propTypes = {
  assign: PropTypes.bool,
  keyPoint: PropTypes.shape({
    description: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
};

KeyResult.defaultProps = {
  assign: false,
  keyPoint: PropTypes.shape({
    description: "",
    percentage: 0,
  }),
};
