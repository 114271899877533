import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import { dateFormat1 } from "../../../util/dateUtils";

function CurrentOneOnOneRow(props) {
  const { createOn, scheduleOn, createdBy, id } = props;
  return (
    <tr>
      <td className={style.w_50}>
        <Link className={style.descriptive_date} to={`/okrprogress/one-on-one/current/${id}`}>
          {dateFormat1(createOn)}
        </Link>
      </td>
      <td className={style.w_25}>
        <span className={style.descriptive_date}>{dateFormat1(scheduleOn)}</span>
      </td>
      <td className={style.w_25}>
        <span className={style.descriptive_date}>{createdBy}</span>
      </td>
    </tr>
  );
}

CurrentOneOnOneRow.propTypes = {
  createOn: PropTypes.string,
  scheduleOn: PropTypes.string,
  createdBy: PropTypes.string,
};

export default CurrentOneOnOneRow;
