import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import ConfirmationPopup from "components/Objectives/ConfirmationPopup";
import routePath from "const/routePath";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxHeading from "shared/Box/BoxHeading";
import style from "./EmployeeViewQuizDetails.module.scss";
import * as actions from "store/actions/index";
import { dateFormat2, getMonthAndYearFromDateString } from "util/dateUtils";
import { useEffect } from "react";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";

export default function EmployeeViewStartQuiz(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const { quizDetailsResume, isQuizStartButtonLoading, isQuizStarted } = useSelector((state) => state.campaign);
  const [quizDetails, setQuizDetails] = useState(props?.location?.state?.quizDetails);
  const quizData = storage.getJSON("employeeQuizData");
  const [isResume, setIsResume] = useState(props?.location?.state?.isResume);
  const headerText = "Are you sure you wish to start now?";
  const subText = `Your attempt will have a time limit of ${quizData?.total_time_for_quiz} minutes. When you start, the timer will begin to count down and cannot be paused. You must finish your attempt before it expires.`;
  const campaignID = props.match.params.id;

  const handleSubmit = () => {
    dispatch({ type: "IS_QUIZ_START_BUTTON_LOADING", payload: true });
    if (campaignID) {
      dispatch({ type: "ISQUIZSTARTED", payload: true });
      window.sessionStorage.removeItem("started");
      if (isResume || quizDetails?.isResume) {
        dispatch(actions.getResumeQuizQuestionsEmployeeView(campaignID, history, routePath.ONGOING_QUIZ));
      } else {
        dispatch(actions.getQuizQuestionsEmployeeView(campaignID, 1, history, routePath.ONGOING_QUIZ));
      }
    }
  };

  useEffect(() => {
    const isTimer = window.sessionStorage.getItem("delay-timer");

    if (isTimer >= 0) {
      window.sessionStorage.removeItem("delay-timer");
    }
    if (window.sessionStorage.getItem("isRefreshed")) {
      window.sessionStorage.removeItem("isRefreshed");
    }

    if (quizDetails !== undefined) {
      window.sessionStorage.setItem("quiz-resume-data", JSON.stringify(quizDetails));
    } else {
      let resumeData = JSON.parse(window.sessionStorage.getItem("quiz-resume-data"));
      if (resumeData) {
        setQuizDetails(resumeData);
      }
    }
    // issue fixed for back button from start quiz
    dispatch({ type: "IS_BACK_DISABLE", payload: true });
    dispatch({ type: "ISFINISH", payload: true });

    return () => {
      dispatch({ type: "IS_BACK_DISABLE", payload: false });
    }
  }, []);

  useEffect(() => {
    if (quizDetailsResume) {
      setQuizDetails(quizDetailsResume);
    }
  }, [quizDetailsResume]);

  useEffect(() => {
    return () => {
      if (history.action === "POP" && isQuizStarted) {
        history.push(routePath.QUIZ)
      }
    };
  }, [history]);

  return (
    <div>
      <SubNavigation type="OKR Progress" />
      {isQuizStartButtonLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">OKR Progress</h1>
            <span className="st-breadcrumb">
            OKR Progress
              <span>Quiz</span>
            </span>
          </Col>
          <Col md={12}>
            <BoxHeading
              heading={quizData?.campaignName}
              // description={dateFormat2(quizDetails?.quizEndDate)}
            />
          </Col>
          <button type="button" className={style.quizButton} onClick={() => setConfirmPopup(true)}>
            {isResume || quizData?.isResume ? "Resume Quiz" : "Start Quiz"}
          </button>
          <ConfirmationPopup
            submitBtnText={"Start"}
            show={confirmPopup}
            submit={handleSubmit}
            hide={() => setConfirmPopup(false)}
            headerText={headerText}
            subText={subText}
            instructions={quizData?.instructions}
            quizDetails={quizDetails}
          />
        </Row>
      </Container>
    </div>
  );
}
