import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import style from "./EmployeeSelfAssessmentList.module.scss";
import { IC_FLAG } from "const/imgCost";
import { dateFormat2 } from "util/dateUtils";

function EmployeeRow({ data, path, toggleCheckboxChange, backgroundColor }) {
  const { empName, displayPicture, empInitial, department, designation } = data.employeeData;

  let rpm = data.allRPM?.map((rpm) => rpm.rpm?.empName);
  let rvm = data.allRVM?.map((rvm) => rvm.rvm?.empName);

  return (
    <tr>
      <td className={style.cb_box}>
        <div className="custom-checkbox custom-checkbox-sm no_mar">
          <input
            id={data.employeeId}
            type="checkbox"
            value={data.employeeId}
            checked={data.isChecked}
            onChange={(e) => toggleCheckboxChange(e, data.employeeId)}
          />
          <label htmlFor={data.employeeId}></label>
        </div>
      </td>
      <td className={style.emp_id}>{data.employeeId}</td>
      <td className={[style.emp_name, style.emp_flag].join(" ")}>
        {data.flagged === "FLAGGED" && (
          <span className={style.flag_icon}>
            <ReactSVG src={`${IC_FLAG}`} alt="flag" />
          </span>
        )}
        <div className={style.tab_team_member_info}>
          <div className={style.team_member_info}>
            <span className={style.team_member_img} style={{ backgroundColor }}>
              {displayPicture && displayPicture.length > 2 ? <img src={displayPicture} alt="" /> : empInitial}
            </span>

            <div className={style.team_member_details}>
              <span className={style.team_member_name}>
                <Link to={path}>{empName}</Link>
              </span>

              <span className={style.team_member_designation}>
                {department} | {designation}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className={[style.objective_status, style.managerTooltip].join(" ")}>
          <span>{rpm && rpm.join(", ").length > 13 ? rpm.join(", ").slice(0, 13) + "..." : rpm?.join(", ")}</span>
          <div className={style.managerTooltipInfo}>{rpm?.join(", ")}</div>
        </div>
      </td>
      <td>
        <div className={[style.objective_status, style.managerTooltip].join(" ")}>
          <span>{rvm && rvm.join(", ").length > 13 ? rvm.join(", ").slice(0, 13) + "..." : rvm?.join(", ")}</span>
          <div className={style.managerTooltipInfo}>{rvm?.join(", ")}</div>
        </div>
      </td>
      <td className={style.submitted_on}>{dateFormat2(data.submittedDate)}</td>
    </tr>
  );
}

EmployeeRow.propTypes = {
  data: PropTypes.object,
};

export default EmployeeRow;
