import React from "react";
import moment from "moment";
import { IC_CLOSE_ROUNDED } from "../../../const/imgCost";

export default function ReplyThread(props) {
    let paddingClass = "pl65";
    let comp = props.answer.replyComment && props.answer.replyComment.map((reply, index) => {
    
    // if(props.replyFlag) {
    //   paddingClass = "pl-5"
    // }
    return (
      <div className={"view_comment_box_row "+paddingClass} key={"comment_" + index + 1}>
        <div className="user_pics" style={{ backgroundColor: props.selectedColor }}>
          {reply.commentedBy.displayPicture && reply.commentedBy.displayPicture.length > 2 ? (
            <img alt="DP" src={reply.commentedBy.displayPicture} />
          ) : (
            reply.commentedBy.displayPicture
          )}
        </div>
        <div className="user_comment_details">
          <div className="user_name">
            {reply.commentedBy.empName}
            <span className="comment_time">{moment(reply.commentedOn).fromNow()}</span>
          </div>
          <div className="user_comment text-break">{reply.comment}</div>
        </div>
        {props.deletable && (
          <div className="remove_user_comment" onClick={(e) => props.deleteComment(props.answer.private)}>
            <img className="svg" src={IC_CLOSE_ROUNDED} alt="" />
          </div>
        )}
      </div>
    )
  })
  return (
      <>
        {comp}
      </>
  )
}