import React from 'react'
import style from "./AssignObjectives.module.scss"
import { backGroundColor } from '../../../const/constValue'

export default function ObjectiveAssignee(props){
    const { displayName, dispArr, designation, department, employeeInitial} = props.user
  return (
    <div className="box ">
        <div className="box-heading-wrapper">
            <div className={["box-heading", style.header_wrapper].join(" ")}>
                <h2>Assignee</h2>
            </div>
            <div className="box-info d-none">
                <span>
                jalskdask klsdf;lkasd lks;lakfd
                </span>
            </div>
        </div>
        <div className="box-light-gradient"></div>
        <div className="box-inner-pad pb-0">
            <div className="col-lg-12 position-relative">
                <div className="form-group">
                    <div className={style.owner_img} style={{backgroundColor: backGroundColor[0]}}>
                    {dispArr && dispArr.length > 2 ? 
                            <img src={dispArr} alt="pic" />
                            :  employeeInitial  }
                    </div>
                    <div className={style.owner_detail}>
                        <h1>{displayName}</h1>
                        <h2>{department}</h2>
                        <h3>{designation}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}