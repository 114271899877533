import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../store/actions/index";
import TodoLists from "../TabPendingApprovals/TodoLists";
import HighlightObjective from "./HighlightObjective/HighlightObjective";
import HighlightTransforming from "./HighlightTransforming/HighlightTransforming";

export default function TabHighlights({
  pendingRpmAssessment,
  pendingSelfAssessment,
  pendingRvmAssessment,
  pendingDeallocationFeeedBack,
  pendingObjectiveApproval,
  employeeId,
  isPage,
  handleEmployeeClick,
  displayAll,
  pendingWeeklyCheckin,
  employeeIds,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPage === "Reportee") dispatch(actions.GetAllGrowComments(employeeIds));
    else dispatch(actions.GetAllGrowComments());
    if (employeeIds) dispatch(actions.GetUserObjectives(employeeIds, "TAB_HIGHLIGHTS"));
  }, [dispatch, isPage]);

  const totalTodoCount =
    (pendingRpmAssessment?.count || 0) +
    (pendingWeeklyCheckin ? 1 : 0) +
    (pendingSelfAssessment ? 1 : 0) +
    (pendingRvmAssessment?.count || 0) +
    (pendingObjectiveApproval?.count || 0) +
    (pendingDeallocationFeeedBack?.count || 0);
  const { employeeObjectives, helpToGrowCommentList } = useSelector((state) => state.checkIn);

  let currentObjectives =
    (employeeObjectives &&
      employeeObjectives.length > 0 &&
      employeeObjectives.filter(
        (value) => value.status === "INPROGRESS" || value.status === "ASSIGNED" || value.status === "PENDING_APPROVAL"
      )) ||
    [];

  return (
    <div>
      <TodoLists
        pendingRpmAssessment={pendingRpmAssessment}
        pendingSelfAssessment={pendingSelfAssessment}
        pendingRvmAssessment={pendingRvmAssessment}
        pendingObjectiveApproval={pendingObjectiveApproval}
        pendingDeallocationFeeedBack={pendingDeallocationFeeedBack}
        totalTodoCount={totalTodoCount}
        pendingWeeklyCheckin={pendingWeeklyCheckin}
        handleEmployeeClick={handleEmployeeClick}
        displayAll={displayAll}
      />
      <HighlightObjective currentObjectives={currentObjectives} isPage={isPage} employeeId={employeeId} />
      {helpToGrowCommentList && <HighlightTransforming helpToGrowCommentList={helpToGrowCommentList} />}
    </div>
  );
}
