import { noData } from "const/constValue";
import React from "react";
import { Col, Table } from "react-bootstrap";
import { getColorByIndex } from "../../../../util/color";
import NoDataFound from "../../../NodataFound/NoDataFound";
import Pagination from "../../../Pagination/Pagination";
import CampaignEmployeeRow from "./CampaignEmployeeRow";
import style from "./CampaignEmployeeTable.module.scss";

function CampaignEmployeeTable({
  pageOfItems,
  pageSize,
  employeeList,
  resetData,
  onChangePage,
  viewQuestionPathname,
  isCapability,
  tableHeading,
}) {
  return (
    <Col className="col-12">
      <div className={[style.campaign_table_box, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>{tableHeading}</h2>
            <h3>View the details of employees</h3>
          </div>
        </div>
        <div className="box-light-gradient"></div>
        <div className={[style.custom_table, "table-theme-1"].join(" ")}>
          {pageOfItems && pageOfItems.length > 0 ? (
            <Table hover responsive className={style.tab_table_responsive}>
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Location</th>
                  <th>Status</th>
                  {isCapability && <th>Manager Status</th>}
                </tr>
              </thead>
              <tbody>
                {pageOfItems &&
                  pageOfItems.length > 0 &&
                  pageOfItems.map((item, index) => (
                    <CampaignEmployeeRow
                      key={item.employeeCampaignId + item.employeeDetails?.employeeId + index}
                      backgroundColor={getColorByIndex(index)}
                      employeeId={item.employeeDetails?.employeeId}
                      image={item.employeeDetails?.displayPicture}
                      pathName={() => viewQuestionPathname(item.employeeCampaignId, item.employeeDetails)}
                      empName={item.employeeDetails?.empName}
                      designation={item.employeeDetails?.designation}
                      department={item.employeeDetails?.department}
                      grade={item.employeeDetails?.grade}
                      submittedDate={item.employeeDetails?.submittedDate}
                      selfAssessmentStatus={item.employeeDetails?.selfAssessmentStatus}
                      status={item?.status}
                      managerStatus={isCapability ? (item.managerSubmittedDate ? "Submitted" : "Pending") : null}
                      location={item.employeeDetails?.location}
                    />
                  ))}
              </tbody>
            </Table>
          ) : (
            <div>
              <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              <div className="gap80"></div>
              <div className="gap80"></div>
            </div>
          )}
        </div>
      </div>
      {employeeList?.length > 0 && (
        <Pagination pageSize={pageSize} items={employeeList} resetData={resetData} onChangePage={onChangePage} />
      )}
    </Col>
  );
}

export default CampaignEmployeeTable;
