import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./AutoCompleateInput.scss"
import { Form } from "react-bootstrap";

const suggestionList = React.createRef();
class Autocomplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);
        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: props.inputVal ? props.inputVal : ""
        };
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState(prevstate => ({
            ...prevstate,
            userInput: nextProps.inputVal
        }))
    }

    noValue = (e) => {

        let value = this.state.userInput;
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: value
        });
        if (this.props.noValueText === '(New Category)') {
            this.props.selectCategory(this.props.catIndex, value);
        }
        if (this.props.noValueText === '(New Technical Role)') {
            this.props.selectTechRole(value);
        }
    }

    onChange = e => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        let filteredSuggestions = suggestions.filter(
            suggestion => {
                return suggestion.label.toLowerCase().indexOf(userInput.toLowerCase().trim()) > -1
            }
        );

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
        if(suggestionList.current && suggestionList.current.scrollTop > 0) {
          suggestionList.current.scrollTop = 0;  
        }
    };

    onClick = e => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
        const filterObj = this.props.suggestions.find(option => option.label === e.currentTarget.innerText);
        if (this.props.noValueText === '(New Category)') {
            this.props.selectCategory(this.props.catIndex, filterObj);
        }

        if (this.props.noValueText === '(New Technical Role)') {
            this.props.selectTechRole(filterObj);
        }
    };

    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;
        // const inputVal = this.state.userInput && filteredSuggestions[activeSuggestion] ? filteredSuggestions[activeSuggestion] : this.state.userInput;
        // User pressed the enter key
        if (e.keyCode === 13) {           
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: this.state.userInput && filteredSuggestions[activeSuggestion] ? filteredSuggestions[activeSuggestion].label : this.state.userInput
            });
            const filterObj = this.state.userInput && filteredSuggestions[activeSuggestion] ? filteredSuggestions[activeSuggestion] : this.state.userInput;
            if (this.props.noValueText === '(New Category)' && filterObj) {
                this.props.selectCategory(this.props.catIndex, filterObj);
            }
            if (this.props.noValueText === '(New Technical Role)' && filterObj) {
                this.props.selectTechRole(filterObj);
            }
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if ( suggestionList.current && suggestionList.current.scrollTop > 0 && activeSuggestion !== filteredSuggestions.length + 1) {
                suggestionList.current.scrollTop = suggestionList.current.scrollTop - suggestionList.current.children[0].clientHeight;
            }
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            if (suggestionList.current && activeSuggestion > 2 && activeSuggestion <= filteredSuggestions.length) {
                suggestionList.current.scrollTop =  (activeSuggestion-1) * suggestionList.current.children[0].clientHeight;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }

    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            noValue,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;

        let suggestionsListComponent;
        if (userInput && showSuggestions) {

            if (filteredSuggestions.length) {

                suggestionsListComponent = (
                    <ul className="suggestions" ref={suggestionList}>
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;
                            let noValueclass;
                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            } if (activeSuggestion === filteredSuggestions.length) {
                                noValueclass = "suggestion-active"
                            }
                            return (
                                <React.Fragment key={index}>
                                    <li className={className} key={suggestion.value} onClick={onClick}>
                                        {suggestion.label}
                                    </li>
                                    {suggestion.label.toLowerCase() !== userInput.trim().toLowerCase() && index === filteredSuggestions.length - 1 && <li className={noValueclass} onClick={noValue}>
                                        {userInput}{this.props.noValueText}
                                    </li>}

                                </React.Fragment>

                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        <li className="suggestion-active" onClick={noValue}>
                            {userInput}{this.props.noValueText}
                        </li>
                    </ul>
                );
            }
        }

        return (
            <Fragment>
                <div className="auto-c-posi-rel">
                    <Form.Group>
                        <Form.Control
                            type="text"
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            value={userInput}
                            placeholder={this.props.placeholderText}
                            className="auto-c-input"
                            trim="blur"
                        /> </Form.Group>
                    {suggestionsListComponent}
                </div>
            </Fragment>
        );
    }
}

export default Autocomplete;
