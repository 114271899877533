import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import style from "./CycleList.module.scss";
import * as actions from "store/actions/index";
import { IC_EMP_DETAIL, IC_PENCIL } from "const/imgCost";
import { moduleRights } from "util/utils";
import { filterArray } from "util/general";
import { noData } from "const/constValue";
import storage from "util/storage";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import SelectListPopup from "./SelectListPopup";
import NoDataFound from "../../NodataFound/NoDataFound";
import Pagination from "../../Pagination/Pagination";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

export default function CycleList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = storage.getUser();
  const [display, setDisplay] = useState(false);
  const [cycleId, setCycleId] = useState("");
  const [showActive, setShowActive] = useState(false);
  const [cycleData, setCycleData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [pageOfItems, setPageOfItems] = useState([]);
  const { quarterlyCycleList, isLoading } = useSelector((state) => state.performance);

  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  const employeeIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  const validEmpID = ['20049812','20119912','20012812','20234512'];
  const compositeID = employeeIds.employeeId + employeeIds.parentGroupId + employeeIds.subGroupId;
  
  useEffect(() => {
    dispatch(actions.GetQuarterlyCycleList("HR"));
    dispatch({ type: "HR_PERFORMANCE_SCREEN" });
    dispatch({ type: "CLEAR_PAGINATION_DATA" });
  }, [dispatch]);

  const handleSwitchActive = () => {
    setShowActive(!showActive);
    let data = null;
    if (!showActive) {
      data = quarterlyCycleList.filter((value) => value.status === "In Progress");
    } else {
      data = quarterlyCycleList;
    }
    setCycleData(data);
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    filteredData = filterArray(quarterlyCycleList, filters);
    setFilteredData(filteredData);
    setSearchFilter("");
  };

  const handleListView = (id) => {
    setDisplay(true);
    setCycleId(id);
  };

  useEffect(() => {
    filteredData.length > 0
      ? setCycleData(filteredData)
      : searchFilter
      ? setCycleData(filteredData)
      : setCycleData(quarterlyCycleList);
  }, [quarterlyCycleList, filteredData, searchFilter]);

  return (
    <div className={style.cycleListWrapper}>
      <SubNavigation type="Performance" />
      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col md={6} className="col-12">
              <h1 className="page-title pb-0">HR</h1>
              <span className="st-breadcrumb">
                Performance<span>Performance Cycle</span>
              </span>
            </Col>
            {moduleRights(config.mainModule.PERFORMANCE, config.subModule.PERFORMANCE_CYCLE)?.includes("CREATE") && (
              <Col md={6} className="text-right m-text-left">
                <button
                  onClick={() => history.push(routePath.CREATE_PMS_CYCLE)}
                  type="button"
                  className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
                  disabled={!validEmpID.includes(compositeID)}
                >
                  <strong>Create New</strong>
                </button>
              </Col>
            )}
            <Col className="col-12">
              <div className="box pb-0 mb-0">
                <div className="box-heading-wrapper">
                  <div className="box-heading justify-content-between d-flex align-items-center">
                    <h2>Cycle List</h2>
                    <div className={style.switches}>
                      <Form>
                        <Form.Check
                          type="switch"
                          id="active-switch"
                          label="Only Active Cycles"
                          value={showActive}
                          defaultChecked={showActive}
                          onChange={handleSwitchActive}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="outer-table-wrapper">
                <div className="box-light-gradient"></div>
                <div className={[style.custom_table, style.hr_table, "table-theme-1"].join(" ")}>
                  {pageOfItems && pageOfItems.length > 0 ? (
                    <Table hover responsive className={style.tab_table_responsive}>
                      <thead>
                        <tr>
                          <th>Period</th>
                          <th>Cycle</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th>Employee Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pageOfItems.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <span>{item.period}</span>
                              </td>
                              <td>
                                <span>{item.cycle}</span>
                              </td>
                              <td>
                                <span
                                  className={
                                    item.status === "In Progress"
                                      ? style.inprogress
                                      : item.status === "Completed"
                                      ? style.completed
                                      : style.ended
                                  }
                                >
                                  {item.status === "In Active" ? "Inactive" : item.status}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={validEmpID.includes(compositeID) ? style.edit_cycle : style.edit_cycle_disable}
                                  onClick={() =>
                                    moduleRights(
                                      config.hrModule.PERFORMANCE,
                                      config.subModule.PERFORMANCE_CYCLE
                                    )?.includes("EDIT") &&
                                    validEmpID.includes(compositeID) &&
                                    history.push(`/hr/performance/performance-cycle/edit/${item.cycleId}`)
                                  }
                                >
                                  <ReactSVG src={`${IC_PENCIL}`} alt="" />
                                </span>
                              </td>
                              <td>
                                <span
                                  className={style.emp_cycle}
                                  onClick={() => item.status !== "In Active" && handleListView(item.cycleId)}
                                >
                                  <ReactSVG src={`${IC_EMP_DETAIL}`} alt="" />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <div>
                      <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                      <div className="gap120"></div>
                    </div>
                  )}
                </div>
              </div>
              {cycleData && (
                <Pagination pageSize={10} items={cycleData} resetData={resetData} onChangePage={onChangePage} />
              )}
            </Col>
          </Row>
        </Container>
      )}
      <SelectListPopup cycleID={cycleId} show={display} hide={() => setDisplay(false)} />
    </div>
  );
}
