import React from "react";
import moment from "moment";
import { IC_SEARCH_RESET } from "../../const/imgCost";
import { ReactSVG } from "react-svg";

export default function NotificationItem({ item, backgroundColor, onDelete, onClick, notificationType, displayPic }) {
  let arr = item.message && item.message.split("[sender]");
  let thirdPerson;
  if (item.isThirdPerson) {
    thirdPerson = arr[1].split("[ThirdPerson]");
  }
  const renderOtherMessage = () => {
    if (item.isThirdPerson) {
      return (
        <>
          {thirdPerson[0]}
          <span className="name">{item.thirdPerson.empName}</span>
          {thirdPerson[1]}
        </>
      );
    } else return arr[1];
  };

  return (
    <li
      id={notificationType === "panel" ? "panel-" + item.id : item.id}
      className={"notification " + (item.isRead ? "" : "private-notification")}
      onClick={onClick}
    >
      <div className="user_wrapper" style={{ backgroundColor }}>
        {displayPic && displayPic.length > 2 ? <img src={displayPic} alt="" /> : displayPic}
      </div>
      <div className="notification_message">
        {item.message?.includes("[sender]") ? (
          <div>
            {arr[0]}
            <span className="name">{item.sender.empName}</span>
            {renderOtherMessage()}
          </div>
        ) : item.sender && item.sender.empName ? (
          <span>
            <span className="name">{item.sender?.empName}</span>
            {item.message}
          </span>
        ) : (
          <span>{item.message}</span>
        )}
        <div className="published">{moment(item.createdDate).fromNow()}</div>
        {onDelete && (
          <span className="delete-notification" onClick={onDelete}>
            <ReactSVG src={`${IC_SEARCH_RESET}`} alt="Delete Notification" />
          </span>
        )}
      </div>
    </li>
  );
}
