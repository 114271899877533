import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import style from "./AddSkill.module.scss";
import * as actions from "store/actions/index";
import Spinner from "shared/Spinner/Spinner";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { competency_level_options } from "const/options";
import routePath from "const/routePath";
import { errorToast, successToast } from "util/general";

let options_month = [
  { value: 0, label: "January" },
  { value: 1, label: "February" },
  { value: 2, label: "March" },
  { value: 3, label: "April" },
  { value: 4, label: "May" },
  { value: 5, label: "June" },
  { value: 6, label: "July" },
  { value: 7, label: "August" },
  { value: 8, label: "September" },
  { value: 9, label: "October" },
  { value: 10, label: "November" },
  { value: 11, label: "December" },
];

export default function AddSkill(props) {
  const { successResponse, isLoading, errorResponse, skills, draftSkills, skillDetail } = useSelector(
    (state) => state.skillMatrix
  );
  const dispatch = useDispatch();
  const [skill, selectSkill] = useState(null);
  const [skillLevel, selectSkillLevel] = useState(null);
  const [usedSkillYear, selectUsedSkillYear] = useState(null);
  const [usedSkillMonth, selectusedSkillMonth] = useState(null);
  const [experienceSkillYear, selectExperienceSkillYear] = useState(null);
  const [experienceSkillMonth, selectExperienceSkillMonth] = useState(null);
  const [disabled, setDisable] = useState(true);
  const [skillList, setSkillList] = useState([]);
  const [displayMonth, setDisplayMonth] = useState(options_month);
  const history = useHistory();

  useEffect(() => {
    const getSkills = () => dispatch(actions.getSkills);
    getSkills();
  }, [dispatch]);

  useEffect(() => {
    if (!history.location.pathname.includes("additional-skill")) {
      const draftSkill = () => dispatch(actions.draftSkill);
      draftSkill();
    } else {
      const getSkillDetail = () => dispatch(actions.getSkill({ skillNameId: props.match.params.id }));
      getSkillDetail();
    }
  }, [dispatch, history.location.pathname, props]);

  useEffect(() => {
    if (skills.length > 0) {
      const skillArray = [];

      skills.map((skill) => {
        if (skill.isActive) {
          const obj = {
            value: skill.skillId,
            label: skill.skillName,
          };
          skillArray.push(obj);
        }
      });
      setSkillList(skillArray);
    } else {
      setSkillList([]);
    }
  }, [skills]);

  const getYear = () => {
    return Array.from({ length: 51 }, (v, k) => {
      const obj = { value: "", label: "" };
      obj.value = k;
      obj.label = k;
      return obj;
    });
  };
  const getMonth = () => {
    return Array.from({ length: 12 }, (v, k) => {
      const obj = { value: "", label: "" };
      obj.value = k;
      obj.label = k;
      return obj;
    });
  };
  const lastUsedSkils = () => {
    let currentYear = new Date().getFullYear();
    if (usedSkillMonth && usedSkillMonth.value > new Date().getMonth()) {
      currentYear = currentYear - 1;
    }
    const year = [];
    const date = currentYear;
    for (let i = date; i >= 1970; i--) {
      const obj = { value: "", label: "" };
      obj.value = i;
      obj.label = i;
      year.push(obj);
    }
    return year;
  };

  useEffect(() => {
    if (Object.keys(draftSkills).length > 0) {
      const usedMonth = options_month.find((skill) => skill.label === draftSkills.lastUsedMonth);
      selectusedSkillMonth(usedMonth);

      const skills = skillList.find((skills) => skills.value === draftSkills.skillNameId);
      selectSkill(skills);

      const Level = competency_level_options.find((skill) => skill.value === draftSkills.competencyLevel);
      selectSkillLevel(Level);

      const skillYear = lastUsedSkils().find((skill) => skill.value === draftSkills.lastUsedYear);
      const date = new Date();
      const array = [];
      if (skillYear && skillYear.value === date.getFullYear()) {
        options_month.forEach((month) => {
          if (month.value < date.getMonth()) {
            array.push(month);
          }
        });
        setDisplayMonth(array);
      } else {
        setDisplayMonth(options_month);
      }
      selectUsedSkillYear(skillYear);

      const expYear = getYear().find((skill) => skill.value === draftSkills.experienceInYears);
      selectExperienceSkillYear(expYear);

      const expMonth = getMonth().find((skill) => skill.value === draftSkills.experienceInMonth);
      selectExperienceSkillMonth(expMonth);
      return;
    }
  }, [draftSkills]);

  const setValue = () => {
    selectSkill(null);
    selectUsedSkillYear(null);
    selectusedSkillMonth(null);
    selectExperienceSkillYear(null);
    selectExperienceSkillMonth(null);
    selectSkillLevel(null);
    setDisable(true);
  };

  useEffect(() => {
    if (Object.keys(skillDetail).length > 0) {
      const usedMonth = options_month.find((skill) => skill.label === skillDetail.lastUsedMonth);
      selectusedSkillMonth(usedMonth);

      const Level = competency_level_options.find((skill) => skill.value === skillDetail.competencyLevel);
      selectSkillLevel(Level);

      const skillYear = lastUsedSkils().find((skill) => skill.value === skillDetail.lastUsedYear);
      const date = new Date();
      const array = [];
      if (skillYear && skillYear.value === date.getFullYear()) {
        options_month.forEach((month) => {
          if (month.value < date.getMonth()) {
            array.push(month);
          }
        });
        setDisplayMonth(array);
      } else {
        setDisplayMonth(options_month);
      }
      selectUsedSkillYear(skillYear);

      const expYear = getYear().find((skill) => skill.value === skillDetail.experienceInYears);
      selectExperienceSkillYear(expYear);

      const expMonth = getMonth().find((skill) => skill.value === skillDetail.experienceInMonth);
      selectExperienceSkillMonth(expMonth);
    }
  }, [skillDetail]);

  useEffect(() => {
    if (Object.keys(skillDetail).length > 0) {
      const skills = skillList.find((skills) => skills.value === skillDetail.skillNameId);
      selectSkill(skills);
    }

    if (Object.keys(draftSkills).length > 0) {
      const skills = skillList.find((skills) => skills.value === draftSkills.skillNameId);
      selectSkill(skills);
    }
  }, [skillDetail.skillNameId, skillList]);

  const selectedSkill = (select) => {
    selectSkill(select ? select : null);
  };

  const lastUsedSkillYear = (select) => {
    const date = new Date();
    const array = [];
    if (select && select.value === date.getFullYear()) {
      options_month.forEach((month) => {
        if (month.value < date.getMonth()) {
          array.push(month);
        }
      });
      setDisplayMonth(array);
    } else {
      setDisplayMonth(options_month);
    }
    selectUsedSkillYear(select ? select : null);
  };

  const lastUsedSkillMonth = (select) => {
    selectusedSkillMonth(select ? select : null);
  };

  const experienceYear = (select) => {
    selectExperienceSkillYear(select ? select : null);
  };

  const experienceMonth = (select) => {
    selectExperienceSkillMonth(select ? select : null);
  };

  const currentLeval = (select) => {
    selectSkillLevel(select ? select : null);
  };

  useEffect(() => {
    if (skill && skillLevel && usedSkillMonth && usedSkillYear && experienceSkillYear && experienceSkillMonth) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [experienceSkillMonth, experienceSkillYear, skill, skillLevel, usedSkillMonth, usedSkillYear]);

  const sendForApproval = () => {
    const param = {};
    if (experienceSkillYear.value === 0 && experienceSkillMonth.value === 0) {
      errorToast("Need to add Relevant Experience");
      return;
    }
    if (skill && skillLevel && usedSkillMonth && usedSkillYear && experienceSkillYear && experienceSkillMonth) {
      if (!history.location.pathname.includes("additional-skill")) {
        param["skillNameId"] = skill.value;
        param["lastUsedYear"] = usedSkillYear.value;
        param["lastUsedMonth"] = usedSkillMonth.label;
        param["experienceInYears"] = experienceSkillYear.value;
        param["experienceInMonth"] = experienceSkillMonth.value;
        param["competencyLevel"] = skillLevel.value;
        param["isCreated"] = true;
        param["skillId"] = draftSkills.skillId ? draftSkills.skillId : null;
        dispatch(actions.sendForApproval(param));
      } else {
        param["skillId"] = skillDetail.skillId;
        param["skillNameId"] = skill.value;
        param["lastUsedYear"] = usedSkillYear.value;
        param["lastUsedMonth"] = usedSkillMonth.label;
        param["experienceInYears"] = experienceSkillYear.value;
        param["experienceInMonth"] = experienceSkillMonth.value;
        param["competencyLevel"] = skillLevel.value;
        param["isCreated"] = true;
        dispatch(actions.updateAdditionalSkill(param));
      }
    }
  };

  const updateSkill = (name, data) => {
    if (!history.location.pathname.includes("additional-skill")) {
      const param = {};
      if (name === "lastUsedMonth") {
        param[name] = data ? data.label : "";
      } else {
        param[name] = data ? data.value : "";
      }
      param["skillId"] = draftSkills && draftSkills.skillId ? draftSkills.skillId : null;

      dispatch(actions.updateDraftSkill(param));
    }
  };

  useEffect(() => {
    if (successResponse && successResponse.success) {
      successToast(successResponse.message);
      if (!history.location.pathname.includes("additional-skill")) {
        setValue();
      } else {
        history.push(routePath.MY_SKILLS);
      }
    }
    if (errorResponse && errorResponse.error) {
      errorToast(errorResponse.message);
    }
  }, [successResponse, errorResponse, history]);

  return (
    <div className={style.addskill_wrapper}>
      <SubNavigation type="WMG" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.MY_SKILLS}> My Skills</Link>
              <span> Additional Skills</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12} xl={8}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>{history.location.pathname.includes("additional-skill") ? "Update Skill" : "Add Skill"}</h2>
                  <h3>
                    {history.location.pathname.includes("additional-skill")
                      ? "Please update/select the skill from below"
                      : "Please add/select the skill from below"}
                  </h3>
                </div>
              </div>
              <div className={style.skill_dd_wrapper}>
                <div className="box-inner-pad">
                  <Row>
                    <Col md={8}>
                      <span className={style.dd_title}>Enter Skill Name</span>
                      <div className="card_action">
                        <Select
                          onChange={(select) => {
                            selectedSkill(select);
                          }}
                          options={skillList}
                          placeholder="Enter Skill Name(E.g.Java, Photoshop, HTML, etc..)"
                          className="custom-default-dd-box"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          value={skill ? skill : null}
                          onBlur={() => {
                            updateSkill("skillNameId", skill);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <span className={style.dd_title}>Skill Competency Level</span>
                      <div className="card_action">
                        <Select
                          onChange={(select) => currentLeval(select)}
                          options={competency_level_options}
                          placeholder="Select Level"
                          className="custom-default-dd-box"
                          classNamePrefix="select"
                          isSearchable={false}
                          value={skillLevel ? skillLevel : null}
                          onBlur={() => {
                            updateSkill("competencyLevel", skillLevel);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="box-inner-pad">
                  <Row>
                    <Col md={6}>
                      <span className={style.dd_title}>I last used this skill in</span>
                      <div className="card_action dropdown_50">
                        <Select
                          onChange={(select) => lastUsedSkillYear(select)}
                          options={lastUsedSkils()}
                          className="custom-default-dd-box"
                          classNamePrefix="select"
                          placeholder="Select Year"
                          isSearchable={false}
                          value={usedSkillYear ? usedSkillYear : null}
                          onBlur={() => {
                            updateSkill("lastUsedYear", usedSkillYear);
                          }}
                        />
                        <Select
                          onChange={(select) => lastUsedSkillMonth(select)}
                          options={displayMonth}
                          className="custom-default-dd-box"
                          classNamePrefix="select"
                          placeholder="Select Month"
                          isSearchable={false}
                          value={usedSkillMonth ? usedSkillMonth : null}
                          onBlur={() => {
                            updateSkill("lastUsedMonth", usedSkillMonth);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <span className={style.dd_title}>Relevent Experience (In Years & Month)</span>
                      <div className="card_action dropdown_50">
                        <Select
                          onChange={(select) => experienceYear(select)}
                          options={getYear()}
                          className="custom-default-dd-box"
                          classNamePrefix="select"
                          isSearchable={false}
                          placeholder="In Years"
                          value={experienceSkillYear ? experienceSkillYear : null}
                          onBlur={() => {
                            updateSkill("experienceInYears", experienceSkillYear);
                          }}
                        />
                        <Select
                          onChange={(select) => experienceMonth(select)}
                          options={getMonth()}
                          className="custom-default-dd-box"
                          classNamePrefix="select"
                          isSearchable={false}
                          placeholder="In Months"
                          value={experienceSkillMonth ? experienceSkillMonth : null}
                          onBlur={() => {
                            updateSkill("experienceInMonth", experienceSkillMonth);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="box-inner-pad pb-0">
                  <div className={["box-flex-center", style.saveBox].join(" ")}>
                    <div className={style.save_box_left}>
                      <Button type="button" variant={"primary"} onClick={sendForApproval} disabled={disabled}>
                        <strong>
                          {history.location.pathname.includes("additional-skill") ? "Update Skill" : "Add Skill"}
                        </strong>
                      </Button>
                    </div>
                    {draftSkills && draftSkills.draftedDate && (
                      <div className={style.save_box_right}>
                        Draft Saved:
                        <span>{moment(draftSkills.draftedDate).format("ddd D MMM h:mm a")} </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
