import React from "react";
import PropTypes from "prop-types";
import TakeActionOfQuestion from "../../Components/TakeActionOfQuestion";
import DateAndTimeSelection from "../../Components/DateAndTimeSelection";
import ThirdPersonSelect from "../../Components/ThirdPersonSelect";
import { Button } from "react-bootstrap";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import moment from "moment";
import InfoCardWithOl from "../../../InfoCard/InfoCardWithOl";

function ManagerTakeAction({
  selectedThirdPerson,
  loggedInUser,
  oneOnOne,
  handleQuestion,
  selectQuestion,
  isEdit,
  handleStartTime,
  handleEndTime,
  startTime,
  endTime,
  handleStartDate,
  startDate,
  oneOnOneAgendaId,
  managerList,
  endOneOnOne,
  scheduleOneOnOne,
  scheduledDate,
  isPage,
  blockTimes,
  timezoneList,
  timezone,
  setTimezone,
  isRegisterInTeams,
}) {
  return (
    <div>
      <div className={["box pt-0 pb-0 radio-wrapper"].join(" ")}>
        <TakeActionOfQuestion
          loggedInUser={loggedInUser}
          oneOnOne={oneOnOne}
          handleQuestion={handleQuestion}
          selectQuestion={selectQuestion}
        />
        {selectQuestion &&
          (moment(scheduledDate).format("DD MM YYYY") !== moment().format("DD MM YYYY") ||
            selectQuestion === "discuss") && (
            <DateAndTimeSelection
              timezone={timezone}
              setTimezone={setTimezone}
              timezoneList={timezoneList}
              isEdit={isEdit}
              oneOnOne={oneOnOne}
              selectQuestion={selectQuestion}
              handleStartTime={handleStartTime}
              handleEndTime={handleEndTime}
              startTime={startTime}
              endTime={endTime}
              handleStartDate={handleStartDate}
              startDate={startDate}
              blockTimes={blockTimes}
            />
          )}
        {selectQuestion === "discuss" && managerList && (
          <ThirdPersonSelect
            isEdit={isEdit}
            selectedThirdPerson={selectedThirdPerson}
            loggedInUser={loggedInUser}
            oneOnOneAgendaId={oneOnOneAgendaId}
            managerList={managerList}
          />
        )}
      </div>
      {!isRegisterInTeams && (
        <InfoCardWithOl
          heading={`Hi ${loggedInUser?.displayName}, We have observed that you have not register yourself in Engage Bot. To make your journey easy we
        recommend you to register yourself in Bot. So in future, you can get update of your scheduled 1-on-1 in
        Microsoft Teams & Outlook`}
          description="In order to receive the Notification, kindly follow the steps mentioned below"
        />
      )}

      {selectQuestion === "close" ? (
        <div className={["box box-flex-center", style.saveBox].join(" ")}>
          <div className={style.save_box_left}>
            <Button className="btn btn-primary" onClick={() => endOneOnOne(isEdit)}>
              End 1-on-1
            </Button>
          </div>
        </div>
      ) : (
        oneOnOne &&
        oneOnOne.talkingPoints &&
        oneOnOne.talkingPoints.filter((value) => value.isCompleted).length !== oneOnOne.talkingPoints.length && (
          <div className={["box box-flex-center", style.saveBox].join(" ")}>
            <div className={style.save_box_left}>
              <Button className="btn btn-primary" onClick={scheduleOneOnOne}>
                {isPage === "previous" ? "Re-Schedule" : "Schedule"}
              </Button>
            </div>
          </div>
        )
      )}
    </div>
  );
}

ManagerTakeAction.propTypes = {
  loggedInUser: PropTypes.object,
  isPage: PropTypes.string,
};

export default ManagerTakeAction;
