import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import * as actions from "store/actions/index";
import { IC_LOCK_PRIVACY_1 } from "const/imgCost";
import { OBJECTIVE_PRIVATE_REPLY, DELETE_OBJECTIVE_COMMENT } from "util/endpoints";
import http from "util/http";
import ObjectiveComments from "./ObjectiveComments";
import ObjectiveCommentBox from "./objectiveCommentBox";

export default function PrivateCommentSection(props) {
  const [comment, setComment] = useState("");
  const [chars, setChars] = useState(200);
  const [commentId, setCommentId] = useState("");
  const dispatch = useDispatch();

  const privateCommentObj =
    props.objective.comment &&
    props.objective.comment.length > 0 &&
    props.objective.comment
      .filter((answer) => {
        if (answer.whoCanView && answer.whoCanView.length === 0) {
          return true;
        } else {
          if (props.managerView && answer.whoCanView && answer.whoCanView[0].employeeId !== props.user.employeeId) {
            return false;
          } else {
            return true;
          }
        }
      })
      .filter((answer) => !answer.reply && answer.private);
  const changeHandle = (comment, id) => {
    setChars(200 - comment.length);
    setComment(comment);
    setCommentId(id);
  };
  const PrivateCommentHandle = (val) => {
    let whoCanView = privateCommentObj.filter((obj) => {
      if (obj.commentId === commentId) {
        return true;
      }
      return false;
    });

    if (val.trim().length < 1) return true;
    let data = {
      userObjectiveId: props.objective.userObjectiveId,
      objectiveComment: {
        comment: comment,
        commentedBy: {
          employeeId: props.user.employeeId,
          empName: props.user.displayName,
          displayPicture: props.user.displayPicture ? props.user.displayPicture : props.user.empInitial,
          subGroupId: props.user.subGroupId,
          subGroupName: props.user.subGroupName,
          parentGroupId: props.user.parentGroupId,
          parentGroupName: props.user.parentGroupName,
        },
        whoCanView: [
          {
            employeeId: whoCanView[0].commentedBy.employeeId,
            empName: whoCanView[0].commentedBy.empName,
            displayPicture: whoCanView[0].commentedBy.displayPicture
              ? whoCanView[0].commentedBy.displayPicture
              : whoCanView[0].commentedBy.empInitial,
            subGroupId: whoCanView[0].commentedBy.subGroupId,
            subGroupName: whoCanView[0].commentedBy.subGroupName,
            parentGroupId: whoCanView[0].commentedBy.parentGroupId,
            parentGroupName: whoCanView[0].commentedBy.parentGroupName,
          },
        ],
        private: true,
      },
      commentId: commentId,
      surveyCycleId: props.surveyCycle.surveyCyclesId,
      employeeId: props.surveyCycle.employeeProfile.employeeId,
      subGroupId: props.surveyCycle.employeeProfile.subGroupId,
      subGroupName: props.surveyCycle.employeeProfile.subGroupName,
      parentGroupId: props.surveyCycle.employeeProfile.parentGroupId,
      parentGroupName: props.surveyCycle.employeeProfile.parentGroupName,
    };

    http
      .post(OBJECTIVE_PRIVATE_REPLY, {}, data)
      .then((response) => response.data)
      .then(() => {
        dispatch(actions.GetObjectivesBySurveyCycle(props.surveyCycle.surveyCyclesId));
        setChars(200);
        setComment("");
      })
      .catch(console.error);
  };

  const deleteComment = (commentId, isReplyId) => {
    let comment = {};
    comment = {
      commentType: "MANAGER",
      employeeId: props.user.employeeId,
      userObjectiveId: props.objective.userObjectiveId,
      commentId: commentId,
      surveyCycleId: props.surveyCycle.surveyCyclesId,
      isReplyId: isReplyId,
    };

    http
      .delete(DELETE_OBJECTIVE_COMMENT, { data: comment })
      .then((response) => response.data.message)
      .then(() => {
        dispatch(actions.GetUserObjectives(props.user));
        dispatch(actions.GetObjectivesBySurveyCycle(props.surveyCycle.surveyCyclesId));
      })
      .catch(() => {});
  };
  const CommentHandle = () => {
    if (comment.trim().length < 1) return true;
    setChars(200);
    setComment("");
  };
  let component = null;
  if (privateCommentObj && privateCommentObj !== null && privateCommentObj.length !== 0) {
    component = privateCommentObj.map((answer, index) => {
      return (
        <div className="view_comment_box_wrapper private_comment_box_wrapper" key={index}>
          <div className="view_comment_box_overview justify-content-between">
            <div className="d-flex">
              <div className="view_comment_box_overview_icon">
                <ReactSVG className="svg" src={`${IC_LOCK_PRIVACY_1}`} alt="" />
              </div>
              <div className="view_comment_box_heading">
                <strong className="semi-bold">
                  {"Private message, only visible to you and " + answer.commentedBy.empName}
                </strong>
              </div>
            </div>
          </div>

          <ObjectiveComments
            isPage={props.isPage}
            privateThread={true}
            key={"private_reply" + index}
            index={index}
            selectedColor={props.selectedColor}
            answer={answer}
          />
          {props.managerView &&
            answer &&
            answer.replyComment !== null &&
            answer.replyComment.map((comments) => {
              return (
                <ObjectiveComments
                  isPage={props.isPage}
                  privateThread={false}
                  key={"privatereply" + index}
                  index={index}
                  selectedColor={props.selectedColor}
                  answer={comments}
                  reply={true}
                  deletable={props.isReviewed}
                  deleteComment={() => deleteComment(answer.commentId, comments.commentId)}
                />
              );
            })}

          {props.isReviewed && (
            <ObjectiveCommentBox
              privateThread={true}
              id={answer.commentId}
              employee={props.user}
              style={props.style}
              selectedColor={props.selectedColor}
              managerCommentHandle={CommentHandle}
              managerPrivateCommentHandle={PrivateCommentHandle}
              changeHandle={(event) => changeHandle(event.target.value, answer.commentId)}
              comment={commentId === answer.commentId ? comment : ""}
              chars={commentId === answer.commentId ? chars : 200}
            />
          )}
        </div>
      );
    });
  }

  return component;
}
