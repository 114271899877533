import React, { useState, useRef } from "react";
import { Form, Button, Overlay, Tooltip } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IMG_TOAST_CLOSE, IC_ERROR, IC_CHAT_BUBBLE } from "../../../const/imgCost";
import { useDispatch } from "react-redux";
import { setOneOnOneAgenda } from "../../../store/actions";

export default function OneOnOneCommentBox(props) {
  const dispatch = useDispatch();
  const targetMessage = useRef(null);
  const [comment, setComment] = useState("");
  const [oneOnOneCommentError, setOneOnOneCommentError] = useState(false);
  const [selectedReviewer, setSelectedReviewer] = useState("");

  const handleOneOnOneComment = (value) => {
    setComment(value);
    setOneOnOneCommentError(false);
  };

  const onSelectReviewer = (e) => {
    if (e.target.value) {
      setOneOnOneCommentError(false);
      setSelectedReviewer(JSON.parse(e.target.value));
    } else {
      setOneOnOneCommentError(true);
      setSelectedReviewer(false);
    }
  };

  const sendOneOnOne = () => {
    let body,
      addOneOnOnewith,
      createdBy = null;
    if (props.page === "myteam") {
      addOneOnOnewith = {
        employeeId: props.employeeProfile.employeeId,
        empName: props.employeeProfile.displayName,
        displayPicture: props.employeeProfile.dispArr || props.employeeProfile.employeeInitial,
        department: props.employeeProfile.department,
        isManager: false,
        email: props.employeeProfile.email,
        country: props.employeeProfile.country,
        subGroupId: props.employeeProfile.subGroupId,
        parentGroupId: props.employeeProfile.parentGroupId,
        subGroupName: props.employeeProfile.subGroupName,
        parentGroupName: props.employeeProfile.parentGroupName,
      };
      createdBy = {
        employeeId: props.employee.employeeId,
        empName: props.employee.displayName,
        displayPicture: props.employee.displayPicture || props.employee.empInitial,
        department: props.employee.department,
        isManager: true,
        email: props.employee.email,
        country: props.employee.country,
        subGroupId: props.employee.subGroupId,
        parentGroupId: props.employee.parentGroupId,
        subGroupName: props.employee.subGroupName,
        parentGroupName: props.employee.parentGroupName,
      };
    } else {
      addOneOnOnewith = {
        employeeId: selectedReviewer.employeeId,
        empName: selectedReviewer.empName,
        displayPicture: selectedReviewer.displayPicture,
        department: selectedReviewer.department,
        isManager: true,
        email: selectedReviewer.email,
        country: selectedReviewer.country,
        subGroupId: selectedReviewer.subGroupId,
        parentGroupId: selectedReviewer.parentGroupId,
        subGroupName: selectedReviewer.subGroupName,
        parentGroupName: selectedReviewer.parentGroupName,
      };
      createdBy = {
        employeeId: props.employee.employeeId,
        empName: props.employee.displayName,
        displayPicture: props.employee.displayPicture || props.employee.empInitial,
        department: props.employee.department,
        isManager: false,
        email: props.employee.email,
        country: props.employee.country,
        subGroupId: props.employee.subGroupId,
        parentGroupId: props.employee.parentGroupId,
        subGroupName: props.employee.subGroupName,
        parentGroupName: props.employee.parentGroupName,
      };
    }
    if (!(selectedReviewer && comment) && (props.page !== "myteam" || !comment)) {
      setOneOnOneCommentError(true);
    } else {
      if (props.commentFrom === "Objective") {
        body = {
          createdBy: createdBy,
          addOneOnOnewith: addOneOnOnewith,
          talkingPoints: {
            userObjectiveId: props.objective.userObjectiveId,
            surveyCycleId: props.surveyCycleId,
            title: props.objective.title,
            keyResultNumber: props.objective.keyPoints.length,
            agenda: comment.trim(),
            cycleStartDate: props.surveyCycleStartDate,
            cycleEndDate: props.surveyCycleEndDate,
            addedBy: {
              employeeId: props.employee.employeeId,
              empName: props.employee.displayName,
              displayPicture: props.employee.displayPicture || props.employee.empInitial,
              department: props.employee.department,
              subGroupId: props.employee.subGroupId,
              parentGroupId: props.employee.parentGroupId,
              subGroupName: props.employee.subGroupName,
              parentGroupName: props.employee.parentGroupName,
            },
          },
        };
      } else {
        body = {
          createdBy: createdBy,
          addOneOnOnewith: addOneOnOnewith,
          talkingPoints: {
            surveyCycleId: props.surveyCycleId,
            questionId: props.questionId,
            question: props.queTitle,
            answer: props.answer,
            agenda: comment.trim(),
            cycleStartDate: props.surveyCycleStartDate,
            cycleEndDate: props.surveyCycleEndDate,
            addedBy: {
              employeeId: props.employee.employeeId,
              empName: props.employee.displayName,
              displayPicture: props.employee.displayPicture || props.employee.empInitial,
              department: props.employee.department,
              subGroupId: props.employee.subGroupId,
              parentGroupId: props.employee.parentGroupId,
              subGroupName: props.employee.subGroupName,
              parentGroupName: props.employee.parentGroupName,
            },
          },
        };
      }
      dispatch(setOneOnOneAgenda(body, props.surveyCycleId));
    }
  };

  let manager = props.reviewer && props.reviewer.managersList;
  let displayManager = null;
  if (props.oneOnOnecomment) {
    let employeeIds = props.oneOnOnecomment.map((value) => value.addOneOnOneWith.employeeId);
    // eslint-disable-next-line no-undef
    let unique = [...new Set(employeeIds)];
    displayManager = manager.filter((value) => !unique.join(" ").includes(value.employeeId));
  } else displayManager = manager;
  return (
    <>
      {displayManager?.length > 0 || props.page === "myteam" ? (
        <div className="private-parent-wrapper comment1on1-wrapper" ref={targetMessage}>
          <div
            className="close_icon"
            onClick={() => {
              props.setCloseOneOnOne();
              setComment("");
            }}
          >
            <ReactSVG src={IMG_TOAST_CLOSE} title="" alt="" />
          </div>
          <div className="manage_comment_view private-comment-wrapper border-bottom-wrapper">
            {props.page !== "myteam" ? (
              <>
                <span className="manager_comment_text">
                  <ReactSVG className="svg" src={IC_CHAT_BUBBLE} title="" alt="lock" />
                  Add 1-on-1 with{" "}
                </span>
                <div className="manage_comment_visible_to">
                  <Form.Group className="no_mar">
                    <Form.Control
                      as="select"
                      size="md"
                      // value={selectedReviewer}
                      onChange={(e) => onSelectReviewer(e)}
                    >
                      <option key={"reviewer_option_null"} value={""}>
                        Choose a reviewer
                      </option>
                      {displayManager.map((item) => {
                        let option = {
                          employeeId: item.employeeId,
                          empName: item.empName,
                          displayPicture: item.displayPicture || item.empInitial,
                          department: item.dept,
                          email: item.emailId,
                          parentGroupId: item.parentGroupId,
                          subGroupId: item.subGroupId,
                          parentGroupName: item.parentGroupName,
                          subGroupName: item.subGroupName,
                        };
                        return (
                          <option key={"ONEONONE" + item.employeeId} value={JSON.stringify(option)}>
                            {item.empName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </div>
              </>
            ) : (
              <span className="manager_comment_text">
                <ReactSVG className="svg" src={IC_CHAT_BUBBLE} title="" alt="lock" />
                Add 1-on-1 with {props.employeeProfile && props.employeeProfile.displayName}
              </span>
            )}
          </div>
          <div className="private-msg-wrapper">
            <div className="textarea-wrapper">
              <Form.Control
                as="textarea"
                maxLength="500"
                placeholder="What do you want to discuss in your next 1:1?"
                value={comment}
                onChange={(e) => handleOneOnOneComment(e.target.value)}
              />
            </div>
            <div className="btn1on1-wrapper">
              <Button onClick={sendOneOnOne} className="btn-secondary">
                <strong>Send</strong>
              </Button>
              <span>After adding, please go to 1-on-1 page to schedule the meeting</span>
            </div>
          </div>
          {oneOnOneCommentError && (
            <Overlay target={targetMessage} show={true} placement="bottom">
              {(props) => (
                <Tooltip id="overlay-oneonone-comment" {...props} show={props.show.toString() || "true"}>
                  <span className="error-icon">
                    <ReactSVG src={IC_ERROR} title="" alt="" />
                  </span>
                  <span className="error-bubble-txt">Required field missing</span>
                </Tooltip>
              )}
            </Overlay>
          )}
        </div>
      ) : (
        <div className="oneonone-comment-limit">
          You have already send 1-on-1 all of your manager
          <div
            className="close_icon"
            onClick={() => {
              props.setCloseOneOnOne();
              setComment("");
            }}
          >
            <ReactSVG src={IMG_TOAST_CLOSE} title="" alt="" />
          </div>
        </div>
      )}
    </>
  );
}
