import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

function ThisOneOnOne(props) {
  const { oneOnOne, isPage } = props
  return (
    <div className={["box pb-0"].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>This 1-on-1</h2>
        </div>
      </div>
      <div className="box-inner-pad box-light-gradient pb-0">
          <ul className="list-item-2info">
            <li>
              <h4>Created by</h4>
              <span>{oneOnOne ? oneOnOne.createdBy.empName +  " on " + moment(oneOnOne.createdDate).format("DD MMM, YYYY") : "-"}</span>
            </li>
           {isPage !== "create" && <li>
              <h4>Due On</h4>
              {(oneOnOne && oneOnOne.scheduledDate) ? <span> {moment(oneOnOne.scheduledDate).format("MMM DD, YYYY")}
              <span className="time">{oneOnOne.fromTime + " - " + oneOnOne.toTime}</span></span> : <span>-</span>}
            </li>}
            <li>
              <h4>Ended by</h4>
              <span>{(oneOnOne && oneOnOne.endedBy) ? oneOnOne.endedBy.empName + " on " + moment(oneOnOne.endDate).format("DD MMM, YYYY") : "-"}</span>
            </li>
            {isPage !== "create" && <li>
              <h4>Other invitee</h4>
              <span>{(oneOnOne && oneOnOne.thirdperson) ? oneOnOne.thirdperson.empName : "-"}</span>
            </li>}
          </ul>
      </div>
    </div>

  )
}

ThisOneOnOne.propTypes = {
  isPage: PropTypes.string,
  oneOnOne: PropTypes.object
}

export default ThisOneOnOne

