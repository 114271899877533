import React, { useState, useEffect, useRef } from "react";
import { Form, Tooltip, Overlay } from "react-bootstrap";
import { IC_DUSTBIN, IC_CB_OBJ, IC_OBJ_ARROW_DOWN, IC_OBJ_RANGE, IC_OBJ_ARROW_UP } from "../../../const/imgCost";
import { ReactSVG } from "react-svg";
import * as actions from "../../../store/actions/index";
import { useSelector, useDispatch } from "react-redux";
import { keyPointType } from "../../../util/general";
import { IC_ERROR } from "../../../const/imgCost";

const KeyResult = (props) => {
  const dispatch = useDispatch();
  const { keyResultErrors } = useSelector((state) => state.objectives);

  const keyLength = keyPointType(props.keyPoint.type);
  const [SELength, setSELength] = useState(keyLength);

  let initialKeys = { ...props.keyPoint };

  if (props.keyPoint.type === "?") {
    delete initialKeys.start;
    delete initialKeys.end;
  } else if (props.keyPoint.type === ":") {
    delete initialKeys.start;
    delete initialKeys.end;
  }

  const [keyResult, setKeyResult] = useState(props.keyPoint);
  const [isValidate, setIsValidate] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const targetQuestion = useRef(null);

  useEffect(() => {
    checkKeyResultValidation();
  }, [keyResult]);

  const checkKeyResultValidation = () => {
    if (keyResult)
      if ((keyResult.type === ":" && !props.disabled) || keyResult.type === "$" || keyResult.type === "#") {
        setIsValidate(true);
        addError(props.index);
      }
    if (isStarted) {
      if (Object.values(keyResult).indexOf("") > -1) {
        setIsValidate(true);
        addError(props.index);
      } else if (
        keyResult.type !== "?" &&
        (+keyResult.start === +keyResult.end || +keyResult.start < 0 || +keyResult.end < 0)
      ) {
        setIsValidate(true);
        addError(props.index);
      } else if (
        keyResult.type !== "?" &&
        keyResult.type === "down" &&
        (+keyResult.start === +keyResult.end || +keyResult.start < 0 || +keyResult.end > keyResult.start)
      ) {
        setIsValidate(true);
        addError(props.index);
      } else if (
        keyResult.type !== "?" &&
        keyResult.type === "up" &&
        (+keyResult.start === +keyResult.end || +keyResult.start < 0 || +keyResult.end < keyResult.start)
      ) {
        setIsValidate(true);
        addError(props.index);
      } else if (keyResult.type !== "?" && !keyResult.start.toString().match(/^\d*\.?\d*$/)) {
        setIsValidate(true);
        addError(props.index);
      } else if (keyResult.type !== "?" && !keyResult.end.toString().match(/^\d*\.?\d*$/)) {
        setIsValidate(true);
        addError(props.index);
      } else if (
        keyResult.weightage === "" ||
        !keyResult.weightage.toString().match(/^\d*\.?\d*$/) ||
        keyResult.weightage < 1 ||
        keyResult.weightage > 100
      ) {
        setIsValidate(true);
        addError(props.index);
      } else {
        removeError(props.index);
        keyResult.edit = true;

        if (props.keyResultPoints && props.keyResultPoints.filter((value) => value.description === "").length > 0) {
          props.setAddKeyPointButton(true);
          let a1 = props.keyResultPoints.filter((value) => value.description);
          let EditableKeyresult = keyResult;
          let editingId = keyResult.keyId;
          if (typeof editingId == "string") {
            props.setKeyResultPoints([...a1, EditableKeyresult]);
          } else {
            props.setKeyResultPoints([
              ...props.keyResultPoints.map((value) => (value.keyId === editingId ? { ...keyResult } : value)),
            ]);
            // props.setKeyResultPoints([...a1, EditableKeyresult])
          }
          // delete EditableKeyresult.keyId
        } else {
          if (
            props.keyResultPoints
              .filter((value) => value.keyId === keyResult.keyId)
              .filter(
                (value) =>
                  value.description === keyResult.description &&
                  value.stretch === keyResult.stretch &&
                  value.end === (keyResult.end ? parseInt(keyResult.end) : null) &&
                  value.start === (keyResult.start ? parseInt(keyResult.start) : null) &&
                  value.weightage === parseInt(keyResult.weightage) &&
                  value.type === keyResult.type
              ).length === 0
          ) {
            let editingId = keyResult.keyId;
            props.setKeyResultPoints([
              ...props.keyResultPoints.map((value) => (value.keyId === editingId ? { ...keyResult } : value)),
            ]);
          }
        }
        dispatch(actions.ObjectiveAdded(false));
        setIsValidate(false);
      }
    }
  };

  const addError = (key) => {
    let errors = [...keyResultErrors];
    let error = { key: key };

    let index = errors.findIndex((x) => x.key === key);
    index === -1 && errors.push(error);
    dispatch(actions.SetKeyResultErrors(errors));
  };

  const removeError = (key) => {
    let errors = null;
    errors = keyResultErrors.filter((item) => item.key !== key);
    dispatch(actions.SetKeyResultErrors(errors));
  };

  const descriptionHandler = (event) => {
    setIsStarted(true);
    setKeyResult({
      ...keyResult,
      description: event.target.value.trim(),
    });
  };

  const stretchHandle = (event) => {
    setIsStarted(true);
    setKeyResult({
      ...keyResult,
      stretch: event.target.value === "true" ? true : false,
    });
  };

  const typeHandler = (event) => {
    let newKeyResult = { ...keyResult };
    const typeLength = keyPointType(event.target.value);
    setSELength(typeLength);
    setIsStarted(true);
    if (event.target.value === "?") {
      newKeyResult.type = event.target.value;
      newKeyResult.isCompleted = false;
      delete newKeyResult.start;
      delete newKeyResult.end;
    } else {
      newKeyResult.type = event.target.value;
      newKeyResult.start = "";
      newKeyResult.end = "";
    }

    setKeyResult(newKeyResult);

    document.getElementById(props.index + "_start").value = "";
    document.getElementById(props.index + "_end").value = "";
  };

  const startHandler = (event) => {
    const value = event.target.value.trim();
    setIsStarted(true);
    setKeyResult({
      ...keyResult,
      start: value,
      progress: value,
      isCompleted: false,
    });
    document.getElementById(props.index + "_start").value = value;
  };

  const endHandler = (event) => {
    const value = event.target.value.trim();
    setIsStarted(true);
    if (document.getElementById(props.index + "_start").value === keyResult.start) {
      setKeyResult({
        ...keyResult,
        end: value,
        progress: keyResult.start,
        isCompleted: false,
      });
    } else {
      setKeyResult({
        ...keyResult,
        end: value,
      });
    }
    document.getElementById(props.index + "_end").value = value;
  };

  const weightageHandler = (event) => {
    setIsStarted(true);
    setKeyResult({
      ...keyResult,
      weightage: event.target.value.trim(),
    });
  };

  return (
    <Form className="pt-3">
      <div className={[props.style.textbox_wrapper, props.style.open].join(" ")} ref={targetQuestion}>
        {!props.disabled && (
          <>
            {" "}
            <div className={props.style.co_dustbin}>
              <ReactSVG
                src={IC_DUSTBIN}
                onClick={() => props.deleteKeyResultPoint(props.keyPoint.keyId, props.index)}
              />
            </div>
          </>
        )}
        <Form.Control
          disabled={props.disabled}
          type="text"
          name="description"
          onBlur={(e) => descriptionHandler(e)}
          defaultValue={keyResult.description}
          maxLength="512"
          placeholder="Enter Key Description"
        />
        <div className={props.style.open_objectives}>
          <div className={props.style.objective_top_bar}>
            <div className="se_bt_wrapper">
              <div className={props.style.objectives_criteria}>
                <div className={props.style.oc}>
                  <input
                    disabled={props.disabled}
                    type="radio"
                    id={props.index + "percentage"}
                    name="type"
                    value="%"
                    checked={keyResult.type === "%"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.percentage_bg} htmlFor={props.index + "percentage"}>
                    %
                  </label>
                </div>
                <div className={props.style.oc}>
                  <input
                    disabled={props.disabled}
                    type="radio"
                    id={props.index + "hash"}
                    name="type"
                    value="up"
                    checked={keyResult.type === "up"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.hash_bg} htmlFor={props.index + "hash"}>
                    <ReactSVG src={IC_OBJ_ARROW_UP} />
                  </label>
                </div>
                <div className={props.style.oc}>
                  <input
                    disabled={props.disabled}
                    type="radio"
                    id={props.index + "dollar"}
                    name="type"
                    value="down"
                    checked={keyResult.type === "down"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.dollar_bg} htmlFor={props.index + "dollar"}>
                    <ReactSVG src={IC_OBJ_ARROW_DOWN} />
                  </label>
                </div>
                <div className={props.style.oc}>
                  <input
                    disabled={props.disabled}
                    type="radio"
                    id={props.index + "range"}
                    name="type"
                    value="between"
                    checked={keyResult.type === "between"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.hours_bg} htmlFor={props.index + "range"}>
                    <ReactSVG src={IC_OBJ_RANGE} />
                  </label>
                </div>
                <div className={props.style.oc}>
                  <input
                    disabled={props.disabled}
                    type="radio"
                    id={props.index + "ic_cb"}
                    name="type"
                    value="?"
                    checked={keyResult.type === "?"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.hours_bg} htmlFor={props.index + "ic_cb"}>
                    <ReactSVG src={IC_CB_OBJ} />
                  </label>
                </div>
              </div>
              <div className={props.style.base_target_wrapper}>
                <span className={keyResult.type === "%" ? "" : "d-none"}>Percentage</span>
                <span className={keyResult.type === "down" ? "" : "d-none"}>Down Skills</span>
                <span className={keyResult.type === "up" ? "" : "d-none"}>Up Skills</span>
                <span className={keyResult.type === "between" ? "" : "d-none"}>Range</span>
                <span className={keyResult.type === "?" ? "" : "d-none"}>Complete/Incomplete</span>
              </div>
            </div>
            <div className={props.style.start_end_wrapper}>
              <div className={["expectation_wrapper", keyResult.type !== "?" && "d-none"].join(" ")}>
                <span className="comp-incompn">Complete / Incomplete</span>
              </div>
              <div className={["hours_wrapper", keyResult.type !== ":" && "d-none"].join(" ")}>
                <div className={props.style.sten_wrapper}>
                  <span className={props.style.label}>Hours</span>
                  <span className={props.style.value}>
                    <Form.Control
                      disabled={props.disabled}
                      type="text"
                      name="hours"
                      id={props.index + "_hours"}
                      maxLength="3"
                      defaultValue={keyResult.hours}
                      onBlur={(e) => console.warn(e)}
                    />
                  </span>
                </div>
              </div>
              <div
                className={[
                  "se_wrapper se_bt_wrapper",
                  (keyResult.type === "?" || keyResult.type === ":") && "d-none",
                ].join(" ")}
              >
                <div className={["start-wrapper", props.style.sten_wrapper].join(" ")}>
                  <span className={props.style.value}>
                    <Form.Control
                      type="text"
                      name="start"
                      id={props.index + "_start"}
                      maxLength={SELength}
                      defaultValue={keyResult.start}
                      onBlur={(e) => startHandler(e)}
                      disabled={props.disabled}
                    />
                  </span>
                  <span className={props.style.label}>to</span>
                  <span className={props.style.value}>
                    <Form.Control
                      type="text"
                      name="end"
                      id={props.index + "_end"}
                      maxLength={SELength}
                      defaultValue={keyResult.end}
                      onBlur={(e) => endHandler(e)}
                      disabled={props.disabled}
                    />
                  </span>
                </div>
                <div
                  className={[
                    props.style.base_target_wrapper,
                    keyResult.type === "between" && props.style.range_wrapper,
                  ].join(" ")}
                >
                  {keyResult.type === "between" ? (
                    <span>Range</span>
                  ) : (
                    <>
                      <span>Base</span> <span>Target</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={["createobj_weightage", props.style.sten_wrapper].join(" ")}>
              <span className={props.style.label}>Weightage&nbsp;(%)</span>
              <span className={props.style.value}>
                <Form.Control
                  disabled={props.disabled}
                  type="text"
                  name="weightage"
                  onBlur={(e) => weightageHandler(e)}
                  defaultValue={keyResult.weightage}
                  maxLength="5"
                />
              </span>
            </div>
          </div>
          <div className={props.style.objective_bottom_bar}>
            <div className={[props.style.create_objective_wrapper, "radio_1on1_wrapper"].join(" ")}>
              <div className={props.style.create_objective_data_wrapper}>
                <Form.Group controlId="">
                  <Form.Label>Is this a Stretch Key Result?</Form.Label>
                  <ul className={props.style.form_option_list}>
                    <li>
                      <div className="custom-checkbox custom-checkbox-rounded">
                        <input
                          id={props.index + "stretch_yes"}
                          disabled={props.disabled}
                          checked={keyResult.stretch === true}
                          onChange={(e) => stretchHandle(e)}
                          type="radio"
                          value={true}
                          name="stretchGoal"
                        />
                        <label htmlFor={props.index + "stretch_yes"}>Yes</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-checkbox custom-checkbox-rounded">
                        <input
                          id={props.index + "stretch_no"}
                          disabled={props.disabled}
                          checked={keyResult.stretch === false}
                          onChange={(e) => stretchHandle(e)}
                          type="radio"
                          value={false}
                          name="stretchGoal"
                        />
                        <label htmlFor={props.index + "stretch_no"}>No</label>
                      </div>
                    </li>
                  </ul>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Overlay target={targetQuestion} show={isValidate} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-error-question" {...props} show={props.show.toString() || "true"}>
            <span className="error-icon">
              <ReactSVG src={IC_ERROR} title="" alt="" />
            </span>
            <span className="error-bubble-txt">Please fill proper details</span>
          </Tooltip>
        )}
      </Overlay>
    </Form>
  );
};

export default KeyResult;
