import React from "react";
import style from "./Hr.module.scss";
import Dashboard from "components/HR/Dashboard/Dashboard";

export default function Hr() {
  return (
    <>
      <div className={style.hr_wrapper}>
        <Dashboard />
      </div>
    </>
  );
}
