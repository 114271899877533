import React, { useState, useEffect } from "react";
import style from "../Dashboard.module.scss";
import { checkinStatusOrder, locationOrder } from "../../../../const/constValue";
import { PieChart, Pie, ResponsiveContainer, Cell, Label, BarChart, XAxis, YAxis, Bar, Tooltip } from "recharts";
import _ from "lodash";

export default function CheckinLapsedCharts(props) {
  const [pieChartData, setPieChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);

  useEffect(() => {
    const PieChartsArray = [];
    const BarChartArray = [];
    const checkinStatusMap = {
      PENDING_FOR_REVIEW: "Submitted",
      REVIEWED: "Reviewed by Manager",
      PENDING_FROM_MEMBER: "Lapsed",
    };

    if (props.checkinData) {
      props.checkinData.pieChartDataList.map((checkins) => {
        PieChartsArray.push({
          name: checkinStatusMap[checkins.status],
          value: checkins.count,
          order: checkinStatusOrder[checkins.status],
        });
        return checkins;
      });
      setPieChartData(_.sortBy(PieChartsArray, ["order"], ["asc"]));

      props.checkinData.barChartList.map((barCheckins) => {
        BarChartArray.push({
          name: barCheckins.location,
          Lapsed: barCheckins.lapsed,
          Submitted: barCheckins.submitted,
          "Reviewed by Manager": barCheckins.reviewdByManager,
          order: locationOrder[barCheckins.location],
        });
        return barCheckins;
      });
      setBarChartData(_.sortBy(BarChartArray, ["order"], ["asc"]));
    } else {
      setPieChartData([]);
      setBarChartData([]);
    }
  }, [props]);

  const COLORS = {
    Submitted: "#FF9066",
    Lapsed: "#00C8BD",
    "Reviewed by Manager": "#7B1CFF",
  };

  const [currentVal, setCurrentVal] = useState(null);

  function CustomLabel({ viewBox }) {
    const { cx, cy } = viewBox;
    let totalValue = 0;
    pieChartData.forEach((dt) => {
      totalValue += dt.value;
    });
    const total = `${totalValue}`;
    return (
      <>
        <text
          x={cx}
          y={cy - 12}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            Total
          </tspan>
        </text>
        <text
          x={cx}
          y={cy + 15}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            {total}
          </tspan>
        </text>
      </>
    );
  }

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    let countStatusLocationWise;
    if (payload) {
      payload.forEach((data) => {
        if (currentVal && currentVal === data.dataKey) {
          Object.keys(data.payload).map((key) => {
            if (key !== "name" && key !== "order") {
              totalVal += data.payload[key];
            }
            return key;
          });
          countStatusLocationWise = data.value;
          pr = (data.value / totalVal) * 100;
        }
      });
    }

    if (active) {
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper">
              <p className="recharts-tooltip-label">{currentVal}</p>
              {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
              <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${countStatusLocationWise}`}</span>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomTooltipForPieChart = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    if (active) {
      pieChartData.forEach((data) => {
        totalVal += data.value;
      });
      pr = (payload[0]["value"] / totalVal) * 100;
      return (
        <>
          <div className="recharts-tooltip-custom-wrapper">
            <p className="recharts-tooltip-label">{payload[0]["name"]}</p>
            {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
            <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${
              payload[0]["value"]
            }`}</span>
          </div>
        </>
      );
    }

    return null;
  };
  /** end bar chart*/
  return (
    <div className={["box", style.graph_wrapper].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>OKR Progress Status</h2>
        </div>
      </div>
      <div className="box-inner-pad pb-0">
        {pieChartData && pieChartData.length > 0 ? (
          <>
            <div className="d-flex">
              <div className="graph-item width50">
                <h5 data-test="aggregate">Aggregate OKR Progress Report</h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="45%"
                        cy="50%"
                        innerRadius={100}
                        outerRadius={125}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                      >
                        <Label width={30} position="center" content={<CustomLabel value1={pieChartData} />}></Label>
                        {pieChartData.map((entry, index) => {
                          return <Cell key={index} fill={COLORS[entry["name"]]} />;
                        })}
                      </Pie>
                      <Tooltip content={<CustomTooltipForPieChart />} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="graph-item width70">
                <h5 className={["pl-4", style.right_text_title].join(" ")} data-test="location">
                  Location Wise OKR Progress report
                </h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={barChartData}
                      margin={{ top: 70, right: 0, left: 0, bottom: 0 }}
                      barSize={25}
                      barGap={1}
                    >
                      <XAxis dataKey="name" axisLine={false} />
                      <YAxis axisLine={false} />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar
                        dataKey="Lapsed"
                        fill={COLORS["Lapsed"]}
                        onMouseOver={() => setCurrentVal("Lapsed")}
                        onMouseLeave={() => setCurrentVal(null)}
                      ></Bar>
                      <Bar
                        dataKey="Submitted"
                        stackId="a"
                        fill={COLORS["Submitted"]}
                        onMouseOver={() => {
                          setCurrentVal("Submitted");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      ></Bar>
                      <Bar
                        dataKey="Reviewed by Manager"
                        stackId="a"
                        fill={COLORS["Reviewed by Manager"]}
                        onMouseOver={() => setCurrentVal("Reviewed by Manager")}
                        onMouseLeave={() => setCurrentVal(null)}
                      ></Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className={style.legent_wrapper}>
              <ul className={style.lenegd_list} data-test="lenegd_list">
                {pieChartData.map((entry, index) => {
                  return (
                    <li className={style.legend_item} key={index}>
                      <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                      {entry["name"]}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        ) : (
          <div className="no-record-found-wrpr mt-3">No Data Available</div>
        )}
      </div>
    </div>
  );
}
