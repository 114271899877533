import React from "react";
import Myobjectives from "./MyObjectives/MyObjectives";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

const Objectives = () => {
  return (
    <div>
      <SubNavigation type="Objectives" />
      <Myobjectives />
    </div>
  );
};

export default Objectives;
