import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import style from "./MyObjectives.module.scss";
import { IC_EXPAND, IC_COLLAPSE } from "const/imgCost";
import { getShortName, keyValue } from "util/general";
import { objectiveStatusValue } from "const/constValue";

export default function CurrentObjectiveRow(props) {
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => state.objectives);
  const toggleExpander = (id) => {
    expanded === id ? dispatch({ type: "EXPANDED", expanded: null }) : dispatch({ type: "EXPANDED", expanded: id });
  };

  useEffect(() => {
    dispatch({ type: "EXPANDED", expanded: null });
  }, [dispatch]);

  let status = null;
  let objective = props.objective;
  if (objective.status) {
    switch (objective.status) {
      case objectiveStatusValue.COMPLETE:
        status = {
          style: style.in_progress,
          name: "Complete",
        };
        break;
      case objectiveStatusValue.ASSIGNED:
        status = {
          style: style.in_progress,
          name: "In Progress",
        };
        break;
      case objectiveStatusValue.INPROGRESS:
        status = {
          style: style.in_progress,
          name: "In Progress",
        };
        break;
      case objectiveStatusValue.EXPIRED:
        status = {
          style: style.in_completed,
          name: "Expired",
        };
        break;
      case objectiveStatusValue.INVALID:
        status = {
          style: style.invalid,
          name: "Invalid",
        };
        break;
      case objectiveStatusValue.REJECTED:
        status = {
          style: style.invalid,
          name: "Rejected",
        };
        break;
      case objectiveStatusValue.PENDING_APPROVAL:
        status = {
          style: style.pending_approval,
          name: "Pending Approval",
        };
        break;
      case objectiveStatusValue.DRAFTED:
        status = {
          style: style.drafted,
          name: "Drafted",
        };
        break;
      case objectiveStatusValue.STRIKED_OUT:
        status = {
          style: style.in_completed,
          name: "Striked Out",
        };
        break;
      default:
        return null;
    }
  }

  let pathName;
  if (props.isPage === "User") {
    pathName =
      objective.definedBy === "UNIVERSITY"
        ? `/objectives/my-objectives/universityObjective/${objective.userObjectiveId}`
        : `/objectives/my-objectives/${objective.userObjectiveId}`;
  } else {
    pathName = `/objectives/all-objectives/assigned-objective/${objective.userObjectiveId}?empId=${props.employeeId}&pgId=${objective.parentGroupId}&sgId=${objective.subGroupId}`;
  }
  return (
    <>
      <tr className={expanded === objective.userObjectiveId ? [style.active, "tr_expanded"].join(" ") : ""}>
        <td className="text-break a_name d-inline-flex border-0">
          <span className={style.expand} onClick={() => toggleExpander(objective.userObjectiveId)}>
            {expanded === objective.userObjectiveId ? (
              <ReactSVG src={`${IC_COLLAPSE}`} />
            ) : (
              <ReactSVG src={`${IC_EXPAND}`} />
            )}
          </span>
          <Link to={pathName}>{objective.title}</Link>
        </td>
        <td>
          <div className={status.style}>{status.name}</div>
        </td>
        <td className={style.assign_by_name}>
          {objective.managerData?.empName && getShortName(objective.managerData?.empName)}
        </td>
        {/* <td>{objective.keyPoints && objective.keyPoints.length} </td> */}
        <td>{/* {objective.hours} Hours {objective.minutes} Mins  */}</td>
        <td className={style.progress_value}>
          {props.isPage === "User" ? (
            objective.progressStatus === "GREEN" ? (
              <span className={style.green}></span>
            ) : objective.progressStatus === "ORANGE" ? (
              <span className={style.yellow}></span>
            ) : objective.progressStatus ? (
              <span className={style.red}></span>
            ) : (
              <span></span>
            )
          ) : objective.percentage >= 66 ? (
            <span className={style.green}></span>
          ) : objective.percentage > 33 ? (
            <span className={style.yellow}></span>
          ) : (
            <span className={style.red}></span>
          )}
          {}
          {objective.percentage}%
        </td>
      </tr>

      {expanded === objective.userObjectiveId && (
        <tr>
          <td colSpan="5">
            {objective.keyPoints?.length > 0 &&
              objective.keyPoints.map((keyPoint, index) => (
                <ul key={index}>
                  <li className="text-break">{keyPoint.description}</li>
                  <li className={style.key_value}>{keyValue(keyPoint)}</li>
                  <li>{keyPoint.percentage}%</li>
                </ul>
              ))}
          </td>
        </tr>
      )}
    </>
  );
}
