import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { IC_DUSTBIN, IC_ACC_ARROW_DOWN } from "../../../../const/imgCost";
import OneOnOneCommentDisplay from "../../Components/OneOnOneCommentDisplay";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";

function TalkingPointInputDisplay(props) {
  const { point, deletePoint, index, loggedInUser, managerId, userId, deletable, allSelect, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handledisplayComment = () => {
    setIsOpen(!isOpen);
  };

  let answer = null;
  if (point.questionId === "5e157d7129edca592691c273") answer = `${point.answer}/10`;
  else answer = point.answer;
  let isTalkingdisabled = deletable ? true : false;
  return (
    <li className={[isOpen ? style.show_comment : "", point.isCompleted || allSelect ? style.disabled : ""].join(" ")}>
      <div className="custom-checkbox custom-checkbox-rounded">
        <input
          id={point.talkingPointId}
          type="checkbox"
          disabled={isTalkingdisabled || disabled || allSelect}
          value={!point.isCompleted}
          checked={point.isCompleted || allSelect}
          onChange={(e) => props.markTalkingPoint(e, point.talkingPointId)}
        />
        <label htmlFor={point.talkingPointId}>
          {point.surveyCycleId ? point.question || point.title : point.agenda}
        </label>
        <div className={style.comment_delete_wrapper}>
          {point.surveyCycleId && (
            <span className={style.comment} onClick={handledisplayComment}>
              Comment
              <img src={IC_ACC_ARROW_DOWN} alt="delete" />
            </span>
          )}
          {point.addedBy.employeeId === loggedInUser.employeeId && deletable && (
            <span className={style.delete} onClick={() => deletePoint(point.talkingPointId, index)}>
              <img src={IC_DUSTBIN} alt="delete" />
            </span>
          )}
        </div>
        {point.keyResultNumber && (
          <span className={style.tp_info_wrapper}>{point.keyResultNumber} Key Results aligned</span>
        )}
        <span className={style.tp_info_wrapper}>
          Added By {point.addedBy.empName}
          <span className={style.time_info}>{moment(point.agendaAddedDate).fromNow()}</span>
        </span>
      </div>
      {isOpen && (
        <div className={style.one_on_one_comment_wrpper}>
          <div className={style.one_on_one_comment}>
            {managerId === loggedInUser.employeeId ? (
              <Link
                className={style.cycle}
                to={{
                  pathname: `/okrprogress/my-team/member-okrprogress/${userId}`,
                  state: { surveyCycleId: point.surveyCycleId },
                }}
              >
                {moment(point.cycleStartDate).format("MMM DD, YYYY")} –{" "}
                {moment(point.cycleEndDate).day("add", -1).format("MMM DD, YYYY")}
              </Link>
            ) : (
              <Link
                className={style.cycle}
                to={{
                  pathname: `/okrprogress/my-history`,
                  state: point.surveyCycleId,
                }}
              >
                {moment(point.cycleStartDate).format("MMM DD, YYYY")} –{" "}
                {moment(point.cycleEndDate).day("add", -1).format("MMM DD, YYYY")}
              </Link>
            )}
            <p>{answer}</p>
            <ul className={style.comment_list_user}>
              <OneOnOneCommentDisplay
                name={point.addedBy.empName}
                date={point.agendaAddedDate}
                comment={point.agenda}
                image={point.addedBy.displayPicture}
              />
            </ul>
          </div>
        </div>
      )}
    </li>
  );
}

TalkingPointInputDisplay.propTypes = {
  point: PropTypes.shape({
    isCompleted: PropTypes.bool,
    talkingPointId: PropTypes.number.isRequired,
    question: PropTypes.string,
    addedBy: PropTypes.shape({
      employeeId: PropTypes.string.isRequired,
      empName: PropTypes.string.isRequired,
      displayPicture: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    agenda: PropTypes.string,
    agendaAddedDate: PropTypes.string,
    title: PropTypes.string,
    surveyCycleId: PropTypes.string,
  }),
};

export default TalkingPointInputDisplay;
