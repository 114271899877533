import React from "react";
import { getColorByIndex } from "../../../../util/color";
import { dateFormat1 } from "../../../../util/dateUtils";
import ViewMoreText from "../../../ViewMoreText/ViewMoreText";
import style from "./HRGlobalFeedback.module.scss";

function HRGlobalFeedbackRow({
  displayPicture,
  empName,
  toggle,
  toggleText,
  employeeId,
  designation,
  department,
  feedbackList,
  userFeedbackIndex,
}) {
  return (
    <div className={style.item_feedback_top_wrapper}>
      <div className={style.item_feedback_top_bar}>
        <div className={style.member_feedback_info}>
          <span
            className={style.user_img}
            style={{
              backgroundColor: getColorByIndex(userFeedbackIndex),
            }}
          >
            {displayPicture && displayPicture.length > 2 ? <img src={displayPicture} alt="pic" /> : displayPicture}
          </span>
          <div className={style.user_info}>
            <span className={style.username}>{empName}</span>
            <span className={style.department}>
              {designation || "--"} | {department || "--"}
            </span>
          </div>
        </div>
        <ul className={style.feedbackListContainer}>
          {feedbackList &&
            feedbackList.length &&
            feedbackList.map((feedbackItem, feedbackIndex) => (
              <React.Fragment key={feedbackIndex}>
                {feedbackIndex <= (toggle === employeeId ? feedbackList.length : 1) && (
                  <li className={style.teams_feedback_list}>
                    <span className={style.skillgaps_feedback_text}>{dateFormat1(feedbackItem.createdDate)}</span>

                    <div className={style.questions_feedback_answer_row}>
                      <div className="w-100">
                        <ul className="common_list_items">
                          {feedbackItem.feedback && feedbackItem.feedback.length < 100 ? (
                            <li>{feedbackItem.feedback ? feedbackItem.feedback : "N/A"}</li>
                          ) : (
                            <li>
                              <ViewMoreText text={feedbackItem.feedback} length={100} />
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div>
      {feedbackList.length > 2 && (
        <div className={style.viewFeedbackContainer}>
          <span
            className={style.view_feedback_link}
            id={["toggleButton", employeeId].join(" ")}
            onClick={() => toggleText(employeeId)}
          >
            {toggle === employeeId ? "View Less" : "View all feedback"}
          </span>
        </div>
      )}
    </div>
  );
}

export default HRGlobalFeedbackRow;
