import React, { useEffect, useState } from "react";
import style from "../CycleMemberProfile/CycleMemberProfile.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import {
  requiredFieldMissing,
  maximumFileSize,
  maximumFiveFile,
  successToast,
  errorToast,
} from "../../../util/general";
import UserProfile from "../../user_profile/user_profile";
import SidebarCheckInActivity from "../../sidebar_checkins/sidebar_checkins";
import ReviewerProfile from "../../reviewer-profile/reviewer-profile";
import Rewards from "../../Rewards/Rewards";
import storage from "../../../util/storage";
import SelfAssessmentObjective from "./SelfAssessmentObjective";
import QuestionTemplate from "../CycleMemberProfile/QuestionTemplate/QuestionTemplate";
import SubmitBox from "../../SubmitBox/SubmitBox";
import SubmittedDateBox from "../../SubmitBox/SubmittedDateBox";
import GlobalReadOnlyComment from "../GlobalComment/GlobalReadOnlyComment";
import GlobalComment from "../GlobalComment/GlobalComment";
import Spinner from "../../../shared/Spinner/Spinner";
import BoxHeading from "../../../shared/Box/BoxHeading";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import { menuText } from "const/constValue";

function SelfAssessment() {
  const loggedInUser = storage.getUser();
  const loggedInUserEmpIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  const dispatch = useDispatch();
  const {
    getPrivateAssessmentData,
    objectives,
    isFileLoading,
    isSubmitted,
    isPerformanceResponseLoading,
    isPrivateAssessmentLoading,
    fileResponse,
    globalFeedbackFileResponse,
    updatedDate,
    submittedDate,
    paSubmitResponse,
    questionTemplateErrors,
    expanded,
    globalSELFComment,
    globalSELFCommentUserData,
  } = useSelector((state) => state.performance);
  const [selfAssessmentError, setSelfAssessmentError] = useState([]);
  const { user } = useSelector((state) => state.myTeam);
  const { filterSurveyData } = useSelector((state) => state.myHistory);
  const count = useSelector((state) => state.madeMyDay.appreciateCount);
  const questionsAnswerTemplate = getPrivateAssessmentData?.selfQuestionTemplate;
  if (questionsAnswerTemplate) questionsAnswerTemplate.type = "SELF";
  const privateAssessmentId = getPrivateAssessmentData?.privateAssessmentId;
  const questionTemplateId = questionsAnswerTemplate?.questionTemplateId;
  const objectivePrivateAssessmentId = getPrivateAssessmentData?.objectivePrivateAssessmentId;
  const isPage = "SELF";
  const cycleId = getPrivateAssessmentData?.cycleId || null;
  const handleAppreciateDuration = (select) =>
    dispatch(actions.GetAppreciateDurationCount(loggedInUserEmpIds, select.value));
  useEffect(() => {
    dispatch({ type: "CLEAR_MY_TEAM_DATA" });
    dispatch(actions.GetUser(loggedInUserEmpIds));
    dispatch(actions.GetSelfAssessmentGlobalComment(loggedInUserEmpIds, cycleId));
    dispatch(actions.GetPrivateAssessmentData(loggedInUserEmpIds, cycleId, "SELF"));
    dispatch(actions.GetFilterSurveyHistory({ ...loggedInUserEmpIds, filterByMonth: 12 }));
    dispatch(actions.GetAppreciateDurationCount(loggedInUserEmpIds, 6));
  }, [dispatch, cycleId]);
  useEffect(() => {
    if (objectives) {
      let assessmentCommentErrors = [];
      objectives
        .filter(
          (value) => value?.weightage !== null && value.status !== "DRAFTED" && !(value.selfAssessmentComment && value.selfAssessmentComment.comment)
        )
        .forEach((value) => assessmentCommentErrors.push({ comment: "", commentId: value.userObjectiveId }));
      setSelfAssessmentError(assessmentCommentErrors);
    }
  }, [objectives]);

  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);
      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
    if (paSubmitResponse) {
      if (paSubmitResponse.success) successToast(paSubmitResponse.message);
      else errorToast(paSubmitResponse.message);

      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
  }, [fileResponse, paSubmitResponse, dispatch, cycleId]);

  useEffect(() => {
    if (globalFeedbackFileResponse) {
      if (globalFeedbackFileResponse.success) successToast(globalFeedbackFileResponse.message);
      else errorToast(globalFeedbackFileResponse.message);
    }
  }, [globalFeedbackFileResponse]);

  const submitSelfAssessment = () => {
    dispatch({
      type: "SET_QUESTION_TEMPLATE_VALIDATION",
      data: questionTemplateErrors,
    });
    let setError = selfAssessmentError.map((value) => {
      return {
        ...value,
        isSubmit: true,
      };
    });
    setSelfAssessmentError(setError);
    if (selfAssessmentError.findIndex((value) => value.comment === "") !== -1) {
      requiredFieldMissing();
      return;
    } else if (
      questionTemplateErrors?.errors?.length > 0 &&
      questionTemplateErrors?.type === "SELF" &&
      questionTemplateErrors.errors.filter((value) => value.error || value.commentBoxError).length > 0
    ) {
      requiredFieldMissing();
      return;
    } else dispatch(actions.SavePrivateAssessmentAnswers(privateAssessmentId, loggedInUserEmpIds, "SELF", cycleId));
  };

  //Question template upload download and delete file
  const downloadFile = (fileId, questionId) => {
    let params = {
      questionId: questionId,
      fileId,
      privateAssessmentId,
      questionTemplateId,
      assessmentType: "SELF",
    };
    dispatch(actions.DownloadFilePrivateAssessment(params));
  };

  const uploadFile = (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("file", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("questionId", id);
      formData.append("privateAssessmentId", privateAssessmentId);
      formData.append("questionTemplateId", questionTemplateId);
      formData.append("assessmentType", "SELF");
      dispatch(actions.UploadFilePrivateAssessment(formData, loggedInUserEmpIds, cycleId, "SELF"));
    } else maximumFiveFile();
  };

  const deleteFile = (fileId, queId) => {
    const data = {
      questionId: queId,
      fileId,
      privateAssessmentId: privateAssessmentId,
      assessmentType: "SELF",
    };
    dispatch(actions.DeleteFilePrivateAssessment({ data: data }, loggedInUserEmpIds, cycleId, "SELF"));
  };

  //Objectives Files
  const uploadFileObjective = (e, files, userObjectiveId) => {
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5 && files?.length + i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("files", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("objectivePrivateAssessmentId", objectivePrivateAssessmentId);
      formData.append("userObjectiveId", userObjectiveId);
      formData.append("assessmentType", isPage);
      dispatch(actions.UploadFileObjectiveAssessment(formData, loggedInUserEmpIds, cycleId, "SELF"));
    } else maximumFiveFile();
  };

  const UploadFileGlobalFeedback = (e, files) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5 && files?.length + i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("files", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("assessmentType", isPage);
      formData.append("quarterlyCycleId", cycleId);
      formData.append("privateAssessmentId", privateAssessmentId);
      dispatch(actions.uploadFileGlobalFeedback(formData, loggedInUserEmpIds, cycleId, "SELF"));
    } else maximumFiveFile();
  };

  const downloadFileObjective = (id, userObjectiveId) => {
    const params = {
      userObjectiveId: userObjectiveId,
      quarterlyCycleId: cycleId,
      fileId: id,
    };
    dispatch(actions.DownloadFileObjectiveAssessment(params));
  };
  const downloadFileGlobalFeedback = (id) => {
    const params = {
      fileId: id,
      privateAssessmentId: privateAssessmentId,
    };
    dispatch(actions.DownloadFileGlobalFeedback(params));
  };

  const removeFileObjective = (id, userObjectiveId) => {
    const data = {
      userObjectiveId: userObjectiveId,
      quarterlyCycleId: cycleId,
      fileId: id,
    };
    dispatch(actions.DeleteFilObjectiveAssessment({ data: data }, loggedInUserEmpIds, cycleId, "SELF"));
  };

  const deleteFileGlobalFeedback = (id) => {
    const data = {
      fileId: id,
      privateAssessmentId: privateAssessmentId,
    };
    dispatch(actions.DeleteFileGlobalFeedback(data, loggedInUserEmpIds, cycleId, "SELF"));
  };

  const saveQuestionAnswer = (data) =>
    dispatch(actions.AutoSavePrivateAssessmentTemplate(data, privateAssessmentId, questionTemplateId, "SELF"));

  const sendGlobalComment = (e) => {
    if (e.target.value?.trim())
      dispatch(
        actions.SetAssessmentGlobalComment(
          e.target.value.trim(),
          privateAssessmentId,
          cycleId,
          loggedInUserEmpIds,
          "SELF"
        )
      );
  };

  let reviewers = null;
  if (getPrivateAssessmentData?.allRM || getPrivateAssessmentData?.allRVM) {
    let allManager = (getPrivateAssessmentData?.allRM || []).concat(getPrivateAssessmentData?.allRVM || []);
    // eslint-disable-next-line no-undef
    const uniqueManager = Array.from(new Set(allManager.map((a) => a.employeeId))).map((id) => {
      return allManager.find((a) => a.employeeId === id);
    });
    reviewers = {
      managersList: uniqueManager,
    };
  }

  if (isPrivateAssessmentLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.cycleTeamWrapper}>
      <SubNavigation type="Performance" />
      {(isFileLoading || isPerformanceResponseLoading) && <Spinner />}
      {isPrivateAssessmentLoading ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col md={12} className="col-12">
              <h1 className="page-title pb-0">{menuText.PERFORMANCE}</h1>
              <span className="st-breadcrumb">
                <Link to={routePath.PERFORMANCE_DASHBOARD}>{menuText.DASHBOARD}</Link>
                <span>{menuText.SELF_ASSESSMENT}</span>
                <span>{getPrivateAssessmentData && getPrivateAssessmentData.cycle}</span>
              </span>
            </Col>
            {getPrivateAssessmentData?.cycle && (
              <Col className="col-12">
                <BoxHeading heading={getPrivateAssessmentData?.cycle} description={getPrivateAssessmentData?.period} />
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={8}>
              {user && <UserProfile user={user} />}
              {objectives && objectives.length > 0 && (
                <SelfAssessmentObjective
                  uploadFileObjective={uploadFileObjective}
                  downloadFileObjective={downloadFileObjective}
                  removeFileObjective={removeFileObjective}
                  expanded={expanded}
                  objectives={objectives}
                  privateAssessmentId={privateAssessmentId}
                  objectivePrivateAssessmentId={objectivePrivateAssessmentId}
                  cycleId={cycleId}
                  isSubmit={isSubmitted}
                  managerView={false}
                  setSelfAssessmentError={setSelfAssessmentError}
                  selfAssessmentError={selfAssessmentError}
                />
              )}
              {questionsAnswerTemplate && (
                <QuestionTemplate
                  isPage="SELF"
                  handleTextBox={saveQuestionAnswer}
                  handleRating={saveQuestionAnswer}
                  handleRatingComment={saveQuestionAnswer}
                  handleRadioComment={saveQuestionAnswer}
                  handleRadio={saveQuestionAnswer}
                  handleRadioOther={saveQuestionAnswer}
                  handleCheckBox={saveQuestionAnswer}
                  handleCheckBoxOther={saveQuestionAnswer}
                  handleCheckBoxComment={saveQuestionAnswer}
                  handleDropDown={saveQuestionAnswer}
                  handleDropDownComment={saveQuestionAnswer}
                  handleDropDownOther={saveQuestionAnswer}
                  handleScale={saveQuestionAnswer}
                  handleScaleComment={saveQuestionAnswer}
                  handleRankComment={saveQuestionAnswer}
                  handleRank={saveQuestionAnswer}
                  handleNps={saveQuestionAnswer}
                  handleNpsComment={saveQuestionAnswer}
                  questionTemplateErrors={questionTemplateErrors}
                  isSubmitted={isSubmitted}
                  questionsAnswerTemplate={questionsAnswerTemplate}
                  uploadFile={uploadFile}
                  downloadFile={downloadFile}
                  viewTemplateName={true}
                  deleteFile={deleteFile}
                />
              )}
              {isSubmitted ? (
                <GlobalReadOnlyComment
                  downloadFileGlobalFeedback={downloadFileGlobalFeedback}
                  heading="Global Feedback"
                  description="Here is what your team member want to say to you"
                  comment={globalSELFCommentUserData}
                  message="You haven't shared any views for this"
                />
              ) : (
                <GlobalComment
                obj= {globalSELFCommentUserData}
                  heading="Global Feedback"
                  optional={true}
                  loggedInUser={loggedInUser}
                  employeeComment={globalSELFComment.find((v) => v)}
                  comments={[]}
                  sendGlobalComment={sendGlobalComment}
                  description="Here is what you want to say to your manager"
                  isMultipleFile={true}
                  isPageSelf={true}
                  UploadFileGlobalFeedback={(e) => UploadFileGlobalFeedback(e, [])}
                  downloadFileGlobalFeedback={downloadFileGlobalFeedback}
                  deleteFileGlobalFeedback={deleteFileGlobalFeedback}
                />
              )}
              {isSubmitted ? (
                <SubmittedDateBox submittedDate={submittedDate} />
              ) : (
                <SubmitBox handleSubmit={submitSelfAssessment} updatedDate={updatedDate} name="Submit" />
              )}
            </Col>
            <Col lg={4}>
              {filterSurveyData && <SidebarCheckInActivity filterSurveyData={filterSurveyData} />}
              {count && <Rewards count={count} handleAppreciateDuration={handleAppreciateDuration} />}
              {reviewers?.managersList?.length > 0 && <ReviewerProfile managerList={reviewers?.managersList} />}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default SelfAssessment;
