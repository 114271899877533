import React from "react";
import moment from "moment";
import ActionBox from "./ActionBox/ActionBox";

const RatingAnswer = (props) => {
  return (
    <>
      <div className="two_column">
        <div className="box_inner_title">{props.question.queTitle}</div>
        <ActionBox {...props} answer={props.question.employeeComment} />
      </div>

      <div className={[props.style.workload_rating_result_wrapper, props.style.employee_rating].join(" ")}>
        <ul className={[props.style.workload_rating, props.style.workload_rating_current].join(" ")}>
          <li>
            <div className={props.style.workload_rating_result}>
              {props.question.answer}
              <span>/</span>10
            </div>
            <div className={props.style.workload_rating_result_duration}>
              Current OKR Progress
              {/* {checkinDetails ? moment(checkinDetails.start).format("MMM DD") : ""} – {checkinDetails ? moment(checkinDetails.end).format("MMM DD") : ""} */}
            </div>
          </li>
        </ul>
        {props.surveyCycle.workloadList && props.surveyCycle.workloadList.length > 0 && (
          <ul className={[props.style.workload_rating, props.style.workload_rating_old].join(" ")}>
            {props.surveyCycle.workloadList.map((item) => {
              return (
                <li key={item.surveyCycleId}>
                  <div className={props.style.workload_rating_result}>
                    {item.answer}
                    <span>/</span>10
                  </div>
                  <div className={props.style.workload_rating_result_duration}>
                    {moment(item.startDate).format("MMM DD")} – {moment(item.endDate).format("MMM DD")}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      {props.question.employeeComment ? (
        <div className={props.style.questions_answer_row}>
          <div className={props.style.questions_answer}>
            <ul className="common_list_items">
              <li>{props.question.employeeComment}</li>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RatingAnswer;
