import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import { GET_FEEDBACK } from "../../util/endpoints";
import { status } from "const/constValue";

export const fetchFeedbacks = (password) => (dispatch) => {
  http
    .get(GET_FEEDBACK, { password: password })
    .then((response) => response.data)
    .then((response) => {
      if (response) {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SET_FEEDBACKS,
            feedbacks: response.data && response.data.reverse(),
            password: password,
            authorizedResponse: "SUCCESS",
          });
        } else {
          dispatch({
            type: actionTypes.SET_FEEDBACKS,
            feedbacks: [],
            password: "",
            authorizedResponse: "FAILURE",
          });
        }
      }
    })
    .catch((error) => console.warn("ENGAGE", error));
};
