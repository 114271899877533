import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import style from "./CycleListManagerEmp.module.scss";
import { IC_FLAG } from "../../../const/imgCost";
import { dateFormat2 } from "../../../util/dateUtils";
import { toFixedNumber } from "../../../util/utils";
import routePath from "const/routePath";

function ReporteeCycleListRow({
  data,
  toggleHandleReportee,
  cycleID,
  isPage,
  backgroundColor,
  isSelfAssessmentAvailable,
}) {
  const { employeeId, empName, department, designation, image, parentGroupId, subGroupId } = data.employeeData;
  return (
    <>
      <tr className={style.managers_team_row}>
        <td className={style.cb_box}></td>
        <td className={style.emp_id}>{employeeId}</td>
        <td className={[style.emp_name, style.emp_flag].join(" ")}>
          <div className="custom-checkbox custom-checkbox-sm no_mar">
            <input
              id={employeeId + "REPORTEE"}
              type="checkbox"
              value={employeeId + "REPORTEE"}
              onChange={(e) => toggleHandleReportee(e, employeeId)}
              checked={data.isChecked}
            />
            <label htmlFor={employeeId + "REPORTEE"}></label>
          </div>

          {data.flagged && (
            <span className={style.flag_icon}>
              <ReactSVG src={IC_FLAG} alt="flag" />
            </span>
          )}

          <div className={style.tab_team_member_info}>
            <div className={style.team_member_info}>
              <span className={style.team_member_img} style={{ backgroundColor }}>
                {image && image.length > 2 ? <img src={image} alt="" /> : image}
              </span>

              <div className={style.team_member_details}>
                <span className={style.team_member_name}>
                  <Link
                    to={`${routePath.CYCLE_MEMBER_PROFILE}/${cycleID}?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}&assessmentType=${isPage}`}
                  >
                    {empName}
                  </Link>
                </span>
                <span className={style.team_member_designation}>
                  {department} | {designation}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td className={style.private_assessment}>{toFixedNumber(data.privateAssessmentRatings, 2)}</td>
        {isSelfAssessmentAvailable && (
          <>
            <td className={style.private_assessment}>{toFixedNumber(data.selfAssessmentAvgRating, 2)}</td>
            <td className={style.private_assessment}>{data.selfAssessmentGrade || "-"}</td>
          </>
        )}
        <td className={style.rating}>{toFixedNumber(data.managerRating, 2)}</td>
        <td>{data.grade || "-"}</td>
        <td className={style.submitted_on}>{dateFormat2(data.submittedDate)}</td>
      </tr>
    </>
  );
}

ReporteeCycleListRow.propTypes = {
  toggleHandleReportee: PropTypes.func,
};

export default ReporteeCycleListRow;
