import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import style from "./RequestsList.module.scss";
import { filterArray } from "util/general";
import Pagination from "../../../Pagination/Pagination";
import NoDataFound from "../../../NodataFound/NoDataFound";

export default function RequestsList(props) {
  const { size } = useSelector((state) => state.skillMatrix);

  const [typeArr, setFilterType] = useState("ALL");
  const [skillList, setSkillList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const filterByRequest = (e) => {
    setFilterType(e.target.value);
  };

  useEffect(() => {
    let requestArray = [];
    if (!(typeArr === "ALL")) {
      requestArray = getResultArray(props.data, typeArr);
    } else {
      requestArray = props.data;
    }
    setSkillList(requestArray);
  }, [typeArr, props.data]);

  const getResultArray = (array_to_filter, toCheck) => {
    let result_arr = [];
    if (array_to_filter) {
      array_to_filter.filter((item) => {
        if (item.status === toCheck) {
          result_arr.push(item);
        }
        return item;
      });
    }
    return result_arr;
  };

  useEffect(() => {
    filteredData.length > 0 ? setSkillList(filteredData) : null ? setSkillList(filteredData) : setSkillList(props.data);
  }, [filteredData, props.data]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (props.data && props.data.length > 0) {
      filteredData = filterArray(skillList, filters);
      setFilteredData(filteredData);
    }
  };

  const onChangePage = (skillList) => {
    setPageOfItems(skillList);
  };

  return (
    <>
      <div className={[style.decline_req_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading w-auto">
            <h2>Technical Role Change Requests</h2>
            <h3>Requests for Tech Role Change</h3>
          </div>
          <div className="tab_check_in_status">
            <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="all"
                type="radio"
                name="status-check"
                value="ALL"
                checked={typeArr === "ALL"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="all">All</label>
            </div>

            <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="declined"
                type="radio"
                name="status-check"
                value="REJECTED"
                checked={typeArr === "REJECTED"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="declined">Declined</label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="approved"
                type="radio"
                name="status-check"
                value="APPROVED"
                checked={typeArr === "APPROVED"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="approved">Approved</label>
            </div>
          </div>
        </div>
        <div className="box-light-gradient h38"></div>
        {pageOfItems && pageOfItems.length !== 0 ? (
          <div className="box-inner-pad pb-0">
            <div className={[style.skill_table, "custom-table", "table-theme-1"].join(" ")}>
              <div className={[style.endorsement_skill_table, "position-relative"].join(" ")}>
                <ul className={style.thead}>
                  <li>
                    <span className={style.endorser_name}>Employee Name</span>
                    <span className={style.skill_name}>Old Technical Role</span>
                    <span className={style.dec_date}>New Technical Role</span>
                    <span className={style.req_date}>Request Date</span>
                    <span className={[style.cmp_lvl, style.current].join(" ")}>Status</span>
                    <span className={style.comments}>Comment</span>
                  </li>
                </ul>
                <ul className={style.tbody}>
                  {pageOfItems.map((item) => (
                    <li key={item.changeRequestId}>
                      <div className={style.endorser_name}>
                        <span>{item.requestedBy.empName ? item.requestedBy.empName : "-"}</span>
                      </div>
                      <div className={style.skill_name}>
                        <span>{item.currentTechnicalRoleName ? item.currentTechnicalRoleName : "-"}</span>
                      </div>
                      <div className={style.dec_date}>
                        <span>{item.requestedTechnicalRoleName ? item.requestedTechnicalRoleName : "-"}</span>
                      </div>
                      <div className={style.req_date}>
                        <span>{moment(item.createdDate).format("MMM D, YYYY")}</span>
                      </div>
                      <div className={[style.cmp_lvl, style.current].join(" ")}>
                        {item.status === "PENDING" && (
                          <span className={[style.req_comp_level, "purple"].join(" ")}>Pending</span>
                        )}
                        {item.status === "APPROVED" && (
                          <span className={[style.req_comp_level, "green"].join(" ")}>Approved</span>
                        )}
                        {item.status === "REJECTED" && (
                          <span className={[style.req_comp_level, "orange"].join(" ")}>Declined</span>
                        )}
                      </div>
                      <div className={style.comments}>
                        <div className={style.comment_txt}>
                          {item.comment ? item.comment.slice(0, 20) : "-"}
                          <div className="d-inline-block position-relative">
                            {item.comment && item.comment.trim().length > 20 && (
                              <span className={style.more}>...More</span>
                            )}
                            <span className={style.description_tooltip}>{item.comment}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <NoDataFound />
            <div className="gap40"></div>
            <div className="gap40"></div>
            <div className="gap40"></div>
          </div>
        )}
      </div>
      {props.data && props.data.length > 0 ? (
        <Pagination pageSize={size} items={skillList} onChangePage={onChangePage} resetData={resetData} />
      ) : null}
    </>
  );
}
