import React from "react";
import { Button } from "react-bootstrap";
import style from "../../SaveCancelBox/SaveCancelBox.module.scss";

const AssignBackBox = ({ disabled, handleAssign, handleBack }) => {
  return (
    <div>
      <div className={["box box-flex-center", style.savecancle_box].join(" ")}>
        <div className={style.save_box_left}>
          <Button
            type="button"
            disabled={disabled}
            variant={"primary"}
            className={["btn-round", style.btn_save].join(" ")}
            onClick={handleAssign}
          >
            <strong>Assign</strong>
          </Button>
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_cancel].join(" ")}
            onClick={handleBack}
          >
            <strong>Back</strong>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AssignBackBox;
