import React, { useEffect, useState, Fragment } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import style from "./University.module.scss";
import { IMG_UNIVERSITY } from "const/imgCost";
import storage from "util/storage";
import MyCertificate from "components/MyCertificate/MyCertificate";
import SelectCertificate from "components/SelectCertificate/SelectCertificate";
import SidebarCertificate from "components/SidebarCertificate/SidebarCertificate";
import { firstWord } from "util/general";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import InfoBannerContact from "components/InfoContactBanner/InfoBannerInfo";
import { wmgBannerText, prismForceText, prismForceUrl } from "const/constValue";

export default function University() {
  const [show, setShow] = useState(false);
  const user = storage.getUser() || {};
  const showpopup = storage.getJSON("showpopup") || {};
  useEffect(() => {
    if (!showpopup[user.employeeId]) {
      setShow(true);
    }
  }, [showpopup, user.employeeId]);

  const handleClose = () => {
    setShow(false);
    const showpopup = storage.getJSON("showpopup") || {};
    if (!showpopup[user.employeeId]) {
      showpopup[user.employeeId] = true;
      storage.setJSON("showpopup", showpopup);
    }
  };

  return (
    <Fragment>
      <InfoBannerContact heading={wmgBannerText} text={prismForceText} linkUrl={prismForceUrl} />
      {/* <SubNavigation type="WMG" /> */}
      <Container>
        <Alert className="alert_welcome_university" variant="primary" show={show}>
          <div className="welcome_wrapper">
            <div className="welcome_text">
            {/* <h2 className="title">Welcome to {firstWord(user.parentGroupName)} University</h2> */}
             {/* changed to static value for parent group   */}
              <h2 className="title">Welcome to Apexon University</h2>
              <p>
                University would enable your learnings by providing the right tools and well-defined learning paths
                which would be tracked very closely. University&apos;s immediate goal is to ensure that your learnings
                are tracked in Jira. While we are consolidating the data from Jira and NEST to ensure 100% Jira
                compliance, University would encourage you to proactively reach out to {firstWord(user.parentGroupName)}{" "}
                University Program Leads in case you are not tracking your current learnings at Jira. In parallel,
                University has also asked {firstWord(user.parentGroupName)} program leads to reach out to you for this
                consolidation.
              </p>
            </div>
            <span className="welcome_img">
              <img src={IMG_UNIVERSITY} alt="University" />
            </span>
            <Button onClick={handleClose} variant="custom" disabled></Button>
          </div>
        </Alert>
      </Container>

      <div className={style.university_wrapper}>
        <Container>
          <Row>
            <Col md={12} lg={8}>
              <SelectCertificate />
            </Col>
            <Col md={12} lg={4}>
              <SidebarCertificate />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <MyCertificate />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
