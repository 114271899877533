import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_NOTIFY } from "const/imgCost";

function AcceptAndReject(props) {
  const { text, handleReject, handleAccept, disabled } = props;
  return (
    <div className="mng_obj_notification">
      <span>
        <ReactSVG src={`${IC_NOTIFY}`} />
      </span>
      <p>{text}</p>
      <Button
        disabled={disabled}
        type="button"
        variant={"primary"}
        className="btn-round btn_save"
        onClick={handleAccept}
      >
        <strong>Accept</strong>
      </Button>
      <Button type="button" variant={"secondary"} className="btn-round btn_cancel" onClick={handleReject}>
        <strong>Reject</strong>
      </Button>
    </div>
  );
}

AcceptAndReject.propTypes = {
  text: PropTypes.string,
  handleReject: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
};

export default AcceptAndReject;
