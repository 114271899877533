import * as actionTypes from "../actions/actionTypes";

const initialState = {
  currentOneOnOneData: [],
  previousOneOnOneData: [],
  questionComment: [],
  sendAgendaResponse: null,
  user: null,
  isLoading: true,
  oneOnOne: null,
  scheduleResponse: null,
  endedResponse: null,
  ended: false,
  managerList: [],
  reporteeList: [],
  teamFeedbackList: [],
  isOneOnOneLoading: true,
  isCurrentLoading: true,
  isPreviousLoading: true,
  managerId: null,
  userId: null,
  oneOnOneResponse: null,
  globalFeedBack: {},
  myGlobalFeedBack: [],
  blockTimes: [],
  size: 10,
  globalFeedbackLoading: false,
  currentTimezone: "",
  timezoneList: [],
  filteredTeamData: {},
  hrOneOnOneFilter: {},
  oneOnOneData: null,
  isHRoneOneFilterLoading: true,
  oneOnOneWithManager: null,
  oneOnOneWithUser: null,
  oneOnOneTeamList: [],
  employeeCount: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_ONE_ON_ONE_DATA:
      return {
        ...state,
        currentOneOnOneData: action.data,
        isCurrentLoading: action.isCurrentLoading,
      };
    case actionTypes.GET_PREVIOUS_ONE_ON_ONE_DATA:
      return {
        ...state,
        previousOneOnOneData: action.data,
        isPreviousLoading: action.isPreviousLoading,
      };
    case actionTypes.GET_ONE_ON_ONE_AGENDA_COMMENT:
      return {
        ...state,
        questionComment: action.data,
      };
    case actionTypes.SEND_AGENDA_SUCCESS:
      return {
        ...state,
        sendAgendaResponse: { success: true, message: action.message },
      };
    case actionTypes.SET_ONE_ON_ONE_USER:
      return {
        ...state,
        user: action.user,
        isLoading: action.isLoading,
      };
    case actionTypes.GET_ONE_ON_ONE:
      return {
        ...state,
        oneOnOne: action.data,
        isLoading: false,
        managerId: action.managerId,
        isOneOnOneLoading: action.isOneOnOneLoading,
        userId: action.userId,
        oneOnOneResponse: action.oneOnOneResponse,
      };
    case actionTypes.SET_ONE_ON_ONE_HR_TEAM_LIST:
      return {
        ...state,
        oneOnOneTeamList: action.managerDetails,
        employeeCount: action.employeeCount || 0,
        isLoading: false,
        oneOnOneData: {},
        isOneOnOneLoading: true,
      };
    case actionTypes.GET_ONE_ON_ONE_FOR_HR:
      return {
        ...state,
        oneOnOneData: action.data,
        isLoading: false,
        oneOnOneWithManager: action.manager,
        isOneOnOneLoading: action.isOneOnOneLoading,
        oneOnOneWithUser: action.user,
      };
    case actionTypes.GET_TIMEZONE:
      return {
        ...state,
        currentTimezone: action.currentTimezone,
        timezoneList: action.timezoneList,
      };
    case actionTypes.RESPONSE_SCHEDULE:
      return {
        ...state,
        scheduleResponse: { success: true, message: action.message },
      };
    case actionTypes.RESPONSE_END_ONE_ON_ONE:
      return {
        ...state,
        endedResponse: { success: true, message: action.message },
        ended: true,
      };
    case actionTypes.GET_ONE_ON_ONE_MEMBER_LIST:
      return {
        ...state,
        managerList: action.managerList,
        reporteeList: action.reporteeList,
        isLoading: action.isLoading,
      };
    case actionTypes.SET_HR_ONE_ON_ONE_FILTER_OPTIONS_DATA:
      return {
        ...state,
        hrOneOnOneFilter: {
          ...state.hrOneOnOneFilter,
          ...action.payload,
        },
      };
    case actionTypes.CLEAR_HR_ONE_ON_ONE_FILTER_OPTIONS_DATA:
      return {
        ...state,
        hrOneOnOneFilter: {},
      };
    case "SET_ONE_ON_ONE_FILTER_DATA":
      return {
        ...state,
        filteredTeamData: {
          ...state.filteredTeamData,
          ...action.payload,
        },
      };
    case "CLEAR_ONE_ON_ONE_FILTER_DATA":
      return {
        ...state,
        filteredTeamData: {},
        oneOnOne: null,
      };
    case actionTypes.GET_MANAGER_LIST:
      return {
        ...state,
        managerList: action.data,
        isLoading: action.isLoading,
      };
    case actionTypes.GET_REPORTEE_LIST:
      return {
        ...state,
        reporteeList: action.data,
        isLoading: action.isLoading,
      };
    case actionTypes.GET_TEAM_FEEDBACK_LIST:
      return {
        ...state,
        teamFeedbackList: action.data,
        isLoading: action.isLoading,
      };
    case "ONE_ON_ONE_SCHEDULE_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "CLEAR_SEND_AGENDA_RESPONSE":
      return {
        ...state,
        sendAgendaResponse: null,
        scheduleResponse: null,
        endedResponse: null,
        oneOnOneResponse: null,
      };
    case "REMOVE_ONE_ON_ONE_DATA":
      return { ...initialState, filteredTeamData: state.filteredTeamData };
    case actionTypes.POST_GLOBAL_FEEDBACK:
      return {
        ...state,
        globalFeedBack: action.payload,
        globalFeedbackLoading: false,
      };
    case actionTypes.GET_GLOBAL_FEEDBACK:
      return {
        ...state,
        myGlobalFeedBack: action.payload,
      };
    case actionTypes.GET_SCHEDULE_TIME:
      return {
        ...state,
        blockTimes: action.payload,
      };
    case actionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        globalFeedBack: {},
      };
    case actionTypes.SHOW_LOADER_GLOBAL_FEEDBACK:
      return {
        ...state,
        globalFeedbackLoading: true,
      };
    default:
      return state;
  }
};

export default reducer;
