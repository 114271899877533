import * as actionTypes from "../actions/actionTypes";

const initialState = { appreciateHistory: null, appreciateResponse: null, allAppreciateHistory: null, appreciateCount: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_APPRECIATE_HISTORY:
      return { ...state, appreciateHistory: action.appreciateHistory };
    case actionTypes.SET_APPRECIATE_COUNT:
      return { ...state, appreciateCount: action.appreciateCount };
    case actionTypes.SET_ALL_APPRECIATE_HISTORY:
      return { ...state, allAppreciateHistory: action.allAppreciateHistory };
    case actionTypes.SET_APPRECIATE_RESPONSE:
      return { ...state, appreciateResponse: action.appreciateResponse };
    case actionTypes.CLEAR_RESPONSE:
      return { ...state, appreciateResponse: null };
    default:
      return state;
  }
};

export default reducer;
