import React, { useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_ACC_ARROW_DOWN } from "../../../../const/imgCost";
import QuestionTemplate from "../QuestionTemplate/QuestionTemplate";
import style from "./SelfAssessmentRating.module.scss";

export default function AccordionRow({
  index,
  template,
  saveQuestionAnswer,
  questionTemplateErrors,
  isSubmitted,
  uploadFile,
  downloadFile,
  deleteFile,
  sendManagerComment,
  sendRating,
  isManagerSubmitted,
  isPage,
  ratingErrors,
  ratingCommentErrorsInSelf,
  fileUploadByManager,
  isRatingDisable
}) {
  const [eventValue, setEventValue] = useState(index);
  let errors = {};
  let displayCardError;
  if (isPage === "REPORTING") {
    if (template.name === "Reporting Assessment") {
      errors = questionTemplateErrors;
      displayCardError = questionTemplateErrors?.errors?.find((v) => (v.error || v.commentBoxError) && v.isSubmit)
        ? true
        : false;
    } else if (template.name === "Self Assessment") {
      if (ratingErrors?.find((v) => v.error && v.isSubmit)) displayCardError = true;
    }
  } else if (isPage === "REVIEWING") {
    if (template.name === "Reviewing Assessment") {
      errors = questionTemplateErrors;
      displayCardError = questionTemplateErrors?.errors?.find((v) => (v.error || v.commentBoxError) && v.isSubmit)
        ? true
        : false;
    } else if (template.name === "Self Assessment") {
      if (ratingErrors?.find((v) => v.error && v.isSubmit)) displayCardError = true;
    }
  }

  return (
    <Accordion defaultActiveKey={`${index}`} className="custom-accordion">
      <Card key={index} className={displayCardError ? "limit-reached-card" : ""}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={`${index}`}
            onClick={(e) => {
              setEventValue(index);
              if (e.currentTarget.classList.contains("show")) {
                e.currentTarget.classList.remove("show");
              } else {
                e.currentTarget.classList.add("show");
              }
            }}
            className={eventValue === index && "show"}
          >
            <div className="accordion-icon">
              <ReactSVG src={IC_ACC_ARROW_DOWN} />
            </div>
            <div className={style.accordion_title}>
              <div className={style.title_left}>{template.nameDOM}</div>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={`${index}`}>
          <Card.Body>
            <div className="box-light-gradient"></div>
            <QuestionTemplate
              ratingErrors={ratingErrors}
              ratingCommentErrorsInSelf={ratingCommentErrorsInSelf}
              isPage={isPage}
              isManagerSubmitted={isManagerSubmitted}
              viewTemplateName={false}
              handleTextBox={saveQuestionAnswer}
              handleRating={saveQuestionAnswer}
              handleRatingComment={saveQuestionAnswer}
              handleRadioComment={saveQuestionAnswer}
              handleRadio={saveQuestionAnswer}
              handleRadioOther={saveQuestionAnswer}
              handleCheckBox={saveQuestionAnswer}
              handleCheckBoxOther={saveQuestionAnswer}
              handleCheckBoxComment={saveQuestionAnswer}
              handleDropDown={saveQuestionAnswer}
              handleDropDownComment={saveQuestionAnswer}
              handleDropDownOther={saveQuestionAnswer}
              handleScale={saveQuestionAnswer}
              handleScaleComment={saveQuestionAnswer}
              handleRankComment={saveQuestionAnswer}
              handleRank={saveQuestionAnswer}
              handleNps={saveQuestionAnswer}
              handleNpsComment={saveQuestionAnswer}
              questionTemplateErrors={errors}
              isSubmitted={isSubmitted}
              questionsAnswerTemplate={template}
              uploadFile={uploadFile}
              downloadFile={downloadFile}
              deleteFile={deleteFile}
              sendManagerComment={sendManagerComment}
              sendRating={sendRating}
              isRatingDisable={template.name === "Self Assessment" ? isRatingDisable : false}
              fileUploadByManager={fileUploadByManager}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
