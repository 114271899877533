import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import style from "./AddSkillMasterModal.module.scss";
import Select from "react-select";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, successToast } from "util/general";

const AddSkillMasterModal = (props) => {
  const [skillName, setSkillName] = useState("");
  const [disable, setDisable] = useState(true);
  const [categoryList, setCategory] = useState([]);
  const [category, selectedCategory] = useState(null);
  const { getSkillMasterCategory, addSkillMasterErrorResponse, addSkillMasterSuccessResponse } = useSelector(
    (state) => state.skillMatrix
  );
  const dispatch = useDispatch();
  const handleSkillName = (e) => {
    const value = e.target.value || "";
    setSkillName(value);
  };
  const submit = () => {
    let data = {
      skillName: skillName.trim(),
      isActive: true,
      categoryId: category.value,
      categoryName: category.label,
    };
    dispatch(actions.addSkillInMaster(data));
    props.hide();
  };

  useEffect(() => {
    const getCategory = () => dispatch(actions.getSkillMasterCategory);
    getCategory();
  }, [dispatch]);

  useEffect(() => {
    if (skillName && category) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [category, skillName]);

  useEffect(() => {
    let arr = [];
    if (getSkillMasterCategory && getSkillMasterCategory.length > 0) {
      getSkillMasterCategory.map((data) => {
        const obj = {};
        obj["value"] = data.categoryId;
        obj["label"] = data.skillCategoryName;
        arr.push(obj);
        return data;
      });
    }
    setCategory(arr);
  }, [getSkillMasterCategory]);

  useEffect(() => {
    if (addSkillMasterSuccessResponse && !addSkillMasterSuccessResponse.error) {
      successToast(addSkillMasterSuccessResponse?.message);
      setSkillName("");
      selectedCategory(null);
    }

    if (addSkillMasterErrorResponse && addSkillMasterErrorResponse.error)
      errorToast(addSkillMasterSuccessResponse?.message);
  }, [addSkillMasterErrorResponse, addSkillMasterSuccessResponse]);

  const selectCategory = (select) => {
    if (select) {
      selectedCategory(select);
    } else {
      selectedCategory(null);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} className="custom_modal_popup">
        <div className={[style.skill_category_modal, "custom_modal_container"].join(" ")}>
          <Modal.Header className={style.mHeader}>
            <Modal.Title>
              {" "}
              Add Skill Master
              <span>You can ADD skills from here to Skill Master</span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="form-group">
              <span className={style.dd_title}>Add Skills</span>
              <div className="card_action">
                <Form.Group controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter Skill Name (E.g. Java, Photoshop, HTML, etc…)"
                    onChange={(event) => handleSkillName(event)}
                    value={skillName}
                  ></Form.Control>
                </Form.Group>
              </div>
            </div>
            <div className="form-group">
              <span className={style.dd_title}>Select Category</span>
              <div className="card_action">
                <Select
                  options={categoryList}
                  className="custom-default-dd-box"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable={true}
                  placeholder="Select the Category"
                  onChange={(select) => selectCategory(select)}
                  value={category}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={submit} disabled={disable}>
              Add
            </Button>
            <Button variant="secondary" className="fs-500" onClick={() => props.hide()}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default AddSkillMasterModal;
