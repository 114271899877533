import React from "react";
import { Modal, Button } from "react-bootstrap";
import style from "./CertificateModal.module.scss";
import moment from "moment";
import NoDataFound from "../../../NodataFound/NoDataFound";
import { noData } from "const/constValue";

const CertificateModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.hide()}
        size="md"
        animation={false}
        className="custom_modal_popup view_certificate_modal"
      >
        <div className="custom_modal_container custom_lg_modal_container">
          <Modal.Header className={style.mHeader}>
            <Modal.Title>
              View Certifications - {props.data.displayName}
              <span>You can View certificates of the Employees </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            {props.data.certificationList && props.data.certificationList.length > 0 ? (
              <>
                <div className="box-light-gradient"></div>
                <div className={style.certificate_table}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Validity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.certificationList.map((item) => {
                        let formatted_start = "",
                          formatted_end = "";
                        if (item.endDate) {
                          formatted_start = moment(item.startDate.split(".")[0]).format("MMMM D, YYYY");
                          formatted_end = moment(item.endDate.split(".")[0]).format("MMMM D, YYYY");
                        }
                        return (
                          <tr key={item.certificateId}>
                            <td>
                              {item.certificateName === "OTHER_CERTIFICATE"
                                ? item.otherCertificateName
                                : item.certificateName}
                            </td>
                            <td>
                              {item.isExpiration === false
                                ? "Lifetime"
                                : item.isActive === false
                                ? "Expired"
                                : formatted_start + " - " + formatted_end}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            )}
          </Modal.Body>
          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={() => props.hide()}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default CertificateModal;
