import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import moment from "moment";
import style from "./MyResume.module.scss";
import * as actions from "store/actions/index";
import { DOWNLOAD_RESUME_SAMPLE } from "util/endpoints";
import { IC_DOWNLOAD_1, IC_INFO } from "const/imgCost";
import storage from "util/storage";
import ResumeAcknowledgeBox from "./ResumeAcknowledgeBox/ResumeAcknowledgeBox";
import { errorToast, successToast } from "util/general";

export default function MyResume() {
  const dispatch = useDispatch();
  const {
    getEmployeeResume,
    uploadResume,
    downloadResume,
    downloadResumeTemplate,
    resumeUpdateReminder,
    resumeUpdateAcknowledgeSuccess,
    resumeUpdateAcknowledgeError,
  } = useSelector((state) => state.skillMatrix);
  const [file, setFile] = useState(null);
  const [displayFile, setDisplayFile] = useState(null);
  const [fileUpdatedDate, setFileUpdatedDate] = useState(null);
  const [invalidFileType, setInvalidFileType] = useState(false);
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [emptyFileValidation, setEmptyFileValidation] = useState(false);
  const [show, setShow] = useState(false);

  let user = storage.getUser();
  let inputElement = React.createRef();

  useEffect(() => {
    dispatch(actions.getEmployeeResume);
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(getEmployeeResume).length > 0) {
      setDisplayFile(getEmployeeResume.data ? getEmployeeResume.data.file : null);
      if (getEmployeeResume.data) {
        setFileUpdatedDate(moment(getEmployeeResume.data.updatedDate).format("DD MMMM, YYYY"));
      }
    }
  }, [getEmployeeResume]);

  useEffect(() => {
    if (uploadResume) {
      if (uploadResume.error) {
        errorToast(uploadResume.message);
      } else if (!uploadResume.error) {
        successToast(uploadResume.message);
      }
    }
  }, [uploadResume]);

  useEffect(() => {
    if (downloadResume) {
      if (downloadResume.error) errorToast(downloadResume.message);
      else if (!downloadResume.error) successToast(downloadResume.message);
    }
  }, [downloadResume]);

  useEffect(() => {
    if (downloadResumeTemplate) {
      if (downloadResumeTemplate.error) errorToast(downloadResumeTemplate.message);
      else if (!downloadResumeTemplate.error) successToast(downloadResumeTemplate.message);
    }
  }, [downloadResumeTemplate]);

  useEffect(() => {
    if (file && file !== null) {
      if (
        file.type === "application/msword" ||
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        if (file.size > 0 && file.size <= 5677568) {
          const formData = new FormData();
          formData.append("file", file);
          dispatch(actions.uploadMyResume(formData));
        } else if (file.size === 0) {
          setEmptyFileValidation(true);
        } else {
          setInvalidFileSize(true);
        }
      } else setInvalidFileType(true);
    }
  }, [file, dispatch]);

  useEffect(() => {
    if (invalidFileType) {
      errorToast("Invalid File Format");
    }
    setInvalidFileType(false);
  }, [invalidFileType]);

  useEffect(() => {
    if (invalidFileSize) {
      errorToast("File is too large");
    }
    setInvalidFileSize(false);
  }, [invalidFileSize]);

  useEffect(() => {
    if (emptyFileValidation) {
      errorToast("File is empty");
    }
    setEmptyFileValidation(false);
  }, [emptyFileValidation]);

  useEffect(() => {
    const checkResumeUpdate = () => {
      dispatch(actions.checkResumeUpdateReminder());
    };
    checkResumeUpdate();
  }, [dispatch]);

  useEffect(() => {
    if (resumeUpdateReminder) {
      setShow(resumeUpdateReminder.isRemiderRequired);
    }
  }, [resumeUpdateReminder]);

  useEffect(() => {
    if (resumeUpdateAcknowledgeSuccess?.success) successToast(resumeUpdateAcknowledgeSuccess.message);
    if (resumeUpdateAcknowledgeError?.error) errorToast(resumeUpdateAcknowledgeError.message);
  }, [resumeUpdateAcknowledgeError, resumeUpdateAcknowledgeSuccess]);

  const downloadMyResume = () => dispatch(actions.downloadResume);

  const uploadMyResumeFun = (e) => setFile(e.target.files[0]);

  return (
    <>
      {show ? <ResumeAcknowledgeBox user={user} date={resumeUpdateReminder.acknowledgeByDate} /> : null}
      <div className={[style.my_resume_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>My Resume</h2>
          </div>
        </div>
        <div className="box-light-gradient h38"></div>
        <div className="box-inner-pad position-relative">
          <div className={style.file_info_wrapper}>
            <div className={style.file_info}>
              <span className={style.file_txt}>{displayFile ? displayFile.name : "Resume not uploaded."}</span>
              {fileUpdatedDate && <span className={style.file_time}> Last updated on {fileUpdatedDate} </span>}
            </div>
            {displayFile && (
              <span className={[style.file_download, "fill-svg-icon"].join(" ")} onClick={downloadMyResume}>
                <ReactSVG src={`${IC_DOWNLOAD_1}`} />
              </span>
            )}
            <div className={style.info_tooltip}>
              <OverlayTrigger
                key="info_tooltip"
                placement="right"
                overlay={<Tooltip id="info_tooltip">Upload [.doc|.docx] format file upto 5MB in size.</Tooltip>}
              >
                <ReactSVG src={`${IC_INFO}`} title="" alt="" />
              </OverlayTrigger>
            </div>
          </div>
          <div className={style.file_btn_group}>
            <input
              id="fileid"
              type="file"
              hidden
              ref={(input) => (inputElement = input)}
              onChange={(event) => uploadMyResumeFun(event)}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            <button
              type="button"
              onClick={() => inputElement.click()}
              className={["btn-round btn btn-primary mr-3", style.create_new_btn].join(" ")}
            >
              <strong>Upload File</strong>
            </button>
            <a href={DOWNLOAD_RESUME_SAMPLE} download>
              <button type="button" className={["btn-round btn btn-secondary", style.create_new_btn].join(" ")}>
                <strong>Download Resume Template </strong>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
