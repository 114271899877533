export const ratingOptions = [
  { value: 1, label: "Poor" },
  { value: 2, label: "Needs Improvement" },
  { value: 3, label: "Meeting Expectations" },
  { value: 4, label: "Exceed Expectations" },
  { value: 5, label: "Outstanding" },
];

export const newRatingOptions = [
  { value: 1, label: "Need Development" },
  { value: 2, label: "Competent" },
  { value: 3, label: "Exemplary" } 
];

export const oneToFourRatingOptions = [
  { value: 1, label: "Need Development" },
  { value: 2, label: "Competent" },
  { value: 3, label: "Exemplary" },
  { value: 4, label: "Outstanding" },
];

export const ObjectiveTypeOption = [
  { value: "Project", label: "Project" },
  { value: "Bench", label: "Bench" }
];

export const feedbackCategory = [
  { value: "Select", label: "Select" },
  { value: "Something doesn't work as expected", label: "Something doesn't work as expected" },
  { value: "Something I Like", label: "Something I Like" },
  { value: "Improvement Suggestion", label: "Improvement Suggestion" },
  { value: "New Feature Suggestion", label: "New Feature Suggestion" },
  { value: "General Comments", label: "General Comments" },
  { value: "Something I Don't Like", label: "Something I Don't Like" },
  { value: "Other", label: "Other" },
];

export const mainMenuItem = [
  { value: 1, label: "Sub Group", name: "subGroupClient" },
  { value: 2, label: "Employee Type", name: "memberTypeList" },
  { value: 3, label: "Location", name: "locationOption" },
  { value: 4, label: "Group", name: "departmentList" },
  { value: 5, label: "Project", name: "projectList" },
  { value: 6, label: "India DD", name: "indiaDDOption" },
  { value: 7, label: "Manager", name: "managerOptions" },
];

export const suggestionGroups = [
  { value: "Regular", label: "Regular" },
  { value: "Direct - Contractor", label: "Direct - Contractor" },
  { value: "Probation", label: "Probation" },
  { value: "Intern", label: "Intern" },
  { value: "Indirect - Contractor", label: "Indirect - Contractor" },
  { value: "Leave of Absence", label: "Leave of Absence" },
  { value: "Regular Temp", label: "Regular Temp" },
];

export const countryOptions = [
  { value: "India", label: "India" },
  { value: "United States", label: "United States" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Germany", label: "Germany" },
  // { value: "Indirect - Contractor", label: "Indirect - Contractor" },
  // { value: "Leave of Absence", label: "Leave of Absence" },
  // { value: "Regular Temp", label: "Regular Temp" }
];

export const locationOptions = [
  { value: "Ahmedabad", label: "Ahmedabad" },
  { value: "Pune", label: "Pune" },
  { value: "New York", label: "New York" },
  { value: "Santa Clara", label: "Santa Clara" },
  { value: "Germany", label: "Germany" },
  { value: "London", label: "London" },
];

export const orderOptions = [
  { value: "DESC", label: "Higher to Lower" },
  { value: "ASC", label: "Lower to Higher" },
];

export const filterOptions = [
  { value: "RATING", label: "Rating cycle" },
  { value: "GRADE", label: "Grade cycle" },
  { value: "MASK", label: "OKR Progress cycle" },
];

export const cycleOptions = [
  { value: 4, label: "Last 4 Cycle" },
  { value: 6, label: "Last 6 Cycle" },
  { value: 9, label: "Last 9 Cycle" },
  { value: 12, label: "Last 12 Cycle" },
];

export const cycleOptions2 = [
  { value: 2, label: "Last 2 Cycle" },
  { value: 4, label: "Last 4 Cycle" },
];

export const timelineOptions = [
  { value: "DEALLOCATION", label: "Deallocation" },
  { value: "REWARDS", label: "Rewards" },
  { value: "PROMOTION", label: "Promotion" },
];
export const monthOptions = [
  { value: 3, label: "Last 3 Months" },
  { value: 6, label: "Last 6 Months" },
  { value: 9, label: "Last 9 Months" },
  { value: 12, label: "Last 12 Months" },
];

export const monthOptions2 = [
  { value: 1, label: "Last 30 Days" },
  { value: 3, label: "Last 3 Months" },
  { value: 6, label: "Last 6 Months" },
];

export const monthOptions3 = [
  { value: 1, label: "Last 30 Days" },
  { value: 3, label: "Last 3 Months" },
  { value: 6, label: "Last 6 Months" },
  { value: 12, label: "Last 12 Months" },
];

export const monthOptions4 = [
  { value: 0, label: "Select" },
  { value: 1, label: "1 Month" },
  { value: 2, label: "2 Months" },
  { value: 3, label: "3 Months" },
  { value: 4, label: "4 Months" },
  { value: 5, label: "5 Months" },
  { value: 6, label: "6 Months" },
];

export const monthOptions5 = [
  { value: 6, label: "last 6 Months" },
  { value: 9, label: "last 9 Months" },
  { value: 12, label: "last 12 Months" },
  { value: 18, label: "last 18 Months" },
  { value: 24, label: "last 24 Months" },
];

export const yearOptions = [
  { value: "endDate", label: "2022 Backwards" },
  { value: "startDate", label: "2023 Onwards" },
  { value: "currentDate", label: "2024 Onwards" } 
];

export const oneOnOneStageOptions = [
  { value: "ALL", label: "All" },
  { value: "PENDING", label: "Pending" },
  { value: "UPCOMING", label: "Upcoming" },
  { value: "LAPSED", label: "Lapsed" },
  { value: "PREVIOUS", label: "Previous" },
];

export const backGroundColor = ["#FF5479", "#CC54FF", "#54A1FF", "#F9C03C", "#13BB8D", "#5C59E3", "#75BA30"];

export const gradeOptions = [
  { label: "A+", value: "A+" },
  { label: "A", value: "A" },
  { label: "B+", value: "B+" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
];

export const timeFrameOptions = [
  {
    label: "1 Week",
    value: "WEEKLY",
  },
  {
    label: "2 Week",
    value: "BI_WEEKLY",
  },
  {
    label: "1 Month",
    value: "MONTHLY",
  },
  {
    label: "3 Month",
    value: "QUARTERLY",
  },
];
export const paRatingOptions = [
  { label: "1-2", value: "1-2" },
  { label: "2-3", value: "2-3" },
  { label: "3-4", value: "3-4" },
  { label: "4-4.5", value: "4-4.5" },
  { label: "4.5-5", value: "4.5-5" },
];

export const competency_level_options = [
  { value: 0, label: "Unexplored" },
  { value: 1, label: "Beginner" },
  { value: 2, label: "Intermediate" },
  { value: 3, label: "Advanced" },
];

export const statusWiseOptions = [
  { value: "ALL", label: "All" },
  { value: "SUBMIT", label: "Submitted" },
  { value: "INVALIDATE", label: "Submitted-NA" },
  { value: "PENDING", label: "Pending" },
];

export const oneOnOneStatusOptions = [
  { label: "Attended", value: "Attended" },
  { label: "Un-Attended", value: "Un-Attended" },
];

export const campaignTypeOptions = [
  { value: "Quiz", label: "Quiz" },
  { value: "Survey", label: "Survey" },
];
