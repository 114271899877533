import moment from "moment";
import React from "react";
import MultipleFile from "../MultipleFileUpload/MultipleFile";

function CommentWithAttachment({ selectedColor, image, empName, commentedOn, comment, downloadFile, files }) {
  return (
    <>
      <div className="view_comment_box_row">
        <div className="user_pics" style={{ backgroundColor: selectedColor }}>
          {image?.length > 2 ? <img alt="DP" src={image} /> : image}
        </div>
        <div className="user_comment_details">
          <div className="user_name">
            {empName}
            {commentedOn && <span className="comment_time">{moment(commentedOn).fromNow()}</span>}
          </div>
          <div className="user_comment text-break">
            <pre>{comment}</pre>
          </div>
        </div>
      </div>
      {files && files.length > 0 && (
        <div className="multiple_uploaded_file">
          <MultipleFile files={files} isDeletable={false} isDownloadable={true} downloadFile={downloadFile} />
        </div>
      )}
    </>
  );
}

export default CommentWithAttachment;
