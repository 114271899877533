import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_ACC_ARROW_DOWN } from "../../../../const/imgCost";
import { objectiveStatus, ratingValue } from "../../../../util/general";
import CommentsTop from "../../../CommentBox/CommentsTop";
import ObjectiveUserStatus from "../../../employeeObjectives/objectiveUserStatus";
import KeyResultTable from "../../../KeyResultTable/KeyResultTable";
import style from "./HRDeallocationMemberDetails.module.scss";
import Select from "react-select";
import Comment from "../../../CommentBox/Comment";
import UniversityKeyResult from "../../../UniversityKeyResult/UniversityKeyResult";
import KeyResultCommentModal from "../../../Performance/CycleMemberProfile/MemberProfileObjecive/ObjeciveModal/KeyResultCommentModal";
import InitiativeModal from "../../../Performance/SelfAssessment/Components/InitiativeModal";
import { backGroundColor, objectiveStatusValue } from "../../../../const/constValue";
import { ratingOptions } from "const/options";
import { dateFormat1 } from "../../../../util/dateUtils";

function HRDeallocationObjectiveTracking({ objectives, loggedInUser }) {
  const [initiativeObject, setInitiativeObject] = useState();
  const [eventValue, setEventValue] = useState();
  const [isModal, setIsModel] = useState(false);
  const [keyPoint, setKeyPoint] = useState();
  const [modalObjective, setModalObjective] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const [viewMoreFlag, setViewMoreFlag] = useState(false);

  return (
    <div className={["box", style.project_box].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-light-gradient h38"></div>
        <div className="box-heading">
          <h2>Objective Tracking</h2>
          <h3>Objective status and progress helps tracking down things in easy way</h3>
        </div>
      </div>
      <Accordion defaultActiveKey={""} className={["custom-accordion", style.member_obj].join(" ")}>
        {objectives &&
          objectives.map((objective, index) => (
            <Card key={objective.userObjectiveId} className="overflow-visible">
              <Card.Header>
                <div className={style.employeeObjectives_process_indicate}>
                  <ObjectiveUserStatus objective={objective} page="{props.page}" editable="{props.editable}" />
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={`${index}`}
                  onClick={(e) => {
                    setEventValue(index);
                    if (e.currentTarget.classList.contains("show")) {
                      e.currentTarget.classList.remove("show");
                    } else {
                      e.currentTarget.classList.add("show");
                    }
                  }}
                  className={[eventValue === index && "show", "indicator_manager_view"].join(" ")}
                >
                  <div className="accordion-heading">
                    <div className="accordion-heading-left">
                      <div className={style.employeeObjectives_heading_wrapper}>
                        <h3>{objective.title}</h3>
                      </div>
                    </div>
                    <div className="accordion-heading-right position-relative">
                      <div className={style.employeeObjectives_process_complete}>{objective.percentage}%</div>
                      <div className={style.emp_obj_end_days}>{objectiveStatus(objective.status)}</div>
                    </div>
                  </div>

                  <div className="accordion-icon">
                    <ReactSVG src={IC_ACC_ARROW_DOWN} />
                  </div>
                </Accordion.Toggle>
                <div className={style.key_initiative_wrapper}>
                  <span>{objective.keyPoints.length} Key Results are aligned</span>
                  <span
                    className={style.initiative_wrapper}
                    onClick={() => {
                      if (objective.plans) {
                        setIsModel(!isModal);
                        setInitiativeObject(objective);
                      }
                    }}
                  >
                    {(objective.plans && objective.plans.length) || 0} Initiatives Founds
                  </span>
                </div>
                <div className={style.objective_update_info}>
                  <span>
                    {objective.status === objectiveStatusValue.COMPLETE
                      ? "This Objective is Assessed by Manager"
                      : objective.objfeedbackStatus === objectiveStatusValue.INVALID
                      ? "This Objective is marked as Invalid"
                      : `This Objective is marked as "Continue Objective"`}
                  </span>
                  {/* {objective.objfeedbackStatus === "CONTINUE" && objective.managerData?.empName} */}
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey={`${index}`}>
                <>
                  <div className={style.timeframe_wrapper}>
                    <div className={style.timeframe_info}>
                      <span>Timeframe : </span>
                      <>
                        &nbsp;&nbsp;
                        {dateFormat1(objective.startDate) + " to " + dateFormat1(objective.endDate)}
                      </>
                    </div>
                  </div>
                  <Card.Body className="p-0 mt-4">
                    <div className={style.idp_info_list}>
                      {(objective.definedBy === "MANAGER" || objective.definedBy === "SERVICE_LINE") && (
                        <KeyResultTable
                          keypoints={objective.keyPoints}
                          edit={objective.status === objectiveStatusValue.COMPLETE}
                          objectiveError={[]}
                          isDisabled={true}
                          clickCommentModal={(point) => {
                            setCommentModal(true);
                            setKeyPoint(point);
                            setModalObjective(objective);
                          }}
                        />
                      )}
                      {objective.definedBy === "UNIVERSITY" && <UniversityKeyResult keyPoints={objective.keyPoints} />}
                    </div>
                  </Card.Body>
                </>
              </Accordion.Collapse>
              {objective.definedBy === "UNIVERSITY" &&
                (objective.objfeedbackStatus === objectiveStatusValue.COMPLETE ||
                  objective.status === objectiveStatusValue.COMPLETE) && (
                  <Card.Footer>
                    <div className="card-ft-wrapper">
                      <span>Rate this progress by selecting a reasonable value</span>
                      <div className="card_action">
                        <Select
                          options={ratingOptions}
                          className={["custom-default-dd-box"].join(" ")} //props.objectiveError && "error-box"
                          classNamePrefix="select"
                          isSearchable={false}
                          value={ratingValue(objective.rating || objective.ratingRemarks)}
                          isDisabled={true}
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </Card.Footer>
                )}
              {objective.objfeedbackStatus === "COMPLETED" && (
                <div className={["view_comment_box_wrapper", "bg-white"].join(" ")}>
                  <CommentsTop heading="Share your view(s)" />
                  <Comment
                    selectedColor={backGroundColor[1]}
                    deletable={false}
                    image={objective.managerData?.displayPicture || objective.managerData?.empInitial}
                    empName={objective.managerData?.empName}
                    comment={objective.objectiveComment}
                    isPrivate={false}
                  />
                  {objective.comment && viewMoreFlag === objective.userObjectiveId && (
                    <div className="assess_prev_comment_wrapper">
                      <h5>Previous Comment(s)</h5>
                      {objective.comment
                        .filter((answer) => {
                          if (answer.whoCanView && answer.whoCanView.length === 0) {
                            return true;
                          } else {
                            if (answer.private) {
                              if (answer.commentedBy.employeeId === loggedInUser.employeeId) {
                                return true;
                              } else if (
                                answer.whoCanView &&
                                answer.whoCanView[0].employeeId === loggedInUser.employeeId
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          }
                        })
                        .filter(() => viewMoreFlag)
                        .map((answer, index) => {
                          return (
                            <Comment
                              selectedColor={backGroundColor[0]}
                              key={index}
                              deletable={false}
                              image={answer.commentedBy?.displayPicture}
                              empName={answer.commentedBy?.empName}
                              commentedOn={answer.commentedOn}
                              comment={answer.comment}
                              isPrivate={answer.private}
                              privateMsg={`Private message, only visible to you and
                                ${
                                  loggedInUser?.employeeId === answer.commentedBy.employeeId
                                    ? ` ${answer.whoCanView?.length > 0 && answer.whoCanView[0].empName}`
                                    : ` ${answer.commentedBy?.empName}`
                                }`}
                            />
                          );
                        })}
                    </div>
                  )}
                  {objective.comment && objective.comment.length > 0 && (
                    <div className={style.view_all_objective_comment}>
                      <span
                        onClick={() => {
                          if (viewMoreFlag === objective.userObjectiveId) {
                            setViewMoreFlag(false);
                          } else setViewMoreFlag(objective.userObjectiveId);
                        }}
                      >
                        {viewMoreFlag === objective.userObjectiveId ? "View Less" : "View More"}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {objective.objfeedbackStatus === "INVALID" && (
                <div className={["view_comment_box_wrapper", "bg-white"].join(" ")}>
                  <CommentsTop heading={"Share your view(s)"} />
                  <Comment
                    selectedColor={backGroundColor[0]}
                    deletable={false}
                    image={objective.managerData?.displayPicture || objective.managerData?.empInitial}
                    empName={objective.managerData?.empName}
                    comment={objective.inValidateComment}
                  />
                </div>
              )}
            </Card>
          ))}
      </Accordion>
      {isModal && (
        <InitiativeModal
          empName={initiativeObject.managerData?.empName}
          show={true}
          hide={() => setIsModel(!isModal)}
          plans={initiativeObject.plans}
          percentage={initiativeObject.percentage}
          status={initiativeObject.status}
          title={initiativeObject.title}
        />
      )}
      {commentModal && (
        <KeyResultCommentModal
          loggedInUser={loggedInUser}
          objective={modalObjective}
          keyPoint={keyPoint}
          isSubmitted={true}
          saveComment={() => {}}
          hide={() => setCommentModal(false)}
          show={commentModal}
        />
      )}
    </div>
  );
}
export default HRDeallocationObjectiveTracking;
