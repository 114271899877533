import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";

function SelectListPopup(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const history = useHistory();
  const selectListOptions = [
    { value: "EMPLOYEE_ASSESSMENT_LIST", label: "Employee Self Assessment" },
    { value: "EMPLOYEE_LIST", label: "Employee List" },
    { value: "REPORTING_MANAGER_LIST", label: "Reporting Manager List" },
    { value: "REVIEWING_MANAGER_LIST", label: "Reviewing Manager List" },
  ];

  const submit = () => {
    if (selectedOption) {
      if (selectedOption.value === "EMPLOYEE_LIST")
        history.push(`/hr/performance/performance-cycle/employee-list/${props.cycleID}`);
      else if (selectedOption.value === "EMPLOYEE_ASSESSMENT_LIST")
        history.push(`/hr/performance/performance-cycle/employee-assessment-list/${props.cycleID}`);
      else if (selectedOption.value === "REPORTING_MANAGER_LIST")
        history.push(`/hr/performance/performance-cycle/reporting-manager-list/${props.cycleID}`);
      else if (selectedOption.value === "REVIEWING_MANAGER_LIST")
        history.push(`/hr/performance/performance-cycle/reviewing-manager-list/${props.cycleID}`);
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} className="custom_modal_popup">
        <div className="custom_modal_container employee-manager-modal">
          <Row>
            <Col md={12}>
              <Modal.Body>
                <h4>Employee Details</h4>
                <p>Please select from below which view would you like to see.</p>
              </Modal.Body>
            </Col>
            <Col md={12}>
              <Select
                options={selectListOptions}
                className="custom-default-dd-box"
                classNamePrefix="select"
                isSearchable={false}
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
              />
            </Col>
            <Col className="custom_modal_button">
              <Button variant="primary" onClick={submit}>
                Submit
              </Button>

              <Button variant="secondary" className="btn-border" onClick={() => props.hide()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default SelectListPopup;
