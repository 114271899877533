import React from "react";
import MySkill from "components/SkillMatrix/MySkill/MySkill";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function SkillMatrix() {
  return (
    <>
      <SubNavigation type="WMG" />
      <MySkill />
    </>
  );
}
