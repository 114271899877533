import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Overlay, Tooltip } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import style from "./SkillsRequired.module.scss";
import * as actions from "store/actions/index";
import { IC_ERROR } from "const/imgCost";
import { noData } from "const/constValue";
import { competency_level_options } from "const/options";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "../../NodataFound/NoDataFound";
import TechnicalRoleChangePopup from "./TechnicalRoleChangePopup/TechnicalRoleChangePopup";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import { errorToast, successToast } from "util/general";

export default function SkillsRequired() {
  let options_month = [
    { value: 0, label: "January" },
    { value: 1, label: "February" },
    { value: 2, label: "March" },
    { value: 3, label: "April" },
    { value: 4, label: "May" },
    { value: 5, label: "June" },
    { value: 6, label: "July" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "October" },
    { value: 10, label: "November" },
    { value: 11, label: "December" },
  ];

  const {
    myDepartmentTechRole,
    isLoading,
    requiredSkillConfig,
    saveMyRequiredSkillResponse,
    saveMyRequiredSkillErrorResponse,
    employeeRequiredSkillList,
    technicalRoleUpdateError,
    technicalRoleUpdateSuccess,
  } = useSelector((state) => state.skillMatrix);
  const [disabled, setDisable] = useState(true);
  const [skillObj, setSkillObj] = useState({});
  const [skillArr, setSkillArr] = useState([]);
  const [submitSkill, setSubmittedSkill] = useState({});
  const inputRef = useRef({});
  const dispatch = useDispatch();
  const [technicalRoleList, setTechnicalRole] = useState([]);
  const [roleBaseSkill, setRoleBaseSkill] = useState({});
  const [role, setRole] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(false);
  const [disableDropdown, setDisableDropdown] = useState(false);
  const history = useHistory();
  /*for feedback modal */
  const [isModalTechnicalRole, setisModalTechnicalRole] = useState(false);
  const hideTechnicalRole = (selectedValue) => {
    if (selectedValue && selectedValue.value) {
      let data = {
        requestedTechnicalRoleId: selectedValue.value,
      };
      dispatch(actions.updateTechnicalRole(data));
    }
    setisModalTechnicalRole(!isModalTechnicalRole);
  };

  useEffect(() => {
    if (technicalRoleUpdateSuccess) {
      if (technicalRoleUpdateSuccess.success) successToast(technicalRoleUpdateSuccess.message);
      if (technicalRoleUpdateError.error) errorToast(technicalRoleUpdateError.message);
    }
  }, [technicalRoleUpdateSuccess, technicalRoleUpdateError]);

  useEffect(() => {
    const roleBaseSkillList = () => dispatch(actions.employeeRequiredSkill);
    roleBaseSkillList();
  }, [dispatch]);

  useEffect(() => {
    if (employeeRequiredSkillList && employeeRequiredSkillList.length > 0) {
      setDisableDropdown(true);
      employeeRequiredSkillList.forEach((rbs) => {
        setRoleBaseSkill(rbs);
      });
    }
  }, [employeeRequiredSkillList]);

  useEffect(() => {
    const selectedSkills = [];
    const array = [];
    if (roleBaseSkill && roleBaseSkill.category) {
      setRole({ value: roleBaseSkill.technicalRoleId, label: roleBaseSkill.technicalRoleName });
      roleBaseSkill.category.forEach((cat) => {
        cat.requiredSkill.skillset.forEach((skillset) => {
          skillset.skill.forEach((sk) => {
            if (
              sk.skillIdName &&
              sk.skillIdNameId &&
              sk.competencyLevel &&
              sk.currentCompetencyLevel &&
              sk.currentCompetencyLevelName
            ) {
              selectedSkills.push(sk.skillIdName);
              array.push(sk.skillIdName);
              setSkillObj((prevState) => ({
                ...prevState,
                [sk.skillIdName]: {
                  lastUsedSkillYear: { value: sk.lastUsedYear, label: sk.lastUsedYear },
                  lastUsedSkillMonth: { value: sk.lastUsedMonth, label: sk.lastUsedMonth },
                  experienceYear: { value: sk.experienceInYears, label: sk.experienceInYears },
                  experienceMonth: { value: sk.experienceInMonth, label: sk.experienceInMonth },
                  currentLeval: { value: sk.currentCompetencyLevel, label: sk.currentCompetencyLevelName },
                },
              }));
              setSubmittedSkill((prevState) => ({
                ...prevState,
                [sk.skillIdName]: false,
              }));
            }
          });

          if (cat.niceToHaveSkill && cat.niceToHaveSkill.skill.length > 0) {
            cat.niceToHaveSkill.skill.forEach((nth) => {
              if (
                nth.skillIdName &&
                nth.skillIdNameId &&
                nth.competencyLevel &&
                nth.currentCompetencyLevel &&
                nth.currentCompetencyLevelName
              ) {
                selectedSkills.push(nth.skillIdName);
                array.push(nth.skillIdName);
                setSkillObj((prevState) => ({
                  ...prevState,
                  [nth.skillIdName]: {
                    lastUsedSkillYear: { value: nth.lastUsedYear, label: nth.lastUsedYear },
                    lastUsedSkillMonth: { value: nth.lastUsedMonth, label: nth.lastUsedMonth },
                    experienceYear: { value: nth.experienceInYears, label: nth.experienceInYears },
                    experienceMonth: { value: nth.experienceInMonth, label: nth.experienceInMonth },
                    currentLeval: { value: nth.currentCompetencyLevel, label: nth.currentCompetencyLevelName },
                  },
                }));
                setSubmittedSkill((prevState) => ({
                  ...prevState,
                  [nth.skillIdName]: false,
                }));
              }
            });
          }
        });
      });
    }
    setSkillArr(array);
    setSelectedSkill(selectedSkills);
  }, [roleBaseSkill]);

  useEffect(() => {
    const techRole = () => dispatch(actions.getMyDepartmentTechRole);
    techRole();
  }, [dispatch]);

  useEffect(() => {
    if (requiredSkillConfig) {
      setRoleBaseSkill(requiredSkillConfig);
    } else {
      setRoleBaseSkill({});
    }
  }, [requiredSkillConfig]);

  useEffect(() => {
    if (myDepartmentTechRole && myDepartmentTechRole.length > 0) {
      const techRole = [];
      myDepartmentTechRole.forEach((tec) => {
        const obj = {
          value: tec.technicalRoleId,
          label: tec.technicalRoleName,
        };
        techRole.push(obj);
      });
      setTechnicalRole(techRole);
    } else {
      setTechnicalRole([]);
    }
  }, [myDepartmentTechRole, setTechnicalRole]);

  const getYear = () => {
    return Array.from({ length: 51 }, (v, k) => {
      const obj = { value: "", label: "" };
      obj.value = k;
      obj.label = k;
      return obj;
    });
  };
  const getMonth = () => {
    return Array.from({ length: 12 }, (v, k) => {
      const obj = { value: "", label: "" };
      obj.value = k;
      obj.label = k;
      return obj;
    });
  };
  const lastUsedSkils = (skillName) => {
    let currentYear = new Date().getFullYear();
    if (
      skillObj[skillName] &&
      skillObj[skillName]["lastUsedSkillMonth"] &&
      skillObj[skillName]["lastUsedSkillMonth"].value > new Date().getMonth()
    ) {
      currentYear = currentYear - 1;
    }
    const year = [];
    const date = currentYear;
    for (let i = date; i >= 1970; i--) {
      const obj = { value: "", label: "" };
      obj.value = i;
      obj.label = i;
      year.push(obj);
    }
    return year;
  };

  const lastUsedSkillYear = (select, index) => {
    setSkillObj((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        lastUsedSkillYear: select,
      },
    }));
  };

  const getDisplayMonth = (skillName) => {
    const date = new Date();
    let array = [];
    if (
      skillObj[skillName] &&
      skillObj[skillName]["lastUsedSkillYear"] &&
      skillObj[skillName]["lastUsedSkillYear"].value === date.getFullYear()
    ) {
      options_month.forEach((month) => {
        if (month.value < date.getMonth()) {
          array.push(month);
        }
      });
    } else {
      array = options_month;
    }
    return array;
  };

  const lastUsedSkillMonth = (select, index) => {
    setSkillObj((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        lastUsedSkillMonth: select,
      },
    }));
  };

  const experienceYear = (select, index) => {
    setSkillObj((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        experienceYear: select,
      },
    }));
  };

  const experienceMonth = (select, index) => {
    setSkillObj((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        experienceMonth: select,
      },
    }));
  };

  const currentLeval = (select, index) => {
    if (select.label.includes(" (Expected)")) {
      select.label = select.label.split(" (Expected)")[0];
    }
    setSkillObj((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        currentLeval: select,
      },
    }));
  };

  const selectSkill = (skill, event) => {
    setSubmittedSkill((prevState) => ({
      ...prevState,
      [skill]: false,
    }));
    if (event && event.target.checked) {
      setSkillArr((prevState) => [...prevState, skill]);
      setSelectedSkill((prevState) => [...prevState, skill]);
      setSkillObj((prevState) => ({
        ...prevState,
        [skill]: {
          lastUsedSkillYear: "",
          lastUsedSkillMonth: "",
          experienceYear: "",
          experienceMonth: "",
          currentLeval: "",
        },
      }));
    } else {
      setSkillArr((prevState) => prevState.filter((sk) => sk !== skill));
      setSelectedSkill((prevState) => prevState.filter((sk) => sk !== skill));
      setSkillObj((prevState) => {
        prevState[skill]["lastUsedSkillYear"] = "";
        prevState[skill]["lastUsedSkillMonth"] = "";
        prevState[skill]["experienceYear"] = "";
        prevState[skill]["experienceMonth"] = "";
        prevState[skill]["currentLeval"] = "";
        delete prevState[skill];
        return prevState;
      });
    }
  };

  useEffect(() => {
    if (skillArr.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [skillArr.length]);

  useEffect(() => {
    if (saveMyRequiredSkillResponse && !saveMyRequiredSkillResponse.error) {
      successToast(saveMyRequiredSkillResponse.message);
      history.push(routePath.MY_SKILLS);
      setSkillObj({});
      setSkillArr([]);
      setRoleBaseSkill({});
      setRole(null);
    }

    if (saveMyRequiredSkillErrorResponse?.error) errorToast(saveMyRequiredSkillErrorResponse.message);
  }, [saveMyRequiredSkillResponse, saveMyRequiredSkillErrorResponse, disableDropdown, history]);

  const submit = () => {
    let isRelevantSkill = true;
    let isValid = true;
    skillArr.forEach((skill) => {
      if (
        !skillObj[skill]["experienceYear"] ||
        !skillObj[skill]["lastUsedSkillMonth"] ||
        !skillObj[skill]["lastUsedSkillYear"] ||
        !skillObj[skill]["experienceMonth"] ||
        !skillObj[skill]["currentLeval"]
      ) {
        setSubmittedSkill((prevState) => ({
          ...prevState,
          [skill]: true,
        }));
        isValid = false;
      }

      if (skillObj[skill]["experienceMonth"].value === 0 && skillObj[skill]["experienceYear"].value === 0) {
        isRelevantSkill = false;
      }
    });

    if (!isRelevantSkill) {
      isValid = false;
      errorToast("Need to add Relevant Experience");
      return;
    }

    let param;
    if (disableDropdown) {
      param = "UPDATE";
    } else {
      param = "SAVE";
    }
    if (isValid) {
      dispatch(actions.saveMyRequiredSkill(roleBaseSkill, param));
    }
  };

  const getRoleBasedSkill = (select) => {
    if (select) {
      dispatch(actions.getRequiredSkillConfig(select.value));
      setRole(select);
    } else {
      setRoleBaseSkill({});
      setRole(null);
    }
  };

  useEffect(() => {
    if (roleBaseSkill && roleBaseSkill.category) {
      roleBaseSkill.category.forEach((cat) => {
        cat.requiredSkill.skillset.forEach((skillset) => {
          skillset.skill.forEach((skill) => {
            Object.keys(skillObj).map((sk) => {
              if (sk === skill.skillIdName) {
                skill.currentCompetencyLevel = skillObj[sk].currentLeval.value;
                skill.currentCompetencyLevelName = skillObj[sk].currentLeval.label;
                skill.lastUsedYear = skillObj[sk].lastUsedSkillYear.value;
                skill.lastUsedMonth = skillObj[sk].lastUsedSkillMonth.label;
                skill.experienceInYears = skillObj[sk].experienceYear.value;
                skill.experienceInMonth = skillObj[sk].experienceMonth.value;
              } else {
                if (!skillArr.includes(skill.skillIdName)) {
                  skill.currentCompetencyLevel = 0;
                  skill.currentCompetencyLevelName = null;
                  skill.lastUsedYear = null;
                  skill.lastUsedMonth = null;
                  skill.experienceInYears = null;
                  skill.experienceInMonth = null;
                }
              }
              return sk;
            });
          });
        });

        if (cat.niceToHaveSkill && cat.niceToHaveSkill.skill.length > 0) {
          cat.niceToHaveSkill.skill.forEach((nth) => {
            Object.keys(skillObj).map((sk) => {
              if (sk === nth.skillIdName) {
                nth.currentCompetencyLevel = skillObj[sk].currentLeval.value;
                nth.currentCompetencyLevelName = skillObj[sk].currentLeval.label;
                nth.lastUsedYear = skillObj[sk].lastUsedSkillYear.value;
                nth.lastUsedMonth = skillObj[sk].lastUsedSkillMonth.label;
                nth.experienceInYears = skillObj[sk].experienceYear.value;
                nth.experienceInMonth = skillObj[sk].experienceMonth.value;
              } else {
                if (!skillArr.includes(nth.skillIdName)) {
                  nth.currentCompetencyLevel = 0;
                  nth.currentCompetencyLevelName = null;
                  nth.lastUsedYear = null;
                  nth.lastUsedMonth = null;
                  nth.experienceInYears = null;
                  nth.experienceInMonth = null;
                }
              }
              return sk;
            });
          });
        }
      });
    }
  }, [roleBaseSkill, skillArr, skillObj]);

  const getCompentancyLevel = (id) => {
    let options = competency_level_options.map((op) => {
      if (op.value === id) {
        return {
          ...op,
          label: op.label + " (Expected)",
        };
      }
      return {
        ...op,
      };
    });
    return options;
  };

  return (
    <div className={style.skillrequired_wrapper}>
      <SubNavigation type="WMG" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.MY_SKILLS}> My Skills</Link>
              <span> Role Based Skills</span>
            </span>
          </Col>
        </Row>
      </Container>

      <div className={style.skillreq_inner_wrapper}>
        <Container>
          <Row>
            <Col md={12}>
              {/* start select skill section */}
              <div className="box">
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2>Please Select Skills</h2>
                    <h3>We’ve identified the following skills based on your role, please mark skills to add them</h3>
                  </div>
                </div>
                <div className="box-inner-pad">
                  <div className={style.select_tech_role}>
                    <Row className="justify-content-between">
                      <Col md={6} lg={4}>
                        <div className="form-group">
                          <span className={style.dd_title}>Select Technical Role</span>
                          <div className="card_action">
                            <Select
                              options={technicalRoleList}
                              className="custom-default-dd-box"
                              classNamePrefix="select"
                              isSearchable={true}
                              isClearable={true}
                              isDisabled={disableDropdown}
                              placeholder="Select technical role"
                              onChange={(select) => getRoleBasedSkill(select)}
                              value={role}
                            />
                          </div>
                        </div>
                      </Col>
                      {disableDropdown && (
                        <div className={style.technical_role_changed_button}>
                          <button type="button" className="btn btn-secondary" onClick={() => hideTechnicalRole()}>
                            <strong>Technical Role Change</strong>
                          </button>
                        </div>
                      )}
                    </Row>
                  </div>
                </div>

                {roleBaseSkill &&
                  roleBaseSkill.category &&
                  roleBaseSkill.category.map((cat) => {
                    return (
                      <div className="box-inner-pad" key={cat.categoryName}>
                        <div className={[style.box_light_gradient_inner, "box-light-gradient"].join(" ")}></div>

                        <div className={style.skill_box}>
                          <h5>{cat.categoryName}</h5>

                          {/* skill */}
                          {cat.requiredSkill.skillset.map((skillset, skillsetIndex) => {
                            return (
                              <div className={style.skill_criteria_info} key={skillsetIndex}>
                                {
                                  <span className={style.skil_criteria_text}>
                                    Should have atleast{" "}
                                    {skillset.shouldKnowAtleast === 0 ? 1 : skillset.shouldKnowAtleast} of the following
                                    skills
                                  </span>
                                }
                                <div>
                                  <ul className={style.skill_listing}>
                                    {skillset.skill.map((skill) => {
                                      return (
                                        <li key={skill.skillIdNameId}>
                                          <div className="custom-checkbox custom-checkbox-rounded">
                                            <span className={style.skill_requirement}>Required</span>
                                            <input
                                              id={skill.skillIdNameId}
                                              type="checkbox"
                                              onChange={(event) => selectSkill(skill.skillIdName, event)}
                                              checked={selectedSkill.includes(skill.skillIdName)}
                                              value={skill.skillIdNameId}
                                            />

                                            <label htmlFor={skill.skillIdNameId}>{skill.skillIdName}</label>
                                            <div className={style.skill_info_show}>
                                              <div
                                                className={`${style.skill_exp_info} ${
                                                  (submitSkill[skill.skillIdName] &&
                                                    skillObj[skill.skillIdName] &&
                                                    !skillObj[skill.skillIdName]["experienceYear"]) ||
                                                  (submitSkill[skill.skillIdName] &&
                                                    skillObj[skill.skillIdName] &&
                                                    !skillObj[skill.skillIdName]["experienceMonth"])
                                                    ? "error"
                                                    : ""
                                                }`}
                                              >
                                                <span>Relevant Experience</span>
                                                <div
                                                  ref={(el) =>
                                                    (inputRef.current[`${skill.skillIdName}${cat.categoryName}0`] = el)
                                                  }
                                                >
                                                  <Select
                                                    options={getYear()}
                                                    className="custom-default-dd-box"
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder="Years"
                                                    onChange={(select) => experienceYear(select, skill.skillIdName)}
                                                    value={
                                                      skillObj &&
                                                      skillObj[skill.skillIdName] &&
                                                      skillObj[skill.skillIdName]["experienceYear"]
                                                        ? {
                                                            value: skillObj[skill.skillIdName]["experienceYear"].value,
                                                            label: skillObj[skill.skillIdName]["experienceYear"].label,
                                                          }
                                                        : null
                                                    }
                                                  />
                                                </div>
                                                {submitSkill[skill.skillIdName] &&
                                                  skillObj[skill.skillIdName] &&
                                                  !skillObj[skill.skillIdName]["experienceYear"] && (
                                                    <Overlay
                                                      target={
                                                        inputRef.current[`${skill.skillIdName}${cat.categoryName}0`]
                                                      }
                                                      show={true}
                                                      placement="top"
                                                    >
                                                      {(props) => (
                                                        <Tooltip
                                                          id="overlay-example"
                                                          className="skill_error_tooltip"
                                                          {...props}
                                                          show="true"
                                                        >
                                                          <span className="error-icon">
                                                            <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                          </span>
                                                          <span className="error-bubble-txt">{"Required Field"}</span>
                                                        </Tooltip>
                                                      )}
                                                    </Overlay>
                                                  )}
                                                <div
                                                  ref={(el) =>
                                                    (inputRef.current[`${skill.skillIdName}${cat.categoryName}1`] = el)
                                                  }
                                                >
                                                  <Select
                                                    options={getMonth()}
                                                    className="custom-default-dd-box"
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder="Months"
                                                    onChange={(select) => experienceMonth(select, skill.skillIdName)}
                                                    value={
                                                      skillObj &&
                                                      skillObj[skill.skillIdName] &&
                                                      skillObj[skill.skillIdName]["experienceMonth"]
                                                        ? {
                                                            value: skillObj[skill.skillIdName]["experienceMonth"].value,
                                                            label: skillObj[skill.skillIdName]["experienceMonth"].label,
                                                          }
                                                        : null
                                                    }
                                                  />
                                                </div>
                                                {submitSkill[skill.skillIdName] &&
                                                  skillObj[skill.skillIdName] &&
                                                  !skillObj[skill.skillIdName]["experienceMonth"] && (
                                                    <Overlay
                                                      target={
                                                        inputRef.current[`${skill.skillIdName}${cat.categoryName}1`]
                                                      }
                                                      show={true}
                                                      placement="top"
                                                    >
                                                      {(props) => (
                                                        <Tooltip
                                                          id="overlay-example"
                                                          className="skill_error_tooltip"
                                                          {...props}
                                                          show="true"
                                                        >
                                                          <span className="error-icon">
                                                            <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                          </span>
                                                          <span className="error-bubble-txt">{"Required Field"}</span>
                                                        </Tooltip>
                                                      )}
                                                    </Overlay>
                                                  )}
                                              </div>
                                              <div
                                                className={`${style.skill_exp_info} ${
                                                  (submitSkill[skill.skillIdName] &&
                                                    skillObj[skill.skillIdName] &&
                                                    !skillObj[skill.skillIdName]["lastUsedSkillYear"]) ||
                                                  (submitSkill[skill.skillIdName] &&
                                                    skillObj[skill.skillIdName] &&
                                                    !skillObj[skill.skillIdName]["lastUsedSkillMonth"])
                                                    ? "error"
                                                    : ""
                                                }`}
                                              >
                                                <span>Last Used</span>
                                                <div
                                                  ref={(el) =>
                                                    (inputRef.current[`${skill.skillIdName}${cat.categoryName}2`] = el)
                                                  }
                                                >
                                                  <Select
                                                    options={lastUsedSkils(skill.skillIdName)}
                                                    className="custom-default-dd-box"
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder="Years"
                                                    onChange={(select) => lastUsedSkillYear(select, skill.skillIdName)}
                                                    value={
                                                      skillObj &&
                                                      skillObj[skill.skillIdName] &&
                                                      skillObj[skill.skillIdName]["lastUsedSkillYear"]
                                                        ? {
                                                            value:
                                                              skillObj[skill.skillIdName]["lastUsedSkillYear"].value,
                                                            label:
                                                              skillObj[skill.skillIdName]["lastUsedSkillYear"].label,
                                                          }
                                                        : null
                                                    }
                                                  />
                                                </div>
                                                {submitSkill[skill.skillIdName] &&
                                                  skillObj[skill.skillIdName] &&
                                                  !skillObj[skill.skillIdName]["lastUsedSkillYear"] && (
                                                    <Overlay
                                                      target={
                                                        inputRef.current[`${skill.skillIdName}${cat.categoryName}2`]
                                                      }
                                                      show={true}
                                                      placement="top"
                                                    >
                                                      {(props) => (
                                                        <Tooltip
                                                          id="overlay-example"
                                                          className="skill_error_tooltip"
                                                          {...props}
                                                          show="true"
                                                        >
                                                          <span className="error-icon">
                                                            <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                          </span>
                                                          <span className="error-bubble-txt">{"Required Field"}</span>
                                                        </Tooltip>
                                                      )}
                                                    </Overlay>
                                                  )}
                                                <div
                                                  ref={(el) =>
                                                    (inputRef.current[`${skill.skillIdName}${cat.categoryName}3`] = el)
                                                  }
                                                >
                                                  <Select
                                                    options={getDisplayMonth(skill.skillIdName)}
                                                    className="custom-default-dd-box"
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder="Months"
                                                    onChange={(select) => lastUsedSkillMonth(select, skill.skillIdName)}
                                                    value={
                                                      skillObj &&
                                                      skillObj[skill.skillIdName] &&
                                                      skillObj[skill.skillIdName]["lastUsedSkillMonth"]
                                                        ? {
                                                            value:
                                                              skillObj[skill.skillIdName]["lastUsedSkillMonth"].value,
                                                            label:
                                                              skillObj[skill.skillIdName]["lastUsedSkillMonth"].label,
                                                          }
                                                        : null
                                                    }
                                                  />
                                                </div>
                                                {submitSkill[skill.skillIdName] &&
                                                  skillObj[skill.skillIdName] &&
                                                  !skillObj[skill.skillIdName]["lastUsedSkillMonth"] && (
                                                    <Overlay
                                                      target={
                                                        inputRef.current[`${skill.skillIdName}${cat.categoryName}3`]
                                                      }
                                                      show={true}
                                                      placement="top"
                                                    >
                                                      {(props) => (
                                                        <Tooltip
                                                          id="overlay-example"
                                                          className="skill_error_tooltip"
                                                          {...props}
                                                          show="true"
                                                        >
                                                          <span className="error-icon">
                                                            <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                          </span>
                                                          <span className="error-bubble-txt">{"Required Field"}</span>
                                                        </Tooltip>
                                                      )}
                                                    </Overlay>
                                                  )}
                                              </div>
                                              <div
                                                className={`${style.skill_exp_info} ${
                                                  submitSkill[skill.skillIdName] &&
                                                  skillObj[skill.skillIdName] &&
                                                  !skillObj[skill.skillIdName]["currentLeval"]
                                                    ? "error"
                                                    : ""
                                                }`}
                                              >
                                                <span>Competency Level</span>
                                                <div
                                                  ref={(el) =>
                                                    (inputRef.current[`${skill.skillIdName}${cat.categoryName}4`] = el)
                                                  }
                                                >
                                                  <Select
                                                    options={getCompentancyLevel(skill.competencyLevel)}
                                                    className="custom-default-dd-box competensy-level-dd"
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder="Level"
                                                    onChange={(select) => currentLeval(select, skill.skillIdName)}
                                                    value={
                                                      skillObj &&
                                                      skillObj[skill.skillIdName] &&
                                                      skillObj[skill.skillIdName]["currentLeval"]
                                                        ? {
                                                            value: skillObj[skill.skillIdName]["currentLeval"].value,
                                                            label: skillObj[skill.skillIdName]["currentLeval"].label,
                                                          }
                                                        : null
                                                    }
                                                  />
                                                </div>
                                                {submitSkill[skill.skillIdName] &&
                                                  skillObj[skill.skillIdName] &&
                                                  !skillObj[skill.skillIdName]["currentLeval"] && (
                                                    <Overlay
                                                      target={
                                                        inputRef.current[`${skill.skillIdName}${cat.categoryName}4`]
                                                      }
                                                      show={true}
                                                      placement="top"
                                                    >
                                                      {(props) => (
                                                        <Tooltip
                                                          id="overlay-example"
                                                          className="skill_error_tooltip"
                                                          {...props}
                                                          show="true"
                                                        >
                                                          <span className="error-icon">
                                                            <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                          </span>
                                                          <span className="error-bubble-txt">{"Required Field"}</span>
                                                        </Tooltip>
                                                      )}
                                                    </Overlay>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            );
                          })}

                          {/* niceToHaveSkill */}
                          <div className={style.skill_criteria_info}>
                            {cat.niceToHaveSkill &&
                              cat.niceToHaveSkill.skill &&
                              cat.niceToHaveSkill.skill.length > 0 && (
                                <span className={style.skil_criteria_text}>Nice To Have following skill</span>
                              )}
                            <ul className={style.skill_listing}>
                              {cat.niceToHaveSkill &&
                                cat.niceToHaveSkill.skill.map((nth) => {
                                  return (
                                    <li key={nth.skillIdNameId}>
                                      <div className="custom-checkbox custom-checkbox-rounded">
                                        <span className={style.skill_requirement}>Nice to have</span>
                                        <input
                                          id={nth.skillIdNameId}
                                          type="checkbox"
                                          onChange={(event) => selectSkill(nth.skillIdName, event)}
                                          checked={selectedSkill.includes(nth.skillIdName)}
                                          value={nth.skillIdNameId}
                                        />
                                        <label htmlFor={nth.skillIdNameId}>{nth.skillIdName}</label>
                                        <div className={style.skill_info_show}>
                                          <div
                                            className={`${style.skill_exp_info} ${
                                              (submitSkill[nth.skillIdName] &&
                                                skillObj[nth.skillIdName] &&
                                                !skillObj[nth.skillIdName]["experienceYear"]) ||
                                              (submitSkill[nth.skillIdName] &&
                                                skillObj[nth.skillIdName] &&
                                                !skillObj[nth.skillIdName]["experienceMonth"])
                                                ? "error"
                                                : ""
                                            }`}
                                          >
                                            <span>Relevant Experience</span>
                                            <div
                                              ref={(el) =>
                                                (inputRef.current[`${nth.skillIdName}${cat.categoryName}0nth`] = el)
                                              }
                                            >
                                              <Select
                                                options={getYear()}
                                                className="custom-default-dd-box"
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                placeholder="Years"
                                                onChange={(select) => experienceYear(select, nth.skillIdName)}
                                                value={
                                                  skillObj &&
                                                  skillObj[nth.skillIdName] &&
                                                  skillObj[nth.skillIdName]["experienceYear"]
                                                    ? {
                                                        value: skillObj[nth.skillIdName]["experienceYear"].value,
                                                        label: skillObj[nth.skillIdName]["experienceYear"].label,
                                                      }
                                                    : null
                                                }
                                              />
                                            </div>
                                            {submitSkill[nth.skillIdName] &&
                                              skillObj[nth.skillIdName] &&
                                              !skillObj[nth.skillIdName]["currentLeval"] && (
                                                <Overlay
                                                  target={inputRef.current[`${nth.skillIdName}${cat.categoryName}0nth`]}
                                                  show={true}
                                                  placement="top"
                                                >
                                                  {(props) => (
                                                    <Tooltip
                                                      id="overlay-example"
                                                      className="skill_error_tooltip"
                                                      {...props}
                                                      show="true"
                                                    >
                                                      <span className="error-icon">
                                                        <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                      </span>
                                                      <span className="error-bubble-txt">{"Required Field"}</span>
                                                    </Tooltip>
                                                  )}
                                                </Overlay>
                                              )}
                                            <div
                                              ref={(el) =>
                                                (inputRef.current[`${nth.skillIdName}${cat.categoryName}1nth`] = el)
                                              }
                                            >
                                              <Select
                                                options={getMonth()}
                                                className="custom-default-dd-box"
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                placeholder="Months"
                                                onChange={(select) => experienceMonth(select, nth.skillIdName)}
                                                value={
                                                  skillObj &&
                                                  skillObj[nth.skillIdName] &&
                                                  skillObj[nth.skillIdName]["experienceMonth"]
                                                    ? {
                                                        value: skillObj[nth.skillIdName]["experienceMonth"].value,
                                                        label: skillObj[nth.skillIdName]["experienceMonth"].label,
                                                      }
                                                    : null
                                                }
                                              />
                                            </div>
                                            {submitSkill[nth.skillIdName] &&
                                              skillObj[nth.skillIdName] &&
                                              !skillObj[nth.skillIdName]["currentLeval"] && (
                                                <Overlay
                                                  target={inputRef.current[`${nth.skillIdName}${cat.categoryName}1nth`]}
                                                  show={true}
                                                  placement="top"
                                                >
                                                  {(props) => (
                                                    <Tooltip
                                                      id="overlay-example"
                                                      className="skill_error_tooltip"
                                                      {...props}
                                                      show="true"
                                                    >
                                                      <span className="error-icon">
                                                        <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                      </span>
                                                      <span className="error-bubble-txt">{"Required Field"}</span>
                                                    </Tooltip>
                                                  )}
                                                </Overlay>
                                              )}
                                          </div>
                                          <div
                                            className={`${style.skill_exp_info} ${
                                              (submitSkill[nth.skillIdName] &&
                                                skillObj[nth.skillIdName] &&
                                                !skillObj[nth.skillIdName]["lastUsedSkillYear"]) ||
                                              (submitSkill[nth.skillIdName] &&
                                                skillObj[nth.skillIdName] &&
                                                !skillObj[nth.skillIdName]["lastUsedSkillMonth"])
                                                ? "error"
                                                : ""
                                            }`}
                                          >
                                            <span>Last Used</span>
                                            <div
                                              ref={(el) =>
                                                (inputRef.current[`${nth.skillIdName}${cat.categoryName}2nth`] = el)
                                              }
                                            >
                                              <Select
                                                options={lastUsedSkils(nth.skillIdName)}
                                                className="custom-default-dd-box"
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                placeholder="Years"
                                                onChange={(select) => lastUsedSkillYear(select, nth.skillIdName)}
                                                value={
                                                  skillObj &&
                                                  skillObj[nth.skillIdName] &&
                                                  skillObj[nth.skillIdName]["lastUsedSkillYear"]
                                                    ? {
                                                        value: skillObj[nth.skillIdName]["lastUsedSkillYear"].value,
                                                        label: skillObj[nth.skillIdName]["lastUsedSkillYear"].label,
                                                      }
                                                    : null
                                                }
                                              />
                                            </div>
                                            {submitSkill[nth.skillIdName] &&
                                              skillObj[nth.skillIdName] &&
                                              !skillObj[nth.skillIdName]["currentLeval"] && (
                                                <Overlay
                                                  target={inputRef.current[`${nth.skillIdName}${cat.categoryName}2nth`]}
                                                  show={true}
                                                  placement="top"
                                                >
                                                  {(props) => (
                                                    <Tooltip
                                                      id="overlay-example"
                                                      className="skill_error_tooltip"
                                                      {...props}
                                                      show="true"
                                                    >
                                                      <span className="error-icon">
                                                        <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                      </span>
                                                      <span className="error-bubble-txt">{"Required Field"}</span>
                                                    </Tooltip>
                                                  )}
                                                </Overlay>
                                              )}
                                            <div
                                              ref={(el) =>
                                                (inputRef.current[`${nth.skillIdName}${cat.categoryName}3nth`] = el)
                                              }
                                            >
                                              <Select
                                                options={getDisplayMonth(nth.skillIdName)}
                                                className="custom-default-dd-box"
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                placeholder="Months"
                                                onChange={(select) => lastUsedSkillMonth(select, nth.skillIdName)}
                                                value={
                                                  skillObj &&
                                                  skillObj[nth.skillIdName] &&
                                                  skillObj[nth.skillIdName]["lastUsedSkillMonth"]
                                                    ? {
                                                        value: skillObj[nth.skillIdName]["lastUsedSkillMonth"].value,
                                                        label: skillObj[nth.skillIdName]["lastUsedSkillMonth"].label,
                                                      }
                                                    : null
                                                }
                                              />
                                            </div>
                                            {submitSkill[nth.skillIdName] &&
                                              skillObj[nth.skillIdName] &&
                                              !skillObj[nth.skillIdName]["currentLeval"] && (
                                                <Overlay
                                                  target={inputRef.current[`${nth.skillIdName}${cat.categoryName}3nth`]}
                                                  show={true}
                                                  placement="top"
                                                >
                                                  {(props) => (
                                                    <Tooltip
                                                      id="overlay-example"
                                                      className="skill_error_tooltip"
                                                      {...props}
                                                      show="true"
                                                    >
                                                      <span className="error-icon">
                                                        <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                      </span>
                                                      <span className="error-bubble-txt">{"Required Field"}</span>
                                                    </Tooltip>
                                                  )}
                                                </Overlay>
                                              )}
                                          </div>
                                          <div
                                            className={`${style.skill_exp_info} ${
                                              submitSkill[nth.skillIdName] &&
                                              skillObj[nth.skillIdName] &&
                                              !skillObj[nth.skillIdName]["currentLeval"]
                                                ? "error"
                                                : ""
                                            }`}
                                          >
                                            <span>Competency Level</span>
                                            <div
                                              ref={(el) =>
                                                (inputRef.current[`${nth.skillIdName}${cat.categoryName}5nth`] = el)
                                              }
                                            >
                                              <Select
                                                options={getCompentancyLevel(nth.competencyLevel)}
                                                className="custom-default-dd-box competensy-level-dd"
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                placeholder="Level"
                                                onChange={(select) => currentLeval(select, nth.skillIdName)}
                                                value={
                                                  skillObj &&
                                                  skillObj[nth.skillIdName] &&
                                                  skillObj[nth.skillIdName]["currentLeval"]
                                                    ? {
                                                        value: skillObj[nth.skillIdName]["currentLeval"].value,
                                                        label: skillObj[nth.skillIdName]["currentLeval"].label,
                                                      }
                                                    : null
                                                }
                                              />
                                            </div>
                                            {submitSkill[nth.skillIdName] &&
                                              skillObj[nth.skillIdName] &&
                                              !skillObj[nth.skillIdName]["currentLeval"] && (
                                                <Overlay
                                                  target={inputRef.current[`${nth.skillIdName}${cat.categoryName}5nth`]}
                                                  show={true}
                                                  placement="top"
                                                >
                                                  {(props) => (
                                                    <Tooltip
                                                      id="overlay-example"
                                                      className="skill_error_tooltip"
                                                      {...props}
                                                      show="true"
                                                    >
                                                      <span className="error-icon">
                                                        <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                                                      </span>
                                                      <span className="error-bubble-txt">{"Required Field"}</span>
                                                    </Tooltip>
                                                  )}
                                                </Overlay>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {requiredSkillConfig === "No Data" && (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap40"></div>
                  </div>
                )}
              </div>

              {/* start button section */}
              {roleBaseSkill && roleBaseSkill.category && (
                <div className={["box-flex-center box", style.saveBox].join(" ")}>
                  <div className={style.save_box_left}>
                    <Button type="button" variant={"primary"} onClick={submit} disabled={disabled}>
                      <strong>{disableDropdown ? "Update Skills" : "Add Skills"}</strong>
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <TechnicalRoleChangePopup show={isModalTechnicalRole} hide={hideTechnicalRole} data={technicalRoleList} />
    </div>
  );
}
