import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import ReactTags from "react-tag-autocomplete";
import moment from "moment";
import style from "../CreateObjective/CreateObjective.module.scss";
import KeyResult from "../KeyResult/KeyResult";
import * as actions from "store/actions/index";
import http from "util/http";
import { errorToast, pleaseSelectDate, requiredFieldMissing, successToast } from "util/general";
import { endOfDay } from "util/dateUtils";
import { MANAGER_DATA } from "util/endpoints";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import PreviouslyCreateObjective from "../CreateObjective/PreviouslyCreateObjective";
import OverlayError from "../OverlayError/OverlayError";
import AssignBackBox from "../AssignObjectives/AssignBackBox";
import EmployeeSelectAssignDate from "../AssignObjectives/EmployeeSelectAssignDate";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import MultiSelect from "react-multi-select-component";
import Select from "react-select";

function SelfObjective() {
  const loggedInUser = storage.getUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    suggestionTags,
    draftSelfObjective,
    objectiveAdded,
    objectiveValidation,
    selfObjectiveKeyErrors,
    createObjectiveResponse,
    selfObjectiveWeightage,
    assignObjectiveResponse,
    isLoading,
    selfObjectiveData,
    predefinedSelfObjective,
  } = useSelector((state) => state.objectives);
  const { objectives } = useSelector((state) => state.checkIn);
  const targetTitle = useRef(null);
  const [keyCount, setKeyCount] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [reviewer, setReviewer] = useState([]);
  const [isOther, setIsOther] = useState(false);
  const [errors,setErrors] = useState({});
  let a = moment(selfObjectiveData && selfObjectiveData.startDate);
  let b,
    duration,
    employee = null;
  if (selfObjectiveData && selfObjectiveData.endDate) {
    b = moment(selfObjectiveData.endDate);
    duration = b.diff(a, "days") + 1;
  }
  if (loggedInUser) {
    employee = {
      ...loggedInUser,
      dispArr: loggedInUser.displayPicture,
      employeeInitial: loggedInUser.empInitial,
    };
  }

  const defaultKeyPoint = {
    description: "",
    type: "",
    start: "",
    end: "",
    weightage: 1,
    stretch: false,
  };

  useEffect(() => {
    if (assignObjectiveResponse) {
      if (assignObjectiveResponse.success) {
        setSubmitDisabled(false);
        successToast(assignObjectiveResponse.message);
        dispatch({ type: "CLEAR_FILE_OBJECTIVE_RESPONSE" });
        history.push(routePath.MY_OBJECTIVES);
      } else {
        setSubmitDisabled(false);
        errorToast(assignObjectiveResponse.message);
        dispatch({ type: "CLEAR_FILE_OBJECTIVE_RESPONSE" });
      }
    }
  }, [assignObjectiveResponse]);

  useEffect(() => {
    dispatch(actions.fetchTag());
    dispatch(actions.DraftObjective());
    dispatch(actions.getSelfPrdefinedObjective(loggedInUser?.podInformation,loggedInUser?.country,loggedInUser?.band));
  }, [objectiveAdded]);

  useEffect(() => {
    dispatch(actions.AllObjective("MANAGER"));
    getReviewer();
  }, []);

  useEffect(() => {
    if (Object.keys(draftSelfObjective).length > 0 || draftSelfObjective) {
      let errorsArr = [...objectiveValidation];

      if (document.getElementById("objective") === "" || draftSelfObjective.title == null) {
        let index = errorsArr.findIndex((x) => x.key === "title");
        index === -1 && errorsArr.push({ key: "title", showError: false });
      } else {
        errorsArr = errorsArr.filter((item) => item.key !== "title");
      }

      if (draftSelfObjective.keyPoints == null || selfObjectiveKeyErrors.length > 0) {
        let index = errorsArr.findIndex((x) => x.key === "keyPoints");
        index === -1 && errorsArr.push({ key: "keyPoints", showError: false });
      } else {
        errorsArr = errorsArr.filter((item) => item.key !== "keyPoints");
        setKeyCount(draftSelfObjective.keyPoints.length);
      }
      dispatch(actions.SetObjectiveValidation(errorsArr));
    }
  }, [draftSelfObjective, selfObjectiveKeyErrors]);

  const getReviewer = () => {
    let params = {
      employeeId: loggedInUser?.employeeId,
      employeeParentGroupId: loggedInUser?.parentGroupId,
      employeeSubGroupId: loggedInUser?.subGroupId,
    };
    http
      .get(MANAGER_DATA, {}, { params })
      .then((response) => response.data.data)
      .then((data) => {
        if (data) setReviewer(data.managersList);
      })
      .catch(() => {});
  };

  const addError = (key) => {
    let errors = [...objectiveValidation];
    let error = { key: key, showError: true };

    let index = errors.findIndex((x) => x.key === key);
    index === -1 ? errors.push(error) : (errors[index].showError = true);
    dispatch(actions.SetObjectiveValidation(errors));
  };

  const removeError = (key) => {
    let errors = null;
    errors = objectiveValidation.filter((item) => item.key !== key);
    dispatch(actions.SetObjectiveValidation(errors));
  };

  const objectiveHandler = (item) => {
    setErrors({ ...errors, OKR_title: false });
    dispatch({
      type: "SET_SELF_OBJECTIVE_DATA",
      payload: {
        selectReviewer: "",
        selectedReviewer: " ",
      },
    });
    if(item?.value === "Other"){
      setIsOther(true);
    } else {
      setIsOther(false);
    }
    if (draftSelfObjective?.objectiveId && item?.value.trim() && item?.value !== "Other") {
      dispatch(
        actions.AddObjective({
          objectiveId: draftSelfObjective?.objectiveId,
          isSelfObjective: true,
          title: item.value.trim(),
          weightage: item.weightage,
          bandWiseObjectiveId: item.bandWiseObjectiveId,
          band: item.band,
          isOther: false,
          description:item.description
        })
      );
      removeError("title");
    } else if (!draftSelfObjective?.objectiveId && item.value && item?.value !== "Other") {
      dispatch(actions.AddObjective({ 
          isSelfObjective: true,
          title: item.value, 
          weightage: item.weightage,  
          bandWiseObjectiveId: item.bandWiseObjectiveId,
          band: item.band,
          isOther: false ,
          description:item.description
        }));
      removeError("title");
    } else if (draftSelfObjective.objectiveId && item?.value === "Other"){
      dispatch(actions.AddObjective({ objectiveId: draftSelfObjective.objectiveId, isSelfObjective: true, isOther: true }));
      removeError("title");
    } else if (!draftSelfObjective.objectiveId && item?.value === "Other"){
      dispatch(actions.AddObjective({ isSelfObjective: true, isOther: true }));
      removeError("title");
    } else {
      addError("title");
    }
  };

  const otherObjectiveHandler = (event, objectiveId) => {
    if (objectiveId && event.target.value.trim()) {
      dispatch(
        actions.AddObjective({
          title: event.target.value.trim(),
          objectiveId: draftSelfObjective?.objectiveId,
        })
      );
      removeError("title");
    } else if (!objectiveId && event.target.value) {
      dispatch(actions.AddObjective({ title: event.target.value }));
      removeError("title");
    } else {
      addError("title");
    }
  }

  const weightageHandler = (event, objectiveId) => {
    if (objectiveId && event.target.value.trim()) {
      dispatch(
        actions.AddObjective({
          weightage: event.target.value.trim(),
          objectiveId: draftSelfObjective.objectiveId,
        })
      );
      removeError("weightage");
    } else if (!objectiveId && event.target.value) {
      dispatch(actions.AddObjective({ weightage: event.target.value }));
      removeError("weightage");
    } else {
      addError("weightage");
    }
  }

  const descriptionHandler = (event, objectiveId) => {
    if (objectiveId) {
      dispatch(
        actions.AddObjective({
          objectiveId: objectiveId,
          isSelfObjective: true,
          description: event.target.value.trim(),
        })
      );
    } else {
      dispatch(actions.AddObjective({ description: event.target.value.trim(), isSelfObjective: true }));
    }
  };

  const objectiveAddedHandler = (flag) => {
    dispatch(actions.ObjectiveAdded(flag));
  };

  const saveObjectiveHandler = () => {
    setSubmitDisabled(true);
    if(draftSelfObjective?.title === undefined){
      setSubmitDisabled(false);
      setErrors({ ...errors, OKR_title: true })
      requiredFieldMissing();
      return;
    }
    if (document.getElementById("otherTitle")?.value === "") {
      setSubmitDisabled(false);
      setErrors({ ...errors, OKR_otherTitle: true })
      requiredFieldMissing();
      return;
    }
    if (document.getElementById("weightage")?.value === "") {
      setSubmitDisabled(false);
      setErrors({ ...errors, ORK_weightage: true })
      requiredFieldMissing();
      return;
    }
    if (document.getElementById("weightage")?.value > 100 || document.getElementById("weightage")?.value < 1) {
      setSubmitDisabled(false);
      errorToast("weightage should not be less than 1 and more than 100");
      return;
    }
    if (selfObjectiveWeightage < 100) {
      setSubmitDisabled(false);
      errorToast(`Add more ${100 - selfObjectiveWeightage}% weightage`);
      return;
    } else if (selfObjectiveWeightage > 100) {
      setSubmitDisabled(false);
      errorToast(`Delete ${selfObjectiveWeightage - 100}% weightage`);
      return;
    }
    if (objectiveValidation.length > 0) {
      setSubmitDisabled(false);
      const submitedErrors = objectiveValidation.map((err) => ({
        ...err,
        showError: true,
      }));
      dispatch(actions.SetObjectiveValidation(submitedErrors));
      requiredFieldMissing();
      return;
    } else if (
      !(selfObjectiveData && selfObjectiveData.startDate) ||
      !(selfObjectiveData && selfObjectiveData.endDate)
    ) {
      setSubmitDisabled(false);
      pleaseSelectDate();
      return;
    } else if (!(selfObjectiveData && selfObjectiveData.selectReviewer)) {
      setSubmitDisabled(false);
      errorToast("Please select manager");
      return;
    } else if (selfObjectiveData.totalWeightagae == 100) {
      setSubmitDisabled(false);
      errorToast("You are already assigned with 100% weightage OKR");
      return;
    } else if ((100 - selfObjectiveData.totalWeightagae) < draftSelfObjective.weightage) {
      setSubmitDisabled(false);
      errorToast("Please check total weightage, as it is exceeding 100%");
      return;
    } else {
      let data = {
        objectiveId: draftSelfObjective.objectiveId,
        managerData: selfObjectiveData && selfObjectiveData.selectReviewer,
        employeeData: {
          employeeId: loggedInUser?.employeeId,
          empName: loggedInUser?.displayName,
          designation: loggedInUser?.designation,
          department: loggedInUser?.department,
          displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
          subGroupId: loggedInUser?.subGroupId,
          subGroupName: loggedInUser?.subGroupName,
          parentGroupId: loggedInUser?.parentGroupId,
          parentGroupName: loggedInUser?.parentGroupName,
        },
        startDate: selfObjectiveData.startDate,
        endDate: endOfDay(selfObjectiveData.endDate),
      };
      dispatch(actions.AssignSelfObjective(draftSelfObjective && draftSelfObjective.objectiveId, data));
    }
  };

  const handleDelete = (i) => {
    if (i !== -1) {
      const tagId = draftSelfObjective.tags[i].id;
      draftSelfObjective.tags.splice(i, 1);
      dispatch(actions.DeleteTags([tagId], draftSelfObjective.objectiveId, true));
    }
  };

  const handleAddition = (tag) => {
    let index = draftSelfObjective.tags && draftSelfObjective.tags.findIndex((x) => x.name === tag.name);
    if (index === -1 || index === undefined || index === null) {
      if (!draftSelfObjective.objectiveId) {
        dispatch(actions.AddObjective({ tags: { ...tag }, isSelfObjective: true }));
      } else {
        dispatch(
          actions.AddObjective({
            objectiveId: draftSelfObjective.objectiveId,
            tags: { ...tag },
          })
        );
      }
    }
  };

  const handleReviewer = (event) => {
    let data = JSON.parse(event.target.value);
    let managerData = {
      employeeId: data.employeeId,
      empName: data.empName,
      designation: data.designation,
      department: data.dept,
      displayPicture: data.dispArr || data.empInitial,
      parentGroupId: data.parentGroupId,
      subGroupId: data.subGroupId,
      parentGroupName: data.parentGroupName,
      subGroupName: data.subGroupName,
    };
    dispatch({
      type: "SET_SELF_OBJECTIVE_DATA",
      payload: {
        selectReviewer: managerData,
        selectedReviewer: event.target.value,
      },
    });
    let requestBody = {
      employee: {
        employeeId: loggedInUser?.employeeId,
        subGroupId: loggedInUser?.subGroupId,
        parentGroupId: loggedInUser?.parentGroupId
      },
      manager: {
        employeeId: data.employeeId,
        subGroupId: data.subGroupId,
        parentGroupId: data.parentGroupId,
      },
    }
    dispatch(actions.getUserObjectiveWithManagerEmployeePair(requestBody));
  };

  const handleBack = () => history.push(routePath.MY_OBJECTIVES);

  const handleOnKeyPress = (e) => {
    e = e || window.event;
    let charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    let charStr = String.fromCharCode(charCode);
    if (e.charCode < 48 || !charStr.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69) {
      e.preventDefault();
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: errors.OKR_title && '1px solid #ff8b67',
    }),
  };

  const previouslyCreateObjective = objectives?.sort(
    (a, b) => moment(b.createdDate) - moment(a.createdDate)
  );

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <SubNavigation type="Objectives" />
      <Container>
        <Row>
          <Col lg={4} md={3} sm={6} className="col-12">
            <h1 className="page-title pb-0">Objectives</h1>
            <span className="st-breadcrumb">
              Objectives
              <span>
                <Link to={routePath.MY_OBJECTIVES}>My Objectives</Link>
              </span>
              <span>Create My Objective</span>
            </span>
          </Col>
          <Col lg={8} md={9} sm={6} className="text-right">
            <div className="draftSaved">
              <span className="label">Draft Saved:</span>
              <span className="data">{moment(draftSelfObjective.draftSavedDate).format("ddd D MMM h:mm a")}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Create My Objective</h2>
                </div>
              </div>
              <div className="box-inner-pad">
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                    <h4 className={style.self_assign_note}>
                      <span>Note: </span>This is self assign objective
                    </h4>
                    <div className={[style.create_objective_title, "form-group"].join(" ")}>
                      <label className="form-label">
                        What is your objective?<span className="text-danger"> * </span>
                      </label>
                      </div>
                      <div className="tags_wrapper">
                      <Select
                          options={predefinedSelfObjective}
                          // className={errors?.subGroups ? "limit-reached" : "multi-select"}
                          className={"multi-select"}
                          styles={customStyles}
                          value = {{label : draftSelfObjective?.isOther ? "Other" : draftSelfObjective?.title} || ""}
                          onChange={objectiveHandler}
                        />
                      </div>
                      <OverlayError target={targetTitle} objectiveValidation={objectiveValidation} errorFor="title" />
                   
                  </Col>
                </Row>
                {draftSelfObjective?.isOther &&
                  <Row className={style.create_objective_wrapper}>
                    <Col className={style.create_objective_data_wrapper}>
                      <Form.Group className={style.formGroup}>
                        <Form.Label>
                          Add Objective Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="otherTitle"
                          maxLength="200"
                          className={[(errors && errors.OKR_otherTitle ? style.errorBox : "" ), style.weightageBox ].join(" ")}
                          defaultValue={draftSelfObjective?.isOther ? isOther ? "" : draftSelfObjective?.title : draftSelfObjective?.title || ""}
                          // defaultValue={draftSelfObjective?.title || ""}
                          onBlur={(e) => otherObjectiveHandler(e, draftSelfObjective?.objectiveId)}
                          onChange={(e) => {
                            if (e.target.value.trim()) {
                              setErrors({ ...errors, OKR_otherTitle: false });
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                }
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                  <Form.Group className={style.formGroup}>
                    <Form.Label>
                     Weightage<span className="text-danger">*</span>
                    </Form.Label>
                        <Form.Control
                          type="number"
                          id="weightage"
                          maxLength="200"
                          disabled={draftSelfObjective?.isOther ? false : true}
                          onWheel={(e) => e.target.blur()} // stopping changing numbers with mouse scrolling 
                          className={[(errors && errors.ORK_weightage ? style.errorBox : "" ), style.weightageBox ].join(" ")}
                          defaultValue={draftSelfObjective?.isOther ? isOther ? "" : draftSelfObjective?.weightage : draftSelfObjective?.weightage || ""}
                          onBlur={(e) => weightageHandler(e, draftSelfObjective?.objectiveId)}
                          onChange={(e) => {
                            if (e.target.value.trim()) {
                              setErrors({ ...errors, ORK_weightage: false });
                            }
                          }}
                          onKeyPress={handleOnKeyPress}
                          onKeyDown={handleOnKeyDown}
                        /> 
                  </Form.Group>
                  </Col>
                </Row>
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                  <Form.Group className={style.formGroup}>
                  <Form.Label>Description</Form.Label>
                      <div className={[style.textbox_wrapper, "border-0"].join(" ")}>
                        <div className="textarea-wrapper ">
                          <Form.Control
                            as="textarea"
                            maxLength="512"
                            placeholder="Add Description"
                            onBlur={(e) => descriptionHandler(e, draftSelfObjective.objectiveId)}
                            defaultValue={draftSelfObjective?.isOther ? isOther ? "" : draftSelfObjective?.description : draftSelfObjective?.description || ""}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                {/* commented because this requirement given by hr  */}
                {/* <Row className={[style.create_objective_wrapper, "tags_wrapper"].join(" ")}>
                  <Col className={style.create_objective_data_wrapper}>
                    <div className="form-group">
                      <label className="form-label"> Tags </label>
                      <div className={style.textbox_wrapper}>
                        <ReactTags
                          noSuggestionsText="Click enter to add new tag"
                          tags={draftSelfObjective.tags ? draftSelfObjective.tags : []}
                          allowNew={true}
                          suggestions={suggestionTags ? suggestionTags : []}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                        />
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </div>
              <div className="box-inner-pad">
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                    <div className="form-group">
                      <label className="form-label">Define Key Results</label>
                      {draftSelfObjective &&
                        draftSelfObjective.keyPoints &&
                        draftSelfObjective.keyPoints.map((item) => (
                          <KeyResult
                            isEditable={true}
                            keyPointLength={draftSelfObjective.keyPoints.length}
                            style={style}
                            key={item.keyId}
                            index={item.keyId}
                            keyPoint={item}
                            objectiveId={draftSelfObjective.objectiveId}
                            isUpdate={false}
                          />
                        ))}
                      {(objectiveAdded || keyCount < 1) && (
                        <KeyResult
                          isEditable={false}
                          style={style}
                          index={"create"}
                          keyPoint={defaultKeyPoint}
                          objectiveId={draftSelfObjective.objectiveId}
                          isUpdate={true}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <span
                      className={["text_link", keyCount < 1 || objectiveAdded ? "disabled" : ""].join(" ")}
                      onClick={() => objectiveAddedHandler(true)}
                    >
                      Add New Key Result
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
            <EmployeeSelectAssignDate
              isPage="SELF_OBJECTIVE"
              handleStartDate={(date) => {
                if (date > new Date()) {
                  setMinDate(date);
                }
                dispatch({
                  type: "SET_SELF_OBJECTIVE_DATA",
                  payload: {
                    startDate: date,
                  },
                });
              }}
              handleEndDate={(date) =>
                dispatch({
                  type: "SET_SELF_OBJECTIVE_DATA",
                  payload: {
                    endDate: date,
                  },
                })
              }
              selectedReviewer={selfObjectiveData && selfObjectiveData.selectedReviewer}
              duration={duration}
              minDate={minDate}
              reviewer={reviewer}
              handleReviewer={handleReviewer}
              endDate={selfObjectiveData && selfObjectiveData.endDate}
              startDate={selfObjectiveData && selfObjectiveData.startDate}
              employee={employee}
            />
            <AssignBackBox handleAssign={saveObjectiveHandler} handleBack={handleBack} disabled={submitDisabled} />
          </Col>
          <Col lg={4}>
            <PreviouslyCreateObjective previouslyCreateObjective={previouslyCreateObjective} isSelf={true} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SelfObjective;
