import { INFORMATION_HUB_ACTIONS } from "store/actions/actionTypes";

const initialState = {
  isLoading: false,
  informationHubResponse: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INFORMATION_HUB_ACTIONS.LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case INFORMATION_HUB_ACTIONS.GET_LIST_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
