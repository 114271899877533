import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import style from "../pages/CheckIns/my-history/my-history.module.scss";
import moment from "moment";
import SliderDot from "../components/Patterns/SliderDot";

export default function PreviousCheckIns(props) {
  // const surveyCycleId = useSelector(state => state.myHistory.surveyCycleId);
  const [currentSurveyCycle, setCurrentSurveyCycle] = useState(null);

  useEffect(() => {
    if (props.currentSurveyCycle) {
      setCurrentSurveyCycle(props.currentSurveyCycle);
    } else if (props.data && props.data.length > 0) {
      setCurrentSurveyCycle(props.data[0].surveyCyclesId);
      // props.isPastHistory && props.setCurrentSurveyCycleID(props.data[0].surveyCyclesId);
    }
  }, [props.data, props.currentSurveyCycle]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //adding slick slider active class on click of slider
  function toggleItem(elem) {
    for (var i = 0; i < elem.length; i++) {
      elem[i].addEventListener("click", function (e) {
        var current = this;
        for (var i = 0; i < elem.length; i++) {
          if (current !== elem[i]) {
            elem[i].classList.remove("active-cycle");
            elem[i].children[0].children[0].classList.remove("active-cycle"); //remove classname if already available in document
          } else {
            current.classList.add("active-cycle");
          }
        }
        e.preventDefault();
      });
    }
  }
  toggleItem(document.querySelectorAll(".slick-slider-box-wrapper .slick-slide"));

  const data = props.data;
  return (
    <div className="box slick-slider-box-wrapper">
      <Slider className={style.slick_slider} {...sliderSettings}>
        {data &&
          data.map((value) => {
            const checkinDetails = {
              start: value.startDate,
              end: value.endDate,
            };
            let currentStyle = null;
            let year = null;
            if (value.surveyCyclesId === currentSurveyCycle) {
              currentStyle = "active-cycle";
            }
            if (moment(value.endDate).format("YYYY") !== moment(value.startDate).format("YYYY")) {
              year = `${moment(value.startDate).format("YYYY")} - ${moment(value.endDate).format("YYYY")}`;
            }

            if (value.status === "PENDING_FROM_MEMBER") {
              return (
                <div
                  key={value.surveyCyclesId}
                  className={["pending_from_member", currentStyle].join(" ")}
                  onClick={() => props.surveyClick(value.surveyCyclesId, checkinDetails)}
                >
                  <div className={style.durationRange}>
                    <SliderDot value={value} />
                    <div className={style.date}>
                      {moment(value.startDate).format("MMM DD")} – {moment(value.endDate).format("MMM DD")}
                    </div>
                  </div>
                  <div className={style.status}>{year || moment(value.endDate).format("YYYY")}</div>
                </div>
              );
            } else if (value.status === "PENDING_FOR_REVIEW") {
              return (
                <div
                  key={value.surveyCyclesId}
                  className={[style.slick_slide, style.pending, currentStyle].join(" ")}
                  onClick={() => props.surveyClick(value.surveyCyclesId, checkinDetails)}
                >
                  <div className={style.durationRange}>
                    <SliderDot value={value} />
                    <div className={style.date}>
                      {moment(value.startDate).format("MMM DD")} – {moment(value.endDate).format("MMM DD")}
                    </div>
                  </div>
                  <div className={style.status}>{year || moment(value.endDate).format("YYYY")}</div>
                </div>
              );
            } else {
              return (
                <div
                  key={value.surveyCyclesId}
                  className={["reviewed-on", currentStyle].join(" ")}
                  onClick={() => props.surveyClick(value.surveyCyclesId, checkinDetails)}
                >
                  <div className={style.durationRange}>
                    <SliderDot value={value} />
                    <div className={style.date}>
                      {moment(value.startDate).format("MMM DD")} – {moment(value.endDate).format("MMM DD")}
                    </div>
                  </div>
                  <div className={style.status}>{year || moment(value.endDate).format("YYYY")}</div>
                </div>
              );
            }
          })}
      </Slider>
    </div>
  );
}
