import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row, Container, DropdownButton } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import MultiSelect from "react-multi-select-component";
import style from "./EmployeeSkillListing.module.scss";
import * as actionTypes from "store/actions/actionTypes";
import * as actions from "store/actions/index";
import {
  IMG_ENDORSEMENT_1,
  IMG_PM,
  IMG_SME,
  IMG_UNI,
  IC_CERTIFICATION,
  IC_DOWNLOAD_1,
  IC_COLLAPSE,
  IC_EXPAND,
  IC_INFO,
} from "const/imgCost";
import { getRandomColor } from "util/color";
import { errorToast, filterArray, filterOptions, successToast } from "util/general";
import { moduleRights } from "util/utils";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../Pagination/Pagination";
import CertificateModal from "./CertificateModal/CertificateModal";
import NoDataFound from "../../NodataFound/NoDataFound";
import { competencyLevel, noData, successMessage } from "const/constValue";
import { competency_level_options } from "const/options";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function EmployeeSkillListing() {
  const [selectedColor, setSelectedColor] = useState("");
  useEffect(() => setSelectedColor(getRandomColor()), []);
  const dispatch = useDispatch();
  const {
    isLoading,
    technicalRoleList,
    filteredData,
    skills,
    band,
    certificateName,
    departmentList,
    size,
    employeeSkillExport,
    employeeSkillExportError,
    downloadResumeEmployeeSkillListing,
    employeeNameForEmployeeSkillListing,
  } = useSelector((state) => state.skillMatrix);

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [year, setYear] = useState([]);
  const [skillGap, setSkillGap] = useState([]);
  const [innerItem, setInnerItem] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [department, setDepartment] = useState([]);
  const [bandListData, setBandList] = useState([]);
  const [viewCertificate, setViewCertificate] = useState(false);
  const [certificate, setCertificate] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [certificate_data, setCertificateData] = useState([]);
  const [technicalRoleData, setTechnicalRoleData] = useState([]);

  const [filterBandArray, setFilterBandArray] = useState([]);
  const [filterSkillArray, setFilterSkillArray] = useState([]);
  const [filterToExperience, setFilterToExperience] = useState([]);
  const [filterDepartmentArray, setFilterDepartmentArray] = useState([]);
  const [filterSkillGapArray, setFilterSkillGapArray] = useState([]);
  const [filterFromExperience, setFilterFromExperience] = useState([]);
  const [filterCertificateArray, setFilterCertificateArray] = useState([]);
  const [filterEndorsementArray, setFilterEndorsementArray] = useState([]);
  const [filterEmployeeNameArray, setFilterEmployeeNameArray] = useState([]);
  const [filterTechnicalRoleArray, setFilterTechnicalRoleArray] = useState([]);
  const [filterCompetencyLevelArray, setFilterCompetencyLevelArray] = useState([]);
  const [filterInfoStretchExperience, setFilterInfoStretchExperience] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [open, setOpen] = useState({ flag: false, id: null });
  const history = useHistory();
  const [isExpYear, setIsExpYear] = useState([]);

  useEffect(() => {
    filterData.length > 0
      ? setData(filterData)
      : // eslint-disable-next-line no-constant-condition
      null
      ? setData(filterData)
      : setData(filteredData);
  }, [filterData, filteredData]);

  const resetData = () => {
    let filterData = null;
    const filters = {
      description: "",
    };
    if (data && data.length > 0) {
      filterData = filterArray(data, filters);
      setFilteredData(filterData);
    }
  };

  const toggleArray = () => {
    let array = [];
    for (let i = 0; i < 2; i++) {
      array.push(false);
    }
    setInnerItem(array);
  };

  function toggleInnerItem(index) {
    let new_array = innerItem;
    for (var i = 0; i < 2; i++) {
      if (i === index) {
        new_array[i] = !new_array[i];
      } else {
        new_array[i] = false;
      }
    }
    setInnerItem((innerItem) => [...innerItem, new_array]);
  }

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
    setOpen({
      flag: false,
      id: null,
    });
  };

  useEffect(() => {
    dispatch({
      type: actionTypes.CLEAR_GET_FILTER_DATA,
    });
    const getBandList = () => {
      dispatch(actions.getBandList);
    };
    const getEmployeeList = () => {
      dispatch(actions.employeeListForEmployeeSkillListing);
    };
    const getskillselectionList = () => {
      dispatch(actions.getSkills);
    };
    const getDepartment = () => {
      dispatch(actions.getDepartmentList);
    };
    const getCertificateList = () => {
      dispatch(actions.getCertificateName);
    };
    const getTechnicalRoleList = () => {
      dispatch(actions.getTechnicalRoleList);
    };

    getBandList();
    getDepartment();
    getEmployeeList();
    getCertificateList();
    getTechnicalRoleList();
    getskillselectionList();
  }, [dispatch]);

  const endorsement = [
    { value: 1, label: "UNIVERSITY" },
    { value: 2, label: "SME" },
    { value: 3, label: "MANAGER" },
    { value: 4, label: "PEER" },
  ];

  useEffect(() => {
    if (skills && skills.length > 0) {
      let array = [];
      for (let i = 0; i < skills.length; i++) {
        array.push({ value: skills[i].skillId, label: skills[i].skillName });
      }
      setSkillData(array);
    }

    if (employeeNameForEmployeeSkillListing && employeeNameForEmployeeSkillListing.length > 0) {
      let array = [];
      for (let i = 0; i < employeeNameForEmployeeSkillListing.length; i++) {
        array.push({
          value: employeeNameForEmployeeSkillListing[i].employeeId,
          label: employeeNameForEmployeeSkillListing[i].displayName,
        });
      }
      setEmployeeName(array);
    }

    if (technicalRoleList && technicalRoleList.length > 0) {
      let array = [];
      for (let i = 0; i < technicalRoleList.length; i++) {
        array.push({ value: technicalRoleList[i], label: technicalRoleList[i] });
      }
      setTechnicalRoleData(array);
    }

    if (band && band.length > 0) {
      let array = [];
      for (let i = 0; i < band.length; i++) {
        array.push({ value: band[i], label: band[i] });
      }
      setBandList(array);
    }

    if (certificateName && certificateName.length > 0) {
      let array = [];
      for (let i = 0; i < certificateName.length; i++) {
        array.push({ value: certificateName[i].id, label: certificateName[i].name });
      }
      array.push({ value: "OTHER_CERTIFICATE", label: "OTHER CERTIFICATE" });
      setCertificate(array);
    }

    if (departmentList && departmentList.length > 0) {
      let array = [];
      for (let i = 0; i < departmentList.length; i++) {
        array.push({ value: departmentList[i].departmentId, label: departmentList[i].departmentName });
      }
      setDepartment(array);
    }
  }, [skills, employeeNameForEmployeeSkillListing, technicalRoleList, band, certificateName, departmentList]);

  useEffect(() => {
    toggleArray();
    let yearArr = [];
    let isExpyear = [];
    for (let i = 0; i <= 50; i++) {
      yearArr.push({ value: i, label: i + " year" });
      isExpyear.push({ value: i, label: i + " year and more" });
    }
    setYear(yearArr);
    setIsExpYear(isExpyear);

    let array = [];
    for (let i = 0; i <= 50; i++) {
      array.push({ value: i, label: i + "" });
    }
    setSkillGap(array);
  }, []);

  useEffect(() => {
    if (showErrorToast) {
      errorToast("Total Experience To , cannot be less than Total Experience From");
      setShowErrorToast(false);
    }

    if (employeeSkillExportError) errorToast("Error Downloading");

    if (employeeSkillExport) successToast(successMessage.DOWNLOAD);

    if (downloadResumeEmployeeSkillListing.error) {
      errorToast(downloadResumeEmployeeSkillListing.message);
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    }

    if (downloadResumeEmployeeSkillListing.success) {
      successToast(downloadResumeEmployeeSkillListing.message);
      dispatch({
        type: actionTypes.CLEAR_NOTIFICATION,
      });
    }
  }, [showErrorToast, employeeSkillExport, employeeSkillExportError, downloadResumeEmployeeSkillListing, dispatch]);

  const getValues = (inputarray) => {
    let result = [];
    for (let i = 0; i < inputarray.length; i++) {
      result.push(inputarray[i].value);
    }
    return result;
  };

  const getLabel = (inputarray) => {
    let result = [];
    for (let i = 0; i < inputarray.length; i++) {
      result.push(inputarray[i].label);
    }
    return result;
  };

  const getArray = (input) => {
    if (input && input.value >= 0) {
      let values = Object.values(input);
      let result = [];
      result.push(values[0]);
      return result;
    } else {
      let result = [];
      return result;
    }
  };

  const clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length === 0 || obj[propName] === {}) {
        delete obj[propName];
      }
    }
  };

  const handleExport = () => {
    let endorsement = getValues(filterEndorsementArray);
    let endorsementFinalArray = endorsement.map((item) => item.toString());
    let data = {
      skill: {
        technicalRoleId: getValues(filterTechnicalRoleArray),
        skillId: getValues(filterSkillArray),
        competencyLevel: getValues(filterCompetencyLevelArray),
        skillGap: getValues(filterSkillGapArray),
        department: getLabel(filterDepartmentArray),
        endorsement: endorsementFinalArray,
      },
      individual: {
        certificateId: getValues(filterCertificateArray),
      },
      employee: {
        band: getValues(filterBandArray),
        employeeName: getValues(filterEmployeeNameArray),
        totalExpFrom: getArray(filterFromExperience),
        totalExpTo: getArray(filterToExperience),
        isExp: getArray(filterInfoStretchExperience),
      },
    };
    clean(data.skill);
    clean(data.individual);
    clean(data.employee);
    if (Object.keys(data.skill).length === 0) {
      delete data.skill;
    }
    if (Object.keys(data.individual).length === 0) {
      delete data.individual;
    }
    if (Object.keys(data.employee).length === 0) {
      delete data.employee;
    }
    if (getArray(filterToExperience).length) {
      let experience_from = getArray(filterFromExperience);
      let experience_to = getArray(filterToExperience);
      if (experience_from[0]) {
        if (experience_to[0] >= experience_from[0]) {
          dispatch(actions.exportList(data));
        } else {
          setShowErrorToast(true);
        }
      } else {
        dispatch(actions.exportList(data));
      }
    } else {
      dispatch(actions.exportList(data));
    }
  };

  const handleSearchButton = () => {
    setPageOfItems([]);
    toggleArray();
    let endorsement = getValues(filterEndorsementArray);
    let endorsementFinalArray = endorsement.map((item) => item.toString());
    let data = {
      skill: {
        technicalRoleId: getValues(filterTechnicalRoleArray),
        skillId: getValues(filterSkillArray),
        competencyLevel: getValues(filterCompetencyLevelArray),
        skillGap: getValues(filterSkillGapArray),
        department: getLabel(filterDepartmentArray),
        endorsement: endorsementFinalArray,
      },
      individual: {
        certificateId: getValues(filterCertificateArray),
      },
      employee: {
        band: getValues(filterBandArray),
        employeeName: getValues(filterEmployeeNameArray),
        totalExpFrom: getArray(filterFromExperience),
        totalExpTo: getArray(filterToExperience),
        isExp: getArray(filterInfoStretchExperience),
      },
    };
    clean(data.skill);
    clean(data.individual);
    clean(data.employee);
    if (Object.keys(data.skill).length === 0) {
      delete data.skill;
    }
    if (Object.keys(data.individual).length === 0) {
      delete data.individual;
    }
    if (Object.keys(data.employee).length === 0) {
      delete data.employee;
    }
    if (getArray(filterToExperience).length) {
      let experience_from = getArray(filterFromExperience);
      let experience_to = getArray(filterToExperience);
      if (experience_from[0]) {
        if (experience_to[0] >= experience_from[0]) {
          dispatch(actions.getFilterData(data));
        } else {
          setShowErrorToast(true);
        }
      } else {
        dispatch(actions.getFilterData(data));
      }
    } else {
      dispatch(actions.getFilterData(data));
    }
    setShow(true);
  };

  const handleClear = () => {
    setFilterDepartmentArray([]);
    setFilterSkillArray([]);
    setFilterSkillGapArray([]);
    setFilterEmployeeNameArray([]);
    setFilterTechnicalRoleArray([]);
    setFilterEndorsementArray([]);
    setFilterCompetencyLevelArray([]);
    setFilterCertificateArray([]);
    setFilterBandArray([]);
    setFilterInfoStretchExperience([]);
    setFilterToExperience([]);
    setFilterFromExperience([]);
    setPageOfItems([]);
    setData([]);
    toggleArray();
    setShow(false);
  };

  const handleCertificate = (data) => {
    setViewCertificate(true);
    setCertificateData(data);
  };

  const hideCertificate = () => {
    setViewCertificate(false);
  };

  useEffect(() => {
    setData(filteredData);
  }, [filteredData]);

  const handleFromExperience = (select) => {
    if (select) setFilterFromExperience({ value: select.value, label: select.label });
    else setFilterFromExperience(null);
  };

  const handleToExperience = (select) => {
    if (select) setFilterToExperience({ value: select.value, label: select.label });
    else setFilterToExperience(null);
  };

  const handleInfostretchExperience = (select) => {
    if (select) setFilterInfoStretchExperience({ value: select.value, label: select.label });
    else setFilterInfoStretchExperience(null);
  };

  const handleDownLoadResume = (user) => {
    dispatch(actions.downloadResumeEmployeeSkillListing(user));
  };

  return (
    <div className={style.roll_based_skill_wrapper}>
      <SubNavigation type="Skill Matrix" />
      <Container>
        <Row>
          <Col md={6}>
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">
              Skill Matrix
              <span>Employee Skill Listing</span>
            </span>
          </Col>
          {moduleRights(config.mainModule.SKILL_MATRIX, config.subModule.EMPLOYEE_SKILL_LISTING)?.includes(
            "EXPORT"
          ) && (
            <Col md={6} className={[style.res_title_btn, "text-right"].join(" ")}>
              <button
                type="button"
                className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
                onClick={handleExport}
              >
                <strong>Export</strong>
              </button>
            </Col>
          )}
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
              <div className={style.filter_info_wrapper}>
                <div className={style.filter_top_bar}>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Skill</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={skillData}
                        value={filterSkillArray}
                        onChange={setFilterSkillArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Skill Gaps</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={skillGap}
                        value={filterSkillGapArray}
                        onChange={setFilterSkillGapArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Employee Name</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={employeeName}
                        value={filterEmployeeNameArray}
                        onChange={setFilterEmployeeNameArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Technical Role</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={technicalRoleData}
                        value={filterTechnicalRoleArray}
                        onChange={setFilterTechnicalRoleArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Endorsement</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={endorsement}
                        value={filterEndorsementArray}
                        onChange={setFilterEndorsementArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Competency Level</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={competency_level_options}
                        value={filterCompetencyLevelArray}
                        onChange={setFilterCompetencyLevelArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Band</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={bandListData}
                        value={filterBandArray}
                        onChange={setFilterBandArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Certification</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={certificate}
                        value={filterCertificateArray}
                        onChange={setFilterCertificateArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Department</span>
                    <div className="tags_wrapper">
                      <MultiSelect
                        options={department}
                        value={filterDepartmentArray}
                        onChange={setFilterDepartmentArray}
                        labelledBy={"Select"}
                        filterOptions={filterOptions}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Apexon Experience</span>
                    <div className="tags_wrapper">
                      <Select
                        options={isExpYear}
                        className="custom-default-dd-box"
                        classNamePrefix="select"
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Select..."
                        onChange={(select) => handleInfostretchExperience(select)}
                        value={filterInfoStretchExperience}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Total Experience from</span>
                    <div className="tags_wrapper">
                      <Select
                        options={year}
                        className="custom-default-dd-box"
                        classNamePrefix="select"
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Select..."
                        onChange={(select) => handleFromExperience(select)}
                        value={filterFromExperience}
                      />
                    </div>
                  </div>
                  <div className={style.filter_box}>
                    <span className={style.dd_title}>Total Experience to</span>
                    <div className="tags_wrapper">
                      <Select
                        options={year}
                        className="custom-default-dd-box"
                        classNamePrefix="select"
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Select..."
                        onChange={(select) => handleToExperience(select)}
                        value={filterToExperience}
                      />
                    </div>
                  </div>
                </div>
                <div className={style.filter_btn_group}>
                  <button
                    type="button"
                    className={["btn-round save_box_btn btn btn-primary mr-3", style.create_new_btn].join(" ")}
                    onClick={handleSearchButton}
                  >
                    <strong>Search</strong>
                  </button>
                  <button
                    type="button"
                    className={["btn-round save_box_btn btn btn-secondary", style.create_new_btn].join(" ")}
                    onClick={handleClear}
                  >
                    <strong>Clear</strong>
                  </button>
                </div>
              </div>
              {isLoading ? (
                <Spinner />
              ) : data && data.length === 0 && show ? (
                <>
                  <div className="box-light-gradient"></div>
                  <div className="gap30"></div>
                  <div className="gap40"></div>
                  <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                  <div className="gap80"></div>
                </>
              ) : pageOfItems && pageOfItems.length > 0 ? (
                <>
                  <div className={style.table_responsive}>
                    <div className={style.table_width}>
                      <div className="box-light-gradient"></div>
                      <div className={[style.outer_box_wrapper, "box-inner-pad"].join(" ")}>
                        <div className={style.panel_title}>
                          <span>Name</span>
                          <span>Skill Gaps</span>
                          <span>Action</span>
                        </div>
                        <div className={style.listing_wrapper}>
                          {pageOfItems.map((data) => {
                            return (
                              <div className={style.item} key={data.employeeId}>
                                <div
                                  className={[
                                    style.item_info_top_wrapper,
                                    open.flag === true && open.id === data.employeeId ? "active" : "",
                                  ].join(" ")}
                                >
                                  <div className={style.item_topbar}>
                                    <div className={style.member_info}>
                                      <i
                                        className={style.expand_collapse_wrapper}
                                        onClick={() => {
                                          if (open.id !== data.employeeId) {
                                            setOpen({ flag: true, id: data.employeeId });
                                          } else {
                                            setOpen({ flag: !open.flag, id: data.employeeId });
                                          }
                                        }}
                                      >
                                        {open.id === data.employeeId && open.flag ? (
                                          <img src={IC_COLLAPSE} className={style.collapse} alt="Collapse" />
                                        ) : (
                                          <img src={IC_EXPAND} className={style.expand} alt="Expand" />
                                        )}
                                      </i>
                                      <div
                                        onClick={() =>
                                          history.push({
                                            pathname: `/okrprogress/my-team/member-okrprogress/dashboard/${data.employeeId}${config.employeeIdSplit}${data.parentGroupId}${config.employeeIdSplit}${data.subGroupId}`,
                                            state: { userResume: data },
                                          })
                                        }
                                        className={style.user}
                                      >
                                        <span className={style.user_img} style={{ backgroundColor: selectedColor }}>
                                          {data.dispArr && data.dispArr.length > 2 ? (
                                            <img src={data.dispArr} alt="displayPicture" />
                                          ) : (
                                            data.dispArr || data.empInitial
                                          )}
                                        </span>
                                        <div className={style.user_info}>
                                          <span className={style.username}>
                                            {data.displayName}
                                            {data.employeeSkill && data.employeeSkill.technicalRoleName?.length > 0
                                              ? " ( " + data.employeeSkill.technicalRoleName + " )"
                                              : null}
                                          </span>
                                          <span className={style.department}>
                                            {data.designation} | {data.department}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <span className={style.skillgaps_text}>
                                      {data.employeeSkill && data.employeeSkill.category
                                        ? data.employeeSkill.totalGapCount
                                          ? data.employeeSkill.totalGapCount
                                          : "0"
                                        : "-"}
                                    </span>
                                    <div className="action-dropdown-wrapper">
                                      <DropdownButton
                                        alignRight
                                        title={
                                          <div className="action-dropdown">
                                            <span className="dots"></span>
                                            <span className="dots"></span>
                                            <span className="dots"></span>
                                          </div>
                                        }
                                        variant="a"
                                      >
                                        <span onClick={() => handleDownLoadResume(data)} className="dropdown-item">
                                          <div className="dropdown_icon">
                                            <ReactSVG src={IC_DOWNLOAD_1} alt="" />
                                          </div>
                                          <div className="dropdown_item">Download Resume</div>
                                        </span>
                                        <span onClick={() => handleCertificate(data)} className="dropdown-item">
                                          <div className="dropdown_icon">
                                            <ReactSVG src={IC_CERTIFICATION} alt="" />
                                          </div>
                                          <div className="dropdown_item">View Certifications</div>
                                        </span>
                                      </DropdownButton>
                                    </div>
                                  </div>
                                  {open.id === data.employeeId && open.flag && (
                                    <div className={style.member_details}>
                                      <div className={style.member_details_list}>
                                        <span>Band</span>
                                        <h5>{data.band}</h5>
                                      </div>
                                      <div className={style.member_details_list}>
                                        <span>Experience Prior</span>
                                        <h5>{data.expBeforeJoining ? data.expBeforeJoining : "0"} years</h5>
                                      </div>
                                      <div className={style.member_details_list}>
                                        <span>With Infostretch</span>
                                        <h5>{data.isExp ? data.isExp : "0"} years</h5>
                                      </div>
                                      <div className={style.member_details_list}>
                                        <span>Total Experience</span>
                                        <h5>{data.totalExp ? data.totalExp : "0"} years</h5>
                                      </div>
                                      <div className={style.member_details_list}>
                                        <span>Location</span>
                                        <h5>{data.location}</h5>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {open.id === data.employeeId && open.flag && (
                                  <div className={style.item_body_wrapper}>
                                    <div className={["inner-accordion", innerItem[0] && "active"].join(" ")}>
                                      <span className="inner-accordion-title" onClick={() => toggleInnerItem(0)}>
                                        Required Skill
                                        <i className="arrow-up-down"></i>
                                      </span>
                                      {innerItem[0] && (
                                        <div className={style.skill_gap_table_wrapper}>
                                          {data.employeeSkill && data.employeeSkill.category ? (
                                            data.employeeSkill.category.map((category, index) => {
                                              return (
                                                <div className={style.skill_gaps_table} key={index}>
                                                  <div className="box-light-gradient"></div>
                                                  <h4 className={style.category_title}>{category.categoryName}</h4>
                                                  <ul className={style.skills_head}>
                                                    <li>
                                                      <span className={style.skill_name}>Skill Name</span>
                                                      <span className={style.endorsement}>Endorsement</span>
                                                      <span className={style.current}>Current</span>
                                                      <div className={style.th_info_wrapper}>
                                                        <span>Expected</span>
                                                        <div
                                                          className={[
                                                            style.th_info_tooltip,
                                                            "custom-tooltip d-none",
                                                          ].join(" ")}
                                                        >
                                                          <div className="box-info">
                                                            <ReactSVG src={IC_INFO} title="" alt="info" />
                                                          </div>
                                                          <div className="info-attach-file">
                                                            <span className="attachment-title">
                                                              Content Will Update
                                                            </span>
                                                            <ul>
                                                              <li>Lorem ipsum</li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <span className={style.relevant_exp}>Rel. Experience</span>
                                                      <span className={style.last_used}>Last Used On</span>
                                                    </li>
                                                  </ul>
                                                  <div className={style.skill_require_set_wrapper}>
                                                    <ul className={style.skills_body}>
                                                      {category.requiredSkill.skillset.map((skill_arr) => {
                                                        return (
                                                          <>
                                                            <span
                                                              className={[
                                                                style.skill_required_txt,
                                                                skill_arr.gapFound === -1 ? "text-danger" : "",
                                                              ].join(" ")}
                                                            >
                                                              *Required{" "}
                                                              {skill_arr.shouldKnowAtleast > 0
                                                                ? skill_arr.shouldKnowAtleast
                                                                : "1"}{" "}
                                                              skills out of {skill_arr.skill.length}
                                                            </span>
                                                            {skill_arr.skill.map((skill) => {
                                                              let experienceInMonth = "",
                                                                experienceInYears = "";
                                                              if (
                                                                skill.experienceInMonth &&
                                                                skill.experienceInMonth >= 0 &&
                                                                skill.experienceInMonth !== null
                                                              )
                                                                experienceInMonth = skill.experienceInMonth;
                                                              if (
                                                                skill.experienceInYears &&
                                                                skill.experienceInYears >= 0 &&
                                                                skill.experienceInYears !== null
                                                              )
                                                                experienceInYears = skill.experienceInYears;
                                                              return (
                                                                <li key={skill.skillIdNameId}>
                                                                  <div className={style.skill_name}>
                                                                    <span>{skill.skillIdName}</span>
                                                                    <div className={style.skill_gap_tooltip}>
                                                                      {skill_arr.gapFound === -1 ? (
                                                                        skill.gapFound === -1 ? (
                                                                          <>
                                                                            <i
                                                                              className={[
                                                                                style.skill_gap_icon,
                                                                                "red",
                                                                              ].join(" ")}
                                                                            ></i>
                                                                            <span className={style.skill_gap_info}>
                                                                              Gap Found
                                                                            </span>
                                                                          </>
                                                                        ) : null
                                                                      ) : null}
                                                                      {skill.gapFound === 1 ? (
                                                                        <>
                                                                          <i
                                                                            className={[
                                                                              style.skill_gap_icon,
                                                                              "green",
                                                                            ].join(" ")}
                                                                          ></i>
                                                                          <span className={style.skill_gap_info}>
                                                                            Exceeding Expectaion
                                                                          </span>
                                                                        </>
                                                                      ) : null}
                                                                    </div>
                                                                  </div>
                                                                  <div className={style.endorsement}>
                                                                    {skill.endorsementList
                                                                      ? skill.endorsementList.map((item) => {
                                                                          return (
                                                                            <>
                                                                              {item.endorsedTypeId === "4" ? (
                                                                                <div className={style.endorse_tooltip}>
                                                                                  <img
                                                                                    src={IMG_ENDORSEMENT_1}
                                                                                    alt="PEER"
                                                                                  />
                                                                                  <div className={style.tool_tip_info}>
                                                                                    <h6>Peer</h6>
                                                                                    <span>
                                                                                      Beginner (
                                                                                      {item.beginner
                                                                                        ? item.beginner
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                    <span>
                                                                                      Intermediate (
                                                                                      {item.intermediate
                                                                                        ? item.intermediate
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                    <span>
                                                                                      Advanced (
                                                                                      {item.advance
                                                                                        ? item.advance
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : null}
                                                                              {item.endorsedTypeId === "3" ? (
                                                                                <div className={style.endorse_tooltip}>
                                                                                  <img src={IMG_PM} alt="PM" />
                                                                                  <div className={style.tool_tip_info}>
                                                                                    <h6>PM</h6>
                                                                                    <span>
                                                                                      Beginner (
                                                                                      {item.beginner
                                                                                        ? item.beginner
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                    <span>
                                                                                      Intermediate (
                                                                                      {item.intermediate
                                                                                        ? item.intermediate
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                    <span>
                                                                                      Advanced (
                                                                                      {item.advance
                                                                                        ? item.advance
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : null}
                                                                              {item.endorsedTypeId === "2" ? (
                                                                                <div className={style.endorse_tooltip}>
                                                                                  <img src={IMG_SME} alt="SME" />
                                                                                  <div className={style.tool_tip_info}>
                                                                                    <h6>SME</h6>
                                                                                    <span>
                                                                                      Beginner (
                                                                                      {item.beginner
                                                                                        ? item.beginner
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                    <span>
                                                                                      Intermediate (
                                                                                      {item.intermediate
                                                                                        ? item.intermediate
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                    <span>
                                                                                      Advanced (
                                                                                      {item.advance
                                                                                        ? item.advance
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : null}
                                                                              {item.endorsedTypeId === "1" ? (
                                                                                <div className={style.endorse_tooltip}>
                                                                                  <img src={IMG_UNI} alt="University" />
                                                                                  <div className={style.tool_tip_info}>
                                                                                    <h6>University</h6>
                                                                                    <span>
                                                                                      Beginner (
                                                                                      {item.beginner
                                                                                        ? item.beginner
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                    <span>
                                                                                      Intermediate (
                                                                                      {item.intermediate
                                                                                        ? item.intermediate
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                    <span>
                                                                                      Advanced (
                                                                                      {item.advance
                                                                                        ? item.advance
                                                                                        : "0"}
                                                                                      )
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : null}
                                                                            </>
                                                                          );
                                                                        })
                                                                      : "-"}
                                                                  </div>
                                                                  <div
                                                                    className={[style.cmp_lvl, style.current].join(" ")}
                                                                  >
                                                                    {skill.currentCompetencyLevel ? (
                                                                      <span
                                                                        className={[
                                                                          style.req_comp_level,
                                                                          competencyLevel[skill.currentCompetencyLevel],
                                                                        ].join(" ")}
                                                                      >
                                                                        {competencyLevel[skill.currentCompetencyLevel]}
                                                                      </span>
                                                                    ) : (
                                                                      "-"
                                                                    )}
                                                                  </div>
                                                                  <div
                                                                    className={[
                                                                      style.cmp_lvl,
                                                                      style.th_info_wrapper,
                                                                    ].join(" ")}
                                                                  >
                                                                    {skill.competencyLevel ? (
                                                                      <span
                                                                        className={[
                                                                          style.req_comp_level,
                                                                          competencyLevel[skill.competencyLevel],
                                                                        ].join(" ")}
                                                                      >
                                                                        {competencyLevel[skill.competencyLevel]}
                                                                      </span>
                                                                    ) : (
                                                                      "-"
                                                                    )}
                                                                  </div>
                                                                  <span className={style.relevant_exp}>
                                                                    {experienceInYears ? experienceInYears : "0"}.
                                                                    {experienceInMonth ? experienceInMonth : "0"} Years
                                                                  </span>
                                                                  <span className={style.last_used}>
                                                                    {skill.lastUsedMonth
                                                                      ? skill.lastUsedMonth + " "
                                                                      : "-"}{" "}
                                                                    {skill.lastUsedYear ? skill.lastUsedYear : "-"}
                                                                  </span>
                                                                </li>
                                                              );
                                                            })}
                                                          </>
                                                        );
                                                      })}
                                                    </ul>
                                                  </div>
                                                  {category.niceToHaveSkill &&
                                                  category.niceToHaveSkill.skill.length > 0 ? (
                                                    <div className={style.skill_require_set_wrapper}>
                                                      <span className={[style.skill_required_txt, " "].join(" ")}>
                                                        Nice to have
                                                      </span>
                                                      <ul className={style.skills_body}>
                                                        {category.niceToHaveSkill.skill.map((nice) => {
                                                          let experienceInMonth = "",
                                                            experienceInYears = "";
                                                          if (
                                                            nice.experienceInMonth &&
                                                            nice.experienceInMonth >= 0 &&
                                                            nice.experienceInMonth !== null
                                                          )
                                                            experienceInMonth = nice.experienceInMonth;
                                                          if (
                                                            nice.experienceInYears &&
                                                            nice.experienceInYears >= 0 &&
                                                            nice.experienceInYears !== null
                                                          )
                                                            experienceInYears = nice.experienceInYears;
                                                          return (
                                                            <li key={nice.skillIdNameId}>
                                                              <div className={style.skill_name}>
                                                                <span>{nice.skillIdName}</span>
                                                              </div>
                                                              <div className={style.endorsement}>
                                                                {nice.endorsementList &&
                                                                  nice.endorsementList.map((item) => {
                                                                    return (
                                                                      <>
                                                                        {item.endorsedTypeId === "4" ? (
                                                                          <div className={style.endorse_tooltip}>
                                                                            <img src={IMG_ENDORSEMENT_1} alt="PEER" />
                                                                            <div className={style.tool_tip_info}>
                                                                              <h6>Peer</h6>
                                                                              <span>
                                                                                Beginner (
                                                                                {item.beginner ? item.beginner : "0"})
                                                                              </span>
                                                                              <span>
                                                                                Intermediate (
                                                                                {item.intermediate
                                                                                  ? item.intermediate
                                                                                  : "0"}
                                                                                )
                                                                              </span>
                                                                              <span>
                                                                                Advanced (
                                                                                {item.advance ? item.advance : "0"})
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        ) : null}
                                                                        {item.endorsedTypeId === "3" ? (
                                                                          <div className={style.endorse_tooltip}>
                                                                            <img src={IMG_PM} alt="PM" />
                                                                            <div className={style.tool_tip_info}>
                                                                              <h6>PM</h6>
                                                                              <span>
                                                                                Beginner (
                                                                                {item.beginner ? item.beginner : "0"})
                                                                              </span>
                                                                              <span>
                                                                                Intermediate (
                                                                                {item.intermediate
                                                                                  ? item.intermediate
                                                                                  : "0"}
                                                                                )
                                                                              </span>
                                                                              <span>
                                                                                Advanced (
                                                                                {item.advance ? item.advance : "0"})
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        ) : null}
                                                                        {item.endorsedTypeId === "2" ? (
                                                                          <div className={style.endorse_tooltip}>
                                                                            <img src={IMG_SME} alt="SME" />
                                                                            <div className={style.tool_tip_info}>
                                                                              <h6>SME</h6>
                                                                              <span>
                                                                                Beginner (
                                                                                {item.beginner ? item.beginner : "0"})
                                                                              </span>
                                                                              <span>
                                                                                Intermediate (
                                                                                {item.intermediate
                                                                                  ? item.intermediate
                                                                                  : "0"}
                                                                                )
                                                                              </span>
                                                                              <span>
                                                                                Advanced (
                                                                                {item.advance ? item.advance : "0"})
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        ) : null}
                                                                        {item.endorsedTypeId === "1" ? (
                                                                          <div className={style.endorse_tooltip}>
                                                                            <img src={IMG_UNI} alt="University" />
                                                                            <div className={style.tool_tip_info}>
                                                                              <h6>University</h6>
                                                                              <span>
                                                                                Beginner (
                                                                                {item.beginner ? item.beginner : "0"})
                                                                              </span>
                                                                              <span>
                                                                                Intermediate (
                                                                                {item.intermediate
                                                                                  ? item.intermediate
                                                                                  : "0"}
                                                                                )
                                                                              </span>
                                                                              <span>
                                                                                Advanced (
                                                                                {item.advance ? item.advance : "0"})
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        ) : null}
                                                                      </>
                                                                    );
                                                                  })}
                                                              </div>
                                                              <div className={[style.cmp_lvl, style.current].join(" ")}>
                                                                <span
                                                                  className={[
                                                                    style.req_comp_level,
                                                                    competencyLevel[nice.currentCompetencyLevel],
                                                                  ].join(" ")}
                                                                >
                                                                  {competencyLevel[nice.currentCompetencyLevel] || "-"}
                                                                </span>
                                                              </div>
                                                              <div
                                                                className={[style.cmp_lvl, style.th_info_wrapper].join(
                                                                  " "
                                                                )}
                                                              >
                                                                <span
                                                                  className={[
                                                                    style.req_comp_level,
                                                                    competencyLevel[nice.competencyLevel],
                                                                  ].join(" ")}
                                                                >
                                                                  {competencyLevel[nice.competencyLevel] || "-"}
                                                                </span>
                                                              </div>
                                                              <span className={style.relevant_exp}>
                                                                {experienceInYears ? experienceInYears : "0"}.
                                                                {experienceInMonth ? experienceInMonth : "0"} Years
                                                              </span>
                                                              <span className={style.last_used}>
                                                                {nice.lastUsedMonth ? nice.lastUsedMonth + " " : "-"}{" "}
                                                                {nice.lastUsedYear ? nice.lastUsedYear : "-"}
                                                              </span>
                                                            </li>
                                                          );
                                                        })}
                                                      </ul>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <div className="no-record-found-wrpr">No Record Found</div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={[
                                        "inner-accordion",
                                        style.addition_skill_wrapper,
                                        innerItem[1] && "active",
                                      ].join(" ")}
                                    >
                                      <span className="inner-accordion-title" onClick={() => toggleInnerItem(1)}>
                                        Additional Skill
                                        <i className="arrow-up-down"></i>
                                      </span>
                                      {innerItem[1] && (
                                        <div className={style.skill_gap_table_wrapper}>
                                          {data.employeeSkill && data.employeeSkill.additionalSkill ? (
                                            <div className={style.skill_gaps_table}>
                                              <div className="box-light-gradient"></div>
                                              <ul className={style.skills_head}>
                                                <li>
                                                  <span className={style.skill_name}>Skill Name</span>
                                                  <span className={style.endorsement}>Endorsement</span>
                                                  <span className={style.current}>Current</span>
                                                  <span className={style.relevant_exp}>Rel. Experience</span>
                                                  <span className={style.last_used}>Last Used On</span>
                                                </li>
                                              </ul>
                                              <div className={style.skill_require_set_wrapper}>
                                                <ul className={style.skills_body}>
                                                  {data.employeeSkill.additionalSkill.map((skill, index) => {
                                                    let experienceInMonth = "",
                                                      experienceInYears = "";
                                                    if (
                                                      skill.experienceInMonth &&
                                                      skill.experienceInMonth >= 0 &&
                                                      skill.experienceInMonth !== null
                                                    )
                                                      experienceInMonth = skill.experienceInMonth;
                                                    if (
                                                      skill.experienceInYears &&
                                                      skill.experienceInYears >= 0 &&
                                                      skill.experienceInYears !== null
                                                    )
                                                      experienceInYears = skill.experienceInYears;
                                                    return (
                                                      <li key={index}>
                                                        <div className={style.skill_name}>
                                                          <span>{skill.skillName}</span>
                                                        </div>
                                                        <div className={style.endorsement}>
                                                          {skill.endorsementList
                                                            ? skill.endorsementList.map((item) => {
                                                                return (
                                                                  <>
                                                                    {item.endorsedTypeId === "4" ? (
                                                                      <div className={style.endorse_tooltip}>
                                                                        <img src={IMG_ENDORSEMENT_1} alt="PEER" />
                                                                        <div className={style.tool_tip_info}>
                                                                          <h6>Peer</h6>
                                                                          <span>
                                                                            Beginner (
                                                                            {item.beginner ? item.beginner : "0"})
                                                                          </span>
                                                                          <span>
                                                                            Intermediate (
                                                                            {item.intermediate
                                                                              ? item.intermediate
                                                                              : "0"}
                                                                            )
                                                                          </span>
                                                                          <span>
                                                                            Advanced (
                                                                            {item.advance ? item.advance : "0"})
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    ) : null}
                                                                    {item.endorsedTypeId === "3" ? (
                                                                      <div className={style.endorse_tooltip}>
                                                                        <img src={IMG_PM} alt="PM" />
                                                                        <div className={style.tool_tip_info}>
                                                                          <h6>PM</h6>
                                                                          <span>
                                                                            Beginner (
                                                                            {item.beginner ? item.beginner : "0"})
                                                                          </span>
                                                                          <span>
                                                                            Intermediate (
                                                                            {item.intermediate
                                                                              ? item.intermediate
                                                                              : "0"}
                                                                            )
                                                                          </span>
                                                                          <span>
                                                                            Advanced (
                                                                            {item.advance ? item.advance : "0"})
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    ) : null}
                                                                    {item.endorsedTypeId === "2" ? (
                                                                      <div className={style.endorse_tooltip}>
                                                                        <img src={IMG_SME} alt="SME" />
                                                                        <div className={style.tool_tip_info}>
                                                                          <h6>SME</h6>
                                                                          <span>
                                                                            Beginner (
                                                                            {item.beginner ? item.beginner : "0"})
                                                                          </span>
                                                                          <span>
                                                                            Intermediate (
                                                                            {item.intermediate
                                                                              ? item.intermediate
                                                                              : "0"}
                                                                            )
                                                                          </span>
                                                                          <span>
                                                                            Advanced (
                                                                            {item.advance ? item.advance : "0"})
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    ) : null}
                                                                    {item.endorsedTypeId === "1" ? (
                                                                      <div className={style.endorse_tooltip}>
                                                                        <img src={IMG_UNI} alt="University" />
                                                                        <div className={style.tool_tip_info}>
                                                                          <h6>University</h6>
                                                                          <span>
                                                                            Beginner (
                                                                            {item.beginner ? item.beginner : "0"})
                                                                          </span>
                                                                          <span>
                                                                            Intermediate (
                                                                            {item.intermediate
                                                                              ? item.intermediate
                                                                              : "0"}
                                                                            )
                                                                          </span>
                                                                          <span>
                                                                            Advanced (
                                                                            {item.advance ? item.advance : "0"})
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    ) : null}
                                                                  </>
                                                                );
                                                              })
                                                            : "-"}
                                                        </div>
                                                        <div className={[style.cmp_lvl, style.current].join(" ")}>
                                                          <span
                                                            className={[
                                                              style.req_comp_level,
                                                              competencyLevel[skill.competencyLevel],
                                                            ].join(" ")}
                                                          >
                                                            {competencyLevel[skill.competencyLevel] || "-"}
                                                          </span>
                                                        </div>
                                                        <span className={style.relevant_exp}>
                                                          {experienceInYears ? experienceInYears : "0"}.
                                                          {experienceInMonth ? experienceInMonth : "0"} Years
                                                        </span>
                                                        <span className={style.last_used}>
                                                          {skill.lastUsedMonth ? skill.lastUsedMonth + " " : "-"}{" "}
                                                          {skill.lastUsedYear ? skill.lastUsedYear : "-"}
                                                        </span>
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="no-record-found-wrpr">No Record Found</div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </Col>
        </Row>
        {isLoading ? (
          <Spinner />
        ) : data && data.length > 0 ? (
          <Pagination pageSize={size} items={data} onChangePage={onChangePage} resetData={resetData} />
        ) : null}
      </Container>
      {viewCertificate ? (
        <CertificateModal show={viewCertificate} hide={hideCertificate} data={certificate_data} />
      ) : null}
    </div>
  );
}
