import React from "react";
import { ReactSVG } from "react-svg";
import "./nodatafound.module.scss";

const NoRecordFound = ({ image, title, description,folderWrapper }) => {
  return (
    <div className="noRecordOuterWrapper">
      <div>
        {folderWrapper ? <></> : <div className="folder-wrapper-overlay-1"></div>}
        <ReactSVG src={image} title="" alt="" />
      </div>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};

export default NoRecordFound;
