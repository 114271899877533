import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { errorToast } from "util/general";
import { buttonText, menuText } from "const/constValue";
import * as actions from "store/actions/index";
import * as actionTypes from "store/actions/actionTypes";
import FilterBox from "shared/AllFilterBox/FilterBox";
import style from "./HRTimelineView.module.scss";
import HRTeamMemberTable from "components/Performance/PerformanceDashboard/EmployeeListTable/HRTeamMemberTable";
import Spinner from "shared/Spinner/Spinner";
import routePath from "const/routePath";

function HRTimelineView() {
  // const [teamMember, setTeamMember] = useState();
  const dispatch = useDispatch();
  const {
    timelineFilterValue,
    hrTimelineFilterOptions,
    isHrTimelineLoading,
    employeeNames,
    hrTimelineEmployeeList,
    isHrTimelineDisplayLoading,
  } = useSelector((state) => state.performance);

  useEffect(() => {
    dispatch(actions.GetOptionsListForHRTimeline());
  }, [dispatch]);

  const handleFilter = () => {
    if (timelineFilterValue?.filterType?.value || timelineFilterValue?.filterTenure?.value) {
      if (timelineFilterValue?.filterType?.value) {
        if (timelineFilterValue?.filterTenure?.value) {
          dispatch(
            actions.GetEmployeeListForHRTimeline(
              timelineFilterValue?.filterSubGroups,
              timelineFilterValue?.filterLocation,
              timelineFilterValue?.filterType,
              timelineFilterValue?.filterTenure
            )
          );
        } else {
          errorToast("Please select tenure with option");
        }
      } else {
        errorToast("Please select option with tenure");
      }
    } else if (
      timelineFilterValue?.filterEmployee?.length > 0 ||
      timelineFilterValue?.filterSubGroups?.value ||
      timelineFilterValue?.filterLocation?.length > 0
    ) {
      if (timelineFilterValue?.filterEmployee?.length > 0) {
        if (timelineFilterValue?.filterSubGroups?.value || timelineFilterValue?.filterLocation?.length > 0) {
          dispatch(
            actions.GetEmployeeListForHRTimeline(
              timelineFilterValue?.filterSubGroups,
              timelineFilterValue?.filterLocation,
              timelineFilterValue?.filterType,
              timelineFilterValue?.filterTenure,
              timelineFilterValue?.filterEmployee
            )
          );
        } else {
          dispatch({
            type: actionTypes.SET_HR_TIMELINE_EMPLOYEE_LIST,
            payload: timelineFilterValue?.filterEmployee,
          });
        }
      } else {
        dispatch(
          actions.GetEmployeeListForHRTimeline(
            timelineFilterValue?.filterSubGroups,
            timelineFilterValue?.filterLocation,
            timelineFilterValue?.filterType,
            timelineFilterValue?.filterTenure
          )
        );
      }
    } else {
      errorToast("Please select any one of filter");
    }
  };

  const handleClearFilter = () => {
    dispatch({ type: actionTypes.CLEAR_HR_TIMELINE_FILTER_DATA });
    dispatch(actions.GetOptionsListForHRTimeline());
  };

  const handleFilterData = (variable, e) => {
    if (variable === "filterSubGroups") {
      dispatch(actions.GetOptionsListForHRTimeline(e, timelineFilterValue.filterLocation));
      dispatch({
        type: actionTypes.SET_HR_TIMELINE_FILTER_DATA,
        payload: {
          filterLocation: [],
        },
      });
    } else if (variable === "filterLocation") {
      dispatch(actions.GetOptionsListForHRTimeline(timelineFilterValue.filterSubGroups, e));
    }
    dispatch({
      type: actionTypes.SET_HR_TIMELINE_FILTER_DATA,
      payload: {
        [variable]: e,
      },
    });
  };

  const getEmployeeNameList = (value) => {
    if (value.length > 2) {
      dispatch(actions.getEmployeeNameList(value));
    }
  };

  const handleAssessmentClick = (data) => {
    const { assessments, cycleId } = data;
    const employeeIds = assessments.employeeId;
    const win = window.open(
      `${routePath.CYCLE_MEMBER_PROFILE}/${cycleId}?empId=${employeeIds?.employeeId}&pgId=${employeeIds?.parentGroupId}&sgId=${employeeIds?.subGroupId}&assessmentType=HR`,
      "_blank"
    );
    win.focus();
  };

  if (isHrTimelineLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.hr_timeline_wrapper}>
      <SubNavigation type="Analysis" />
      {isHrTimelineDisplayLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0">{menuText.HR}</h1>
            <span className="st-breadcrumb">
              Analysis<span>Timeline</span>
            </span>
          </Col>
        </Row>
        <div className="box p-4">
          <FilterBox
            isSubGroupSingleSelect={true}
            subGroupClient={hrTimelineFilterOptions?.subGroupList}
            displaySubgroups={true}
            handleFilterData={handleFilterData}
            displayLocation={true}
            locationList={hrTimelineFilterOptions?.locationList}
            employeeNameList={employeeNames}
            filterEmployee={timelineFilterValue?.filterEmployee}
            filterSubGroups={timelineFilterValue?.filterSubGroups}
            filterLocation={timelineFilterValue?.filterLocation}
            searchEmployeeBox={true}
            getEmployeeNameList={getEmployeeNameList}
            filterTenure={timelineFilterValue?.filterTenure}
            filterType={timelineFilterValue?.filterType}
            displayTenure={true}
            displayType={true}
          />
          <button
            type="button"
            className={["btn-round save_box_btn btn btn-primary mr-3", style.create_new_btn].join(" ")}
            onClick={handleFilter}
          >
            <strong>{buttonText.SEARCH}</strong>
          </button>
          <button
            type="button"
            className={["btn-round save_box_btn btn btn-secondary mr-3", style.create_new_btn].join(" ")}
            onClick={handleClearFilter}
          >
            <strong>{buttonText.CLEAR}</strong>
          </button>
        </div>

        <div className="box pb-0 mb-0">
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>Timeline View</h2>
              <h3>Detail view of employees timeline</h3>
            </div>
          </div>

          <div className="box-light-gradient"></div>
          <HRTeamMemberTable teamMemberList={hrTimelineEmployeeList} handleAssessmentClick={handleAssessmentClick} />
        </div>
      </Container>
    </div>
  );
}

export default HRTimelineView;
