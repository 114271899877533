import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import checkInReducer from "./reducers/checkIn";
import madeMyDayReducer from "./reducers/madeMyDay";
import myHistoryReducer from "./reducers/myHistory";
import myTeamReducer from "./reducers/myTeam";
import teamHistoryReducer from "./reducers/teamHistory";
import questionsReducer from "./reducers/questions";
import notificationReducer from "./reducers/notification";
import winChallengeReducer from "./reducers/winchallenge";
import objectivesReducer from "./reducers/objectives";
import performanceReducer from "./reducers/performance";
import oneOnOneReducer from "./reducers/oneOnOne";
import universityReducer from "./reducers/university";
import skillMatrixReducer from "./reducers/skillMatrix";
import feedbackReducer from "./reducers/feedback";
import hrDashboardReportReducer from "./reducers/hrDashboardReport";
import deAllocationReducer from "./reducers/deAllocation";
import adminReducer from "./reducers/admin";
import paginationReducer from "./reducers/pagination";
import managerDashboardReducer from "./reducers/managerDashboard";
import campaignReducer from "./reducers/campaign";
import capabilityReducer from "./reducers/capability";
import okrTemplateReducer from "./reducers/okrTemplate";
import informationHubReducer from "./reducers/informationHub";
import monthlyCheckinFeedbackReducer from "./reducers/monthlyCheckinFeedback";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  checkIn: checkInReducer,
  madeMyDay: madeMyDayReducer,
  myHistory: myHistoryReducer,
  myTeam: myTeamReducer,
  teamHistory: teamHistoryReducer,
  questions: questionsReducer,
  notification: notificationReducer,
  winChallenge: winChallengeReducer,
  objectives: objectivesReducer,
  performance: performanceReducer,
  oneOnOne: oneOnOneReducer,
  university: universityReducer,
  skillMatrix: skillMatrixReducer,
  feedback: feedbackReducer,
  hrDashboardReport: hrDashboardReportReducer,
  deAllocation: deAllocationReducer,
  admin: adminReducer,
  managerDashboard: managerDashboardReducer,
  campaign: campaignReducer,
  pagination: paginationReducer,
  capability: capabilityReducer,
  okrTemplate: okrTemplateReducer,
  informationHub: informationHubReducer,
  monthlyCheckinFeedback: monthlyCheckinFeedbackReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") state = undefined;
  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
