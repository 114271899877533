import { Button, Modal } from "react-bootstrap";
import { IC_ERROR } from "const/imgCost";
import { ReactSVG } from "react-svg";
import React from "react";

import styles from "./PendingAssigneePopUp.module.scss";
import { backGroundColor } from "const/constValue";


export default function PendingAssigneePopUp(props) {
    const handleClose = () => {
        props?.onClose && props.onClose();
    }

    const handleContinue = () => {
        props?.onContinue && props.onContinue();
    }

    const handleUpdateList = () => {
        props?.openUpdateList && props.openUpdateList()
    }

    return (
        <Modal
            size="lg"
            show={props?.show}
            animation={false}
        >
            <Modal.Header className={styles.header}>
                <span>
                    <ReactSVG src={IC_ERROR} />
                </span>
                <div>Either the OKR’s are not assigned or the overall OKR weightage is less than 100%</div>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
                <div className={styles.empContainer}>
                    {props?.pendingEmployees.map((emp, index) => (
                        <div key={emp.employeeId} className={styles.empInfo}>
                            <div className={styles.empImg} style={{ backgroundColor: backGroundColor[index % backGroundColor.length] }}>
                                {emp?.dispArr ? <img src={emp.dispArr} alt={emp.empName} /> : emp.empInitial}
                            </div>
                            <div className={styles.empDetail}>
                                <h6>{emp.empName}</h6>
                                <span>{emp.designation}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div >
                    <div className={styles.infoMsg}>
                        If you wish to continue with the already selected employees, please click on the "Skip and Continue"
                        action or click "<span className={styles.link} onClick={handleUpdateList}>Update List</span>" to choose the above employees
                    </div>
                </div>
                <div className={styles.btnContainer}>
                    <Button
                        type="button"
                        variant="primary"
                        className="btn-round"
                        onClick={handleContinue}
                    >
                        <strong>Skip & Continue</strong>
                    </Button>
                    <Button
                        type="button"
                        variant="secondary"
                        className="btn-round"
                        onClick={handleClose}
                    >
                        <strong>Close</strong>
                    </Button>
                </div>

            </Modal.Body>
        </Modal>
    )
}