import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import style from "../../AssignObjectives/AssignObjectives.module.scss";
import * as actions from "store/actions/index";
import { scrollToTop } from "util/general";
import AuthContext from "context/authContext";
import Spinner from "shared/Spinner/Spinner";
import ObjectiveHeading from "../../../Objectives/AllObjectives/UnivertsityCreatedObjective/ObjectiveHeading";
import ObjectiveOwner from "../../AssignObjectives/ObjectiveOwner";
import ObjectiveActivities from "../../CreatedObjective/ObjectiveActivities";
import KeyAccordion from "./Key_Result_Accordion";
import RejectMessageModal from "../../RejectMessageModal/RejectMessageModal";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

export default function UnivertsityObjective(props) {
  const context = useContext(AuthContext);
  const employeeIds = {
    employeeId: context.state.user?.employeeId,
    parentGroupId: context.state.user?.parentGroupId,
    subGroupId: context.state.user?.subGroupId,
  };
  const userObjectiveId = props.match.params.id;
  const [rejectModel, setRejectModel] = useState(false);
  const [activityData, setActivityData] = useState({});
  const dispatch = useDispatch();
  const { activities } = useSelector((state) => state.objectives);
  const { objective } = useSelector((state) => state.objectives);
  const _objective = useSelector((state) => state.checkIn.objective);

  useEffect(() => {
    scrollToTop(500);
    dispatch({ type: "CLEAR_OBJECTIVES_STATE" });
    dispatch(actions.GetObjective(userObjectiveId));
    dispatch(actions.GetManagerObjectiveActivity(userObjectiveId, employeeIds));
    dispatch(actions.GetUserObjective(userObjectiveId, employeeIds));
  }, [dispatch, userObjectiveId]);

  let data = [];
  if (props.location?.state && props.location.state.universityObjectiveByManager) {
    data = objective;
  } else {
    data = _objective;
  }
  let loader = false;
  loader = objective === null ? (_objective === null ? true : false) : false;

  const handleRejectMessagePopup = (data) => {
    setRejectModel(true);
    setActivityData(data);
  };

  if (loader) {
    return <Spinner />;
  }

  return (
    <div className={style.assignobjectives_wrapper}>
      <SubNavigation type="Objectives" />
      <Container>
        <Row>
          {props.location?.state?.universityObjectiveByManager ? (
            <Col md={6} className="col-12">
              <h1 className="page-title pb-0">Objectives</h1>
              <span className="st-breadcrumb">
                Objectives
                <span>
                  <Link to={routePath.ALL_OBJECTIVES}>All Objectives</Link>
                </span>
                <span>University Programs</span>
              </span>
            </Col>
          ) : (
            <Col md={6} className="col-12">
              <h1 className="page-title pb-0">Objectives</h1>
              <span className="st-breadcrumb">
                Objectives
                <span>
                  <Link to="/objectives/my-objectives">My Objectives</Link>
                </span>
                <span>Objective Details</span>
              </span>
            </Col>
          )}
          {data && <ObjectiveHeading objective={data} />}
          <Col xl={8}>
            {data && <KeyAccordion objective={data} />}
            {activities && (
              <ObjectiveActivities activities={activities} handleRejectMessagePopup={handleRejectMessagePopup} />
            )}
          </Col>
          <Col xl={4}>{data && data.createdBy && <ObjectiveOwner owner={data.createdBy} />}</Col>
        </Row>
      </Container>
      {rejectModel && (
        <RejectMessageModal
          activityData={activityData}
          objective={data}
          reasonOfComment={
            activityData.whatChanged === "OBJ_FEEDBACK_STATUS"
              ? 'has marked objective as "INVALID"'
              : `has rejected your objective "${data && data.title}"`
          }
          hide={() => setRejectModel(false)}
          show={rejectModel}
        />
      )}
    </div>
  );
}
