import React from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./TimeOverPopup.module.scss";

const TimeOverPopup = ({ show, hide, subText, path }) => {
  return (
    <>
      <Modal show={show} onHide={hide} animation={false} className={style.custom_modal_popups}>
        <div className={style.custom_modal_container}>
          <Row>
            <Col md={12}>
              <Modal.Body>
                <div className={style.try}>
                  <ReactSVG src={path} title="" alt="" />

                  <p className={style.popupText}>{subText}</p>
                </div>
              </Modal.Body>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default TimeOverPopup;
