import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import style from "./AssigneePopup.module.scss";
import TeamRow from "./TeamRow";
import NoDataFound from "../../../NodataFound/NoDataFound";
import { noData } from "const/constValue";
import { ReactSVG } from "react-svg";
import { IC_ERROR } from "const/imgCost";

const OKRDetailsPopup = (props) => {
  let [team, setTeam] = useState(props.teamData);

  return (
    <>
      <Modal
        size="lg"
        show={props.openPopup}
        onHide={() => props.setOpenPopup(true)}
        animation={false}
        className={style.assigneepopup_wrapper}
      >
        <div className={style.modal_header}>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="row">
            <ReactSVG src={`${IC_ERROR}`} title="" alt="" className={style.icon_style}/>
            <div className={style.headingdiv}>
              <h1>Wait! Please look into the employees whose OKR are not assigned 100 % </h1>
              <h2>Make sure you look into this first before moving forword</h2>
            </div>
          </div>
          </div>
        </div>
        <Modal.Body>
          <div className="box-light-gradient"></div> 
          <div className="col-xl-12">
            <div className={style.data_container}>
              {team && team.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <td className={style.name}>Name</td>
                      <td className={style.band}>Band</td>
                      <td className={style.totalWeightageOfManager}>Assigned OKR Weightage (%)</td>
                      <td className={style.remainingWeightageOfManager}>Remaining OKR Weightage (%)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {team.map((value, index) => (
                      <TeamRow
                        index={index}
                        closePopup={props.openPopup}
                        setTeam={setTeam}
                        teamData={team}
                        team={value}
                        key={"TEAM_" + value.employeeId}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.assigned_modal_footer}>
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_cancel].join(" ")}
            onClick={() => {
              setTeam(props.teamData);
              props.setOpenPopup(false);
            }}
          >
            <strong>Close</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OKRDetailsPopup;
