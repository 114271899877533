import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { ReactSVG } from "react-svg";
import CommentTextArea from "components/CommentBox/CommentTextArea";
import CommentsTop from "components/CommentBox/CommentsTop";
import { CANCEL_CROSS, IC_LOCK_PRIVACY_2 } from "const/imgCost";
import { MonthlyCheckinTexts, btnName } from "const/constValue";
import storage from "util/storage";
import RecentCheckins from "./RecentCheckins/RecentCheckins";
import style from "./MonthlyCheckInFeedback.module.scss";
import { errorToast, getFullMonthName  } from "util/general";


export default function ManagerCheckInFeedback({
  period,
  handleChangeResult,
  feedbackQuestions,
  employeesProfiles,
  recentCheckIns,
  handleSubmitFeedback,
  checkedEmployee
}) {
  const loggedInUser = storage.getUser();
  const [comment, setComment] = useState("");
  const [selectEmployees, setSelectEmployees] = useState([]);
  const [individualMessageEmployees, setIndividualMessageEmployees] = useState([]);
  const [selectedEmployeesCount, setSelectedEmployeesCount] = useState(0);
  const [individualMessages, setIndividualMessages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [activeCommentIndex, setActiveCommentIndex] = useState(0);
  const [isCommentModified, setIsCommentModified] = useState(false);
  const [isIndividualMessageRemoved, setIsIndividualMessageRemoved] = useState(false);

  useEffect(() => {
    if (employeesProfiles && employeesProfiles.length > 0) {
      const selectEmployeesData = employeesProfiles.map((employeeProfile) => {
        if(checkedEmployee.includes(employeeProfile.employeeId)){
          return {
            selected: true,
            ...employeeProfile,
          };
        } else {
          return {
            selected: false,
            ...employeeProfile,
          };
        }
      });
      checkedEmployee?.length === selectEmployeesData?.length && setSelectAll(true);
      // to display check box which was slected previously by checkin the answer which is not null
      setSelectEmployees(selectEmployeesData);
    }
  }, [employeesProfiles]);

  useEffect(() => {
    let count = 0;
    const commonCommentEmployees = [];
    for (let i = 0; i < selectEmployees.length; i++) {
      if (selectEmployees[i].selected) {
        count = count + 1;
        const isAvailableInOptions = employeeOptions.find(employee => employee.value === selectEmployees[i].displayName);
        if(!isAvailableInOptions){
          commonCommentEmployees.push(selectEmployees[i]);
        }
      }
    }
    setIndividualMessageEmployees([...commonCommentEmployees]);
    setSelectedEmployeesCount(count);
    // to display the saved comment on page refresh for selected employees
    commonCommentEmployees.length > 0 && setComment(commonCommentEmployees[0]?.managerComment);
  }, [selectEmployees]);

  useEffect(() => {
    if (feedbackQuestions && feedbackQuestions?.length > 0 && questionsData.length === 0) {
      const questionsData = feedbackQuestions.map((feedbackQuestion) => {
        return {
          ...feedbackQuestion,
          // answer: null,
        };
      });
      setQuestionsData(questionsData);
    }
  }, [feedbackQuestions]);

  useEffect(()=> {
    if(isCommentModified && comment){
      const timer  = setTimeout(()=> {
        handleComment();
      }, 1000);

      return () => clearTimeout(timer);
    }
  },[comment, isCommentModified]);

  useEffect(() => {
    if (individualMessages.length > 0) {
      if (!isIndividualMessageRemoved && isCommentModified) {
        const timer = setTimeout(() => {
          handleSaveIndividulMessage(activeCommentIndex);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setIsIndividualMessageRemoved(false);
      }
    }
  }, [individualMessages, isCommentModified]);

  useEffect(()=> {
    if(individualMessageEmployees.length === 0){
      setComment("");
    }
  },[individualMessageEmployees]);

  // this function will call whenever we modify the comment 
  const handleComment = () => {
    const employeeCompositeIds = [];
    for (let i = 0; i < individualMessageEmployees.length; i++) {
      const { employeeId, parentGroupId, subGroupId, selected } =
        individualMessageEmployees[i];
      if (selected) {
        employeeCompositeIds.push({ employeeId, parentGroupId, subGroupId });
      }
    }
    // calling feeback interaction api to save the comment for the common comment employees
    if (employeeCompositeIds.length > 0) {
      const body = {
        message: comment,
        employeeCompositeIds,
        isIndividualMessage: false
      };
      handleChangeResult(body);
    }
  };

  // this function will clear the questions answers, selected employees and comment by acceping a isClearButtonAction:boolean paramenter 
  const handleClear = (isClearButtonAction) => {
    let employeeProfile = selectEmployees && selectEmployees.filter((item) => item.selected == true);
    const employeeCompositeIds = [];
    for (let i = 0; i < employeeProfile.length; i++) {
      const { employeeId, parentGroupId, subGroupId } = employeeProfile[i];
      employeeCompositeIds.push({ employeeId, parentGroupId, subGroupId });
    }

    // calling the feedback interaction api to clear the comment based on the isClearButtonAction and employeeCompositeIds values
    if(isClearButtonAction && employeeCompositeIds.length > 0){
      let body = {
        questionId:null,
        message: null,
        employeeCompositeIds : employeeCompositeIds
      }
      handleChangeResult(body,true);
    }

    const selectEmployeesData = employeesProfiles.map((employeeProfile) => {
      return {
        selected: false,
        ...employeeProfile,
      };
    });
    // reset the selected employees state to unselected 
    setSelectEmployees(selectEmployeesData);
    // reset the selected common comments employees to unselected
    setIndividualMessageEmployees(selectEmployeesData);

    const questionsData = feedbackQuestions.map(feedbackQuestion => {
      return {
        ...feedbackQuestion,
        answer: null,
      }
    })
    // reset the answered questionsData to unanswered
    setQuestionsData(questionsData);
    // set the individual messages state to empty array
    setIndividualMessages([]);
    // set the individual employees dropdown values to empty array 
    setEmployeeOptions([]);
    // set select all state as false 
    setSelectAll(false);
    // set the common comment as empty
    setComment("");
    // set the comment modified state as false
    setIsCommentModified(false);
  };

  // this function will select or unselect employee by accepting employeeProfile:employeeProfile as a parameter
  const handleSelect = async (employeeProfile) => {
    const questionsAnswered = questionsData.filter(question => question.answer);
    if (questionsAnswered.length === questionsData.length) {
      const { employeeId, selected, displayName } = employeeProfile;
      const updatedData = selectEmployees.filter((employee) => {
        if (employee.employeeId === employeeId) {
          employee.selected = !employee.selected;
        }
        return employee;
      });
      const isSelectedAll = updatedData.find(
        (employee) => employee.selected === false
      );
      const selectedEmployee = updatedData.filter(
        (employee) => employee.selected === true
      );
      const deSelect = updatedData.filter(
        (employee) => employee.selected === false
      );
      const unSelectedEmployee = deSelect.find(
        (employee) => employee.employeeId === employeeId
      );

      // set the selectAll state based on the employees selections.
      if (isSelectedAll) {
        setSelectAll(false);
      } else {
        setSelectAll(true);
      }
      // set the selectEmployees state based on the employee selected or unselected
      setSelectEmployees(updatedData);
      if(selected){
        const filteredOptions = employeeOptions.filter(employee => employee.value !== displayName);
        // if employee is unselected then set the filtered employees to individual message employee options dropdown
        setEmployeeOptions([...filteredOptions]);
        // if filtered employee options are empty then set the individual messages state as empty array
        if(filteredOptions.length === 0){
          setIndividualMessages([]);
        } else {
          const filteredMessages = individualMessages.filter(individualMessage => individualMessage.selectedEmployee.value !== employeeProfile.displayName);
          setIndividualMessages(filteredMessages);
        }
      }
      const questionAnswerList = [];
      for (let i = 0; i < questionsData.length; i++) {
        const { questionId, answer } = questionsData[i];
        if (answer && selected) {
          answer = null;
        }
        questionAnswerList.push({ questionId, answer });
      }
      const selectedEmployeeList = [];
      for (let i = 0; i < selectedEmployee.length; i++) {
        const { employeeId, parentGroupId, subGroupId } = selectedEmployee[i];
        selectedEmployeeList.push({ employeeId, parentGroupId, subGroupId });
      }
      let unSelectedEmployeeList = [{
        employeeId : unSelectedEmployee?.employeeId,
        parentGroupId : unSelectedEmployee?.parentGroupId,
        subGroupId : unSelectedEmployee?.subGroupId
      }]
      const compositIDs = !selected ? selectedEmployeeList : unSelectedEmployeeList
      const body = {
        questionAnswerList,
        employeeCompositeIds: compositIDs
      };
      await handleChangeResult(body);
      if (comment && isCommentModified) {
        body.message = comment;
        body.isIndividualMessage = false;
        delete body.questionAnswerList;
        await handleChangeResult(body);
      }
      // after feedback interaction api call set the IsCommentModifed state as false.
      setIsCommentModified(false);
    } else{
      errorToast("Please select the answers for feedback questions before selecting an employee");
    }
  };

  // this function will add the individual message block below the common comments section
  const handleAddIndividualMessage = () => {
    if (employeeOptions.length > 0 && employeeOptions.length > individualMessages.length) {
        const messages = individualMessages;
        messages.push({ selectedEmployee: employeeOptions[0], message: "" });
        // set the individual messages state based on the individual message employees options state 
        setIndividualMessages([...messages]);
        // set the comment modified state as false
        setIsCommentModified(false);
    } else if(individualMessages.length > 0){
      errorToast(
        "There is no any employee to add another individual message."
      );
    } else {
      errorToast(
        "To send a individual message to a specific employee, just exclude their name from the list of common comments."
      );
    }
  };

  // this function will update the existing individual employee message based on the index by accepting commment:string and index:number as params
  const handleIndividualCommentMessage = (comment, index) => {
    const messages = individualMessages;
    messages[index].message = comment;
    setIndividualMessages([...messages]);
    setActiveCommentIndex(index);

    if(!isCommentModified) {
      setIsCommentModified(true);
    }
  };

  // this function will update the individual message employee dropdown value by accepting the employee:{value:string, label:string} and index:number as params 
  const handleSelectEmployee = (employee, index) => {
    const messages = individualMessages;
    messages[index].selectedEmployee = employee;
    setIndividualMessages([...messages]);
  };
  
  // this function will select all the employees when we click on the select all radio button
  const handleSelectAll = () => {
    const questionsAnswered = questionsData.filter(question => question.answer);
    if (questionsAnswered.length === questionsData.length) {
      const updatedData = selectEmployees.filter((employee) => {
        if (selectAll) {
          employee.selected = !employee.selected;
        } else {
          employee.selected = true;
        }
        return employee;
      });
      // set the selectEmployees state based on the previous state
      setSelectEmployees(updatedData);
      if (selectAll) {
        // set the individual messages state as empty array
        setIndividualMessages([]);
      }
      // set the selectAll state to false if it is true
      setSelectAll(!selectAll);
      // set the comment modified state as false
      setIsCommentModified(false);
      
      const employeeCompositeIds = [];
      for (let i = 0; i < selectEmployees.length; i++) {
        const { employeeId, parentGroupId, subGroupId } = selectEmployees[i];
        employeeCompositeIds.push({ employeeId, parentGroupId, subGroupId });
      }
      const questionAnswerList = [];
      for (let i = 0; i < questionsData.length; i++) {
        const { questionId, answer } = questionsData[i];
        if (answer && selectAll) {
          answer = null;
        }
        questionAnswerList.push({ questionId, answer });
      }
        const body = {
          questionAnswerList,
          employeeCompositeIds
        };
        handleChangeResult(body);
    } else {
      errorToast(
        "Please select the answers for feedback questions before selecting an employee"
      );
    }
  };

  // this function will change the question answer by accepting questionId:string and answer:string as params
  const handleChangeAnswer = (questionId, answer) => {
    const modifiedQuestionsData = questionsData.map(question => {
      if(question.questionId === questionId){
        return {
          ...question,
          answer
        }
      }
      return question;
    });
  // set the updated questions data to the state 
  setQuestionsData(modifiedQuestionsData);
  }

  // this function will call the feedback interaction api to clear the comment by accepting employee:employeeProfile and isIndividualMessage:boolean as params
  const handleClearComment = (employee, isIndividualMessage) => {
    const body = {
      message: "",
      employeeCompositeIds: [
        {
          employeeId: employee?.employeeId,
          parentGroupId: employee?.parentGroupId,
          subGroupId: employee?.subGroupId,
        },
      ],
      isIndividualMessage,
    };
    handleChangeResult(body);
  }

  // this function will remove the employee from the common comments section by accepting employeeId:string as a parameter
  const handleRemoveEmployee = (employeeId) => {
    const updatedData = individualMessageEmployees.filter(
      (employee) => employee.employeeId !== employeeId
    );
    // set the updated state by removing the employee from the existing state
    setIndividualMessageEmployees(updatedData);
    const employeeOptions = selectEmployees.filter(
      ({ employeeId, selected }) =>
        selected &&
        !updatedData.some(({ employeeId: empId }) => employeeId === empId)
    );
    const formattedOptions = employeeOptions.map((employee) => {
      const { displayName } = employee;
      return {
        label: displayName,
        value: displayName,
      };
    });
    // set the formatted employees array to employeeOptions state
    setEmployeeOptions(formattedOptions);

    const removedEmployee = selectEmployees.find(selectEmployee => selectEmployee.employeeId === employeeId);
    handleClearComment(removedEmployee, false);
    // set the comment modified state as false
    setIsCommentModified(false);
  };

  // this function will remove the individual comment message block by accepting index:number as a param
  const handleCloseMessageBox = (index) => {
    const { selectedEmployee } = individualMessages[index];
    const removedEmployee = selectEmployees.find(employee => employee.displayName === selectedEmployee.value);
    const filterMessages = individualMessages.filter(
      (individualMessage, i) => i != index
    );
    // set the updated state to individual messages
    setIndividualMessages(filterMessages);
    // set the IsIndividualMessageRemoved state as true to not to trigger the api call for saving the comment
    setIsIndividualMessageRemoved(true);
    // function to clear the removed employee comment
    handleClearComment(removedEmployee, true);
  };

  // this function will save the individual message by accepting index:number as a parameter
  const handleSaveIndividulMessage = (index) => {
    const messageData = individualMessages[index];
    const { selectedEmployee, message } = messageData;
    let employeeProfile = selectedEmployee || employeeOptions[0];
    employeeProfile = selectEmployees.find(
      (employee) => employee.displayName === employeeProfile.value
    );
    const { employeeId, parentGroupId, subGroupId } = employeeProfile;
    const body = {
      message,
      employeeCompositeIds: [{ employeeId, parentGroupId, subGroupId }],
      isIndividualMessage: true,
    };
    handleChangeResult(body);
  };

  // this function will submit the employees feedback
  const handleSubmit = async () => {
    let employeeProfile = selectEmployees && selectEmployees.filter((item) => item.selected == true);
    const employeeCompositeIds = [];
    for (let i = 0; i < employeeProfile.length; i++) {
      const { employeeId, parentGroupId, subGroupId } = employeeProfile[i];
      employeeCompositeIds.push({ employeeId, parentGroupId, subGroupId });
    }
    let body = {
      compositeIds : employeeCompositeIds
    }
    if(employeeCompositeIds.length > 0) {
      await handleSubmitFeedback(body);
      handleClear(false);
    } else {
      errorToast(
        "Please select at least one employee."
      );
    }
  };

  // this function will modify the existing common comment by acceptiong value:string as a parameter
  const handleCommentChange = (value) => {
    setComment(value);
    if(!isCommentModified) {
      setIsCommentModified(true);
    }
  }
  
  return (
    <>
      {employeesProfiles.length > 0 && <div
        className={`box ${recentCheckIns.length > 0 ? style.box_styles : ""}`}
      >
        <div className="box-heading-wrapper pb-1">
          <div className="box-heading">
          <h2> {getFullMonthName(period)} {MonthlyCheckinTexts.CHECK_INS_PENDING}</h2>
            <Row className={style.details_wrapper}>
              <Col>
                <h6>
                  <span className={style.greyText}>{period}&nbsp;{MonthlyCheckinTexts.CHECK_IN_PERIOD}&nbsp;</span>
                </h6>
              </Col>
            </Row>
          </div>
        </div>
        <div className="box-inner-pad box-light-gradient no-border">
          <h6 className={style.note_text}>{MonthlyCheckinTexts.NOTE}</h6>
          <div className={style.inner_container}>
            <div className={style.questions_main_wrapper}>
              <div className="box-heading-wrapper">
                <div className={`box-heading ${style.inner_header}`}>
                  <h5>{MonthlyCheckinTexts.SUBMIT_YOUR_MONTHLY_CHECK_IN}</h5>
                  <h5>
                    <span className={style.greyText}>
                      {MonthlyCheckinTexts.QUESTIONS}
                    </span>
                    &nbsp;&nbsp;{feedbackQuestions?.length}
                  </h5>
                </div>
              </div>
              <div className="box-inner-pad box-light-gradient no-border">
                {questionsData.map((newQuestion) => {
                  const { questionTitle, questionId, answer, required } =
                    newQuestion;
                  return (
                    <div key={questionId}>
                      <div className={`row ${style.question_wrapper} pb-3`}>
                        <div className={`col-lg-6 ${style.question_text} pr-0`}>
                          {questionTitle}
                          {required && <span className="text-danger"> *</span>}
                        </div>
                        <div className="col-lg-6">
                          <ul className={style.question_options_list}>
                            <li>
                              <div className="custom-checkbox custom-checkbox-rounded">
                                <input
                                  id={`yes-${questionId}`}
                                  data-test='radioYes'
                                  checked={answer === "Yes" ? true : false}
                                  onChange={() =>
                                    handleChangeAnswer(questionId, "Yes")
                                  }
                                  type="radio"
                                  value={answer || ""}
                                />
                                <label htmlFor={`yes-${questionId}`}>Yes</label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-checkbox custom-checkbox-rounded">
                                <input
                                  id={`no-${questionId}`}
                                  data-test='radioNo'
                                  checked={answer === "No" ? true : false}
                                  onChange={() =>
                                    handleChangeAnswer(questionId, "No")
                                  }
                                  type="radio"
                                  value={answer || ""}
                                />
                                <label htmlFor={`no-${questionId}`}>No</label>
                              </div>
                            </li>
                            {questionId === "65ba4d9f56b01393726fd562" && <li>
                              <div className="custom-checkbox custom-checkbox-rounded">
                                <input
                                  id={`other-${questionId}`}
                                  data-test='radioOther'
                                  checked={answer === "No Challenges Raised" ? true : false}
                                  onChange={() =>
                                    handleChangeAnswer(questionId, "No Challenges Raised")
                                  }
                                  type="radio"
                                  value={answer || ""}
                                />
                                <label htmlFor={`other-${questionId}`}>No Challenges Raised</label>
                              </div>
                            </li>}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={style.select_employees_header_wrapper}>
          <div>
            <h4>
              {MonthlyCheckinTexts.SELECT_EMPLOYEES} ({selectedEmployeesCount}){" "}
            </h4>
          </div>
          <div
            className={`custom-checkbox custom-checkbox-sm ${style.select_all_checkbox}`}
          >
            <input
              id={"select_all"}
              checked={selectAll}
              onChange={handleSelectAll}
              type="checkbox"
              value={selectAll}
            />
            <label htmlFor={"select_all"}>Select All</label>
          </div>
        </div>
        <div className={style.employee_checkbox_wrapper}>
          {selectEmployees.map((employee) => {
            const { employeeId, displayName, selected } = employee;
            return (
              <Fragment key={employeeId}>
                {displayName && (
                  <div
                    className={`custom-checkbox custom-checkbox-sm ${style.employee_checkbox}`}
                  >
                    <input
                      id={employeeId}
                      checked={selected}
                      onChange={(e) => handleSelect(employee)}
                      type="checkbox"
                      value={selected}
                    />
                    <label htmlFor={employeeId}>{displayName}</label>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
        <div
          className={`${style.comment_box_wrapper} view_comment_box_wrapper collapse show mt-0 mb-0`}
        >
          <>
            <CommentsTop heading="Share your view(s)" />
            <div className={style.selected_employees_wrapper}>
              <h6 className={style.note_text}>
                {MonthlyCheckinTexts.NOTE_FOR_MANAGER}
              </h6> 
              <h4>{MonthlyCheckinTexts.EMPLOYEES}</h4>
              <div className={style.chips_main_wrapper}>
                {individualMessageEmployees.map((employee) => {
                  const { displayName, employeeId, selected } = employee;
                  if (selected) {
                    return (
                      <Fragment key={employeeId}>
                        {displayName && (
                          <div className={style.chip_wrapper}>
                            <span>{displayName}</span>
                            <span className={style.close_icon_wrapper}>
                              <ReactSVG
                                src={`${CANCEL_CROSS}`}
                                onClick={() => handleRemoveEmployee(employeeId)}
                              />
                            </span>
                          </div>
                        )}
                      </Fragment>
                    );
                  }
                })}
              </div>
            </div>
            <CommentTextArea
              value={comment}
              maxLength={500}
              changeHandle={(e) => handleCommentChange(e?.target?.value)}
              image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
              placeholder={"Please add your view(s) here"}
            />
            {individualMessages.map((individualMessage, index) => {
              return (
                <div
                  className={style.individual_message_box}
                  key={`message${index}`}
                >
                  <div className={style.message_box_header}>
                    <div className={style.header_wrapper}>
                      <div className={style.text_main_wrapper}>
                        <ReactSVG
                          src={`${IC_LOCK_PRIVACY_2}`}
                          onClick={() => {}}
                        />
                        <span className={style.individual_message_text}>
                          individual message to
                        </span>
                      </div>
                      <div>
                        <Select
                          options={employeeOptions}
                          className={`custom-default-dd-box ${style.select_dropdown}`}
                          classNamePrefix="select"
                          value={individualMessage.selectedEmployee}
                          onChange={(employee) =>
                            handleSelectEmployee(employee, index)
                          }
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                    <div className={style.close_icon_wrapper}>
                      <ReactSVG
                        src={`${CANCEL_CROSS}`}
                        onClick={() => handleCloseMessageBox(index)}
                      />
                    </div>
                  </div>
                  <div className="comment_area_wrapper">
                    <Form.Group className="form_group_comment">
                      <div className="textarea-wrapper">
                        <Form.Control
                          as="textarea"
                          value={individualMessage.mesaage}
                          maxLength={500}
                          onChange={(e) =>
                            handleIndividualCommentMessage(
                              e.target.value,
                              index
                            )
                          }
                          placeholder={"Please add your view(s) here"}
                          className="manager_comments"
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              );
            })}
            <div className={style.add_message_text}>
              <span
                className={
                  individualMessages.length > 0
                    ? style.add_another_message
                    : style.add_a_message
                }
                onClick={handleAddIndividualMessage}
              >
                Add {individualMessages.length > 0 ? "another " : "an "}
                individual message
              </span>
            </div>
          </>
        </div>
        <div className={style.button_wrapper}>
          <button
            data-test='submit'
            type="button"
            onClick={handleSubmit}
            className={`btn btn-primary ${style.submit_button}`}
          >
            <strong>{btnName.SUBMIT}</strong>
          </button>
          <button
            type="button"
            onClick={() => handleClear(true)}
            className={`btn btn-secondary ${style.clear_button}`}
          >
            <strong>{btnName.CLEAR}</strong>
          </button>
        </div>
      </div>}
      {recentCheckIns.length > 0 && (
        <RecentCheckins recentCheckIns={recentCheckIns} period={period}/>
      )}
    </>
  );
}
