import React from "react";
import { ReactSVG } from "react-svg";
import { IC_INFO } from "../../const/imgCost";
import style from "./InfoTooltipWithLi.module.scss";

function InfoTooltip({ heading, top }) {
  return (
    <div className={[style.filter_info_tooltip, "custom-tooltip", top && "custom-tooltip-top"].join(" ")}>
      <div className="box-info">
        <ReactSVG src={`${IC_INFO}`} title="" alt="info" />
      </div>
      <div className="info-attach-file">
        <span className="attachment-title">{heading}</span>
      </div>
    </div>
  );
}

export default React.memo(InfoTooltip);
