import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import style from "../components/questions/questions.module.scss";
import ManagerView from "../components/CommentBox/ManagerView";
import InputRange from "react-input-range";
import "../../node_modules/react-input-range/lib/css/index.css";
import { useSelector } from "react-redux";

const RatingBar = (props) => {
  const [commentData, setCommentData] = useState(props.question.employeeComment ? props.question.employeeComment : "");
  let leftChars = props.question.employeeComment ? 500 - props.question.employeeComment.length : 500;

    const [rating, setRating] = useState(props?.current || 5);
  
  const [chars, setChars] = useState(leftChars);
  const current = props.current;

  useEffect(()=>{
    setCommentData(props.question.employeeComment ? props.question.employeeComment :"" )
  },[props.question.employeeComment])

  const { submitedDate, existWinChallenge, surveyCycleId } = useSelector((state) => state.questions);

  const commentHandle = (qAnswer) => {
    setCommentData(qAnswer)
    setChars(500 - qAnswer.length);
  }
  useEffect(()=>{
    setRating(props?.current);
  },[props.current])

  function clickHandler(value, id) {
    setRating(value);
    if (value !== current) {
      props.ratingAnswerHandler(value, id);
    }
  }

  const label = (value) => {
    switch (value) {
      case 1:
        return "Smooth";
      case 2:
        return "Reasonable";
      case 3:
        return "Needs Attention";
      case 4:
        return "Demanding";
      case 5:
        return "Bit Tough";
      case 6:
        return "Hard";
      case 7:
        return "Severe";
      case 8:
        return "Difficult";
      case 9:
        return "Nearly exhausted";
      case 10:
        return "Exhausted";
      default:
        return "";
    }
  };

  useEffect(()=>{
    setCommentData(props.question.employeeComment);
  },[props.question.employeeComment])

  let winChallenge = existWinChallenge && existWinChallenge.filter((i) => i.queId === props.question.queId);
  return !props.edit ? (
    <ManagerView
      surveyCycleId={surveyCycleId}
      style={style}
      isPage={props.isPage}
      edit={props.edit}
      submitCycle={props.submitCycle}
      managerRole={props.managerRole}
      question={props.question}
      selectedColor={props.selectedColor}
      winChallenge={winChallenge && winChallenge.length > 0 ? winChallenge[0] : null}
      childEmployee={props.childEmployee}
    />
  ) : (
    <div className="box-inner-pad ratingBarBox nRatingBarBox">
      {submitedDate ? (
        <div className={[style.workload_rating_result_wrapper, style.employee_rating].join(" ")}>
          <ul className={[style.workload_rating, style.workload_rating_current].join(" ")}>
            <li>
              <div className={style.workload_rating_result}>
                {current}
                <span>/</span>10
              </div>
              <div className={style.workload_rating_result_duration}>Current OKR Progress</div>
            </li>
          </ul>
        </div>
      ) : (
        <Form.Group controlId="">
          <Form.Label>
            {props.question.queTitle}
            {props.question.required && <span className="text-danger"> * </span>}
            <Form.Text>
              Metric answers are visible by account admin(s) in addition to your reviewer and followers
            </Form.Text>
          </Form.Label>
          <div className={["two_column", style.workload_rating_guide].join(" ")}>
            <span>Manageable</span>
            <span>Overloaded</span>
          </div>
          <div className="custom-range-slider">
            <InputRange
              maxValue={10}
              minValue={1}
              value={+rating}
              formatLabel={(value) => label(value)}
              onChange={(value) => {
                clickHandler(value, props.id);
              }}
            />
            <ul className="rating-number">
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>10</li>
            </ul>
          </div>
          <div className="pt-0 ">
            <div className="textarea-wrapper">
              <Form.Control
                as="textarea"
                maxLength="500"
                placeholder="+Do you have anything else to add (optional)"
                onBlur={(e) => props.ratingCommentHandler(e, props.question.queId)}
                onChange={(e) => commentHandle(e.target.value)}
                // defaultValue={props.question.employeeComment}
                value={commentData === null ? "" : commentData}
              />
            </div>
            <div className="checkin-question-info mt-2"> {chars} Characters Left</div>
          </div>
        </Form.Group>
      )}
    </div>
  );
};

export default RatingBar;
