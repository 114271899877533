import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Col, Row, Modal, Button } from "react-bootstrap";
import style from "./AllObjectives.module.scss";
import { IC_CSV_MODAL, IC_ERROR } from "const/imgCost";

const ComfirmUploadPopup = (props) => {
  let inputElement = React.createRef(); 

  return (
    <>
      <Modal
        size={props.errorFlag ? "lg" : "lg"}
        show={props.show}
        onHide={() => props.hide()}
        animation={false}
        className="custom_modal_popup"
      >
        <div className="custom_modal_container">         
          <Row>
            <Col md={12}>
              <Modal.Body>
                {props.errorFlag ? (
                  <div className={style.uploadcsv_popup_wrapper}>
                    <div className={style.title_box}>
                      <h1>Wait! We have found some issues!</h1>
                      <h2>Make sure you clear all the issues ﬁrst and then re-upload the ﬁle.</h2>
                    </div>
                    <div className={style.fileicon_3}>
                      <ReactSVG src={`${IC_CSV_MODAL}`} />
                      <span>{props.fileName}</span>
                    </div>
                    <div className={style.error_box}>
                      <div className={style.error_title}>
                        <div className={style.fileicon_2}>
                          <ReactSVG src={`${IC_ERROR}`} />
                        </div>
                        <span>Errors Found</span>
                      </div>
                      <div className={style.error_point}>
                        <ul>
                          {props.isFromPerformance && (
                            <li>Please ensure that column name should be EmployeeId, Entity, Rating.</li>
                          )}
                          {(props.isFromObjective ? props.columnError : (!props?.isFromQuiz && !props.isFromPerformance)) && (
                            <li>
                              Please ensure that column name should be objective_name, objective_weightage, emp_band, objective_description, key_areas,
                              key_area_weightage, unit, base_value, target_value, tags, stretch_keyresult.
                            </li> 
                          )}
                          {props.errorList.map((e, i) => {
                            return <li key={i}>{e}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={style.uploadcsv_popup_wrapper}>
                    <div className={style.title_box}>
                      <h1>Are you certain you want to pursue with this file?</h1>
                      <h2>Tap "Proceed" button if you are sure, else tap "Cancel".</h2>
                    </div>
                    <div>
                      <span className={style.fileicon_2}>
                        <ReactSVG src={`${IC_CSV_MODAL}`} />
                      </span>{" "}
                      <p className={style.file_name_2}>{props.fileName}</p>
                      {!props.isTeamObjective && props.handlePreview &&
                        <div style={{ cursor: "pointer", color: " blue" }} onClick={props.handlePreview}>
                          Preview Template
                        </div>
                      }
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Col>
            <Col className="custom_modal_button">
              <input
                id="fileids"
                type="file"
                accept=".xlsx"
                hidden
                ref={(input) => (inputElement = input)}
                onChange={() => {
                  props.setFile(inputElement.files[0]);
                  props.uploadFileAPI(inputElement.files[0]);
                }}
              />
              {props.errorFlag ? (
                <Button variant="primary" onClick={() => inputElement.click()}>
                  Upload
                </Button>
              ) : (
                <Button variant="primary" onClick={() => props.submit()}>
                  Proceed
                </Button>
              )}

              <Button variant="secondary" className="btn-border" onClick={() => props.hide()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ComfirmUploadPopup;
