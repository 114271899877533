import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import style from "./MemberCertification.module.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { IC_INPUT_SEARCH, IC_DOWNLOAD_1, IC_INFO, IC_SORT_UP_DOWN, IC_SORT_UP, IC_SORT_DOWN } from "const/imgCost";
import moment from "moment";
import * as actions from "store/actions/index";
import { errorToast, filterArray, sortAlphanumerics, sortNumeric, successToast } from "util/general";
import { moduleRights } from "util/utils";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "../NodataFound/NoDataFound";
import Pagination from "../Pagination/Pagination";
import { noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function MemberCertification() {
  const dispatch = useDispatch();
  const {
    isLoading,
    certificateListForUniversityManager,
    downloadFileResponse,
    size,
    universityMemberCertificateExport,
  } = useSelector((state) => state.university);
  const [searchText, setSearchText] = useState("");
  const [pageOfItems, setPageOfItems] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [typeArr, setFilterType] = useState("ALL");
  const [defaultKey, setDefaultKey] = useState("employeeName");
  const [sortingOrder, setSortingOrder] = useState("ascending");
  const [sortingKey, setSortingKey] = useState(defaultKey);
  const columns = ["employeeName", "employeeId", "certificationName"];

  //to get data from api
  useEffect(() => {
    const getCertificateList = () => {
      dispatch(actions.getCertificateListForAllEmployee);
    };
    getCertificateList();
  }, [dispatch]);

  // to clear input search field & set data to default
  const reset = () => {
    setSearchText("");
    setCertificateData(certificateListForUniversityManager);
  };

  // set api data to local state and empty the search field
  useEffect(() => {
    if (certificateListForUniversityManager && certificateListForUniversityManager.length > 0)
      setCertificateData(certificateListForUniversityManager);
    setSearchText("");
  }, [certificateListForUniversityManager]);

  //for change of classnames if value is entered in search box
  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  useEffect(() => {
    if (downloadFileResponse) {
      if (downloadFileResponse.error) errorToast(downloadFileResponse.message);
      else successToast(downloadFileResponse.message);
    }
  }, [downloadFileResponse]);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
    if (!e.target.value) {
      setFilterType("ALL");
    }
  };

  const downloadCertificate = (item) => {
    const params = {
      employeeId: item.employeeId,
      parentGroupId: item.parentGroupId,
      subGroupId: item.subGroupId,
    };
    dispatch(actions.downloadCertificate(params, item.id));
  };

  const onChangeCertificatePagePage = (item) => {
    setPageOfItems(item);
  };

  useEffect(() => {
    filteredData.length > 0
      ? setCertificateData(filteredData)
      : undefined
      ? setCertificateData(filteredData)
      : setCertificateData(certificateListForUniversityManager);
  }, [filteredData, certificateListForUniversityManager]);

  useEffect(() => {
    let array = [];
    if (searchText.length > 0) {
      if (searchText.startsWith("#")) {
        const queryStr = searchText.substring(1).trim();

        array = certificateListForUniversityManager.filter((data) =>
          data.certificationName.toLowerCase().includes(queryStr.toLowerCase().trim())
        );
      } else {
        array = certificateListForUniversityManager.filter(
          (data) =>
            data.employeeName.toLowerCase().includes(searchText.toLowerCase().trim()) ||
            data.employeeId.includes(searchText)
        );
      }
    } else {
      array = certificateListForUniversityManager;
    }

    if (typeArr === "VALID") {
      array = array.filter((req) => req.status.toLowerCase() === "valid");
    } else if (typeArr === "EXPIRED") {
      array = array.filter((req) => req.status.toLowerCase() === "expired");
    }
    const baseSortColumns = columns.filter((col) => col !== defaultKey) || [];
    for (const col of baseSortColumns) {
      array = JSON.parse(JSON.stringify(array));
      if (col === "employeeId") {
        array = sortNumeric([...array], col);
      } else {
        array = sortAlphanumerics([...array], col);
      }
    }
    array = [...array];
    if (defaultKey === "employeeId") {
      array = sortNumeric(array, defaultKey);
    } else {
      array = sortAlphanumerics(array, defaultKey);
    }
    if (sortingOrder === "descending") {
      array = [...array.reverse()];
    }
    setCertificateData([...array]);
  }, [certificateListForUniversityManager, defaultKey, searchText, sortingOrder, typeArr]);

  const sortHandler = (key, order) => {
    let sortOrder;
    let sortKey;
    if (key === defaultKey) {
      sortKey = key;
      sortOrder = order === "ascending" ? "descending" : "ascending";
    } else {
      sortKey = key;
      sortOrder = "ascending";
    }
    setDefaultKey(key);
    setSortingKey(sortKey);
    setSortingOrder(sortOrder);
    baseSortHander(certificateData, key, sortOrder);
  };

  const baseSortHander = (list, key, order) => {
    const baseSortColumns = columns.filter((col) => col !== key) || [];
    for (const col of baseSortColumns) {
      list = JSON.parse(JSON.stringify(list));
      if (col === "employeeId") {
        list = sortNumeric([...list], col);
      } else {
        list = sortAlphanumerics([...list], col);
      }
    }
    list = [...list];
    if (key === "employeeId") {
      list = sortNumeric(list, key);
    } else {
      list = sortAlphanumerics(list, key);
    }
    if (order === "descending") {
      list = [...list.reverse()];
    }
    setCertificateData(list);
  };

  useEffect(() => {
    if (certificateData && certificateData.length === 0) {
      setPageOfItems([]);
    }
  }, [certificateData]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (certificateData && certificateData.length > 0) {
      filteredData = filterArray(certificateData, filters);
      setFilteredData(filteredData);
    }
  };

  const filterByRequest = (e) => {
    const value = e.target.value;
    let array = [];
    if (e.target.checked) {
      setFilterType(value);
      if (value === "VALID") {
        array = certificateListForUniversityManager.filter((req) => req.status.toLowerCase() === "valid");
      } else if (value === "EXPIRED") {
        array = certificateListForUniversityManager.filter((req) => req.status.toLowerCase() === "expired");
      } else {
        array = certificateListForUniversityManager;
      }
    }
    if (!e.target.checked) {
      setFilterType("ALL");
    }
    setCertificateData(array);
  };

  const handleClick = () => {
    let param = {};
    let type = "";
    if (!(typeArr === "ALL")) {
      typeArr === "VALID" ? (type = "Valid") : (type = "Expired");

      if (searchText && searchText.length > 0) {
        param = {
          filter: type,
          search: searchText,
        };
      } else {
        param = {
          filter: type,
        };
      }

      dispatch(actions.getUniversityMemberCertificateExport(param));
    } else {
      if (searchText && searchText.length > 0) {
        param = {
          search: searchText,
        };
        dispatch(actions.getUniversityMemberCertificateExport(param));
      } else {
        dispatch(actions.getUniversityMemberCertificateExport());
      }
    }
  };

  useEffect(() => {
    if (universityMemberCertificateExport) {
      if (universityMemberCertificateExport.error) errorToast(universityMemberCertificateExport.message);
      if (universityMemberCertificateExport.success) successToast(universityMemberCertificateExport.message);
    }
  }, [universityMemberCertificateExport]);

  return (
    <div className={style.member_certificate_wrapper}>
      <SubNavigation type="University" />
      <Container>
        <Row className="align-items-center">
          <Col md={8}>
            <h1 className="page-title">Member Certifications</h1>
            <span>It allows members to acquire new skills, perform better, and increase productivity </span>
          </Col>
          {moduleRights(config.mainModule.UNIVERSITY, config.subModule.MEMBER_CERTIFICATIONS)?.includes("EXPORT") && (
            <Col md={4} className={[style.res_title_btn, "text-right"].join(" ")}>
              <button
                type="button"
                className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
                onClick={handleClick}
              >
                <strong>Export</strong>
              </button>
            </Col>
          )}
        </Row>
      </Container>
      <div className="gap30"></div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className={[style.my_certificate_box, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="check_in_search_bar">
                  <Form>
                    <Form.Group controlId="" className="form-postfix mb-0">
                      <Form.Control
                        type="text"
                        placeholder="Search for Emp id/ name/ certificate"
                        name="searchBox"
                        className={className}
                        value={searchText}
                        onChange={handleSearchInputChange}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      <Button className="close-button" type="reset" onClick={reset}>
                        <span className="close-icon"></span>
                      </Button>
                      <div className="form-postfix-icon">
                        <img src={IC_INPUT_SEARCH} title="" alt="" />
                      </div>
                    </Form.Group>
                  </Form>
                  <div className={[style.filter_info_tooltip, "custom-tooltip"].join(" ")}>
                    <div className="box-info">
                      <ReactSVG src={IC_INFO} title="" alt="info" />
                    </div>
                    <div className="info-attach-file">
                      <span className="attachment-title">Search Criteria</span>
                      <ul>
                        <li>If you want to search employee by employee id then search through employee id</li>
                        <li>If you want to search by employees then search through employee name. </li>
                        <li>If you want to search by certification use #.E.g. #certification</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="tab_check_in_status">
                  <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
                    <input
                      id="all-19"
                      type="radio"
                      name="status-check"
                      value="ALL"
                      checked={typeArr === "ALL"}
                      onChange={(e) => filterByRequest(e)}
                    />
                    <label htmlFor="all-19">All</label>
                  </div>

                  <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
                    <input
                      id="g129"
                      type="radio"
                      name="status-check"
                      value="VALID"
                      checked={typeArr === "VALID"}
                      onChange={(e) => filterByRequest(e)}
                    />
                    <label htmlFor="g129">Valid</label>
                  </div>

                  <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
                    <input
                      id="g239"
                      type="radio"
                      name="status-check"
                      value="EXPIRED"
                      checked={typeArr === "EXPIRED"}
                      onChange={(e) => filterByRequest(e)}
                    />
                    <label htmlFor="g239">Expired</label>
                  </div>
                </div>
              </div>

              {isLoading && <Spinner />}
              <>
                {pageOfItems && pageOfItems.length !== 0 ? (
                  <>
                    <div className="box-light-gradient h38"></div>
                    <div className="box-inner-pad pb-2">
                      <div className={[style.member_certificate_table, "custom-table", "table-theme-1"].join(" ")}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th onClick={() => sortHandler("employeeId", sortingOrder)}>
                                Emp Id
                                <div className={style.sorting_icon}>
                                  <ReactSVG
                                    src={
                                      sortingKey === "employeeId" && sortingOrder === "ascending"
                                        ? IC_SORT_UP
                                        : sortingKey === "employeeId" && sortingOrder === "descending"
                                        ? IC_SORT_DOWN
                                        : IC_SORT_UP_DOWN
                                    }
                                  />
                                </div>
                              </th>

                              <th onClick={() => sortHandler("employeeName", sortingOrder)}>
                                Name
                                <div className={style.sorting_icon}>
                                  <ReactSVG
                                    src={
                                      sortingKey === "employeeName" && sortingOrder === "ascending"
                                        ? IC_SORT_UP
                                        : sortingKey === "employeeName" && sortingOrder === "descending"
                                        ? IC_SORT_DOWN
                                        : IC_SORT_UP_DOWN
                                    }
                                  />
                                </div>
                              </th>

                              <th onClick={() => sortHandler("certificationName", sortingOrder)}>
                                Certification
                                <div className={style.sorting_icon}>
                                  <ReactSVG
                                    src={
                                      sortingKey === "certificationName" && sortingOrder === "ascending"
                                        ? IC_SORT_UP
                                        : sortingKey === "certificationName" && sortingOrder === "descending"
                                        ? IC_SORT_DOWN
                                        : IC_SORT_UP_DOWN
                                    }
                                  />
                                </div>
                              </th>
                              <th>Validity</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pageOfItems &&
                              pageOfItems.length !== 0 &&
                              pageOfItems.map((item) => {
                                if (item) {
                                  let startDate, endDate;
                                  if (item.startDate && item.endDate) {
                                    startDate = moment(item.startDate?.split(".")[0]).format("MMMM D, YYYY");
                                    endDate = moment(item.endDate?.split(".")[0]).format("MMMM D, YYYY");
                                  }
                                  const displayCertificateDuration = startDate + " - " + endDate;
                                  return (
                                    <tr key={item.id}>
                                      <td>{item.employeeId}</td>
                                      <td>{item.employeeName}</td>
                                      <td>{item.certificationName}</td>
                                      <td>
                                        {startDate && endDate
                                          ? displayCertificateDuration
                                          : item.startDate
                                          ? `${moment(item.startDate).format("MMMM D, YYYY")}${" - "}${"Lifetime"}`
                                          : "Lifetime"}
                                      </td>
                                      <td>
                                        {item.status === "Valid" ? (
                                          <span className={[style.req_comp_level, "purple"].join(" ")}>Valid</span>
                                        ) : (
                                          <span className={[style.req_comp_level, "orange"].join(" ")}>Expired</span>
                                        )}
                                      </td>

                                      <td>
                                        <span className={[style.download, "fill-svg-icon-h"].join(" ")}>
                                          <ReactSVG src={IC_DOWNLOAD_1} onClick={() => downloadCertificate(item)} />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                } else {
                                  return <></>;
                                }
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap80"></div>
                  </div>
                )}
              </>
            </div>
            {certificateData && certificateData.length !== 0 && (
              <Pagination
                pageSize={size}
                items={certificateData}
                onChangePage={onChangeCertificatePagePage}
                resetData={resetData}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
