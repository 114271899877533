import React, { useState } from "react";
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Tooltip, Label, CartesianGrid } from "recharts";
import Rectangle from "recharts/lib/shape/Rectangle";
import style from "./TeamsWeeklyCheckInChart.module.scss";

const COLORS = {
  Submitted: "#6DD400",
  Lapsed: "#7033FF",
  "Reviewed by Manager": "#FFB200",
};

function TeamsWeeklyCheckInChart({ checkinGraphData }) {
  const [currentVal, setCurrentVal] = useState(null);
  const legendData = [{ name: "Lapsed" }, { name: "Submitted" }, { name: "Reviewed by Manager" }];
  const CustomTooltip = (props) => {
    const { active, payload } = props;
    let checkinCount;
    let pr;
    if (payload) {
      payload.forEach((data) => {
        if (currentVal && currentVal === data.dataKey) {
          checkinCount = data.payload[currentVal];
          if (currentVal === "Lapsed") {
            pr = data.payload.lapsedPr;
          } else if (currentVal === "Submitted") {
            pr = data.payload.submittedPr;
          } else if (currentVal === "Reviewed by Manager") {
            pr = data.payload.reviewedPr;
          }
        }
      });
    }

    if (active) {
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper">
              <p className="recharts-tooltip-label">{currentVal}</p>
              {checkinCount && <span className="recharts-tooltip-value">{`${checkinCount} OKR Progress`}</span>}
              {pr && <span className="recharts-tooltip-value w-100 d-inline-block">{`${pr.toFixed(2)}%`}</span>}
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomizedTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={-5} fill="#666" className="customized-axis-tick-text" textAnchor="end">
          {payload.value.split("##")[0]}
        </text>
        <text x={0} y={0} dy={8} fill="#666" className="customized-axis-tick-text" textAnchor="end">
          Cycle
        </text>
        <text x={0} y={0} dy={20} fill="#666" className="customized-axis-tick-text-span" textAnchor="end">
          {payload.value.split("##")[1]}
        </text>
      </g>
    );
  };

  return (
    <div className={["graph-item", style.teams_weekly_chart].join(" ")}>
      <div className="graph-heading">Team members cycle OKR Progress Status</div>
      <div style={{ width: "100%", height: "auto" }}>
        <ResponsiveContainer width="100%" aspect={4.0 / 2} height="auto">
          <BarChart data={checkinGraphData} layout="vertical" barSize={8} barGap={4}>
            <XAxis type="number">
              <Label value="Team member" offset={0} position="insideBottomRight" />
            </XAxis>
            <CartesianGrid strokeDasharray="6 3" vertical={false} horizontalPoints={[74]} />
            <YAxis
              dataKey="name"
              reversed
              type="category"
              axisLine={false}
              label=""
              tick={<CustomizedTick />}
              width={105}
            >
              <Label value="Cycle status" offset={0} position="insideTopLeft" />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="Submitted"
              stackId="a"
              fill={COLORS["Submitted"]}
              onMouseOver={() => {
                setCurrentVal("Submitted");
              }}
              shape={(props) => {
                if (!props.payload.Lapsed) {
                  props.radius = 20;
                } else props.radius = [20, 0, 0, 20];
                return <Rectangle {...props} />;
              }}
              onMouseLeave={() => setCurrentVal(null)}
            ></Bar>
            <Bar
              dataKey="Lapsed"
              fill={COLORS["Lapsed"]}
              stackId="a"
              onMouseOver={() => setCurrentVal("Lapsed")}
              onMouseLeave={() => setCurrentVal(null)}
              shape={(props) => {
                if (!props.payload.Submitted) {
                  props.radius = 20;
                } else props.radius = [0, 20, 20, 0];
                return <Rectangle {...props} />;
              }}
            ></Bar>
            <Bar
              dataKey="Reviewed by Manager"
              fill={COLORS["Reviewed by Manager"]}
              onMouseOver={() => setCurrentVal("Reviewed by Manager")}
              onMouseLeave={() => setCurrentVal(null)}
              radius={20}
            ></Bar>
          </BarChart>
        </ResponsiveContainer>
        <div className={style.legend_wrapper}>
          <ul className={style.legend_list} data-test="lenegd_list">
            {legendData.map((entry, index) => {
              return (
                <li className={style.legend_item} key={index}>
                  <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                  {entry["name"]}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TeamsWeeklyCheckInChart;
