import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/index";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import NoRecordFound from "components/NodataFound/NoRecordFound";
import { MonthlyCheckinTexts } from "const/constValue";
import { IMG_WAITING_FOR_SUBMISSION } from "const/imgCost";
import ManagerCheckInFeedback from "./MonthlyCheckInFeedback/MonthlyCheckInFeedback";
import ManagerPastCheckins from "./PastCheckins/PastCheckins";

export default function ManagerViewMonthlyCheckin() {
  const loggedInUser = storage.getUser();
  const dispatch = useDispatch();
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [employeesProfiles, setEmployeesProfiles] = useState([]);
  const [recentCheckIns, setRecentCheckIns] = useState([]);
  const [checkedEmployee, setCheckedEmployee] = useState([]);

  const {
    employeeManagerCheckinFeedbackDTO,
    isLoading,
    showYearListData,
    currentFeedbackDataManagerMessage,
    feedbackHistoryManager,
    totalCount,
    historyDetails,
  } = useSelector((state) => state.monthlyCheckinFeedback);

  useEffect(() => {
    dispatch(actions.getCurrentfeedbackManager());
    dispatch(actions.showYearList());
  }, []);

  useEffect(() => {
    if (employeeManagerCheckinFeedbackDTO.length > 0) {

      const validEmployees = employeeManagerCheckinFeedbackDTO?.filter((i) => i.managerStatus !== "COMPLETED" && i.submittedDateManager === null);

      const selectedAnswer = validEmployees?.filter((i) => i.questionAnswerListManager[0].answer !== null);
      const unSelectedAnswer = validEmployees?.filter((i) => i.questionAnswerListManager[0].answer === null);
      // to questions which does not have answer which is not null
      setFeedbackQuestions(selectedAnswer?.length > 0 ? selectedAnswer[0]?.questionAnswerListManager : unSelectedAnswer[0]?.questionAnswerListManager);

      let checkedEmployeeId = selectedAnswer?.map((checkIn) => { return checkIn.employeeProfile });
      checkedEmployeeId = [...new Map(checkedEmployeeId?.map(profile => [profile["employeeId"], profile.employeeId])).values()];
      // set unique employee which previously selected as getting answer not null from data
      setCheckedEmployee(checkedEmployeeId);
      
      const profiles = validEmployees?.map((checkIn) => {
        return {
          ...checkIn.employeeProfile,
          managerComment: checkIn.managerComment,
        }
      });
      const uniqueProfilesOfSelected = [...new Map(profiles?.map(profile => [profile["employeeId"], profile])).values()];
      // set feedback data which is pending for the submission and it sorted with unique by employee id
      setEmployeesProfiles(uniqueProfilesOfSelected);

      let recentCheckIns = employeeManagerCheckinFeedbackDTO?.filter((i) => i.managerStatus === "COMPLETED" && i.submittedDateManager !== null);
      recentCheckIns = [...new Map(recentCheckIns?.map(profile => [profile.employeeProfile["employeeId"], profile])).values()];
      // set feedback data which is completed the submission and it sorted with unique by employee id
      setRecentCheckIns(recentCheckIns);
    }
  }, [employeeManagerCheckinFeedbackDTO]);

  const handleChangeResult = (payload, isClear) => {
    dispatch(actions.feedbackInteractionEmployeeAndManager(payload, "manager", isClear));
  }

  const handleSubmitFeedback = (payload) => {
    dispatch(actions.submitFeedbackForEmployeeandManager(payload, "manager"));
  }

  const getFeedbackHistoryData = (body) => {
    dispatch(actions.getFeedbackHistoryManager(body, loggedInUser?.parentGroupId, loggedInUser?.subGroupId, loggedInUser?.employeeId));
  }

  const getHistoryDetails = (period, employeeData) => {
    dispatch(
      actions.getHistoryDetailsEmployeeAndManager(
        employeeData?.employeeId,
        employeeData?.parentGroupId,
        employeeData?.subGroupId,
        period,
        "manager"
      )
    );
  }

  return (
    <>
      {isLoading && <Spinner />}
      {employeeManagerCheckinFeedbackDTO.length === 0 &&
        currentFeedbackDataManagerMessage === "Record Not Found" ? (
        <div className="box">
          <NoRecordFound
            image={IMG_WAITING_FOR_SUBMISSION}
            title={MonthlyCheckinTexts.MONTHLY_CHECK_IN_COMPLETED_TITLE}
            description={MonthlyCheckinTexts.MONTHLY_CHECK_IN_COMPLETED_NOTE}
            folderWrapper={true}
          />
          <div className="gap80"></div>
        </div>
      ) : (
        employeeManagerCheckinFeedbackDTO.length > 0 && (
          <ManagerCheckInFeedback
            handleChangeResult={handleChangeResult}
            handleSubmitFeedback={handleSubmitFeedback}
            period={employeeManagerCheckinFeedbackDTO[0]?.period}
            feedbackQuestions={feedbackQuestions}
            employeesProfiles={employeesProfiles}
            recentCheckIns={recentCheckIns}
            checkedEmployee={checkedEmployee}
          />
        )
      )}
      <ManagerPastCheckins
        showYearListData={showYearListData}
        getFeedbackHistoryData={getFeedbackHistoryData}
        feedbackHistoryManager={feedbackHistoryManager}
        totalCount={totalCount}
        getHistoryDetails={getHistoryDetails}
        historyDetails={historyDetails}
      />
    </>
  );
}
