import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "../CycleMemberProfile.module.scss";
import Select from "react-select";
import { Form } from "react-bootstrap";
import OptionalComment from "../QuestionAnswer/OptionalComment";

function DropDownQuestion(props) {
  const {
    errors,
    dropDownOtherHandlerTemplate,
    uploadFile,
    downloadFile,
    deleteFile,
    dropDownCommentHandlerTemplate,
    isSubmitted,
    item,
    dropDownHandlerTemplate,
    isSurvey,
  } = props;
  const [selectedOption, setSelectedOption] = useState(
    item.otherTextAnswer ? { value: "OTHER99", label: "Others (please specify)" } : ""
  );
  const [open, setOpen] = useState(item.otherTextAnswer ? true : false);

  let error = errors?.find((v) => v.qId === item.questionId && v.error && v.isSubmit);
  let commentError = errors?.find((v) => v.qId === item.questionId && v.commentBoxError && v.isSubmit);

  const handleChange = (select) => {
    setSelectedOption(select);
    if (select.value === "OTHER99") {
      setOpen(true);
    } else {
      dropDownHandlerTemplate(select, item);
      setOpen(false);
    }
  };

  const options = [];
  let select = "";
  if (item.options.length > 0) {
    item.options.map((v) => {
      const obj = {
        value: v.optionId,
        label: v.option,
      };
      if (v.isSelected) {
        select = {
          value: v.optionId,
          label: v.option,
        };
      }
      options.push(obj);
      return v;
    });
  }
  if (item.isOther) {
    options.push({ value: "OTHER99", label: "Others (please specify)" });
  }

  return (
    <>
      <div className="radio-wrapper">
        <Form.Group className={[style.cb_pv_wrapper].join(" ")} controlId="">
          {item.options && (
            <Select
              isDisabled={isSubmitted}
              options={options}
              className={["custom-default-dd-box", "question-drop-down", error && "limit-reached"].join(" ")}
              classNamePrefix="select"
              onChange={(select) => handleChange(select)}
              placeholder="Select"
              value={selectedOption || select}
            />
          )}

          {open &&
            (isSubmitted ? (
              <span className="w-100 d-block mt-2 word-break">{item.otherTextAnswer}</span>
            ) : (
              <div className="textarea-wrapper">
                <Form.Control
                  as="textarea"
                  className="mt-3"
                  placeholder="Add a new answer"
                  maxLength="500"
                  defaultValue={item.otherTextAnswer}
                  onBlur={(e) => dropDownOtherHandlerTemplate(e, item)}
                />
              </div>
            ))}
          {item.optionalCommentBox && (
            <OptionalComment
              item={item}
              uploadFile={(e) => uploadFile(e, item.questionId)}
              commentError={commentError ? true : false}
              handleComment={(e) => dropDownCommentHandlerTemplate(e, item)}
              isSubmitted={isSubmitted}
              downloadFile={downloadFile}
              commentPlaceHolder={
                item.commentResponse === "Required" ? "Add a new answer (Required)" : "Add a new answer (Optional)"
              }
              deleteFile={deleteFile}
              isSurvey={isSurvey}
            />
          )}
        </Form.Group>
      </div>
    </>
  );
}

DropDownQuestion.propTypes = {
  item: PropTypes.object,
};

export default DropDownQuestion;
