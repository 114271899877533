import React from "react";
import { toFixedNumber } from "../../util/utils";
import style from "./CardSideValue.module.scss";

function CardSideValue({ title, name, rating, status, sideView }) {
  return (
    <div className={[style.right_side_value_wrapper, rating ? style.margin_cover : ""].join(" ")}>
      <div className={style.title_text}>
        {title}
        <span className={style.text}>{name}</span>
      </div>
      {sideView && (
        <div className={style.manager_rating_wrapper}>
          {rating && (
            <div className={style.rating_info}>
              Rating : <span className={style.text}>{toFixedNumber(rating, 2)}</span>
            </div>
          )}
          <div className={style.status_info}>
            Status: <span className={style.text}>{status}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardSideValue;
