import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import style from "./CycleMemberProfile.module.scss";
import * as actions from "store/actions/index";
import { moduleView, toFixedNumber, useQuery } from "util/utils";
import { errorToast, maximumFileSize, maximumFiveFile, requiredFieldMissing, successToast } from "util/general";
import storage from "util/storage";
import withLink from "hoc/withLink/withLink";
import Spinner from "shared/Spinner/Spinner";
import BoxHeading from "shared/Box/BoxHeading";
import UserProfile from "../../user_profile/user_profile";
import ReviewerProfile from "../../reviewer-profile/reviewer-profile";
import Rewards from "../../Rewards/Rewards";
import MemberProfileObjective from "./MemberProfileObjecive/MemberProfileObjecive";
import SidebarCheckInActivity from "../../sidebar_checkins/sidebar_checkins";
import QuestionTemplate from "./QuestionTemplate/QuestionTemplate";
import SidebarWinsChallenges from "../../SidebarWinsChallenges/SidebarWinsChallenges";
import SubmitBox from "../../SubmitBox/SubmitBox";
import SubmittedDateBox from "../../SubmitBox/SubmittedDateBox";
import GlobalReadOnlyComment from "../GlobalComment/GlobalReadOnlyComment";
import GradeInfoCard from "../../GradeInfoCard/GradeInfoCard";
import GlobalComment from "../GlobalComment/GlobalComment";
import Assessment from "./SelfAssessmentRating/Assessment";
import config from "util/config";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

const UserProfileWithLink = withLink(UserProfile);
export default function ReportingMemberProfile(props) {
  const loggedInUser = storage.getUser();
  const loggedInUserEmpId = loggedInUser?.employeeId;
  const cycleId = props.match.params.id;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const managerIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  const dispatch = useDispatch();
  const [reportingAssessmentError, setReportingAssessmentError] = useState([]);
  const history = useHistory();
  const {
    getPrivateAssessmentData,
    managerList,
    isFileLoading,
    isPerformanceResponseLoading,
    isSubmitted,
    fileResponse,
    updatedDate,
    submittedDate,
    paSubmitResponse,
    isPrivateAssessmentLoading,
    questionTemplateErrors,
    privateAssessmentObjectiveErrors,
    allManagerStatus,
    managerWiseGrade,
    allManagerDetails,
    globalRPMComment,
    globalSELFComment,
    quaterlyCycleData,
    globalFeedbackFileResponse,
    globalSELFCommentUserData,
  } = useSelector((state) => state.performance);
  const { winResponse, challengeResponse } = useSelector((state) => state.winChallenge);
  const { user } = useSelector((state) => state.myTeam);
  const { filterSurveyData } = useSelector((state) => state.myHistory);
  const count = useSelector((state) => state.madeMyDay.appreciateCount);
  const [ratingErrors, setRatingErrors] = useState([]);
  const subMenuAvailable = moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.REPORTING_ASSESSMENT);

  useEffect(() => { dispatch(actions.getQuarterlyCycle(cycleId)); }, [dispatch, cycleId]);

  useEffect(() => {
    if (managerList && managerList.length > 0) {
      let assessmentCommentErrors = [];
      managerList.map((manager) => {
        if (
          manager.employeeId === loggedInUserEmpId ||
          manager.objectives.find((v) => v.updatedByManager?.employeeId === loggedInUserEmpId)
        ) {
          manager.objectives
            .filter((value) => value?.weightage !== null && value.status !== "COMPLETED" && !value.reportingAssessmentComment?.comment)
            .forEach((value) =>
              assessmentCommentErrors.push({
                comment: "",
                commentId: value.userObjectiveId,
              })
            );
        }
        return manager;
      });
      setReportingAssessmentError(assessmentCommentErrors);
    }
    if (getPrivateAssessmentData?.selfQuestionTemplate) {
      let assessmentRatingErrors = [];
      if (getPrivateAssessmentData?.selfQuestionTemplate?.questions.find((value) => value.type === "SCALED")) {
        getPrivateAssessmentData.selfQuestionTemplate.questions.map((data) => {
          if (data.respondToAll && data.type === "SCALED" && data.notShowScaleToEmployee) {
            data.ratingCommentByRpm &&
              data.ratingCommentByRpm
                .filter((value) => value.employeeId === loggedInUserEmpId)
                .map((rating) => {
                  if (!rating.scaleValue) {
                    assessmentRatingErrors.push({
                      queId: data.questionId,
                      error: true,
                      type: "RATING",
                    });
                  }
                  if (!rating.comment && data.commentResponse === "Required") {
                    assessmentRatingErrors.push({
                      queId: data.questionId,
                      error: true,
                      type: "COMMENT",
                    });
                  }
                  return rating;
                });
          }
          return data;
        });
        setRatingErrors(assessmentRatingErrors);
      }
    }
  }, [getPrivateAssessmentData, dispatch, loggedInUserEmpId, managerList]);

  useEffect(() => {
    dispatch({ type: "CLEAR_MY_TEAM_DATA" });
    dispatch(actions.GetRPMAssessmentGlobalComment(employeeIds, cycleId));
    dispatch(actions.GetSelfAssessmentGlobalComment(employeeIds, cycleId));
    dispatch(actions.FetchWinChallenge(employeeIds, managerIds, 3, "WIN"));
    dispatch(actions.FetchWinChallenge(employeeIds, managerIds, 3, "CHALLENGE"));
    dispatch(actions.GetUser(employeeIds));
    dispatch(actions.GetAppreciateDurationCount(employeeIds, 6));
    dispatch(actions.GetFilterSurveyHistory({ ...employeeIds, filterByMonth: 12 }));
    dispatch(actions.GetPrivateAssessmentData(employeeIds, cycleId, "REPORTING", managerIds));
  }, [dispatch, cycleId]);
  const handleAppreciateDuration = (select) => dispatch(actions.GetAppreciateDurationCount(employeeIds, select.value));

  // let questionsAnswer = getPrivateAssessmentData && getPrivateAssessmentData.questionAnswer;
  const questionsAnswerTemplate = getPrivateAssessmentData?.rpmQuestionTemplate;
  const privateAssessmentId = getPrivateAssessmentData?.privateAssessmentId;
  const questionTemplateId = questionsAnswerTemplate?.questionTemplateId;
  const selfQuestionTemplate = getPrivateAssessmentData?.selfQuestionTemplate;
  const selfQuestionTemplateId = selfQuestionTemplate?.questionTemplateId;
  const selfPrivateAssessmentId = getPrivateAssessmentData?.selfPrivateAssessmentId;
  const gradeOrRatingOrBoth = getPrivateAssessmentData?.gradeOrRatingOrBoth;
  const isHideGradeInfoCard = getPrivateAssessmentData?.showRatingDropDown;
  let selfAssessmentGrade = getPrivateAssessmentData?.selfAssessmentGrade;
  let overAllAggregateText = "Aggregate Grade";
  if (gradeOrRatingOrBoth === "BOTH") {
    selfAssessmentGrade = getPrivateAssessmentData?.selfAssessmentGrade
      ? getPrivateAssessmentData?.selfAssessmentGrade +
        "/" +
        toFixedNumber(getPrivateAssessmentData.selfAssessmentAvgRating, 2)
      : "";
    overAllAggregateText = "Aggregate Progress";
  } else if (gradeOrRatingOrBoth === "RATING") {
    selfAssessmentGrade = toFixedNumber(getPrivateAssessmentData.selfAssessmentAvgRating, 2);
    overAllAggregateText = "Aggregate Rating";
  }
  const employeeComment =
    globalRPMComment?.length > 0 && globalRPMComment?.find((value) => value.user?.employeeId === loggedInUserEmpId);
  const otherComment = 
    globalRPMComment?.length > 0 && globalRPMComment?.filter((value) => value.user?.employeeId !== loggedInUserEmpId);
    const selAssessmentRPMGrade =
    getPrivateAssessmentData?.autoSaveGrade || getPrivateAssessmentData?.autoSaveRating
      ? [
          {
            employeeId: loggedInUser?.employeeId,
            empName: loggedInUser?.displayName,
            grade:
              gradeOrRatingOrBoth === "BOTH"
                ? getPrivateAssessmentData?.autoSaveGrade +
                  "/" +
                  toFixedNumber(getPrivateAssessmentData?.autoSaveRating, 2)
                : gradeOrRatingOrBoth === "RATING"
                ? toFixedNumber(getPrivateAssessmentData?.autoSaveRating, 2)
                : getPrivateAssessmentData?.autoSaveGrade,
            type: "RPM",
          },
        ]
      : getPrivateAssessmentData?.rpmRatings?.map((manager) => {
          return {
            ...manager,
            grade:
              gradeOrRatingOrBoth === "BOTH"
                ? manager.grade
                  ? manager.grade + "/" + toFixedNumber(manager?.rating, 2)
                  : ""
                : gradeOrRatingOrBoth === "RATING"
                ? toFixedNumber(manager?.rating, 2)
                : manager.grade,
            type: "RPM",
          };
        }) || [];

  const saveAnswersHandler = () => {
    dispatch({
      type: "SET_QUESTION_TEMPLATE_VALIDATION",
      data: questionTemplateErrors,
    });
    dispatch({
      type: "SET_PRIVATE_ASSESSMENT_OBJECTIVE_VALIDATION",
      data: privateAssessmentObjectiveErrors,
    });
    let setError = reportingAssessmentError.map((value) => {
      return {
        ...value,
        isSubmit: true,
      };
    });
    let setRating = ratingErrors.map((value) => {
      return {
        ...value,
        isSubmit: true,
      };
    });
    setRatingErrors(setRating);
    setReportingAssessmentError(setError);
    if (
      privateAssessmentObjectiveErrors.length > 0 &&
      privateAssessmentObjectiveErrors.filter((value) => value.objectiveId).length > 0 &&
      quaterlyCycleData?.showRatingDropDown /* checking is validation should apply or not according to flag */
    ) {
      errorToast("Please select objective rating");
    } else if (reportingAssessmentError.findIndex((value) => value.comment === "") !== -1) {
      requiredFieldMissing();
      return;
    } else if (
      questionTemplateErrors?.type === "REPORTING" &&
      questionTemplateErrors?.errors?.length > 0 &&
      questionTemplateErrors.errors.filter((value) => value.error || value.commentBoxError).length > 0
    ) {
      requiredFieldMissing();
      return;
    } else if (ratingErrors?.length > 0 && quaterlyCycleData?.showRatingDropDown && ratingErrors.find((value) => value.error && value.type === "RATING")) {
      errorToast("Select Self Assessment Rating");
    } else if (ratingErrors?.length > 0 && ratingErrors.find((value) => value.type === "COMMENT" && value.error)) {
      errorToast("Comment is required");
    } else dispatch(actions.SavePrivateAssessmentAnswers(privateAssessmentId, employeeIds, "REPORTING"));
  };

  //response useEffect
  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);

      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
    if (paSubmitResponse) {
      if (paSubmitResponse.success) {
        successToast(paSubmitResponse.message);
        const previousState = storage.get("assesmentCurrentData");
        if (previousState) {
          storage.remove("assesmentCurrentData");
          history.push(`${routePath.REPORTING_ASSESSMENT}/${cycleId}`, {
            data: JSON.parse(previousState),
          });
        } else {
          history.push(`${routePath.REPORTING_ASSESSMENT}/${cycleId}`);
        }
      } else errorToast(paSubmitResponse.message);

      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
  }, [fileResponse, paSubmitResponse, dispatch, cycleId, history]);

  //PMS US
  let questionTemplates = [];
  if (questionsAnswerTemplate || selfQuestionTemplate) {
    if (questionsAnswerTemplate) {
      questionsAnswerTemplate.name = "Reporting Assessment";
      questionsAnswerTemplate.nameDOM = "Reporting Assessment";
      questionsAnswerTemplate.type = "REPORTING";
      questionsAnswerTemplate.privateAssessmentId = privateAssessmentId;
    }
    if (selfQuestionTemplate) {
      //if HR select respondToManager or respondToAll then manager can see this question
      selfQuestionTemplate.questions = selfQuestionTemplate.questions.filter(
        (v) => v.respondToManager || v.respondToAll
      );
      selfQuestionTemplate.name = "Self Assessment";
      selfQuestionTemplate.nameDOM = <div>Self Assessment</div>;
      selfQuestionTemplate.privateAssessmentId = selfPrivateAssessmentId;
      selfQuestionTemplate.type = "SELF";
    }
    selfQuestionTemplate && questionTemplates.push(selfQuestionTemplate);
    questionsAnswerTemplate && questionTemplates.push(questionsAnswerTemplate);
  }

  const sendManagerComment = (comment, item) => {
    let error = ratingErrors.map((value) => {
      if (value.queId === item.questionId) {
        value.error = false;
      }
      return value;
    });
    setRatingErrors(error);
    let data;
    data = {
      questionId: item.questionId,
      managerComment: comment.trim(),
    };
    dispatch(
      actions.AutoSavePrivateAssessmentTemplate(
        data,
        selfPrivateAssessmentId,
        selfQuestionTemplateId,
        "SELF",
        employeeIds,
        cycleId,
        managerIds,
        "REPORTING"
      )
    );
    successToast("Save Comment");
  };

  const sendRating = (rating, item) => {
    let error = ratingErrors.map((value) => {
      if (value.queId === item.questionId) {
        value.error = false;
      }
      return value;
    });
    setRatingErrors(error);
    let data = item;
    data = {
      questionId: item.questionId,
      scaleValue: rating.value,
    };
    // data.rating
    dispatch(
      actions.AutoSavePrivateAssessmentTemplate(
        data,
        selfPrivateAssessmentId,
        selfQuestionTemplateId,
        "SELF",
        employeeIds,
        cycleId,
        managerIds,
        "REPORTING"
      )
    );
  };

  const downloadFile = (fileId, questionId, questionTemplateId, privateAssessmentId, type) => {
    let params = {
      questionId: questionId,
      privateAssessmentId,
      fileId,
      questionTemplateId,
      assessmentType: type,
    };
    dispatch(actions.DownloadFilePrivateAssessment(params));
  };

  const uploadFile = (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("file", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("questionId", id);
      formData.append("privateAssessmentId", privateAssessmentId);
      formData.append("questionTemplateId", questionTemplateId);
      formData.append("assessmentType", "REPORTING");
      dispatch(actions.UploadFilePrivateAssessment(formData, employeeIds, cycleId, "REPORTING"));
    } else maximumFiveFile();
  };

  const fileUploadByManager = (e, id, queTemplateId, paId, type) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("file", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("questionId", id);
      formData.append("isRPM", true);
      formData.append("privateAssessmentId", paId);
      formData.append("questionTemplateId", queTemplateId);
      formData.append("assessmentType", type);
      dispatch(actions.UploadFilePrivateAssessment(formData, employeeIds, cycleId, "REPORTING"));
    } else maximumFiveFile();
  };

  useEffect(() => {
    if (globalFeedbackFileResponse) {
      if (globalFeedbackFileResponse.success) successToast(globalFeedbackFileResponse.message);
      else errorToast(globalFeedbackFileResponse.message);
    }
  }, [globalFeedbackFileResponse]);

  const UploadFileGlobalFeedback = (e, files) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5 && files?.length + i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("files", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("assessmentType", "REPORTING");
      formData.append("quarterlyCycleId", cycleId);
      formData.append("privateAssessmentId", privateAssessmentId);
      dispatch(actions.uploadFileGlobalFeedback(formData, employeeIds, cycleId, "REPORTING"));
    } else maximumFiveFile();
  };

  const downloadFileGlobalFeedback = (id,privateAssessment_Id) => {
    const params = {
      fileId: id,
      privateAssessmentId: privateAssessment_Id !== undefined ? privateAssessment_Id : privateAssessmentId ,
      // privateAssessmentId: globalRPMComment.filter((v) => v.status === "submit").length > 0 ? privateAssessmentId : privateAssessment_Id,
    };
    dispatch(actions.DownloadFileGlobalFeedback(params));
  };

  const deleteFileGlobalFeedback = (id) => {
    const data = {
      fileId: id,
      privateAssessmentId: privateAssessmentId,
    };
    dispatch(actions.DeleteFileGlobalFeedback(data, employeeIds, cycleId, "REPORTING"));
  };

  const deleteFile = (fileId, queId, queTemplateId, paId, type) => {
    const data = {
      questionId: queId,
      fileId,
      privateAssessmentId: paId,
      assessmentType: type,
      isRPM: type === "SELF" ? true : undefined,
    };
    dispatch(actions.DeleteFilePrivateAssessment({ data: data }, employeeIds, cycleId, "REPORTING"));
  };

  const saveQuestionAnswer = (data) =>
    dispatch(actions.AutoSavePrivateAssessmentTemplate(data, privateAssessmentId, questionTemplateId, "REPORTING"));

  const sendGlobalComment = (e) => {
    if (e.target.value?.trim())
      dispatch(
        actions.SetAssessmentGlobalComment(
          e.target.value.trim(),
          privateAssessmentId,
          cycleId,
          employeeIds,
          "REPORTING"
        )
      );
  };

  let reviewers = null;
  if (getPrivateAssessmentData?.allRM || getPrivateAssessmentData?.allRVM) {
    let allManager = (getPrivateAssessmentData?.allRM || []).concat(getPrivateAssessmentData?.allRVM || []);
    // eslint-disable-next-line no-undef
    const uniqueManager = Array.from(new Set(allManager.map((a) => a.employeeId))).map((id) => {
      return allManager.find((a) => a.employeeId === id);
    });
    reviewers = {
      managersList: uniqueManager,
    };
  }
  let winsChallengesPath = `/okrprogress/my-team/member-okrprogress/dashboard/${employeeIds.employeeId}${config.employeeIdSplit}${employeeIds.parentGroupId}${config.employeeIdSplit}${employeeIds.subGroupId}`;

  let winsAndChallenges = [];
  if (winResponse || challengeResponse) {
    if (winResponse?.length > 0 && challengeResponse?.length > 0) {
      winsAndChallenges = winResponse.concat(challengeResponse);
    } else if (winResponse?.length > 0) {
      winsAndChallenges = winResponse;
    } else if (challengeResponse?.length > 0) {
      winsAndChallenges = challengeResponse;
    }
  }

  if (isPrivateAssessmentLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.cycleTeamWrapper}>
      <SubNavigation type="Performance" />
      {(isFileLoading || isPerformanceResponseLoading) && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Performance</h1>
            <span className="st-breadcrumb">
              {subMenuAvailable ? "Assessment" : <Link to="/performance/dashboard">Dashboard</Link>}
              {subMenuAvailable && (
                <span>
                  <Link to="/performance/reporting-assessment">Reporting Assessment</Link>
                </span>
              )}
              <span>
                {/* changed url because https://jira.infostretch.com/browse/EN-8002 */}
              {/* <Link to={`/performance/reporting-assessment/${cycleId}`}>{getPrivateAssessmentData.cycle}</Link> */}
                <Link to={"/performance/dashboard"}>{getPrivateAssessmentData.cycle}</Link>
              </span>
              <span>{user && user.displayName}</span>
            </span>
          </Col>
          {getPrivateAssessmentData?.cycle && (
            <Col className="col-12">
              <BoxHeading heading={getPrivateAssessmentData.cycle} description={getPrivateAssessmentData.period} />
            </Col>
          )}
        </Row>
        {gradeOrRatingOrBoth !== "RATING" && isHideGradeInfoCard && <GradeInfoCard title="Performance Grade" />}
        <Row>
          <Col lg={8}>
            {user && <UserProfileWithLink user={user} />}
            {getPrivateAssessmentData?.objectiveTracking === null && selfQuestionTemplate === null && (
              <div className="box pb-0">
                <div className="box-heading-wrapper border-bottom-0">
                  <div className="box-heading">
                    <h2>
                      Aggregate Grade/Rating -{" "}
                      {gradeOrRatingOrBoth === "BOTH"
                        ? getPrivateAssessmentData?.selfAssessmentGrade /
                          getPrivateAssessmentData?.selfAssessmentAvgRating
                        : gradeOrRatingOrBoth === "GRADE"
                        ? getPrivateAssessmentData?.selfAssessmentGrade
                        : getPrivateAssessmentData?.selfAssessmentAvgRating}{" "}
                    </h2>
                  </div>
                </div>
              </div>
            )}
            {getPrivateAssessmentData.objectiveTracking && (
              <MemberProfileObjective
                setAssessmentError={setReportingAssessmentError}
                assessmentError={reportingAssessmentError}
                questionTemplateErrors={questionTemplateErrors}
                reviewer={reviewers}
                isSubmitted={isSubmitted}
                isPage="REPORTING"
                employeeData={user}
                managerWiseGrade={managerWiseGrade}
                user={false}
                hrView={false}
                allManagerStatus={allManagerStatus}
                allManagerDetails={allManagerDetails}
                rowData={getPrivateAssessmentData}
                managerList={managerList}
                managerIds={managerIds}
              />
            )}
            {questionTemplates && questionTemplates.length > 0 ? (
              <Assessment
                selfAssessmentAvgGrade={selfAssessmentGrade}
                overAllAggregateText={overAllAggregateText}
                selfAssessmentManagerViseGrade={selAssessmentRPMGrade}
                isPage="REPORTING"
                ratingErrors={ratingErrors}
                saveQuestionAnswer={saveQuestionAnswer}
                questionTemplateErrors={questionTemplateErrors}
                isSubmitted={isSubmitted}
                isRatingDisable={!quaterlyCycleData?.showRatingDropDown}
                questionsAnswerTemplate={questionsAnswerTemplate}
                uploadFile={uploadFile}
                downloadFile={downloadFile}
                deleteFile={deleteFile}
                questionTemplates={questionTemplates}
                sendManagerComment={sendManagerComment}
                fileUploadByManager={fileUploadByManager}
                sendRating={sendRating}
              />
            ) : (
              questionsAnswerTemplate && (
                <QuestionTemplate
                  handleTextBox={saveQuestionAnswer}
                  handleRating={saveQuestionAnswer}
                  handleRatingComment={saveQuestionAnswer}
                  handleRadioComment={saveQuestionAnswer}
                  handleRadio={saveQuestionAnswer}
                  handleRadioOther={saveQuestionAnswer}
                  handleCheckBox={saveQuestionAnswer}
                  handleCheckBoxOther={saveQuestionAnswer}
                  handleCheckBoxComment={saveQuestionAnswer}
                  handleDropDown={saveQuestionAnswer}
                  handleDropDownComment={saveQuestionAnswer}
                  handleDropDownOther={saveQuestionAnswer}
                  handleScale={saveQuestionAnswer}
                  handleScaleComment={saveQuestionAnswer}
                  handleRankComment={saveQuestionAnswer}
                  handleRank={saveQuestionAnswer}
                  handleNps={saveQuestionAnswer}
                  handleNpsComment={saveQuestionAnswer}
                  questionTemplateErrors={questionTemplateErrors}
                  isSubmitted={isSubmitted}
                  questionsAnswerTemplate={questionsAnswerTemplate}
                  uploadFile={uploadFile}
                  viewTemplateName={true}
                  fileUploadByManager={fileUploadByManager}
                  downloadFile={(qId) => downloadFile(qId, questionTemplateId, privateAssessmentId, "REPORTING")}
                  deleteFile={deleteFile}
                />
              )
            )}
            {getPrivateAssessmentData?.selfAssessmentStatus?.status === "Submit" && (
              <GlobalReadOnlyComment
                heading="Global Feedback"
                description="Here is what your team member want to say to you"
                comment={globalSELFCommentUserData}
                message="Employee haven't shared any view"
                downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                  downloadFileGlobalFeedback(id, privateAssessmentId)
                }
              />
            )}
            {isSubmitted ? (
              <GlobalReadOnlyComment
                heading="Reporting Manager's Global Feedback"
                description="Here is what Reporting manager has given their views"
                comment={globalRPMComment?.length > 0 ? globalRPMComment.filter((v) => v.status === "Submit") : []}
                message="You haven't shared any view"
                downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                  downloadFileGlobalFeedback(id, privateAssessmentId)
                }
              />
            ) : (
              <GlobalComment
                heading="Reporting Manager's GlobalFeedback"
                optional={true}
                loggedInUser={loggedInUser}
                employeeComment={employeeComment}
                comments={otherComment?.length > 0 ? otherComment.filter((v) => v.status === "Submit") : []}
                // globalRPMCommentFiles={globalRPMComment}
                sendGlobalComment={sendGlobalComment}
                description="Here is what you want to say to team member"
                isMultipleFile={true}
                isRPM={true}
                // obj={globalRPMComment.length > 0 && globalRPMComment[0]}
                obj={globalRPMComment.length > 0 ? globalRPMComment.filter((v) => v.status === "SAVED") : []}
                UploadFileGlobalFeedback={(e) => UploadFileGlobalFeedback(e, globalRPMComment.filter((v) => v.user.employeeId === managerIds.employeeId && v.user.parentGroupId === managerIds.parentGroupId && v.user.subGroupId === managerIds.subGroupId)[0]?.files)}
                downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                  downloadFileGlobalFeedback(id, privateAssessmentId)
                }
                deleteFileGlobalFeedback={(id, privateAssessmentId) =>
                  deleteFileGlobalFeedback(id, privateAssessmentId)
                }
              />
            )}
            {isSubmitted ? (
              <SubmittedDateBox submittedDate={submittedDate} />
            ) : (
              <SubmitBox handleSubmit={saveAnswersHandler} updatedDate={updatedDate} name="Submit" />
            )}
          </Col>
          <Col lg={4}>
            {filterSurveyData && <SidebarCheckInActivity filterSurveyData={filterSurveyData} />}
            {winsAndChallenges && winsAndChallenges.length > 0 && (
              <SidebarWinsChallenges winsAndChallenges={winsAndChallenges} pathName={winsChallengesPath} />
            )}
            {count && <Rewards count={count} handleAppreciateDuration={handleAppreciateDuration} />}
            {reviewers?.managersList?.length > 0 && <ReviewerProfile managerList={reviewers.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
