import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import style from "./DeallocationMemberDetails.module.scss";
import * as actions from "store/actions/index";
import { SetObjectiveError } from "store/actions/deAllocation";
import { requiredFieldMissing, maximumFileSize, maximumFiveFile, successToast, errorToast } from "util/general";
import { useQuery } from "util/utils";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import UserProfile from "../../../user_profile/user_profile";
import DeallocationObjectiveTracking from "./DeallocationObjectiveTracking";
import QuestionTemplate from "../../CycleMemberProfile/QuestionTemplate/QuestionTemplate";
import SubmittedDateBox from "../../../SubmitBox/SubmittedDateBox";
import SubmitBox from "../../../SubmitBox/SubmitBox";
import FeedbackNotApplicableComment from "./FeedbackNotApplicableComment/FeedbackNotApplicableComment";
import ReviewerProfile from "../../../reviewer-profile/reviewer-profile";
import SidebarProjectDetails from "../../../SidebarProjectDetails/SidebarProjectDetails";
import ListViewModal from "../../../ListViewModal/ListViewModal";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import NoDataFound from "components/NodataFound/NoDataFound";
import routePath from "const/routePath";
import { errorMessage } from "const/constValue";

export default function DeallocationMemberDetails(props) {
  const projectDeAllocationId = props.match.params.id;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const history = useHistory();
  const loggedInUser = storage.getUser();
  const {
    empDeallocationData,
    objectives,
    isLoadingDetailscreen,
    deallocationResponse,
    isSubmitted,
    updatedDate,
    submitFeedbackLoader,
    isFileLoading,
    fileResponse,
  } = useSelector((state) => state.deAllocation);

  const dispatch = useDispatch(); // using dispatch method
  const { questionTemplateErrors } = useSelector((state) => state.performance);
  const { user } = useSelector((state) => state.myTeam); // get current user
  const { reviewer } = useSelector((state) => state.checkIn); // get current user
  const [objectiveLevelError, setObjectivesLevelError] = useState([]);
  const [viewProject, setViewProject] = useState(false);
  const [notApplicable, setNotApplicable] = useState(false);
  const [comment, setComment] = useState("");
  const questionsAnswerTemplate = empDeallocationData?.deallocationquestionTemplate;
  if (questionsAnswerTemplate) {
    questionsAnswerTemplate.type = "DEALLOCATION";
  }
  const submittedDate = empDeallocationData && empDeallocationData?.submittedDate;
  const employeeName = empDeallocationData?.empName || "";
  const isLargeName =
    empDeallocationData?.currentprojects?.length > 0 &&
    empDeallocationData.currentprojects.map((v) => v.projectName).join(", ").length > 250;

  const currentProjectName =
    empDeallocationData?.currentprojects?.length > 0
      ? isLargeName
        ? empDeallocationData.currentprojects
            .map((v) => v.projectName)
            .join(", ")
            .slice(0, 250) + "..."
        : empDeallocationData.currentprojects.map((v) => v.projectName).join(", ")
      : "NA";

  useEffect(() => {
    dispatch(actions.GetEmployeeDeallocationData(projectDeAllocationId));
    dispatch(actions.GetReviewer(employeeIds));
    dispatch(actions.GetUser(employeeIds)); //get current user
  }, [dispatch, projectDeAllocationId]);

  useEffect(() => {
    if (deallocationResponse) {
      if (deallocationResponse.success) {
        successToast(deallocationResponse.message);
        history.push(`/performance/project-deallocation`);
      }
    }
  }, [deallocationResponse, history]);

  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);

      dispatch({ type: "CLEAR_DEALLOCATION_FILE_RESPONSE" });
    }
  }, [fileResponse, dispatch]);

  const submitDeallocation = () => {
    let allError = [];
    dispatch({
      type: "SET_QUESTION_TEMPLATE_VALIDATION",
      data: questionTemplateErrors,
    });
    if (objectives && objectives.length > 0) {
      dispatch(SetObjectiveError(objectives));
      let obj = objectives?.filter((v) => v.status !== "COMPLETED");
      obj.map((v) => {
        if (!v.objfeedbackStatus) {
          allError.push({
            id: v.userObjectiveId,
            type: "SELECT_OPTION",
            isSubmit: true,
          });
        } else if (v.objfeedbackStatus === "INVALID" && !v.inValidateComment) {
          allError.push({
            id: v.userObjectiveId,
            type: "INVALID_COMMENT",
            isSubmit: true,
          });
        } else if (v.objfeedbackStatus === "COMPLETED") {
          if (v.definedBy === "MANAGER" || v.definedBy === "SERVICE_LINE") {
            v.keyPoints.map((point) => {
              if (!point.rating) {
                let error = {
                  keyId: point.keyId,
                  id: v.userObjectiveId,
                  type: "RATING",
                  isSubmit: true,
                  error: true,
                };
                allError.push(error);
              }
              if (!v.objectiveComment) {
                let error = {
                  id: v.userObjectiveId,
                  type: "OBJECTIVE_COMMENT",
                  isSubmit: true,
                  error: true,
                };
                allError.push(error);
              }
              return point;
            });
          } else if (v.definedBy === "UNIVERSITY") {
            if (!v.rating) {
              let error = {
                id: v.userObjectiveId,
                type: "RATING",
                isSubmit: true,
                error: true,
              };
              allError.push(error);
            }
            if (!v.objectiveComment) {
              let error = {
                id: v.userObjectiveId,
                type: "OBJECTIVE_COMMENT",
                isSubmit: true,
                error: true,
              };
              allError.push(error);
            }
          }
        }
        return v;
      });
      setObjectivesLevelError(allError);
    }
    if (
      allError?.length > 0 ||
      (questionTemplateErrors?.type === "DEALLOCATION" &&
        questionTemplateErrors?.errors?.length > 0 &&
        questionTemplateErrors.errors.filter((value) => value.error || value.commentBoxError).length > 0)
    ) {
      requiredFieldMissing();
    } else {
      if (objectives?.length > 0 && objectives.filter((v) => v.status !== "COMPLETED")?.length > 0) {
        let body = {
          userObjectives: objectives
            .filter((v) => v.status !== "COMPLETED")
            .map((v) => {
              return {
                userObjectiveId: v.userObjectiveId,
                objfeedbackStatus: v.objfeedbackStatus,
              };
            }),
          ...employeeIds,
        };
        dispatch(actions.ValidateObjectiveForDeallocation(body, "SUBMIT", projectDeAllocationId));
      } else {
        dispatch(
          actions.UpdateDeallocationFeedback({
            status: "SUBMIT",
            projectDeAllocationId,
          })
        );
      }
    }
  };

  const saveQuestionAnswer = (data) =>
    dispatch(
      actions.UpdateDeallocationFeedback({
        questions: data,
        projectDeAllocationId,
      })
    );

  const downloadFile = (fileId, questionId) => {
    let params = {
      fileId,
      questionId: questionId,
      projectDeAllocationId,
    };
    dispatch(actions.DownloadFileInDeallocation(params));
  };

  const uploadFile = (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    if (i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("file", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("questionId", id);
      formData.append("projectDeAllocationId", projectDeAllocationId);
      dispatch(actions.UploadFileInDeallocation(formData, projectDeAllocationId));
    } else maximumFiveFile();
  };

  const isProjectManager = empDeallocationData?.projectManager?.employeeId === loggedInUser.employeeId ? true : false;

  const deleteFile = (fileId, queId) => {
    const data = {
      fileId,
      questionId: queId,
      projectDeAllocationId,
    };
    dispatch(actions.DeleteFileInDeallocation({ data: data }, projectDeAllocationId));
  };

  const submitNotApplicable = () => {
    dispatch({
      type: "SET_QUESTION_TEMPLATE_VALIDATION",
      data: questionTemplateErrors,
    });
    if (comment?.trim()) {
      if (
        questionTemplateErrors?.type === "DEALLOCATION" &&
        questionTemplateErrors?.errors?.length > 0 &&
        questionTemplateErrors.errors.filter((value) => value.error || value.commentBoxError).length > 0
      ) {
        requiredFieldMissing();
        return;
      } else {
        //call API
        dispatch(
          actions.UpdateDeallocationFeedback({
            comment: comment.trim(),
            status: "INVALIDATE",
            projectDeAllocationId,
          })
        );
      }
    } else {
      errorToast(errorMessage.ADD_COMMENT);
    }
  };
  let displayNotApplicationForm = false;
  if (objectives.length > 0) {
    if (empDeallocationData?.status !== "SUBMIT" && isProjectManager) {
      displayNotApplicationForm = true;
    }
  }

  if (isLoadingDetailscreen) {
    return <Spinner />;
  }

  return (
    <div className={style.emp_detail_wrapper}>
      <SubNavigation type="Performance" />
      {(submitFeedbackLoader || isFileLoading) && <Spinner />}
      <div className={style.feedback_inner_wrapper}>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="page-title pb-0">Performance</h1>
              <span className="st-breadcrumb">
                Feedback
                <span>
                  <Link to={routePath.PROJECT_DEALLOCATION}>Deallocation Feedback</Link>
                </span>
                <span>{employeeName}</span>
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              {user && <UserProfile user={user} />}
              {!isProjectManager && empDeallocationData?.status !== "SUBMIT" && (
                <div className="box">
                  <NoDataFound description="Manager not submitted yet" />
                  <div className="gap120"></div>
                </div>
              )}
              {displayNotApplicationForm && (
                <FeedbackNotApplicableComment
                  isSubmitted={isSubmitted}
                  displayPicture={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                  displayName={loggedInUser?.displayName}
                  invalidateComment={empDeallocationData?.comment}
                  submittedDate={submittedDate}
                  dangerText="(Mandatory to fill Project Deallocation Assessment)"
                  textHeading={`Hi ${loggedInUser?.displayName}, If you have already submitted the previous feedback of ${employeeName} few weeks before and you don’t want to assess Objective again than you have an option to mark this feedback Not Applicable.`}
                  commentBoxHeadingText="Add Comment"
                  placeholder="Add a comment"
                  textLength={1000}
                  notApplicable={notApplicable}
                  setNotApplicable={setNotApplicable}
                  comment={comment}
                  setComment={setComment}
                />
              )}
              {((!isProjectManager && empDeallocationData?.status === "SUBMIT") || isProjectManager) && (
                <div className={style.emp_proj_list_wrapper}>
                  <div className={style.emp_proj_detail_Wrapper}>
                    {objectives?.length > 0 && !notApplicable && empDeallocationData.status !== "INVALIDATE" && (
                      <DeallocationObjectiveTracking
                        objectiveLevelError={objectiveLevelError}
                        isSubmitted={isSubmitted}
                        employeeId={employeeIds.employeeId}
                        employeeIds={employeeIds}
                        loggedInUser={loggedInUser}
                        objectives={objectives}
                        managersList={reviewer?.managersList}
                      />
                    )}
                    {questionsAnswerTemplate && (
                      <QuestionTemplate
                        handleTextBox={saveQuestionAnswer}
                        handleRating={saveQuestionAnswer}
                        handleRatingComment={saveQuestionAnswer}
                        handleRadioComment={saveQuestionAnswer}
                        handleRadio={saveQuestionAnswer}
                        handleRadioOther={saveQuestionAnswer}
                        handleCheckBox={saveQuestionAnswer}
                        handleCheckBoxOther={saveQuestionAnswer}
                        handleCheckBoxComment={saveQuestionAnswer}
                        handleDropDown={saveQuestionAnswer}
                        handleDropDownComment={saveQuestionAnswer}
                        handleDropDownOther={saveQuestionAnswer}
                        handleScale={saveQuestionAnswer}
                        handleScaleComment={saveQuestionAnswer}
                        handleRankComment={saveQuestionAnswer}
                        handleRank={saveQuestionAnswer}
                        handleNps={saveQuestionAnswer}
                        handleNpsComment={saveQuestionAnswer}
                        employeeId={employeeIds.employeeId}
                        questionTemplateErrors={questionTemplateErrors}
                        isSubmitted={isSubmitted}
                        questionsAnswerTemplate={questionsAnswerTemplate}
                        uploadFile={uploadFile}
                        viewTemplateName={true}
                        downloadFile={downloadFile}
                        deleteFile={deleteFile}
                      />
                    )}
                  </div>
                </div>
              )}
              {isSubmitted || !isProjectManager ? (
                <SubmittedDateBox submittedDate={submittedDate} />
              ) : (
                <SubmitBox
                  name="Submit"
                  handleSubmit={notApplicable ? submitNotApplicable : submitDeallocation}
                  updatedDate={updatedDate}
                />
              )}
            </Col>
            <Col lg={4}>
              <SidebarProjectDetails
                previousProject={
                  empDeallocationData?.projectName?.length > 0
                    ? empDeallocationData.projectName.map((v) => v.projectName).join(", ")
                    : "NA"
                }
                currentProject={currentProjectName}
                isLargeName={isLargeName}
                handleViewMore={() => setViewProject(true)}
              />
              {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer.managersList} />}
            </Col>
          </Row>
        </Container>
      </div>
      {empDeallocationData?.currentprojects?.length > 0 && viewProject && (
        <ListViewModal
          show={viewProject}
          hide={() => setViewProject(false)}
          title={"Project Lists"}
          lists={empDeallocationData.currentprojects.map((v) => v.projectName)}
        />
      )}
    </div>
  );
}
