import React /* useState */ from "react";
import { Col, Row } from "react-bootstrap";
import style from "./CheckinsSubmited.module.scss";
import { useSelector } from "react-redux";
import CheckInStreak from "../CheckInStreak/CheckInStreak";

export default function CheckinsSubmited() {
  const currentCheckIn = useSelector((state) => state.checkIn);
  const streak = useSelector((state) => state.checkIn.streak);
  const { reviewedBy } = useSelector((state) => state.questions);
  return (
    <Row>
      <Col md={12}>
        <div className={style.checkins_submited}>
          <Row>
            <Col>
              {currentCheckIn.status === "REVIEWED" ? (
                <p className="pagetitle_separator">
                  <span>Greetings!</span> {reviewedBy && reviewedBy.empName} has reviewed your OKR Progress{" "}
                </p>
              ) : (
                <p className="pagetitle_separator">
                  <span>Great Job!</span> Your OKR Progress has been submitted for review.
                </p>
              )}
              <div className="box p-0">
                <CheckInStreak
                  currentStreak={streak?.currentStreak}
                  yourBestStreak={streak?.yourBestStreak}
                  completionRate={streak?.completionRate}
                  orgBestStreak={streak?.orgBestStreak}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
