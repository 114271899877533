import React, { useState } from "react";

const SingleComponent = props => {
  const [toggle, setToggle] = useState(true);
  const toggleText = (id, comment) => {
    if (toggle) {
      document.getElementById("textArea " + id).children[0].innerHTML = comment;
      document.getElementById("toggleButton " + id).innerText = " (View Less)";
      setToggle(false);
    } else {
      document.getElementById(
        "textArea " + id
      ).children[0].innerHTML = comment.slice(0, 250);
      document.getElementById("toggleButton " + id).innerText = " (View More)";
      setToggle(true);
    }
  };
  return (
    <>
      <div id={["textArea", props.id].join(" ")}>
        <span>{props.comment.slice(0, 250)} </span>
        <span
            className="link"
            id={["toggleButton", props.id].join(" ")}
            onClick={() => toggleText(props.id, props.comment)}>
            (View More)
            </span>
      </div>
    </>
  );
};

export default SingleComponent;
