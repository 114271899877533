import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import style from "./CustomNotification.module.scss";
import * as actions from "../../store/actions/index";
import { dateFormat22 } from "../../util/dateUtils";
import NoDataFound from "../NodataFound/NoDataFound";
import Pagination from "../Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { filterArray } from "../../util/general";
import { noData } from "const/constValue";

function BotNotificationList() {
  const dispatch = useDispatch();
  const [listData, setListData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState();
  const [pageOfItems, setPageOfItems] = useState([]);
  const { botNotificationList } = useSelector((state) => state.admin);
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  useEffect(() => {
    dispatch(actions.getBotNotificationList());
  }, [dispatch]);

  useEffect(() => {
    if (botNotificationList?.length) {
      filteredData.length > 0
        ? setListData(filteredData)
        : searchFilter
        ? setListData(filteredData)
        : setListData(botNotificationList);
    } else {
      setListData([]);
      setPageOfItems([]);
    }
  }, [botNotificationList, filteredData, searchFilter]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    filteredData = filterArray(botNotificationList, filters);
    setFilteredData(filteredData);
    setSearchFilter("");
  };

  const handleTitleClick = (notificationBroadcastId) => {
    dispatch(actions.getBotNotificationEmployeeList(notificationBroadcastId));
  };

  return (
    <div className={style.bot_notification_wrapper}>
      <div className="box pb-0 mb-0 ">
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Bot Notification List</h2>
            <h3>View bot notification details</h3>
          </div>
        </div>
      </div>
      <div className="outer-table-wrapper">
        <div className="box-light-gradient"></div>
        <div className={[style.custom_table, "table-theme-1"].join(" ")}>
          {pageOfItems && pageOfItems.length > 0 ? (
            <Table hover responsive className={style.tab_table_responsive}>
              <thead>
                <tr>
                  <th>Notification Name</th>
                  <th>Created On</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItems.map((list) => (
                  <tr key={list.notificationBroadcastId}>
                    <td className={style.title}>
                      <span onClick={() => handleTitleClick(list.notificationBroadcastId)}>{list.title}</span>
                    </td>
                    <td className={style.date}>
                      <span>{dateFormat22(list.createdDate) ?? "-"}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>
              <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            </div>
          )}
        </div>
      </div>
      {listData && listData.length > 0 && (
        <Pagination pageSize={10} items={listData} resetData={resetData} onChangePage={onChangePage} />
      )}
    </div>
  );
}

export default BotNotificationList;
