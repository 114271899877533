import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Container, Col, Row } from "react-bootstrap";
import moment from "moment";
import style from "./EmployeesObjectives.module.scss";
import * as actions from "store/actions/index";
import { filterArray, filterOptions, pleaseSelectDate } from "util/general";
import { stringToDate1 } from "util/dateUtils";
import CommonDatePicker from "shared/CommonDatePicker";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";
import FilterTeam from "../../FilterTeam/FilterTeam";
import EmployeeRow from "./EmployeeRow/EmployeeRow";
import { noData } from "const/constValue";
import FilterBox from "shared/AllFilterBox/FilterBox";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

function EmployeesObjectives() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageOfItems, setPageOfItems] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(history?.location?.state || "");
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const pageSize = useSelector((state) => state.myTeam.pageSize);
  const { locationList } = useSelector((state) => state.performance);
  const { subGroupClient } = useSelector((state) => state.admin);
  const { isLoading, allEmployeeObjectiveData, objectiveFilterStartDate, objectiveFilterEndDate } = useSelector(
    (state) => state.hrDashboardReport
  );

  useEffect(() => {
    dispatch(actions.getSubGroupClient());
    dispatch(actions.GetLocationList());
  }, []);

  useEffect(() => {
    if (allEmployeeObjectiveData.length > 0) {
      filteredData.length > 0
        ? setTeamData(filteredData)
        : statusFilter || searchFilter
        ? setTeamData(filteredData)
        : setTeamData(allEmployeeObjectiveData);
    }
  }, [filteredData, allEmployeeObjectiveData, searchFilter, statusFilter]);

  useEffect(() => {
    if (history.location.state) {
      const query = new URLSearchParams(history.location.search);
      let sDate = query.get("startDate");
      let eDate = query.get("endDate");
      let filterSubGroups = query.get("subGroup");
      let isObjectiveAssigned = history.location.state;
      setStatusFilter(isObjectiveAssigned);
      if (sDate && eDate) {
        dispatch({
          type: "SET_EMPLOYEE_OBJECTIVE_FILTERED_DATE",
          payload: {
            objectiveFilterStartDate: new Date(stringToDate1(sDate)),
            objectiveFilterEndDate: new Date(stringToDate1(eDate)),
          },
        });
        dispatch(actions.getEmployeeObjectiveForHR(sDate, eDate, isObjectiveAssigned, { filterSubGroups }));
      }
    }
  }, [history.location.state]);

  useEffect(() => {
    if (allEmployeeObjectiveData.length === 0) {
      setTeamData([]);
      setSearchFilter(null);
      setFilteredData([]);
      setPageOfItems([]);
    } else if (allEmployeeObjectiveData.length > 0 && history.location.state) {
      setStatusFilter(history.location.state);
      handleStatusChange(history.location.state);
    }
  }, [allEmployeeObjectiveData]);

  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  const search = (value) => {
    let filteredData = null;
    const filters = {
      empName: value,
      assigned: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(allEmployeeObjectiveData, filters);
    setFilteredData(filteredData);
    setSearchFilter(value);
  };

  const handleStatusChange = (value) => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      assigned: value,
    };
    filteredData = filterArray(allEmployeeObjectiveData, filters);
    setStatusFilter(value);
    setFilteredData(filteredData);
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      assigned: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(allEmployeeObjectiveData, filters);

    setFilteredData(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter(searchFilter);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
      assigned: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(allEmployeeObjectiveData, filters);
    setFilteredData(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter("");
  };

  const handleFilter = (name, value) => {
    setStatusFilter("");
    dispatch(
      actions.getEmployeeObjectiveForHR(
        moment(objectiveFilterStartDate).format("MM-DD-YYYY"),
        moment(objectiveFilterEndDate).format("MM-DD-YYYY"),
        false,
        {
          ...filterValue,
          [name]: value,
        }
      )
    );
    setFilterValue({ ...filterValue, [name]: value });
  };

  const filter = {
    value: ["", "true", "false"],
    name: ["All", "Assigned", "Not Assigned"],
  };

  const handleDate = (type, date) => {
    dispatch({ type: "SET_EMPLOYEE_OBJECTIVE_FILTERED_DATE", payload: { [type]: date } });
  };

  const applyDateFilter = () => {
    if (objectiveFilterStartDate && objectiveFilterEndDate) {
      let sDate = moment(objectiveFilterStartDate).format("MM-DD-YYYY");
      let eDate = moment(objectiveFilterEndDate).format("MM-DD-YYYY");
      dispatch(actions.getEmployeeObjectiveForHR(sDate, eDate));
      setFilterValue();
    } else {
      pleaseSelectDate();
    }
  };

  return (
    <div>
      <SubNavigation type="Employee Objective" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">
              HR <span>Employees Objectives</span>
            </span>
          </Col>
        </Row>
        <div className={["box", style.date_range_wrapper].join(" ")}>
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>Select Date Range</h2>
            </div>
            <div className="date_picker_time_wrapper p-0">
              <div className="cl_t_wrapper">
                <div className="calendar_wrapper form-group date_range_calendar_wrapper ">
                  <CommonDatePicker
                    handleStartDate={(date) => handleDate("objectiveFilterStartDate", date)}
                    handleEndDate={(date) => handleDate("objectiveFilterEndDate", date)}
                    startDate={objectiveFilterStartDate && objectiveFilterStartDate}
                    endDate={objectiveFilterEndDate && objectiveFilterEndDate}
                    startDatePlaceHolder="Start Date"
                    endDatePlaceHolderText="End Date"
                  />
                  <button
                    type="button"
                    className="btn btn-secondary date-range-apply-btn ml-0"
                    onClick={applyDateFilter}
                  >
                    <strong>Apply</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(subGroupClient?.length > 1 || locationList?.length > 1) && (
          <div className={[style.box_btn_grp, "box"].join(" ")}>
            <FilterBox
              displaySubgroups={subGroupClient?.length > 1 ? true : false}
              subGroupClient={subGroupClient}
              filterSubGroups={filterValue?.filterSubGroups}
              handleFilterData={handleFilter}
              filterOptions={filterOptions}
              displayLocation={locationList?.length > 1 ? true : false}
              filterLocation={filterValue?.filterLocation}
              locationList={locationList}
              isSubGroupSingleSelect={true}
            />
          </div>
        )}
        <div className="box">
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>Employees Objective Status</h2>
            </div>
          </div>
          {allEmployeeObjectiveData && allEmployeeObjectiveData.length > 0 && (
            <FilterTeam
              filterValue={statusFilter}
              placeholder="Search for members"
              filter={filter}
              value={searchFilter ? searchFilter : ""}
              teamData={allEmployeeObjectiveData}
              onSearch={(e) => search(e.target.value)}
              onRadioChange={(e) => handleStatusChange(e.target.value)}
              onReset={resetData}
              onResetSearch={resetSearch}
            />
          )}
          <div className="box-light-gradient h38"></div>
          <div className="box-inner-pad border-bottom-0 pb-0">
            <div className={style.teamsobjective__outer_wrapper}>
              {pageOfItems && pageOfItems.length > 0 ? (
                <div className={style.teamsobjective_wrapper}>
                  <ul className={style.tHead}>
                    <li className={style.plus_space}></li>
                    <li className={style.name}>Name</li>
                    <li className={style.objective_status}>
                      Objectives
                      <br /> Status
                    </li>
                    <li className={style.objective_status}>
                      Reporting
                      <br /> Manager(s)
                    </li>
                    <li className={style.objective_status}>
                      Reviewing
                      <br /> Manager(s)
                    </li>
                    <li className={style.status}>Status</li>
                    <li className={style.progress}>Progress</li>
                  </ul>
                  <ul className={style.tBody}>
                    {pageOfItems &&
                      pageOfItems.map((teamRow, index) => (
                        <EmployeeRow key={"TEAM_ROW_" + teamRow.employeeId + index} teamRow={teamRow} isPage={"HR"} />
                      ))}
                  </ul>
                </div>
              ) : (
                <div>
                  <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                  <div className="gap80"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        {allEmployeeObjectiveData && allEmployeeObjectiveData.length > 0 && (
          <Pagination pageSize={pageSize} items={teamData} resetData={resetData} onChangePage={onChangePage} />
        )}
      </Container>
    </div>
  );
}

export default EmployeesObjectives;
