import http from "util/http";
import {
  LOCATION_LIST_IN_CAMPAIGN,
  SET_CAMPAIGN,
  IMPORT_QUESTION_TEMPLATE,
  CREATED_CAMPAIGN,
  CAMPAIGN_LOCATION_FILTER,
  GET_ALL_EMPLOYEE_NAME_LIST,
  CAMPAIGN_LIST_FOR_ADMIN,
  CAMPAIGN_LIST_FOR_EMPLOYEE,
  CAMPAIGN_DETAIL,
  PREVIEW_CAMPAIGN,
  CAMPAIGN_FILTER,
  UPDATE_CAMPAIGN_FILTER,
  DELETE_CAMPAIGN_FILTER,
  CAMPAIGN_TEMPLATE_LIST,
  CAMPAIGN_ANSWER_UPDATE,
  SUBMIT_CAMPAIGN_TEMPLATE,
  EMPLOYEE_CAMPAIGN_DETAIL_LIST,
  FILTER_DATA_FOR_CAMPAIGN,
  CAMPAIGN_QUESTIONS_STATS,
  CAMPAIGN_QUESTION_POPUP,
  EXPORT_CAMPAIGN_QUESTION,
  PENDING_CAMPAIGN_DETAIL,
  CLONE_CAMPAIGN,
  CAPABILITY_TEMPLATE_LIST,
  EMPLOYEE_CAPABILITY_TEMPLATE,
  CAMPAIGN_MANAGER_ANSWER_UPDATE,
  MANAGER_CAPABILITY_COMMENT,
  CAPABILITY_LIST_FOR_ADMIN,
  DELETE_CAMPAIGN,
  EMPLOYEE_CAMPAIGN_QUIZ_DETAILS,
  QUIZ_TEMPLATE_EMPLOYEE_VIEW,
  QUIZ_ANSWER_UPDATE,
  QUIZ_QUESTIONS_EMPLOYEE_VIEW,
  QUIZ_RESUME_QUESTIONS_EMPLOYEE_VIEW,
  QUIZ_REFRESH_QUESTIONS_EMPLOYEE_VIEW,
  SUBMIT_QUIZ,
  GET_QUIZ_RESULT_FOR_EMPLOYEE,
  GET_QUIZ_RESULT_FOR_HR,
  WRONG_ANSWERS_FOR_HR,
  WRONG_ANSWERS_FOR_EMPLOYEE,
  VIEW_DETAILS_EXPORT,
  EMPLOYEE_QUIZ_GRAPH_DATA,
  GET_DETAILS_INDEPENDENT_OF_ROLE_RIGHTS,
  COUNTRY_LIST,
  EDIT_CAMPAIGN,
} from "util/endpoints";
import * as actionTypes from "./actionTypes";
import { saveAs } from "file-saver";
import { toFixedNumber } from "util/utils";
import { toDate } from "util/dateUtils";
import { successToast, errorToast } from "util/general";
import storage from "util/storage";
import { errorMessage, status, successMessage } from "const/constValue";
import { monthOptions4 } from "const/options";
export const getLocationListForCampaign = (body) => (dispatch) => {
  http
    .post(LOCATION_LIST_IN_CAMPAIGN, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let locationList = [];
        if (response.data) {
          locationList = response.data.map((value) => {
            return {
              value: value,
              label: value,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_LOCATION_LIST_IN_CAMPAIGN,
          data: locationList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};
export const getCampaignListForEmployee = () => (dispatch) => {
  dispatch({ type: "START_CAMPAIGN_LOADING" });
  http
    .get(CAMPAIGN_LIST_FOR_EMPLOYEE)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_LIST_FOR_EMPLOYEE,
            payload: {
              campaignListForEmployee: response.data,
              isLoading: false,
              isCampaignLoading: true, //in list page campaign view will be default
              employeeCampaignData: null, //in list page campaign view will be default
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_LIST_FOR_EMPLOYEE,
            payload: {
              isLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAMPAIGN_LIST_FOR_EMPLOYEE,
        payload: {
          isLoading: false,
        },
      });
    });
};
export const getCampaignListForAdmin = (data) => (dispatch) => {
  let body = {
    offSet: data?.offset || "10",
    pageNumber: data?.pageNumber || "1",
    category: data?.category || "All",
  };
  dispatch({ type: "START_CAMPAIGN_LOADING" });
  http
    .post(CAMPAIGN_LIST_FOR_ADMIN, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({ type: "CLEAR_CAMPAIGN_RESPONSE" });
        if (response.data) {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_LIST_FOR_ADMIN,
            payload: {
              campaignListForAdmin: response.data.campaignList,
              employeeList: [],
              isLoading: false,
              totalCount: response.data.totalCount,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_LIST_FOR_ADMIN,
            payload: {
              campaignListForAdmin: [],
              isLoading: false,
              totalCount: 0,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAMPAIGN_LIST_FOR_ADMIN,
        payload: {
          campaignListForAdmin: [],
          isLoading: false,
          totalCount: 0,
        },
      });
    });
};
export const getPreviewOfCampaign = (id) => (dispatch) => {
  dispatch({ type: "START_CAMPAIGN_POPUP_LOADING" });
  http
    .get(PREVIEW_CAMPAIGN, { campaignId: id })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_PREVIEW_DATA,
            payload: {
              previewCampaign: response.data,
              popupLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_PREVIEW_DATA,
            payload: {
              isLoading: false,
              popupLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAMPAIGN_PREVIEW_DATA,
        payload: {
          isLoading: false,
        },
      });
    });
};
export const getEmployeeCampaignDetail = () => (dispatch) => {
  http
    .get(PENDING_CAMPAIGN_DETAIL)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          storage.set("cRole", "CAMPAIGN");
          dispatch({
            type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
            payload: {
              employeeCampaignData: response.data,
              isCampaignLoading: false,
              isSubmitted: response.data.status === "Submitted",
              isLoading: false,
              submittedDate: response.data.submittedDate,
            },
          });
        } else {
          storage.remove("cRole");
          dispatch({
            type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
            payload: {
              isCampaignLoading: false,
              employeeCampaignData: null,
              isLoading: false,
            },
          });
        }
      } else {
        storage.remove("cRole");
        dispatch({
          type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
          payload: {
            isCampaignLoading: false,
            employeeCampaignData: null,
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      storage.remove("cRole");
      dispatch({
        type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
        payload: {
          isCampaignLoading: false,
          isLoading: false,
        },
      });
    });
};
export const getCampaignDetailForAdmin = (employeeCampaignId) => (dispatch) => {
  http
    .get(CAMPAIGN_DETAIL, { employeeCampaignId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          let errors = [];
          if (response.data.isCapabilityDevelopmentFramework) {
            errors = response.data.questionTemplate?.questions?.map((que) => {
              if (que.options.some((v) => v.isManagerSelected)) {
                return {
                  queId: que.questionId,
                  error: false,
                };
              } else {
                return {
                  queId: que.questionId,
                  error: true,
                };
              }
            });
          }
          dispatch({
            type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
            payload: {
              employeeCampaignData: response.data,
              isCampaignLoading: false,
              isSubmitted: true,
              isLoading: false,
              managerError: { submit: false, errors },
              submittedDate: response.data.submittedDate,
              isManagerSubmitted: response.data.managerSubmittedDate ? true : false,
              managerSubmittedDate: response.data.managerSubmittedDate,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
            payload: {
              isCampaignLoading: false,
              managerError: {},
              employeeCampaignData: null,
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
          payload: {
            isCampaignLoading: false,
            employeeCampaignData: null,
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
        payload: {
          isCampaignLoading: false,
        },
      });
    });
};
export const createCampaign = (body) => (dispatch) => {
  dispatch({ type: "CREATE_CAMPAIGN_LOADING" });
  http
    .post(SET_CAMPAIGN, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              isCreated: true,
              success: true,
              message: "Successfully created",
            },
          },
        });
      } else {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAMPAIGN_RESPONSE,
        payload: {
          apiResponse: {
            success: false,
            message: "Required field missing!",
          },
        },
      });
    });
};
export const updateCampaign = (body, isPublished, campaignId) => (dispatch) => {
  if (isPublished) {
    dispatch({ type: "PUBLISH_CAMPAIGN_HR_VIEW_LOADING", payload: true });
  }
  http
    .post(SET_CAMPAIGN, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (isPublished === "TEMPLATE_UPDATED") {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                isPublished,
                success: true,
                message: "Updated Successfully!",
                id: response.data,
              },
            },
          });
          dispatch({ type: "PUBLISH_CAMPAIGN_HR_VIEW_LOADING", payload: false });
        } else if (isPublished === "REMOVE_TEMPLATE") {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: true,
                message: "Removed Successfully!",
                isPublished,
                id: response.data,
              },
            },
          });
          dispatch({ type: "PUBLISH_CAMPAIGN_HR_VIEW_LOADING", payload: false });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                isPublished,
                id: response.data,
              },
            },
          });
          dispatch({ type: "PUBLISH_CAMPAIGN_HR_VIEW_LOADING", payload: false });
        }
        if (body.subGroupId?.length > 0 || campaignId) {
          dispatch(getCreatedCampaign(body.campaignId));
        }
      } else {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
          },
        });
        dispatch({ type: "PUBLISH_CAMPAIGN_HR_VIEW_LOADING", payload: false });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAMPAIGN_RESPONSE,
        payload: {
          apiResponse: {
            success: false,
            message: errorMessage.SOMETHING_WRONG,
          },
        },
      });
      dispatch({ type: "PUBLISH_CAMPAIGN_HR_VIEW_LOADING", payload: false });
    });
};
export const getCreatedCampaign = (id) => {
  return (dispatch) => {
    // dispatch({ type: "START_CAMPAIGN_LOADING" });
    http
      .get(CREATED_CAMPAIGN, { campaignId: id })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          let data = response.data;
          let isPublished = response.data.status === status.PUBLISHED;
          data.campaignAdmins = response.data.campaignAdmins?.map((value) => {
            return {
              value: value.employeeId,
              label: `${value.empName} (${value.employeeId})`,
              parentGroupId: value.parentGroupId,
              subGroupId: value.subGroupId,
            };
          });
          if (response.data.subGroups?.length > 0) {
            dispatch(getLocationListForCampaign({ subGroupId: response.data.subGroups?.map((v) => v.subGroupId) }));
          }
          data.subGroups = response.data.subGroups?.map((value) => {
            return {
              value: value.subGroupId,
              label: value.subGroupDisplayName,
            };
          });
          data.category = {
            value: response?.data?.category,
            label: response?.data?.category,
          };
          data.campaignFilters = response.data.campaignFilters?.map((filter) => {
            return {
              id: filter.id,
              isChange: false,
              cutOffMonth: monthOptions4.find((v) => v.value === filter.cutOffMonth),
              isFilterDropdownDisabled: isPublished
                ? filter.startDate
                  ? new Date(filter.startDate) < new Date()
                  : false
                : false,
              department:
                filter.department.length > 0
                  ? filter.department.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              designation:
                filter.designation.length > 0
                  ? filter.designation.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              employeeIds:
                filter.employeeIds.length > 0
                  ? filter.employeeIds.map((v) => {
                      return {
                        value: v.employeeId,
                        label: `${v.empName} (${v.employeeId})`,
                        parentGroupId: v.parentGroupId,
                        subGroupId: v.subGroupId,
                      };
                    })
                  : [],
              employeeType:
                filter.employeeType.length > 0
                  ? filter.employeeType.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              departmentList: filter.locationwiseDataList.department.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              designationList: filter.locationwiseDataList.designation.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              employeeGroupList: filter.locationwiseDataList.employeeType.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              employeesListForLocation: filter.locationwiseDataList.employees.map((v) => {
                return {
                  value: v.employeeId,
                  label: `${v.empName} (${v.employeeId})`,
                  parentGroupId: v.parentGroupId,
                  subGroupId: v.subGroupId,
                };
              }),
              location: filter.location.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              startDate: filter?.startDate ? toDate(filter?.startDate) : null,
              endDate: filter?.endDate ? toDate(filter?.endDate) : null,
            };
          });
          dispatch({
            type: actionTypes.SET_CREATED_CAMPAIGN,
            campaignData: data,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};
export const importQuizQuestion = (questionData) => (dispatch) => {
  dispatch({ type: "IMPORT_QUIZ_LOADING" });
  http
    .post(IMPORT_QUESTION_TEMPLATE, {}, questionData)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_IMPORT_QUIZ_TEMPLATE_RESPONSE,
          payload: {
            apiResponse: {
              isUploaded: true,
              success: true,
              message: "Successfully created",
              isImportLoading: false,
            },
          },
        });
      } else {
        dispatch({
          type: actionTypes.SET_IMPORT_QUIZ_TEMPLATE_RESPONSE,
          payload: {
            apiResponse: {
              isUploaded: false,
              success: false,
              message: response.message.message,
              isImportLoading: false,
            },
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_IMPORT_QUIZ_TEMPLATE_RESPONSE,
        payload: {
          apiResponse: {
            isUploaded: false,
            success: false,
            message: "Required field missing!",
            isImportLoading: false,
          },
        },
      });
    });
};
export const getCloneCampaign = (id) => {
  return (dispatch) => {
    // dispatch({ type: "START_CAMPAIGN_LOADING" });
    http
      .post(CLONE_CAMPAIGN, { campaignId: id })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS && response.data) {
          let data = response.data;
          let isPublished = false;
          data.campaignId = response?.data?.campaignId;
          data.campaignAdmins = response.data.campaignAdmins?.map((value) => {
            return {
              value: value.employeeId,
              label: `${value.empName} (${value.employeeId})`,
              parentGroupId: value.parentGroupId,
              subGroupId: value.subGroupId,
            };
          });
          if (response.data.subGroups?.length > 0) {
            dispatch(getLocationListForCampaign({ subGroupId: response.data.subGroups?.map((v) => v.subGroupId) }));
          }
          data.subGroups = response.data.subGroups?.map((value) => {
            return {
              value: value.subGroupId,
              label: value.subGroupDisplayName,
            };
          });
          data.category = {
            value: response?.data?.category,
            label: response?.data?.category,
          };
          data.campaignFilters = response.data.campaignFilters.map((filter) => {
            return {
              id: filter.id,
              isChange: false,
              isFilterDropdownDisabled: isPublished
                ? filter.startDate
                  ? new Date(filter.startDate) < new Date()
                  : false
                : false,
              designation:
                filter.designation.length > 0
                  ? filter.designation.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              department:
                filter.department.length > 0
                  ? filter.department.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              employeeIds:
                filter.employeeIds.length > 0
                  ? filter.employeeIds.map((v) => {
                      return {
                        value: v.employeeId,
                        label: `${v.empName} (${v.employeeId})`,
                        parentGroupId: v.parentGroupId,
                        subGroupId: v.subGroupId,
                      };
                    })
                  : [],
              employeeType:
                filter.employeeType.length > 0
                  ? filter.employeeType.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              departmentList: filter.locationwiseDataList.department.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              designationList: filter.locationwiseDataList.designation.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              employeeGroupList: filter.locationwiseDataList.employeeType.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              employeesListForLocation: filter.locationwiseDataList.employees.map((v) => {
                return {
                  value: v.employeeId,
                  label: `${v.empName} (${v.employeeId})`,
                  parentGroupId: v.parentGroupId,
                  subGroupId: v.subGroupId,
                };
              }),
              location: filter.location.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              startDate: filter?.startDate ? toDate(filter?.startDate) : null,
              endDate: filter?.endDate ? toDate(filter?.endDate) : null,
            };
          });
          dispatch({
            type: actionTypes.SET_CREATED_CAMPAIGN,
            campaignData: data,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};
export const deleteCampaign = (id, category) => {
  return (dispatch) => {
    dispatch({ type: "CREATE_CAMPAIGN_LOADING" });
    http
      .delete(DELETE_CAMPAIGN, {}, {}, { campaignId: id, type: category })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(getCampaignListForAdmin({}));
          dispatch({ type: "CLEAR_PAGINATION_DATA" });
          dispatch({
            type: actionTypes.UPDATE_CAMPAIGN_LIST_FOR_ADMIN,
            payload: {
              apiResponse: {
                success: true,
                message: successMessage.DELETE_SUCCESSFULLY,
              },
              isCreateLoading: false,
            },
            campaignId: id,
          });
          successToast(successMessage.DELETE_SUCCESSFULLY);
        } else {
          dispatch({
            type: actionTypes.UPDATE_CAMPAIGN_LIST_FOR_ADMIN,
            payload: {
              apiResponse: {
                success: true,
                message: response.message.message,
              },
            },
            isCreateLoading: false,
          });
          errorToast(response.message.message);
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          isCreateLoading: false,
        });
        errorToast("Can't Delete! Employee has submitted");
      });
  };
};
export const getFilterForCampaign = (campaignId) => (dispatch) => {
  http
    .get(FILTER_DATA_FOR_CAMPAIGN, { campaignId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_FILTER_FOR_CAMPAIGN,
            payload: {
              locationList: response.data?.location?.map((v) => {
                return { value: v, label: v };
              }),
              designationList: response.data?.designation?.map((v) => {
                return { value: v, label: v };
              }),
              departmentList: response.data?.department?.map((v) => {
                return { value: v, label: v };
              }),
              employeeFilterList: response.data?.employees?.map((v) => {
                return {
                  value: v.employeeId,
                  label: v.empName + " (" + v.employeeId + ")",
                  parentGroupId: v.parentGroupId,
                  subGroupId: v.subGroupId,
                };
              }),
              employeeGroupList: response.data?.employeeType?.map((v) => {
                return { value: v, label: v };
              }),
              isFilterViewLoading: false,
            },
          });
        }
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};
export const getLocationFilter = (location, id, campaignId, subGroupId) => (dispatch) => {
  let params = campaignId
    ? {
        location,
        campaignId,
        subGroupId,
      }
    : { location };
  http
    .post(CAMPAIGN_LOCATION_FILTER, {}, params)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_LOCATION_FILTER_FOR_CAMPAIGN,
            id: id,
            payload: {
              // location: { label: location, value: location },
              employeesListForLocation:
                response.data?.employees?.map((v) => {
                  return {
                    value: v.employeeId,
                    label: `${v.empName} (${v.employeeId})`,
                    parentGroupId: v.parentGroupId,
                    subGroupId: v.subGroupId,
                  };
                }) || [],
              departmentList: response.data?.department?.map((v) => {
                return { value: v, label: v };
              }),
              designationList: response.data?.designation?.map((v) => {
                return { value: v, label: v };
              }),
              employeeGroupList: response.data?.employeeType?.map((v) => {
                return { value: v, label: v };
              }),
              isLoading: false,
            },
          });
        }
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};
export const getEmployeeNameListForCampaign = (name) => (dispatch) => {
  http
    .get(GET_ALL_EMPLOYEE_NAME_LIST, { name: name })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let employeeList = [];
        if (response.data) {
          employeeList = response.data.map((value) => {
            return {
              value: value.employeeId,
              label: `${value.empName} (${value.employeeId})`,
              parentGroupId: value.parentGroupId,
              subGroupId: value.subGroupId,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_EMPLOYEE_NAME_FOR_CAMPAIGN,
          data: employeeList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const editCampaignFilter = (body, campaignId, newLocationAdded) => (dispatch) => {
  http
    .post(EDIT_CAMPAIGN, { campaignId, newLocationAdded }, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_EDIT_CAMPAIGN_DATA,
          payload: {
            success: true,
            message: "Saved Successfully",
          },
        });
      } else {
        dispatch({
          type: actionTypes.SET_EDIT_CAMPAIGN_DATA,
          payload: {
            success: false,
            message: response.message.message,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_EDIT_CAMPAIGN_DATA,
        payload: {
          success: false,
          message: errorMessage.SOMETHING_WRONG,
        },
      });
    });
};

export const createCampaignFilter = (body, campaignId, isEdit, newLocationAdded, isPublished) => (dispatch) => {
  http
    .post(CAMPAIGN_FILTER, { campaignId }, body)
    .then((response) => response.data)
    .then((response) => {
      dispatch(getCreatedCampaign(campaignId));
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: true,
              message: "Saved Successfully",
            },
          },
        });
        if (isEdit && isPublished) {
          dispatch(editCampaignFilter(body, campaignId, newLocationAdded));
        }
      } else {
        if (response.message.code === 1001) {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: false,
                message: "Please Select Date",
              },
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: false,
                message: response.message.message,
              },
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAMPAIGN_RESPONSE,
        payload: {
          apiResponse: {
            success: false,
            message: "Please Select Date",
          },
        },
      });
    });
};
export const updateCampaignFilter =
  (body, campaignId, campaignFilterId, isEdit, newLocationAdded, isPublished) => (dispatch) => {
    http
      .put(UPDATE_CAMPAIGN_FILTER, { campaignId, campaignFilterId }, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(getCreatedCampaign(campaignId));
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: true,
                message: "Saved Successfully",
              },
            },
          });
          if (isEdit && isPublished) {
            dispatch(editCampaignFilter(body, campaignId, newLocationAdded));
          }
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: false,
                message: response.message.message,
              },
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: false,
              message: errorMessage.SOMETHING_WRONG,
            },
          },
        });
      });
  };
export const deleteCampaignFilter = (campaignId, campaignFilterId) => (dispatch) => {
  http
    .delete(DELETE_CAMPAIGN_FILTER, {}, {}, { campaignId, campaignFilterId })
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.status === status.SUCCESS) {
          dispatch(getCreatedCampaign(campaignId));
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: true,
                message: "deleted Successfully",
              },
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: false,
                message: response.message.message,
              },
            },
          });
        }
      },
      () => {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          message: "Issue deleting",
        });
      }
    );
};
export const getCampaignTemplateList = (config) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_CAMPAIGN_TEMPLATE_LIST,
      templateList: null,
      isTemplateLoading: true,
    });
    http
      .get(CAMPAIGN_TEMPLATE_LIST, {}, config)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_TEMPLATE_LIST,
            templateList: response.data?.length > 0 ? response.data : [],
            isTemplateLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_TEMPLATE_LIST,
            templateList: [],
            isTemplateLoading: false,
          });
        }
      })
      .catch(() => {});
  };
};
export const AutoSaveCampaignTemplate = (data, campaignId, questionTemplateId) => {
  let qna = {
    questions: data,
    campaignId,
    questionTemplateId,
  };
  return () => {
    http
      .put(CAMPAIGN_ANSWER_UPDATE, {}, qna)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          // dispatch({ type: "SET_UPDATED_DATE", updatedDate: response.data });
        }
      })
      .catch(() => {});
  };
};
export const submitCampaignTemplate = (campaignId) => (dispatch) => {
  dispatch({ type: "START_CAMPAIGN_LOADING" });
  http
    .post(SUBMIT_CAMPAIGN_TEMPLATE, { campaignId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: true,
              message: "Successfully Submitted",
            },
            isLoading: false,
            isSubmitted: true,
            submittedDate: new Date(),
          },
        });
        successToast(response.message.message);
      } else {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            isLoading: false,
          },
        });
        errorToast(response.message.message);
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAMPAIGN_RESPONSE,
        payload: {
          apiResponse: {
            success: false,
            message: errorMessage.SOMETHING_WRONG,
          },
          isLoading: false,
        },
      });
      errorToast(errorMessage.SOMETHING_WRONG);
    });
};
export const getEmployeeListForCampaign = (campaignId, body) => (dispatch) => {
  dispatch({ type: "START_CAMPAIGN_EMPLOYEE_VIEW_LOADING" });
  http
    .post(EMPLOYEE_CAMPAIGN_DETAIL_LIST, { campaignId }, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN,
            payload: {
              employeeList: response.data.employeeCampaignList,
              pendingPercentage: toFixedNumber(response.data.pendingPercentage, 2),
              submittedCount: response.data.submittedCount,
              pendingCount: response.data.pendingCount,
              submittedPercentage: toFixedNumber(response.data.submittedPercentage, 2),
              isFilterViewLoading: false,
              employeeCampaignData: {},
              isCampaignLoading: true,
              campaignAdmins: response.data.campaignAdmins,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN,
            payload: {
              employeeList: [],
              pendingPercentage: "-",
              submittedCount: 0,
              pendingCount: 0,
              submittedPercentage: "-",
              isFilterViewLoading: false,
              employeeCampaignData: {},
              isCampaignLoading: true,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN,
          payload: {
            employeeList: [],
            pendingPercentage: 100,
            submittedCount: 0,
            pendingCount: 0,
            submittedPercentage: 0,
            isFilterViewLoading: false,
          },
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};
export const getQuestionChartData = (config, body) => (dispatch) => {
  dispatch({ type: "START_CAMPAIGN_EMPLOYEE_VIEW_LOADING" });
  http
    .post(CAMPAIGN_QUESTIONS_STATS, {}, body, config)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_QUESTION_GRAPH,
            payload: {
              questionsGraph: response.data,
              isFilterViewLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_QUESTION_GRAPH,
          payload: {
            questionsGraph: [],
            isFilterViewLoading: false,
            // apiResponse: {
            //   success: false,
            //   message: response.message.message,
            // },
          },
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};
export const getCampaignQuestionPopup = (config, body) => (dispatch) => {
  dispatch({ type: "START_CAMPAIGN_EMPLOYEE_VIEW_LOADING" });
  http
    .post(CAMPAIGN_QUESTION_POPUP, {}, body, config)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_QUESTION_POPUP,
            payload: {
              questionsPopup: response.data,
              previewPopup: true,
              isFilterViewLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_QUESTION_POPUP,
          payload: {
            questionsPopup: [],
            isFilterViewLoading: false,
            previewPopup: true,
            // apiResponse: {
            //   success: false,
            //   message: response.message.message,
            // },
          },
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};
//EXPORT QUESTION
export const downloadCampaignQuestion = (config, body, name) => (dispatch) => {
  dispatch({ type: "START_CAMPAIGN_EMPLOYEE_VIEW_LOADING" });
  http
    .post(EXPORT_CAMPAIGN_QUESTION, {}, body, config)
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = name ? `${name}.xlsx` : `Campaign Question Data.xlsx`;
        saveAs(blob, filename);
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: true,
              message: successMessage.FILE_DOWNLOADED,
            },
            isFilterViewLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAMPAIGN_RESPONSE,
        payload: { apiResponse: { success: false, message: "Error Downloading file ." }, isFilterViewLoading: false },
      });
    });
};
export const getCapabilityTemplateList = () => {
  return (dispatch) => {
    http
      .get(CAPABILITY_TEMPLATE_LIST, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SET_CAPABILITY_TEMPLATE_LIST,
            payload: {
              capabilityTemplateList: {
                data: response.data?.length > 0 ? response.data : [],
                filterList: response.data.slice(0, 5),
                currentPage: 1,
                pageSize: 5,
                totalItem: response.data?.length,
              },
              capabilityListLoader: false,
              employeeCampaignData: {},
              isCampaignLoading: true,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAPABILITY_TEMPLATE_LIST,
            payload: {
              capabilityTemplateList: [],
              capabilityListLoader: false,
              employeeCampaignData: {},
              isCampaignLoading: true,
            },
          });
        }
      })
      .catch(() => {});
  };
};
export const employeeCapabilityDetail = (id) => (dispatch) => {
  let config = {
    params: {
      campaignId: id,
    },
  };
  http
    .get(EMPLOYEE_CAPABILITY_TEMPLATE, {}, config)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
            payload: {
              employeeCampaignData: response.data,
              isCampaignLoading: false,
              isSubmitted: response.data.status === "Submitted" || response.data.status === "Lapsed",
              isLoading: false,
              submittedDate: response.data.submittedDate,
              isManagerSubmitted: response.data.managerSubmittedDate ? true : false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
            payload: {
              isCampaignLoading: false,
              employeeCampaignData: null,
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
          payload: {
            isCampaignLoading: false,
            employeeCampaignData: null,
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA,
        payload: {
          isCampaignLoading: false,
          isLoading: false,
        },
      });
    });
};
export const submitCapabilityByManager = (data, campaignId, questionTemplateId, employeeIds) => {
  let body = {
    questionsMap: data,
    campaignId,
    questionTemplateId,
    compositeEmployeeId: {
      employeeId: employeeIds?.employeeId,
      parentGroupId: employeeIds?.parentGroupId,
      subGroupId: employeeIds?.subGroupId,
    },
  };
  return (dispatch) => {
    dispatch({ type: "START_CAMPAIGN_LOADING" });
    http
      .put(CAMPAIGN_MANAGER_ANSWER_UPDATE, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: true,
                message: successMessage.SUCCESSFULLY_SUBMITTED,
              },
              isLoading: false,
              isManagerSubmitted: true,
              managerSubmittedDate: new Date(),
            },
            employeeId: employeeIds.employeeId,
          });
          dispatch({ type: "SET_UPDATED_DATE", updatedDate: response.data });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: false,
                message: response.message.message,
              },
              isLoading: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: false,
              message: errorMessage.SOMETHING_WRONG,
            },
            isLoading: false,
          },
        });
      });
  };
};
export const managerCapabilityComment = (employeeCampaignId, comment) => {
  let body = {
    employeeCampaignId: employeeCampaignId,
    comment,
  };
  return (dispatch) => {
    dispatch({ type: "START_CAMPAIGN_LOADING" });
    http
      .post(MANAGER_CAPABILITY_COMMENT, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(getCampaignDetailForAdmin(employeeCampaignId));
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: true,
                message: successMessage.SUCCESSFULLY_SUBMITTED,
              },
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAMPAIGN_RESPONSE,
            payload: {
              apiResponse: {
                success: false,
                message: response.message.message,
              },
              isLoading: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_CAMPAIGN_RESPONSE,
          payload: {
            apiResponse: {
              success: false,
              message: errorMessage.SOMETHING_WRONG,
            },
            isLoading: false,
          },
        });
      });
  };
};
export const getCapabilityListForAdmin = () => (dispatch) => {
  http
    .get(CAPABILITY_LIST_FOR_ADMIN)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({ type: "CLEAR_CAMPAIGN_RESPONSE" });
        if (response.data) {
          dispatch({
            type: actionTypes.SET_CAPABILITY_LIST_FOR_ADMIN,
            payload: {
              capabilityListForAdmin: {
                data: response.data?.length > 0 ? response.data : [],
                filterList: response.data.slice(0, 5),
                currentPage: 1,
                pageSize: 5,
                totalItem: response.data?.length,
              },
              employeeList: [],
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_CAPABILITY_LIST_FOR_ADMIN,
            payload: {
              capabilityList: [],
              isLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAPABILITY_LIST_FOR_ADMIN,
        payload: {
          campaignListForAdmin: [],
          isLoading: false,
        },
      });
    });
};
export const getEmployeeCampaignQuizDetail = (campaignId, data) => {
  let body = {
    employeeIds: data?.employeeIds ? data?.employeeIds : null,
    designation: data?.designation ? data?.designation : null,
    employeeType: data?.employeeType ? data?.employeeType : null,
    status: data?.status ? data?.status : null,
    name: data?.name ? data?.name : null,
    filter: data?.filter ? data?.filter : null,
    offSet: data?.offSet ? data?.offSet : "10",
    pageNumber: data?.pageNumber ? data?.pageNumber : "1",
    startDate: data?.startDate ? data?.startDate : null,
    endDate: data?.endDate ? data?.endDate : null,
  };
  return (dispatch) => {
    dispatch({ type: "START_CAMPAIGN_EMPLOYEE_VIEW_LOADING" });
    http
      .post(EMPLOYEE_CAMPAIGN_QUIZ_DETAILS, { campaignId }, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data) {
            dispatch({
              type: actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN,
              payload: {
                employeeCampaignQuizList: response.data.employeeCampaignQuizList,
                attemptedPercentage: toFixedNumber(response.data.attemptedPercentage, 2),
                notattemptedPercentage: toFixedNumber(response.data.notattemptedPercentage, 2),
                attemptedCount: response.data.attemptedCount,
                notattemptedCount: response.data.notattemptedCount,
                totalCount: response.data.totalCount,
                isFilterViewLoading: false,
              },
            });
          } else {
            dispatch({
              type: actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN,
              payload: {
                employeeCampaignQuizList: [],
                attemptedPercentage: "-",
                notattemptedPercentage: "-",
                attemptedCount: 0,
                notattemptedCount: 0,
                totalCount: 0,
                isFilterViewLoading: false,
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN,
            payload: {
              employeeCampaignQuizList: [],
              attemptedPercentage: 0,
              notattemptedPercentage: 100,
              attemptedCount: 0,
              notattemptedCount: 0,
              totalCount: 0,
              isFilterViewLoading: false,
            },
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};
export const getQuizTemplateEmployeeViewPastData = (data) => {
  let body = {
    status: data?.status ? data?.status : null,
    offSet: data?.offSet ? data?.offSet : "10",
    pageNumber: data?.pageNumber ? data?.pageNumber : "1",
  };
  return (dispatch) => {
    dispatch({ type: "EMPLOYEE_VIEW_QUIZ_DETAILS_LOADING" });
    http
      .post(QUIZ_TEMPLATE_EMPLOYEE_VIEW, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data) {
            dispatch({
              type: actionTypes.SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_PAST_DATA,
              payload: {
                viewQuizListPastData: response.data.viewQuizList,
                viewQuizListPastCount: response.data.totalCount,
                isEmployeeQuizDetailsLoading: false,
              },
            });
          } else {
            dispatch({
              type: actionTypes.SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_PAST_DATA,
              payload: {
                viewQuizListPastData: [],
                viewQuizListPastCount: 0,
                isEmployeeQuizDetailsLoading: false,
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_PAST_DATA,
            payload: {
              viewQuizListPastData: [],
              viewQuizListPastCount: 0,
              isEmployeeQuizDetailsLoading: true,
            },
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};
export const getQuizTemplateEmployeeViewOngoingData = (data) => {
  let body = {
    status: data?.status ? data?.status : null,
    offSet: data?.offSet ? data?.offSet : "10",
    pageNumber: data?.pageNumber ? data?.pageNumber : "1",
  };
  return (dispatch) => {
    dispatch({ type: "EMPLOYEE_VIEW_QUIZ_DETAILS_LOADING" });
    http
      .post(QUIZ_TEMPLATE_EMPLOYEE_VIEW, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data) {
            dispatch({
              type: actionTypes.SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_ONGOING_DATA,
              payload: {
                viewQuizListOngoingData: response.data.viewQuizList,
                viewQuizListOngoingCount: response.data.totalCount,
                isEmployeeQuizDetailsLoading: false,
              },
            });
          } else {
            dispatch({
              type: actionTypes.SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_ONGOING_DATA,
              payload: {
                viewQuizListOngoingData: [],
                viewQuizListOngoingCount: 0,
                isEmployeeQuizDetailsLoading: false,
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_ONGOING_DATA,
            payload: {
              viewQuizListOngoingData: [],
              viewQuizListOngoingCount: 0,
              isEmployeeQuizDetailsLoading: true,
            },
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};
export const getRefreshedCampaign = (id) => {
  return (dispatch) => {
    http
      .get(CREATED_CAMPAIGN, { campaignId: id })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          let data = response.data;
          if (data.campaignId) {
            let config = {
              params: {
                campaignId: data.campaignId,
                type: data.category,
                numberOfQuestions: data.number_Of_Questions,
              },
            };
            dispatch(getCampaignTemplateList(config));
          }
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};
export const AutoSaveQuizTemplate = (data, campaignId, questionTemplateId, attemptNumber) => {
  let qna = {
    questions: data,
    campaignId,
    questionTemplateId,
  };
  let config = {
    params: {
      attemptNumber: attemptNumber,
    },
  };
  return () => {
    http
      .put(QUIZ_ANSWER_UPDATE, {}, qna, config)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          // dispatch({ type: "SET_UPDATED_DATE", updatedDate: response.data });
        }
      })
      .catch(() => {});
  };
};
export const getQuizRefreshQuestionsEmployeeView =
  (campaignId, timeStamp, pageNumber, offSet, attemptNumber, progressBarPercentage, history, pathName) =>
  (dispatch) => {
    let body = {
      pageNumber: pageNumber,
      offSet: offSet,
      attemptNumber: attemptNumber,
      progressBarPercentage: progressBarPercentage,
    };
    dispatch({ type: "ISQUIZSTARTED", payload: true });
    http
      .post(QUIZ_REFRESH_QUESTIONS_EMPLOYEE_VIEW, { campaignId, timeStamp }, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_REFRESH_VIEW_QUIZ_QUESTIONS,
            payload: {
              apiResponse: {
                success: true,
                message: "Saved Successfully",
              },
              employeeRefreshQuizData: response.data,
              quizStartDate: new Date(),
            },
          });
          dispatch({ type: "IS_QUIZ_START_BUTTON_LOADING", payload: false });
          if (response?.data && pathName !== null) {
            dispatch({ type: "IS_REDIRECT_POPUP", payload: false });
            history.push({
              pathname: `${pathName}/${campaignId}`,
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_REFRESH_VIEW_QUIZ_QUESTIONS,
            payload: {
              apiResponse: {
                success: false,
                message: response.message.message,
              },
              employeeRefreshQuizData: null,
              quizStartDate: null,
            },
          });
          dispatch({ type: "IS_QUIZ_START_BUTTON_LOADING", payload: false });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_REFRESH_VIEW_QUIZ_QUESTIONS,
          payload: {
            apiResponse: {
              success: false,
              message: errorMessage.SOMETHING_WRONG,
            },
            employeeRefreshQuizData: null,
            quizStartDate: null,
          },
        });
        dispatch({ type: "IS_QUIZ_START_BUTTON_LOADING", payload: false });
      });
  };
export const setQuizInformationInterval =
  (campaignId, timeStamp, pageNumber, offSet, attemptNumber, progressBarPercentage) => (dispatch) => {
    let body = {
      pageNumber: pageNumber,
      offSet: offSet,
      attemptNumber: attemptNumber,
      progressBarPercentage: progressBarPercentage,
    };
    dispatch({ type: "ISQUIZSTARTED", payload: true });
    http
      .post(QUIZ_REFRESH_QUESTIONS_EMPLOYEE_VIEW, { campaignId, timeStamp }, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SET_QUIZ_INFO_INTERVAL,
            payload: {
              apiResponse: {
                success: true,
                message: "Saved Successfully",
              },
              // employeeRefreshQuizData: response.data,
              quizStartDate: new Date(),
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_QUIZ_INFO_INTERVAL,
            payload: {
              apiResponse: {
                success: false,
                message: response.message.message,
              },
              // employeeRefreshQuizData: {},
              quizStartDate: null,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_QUIZ_INFO_INTERVAL,
          payload: {
            apiResponse: {
              success: false,
              message: errorMessage.SOMETHING_WRONG,
            },
            // employeeRefreshQuizData: {},
            quizStartDate: null,
          },
        });
      });
  };
export const getQuizQuestionsEmployeeView = (campaignId, pageNumber, history, pathName) => (dispatch) => {
  dispatch({ type: "ISQUIZSTARTED", payload: true });
  http
    .put(QUIZ_QUESTIONS_EMPLOYEE_VIEW, { campaignId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_VIEW_QUIZ_QUESTIONS,
          payload: {
            apiResponse: {
              success: true,
              message: "Saved Successfully",
            },
            employeeQuizData: response.data,
            questionsIds: response.data.questionsIds,
            quizStartDate: new Date(),
          },
        });
        let questionNumber = response?.data?.number_Of_Questions;
        let number_Of_Attemptes_done = response?.data?.number_Of_Attemptes_done + 1;
        let timeStamp = response?.data?.total_Time * 60;
        let offSet = 1;
        if (questionNumber > 10) {
          offSet = 2;
        }
        let progressBarPercentage =
          questionNumber <= 10
            ? Math.floor(((pageNumber * offSet) / questionNumber) * 100)
            : questionNumber % 2 === 0
            ? Math.floor(((pageNumber * offSet) / questionNumber) * 100)
            : Math.floor(((pageNumber * offSet - 1) / questionNumber) * 100);
        dispatch(
          getQuizRefreshQuestionsEmployeeView(
            campaignId,
            timeStamp,
            pageNumber,
            offSet,
            number_Of_Attemptes_done,
            progressBarPercentage,
            history,
            pathName
          )
        );
      } else {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_VIEW_QUIZ_QUESTIONS,
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            employeeQuizData: null,
            questionsIds: null,
            quizStartDate: null,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_EMPLOYEE_VIEW_QUIZ_QUESTIONS,
        payload: {
          apiResponse: {
            success: false,
            message: errorMessage.SOMETHING_WRONG,
          },
          employeeQuizData: null,
          questionsIds: null,
          quizStartDate: null,
        },
      });
    });
};
export const getResumeQuizQuestionsEmployeeView = (campaignId, history, pathName) => (dispatch) => {
  dispatch({ type: "ISQUIZSTARTED", payload: true });
  http
    .get(QUIZ_RESUME_QUESTIONS_EMPLOYEE_VIEW, { campaignId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_RESUME_VIEW_QUIZ_QUESTIONS,
          payload: {
            apiResponse: {
              success: true,
              message: "Saved Successfully",
            },
            employeeResumeQuizData: response.data,
            questionsIds: response.data.questionsIds,
          },
        });
        let questionNumber = response?.data?.numberOfQuestions;
        let pageNumber = response?.data?.pageNumber;
        let number_Of_Attemptes_done = response?.data?.attemptNumber + 1;
        let timeStamp = -1;
        let offSet = 1;
        if (questionNumber > 10) {
          offSet = 2;
        }
        let progressBarPercentage = response?.data?.progressBarPercentage;
        dispatch(
          getQuizRefreshQuestionsEmployeeView(
            campaignId,
            timeStamp,
            pageNumber,
            offSet,
            number_Of_Attemptes_done,
            progressBarPercentage,
            history,
            pathName
          )
        );
      } else {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_RESUME_VIEW_QUIZ_QUESTIONS,
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            employeeResumeQuizData: {},
            questionsIds: null,
            quizStartDate: null,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_EMPLOYEE_RESUME_VIEW_QUIZ_QUESTIONS,
        payload: {
          apiResponse: {
            success: false,
            message: errorMessage.SOMETHING_WRONG,
          },
          employeeResumeQuizData: {},
          questionsIds: null,
          quizStartDate: null,
        },
      });
    });
};
export const submitEmployeeQuiz = (campaignId, attemptNumber, history, pathName, data) => (dispatch) => {
  let body = {
    questionIds: data?.gQuestionId ? data?.gQuestionId : [],
    quizStartDate: data?.quizStartDate ? data?.quizStartDate : "",
  };
  http
    .post(SUBMIT_QUIZ, { campaignId, attemptNumber }, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_QUIZ_RESPONSE,
          payload: {
            apiResponse: {
              success: true,
              message: "Successfully Submitted",
            },
          },
        });
        successToast(response.message.message);
        history.push({
          pathname: `${pathName}/${campaignId}`,
          state: { hideStart: true },
        });
      } else {
        dispatch({
          type: actionTypes.SET_QUIZ_RESPONSE,
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
          },
        });
        errorToast(response.message.message);
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_QUIZ_RESPONSE,
        payload: {
          apiResponse: {
            success: false,
            message: errorMessage.SOMETHING_WRONG,
          },
        },
      });
    });
};
export const getQuizResultForEmployee = (campaignId) => (dispatch) => {
  dispatch({ type: "EMPLOYEE_VIEW_QUIZ_RESULT_LOADING" });
  http
    .get(GET_QUIZ_RESULT_FOR_EMPLOYEE, { campaignId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_VIEW_QUIZ_RESULT,
            payload: {
              employeeViewQuizResult: response.data,
              isEmployeeViewQuizResultLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_VIEW_QUIZ_RESULT,
            payload: {
              employeeViewQuizResult: {},
              isEmployeeViewQuizResultLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_EMPLOYEE_VIEW_QUIZ_RESULT,
        payload: {
          employeeViewQuizResult: {},
          isEmployeeViewQuizResultLoading: true,
        },
      });
    });
};
export const getQuizResultForHr = (campaignId, attemptNumber, employeeId, subGroupId, parentGroupId) => (dispatch) => {
  dispatch({ type: "HR_VIEW_EMPLOYEE_QUIZ_RESULT_LOADING" });
  http
    .get(GET_QUIZ_RESULT_FOR_HR, { campaignId, attemptNumber, employeeId, subGroupId, parentGroupId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_HR_VIEW_QUIZ_RESULT,
            payload: {
              hrViewQuizResult: response.data,
              isHrViewEmployeeQuizResultLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_HR_VIEW_QUIZ_RESULT,
            payload: {
              hrViewQuizResult: {},
              isHrViewEmployeeQuizResultLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_HR_VIEW_QUIZ_RESULT,
        payload: {
          hrViewQuizResult: {},
          isHrViewEmployeeQuizResultLoading: true,
        },
      });
    });
};
export const getWrongAnswersForEmployee = (campaignId, attemptNumber) => (dispatch) => {
  dispatch({ type: "EMPLOYEE_VIEW_QUIZ_RESULT_LOADING" });
  http
    .get(WRONG_ANSWERS_FOR_EMPLOYEE, { campaignId, attemptNumber })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_WRONG_ANSWERS_FOR_EMPLOYEE,
            payload: {
              wrongAnswersForEmployee: response.data,
              isEmployeeViewQuizResultLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_WRONG_ANSWERS_FOR_EMPLOYEE,
            payload: {
              wrongAnswersForEmployee: {},
              isEmployeeViewQuizResultLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_WRONG_ANSWERS_FOR_EMPLOYEE,
        payload: {
          wrongAnswersForEmployee: {},
          isEmployeeViewQuizResultLoading: true,
        },
      });
    });
};
export const getWrongAnswersForHr =
  (campaignId, attemptNumber, employeeId, subGroupId, parentGroupId) => (dispatch) => {
    dispatch({ type: "HR_VIEW_EMPLOYEE_QUIZ_RESULT_LOADING" });
    http
      .get(WRONG_ANSWERS_FOR_HR, { campaignId, attemptNumber, employeeId, subGroupId, parentGroupId })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data) {
            dispatch({
              type: actionTypes.SET_WRONG_ANSWERS_FOR_HR,
              payload: {
                wrongAnswersForHr: response.data,
                isHrViewEmployeeQuizResultLoading: false,
              },
            });
          } else {
            dispatch({
              type: actionTypes.SET_WRONG_ANSWERS_FOR_HR,
              payload: {
                wrongAnswersForHr: {},
                isHrViewEmployeeQuizResultLoading: false,
              },
            });
          }
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_WRONG_ANSWERS_FOR_HR,
          payload: {
            wrongAnswersForHr: {},
            isHrViewEmployeeQuizResultLoading: true,
          },
        });
      });
  };
export const getEmployeeQuizResultReport = (param, body) => (dispatch) => {
  http
    .post(VIEW_DETAILS_EXPORT, param, body, { responseType: "arraybuffer" })
    .then((response) => {
      if (response.data) {
        // eslint-disable-next-line no-undef
        var byteArray = new Uint8Array(response.data);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let filename = `Quiz Result Data.xlsx`;
        saveAs(blob, filename);
        successToast("File downloaded successfully.");
      }
    })
    .catch(() => {
      errorToast("Error Downloading file.");
    });
};
export const getQuizResultGraphData = (campaignId, data) => {
  let body = {
    employeeIds: data?.employeeIds ? data?.employeeIds : null,
    designation: data?.designation ? data?.designation : null,
    employeeType: data?.employeeType ? data?.employeeType : null,
    status: data?.status ? data?.status : null,
    name: data?.name ? data?.name : null,
    filter: data?.filter ? data?.filter : null,
    offSet: data?.offSet ? data?.offSet : "10",
    pageNumber: data?.pageNumber ? data?.pageNumber : "1",
    startDate: data?.startDate ? data?.startDate : null,
    endDate: data?.endDate ? data?.endDate : null,
    subGroups: data?.subGroups ? data?.subGroups : null,
    locations: data?.locations ? data?.locations : null,
  };
  return (dispatch) => {
    http
      .post(EMPLOYEE_QUIZ_GRAPH_DATA, { campaignId }, body)
      .then((response) => response)
      .then((response) => {
        if (response.data.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_QUIZ_RESULT_GRAPH_DATA,
            payload: response.data.data,
          });
          dispatch({
            type: actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN,
            payload: {
              isFilterViewLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_QUIZ_RESULT_GRAPH_DATA,
            payload: [],
          });
          dispatch({
            type: actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN,
            payload: {
              isFilterViewLoading: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_EMPLOYEE_QUIZ_RESULT_GRAPH_DATA,
          payload: [],
        });
      });
  };
};

export const getAllEmployeesDetailsIndependentOfRoleRights = (campaignId) => (dispatch) => {
  http
    .get(GET_DETAILS_INDEPENDENT_OF_ROLE_RIGHTS, { campaignId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_EMPLOYEES_DETAILS_ROLE_RIGHTS_INDEPENDENT,
            payload: {
              departmentList:
                response.data?.departments?.length > 0
                  ? response.data?.departments.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              designationList:
                response.data?.designations?.length > 0
                  ? response.data?.designations.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              employeeTypeList:
                response.data?.employeeType?.length > 0
                  ? response.data?.employeeType.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              employeesList:
                response.data?.employees?.length > 0
                  ? response.data?.employees.map((v) => {
                      return {
                        value: v.employeeId,
                        label: v.empName + " (" + v.employeeId + ")",
                        parentGroupId: v.parentGroupId,
                        subGroupId: v.subGroupId,
                      };
                    })
                  : [],
              locationList:
                response.data?.locations?.length > 0
                  ? response.data?.locations.map((v) => {
                      return {
                        value: v,
                        label: v,
                      };
                    })
                  : [],
              subGroupList:
                response.data?.subGroups?.length > 0
                  ? response.data?.subGroups.map((v) => {
                      return {
                        value: v.subGroupId,
                        label: v.subGroupDisplayName,
                      };
                    })
                  : [],
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEES_DETAILS_ROLE_RIGHTS_INDEPENDENT,
            payload: {
              departmentList: [],
              employeesList: [],
              designationList: [],
              employeeTypeList: [],
              subGroupList: [],
              locationList: [],
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_EMPLOYEES_DETAILS_ROLE_RIGHTS_INDEPENDENT,
        payload: {
          departmentList: [],
          employeesList: [],
          designationList: [],
          employeeTypeList: [],
          subGroupList: [],
          locationList: [],
        },
      });
    });
};

export const getCountryList = (param, body) => (dispatch) => {
  http
    .post(COUNTRY_LIST, param, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let countryList = [];
        if (response.data) {
          countryList = response.data.map((value) => {
            return {
              value: value,
              label: value,
            };
          });
        }
        countryList.push({ value: "All", label: "All" });
        dispatch({
          type: actionTypes.SET_COUNTRY_LIST,
          payload: {
            countryList: countryList,
          },
        });
      } else {
        dispatch({
          type: actionTypes.SET_COUNTRY_LIST,
          payload: {
            countryList: [],
          },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SET_COUNTRY_LIST,
        payload: {
          countryList: [],
        },
      });
    });
};
