import React from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./AllObjectives.module.scss";
import { IC_ERROR } from "const/imgCost";

const DetailErrorInfoPopup = ({ show, submit }) => {
  return (
    <>
      <Modal
        size={"lg"}
        show={show}
        onHide={submit}
        animation={false}
        className="custom_modal_popup"
      >
        <div className="custom_modal_container">
          <Row>
            <Col md={12}>
              <Modal.Body>
                <div className={style.uploadcsv_popup_wrapper}>
                  <div className={style.title_box}>
                    <h1>
                      Make sure you clear all the issues ﬁrst and then upload
                      the ﬁle.
                    </h1>
                  </div>
                  <div className={style.error_box}>
                    <div className={style.error_title}>
                      <div className={style.fileicon_2}>
                        <ReactSVG src={`${IC_ERROR}`} />
                      </div>
                      <span>Information for creating upload files</span>
                    </div>
                    <div className={style.error_point}>
                      <b>A. objective_name:</b>
                      <ul>
                        <li>objective_name is a mandatory field </li>
                        <li>
                          Max 200 characters are allowed to be added in
                          objective_name field
                        </li>
                      </ul>
                      <b>B. objective_weightage</b>
                      <ul>
                        <li>It is a mandatory field.</li>
                        <li>Weightage must be in between 1 to 100%.</li>
                        <li>
                          Weightage will be the fixed for respective defined OKR
                          as per provided band structure.
                        </li>
                      </ul>
                      <b>C. emp_band</b>
                      <ul>
                        <li>It is a mandatory field.</li>
                      </ul>
                      <b>D. objective_description:</b>
                      <ul>
                        <li>objective_description is an optional field</li>
                        <li>
                          Max 512 characters are allowed to be added in
                          objective_description field
                        </li>
                      </ul>
                      <b>E. key_areas:</b>
                      <ul>
                        <li>key_areas is a mandatory field</li>
                        <li>
                          Max 512 characters are allowed to be added in
                          key_areas field
                        </li>
                      </ul>
                      <b>F. key_area_weightage: </b>
                      <ul>
                        <li>key_area_weightage is a mandatory field</li>
                        <li>
                          Only numerical values up to 5 digits are allowed
                          including floating point{" "}
                        </li>
                        <li>
                          key_area_weightage aggregate must be 100 for all added
                          key_areas
                        </li>
                        <li>Alphabets are not allowed in key_area_weightage</li>
                      </ul>
                      <b>G. unit:</b>
                      <ul>
                        <li>unit is a mandatory field</li>
                        <li>
                          (up, down, between) will be used when the unit of
                          measurement is numeric
                        </li>
                        <li>
                          (%) will be used when the unit of measurement is
                          percentage
                        </li>
                        <li>
                          (?) will be used when the unit of measurement is
                          yes/no
                        </li>
                        <li>
                          Only single unit symbol is allowed to be added for a
                          single key_areas
                        </li>
                      </ul>
                      <b>H. base_value and target_value</b>
                      <ul>
                        <li>
                          base and target value is only for unit symbol " 'up',
                          'down', 'between', '%', " not for " '?'"
                        </li>
                        <li>
                          base and target value must be a numerical value with
                          floating
                        </li>
                        <li>
                          Negative value is not allowed to be added in base and
                          target value
                        </li>
                        <li>
                          Alphabets are not allowed to be added in base and
                          target value
                        </li>
                        <li>
                          For 'up', 'down', 'between', only 10 digits are
                          allowed to be added with floating point
                        </li>
                        <li>
                          For '%', only 5 digits are allowed to be added with
                          floating point
                        </li>
                        <li>target and base value cannot be same</li>
                      </ul>
                      <b>I. tags</b>
                      <ul>
                        <li>tags is an optional field</li>
                        <li>
                          You will be allowed to add unique tag only once for a
                          single objective
                        </li>
                        <li>
                          You will be allowed to add as many tags as you want
                          for any objective
                        </li>
                        <li>
                          All added tags must be in single line separated by
                          comma (,) for given objective
                        </li>
                      </ul>
                      <b>J. stretch_keyresult</b>
                      <ul>
                        <li>stretch_keyresult is an optional field</li>
                        <li>
                          You will be allowed to add empty, "Yes" or "No" for a
                          single key result
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Col>
            <Col className="custom_modal_button">
              <Button variant="primary" onClick={submit}>
                Okay
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default DetailErrorInfoPopup;
