import UserProfile from "components/user_profile/user_profile";
import React, { useEffect } from "react";
import * as actions from "store/actions/index";
import { Col, Container, Row } from "react-bootstrap";
import style from "./CampaignQuizEmployeeTable.module.scss";
import ReviewerProfile from "components/reviewer-profile/reviewer-profile";
import CampaignQuizEmployeePerformance from "./CampaignQuizEmployeePerformance";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
export default function CampaignQuizEmployeeResult(props) {
  const location = useLocation();

  const dispatch = useDispatch();
  const employeeId = location.state?.employeeId;
  const employeeIds = {
    employeeId: location.state?.employeeId,
    parentGroupId: location.state?.parentGroupId,
    subGroupId: location.state?.subGroupId,
  };
  const { reviewer } = useSelector((state) => state.checkIn);
  const user = {
    employeeId: location.state?.employeeId,
    employeeInitial: location.state?.empInitial,
    displayName: location.state?.displayName,
    designation: location.state?.designation,
    department: location.state?.department,
    dispArr: location.state?.displayPicture,
    band: location.state?.band,
    firstName: location.state?.fname,
    totalExp: location.state?.totalExp,
    parentGroupName: location.state?.parentGroupName,
    expBeforeJoining: location.state?.expBeforeJoining,
    isExp: location.state?.isExp,
    email: location.state?.email,
    location: location.state?.location,
    country: location.state?.country,
  };
  useEffect(() => {
    dispatch(actions.GetReviewer(employeeIds));
  }, [dispatch, employeeId]);
  return (
    <>
      <Container>
        <SubNavigation />
        <Col>
          <h1 className="page-title pb-0">HR</h1>
          <span className="st-breadcrumb">
            HR<span> View Campaign</span>
            <span>View Employee Details</span>
          </span>
        </Col>
        <Row>
          <Col lg={8}>
            {user && <UserProfile user={user} />}
            <div
              className={[
                "custom-tabs",
                "custom-tabs-full-width",
                "tab-content-dashboard",
                style.home_tabs,
              ].join(" ")}
            ></div>
            <CampaignQuizEmployeePerformance />
          </Col>
          <Col lg={4}>
            {reviewer?.managersList?.length > 0 && (
              <ReviewerProfile managerList={reviewer.managersList} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
