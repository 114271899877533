import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  data: null,
  apiError: null,
  reviewer: null,
  surveyData: null,
  checkinStatusForEmployee: [],
  managerCheckinStatusForEmployee: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_HISTORY:
      return { ...state, ...action.payload, isLoading: false };
    case actionTypes.SET_MY_HISTORY:
      return { ...state, ...action.payload };
    case actionTypes.SURVEY_CYCLE_CLICKED:
      return { ...state, ...action.payload };
    case actionTypes.MY_HISTORY_FAILURE:
      return { ...state, apiError: action.apiError, isLoading: false };
    case actionTypes.MY_HISTORY_REVIEWER:
      return { ...state, reviewer: action.reviewer, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
