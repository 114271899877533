import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { noData } from "const/constValue";
import { IC_COLLAPSE, IC_EXPAND, MEMBER_NOT_FOUND } from "const/imgCost";
import { filterArray } from "util/general";
import * as actions from "store/actions/index";
import style from "./TeamMemberTable.module.scss";
import EmployeeListRow from "components/EmployeeListPopup/EmployeeListRow";
import NoDataFound from "components/NodataFound/NoDataFound";
import TeamMemberTimelineGraph from "./TeamMemberTimelineGraph";
import NoRecordFound from "components/NodataFound/NoRecordFound";
import Pagination from "components/Pagination/Pagination";
import FilterTeam from "components/FilterTeam/FilterTeam";
import Spinner from "shared/Spinner/Spinner";

function HRTeamMemberTable({ teamMemberList, handleAssessmentClick }) {
  const dispatch = useDispatch();
  const [pageOfItems, setPageOfItems] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const { multiExpanded } = useSelector((state) => state.managerDashboard);
  const { employeeTimelineForHr, isPageLoading } = useSelector((state) => state.performance);
  const pageSize = useSelector((state) => state.myTeam.pageSize);

  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  useEffect(() => {
    setTeamData(teamMemberList);
    setSearchFilter("");
  }, [teamMemberList]);

  const toggleExpander = (employeeIds) => {
    if (!employeeTimelineForHr.find((value) => value.employeeId === employeeIds?.employeeId)) {
      dispatch(actions.getEmployeeTimelineForHR("currentDate", employeeIds));
    }
    multiExpanded.includes(employeeIds?.employeeId)
      ? dispatch({ type: "REMOVE_MULTI_EXPAND", payload: employeeIds?.employeeId })
      : dispatch({ type: "MULTI_EXPAND", payload: employeeIds?.employeeId });
  };

  const handleMonthDropdown = (data, employeeIds) => {
    dispatch(actions.getEmployeeTimelineForHR(data.value, employeeIds));
  };

  const handleDeallocationGraphClick = (data, index, employeeIds) => {
    const win = window.open(
      `/hr/feedback/deallocation-feedback/${data["projectDeAllocationId" + index]}?empId=${
        employeeIds?.employeeId
      }&pgId=${employeeIds?.employeeParentGroupId}&sgId=${employeeIds?.employeeSubGroupId}`,
      "_blank"
    );
    win.focus();
  };

  const search = (event) => {
    let filteredData = null;
    const filters = {
      empName: event.target.value,
    };
    filteredData = filterArray(teamMemberList, filters);
    let filterReportee = null;
    let data = [];
    if (filteredData.length === 0) {
      teamMemberList.map((value) => {
        if (value.reportees && value.reportees.length > 0) {
          data = data.concat(value.reportees);
        }
      });
    }
    filterReportee = filterArray(data, filters);
    setFilteredData(filteredData.length === 0 ? filterReportee : filteredData);
    setSearchFilter(event.target.value);
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
    };
    filteredData = filterArray(teamMemberList, filters);

    setFilteredData(filteredData);
    setSearchFilter(searchFilter);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
    };
    filteredData = filterArray(teamMemberList, filters);
    setFilteredData(filteredData);
    setSearchFilter("");
  };

  useEffect(() => {
    filteredData.length > 0
      ? setTeamData(filteredData)
      : searchFilter
      ? setTeamData(filteredData)
      : setTeamData(teamMemberList);
  }, [filteredData]);

  return (
    <div className={style.team_member_table_wrapper}>
      {isPageLoading && <Spinner />}
      {teamMemberList && teamMemberList.length > 0 ? (
        <>
          <FilterTeam
            placeholder="Search for team members"
            filter={[]}
            filterValue={""}
            value={searchFilter ? searchFilter : ""}
            teamData={teamMemberList}
            onSearch={search}
            onRadioChange={() => {}}
            onReset={resetData}
            onResetSearch={resetSearch}
          />
          <div className="box-light-gradient"></div>
          <div className="mb-3">
            <div className="col-xl-12">
              {pageOfItems?.length > 0 ? (
                <div className={[style.dynamic_table_wrapper].join(" ")}>
                  <div className={style.data_container}>
                    <div className="pb-4"></div>
                    <div className={style.team_member_table_wrapper}>
                      <Table>
                        <thead>
                          <tr>
                            <td className={style.name}>Name</td>
                          </tr>
                        </thead>
                        <tbody>
                          {pageOfItems.map((member, index) => {
                            let employeeGraphData = employeeTimelineForHr.find(
                              (value) => value.employeeId === member?.employeeId
                            );
                            let employeeIds = {
                              employeeId: member.employeeId,
                              employeeParentGroupId: member?.parentGroupId,
                              employeeSubGroupId: member?.subGroupId,
                            };
                            let toggleExpand =
                              employeeGraphData && multiExpanded.includes(member?.employeeId) ? IC_COLLAPSE : IC_EXPAND;
                            return (
                              <React.Fragment key={index}>
                                <EmployeeListRow
                                  key={index}
                                  toggleExpander={() => toggleExpander(employeeIds)}
                                  toggleIcon={toggleExpand}
                                  index={index}
                                  displayPicture={member?.displayPicture || member?.empInitial}
                                  secondColum={null}
                                  handleEmployeeClick={() => {}}
                                  department={member?.department}
                                  designation={member?.designation}
                                  empName={member?.empName}
                                  id={member?.id}
                                />
                                {employeeGraphData && multiExpanded.includes(member?.employeeId) && (
                                  <tr className={style.inside_row}>
                                    <td colSpan="3">
                                      {employeeGraphData && employeeGraphData?.timelineGraph?.length > 0 ? (
                                        <TeamMemberTimelineGraph
                                          handleTeamCycleDropdown={(data) => handleMonthDropdown(data, employeeIds)}
                                          employeeGraphData={employeeGraphData}
                                          handleGraphClick={handleAssessmentClick}
                                          handleDeallocationGraphClick={(data, index) =>
                                            handleDeallocationGraphClick(data, index, employeeIds)
                                          }
                                        />
                                      ) : (
                                        <>
                                          <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                                          <div className="gap120"></div>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <NoRecordFound
                    image={`${MEMBER_NOT_FOUND}`}
                    title={noData.MEMBER_NOT_FOUND}
                    // description={noData.MEMBER_NOT_FOUND_DESCRIPTION}
                  />

                  <div className="gap120"></div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div>
          <NoRecordFound
            image={`${MEMBER_NOT_FOUND}`}
            title={noData.MEMBER_NOT_FOUND}
            // description={noData.MEMBER_NOT_FOUND_DESCRIPTION}
          />

          <div className="gap120"></div>
        </div>
      )}
      {teamMemberList && teamMemberList.length > 0 && (
        <Pagination pageSize={pageSize} items={teamData} resetData={resetData} onChangePage={onChangePage} />
      )}
    </div>
  );
}

export default HRTeamMemberTable;
