import React from "react";
import { Button, Modal } from "react-bootstrap";
import style from "./ListViewModal.module.scss";

function ListViewModal({ show, hide, title, lists }) {
  return (
    <Modal show={show} onHide={() => hide()} size="lg" animation={false} className="custom_modal_popup">
      <div className="custom_modal_container custom_lg_modal_container">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={style.mBody}>
          <div className="box-light-gradient"></div>
          <div className={style.lists_wrapper}>
            <ul>
              {lists.map((item, index) => (
                <li key={item + index}>{item}</li>
              ))}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer className={["custom_modal_button justify-content-start border-0"].join(" ")}>
          <Button variant="primary" className="fs-500" onClick={() => hide()}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default ListViewModal;
