import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import PropTypes from "prop-types";
import HideAndShow from "../../HideAndShow/HideAndShow";

function MeetingNotes({ meetingNotes }) {
  const [hide, setHide] = useState(false);

  return (
    <div className={["card"].join(" ")}>
      <div className="card_info">
        <div className="card_title flex-wrap">
          <span className="card_title_text">Meeting Notes</span>
          <span className="card_title_description">
            You and respective person will be able to see these notes while discussion
          </span>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>

      <Collapse in={!hide}>
        <div className={style.tp_inner_wrapper}>
          {meetingNotes && (
            <ul className="common_list_items pt10 text-break p-4">
              {Object.values(meetingNotes).map((point, index) => (
                <li className="fs-500 mb-2" key={"MEETING_NOTES" + index}>
                  {point}
                </li>
              ))}
            </ul>
          )}
        </div>
      </Collapse>
    </div>
  );
}

MeetingNotes.propTypes = {
  sharedNotes: PropTypes.object,
};

export default MeetingNotes;
