import React from "react";
import DetailsChartView from "./DetailsChartView/DetailsChartView";
import Spinner from "shared/Spinner/Spinner";

export default function CheckInDetailsCharts({
  feedbackGraphData,
  isGraphDataLoding,
  exportQuestionWise,
}) {
  return (
    <React.Fragment>
      {isGraphDataLoding && <Spinner />}
      <DetailsChartView isEmployee={true} feedbackGraphData={feedbackGraphData} exportQuestionWise={exportQuestionWise}/>
      <DetailsChartView isEmployee={false} feedbackGraphData={feedbackGraphData} exportQuestionWise={exportQuestionWise}/>
    </React.Fragment>
  );
}
