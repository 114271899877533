import React, { useState, useEffect } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Label, BarChart, XAxis, YAxis, Bar, Tooltip, Text } from "recharts";
import style from "./EmployeeQuizResultCharts.module.scss";
import _ from "lodash";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function QuizPieAndBarChartLocation(props) {
  const [currentVal, setCurrentVal] = useState(null);
  const [pieChartDataLocation, setPieChartDataLocation] = useState([]);
  const [barChartDataLocation, setBarChartDataLocation] = useState([]);
  const [barChartDataCountry, setBarChartDataCountry] = useState([]);
  const [locationLength, setlocationLength] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("All");
  const { countryList } = useSelector((state) => state.campaign);

  useEffect(() => {
    const PieChartsArray = [];
    const BarChartArray = [];

    if (props?.quizResultData !== undefined) {
      let pieData = props?.quizResultData === undefined || props?.quizResultData === null ? [] : props?.quizResultData;
      PieChartsArray.push(
        {
          name: "Pass",
          value: pieData?.organisationPassCount,
          percentageValue: pieData?.organisationPassPercentage,
        },
        {
          name: "Fail",
          value: pieData?.organisationFailCount,
          percentageValue: pieData?.organisationFailPercentage,
        }
      );
      setPieChartDataLocation(_.sortBy(PieChartsArray, ["name"], ["asc"]));

      let barData =
        props?.quizResultData?.locationWiseChartList === undefined ||
        props?.quizResultData?.locationWiseChartList === null
          ? []
          : props?.quizResultData?.locationWiseChartList;
      barData.map((barLocation) => {
        BarChartArray.push({
          name: barLocation.name,
          passCount: barLocation.passCount,
          failCount: barLocation.failCount,
          notAttemptedCount: barLocation.notAttemptedCount,
          passPercentageValue: barLocation.passPercentage,
          failPercentageValue: barLocation.failPercentage,
          notAttemptedPercentageValue: barLocation.notAttemptedPercentage,
          countryName: barLocation.countryName,
        });
        return barLocation;
      });
      setBarChartDataLocation(_.sortBy(BarChartArray, ["name"], ["asc"]));
      setBarChartDataCountry(_.sortBy(BarChartArray, ["name"], ["asc"]));
      setlocationLength(barData.length > 7 ? true : false);
    } else {
      setPieChartDataLocation([]);
      setBarChartDataCountry([]);
      setBarChartDataLocation([]);
    }
  }, [props]);

  function CustomLabel({ viewBox }) {
    const { cx, cy } = viewBox;
    let totalValue = 0;
    pieChartDataLocation.forEach((dt) => {
      totalValue += dt.value;
    });
    const total = `${totalValue}`;
    return (
      <>
        <text
          x={cx}
          y={cy - 12}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            Total
          </tspan>
        </text>
        <text
          x={cx}
          y={cy + 15}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            {total}
          </tspan>
        </text>
      </>
    );
  }

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    let percentValue;
    let count;
    if (currentVal === "Pass") {
      percentValue = payload[0]?.payload?.passPercentageValue?.toFixed(2);
      count = payload[0]?.payload?.passCount;
    } else if (currentVal === "Fail") {
      percentValue = payload[0]?.payload?.failPercentageValue?.toFixed(2);
      count = payload[0]?.payload?.failCount;
    } else if (currentVal === "Not Attempted") {
      percentValue = payload[0]?.payload?.notAttemptedPercentageValue?.toFixed(2);
      count = payload[0]?.payload?.notAttemptedCount;
    }
    if (active) {
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper">
              <p className="recharts-tooltip-label">{currentVal}</p>
              <span className="recharts-tooltip-value">{`${percentValue}%`}</span>
              <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${count}`}</span>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomTooltipForPieChart = (props) => {
    const { active, payload } = props;
    if (active) {
      return (
        <>
          <div className="recharts-tooltip-custom-wrapper">
            <p className="recharts-tooltip-label">{payload[0].name}</p>
            {<span className="recharts-tooltip-value">{`${payload[0].payload.percentageValue.toFixed(2)}%`}</span>}
            <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${
              payload[0]["value"]
            }`}</span>
          </div>
        </>
      );
    }

    return null;
  };

  const barChartDataForlegend = [
    {
      name: "Pass",
    },
    {
      name: "Fail",
    },
    {
      name: "Not Attempted",
    },
  ];

  const COLORS = {
    Pass: "#6ACF00",
    Fail: "#F07575",
    "Not Attempted": "#FDC861",
  };

  const COLORS_PIE_CHART = {
    Pass: "#00C4BC",
    Fail: "#FDC861",
  };

  const handleCycleList = (e) => {
    setDropdownValue(countryList.filter((item) => item?.value === e.value));
    if (e.value !== "All") {
      const newData = barChartDataCountry.filter((item) => item?.countryName === e.value);
      setBarChartDataLocation(newData);
    } else {
      setBarChartDataLocation(barChartDataCountry);
    }
  };

  return (
    <>
      <div className="d-flex">
        <div className="graph-item width50">
          <h5>Aggregate Result Status: attempted/not attempted</h5>
          <div
            className={
              barChartDataLocation?.length > 7
                ? style.pie_chart_location_wrapper
                : style.pie_chart_location_wrapper_less_location
            }
          >
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={pieChartDataLocation}
                  cx="45%"
                  cy="50%"
                  cursor="pointer"
                  innerRadius={100}
                  outerRadius={125}
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="value"
                >
                  <Label width={30} position="center" content={<CustomLabel value1={pieChartDataLocation} />}></Label>
                  {pieChartDataLocation.map((entry, index) => {
                    return <Cell key={index} fill={COLORS_PIE_CHART[entry["name"]]} />;
                  })}
                </Pie>
                <Tooltip content={<CustomTooltipForPieChart />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className={barChartDataLocation?.length > 7 ? style.location_pie_legend : ""}>
            <div className={style.legent_wrapper}>
              <ul className={style.lenegd_list}>
                {pieChartDataLocation.map((entry, index) => {
                  return (
                    <li className={style.legend_item} key={index}>
                      <span
                        className={style.legend_color}
                        style={{ backgroundColor: COLORS_PIE_CHART[entry["name"]] }}
                      ></span>
                      {entry["name"]}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="graph-item width100">
          <div className={locationLength ? style.location_dropdown_wrapper : ""}>
            <div>
              <h5 className={["pl-4", style.right_text_title].join(" ")}>Result Status</h5>
              <h6 className={["pl-4", style.bar_chart_sub_header, style.right_text_title].join(" ")}>
                <span>Attempted</span> ( Showing Pass - Fail percent )
              </h6>
            </div>
            {locationLength && (
              <div className="pl-4 cycle-list-wrapper d-flex dropdown-multiselect">
                <Form.Group controlId="">
                  <Select
                    options={countryList.length > 0 ? _.sortBy(countryList, ["label"], ["asc"]) : countryList}
                    className="custom-default-dd-box"
                    classNamePrefix="select"
                    placeholder="Select Country"
                    isSearchable={true}
                    value={dropdownValue}
                    onChange={handleCycleList}
                  />
                </Form.Group>
              </div>
            )}
          </div>

          {barChartDataLocation?.length > 7 ? (
            <div className={style.location_wrapper}>
              <ResponsiveContainer>
                <BarChart
                  data={barChartDataLocation}
                  layout="vertical"
                  barSize={8}
                  barGap={2}
                  margin={{ top: 30, right: 0, left: 15, bottom: 0 }}
                >
                  <XAxis type="number" axisLine={false} />
                  <YAxis
                    type="category"
                    width={100}
                    dataKey="name"
                    axisLine={false}
                    tick={{ wordWrap: "break-word" }}
                    interval={0}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey="failCount"
                    fill={COLORS["Fail"]}
                    onMouseOver={() => setCurrentVal("Fail")}
                    onMouseLeave={() => setCurrentVal(null)}
                    stackId="a"
                    radius={[10, 0, 0, 10]}
                  ></Bar>
                  <Bar
                    dataKey="passCount"
                    fill={COLORS["Pass"]}
                    onMouseOver={() => setCurrentVal("Pass")}
                    onMouseLeave={() => setCurrentVal(null)}
                    stackId="a"
                    radius={[0, 10, 10, 0]}
                  ></Bar>
                  <Bar
                    dataKey="notAttemptedCount"
                    fill={COLORS["Not Attempted"]}
                    onMouseOver={() => {
                      setCurrentVal("Not Attempted");
                    }}
                    onMouseLeave={() => setCurrentVal(null)}
                    radius={[10, 10, 10, 10]}
                  ></Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className={style.legent_wrapper}>
                <ul className={style.lenegd_list}>
                  {barChartDataForlegend.map((entry, index) => {
                    return (
                      <li className={style.legend_item} key={index}>
                        <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                        {entry["name"]}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            <div style={{ width: "100%", height: 350 }}>
              <ResponsiveContainer>
                <BarChart
                  data={barChartDataLocation}
                  margin={{ top: 70, right: 0, left: 0, bottom: 0 }}
                  barSize={25}
                  barGap={1}
                >
                  <XAxis dataKey="name" axisLine={false} />
                  <YAxis axisLine={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey="failCount"
                    fill={COLORS["Fail"]}
                    onMouseOver={() => setCurrentVal("Fail")}
                    onMouseLeave={() => setCurrentVal(null)}
                    stackId="a"
                  ></Bar>
                  <Bar
                    dataKey="passCount"
                    fill={COLORS["Pass"]}
                    onMouseOver={() => setCurrentVal("Pass")}
                    onMouseLeave={() => setCurrentVal(null)}
                    stackId="a"
                  ></Bar>
                  <Bar
                    dataKey="notAttemptedCount"
                    fill={COLORS["Not Attempted"]}
                    onMouseOver={() => {
                      setCurrentVal("Not Attempted");
                    }}
                    onMouseLeave={() => setCurrentVal(null)}
                  ></Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className={style.legent_wrapper}>
                <ul className={style.lenegd_list}>
                  {barChartDataForlegend.map((entry, index) => {
                    return (
                      <li className={style.legend_item} key={index}>
                        <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                        {entry["name"]}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
