import { noData, placeHolder } from "const/constValue";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { IC_INPUT_SEARCH } from "../../const/imgCost";
import { filterArray, gradeValue } from "../../util/general";
import { toFixedNumber } from "../../util/utils";
import NoDataFound from "../NodataFound/NoDataFound";
import EmployeeList from "./EmployeeList";
import style from "./EmployeeListPopup.module.scss";

function EmployeeListPopup({
  employees,
  openPopup,
  closePopup,
  handleExport,
  secondColum,
  selectedCycleName,
  handleLineChart,
  isExport,
}) {
  let [team, setTeam] = useState(employees);
  useEffect(() => {
    setTeam(employees);
    return () => {
      setTeam([]);
    };
  }, []);
  let [searchFilter, setSearchFilter] = useState("");
  let className = "form-control-rounded form-search-item";
  if (searchFilter.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }
  const search = (e) => {
    let filteredData = null;
    const filters = {
      empName: e.target.value,
    };
    filteredData = filterArray(employees, filters);
    setTeam(filteredData);
    setSearchFilter(e.target.value);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
    };
    filteredData = filterArray(employees, filters);
    setTeam(filteredData);
    setSearchFilter("");
  };

  return (
    <>
      <Modal size="lg" show={openPopup} onHide={closePopup} animation={false} className={style.assigneepopup_wrapper}>
        <div className={style.modal_header}>
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-md-12 col-12">
              <h1>Employees View</h1>
              <h2>{selectedCycleName}</h2>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className={style.search_bar}>
                <Form className={style.allobjective_searchbar}>
                  <Form.Group controlId="" className="form-postfix mb-0 w-100">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder={placeHolder.SEARCH_TEAM}
                      name="searchBox"
                      className={className}
                      onChange={search}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <Button className="close-button" onClick={resetSearch} type="reset">
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body>
          <div className="box-light-gradient"></div>
          <div className="col-xl-12">
            <div className={style.data_container}>
              {team && team.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <td className={style.name}>Name</td>
                      <td className={style.objective_assigned}>{secondColum}</td>
                      <td className={style.status}>Location</td>
                    </tr>
                  </thead>
                  <tbody>
                    {team.map((value, index) => {
                      let graphData = [];
                      if (secondColum.endsWith("Rating")) {
                        if (value.ratingHistory?.length > 0) {
                          graphData = value.ratingHistory.map((v) => {
                            return {
                              ...v,
                              rating: v.rating || "0",
                            };
                          });
                        }
                      } else {
                        if (value.ratingHistory?.length > 0) {
                          graphData = value.ratingHistory.map((v) => {
                            return {
                              ...v,
                              grade: v.grade || "0",
                              GradeRating: gradeValue(v.grade),
                            };
                          });
                        }
                      }
                      return (
                        <EmployeeList
                          gradeHistory={graphData?.reverse()}
                          // employee={value.employee}
                          current={
                            secondColum.endsWith("Rating")
                              ? toFixedNumber(value.rating, 2)
                              : value.grade
                              ? value.grade + (value.rating?.toFixed(2) ? ` (${value.rating?.toFixed(2)})` : "")
                              : toFixedNumber(value.rating, 2)
                          }
                          handleLineChart={handleLineChart}
                          index={index}
                          secondColum={false}
                          employee={value}
                          key={index}
                          graphYAxis={"rating"}
                          graphLineValue={secondColum.endsWith("Rating") ? "rating" : "GradeRating"}
                          graphXAxis={"period"}
                          graphType={secondColum.endsWith("Rating") ? "number" : "Grade"}
                        />
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.assigned_modal_footer}>
          {isExport && (
            <Button
              type="button"
              variant={"primary"}
              className={["btn-round", style.btn_save].join(" ")}
              onClick={() => {
                resetSearch();
                closePopup();
                handleExport();
              }}
            >
              <strong>Export</strong>
            </Button>
          )}
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_cancel].join(" ")}
            onClick={() => {
              resetSearch();
              setTeam();
              closePopup();
            }}
          >
            <strong>Close</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmployeeListPopup;
