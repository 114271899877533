import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import style from "./AddScaleModal.module.scss";
import { TEMPLATE_SCALE } from "../../../../util/endpoints";
import http from "../../../../util/http";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions/index";
import storage from "../../../../util/storage";
import { errorToast, successToast } from "util/general";

const AddScaleModal = (props) => {
  const [title, setTitle] = useState("");
  const [scaleValue, setScaleValue] = useState("");
  const [scaleRate, setScaleRate] = useState("");
  const dispatch = useDispatch();
  let options = scaleValue.split("/").map((obj, i) => {
    return (
      <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm no_mar" key={i}>
        <input id={i} type="radio" />
        <label htmlFor={i}>{obj}</label>
      </div>
    );
  });
  function saveScale() {
    if (scaleValue.split("/").length === scaleRate.split(",").length) {
      let data = {
        scaleDesc: title,
        name: scaleValue,
        value: scaleRate,
      };
      const campaignId = storage.get("cId");
      let config = campaignId && {
        params: {
          campaignId,
        },
      };
      http
        .post(TEMPLATE_SCALE, {}, data, config)
        .then((response) => response.data)
        .then((response) => {
          dispatch(actions.getTemplateScale());
          setTitle("");
          setScaleValue("");
          successToast("Scale Save successfully");
          props.hide();
        })
        .catch(console.error);
    } else {
      errorToast("Options and values not match");
    }
  }
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} size="lg" className="custom_modal_popup">
        <div className={[style.scale_modal, "custom_modal_container"].join(" ")}>
          <Modal.Header className={style.mHeader}>
            <Modal.Title> Scale </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="form-group">
              <label className={style.dd_title}>Name</label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                value={props.title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></Form.Control>
            </div>
            <div className="form-group">
              <label className={style.dd_title}>
                Scale Options <span className="text-muted">(Enter slash(/) to seperate every value)</span>
              </label>
              <div className="textarea-wrapper">
                <Form.Control
                  as="textarea"
                  placeholder="Enter slash(/) to seperate every value"
                  value={props.scaleValue}
                  onChange={(e) => {
                    setScaleValue(e.target.value);
                  }}
                ></Form.Control>
              </div>
              <div className="gap30"></div>
              <label className={style.dd_title}>
                Scale Values <span className="text-muted">(Enter comma(,) to seperate every value)</span>
              </label>
              <div className="textarea-wrapper">
                <Form.Control
                  as="textarea"
                  placeholder="Enter comma(,) to seperate every value"
                  value={props.scaleRate}
                  onChange={(e) => {
                    setScaleRate(e.target.value);
                  }}
                ></Form.Control>
              </div>
            </div>
            <div className="form-group">
              <label className={style.dd_title}>Preview</label>
              {options}
            </div>
          </Modal.Body>

          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={() => saveScale()}>
              Save
            </Button>
            <Button variant="secondary" className="fs-500" onClick={() => props.hide()}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default AddScaleModal;
