import React from "react";
import { Modal, Button } from "react-bootstrap";
import style from "./CheckInDetailsViewPopup.module.scss";
import { MonthlyCheckinTexts } from "const/constValue";
import FeedbackQuestionsTable from "components/FeedbackQuestionsTable/FeedbackQuestionsTable";

const CheckInDetailsViewPopup = (props) => {
  const { openPopup, setOpenPopup, historyDetails, period } = props;
  
  return (
    <>
      <Modal
        size="lg"
        show={openPopup}
        onHide={() => setOpenPopup(true)}
        animation={false}
      >
        <div className={style.modal_header}>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="row">
              <div className={style.heading_wrapper}>
                <h2>{MonthlyCheckinTexts.MONTHLY_CHECK_IN.replace(':', '')}</h2>
                <h6>
                  <span className={style.greyText}>{period ? period : historyDetails?.period}&nbsp;{MonthlyCheckinTexts.CHECK_IN_PERIOD}&nbsp;</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body>
          <div className="box-light-gradient"></div>
          <div className={`col-xl-12 ${style.content_wrapper}`}>
            <div className={style.your_feedback}>
              <FeedbackQuestionsTable
                title="Employee Feedback"
                questionAnswerList={historyDetails?.questionAnswerListEmployee}
                viewsTtitle="Employee View(s)"
                comment={historyDetails?.employeeComment}
              />
            </div>
            <div className={style.manager_feedback}>
              <FeedbackQuestionsTable
                title="Manager Feedback"
                questionAnswerList={historyDetails?.questionAnswerListManager}
                viewsTtitle="Manager View(s)"
                comment={historyDetails?.managerComment}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.modal_footer}>
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_close].join(" ")}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <strong>Close</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CheckInDetailsViewPopup;
