import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import style from "../TabHighlights.module.scss";
import { IC_TREE_VIEW2 } from "const/imgCost";
import HideAndShow from "../../HideAndShow/HideAndShow";
import ObjectiveRow from "./ObjectiveRow";

export default function HighlightObjective({ currentObjectives, isPage, employeeId }) {
  const [hide, setHide] = useState(false);
  const totalObjectives = currentObjectives && currentObjectives.length;
  // let priority_id = `priority_${props.priorityKey}`;

  return (
    <div>
      <div className="card objectives">
        <div className="card_info">
          <div className="card_title">
            <div className="d-flex">
              <ReactSVG src={`${IC_TREE_VIEW2}`} className="icon-svg" />
              <div className="card_title_text">
                Objectives <span>({totalObjectives || 0})</span>
              </div>
            </div>
          </div>
          <HideAndShow hide={hide} setHide={setHide} />
        </div>
        {!hide && (
          <div className="card_details">
            <div className="box-light-gradient"></div>
            <div className={style.to_do_row}>
              <div className={style.objectiveContent}>
                {totalObjectives > 0 ? (
                  currentObjectives
                    .filter((value, index) => index < 2)
                    .map((objective) => (
                      <ObjectiveRow objective={objective} key={"OBJECTIVE_" + objective.objectiveId} />
                    ))
                ) : (
                  <div className="noObjective">New objective is not assigned</div>
                )}
              </div>
            </div>
            {isPage === "Reportee" ? (
              <Link className={["viewall", "mt-0"].join(" ")} to={`/objectives/all-objectives/${employeeId}`}>
                View all objectives
              </Link>
            ) : (
              <Link className={["viewall", "mt-0"].join(" ")} to="/objectives/my-objectives">
                View all of your objectives
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

HighlightObjective.propTypes = {
  currentObjectives: PropTypes.array.isRequired,
};

HighlightObjective.defaultProps = {
  currentObjectives: [],
};
