import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import style from "./ObjeciveModal.module.scss";
import CommentsTop from "../../../../CommentBox/CommentsTop";
import Comment from "../../../../CommentBox/Comment";
import CommentBox from "../../../SelfAssessment/Components/CommentBox";
import KeyResultPoint from "../../../../KeyResultPoint/KeyResultPoint";

function KeyResultCommentModal(props) {
  const [comment, setComment] = useState("");
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} size="lg" animation={false} className="custom_modal_popup">
        <div className="custom_modal_container custom_lg_modal_container">
          <Modal.Header className={style.mHeader}>
            <Modal.Title>
              {props.objective.title}
              <span>{props.objective.keyPoints.length || 0} Key Results</span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="box-light-gradient"></div>
            <div className={style.activites_item}>
              <h4 className={style.key_result_title}>Key Result</h4>
              <KeyResultPoint keypoint={props.keyPoint} />
              <div
                className={[
                  style.reject_comment_wrapper,
                  "view_comment_box_wrapper",
                  props.isSubmitted && "bg-white",
                ].join(" ")}
              >
                <CommentsTop heading="Reviewer's Comment(s)" />
                {props.isSubmitted && (
                  <Comment
                    image={props.objective?.updatedByManager?.displayPicture || props.objective?.updatedByManager?.empInitial || props.objective.managerData?.displayPicture}
                    selectedColor="#CC54FF"
                    empName={props.objective?.updatedByManager?.empName || props.objective.managerData?.empName}
                    commentedOn={props.keyPoint.updatedDate}
                    comment={props.keyPoint.comment || "-"}
                    isPrivate={false}
                    deletable={false}
                  />
                )}
                {!props.isSubmitted && (
                  <CommentBox
                    image={props.loggedInUser?.displayPicture || props.loggedInUser?.empInitial}
                    selectedColor="#CC54FF"
                    blurHandler={(e) => setComment(e.target.value)}
                    comment={props.keyPoint.comment}
                    commentLength={500}
                    error={false}
                  />
                )}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="custom_modal_button justify-content-start">
            {!props.isSubmitted && (
              <Button
                variant="primary"
                className="fs-500"
                onClick={() => props.saveComment(comment, props.keyPoint, props.objective)}
              >
                Save
              </Button>
            )}
            <Button
              variant={props.isSubmitted ? "primary" : "secondary"}
              className={["fs-500", !props.isSubmitted && "ml-3"].join(" ")}
              onClick={() => props.hide()}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

KeyResultCommentModal.propTypes = {
  objective: PropTypes.object,
};

export default KeyResultCommentModal;
