import React from 'react'
import PropTypes from 'prop-types'
import UniversityKeyResultRow from './UniversityKeyResultRow'
import style from "./UniversityKeyResult.module.scss"
import { Accordion } from 'react-bootstrap'

function UniversityKeyResult({keyPoints}) {
  
  return (
    <div className={style.keyresult_accordion_wrapper} xl={12}> 
        <Accordion defaultActiveKey={"0"} className="custom-accordion">
        {keyPoints.map((keypoint, index) => <UniversityKeyResultRow keypoint={keypoint} key={index} index={index} />) }
        </Accordion>
    </div>
  )
}

UniversityKeyResult.propTypes = {
  keyPoints: PropTypes.array
}

export default UniversityKeyResult

