import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Form, Row, Container } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import MultiSelect from "react-multi-select-component";
import Select from "react-select";
import style from "./AdminCreateRoles.module.scss";
import * as actions from "store/actions/index";
import { errorToast, requiredFieldMissing, successToast } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import routePath from "const/routePath";

export default function AdminCreateRoles(props) {
  const editId = props.match?.params?.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    roleData,
    isLoading,
    bandOption,
    locationOption,
    indiaDDOption,
    departmentOption,
    designationOption,
    modulesOption,
    rightsOption,
    apiResponse,
    subGroupClient,
  } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(actions.getSubGroupClient());
  }, [dispatch]);

  useEffect(() => {
    if (editId !== "CREATE") {
      dispatch(actions.getRoleDataForEdit(editId));
    }
  }, [dispatch, editId]);

  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.success) {
        successToast(apiResponse.message);
        history.push(routePath.ADMIN_ROLES);
      } else errorToast(apiResponse.message);
      dispatch({ type: "CLEAR_ROLE_ASSIGN_RESPONSE" });
    }
  }, [dispatch, apiResponse, history]);

  const handleCreateRole = () => {
    // eslint-disable-next-line no-useless-escape
    var regex = /[ !@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (roleData?.role && regex.test(roleData.role)) {
      errorToast("Please only use standard alphanumerics in role name");
    }

    if (
      roleData &&
      roleData.role &&
      roleData.rights?.length > 0 &&
      roleData.locations?.length > 0 &&
      roleData.departments?.length > 0 &&
      roleData.designations?.length > 0 &&
      roleData.modules?.length > 0
    ) {
      let body = {
        roleName: roleData.role,
        band: roleData.band?.map((v) => v.value),
        location: roleData.locations.map((v) => v.value),
        modules: roleData.modules.map((v) => v.subModuleName),
        department: roleData.departments.map((v) => v.value),
        designation: roleData.designations.map((v) => v.value),
        rightsList: roleData.rights.map((v) => v.value),
        indiaDD:
          roleData.indiaDD?.length > 0
            ? roleData.indiaDD.map((v) => {
                return {
                  employeeId: v.value,
                  parentGroupId: v.parentGroupId,
                  subGroupId: v.subGroupId,
                };
              })
            : [],
        subGroupId: roleData.subGroup.value,
      };
      if (editId !== "CREATE") dispatch(actions.updateRoleRights(body));
      else dispatch(actions.createRoleRights(body));
    } else {
      requiredFieldMissing();
    }
  };

  const handleSubGroup = (item) => {
    dispatch(actions.getAllDataForAssignRole(editId !== "CREATE", item.value));
    dispatch({
      type: "SET_ROLES_DATA_IN_ADMIN",
      payload: {
        subGroup: item,
      },
    });
  };

  return (
    <div className={style.roll_based_skill_wrapper}>
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0 mt-3">Admin</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.ADMIN_ROLES}>Admin</Link>
              <span>Roles</span>
              <span>{editId !== "CREATE" ? "Edit Role" : "Create New Role"} </span>
            </span>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <div className={[style.define_admin_roles_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Roles and Responsibilities</h2>
                  <h3>Assign rights for the following roles.</h3>
                </div>
              </div>
              <div></div>
              <div className="box-inner-pad">
                <div className="mb-4">
                  <Form.Group className={[style.fg_text_box].join(" ")} controlId="">
                    <Form.Label className={style.dd_title}>
                      Role Name<span className="text-danger">*</span>
                      <br />
                      <span className={[style.dd_note, "text-danger"].join(" ")}>
                        Space &amp; Special characters are not supported
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Define New Role"
                      value={roleData?.role || ""}
                      disabled={editId !== "CREATE" ? true : false}
                      maxLength="100"
                      onChange={(e) =>
                        dispatch({
                          type: "SET_ROLES_DATA_IN_ADMIN",
                          payload: {
                            role: e.target.value,
                          },
                        })
                      }
                    />
                  </Form.Group>
                </div>

                <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
                  <Form.Group controlId="">
                    <Form.Label className={style.dd_title}>
                      Sub Group<span className="text-danger">*</span>
                    </Form.Label>
                    <div className="tags_wrapper">
                      <Select
                        options={subGroupClient}
                        className="custom-default-dd-box"
                        classNamePrefix="select"
                        placeholder="Select..."
                        value={roleData?.subGroup || ""}
                        onChange={handleSubGroup}
                      />
                    </div>
                  </Form.Group>
                </div>

                {(rightsOption?.length > 0 || modulesOption?.length > 0) && (
                  <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
                    {rightsOption?.length > 0 && (
                      <Form.Group controlId="">
                        <Form.Label className={style.dd_title}>
                          Rights Assign<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          <MultiSelect
                            className="multi-select"
                            classNamePrefix="select"
                            isSearchable={true}
                            options={rightsOption}
                            value={roleData?.rights}
                            onChange={(e) =>
                              dispatch({
                                type: "SET_ROLES_DATA_IN_ADMIN",
                                payload: {
                                  rights: e,
                                },
                              })
                            }
                          />
                        </div>
                      </Form.Group>
                    )}
                    {modulesOption?.length > 0 && (
                      <Form.Group controlId="">
                        <Form.Label className={style.dd_title}>
                          Modules<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          {!(roleData?.modules?.length > 0) && <span className="select_placeholder">Select...</span>}
                          <Multiselect
                            groupBy="moduleName"
                            id={"multi-select-dd-box"}
                            options={modulesOption}
                            selectedValues={roleData?.modules}
                            onRemove={(list) =>
                              dispatch({
                                type: "SET_ROLES_DATA_IN_ADMIN",
                                payload: {
                                  modules: list,
                                },
                              })
                            }
                            onSelect={(list) =>
                              dispatch({
                                type: "SET_ROLES_DATA_IN_ADMIN",
                                payload: {
                                  modules: list,
                                },
                              })
                            }
                            closeOnSelect={false}
                            displayValue="subModuleName"
                            showCheckbox={true}
                          />
                        </div>
                      </Form.Group>
                    )}
                  </div>
                )}
                {(departmentOption?.length > 0 || designationOption?.length > 0) && (
                  <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
                    {departmentOption?.length > 0 && (
                      <Form.Group controlId="">
                        <Form.Label className={style.dd_title}>
                          Department<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          <MultiSelect
                            className="multi-select"
                            classNamePrefix="select"
                            isSearchable={true}
                            options={departmentOption}
                            value={roleData?.departments}
                            onChange={(e) =>
                              dispatch({
                                type: "SET_ROLES_DATA_IN_ADMIN",
                                payload: {
                                  departments: e,
                                },
                              })
                            }
                          />
                        </div>
                      </Form.Group>
                    )}
                    {designationOption?.length > 0 && (
                      <Form.Group controlId="">
                        <Form.Label className={style.dd_title}>
                          Designation<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          <MultiSelect
                            className="multi-select"
                            classNamePrefix="select"
                            isSearchable={true}
                            options={designationOption}
                            value={roleData?.designations}
                            onChange={(e) =>
                              dispatch({
                                type: "SET_ROLES_DATA_IN_ADMIN",
                                payload: {
                                  designations: e,
                                },
                              })
                            }
                          />
                        </div>
                      </Form.Group>
                    )}
                  </div>
                )}
                {(bandOption?.length > 0 || locationOption?.length > 0) && (
                  <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
                    {bandOption?.length > 0 && (
                      <Form.Group controlId="">
                        <Form.Label className={style.dd_title}>
                          Band<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          <MultiSelect
                            className="multi-select"
                            classNamePrefix="select"
                            isSearchable={true}
                            options={bandOption}
                            value={roleData?.band}
                            onChange={(e) =>
                              dispatch({
                                type: "SET_ROLES_DATA_IN_ADMIN",
                                payload: {
                                  band: e,
                                },
                              })
                            }
                          />
                        </div>
                      </Form.Group>
                    )}
                    {locationOption?.length > 0 && (
                      <Form.Group controlId="">
                        <Form.Label className={style.dd_title}>
                          Location<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          <MultiSelect
                            className="multi-select"
                            classNamePrefix="select"
                            isSearchable={true}
                            options={locationOption}
                            value={roleData?.locations}
                            onChange={(e) =>
                              dispatch({
                                type: "SET_ROLES_DATA_IN_ADMIN",
                                payload: {
                                  locations: e,
                                },
                              })
                            }
                          />
                        </div>
                      </Form.Group>
                    )}
                  </div>
                )}
                {indiaDDOption?.length > 0 && (
                  <div className="radio-wrapper d-flex dropdown-multiselect">
                    <Form.Group controlId="">
                      <Form.Label className={style.dd_title}>India DD</Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          className="multi-select"
                          classNamePrefix="select"
                          isSearchable={true}
                          options={indiaDDOption}
                          value={roleData?.indiaDD}
                          onChange={(e) =>
                            dispatch({
                              type: "SET_ROLES_DATA_IN_ADMIN",
                              payload: {
                                indiaDD: e,
                              },
                            })
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                )}
              </div>
            </div>
            <div className={["box-flex-center box", style.saveBox].join(" ")}>
              <div className={style.save_box_left}>
                <Button type="button" variant={"primary"} onClick={handleCreateRole}>
                  <strong>{editId !== "CREATE" ? "Edit" : "Create"}</strong>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
