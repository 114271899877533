import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { backGroundColor } from "const/constValue";
import { monthOptions2 } from "const/options";
import { IC_COMMENT_ROUND } from "../../const/imgCost";
import HideAndShow from "../HideAndShow/HideAndShow";
import RoundEmployeeList from "../RoundEmployeeList/RoundEmployeeList";
import style from "./PendingApprovals.module.scss";
import Select from "react-select";
import { dateFormat1 } from "../../util/dateUtils";
import routePath from "const/routePath";

function UpComingItems({
  pendingOneOnOnes,
  previousOneOnOnes,
  upcomingOneOnOnes,
  displayAll,
  handleEmployeeClick,
  handleOneOnOneMonths,
  missedOneOnOnes,
}) {
  const [hide, setHide] = useState(false);
  return (
    <div className={["card", style.to_do_wrapper].join(" ")}>
      <div className="card_info">
        <div className="card_title">
          <div className="d-flex">
            <ReactSVG src={IC_COMMENT_ROUND} className={style.todo_svg} />
            <div className="card_title_text">1-on-1&apos;s </div>
          </div>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>
      {!hide && (
        <div>
          <div className="box-light-gradient"></div>
          <div className="card_details">
            <div className={style.to_do_card_wrapper}>
              <Row>
                <Col md={6}></Col>
                <Col md={6}>
                  <div className="card_action float-right mr-3">
                    <Select
                      onChange={(select) => handleOneOnOneMonths(select.value)}
                      options={monthOptions2}
                      className="select_dropdown select_dropdown_text"
                      classNamePrefix="select"
                      isSearchable={false}
                      defaultValue={{
                        value: 1,
                        label: "Last Month",
                      }}
                    />
                  </div>
                </Col>
              </Row>
              {previousOneOnOnes && (
                <div className={style.to_do_item_wrapper}>
                  <div className={style.to_do_heading}>
                    Previous 1-on-1&apos;s ({previousOneOnOnes.oneOnOneAgenda.length})
                  </div>
                  <div className={style.to_do_subheading}>Details about previously held 1-on-1 are as follows:</div>

                  <div className={style.avatar_list}>
                    <div className={style.avatar_container}>
                      {previousOneOnOnes.oneOnOneAgenda
                        .filter((value, index) => index < 13)
                        .map((employee, index) => (
                          <RoundEmployeeList
                            tooltipSubText="Completed On :- "
                            dates={employee.endDate.map((v) => dateFormat1(v))}
                            empName={employee.employee.empName}
                            image={employee.employee?.displayPicture || employee.employee?.empInitial}
                            selectedColor={backGroundColor[index % backGroundColor.length]}
                            key={"ASSIGNEE_" + employee.employee.employeeId + index}
                            handleEmployeeClick={() =>
                              handleEmployeeClick(employee.employee, "PREVIOUS_ONE_ON_ONE", employee.oneOnOneAgendaId)
                            }
                          />
                        ))}
                      {previousOneOnOnes.oneOnOneAgenda.length > 13 && (
                        <div className={style.avatar} onClick={() => displayAll("PREVIOUS_ONE_ON_ONE")}>
                          <div className={style.avtar_item}>+{previousOneOnOnes.oneOnOneAgenda.length - 13}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {pendingOneOnOnes && (
                <div className={style.to_do_item_wrapper}>
                  <div className={style.to_do_heading}>Pending 1-on-1&apos;s ({pendingOneOnOnes.employees.length})</div>
                  <div className={style.to_do_subheading}>You didn&apos;t have your 1-on-1 with following members:</div>

                  <div className={style.avatar_list}>
                    <div className={style.avatar_container}>
                      {pendingOneOnOnes.employees
                        .filter((value, index) => index < 13)
                        .map((employee, index) => (
                          <RoundEmployeeList
                            empName={employee.empName}
                            image={employee.displayPicture || employee.empInitial}
                            selectedColor={backGroundColor[index % backGroundColor.length]}
                            key={"ASSIGNEE_" + employee.employeeId + index}
                            handleEmployeeClick={() => handleEmployeeClick(employee, "PENDING_ONE_ON_ONE")}
                          />
                        ))}
                      {pendingOneOnOnes.employees.length > 13 && (
                        <div className={style.avatar} onClick={() => displayAll("PENDING_ONE_ON_ONE")}>
                          <div className={style.avtar_item}>+{pendingOneOnOnes.employees.length - 13}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {upcomingOneOnOnes && (
                <div className={style.to_do_item_wrapper}>
                  <div className={style.to_do_heading}>
                    Upcoming 1-on-1&apos;s ({upcomingOneOnOnes.oneOnOneAgenda.length})
                  </div>
                  <div className={style.to_do_subheading}>You have your 1-on-1 scheduled with following people:</div>

                  <div className={style.avatar_list}>
                    <div className={style.avatar_container}>
                      {upcomingOneOnOnes.oneOnOneAgenda
                        .filter((value, index) => index < 13)
                        .map((employee, index) => (
                          <RoundEmployeeList
                            tooltipSubText="Scheduled On :- "
                            dates={employee.scheduledDate.map((v) => dateFormat1(v))}
                            date={dateFormat1(employee.scheduledDate)}
                            empName={employee.employee.empName}
                            image={employee.employee?.displayPicture || employee.employee?.empInitial}
                            selectedColor={backGroundColor[index % backGroundColor.length]}
                            key={"ASSIGNEE_" + employee.employee.employeeId + index}
                            handleEmployeeClick={() =>
                              handleEmployeeClick(employee.employee, "UPCOMING_ONE_ON_ONE", employee.oneOnOneAgendaId)
                            }
                          />
                        ))}
                      {upcomingOneOnOnes.oneOnOneAgenda.length > 13 && (
                        <div className={style.avatar} onClick={() => displayAll("UPCOMING_ONE_ON_ONE")}>
                          <div className={style.avtar_item}>+{upcomingOneOnOnes.oneOnOneAgenda.length - 13}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {missedOneOnOnes && (
                <div className={style.to_do_item_wrapper}>
                  <div className={style.to_do_heading}>
                    Lapsed 1-on-1&apos;s ({missedOneOnOnes.oneOnOneAgenda.length})
                  </div>
                  <div className={style.to_do_subheading}>You have your 1-on-1 scheduled with following people:</div>

                  <div className={style.avatar_list}>
                    <div className={style.avatar_container}>
                      {missedOneOnOnes.oneOnOneAgenda
                        .filter((value, index) => index < 13)
                        .map((employee, index) => (
                          <RoundEmployeeList
                            tooltipSubText="Scheduled On :- "
                            dates={employee.scheduledDate.map((v) => dateFormat1(v))}
                            empName={employee.employee.empName}
                            image={employee.employee?.displayPicture || employee.employee?.empInitial}
                            selectedColor={backGroundColor[index % backGroundColor.length]}
                            key={"ASSIGNEE_" + employee.employee.employeeId + index}
                            handleEmployeeClick={() =>
                              handleEmployeeClick(employee.employee, "MISSED_ONE_ON_ONE", employee.oneOnOneAgendaId)
                            }
                          />
                        ))}
                      {missedOneOnOnes.oneOnOneAgenda.length > 13 && (
                        <div className={style.avatar} onClick={() => displayAll("UPCOMING_ONE_ON_ONE")}>
                          <div className={style.avtar_item}>+{missedOneOnOnes.oneOnOneAgenda.length - 13}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className={style.to_do_heading}>Scheduling 1-on-1&apos;s</div>
              <Link to={routePath.ONE_ON_ONE}>Go to 1-on-1</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpComingItems;
