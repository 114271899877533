import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import { dateFormat1 } from "../../../util/dateUtils";

function PreviousOneOnOneRow(props) {
  const { createOn, meetingDate, endedBy, id } = props;

  return (
    <tr>
      <td className={style.w_50}>
        <Link className={style.descriptive_date} to={`/okrprogress/one-on-one/previous/${id}`}>
          {dateFormat1(createOn)}
        </Link>
      </td>
      <td className={style.w_25}>
        <span className={style.descriptive_date}>{dateFormat1(meetingDate)}</span>
      </td>
      <td className={style.w_25}>
        {endedBy ? (
          <span className={style.descriptive_date}>{endedBy}</span>
        ) : (
          <Link className="f-pc" to={`/okrprogress/one-on-one/previous/${id}`}>
            Re-Schedule
          </Link>
        )}
      </td>
    </tr>
  );
}

PreviousOneOnOneRow.propTypes = {
  createOn: PropTypes.string,
  meetingDate: PropTypes.string,
  endedBy: PropTypes.string,
};

export default PreviousOneOnOneRow;
