import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./DetailsViewPopup.module.scss";
import { MonthlyCheckinTexts } from "const/constValue";
import FeedbackQuestionsTable from "components/FeedbackQuestionsTable/FeedbackQuestionsTable";

const DetailsViewPopup = ({
  period,
  openPopup,
  setOpenPopup,
  managerList,
  historyDetails,
  getHistoryDetails,
}) => {
  const [activeTabId, setActiveTabId] = useState(null);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(()=> {
    if(managerList?.length > 0){
      getHistoryDetails(period, managerList[0]);
      setActiveTabId(managerList[0]?.employeeId)
    }
  },[managerList])

  const handleSelect = (e, manager) => {
    e.preventDefault();
    setActiveTabId(manager.employeeId);
    getHistoryDetails(period, manager);
  };

  return (
    <>
      <Modal
        size="lg"
        show={openPopup}
        onHide={() => setOpenPopup(true)}
        animation={false}
        className={style.details_view_popup_wrapper}
      >
        <div className={style.modal_header}>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="row">
              <div className={style.heading_wrapper}>
              <h2>{MonthlyCheckinTexts.MONTHLY_CHECK_IN.replace(':', '')}</h2>
                <h6>
                  <span className={style.greyText}>{period}&nbsp;{MonthlyCheckinTexts.CHECK_IN_PERIOD}&nbsp;</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body>
          <div className="box-light-gradient"></div>
          <div className="col-xl-12">
            <div
              className={`custom-tabs custom-tabs-full-width tab-content-dashboard ${style.data_container}`}
            >
              <div className="slick-slider-box-wrapper">
                <Slider
                  className={`${style.slick_slider} nav nav-tabs`}
                  {...sliderSettings}
                >
                  {managerList?.map((manager, index) => {
                    const { empName, employeeId } = manager;
                    return (
                      <a
                        href="#"
                        role="tab"
                        data-rb-event-key={employeeId}
                        id={`tabs-navigation-ta-${employeeId}"`}
                        data-test='getHistoryDetails'
                        aria-controls={`tabs-navigation-tabpane-${employeeId}`}
                        aria-selected="true"
                        className={`nav-item nav-link ${employeeId === activeTabId ? "active": ""}`}
                        onClick={(e) => handleSelect(e, manager)}
                        key={index}
                      >
                        {empName}
                      </a>
                    );
                  })}
                </Slider>
              </div>
              <div className="tab-content">
                <div
                  id={`tabs-navigation-tab-${activeTabId}"`}
                  role="tabpanel"
                  className="fade tab-pane active show"
                >
                  {historyDetails && <div>
                    <div className={style.your_feedback}>
                      <FeedbackQuestionsTable
                        title="Employee Feedback"
                        questionAnswerList={historyDetails?.questionAnswerListEmployee}
                        viewsTtitle="Employee View(s)"
                        comment={historyDetails?.employeeComment}
                      />
                    </div>
                    <div className={style.manager_feedback}>
                      <FeedbackQuestionsTable
                        title="Manager Feedback"
                        questionAnswerList={historyDetails?.questionAnswerListManager}
                        viewsTtitle="Manager View(s)"
                        comment={historyDetails?.managerComment}
                      />
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.modal_footer}>
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_close].join(" ")}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <strong>Close</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetailsViewPopup;
