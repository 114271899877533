import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import style from "./SidebarWinsChallenges.module.scss";

export default function SidebarWinsChallenges({ winsAndChallenges, pathName }) {
  return (
    <div className={["box", style.sidebar_wc_wrapper].join(" ")}>
      <div className="box-heading-wrapper noborder no_pad">
        <div className="box-heading">
          <h2>Wins &amp; Challenges</h2>
        </div>
      </div>
      <div className="box-inner-pad pl-0 pr-0 pb-0">
        <ul className={style.wc_list_wrapper}>
          {winsAndChallenges
            .filter((v, i) => i < 2)
            .map((value) => (
              <li key={value.winAndChallengeId} className="text-break">
                <p>{value.comment}t</p>
                <h6>
                  Check-in :{" "}
                  <span>
                    {moment(value.surveyCyclesStartDate).format("MMM DD, YYYY") +
                      " - " +
                      moment(value.surveyCyclesEndDate).format("MMM DD, YYYY")}
                  </span>
                </h6>
              </li>
            ))}
        </ul>
        <div className={style.view_more}>
          <Link to={pathName}>View More</Link>
        </div>
      </div>
    </div>
  );
}
