import React, { useState, useEffect } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Label, BarChart, XAxis, YAxis, Bar, Tooltip } from "recharts";
import style from "./EmployeeQuizResultCharts.module.scss";
import _ from "lodash";

export default function QuizPieAndBarChartSubGroup(props) {
  const [currentVal, setCurrentVal] = useState(null);
  const [pieChartDataSubgroup, setPieChartDataSubgroup] = useState([]);
  const [barChartDataSubgroup, setBarChartDataSubgroup] = useState([]);
  const [locationLength, setlocationLength] = useState(false);

  useEffect(() => {
    const PieChartsArray = [];
    const BarChartArray = [];

    if (props?.quizResultData !== undefined) {
      let pieData = props?.quizResultData === undefined ? [] : props?.quizResultData;
      PieChartsArray.push(
        {
          name: "Attempted",
          value: pieData?.organisationAttemptedCount,
          percentageValue: pieData?.organisationAttemptedPercentage,
        },
        {
          name: "Not Attempted",
          value: pieData?.organisationNotAttemptedCount,
          percentageValue: pieData?.organisationNotAttemptedPercentage,
        }
      );
      setPieChartDataSubgroup(_.sortBy(PieChartsArray, ["name"], ["asc"]));

      let barData =
        props?.quizResultData?.subGroupWiseChartList === undefined ||
        props?.quizResultData?.subGroupWiseChartList === null
          ? []
          : props?.quizResultData?.subGroupWiseChartList;
      barData.map((barSubgroup) => {
        BarChartArray.push({
          name: barSubgroup.name,
          passCount: barSubgroup.passCount,
          failCount: barSubgroup.failCount,
          notAttemptedCount: barSubgroup.notAttemptedCount,
          passPercentageValue: barSubgroup.passPercentage,
          failPercentageValue: barSubgroup.failPercentage,
          notAttemptedPercentageValue: barSubgroup.notAttemptedPercentage,
        });
        return barSubgroup;
      });
      setBarChartDataSubgroup(_.sortBy(BarChartArray, ["name"], ["asc"]));
      setlocationLength(barData.length > 7 ? true : false);
    } else {
      setPieChartDataSubgroup([]);
      setBarChartDataSubgroup([]);
    }
  }, [props]);

  function CustomLabel({ viewBox }) {
    const { cx, cy } = viewBox;
    let totalValue = 0;
    pieChartDataSubgroup.forEach((dt) => {
      totalValue += dt.value;
    });
    const total = `${totalValue}`;
    return (
      <>
        <text
          x={cx}
          y={cy - 12}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            Total
          </tspan>
        </text>
        <text
          x={cx}
          y={cy + 15}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            {total}
          </tspan>
        </text>
      </>
    );
  }

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    let percentValue;
    let count;
    if (currentVal === "Pass") {
      percentValue = payload[0]?.payload?.passPercentageValue?.toFixed(2);
      count = payload[0]?.payload?.passCount;
    } else if (currentVal === "Fail") {
      percentValue = payload[0]?.payload?.failPercentageValue?.toFixed(2);
      count = payload[0]?.payload?.failCount;
    } else if (currentVal === "Not Attempted") {
      percentValue = payload[0]?.payload?.notAttemptedPercentageValue?.toFixed(2);
      count = payload[0]?.payload?.notAttemptedCount;
    }
    if (active) {
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper">
              <p className="recharts-tooltip-label">{currentVal}</p>
              <span className="recharts-tooltip-value">{`${percentValue}%`}</span>
              <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${count}`}</span>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomTooltipForPieChart = (props) => {
    const { active, payload } = props;
    if (active) {
      return (
        <>
          <div className="recharts-tooltip-custom-wrapper">
            <p className="recharts-tooltip-label">{payload[0].name}</p>
            {<span className="recharts-tooltip-value">{`${payload[0].payload.percentageValue.toFixed(2)}%`}</span>}
            <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${
              payload[0]["value"]
            }`}</span>
          </div>
        </>
      );
    }

    return null;
  };

  const barChartDataForlegend = [
    {
      name: "Pass",
    },
    {
      name: "Fail",
    },
    {
      name: "Not Attempted",
    },
  ];

  const COLORS = {
    Pass: "#6ACF00",
    Fail: "#F07575",
    "Not Attempted": "#FDC861",
  };

  const COLORS_PIE_CHART = {
    Attempted: "#00C4BC",
    "Not Attempted": "#FDC861",
  };

  return (
    <>
      <div className="d-flex">
        <div className="graph-item width50">
          <h5>Aggregate Result Status: attempted/not attempted</h5>
          <div className={style.pie_chart_location_wrapper}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={pieChartDataSubgroup}
                  cx="45%"
                  cy="50%"
                  cursor="pointer"
                  innerRadius={100}
                  outerRadius={125}
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="value"
                >
                  <Label width={30} position="center" content={<CustomLabel value1={pieChartDataSubgroup} />}></Label>
                  {pieChartDataSubgroup.map((entry, index) => {
                    return <Cell key={index} fill={COLORS_PIE_CHART[entry["name"]]} />;
                  })}
                </Pie>
                <Tooltip content={<CustomTooltipForPieChart />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className={style.location_pie_legend}>
            <div className={style.legent_wrapper}>
              <ul className={style.lenegd_list}>
                {pieChartDataSubgroup.map((entry, index) => {
                  return (
                    <li className={style.legend_item} key={index}>
                      <span
                        className={style.legend_color}
                        style={{ backgroundColor: COLORS_PIE_CHART[entry["name"]] }}
                      ></span>
                      {entry["name"]}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="graph-item width100">
          <h5 className={["pl-4", style.right_text_title].join(" ")}>Result Status</h5>
          <div className={["pl-4", style.bar_chart_sub_header, style.right_text_title].join(" ")}>
            <span>Attempted</span> ( Showing Pass - Fail percent )
          </div>

          <div className={style.subgroup_wrapper}>
            <ResponsiveContainer>
              <BarChart
                data={barChartDataSubgroup}
                layout="vertical"
                barSize={8}
                barGap={1}
                margin={{ top: 30, right: 0, left: 15, bottom: 0 }}
              >
                <XAxis type="number" axisLine={false} />
                <YAxis
                  type="category"
                  width={100}
                  dataKey="name"
                  axisLine={false}
                  tick={{ wordWrap: "break-word" }}
                  interval={0}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="failCount"
                  fill={COLORS["Fail"]}
                  onMouseOver={() => setCurrentVal("Fail")}
                  onMouseLeave={() => setCurrentVal(null)}
                  stackId="a"
                  radius={[10, 0, 0, 10]}
                ></Bar>
                <Bar
                  dataKey="passCount"
                  fill={COLORS["Pass"]}
                  onMouseOver={() => setCurrentVal("Pass")}
                  onMouseLeave={() => setCurrentVal(null)}
                  stackId="a"
                  radius={[0, 10, 10, 0]}
                ></Bar>
                <Bar
                  dataKey="notAttemptedCount"
                  fill={COLORS["Not Attempted"]}
                  onMouseOver={() => {
                    setCurrentVal("Not Attempted");
                  }}
                  onMouseLeave={() => setCurrentVal(null)}
                  radius={[10, 10, 10, 10]}
                ></Bar>
              </BarChart>
            </ResponsiveContainer>
            <div className={style.legent_wrapper}>
              <ul className={style.lenegd_list}>
                {barChartDataForlegend.map((entry, index) => {
                  return (
                    <li className={style.legend_item} key={index}>
                      <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                      {entry["name"]}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
