import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Form } from "react-bootstrap";
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Tooltip } from "recharts";
import Select from "react-select";
import style from "./AssessmentReportCharts.module.scss";
import * as actions from "store/actions/index";
import { moduleView, toFixedNumber } from "util/utils";
import config from "util/config";

export default function AssessmentReportCharts({ filterValue }) {
  const [cycle, setCycle] = useState();
  const [currentVal, setCurrentVal] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { allQuarterlyCycleList, assessmentReportChartData } = useSelector((state) => state.hrDashboardReport);

  useEffect(() => {
    dispatch(actions.getALLQuarterlyCycleList());
  }, []);

  useEffect(() => {
    if (allQuarterlyCycleList.length > 0) {
      const cycleId = allQuarterlyCycleList[0].value;
      setCycle(allQuarterlyCycleList[0]);
      dispatch(actions.getAssessmentReportChartData(cycleId));
    }
  }, [allQuarterlyCycleList]);

  const handleCycleList = (e) => {
    setCycle(e);
    dispatch(actions.getAssessmentReportChartData(e.value, filterValue));
  };

  const COLORS = {
    SUBMITTED: "#22C124",
    PENDING: "#FB545E",
  };
  const handleBarChart = (e) => {
    const value = e.name;
    const cycleId = cycle.value;
    //if User have HR Module and performance cycle sub module right then user can redirect otherwise isn't clickable
    if (moduleView(config.hrModule.PERFORMANCE)?.includes(config.subModule.PERFORMANCE_CYCLE)) {
      switch (value) {
        case "SELF_ASSESSMENT":
          history.push({
            pathname: `/hr/performance/performance-cycle/employee-assessment-list/${cycleId}`,
          });
          break;
        case "REPORTING_ASSESSMENT":
          history.push({
            pathname: `/hr/performance/performance-cycle/reporting-manager-list/${cycleId}`,
          });
          break;
        case "REVIEWING_ASSESSMENT":
          history.push({
            pathname: `/hr/performance/performance-cycle/reviewing-manager-list/${cycleId}`,
          });
          break;
        default:
          break;
      }
    }
  };

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    let pr;
    let count;

    if (payload?.length > 0) {
      let type = currentVal === "Pending" ? "pendingPercentage" : "submittedPercentage";
      pr = payload.find((v) => v.dataKey === currentVal)?.payload[type];
      count = payload.find((v) => v.dataKey === currentVal)?.value;
    }

    if (active) {
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper">
              <p className="recharts-tooltip-label">{currentVal}</p>
              {pr && <span className="recharts-tooltip-value">{`${toFixedNumber(pr, 2)}%`}</span>}
              <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${count}`}</span>
            </div>
          )}
        </>
      );
    }

    return null;
  };
  let legendData = [
    { name: "Pending", key: "PENDING" },
    { name: "Submitted", key: "SUBMITTED" },
  ];
  return (
    <div className={["box", style.graph_wrapper, style.date_range_wrapper].join(" ")}>
      <div className="box-heading-wrapper border-bottom">
        <div className="box-heading">
          <h2>Assessment Status</h2>
        </div>
      </div>
      <div className="box-inner-pad pb-0">
        {assessmentReportChartData && assessmentReportChartData?.length > 0 ? (
          <>
            <div className={style.heading_wrapper}>
              <div className={style.left_side_heading}>
                <h5>Quarterly Assessment Status</h5>
                <h6>Cycle Name: {cycle?.label}</h6>
              </div>
              <div className="cycle-list-wrapper d-flex dropdown-multiselect">
                <Form.Group controlId="">
                  <Select
                    options={allQuarterlyCycleList}
                    className="custom-default-dd-box"
                    classNamePrefix="select"
                    placeholder="Select Cycle"
                    isSearchable={true}
                    value={cycle}
                    onChange={handleCycleList}
                  />
                </Form.Group>
              </div>
            </div>

            <div className={style.graph_view}>
              <div className="graph-item width70">
                <h5 className={["pl-4", style.right_text_title].join(" ")}>Assessment report</h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <BarChart
                      margin={{ top: 70, right: 0, left: 0, bottom: 0 }}
                      barSize={25}
                      barGap={1}
                      data={assessmentReportChartData}
                      cursor="pointer"
                    >
                      <Bar
                        dataKey="Pending"
                        fill={COLORS["PENDING"]}
                        onClick={(e) => handleBarChart(e)}
                        stackId="a"
                        onMouseOver={() => {
                          setCurrentVal("Pending");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      ></Bar>
                      <Bar
                        dataKey="Submitted"
                        fill={COLORS["SUBMITTED"]}
                        onClick={(e) => handleBarChart(e)}
                        onMouseOver={() => setCurrentVal("Submitted")}
                        onMouseLeave={() => setCurrentVal(null)}
                      ></Bar>
                      <XAxis dataKey="name" axisLine={false} />
                      <YAxis axisLine={false} />
                      <Tooltip content={<CustomTooltip />} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className={style.legent_wrapper}>
                <ul className={style.lenegd_list}>
                  {legendData.map((entry, index) => {
                    return (
                      <li className={style.legend_item} key={index}>
                        <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["key"]] }}></span>
                        {entry["name"]}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className="no-record-found-wrpr mt-3">No Data Available</div>
        )}
      </div>
    </div>
  );
}
