import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import * as actions from "store/actions/index";
import style from "./AddSkillMaster.module.scss";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import { errorToast, successToast } from "util/general";

export default function AddSkillMaster(props) {
  const history = useHistory();
  const [skillName, setSkillName] = useState("");
  const [isActive, setisActive] = useState(true);
  const dispatch = useDispatch();
  const { skilladdedinmaster, skillmasterupdated, alreadyexist } = useSelector((state) => state.skillMatrix);

  const handleSkillName = (e) => {
    const value = e.target.value || "";
    setSkillName(value);
  };

  const handleEditSkillName = (e) => {
    const value = e.target.value || props.history.location.state.skillName;
    setSkillName(value);
  };

  const handleSkillStatus = () => {
    let status = isActive === true ? false : true;
    setisActive(status);
  };

  const handleEditSkillStatus = () => {
    let status;
    props.history.location.state.isActive ? (status = false) : (status = true);
    setisActive(status);
  };

  const submitHandler = () => {
    if (props.history.location.state) {
      let data = {
        skillId: props.history.location.state.skillId,
        skillName: skillName || props.history.location.state.skillName,
        isActive: isActive,
      };
      dispatch(actions.updateSkillMaster(data));
    } else {
      let data = {
        skillName: skillName.trim(),
        isActive: isActive,
      };

      //accepting data in form-data
      // const data = new FormData()
      // data.append('skillName',skillName)
      // data.append('isActive',isActive)
      dispatch(actions.addSkillInMaster(data));
    }
  };

  useEffect(() => {
    if (props.history.location.state) setSkillName(props.history.location.state.skillName);
  }, [props.history.location.state]);

  useEffect(() => {
    if (skilladdedinmaster === true) {
      successToast("Skill added successfully");
      setSkillName("");
      setisActive(true);
    }
  }, [history, skilladdedinmaster]);

  useEffect(() => {
    if (skillmasterupdated === true) {
      successToast("Skill edited successfully");
      history.push(routePath.MMG_SKILL_MASTER, {
        searchSkillName: props.history.location.state.searchSkillName,
        searchActiveStatus: props.history.location.state.searchActiveStatus,
      });
    }
  }, [
    history,
    props.history.location.state.searchActiveStatus,
    props.history.location.state.searchSkillName,
    skillmasterupdated,
  ]);

  useEffect(() => {
    if (alreadyexist === true) {
      errorToast("Skill already exist.");
    }
  }, [alreadyexist]);

  return (
    <div className={style.skill_master_wrapper}>
      <SubNavigation type="Skill Matrix" />
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title">{props.history.location.state ? "Edit Skill" : "Add New Skill"}</h1>
            <span>Skill Matrix</span>
          </Col>
        </Row>
        <div className="gap30"></div>
        <Row>
          <Col md={12}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>{props.history.location.state ? "Edit Skill" : "Add Skill"}</h2>
                  {props.history.location.state ? (
                    <h3>Please edit skill to the master database</h3>
                  ) : (
                    <h3>Please add new skill to the master database</h3>
                  )}
                </div>
              </div>
              <div className={style.skill_dd_wrapper}>
                <div className="box-inner-pad">
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="">
                        <Form.Label className={style.dd_title}>
                          {props.history.location.state ? "Skill Name" : "Enter Skill Name"}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Skill Name (E.g. Java, Photoshop, HTML, etc…)"
                          value={skillName}
                          onChange={props.history.location.state ? handleEditSkillName : handleSkillName}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="">
                        <Form.Label className={style.dd_title}>
                          {props.history.location.state ? "Skill Status" : "Make this skill Active"}
                        </Form.Label>
                        <div className={style.switches}>
                          <Form.Check
                            type="switch"
                            id="switch"
                            label="Active"
                            defaultChecked={
                              props.history.location.state ? props.history.location.state.isActive : isActive
                            }
                            onChange={props.history.location.state ? handleEditSkillStatus : handleSkillStatus}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className={["box-flex-center box", style.saveBox].join(" ")}>
              <div className={style.save_box_left}>
                <Button
                  disabled={props.history.location.state ? false : !skillName.trim()}
                  type="button"
                  variant={"primary"}
                  onClick={submitHandler}
                >
                  <strong>{props.history.location.state ? "Update" : "Add"}</strong>
                </Button>
              </div>
              {/* <div className={style.save_box_right}>
                            Draft Saved:
                            <span> Mon 1 Jun 6:01 pm </span>
                        </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
