import * as actionTypes from "../actions/actionTypes";
const initialState = {
  isLoading: true,
  apiResponse: null,
  locationList: [],
  departmentList: [],
  designationList: [],
  filterValue: {},
  questionTemplate: {},
  isCreateLoading: false,
  departmentCapabilityList: [],
  questionMap: null,
  mapTemplateValue: {},
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEPARTMENT_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_DESIGNATION_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CAPABILITY_RESPONSE:
      return {
        ...state,
        ...action.payload,
        isCreateLoading: false,
      };
    case actionTypes.SET_DEPARTMENT_CAPABILITY_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_CAPABILITY_LOADING:
      return {
        ...state,
        isCreateLoading: true,
      };
    case actionTypes.CLEAR_CAPABILITY_RESPONSE:
      return {
        ...state,
        apiResponse: null,
      };
    case actionTypes.SET_CAPABILITY_TEMPLATE:
      return {
        ...state,
        ...action.payload,
      };
    case "SET_CAPABILITY_DEPARTMENT_AND_DESIGNATION":
      return {
        ...state,
        filterValue: {
          ...state.filterValue,
          ...action.payload,
        },
      };
    case actionTypes.SET_CAPABILITY_MAP_READONLY_VIEW:
      return {
        ...state,
        filterValue: {
          filterDepartment: { value: action.payload.department, label: action.payload.department },
          filterDesignation: action.payload.designation?.map((des) => ({ value: des, label: des })),
          disabledFilterDepartment: true,
          disabledFilterDesignation: true,
        },
        questionMap: {
          ...action.payload.questionMap,
          isDisabled: true,
          isView: "VIEW",
        },
        designationList: [
          ...action.payload.designation?.map((des) => ({ value: des, label: des, disabled: true })),
          ...action.payload.remainingDesignation?.map((des) => ({ value: des, label: des, disabled: true })),
        ],
      };
    case actionTypes.SET_CAPABILITY_MAP_EDIT_VIEW:
      return {
        ...state,
        filterValue: {
          filterDepartment: { value: action.payload.department, label: action.payload.department },
          filterDesignation: action.payload.designation?.map((des) => ({ value: des, label: des })),
          disabledFilterDepartment: true,
          disabledFilterDesignation: false,
        },
        questionMap: {
          ...action.payload.questionMap,
          isDisabled: false,
          isView: "EDIT",
        },
        mapTemplateValue: action.payload.questionMap,
        designationList: [
          ...action.payload.designation?.map((des) => ({ value: des, label: des, disabled: true })),
          ...action.payload.remainingDesignation?.map((des) => ({ value: des, label: des, disabled: false })),
        ],
      };
    case "RESET_QUESTION_MAP": {
      return {
        ...state,
        filterValue: {
          filterDepartment: "",
          filterDesignation: [],
          disabledFilterDepartment: false,
          disabledFilterDesignation: false,
        },
        questionMap: null,
      };
    }
    case "SET_AUTO_SAVE_MAPPING_VALUE":
      return {
        ...state,
        mapTemplateValue: {
          ...state.mapTemplateValue,
          [action.designation]: {
            ...state.mapTemplateValue[action.designation],
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};
export default reducer;
