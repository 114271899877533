import React, { useState, useEffect } from "react";
import style from "./AssigneePopup.module.scss";
import { useSelector } from "react-redux";
import { backGroundColor } from "../../../../const/constValue";

export default function TeamRow(props) {
  const {
    empName,
    dispArr,
    empInitial,
    assigned,
    designation,
    employeeId,
    userObjectives,
    isChecked,
    objectiveAssigned,
    band,
    totalWeightageOfManager,
    selectAllEmployees
  } = props.team;
  const [check, setCheck] = useState(isChecked || selectAllEmployees);

  // const dispatch = useDispatch();
  const disable = useSelector((state) => state.objectives.disable);
  let array = props.teamData;

  useEffect(() => {
    if (!isChecked) setCheck(false);
  }, [isChecked]);

  useEffect(() => {
    setCheck(isChecked || selectAllEmployees);
  }, [isChecked, selectAllEmployees]);

  const handleTeam = (e, data) => {
    array.forEach((user) => {
      if (user.employeeId === data.employeeId) user.isChecked = e.target.checked;
    });
    // let len = array.filter((value) => value.isChecked).length;

    // if (len === 20) {
    //   dispatch({ type: DISABLE });
    // } else {
    //   dispatch({ type: UN_DISABLE });
    // }
    if(data.objectiveAssigned === false){
      setCheck(!check);
    }
    props.setTeam(array);
    props.setCheckBoxClick(1+props.checkBoxClick);
    // if (e.target.checked === false && data.objectiveAssigned === false) {
    //   props.setSelectAllEmployees(false);
    // } else if (array?.filter((data) => data.isChecked === true)?.length === array.length && !props.selectAllDisable) {
    //   props.setSelectAllEmployees(true);
    //   debugger
    // }
    // debugger
  };

  return (
    <tr className={check || objectiveAssigned ? style.active : undefined}>
      <td colSpan={2}>
        <div
          className={style.user_img}
          style={{ backgroundColor: backGroundColor[props.index % backGroundColor.length] }}
        >
          {dispArr ? <img src={dispArr} alt="pic" /> : empInitial}
        </div>
        <div className={style.user_name}>
          <h1>{empName}</h1>
          <h2>{designation}</h2>
        </div>
        <div className={style.check_infoneer}>
          <label className={style.container_checkbox}>
            <input
              type="checkbox"
              id={employeeId}
              checked={check || objectiveAssigned}
              disabled={check ? false : disable}
              onChange={(e) => handleTeam(e, props.team)}
            />
            <span className={style.checkmark}></span>
          </label>
        </div>
      </td>
      <td>
        {/* here we need to pass dynamic band field which we required from BE side*/}
        <span className={style.check_band}>
          {band}
        </span>
      </td>
      <td>
        <span className={style.check_no_of_obj}>
          {userObjectives && userObjectives.filter((value) => new Date(value.endDate) >= new Date()).length > 0
            ? userObjectives.filter((value) => new Date(value.endDate) >= new Date()).length
            : 0}
        </span>
      </td>
      <td>
        {assigned === "true" ? (
          <div className={style.assigned}>Assigned</div>
        ) : (
          <div className={style.not_assigned}>Not Assigned</div>
        )}
      </td>
    </tr>
  );
}
