import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import moment from "moment";
import config from "../../util/config";
import {
  DELETE_MANAGER_COMMENT_IN_TEMPLATE,
  MANAGER_DATA,
  MANAGER_SURVEY_HISTORY_IN_TEAM,
  REVIEWED,
  UPDATE_MANAGER_COMMENT_IN_TEMPLATE,
  USER_CHECKIN,
  VIEW_CYCLE,
} from "../../util/endpoints";
import { scrollToTop } from "../../util/general";
import { CheckWinChallenge } from "./winchallenge";
import { status } from "const/constValue";

export const GetCurrentCycle = (params) => {
  return (dispatch) => {
    http
      .get(USER_CHECKIN, {}, { params })
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          let currentUser = response.employeeProfile;
          const user = {
            employeeInitial: response.employeeProfile.employeeInitial,
            id: currentUser.employeeId,
            name: currentUser.displayName || currentUser.userName,
            designation: currentUser.designation,
            department: currentUser.department,
            image: currentUser.dispArr,
            band: currentUser.band,
            totalExp: currentUser.totalExp,
            expBeforeJoining: currentUser.expBeforeJoining,
            isExp: currentUser.isExp,
            due_date: response.endDate,
            completed: 5, // TODO: need to replace this values once we get from Service Resposne
            total: 10, // TODO: need to replace this values once we get from Service Resposne
            email: currentUser.email,
            location: currentUser.location,
            country: currentUser.country,
            parentGroupId: currentUser.parentGroupId,
            subGroupId: currentUser.subGroupId,
          };
          const currentCheckIn = { start: "", end: "" };

          currentCheckIn["start"] = moment(response.startDate).format(config.dates.shortMonthNameAndDate);
          currentCheckIn["end"] = moment(response.endDate).format(config.dates.shortMonthNameAndDate);

          const rating = response.questionsAnswer.filter((rating) => rating.type === "RATING");
          const moodoMeter = response.questionsAnswer.filter((rating) => rating.type === "MOODOMETER");

          dispatch(CheckWinChallenge(response.surveyCyclesId, currentUser));

          dispatch({
            type: actionTypes.SET_MEMBER_DATA,
            payload: {
              user,
              currentCheckIn,
              isSubmitted: response.status !== "PENDING_FROM_MEMBER",
              data: response,
              selectedRating: rating[0].answer,
              moodoMeter: moodoMeter,
              isLoading: false,
            },
          });
          if (response.status !== "PENDING_FROM_MEMBER") {
            dispatch({
              type: actionTypes.SET_QUESTIONS,
              payload: {
                user,
                data: response,
                rating: rating[0].answer || null,
                currentCheckIn,
              },
            });
          }
        }
      })
      .catch(() => dispatch({ type: actionTypes.TEAM_HISTORY_API_FAILURE }));
  };
};

export const GetMemberReviewer = (paramData) => {
  let params = {
    employeeId: paramData.employeeId,
    employeeSubGroupId: paramData.subGroupId,
    employeeParentGroupId: paramData.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(MANAGER_DATA, {}, { params })
      .then((response) => response.data.data)
      .then((data) => {
        dispatch({ type: actionTypes.SET_MEMBER_REVIEWER, reviewer: data });
        dispatch({
          type: actionTypes.SET_QUESTIONS,
          payload: { reviewer: data },
        });
      })
      .catch(() => dispatch({ type: actionTypes.TEAM_HISTORY_API_FAILURE }));
  };
};

export const GetSurveyHistory = (currentUser, params) => {
  return (dispatch) => {
    http
      .get(MANAGER_SURVEY_HISTORY_IN_TEAM, {}, { params })
      .then((response) => response.data.data)
      .then((response) => {
        if (response && response[0]) {
          const surveyData = response;

          const employee = {
            id: currentUser.employeeId,
            name: currentUser.displayName,
            designation: currentUser.designation,
            department: currentUser.department,
            image: currentUser.displayPicture,
            band: currentUser.band,
            firstName: currentUser.fname,
            totalExp: currentUser.totalExp,
            empInitial: currentUser.empInitial,
            parentGroupId: currentUser.parentGroupId,
            subGroupId: currentUser.subGroupId,
          };

          dispatch({
            type: actionTypes.SET_SURVEY_HISTORY,
            payload: {
              employee,
              surveyData,
              isLoading: false,
            },
          });

          dispatch({ type: actionTypes.SET_QUESTIONS, payload: { employee } });
        }
      })
      .catch(() => dispatch({ type: actionTypes.TEAM_HISTORY_API_FAILURE }));
  };
};

export const GetReviewed = (paramsData) => {
  return (dispatch) => {
    http
      .post(REVIEWED, {}, paramsData)
      .then((response) => response.data)
      .then((data) => {
        if (data.message.message === "Success") {
          dispatch({ type: actionTypes.SET_IS_REVIEWED, isReviewed: true });
          dispatch({
            type: actionTypes.SET_QUESTIONS,
            payload: { isReviewed: true },
          });
          scrollToTop(500);
          let params = {
            employeeId: paramsData.employeeId.employeeId,
            employeeSubGroupId: paramsData.employeeId.subGroupId,
            employeeParentGroupId: paramsData.employeeId.parentGroupId,
            surveyCycleId: paramsData.surveyCycleId,
          };
          http
            .get(VIEW_CYCLE, {}, { params })
            .then((response) => response.data.data)
            .then((response) => {
              if (response) {
                dispatch({
                  type: actionTypes.SET_CYCLE_DATA,
                  payload: { data: response },
                });
                dispatch({
                  type: actionTypes.SET_QUESTIONS,
                  payload: { data: response },
                });
              }
            })
            .catch(() => dispatch({ type: actionTypes.TEAM_HISTORY_API_FAILURE }));
        }
      })
      .catch(() => dispatch({ type: actionTypes.TEAM_HISTORY_API_FAILURE }));
  };
};

export const ViewCycle = (data) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
    surveyCycleId: data.surveyCycleId,
  };
  return (dispatch) => {
    dispatch({
      type: actionTypes.SURVEY_CYCLE_CLICK,
      payload: {
        isLoading: true,
      },
    });
    setTimeout(() => {
      http
        .get(VIEW_CYCLE, {}, { params })
        .then((response) => response.data.data)
        .then((response) => {
          if (response) {
            let currentUser = response.employeeProfile;

            const user = {
              employeeInitial: response.employeeProfile.employeeInitial,
              id: currentUser.employeeId,
              name: currentUser.displayName || currentUser.userName,
              designation: currentUser.designation,
              department: currentUser.department,
              image: currentUser.dispArr,
              band: currentUser.band,
              totalExp: currentUser.totalExp,
              expBeforeJoining: currentUser.expBeforeJoining,
              isExp: currentUser.isExp,
              completed: 5,
              total: 10,
              parentGroupId: currentUser.parentGroupId,
              subGroupId: currentUser.subGroupId,
            };
            const currentCheckIn = { start: "", end: "" };

            currentCheckIn["start"] = moment(response.startDate).format(config.dates.shortMonthNameAndDate);
            currentCheckIn["end"] = moment(response.endDate).format(config.dates.shortMonthNameAndDate);
            const selectedRating = response.questionsAnswer.filter((rating) => rating.type === "RATING");
            const moodoMeter = response.questionsAnswer.filter((rating) => rating.type === "MOODOMETER");
            dispatch(CheckWinChallenge(data.surveyCycleId, data));

            dispatch({
              type: actionTypes.SET_CYCLE_DATA,
              payload: {
                data: response,
                checkinDetails: currentCheckIn,
                currentCheckIn,
                user,
                selectedRating: selectedRating[0].answer,
                moodoMeter: moodoMeter,
              },
            });
            if (response.status !== "PENDING_FROM_MEMBER") {
              dispatch({
                type: actionTypes.SET_QUESTIONS,
                payload: {
                  data: response,
                  rating: selectedRating[0].answer || null,
                  checkinDetails: currentCheckIn,
                  currentCheckIn,
                  user,
                },
              });
            }
          }
        })
        .catch(() => dispatch({ type: actionTypes.TEAM_HISTORY_API_FAILURE }));
    }, 500);
  };
};

export const updateCommentInCheckinTemplate = (commentToSend, employeeIds) => (dispatch) => {
  http
    .put(UPDATE_MANAGER_COMMENT_IN_TEMPLATE, {}, commentToSend)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch(ViewCycle({ ...employeeIds, surveyCycleId: commentToSend.surveyCycleId }));
      }
    })
    .catch(() => {});
};

export const deleteCommentInCheckinTemplate = (comment, employeeIds) => (dispatch) => {
  http
    .delete(DELETE_MANAGER_COMMENT_IN_TEMPLATE, { data: comment })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch(ViewCycle({ ...employeeIds, surveyCycleId: comment.surveyCycleId }));
      }
    })
    .catch(() => {});
};
