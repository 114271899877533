import React from "react";
import style from "./GradeCard.module.scss";

function GradeCard({ grades, aggregateGrade, overAllAggregateText }) {
  return (
    <div className={style.grade_card_wrapper}>
      <div className={style.manager_rating_wrapper}>
        <div className={style.card_heading}>Overall Progress</div>
        {grades &&
          grades.map((emp, index) => (
            <div className={style.card_text} key={emp.employeeId + index}>
              <div>
                {emp.empName} ({emp.type})
              </div>
              <div>
                <span>{emp.grade}</span>
              </div>
            </div>
          ))}
      </div>
      {aggregateGrade && (
        <div className={style.overAll_wrapper}>
          <div className={style.card_heading}>Aggregate Progress</div>
          <div className={style.card_text}>
            {overAllAggregateText} : <span>{aggregateGrade}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default GradeCard;
