import React from "react";
import { Table } from "react-bootstrap";
import style from "./FeedbackQuestionsTable.module.scss";

const FeedbackQuestionsTable = (props) => {
  const { title, viewsTtitle, comment, questionAnswerList, isRecentCheckin = false } = props;

  const renderAnswer = (answer) => {
    switch(answer) {
      case "YES":
        return "Yes";
      case "NO":
        return "No";
      case "NO CHALLENGES RAISED":
        return "No Challenges Raised";
      default: 
        return answer;
    }
  }

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <h3 className={style.heading}>{title}</h3>
          </th>
        </tr>
      </thead>
      <tbody>
        {questionAnswerList?.map((questionAnswer) => {
          const { answer, questionId, questionTitle } = questionAnswer;
          return (
            <tr key={questionId}>
              <td>
                <p className={style.question_text}>{questionTitle}</p>
                {answer ? (
                  <ul>
                    <li className={style.question_result}>
                      <span>{renderAnswer(answer?.toUpperCase())}</span>
                    </li>
                  </ul>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          );
        })}
        {!isRecentCheckin && <tr>
          <td>
            <p className={style.question_text}>{viewsTtitle}</p>
            {comment ? (
              <ul>
                <li className={style.question_result}>
                  <div>
                    <pre>{comment}</pre>
                  </div>
                </li>
              </ul>
            ) : (
              "-"
            )}
          </td>
        </tr>}
      </tbody>
    </Table>
  );
};

export default FeedbackQuestionsTable;
