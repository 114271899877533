import React from "react";
import { Form } from "react-bootstrap";
import UploadFile from "../../Performance/CycleMemberProfile/QuestionAnswer/UploadFile";
import style from "./PreviewTemplate.module.scss";

export default function CheckboxType(props) {
  const { isManagerView, question } = props;
  let options = question.options.map((obj, i) => {
    return (
      <div className="custom-checkbox custom-checkbox-sm no_mar" key={i}>
        <input id={i} type="checkbox" disabled={isManagerView} />
        <label htmlFor={i}>{obj.option}</label>
      </div>
    );
  });
  let responseText = "There would be employee’s comment over here";
  return (
    <div className={[style.question_list, "box-inner-pad", style.disabledClick].join(" ")}>
      <span className={style.question_sr_no}>Q{props.no + 1}</span>
      <div className={style.que_ans_wrapper}>
        <div className={style.question_name}>
          <h4>
            {question.questionName}
            {question.isRequired && <span className="text-danger">*</span>}
          </h4>
        </div>
        {question.descriptionFlag && (
          <p className={[style.answer_description, "mt-1"].join(" ")}>{question.description}</p>
        )}
        <div className={style.ans_wrapper}>
          {options}
          {question.isOther && !isManagerView && (
            <div className="custom-checkbox custom-checkbox-sm no_mar">
              <input type="checkbox" />
              <label>{question.otherText === "" ? "Others (please specify)" : question.otherText}</label>
              <Form.Control as="textarea" placeholder="Add a new answer"></Form.Control>
            </div>
          )}
          {isManagerView ? (
            <p className={[style.answer_description, "mt-3"].join(" ")}>{responseText}</p>
          ) : question.optionalCommentBox ? (
            <div className={style.txt_box_wrapper}>
              <Form.Group controlId="">
                <div className="textarea-wrapper mt-3">
                  <UploadFile questionId="QUESTION_ID" uploadFile={() => {}} />
                  <Form.Control
                    as="textarea"
                    maxLength="5000"
                    placeholder={`Add a new answer (${question.commentResponse})`}
                  />
                </div>
              </Form.Group>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
