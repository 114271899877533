import React from "react";
import PropTypes from "prop-types";
import style from "./KeyResultPoint.module.scss";
import { keyValue, keyValueAchieved } from "../../util/general";

function KeyResultPoint({ keypoint }) {
  return (
    <div className={style.keyresult_wrapper}>
      <div className={style.keyresult_item}>
        <p className={[style.key_description, "text-break", "p-0"].join(" ")}>{keypoint.description}</p>
        <div className={style.percentage_weightage_wrapper}>
          <span className={style.key_percentage}>{keypoint.percentage}%</span>
          <span className={style.weightage}>Weightage: {keypoint.weightage}</span>
        </div>
        <div className={[style.key_values, style.Key_n_value].join(" ")}>Key Value Assigned : {keyValue(keypoint)}</div>
        <div className={[style.key_values, style.Key_n_value].join(" ")}>
          Key Value achieved : {keyValueAchieved(keypoint)}
        </div>
        {keypoint.stretch && (
          <div className={[style.key_values, style.Key_n_value].join(" ")}>This is the stretch key result</div>
        )}
      </div>
    </div>
  );
}

KeyResultPoint.propTypes = {
  keypoint: PropTypes.shape({
    stretch: PropTypes.bool,
    description: PropTypes.string,
  }),
};

export default KeyResultPoint;
