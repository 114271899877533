import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import ManagerViewMonthlyCheckin from "components/MonthlyCheckIn/ManagerViewMonthlyCheckin/MonthlyCheckInWrapper";
import style from "./TeamsCheckIn.module.scss";

export default function TeamsCheckIn() {
  return (
    <Fragment>
      <SubNavigation type="Monthly Checkin" />
      <Container>
        <div className={style.container_wrapper}>
          <ManagerViewMonthlyCheckin />
        </div>
      </Container>
    </Fragment>
  );
}
