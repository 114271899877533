import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import { TEAM_VIEW, USER_PROFILE } from "../../util/endpoints";

export const GetTeam = () => {
  return (dispatch) =>
    http
      .get(TEAM_VIEW)
      .then((response) => response.data)
      .then((data) => {
        if (data.message.code === 4001) {
          dispatch({
            type: actionTypes.SET_TEAM,
            team: {
              // teamError: true,
              isLoadingTeam: false,
            },
          });
        } else if (data.data) {
          dispatch({
            type: actionTypes.SET_TEAM,
            team: {
              teamData: data.data,
              // teamError: false,
              isLoadingTeam: false,
            },
          });
        }
      })
      .catch(() => dispatch({ type: actionTypes.GET_TEAM_FAILED }));
};

export const ChangePageSize = (size) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PAGESIZE, pageSize: size });
  };
};

export const ChangeCurrentPage = (size) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CURRENT_PAGE, currentPage: size });
  };
};

export const GetUser = (paramsData) => {
  let params = {
    employeeId: paramsData.employeeId,
    employeeSubGroupId: paramsData.subGroupId,
    employeeParentGroupId: paramsData.parentGroupId,
  };
  return (dispatch) => {
    dispatch({ type: "START_MY_TEAM_LOADING" });
    http
      .get(USER_PROFILE, {}, { params })
      .then((response) => response.data)
      .then((data) => {
        if (data.data) {
          dispatch({ type: actionTypes.SET_USER, user: data.data, isLoading: false });
        } else {
          dispatch({ type: actionTypes.SET_USER, user: null, isLoading: false });
        }
      })
      .catch(() => dispatch({ type: actionTypes.SET_USER, user: null, isLoading: false }));
  };
};
