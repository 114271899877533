import React from "react";
import { ReactSVG } from "react-svg";
import { IMG_NO_NOTIFICATION } from "const/imgCost";

const NoNotification = () => (
  <>
    <div className="nonotification_wrapper">
      <div>
        <ReactSVG src={`${IMG_NO_NOTIFICATION}`} title="" alt="" />
      </div>
      <h3>No Notifications </h3>
      <h4>You haven’t got any notifications yet</h4>
    </div>
  </>
);

export default NoNotification;
