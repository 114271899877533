import FilterTeam from "components/FilterTeam/FilterTeam";
import React, { useEffect, useState } from "react";
import { Col, Table } from "react-bootstrap";
import { moduleView, toFixedNumber } from "util/utils";
import Pagination from "shared/Pagination/Pagination";
import CampaignQuizEmployeeRow from "./CampaignQuizEmployeeRow";
import style from "./CampaignQuizEmployeeTable.module.scss";
import { quizEmployee } from "assets/mocks/filterViewEmployeeData";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NoDataFound from "components/NodataFound/NoDataFound";
import * as actions from "store/actions/index";
import { noData } from "const/constValue";
import routePath from "const/routePath";
import { getEmployeeIdsArray, getStringArray, successToast } from "util/general";
import config from "util/config";
import storage from "util/storage";

function CampaignQuizEmployeeTable({ campaignId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [pageNumber, setPageNumber] = useState();
  const [offSet, setOffSet] = useState();
  const isCampaignAdmin = moduleView(config.hrModule.CAMPAIGN_DETAILS)?.includes(config.hrModule.CAMPAIGN_DETAILS);
  const {
    employeeCampaignQuizList,
    attemptedPercentage,
    notattemptedPercentage,
    totalCount,
    campaignFilterSearch,
    employeeQuizDataExport,
  } = useSelector((state) => state.campaign);
  const { expanded } = useSelector((state) => state.myTeam);

  const { filterEmployee, startDate, endDate, filterDesignation, filterMemberType, filterStatus } =
    campaignFilterSearch;

  useEffect(() => {
    dispatch({
      type: "STATUS_AND_PAGINATION_QUIZ_DATA",
      payload: {
        pageNumber: pageNumber ? pageNumber : null,
        offSet: offSet ? offSet : null,
        filter: statusFilter ? statusFilter : null,
        name: searchFilter ? searchFilter : null,
      },
    });
  }, [pageNumber, offSet, statusFilter, searchFilter]);

  const getEmployeeQuizDetailsData = (data) => {
    dispatch(actions.getEmployeeCampaignQuizDetail(campaignId, { ...data }));
  };

  const onChangePage = (currentPage, pageSize) => {
    setPageNumber(currentPage);
    setOffSet(pageSize);
    getEmployeeQuizDetailsData({
      pageNumber: currentPage.toString(),
      offSet: pageSize.toString(),
      name: searchFilter,
      filter: statusFilter,
      startDate: startDate,
      endDate: endDate,
      employeeType: getStringArray(filterMemberType, "label"),
      designation: getStringArray(filterDesignation, "label"),
      employeeIds: getEmployeeIdsArray(filterEmployee),
      status: filterStatus?.value,
    });
  };

  const search = (e) => {
    setSearchFilter(e.target.value);
  };

  const searchMember = (event) => {
    getEmployeeQuizDetailsData({
      name: event.target.value,
      filter: statusFilter,
      startDate: startDate,
      endDate: endDate,
      employeeType: getStringArray(filterMemberType, "label"),
      designation: getStringArray(filterDesignation, "label"),
      employeeIds: getEmployeeIdsArray(filterEmployee),
      status: filterStatus?.value,
      pageNumber: pageNumber,
      offSet: offSet,
    });
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value);
    getEmployeeQuizDetailsData({
      name: searchFilter,
      filter: value,
      startDate: startDate,
      endDate: endDate,
      employeeType: getStringArray(filterMemberType, "label"),
      designation: getStringArray(filterDesignation, "label"),
      employeeIds: getEmployeeIdsArray(filterEmployee),
      status: filterStatus?.value,
      pageNumber: pageNumber,
      offSet: offSet,
    });
  };

  const resetSearch = () => {
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter("");
    getEmployeeQuizDetailsData({ name: "", filter: statusFilter });
  };

  const handleExport = () => {
    successToast("Your files would be downloaded in background");
    let param = {
      campaignId: campaignId,
    };

    let body = {
      employeeIds: filterEmployee ? getEmployeeIdsArray(filterEmployee) : null,
      designation: filterDesignation ? getStringArray(filterDesignation, "label") : null,
      employeeType: filterMemberType ? getStringArray(filterMemberType, "label") : null,
      status: filterStatus ? filterStatus.value : null,
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      name: searchFilter ? searchFilter : null,
      filter: statusFilter ? statusFilter : null,
      offSet: "10",
      pageNumber: "1",
    };
    dispatch(actions.getEmployeeQuizResultReport(param, body));
  };

  const filter = {
    value: ["", "Pass", "Fail"],
    name: ["All", "Pass", "Fail"],
  };

  const quizEmployeeeDetail =
    employeeCampaignQuizList &&
    employeeCampaignQuizList?.length > 0 &&
    employeeCampaignQuizList?.map((value) => {
      if (value?.listAttemptResult && value?.listAttemptResult?.length > 0) {
        return value?.listAttemptResult?.map((attempt) => {
          return { ...attempt, ...value };
        });
      } else {
        return [value];
      }
    });

  const resultHandler = (data, attemptNumber) => {
    storage.setJSON("quizHrViewEmployeeData", {
      campaignId: campaignId,
      attemptNumber: attemptNumber,
      employeeId: data.employeeId,
      subGroupId: data.subGroupId,
      parentGroupId: data.parentGroupId,
    });
    history.push({
      pathname: isCampaignAdmin ? routePath.QUIZ_EMPLOYEE_RESULT : routePath.QUIZ_EMPLOYEE_RESULT_SUB_ADMIN,
      state: data,
    });
  };

  const renderQuizResult = (data) => {
    return data.map((value, index) => {
      return (
        <CampaignQuizEmployeeRow
          key={index}
          index={index}
          name={""}
          image={""}
          designation={value.employeeDetails?.designation}
          department={value.employeeDetails?.department}
          onExpand={true}
          result={value?.result}
          attemptNumber={value.attemptNumber}
          handleResultClick={() => resultHandler(value.employeeDetails, value.attemptNumber)}
          // handleResultClick={() => history.push(`${routePath.QUIZ_EMPLOYEE_RESULT}`)}
        />
      );
    });
  };

  function toggleExpander(empId) {
    expanded === empId
      ? dispatch({ type: "EXPANDED", expanded: null })
      : dispatch({ type: "EXPANDED", expanded: empId });
  }

  return (
    <Col className="col-12">
      <div className={[style.campaign_quiz_table_box, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Quiz</h2>
            <h3>View the details of employees</h3>
          </div>
          <div className={style.pending_submit_info}>
            <span className={style.pending}>
              Pending
              <small>{toFixedNumber(notattemptedPercentage, 2)}%</small>
            </span>
            <span className={style.submitted}>
              Submitted
              <small>{toFixedNumber(attemptedPercentage, 2)}%</small>
            </span>
          </div>
        </div>
        <div className="outer-table-wrapper">
          <FilterTeam
            placeholder="Search for team members"
            filter={filter}
            filterValue={statusFilter}
            value={searchFilter ? searchFilter : ""}
            teamData={quizEmployeeeDetail}
            onSearch={search}
            onRadioChange={(e) => handleStatusChange(e.target.value)}
            onResetSearch={resetSearch}
            searchMember={searchMember}
            isExport={true}
            handleExport={handleExport}
          />
          <div className="box-light-gradient"></div>
          <div>
            <Col xs={12}>
              <div className="custom-table table-theme-1 pt20">
                {quizEmployeeeDetail && quizEmployeeeDetail?.length > 0 ? (
                  <Table hover responsive className={style.tab_table_responsive}>
                    <thead>
                      <tr>
                        <th>Employee Name</th>
                        <th>Location</th>
                        <th>Attempts</th>
                        <th>Result</th>
                        <th>Quiz Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quizEmployeeeDetail &&
                        quizEmployeeeDetail?.length > 0 &&
                        quizEmployeeeDetail.map((item) =>
                          item?.slice(0, 1).map((value, index) => (
                            <React.Fragment key={value.employeeId}>
                              <CampaignQuizEmployeeRow
                                key={index}
                                index={index}
                                name={value.employeeDetails?.displayName}
                                image={value.employeeDetails?.dispArr || value.employeeDetails?.empInitial}
                                designation={value.employeeDetails?.designation}
                                department={value.employeeDetails?.department}
                                location={value.employeeDetails?.location}
                                attempts={value?.number_Of_Attemptes_done}
                                result={value?.result}
                                isExpanded={expanded === value.employeeId}
                                toggleExpander={() => toggleExpander(value.employeeId)}
                                showExpand={!value.attempted ? false : item.length > 1 ? true : false}
                                onExpand={false}
                                attemptNumber={value.attemptNumber}
                                attempted={value.attempted}
                                handleResultClick={() => resultHandler(value.employeeDetails, value.attemptNumber)}
                              />

                              {item.length > 1 && expanded === value.employeeId && renderQuizResult(item.slice(1))}
                            </React.Fragment>
                          ))
                        )}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap80"></div>
                    <div className="gap80"></div>
                  </div>
                )}
              </div>
            </Col>
          </div>
        </div>
      </div>
      {totalCount > 0 && <Pagination totalItem={totalCount} onChangePage={onChangePage} />}
    </Col>
  );
}
export default CampaignQuizEmployeeTable;
