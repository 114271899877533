import { monthOptions5,yearOptions } from "const/options";
import React, { useEffect, useState } from "react";
import ComposeChart from "shared/Chart/ComposeChart/ComposeChart";
import TitleAndDropdown from "shared/Dropdown/TextDropdown/TitleAndDropdown";

function TeamMemberTimelineGraph({
  handleTeamCycleDropdown,
  employeeGraphData,
  handleGraphClick,
  handleDeallocationGraphClick,
  selectedCycle,
}) {
  const [timeLineDropdown, setTimeLineDropdown] = useState({
    value: "currentDate",
    label: "2024 Onwords",
  });

  const handleDropdownChange = (data) => {
    handleTeamCycleDropdown(data);
    setTimeLineDropdown(data);
  };

  useEffect(()=>{
    if(selectedCycle){
      const dropdownValue=yearOptions.find(option => option.label.includes(selectedCycle.year))
      handleDropdownChange(dropdownValue)
    }
  },[selectedCycle])

  return (
    <div>
      <TitleAndDropdown options={yearOptions} defaultValue={timeLineDropdown} handleChange={handleDropdownChange} />

      <ComposeChart
      //  isStartDate={timeLineDropdown?.value === "startDate"}
       isCurrentDate={timeLineDropdown?.value}
        projectLineVisible={employeeGraphData.projectLineVisible}
        assessmentAreaVisible={employeeGraphData.assessmentAreaVisible}
        data={employeeGraphData.timelineGraph}
        handleDeallocationGraphClick={handleDeallocationGraphClick}
        projects={employeeGraphData.currentProjectLineGraph}
        type={employeeGraphData.timelineGraph?.some((v) => v.gradeOrRatingOrBoth === "RATING") ? "Rating" : timeLineDropdown?.value === "startDate" ? "Rating":timeLineDropdown?.value === "currentDate" ? "Rating" : "Grade"}
        // type={employeeGraphData.timelineGraph?.some((v) => v.gradeOrRatingOrBoth === "RATING") ? "Rating" : "Grade"}
        handleAssessmentClick={handleGraphClick}
        isPage="MANAGER"
      />
    </div>
  );
}

export default TeamMemberTimelineGraph;
