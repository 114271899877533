import React from "react";
import { render } from "react-dom";
import App from "./App";
import "./assets/fonts/stylesheet.css";
import "./base.scss";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import "./util/interceptors";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import store from "./store/store";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
export const history = createBrowserHistory();

const app = (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
