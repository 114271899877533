import React, { useEffect, useState } from "react";
import style from "./GrowQuestion.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../store/actions/index";
import moment from "moment";
import CommentsTop from "../CommentBox/CommentsTop";
import Comment from "../CommentBox/Comment";
import { backGroundColor } from "../../const/constValue";
import CommentTextArea from "../CommentBox/CommentTextArea";
import storage from "../../util/storage";

/*
 * PROPS: edit
 */
export default function GrowQuestion(props) {
  const loggedInUser = storage.getUser();
  const dispatch = useDispatch();
  const { helpToGrow, surveyCycleId, user, employee, reviewedDate } = useSelector((state) => state.questions);
  const { submitable } = useSelector((state) => state.checkIn);

  /*  STATES  */
  const [chars, setChars] = useState(250);
  const [comment, setComment] = useState("");
  const [managerComments, setManagerComments] = useState(helpToGrow || []);

  const reviewDays = reviewedDate != null ? Math.abs(moment(reviewedDate).diff({}, "days")) : 1;

  const configReviewedDays = process.env.REACT_APP_MANAGER_COMMENTS_DAYS;

  /* Change in helpToGrow - componentDidMount */
  useEffect(() => setManagerComments(helpToGrow || []), [helpToGrow]);

  // Return null if no helpToGrow
  // if (helpToGrow && helpToGrow.length === 0) return null;

  const changeHandle = (qAnswer) => {
    setChars(250 - qAnswer.length);
    setComment(qAnswer);

    if (qAnswer) {
      let errors = [...submitable];
      let error = { key: "growComment" };

      let index = errors.findIndex((x) => x.key === "growComment");
      index === -1 && errors.push(error);
      dispatch(actions.validationInSubmit(errors));
    } else {
      let errors = null;
      errors = submitable.filter((item) => item.key !== "growComment");
      dispatch(actions.validationInSubmit(errors));
    }
  };

  const handleGrowAnswer = () => {
    if (comment.trim().length < 1) return true;
    setComment("");
    setChars(250);
    const params = { surveyCycleId: surveyCycleId };
    const response = {
      comment: comment.trim(),
      commentedBy: {
        employeeId: loggedInUser?.employeeId,
        empName: loggedInUser?.displayName,
        displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
      },
    };
    dispatch(actions.HelpToGrowAnswer(params, response));

    let errors = null;
    errors = submitable.filter((item) => item.key !== "growComment");
    dispatch(actions.validationInSubmit(errors));
  };

  const deleteComment = (commentId, index) => {
    const comment = { commentId: commentId, surveyCycleId: surveyCycleId };
    dispatch(actions.DeleteHelpToGrow({ data: comment }, index));
  };

  if (props.edit) {
    return (
      <>
        <div className={["box pb-0", style.saveBox].join(" ")}>
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>
                Help {user && user.name} grow <span className="optional">(optional)</span>
              </h2>
              <h3>Tell {user && user.name} what you liked and what you'd like to see more of.</h3>
            </div>
          </div>
          <div>
            <div className="box-inner-pad">
              {managerComments.length === 0 && reviewDays > configReviewedDays ? (
                "You have not provided any view(s)"
              ) : (
                <div className="view_comment_box_wrapper collapse show mt-0 mb-0">
                  <CommentsTop heading="Share your view(s)" />
                  {managerComments &&
                    managerComments.map((comment, index) => (
                      <Comment
                        key={"grow-quest-comment-" + index}
                        selectedColor={backGroundColor[1]}
                        image={comment && comment.commentedBy.displayPicture}
                        empName={comment && comment.commentedBy.empName}
                        commentedOn={comment.commentedOn}
                        comment={comment.comment}
                        isPrivate={false}
                        deletable={
                          reviewDays <= configReviewedDays && loggedInUser.employeeId === comment.commentedBy.employeeId
                        }
                        deleteComment={() => deleteComment(comment.commentId, index)}
                      />
                    ))}

                  {reviewDays <= configReviewedDays && (
                    <CommentTextArea
                      chars={chars}
                      showChars={true}
                      value={comment}
                      selectedColor={props.selectedColor}
                      image={employee && (employee.image || employee.empInitial)}
                      changeHandle={(e) => changeHandle(e.target.value)}
                      placeholder="Add a comment"
                      maxLength={250}
                      submitButton={true}
                      submitComment={handleGrowAnswer}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else
    return (
      <>
        <div className={["box pb-0", style.saveBox].join(" ")}>
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>Tips to Grow</h2>
              <h3>Here is where your manager wants to grow you up</h3>
            </div>
          </div>
          <div className="box-inner-pad">
            {(!helpToGrow || helpToGrow.length < 1) && (
              <p className={style.no_diff_planstxt}>
                {" "}
                It seems your manager has no view on this or may have different plans
              </p>
            )}
            {helpToGrow && helpToGrow.length > 0 && (
              <div className="view_comment_box_wrapper bg-white collapse show mt-0 mb-0">
                <CommentsTop heading="Your manager's view" />
                {helpToGrow.map((comment, index) => (
                  <Comment
                    key={"grow-quest-comment-" + index}
                    selectedColor={backGroundColor[1]}
                    image={comment.commentedBy && comment.commentedBy.displayPicture}
                    empName={comment.commentedBy && comment.commentedBy.empName}
                    commentedOn={comment.commentedOn}
                    comment={comment.comment}
                    isPrivate={false}
                    deletable={
                      reviewDays <= configReviewedDays && loggedInUser?.employeeId === comment.commentedBy.employeeId
                    }
                    deleteComment={() => deleteComment(comment.commentId, index)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </>
    );
}
