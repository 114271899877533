import React, { useEffect, useState } from "react";
import { Button, Col, Row, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import MultiSelect from "react-multi-select-component";
import style from "./AdminDefineRoles.module.scss";
import * as actions from "store/actions/index";
import { errorToast, requiredFieldMissing, successToast } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import CustomDropdown from "shared/Dropdown/CustomDropdown";
import TooltipText from "../../TooltipText/TooltipText";
import NoDataFound from "../../NodataFound/NoDataFound";
import ConfirmationPopup from "../../Objectives/ConfirmationPopup";
import { noData } from "const/constValue";
import routePath from "const/routePath";

export default function AdminDefineRoles() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [roles, setRoles] = useState();
  const [selectedEmployees, setSelectedEmployees] = useState();
  const [display, setDisplay] = useState(false);
  const [roleName, setRoleName] = useState();
  const headerText = "Sure you want to delete this role?";
  const subText = "We recommend editing the role. Once deleted, you won't be able to undo it.";
  const { employeesList, roleOptions, roleRightsList, apiResponse, isLoading } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(actions.getRoleRightsList());
    // dispatch(actions.getAllDataForAssignRole());
  }, [dispatch]);

  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.success) successToast(apiResponse.message);
      else errorToast(apiResponse.message);

      dispatch({ type: "CLEAR_ROLE_ASSIGN_RESPONSE" });
    }
  }, [dispatch, apiResponse]);

  const getEmployeeNameList = (value) => {
    if (value?.length > 2) {
      dispatch(actions.getEmployeeNameListForAdmin(value));
    }
  };

  const handleRoleAssign = () => {
    if (roles?.length > 0 && selectedEmployees?.length > 0) {
      let body = {
        roleName: roles.map((v) => v.label),
        employeeId: selectedEmployees.map((v) => {
          return {
            employeeId: v.value,
            parentGroupId: v.parentGroupId,
            subGroupId: v.subGroupId,
          };
        }),
      };
      dispatch(actions.createUserRoleRights(body));
      setRoles();
      setSelectedEmployees();
    } else {
      requiredFieldMissing();
    }
  };
  const deleteRole = (roleName) => {
    setDisplay(true);
    setRoleName(roleName);
  };
  const ConfirmDeleteRole = () => {
    dispatch(actions.deleteRoleRight(roleName));
    setDisplay(false);
  };

  return (
    <div className={style.roll_based_skill_wrapper}>
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6}>
            <h1 className="page-title pb-0 mt-3">Admin</h1>
            <span className="st-breadcrumb">
              Admin <span>Roles</span>
            </span>
          </Col>
          <Col md={6} className={[style.res_title_btn, "text-right mt-4"].join(" ")}>
            <button
              type="button"
              onClick={() => history.push(routePath.ADMIN_CREATE_ROLE + "/CREATE")}
              className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
            >
              <strong>Create New role</strong>
            </button>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className={[style.define_admin_roles_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Roles and Responsibilities</h2>
                  <h3>Assign Roles for the following Assignees.</h3>
                </div>
              </div>
              <div className="box-inner-pad">
                <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
                  <Form.Group controlId="">
                    <Form.Label className={style.dd_title}>Roles</Form.Label>
                    <div className={["tags_wrapper", style.role_dropdown].join(" ")}>
                      <MultiSelect
                        className="multi-select"
                        classNamePrefix="select"
                        isSearchable={true}
                        options={roleOptions}
                        value={roles}
                        onChange={(e) => setRoles(e)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group controlId="">
                    <Form.Label className={style.dd_title}>Assignees</Form.Label>
                    <div className="tags_wrapper">
                      <Multiselect
                        id={"multi-select-search-dd-box"}
                        options={employeesList || []} // Options to display in the dropdown
                        selectedValues={selectedEmployees} // Preselected value to persist in dropdown
                        onSelect={(list) => setSelectedEmployees(list)} // Function will trigger on select event
                        onRemove={(list) => setSelectedEmployees(list)} // Function will trigger on remove event
                        displayValue="label" // Property name to display in the dropdown options
                        onSearch={(e) => getEmployeeNameList(e)}
                        closeOnSelect={false}
                        showCheckbox={true}
                        placeholder="Search"
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className={style.save_box_left}>
                  <Button type="button" variant={"primary"} onClick={handleRoleAssign}>
                    <strong>Assign</strong>
                  </Button>
                </div>
              </div>
            </div>
            <div className={[style.roles_history_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Roles</h2>
                  {/* <h3>Now you can also add skills apart from MMG defined for you</h3> */}
                </div>
              </div>
              <div className="box-light-gradient"></div>
              <div className="box-inner-pad p-0">
                <div className={[style.defined_roles_table, "custom-table", "table-theme-1"].join(" ")}>
                  {roleRightsList && roleRightsList.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className={style.roles}>Roles</th>
                          <th className={style.right}>Sub Group</th>
                          <th className={style.right}>Rights</th>
                          <th className={style.department}>Department</th>
                          <th className={style.modules}>Module</th>
                          <th className={style.indiaDD}>IndiaDD</th>
                          <th className={style.band}>Band</th>
                          <th className={style.location}>Location</th>
                          <th className={style.action}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleRightsList.map((item, index) => (
                          <tr key={item.roleName + index}>
                            <td>{item.roleName}</td>
                            <td>{item.subGroupName}</td>
                            <td>
                              <TooltipText len={13} array={item.rightsList} />
                            </td>
                            <td>
                              <TooltipText len={13} array={item.department} />
                            </td>
                            <td>
                              <TooltipText len={13} array={item.modules} />
                            </td>
                            <td>
                              {item.indiaDD?.length > 0 ? (
                                <TooltipText len={13} array={item.indiaDD.map((v) => v.employeeId?.split("&&")[1])} />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>{item.band?.length > 0 ? <TooltipText len={13} array={item.band} /> : "-"}</td>
                            <td>
                              <TooltipText len={13} array={item.location} />
                            </td>
                            <td>
                              <CustomDropdown
                                editPath={`/admin/create-role/${item.roleName}`}
                                handleDelete={() => deleteRole(item.roleName)}
                                deleteText="Delete"
                                editText="Edit Role"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                      <div className="gap80"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmationPopup
        headerText={headerText}
        subText={subText}
        show={display}
        submit={() => ConfirmDeleteRole()}
        hide={() => setDisplay(false)}
      />
    </div>
  );
}
