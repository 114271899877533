import React from "react";
import style from "./Plans.module.scss";
import Priority from "../../shared/Priority";

const PreviousPlans = props => {
  return (
    <div className="box-inner-pad">
      <div className="box_inner_title">
       {props.edit 
        ? "Plans completed from your past okr progress"
        : "Mark plans from your past okr progress as complete" } 
      </div>
      <div className={style.priorities_list}>
        {props.previousPlans &&
          props.previousPlans.map(
            (previousPlan, index) =>
              !previousPlan.carryForwarded && (
                <Priority
                  index={index}
                  checked={props.checked}
                  key={("PREVIOUSPLAN_", previousPlan.planId)}
                  previousPlan={previousPlan}
                  edit={props.edit}
                  carryForward={props.carryForward}
                  priorityKey={1}
                  isReviewing={props.managerView}
                />
              )
          )}
      </div>
    </div>
  );
};
export default PreviousPlans;
