import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  user: null,
  currentCheckIn: null,
  isSubmitted: null,
  data: null,
  selectedRating: null,
  moodoMeter: null,
  reviewer: null,
  surveyData: null,
  employee: null,
  isReviewed: false,
  checkinDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MEMBER_DATA:
      return { ...state, ...action.payload, isLoading: false };
    case actionTypes.SURVEY_CYCLE_CLICK:
      return { ...state, ...action.payload };
    case actionTypes.SET_MEMBER_REVIEWER:
      return { ...state, reviewer: action.reviewer, isLoading: false };
    case actionTypes.SET_SURVEY_HISTORY:
      return { ...state, ...action.payload, isLoading: false };
    case actionTypes.SET_IS_REVIEWED:
      return { ...state, isReviewed: action.isReviewed, isLoading: false };
    case actionTypes.SET_CYCLE_DATA:
      return { ...state, ...action.payload, isLoading: false };
    case actionTypes.TEAM_HISTORY_API_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
