import React from "react";
import PropTypes from "prop-types";
import { IC_FILE_UPLOAD_1 } from "../../../../const/imgCost";
import { ReactSVG } from "react-svg";

function UploadFile({ questionId, uploadFile }) {
  return (
    <div className="attached-pin-icon">
      <div className="custom-tooltip">
        <div className="box-info">
          <label className="custom-file-upload mb-0 mt-1">
            <input
              type="file"
              id={"FILE_" + questionId}
              name="file"
              onChange={uploadFile}
              accept="image/png, .jpeg, .jpg, image/gif, application/pdf, .doc, .docx, .odf"
            />
            <span>
              <ReactSVG src={`${IC_FILE_UPLOAD_1}`} title="" alt="Choose File" />
            </span>
          </label>
        </div>
        <div className="info-attach-file">
          <span className="attachment-title">Attachments</span>
          <ul>
            <li>You can upload a single file only</li>
            <li>Maximum file size is 2MB</li>
            <li>Supported files &quot;pdf, png, jpg, doc, docx, ppt, pptx, xls, xlsx, zip&quot;</li>
          </ul>
          <span className="attachment-note">Note:</span>
          <p>We always encourage Apexon Associates to upload large files on Microsoft OneDrive and share link here.</p>
        </div>
      </div>
    </div>
  );
}

UploadFile.propTypes = {
  questionId: PropTypes.number,
  uploadFile: PropTypes.func,
};

export default UploadFile;
