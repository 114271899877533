import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Table } from "react-bootstrap";
import style from "./CampaignList.module.scss";
import * as actions from "store/actions/index";
import { camelCase, errorToast, filterArray, successToast } from "util/general";
import { moduleRights, moduleView } from "util/utils";
import { dateFormat2 } from "util/dateUtils";
import config from "util/config";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import CustomDropdown from "shared/Dropdown/CustomDropdown";
import Pagination from "shared/Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";
import ConfirmationPopup from "../../Objectives/ConfirmationPopup";
import { menuText, noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import FilterRadio from "components/FilterRadio/FilterRadio";

function CampaignList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categoryForDelete, setCategoryForDelete] = useState("");
  const { campaignListForAdmin, isLoading, apiResponse, isCreateLoading, totalCount } =
    useSelector((state) => state.campaign);
  const headerText = "Are you sure you want to delete this Campaign?";
  const subText = "We recommend editing the campaign. Once deleted, you won't be able to undo it.";
  storage.remove("cId");
  const isCampaignAdmin = moduleView(config.hrModule.CAMPAIGN_DETAILS)?.includes(config.hrModule.CAMPAIGN_DETAILS);

  const editCampaignPath = (id) => {
    if (isCampaignAdmin) return `/hr/campaign-details/hr-edit?id=${id}`;
    else return `/okrprogress/campaign-list/edit?id=${id}`;
  };

  const cloneCampaignPath = (id) => {
    if (isCampaignAdmin) return `/hr/campaign-details/hr-clone?id=${id}`;
    else return `/okrprogress/campaign-list/clone?id=${id}`;
  };

  const viewCampaignDetailsPath = (id, category) => {
    if (isCampaignAdmin) {
      storage.set("campaign-category", category);
      return `/hr/campaign-details/admin/view/${id}`;
    } else {
      storage.set("campaign-category", category);
      return `/okrprogress/campaign-list/view/${id}`;
    }
  };

  useEffect(() => {
    getCampaignListForAdminAndCount({});
    dispatch({
      type: "CLEAR_CAMPAIGN_VIEW_FILTER_DATA",
    });
    dispatch({ type: "RESET_QUESTION_MAP" });
  }, [dispatch]);

  const getCampaignListForAdminAndCount = (data) => {
    dispatch(actions.getCampaignListForAdmin(data));
  };

  const onChangePage = (currentPage, pageSize) => {
    getCampaignListForAdminAndCount({
      pageNumber: currentPage.toString(),
      offset: pageSize.toString(),
      category: categoryFilter,
    });
  };

  const handleCategoryChange = (value) => {
    setCategoryFilter(value);
    getCampaignListForAdminAndCount({ category: value });
    dispatch({ type: "CLEAR_PAGINATION_DATA" });
  };
  useEffect(()=>{
    dispatch({ type: "CLEAR_PAGINATION_DATA" });
  },[])

  // useEffect(() => {
  //   if (apiResponse) {
  //     apiResponse.success ? successToast(apiResponse.message) : errorToast(apiResponse.message);
  //     dispatch({ type: "CLEAR_CAMPAIGN_RESPONSE" });
  //   }
  // }, [apiResponse, dispatch]);

  const handleDelete = (id, category) => {
    setCampaignId(id);
    setCategoryForDelete(category);
    setConfirmPopup(true);
  };

  const deleteCampaign = () => {
    dispatch(actions.deleteCampaign(campaignId, categoryForDelete));
    setConfirmPopup(false);
  };

  const filter = {
    value: ["", "Survey", "Quiz"],
    name: ["All", "Survey", "Quiz"],
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!isCampaignAdmin && <SubNavigation type="OKR Progress" />}
      {isCreateLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">
              {isCampaignAdmin ? menuText.HR : menuText.CHECK_IN}
            </h1>
            <span className="st-breadcrumb">
              {isCampaignAdmin ? menuText.HR : menuText.CHECK_IN}
              <span>{menuText.CAMPAIGN}</span>
            </span>
          </Col>
          {moduleRights(
            config.mainModule.CAMPAIGN_DETAILS,
            config.subModule.CAMPAIGN
          )?.includes("CREATE") && (
            <Col md={6} className="text-right m-text-left">
              <button
                onClick={() =>
                  history.push(routePath.HR_CAMPAIGN_DETAILS_CREATE)
                }
                type="button"
                className={[
                  "btn-round save_box_btn btn btn-primary",
                  style.create_new_btn,
                ].join(" ")}
              >
                <strong>Create New</strong>
              </button>
            </Col>
          )}
          <Col className="col-12">
            <div className="box pb-0 mb-0">
              <div className="box-heading-wrapper">
                <div className="box-heading w-auto">
                  <h2>Campaign List</h2>
                  <h3>
                    View campaign details or perform certain actions accordingly
                  </h3>
                </div>
                <FilterRadio
                  filter={filter}
                  filterValue={categoryFilter}
                  onRadioChange={(e) => handleCategoryChange(e.target.value)}
                />
              </div>
            </div>
            <div className="outer-table-wrapper">
              <div className="box-light-gradient"></div>
              <div
                className={[
                  style.custom_table,
                  style.hr_table,
                  "table-theme-1",
                ].join(" ")}
              >
                {campaignListForAdmin && campaignListForAdmin.length > 0 ? (
                  <Table
                    hover
                    responsive
                    className={style.tab_table_responsive}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Starts On</th>
                        <th>Ends On</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campaignListForAdmin.map((list) => (
                        <tr key={list.campaignId}>
                          <td className={style.campaignName}>
                            <span>{list.campaignName}</span>
                          </td>
                          <td className={style.campaignName}>
                            <span>{!list.category ? "-" : list.category}</span>
                          </td>
                          <td className={style.date}>
                            <span>{dateFormat2(list.startDate) ?? "-"}</span>
                          </td>
                          <td className={style.date}>
                            <span>{dateFormat2(list.endDate) ?? "-"}</span>
                          </td>
                          <td>
                            <span
                              className={[
                                "span-status",
                                list.status?.toLowerCase(),
                              ].join(" ")}
                            >
                              {camelCase(list.status)}
                            </span>
                          </td>
                          <td className="">
                            <CustomDropdown
                              disabled={!list.isCampaignAdmin}
                              editPath={() => editCampaignPath(list.campaignId)}
                              handleDelete={() =>
                                handleDelete(list.campaignId, list.category)
                              }
                              clonePath={() =>
                                cloneCampaignPath(list.campaignId)
                              }
                              viewPath={() =>
                                viewCampaignDetailsPath(
                                  list.campaignId,
                                  list.category
                                )
                              }
                              editText={
                                list.status === "Completed"
                                  ? null
                                  : "Edit Campaign"
                              }
                              viewText={
                                list.status !== "DRAFTED"
                                  ? "View Details"
                                  : null
                              }
                              deleteText={
                                list.status === "Completed"
                                  ? null
                                  : "Delete Campaign"
                              }
                              cloneText={
                                list.status !== "DRAFTED"
                                  ? "Clone Campaign"
                                  : null
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap120"></div>
                  </div>
                )}
              </div>
            </div>
            {totalCount > 0 && (
              <Pagination totalItem={totalCount} onChangePage={onChangePage} />
            )}
          </Col>
        </Row>
        <ConfirmationPopup
          show={confirmPopup}
          submit={deleteCampaign}
          hide={() => setConfirmPopup(false)}
          headerText={headerText}
          subText={subText}
        />
      </Container>
    </>
  );
}

export default CampaignList;
