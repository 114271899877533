import React from "react";
import style from "./../TabEngagement.module.scss";
import moment from "moment";
import PropTypes from "prop-types";
import { backGroundColor } from "../../../const/constValue";

export default function FilteredMadeMyDay({ item, index }) {
  let msg = item.message;
  let arr = msg.split("@" + item.receiver.empName);

  return (
    <div className={style.commentStack}>
      <div className={style.user_img_wrapper}>
        <div className={style.user_img} style={{ backgroundColor: backGroundColor[index % backGroundColor.length] }}>
          {item.sender?.displayPicture?.length > 2 ? (
            <img src={item.sender.displayPicture} title="" alt="" />
          ) : (
            item.sender?.displayPicture
          )}
        </div>
        <div
          className={style.user_img}
          style={{ backgroundColor: backGroundColor[(index + 1) % backGroundColor.length] }}
        >
          {item.receiver?.displayPicture.length > 2 ? (
            <img src={item.receiver?.displayPicture} title="" alt="" />
          ) : (
            item.receiver?.displayPicture
          )}
        </div>
      </div>
      <div className={style.detailArea}>
        <div className={style.headArea}>
          <div className={style.namespace} style={{ wordBreak: "break-word" }}>
            <div className={style.nameinfotext}>
              <div className={style.name}>
                {item.sender?.empName} to {item.receiver?.empName}
              </div>
              <div className={style.time}>{moment(item.createdDate).format("ddd D MMM h:mm a")}</div>
            </div>
            <p className={style.made_my_day_info}>
              {arr[0]}
              <span>@{item.receiver?.empName}</span>
              {arr[1]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

FilteredMadeMyDay.propTypes = {
  item: PropTypes.shape({
    receiver: PropTypes.shape({
      empName: PropTypes.string,
      displayPicture: PropTypes.string,
    }),
    sender: PropTypes.shape({
      empName: PropTypes.string,
      displayPicture: PropTypes.string,
    }),
    createdDate: PropTypes.string,
  }),
  index: PropTypes.number,
};
