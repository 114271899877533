import React, { useState } from "react";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Collapse, Dropdown, DropdownButton } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "../WinsChallenges.module.scss";
import { IC_ACHIEVEMENT, IC_MOVE, IC_INFO, IC_CALENDAR, IC_CHALLENGE } from "const/imgCost";
import { dateFormat1 } from "util/dateUtils";
import { monthOptions } from "const/options";
import ModalsPopup from "../../ModalsPopup/ModalsPopup";
import SingleComponent from "../SingleComponent";
import HideAndShow from "../../HideAndShow/HideAndShow";
import config from "util/config";

const Challenges = (props) => {
  const [hide, setHide] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [winChallenge, setWinChallengeId] = useState(null);

  return (
    <div className={["card", style.win_challenges].join(" ")}>
      <div className="card_info">
        <div className="card_title">
          <div className="d-flex">
            <ReactSVG src={`${IC_CHALLENGE}`} className="icon-svg" />
            <div className="card_title_text">Challenges</div>
          </div>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>
      <Collapse in={!hide}>
        <div className="card_details">
          <div className="box-light-gradient"></div>
          <div className={style.to_do_row}>
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <div className="card_action float-right mr-3">
                  <Select
                    onChange={(select) => props.filter(select.value)}
                    options={monthOptions}
                    className="select_dropdown select_dropdown_text"
                    classNamePrefix="select"
                    isSearchable={false}
                    defaultValue={{
                      value: 3,
                      label: "Last 3 Months",
                    }}
                  />
                </div>
              </Col>
            </Row>

            <div className={style.wc_data}>
              <ul
                data-draggable="target"
                id="CHALLENGE"
                className={!props.challengesBucket || (props.challengesBucket.length === 0 ? "heightAuto" : undefined)}
              >
                {!props.challengesBucket ||
                  (props.challengesBucket.length === 0 && (
                    <li className="no-record-wc">No challenges have been added.</li>
                  ))}
                {props.challengesBucket &&
                  props.challengesBucket.length > 0 &&
                  props.challengesBucket.map((challenge) => {
                    return (
                      <li
                        data-draggable="item"
                        key={challenge.winAndChallengeId}
                        id={challenge.winAndChallengeId}
                        style={props.isPage !== "myteam" ? { backgroundImage: "none", cursor: "auto" } : {}}
                      >
                        {challenge.comment.length < 250 ? (
                          <div className="wc-text">{challenge.comment}</div>
                        ) : (
                          <SingleComponent id={challenge.winAndChallengeId} comment={challenge.comment} />
                        )}
                        <span className={style.ic_dot}></span>
                        <div className={style.data_date}>
                          <Link
                            to={
                              props.isPage === "myteam"
                                ? {
                                    pathname: `/okrprogress/my-team/member-okrprogress/${
                                      props.employeeId +
                                      config.employeeIdSplit +
                                      props.parentGroupId +
                                      config.employeeIdSplit +
                                      props.subGroupId
                                    }`,
                                    state: challenge.surveyCyclesId,
                                  }
                                : {
                                    pathname: `/okrprogress/my-history`,
                                    state: challenge.surveyCyclesId,
                                  }
                            }
                          >
                            {dateFormat1(challenge.surveyCyclesStartDate) +
                              " - " +
                              dateFormat1(challenge.surveyCyclesEndDate)}
                          </Link>
                          <div className="custom-tooltip custom-tooltip-top ml-2">
                            <div className="box-info">
                              <span>
                                <ReactSVG src={`${IC_INFO}`} />
                              </span>
                            </div>
                            <div className="info-attach-file">
                              <Row>
                                <Col>
                                  <div className="tTitle">Created&nbsp;On</div>
                                  <div className="tDateBox">
                                    <div className="tCalendar">
                                      <img src={`${IC_CALENDAR}`} title="" alt="Calendar Icon" />
                                    </div>
                                    <div className="tDate">{dateFormat1(challenge?.createdDate)}</div>
                                  </div>
                                </Col>
                                {challenge.movedDate && (
                                  <Col>
                                    <div className="tTitle">Moved&nbsp;On</div>
                                    <div className="tDateBox">
                                      <div className="tCalendar">
                                        <img src={`${IC_CALENDAR}`} title="" alt="Calendar Icon" />
                                      </div>
                                      <div className="tDate">{dateFormat1(challenge?.movedDate)}</div>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>

                        {props.isPage === "myteam" && (
                          <div className={style.dropdown_move_list}>
                            <div className={style.move}>
                              <ReactSVG src={`${IC_MOVE}`} title="" alt="" />
                            </div>
                            <div className="action-dropdown-wrapper">
                              <DropdownButton
                                alignRight
                                title={
                                  <div className="action-dropdown">
                                    <span className={style.dots}></span>
                                    <span className={style.dots}></span>
                                    <span className={style.dots}></span>
                                  </div>
                                }
                                variant="a"
                              >
                                <Dropdown.Item
                                  onClick={() => {
                                    setWinChallengeId(challenge.winAndChallengeId);
                                    setDialog(true);
                                  }}
                                >
                                  <div className="dropdown_icon">
                                    <img src={`${IC_ACHIEVEMENT}`} alt="" />
                                  </div>
                                  <div className="dropdown_item">Move to wins</div>
                                </Dropdown.Item>

                                <Dropdown.Item>
                                  <div className="dropdown_icon">
                                    <img src={`${IC_ACHIEVEMENT}`} alt="" />
                                  </div>
                                  <div className="dropdown_item">Move to Challenges</div>
                                </Dropdown.Item>
                              </DropdownButton>
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </Collapse>
      <ModalsPopup
        show={dialog}
        hide={() => {
          setDialog(false);
          setWinChallengeId(null);
        }}
        fromType="CHALLENGE"
        toType="WIN"
        submit={() => {
          setDialog(false);
          props.onMoveWinChallenge(winChallenge, "WIN", "CHALLENGE");
          setWinChallengeId(null);
        }}
      />
    </div>
  );
};

export default Challenges;
