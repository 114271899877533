import { status } from "const/constValue";
import { OKR_TEMPLATE_ENDPOINT } from "util/endpoints";
import { OKR_TEMPLATE_ACTIONS } from "./actionTypes";
import http from "util/http";
import { errorToast, successToast } from "util/general";
import { ChangeCurrentPage, ChangePageSize } from "./myTeam";

const dispatchHelperFunction = (dispatch, response) => {
  const payload = {
    responseStatus: null,
    message: null,
    isWeightage: false,
  };
  if (response.status === status.SUCCESS) {
    dispatch(getPredefinedObjectiveAdminView());
  } else if (response.status === status.FAILURE) {
    payload.isWeightage = !payload.isWeightage;
  }
  payload.responseStatus = response.status;
  payload.message = response.message.message;
  dispatch({
    type: OKR_TEMPLATE_ACTIONS.SET_TOAST_MESSAGE,
    payload,
  });
};

export const getPredefinedObjectiveAdminView = (pageNumber1, offSet1, title1) => (dispatch) => {
  let pageNumber = pageNumber1 || 1;
  let offset = offSet1 || 10;
  let title = title1 || "";
  dispatch({
    type: OKR_TEMPLATE_ACTIONS.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .post(OKR_TEMPLATE_ENDPOINT.GET_PREDEFINED_OBJECTIVE_ADMIN_VIEW, {}, { offset, pageNumber, title })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data.bandWiseObjectiveDTOList && response.data.bandWiseObjectiveDTOList.length > 0) {
          dispatch({
            type: OKR_TEMPLATE_ACTIONS.SET_LIST_DATA,
            payload: {
              predefinedObjectives: response.data.bandWiseObjectiveDTOList,
              totalCount: response.data.totalCount,
              isLoading: false,
            },
          });
          dispatch(ChangeCurrentPage(pageNumber));
          dispatch(ChangePageSize(offset));
        } else {
          dispatch({
            type: OKR_TEMPLATE_ACTIONS.SET_LIST_DATA,
            payload: {
              predefinedObjectives: [],
              totalCount:0,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: OKR_TEMPLATE_ACTIONS.SET_LIST_DATA,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const createPredefinedObjectiveAdminView = (body) => (dispatch) => {
  dispatch({
    type: OKR_TEMPLATE_ACTIONS.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .post(OKR_TEMPLATE_ENDPOINT.CREATE_PREDEFINED_OBJECTIVE_ADMIN_VIEW, {}, body)
    .then((response) => response.data)
    .then((response) => {
      dispatchHelperFunction(dispatch, response);
    })
    .catch((response) => {
      if(response?.message?.message){
        errorToast(response.message.message)
      } else {
        errorToast("Issue creating predefined objective")
      }
    })
    .finally(() => {
      dispatch({
        type: OKR_TEMPLATE_ACTIONS.LOADING,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const deletePredefinedObjectiveAdminView = (bandWiseObjectiveId) => (dispatch) => {
  dispatch({
    type: OKR_TEMPLATE_ACTIONS.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .delete(OKR_TEMPLATE_ENDPOINT.DELETE_PREDEFINED_OBJECTIVE_ADMIN_VIEW, {}, {}, { bandWiseObjectiveId })
    .then((response) => response.data)
    .then((response) => {
      dispatchHelperFunction(dispatch, response);
    })
    .catch(() => {
      errorToast("Issue deleting predefined objective")
    })
    .finally(() => {
      dispatch({
        type: OKR_TEMPLATE_ACTIONS.LOADING,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const updatePredefinedObjective = (bandWiseObjectiveId, body, pageSize, currentPage) => (dispatch) => {
  dispatch({
    type: OKR_TEMPLATE_ACTIONS.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .put(OKR_TEMPLATE_ENDPOINT.UPDATE_PREDEFINED_OBJECTIVE_ADMIN_VIEW, { bandWiseObjectiveId }, body)
    .then((response) => response.data)
    .then((response) => {
      dispatchHelperFunction(dispatch, response);
    })
    .catch(() => {
      errorToast("Issue updating predefined objective!")
    })
    .finally(() => {
      dispatch({
        type: OKR_TEMPLATE_ACTIONS.LOADING,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const getManagerListPredefinedObjective = () => (dispatch) => {
  dispatch({
    type: OKR_TEMPLATE_ACTIONS.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get( OKR_TEMPLATE_ENDPOINT.GET_MANAGER_LIST_PREDEFINED_OBJECTIVE_ADMIN_VIEW)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data && response.data.length > 0) {
          dispatch({ type: "CLEAR_ROLES_DATA_IN_ADMIN" });
          dispatch({
            type: OKR_TEMPLATE_ACTIONS.SET_MANAGER_LIST,
            payload: {
              managerList: response.data,
              managerOption: response.data.map((item) => {
                return {
                  value: item.managerId,
                  label: item.managerName,
                  managerParentGroupId: item.managerParentGroupId,
                  managerSubGroupId: item.managerSubGroupId,
                };
              }),
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: OKR_TEMPLATE_ACTIONS.SET_MANAGER_LIST,
            payload: {
              managerList: [],
              managerOption: [],
              isLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: OKR_TEMPLATE_ACTIONS.SET_MANAGER_LIST,
        payload: {
          isLoading: false,
        },
      });
    });
};