import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import style from "./PerformanceDashboard.module.scss";
import * as actions from "store/actions/index";
import Select from "react-select";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "components/NodataFound/NoDataFound";
import { noData } from "const/constValue";
import { cycleOptions2, monthOptions5, yearOptions,filterOptions } from "const/options";
import storage from "util/storage";
import TitleAndDropdown from "shared/Dropdown/TextDropdown/TitleAndDropdown";
import ComposeChart from "shared/Chart/ComposeChart/ComposeChart";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import SearchBox from "shared/SearchBox/SearchBox";
import TeamAssessmentGraph from "./Chart/TeamAssessmentGraph";
import TeamMemberTable from "./EmployeeListTable/TeamMemberTable";
import * as actionTypes from "store/actions/actionTypes";
import { performance } from "../performanceText";

function PerformanceDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = storage.getUser();
  const [searchFilter, setSearchFilter] = useState(null);
  const [cycleOptionValue, setCycleOptionValue] = useState(4);
  const [filterBy, setFilterBy] = useState({ value: "RATING", label: "Rating cycle" });
  const [timeLineDropdown, setTimeLineDropdown] = useState({
    value: "currentDate",
    label: "2024 Onwords",
  });
  const [selectedCycle,setSelectedCycle]=useState(null)
  const [cycleCount, setCycleCount] = useState({
    value: 4,
    label: "Last 4 Cycle",
  });
  const {
    isLoading,
    isPageLoading,
    timelineGraph,
    projectLineVisible,
    assessmentAreaVisible,
    currentProjectLineGraph,
    gradeTeamAssessmentGraph,
    ratingTeamAssessmentGraph,
    assessmentTeam,
  } = useSelector((state) => state.performance);

  const isManager = storage.get("isManager") || false;

  const collectiveData = [...gradeTeamAssessmentGraph,...ratingTeamAssessmentGraph];
  const maskCollectiveData = collectiveData.filter(item => item?.showRatingDropDown === false);
  const gradeTeamAssessmentGraphData = gradeTeamAssessmentGraph.filter(item => item?.showRatingDropDown === true);
  const ratingTeamAssessmentGraphData = ratingTeamAssessmentGraph.filter(item => item?.showRatingDropDown === true);
  let sortedCollectiveData = maskCollectiveData.sort((c1, c2) => (c1.createdDate < c2.createdDate) ? 1 : (c1.createdDate > c2.createdDate) ? -1 : 0);

  //componentDidMount
  useEffect(() => {
    dispatch(actions.getEmployeeTimeline("currentDate"));
    isManager && dispatch(actions.getGradeTeamAssessmentForManager());
    isManager && dispatch(actions.getRatingTeamAssessmentForManager());
    dispatch({ type: "REMOVE_PRIVATE_ASSESSMENT_DATA" });
  }, [dispatch]);

  const resetSearch = () => {
    dispatch({ type: actionTypes.SET_ASSESSMENT_TEAM_BY_SEARCH_NAME, payload: { assessmentTeam: [] } });
    setSearchFilter("");
  };

  const search = (event) => {
    setSearchFilter(event.target.value);
    if (event.target.value?.length > 2) {
      dispatch(
        actions.getTeamAssessmentSearchByManager(
          filterBy?.value === "GRADE"
            ? gradeTeamAssessmentGraphData.length > 0
              ? filterBy?.value
              : "RATING"
            : filterBy?.value,
          cycleCount.value,
          event.target.value
        )
      );
    }
  };

  const handleGraphClick = (data, type) => {
    if (data) {
      const { assessments, cycleId } = data;
      if (type === "TEAM_EMPLOYEE") {
        dispatch(
          actions.GetRPMAssessmentGlobalCommentPreviousManager(assessments, cycleId, loggedInUser, type, history)
        );
      } else {
        history.push(`/performance/dashboard/cycle-detail/${cycleId}`);
      }
    }
  };

  const handleMonthDropdown = (data) => {
    dispatch(actions.getEmployeeTimeline(data?.value));
    setTimeLineDropdown(data);
  };

  const handleCycleDropdown = (data) => {
    setCycleOptionValue(data.value);
    dispatch(actions.getGradeTeamAssessmentForManager(data.value));
    dispatch(actions.getRatingTeamAssessmentForManager(data.value));
    setCycleCount(data);
  };

  const handleGradeOrRatingDropdown = (data) => {
    setFilterBy(data);
  };

  const handleTeamAssessmentGraphClick = (data, key) => {
    dispatch({
      type: actionTypes.SET_TEAM_MEMBER_LIST_FOR_ASSESSMENT_DASHBOARD,
      payload: {
        cycleId: data.cycleId,
        type: key,
        graphType:
          filterBy?.value === "GRADE"
            ? gradeTeamAssessmentGraphData.length > 0
              ? filterBy?.value
              : "RATING"
            : filterBy?.value === "MASK" ?  filterBy?.value : "RATING",
      },
    });
    const cycleData={
      year:data.period.split(" ")[1],
      id:data.cycleId
    }
    setSelectedCycle(cycleData)
  };

  if (isLoading) {
    return <Spinner />;
  }

  let teamAssessmentGraphData = null;

  switch (filterBy?.value) {
    case "GRADE":
      teamAssessmentGraphData = gradeTeamAssessmentGraphData?.length > 0 && gradeTeamAssessmentGraphData;
      break;
    case "RATING":
      teamAssessmentGraphData = ratingTeamAssessmentGraphData;
      break;
    case "MASK":
       teamAssessmentGraphData = sortedCollectiveData?.slice(0, cycleOptionValue);
      break;
  }

  return (
    <div className={style.performance_dashboard_wrapper}>
      <SubNavigation type="Performance" />
      {isPageLoading && <Spinner />}
      <Container>
        <Row>
          <Col className="col-12">
            <div className="box pb-0 mb-0">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>{performance.PERFORMANCE_DASHBOARD_HEADING}</h2>
                </div>
              </div>
              <div className={[style.review_graph_wrapper].join(" ")}>
                <div className="box-light-gradient"></div>
                <TitleAndDropdown
                  options={yearOptions}
                  defaultValue={timeLineDropdown}
                  handleChange={handleMonthDropdown}
                  title="Assessment Period"
                  subTitle={`Showing ${timeLineDropdown?.label} trends`}
                />
                {timelineGraph?.length > 0 ? (
                  <ComposeChart
                    // isStartDate={timeLineDropdown?.value === "startDate"}
                    isCurrentDate={timeLineDropdown?.value}
                    projectLineVisible={projectLineVisible}
                    assessmentAreaVisible={assessmentAreaVisible}
                    data={timelineGraph}
                    projects={currentProjectLineGraph}
                    type={timelineGraph?.some((v) => v.gradeOrRatingOrBoth === "RATING") ? "Rating" : timeLineDropdown?.value === "startDate" ? "Rating":timeLineDropdown?.value === "currentDate" ? "Rating" : "Grade"}
                    handleAssessmentClick={handleGraphClick}
                  />
                ) : (
                  <>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap120"></div>
                  </>
                )}
              </div>

              {isManager && (gradeTeamAssessmentGraphData?.length > 0 || ratingTeamAssessmentGraphData?.length > 0) && (
                <div className={[style.dynamic_table_wrapper].join(" ")}>
                  <div className="box-light-gradient"></div>
                  <div className={style.table_heading}>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="title">{performance.TEAM_MEMBER_PERFORMANCE_DASHBOARD_HEADING}</div>
                      </Col>
                    </Row>
                  </div>
                  <div className="tab_filter">
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div className="check_in_search_bar">
                          <SearchBox
                            placeholder="Search for team members"
                            value={searchFilter ? searchFilter : ""}
                            onSearch={search}
                            onResetSearch={resetSearch}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div className={style.dropdown_wrapper1}>
                          <Select
                            onChange={handleCycleDropdown}
                            options={cycleOptions2}
                            className="select_dropdown select_dropdown_text float-right"
                            classNamePrefix="select"
                            isSearchable={false}
                            defaultValue={cycleCount}
                          />
                            <Select
                              onChange={handleGradeOrRatingDropdown}
                              options={filterOptions}
                              className="select_dropdown select_dropdown_text float-right"
                              classNamePrefix="select"
                              isSearchable={false}
                              defaultValue={filterBy}
                            />
                          {/* {gradeTeamAssessmentGraph?.length > 0 && ratingTeamAssessmentGraph?.length > 0 && (
                            <Select
                              onChange={handleGradeOrRatingDropdown}
                              options={filterOptions}
                              className="select_dropdown select_dropdown_text float-right"
                              classNamePrefix="select"
                              isSearchable={false}
                              defaultValue={filterBy}
                            />
                          )} */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="col-xl-12">
                    <div className={style.data_container}>
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          {/* By default filter by value is Grade
                          if there is no grade graph only rating graph for manager then we have change grade value to rating manually */}
                          <TeamAssessmentGraph
                            handleClick={handleTeamAssessmentGraphClick}
                            graphType={
                              filterBy?.value === "GRADE"
                                ? gradeTeamAssessmentGraphData.length > 0
                                  ? filterBy?.value
                                  : "RATING"
                                : filterBy?.value
                            }
                            teamAssessmentGraphData={teamAssessmentGraphData}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4} xl={4}></Col>
                      </Row>
                      <div className="pb-4"></div>
                      {((searchFilter && searchFilter.length > 2) || (assessmentTeam && assessmentTeam.length > 0)) && (
                        <TeamMemberTable
                          graphType={filterBy?.label}
                          teamMemberList={assessmentTeam}
                          handleAssessmentClick={(data) => handleGraphClick(data, "TEAM_EMPLOYEE")}
                          selectedCycle={selectedCycle}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PerformanceDashboard;
