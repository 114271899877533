import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import style from "components/Plans/Plans.module.scss";
import * as actions from "store/actions/index";
import { IC_RELOAD } from "const/imgCost";

export default function Priority(props) {
  let [isChecked, setIsChecked] = useState(props.previousPlan.planStatus === "INCOMPLETE" ? false : true);
  let boxClass = [style.priority_block];
  let priority_id = props.previousPlan.planId;

  const dispatch = useDispatch();

  const handleCheck = (e, id) => {
    setIsChecked(e.target.checked);
    dispatch(actions.DonePlan(id));
  };
  let disabled;
  if (props.edit) {
    disabled = {
      color: "#B8B4CC",
    };
  }
  return (
    <div className={boxClass.join(" ")}>
      <div className={style.priority_title}>
        <div className="custom-checkbox custom-checkbox-rounded mb-0 pr-3">
          {!props.edit ? (
            <input
              id={priority_id}
              type="checkbox"
              checked={isChecked}
              onChange={(e) => handleCheck(e, props.previousPlan.planId)}
            />
          ) : (
            <input
              id={priority_id}
              type="checkbox"
              checked={isChecked}
              disabled
              onChange={() => handleCheck(props.previousPlan.planId)}
            />
          )}

          <label htmlFor={priority_id}>
            <span className="text-break" style={disabled}>
              {props.previousPlan.planDescription}
            </span>
          </label>
        </div>

        <div className="priority_carryforward">
          <span>
            <div className="custom-tooltip custom-tooltip-top">
              {!props.edit && !isChecked && (
                <div className="box-info" onClick={() => props.carryForward(props.previousPlan.planId, props.index)}>
                  <span>
                    <ReactSVG className="svg" src={`${IC_RELOAD}`} alt="comment" />
                  </span>
                </div>
              )}
              <div className="info-attach-file">
                <span className="attachment-title">Carry Forward</span>
                <ul>
                  <li>You can carry forward incompleted task to next check-in cycle.</li>
                </ul>
              </div>
            </div>
          </span>
        </div>
      </div>
      {props.previousPlan.objectiveDescription && (
        <ul className="list-of-objective">
          <li className="pb-0">
            <span className="pre-plan-disabled">{props.previousPlan.objectiveDescription}</span>
          </li>
        </ul>
      )}
    </div>
  );
}
