import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Form } from "react-bootstrap";
import style from "./MapCapabilityTemplate.module.scss";
import Select from "react-select";
import { ReactSVG } from "react-svg";
import MultiSelect from "react-multi-select-component";
import SubmitBox from "components/SubmitBox/SubmitBox";
import { noData } from "const/constValue";
import NoDataFound from "components/NodataFound/NoDataFound";
import { IC_DELETE, IC_EDIT_OBJECTIVE, IC_VIEW_DETAILS } from "const/imgCost";
import * as actionTypes from "store/actions/actionTypes";
import * as actions from "store/actions/index";
import { useQuery } from "util/utils";
import Spinner from "shared/Spinner/Spinner";
import RadioButtons from "shared/RadioButton/RadioButtons";
import { errorToast, requiredFieldMissing, successToast } from "util/general";
import { Link, useHistory } from "react-router-dom";

export default function MapCapabilityTemplate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const questionTemplateId = useQuery().get("id");
  const campaignId = useQuery().get("campaignId");
  const [onSubmitError, setOnSubmitError] = useState(false);
  const {
    designationList,
    departmentList,
    filterValue,
    questionTemplate,
    isLoading,
    questionMap,
    apiResponse,
    isCreateLoading,
    departmentCapabilityList,
    mapTemplateValue,
  } = useSelector((state) => state.capability);

  useEffect(() => {
    dispatch(actions.getCapabilityTemplate(questionTemplateId, campaignId));
    dispatch(actions.getDepartmentListCampaign(campaignId));
    dispatch(actions.getDepartmentWiseCapabilityTemplate(campaignId));
  }, []);

  //component did mount API response
  useEffect(() => {
    if (apiResponse) {
      apiResponse.success ? successToast(apiResponse.message) : errorToast(apiResponse.message);
      dispatch({ type: actionTypes.CLEAR_CAPABILITY_RESPONSE });
    }
  }, [apiResponse, dispatch]);

  const handleFilter = (name, e) => {
    dispatch({
      type: "SET_CAPABILITY_DEPARTMENT_AND_DESIGNATION",
      payload: {
        [name]: e,
      },
    });
    if (name === "filterDepartment") {
      e?.value && dispatch(actions.getDesignationListFromDepartment(e?.value, campaignId));
      dispatch({
        type: "SET_CAPABILITY_DEPARTMENT_AND_DESIGNATION",
        payload: {
          filterDesignation: [],
        },
      });
    }
  };

  const handleDelete = (data) => {
    let body = {
      campaignId,
      removeDepartment: data.department,
    };
    dispatch(actions.mapQuestionTemplate(body, "DELETE"));
  };

  const handleEdit = (data) => {
    dispatch({ type: actionTypes.SET_CAPABILITY_MAP_EDIT_VIEW, payload: data });
  };

  const handleView = (data) => {
    dispatch({ type: actionTypes.SET_CAPABILITY_MAP_READONLY_VIEW, payload: data });
  };

  const handleRadioMapping = (designation, questionId, optionId) => {
    let body = {
      campaignId,
      department: filterValue?.filterDepartment?.value,
      designation,
      questionId,
      optionId,
    };
    dispatch({
      type: "SET_AUTO_SAVE_MAPPING_VALUE",
      payload: {
        [questionId]: optionId,
      },
      designation,
    });
    dispatch(actions.mapQuestionTemplate(body));
  };

  let questionMode = false;
  let btnName = "Save";
  if (filterValue?.filterDepartment && filterValue?.filterDesignation?.length > 0) {
    questionMode = true;
  }
  if (questionMap?.isView === "VIEW") btnName = "Back";
  else if (questionMap?.isView === "EDIT") btnName = "Edit";

  const handleMapping = () => {
    if (btnName === "Save" || btnName === "Edit") {
      for (let key in mapTemplateValue) {
        if (mapTemplateValue[key] && Object.keys(mapTemplateValue[key]).length !== questionTemplate?.questions.length) {
          setOnSubmitError(true);
          requiredFieldMissing();
          return;
        }
      }
      setOnSubmitError(false);
      dispatch(actions.getDepartmentWiseCapabilityTemplate(campaignId));
    }
    dispatch({ type: "RESET_QUESTION_MAP" });
  };

  const getClassName = (id, designation) => {
    if (
      onSubmitError &&
      mapTemplateValue[designation] &&
      !Object.keys(mapTemplateValue[designation])?.some((key) => key == id)
    ) {
      return "limit-reached";
    }
    return "";
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.map_capability_wrapper}>
      {isCreateLoading && <Spinner />}
      <div className="gap30"></div>
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Question Template</h1>
            <span className="st-breadcrumb">
              <Link onClick={() => history?.goBack()} to="#">
                Admin
              </Link>
              <span>Map capability value</span>
            </span>
          </Col>
          <Col className={["col-md-12"]}>
            <div className={style.map_capability_questions}>
              <div className="box">
                <div className={["box-heading-wrapper", !questionMode && style.no_question_list].join(" ")}>
                  <div className="box-heading">
                    <h2>{questionTemplate?.templateName}</h2>
                    <h3>Select department &#38; designation and give skill expectancy on template</h3>
                    <div className={style.filter_for_capability}>
                      <div className={style.filter_top_bar}>
                        <div className={style.filter_box}>
                          <span className={style.dd_title}>Department</span>
                          <div className="tags_wrapper">
                            <Select
                              isDisabled={filterValue?.disabledFilterDepartment}
                              options={departmentList}
                              className="custom-default-dd-box"
                              classNamePrefix="select"
                              placeholder="Select..."
                              value={filterValue?.filterDepartment || ""}
                              onChange={(e) => {
                                handleFilter("filterDepartment", e);
                              }}
                            />
                          </div>
                        </div>
                        <div className={style.filter_box}>
                          <span className={style.dd_title}>Designation</span>
                          <div className="tags_wrapper">
                            <MultiSelect
                              disabled={filterValue?.disabledFilterDesignation}
                              options={designationList || []}
                              value={filterValue?.filterDesignation}
                              onChange={(e) => {
                                handleFilter("filterDesignation", e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {questionMode && <div className="box-light-gradient h38"></div>}
                {questionMode &&
                  questionTemplate?.questions.map((item, index) => (
                    <div className={style.questionList} key={item.questionId}>
                      <span className={style.question_sr_no}>Q{index + 1}</span>
                      <div className={style.que_ans_wrapper}>
                        <Form.Group controlId="" className="d-flex justify-content-between">
                          <Form.Label>
                            {item.questionName}
                            {(item.isRequired || item.commentResponse === "Required") && (
                              <span className="text-danger">*</span>
                            )}
                            {item.description && <Form.Text>{item["description"]}</Form.Text>}
                          </Form.Label>
                        </Form.Group>
                        {filterValue?.filterDesignation &&
                          filterValue?.filterDesignation.map((designation, designationIdex) => (
                            <React.Fragment key={designation.value + designationIdex}>
                              <RadioButtons
                                options={item.options}
                                ulClass={getClassName(item.questionId, designation.value)}
                                name={designation.value}
                                // style={style}
                                isDisabled={questionMap?.isDisabled}
                                questionId={item.questionId}
                                questionName={item.questionName}
                                defaultChecked={questionMap ? questionMap[designation.value]?.[item.questionId] : ""}
                                handleRadio={(optionId) =>
                                  handleRadioMapping(designation.value, item.questionId, optionId)
                                }
                              />
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {questionMode && <SubmitBox name={btnName} handleSubmit={handleMapping} />}
            {!questionMode && (
              <div className={[style.map_capability_history_wrapper, "box"].join(" ")}>
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2>Capability Mapping List</h2>
                  </div>
                </div>
                <div className="box-light-gradient"></div>
                <div className="box-inner-pad p-0">
                  <div className={[style.defined_roles_table, "custom-table", "table-theme-1"].join(" ")}>
                    {departmentCapabilityList?.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className={style.department_name}>Department</th>
                            {/* <th className={style.designation_name}>Designation</th> */}
                            <th className={style.action}>View</th>
                            <th className={style.action}>Edit</th>
                            <th className={style.action}>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {departmentCapabilityList.map((item) => (
                            <tr key={item.department}>
                              <td>{item.department}</td>
                              {/* <td>Project Manager, Associate Implementation analyst</td> */}
                              <td className={style.action}>
                                <span onClick={() => handleView(item)}>
                                  <ReactSVG src={`${IC_VIEW_DETAILS}`} alt="" title="view" />
                                </span>
                              </td>
                              <td className={style.action}>
                                <span onClick={() => handleEdit(item)}>
                                  <ReactSVG src={`${IC_EDIT_OBJECTIVE}`} alt="" title="edit" />
                                </span>
                              </td>
                              <td className={style.action}>
                                <span onClick={() => handleDelete(item)}>
                                  <ReactSVG src={`${IC_DELETE}`} alt="" title="delete" />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div>
                        <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                        <div className="gap80"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
