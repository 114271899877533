/* eslint-disable no-script-url */
import React, { useState } from "react";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Collapse, Dropdown, DropdownButton } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "../WinsChallenges.module.scss";
import { IC_ACHIEVEMENT, IC_MOVE, IC_CHALLENGES, IC_CALENDAR, IC_INFO, IC_COLLECTIVE } from "../../../const/imgCost";
import { dateFormat1 } from "../../../util/dateUtils";
import ModalsPopup from "../../ModalsPopup/ModalsPopup";
import SingleComponent from "../SingleComponent";
import HideAndShow from "../../HideAndShow/HideAndShow";
import config from "util/config";

const options = [
  { value: 3, label: "Last 3 Months" },
  { value: 6, label: "Last 6 Months" },
  { value: 9, label: "Last 9 Months" },
  { value: 12, label: "Last 12 Months" },
];

const Collectives = (props) => {
  const [hide, setHide] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialog1, setDialog1] = useState(false);
  const [winChallenge, setWinChallengeId] = useState(null);

  return (
    <div className={["card", style.win_challenges].join(" ")}>
      <div className="card_info">
        <div className="card_title">
          <div className="d-flex">
            <ReactSVG src={`${IC_COLLECTIVE}`} className="icon-svg" />
            <div className="card_title_text">Collective</div>
          </div>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>
      <Collapse in={!hide}>
        <div className="card_details">
          <div className="box-light-gradient"></div>
          <div className={style.to_do_row}>
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <div className="card_action float-right mr-3">
                  <Select
                    onChange={(select) => props.filter(select.value)}
                    options={options}
                    className="select_dropdown select_dropdown_text"
                    classNamePrefix="select"
                    isSearchable={false}
                    defaultValue={{
                      value: 3,
                      label: "Last 3 Months",
                    }}
                  />
                </div>
              </Col>
            </Row>

            <div className={style.wc_data}>
              <ul
                id="COLLECTIVE"
                className={!props.collectiveBucket || (props.collectiveBucket.length === 0 && "heightAuto")}
              >
                {!props.collectiveBucket ||
                  (props.collectiveBucket.length === 0 && (
                    <li className="no-record-wc">No collections have been added.</li>
                  ))}
                {props.collectiveBucket &&
                  props.collectiveBucket.length > 0 &&
                  props.collectiveBucket.map((collection) => {
                    return (
                      <li
                        data-draggable="item"
                        id={collection.winAndChallengeId}
                        key={collection.winAndChallengeId}
                        style={props.isPage !== "myteam" ? { backgroundImage: "none", cursor: "auto" } : {}}
                      >
                        {collection.comment.length < 250 ? (
                          <div className="wc-text">{collection.comment}</div>
                        ) : (
                          <SingleComponent id={collection.winAndChallengeId} comment={collection.comment} />
                        )}
                        <span className={style.ic_dot}></span>
                        <div className={style.data_date}>
                          <Link
                            to={
                              props.isPage === "myteam"
                                ? {
                                    pathname: `/okrprogress/my-team/member-okrprogress/${
                                      props.employeeId +
                                      config.employeeIdSplit +
                                      props.parentGroupId +
                                      config.employeeIdSplit +
                                      props.subGroupId
                                    }`,
                                    state: collection.surveyCyclesId,
                                  }
                                : {
                                    pathname: `/okrprogress/my-history`,
                                    state: collection.surveyCyclesId,
                                  }
                            }
                          >
                            {dateFormat1(collection.surveyCyclesStartDate) +
                              " - " +
                              dateFormat1(collection.surveyCyclesEndDate)}
                          </Link>
                          <div className="custom-tooltip custom-tooltip-top ml-2">
                            <div className="box-info">
                              <span>
                                <ReactSVG src={`${IC_INFO}`} />
                              </span>
                            </div>
                            <div className="info-attach-file">
                              <Row>
                                <Col>
                                  <div className="tTitle">Created&nbsp;Date</div>
                                  <div className="tDateBox">
                                    <div className="tCalendar">
                                      <img src={IC_CALENDAR} title="" alt="Calendar Icon" />
                                    </div>
                                    <div className="tDate">{dateFormat1(collection?.createdDate)}</div>
                                  </div>
                                </Col>
                                {collection.movedDate && (
                                  <Col>
                                    <div className="tTitle">Moved&nbsp;On</div>
                                    <div className="tDateBox">
                                      <div className="tCalendar">
                                        <img src={IC_CALENDAR} title="" alt="Calendar Icon" />
                                      </div>
                                      <div className="tDate">{dateFormat1(collection.movedDate)}</div>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                        {props.isPage === "myteam" && (
                          <div className={style.dropdown_move_list}>
                            <div className={style.move}>
                              <ReactSVG src={`${IC_MOVE}`} title="" alt="" />
                            </div>
                            <div className="action-dropdown-wrapper">
                              <DropdownButton
                                alignRight
                                title={
                                  <div className="action-dropdown">
                                    <span className={style.dots}></span>
                                    <span className={style.dots}></span>
                                    <span className={style.dots}></span>
                                  </div>
                                }
                                variant="a"
                              >
                                <Dropdown.Item
                                  onClick={() => {
                                    setWinChallengeId(collection.winAndChallengeId);
                                    setDialog(true);
                                  }}
                                >
                                  <div className="dropdown_icon">
                                    <img src={IC_ACHIEVEMENT} alt="" />
                                  </div>
                                  <div className="dropdown_item">Move to wins</div>
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() => {
                                    setWinChallengeId(collection.winAndChallengeId);
                                    setDialog1(true);
                                  }}
                                >
                                  <div className="dropdown_icon">
                                    <img src={IC_CHALLENGES} alt="" />
                                  </div>
                                  <div className="dropdown_item">Move to Challenges</div>
                                </Dropdown.Item>
                              </DropdownButton>
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </Collapse>
      <ModalsPopup
        show={dialog}
        hide={() => {
          setDialog(false);
          setWinChallengeId(null);
        }}
        fromType="COLLECTIVE"
        toType="WIN"
        submit={() => {
          setDialog(false);
          props.onMoveWinChallenge(winChallenge, "WIN", "COLLECTIVE");
          setWinChallengeId(null);
        }}
      />
      <ModalsPopup
        show={dialog1}
        hide={() => {
          setDialog1(false);
          setWinChallengeId(null);
        }}
        fromType="COLLECTIVE"
        toType="CHALLENGE"
        submit={() => {
          setDialog1(false);
          props.onMoveWinChallenge(winChallenge, "CHALLENGE", "COLLECTIVE");
          setWinChallengeId(null);
        }}
      />
    </div>
  );
};

export default Collectives;
