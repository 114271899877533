import React, { useEffect, useContext, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { isBlinkSubMenu } from "util/general";
import style from "./SubNavigation.module.scss";
import AuthContext from "context/authContext";
import { useSelector } from "react-redux";
import { blinkMenu } from "const/constValue";

export default function SubNavigation({ type }) {
  const history = useHistory();
  const context = useContext(AuthContext);
  const { navigation } = context.state;
  const { viewQuizListOngoingCount } = useSelector((state) => state.campaign);
  const [highlight,setHighlight] = useState();
  const subNavigation = navigation?.find((nav) => nav.moduleName === type)?.subModuleAndRights;
  useEffect(() => {
    let subNav = document.getElementById("subNavigation");
    if (subNav) {
      if (history.location.pathname === "/") {
        subNav.classList.add("active");
        subNav.classList.add("submenu_transition");
      } else if (history.location.pathname === "/home") {
        subNav.classList.add("active");
        subNav.classList.remove("submenu_transition");
        subNav.classList.add("home_remove_subnav");
      } else {
        subNav.classList.add("active");
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (viewQuizListOngoingCount > 0) {
      if (!blinkMenu.includes("Quiz")) {
        setHighlight(true);
      }
    } else if (viewQuizListOngoingCount === null) {
      if (blinkMenu.includes("Quiz")) {
        setHighlight(false);
      }
    }

    return () => {
      if (blinkMenu.includes("Quiz")) {
        setHighlight(false);
      }
    };
  }, [viewQuizListOngoingCount]);

  if (!subNavigation || subNavigation?.length === 0) {
    return <div className="gap30"></div>;
  }

  return (
    <div id="subNavigation" className="subNavigation active">
      <Container>
        <Scrollbars className={style.subNavScroll}>
          <ul className={style.subNav}>
            {subNavigation.map((subNav) => (
              <li key={subNav.subModuleName}>
                <NavLink
                  to={subNav.url || ""}
                  className={isBlinkSubMenu(subNav.subModuleName,highlight) ? "blinked_nav_wrapper" : ""}
                >
                  {isBlinkSubMenu(subNav.subModuleName,highlight) && (
                    <div className={style.blinked_wrapper}>
                      <div className={style.blinked}></div>
                    </div>
                  )}
                  {subNav.subModuleName}
                </NavLink>
              </li>
            ))}
          </ul>
        </Scrollbars>
      </Container>
    </div>
  );
}
