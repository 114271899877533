import React, { useEffect } from "react";
import PropTypes from "prop-types";
import style from "../CycleMemberProfile.module.scss";
import SliderQuestion from "./SliderQuestion";
import RadioQuestion from "./RadioQuestion";
import CheckBoxQuestion from "./CheckBoxQuestion";
import DropDownQuestion from "./DropDownQuestion";
import ScaleQuestion from "./ScaleQuestion";
import RankQuestion from "./RankQuestion";
import NpsQuestion from "./NpsQuestion";
import TextBoxQuestion from "./TextBoxQuestion";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "./ErrorHandler";
import Questions from "./Questions";
import withRatingAndComment from "../../../../hoc/questionTemplate/withRatingAndComment";
import withManagerQuestionAnswer from "../../../../hoc/questionTemplate/withManagerQuestionAnswer";
import { getCapabilityQUestionTooltip } from "util/general";

const QuestionsWithRatingAndComment = withRatingAndComment(Questions);
const QuestionsWithManagerResponse = withManagerQuestionAnswer(Questions);

function QuestionTemplate(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.myTeam);
  const {
    questionTemplateErrors,
    isSubmitted,
    uploadFile,
    deleteFile,
    questionsAnswerTemplate,
    downloadFile,
    handleTextBox,
    handleRating,
    handleRatingComment,
    handleRadioComment,
    handleRadio,
    handleRadioOther,
    handleCheckBox,
    handleCheckBoxOther,
    handleCheckBoxComment,
    handleDropDown,
    handleDropDownComment,
    handleDropDownOther,
    handleScale,
    handleScaleComment,
    handleRankComment,
    handleRank,
    handleNps,
    handleNpsComment,
    viewTemplateName,
    sendManagerComment,
    fileUploadByManager,
    sendRating,
    ratingErrors,
    isPage,
    isManagerSubmitted,
    checkinQuestionData,
    managerCommentHandle,
    managerDeleteComment,
    ratingCommentErrorsInSelf,
    canManagerResponse,
    handleManagerRadioResponse,
    managerAnswerError,
    isCapability,
    isQuiz,
    isSurvey,
    isRatingDisable
  } = props;

  useEffect(() => {
    if (questionsAnswerTemplate) {
      dispatch({
        type: "SET_QUESTION_TEMPLATE_ERROR",
        error: { errors: setError(questionsAnswerTemplate), type: questionsAnswerTemplate.type },
      });
    }
  }, [questionsAnswerTemplate, dispatch]);

  const textBoxHandlerTemplate = (e, data) => {
    if (data.isRequired) {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.filter((v) => !(v.qId === data.questionId));
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    if (e.target.value.trim().length > 0) {
      // data.answer = e.target.value.trim();
      handleTextBox({ questionId: data.questionId, answer: e.target.value.trim() });
    }
  };

  const ratingAnswerHandlerTemplate = (id, data) => {
    if (data.isRequired) {
      let errors = questionTemplateErrors?.errors?.filter((v) => !(v.qId === data.questionId));
      dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
    }
    let options = data.options.map((value) => {
      if (value.optionId === id) value.isSelected = true;
      else value.isSelected = false;
      return value;
    });
    handleRating({ questionId: data.questionId, options });
  };

  const ratingCommentHandlerTemplate = (e, data) => {
    if (data.commentResponse === "Required") {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    // data.answer = e.target.value.trim();
    handleRatingComment({ questionId: data.questionId, answer: e.target.value.trim() });
  };

  const radioCommentHandlerTemplate = (e, data) => {
    if (data.commentResponse === "Required") {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    // data.answer = e.target.value.trim();
    handleRadioComment({ questionId: data.questionId, answer: e.target.value.trim() });
  };

  const radioButtonHandlerTemplate = (e, data, optionId) => {
    if (data.isRequired) {
      let errors = questionTemplateErrors?.errors?.map((v) => {
        if (v.qId === data.questionId) {
          v.error = false;
          return v;
        }
        return v;
      });
      dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
    }
    let options = data.options.map((value) => {
      if (value.optionId === optionId) value.isSelected = e.target.checked;
      else value.isSelected = null;
      return value;
    });
    // data.otherTextAnswer = null;
    handleRadio({ questionId: data.questionId, otherTextAnswer: null, options });
  };

  const radioOtherHandlerTemplate = (e, data) => {
    // data.otherTextAnswer = e.target.value.trim();
    let options = data.options.map((value) => {
      value.isSelected = null;
      return value;
    });
    if (data.isRequired) {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.error = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    handleRadioOther({ questionId: data.questionId, otherTextAnswer: e.target.value.trim(), options });
  };
  //checkboxHandlerTemplate
  const checkboxHandlerTemplate = (e, data, optionsData) => {
    if (data.isRequired) {
      if (e.target.checked) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.error = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    let options = data.options.map((value) => {
      if (value.optionId === optionsData.optionId) value.isSelected = e.target.checked;
      return value;
    });
    handleCheckBox({ questionId: data.questionId, options });
  };

  const checkBoxOtherHandlerTemplate = (e, data) => {
    // data.otherTextAnswer = e.target.value.trim();
    if (data.isRequired) {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.error = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    handleCheckBoxOther({ questionId: data.questionId, otherTextAnswer: e.target.value.trim() });
  };

  const checkBoxCommentHandlerTemplate = (e, data) => {
    if (data.commentResponse === "Required") {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    // data.answer = e.target.value.trim();
    handleCheckBoxComment({ questionId: data.questionId, answer: e.target.value.trim() });
  };

  const dropDownHandlerTemplate = (e, data) => {
    if (data.isRequired || data.commentResponse === "Required") {
      let errors = questionTemplateErrors?.errors?.map((v) => {
        if (v.qId === data.questionId) {
          v.error = false;
          return v;
        }
        return v;
      });
      dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
    }
    let options = data.options.map((value) => {
      if (value.optionId === e.value) value.isSelected = true;
      else value.isSelected = false;
      return value;
    });
    // data.otherTextAnswer = null;
    handleDropDown({ questionId: data.questionId, otherTextAnswer: null, options });
  };

  const dropDownCommentHandlerTemplate = (e, data) => {
    if (data.commentResponse === "Required") {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    // data.answer = e.target.value.trim();
    handleDropDownComment({ questionId: data.questionId, answer: e.target.value.trim() });
  };

  const dropDownOtherHandlerTemplate = (e, data) => {
    let options = data.options.map((value) => {
      value.isSelected = null;
      return value;
    });
    if (data.isRequired) {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.error = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.error = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    // data.otherTextAnswer = e.target.value.trim();
    handleDropDownOther({ questionId: data.questionId, otherTextAnswer: e.target.value.trim(), options });
  };

  //Scale
  const scaleRatingHandlerTemplate = (value, data) => {
    if (data.isRequired) {
      let errors = questionTemplateErrors?.errors?.map((v) => {
        if (v.qId === data.questionId) {
          v.error = false;
          return v;
        }
        return v;
      });
      dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
    }
    handleScale({ questionId: data.questionId, scaleValue: value });
  };

  const scaleCommentHandlerTemplate = (e, data) => {
    if (data.commentResponse === "Required") {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    handleScaleComment({ questionId: data.questionId, answer: e.target.value.trim() });
  };

  //Rank
  const RankCommentHandlerTemplate = (e, data) => {
    if (data.commentResponse === "Required") {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    // data.answer = e.target.value.trim();
    handleRankComment({ questionId: data.questionId, answer: e.target.value.trim() });
  };

  const RankHandlerTemplate = (e, data, optionsData) => {
    let options = data.options.map((value) => {
      if (value.optionId === optionsData.optionId) value.optionRating = e.target.value;
      return value;
    });
    if (data.isRequired) {
      if (data.options.filter((option) => !option.optionRating).length === 0) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.error = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.error = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    handleRank({ questionId: data.questionId, options });
  };

  //Nps
  const NpsRatingHandlerTemplate = (value, data) => {
    // data.scaleValue = value;
    handleNps({ questionId: data.questionId, scaleValue: value });
  };

  const NpsCommentHandlerTemplate = (e, data) => {
    if (data.commentResponse === "Required") {
      if (e.target.value.trim()) {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = false;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      } else {
        let errors = questionTemplateErrors?.errors?.map((v) => {
          if (v.qId === data.questionId) {
            v.commentBoxError = true;
            return v;
          }
          return v;
        });
        dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
      }
    }
    // data.answer = e.target.value.trim();
    handleNpsComment({ questionId: data.questionId, answer: e.target.value.trim() });
  };

  const categoryQuesiton = [];
  if (questionsAnswerTemplate?.questions?.filter((value) => value.questionCategory).length > 0) {
    questionsAnswerTemplate.questions
      .filter((value) => value.questionCategory)
      .filter((value) => {
        if (!categoryQuesiton.includes(value.questionCategory.name)) {
          categoryQuesiton.push(value.questionCategory.name);
        }
        return null;
      });
  }

  const renderComponent = (item, name) => {
    switch (item.type) {
      case "SLIDER":
        return (
          <SliderQuestion
            name={name}
            isSubmitted={isSubmitted}
            item={item}
            errors={questionTemplateErrors.errors}
            deleteFile={deleteFile}
            ratingAnswerHandlerTemplate={ratingAnswerHandlerTemplate}
            uploadFile={uploadFile}
            ratingCommentHandlerTemplate={ratingCommentHandlerTemplate}
            downloadFile={downloadFile}
            isSurvey={isSurvey}
          />
        );
      case "RADIOBUTTON":
        return (
          <RadioQuestion
            name={name}
            radioOtherHandlerTemplate={radioOtherHandlerTemplate}
            errors={questionTemplateErrors.errors}
            isSubmitted={isSubmitted}
            item={item}
            radioButtonHandlerTemplate={radioButtonHandlerTemplate}
            uploadFile={uploadFile}
            radioCommentHandlerTemplate={radioCommentHandlerTemplate}
            downloadFile={downloadFile}
            deleteFile={deleteFile}
            isCapability={isCapability}
            isSurvey={isSurvey}
          />
        );
      case "BOOLEAN":
        return (
          <RadioQuestion
            name={name}
            radioOtherHandlerTemplate={radioOtherHandlerTemplate}
            errors={questionTemplateErrors.errors}
            isSubmitted={isSubmitted}
            item={item}
            radioButtonHandlerTemplate={radioButtonHandlerTemplate}
            uploadFile={uploadFile}
            radioCommentHandlerTemplate={radioCommentHandlerTemplate}
            downloadFile={downloadFile}
            deleteFile={deleteFile}
            isSurvey={isSurvey}
          />
        );
      case "CHECKBOX":
        return (
          <CheckBoxQuestion
            name={name}
            isSubmitted={isSubmitted}
            errors={questionTemplateErrors.errors}
            checkBoxOtherHandlerTemplate={checkBoxOtherHandlerTemplate}
            item={item}
            downloadFile={downloadFile}
            uploadFile={uploadFile}
            deleteFile={deleteFile}
            checkBoxCommentHandlerTemplate={checkBoxCommentHandlerTemplate}
            checkboxHandlerTemplate={checkboxHandlerTemplate}
            isSurvey={isSurvey}
          />
        );
      case "DROPDOWN":
        return (
          <DropDownQuestion
            name={name}
            dropDownOtherHandlerTemplate={dropDownOtherHandlerTemplate}
            dropDownCommentHandlerTemplate={dropDownCommentHandlerTemplate}
            downloadFile={downloadFile}
            deleteFile={deleteFile}
            uploadFile={uploadFile}
            isSubmitted={isSubmitted}
            errors={questionTemplateErrors.errors}
            dropDownHandlerTemplate={dropDownHandlerTemplate}
            item={item}
            isSurvey={isSurvey}
          />
        );
      case "TEXTBOX":
        return (
          <TextBoxQuestion
            name={name}
            isSubmitted={isSubmitted}
            errors={questionTemplateErrors.errors}
            textBoxHandlerTemplate={textBoxHandlerTemplate}
            item={item}
          />
        );
      case "SCALED":
        return (
          <ScaleQuestion
            name={name}
            isSubmitted={isSubmitted}
            scaleRatingHandlerTemplate={scaleRatingHandlerTemplate}
            scaleCommentHandlerTemplate={scaleCommentHandlerTemplate}
            item={item}
            errors={questionTemplateErrors.errors}
            downloadFile={downloadFile}
            deleteFile={deleteFile}
            uploadFile={uploadFile}
            isSurvey={isSurvey}
          />
        );
      case "RANK":
        return (
          <RankQuestion
            name={name}
            isSubmitted={isSubmitted}
            item={item}
            RankHandlerTemplate={RankHandlerTemplate}
            RankCommentHandlerTemplate={RankCommentHandlerTemplate}
            errors={questionTemplateErrors.errors}
            downloadFile={downloadFile}
            deleteFile={deleteFile}
            uploadFile={uploadFile}
            isSurvey={isSurvey}
          />
        );
      case "NPS":
        return (
          <NpsQuestion
            name={name}
            isSubmitted={isSubmitted}
            item={item}
            NpsRatingHandlerTemplate={NpsRatingHandlerTemplate}
            NpsCommentHandlerTemplate={NpsCommentHandlerTemplate}
            errors={questionTemplateErrors.errors}
            downloadFile={downloadFile}
            deleteFile={deleteFile}
            uploadFile={uploadFile}
            isSurvey={isSurvey}
          />
        );
      default:
        return null;
    }
  };

  const renderQuestion = (item) => {
    let expectedValue = item?.options?.find((v) => v.isExpected)?.option || null;
    let t = expectedValue && `Expected Value: ${expectedValue}`;
    if (item.optionalCommentBox && !expectedValue) {
      t = getCapabilityQUestionTooltip(user?.designation, item.questionName);
    }

    let props = {
      checkinQuestionData: checkinQuestionData,
      managerCommentHandle: (comment) => managerCommentHandle(comment, item),
      managerDeleteComment: (commentId, isPrivate, isReply) =>
        managerDeleteComment(item, commentId, isPrivate, isReply),
      ratingCommentErrorsInSelf: ratingCommentErrorsInSelf,
      ratingErrors: ratingErrors,
      name: questionsAnswerTemplate.name,
      isManagerSubmitted: isManagerSubmitted,
      isPage: isPage,
      item: item,
      renderComponent: renderComponent,
      key: item.questionId,
      sendManagerComment: sendManagerComment,
      fileUploadByManager: fileUploadByManager,
      downloadFile: downloadFile,
      deleteFile: deleteFile,
      sendRating: sendRating,
      isRatingDisable: isRatingDisable,
      canManagerResponse,
      handleManagerRadioResponse,
      toolTip: t,
      error:
        managerAnswerError?.submit && managerAnswerError?.errors?.some((v) => v.queId === item.questionId && v.error),
    };

    if (canManagerResponse && item.type === "RADIOBUTTON") {
      //Only for capability development
      return <QuestionsWithManagerResponse {...props} />;
    } else {
      return <QuestionsWithRatingAndComment {...props} />;
    }
  };

  return (
    <div className="box private-assessment-wrapper">
      {viewTemplateName && (
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>{questionsAnswerTemplate?.templateName}</h2>
          </div>
        </div>
      )}
      <>
        {questionsAnswerTemplate?.questions?.filter((value) => !value.questionCategory).length > 0 &&
          questionsAnswerTemplate.questions
            .filter((value) => !value.questionCategory)
            .map((item) => renderQuestion(item))}
        {categoryQuesiton.length > 0 &&
          categoryQuesiton.map((category, index) => {
            return (
              <div key={category + index}>
                {!isQuiz && (
                  <>
                    <div className="box-light-gradient h38"></div>
                    <h5 className={style.categoryTitle}>{category}</h5>
                  </>
                )}
                {questionsAnswerTemplate?.questions?.filter((value) => value.questionCategory).length > 0 &&
                  questionsAnswerTemplate.questions
                    .filter((value) => value.questionCategory && value.questionCategory.name === category)
                    .map((item) => renderQuestion(item))}
              </div>
            );
          })}
      </>
    </div>
  );
}

QuestionTemplate.propTypes = {
  questionsAnswerTemplate: PropTypes.object.isRequired,
  questionTemplateErrors: PropTypes.object,
  uploadFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  privateAssessmentId: PropTypes.string,
};

QuestionTemplate.defaultProps = {
  questionTemplateErrors: {
    errors: null,
  },
};

export default QuestionTemplate;
