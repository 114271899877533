import React, { useState } from "react";
import { Accordion, Card, Button, Row } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./Key_Result_Accordion.module.scss";
import { IC_ACC_ARROW_DOWN } from "const/imgCost";

export default function KeyAccordion(props) {
  let { keyPoints, hours, minutes } = props.objective;
  const [eventValue, setEventValue] = useState(0);
  const cardView =
    keyPoints &&
    keyPoints.map((keypoint, index) => {
      let details = keypoint.details && keypoint.details.split("/n");
      let subPoints = details
        ? details.map((obj, i) => {
            let text = "";
            let href = "";
            if (obj.indexOf("URL") !== -1) {
              if (obj.indexOf("URL>[") !== -1) {
                href = obj.split(">[")[1].split("]<")[0];
              }
              if (obj.indexOf("URL> [") !== -1 && obj.indexOf("] <URL") !== -1) {
                href = obj.split("> [")[1].split("] <")[0];
              }
              if (obj.indexOf("URL> [") !== -1) {
                href = obj.split("> [")[1].split("]<")[0];
              }
              if (obj.indexOf("] <URL") !== -1) {
                href = obj.split(">[")[1].split("] <")[0];
              }
              text = obj
                .split("<URL>")
                .join("<a target='_blank' href='" + href + "'>")
                .split("</URL>")
                .join("</a>")
                .trim();
            } else {
              text = obj;
            }
            return (
              <li
                key={i}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            );
          })
        : null;
      return (
        <Card key={index}>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey={`${index}`}
              onClick={(e) => {
                setEventValue(index);
                if (e.currentTarget.classList.contains("show")) {
                  e.currentTarget.classList.remove("show");
                } else {
                  e.currentTarget.classList.add("show");
                }
              }}
              className={eventValue === index && "show"}
            >
              <div className="accordion-icon">
                <ReactSVG src={`${IC_ACC_ARROW_DOWN}`} />
              </div>
              <div className={style.accordion_title}>
                <div className={style.title_left}>{keypoint.description}</div>
                <div className={style.title_right}>{keypoint.hours} hours</div>
              </div>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={`${index}`}>
            <Card.Body>
              <div className="box-light-gradient"></div>
              <ul>{subPoints}</ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });
  return (
    <div className={style.keyresult_accordion_wrapper}>
      <div className="box pb-0">
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>{keyPoints.length} Key Results Aligned</h2>
            {/* <h3>Some text goes here, will be updated soon</h3> */}
          </div>
          <div className="box-info ">
            <div>
              <p className={style.hours}>
                {hours} Hours {minutes} minutes
              </p>
              <p className={style.duration}>Total Duration</p>
            </div>
          </div>
        </div>
        <div className="box-light-gradient"></div>
        <div className="box-inner-pad">
          <Row>
            <div className="col-xl-12">
              <Accordion defaultActiveKey={"0"} className="custom-accordion">
                {cardView}
              </Accordion>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
