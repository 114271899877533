import React, { Fragment, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import * as actions from "store/actions/index";
import { scrollToTop } from "util/general";
import { getRandomColor } from "util/color";
import AuthContext from "context/authContext";
import Spinner from "shared/Spinner/Spinner";
import AppericiateColleagues from "components/AppericiateColleagues/AppericiateColleagues";
import FeelingBox from "components/feeling-box/feelingbox";
import Questions from "components/questions/questions";
import ReviewerProfile from "components/reviewer-profile/reviewer-profile";
import Userbox from "components/UserBox/UserBox";
import CheckinsSubmited from "components/CheckinsSubmited/CheckinsSubmited";
import CurrentDraftCheckIn from "components/CurrentDraftCheckIn/CurrentDraftCheckIn";
import Pulse from "components/Pulse/pulse";
import NoDataFound from "components/NodataFound/NoDataFound";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function CheckIns() {
  const context = useContext(AuthContext);
  const [selectedColor, setSelectedColor] = useState("");
  const [user, setUser] = useState();
  const [topCurrentDraft, setTopCurrentDraft] = useState(null);
  const dispatch = useDispatch();
  const {
    isLoading,
    currentCheckIn,
    submitedDate,
    reviewedDate,
    updatedDate,
    status,
    questionsAnswer,
    reviewer,
    data,
  } = useSelector((state) => state.checkIn);
  const employeeIds = {
    employeeId: context.state.user?.employeeId,
    parentGroupId: context.state.user?.parentGroupId,
    subGroupId: context.state.user?.subGroupId,
  };

  // componentDidMount
  useEffect(() => {
    setSelectedColor(getRandomColor());
    dispatch(actions.getEmployeeCampaignDetail());
    dispatch(actions.FetchCurrentCheckIn(employeeIds));
    dispatch(actions.GetReviewer(employeeIds));
    dispatch({ type: "REMOVE_SURVEY_CYCLE_ID" });
    dispatch({ type: "REMOVE_IS_LOADING_OBJECTIVE" });
  }, [dispatch]);

  useEffect(() => {
    scrollToTop(500);
  }, []);

  // componentDidUpdate
  useEffect(() => {
    if (submitedDate) {
      dispatch(actions.GetStreak(employeeIds));
    }
  }, [submitedDate, dispatch]);

  // componentDidUpdate (user) ???
  useEffect(() => {
    let currentUser = context.state.user;
    setUser({
      employeeId: currentUser.employeeId,
      employeeInitial: currentUser.empInitial,
      name: currentUser.displayName,
      designation: currentUser.designation,
      department: currentUser.department,
      image: currentUser.displayPicture,
      band: currentUser.band,
      firstName: currentUser.fname,
      totalExp: currentUser.totalExp,
      expBeforeJoining: currentUser.expBeforeJoining,
      isExp: currentUser.isExp,
      due_date: currentUser.endDate,
      completed: 5, // TODO: need to replace this values once we get from Service Response
      total: 10, // TODO: need to replace this values once we get from Service Response
      subGroupName: currentUser.subGroupName,
      subGroupId: currentUser.subGroupId,
      parentGroupName: currentUser.parentGroupName,
      parentGroupId: currentUser.parentGroupId,
    });
  }, [context.state.user]);

  useEffect(() => {
    if (currentCheckIn) {
      let dateDisplay =
        reviewedDate || status === "REVIEWED"
          ? { date: reviewedDate, text: "Reviewed On: " }
          : submitedDate || status === "PENDING_FOR_REVIEW"
          ? { date: submitedDate, text: "Submitted On: " }
          : { date: updatedDate, text: "Draft Saved On: " };
      setTopCurrentDraft(
        <CurrentDraftCheckIn startDate={currentCheckIn.start} endDate={currentCheckIn.end} dateDisplay={dateDisplay} />
      );
    }
  }, [currentCheckIn, updatedDate, reviewedDate, submitedDate, status]);

  // let bannerText = storage.get("supportContactDetails") ? [storage.get("supportContactDetails")] : null;

  return (
    <Fragment>
      <SubNavigation type="OKR Progress" />
      {/* {bannerText && <InfoBannerContact heading={bannerHeading} text={bannerText} />} */}
      {topCurrentDraft}
      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          {submitedDate ? (
            // TODO to be optimized
            <CheckinsSubmited />
          ) : (
            <Row>
              <Col md={12} lg={7}>
                <Userbox isPage="checkin" managerView={false} reviewer={reviewer} user={user} />
              </Col>
              <Col md={12} lg={5}>
                <FeelingBox user={user} />
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={8}>
              {submitedDate && (
                <>
                  <Pulse
                    managerView={false}
                    selectedColor={selectedColor}
                    status={status}
                    question={questionsAnswer && questionsAnswer.filter((value) => value.type === "MOODOMETER")[0]}
                  />
                </>
              )}
              {data ? (
                <Questions
                isCurrentCheckin = {true}
                  isPage="checkin"
                  data={data}
                  managerView={false}
                  checkinQuestionData={{ employee: user, readOnly: true, defaultOpen: true, viewCommentIcon: false }}
                />
              ) : (
                <div className="box">
                  <NoDataFound description="Welcome to Engage! No any OKR Progress cycle yet created for you in engage." />
                  <div className="gap80"></div>
                </div>
              )}
            </Col>
            <Col lg={4}>
              {user && <AppericiateColleagues managerView={false} user={user} />}
              {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer?.managersList} />}
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
}
