import React from "react";
import PropTypes from "prop-types";
import style from "./questions.module.scss";
import RatingBar from "shared/RatingBar";
import QuestionTextBox from "shared/QuestionTextBox";
import QuestionTemplate from "components/Performance/CycleMemberProfile/QuestionTemplate/QuestionTemplate";

const CheckInQuestion = ({
  questionsAnswer,
  questionTemplate,
  heading,
  description,
  isManager,
  managerRole,
  isPage,
  submitedDate,
  submitCycle,
  childEmployee,
  textAnswerHandler,
  onPrivateMessage,
  ratingAnswerHandler,
  ratingCommentHandler,
  saveQuestionAnswer,
  questionTemplateErrors,
  isSubmitted,
  uploadFile,
  downloadFile,
  checkinQuestionData,
  managerCommentHandle,
  deleteComment,
  deleteFile,
}) => {
  return (
    <div className={["box", style.questions_box].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>{heading}</h2>
          <h3>{description}</h3>
        </div>
      </div>
      {/* only static Text box question */}
      {questionsAnswer &&
        questionsAnswer
          .filter((item) => item.type !== "MOODOMETER")
          .map((item, index) =>
            item.type === "TEXT" ? (
              isManager ? (
                <QuestionTextBox
                  key={item.queId}
                  isManager={isManager}
                  edit={false}
                  submitCycle={false}
                  managerRole={managerRole}
                  isPage={isPage}
                  question={item}
                  childEmployee={childEmployee}
                />
              ) : (
                <QuestionTextBox
                  key={"Question_" + index + 1}
                  edit={isPage === "checkin" && !submitedDate ? true : false}
                  isPage={isPage}
                  question={item}
                  managerRole={managerRole}
                  submitCycle={submitCycle}
                  onBlur={(e) => textAnswerHandler(e.target.value.trim(), item.queId, item.required)}
                  onPrivateMessage={onPrivateMessage}
                />
              )
            ) : null
          )}

      {/* dynamic question  */}
      {questionTemplate && (
        <div className={style.dynamic_question}>
          <QuestionTemplate
            isPage="CHECKIN"
            handleTextBox={saveQuestionAnswer}
            handleRating={saveQuestionAnswer}
            handleRatingComment={saveQuestionAnswer}
            handleRadioComment={saveQuestionAnswer}
            handleRadio={saveQuestionAnswer}
            handleRadioOther={saveQuestionAnswer}
            handleCheckBox={saveQuestionAnswer}
            handleCheckBoxOther={saveQuestionAnswer}
            handleCheckBoxComment={saveQuestionAnswer}
            handleDropDown={saveQuestionAnswer}
            handleDropDownComment={saveQuestionAnswer}
            handleDropDownOther={saveQuestionAnswer}
            handleScale={saveQuestionAnswer}
            handleScaleComment={saveQuestionAnswer}
            handleRankComment={saveQuestionAnswer}
            handleRank={saveQuestionAnswer}
            handleNps={saveQuestionAnswer}
            handleNpsComment={saveQuestionAnswer}
            questionTemplateErrors={questionTemplateErrors}
            isSubmitted={isSubmitted}
            questionsAnswerTemplate={questionTemplate}
            uploadFile={uploadFile}
            downloadFile={downloadFile}
            viewTemplateName={false}
            deleteFile={deleteFile}
            checkinQuestionData={checkinQuestionData}
            managerCommentHandle={(comment, questionData) =>
              managerCommentHandle(comment, questionData, questionTemplate.questionTemplateId)
            }
            managerDeleteComment={deleteComment}
          />
        </div>
      )}

      {/* only static rating type question */}
      {questionsAnswer &&
        questionsAnswer
          .filter((item) => item.type !== "MOODOMETER")
          .map((item, index) =>
            item.type === "RATING" ? (
              isManager ? (
                <RatingBar
                  isManager={isManager}
                  key={item.queId + "_" + index}
                  edit={false}
                  submitCycle={false}
                  managerRole={managerRole}
                  isPage={isPage}
                  question={item}
                  childEmployee={childEmployee}
                />
              ) : (
                <RatingBar
                  id={item.queId}
                  key={item.queId + "_" + index}
                  isPage={isPage}
                  min="1"
                  max="10"
                  current={item.answer}
                  edit={isPage === "checkin" && !submitedDate ? true : false}
                  question={item}
                  managerRole={managerRole}
                  ratingAnswerHandler={ratingAnswerHandler}
                  ratingCommentHandler={ratingCommentHandler}
                />
              )
            ) : null
          )}
    </div>
  );
};

CheckInQuestion.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
};

export default CheckInQuestion;
