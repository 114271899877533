import React from "react";
import { Modal, Button } from "react-bootstrap";
import style from "./DeleteSkillCategoryModal.module.scss";

const DeleteSkillCategoryModal = (props) => {
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} className="custom_modal_popup">
        <div className={[style.skill_category_delete_modal, "custom_modal_container"].join(" ")}>
          <Modal.Header className={style.mHeader}>
            <Modal.Title> Sure you want to Remove this category? </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <p>Once removed, you can ADD it later from the category.</p>
          </Modal.Body>

          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={() => props.deleteCategory(props.index)}>
              Remove
            </Button>
            <Button variant="secondary" className="fs-500" onClick={() => props.hide()}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default DeleteSkillCategoryModal;
