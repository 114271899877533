import React from "react";
import { Form } from "react-bootstrap";
import styles from "./customSwitch.module.scss";

export default function CustomSwitch({ label, defaultChecked, handleChange, checked, disabled, id }) {
  return (
    <div className={styles.switches}>
      <Form>
        <Form.Check
          type="switch"
          id={`reportees-switch-${id}`}
          label={label || ""}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          disabled={disabled}
        />
      </Form>
    </div>
  );
}
