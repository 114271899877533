import OneOnOnePatterns from "components/Patterns/OneOnOnePatterns";
import { IC_MINUS, IC_PLUS } from "const/imgCost";
import React from "react";
import style from "./OneOnOne.module.scss";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { getColorByIndex } from "util/color";

OneOnOneMemberRow.propTypes = {
  showExpand: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isSubTeam: PropTypes.bool,
  toggleExpander: PropTypes.func,
  handlePatternClick: PropTypes.func,
  image: PropTypes.string,
  name: PropTypes.string,
  designation: PropTypes.string,
  department: PropTypes.string,
  index: PropTypes.number,
  pattern: PropTypes.array,
};

OneOnOneMemberRow.defaultProps = {
  showExpand: false,
  isExpanded: false,
  toggleExpander: () => {},
  handlePatternClick: () => {},
  image: "",
  name: "",
  designation: "",
  department: "",
  index: 0,
  isSubTeam: false,
  pattern: [],
};

function OneOnOneMemberRow({
  showExpand,
  isExpanded,
  toggleExpander,
  isSubTeam,
  image,
  name,
  designation,
  department,
  index,
  handlePatternClick,
  pattern,
}) {
  return (
    <tr>
      <td>
        <div className={style.tab_team_member_info}>
          <div className={[style.team_member_info, isSubTeam ? style.sub_team_member : ""].join(" ")}>
            <div className={style.team_member_img} style={{ background: getColorByIndex(index) }}>
              {image?.length > 2 ? <img src={image} alt="background image" /> : image}
            </div>
            <div className={style.team_member_details}>
              <div className={style.team_member_name}>{name}</div>
              <div className={style.team_member_designation}>
                {designation} | {department}
              </div>
            </div>
            {showExpand && (
              <div
                onClick={toggleExpander}
                className={[style.team_member_info_collapse, isExpanded ? style.collapseClose : ""].join(" ")}
              >
                <ReactSVG src={isExpanded ? IC_MINUS : IC_PLUS} alt="" className={style.minus} />
              </div>
            )}
          </div>
        </div>
      </td>
      <td>
        <div className={style.tab_checkin_patterns}>
          {pattern &&
            pattern.length > 0 &&
            pattern
              .filter((value, index) => index < 10)
              .map((value) => (
                <OneOnOnePatterns
                  fromTime={value.fromTime}
                  toTime={value.toTime}
                  scheduledDate={value.scheduledDate}
                  handlePatternClick={() => handlePatternClick(value.oneOnOneAgendaId)}
                  scheduledBy={value.createdByName}
                  status={value.ended ? "Attended" : "Un-Attended"}
                  key={pattern.oneOnOneAgendaId}
                />
              ))}
        </div>
      </td>
    </tr>
  );
}

export default OneOnOneMemberRow;
