import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import moment from "moment";
import style from "./Dashboard.module.scss";
import * as actions from "store/actions/index";
import {
  errorToast,
  filterNameFromOption,
  filterOptionFromName,
  hightOfMainMenu,
  setArrayToObject,
  setArrayToObjectWithOnlyValue,
  successToast,
} from "util/general";
import config from "util/config";
import CheckinLapsedCharts from "./CheckinLapsedCharts/CheckinLapsedCharts";
import MoodOMeterCharts from "./MoodOMeterCharts/MoodOMeterCharts";
import WorkLoadCharts from "./WorkLoadCharts/WorkLoadCharts";
import EmployeeObjectiveCharts from "./EmployeeObjectiveCharts/EmployeeObjectiveCharts";
import AssessmentReportCharts from "./AssessmentReportCharts/AssessmenReportCharts";
import CustomDateRangePicker from "shared/DateRangePicker/DateRangePicker";
import Spinner from "shared/Spinner/Spinner";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import * as actionTypes from "store/actions/actionTypes";
import { btnName, errorMessage } from "const/constValue";
import { IC_EXPORT, IC_FILTER, SIDE_DROPDOWN_ARROW } from "const/imgCost";
import SubMenuListItem from "./DashboardFilter/Dropdown/SubMenuListItem";
import DashboardFilterSelection from "./DashboardFilter/DashboardFilterSelection/DashboardFilterSelection";

export default function Dashboard() {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [graphResponse, setGraphResponse] = useState(null);
  const [filterToggle, setFilterToggle] = useState(false);
  const [mainMenuSelection, setMainMenuSelection] = useState("");
  const {
    getHrDashboardReport,
    dashboardDataLoading,
    dashboardReportFilter,
    dashboardFilters,
    hrDashboardExcelError,
    hrDashboardExcelSuccess,
    dashboardOption,
    mainMenuItem,
    allQuarterlyCycleList,
  } = useSelector((state) => state.hrDashboardReport);
  const { subGroupClient } = useSelector((state) => state.admin);
  const { fileResponse } = useSelector((state) => state.performance);

  useEffect(() => {
    dispatch(actions.getSubGroupClient());
  }, [dispatch]);

  useEffect(() => {
    if (subGroupClient?.length === 1) {
      dispatch(actions.getHrDashboardFilterOptions(subGroupClient));
      dispatch({
        type: actionTypes.CLEAR_DASHBOARD_REPORT_FILTER_DATA,
        payload: { filterSubGroups: subGroupClient },
      });
    }
  }, [subGroupClient]);

  useEffect(() => {
    if (hrDashboardExcelError?.error) {
      errorToast(hrDashboardExcelError.message);
    }
    if (hrDashboardExcelSuccess?.success) {
      successToast(hrDashboardExcelSuccess.message);
    }
  }, [hrDashboardExcelError, hrDashboardExcelSuccess]);

  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);

      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
  }, [dispatch, fileResponse]);

  useEffect(() => {
    if (getHrDashboardReport) {
      setGraphResponse(getHrDashboardReport);
    } else setGraphResponse([]);
  }, [getHrDashboardReport]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (filterToggle) {
          if (document.getElementById("filter-box").contains(event.target)) {
            // Clicked in box
          } else {
            setFilterToggle(false);
            setMainMenuSelection("");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, filterToggle]);
  }

  useOutsideAlerter(wrapperRef);

  const getDateRange = (startDate, endDate) => {
    const params = {
      startDate: moment(startDate).format(config.dates.secondary),
      endDate: moment(endDate).format(config.dates.secondary),
    };
    dispatch(actions.getHrDashboardReport(params, dashboardFilters));
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleSelectAllFilterData = (variable, isChecked, type) => {
    if (variable !== mainMenuSelection) {
      setMainMenuSelection("");
    }
    const filterSelectedData = {
      [filterNameFromOption(variable)]: { ...setArrayToObject(dashboardOption[variable], isChecked) },
    };
    dispatch({
      type: actionTypes.SET_HR_DASHBOARD_MENU_CHECKED,
      data: { [variable]: isChecked },
    });
    if (variable === "subGroupClient") {
      dispatch({
        type: actionTypes.CLEAR_DASHBOARD_REPORT_FILTER_DATA,
        payload: { filterSubGroups: { ...setArrayToObject(subGroupClient, isChecked) } },
      });
      dispatch(actions.getHrDashboardFilterOptions(setArrayToObjectWithOnlyValue(subGroupClient, isChecked)));
    } else {
      dispatch({
        type: actionTypes.SET_DASHBOARD_REPORT_FILTER_DATA,
        payload: filterSelectedData,
      });
      if (type === "REMOVE_FROM_FILTER") {
        dispatch({
          type: actionTypes.SET_DASHBOARD_FILTERS,
          payload: filterSelectedData,
        });
        let dated = new Date();
        let quarter = Math.floor(dated.getMonth() / 3);
        let sDate = new Date(dated.getFullYear(), quarter * 3, 1);
        let eDate = new Date(sDate.getFullYear(), sDate.getMonth() + 3, 0);
        const params = {
          startDate: moment(startDate).format("MM-DD-YYYY"),
          endDate: moment(endDate).format("MM-DD-YYYY"),
        };
        dispatch(actions.getHrDashboardReport(params, { ...dashboardReportFilter, ...filterSelectedData }));
        dispatch(
          actions.getEmployeeObjectiveChartData(
            { startDate: sDate, endDate: eDate },
            { ...dashboardReportFilter, ...filterSelectedData }
          )
        );
        if (allQuarterlyCycleList.length > 0) {
          const cycleId = allQuarterlyCycleList[0].value;
          dispatch(actions.getAssessmentReportChartData(cycleId, { ...dashboardReportFilter, ...filterSelectedData }));
        }
      }
    }
  };

  const handleFilterData = (variable, value, isChecked) => {
    const filterSelected = {
      ...dashboardReportFilter[variable],
      [value.value + config.idSplit + value.label]: isChecked,
    };
    const filterSelectedData = {
      [variable]: filterSelected,
    };
    if (!isChecked) {
      dispatch({
        type: actionTypes.SET_HR_DASHBOARD_MENU_CHECKED,
        data: { [filterOptionFromName(variable)]: isChecked },
      });
    }
    if (variable === "filterSubGroups") {
      dispatch({
        type: actionTypes.CLEAR_DASHBOARD_REPORT_FILTER_DATA,
        payload: {
          filterSubGroups: filterSelected,
        },
      });
      dispatch(actions.getHrDashboardFilterOptions(filterSelected));
    } else {
      dispatch({
        type: actionTypes.SET_DASHBOARD_REPORT_FILTER_DATA,
        payload: filterSelectedData,
      });
    }
  };

  const handleClearFilter = () => {
    dispatch({
      type: actionTypes.CLEAR_ALL_DASHBOARD_REPORT_FILTER_DATA,
    });
    if (Object.entries(dashboardFilters)?.length > 0) {
      const cycleId = allQuarterlyCycleList?.length > 0 && allQuarterlyCycleList[0]?.value;
      let dated = new Date();
      let quarter = Math.floor(dated.getMonth() / 3);
      let sDate = new Date(dated.getFullYear(), quarter * 3, 1);
      let eDate = new Date(sDate.getFullYear(), sDate.getMonth() + 3, 0);
      const params = {
        startDate: moment(startDate).format(config.dates.secondary),
        endDate: moment(endDate).format(config.dates.secondary),
      };
      dispatch(actions.getHrDashboardReport(params));
      /* changed date format for production issue */
      dispatch(actions.getEmployeeObjectiveChartData({ startDate: moment(sDate).format(config.dates.secondary), endDate: moment(eDate).format(config.dates.secondary) }));
      dispatch(actions.getAssessmentReportChartData(cycleId));
    }
  };

  const applyDashboardFilter = (type) => {
    if (!startDate || !endDate) {
      errorToast(errorMessage.DATE_FORMAT);
    } else {
      // dispatch action
      const params = {
        startDate: moment(startDate).format(config.dates.custom),
        endDate: moment(endDate).format(config.dates.custom),
      };

      let dated = new Date();
      let quarter = Math.floor(dated.getMonth() / 3);
      let sDate = new Date(dated.getFullYear(), quarter * 3, 1);
      let eDate = new Date(sDate.getFullYear(), sDate.getMonth() + 3, 0);
      if (type === "export") {
        dispatch(actions.getHrDashboardExportReport(params, dashboardReportFilter));
      } else {
        dispatch({
          type: actionTypes.SET_DASHBOARD_FILTERS,
          payload: dashboardReportFilter,
        });
        dispatch(actions.getHrDashboardReport(params, dashboardReportFilter));
        /* changed date format for production issue */
        dispatch(actions.getEmployeeObjectiveChartData({ startDate: moment(sDate).format(config.dates.secondary), endDate: moment(eDate).format(config.dates.secondary) }, dashboardReportFilter));
        if (allQuarterlyCycleList.length > 0) {
          const cycleId = allQuarterlyCycleList[0].value;
          dispatch(actions.getAssessmentReportChartData(cycleId, dashboardReportFilter));
        }
        setFilterToggle(false);
        setMainMenuSelection("");
      }
    }
  };

  const handleRemove = (variable, value) => {
    const data = { value: value.split(config.idSplit)[0], label: value.split(config.idSplit)[1] };
    const filterSelected = {
      ...dashboardFilters[variable],
      [data.value + config.idSplit + data.label]: false,
    };
    const filterSelectedData = {
      [variable]: filterSelected,
    };
    if (variable === "filterSubGroups") {
      dispatch({
        type: actionTypes.CLEAR_DASHBOARD_REPORT_FILTER_DATA,
        payload: {
          filterSubGroups: filterSelected,
        },
      });
      dispatch(actions.getHrDashboardFilterOptions(filterSelected));
    } else {
      dispatch({
        type: actionTypes.SET_DASHBOARD_FILTERS,
        payload: filterSelectedData,
      });

      const params = {
        startDate: moment(startDate).format(config.dates.secondary),
        endDate: moment(endDate).format(config.dates.secondary),
      };

      let dated = new Date();
      let quarter = Math.floor(dated.getMonth() / 3);
      let sDate = new Date(dated.getFullYear(), quarter * 3, 1);
      let eDate = new Date(sDate.getFullYear(), sDate.getMonth() + 3, 0);
      dispatch(actions.getHrDashboardReport(params, dashboardFilters));
      /* changed date format for production issue */
      dispatch(actions.getEmployeeObjectiveChartData({ startDate: moment(sDate).format(config.dates.secondary), endDate: moment(eDate).format(config.dates.secondary)}, dashboardFilters));
      if (allQuarterlyCycleList.length > 0) {
        const cycleId = allQuarterlyCycleList[0].value;
        dispatch(actions.getAssessmentReportChartData(cycleId, dashboardFilters));
      }
    }
  };

  return (
    <div className={style.hr_dashboard_wrapper} ref={wrapperRef}>
      <SubNavigation type="Analysis" />
      {dashboardDataLoading && <Spinner />}
      <Container>
        <Row className="align-items-center">
          <Col md={12}>
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">
              Analysis<span>Module Analysis</span>
            </span>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={12}>
            <div className={style.filters_wrapper}>
              <div className={style.filter_dashboard_wrapper}>
                <div className={[style.badge_wrapper, style.date_picker_badge, "box"].join(" ")}>
                  <div className={style.badge_title}>Select Dates</div>
                  <div className="calendar_wrapper form-group date_range_calendar_wrapper">
                    <CustomDateRangePicker getDateRange={getDateRange} />
                  </div>
                </div>
                <div className={style.filter_wrapper}>
                  <div
                    className={[style.badge_wrapper, "box"].join(" ")}
                    onClick={() => setFilterToggle(() => !filterToggle)}
                  >
                    <div className={style.badge_title}>Filter </div>
                    <div>
                      <ReactSVG src={IC_FILTER} />
                    </div>
                  </div>
                  {filterToggle && (
                    <div id="filter-box">
                      <div className={[style.dashboard_dropdown_wrapper, "box"].join(" ")}>
                        <ul>
                          {mainMenuItem.map((value) => (
                            <li key={value.value}>
                              <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-2">
                                <input
                                  type="checkbox"
                                  id={value.name + value.label}
                                  disabled={value.count ? false : true}
                                  checked={value.checked}
                                  onChange={(e) => handleSelectAllFilterData(value.name, e.target.checked)}
                                />
                                <label htmlFor={value.name + value.label}>{value.label}</label>
                              </div>
                              <div className={style.right_side} onClick={() => setMainMenuSelection(value?.name)}>
                                <div className={style.count}>{value.count}</div>
                                <ReactSVG src={SIDE_DROPDOWN_ARROW} />
                              </div>
                            </li>
                          ))}
                          <div className="d-flex justify-content-between mt-2 mb-2">
                            <div className={style.filter_clear_button}>
                              <button
                                type="button"
                                className="btn btn-primary dashboard-filter-btn"
                                onClick={() => applyDashboardFilter()}
                                data-test="apply"
                              >
                                <strong>{btnName.APPLY}</strong>
                              </button>
                            </div>
                            <div className={style.filter_clear_button}>
                              <button
                                type="button"
                                className="btn btn-secondary dashboard-filter-btn"
                                onClick={() => handleClearFilter()}
                                data-test="clear"
                              >
                                <strong>{btnName.CLEAR}</strong>
                              </button>
                            </div>
                          </div>
                        </ul>
                      </div>
                      {mainMenuSelection && (
                        <div
                          style={{ top: hightOfMainMenu(mainMenuSelection) || "90px" }}
                          className={[style.dashboard_dropdown_wrapper, style.subMenu_dropdown, "box"].join(" ")}
                        >
                          {
                            <SubMenuListItem
                              firstMenu={mainMenuSelection}
                              menuItems={
                                mainMenuSelection === "subGroupClient"
                                  ? subGroupClient
                                  : dashboardOption[mainMenuSelection]
                              }
                              handleFilterData={handleFilterData}
                              dashboardReportFilter={dashboardReportFilter}
                            />
                          }
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={[style.badge_wrapper, "box"].join(" ")} onClick={() => applyDashboardFilter("export")}>
                  <div className={style.badge_title}>Export </div>
                  <div>
                    <ReactSVG src={`${IC_EXPORT}`} />
                  </div>
                </div>
              </div>
              {Object.keys(dashboardFilters).length > 0 && (
                <div className={style.filter_clear_button}>
                  <button
                    type="button"
                    className="btn btn-secondary date-range-apply-btn"
                    onClick={() => handleClearFilter()}
                    data-test="clear"
                  >
                    <strong>{btnName.CLEAR_ALL}</strong>
                  </button>
                </div>
              )}
            </div>

            <DashboardFilterSelection
              dashboardReportFilter={dashboardFilters}
              dashboardOption={dashboardOption}
              handleRemove={handleRemove}
              handleSelectAllFilterData={(item) => handleSelectAllFilterData(item, false, "REMOVE_FROM_FILTER")}
            />
            <CheckinLapsedCharts checkinData={graphResponse?.checkInChart} />
            <MoodOMeterCharts moodOMeterData={graphResponse?.moodOMeterChart} />
            <WorkLoadCharts workLoadData={graphResponse?.workLoadChart} />
            <EmployeeObjectiveCharts filterValue={dashboardFilters} />
            <AssessmentReportCharts filterValue={dashboardFilters} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
