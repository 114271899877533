import React from "react";
import style from "../AssignObjectives/AssignObjectives.module.scss";
import { Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { IC_SETTINGS, IC_DELETE_2, IC_EDIT_OBJECTIVE } from "../../../const/imgCost";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { dateFormat1, endedDays } from "../../../util/dateUtils";
import storage from "util/storage";

//this objetive heading is user assigned objective heading from assignObjective component
export default function ObjectiveHeading(props) {
  const {
    createdDate,
    title,
    employeeData,
    completionDate,
    startDate,
    endDate,
    percentage,
    userObjectiveId,
    managerData,
    progressStatus,
    status,
    weightage,
  } = props.objective;
  const userSelectedStatus = (status) => {
    if (status === "ORANGE") {
      return <div className="status_color_yellow"></div>;
    } else if (status === "GREEN") {
      return <div className="status_color_green"></div>;
    } else if (status === "RED") {
      return <div className="status_color_red"></div>;
    }
  };
  const loggedinUser = storage.getUser();

  return (
    <Col className="col-12">
      <div className={["custom-tabs custom-tabs-full-width tab-content-dashboard", style.allobjectives_tabs].join(" ")}>
        <div className="box ">
          <div className="box-heading-wrapper">
            <div className={["box-heading", "text-break"].join(" ")}>
              <h2>
                {progressStatus && userSelectedStatus(progressStatus)}
                {title}
              </h2>
            </div>
            {props.created && !props.objective.isNotify && !completionDate && status !== "DRAFTED" && (
              <div className={style.settings_icon}>
                <div className="action-dropdown-wrapper">
                  <DropdownButton alignRight title={<ReactSVG src={IC_SETTINGS} alt="" />} variant="a">
                    {managerData && managerData?.employeeId + managerData?.parentGroupId + managerData?.subGroupId === loggedinUser?.employeeId + loggedinUser?.parentGroupId + loggedinUser?.subGroupId && (
                      <Dropdown.Item>
                        <Link
                          to={`/objectives/edit-objective/${userObjectiveId}?empId=${employeeData.employeeId}&pgId=${employeeData.parentGroupId}&sgId=${employeeData.subGroupId}`}
                          className="dropdown_item"
                        >
                          <div className="dropdown_icon">
                            <ReactSVG src={IC_EDIT_OBJECTIVE} alt="" />
                          </div>
                          Edit Objective
                        </Link>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item className={style.setting_delete}>
                      <div className="dropdown_icon">
                        <ReactSVG src={IC_DELETE_2} alt="" />
                      </div>
                      <div className={style.dropdown_delete} onClick={() => props.deleteObjective(userObjectiveId)}>
                        Delete
                      </div>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            )}
          </div>
          <div className="box-inner-pad pb-0">
            <div className="form-group">
              <Row>
              <Col xl={2} md={3} lg={2} className={style.assign_objective_duration}>
                  <span>OKR Weightage</span>
                  {weightage? weightage + "%" : "-" }
                </Col>
                <Col xl={2} md={3} lg={2} className={style.assign_objective_duration}>
                  <span>Assigned On</span>
                  {dateFormat1(createdDate)}
                </Col>
                <Col xl={3} md={6} lg={4} className={style.assign_objective_duration}>
                  <span>Timeframe</span>
                  {dateFormat1(startDate) + " to " + dateFormat1(endDate)}
                </Col>
                <Col
                  xl={4}
                  md={3}
                  lg={2}
                  className={["text-right", style.assign_objective_duration].join(" ")}
                >
                  <div className={style.status_wrapper}>
                    {percentage >= 66 ? (
                      <div className={style.status_color_green}></div>
                    ) : percentage > 33 ? (
                      <div className={style.status_color_yellow}></div>
                    ) : (
                      <div className={style.status_color_red}></div>
                    )}
                    {percentage}%{completionDate ? <div>Completed</div> : <div>{endedDays(endDate)}</div>}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
