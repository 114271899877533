import React from "react";
import DurationDateSelector from "../../DurationDateSelector/DurationDateSelector";
import { Row, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { backGroundColor } from "../../../const/constValue";

export default function EmployeeSelectAssignDate({
  employee,
  handleStartDate,
  handleEndDate,
  duration,
  endDate,
  startDate,
  handleReviewer,
  reviewer,
  isPage,
  minDate,
  selectedReviewer,
}) {
  return (
    <div className="box pb-0">
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Assign &amp; Timeframe Details</h2>
          {/* <h3>Some text goes here, will be updated soon</h3> */}
        </div>
      </div>
      <div className="box-light-gradient"></div>

      <div className="box-inner-pad">
        <Row>
          <div className="col-xl-12">
            <div className="notice_image_page">
              {employee && (
                <div className="user_info p-0 border-0">
                  <div className="user_img" style={{ backgroundColor: backGroundColor[0] }}>
                    {employee.dispArr ? <img src={employee.dispArr} alt="" /> : employee.employeeInitial}
                  </div>

                  <div className="user_details">
                    <div>
                      <h2 className="user_name">{employee.displayName}</h2>
                      <h2 className="user_designation">
                        {employee.designation} | {employee.department}
                      </h2>
                    </div>
                  </div>
                  <div className="userBandWrapper">
                    <span className="userBandTitle">Band</span>
                    <h5 className="userBand">{employee.band}</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Row>
      </div>
      <DurationDateSelector
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        duration={duration}
        startDate={startDate}
        minDate={minDate}
        endDate={endDate}
      />
      <div className={["box-inner-pad", "reviewer-select", isPage !== "SELF_OBJECTIVE" && "d-none"].join(" ")}>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Please Select the Reviewer you might want to notify</Form.Label>
          <Form.Control as="select" onChange={handleReviewer} defaultValue="" value={selectedReviewer}>
            <option value="">Select Reviewer</option>
            {reviewer &&
              reviewer.length > 0 &&
              reviewer.map((value) => (
                <option key={value.employeeId} value={JSON.stringify(value)}>
                  {value.empName}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      </div>
    </div>
  );
}

EmployeeSelectAssignDate.propTypes = {
  employee: PropTypes.shape({
    displayName: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    band: PropTypes.string,
    dispArr: PropTypes.string,
    employeeInitial: PropTypes.string,
  }),
  handleStartDate: PropTypes.func,
  handleEndDate: PropTypes.func,
  duration: PropTypes.number,
  endDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
};
