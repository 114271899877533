import _ from "lodash";
import React, { Component } from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";

class CustomDateRangePicker extends Component {
  constructor(props) {
    super(props);
    const currentMoment = moment().subtract(7, "days");
    this.state = {
      focusedInput: null,
      startDate: this.calculateActiveWeek(currentMoment)[1],
      endDate: this.calculateActiveWeek(currentMoment)[0],
      fullscreen: false,
      direction: "left",
      dateFormat: "MM/DD/YYYY",
      small: false,
      block: false,
      orientation: "horizontal",
      numMonths: 2,
      minimumNights: 7,
      hoveredDays: this.calculateActiveWeek(currentMoment),
    };
    this.handleDatesChange = this.handleDatesChange.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
    this.handleChangeFullscreen = this.handleChangeFullscreen.bind(this);
    this.handleChangeDirection = this.handleChangeDirection.bind(this);
    this.handleChangeDateFormat = this.handleChangeDateFormat.bind(this);
    this.calculateActiveWeek = this.calculateActiveWeek.bind(this);
    this.renderMonthElement = this.renderMonthElement.bind(this);
    this.props.getDateRange(this.state.startDate, this.state.endDate);
  }

  handleDatesChange(props, type) {
    const { startDate, endDate, selectedDate } = props;
    if (type === "SELECT") {
      this.setState({
        startDate: this.calculateActiveWeek(selectedDate)[1],
        endDate: this.calculateActiveWeek(selectedDate)[0],
      });
      this.props.getDateRange(this.calculateActiveWeek(selectedDate)[1], this.calculateActiveWeek(selectedDate)[0]);
    } else {
      this.setState({ startDate, endDate });
      this.props.getDateRange(startDate, endDate);
    }
  }

  calculateActiveWeek = (date) => {
    const mon = date.clone().startOf("isoweek");
    const tue = mon.clone().add(1, "d");
    const wed = mon.clone().add(2, "d");
    const thu = mon.clone().add(3, "d");
    const fri = mon.clone().add(4, "d");
    const sat = mon.clone().add(5, "d");
    const sun = mon.clone().add(6, "d");
    return [sun, mon, tue, wed, thu, fri, sat];
  };

  handleFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  handleChangeFullscreen() {
    this.setState({ fullscreen: !this.state.fullscreen });
  }

  handleChangeDirection(e) {
    this.setState({ direction: e.target.value });
  }

  handleChangeDateFormat(e) {
    this.setState({ dateFormat: e.target.value });
  }

  renderCalendarDay = (date) => {
    const dayClasses = ["CalendarDay", "CalendarDay__default", "CalendarDay_1", "CalendarDay__default_2"];
    let style = {
      width: "27px",
      height: "27px",
    };

    if (date.day) {
      const currentDate = date.day.format("MM/DD/YYYY");
      let startDate = this.state.startDate?.format("MM/DD/YYYY");
      let endDate = this.state.endDate?.format("MM/DD/YYYY");
      if (!startDate || !endDate) {
        startDate = this.calculateActiveWeek(moment())[1].format("MM/DD/YYYY");
        endDate = this.calculateActiveWeek(moment())[0].format("MM/DD/YYYY");
      }
      const dayOfMonth = date.day.date();

      // const isStartDayMatch = currentDate === startDate ? true : false;
      // const isEndDayMatch = currentDate === endDate ? true : false;
      const isMatch =
        moment(currentDate).isSameOrAfter(startDate) && moment(currentDate).isSameOrBefore(endDate) ? true : false;

      let style = {
        width: "39px",
        height: "38px",
        backgroundColor: isMatch ? "#7033FF" : "white",
        color: isMatch ? "white" : "black",
        // borderRadius: isStartDayMatch ? "50% 0 0 50%" : isEndDayMatch ? "0 50% 50% 0" : isMatch ? "0.1px" : "0",
        borderRadius: isMatch ? "0.1px" : "0",
      };
      return (
        <td
          style={style}
          className={dayClasses}
          onClick={() => this.handleDatesChange({ startDate: null, endDate: null, selectedDate: date.day }, "SELECT")}
        >
          {dayOfMonth}
        </td>
      );
    } else {
      return <td style={style} className={dayClasses} />;
    }
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div className="custom-month-year-picker">
      <div className="custom-month-picker">
        <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className="custom-year-picker">
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {_.range(moment().year() - 20, moment().year() + 50 + 1, 1).map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  testCustomInputIcon = () => {
    return <h2>fdfdfd</h2>;
  };

  render() {
    return (
      <>
        <DateRangePicker
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={(props) => this.handleDatesChange(props, "CHANGE")} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={this.handleFocusChange} // PropTypes.func.isRequired,
          displayFormat={this.state.dateFormat}
          hideKeyboardShortcutsPanel={true}
          numberOfMonths={1}
          block={this.state.block}
          small={this.state.small}
          withFullScreenPortal={this.state.fullscreen}
          anchorDirection={this.state.direction}
          orientation={this.state.orientation}
          isOutsideRange={() => false}
          renderCalendarDay={this.renderCalendarDay}
          renderMonthElement={this.renderMonthElement}
          showDefaultInputIcon={true}
          minimumNights={0}
        />
      </>
    );
  }
}
export default CustomDateRangePicker;
