import * as actionTypes from "../actions/actionTypes";

const initialState = {
  feedbacks: [],
  password: false,
  isLoading: true,
  authorizedResponse: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FEEDBACKS:
        return {
          ...state,
          feedbacks: action.feedbacks,
          password: action.password,
          isLoading: false,
          authorizedResponse: action.authorizedResponse
        };
    default:  
      return state;
  }
};

export default reducer;
