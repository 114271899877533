const softwareDeveloperDesignation = [
  "Software Engineer",
  "Associate Software Engineer",
  "Sr. Software Engineer",
  "Senior Software Engineer",
  "Solutions Architect",
  "Solutions- Architect",
];
const leadDesignation = ["Technical Lead", "Senior Technical Lead"];
const managerDesignation = [
  "Associate Project Manager",
  "Senior Technical Manager",
  "Sr. Product Manager",
  "Project Manager",
];

export const capabilityTemplateMapping = [
  {
    questionName: "Drive Results",
    designation: softwareDeveloperDesignation,
    toolTip: "Is accountable , strive and achieve excellence in your commitment to the client",
  },
  {
    questionName: "Be Resourceful",
    designation: softwareDeveloperDesignation,
    toolTip: "Is Solution focussed , problem solver mindset",
  },
  {
    questionName: "Improve and Innovate",
    designation: softwareDeveloperDesignation,
    toolTip: "Is effective and finds easy solutions to complex problems and processes",
  },

  {
    questionName: "Resilience",
    designation: softwareDeveloperDesignation,
    toolTip: "Develops a mindset - All Obstacles and Road blocks have a solution",
  },
  {
    questionName: "Progress over Perfection",
    designation: softwareDeveloperDesignation,
    toolTip: "Isn't afraid to try, fail and learn but continue the process ",
  },
  {
    questionName: "Influencing",
    designation: softwareDeveloperDesignation,
    toolTip:
      "Is able to articulate thoughts and feelings clearly , uses appropriate aids to convey a compelling message",
  },

  {
    questionName: "Honesty",
    designation: softwareDeveloperDesignation,
    toolTip: "Is able to say and do things that are right, even when no one is watching",
  },
  {
    questionName: "Candid",
    designation: softwareDeveloperDesignation,
    toolTip: "Provides and Receives honest and constructive feedback ",
  },
  {
    questionName: "Listening to Understand",
    designation: softwareDeveloperDesignation,
    toolTip: "Uninterrupted and Patient in conversations, seeks approval by paraphrasing ,appropriately",
  },
  {
    questionName: "Business Acumen",
    designation: softwareDeveloperDesignation,
    toolTip: "Strong know how of the Customer, Industry and  technology for providing best in class solutions",
  },
  {
    questionName: "Prudence",
    designation: softwareDeveloperDesignation,
    toolTip: "Is observant and curious to identify inefficiencies and wastages in the work front including financial",
  },
  {
    questionName: "Managing Ambiguity",
    designation: softwareDeveloperDesignation,
    toolTip: "Seeks for clarity and reaches out to people when in doubt ",
  },
  {
    questionName: "Growth Mindset",
    designation: softwareDeveloperDesignation,
    toolTip: "Is prepared to try and look at options and not give up when faced with a road block",
  },
  {
    questionName: "Emotional Intelligence",
    designation: softwareDeveloperDesignation,
    toolTip: "Is able to appreciate different perspectives,thoughts, needs amongst others, team members",
  },
  {
    questionName: "Team Player",
    designation: softwareDeveloperDesignation,
    toolTip:
      "Establishes a strong working relationship amongst peers and teams and seeks for ideas and solutions from team members",
  },
  {
    questionName: "Compassionate",
    designation: softwareDeveloperDesignation,
    toolTip: "Is receptive of others views and considerate of their thoughts and feelings",
  },
  {
    questionName: "Vulnerable",
    designation: softwareDeveloperDesignation,
    toolTip: 'Is able to say "I don’t know", " I need help" with out thinking of repercussions',
  },
  {
    questionName: "Gratitude",
    designation: softwareDeveloperDesignation,
    toolTip: "Is happy to celebrate success with others, recognizes people for their contributions",
  },
  {
    questionName: "Drive Results",
    designation: leadDesignation,
    toolTip: "Strives to achieve excellence in delivering to client commitments, recognizes result driven behaviors",
  },
  {
    questionName: "Be Resourceful",
    designation: leadDesignation,
    toolTip: "Creates an environment to promote creative thinking and problem solving",
  },
  {
    questionName: "Improve and Innovate",
    designation: leadDesignation,
    toolTip: "Critically examine processes, promote and encourage team members in ideation of process improvement ",
  },

  {
    questionName: "Resilience",
    designation: leadDesignation,
    toolTip:
      "Is a role model and exemplifies the mindset - Roadblocks can be tackled with creative thinking and open communication",
  },
  {
    questionName: "Progress over Perfection",
    designation: leadDesignation,
    toolTip: "Explains the essence of what Progress over Perfection means to the customer and to the business",
  },
  {
    questionName: "Influencing",
    designation: leadDesignation,
    toolTip:
      "Is able to role model , coach teams to convey a thought , its impact and help with relevant aids to suit the desired recipients ",
  },

  {
    questionName: "Honesty",
    designation: leadDesignation,
    toolTip: "Is able to recognize and appreciate  honesty, is just and fair in all dealings with Teams and Customers",
  },
  {
    questionName: "Candid",
    designation: leadDesignation,
    toolTip: "Is receptive of constructive feedback and works on it. Seeks and provides feedback regularly",
  },
  {
    questionName: "Listening to Understand",
    designation: leadDesignation,
    toolTip: "Patient in conversations , does not interrupt and respond abruptly ",
  },
  {
    questionName: "Business Acumen",
    designation: leadDesignation,
    toolTip: "Strong know how of the Customer, Industry and  technology for providing best in class solutions",
  },
  {
    questionName: "Prudence",
    designation: leadDesignation,
    toolTip: "Is cautious with spends and utilization and encourages teams to think and takes judicious decisions",
  },
  {
    questionName: "Managing Ambiguity",
    designation: leadDesignation,
    toolTip: "Has a clear Understanding of the project and breaks down the information into chunks and tasks. ",
  },
  {
    questionName: "Growth Mindset",
    designation: leadDesignation,
    toolTip: "Encourages out of  box thinking and promotes people to work towards multiple solutions ",
  },
  {
    questionName: "Emotional Intelligence",
    designation: leadDesignation,
    toolTip: "Is able to adapt and modify responses based on the needs and situations in/of the team",
  },
  {
    questionName: "Team Player",
    designation: leadDesignation,
    toolTip: "Involves collective participation and promotes group think and solutions that are diverse and inclusive",
  },
  {
    questionName: "Compassionate",
    designation: leadDesignation,
    toolTip: "Is caring and expresses willingness to listen with an intent to help or provide guidance where needed",
  },
  {
    questionName: "Vulnerable",
    designation: leadDesignation,
    toolTip: "Is able to proudly take accountability of failures and share the learnings from it",
  },
  {
    questionName: "Gratitude",
    designation: leadDesignation,
    toolTip: "Recognizes people for making a difference with their contribution. Celebrate success ",
  },
  {
    questionName: "Drive Results",
    designation: managerDesignation,
    toolTip:
      "Is a role model to Individuals and leaders and fosters the culture to strive for excellence and drive results",
  },
  {
    questionName: "Be Resourceful",
    designation: managerDesignation,
    toolTip: "Exemplifies a problem solver mindset and inspires out of the box thinking",
  },
  {
    questionName: "Improve and Innovate",
    designation: managerDesignation,
    toolTip:
      "Identifies and promotes the culture of process improvement and contribute to building an Infostretch Innovation Index",
  },

  {
    questionName: "Resilience",
    designation: managerDesignation,
    toolTip:
      "Is an ambassador and exemplifies the mindset - Roadblocks can be tackled with creative thinking and open communication",
  },
  {
    questionName: "Progress over Perfection",
    designation: managerDesignation,
    toolTip: "Curates this culture and encourages individuals and teams by recognizing the business impact",
  },
  {
    questionName: "Influencing",
    designation: managerDesignation,
    toolTip:
      "Is able to role model , coach teams to convey a thought , its impact and help with relevant aids to suit the desired recipients ",
  },

  {
    questionName: "Honesty",
    designation: managerDesignation,
    toolTip: "Is able to recognize and appreciate  honesty, is just and fair in all dealings with Teams and Customers",
  },
  {
    questionName: "Candid",
    designation: managerDesignation,
    toolTip: "Is receptive of constructive feedback and works on it. Seeks and provides feedback regularly",
  },
  {
    questionName: "Listening to Understand",
    designation: managerDesignation,
    toolTip: "Is able to empathies and respond appropriately , even to implied needs",
  },
  {
    questionName: "Business Acumen",
    designation: managerDesignation,
    toolTip: "Strong know how of the Customer, Industry and  technology for providing best in class solutions",
  },
  {
    questionName: "Prudence",
    designation: managerDesignation,
    toolTip:
      "Is a role model and adopts frugal approaches while strategists and approving for time , resources and money",
  },
  {
    questionName: "Managing Ambiguity",
    designation: managerDesignation,
    toolTip: "Creates a compelling purpose and sets clear bench marks , even in the absence of finer details",
  },
  {
    questionName: "Growth Mindset",
    designation: managerDesignation,
    toolTip:
      "Creates an environment where solutions to select problems are sought from across the board and not limited to experience or pedigree",
  },
  {
    questionName: "Emotional Intelligence",
    designation: managerDesignation,
    toolTip:
      "Is able to sense and respond to the thoughts and needs of various teams and individuals in the organization",
  },
  {
    questionName: "Team Player",
    designation: managerDesignation,
    toolTip:
      "Promotes and recognizes strong and diverse team players and provides avenues for cross functional teaming ",
  },
  {
    questionName: "Compassionate",
    designation: managerDesignation,
    toolTip: 'Listen with out a bias or judgement and provide a "safety net" during tough periods and conversations',
  },
  {
    questionName: "Vulnerable",
    designation: managerDesignation,
    toolTip:
      "Is upfront about personal and professional challenges and has no hesitation in sharing about shortfalls both personally and professionally",
  },
  {
    questionName: "Gratitude",
    designation: managerDesignation,
    toolTip:
      "Promotes a culture of appreciation across the organization and is invested in recognizing people for their contribution , big and small",
  },
];
