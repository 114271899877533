import * as actionTypes from "./actionTypes";
import http from "util/http";
import {
  DEPARTMENT_LIST,
  DEPARTMENT_WISE_CAPABILITY_TEMPLATE,
  DESIGNATION_LIST_FROM_DEPARTMENT,
  DETAIL_TEMPLATE,
  SET_CAMPAIGN,
} from "util/endpoints";
import { errorMessage, status, successMessage } from "const/constValue";

export const getDepartmentListCampaign = (campaignId) => (dispatch) => {
  http
    .get(DEPARTMENT_LIST, {}, { params: { campaignId } })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_DEPARTMENT_LIST,
            payload: {
              departmentList: response.data.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_DEPARTMENT_LIST,
          payload: {
            departmentList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_DEPARTMENT_LIST,
        payload: {
          departmentList: [],
          isLoading: false,
        },
      });
    });
};

export const getDesignationListFromDepartment = (department, campaignId) => (dispatch) => {
  const params = { campaignId };
  http
    .put(DESIGNATION_LIST_FROM_DEPARTMENT, {}, { department }, { params })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_DESIGNATION_LIST,
            payload: {
              designationList: response.data.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_DESIGNATION_LIST,
          payload: {
            designationList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_DESIGNATION_LIST,
        payload: {
          designationList: [],
          isLoading: false,
        },
      });
    });
};

export const getCapabilityTemplate = (questionTemplateId, campaignId) => (dispatch) => {
  const params = { campaignId };
  http
    .get(DETAIL_TEMPLATE, { questionTemplateId }, { params })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data.questions?.length) {
          if (response.status === status.SUCCESS) {
            if (response.data) {
              dispatch({
                type: actionTypes.SET_CAPABILITY_TEMPLATE,
                payload: {
                  questionTemplate: response.data,
                  isLoading: false,
                },
              });
            }
          } else {
            dispatch({
              type: actionTypes.SET_CAPABILITY_TEMPLATE,
              payload: {
                questionTemplate: {},
                isLoading: false,
              },
            });
          }
        }
      }
    })
    .catch(() => {});
};

export const mapQuestionTemplate = (body, type) => (dispatch) => {
  type === "DELETE" && dispatch({ type: actionTypes.START_CAPABILITY_LOADING });
  http
    .post(SET_CAMPAIGN, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (type === "DELETE") {
          dispatch(getDepartmentWiseCapabilityTemplate(body?.campaignId));
          dispatch(getDepartmentListCampaign(body?.campaignId));
          dispatch({
            type: actionTypes.SET_CAPABILITY_RESPONSE,
            payload: {
              apiResponse: {
                success: true,
                message: successMessage.DELETE_SUCCESSFULLY,
              },
              filterValue: {},
              isCreateLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_CAPABILITY_RESPONSE,
          payload: {
            apiResponse: {
              success: false,
              message: response?.message?.message,
            },
            isCreateLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAPABILITY_RESPONSE,
        payload: {
          apiResponse: {
            success: false,
            message: errorMessage.REQUIRED_FIELD_MISSING,
          },
          isCreateLoading: false,
        },
      });
    });
};

export const getDepartmentWiseCapabilityTemplate = (campaignId) => (dispatch) => {
  dispatch({ type: actionTypes.START_CAPABILITY_LOADING });
  http
    .get(DEPARTMENT_WISE_CAPABILITY_TEMPLATE, {}, { params: { campaignId } })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_DEPARTMENT_CAPABILITY_LIST,
          payload: {
            departmentCapabilityList: response.data,
            mapTemplateValue: {},
            isCreateLoading: false,
          },
        });
      } else {
        dispatch({
          type: actionTypes.SET_DEPARTMENT_CAPABILITY_LIST,
          payload: {
            departmentCapabilityList: [],
            isCreateLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_CAPABILITY_RESPONSE,
        payload: {
          apiResponse: {
            success: false,
            message: errorMessage.SOMETHING_WRONG,
          },
          isCreateLoading: false,
        },
      });
    });
};
