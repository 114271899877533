import config from "util/config";
import { setDefaultMenu } from "util/utils";

export const NotificationTypes = {
  YOU_MADE_MYDAY: "YOUMADEMYDAY",
  PRIVATE_COMMENT: "PRIVATECOMMENT",
  SUBMIT: "SUBMIT",
  REVIEWED: "REVIEWED",
  WIN: "WIN",
  CHALLENGE: "CHALLENGE",
  COLLECTIVE: "COLLECTIVE",
  OBJECTIVE_ADD: "OBJECTIVE_ADD",
  OBJECTIVE_DELETE: "OBJECTIVE_DELETE",
  OBJECTIVE_COMPLETED: "OBJECTIVE_COMPLETED",
  OBJECTIVE_UPDATE: "OBJECTIVE_UPDATE",
  OBJECTIVE_ACCEPTED: "OBJECTIVE_ACCEPTED",
  OBJECTIVE_REJECTED: "OBJECTIVE_REJECTED",
  OBJECTIVE_PRIVATE_COMMENT: "OBJECTIVE_PRIVATE_COMMENT",
  ONEONONEAGENDA_END: "ONEONONEAGENDA_END",
  ONEONONEAGENDA_SCHEDULE: "ONEONONEAGENDA_SCHEDULE",
  SELF_OBJECTIVE_REQUEST: "SELF_OBJECTIVE_REQUEST",
  SELF_OBJECTIVE_REJECTED: "SELF_OBJECTIVE_REJECTED",
  SELF_OBJECTIVE_APPROVED: "SELF_OBJECTIVE_APPROVED",
  TECHNICAL_ROLE_APPROVED: "TECHNICAL_ROLE_APPROVED",
  TECHNICAL_ROLE_REJECTED: "TECHNICAL_ROLE_REJECTED",
  ENDORSEMENT_BY_ME: "ENDORSEMENT_BY_ME",
  SELF_ENDORSEMENT_REQUESTED: "SELF_ENDORSEMENT_REQUESTED",
  SELF_ENDORSEMENT_ACCEPTED: "SELF_ENDORSEMENT_ACCEPTED",
  SELF_ENDORSEMENT_REJECTED: "SELF_ENDORSEMENT_REJECTED",
  MMG_ENDORSEMENT_REQ_TO_UNIVERSITY_ENDORSED_BY: "WMG_ENDORSEMENT_REQ_TO_UNIVERSITY_ENDORSED_BY",
  MMG_ENDORSEMENT_REQUEST_ENDORSED_BY: "WMG_ENDORSEMENT_REQUEST_ENDORSED_BY",
  MMG_ENDORSEMENT_REQUEST_ENDORSED_TO: "WMG_ENDORSEMENT_REQUEST_ENDORSED_TO",
  MMG_ENDORSEMENT_REQUEST_REJECTED: "WMG_ENDORSEMENT_REQUEST_REJECTED",
  MMG_ENDORSEMENT_REQUEST_ACCEPTED: "WMG_ENDORSEMENT_REQUEST_ACCEPTED",
  MMG_ENDORSEMENT_ACCEPTED_REQUEST: "WMG_ENDORSEMENT_ACCEPTED_REQUEST",
  MMG_ENDORSEMENT_REJECTED_REQUEST: "WMG_ENDORSEMENT_REJECTED_REQUEST",
  GLOBAL_FEEDBACK_SUBMIT: "GLOBAL_FEEDBACK_SUBMIT",
  PROJECT_DEALLOCATION_MANAGER_NOTIFY: "PROJECT_DEALLOCATION_MANAGER_NOTIFY",
  REOPEN_PRIVATE_ASSESSMENT: "REOPEN_PRIVATE_ASSESSMENT",
  RATING_NOTIFY: "RATING_NOTIFY",
  CAMPAIGN: "CAMPAIGN",
  CAMPAIGN_ADMIN: "CAMPAIGN_ADMIN",
  CAPABILITY_ADMIN: "CAPABILITY_ADMIN",
  CAPABILITY: "CAPABILITY",
};

export const redirectNotification = (notification, history) => {
  switch (notification.type) {
    case NotificationTypes.YOU_MADE_MYDAY:
      history.push({ pathname: `/home`, state: { type: "APPRECIATE" } });
      break;
    case NotificationTypes.PRIVATE_COMMENT:
      if (notification.isManager) {
        if (notification.employeeId === notification.receiver.employeeId) {
          history.push({
            pathname: `/okrprogress/my-history`,
            state: notification.surveyCycleId,
          });
        } else {
          history.push({
            pathname: `/okrprogress/my-team/member-okrprogress/${
              notification.employeeId +
              config.employeeIdSplit +
              notification.parentGroupId +
              config.employeeIdSplit +
              notification.subGroupId
            }`,
            state: { cycleId: notification.surveyCycleId },
          });
        }
      } else {
        history.push({
          pathname: `/okrprogress/my-team/member-okrprogress/${notification.sender?.employeeId}${config.employeeIdSplit}${notification.sender?.parentGroupId}${config.employeeIdSplit}${notification.sender?.subGroupId}`,
          state: { cycleId: notification.surveyCycleId },
        });
      }
      break;
    case NotificationTypes.SUBMIT:
      history.push({
        pathname: `/okrprogress/my-team/member-okrprogress/${notification.sender?.employeeId}${config.employeeIdSplit}${notification.sender?.parentGroupId}${config.employeeIdSplit}${notification.sender?.subGroupId}`,
        state: { cycleId: notification.surveyCycleId },
      });
      break;
    case NotificationTypes.REVIEWED:
      history.push({
        pathname: `/okrprogress/my-history`,
        state: notification.surveyCycleId,
      });
      break;
    case NotificationTypes.WIN:
      history.push({ pathname: `/home`, state: { type: "WIN" } });
      break;
    case NotificationTypes.CHALLENGE:
      history.push({ pathname: `/home`, state: { type: "CHALLENGE" } });
      break;
    case NotificationTypes.COLLECTIVE:
      history.push({ pathname: `/home`, state: { type: "COLLECTIVE" } });
      break;
    case NotificationTypes.OBJECTIVE_ADD:
      history.push({ pathname: `/objectives/my-objectives`, state: { type: "CURRENT_OBJECTIVES" } });
      break;
    case NotificationTypes.OBJECTIVE_DELETE:
      history.push({ pathname: `/objectives/my-objectives`, state: { type: "CURRENT_OBJECTIVES" } });
      break;
    case NotificationTypes.OBJECTIVE_UPDATE:
      history.push({ pathname: `/objectives/my-objectives`, state: { type: "CURRENT_OBJECTIVES" } });
      break;
    case NotificationTypes.OBJECTIVE_COMPLETED:
      history.push(
        `/objectives/all-objectives/assigned-objective/${notification.userObjectiveId}?empId=${notification.sender?.employeeId}&pgId=${notification.sender?.parentGroupId}&sgId=${notification.sender?.subGroupId}`
      );
      break;
    case NotificationTypes.SELF_OBJECTIVE_REQUEST:
      history.push(
        `/objectives/all-objectives/assigned-objective/${notification.userObjectiveId}?empId=${notification.sender?.employeeId}&pgId=${notification.sender?.parentGroupId}&sgId=${notification.sender?.subGroupId}`
      );
      break;
    case NotificationTypes.SELF_OBJECTIVE_APPROVED:
      history.push({
        pathname: `/objectives/my-objectives/${notification.userObjectiveId}`,
      });
      break;
    case NotificationTypes.SELF_OBJECTIVE_REJECTED:
      history.push({
        pathname: `/objectives/my-objectives/${notification.userObjectiveId}`,
      });
      break;
    case NotificationTypes.OBJECTIVE_ACCEPTED:
      history.push({ pathname: `/objectives/my-objectives`, state: { type: "COMPLETED_OBJECTIVES" } });
      break;
    case NotificationTypes.OBJECTIVE_REJECTED:
      history.push({ pathname: `/objectives/my-objectives`, state: { type: "CURRENT_OBJECTIVES" } });
      break;
    case NotificationTypes.OBJECTIVE_PRIVATE_COMMENT:
      if (notification.isManager) {
        if (notification.employeeId === notification.receiver.employeeId) {
          history.push({
            pathname: `/okrprogress/my-history`,
            state: notification.surveyCycleId,
          });
        } else {
          history.push({
            pathname: `/okrprogress/my-team/member-okrprogress/${
              notification.employeeId +
              config.employeeIdSplit +
              notification.parentGroupId +
              config.employeeIdSplit +
              notification.subGroupId
            }`,
            state: { cycleId: notification.surveyCycleId },
          });
        }
      } else {
        history.push({
          pathname: `/okrprogress/my-team/member-okrprogress/${
            notification.sender?.employeeId +
            config.employeeIdSplit +
            notification.sender?.parentGroupId +
            config.employeeIdSplit +
            notification.sender?.subGroupId
          }`,
          state: { cycleId: notification.surveyCycleId },
        });
      }
      break;
    case NotificationTypes.ONEONONEAGENDA_END:
      history.push({
        pathname: `/okrprogress/one-on-one/previous/${notification.oneOnOneAgendaId}`,
      });
      break;
    case NotificationTypes.ONEONONEAGENDA_SCHEDULE:
      history.push({
        pathname: `/okrprogress/one-on-one/current/${notification.oneOnOneAgendaId}`,
      });
      break;
    case NotificationTypes.SELF_ENDORSEMENT_REQUESTED:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.SELF_ENDORSEMENT_ACCEPTED:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.SELF_ENDORSEMENT_REJECTED:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.ENDORSEMENT_BY_ME:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.MMG_ENDORSEMENT_REQ_TO_UNIVERSITY_ENDORSED_BY:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.MMG_ENDORSEMENT_REQUEST_ENDORSED_BY:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.MMG_ENDORSEMENT_REQUEST_ENDORSED_TO:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.MMG_ENDORSEMENT_REQUEST_REJECTED:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.MMG_ENDORSEMENT_REQUEST_ACCEPTED:
      history.push({ pathname: "/wmg/skills-endorsement" });
      break;
    case NotificationTypes.MMG_ENDORSEMENT_ACCEPTED_REQUEST:
      history.push({ pathname: "/wmg-view/endorsement/wmg-endorsement" });
      break;
    case NotificationTypes.MMG_ENDORSEMENT_REJECTED_REQUEST:
      history.push({ pathname: "/wmg-view/endorsement/wmg-endorsement" });
      break;
    case NotificationTypes.TECHNICAL_ROLE_APPROVED:
      history.push({ pathname: "/wmg/my-technical-role-change" });
      break;
    case NotificationTypes.TECHNICAL_ROLE_REJECTED:
      history.push({ pathname: "/wmg/my-technical-role-change" });
      break;
    case NotificationTypes.GLOBAL_FEEDBACK_SUBMIT:
      history.push({ pathname: "/okrprogress/global-feedback" });
      break;
    case NotificationTypes.PROJECT_DEALLOCATION_MANAGER_NOTIFY:
      history.push({ pathname: "/performance/project-deallocation" });
      break;
    case NotificationTypes.REOPEN_PRIVATE_ASSESSMENT:
      if (notification.eventType === "SELFASSESSMENT") {
        history.push({ pathname: `/performance/self-assessment` });
      } else if (notification.eventType === "RPMASSESSMENT") {
        history.push(`/performance/reporting-assessment/${notification.quarterlyCycleId}`);
      } else if (notification.eventType === "RVMASSESSMENT") {
        history.push(`/performance/reviewing-assessment/${notification.quarterlyCycleId}`);
      }
      break;
    case NotificationTypes.RATING_NOTIFY:
      history.push({
        pathname: `/performance/dashboard/cycle-detail/${notification.quarterlyCycleId}`,
      });
      break;
    case NotificationTypes.CAMPAIGN:
      history.push({
        pathname: `/okrprogress/campaign`,
      });
      break;
    case NotificationTypes.CAPABILITY:
      history.push({
        pathname: `/wmg/capability-development/${notification.quarterlyCycleId}`,
        state: {
          update: notification.quarterlyCycleId,
        },
      });
      break;
    case NotificationTypes.CAMPAIGN_ADMIN:
      history.push(`/okrprogress/campaign-list/edit?id=${notification.surveyCycleId}`);
      break;
    case NotificationTypes.CAPABILITY_ADMIN:
      history.push(`/wmg/capability-development/campaign-list/edit?id=${notification.surveyCycleId}`);
      break;
    default:
      break;
  }
};
