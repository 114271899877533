import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button, Collapse } from "react-bootstrap";
import moment from "moment";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import * as actions from "store/actions/index";
import { IC_DUSTBIN } from "const/imgCost";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import HideAndShow from "../../HideAndShow/HideAndShow";
import AddTalkingPointBox from "../Components/AddTalkingPointBox";
import ThisOneOnOne from "../Components/ThisOneOnOne";
import PreviouslyHeldOneOnOne from "../Components/PreviouslyHeldOneOnOne";
import TakeActionOfQuestion from "../Components/TakeActionOfQuestion";
import PrivateNotes from "../Components/PrivateNotes";
import SharedNotes from "../Components/SharedNotes";
import { useQuery } from "util/utils";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

function CreateOneOnOne(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = storage.getUser();
  const isManager = props.match.params.id === "manager" ? true : false;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const { user, isLoading, oneOnOne, previousOneOnOneData } = useSelector((state) => state.oneOnOne);
  const [hide, setHide] = useState(false);
  const [comment, setComment] = useState("");
  const [privateNote, setPrivateNote] = useState("");
  const [sharedNote, setSharedNote] = useState("");
  const [selectQuestion, setSelectQuestion] = useState("");

  useEffect(() => {
    dispatch({ type: "REMOVE_ONE_ON_ONE_DATA" });
    dispatch(actions.GetOneOnOneUser(employeeIds));
  }, [dispatch]);

  useEffect(() => {
    if (oneOnOne) {
      if (oneOnOne.oneOnOneAgendaId) {
        history.push(`/okrprogress/one-on-one/current/${oneOnOne.oneOnOneAgendaId}`);
      }
    }
  }, [oneOnOne, history]);

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleQuestion = (e) => {
    setSelectQuestion(e.target.value);
  };

  const sendTalkingPoint = () => {
    const data = {
      createdBy: {
        isManager: !isManager,
        employeeId: loggedInUser?.employeeId,
        empName: loggedInUser?.displayName,
        displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
        department: loggedInUser?.department,
        email: loggedInUser?.email,
        country: loggedInUser?.country,
        parentGroupId: loggedInUser?.parentGroupId,
        subGroupId: loggedInUser?.subGroupId,
        subGroupName: loggedInUser?.subGroupName,
        parentGroupName: loggedInUser?.parentGroupName,
      },
      addOneOnOnewith: {
        isManager: isManager,
        employeeId: user.employeeId,
        empName: user.displayName,
        displayPicture: user.dispArr || user.employeeInitial,
        department: user.department,
        email: user.email,
        country: user.country,
        parentGroupId: user.parentGroupId,
        subGroupId: user.subGroupId,
        subGroupName: user.subGroupName,
        parentGroupName: user.parentGroupName,
      },
      talkingPoints: {
        agenda: comment.trim(),
        addedBy: {
          employeeId: loggedInUser?.employeeId,
          empName: loggedInUser?.displayName,
          displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
          department: loggedInUser?.department,
          parentGroupId: loggedInUser?.parentGroupId,
          subGroupId: loggedInUser?.subGroupId,
          subGroupName: loggedInUser?.subGroupName,
          parentGroupName: loggedInUser?.parentGroupName,
        },
      },
    };
    if (comment.trim()) dispatch(actions.SendTalkingPoint(data, loggedInUser.employeeId));
  };

  const deletePoint = (id) => {
    let data = {
      oneOnOneAgendaId: oneOnOne.oneOnOneAgendaId,
      talkingPointId: id,
    };
    dispatch(actions.DeleteTalkingPoint(data, oneOnOne.oneOnOneAgendaId));
  };

  const handlePrivateNote = (e) => {
    setPrivateNote(e.target.value);
  };

  const sendPrivateNote = () => {
    setPrivateNote("");
    if (privateNote.trim()) {
      let body = {
        oneOnOneAgendaId: oneOnOne.oneOnOneAgendaId,
        privateNotes: {
          comment: privateNote.trim(),
          commentedBy: {
            employeeId: loggedInUser?.employeeId,
            empName: loggedInUser?.displayName,
            displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
            department: loggedInUser?.department,
            designation: loggedInUser?.designation,
            parentGroupId: loggedInUser?.parentGroupId,
            subGroupId: loggedInUser?.subGroupId,
            subGroupName: loggedInUser?.subGroupName,
            parentGroupName: loggedInUser?.parentGroupName,
          },
        },
      };
      dispatch(actions.UpdateOneOnOne(body, oneOnOne.oneOnOneAgendaId));
    }
  };

  const sendSharedNote = () => {
    setSharedNote("");
    if (sharedNote.trim()) {
      let body = {
        oneOnOneAgendaId: oneOnOne.oneOnOneAgendaId,
        sharedNotes: {
          comment: sharedNote.trim(),
          commentedBy: {
            employeeId: loggedInUser?.employeeId,
            empName: loggedInUser?.displayName,
            displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
            department: loggedInUser?.department,
            designation: loggedInUser?.designation,
            parentGroupId: loggedInUser?.parentGroupId,
            subGroupId: loggedInUser?.subGroupId,
            subGroupName: loggedInUser?.subGroupName,
            parentGroupName: loggedInUser?.parentGroupName,
          },
        },
      };
      dispatch(actions.UpdateOneOnOne(body, oneOnOne.oneOnOneAgendaId));
    }
  };

  const deletePrivateNote = (id) => {
    dispatch(actions.DeletePrivateNote(id, oneOnOne.oneOnOneAgendaId));
  };

  const deleteSharedNote = (id) => {
    dispatch(actions.DeleteSharedNote(id, oneOnOne.oneOnOneAgendaId));
  };

  const handleSharedNote = (e) => {
    setSharedNote(e.target.value);
  };
  let userPath;
  if (isManager) {
    userPath = `/okrprogress/one-on-one/manager?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`;
  } else {
    userPath = `/okrprogress/one-on-one/team?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`;
  }

  return (
    <div className={style.one_on_one_tp_wrapper}>
      <SubNavigation type="OKR Progress" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">1—on-1s</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.ONE_ON_ONE}>1—on-1s</Link>
              <span>
                <Link to={userPath}>{user && user.displayName}</Link>
              </span>
              <span>Create 1-on-1s</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <Form>
              <div className={["card"].join(" ")}>
                <div className="card_info">
                  <div className="card_title">
                    <span className="card_title_text">Talking Points</span>
                  </div>
                  <HideAndShow hide={hide} setHide={setHide} />
                </div>

                <Collapse in={!hide}>
                  <div className="card_details">
                    <div className={style.tp_inner_wrapper}>
                      <div className={style.tp_list_wrapper}>
                        <div className={["box mb-0 pt-0 pb-0"].join(" ")}>
                          <div className="box-inner-pad p-0">
                            <Form.Group controlId="">
                              <ul className={style.form_option_list}>
                                {oneOnOne &&
                                  oneOnOne.talkingPoints.map((point, index) => (
                                    <li className={style.show_comment} key={point.talkingPointId}>
                                      <div className="custom-checkbox custom-checkbox-rounded">
                                        <input id={point.talkingPointId} type="checkbox" disabled />
                                        <label htmlFor={point.talkingPointId}>
                                          {point.surveyCycleId ? point.question || point.title : point.agenda}
                                        </label>
                                        <div className={style.comment_delete_wrapper}>
                                          {point.addedBy.employeeId === loggedInUser.employeeId && (
                                            <span
                                              className={style.delete}
                                              onClick={() => deletePoint(point.talkingPointId, index)}
                                            >
                                              <img src={IC_DUSTBIN} alt="delete" />
                                            </span>
                                          )}
                                        </div>
                                        <span className={style.tp_info_wrapper}>
                                          Added By {point.addedBy.empName}
                                          <span className={style.time_info}>
                                            {moment(point.agendaAddedDate).fromNow()}
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <AddTalkingPointBox
                        image={loggedInUser && (loggedInUser.displayPicture || loggedInUser.empInitial)}
                        placeholder="Add a talking point"
                        comment={comment}
                        handleComment={handleComment}
                        sendTalkingPoint={sendTalkingPoint}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
              <PrivateNotes
                disabled={!oneOnOne}
                loggedInUser={loggedInUser}
                image={loggedInUser && (loggedInUser?.displayPicture || loggedInUser?.empInitial)}
                handleComment={handlePrivateNote}
                privateNotes={oneOnOne && oneOnOne.privateNotes}
                comment={privateNote}
                sendTalkingPoint={sendPrivateNote}
                deletePrivateNote={deletePrivateNote}
              />
              <SharedNotes
                isPage="create"
                disabled={!oneOnOne}
                loggedInUser={loggedInUser}
                image={loggedInUser && (loggedInUser.displayPicture || loggedInUser.empInitial)}
                handleComment={handleSharedNote}
                sharedNotes={oneOnOne && oneOnOne.sharedNotes}
                isEnded={oneOnOne && oneOnOne.isEnded}
                comment={sharedNote}
                deleteSharedNote={deleteSharedNote}
                sendTalkingPoint={sendSharedNote}
              />
              <div className={["box pt-0 pb-0 radio-wrapper"].join(" ")}>
                <TakeActionOfQuestion
                  oneOnOne={oneOnOne}
                  handleQuestion={handleQuestion}
                  selectQuestion={selectQuestion}
                />
              </div>
              <div className={["box box-flex-center", style.saveBox].join(" ")}>
                <div className={style.save_box_left}>
                  <Button className="btn btn-primary" disabled={!oneOnOne}>
                    Schedule
                  </Button>
                </div>
              </div>
            </Form>
          </Col>
          <Col lg={4}>
            <ThisOneOnOne oneOnOne={oneOnOne} isPage="create" />
            {user && <PreviouslyHeldOneOnOne data={previousOneOnOneData} employeeId={user.employeeId} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CreateOneOnOne;
