import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import CommentsTop from "components/CommentBox/CommentsTop";
import { IC_LOCK_PRIVACY_2 } from "const/imgCost";
import { MonthlyCheckinTexts, backGroundColor } from "const/constValue";
import storage from "util/storage";
import style from "./RecentCheckInDetailsViewPopup.module.scss";
import FeedbackQuestionsTable from "components/FeedbackQuestionsTable/FeedbackQuestionsTable";

const RecentCheckInDetailsViewPopup = (props) => {
  const loggedInUser = storage.getUser();
  const [employeeList, setEmployeeList] = useState();
  const [commonComment, setCommonComment] = useState();
  const [individualEmployeeList, setIndividualEmployeeList] = useState();
  const { openPopup, setOpenPopup, checkInDetails } = props;

  useEffect(() => {
    // created team member list by unique employee id to show with same response
    let employeeList = checkInDetails?.map((checkIn) => { return checkIn.employeeProfile });
    employeeList = [...new Map(employeeList?.map(profile => [profile["employeeId"], profile])).values()];
    setEmployeeList(employeeList);
    // created team member list by unique employee id to show which employee has common message
    const commonMessage = checkInDetails?.filter((i) => i.isIndividualMessage === false);
    setCommonComment(commonMessage);
    // filtered here which employee has individual message by manager
    const individualMessage = checkInDetails?.filter((i) => i.isIndividualMessage === true);
    setIndividualEmployeeList(individualMessage);
  }, [checkInDetails])
  return (
    <>
      <Modal
        size="lg"
        show={openPopup}
        onHide={() => setOpenPopup(true)}
        animation={false}
      >
        <div className={style.modal_header}>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="row">
              <div className={style.heading_wrapper}>
                <h2>Monthly check-in</h2>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body>
          <div className={style.employees_main_wrapper}>
            <div className={style.employee_names_wrapper}>
              {employeeList?.map((employee, index) => {
                return <div key={`employee${index}`}>{employee.displayName}</div>;
              })}
            </div>
          </div>
          <div className={`col-xl-12 ${style.content_wrapper}`}>
            {checkInDetails?.length > 0 && <div className={style.your_feedback}>
              <FeedbackQuestionsTable
                title="Feedback"
                questionAnswerList={checkInDetails[0]?.questionAnswerListManager}
                isRecentCheckin={true}
              />
            </div>}
          </div>
          <div
            className={`${style.comment_box_wrapper} view_comment_box_wrapper collapse show mt-0 mb-0`}
          >
            <div>
              <CommentsTop heading="Share your view(s)" />
              <div className={style.selected_employees_wrapper}>
                <div className="view_comment_box_row">
                  <div className={style.employees_inner_wrapper}>
                    <h4>{MonthlyCheckinTexts.EMPLOYEES}</h4>
                  </div>
                </div>
                {commonComment?.map(
                  (commonComment, index) => {
                    return (<>
                      <div className={`${style.employee_names_wrapper} ${style.common_employee_names_wrapper}`}>
                        <div key={`employee${index}`}>{commonComment?.employeeProfile?.displayName}</div>
                      </div>
                      <div className="view_comment_box_row">
                        <div className="comment_box_wrapper fg-comment-wrapper">
                          <div className="user_img"
                            style={{ backgroundColor: backGroundColor[0] }}>
                            {loggedInUser?.displayPicture && loggedInUser?.displayPicture.length > 2 ? (
                              <img alt="DP" src={loggedInUser?.displayPicture} />
                            ) : (loggedInUser?.empInitial)}
                          </div>
                          <div className="comment_area_wrapper">
                            <div>
                              <ul>
                                <li>
                                  <div>
                                    <pre>{commonComment?.managerComment}</pre>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    );
                  }
                )}
              </div>
            </div>
            <div className={style.individual_feedback_wrapper}>
              {individualEmployeeList?.map(
                (individualFeedback, index) => {
                  return (
                    <Fragment key={`individual-feedback${index}`}>
                      <div className={style.message_box_header}>
                        <div className={style.header_wrapper}>
                          <div className={style.text_main_wrapper}>
                            <ReactSVG
                              src={`${IC_LOCK_PRIVACY_2}`}
                              onClick={() => { }}
                            />
                            <span className={style.individual_message_text}>
                              individual message to:
                            </span>
                          </div>
                          <div className={style.employee_names_wrapper}>
                            <div>{individualFeedback?.employeeProfile?.displayName}</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ul>
                          <li>
                            <div>
                              <pre>{individualFeedback?.managerComment}</pre>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Fragment>
                  );
                }
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.modal_footer}>
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_close].join(" ")}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <strong>Close</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RecentCheckInDetailsViewPopup;
