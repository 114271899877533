import React from "react";
import { PieChart, Pie, Label, Tooltip, Cell, ResponsiveContainer } from "recharts";
import style from "./TwoLevelPieChart.module.scss";

const COLORS = {
  Submitted: "#6DD400",
  Lapsed: "#7033FF",
  "Reviewed by Manager": "#FFB200",
  "Pending for Review": "#FD8C61",
  EMPTY: "#F6F5FA",
};

function TwoLevelPieChart({ height, insideData, outsideData, legendData, noDataText }) {
  function CustomLabel({ viewBox }) {
    const { cx, cy } = viewBox;
    return (
      <>
        <text
          x={cx}
          y={cy - 8}
          fill="#928FA0"
          className={["recharts-text recharts-label inner-recharts-label", style.graphText].join("")}
          textAnchor="middle"
          dominantBaseline="central"
        >
          Total
        </text>
        <text
          x={cx}
          y={cy + 8}
          fill="#928FA0"
          className={["recharts-text recharts-label inner-recharts-label", style.graphText].join("")}
          textAnchor="middle"
          dominantBaseline="central"
        >
          OKR Progress
        </text>
      </>
    );
  }

  const CustomTooltip = ({ payload, active }) => {
    if (active && payload[0]?.name !== "EMPTY") {
      let name = payload[0]?.name;
      let checkinCount = payload[0]?.payload?.count;
      let pr = payload[0]?.payload?.value;
      return (
        <div className="recharts-tooltip-custom-wrapper">
          <p className="recharts-tooltip-label">{name}</p>
          <span className="recharts-tooltip-value w-100 d-inline-block">{checkinCount} OKR Progress</span>
          {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
        </div>
      );
    }
    return "";
  };

  return (
    <div className="graph-item">
      {insideData?.length > 0 || outsideData?.length > 0 ? (
        <div style={{ width: "100%", height: height }}>
          <ResponsiveContainer aspect={4.0 / 3.3}>
            <PieChart width={240} height={height}>
              <Pie
                data={insideData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={75}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                <Label width={30} position="center" content={<CustomLabel value1={insideData} />}></Label>
                {insideData?.map((entry, index) => {
                  return <Cell key={index} fill={COLORS[entry["name"]]} />;
                })}
              </Pie>
              <Pie data={outsideData} dataKey="value" innerRadius={85} outerRadius={100} fill="#FFB200">
                {outsideData?.map((entry, index) => {
                  return <Cell key={index} fill={COLORS[entry["name"]]} />;
                })}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="no-data">{noDataText}</div>
      )}
      {insideData && insideData.length > 0 && (
        <div className={style.legend_wrapper}>
          <ul className={style.legend_list} data-test="lenegd_list">
            {legendData.map((entry, index) => {
              return (
                <li className={style.legend_item} key={index}>
                  <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                  {entry["name"]}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default React.memo(TwoLevelPieChart);
