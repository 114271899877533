import React, { useEffect } from "react";
import { ReactSVG } from "react-svg";
import { useSelector, useDispatch } from "react-redux";
import { DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import style from "./TeamsObjectives.module.scss";
import { IC_EXPAND, IC_COLLAPSE, IC_ASSIGN_OBJECTIVE } from "const/imgCost";
import ObjectiveResults from "./ObjectiveResults";

const TeamRows = (props) => {
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => state.objectives);

  useEffect(() => {
    dispatch({ type: "EXPANDED", expanded: null });
  }, [dispatch]);

  const toggleExpander = (id) => {
    expanded === id ? dispatch({ type: "EXPANDED", expanded: null }) : dispatch({ type: "EXPANDED", expanded: id });
  };

  const teamRow = props.teamRow;
  let sum = 0;
  let ans = null;
  if (teamRow.userObjectives && teamRow.userObjectives.length > 0) {
    teamRow.userObjectives
      .sort((a, b) => moment(b.createdDate) - moment(a.createdDate))
      .forEach((value, index, array) => {
        sum += value.percentage;
        ans = (sum / array.length).toFixed();
      });
  }

  return (
    <>
      <li
        className={[
          "team-obj-listing",
          expanded === teamRow.employeeId && style.active,
          expanded === teamRow.employeeId ? "tr_expanded" : "",
        ].join(" ")}
      >
        <div className={style.plus_space} onClick={() => toggleExpander(teamRow.employeeId)}>
          {teamRow.userObjectives.length > 0 &&
            (expanded === teamRow.employeeId ? <ReactSVG src={`${IC_COLLAPSE}`} /> : <ReactSVG src={`${IC_EXPAND}`} />)}
        </div>
        <div className={style.name}>
          <div className={style.user_img} style={{ backgroundColor: props.selectedColor }}>
            {teamRow.dispArr ? <img src={teamRow.dispArr} alt="pic" /> : teamRow.empInitial}
          </div>
          <Link
            to={`/objectives/all-objectives/ID?empId=${teamRow.employeeId}&pgId=${teamRow.parentGroupId}&sgId=${teamRow.subGroupId}`}
          >
            <div className={style.username}>
              <h2>{teamRow.empName}</h2>
              <h3>
                {teamRow.designation} | {teamRow.dept}
              </h3>
            </div>
          </Link>
        </div>
        <div className={style.objective_status}>
          {teamRow.userObjectives.length > 0
            ? teamRow.userObjectives
                .filter((value, index) => index < 5)
                .map((status, index) => {
                  if (status.percentage >= 66)
                    return (
                      <div key={index} className="d-inline-block">
                        <div className={style.green_status}></div>
                      </div>
                    );
                  else if (status.percentage > 33)
                    return (
                      <div key={index} className="d-inline-block">
                        <div className={style.yellow_status}></div>
                      </div>
                    );
                  else
                    return (
                      <div key={index} className="d-inline-block">
                        <div className={style.red_status}></div>
                      </div>
                    );
                })
            : " - "}
        </div>
        <div className={style.status}>
          {teamRow.assigned === "false" && <div className={style.notassigned}>Not Assigned</div>}
        </div>
        <div className={style.progress}>
          <span className={style.percentage}>
            {teamRow.userObjectives && teamRow.userObjectives.length > 0 ? `${ans}%` : "-"}
          </span>
        </div>
        <div className="action-dropdown-wrapper">
          <DropdownButton
            disabled={teamRow?.totalWeightageOfManager >= 100 ? true : false}
            alignRight
            title={
              <div className="action-dropdown">
                <span className="dots"></span>
                <span className="dots"></span>
                <span className="dots"></span>
              </div>
            }
            variant="a"
          >
            <Link
              to={`/objectives/all-objectives?empId=${teamRow.employeeId}&pgId=${teamRow.parentGroupId}&sgId=${teamRow.subGroupId}`}
              style={{ color: "#000" }}
              className="dropdown-item"
            >
              <div className="dropdown_icon">
                <ReactSVG src={`${IC_ASSIGN_OBJECTIVE}`} alt="" />
              </div>
              <div className="dropdown_item">Assign Objective</div>
            </Link>
          </DropdownButton>
        </div>
      </li>
      {expanded === teamRow.employeeId &&
        teamRow.userObjectives &&
        teamRow.userObjectives.map((objective) => (
          <ObjectiveResults
            isPage={props.isPage}
            objective={objective}
            employeeId={teamRow.employeeId}
            employeeBand={teamRow.band}
            key={objective.userObjectiveId}
            deleteObjective={props.deleteObjective}
            loginEmployee={props.loginEmployee}
          />
        ))}
    </>
  );
};
export default TeamRows;
