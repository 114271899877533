import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../store/actions/index";
import style from "./Plans.module.scss";
import PlanBox from "./PlanBox";
import PreviousPlans from "./PreviousPlans";

const Plans = (props) => {
  const [priority, setPriority] = useState("");
  const [flag, setFlag] = useState("");
  const dispatch = useDispatch();
  const { plans, previousPlans, objectives } = useSelector((state) => state.checkIn);

  useEffect(() => {
    if (props.isPage === "checkin" && !props.isPast) dispatch(actions.FetchPlans());
  }, [dispatch, props.managerView]);

  const addPriority = (priority) => {
    if (priority.trim()) {
      dispatch(actions.SetPlan(priority.trim(), props.surveyCycleId));
      setFlag(false);
      setPriority("");
    }
  };

  const handlePlanChange = (plan) => {
    if (plan.length < 200) {
      setPriority(plan);
      dispatch(actions.SetPlan(plan, "ONCHANGE"));
    }
  };

  const deletePlan = (id, index) => {
    dispatch(actions.DeletePlan(id, index));
  };

  const editPlan = (plan, id, index) => {
    if (plan.trim()) dispatch(actions.EditPlan(plan.trim(), id, index));
  };

  const carryForward = (id, index) => {
    dispatch(actions.CarryForwardPlan(id, props.surveyCycleId, index));
  };

  const linkObjective = (planId, objectiveId, description) => {
    dispatch(actions.LinkObjective(planId, objectiveId, description));
  };

  const deleteObjective = (planId, objectiveId) => {
    dispatch(actions.DeselectObjective(props.employeeIds, objectiveId, planId));
  };

  return (
    <div>
      <div className={["box", style.plan_box].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Plans</h2>
            <h3>
              {props.managerView
                ? "You are seeing only those plans which are linked with an objectives"
                : "Writing down plans always help tracking things accurately"}
            </h3>
          </div>
        </div>
        {!props.managerView &&
          previousPlans &&
          previousPlans.length > 0 &&
          previousPlans.filter((value) => !value.carryForwarded).length > 0 && (
            <PreviousPlans
              previousPlans={previousPlans}
              carryForward={carryForward}
              priorityKey={1}
              managerView={props.managerView}
              edit={!props.edit}
            />
          )}

        <div className="box-inner-pad pb-0">
          {!props.managerView && (
            <div className="box_inner_title">
              What do you intend to accomplish between now and your next OKR Progress?<span className="text-danger">*</span>
            </div>
          )}
          {props.edit && (
            <>
              {plans &&
                plans.length > 0 &&
                plans.map((plan, index) => (
                  <PlanBox
                    deleteObjective={deleteObjective}
                    linkObjective={linkObjective}
                    objectives={objectives}
                    key={"PLAN_BOX_" + plan.planId}
                    length={plans.length}
                    plan={plan}
                    index={index}
                    deletePlan={deletePlan}
                    editPlan={editPlan}
                  />
                ))}
              {(flag || plans.length === 0) && (
                <div className={style.plan_textarea_container}>
                  <Form.Group className={style.planWrapper}>
                    <div className="textarea-wrapper ">
                      <Form.Control
                        as="textarea"
                        maxLength="200"
                        className={style.plan_textarea}
                        defaultValue={priority}
                        placeholder="Add a plan"
                        onBlur={(e) => addPriority(e.target.value)}
                        onChange={(e) => handlePlanChange(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                </div>
              )}
              <span
                className={[(plans.length === 0 || flag) && "disabled", "addnewplan_link"].join(" ")}
                onClick={() => setFlag(true)}
              >
                Add new plan
              </span>
            </>
          )}
          {props.managerView && previousPlans.filter((value) => value.objectiveDescription).length > 0 && (
            <div className="box_inner_title pb-4">
              Plans completed from past OKR Progress
              {props.managerView &&
                previousPlans &&
                previousPlans
                  .filter((value) => value.objectiveDescription && !value.carryForwarded)
                  .map((plan) => (
                    <div key={"PLAN_" + plan.planId}>
                      <ul className="common_list_items pt10 text-break">
                        <div className="custom-checkbox custom-checkbox-rounded mb-0 pr-3">
                          <input
                            id={plan.planId}
                            type="checkbox"
                            checked={plan.planStatus === "INCOMPLETE" ? false : true}
                            disabled
                          />

                          <label htmlFor={plan.planId}>
                            <span className="text-break fs-500">{plan.planDescription}</span>
                          </label>
                        </div>
                      </ul>
                      <ul className="list-of-objective text-break pl-4">
                        <li className="pb-0">
                          <span>{plan.objectiveDescription}</span>
                        </li>
                      </ul>
                    </div>
                  ))}
            </div>
          )}
          {props.managerView && plans.filter((value) => value.objectiveDescription).length > 0 && (
            <div className="box_inner_title">Current plans</div>
          )}
          {!props.edit &&
            (!props.managerView
              ? plans &&
                plans.map((plan) => (
                  <div className={style.plan_next_checkin} key={"PLAN_" + plan.planId}>
                    <ul className="common_list_items pt10 text-break pt-1 pb-1">
                      <li className="fs-500 mb-3">{plan.planDescription}</li>
                    </ul>
                    {plan.objectiveDescription && (
                      <ul className="list-of-objective text-break">
                        <li className="pb-0">
                          <span>{plan.objectiveDescription}</span>
                        </li>
                      </ul>
                    )}
                  </div>
                ))
              : plans &&
                plans
                  .filter((value) => value.objectiveDescription)
                  .map((plan) => (
                    <div key={"PLAN_" + plan.planId}>
                      <ul className="common_list_items pt10 text-break">
                        <li className="fs-500">{plan.planDescription}</li>
                      </ul>
                      <ul className="list-of-objective text-break">
                        <li className="pb-0">
                          <span>{plan.objectiveDescription}</span>
                        </li>
                      </ul>
                    </div>
                  )))}
        </div>
      </div>
    </div>
  );
};

export default Plans;
