import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import {
  PENDING_ONE_ON_ONE,
  UNASSIGNED_OBJECTIVES,
  CURRENT_PREVIOUS_CHECKIN,
  TEAMS_CHECKIN,
  MANAGER_PENDING_TODO,
  EMPLOYEE_PENDING_TODO,
  PENDING_OBJECTIVE_APPROVAL,
} from "../../util/endpoints";
import { dateFormat4, getYears } from "../../util/dateUtils";

// manager objective approve
export const getPendingApprovalObjective = () => {
  return (dispatch) => {
    http
      .get(PENDING_OBJECTIVE_APPROVAL)
      .then((response) => response.data)
      .then((response) => {
        if (response?.status === "SUCCESS") {
          dispatch({
            type: actionTypes.SET_PENDING_OBJECTIVE_APPROVAL,
            pendingObjectiveApproval: response.data,
          });
        } else {
          dispatch({
            type: actionTypes.SET_PENDING_OBJECTIVE_APPROVAL,
            pendingObjectiveApproval: null,
          });
        }
      })
      .catch(() => {
        // handle error message
      });
  };
};

//manager employee list for not assign objective
export const getUnassignedObjective = () => {
  return (dispatch) => {
    http
      .get(UNASSIGNED_OBJECTIVES)
      .then((response) => response.data)
      .then((response) => {
        if (response?.status === "SUCCESS") {
          if (response.data.employees.length > 0) {
            dispatch({
              type: actionTypes.SET_UNASSIGNED_OBJECTIVES,
              unassignedObjectiveEmployees: response.data.employees,
            });
          } else {
            dispatch({
              type: actionTypes.SET_UNASSIGNED_OBJECTIVES,
              unassignedObjectiveEmployees: [],
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_UNASSIGNED_OBJECTIVES,
            unassignedObjectiveEmployees: [],
          });
        }
      })
      .catch(() => {
        // handle error message
      });
  };
};

export const getPendingOneOnOne = (month) => {
  return (dispatch) => {
    http
      .get(PENDING_ONE_ON_ONE, { month })
      .then((response) => response.data)
      .then((response) => {
        if (response?.status === "SUCCESS") {
          dispatch({
            type: actionTypes.SET_PENDING_ONE_ON_ONE,
            pendingOneOnOnes: response.data.pendingOneOnOnes || null,
            previousOneOnOnes: response.data.previousOneOnOnes || null,
            upcomingOneOnOnes: response.data.upcomingOneOnOnes || null,
            missedOneOnOnes: response.data.missedOneOnOnes || null,
          });
        } else {
          dispatch({
            type: actionTypes.SET_PENDING_ONE_ON_ONE,
            pendingOneOnOnes: null,
            previousOneOnOnes: null,
            upcomingOneOnOnes: null,
            missedOneOnOnes: null,
          });
        }
      })
      .catch(() => {
        // handle error message
      });
  };
};

export const getTeamsCheckin = (month) => {
  return (dispatch) => {
    http
      .get(TEAMS_CHECKIN, { month })
      .then((response) => response.data)
      .then((response) => {
        if (response?.status === "SUCCESS") {
          if (response.data) {
            let noData =
              response.data.pendingForReview?.count +
                response.data.reviewed?.count +
                response.data?.pendingFromMemeber?.count ===
              0
                ? []
                : false;
            dispatch({
              type: actionTypes.SET_TEAMS_CHECKIN,
              payload: {
                checkinPendingFromEmployees: noData || [
                  {
                    name: "Submitted",
                    order: 1,
                    value: response.data.pendingForReview?.percentage + response.data.reviewed?.percentage,
                    count: response.data.pendingForReview?.count + response.data.reviewed?.count,
                  },
                  {
                    name: "Lapsed",
                    order: 2,
                    value: response.data.pendingFromMemeber?.percentage,
                    count: response.data.pendingFromMemeber?.count,
                  },
                ],
                pendingCheckinReview: noData || [
                  {
                    name: "Pending for Review",
                    order: 1,
                    value: response.data.pendingForReview?.percentage,
                    count: response.data.pendingForReview?.count,
                  },
                  {
                    name: "EMPTY",
                    order: 2,
                    value: 100 - response.data.pendingForReview?.percentage,
                  },
                ],
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_TEAMS_CHECKIN,
            payload: {},
          });
        }
      })
      .catch(() => {
        // handle error message
      });
  };
};

export const getManagerPendingTodo = () => {
  return (dispatch) => {
    http
      .get(MANAGER_PENDING_TODO)
      .then((response) => response.data)
      .then((response) => {
        if (response?.status === "SUCCESS") {
          dispatch({
            type: actionTypes.SET_MANAGER_PENDING,
            payload: {
              pendingRpmAssessment: response.data.rpmAssessment?.count > 0 ? response.data.rpmAssessment : null,
              pendingSelfAssessment: response.data.selfAssessment,
              pendingRvmAssessment: response.data.rvmAssessment?.count > 0 ? response.data.rvmAssessment : null,
              pendingDeallocationFeeedBack: response.data.deallocationFeeedback,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_MANAGER_PENDING,
            payload: {},
          });
        }
      })
      .catch(() => {
        // handle error message
      });
  };
};

export const getEmployeePendingTodo = () => {
  return (dispatch) => {
    http
      .get(EMPLOYEE_PENDING_TODO)
      .then((response) => response.data)
      .then((response) => {
        if (response?.status === "SUCCESS") {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_PENDING,
            pendingWeeklyCheckin: response.data,
          });
        } else {
          dispatch({
            type: actionTypes.SET_EMPLOYEE_PENDING,
            pendingWeeklyCheckin: null,
          });
        }
      })
      .catch(() => {
        // handle error message
      });
  };
};

export const getCurrentAndPreviousCheckin = () => {
  return (dispatch) => {
    http
      .get(CURRENT_PREVIOUS_CHECKIN)
      .then((response) => response.data)
      .then((response) => {
        if (response?.status === "SUCCESS") {
          if (response.data?.length > 0) {
            dispatch({
              type: actionTypes.SET_CURRENT_AND_PREVIOUS_CHECKIN,
              currentAndPreviousCheckin: response.data
                .map((v) => {
                  return {
                    startDate: v.startDate,
                    endDate: v.endDate,
                    graphData: [
                      {
                        Lapsed: v?.currentWeek?.pendingFromMember?.count,
                        "Reviewed by Manager": v?.currentWeek?.reviewed?.count,
                        Submitted: v.currentWeek?.pendingForReview?.count + v.currentWeek?.reviewed?.count,
                        name: `Current##${dateFormat4(v?.currentWeek?.startDate)}-${dateFormat4(
                          v?.currentWeek?.endDate
                        )},${getYears(v?.currentWeek?.endDate)}`,
                        order: 1,
                        lapsedPr: v?.currentWeek?.pendingFromMember?.percentage,
                        reviewedPr: v?.currentWeek?.reviewed?.percentage,
                        submittedPr: v.currentWeek?.pendingForReview?.percentage + v.currentWeek?.reviewed?.percentage,
                      },
                      {
                        Lapsed: v?.previousWeek?.pendingFromMember?.count,
                        "Reviewed by Manager": v?.previousWeek?.reviewed?.count,
                        Submitted: v.previousWeek?.pendingForReview?.count + v.previousWeek?.reviewed?.count,
                        name: `Previous##${dateFormat4(v?.previousWeek?.startDate)}-${dateFormat4(
                          v?.previousWeek?.endDate
                        )},${getYears(v?.previousWeek?.endDate)}`,
                        order: 2,
                        lapsedPr: v?.previousWeek?.pendingFromMember?.percentage,
                        reviewedPr: v?.previousWeek?.reviewed?.percentage,
                        submittedPr:
                          v.previousWeek?.pendingForReview?.percentage + v.previousWeek?.reviewed?.percentage,
                      },
                    ],
                  };
                })
                .reverse(),
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_CURRENT_AND_PREVIOUS_CHECKIN,
            currentAndPreviousCheckin: null,
          });
        }
      })
      .catch(() => {
        // handle error message
      });
  };
};
