import React from "react";
import style from "./PreviewTemplate.module.scss";
import { Form } from "react-bootstrap";
import InputRange from "react-input-range";
import UploadFile from "../../Performance/CycleMemberProfile/QuestionAnswer/UploadFile";

export default function NPSType(props) {
  const { isManagerView } = props;
  let options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let responseText = "There would be employee’s comment over here";

  return (
    <div className={[style.question_list, "box-inner-pad", style.disabledClick].join(" ")}>
      <span className={style.question_sr_no}>Q{props.no + 1}</span>
      <div className={style.que_ans_wrapper}>
        <div className={style.question_name}>
          <h4>
            {props.question.questionName}
            {props.question.isRequired && <span className="text-danger">*</span>}
          </h4>
        </div>
        {props.question.descriptionFlag && (
          <p className={[style.answer_description, "mt-1"].join(" ")}>{props.question.description}</p>
        )}

        <div className={style.ans_wrapper}>
          <div className="ratingBarBox">
            <Form.Group controlId="">
              <div className="custom-range-slider nps-range-slider">
                <InputRange maxValue={10} minValue={0} id={12121213} value={9} />
                <ul className="rating-number">
                  {options.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              </div>
              {isManagerView ? (
                <p className={[style.answer_description, "mt-3"].join(" ")}>{responseText}</p>
              ) : props.question.optionalCommentBox ? (
                <div className="textarea-wrapper mt-3">
                  <UploadFile questionId="QUESTION_ID" uploadFile={() => {}} />
                  <Form.Control
                    as="textarea"
                    maxLength="5000"
                    placeholder={`Add a new answer (${props.question.commentResponse})`}
                  />
                </div>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
