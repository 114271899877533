import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./MyEndorsementRequest.module.scss";
import moment from "moment";
import * as actions from "store/actions/index";
import { IC_INFO } from "const/imgCost";
import { filterArray } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../../../Pagination/Pagination";
import NoDataFound from "../../../../NodataFound/NoDataFound";
import { noData, competencyLevel } from "const/constValue";

export default function MyEndorsementRequest() {
  const dispatch = useDispatch();
  const [totalReq, setTotalReq] = useState([]);
  const { isLoading, size, getPendingDeclineReq } = useSelector((state) => state.skillMatrix);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [typeArr, setFilterType] = useState("ALL");
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useEffect(() => {
    const getReq = () => dispatch(actions.getPendingDeclineRequest);
    getReq();
  }, [dispatch]);

  useEffect(() => {
    if (getPendingDeclineReq && getPendingDeclineReq.length > 0) {
      setTotalReq(getPendingDeclineReq);
      setFilterType("ALL");
    } else {
      setTotalReq([]);
      setFilterType("ALL");
    }
  }, [getPendingDeclineReq]);

  const resetData = (event) => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    if (getPendingDeclineReq && getPendingDeclineReq.length > 0) {
      filteredData = filterArray(getPendingDeclineReq, filters);
      setFilteredData(filteredData);
      setSearchFilter("");
    }
    setFilterType("ALL");
  };

  useEffect(() => {
    filteredData.length > 0
      ? setTotalReq(filteredData)
      : searchFilter
      ? setTotalReq(filteredData)
      : setTotalReq(getPendingDeclineReq.length > 0 && getPendingDeclineReq);
  }, [filteredData, getPendingDeclineReq, searchFilter]);

  const filterByRequest = (e) => {
    const value = e.target.value;
    let array = [];
    if (e.target.checked) {
      setFilterType(value);
      if (value === "PENDING") {
        array = getPendingDeclineReq.filter((req) => req.status.toLowerCase() === "pending");
      } else if (value === "DECLINE") {
        array = getPendingDeclineReq.filter((req) => req.status.toLowerCase() === "decline");
      } else {
        array = getPendingDeclineReq;
      }
    }
    setTotalReq(array);
  };

  useEffect(() => {
    if (totalReq && totalReq.length === 0) {
      setPageOfItems([]);
    }
  }, [totalReq]);

  return (
    <>
      {isLoading && <Spinner />}
      <div className={[style.my_endorsement_req_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>My Requests</h2>
            <h3>My Pending/Declined Requests for Endorsement</h3>
          </div>
          <div className="tab_check_in_status">
            <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="all-1"
                type="radio"
                name="status-check"
                value="ALL"
                checked={typeArr == "ALL"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="all-1">All</label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="g12"
                type="radio"
                name="status-check"
                value="DECLINE"
                checked={typeArr == "DECLINE"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="g12">Declined</label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="g23"
                type="radio"
                name="status-check"
                value="PENDING"
                checked={typeArr == "PENDING"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="g23">Pending</label>
            </div>
          </div>
        </div>
        <div className="box-light-gradient h38"></div>
        {pageOfItems && pageOfItems.length > 0 ? (
          <div className="box-inner-pad pb-0">
            <div className={[style.skill_table, "custom-table", "table-theme-1"].join(" ")}>
              <div className={[style.endorsement_skill_table, "position-relative"].join(" ")}>
                <ul className={style.thead}>
                  <li>
                    <span className={style.skill_name}>Skill Name</span>
                    <span className={style.endorser_name}>Endorse by</span>
                    <span className={[style.cmp_lvl, style.current].join(" ")}>Competency Level</span>
                    <span className={style.req_date}>Request Date</span>
                    <span className={style.status}>Status</span>
                    <span className={style.comments}>Comment</span>
                  </li>
                </ul>
                <ul className={style.tbody}>
                  {pageOfItems &&
                    pageOfItems.length > 0 &&
                    pageOfItems.map((data, index) => {
                      return (
                        <li key={index}>
                          <div className={style.skill_name}>
                            <span>{data.skillIdName}</span>
                          </div>
                          <div className={style.endorser_name}>
                            <span>{data.endorsedByName ? data.endorsedByName : data.endorsedTypeName}</span>
                            {data.endorseRequestByDepartmentName && (
                              <div className={[style.mmg_tooltip, "custom-tooltip", "mmg_table_tooltip"].join(" ")}>
                                <div className="box-info">
                                  <span>
                                    <img className="svg" src={IC_INFO} alt="box-info" />
                                  </span>
                                </div>
                                <div className="info-attach-file">
                                  <p>
                                    Requested by <b>WMG ({data.endorseRequestByName})</b>
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={[style.cmp_lvl, style.current].join(" ")}>
                            <span
                              className={[style.req_comp_level, competencyLevel[data.currentCompetencyLevel]].join(" ")}
                            >
                              {competencyLevel[data.currentCompetencyLevel]}
                            </span>
                          </div>
                          <div className={style.req_date}>
                            <span>{moment(data.requestedDate).format("MMMM D, YYYY")}</span>
                          </div>
                          <div className={style.status}>
                            <div className={[style.cmp_lvl, style.current].join(" ")}>
                              {data.status.toLowerCase() === "pending" && (
                                <span className={[style.req_comp_level, "yellow"].join(" ")}>{data.status}</span>
                              )}
                              {data.status.toLowerCase() === "decline" && (
                                <span className={[style.req_comp_level, "red"].join(" ")}>Declined</span>
                              )}
                            </div>
                          </div>
                          <div className={style.comments}>
                            <div className={style.comment_txt}>
                              {data.comment ? data.comment.slice(0, 15) : "-"}
                              <div className="d-inline-block position-relative">
                                {data.comment && data.comment.trim().length > 15 && (
                                  <span className={style.more}> ...More</span>
                                )}
                                <span className={style.description_tooltip}>{data.comment}</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            <div className="gap120"></div>
          </div>
        )}
      </div>
      {totalReq && totalReq.length !== 0 && (
        <Pagination pageSize={size} items={totalReq} onChangePage={onChangePage} resetData={resetData} />
      )}
    </>
  );
}
