import React from "react";

export default function RoundEmployeeList({
  image,
  selectedColor,
  empName,
  handleEmployeeClick,
  tooltipSubText,
  dates,
}) {
  return (
    <div className="round-employee-list-wrapper">
      <div className="employee-avatar-item" style={{ backgroundColor: selectedColor }} onClick={handleEmployeeClick}>
        {image?.length > 2 ? <img src={image} alt="displayPicture" /> : image}
      </div>
      <div className="employee-avatar-tooltip">
        <div>{empName}</div>
        {dates &&
          dates.map((date, index) => (
            <div key={date + index}>
              {tooltipSubText}
              <span>{date}</span>
            </div>
          ))}
      </div>
    </div>
  );
}

RoundEmployeeList.defaultProps = {
  image: "AB",
  empName: "ABC",
  selectedColor: "#75BA30",
};
