import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "../DeallocationFeedback.module.scss";
import { IC_COLLAPSE, IC_EXPAND, IC_EYE } from "const/imgCost";
import { backGroundColor } from "const/constValue";
import { stringToDateFormat1 } from "util/dateUtils";
import { getShortName } from "util/general";

function HRDeallocationMemberRow({ empInfo, index, handleView }) {
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => state.objectives);
  useEffect(() => {
    dispatch({ type: "EXPANDED", expanded: null });
  }, [dispatch]);

  const toggleExpander = (id) => {
    expanded === id ? dispatch({ type: "EXPANDED", expanded: null }) : dispatch({ type: "EXPANDED", expanded: id });
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>Marked as Non Applicable</Popover.Content>
    </Popover>
  );

  const renderStatus = (status) => {
    switch (status) {
      case "PENDING":
        return <span className={style.status_pending}>Pending</span>;
      case "SUBMIT":
        return <span className={style.status_submitted}>Submitted</span>;
      case "INVALIDATE":
        return (
          <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
            <span className={style.status_submitted}>Submitted</span>
          </OverlayTrigger>
        );
      default:
        return <></>;
    }
  };
  const pathName = `/hr/feedback/deallocation-feedback/${empInfo.projectDeAllocationId}?empId=${empInfo.employee?.employeeId}&pgId=${empInfo.employee?.parentGroupId}&sgId=${empInfo.employee?.subGroupId}&managerEmpId=${empInfo.projectManager?.employeeId}&managerPgId=${empInfo.projectManager?.parentGroupId}&managerSgId=${empInfo.projectManager?.subGroupId}`;
  return (
    <>
      <li className={style.list_item}>
        <div className={style.plus_space} onClick={() => toggleExpander(empInfo.projectDeAllocationId)}>
          {empInfo.other ? (
            expanded === empInfo.projectDeAllocationId ? (
              <ReactSVG src={`${IC_COLLAPSE}`} />
            ) : (
              <ReactSVG src={`${IC_EXPAND}`} />
            )
          ) : (
            ""
          )}
        </div>
        <div className={style.tab_team_member_info}>
          <div className={style.team_member_info}>
            <div
              className={style.team_member_img}
              style={{
                backgroundColor: backGroundColor[index % backGroundColor.length],
              }}
            >
              {empInfo.employee.displayPicture ? (
                <img src={empInfo.employee.displayPicture} alt={empInfo.employee.empInitial} />
              ) : (
                empInfo.employee.empInitial
              )}
            </div>
            <div className={style.team_member_details}>
              <div className={style.team_member_name}>{empInfo.employee.empName}</div>
              <div className={style.team_member_designation}>
                {empInfo.employee.designation} | {empInfo.employee.department}
              </div>
            </div>
          </div>
        </div>

        <div className={[style.tab_manager_name, style.managerTooltip].join(" ")}>
          <span>{empInfo.projectManager?.empName && getShortName(empInfo.projectManager?.empName)}</span>
          <div className={style.managerTooltipInfo}>{empInfo.projectManager?.empName}</div>
        </div>
        <div className={style.tab_project_name}>
          <div className={style.project_name}>
            {empInfo.projectName?.length > 0 && empInfo.projectName.map((v) => v.projectName).join(", ")}
          </div>
          <div className={style.duration}>
            {empInfo.projectName?.length > 0 &&
              empInfo.projectName.map((v) => v.projectWorkingDays).join(", ") + " Days"}
          </div>
        </div>
        <div className={style.tab_location}>{empInfo.location}</div>
        <div className={style.tab_date}>
          {empInfo.projectName?.length > 0 &&
            empInfo.projectName[empInfo.projectName.length - 1].deallocationDate &&
            stringToDateFormat1(empInfo.projectName[empInfo.projectName.length - 1].deallocationDate)}
        </div>
        <div className={style.st_wrapper}>{renderStatus(empInfo.status)}</div>
        {empInfo.status === "SUBMIT" ? (
          <Link to={pathName} className={style.action_wrapper}>
            <ReactSVG src={`${IC_EYE}`} />
          </Link>
        ) : (
          <div className={style.action_wrapper} onClick={() => handleView(empInfo)}>
            <ReactSVG src={`${IC_EYE}`} />
          </div>
        )}
      </li>
      {/* if one employee deallocate from two time or more time in same project then below part work */}
      {expanded === empInfo.projectDeAllocationId &&
        empInfo.other &&
        empInfo.other.map((emp) => (
          <li className={style.internal_list_item} key={emp.projectDeAllocationId}>
            <div className={style.plus_space}></div>
            <div className={style.tab_team_member_info}></div>
            <div className={[style.tab_manager_name, style.managerTooltip].join(" ")}>
              <span>{emp.projectManager?.empName && getShortName(emp.projectManager?.empName)}</span>
              <div className={style.managerTooltipInfo}>{emp.projectManager?.empName}</div>
            </div>
            <div className={style.tab_project_name}>
              <div className={style.project_name}>
                {emp.projectName?.length > 0 && emp.projectName.map((v) => v.projectName).join(", ")}
              </div>
              <div className={style.duration}>
                {emp.projectName?.length > 0 && emp.projectName.map((v) => v.projectWorkingDays).join(", ") + " Days"}
              </div>
            </div>
            <div className={style.tab_location}>{emp.location}</div>
            <div className={style.tab_date}>
              {emp.projectName?.length > 0 &&
                emp.projectName[emp.projectName?.length - 1].deallocationDate &&
                stringToDateFormat1(emp.projectName[emp.projectName?.length - 1].deallocationDate)}
            </div>
            <div className={style.st_wrapper}>{renderStatus(emp.status)}</div>
            {emp.status === "SUBMIT" ? (
              <Link
                to={`/hr/feedback/deallocation-feedback/${emp.employeeId}&${emp.projectDeAllocationId}&${emp.managerId}`}
                className={style.action_wrapper}
              >
                <ReactSVG src={`${IC_EYE}`} />
              </Link>
            ) : (
              <div className={style.action_wrapper} onClick={() => handleView(emp)}>
                <ReactSVG src={`${IC_EYE}`} />
              </div>
            )}
          </li>
        ))}
    </>
  );
}

export default HRDeallocationMemberRow;
