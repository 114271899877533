import React from "react";
import Select from "react-select";
import { MY_LOGO } from "../../../../const/imgCost";
import CommentsTop from "../../../CommentBox/CommentsTop";
import CommentBox from "../../../Performance/SelfAssessment/Components/CommentBox";
import style from "./../PreviewTemplate.module.scss";

export default function ScaledType(props) {
  let ratingName = props.question.scaleObj && props.question.scaleObj.name.split("/");
  let ratingValue = props.question.scaleObj?.value?.split(",");
  const options = [];
  if (ratingName?.length > 0) {
    ratingName.map((v, i) => {
      const obj = {
        value: ratingValue[i],
        label: v,
      };
      options.push(obj);
      return v;
    });
  }
  let responseText = "There would be employee’s comment over here";

  return (
    <div className={[style.question_list, "box-inner-pad"].join(" ")}>
      <span className={style.question_sr_no}>Q{props.no + 1}</span>
      <div className={style.que_ans_wrapper}>
        <div className={style.question_name}>
          <h4>
            {props.question.questionName}
            {props.question.isRequired && <span className="text-danger">*</span>}
          </h4>
        </div>
        {props.question.descriptionFlag && (
          <p className={[style.answer_description, "mt-1"].join(" ")}>{props.question.description}</p>
        )}
        <p className={[style.answer_description, "mt-3"].join(" ")}>{responseText}</p>
        <div className={style.ans_wrapper}>
          <div className="card template-rating">
            <div className="card-footer">
              <div className="card-ft-wrapper">
                <div className="reporting-wrapper">
                  <span>Rate this question by selecting a reasonable value</span>
                </div>
                <div className="card_action">
                  <Select
                    options={options}
                    className="custom-default-dd-box"
                    classNamePrefix="select"
                    isSearchable={false}
                    onChange={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={["view_comment_box_wrapper"].join(" ")}>
            <CommentsTop heading="Share your view(s)" />
            <CommentBox
              selectedColor="white"
              error={false}
              blurHandler={() => {}}
              image={MY_LOGO}
              commentLength={5000}
              handleMultipleFileUpload={() => {}}
              isMultipleFile={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
