import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import style from "../EmployeesObjectives.module.scss";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ObjectiveResults from "../../../Objectives/TeamsObjectives/ObjectiveResults";
import { IC_EXPAND, IC_COLLAPSE } from "../../../../const/imgCost";
import { getRandomColor } from "../../../../util/color";

const EmployeeRow = (props) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => state.objectives);

  useEffect(() => {
    setSelectedColor(getRandomColor());
    dispatch({ type: "EXPANDED", expanded: null });
  }, [dispatch]);

  const toggleExpander = (id) => {
    expanded === id ? dispatch({ type: "EXPANDED", expanded: null }) : dispatch({ type: "EXPANDED", expanded: id });
  };

  const teamRow = props.teamRow;
  let sum = 0;
  let ans = null;
  if (teamRow.userObjectives && teamRow.userObjectives.length > 0) {
    teamRow.userObjectives
      .sort((a, b) => moment(b.createdDate) - moment(a.createdDate))
      .forEach((value, index, array) => {
        sum += value.percentage;
        ans = (sum / array.length).toFixed();
      });
  }

  let rpm = props.teamRow.projectReportingManagers;
  let rvm = props.teamRow.projectReviewingManagers;

  return (
    <>
      <li
        className={[
          "team-obj-listing",
          expanded === teamRow.employeeId && style.active,
          expanded === teamRow.employeeId ? "tr_expanded" : "",
        ].join(" ")}
      >
        <div className={style.plus_space} onClick={() => toggleExpander(teamRow.employeeId)}>
          {teamRow.userObjectives.length > 0 &&
            (expanded === teamRow.employeeId ? <ReactSVG src={IC_COLLAPSE} /> : <ReactSVG src={IC_EXPAND} />)}
        </div>
        <div className={style.name}>
          <div className={style.user_img} style={{ backgroundColor: selectedColor }}>
            {teamRow.dispArr?.length > 2 ? <img src={teamRow.dispArr} alt="pic" /> : teamRow.empInitial}
          </div>
          <div className={style.username}>
            <h2>{teamRow.empName}</h2>
            <h3>
              {teamRow.designation} | {teamRow.dept}
            </h3>
          </div>
        </div>
        <div className={style.objective_status}>
          {teamRow.userObjectives.length > 0
            ? teamRow.userObjectives
                .filter((value, index) => index < 5)
                .map((status, index) => {
                  if (status.percentage >= 66)
                    return (
                      <div key={index} className="d-inline-block">
                        <div className={style.green_status}></div>
                      </div>
                    );
                  else if (status.percentage > 33)
                    return (
                      <div key={index} className="d-inline-block">
                        <div className={style.yellow_status}></div>
                      </div>
                    );
                  else
                    return (
                      <div key={index} className="d-inline-block">
                        <div className={style.red_status}></div>
                      </div>
                    );
                })
            : " - "}
        </div>
        <div className={[style.objective_status, style.managerTooltip].join(" ")}>
          <span>{rpm && rpm.join(", ").length > 13 ? rpm.join(", ").slice(0, 13) + "..." : rpm.join(", ")}</span>
          <div className={style.managerTooltipInfo}>{rpm.join(", ")}</div>
        </div>
        <div className={[style.objective_status, style.managerTooltip].join(" ")}>
          <span>{rvm && rvm.join(", ").length > 13 ? rvm.join(", ").slice(0, 13) + "..." : rvm.join(", ")}</span>
          <div className={style.managerTooltipInfo}>{rvm.join(", ")}</div>
        </div>
        <div className={style.status}>
          {teamRow.assigned === "true" ? (
            <div className={style.assigned}>Assigned</div>
          ) : (
            <div className={style.notassigned}>Not Assigned</div>
          )}
        </div>
        <div className={style.progress}>
          <span className={style.percentage}>
            {teamRow.userObjectives && teamRow.userObjectives.length > 0 ? `${ans}%` : "-"}
          </span>
        </div>
      </li>
      {expanded === teamRow.employeeId &&
        teamRow.userObjectives &&
        teamRow.userObjectives.map((objective) => (
          <ObjectiveResults
            isPage={props.isPage}
            objective={objective}
            employeeId={teamRow.employeeId}
            key={objective.userObjectiveId}
            deleteObjective={props.deleteObjective}
            loginEmployee={props.loginEmployee}
          />
        ))}
    </>
  );
};
export default EmployeeRow;
