import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import style from "./AddSkillModal.module.scss";

const AddSkillModal = (props) => {
  const [skill, setSkill] = useState(null);
  const handleSkill = (e) => {
    setSkill(e.target.value);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.hide()}
        size="md"
        animation={false}
        className="custom_modal_popup view_certificate_modal"
      >
        <div className="custom_modal_container custom_lg_modal_container">
          <Modal.Header className={style.mHeader}>
            <Modal.Title>
              Add New Skill
              <span>{props.category} </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="box-light-gradient h38"></div>
            <div className={style.category_add_wrapper}>
              <Form.Group controlId="">
                <Form.Label className={style.dd_title}>Skill Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Skill Name (E.g. Java, Photoshop, HTML, etc…)"
                  value={skill || ""}
                  onChange={handleSkill}
                  maxLength="100"
                ></Form.Control>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button
              variant="primary"
              className="fs-500"
              onClick={() => props.hide(skill)}
              disabled={skill && skill.trim() ? false : true}
            >
              Add
            </Button>
            <Button variant="secondary" className="fs-500 ml-3" onClick={() => props.hideModal()}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default AddSkillModal;
