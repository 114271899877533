import React, { useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import style from "./MemberProfileObjecive.module.scss";
import { CANCEL_ROUND, IC_ACC_ARROW_DOWN, IC_EDIT_OBJECTIVE, IC_FLAG, SUBMIT_ROUND } from "const/imgCost";
import { endOfDay } from "util/dateUtils";
import { objectiveStatus, flagCheck, ratingValue, newRatingValue, oneToFourRatingValue, errorToast } from "util/general";
import { maximumFiveFile, maximumFileSize } from "util/general";
import { backGroundColor } from "const/constValue";
import { newRatingOptions, ratingOptions, oneToFourRatingOptions } from "const/options";
import * as actions from "store/actions/index";
import Comment from "../../../CommentBox/Comment";
import KeyResultTable from "../../../KeyResultTable/KeyResultTable";
import CommentsTop from "../../../CommentBox/CommentsTop";
import CommentBox from "../../SelfAssessment/Components/CommentBox";
import UniversityKeyResult from "../../../UniversityKeyResult/UniversityKeyResult";
import MultipleFile from "../../../MultipleFileUpload/MultipleFile";
import StartAndEndDatePicker from "../../CreatePerformanceCycle/StartAndEndDatePicker";
import CommentWithAttachment from "../../../CommentBox/CommentWithAttachment";

export default function ObjectiveHTML(props) {
  let { objective, loggedInUser, isPage } = props;
  const dispatch = useDispatch();
  const [viewMoreFlag, setViewMoreFlag] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isChange, setIsChange] = useState(false);
  const [editTimeLine, setEditTimeLine] = useState(false);
  const objectivePrivateAssessmentId = props.privateAssessmentData.objectivePrivateAssessmentId;
  const isSameUser = props.manager.employeeId === loggedInUser?.employeeId ? true : false;
  let isEditRating = true;

  //first condition => if reporting manager assign objective then other reporting manager can't see rating
  //second condition => when one reporting manager change rating of old manager then other reporting manager can't see rating(compare by updatedBy in objective)
  //Third condition => when other manager completed objective in 100% time then he/she not able see rating
  if (
    isPage === "REPORTING" &&
    !isSameUser &&
    (props.allManagerDetails?.find(
      (v) => v.type === "REPORTING" && v.employeeId === objective.managerData?.employeeId
    ) ||
      (objective.updatedByManager &&
        (props.allManagerDetails.find((v) => v.employeeId === objective.updatedByManager.employeeId)
          ? loggedInUser.employeeId !== objective.updatedByManager.employeeId
          : false)) ||
      objective.status === "COMPLETED") //as per BACKEND TEAM if past manager change rating then we should provide enable rating to reporting manager
  ) {
    isEditRating = false;
  }
  let status = "";
  let _class = "";
  let isUniversityObjective = objective.definedBy === "UNIVERSITY" ? true : false;
  if (objective.percentage >= 66) {
    status = <div>On&nbsp;Track</div>;
    _class = style.step1;
  } else if (objective.percentage < 66 && objective.percentage > 33) {
    status = <div>Needs&nbsp;Attention</div>;
    _class = style.step2;
  } else if (objective.percentage <= 33) {
    status = <div>Running&nbsp;Behind</div>;
    _class = style.step3;
  }

  let commentVisible = true;
  if (!props.isPrivateAssessmentSubmit) {
    if (
      isPage === "REPORTING" &&
      !isSameUser &&
      (props.allManagerDetails?.find(
        (v) => v.type === "REPORTING" && v.employeeId === objective.managerData?.employeeId
      ) ||
        (objective.updatedByManager &&
          (props.allManagerDetails.find((v) => v.employeeId === objective.updatedByManager.employeeId)
            ? loggedInUser.employeeId !== objective.updatedByManager.employeeId
            : false)) ||
        objective.status === "COMPLETED")
    )
      commentVisible = false;
    else commentVisible = true;
  } else if (
    isPage === "REPORTING" &&
    !isSameUser &&
    (props.allManagerDetails?.find(
      (v) => v.type === "REPORTING" && v.employeeId === objective.managerData?.employeeId
    ) ||
      (objective.updatedByManager &&
        (props.allManagerDetails.find((v) => v.employeeId === objective.updatedByManager.employeeId)
          ? loggedInUser.employeeId !== objective.updatedByManager.employeeId
          : false)) ||
      objective.status === "COMPLETED")
  ) {
    commentVisible = false;
  }

  const handleKeyRating = (value, keyId) => {
    dispatch(
      actions.SavePrivateAssessmentObjectiveRating({
        privateAssessmentType: isPage,
        type: isUniversityObjective ? "university_objective" : "teams_objective",
        rating: value.value,
        privateAssessmentId: props.privateAssessmentData.privateAssessmentId,
        userObjectiveId: objective.userObjectiveId,
        keyId: keyId,
        employeeIds: props.privateAssessmentData.employeeData,
        cycleId: props.privateAssessmentData.cycleId,
      })
    );
  };

  const assessmentComment = (e) => {
    let comment = e.target.value.trim();
    dispatch(
      actions.SetAssessmentComment(
        comment,
        loggedInUser,
        props.privateAssessmentData.privateAssessmentId,
        objective.userObjectiveId,
        isPage,
        props.privateAssessmentData.cycleId,
        props.employeeData,
        loggedInUser?.employeeId
      )
    );
    if (isPage === "REPORTING") {
      //assessment comment required only for reporting manager
      let newObjective = props.assessmentError.map((value) => {
        if (value.commentId === objective.userObjectiveId) {
          value.comment = e.target.value.trim();
        }
        return value;
      });
      props.setAssessmentError(newObjective);
    }
  };

  //Objective Files
  const handleMultipleFileUpload = (e) => {
    const formData = new FormData();
    let array = [];
    let i = e.target.files.length;
    let totalUserFiles = objective.files?.filter((v) => v.assessmentType === isPage).length || 0;
    if (i <= 5 && totalUserFiles + i <= 5) {
      while (i !== 0) {
        if (e.target.files[i - 1].size > 2097152) {
          maximumFileSize();
          return;
        }
        formData.append("files", e.target.files[i - 1]);
        array.push({ name: e.target.files[i - 1].name });
        i--;
      }
      formData.append("objectivePrivateAssessmentId", objectivePrivateAssessmentId);
      formData.append("userObjectiveId", objective.userObjectiveId);
      formData.append("quarterlyCycleId", props.privateAssessmentData.cycleId);
      formData.append("assessmentType", isPage);
      dispatch(
        actions.UploadFileObjectiveAssessment(
          formData,
          props.privateAssessmentData?.employeeData,
          props.privateAssessmentData.cycleId,
          isPage
        )
      );
    } else maximumFiveFile();
  };

  //get current Quarter start and end date from HR CYCLE Creation
  const getStartDate = () => {
    let qStartDate =
      props.privateAssessmentData.quarterStartDate && new Date(props.privateAssessmentData.quarterStartDate);
    return qStartDate;
  };
  const getEndDate = () => {
    let qEndDate = props.privateAssessmentData.quarterEndDate && new Date(props.privateAssessmentData.quarterEndDate);
    return qEndDate;
  };

  const handleSubmitTimeline = () => {
    const qstartDate = getStartDate();
    const qendDate = getEndDate();
    if ((startDate < qstartDate && endDate < qstartDate) || startDate > qendDate) {
      errorToast("Date range should fall in current quarter.");
    } else {
      setEditTimeLine(false);
      let body = {
        userObjectiveId: objective.userObjectiveId,
        privateAssessmentId: props.privateAssessmentData.privateAssessmentId,
        startDate: startDate || undefined,
        endDate: endOfDay(endDate),
      };
      dispatch(actions.SavePrivateAssessmentObjectiveDate(body, isPage));
    }
  };

  const handleCancelTimeline = () => {
    setStartDate();
    setEndDate();
    setEditTimeLine(false);
  };

  const downloadFile = (id) => {
    const params = {
      userObjectiveId: objective.userObjectiveId,
      quarterlyCycleId: props.privateAssessmentData.cycleId,
      fileId: id,
    };
    dispatch(actions.DownloadFileObjectiveAssessment(params));
  };

  const removeFile = (id) => {
    const body = {
      userObjectiveId: objective.userObjectiveId,
      quarterlyCycleId: props.privateAssessmentData.cycleId,
      fileId: id,
    };
    dispatch(
      actions.DeleteFilObjectiveAssessment(
        { data: body },
        props.privateAssessmentData?.employeeData,
        props.privateAssessmentData?.cycleId,
        isPage
      )
    );
  };

  let comment = "";
  if (isPage === "REVIEWING" && objective.reviewingAssessmentComment) {
    if (Array.isArray(objective.reviewingAssessmentComment)) {
      if (objective.reviewingAssessmentComment.length > 0) {
        objective.reviewingAssessmentComment.map((value) => {
          if (value.commentedBy.employeeId === loggedInUser?.employeeId) {
            comment = value.comment || "";
          }
          return value;
        });
      }
    }
  } else if (isPage === "REPORTING" && objective.reportingAssessmentComment) {
    comment = objective.reportingAssessmentComment.comment || "";
  }

  return (
    <Card key={objective.objectiveId}>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          key={objective.userObjectiveId}
          eventKey={`${props.index}`}
          className={!isChange && props.index === 0 ? "show" : props.eventValue === objective.userObjectiveId && "show"}
          onClick={(e) => {
            setIsChange(true);
            props.setEventValue(objective.userObjectiveId);
            if (e.currentTarget.classList.contains("show")) {
              e.currentTarget.classList.remove("show");
            } else {
              e.currentTarget.classList.add("show");
            }
          }}
        >
          <div className="accordion-heading">
            <div className="accordion-heading-left">
              <div className={style.idp_process_indicate}>
                <div className={style.idp_step}>
                  <div className="box-info">
                    <div className={[style.step, _class, style.active].join(" ")}></div>
                    <div className={["info-attach-file", "emp_objective_tooltip"].join(" ")}>
                      <div className="row">
                        <div className="col">
                          <div>{status}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.idp_heading_wrapper}>
                <h3>{objective.title}</h3>
              </div>
            </div>
            <div className="accordion-heading-right">
              {props.hrView && objective.flagged ? (
                <span className={style.flag}>
                  <img src={IC_FLAG} alt="flag" />
                </span>
              ) : null}
              <div className={style.idp_process_complete}>{objective.percentage}%</div>
              <div className={style.idp_end_days}>{objectiveStatus(objective.status)}</div>
            </div>
          </div>
          <div className="accordion-icon">
            <ReactSVG src={IC_ACC_ARROW_DOWN} />
          </div>
        </Accordion.Toggle>
        <div className={style.key_initiative_wrapper}>
          <span className={style.key_result}>{objective.keyPoints.length} Key Results aligned</span>
          <span
            className={style.initiative_wrapper}
            onClick={() => {
              if (objective.plans && objective.plans.length > 0) {
                props.setIsModel(!props.isModal);
                props.setUserObjective(objective);
              } else return false;
            }}
          >
            {objective.plans?.length || 0} Initiatives Found
          </span>
        </div>
        <div className={style.timeframe_wrapper}>
          {!editTimeLine && (
            <div className={style.timeframe_info}>
              <span>Timeframe : </span>
              <>
                &nbsp;&nbsp;
                {moment(startDate || objective.startDate).format("MMM DD, YYYY") +
                  " to " +
                  moment(endDate || objective.endDate).format("MMM DD, YYYY")}
              </>
              {commentVisible &&
                objective.status !== "COMPLETED" &&
                !props.isPrivateAssessmentSubmit && ( //date change work same as rating part
                  <div className={style.edit_button} onClick={() => setEditTimeLine(true)}>
                    <ReactSVG src={IC_EDIT_OBJECTIVE} alt="" />
                  </div>
                )}
            </div>
          )}
          {editTimeLine && (
            <div className={style.timeframe_edit_box}>
              <div className={style.timeframe}>Timeframe :</div>
              <div className={style.date_picker_wrapper}>
                <div className="calendar_outer_wrapper form-group">
                  <StartAndEndDatePicker
                    startDate={startDate || new Date(objective.startDate)}
                    endDate={endDate || new Date(objective.endDate)}
                    handleStartDate={setStartDate}
                    handleEndDate={setEndDate}
                  />
                </div>
                <div className={style.btn_group_wrapper}>
                  <div className={style.btn_round} onClick={handleSubmitTimeline}>
                    <ReactSVG src={SUBMIT_ROUND} alt="" />
                  </div>
                  <div className={style.btn_round} onClick={handleCancelTimeline}>
                    <ReactSVG src={CANCEL_ROUND} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {objective?.weightage && <div className={style.timeframe_info}>
              <span>&nbsp;|&nbsp; Weightage : </span>
              <>&nbsp;&nbsp; {objective?.weightage}%</>
            </div>}
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={`${props.index}`}>
        <Card.Body className="pl-0">
          <div className={style.idp_info_list}>
            {!isUniversityObjective && (
              <KeyResultTable
                isHideRatingDropDown={props.isHideRatingDropDown}
                isDisabled={props.isDisabledRating}
                objectiveError={props.objectiveError}
                keypoints={objective.keyPoints}
                edit={isEditRating}
                isOldRatingOption={props?.isOldRatingOption}
                isOneToFourRatings={props?.isOneToFourRatings}
                handleKeyRating={handleKeyRating}
                clickCommentModal={(point) => {
                  props.setResultModal(true);
                  props.setkeyPoint(point);
                  props.setObjective(objective);
                }}
              />
            )}
            {isUniversityObjective && <UniversityKeyResult keyPoints={objective.keyPoints} />}
          </div>
        </Card.Body>
      </Accordion.Collapse>
      {isUniversityObjective && isEditRating && (
        !props.isHideRatingDropDown &&
        <Card.Footer>
          <div className="card-ft-wrapper">
            <span>Rate this progress by selecting a reasonable value</span>
            <div className="card_action">
              <Select
                options={props?.isOldRatingOption ? ratingOptions : props?.isOneToFourRatings ? oneToFourRatingOptions : newRatingOptions}
                className={["custom-default-dd-box", props.objectiveError && "error-box"].join(" ")}
                classNamePrefix="select"
                isSearchable={false}
                defaultValue={props?.isOldRatingOption ? ratingValue(objective.ratingRemarks) : props?.isOneToFourRatings ? oneToFourRatingValue(objective.ratingRemarks) : newRatingValue(objective.ratingRemarks)}
                isDisabled={props.isDisabledRating}
                onChange={(value) => {
                  dispatch(
                    actions.SavePrivateAssessmentObjectiveRating({
                      privateAssessmentType: isPage,
                      type: "university_objective",
                      rating: value.value,
                      privateAssessmentId: props.privateAssessmentData.privateAssessmentId,
                      userObjectiveId: objective.userObjectiveId,
                      employeeId: props.privateAssessmentData.employeeData,
                      cycleId: props.privateAssessmentData.cycleId,
                      flagged: flagCheck(objective.percentage, value.value),
                    })
                  );
                }}
              />
            </div>
          </div>
        </Card.Footer>
      )}
      <div>
        {commentVisible && (
          <div className={["view_comment_box_wrapper", props.isPrivateAssessmentSubmit && "bg-white"].join(" ")}>
            <CommentsTop heading="Share your view(s)" />
            {/* Assessment Comment(s) START */}
            <div className={"assess_prev_comment_wrapper"}>
              <h5>Assessment Comment(s)</h5>
              {objective.selfAssessmentComment &&
                (props.privateAssessmentData?.selfAssessmentStatus?.status === "Submit" ||
                  isPage === "EMPLOYEE" ||
                  props.hrView) && (
                  <CommentWithAttachment
                    selectedColor={backGroundColor[0]}
                    image={objective.selfAssessmentComment?.commentedBy?.displayPicture}
                    empName={objective.selfAssessmentComment?.commentedBy?.empName}
                    commentedOn={objective.selfAssessmentComment?.commentedOn}
                    comment={objective.selfAssessmentComment?.comment}
                    files={objective.files?.filter((v) => v.assessmentType === "SELF")}
                    downloadFile={downloadFile}
                  />
                )}

              {/* This below comment is deallocation time comment and 100% completed time comment */}
              {objective.objectiveComment && objective.objectiveComment.trim() && (
                <Comment
                  selectedColor={backGroundColor[6]}
                  deletable={false}
                  image={objective.managerData?.dispArr || objective.managerData?.empInitial}
                  empName={objective.managerData?.empName}
                  comment={objective.objectiveComment}
                  commentedOn={objective.completionDate || new Date()}
                  isPrivate={false}
                />
              )}
              {objective.reportingAssessmentComment &&
                ((isPage === "REPORTING" && props.isPrivateAssessmentSubmit) ||
                  isPage === "REVIEWING" ||
                  isPage === "EMPLOYEE" ||
                  props.hrView) && (
                  <CommentWithAttachment
                    selectedColor={backGroundColor[1]}
                    image={objective.reportingAssessmentComment?.commentedBy?.displayPicture}
                    empName={objective.reportingAssessmentComment?.commentedBy?.empName}
                    commentedOn={objective.reportingAssessmentComment?.commentedOn}
                    comment={objective.reportingAssessmentComment?.comment}
                    files={objective.files?.filter((v) => v.assessmentType === "REPORTING")}
                    downloadFile={downloadFile}
                  />
                )}

              {Array.isArray(objective.reviewingAssessmentComment) &&
                objective.reviewingAssessmentComment.length > 0 &&
                ((isPage === "REVIEWING" && props.isPrivateAssessmentSubmit) ||
                  isPage === "EMPLOYEE" ||
                  isPage === "REPORTING" ||
                  props.hrView
                  ? objective.reviewingAssessmentComment.map((comment, i) => (
                    <CommentWithAttachment
                      key={i}
                      selectedColor={backGroundColor[2]}
                      image={comment?.commentedBy?.displayPicture}
                      empName={comment?.commentedBy?.empName}
                      commentedOn={comment?.commentedOn}
                      comment={comment?.comment}
                      files={objective.files?.filter(
                        (v) =>
                          v.assessmentType === "REVIEWING" &&
                          comment?.commentedBy?.employeeId === v.createdBy?.employeeId
                      )}
                      downloadFile={downloadFile}
                    />
                  ))
                  : objective.reviewingAssessmentComment.filter(
                    (v) => v.commentedBy.employeeId !== loggedInUser?.employeeId
                  )?.length > 0 &&
                  objective.reviewingAssessmentComment
                    .filter((v) => v.commentedBy.employeeId !== loggedInUser?.employeeId)
                    .map((comment, i) => (
                      <CommentWithAttachment
                        key={i}
                        selectedColor={backGroundColor[2]}
                        image={comment?.commentedBy?.displayPicture}
                        empName={comment?.commentedBy?.empName}
                        commentedOn={comment?.commentedOn}
                        comment={comment?.comment}
                        files={objective.files?.filter(
                          (v) =>
                            v.assessmentType === "REVIEWING" &&
                            comment?.commentedBy?.employeeId === v.createdBy?.employeeId
                        )}
                        downloadFile={downloadFile}
                      />
                    )))}
            </div>
            {/* Assessment Comment(s) END */}

            {/* Previous Comment(s) start */}
            {objective.comment && viewMoreFlag && (
              <div className="assess_prev_comment_wrapper">
                <h5>Previous Comment(s)</h5>
                {objective.comment
                  .filter((answer) => {
                    if (answer.whoCanView && answer.whoCanView.length === 0) {
                      return true;
                    } else {
                      if (answer.private) {
                        if (answer.commentedBy.employeeId === loggedInUser?.employeeId) {
                          return true;
                        } else if (answer.whoCanView && answer.whoCanView[0].employeeId === loggedInUser?.employeeId) {
                          return true;
                        } else {
                          return false;
                        }
                      } else {
                        return true;
                      }
                    }
                  })
                  .filter(() => viewMoreFlag)
                  .map((answer, index) => {
                    return (
                      <Comment
                        selectedColor={backGroundColor[4]}
                        key={index}
                        deletable={false}
                        image={answer.commentedBy?.displayPicture}
                        empName={answer.commentedBy?.empName}
                        commentedOn={answer.commentedOn}
                        comment={answer.comment}
                        isPrivate={answer.private}
                        privateMsg={`Private message, only visible to you and
                                ${
                                  loggedInUser?.employeeId === answer.commentedBy.employeeId
                            ? ` ${answer.whoCanView?.length > 0 && answer.whoCanView[0].empName}`
                            : ` ${answer.commentedBy?.empName}`
                          }`}
                      />
                    );
                  })}
              </div>
            )}
            {/* Previous Comment(s) End */}
            {!props.isPrivateAssessmentSubmit && (
              <>
                <CommentBox
                  selectedColor={backGroundColor[5]}
                  error={props.assessmentError?.find(
                    (value) => value.commentId === objective.userObjectiveId && value.isSubmit && !value.comment
                  )}
                  blurHandler={assessmentComment}
                  image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                  comment={comment}
                  commentLength={1000}
                  handleMultipleFileUpload={handleMultipleFileUpload}
                  isMultipleFile={objective.files?.filter((v) => v.assessmentType === isPage).length > 4 ? false : true}
                />
                {objective.files?.filter(
                  (v) => v.assessmentType === isPage && v.createdBy?.employeeId === loggedInUser?.employeeId
                ).length > 0 && (
                    <div className="multiple_uploaded_file">
                      <MultipleFile
                        isDownloadable={true}
                        isDeletable={true}
                        downloadFile={downloadFile}
                        files={objective.files?.filter(
                          (v) => v.assessmentType === isPage && v.createdBy?.employeeId === loggedInUser?.employeeId
                        )}
                        removeFile={removeFile}
                      />
                    </div>
                  )}
              </>
            )}

            {objective.comment && objective.comment.length > 0 && (
              <div className={style.view_all_objective_comment}>
                <span onClick={() => setViewMoreFlag(!viewMoreFlag)}>{viewMoreFlag ? "View Less" : "View More"}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}
