/* eslint-disable no-script-url */
import React, { useState } from "react";
import Select from "react-select";
import { Col, Row, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import style from "../WinsChallenges.module.scss";
import { IC_CALENDAR, IC_INFO, IC_WINS } from "const/imgCost";
import { monthOptions } from "const/options";
import { dateFormat1 } from "util/dateUtils";
import SingleComponent from "../SingleComponent";
import HideAndShow from "../../HideAndShow/HideAndShow";
import config from "util/config";

const Wins = (props) => {
  const [hide, setHide] = useState(false);

  return (
    <div className={["card", style.win_challenges].join(" ")}>
      <div className="card_info">
        <div className="card_title">
          <div className="d-flex">
            <ReactSVG src={`${IC_WINS}`} className="icon-svg" />
            <div className="card_title_text">Wins</div>
          </div>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>
      <Collapse in={!hide}>
        <div className="card_details">
          <div className="box-light-gradient"></div>
          <div className={style.to_do_row}>
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <div className="card_action float-right mr-3">
                  <Select
                    onChange={(select) => props.filter(select.value)}
                    options={monthOptions}
                    className="select_dropdown select_dropdown_text"
                    classNamePrefix="select"
                    isSearchable={false}
                    defaultValue={{
                      value: 3,
                      label: "Last 3 Months",
                    }}
                  />
                </div>
              </Col>
            </Row>

            <div className={style.wc_data}>
              <ul
                data-draggable="target"
                id="WIN"
                className={
                  !props.winsBucket || (props.winsBucket && props.winsBucket.length === 0 ? "heightAuto" : undefined)
                }
              >
                {!props.winsBucket ||
                  (props.winsBucket && props.winsBucket.length === 0 && (
                    <li className="no-record-wc">No wins have been added.</li>
                  ))}
                {props.winsBucket &&
                  props.winsBucket.length > 0 &&
                  props.winsBucket.map((win) => {
                    return (
                      <li key={"WIN_" + win.winAndChallengeId} id={win.winAndChallengeId}>
                        {win.comment.length < 250 ? (
                          <div className="wc-text">{win.comment}</div>
                        ) : (
                          <SingleComponent id={win.winAndChallengeId} comment={win.comment} />
                        )}
                        <span className={style.ic_dot}></span>
                        <div className={style.data_date}>
                          <Link
                            to={
                              props.isPage === "myteam"
                                ? {
                                    pathname: `/okrprogress/my-team/member-okrprogress/${
                                      props.employeeId +
                                      config.employeeIdSplit +
                                      props.parentGroupId +
                                      config.employeeIdSplit +
                                      props.subGroupId
                                    }`,
                                    state: win.surveyCyclesId,
                                  }
                                : {
                                    pathname: `/okrprogress/my-history`,
                                    state: win.surveyCyclesId,
                                  }
                            }
                          >
                            {dateFormat1(win.surveyCyclesStartDate) + " - " + dateFormat1(win.surveyCyclesEndDate)}
                          </Link>
                          <div className="custom-tooltip custom-tooltip-top ml-2">
                            <div className="box-info">
                              <span>
                                <ReactSVG src={`${IC_INFO}`} />
                              </span>
                            </div>
                            <div className="info-attach-file">
                              <Row>
                                <Col>
                                  <div className="tTitle">Created&nbsp;On</div>
                                  <div className="tDateBox">
                                    <div className="tCalendar">
                                      <img src={`${IC_CALENDAR}`} title="" alt="Calendar Icon" />
                                    </div>
                                    <div className="tDate">{dateFormat1(win?.createdDate)}</div>
                                  </div>
                                </Col>
                                {win.movedDate && (
                                  <Col>
                                    <div className="tTitle">Moved&nbsp;On</div>
                                    <div className="tDateBox">
                                      <div className="tCalendar">
                                        <img src={`${IC_CALENDAR}`} title="" alt="Calendar Icon" />
                                      </div>
                                      <div className="tDate">{dateFormat1(win?.movedDate)}</div>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default Wins;
