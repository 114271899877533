import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import React from "react";
import CampaignList from "./CampaignList";
import style from "./CampaignList.module.scss";

function CampaignListForAdmin() {
  return (
    <div className={style.campaign_list_wrapper}>
      <SubNavigation type="Campaign Details" />
      <CampaignList />
    </div>
  );
}

export default CampaignListForAdmin;
