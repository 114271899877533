import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import style from "./AddScaleModal.module.scss";

const AddPreviewModal = (props) => {
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} size="lg" className="custom_modal_popup">
        <div className={[style.scale_modal, "custom_modal_container"].join(" ")}>
          <Modal.Header className={style.mHeader}>
            <Modal.Title> Scale </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="form-group">
              <label className={style.dd_title}>Name</label>
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  value={props.currentSelectedScale ? props.currentSelectedScale.scaleDesc : ""}
                ></Form.Control>               
            </div>
            <div className="form-group">
              <label className={style.dd_title}>Scale Options <span className="text-muted">(Enter slash(/) to seperate every value)</span></label>
              <div className="textarea-wrapper">
                <Form.Control
                  as="textarea"
                  placeholder="Enter slash(/) to seperate every value"
                  value={props.currentSelectedScale ? props.currentSelectedScale.name: ""}></Form.Control>               
              </div>
            </div>
            <div className="form-group">
              <label className={style.dd_title}>Scale Values <span className="text-muted">(Enter comma(,) to seperate every value)</span></label>
              <div className="textarea-wrapper">
                <Form.Control
                  as="textarea"
                  placeholder="Enter comma(,) to seperate every value"
                  value={props.currentSelectedScale ? props.currentSelectedScale.value: ""}></Form.Control>               
              </div>
            </div>
            <div className="form-group">
              <label className={style.dd_title}>Preview</label>
              { props.currentSelectedScale && props.currentSelectedScale.name.split("/").map((obj, i) => {
                  return (
                      <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm no_mar" key={i}>
                          <input id={i} type="radio" />
                          <label htmlFor={i}>{obj}</label>
                      </div>
                  )
              })}
            </div>
          </Modal.Body>

          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={() => props.hide()}>Close</Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default AddPreviewModal;
