import React from "react";

const AuthContext = React.createContext({
  isLoggedIn: false,
  user: null,
  isManager: false,
  draftSavedDate: null,
  onLogin: () => {},
  onLogout: () => {},
  navigation: [],
  pageView: [],
});

export const ActionType = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  DRAFTDSAVEDDATE: "DRAFTDSAVEDDATE",
  CHANGE_PAGE: "CHANGE_PAGE",
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        user: action.payload.user,
        isLoggedIn: action.payload.isLoggedIn,
        navigation: action.payload.navigation,
      };
    case ActionType.LOGOUT:
      return {
        user: null,
        isLoggedIn: false,
      };
    case ActionType.DRAFTDSAVEDDATE:
      return {
        ...state,
        draftSavedDate: action.payload,
      };
    case ActionType.CHANGE_PAGE:
      return {
        ...state,
        navigation: action.payload,
      };
    default:
      return state;
  }
};

export default AuthContext;
