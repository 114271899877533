import Axios from "axios";
import endpoints from "./endpoints";

class Http {
  url = process.env.REACT_APP_API_BASE_URL;
  isMockEnabled = process.env.REACT_APP_MOCK === "true" ? true : false;

  get(endpoint, params, config) {
    return Axios.get(this.getURL(endpoint, params), config);
  }

  post(endpoint, params, data, config) {
    if (this.isMockEnabled) {
      return Axios.get(this.getURL(endpoint, params), data, config);
    }
    return Axios.post(this.getURL(endpoint, params), data, config);
  }

  put(endpoint, params, data, config) {
    if (this.isMockEnabled) {
      return Axios.get(this.getURL(endpoint, params), data, config);
    }
    return Axios.put(this.getURL(endpoint, params), data, config);
  }

  patch(endpoint, params, data, config) {
    if (this.isMockEnabled) {
      return Axios.get(this.getURL(endpoint, params), data, config);
    }
    return Axios.patch(this.getURL(endpoint, params), data, config);
  }

  delete(endpoint, data, config, params) {
    if (this.isMockEnabled) {
      return Axios.get(this.getURL(endpoint, params), data, config);
    }
    return Axios.delete(this.getURL(endpoint, params), data, config);
  }

  request(config) {
    return Axios.request(config);
  }

  getURL(endpoint, params) {
    if (this.isMockEnabled) {
      return `/mocks/${endpoints.MOCK_ENDPOINTS[endpoint]}`;
    } else {
      return this.getParams(endpoints.API_ENDPOINTS[endpoint], params);
    }
  }

  getParams(url, params) {
    for (let key in params) {
      url = url.replace(new RegExp("\\{" + key + "\\}", "gm"), params[key]);
    }

    return this.url + url;
  }
}

export default new Http();
