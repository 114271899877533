import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import Spinner from "shared/Spinner/Spinner";
import { IC_CLOSE, IC_DOCUMENT, IC_ZOOM_IN, IC_ZOOM_OUT } from "const/imgCost";
import style from "./PDFViewerPopup.module.scss";

const PDFViewerPopup = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInnerLoader, setIsInnerLoader] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageScale, setPageScale] = useState(1.2);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const handleInnerLoader = () => {
    setTimeout(() => {
      setIsInnerLoader(false);
    }, 1000);
  };

  const handleZoomIn = () => {
    if (pageScale < 3) {
      setIsInnerLoader(true);
      setPageScale(pageScale + 0.1);
      handleInnerLoader();
    }
  };

  const handleZoomOut = () => {
    if (pageScale > 0.3) {
      setIsInnerLoader(true);
      setPageScale(pageScale - 0.1);
      handleInnerLoader();
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={true}
        onHide={props.handleClose}
        animation={false}
        backdrop="static"
        className={style.pdfviewer_wrapper}
      >
        <div className={style.modal_header}>
          <div className="row bg-black">
            <div className={`col-12 ${style.modal_header_wrapper}`}>
              <div className={style.pdf_title_wrapper}>
                <img src={IC_DOCUMENT} alt="document" />
                <h1>{props.pdfTitle}</h1>
              </div>
              <div className={style.pdf_header_controls}>
                <span onClick={handleZoomIn}>
                  <img src={IC_ZOOM_IN} alt="zoom-in" />
                </span>
                <span onClick={handleZoomOut}>
                  <img src={IC_ZOOM_OUT} alt="zoom-out" />
                </span>
                <span onClick={props.handleClose}>
                  <img src={IC_CLOSE} alt="close" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body className={style.model_body_wrapper}>
          <div className="box-light-gradient"></div>
          <div className="col-xl-12 p-0">
            <div className={style.pdf_content_wrapper}>
              {isLoading && <Spinner />}
              <Document
                file={props.pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Spinner />}
              >
                {isInnerLoader && <Spinner />}
                {numPages &&
                  !isLoading &&
                  Array.from({ length: numPages }, (val, index) => {
                    return (
                      <Page
                        pageNumber={index + 1}
                        width={900}
                        scale={pageScale}
                        renderMode="svg"
                      />
                    );
                  })}
              </Document>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PDFViewerPopup;
