import { btnName, noData } from "const/constValue";
import React, { useState, useEffect } from "react";
import style from "./EmployeeQuizResultCharts.module.scss";
import QuizPieAndBarChartSubGroup from "./QuizPieAndBarChartSubGroup";
import QuizPieAndBarChartLocation from "./QuizPieAndBarChartLocation";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { getStringArray, successToast } from "util/general";
import NoDataFound from "components/NodataFound/NoDataFound";

export default function EmployeeQuizResultCharts(props) {
  const dispatch = useDispatch();
  const { campaignFilterSearch } = useSelector((state) => state.campaign);

  const { startDate, endDate, filterMemberType, filterStatus, filterSubGroups, filterLocation } = campaignFilterSearch;

  useEffect(() => {
    if (props?.quizResultGraphData?.locationWiseChartList?.length > 7) {
      let param = {
        campaignId: props?.campaignId,
      };

      let body = {
        employeeType: filterMemberType ? getStringArray(filterMemberType, "label") : null,
        status: filterStatus ? filterStatus.value : null,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        subGroups: filterSubGroups ? getStringArray(filterSubGroups, "value") : null,
        locations: filterLocation ? getStringArray(filterLocation, "label") : null,
        offSet: "",
        pageNumber: "",
      };
      dispatch(actions.getCountryList(param, body));
    }
  }, [props?.quizResultGraphData, campaignFilterSearch]);

  const handleExport = () => {
    successToast("Your files would be downloaded in background");
    let param = {
      campaignId: props?.campaignId,
    };

    let body = {
      employeeType: filterMemberType ? getStringArray(filterMemberType, "label") : null,
      status: filterStatus ? filterStatus.value : null,
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      subGroups: filterSubGroups ? getStringArray(filterSubGroups, "value") : null,
      locations: filterLocation ? getStringArray(filterLocation, "label") : null,
      offSet: "10",
      pageNumber: "1",
    };
    dispatch(actions.getEmployeeQuizResultReport(param, body));
  };

  return (
    <div className={["box", style.quiz_graph_wrapper].join(" ")}>
      <div className={style.quiz_graph_header}>
        <h2>GRAPHICAL VIEW</h2>
        <button type="button" className="btn btn-secondary date-range-apply-btn" onClick={handleExport}>
          <strong>{btnName.EXPORT}</strong>
        </button>
      </div>
      <div className="box-light-gradient"></div>
      {props?.quizResultGraphData !== null ? (
        <>
          <div className={style.sub_group_wrapper}>
            <div className={style.quiz_sub_header}>Sub Group</div>
          </div>

          <div className="box-inner-pad">
            {props?.quizResultGraphData ? (
              <QuizPieAndBarChartSubGroup quizResultData={props?.quizResultGraphData} />
            ) : (
              <div className="no-record-found-wrpr mt-3">No Data Available</div>
            )}
          </div>
          <div className="box-light-gradient"></div>
          <div className={style.sub_group_wrapper}>
            <div className={style.quiz_sub_header}>Result Status</div>
          </div>
          <div className="box-inner-pad pb-0">
            {props?.quizResultGraphData ? (
              <QuizPieAndBarChartLocation quizResultData={props?.quizResultGraphData} />
            ) : (
              <div className="no-record-found-wrpr mt-3">No Data Available</div>
            )}
          </div>
        </>
      ) : (
        <>
          <NoDataFound header={noData.NO_RECORD_FOUND} description={noData.DATA_IS_NOT_YET_FOUND} />
          <div className="gap80"></div>
        </>
      )}
    </div>
  );
}
