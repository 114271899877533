import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Filter(props) {
  return (
    <div className="tab_filter">
      <Row>
        <Col xs={12} sm={12} md={6} lg={8} xl={8}>
          <div className="tab_check_in_status">
            <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="all"
                type="radio"
                name="status-check"
                value={props.filter.value[0]}
                onChange={props.onRadioChange}
                defaultChecked
                checked={props.statusFilter === props.filter.value[0]}
              />
              <label htmlFor="all">{props.filter.name[0]}</label>
            </div>

            <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="g1"
                type="radio"
                name="status-check"
                value={props.filter.value[1]}
                onChange={props.onRadioChange}
                checked={props.statusFilter === props.filter.value[1]}
              />
              <label htmlFor="g1">{props.filter.name[1]}</label>
            </div>

            <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="g2"
                type="radio"
                name="status-check"
                value={props.filter.value[2]}
                onChange={props.onRadioChange}
                checked={props.statusFilter === props.filter.value[2]}
              />
              <label htmlFor="g2">{props.filter.name[2]}</label>
            </div>

            {props.filter.name[3] && (
              <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                <input
                  id="g3"
                  type="radio"
                  name="status-check"
                  value={props.filter.value[3]}
                  onChange={props.onRadioChange}
                  checked={props.statusFilter === props.filter.value[3]}
                />
                <label htmlFor="g3">{props.filter.name[3]}</label>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
