import React from "react";
import style from "./AssigneePopup.module.scss";
import { backGroundColor } from "../../../../const/constValue";

export default function TeamRow(props) {
  const {
    empName,
    dispArr,
    empInitial,
    designation,
    band,
    totalWeightageOfManager
  } = props.team;

  return (
    <tr>
      <td>
        <div
          className={style.user_img}
          style={{ backgroundColor: backGroundColor[props.index % backGroundColor.length] }}
        >
          {dispArr ? <img src={dispArr} alt="pic" /> : empInitial}
        </div>
        <div className={style.user_name}>
          <h1>{empName}</h1>
          <h2>{designation}</h2>
        </div>
      </td>
      <td>{band}</td>
      <td className={style.check_totalWeightageOfManager}>
        <span >
          {totalWeightageOfManager}
        </span>
      </td>
      <td>{100 - totalWeightageOfManager}</td>
    </tr>
  );
}
