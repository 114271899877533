import React from "react";
import style from "../TabHighlights.module.scss";
import { IC_CLOCK } from "../../../const/imgCost";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { dayDiff } from "../../../util/dateUtils";

export default function ObjectiveRow({ objective }) {
  const { title, endDate, percentage, progressStatus } = objective;
  let progressDot = <span></span>;
  if (progressStatus) {
    if (progressStatus === "GREEN") progressDot = <span className={["green", style.status].join(" ")}></span>;
    else if (progressStatus === "ORANGE") progressDot = <span className={["yellow", style.status].join(" ")}></span>;
    else progressDot = <span className={["red", style.status].join(" ")}></span>;
  }
  return (
    <div className={style.objectiveBox}>
      <div className={style.objectiveLeft}>
        {progressDot}
        <h5 className={style.name}>{title}</h5>
      </div>
      <div className={style.objectiveRight}>
        <div className={style.duration}>
          <ReactSVG className="img" src={`${IC_CLOCK}`} />
          <span className={style.text}> {`${dayDiff(endDate)} Days`}</span>
        </div>
        <span className={style.percentage}>{percentage}%</span>
      </div>
    </div>
  );
}

ObjectiveRow.propTypes = {
  objective: PropTypes.shape({
    title: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
};

ObjectiveRow.defaultProps = {
  objective: {
    title: "",
    endDate: "",
    percentage: 0,
  },
};
