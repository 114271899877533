import React from "react";
import { Button, Form } from "react-bootstrap";
import { IC_INPUT_SEARCH } from "const/imgCost";

function SearchBox({ placeholder, value, onSearch, onResetSearch }) {
  let className = "form-control-rounded form-search-item";
  if (value.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }
  return (
    <Form>
      <Form.Group controlId="" className="form-postfix mb-0">
        <Form.Control
          size="sm"
          value={value}
          type="text"
          placeholder={placeholder}
          name="searchBox"
          className={className}
          onChange={onSearch}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        <Button className="close-button" type="reset" onClick={onResetSearch}>
          <span className="close-icon"></span>
        </Button>
        <div className="form-postfix-icon">
          <img src={IC_INPUT_SEARCH} title="" alt="" />
        </div>
      </Form.Group>
    </Form>
  );
}

export default React.memo(SearchBox);
