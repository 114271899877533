import React, { useEffect, useState } from "react";
import { Collapse, Row, Col } from "react-bootstrap";
import style from "./CampaignQuizEmployeePerformance.module.scss";
import QuestionTemplate from "components/Performance/CycleMemberProfile/QuestionTemplate/QuestionTemplate";
import HideAndShow from "components/HideAndShow/HideAndShow";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/index";
import Spinner from "shared/Spinner/Spinner";
import storage from "util/storage";
const CampaignQuizEmployeePerformance = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(false);
  const { hrViewQuizResult, wrongAnswersForHr, isHrViewEmployeeQuizResultLoading } = useSelector(
    (state) => state.campaign
  );
  const { campaignId, attemptNumber, employeeId, subGroupId, parentGroupId } =
    storage.getJSON("quizHrViewEmployeeData");
  useEffect(() => {
    if ((campaignId, attemptNumber, employeeId, subGroupId, parentGroupId)) {
      dispatch(actions.getQuizResultForHr(campaignId, attemptNumber, employeeId, subGroupId, parentGroupId));
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      storage.remove("quizHrViewEmployeeData");
    };
  }, []);

  const wrongAnswerHandler = (e, inCorrectAnswer) => {
    if (inCorrectAnswer > 0) {
      if ((campaignId, attemptNumber, employeeId, subGroupId, parentGroupId)) {
        dispatch(actions.getWrongAnswersForHr(campaignId, attemptNumber, employeeId, subGroupId, parentGroupId));
      }
      setShow(true);
    } else {
      e.preventDefault();
    }
  };
  return (
    <>
      <div className={style.grade_info_card}>
        {isHrViewEmployeeQuizResultLoading && <Spinner />}
        {hrViewQuizResult &&
          hrViewQuizResult?.attemptResultList?.length > 0 &&
          hrViewQuizResult?.attemptResultList?.map((item) => (
            <Row>
              <Col lg={12}>
                <div className="card">
                  <div className="card_info">
                    <div className="card_title">
                      <div className={[style.info_tooltip, "custom-tooltip", "custom-tooltip-top"].join(" ")}>
                        <div>Employee Performance</div>
                      </div>
                    </div>
                    <HideAndShow hide={hide} setHide={setHide} />
                  </div>
                  <Collapse in={!hide}>
                    <div className="card_details">
                      <div className={["box-light-gradient", style.gradient_size].join(" ")}></div>
                      <div className={style.grade_collection}>
                        <div className={item.result === "Pass" ? style.grade_Result_Pass : style.grade_Result_Fail}>
                          <div className={style.cardIcon}>
                            {item.result === "Pass" ? (
                              <svg
                                width="56"
                                height="56"
                                viewBox="0 0 56 56"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="28" cy="28" r="28" fill="white" fill-opacity="0.5" />
                                <circle
                                  cx="28"
                                  cy="28"
                                  r="27"
                                  stroke="#6DD400"
                                  stroke-opacity="0.66"
                                  stroke-width="2"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M40.9181 16.5463C41.7024 17.2852 41.7024 18.4975 41.1142 19.2549L25.037 38.4236C24.8409 38.8092 24.4488 39.0194 23.8606 38.9986C23.4684 38.9801 23.0763 38.7353 22.6842 38.322L15.8219 28.7388C15.2338 27.9306 15.4298 26.7206 16.018 26.0371C16.8023 25.3535 17.7826 25.4528 18.3708 26.2611L24.0566 34.237L38.7614 16.5763C39.3496 15.8212 40.3299 15.805 40.9181 16.5463Z"
                                  fill="#6DD400"
                                  stroke="#6DD400"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="56"
                                height="56"
                                viewBox="0 0 56 56"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="28"
                                  cy="28"
                                  r="27"
                                  fill="white"
                                  fill-opacity="0.65"
                                  stroke="#F07575"
                                  stroke-width="2"
                                />
                                <path
                                  d="M29.2071 30.2709L28.5 29.5638L27.7929 30.2709L22.1394 35.9244C21.8456 36.2182 21.3692 36.2182 21.0754 35.9244C20.7816 35.6306 20.7816 35.1542 21.0754 34.8604L26.7289 29.2069L27.436 28.4998L26.7289 27.7927L21.0754 22.1392C20.7816 21.8454 20.7816 21.369 21.0754 21.0752C21.3692 20.7813 21.8456 20.7813 22.1394 21.0752L27.7929 26.7286L28.5 27.4358L29.2071 26.7286L34.8606 21.0752C35.1544 20.7813 35.6308 20.7813 35.9246 21.0752C36.2184 21.369 36.2184 21.8454 35.9246 22.1392L30.2711 27.7927L29.564 28.4998L30.2711 29.2069L35.9246 34.8604C36.2184 35.1542 36.2184 35.6306 35.9246 35.9244C35.6308 36.2182 35.1544 36.2182 34.8606 35.9244L29.2071 30.2709Z"
                                  fill="white"
                                  fill-opacity="0.65"
                                  stroke="#F07575"
                                  stroke-width="2"
                                />
                              </svg>
                            )}
                          </div>
                          <div className={style.cardText}> Result</div>
                          <div>{item.result}</div>
                        </div>
                        <div className={style.grade_Score}>
                          <div className={style.cardIcon}>
                            <svg
                              width="56"
                              height="56"
                              viewBox="0 0 56 56"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="28" cy="28" r="28" fill="white" fill-opacity="0.8" />
                              <circle cx="28" cy="28" r="27" stroke="#B8B4CC" stroke-opacity="0.66" stroke-width="2" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20.4639 18.1499C20.4639 17.514 20.8795 17 21.5723 17H35.4277C35.9819 17 36.5361 17.514 36.5361 18.1499V19.4453C38.4759 19.9552 40 21.7563 40 23.8996C40 26.3117 38.0602 28.2887 35.7048 28.4841C34.8735 30.2976 33.3494 31.7385 31.5482 32.5019L32.7952 37.6988H34.1807C34.8735 37.6988 35.4277 38.2141 35.4277 38.8487C35.4277 39.4846 34.8735 40 34.1807 40H22.6807C22.1265 40 21.5723 39.4846 21.5723 38.8487C21.5723 38.2141 22.1265 37.6988 22.6807 37.6988H24.0663L25.4518 32.5019C23.512 31.7385 21.988 30.2976 21.1566 28.4841C18.8012 28.2887 17 26.3117 17 23.8996C17 21.7563 18.3855 19.9552 20.4639 19.4453V18.1499ZM20.4639 25.0495C20.4639 25.3432 20.4639 25.6328 20.4639 25.9182C19.7711 25.5275 19.2169 24.7697 19.2169 23.8996C19.2169 23.0489 19.7711 22.3049 20.4639 21.9073V25.0495ZM36.5361 25.9182C37.2289 25.5275 37.6446 24.7697 37.6446 23.8996C37.6446 23.0489 37.2289 22.3049 36.5361 21.9073V25.0495C36.5361 25.3432 36.5361 25.6328 36.5361 25.9182ZM29.3313 33.0589L30.4398 37.6988H26.5602L27.6687 33.0589C27.9458 33.0852 28.2229 33.099 28.5 33.099C28.7771 33.099 29.0542 33.0852 29.3313 33.0589ZM22.6807 19.2999V25.0495C22.6807 28.225 25.3133 30.7992 28.5 30.7992C31.6867 30.7992 34.1807 28.225 34.1807 25.0495V19.2999H22.6807ZM28.5 21.5997C27.253 21.5997 26.1446 22.6291 26.1446 23.8996C26.1446 25.17 27.253 26.1994 28.5 26.1994C29.747 26.1994 30.7169 25.17 30.7169 23.8996C30.7169 22.6291 29.747 21.5997 28.5 21.5997Z"
                                fill="#B8B4CC"
                              />
                            </svg>
                          </div>
                          <div className={style.cardText}>Score</div>
                          <div>{item.score}%</div>
                        </div>
                        <div className={style.grade_correctAnswers}>
                          <div className={style.cardIcon}>
                            <svg
                              width="56"
                              height="56"
                              viewBox="0 0 56 56"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="28"
                                cy="28"
                                r="27"
                                fill="white"
                                fill-opacity="0.698672"
                                stroke="#01B7EA"
                                stroke-width="2"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16 28C16 34.6361 21.3494 40 28 40C34.6506 40 40 34.6361 40 28C40 21.3783 34.6506 16 28 16C21.3494 16 16 21.3783 16 28ZM18.4578 28C18.4578 22.7084 22.6506 18.4 28 18.4C33.3494 18.4 37.5422 22.7084 37.5422 28C37.5422 33.306 33.3494 37.6 28 37.6C22.6506 37.6 18.4578 33.306 18.4578 28ZM24.3855 29.2C24.3855 28.5494 23.8072 28 23.2289 28C22.506 28 21.9277 28.5494 21.9277 29.2C21.9277 32.5253 24.6747 35.2 28 35.2C31.3253 35.2 33.9277 32.5253 33.9277 29.2C33.9277 28.5494 33.494 28 32.7711 28C32.1928 28 31.6145 28.5494 31.6145 29.2C31.6145 31.1952 30.0241 32.8 28 32.8C25.9759 32.8 24.3855 31.1952 24.3855 29.2ZM23.2289 25.6C23.8072 25.6 24.3855 25.0651 24.3855 24.4C24.3855 25.0651 24.9639 25.6 25.5422 25.6C26.2651 25.6 26.8434 25.0651 26.8434 24.4C26.8434 23.0843 25.6867 22 24.3855 22C23.0843 22 21.9277 23.0843 21.9277 24.4C21.9277 25.0651 22.506 25.6 23.2289 25.6ZM31.6145 24.4C31.6145 25.0651 31.0361 25.6 30.4578 25.6C29.7349 25.6 29.1566 25.0651 29.1566 24.4C29.1566 23.0843 30.3133 22 31.6145 22C32.9157 22 33.9277 23.0843 33.9277 24.4C33.9277 25.0651 33.494 25.6 32.7711 25.6C32.1928 25.6 31.6145 25.0651 31.6145 24.4Z"
                                fill="#01B7EA"
                              />
                            </svg>
                          </div>
                          <div className={style.cardText}>Correct Answers</div>
                          <div>{item.correctAnswer}</div>
                        </div>
                        <div
                          className={style.grade_wrongAnswer}
                          onClick={(e) => wrongAnswerHandler(e, item.inCorrectAnswer)}
                        >
                          <div className={style.cardIcon}>
                            <svg
                              width="56"
                              height="56"
                              viewBox="0 0 56 56"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="28"
                                cy="28"
                                r="27"
                                fill="white"
                                fill-opacity="0.8"
                                stroke="#FF9066"
                                stroke-width="2"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16 28C16 34.6361 21.3494 40 28 40C34.6506 40 40 34.6361 40 28C40 21.3783 34.6506 16 28 16C21.3494 16 16 21.3783 16 28ZM18.4578 28C18.4578 22.7084 22.6506 18.4 28 18.4C33.3494 18.4 37.5422 22.7084 37.5422 28C37.5422 33.306 33.3494 37.6 28 37.6C22.6506 37.6 18.4578 33.306 18.4578 28ZM31.4699 33C31.4699 33.5422 32.0482 34 32.6265 34C33.3494 34 33.9277 33.5422 33.9277 33C33.9277 30.2289 31.1807 28 27.8554 28C24.5301 28 21.9277 30.2289 21.9277 33C21.9277 33.5422 22.3614 34 23.0843 34C23.6627 34 24.241 33.5422 24.241 33C24.241 31.3373 25.8313 30 27.8554 30C29.8795 30 31.4699 31.3373 31.4699 33ZM23.2289 25.6C23.8072 25.6 24.3855 25.0651 24.3855 24.4C24.3855 25.0651 24.9639 25.6 25.5422 25.6C26.2651 25.6 26.8434 25.0651 26.8434 24.4C26.8434 23.0843 25.6867 22 24.3855 22C23.0843 22 21.9277 23.0843 21.9277 24.4C21.9277 25.0651 22.506 25.6 23.2289 25.6ZM31.6145 24.4C31.6145 25.0651 31.0361 25.6 30.4578 25.6C29.7349 25.6 29.1566 25.0651 29.1566 24.4C29.1566 23.0843 30.3133 22 31.6145 22C32.9157 22 33.9277 23.0843 33.9277 24.4C33.9277 25.0651 33.494 25.6 32.7711 25.6C32.1928 25.6 31.6145 25.0651 31.6145 24.4Z"
                                fill="#FF9066"
                              />
                            </svg>
                          </div>
                          <div className={style.cardText}>Wrong Answers</div>
                          <div>{item.inCorrectAnswer}</div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </Row>
          ))}
      </div>
      {show === true && (
        <>
          <span className={style.QuestionTemplate}>
            <div className="box">
              <div className="box-heading-wrapper box-heading">
                <div className="box-heading">
                  <h2>Quiz Template</h2>
                  <h3>view the answers of the following questions</h3>
                </div>
              </div>
              <div className="box-light-gradient"></div>
              <QuestionTemplate
                handleTextBox={() => {}}
                handleRating={() => {}}
                handleRatingComment={() => {}}
                handleRadioComment={() => {}}
                handleRadio={() => {}}
                handleRadioOther={() => {}}
                handleCheckBox={() => {}}
                handleCheckBoxOther={() => {}}
                handleCheckBoxComment={() => {}}
                handleDropDown={() => {}}
                handleDropDownComment={() => {}}
                handleDropDownOther={() => {}}
                handleScale={() => {}}
                handleScaleComment={() => {}}
                handleRankComment={() => {}}
                handleRank={() => {}}
                handleNps={() => {}}
                handleNpsComment={() => {}}
                // employeeId={employeeIds.employeeId}
                // questionTemplateErrors={questionTemplateErrors}
                isSubmitted={true}
                questionsAnswerTemplate={wrongAnswersForHr}
                uploadFile={() => {}}
                viewTemplateName={true}
                deleteFile={() => {}}
              />
            </div>
          </span>
        </>
      )}
      <Row>
        <Col lg={12}>
          <div className="box">
            <button type="button" className={style.backButton} onClick={() => history.goBack()}>
              <strong>Back</strong>
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default CampaignQuizEmployeePerformance;
