import React, { useEffect, useState } from "react";
import { Container, Row, Button, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import style from "./TeamsFeedBack.module.scss";
import * as actions from "store/actions/index";
import { filterArray } from "util/general";
import { getColorByIndex } from "util/color";
import { IC_INPUT_SEARCH, IC_COLLAPSE, IC_EXPAND } from "const/imgCost";
import { dateFormat1 } from "util/dateUtils";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";
import ViewMoreText from "../../ViewMoreText/ViewMoreText";
import { noData } from "const/constValue";

const TeamsFeedBack = () => {
  const { isLoading, teamFeedbackList } = useSelector((state) => state.oneOnOne);
  const dispatch = useDispatch();
  const [feedbackData, setFeedbackData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageOfItems, setPageOfItems] = useState([]);
  const [open, setOpen] = useState({ flag: false, id: null });
  const [filterData, setFilteredData] = useState([]);
  const [toggle, setToggle] = useState("");

  useEffect(() => {
    setFeedbackData(teamFeedbackList);
  }, [teamFeedbackList]);

  useEffect(() => {
    const getTeamFeedbackApiCall = () => {
      dispatch(actions.getTeamFeedback);
    };
    getTeamFeedbackApiCall();
  }, [dispatch]);

  useEffect(() => {
    let filterTeamList = [];
    if (searchText && searchText.length > 0) {
      if (searchText.startsWith("#")) {
        const queryStr = searchText.substring(1).trim();
        filterTeamList = teamFeedbackList.filter((data) =>
          data.empName.toLowerCase().includes(queryStr.toLowerCase().trim())
        );
      } else {
        filterTeamList = teamFeedbackList.filter(
          (data) =>
            data.empName.toLowerCase().includes(searchText.toLowerCase().trim()) ||
            (data.empName && data.empName.toLowerCase().includes(searchText.toLowerCase().trim()))
        );
      }
    } else {
      filterTeamList = teamFeedbackList;
    }
    setPageOfItems([]);
    setFeedbackData(filterTeamList);
  }, [teamFeedbackList, searchText]);

  useEffect(() => {
    if (teamFeedbackList.length > 0) {
      filterData.length > 0
        ? setFeedbackData(filterData)
        : // eslint-disable-next-line no-constant-condition
        null
        ? setFeedbackData(filterData)
        : setFeedbackData(teamFeedbackList);
    }
  }, [filterData, teamFeedbackList]);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  const reset = () => {
    setSearchText("");
  };

  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (feedbackData && feedbackData.length > 0) {
      filteredData = filterArray(feedbackData, filters);
      setFilteredData(filteredData);
    }
    let filterTeamList = [];
    if (searchText.length > 0) {
      if (searchText.startsWith("#")) {
        const queryStr = searchText.substring(1).trim();
        filterTeamList = teamFeedbackList.filter((data) =>
          data.empName.toLowerCase().includes(queryStr.toLowerCase().trim())
        );
      } else {
        filterTeamList = teamFeedbackList.filter(
          (data) =>
            (data.empName && data.empName.toLowerCase().includes(searchText.toLowerCase().trim())) ||
            data.empName.toLowerCase().includes(searchText.toLowerCase().trim())
        );
      }
    } else {
      filterTeamList = teamFeedbackList;
    }
    setFeedbackData(filterTeamList);
  };

  const toggleText = (feedbackID) => {
    if (toggle === feedbackID) {
      setToggle("");
    } else {
      setToggle(feedbackID);
    }
  };

  return (
    <div className={style.teams_feedback_wrapper}>
      <Container>
        <Row>
          <Col md={12} className="col-12 mt-3">
            <h1 className="page-title pb-0">OKR Progress</h1>
            <span className="st-breadcrumb">
              1-on-1s<span>Team&apos;s Feedback </span>
            </span>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col lg={12}>
            <div className={[style.my_team_feedback_box, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading w-auto">
                  <h2>Team&apos;s Feedback</h2>
                  <h3>You can view feedback of your team members</h3>
                </div>
              </div>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Form>
                    <Form.Group controlId="1" className={[style.searchBoxContainer, "form-postfix mb-0"].join(" ")}>
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Search for team members"
                        name="searchBox"
                        className={className}
                        onChange={handleSearchInputChange}
                        value={searchText}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      <Button className={[style.closeIconStyle, "close-button"].join(" ")} type="reset" onClick={reset}>
                        <span className="close-icon"></span>
                      </Button>
                      <div className="form-postfix-icon">
                        <img className={style.searchIconStyle} src={IC_INPUT_SEARCH} title="" alt="" />
                      </div>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              {isLoading ? (
                <Spinner />
              ) : pageOfItems && pageOfItems.length === 0 ? (
                <>
                  <div className="box-light-gradient"></div>
                  <div className="gap80"></div>
                  <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                  <div className="gap80"></div>
                </>
              ) : pageOfItems && pageOfItems.length > 0 ? (
                <>
                  <div className={style.table_responsive}>
                    <div className={style.table_width}>
                      <div className="box-light-gradient"></div>
                      <div className="box-inner-pad">
                        <div className={style.panel_title}>
                          <span>Name</span>
                          <span>Date</span>
                          <span>Feedback</span>
                        </div>
                        <div className={style.listing_wrapper}>
                          {pageOfItems.map((data, index) => {
                            return (
                              <div className={style.item} key={data.employeeId}>
                                <div
                                  className={[
                                    style.item_info_top_wrapper,
                                    open.flag === true && open.id === data.employeeId ? "active" : "",
                                  ].join(" ")}
                                >
                                  <div className={style.item_topbar}>
                                    <div
                                      className={style.member_info}
                                      onClick={() => {
                                        if (data.feedbackList.length) {
                                          if (open.id !== data.employeeId) {
                                            setOpen({
                                              flag: true,
                                              id: data.employeeId,
                                            });
                                          } else {
                                            setOpen({
                                              flag: !open.flag,
                                              id: data.employeeId,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <div className={style.expand_collaspe_Wrapper_Container}>
                                        {data.feedbackList.length ? (
                                          <i className={style.expand_collapse_wrapper}>
                                            {open.id === data.employeeId && open.flag ? (
                                              <img src={IC_COLLAPSE} className={style.collapse} alt="Collapse" />
                                            ) : (
                                              <img src={IC_EXPAND} className={style.expand} alt="Expand" />
                                            )}
                                          </i>
                                        ) : null}
                                      </div>

                                      <span
                                        className={style.user_img}
                                        style={{ backgroundColor: getColorByIndex(index) }}
                                      >
                                        {data.displayPicture && data.displayPicture.length > 2 ? (
                                          <img src={data.displayPicture} alt="pic" />
                                        ) : (
                                          data.displayPicture
                                        )}
                                      </span>
                                      <div className={style.user_info}>
                                        <span className={style.username}>{data.empName || "--"}</span>
                                        <span className={style.department}>
                                          {data.designation || "--"} | {data.department || "--"}
                                        </span>
                                      </div>
                                    </div>
                                    {!data.feedbackList.length && <span className={style.skillgaps_text}>{"-"}</span>}
                                    {!data.feedbackList.length && <span className={style.skillgaps_text}>{"-"}</span>}
                                  </div>
                                </div>
                                {open.id === data.employeeId &&
                                  open.flag &&
                                  data.feedbackList.length &&
                                  data.feedbackList.map((userFeedback, userFeedbackIndex) => (
                                    <div key={userFeedback.feedbackId} className={style.item_feedback_top_wrapper}>
                                      <div className={style.item_feedback_top_bar}>
                                        <div className={style.member_feedback_info}>
                                          <span
                                            className={style.user_img}
                                            style={{
                                              backgroundColor: getColorByIndex(userFeedbackIndex),
                                            }}
                                          >
                                            {userFeedback.givenBy.displayPicture &&
                                            userFeedback.givenBy.displayPicture.length > 2 ? (
                                              <img src={userFeedback.givenBy.displayPicture} alt="pic" />
                                            ) : (
                                              userFeedback.givenBy.displayPicture
                                            )}
                                          </span>
                                          <div className={style.user_info}>
                                            <span className={style.username}>{userFeedback.givenBy.empName}</span>
                                            <span className={style.department}>
                                              {userFeedback.givenBy.designation || "--"} |{" "}
                                              {userFeedback.givenBy.department ? userFeedback.givenBy.department : "--"}
                                            </span>
                                          </div>
                                        </div>
                                        <ul className={style.feedbackListContainer}>
                                          {userFeedback.feedbackList &&
                                            userFeedback.feedbackList.length &&
                                            userFeedback.feedbackList.map((feedbackItem, feedbackIndex) => (
                                              <React.Fragment key={feedbackIndex}>
                                                {feedbackIndex <=
                                                  (toggle === userFeedback.feedbackId
                                                    ? userFeedback.feedbackList.length
                                                    : 1) && (
                                                  <li className={style.teams_feedback_list}>
                                                    <span className={style.skillgaps_feedback_text}>
                                                      {dateFormat1(feedbackItem?.createdDate)}
                                                    </span>

                                                    <div className={style.questions_feedback_answer_row}>
                                                      <div className="w-100">
                                                        <ul className="common_list_items">
                                                          {feedbackItem.feedback &&
                                                          feedbackItem.feedback.length < 100 ? (
                                                            <li>
                                                              {feedbackItem.feedback ? feedbackItem.feedback : "N/A"}
                                                            </li>
                                                          ) : (
                                                            <li>
                                                              <ViewMoreText text={feedbackItem.feedback} length={100} />
                                                            </li>
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </li>
                                                )}
                                              </React.Fragment>
                                            ))}
                                        </ul>
                                      </div>
                                      {userFeedback.feedbackList.length > 2 && (
                                        <div className={style.viewFeedbackContainer}>
                                          <span
                                            className={style.view_feedback_link}
                                            id={["toggleButton", userFeedback.feedbackId].join(" ")}
                                            onClick={() => toggleText(userFeedback.feedbackId)}
                                          >
                                            {toggle === userFeedback.feedbackId ? "View Less" : "View all feedback"}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </Col>
        </Row>
        {isLoading ? (
          <Spinner />
        ) : feedbackData && feedbackData.length > 0 ? (
          <Pagination pageSize={"10"} items={feedbackData} onChangePage={onChangePage} resetData={resetData} />
        ) : null}
      </Container>
    </div>
  );
};

export default TeamsFeedBack;
