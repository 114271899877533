import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { imgToSvg } from "util/utils";
import Tribute from "tributejs";
import style from "./AppericiateColleagues.module.scss";
import { AppreciateHistory, SearchEmployee, Appreciate } from "store/actions/madeMyDay";
import { CLEAR_RESPONSE } from "store/actions/actionTypes";
import PreviousAppreciateHistory from "./PreviousAppreciateHistory";
import { errorToast, successToast } from "util/general";

export default function AppericiateColleagues({ user }) {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [selectEmployee, setSelectEmployee] = useState(null);
  const [appreciateLength, setAppreciateLength] = useState(100);
  const history = useSelector((state) => state.madeMyDay.appreciateHistory);
  const appreciateResponse = useSelector((state) => state.madeMyDay.appreciateResponse);

  useEffect(() => {
    imgToSvg();

    const tribute = new Tribute({
      values: (query, callback) => SearchEmployee(query, callback, user.employeeId, user.parentGroupId),
      trigger: "@",
      lookup: "empName",
      menuItemTemplate: function (item) {
        let pic;
        if (item.original.displayPicture) {
          pic =
            item.original.displayPicture.length === 2
              ? item.original.displayPicture
              : `<img src=${item.original.displayPicture} />`;
        }

        return `<div class="appreciate_item">
            <div class="appreciate_list_pics">${pic}</div>
            <div class="appreciate_profile-info">
              <div class="appriciatelist_name">${item.original.empName}</div>
              <div class="appriciatelist_designation">${item.original.designation}</div>
            </div>
          </div>`;
      },
      selectTemplate: (item) => {
        if (typeof item === undefined) return null;

        let receiver = {
          employeeId: item.original.employeeId,
          empName: item.original.empName,
          displayPicture: item.original.displayPicture,
          designation: item.original.designation,
          parentGroupId: item.original.parentGroupId,
          subGroupId: item.original.subGroupId,
        };
        setSelectEmployee(receiver);
        return `<span style="color:#7033FF" contenteditable='false'>@${item.original.empName}</span>`;
      },
      allowSpaces: true,
      containerClass: "tribute-container",
      menuContainer: document.getElementById("menuContainer"),
    });

    let appreciateBox = document.getElementById("appreciateBox");
    if (selectEmployee) {
      tribute.detach(appreciateBox);
      setAppreciateLength(102 + selectEmployee.empName.length);
    } else {
      tribute.attach(appreciateBox);
      setAppreciateLength(100);
    }
  }, [selectEmployee, user.employeeId]);

  useEffect(() => dispatch(AppreciateHistory(user)), [dispatch, success]);

  useEffect(() => {
    if (appreciateResponse) {
      if (appreciateResponse.message.code === 2004) {
        errorToast(appreciateResponse.message.message);
      } else {
        successToast("Successfully added made my day");
        document.getElementById("appreciateBox").innerHTML = "";
        setSuccess(!success);
        setSelectEmployee(null);
      }
      dispatch({ type: CLEAR_RESPONSE });
    }
  }, [appreciateResponse]);

  const handleKeyPressAppreciate = (event) => {
    let appreciateText = document.getElementById("appreciateBox").textContent.trim();
    if (event.keyCode === 13) event.preventDefault();
    else if (event.keyCode !== 8 && appreciateLength <= appreciateText.length) event.preventDefault();
  };

  const handleAppreciateChange = () => {
    let appreciateText = document.getElementById("appreciateBox").textContent;
    if (selectEmployee) {
      if (!appreciateText.includes(`@${selectEmployee.empName}`)) {
        setSelectEmployee(null);
      }
    }
  };

  const handleOnPaste = (event) => {
    event.preventDefault();
    let appreciateText = document.getElementById("appreciateBox").textContent.trim();
    var text = event.clipboardData.getData("text/plain");
    if (appreciateLength <= (text + appreciateText).length) return;
    document.execCommand("insertHTML", false, text);
  };

  const handleAppreciate = () => {
    let message = document.getElementById("appreciateBox").textContent;
    message = message.replace(/\s\s+/g, " ");
    let sender = {
      employeeId: user.employeeId,
      parentGroupId: user.parentGroupId,
      subGroupId: user.subGroupId,
      empName: user.name,
      displayPicture: user.image || user.employeeInitial,
      designation: user.designation,
    };

    if (selectEmployee) {
      if (!(message.trim().length - 2 > selectEmployee.empName.length)) {
        errorToast("please enter message");
      } else {
        dispatch(
          Appreciate({
            sender: sender,
            receiver: selectEmployee,
            message: message,
          })
        );
      }
    } else {
      errorToast("Please select employee!");
    }
  };

  return (
    <div>
      <div className={["box", "p_b_n", style.appreciate_colleagues_box].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Appreciate Colleagues</h2>
            <h3 className="box-heading-light fs-15">
              Who did something amazing that made your day? Share the impact they had on you or the team by giving you
              made my day.
            </h3>
          </div>
        </div>
        <div className={["box-inner-pad", "box-light-gradient", style.made_my_day_box].join(" ")}>
          <h5 className="semi-bold">You Made My Day</h5>
          <h6 className="small">Maximum 100 Characters</h6>
          <div className={style.add_made_my_day_item}>
            <div className="auto_complete_wrapper global_search_employee_list mb20 appreciate_tbody" id="menuContainer">
              <Form.Group>
                <div className="textarea-wrapper">
                  <div
                    id="appreciateBox"
                    contentEditable
                    placeholder="@ Name of employee"
                    onInput={handleAppreciateChange}
                    onKeyDown={handleKeyPressAppreciate}
                    onPaste={handleOnPaste}
                  ></div>
                </div>
              </Form.Group>
            </div>
            <Button type="button" variant={"primary"} className="btn-round btn-border mb5" onClick={handleAppreciate}>
              Submit
            </Button>
          </div>
        </div>
        {history && history.length > 0 && <PreviousAppreciateHistory history={history} />}
      </div>
    </div>
  );
}
