import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

function DateAndTimePicker({
  date,
  maxDate,
  minDate,
  handleDate,
  startTime,
  blockTimes,
  startMinTime,
  startMaxTime,
  handleEndTime,
  handleStartTime,
  endTime,
  endMaxTime,
  endMinTime,
}) {
  return (
    <div className="cl_t_wrapper">
      <div className="calendar_wrapper form-group">
        <DatePicker
          className="form-control"
          placeholderText="Select Date"
          selected={date}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleDate}
          dateFormat="MMMM d, yyyy"
        />
      </div>
      <div className="time_wrapper">
        <Form.Group>
          <DatePicker
            selected={startTime}
            onChange={handleStartTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            placeholderText="Start"
            excludeTimes={blockTimes}
            minTime={startMinTime}
            maxTime={startMaxTime}
            timeCaption="From"
            dateFormat="h:mm aa"
            timeFormat="h:mm aa"
            className="form-control"
          />
        </Form.Group>
        <span>to</span>
        <Form.Group>
          <DatePicker
            selected={endTime}
            onChange={handleEndTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            placeholderText="End"
            minTime={endMinTime}
            maxTime={endMaxTime}
            timeCaption="To"
            dateFormat="h:mm aa"
            timeFormat="h:mm aa"
            className="form-control"
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default DateAndTimePicker;
