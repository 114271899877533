import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./OneOnOnePatterns.module.scss";
import { dateFormat1 } from "util/dateUtils";
import PropTypes from "prop-types";
import { getShortName } from "util/general";

OneOnOnePatterns.defaultProps = {
  handlePatternClick: () => {},
  scheduledDate: "",
  fromTime: "",
  toTime: "",
  scheduledBy: "",
  status: "",
};

OneOnOnePatterns.propTypes = {
  handlePatternClick: PropTypes.func,
  scheduledDate: PropTypes.string,
  fromTime: PropTypes.string,
  toTime: PropTypes.string,
  scheduledBy: PropTypes.string,
  status: PropTypes.string,
};

export default function OneOnOnePatterns({ handlePatternClick, scheduledDate, fromTime, toTime, scheduledBy, status }) {
  const ref = useRef(null);

  return (
    <div className="custom-tooltip custom-tooltip-top" container={ref.current} onClick={handlePatternClick}>
      <div className={["box-info", style.dot, status === "Un-Attended" && style.tab_checkin_delay].join(" ")}>
        <span></span>
      </div>
      <div className="info-attach-file">
        <Row>
          <Col md={12}>
            <span className="checkin-cycle">{`1-on-1's : ${dateFormat1(scheduledDate)} ${fromTime} - ${toTime}`}</span>
          </Col>
          <Col>
            <div className="tTitle">Scheduled By</div>
            <div className="tDateBox">
              <div className="tDate">{getShortName(scheduledBy)}</div>
            </div>
          </Col>
          <Col>
            <div className="tTitle">Status</div>
            <div className="tDateBox">
              <div className="tDate">{status}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
