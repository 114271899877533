import React from "react";
import { Form } from "react-bootstrap";
import style from "./withManagerQuestionAnswer.module.scss";

const withManagerQuestionAnswer = (WrappedComponent) => (props) => {
  let { item, isManagerSubmitted, handleManagerRadioResponse, name, error } = props;

  const newProps = {
    ...props,
    isCapabilityDevelopmentFramework: true,
    managerResponse: props.canManagerResponse ? (
      <>
        <div className="box-light-gradient"></div>
        <div className="radio-wrapper ratingBarBox plr-25">
          <Form.Group controlId="">
            <ul className={[style.form_option_list, error && "limit-reached"].join(" ")}>
              {item.options &&
                item.options.length > 0 &&
                item.options.map((optionsData, indexopt) => (
                  <li key={"RADIO_MANAGER" + optionsData.optionId}>
                    <div className="custom-checkbox custom-checkbox-rounded">
                      <input
                        disabled={isManagerSubmitted}
                        id={
                          "RADIO_MANAGER" + item.questionId + "_" + optionsData.optionId + "_" + indexopt + "_" + name
                        }
                        type="radio"
                        name={"RADIO_MANAGER" + item.questionName + name}
                        defaultChecked={optionsData.isManagerSelected ? true : false}
                        // checked={optionsData.isSelected}
                        onChange={(value) => handleManagerRadioResponse(value, item, optionsData.optionId)}
                      />
                      <label
                        htmlFor={
                          "RADIO_MANAGER" + item.questionId + "_" + optionsData.optionId + "_" + indexopt + "_" + name
                        }
                      >
                        {optionsData.option}
                      </label>
                    </div>
                  </li>
                ))}
            </ul>
          </Form.Group>
        </div>
      </>
    ) : (
      <></>
    ),
  };
  return <WrappedComponent {...newProps} />;
};

export default withManagerQuestionAnswer;
