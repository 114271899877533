import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import React, { useEffect } from "react";
import style from "./EmployeeViewQuizDetails.module.scss";
import { Col, Container, Row, Table } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_EYE } from "const/imgCost";
import Pagination from "shared/Pagination/Pagination";
import routePath from "const/routePath";
import * as actions from "store/actions/index";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { noData } from "const/constValue";
import NoDataFound from "components/NodataFound/NoDataFound";
import Spinner from "shared/Spinner/Spinner";
import storage from "util/storage";

export default function EmployeeViewQuizDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    viewQuizListPastData,
    viewQuizListOngoingData,
    isEmployeeQuizDetailsLoading,
    viewQuizListPastCount,
    isFinish,
    isQuizStarted,
  } = useSelector((state) => state.campaign);

  useEffect(() => {
    dispatch(actions.getQuizTemplateEmployeeViewPastData({ status: "Past" }));
    dispatch(actions.getQuizTemplateEmployeeViewOngoingData({ status: "Ongoing" }));
  }, [dispatch]);

  useEffect(() => {
    if (!isFinish) {
      dispatch({ type: "ISFINISH", payload: true });
    }
    if (isQuizStarted) {
      dispatch({ type: "ISQUIZSTARTED", payload: false });
    }

    const isTimer = window.sessionStorage.getItem("delay-timer");

    if (isTimer >= 0) {
      window.sessionStorage.removeItem("delay-timer");
    }
    if (window.sessionStorage.getItem("capaignID")) {
      window.sessionStorage.removeItem("capaignID");
    }
    if (window.sessionStorage.getItem("isRefreshed")) {
      window.sessionStorage.removeItem("isRefreshed");
    }
  }, []);

  const onChangePage = (currentPage, pageSize) => {
    dispatch(
      actions.getQuizTemplateEmployeeViewOngoingData({
        status: "Ongoing",
        pageNumber: currentPage.toString(),
        offset: pageSize.toString(),
      })
    );
  };

  return (
    <div>
      <SubNavigation type="OKR Progress" />
      {isEmployeeQuizDetailsLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">OKR Progress</h1>
            <span className="st-breadcrumb">
              OKR Progress
              <span>Quiz</span>
            </span>
          </Col>
          <Col md={12}>
            {viewQuizListOngoingData && viewQuizListOngoingData?.length > 0 ? (
              <div className="box">
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2>Quiz</h2>
                    <h3>Appear For Quiz</h3>
                  </div>
                </div>
                <div className="box-light-gradient"></div>
                <div className={style.notification_filter_wrapper}>
                  <div className={[style.title_text_wrapper].join(" ")}>
                    <div className={[style.custom_table, style.hr_table, "table-theme-1"].join(" ")}>
                      <Table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {viewQuizListOngoingData?.map((item) => (
                            <tr key={item?.campaignId}>
                              <td className={style.campaignName}>
                                <span
                                  onClick={() => {
                                    history.push({
                                      pathname:
                                        item.number_Of_Attemptes_remaining === 0 ||
                                        item.quizStatusForEmployee === "Pass"
                                          ? `${routePath.QUIZ_RESULT}/${item?.campaignId}`
                                          : `${routePath.START_QUIZ}/${item?.campaignId}`,
                                      state: { quizDetails: item, hideStart: true },
                                    });
                                    storage.setJSON("employeeQuizData", item);
                                    dispatch({ type: "QUIZ_DETAILS_RESUME", payload: item });
                                  }}
                                >
                                  {item?.campaignName}
                                </span>
                              </td>
                              <td>
                                <span className={["span-status", item?.attempted ? "submitted" : "pending"].join(" ")}>
                                  {item?.attempted ? "Submitted" : "Pending"}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Col>
          <Col md={12}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>My Quizes</h2>
                  <h3>View the quiz you have appeared</h3>
                </div>
              </div>
              <div className="box-light-gradient"></div>
              <div className={style.notification_filter_wrapper}>
                <div className={[style.title_text_wrapper].join(" ")}>
                  <div className={[style.custom_table, style.hr_table, "table-theme-1"].join(" ")}>
                    {viewQuizListPastData && viewQuizListPastData?.length > 0 ? (
                      <Table className={style.table_Responsive}>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {viewQuizListPastData?.map((item) => (
                            <tr key={item.campaignId}>
                              <td className={style.campaignNamePastQuiz}>
                                <span>{item.campaignName}</span>
                              </td>
                              <td>
                                <div
                                  className={style.action_wrapper}
                                  onClick={() => {
                                    history.push({
                                      pathname: `${routePath.QUIZ_RESULT}/${item?.campaignId}`,
                                      state: { hideStart: false },
                                    });
                                    window.sessionStorage.setItem("capaignID", item?.campaignId);
                                  }}
                                >
                                  <ReactSVG src={`${IC_EYE}`} className={style.eye_icon} />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <div>
                        <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                        <div className="gap40"></div>
                        <div className="gap40"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {viewQuizListPastCount > 0 && <Pagination totalItem={viewQuizListPastCount} onChangePage={onChangePage} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
