import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  successResponse: null,
  errorResponse: null,
  skills: [],
  draftSkills: {},
  size: 10,
  additionalSkillList: [],
  skillDetail: {},
  skilladdedinmaster: "",
  skillmasterupdated: "",
  alreadyexist: false,
  deleteAdditionalSkillResponse: null,
  departmentList: [],
  categoryList: [],
  roleByDepartment: [],
  technicalRole: [],
  saveRequiredSkillResponse: null,
  saveRequiredSkillErrorResponse: null,
  requiredSkillConfig: null,
  saveMyRequiredSkillResponse: null,
  saveMyRequiredSkillErrorResponse: null,
  myDepartmentTechRole: [],
  rolewiseskill: [],
  employeeRequiredSkillList: [],
  getRequiredSkillRecord: null,
  employeeList: [],
  endorsementType: [],
  endorsementSkill: [],
  band: [],
  certificateName: [],
  filteredData: [],
  technicalRoleList: [],
  endorseEmployeeSuccessResponse: null,
  endorseEmployeeErrorResponse: null,
  endorseReqErrorResponse: null,
  endorseReqResponse: null,
  isFilterLoading: false,
  getEndorseByMe: null,
  getEndorseReqForEmp: [],
  getMyEndorsementList: [],
  getPendingDeclineReq: [],
  approveEndorseReqResponse: null,
  approveEndorseReqErrorResponse: null,
  getDeclineReqList: [],
  getMMGEndorserList: [],
  sendMMGEndorseReqResponse: null,
  sendMMGEndorseReqErrorResponse: null,
  getMMGEndorseReq: [],
  getUniversityReq: [],
  getUniversityApproveDeclineReq: [],
  getDefineSkillDraft: null,
  getSkillMasterCategory: [],
  addSkillMasterSuccessResponse: null,
  addSkillMasterErrorResponse: null,
  errorAddCategory: [],
  successAddCategory: [],
  categoryWiseSkillList: [],
  employeeSkillExport: false,
  employeeSkillExportError: false,
  getPage: 1,
  uploadResume: null,
  downloadResume: null,
  downloadResumeTemplate: null,
  getEmployeeResume: {},
  downloadResumeEmployeeSkillListing: {},
  technicalRoleUpdateError: {},
  technicalRoleUpdateSuccess: {},
  listRequestChangeForMmg: [],
  resumeUpdateReminder: {},
  approveRejectRequestForTechnicalRoleChangeMMGSuccess: {},
  approveRejectRequestForTechnicalRoleChangeMMGError: {},
  resumeUpdateAcknowledgeSuccess: {},
  resumeUpdateAcknowledgeError: {},
  myTechnicalRoleChangeRequest: [],
  employeeNameForEmployeeSkillListing: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_Skill:
      return {
        ...state,
        isLoading: false,
        successResponse: { success: true, message: action.payload },
      };

    case actionTypes.UPDATE_ADDITIONAL_SKILL:
      return {
        ...state,
        isLoading: false,
        successResponse: { success: true, message: action.payload },
      };
    case actionTypes.ERROR_IN_ADD_Skill:
      return {
        ...state,
        isLoading: false,
        errorResponse: { error: true, message: action.payload },
      };

    case actionTypes.DISPLAY_LOADER:
      return {
        ...state,
        isLoading: true,
        successResponse: null,
        errorResponse: null,
        skillDetail: {},
      };

    case actionTypes.ADD_SKILL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorResponse: { error: true, message: action.payload },
      };

    case actionTypes.GET_SKILLS:
      return {
        ...state,
        isLoading: false,
        skills: action.payload ? action.payload : [],
      };

    case actionTypes.FAILURE_DRAFT_RESPONSE: {
      return {
        ...state,
        isLoading: false,
        draftSkills: {},
      };
    }
    case actionTypes.DRAFT_SKILL: {
      return {
        ...state,
        isLoading: false,
        draftSkills: action.payload ? action.payload : {},
        skillDetail: {},
      };
    }

    case actionTypes.UPDATE_DRAFT_SKILL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        successResponse: null,
        errorResponse: null,
        deleteAdditionalSkillResponse: null,
        saveRequiredSkillResponse: null,
        saveRequiredSkillErrorResponse: null,
        saveMyRequiredSkillErrorResponse: null,
        saveMyRequiredSkillResponse: null,
        requiredSkillConfig: null,
        getRequiredSkillRecord: null,
        rolewiseskill: [],
        employeeList: [],
        endorsementType: [],
        endorsementSkill: [],
        endorseEmployeeSuccessResponse: null,
        endorseEmployeeErrorResponse: null,
        endorseReqResponse: null,
        endorseReqErrorResponse: null,
        approveEndorseReqResponse: null,
        approveEndorseReqErrorResponse: null,
        sendMMGEndorseReqErrorResponse: null,
        sendMMGEndorseReqResponse: null,
        getUniversityReq: null,
        getEndorseReqForEmp: null,
        addSkillMasterSuccessResponse: null,
        addSkillMasterErrorResponse: null,
        errorAddCategory: {},
        getDefineSkillDraft: null,
        successAddCategory: {},
        skilladdedinmaster: {},
        employeeSkillExport: false,
        employeeSkillExportError: false,
        uploadResume: null,
        downloadResume: null,
        downloadResumeTemplate: null,
        getEmployeeResume: {},
        technicalRoleUpdateError: {},
        technicalRoleUpdateSuccess: {},
        approveRejectRequestForTechnicalRoleChangeMMGSuccess: {},
        approveRejectRequestForTechnicalRoleChangeMMGError: {},
        resumeUpdateAcknowledgeSuccess: {},
        resumeUpdateAcknowledgeError: {},
        downloadResumeEmployeeSkillListing: {},
      };

    case actionTypes.ADDITIONAL_SKILL_LIST:
      return {
        ...state,
        isLoading: false,
        additionalSkillList: action.payload ? action.payload : [],
      };

    case actionTypes.CLEAR_DRAFT_SKILL: {
      return {
        ...state,
        isLoading: false,
        draftSkills: {},
        successResponse: null,
        errorResponse: null,
      };
    }

    case actionTypes.GET_SKILL: {
      return {
        ...state,
        isLoading: false,
        draftSkills: {},
        skillDetail: action.payload ? action.payload : {},
      };
    }

    case actionTypes.SKILL_ADDED_IN_MASTER: {
      return {
        ...state,
        skilladdedinmaster: { success: true, message: action.payload },
      };
    }

    case actionTypes.ADDITIONAL_SKILL_DELETE_ERROR:
      return {
        ...state,
        isLoading: false,
        deleteAdditionalSkillResponse: { error: true, message: action.payload },
      };

    case actionTypes.DELETE_ADDITIONAL_SKILL: {
      let additionalSkill = [...state.additionalSkillList];
      if (additionalSkill) {
        additionalSkill = additionalSkill.filter((sk) => sk.skillId !== action.payload.id);
      }
      return {
        ...state,
        isLoading: false,
        additionalSkillList: additionalSkill.length > 0 ? additionalSkill : [],
        deleteAdditionalSkillResponse: { error: false, message: action.payload.message },
      };
    }

    case actionTypes.FAIL_DELETE_ADDITIONAL_SKILL:
      return {
        ...state,
        isLoading: false,
        deleteAdditionalSkillResponse: { error: true, message: action.payload },
      };

    case actionTypes.CLEAR_SKILL_ADDED_IN_MASTER: {
      return {
        ...state,
        skilladdedinmaster: false,
      };
    }

    case actionTypes.UPDATE_SKILL_MASTER: {
      return {
        ...state,
        skillmasterupdated: { success: true, message: action.payload },
      };
    }

    case actionTypes.CLEAR_UPDATE_SKILL_MASTER_NOTIFICATION: {
      return {
        ...state,
        skillmasterupdated: { succes: false },
      };
    }

    case actionTypes.ALREADY_EXIST: {
      return {
        ...state,
        alreadyexist: { error: true, message: action.payload },
      };
    }

    case actionTypes.ALREADY_EXIST_NOTIFICATION_OFF: {
      return {
        ...state,
        alreadyexist: { error: false },
      };
    }

    case actionTypes.GET_DEPARTMENT_LIST: {
      return {
        ...state,
        departmentList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_CATEGORY_LIST: {
      return {
        ...state,
        categoryList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_ROLE_BY_DEPARTMENT: {
      return {
        ...state,
        roleByDepartment: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_TECHNICAL_ROLE: {
      return {
        ...state,
        technicalRole: action.payload ? action.payload : [],
      };
    }

    case actionTypes.SAVE_REQUIRED_SKILL: {
      return {
        ...state,
        isLoading: false,
        saveRequiredSkillResponse: { error: false, message: action.payload },
      };
    }

    case actionTypes.SAVE_REQUIRED_SKILL_ERROR: {
      return {
        ...state,
        isLoading: false,
        saveRequiredSkillErrorResponse: { error: true, message: action.payload },
      };
    }

    case actionTypes.GET_REQUIRED_SKILL_CONFIG: {
      return {
        ...state,
        isLoading: false,
        requiredSkillConfig: action.payload ? action.payload : "No Data",
      };
    }
    case actionTypes.LIST_ROLE_WISE_SKILL_REQUIRED_FOR_MMG: {
      return {
        ...state,
        rolewiseskill: action.payload ? action.payload : [],
        isLoading: false,
      };
    }

    case actionTypes.SAVE_MY_REQUIRED_SKILL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        saveMyRequiredSkillResponse: { error: false, message: action.payload },
      };
    }

    case actionTypes.SAVE_MY_REQUIRED_SKILL_ERROR: {
      return {
        ...state,
        isLoading: false,
        saveMyRequiredSkillErrorResponse: { error: true, message: action.payload },
      };
    }

    case actionTypes.GET_MY_DEPARTMENT_TECH_ROLE: {
      return {
        ...state,
        isLoading: false,
        myDepartmentTechRole: action.payload ? action.payload : [],
      };
    }
    case actionTypes.LIST_EMPLOYEE_REQUIRED_SKILL: {
      return {
        ...state,
        isLoading: false,
        employeeRequiredSkillList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_REQUIRED_SKILL_DETAILS: {
      return {
        ...state,
        isLoading: false,
        getRequiredSkillRecord: action.payload ? action.payload : {},
      };
    }
    case actionTypes.GET_ENDORSEMENT_SKILL: {
      return {
        ...state,
        isLoading: false,
        endorsementSkill: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_EMPLOYEE_LIST: {
      return {
        ...state,
        isLoading: false,
        employeeList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_ENDORSEMENT_TYPE: {
      return {
        ...state,
        isLoading: false,
        endorsementType: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_TECHNICAL_ROLE_LIST: {
      return {
        ...state,
        isLoading: false,
        technicalRoleList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_BAND_LIST: {
      return {
        ...state,
        isLoading: false,
        band: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_FILTER_DATA: {
      return {
        ...state,
        isLoading: false,
        filteredData: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_CERTIFICATE_NAME: {
      return {
        ...state,
        certificateName: action.payload ? action.payload : [],
      };
    }

    case actionTypes.ENDORSE_EMPLOYEE: {
      return {
        ...state,
        isLoading: false,
        endorseEmployeeSuccessResponse: { error: false, message: action.payload },
      };
    }

    case actionTypes.ENDORSE_EMPLOYEE_ERROR: {
      return {
        ...state,
        isLoading: false,
        endorseEmployeeErrorResponse: { error: true, message: action.payload },
      };
    }

    case actionTypes.ENDORSE_REQ: {
      return {
        ...state,
        isLoading: false,
        endorseReqResponse: { error: false, message: action.payload },
      };
    }

    case actionTypes.ENDORSE_REQ_ERROR: {
      return {
        ...state,
        isLoading: false,
        endorseReqErrorResponse: { error: true, message: action.payload },
      };
    }

    case actionTypes.GET_ENDORSEMENT_BY_ME: {
      return {
        ...state,
        isLoading: false,
        getEndorseByMe: action.payload ? action.payload : null,
      };
    }

    case actionTypes.GET_ENDORSE_REQ_FOR_EMP: {
      return {
        ...state,
        isLoading: false,
        getEndorseReqForEmp: action.payload ? action.payload : null,
      };
    }

    case actionTypes.GET_MY_ENDORSEMENT: {
      return {
        ...state,
        isLoading: false,
        getMyEndorsementList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_PENDING_DECLINE_REQ: {
      return {
        ...state,
        isLoading: false,
        getPendingDeclineReq: action.payload ? action.payload : [],
      };
    }
    case actionTypes.APPROVE_ENDORSE_REQ: {
      return {
        ...state,
        isLoading: false,
        approveEndorseReqResponse: { error: false, message: action.payload },
      };
    }

    case actionTypes.APPROVE_ENDORSE_REQ_ERROR: {
      return {
        ...state,
        isLoading: false,
        approveEndorseReqErrorResponse: { error: true, message: action.payload },
      };
    }

    case actionTypes.GET_DECLINE_ENDORSE_REQ: {
      return {
        ...state,
        isLoading: false,
        getDeclineReqList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_MMG_ENDORSER_NAME: {
      return {
        ...state,
        isLoading: false,
        getMMGEndorserList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.SEND_MMG_ENDORSE_REQ: {
      return {
        ...state,
        isLoading: false,
        sendMMGEndorseReqResponse: { error: false, message: action.payload },
      };
    }

    case actionTypes.SEND_MMG_ENDORSE_REQ_ERROR: {
      return {
        ...state,
        isLoading: false,
        sendMMGEndorseReqErrorResponse: { error: true, message: action.payload },
      };
    }

    case actionTypes.GET_MMG_ENDORSE_REQ: {
      return {
        ...state,
        isLoading: false,
        getMMGEndorseReq: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_UNIVERSITY_REQ: {
      return {
        ...state,
        isLoading: false,
        getUniversityReq: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_UNIVERSITY_DECLINE_APPROVE_REQ: {
      return {
        ...state,
        isLoading: false,
        getUniversityApproveDeclineReq: action.payload ? action.payload : [],
      };
    }

    case actionTypes.GET_DEFINE_SKILL_DRAFT: {
      return {
        ...state,
        isLoading: false,
        getDefineSkillDraft: action.payload ? action.payload : null,
      };
    }

    case actionTypes.GET_SKILL_MASTER_CATEGORY: {
      return {
        ...state,
        isLoading: false,
        getSkillMasterCategory: action.payload ? action.payload : [],
      };
    }
    case actionTypes.ADD_SKILL_MASTER: {
      return {
        ...state,
        isLoading: false,
        addSkillMasterSuccessResponse: { error: false, message: action.payload },
      };
    }

    case actionTypes.ADD_SKILL_MASTER_ERROR: {
      return {
        ...state,
        isLoading: false,
        addSkillMasterErrorResponse: { error: true, message: action.payload },
      };
    }

    case actionTypes.ERROR_IN_ADD_CATEGORY: {
      return {
        ...state,
        isLoading: false,
        errorAddCategory: { error: true, message: action.payload },
      };
    }
    case actionTypes.ADD_CATEGORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        successAddCategory: { success: true, message: action.payload },
      };
    }
    case actionTypes.CATEGORY_WISE_SKILL_MASTER: {
      return {
        ...state,
        isLoading: false,
        categoryWiseSkillList: action.payload ? action.payload : [],
      };
    }

    case actionTypes.EMPLOYEE_SKILL_LISTING_EXPORT: {
      return {
        ...state,
        isLoading: false,
        employeeSkillExport: true,
      };
    }

    case actionTypes.EMPLOYEE_SKILL_LISTING_EXPORT_ERROR: {
      return {
        ...state,
        isLoading: false,
        employeeSkillExportError: true,
      };
    }

    case actionTypes.PAGE_NUMBER: {
      return {
        ...state,
        getPage: action.payload ? action.payload.currentPage : 1,
      };
    }

    case actionTypes.UPLOAD_RESUME_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        uploadResume: { error: false, message: action.payload },
      };
    }

    case actionTypes.ERROR_IN_UPLOAD_RESUME: {
      return {
        ...state,
        isLoading: false,
        uploadResume: { error: true, message: action.payload },
      };
    }

    case actionTypes.GET_EMPLOYEE_RESUME:
      return {
        ...state,
        isLoading: false,
        getEmployeeResume: action.payload,
      };

    case actionTypes.DOWNLOAD_RESUME:
      return {
        ...state,
        isLoading: false,
        downloadResume: { error: false, message: action.payload },
      };

    case actionTypes.DOWNLOAD_RESUME_ERROR:
      return {
        ...state,
        isLoading: false,
        downloadResume: { error: true, message: action.payload },
      };

    case actionTypes.DOWNLOAD_RESUME_TEMPLATE:
      return {
        ...state,
        isLoading: false,
        downloadResumeTemplate: { error: false, message: action.payload },
      };

    case actionTypes.DOWNLOAD_RESUME_TEMPLATE_ERROR:
      return {
        ...state,
        isLoading: false,
        downloadResumeTemplate: { error: true, message: action.payload },
      };

    case actionTypes.RESUME_DOWNLOADING_EMPLOYEE_SKILL_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        downloadResumeEmployeeSkillListing: { success: true, message: action.payload },
      };

    case actionTypes.RESUME_DOWNLOADING_EMPLOYEE_SKILL_LISTING_ERROR:
      return {
        ...state,
        isLoading: false,
        downloadResumeEmployeeSkillListing: { error: true, message: action.payload },
      };
    case actionTypes.UPDATE_TECHNICAL_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        technicalRoleUpdateSuccess: { success: true, message: action.payload },
      };

    case actionTypes.UPDATE_TECHNICAL_ROLE_ERROR:
      return {
        ...state,
        isLoading: false,
        technicalRoleUpdateError: { error: true, message: action.payload },
      };

    case actionTypes.RESUME_UPDATE_REMINDER:
      return {
        ...state,
        isLoading: false,
        resumeUpdateReminder: action.payload,
      };

    case actionTypes.LIST_CHANGE_REQUEST_FOR_MMG:
      return {
        ...state,
        isLoading: false,
        listRequestChangeForMmg: action.payload,
      };
    case actionTypes.APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        approveRejectRequestForTechnicalRoleChangeMMGSuccess: {
          success: true,
          message: action.payload.message,
          title: action.payload.title,
        },
      };
    case actionTypes.APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG_ERROR:
      return {
        ...state,
        isLoading: false,
        approveRejectRequestForTechnicalRoleChangeMMGError: { error: true, message: action.payload },
      };
    case actionTypes.RESUME_UPDATE_ACKNOWLEDGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resumeUpdateAcknowledgeSuccess: { success: true, message: action.payload },
      };
    case actionTypes.RESUME_UPDATE_ACKNOWLEDGE_ERROR:
      return {
        ...state,
        isLoading: false,
        resumeUpdateAcknowledgeError: { error: true, message: action.payload },
      };
    case actionTypes.MY_TECHNICAL_ROLE_CHANGE_REQUEST_LIST:
      return {
        ...state,
        isLoading: false,
        myTechnicalRoleChangeRequest: action.payload,
      };
    case actionTypes.GET_EMPLOYEE_NAME_FOR_EMPLOYEE_SKILL_LISTING:
      return {
        ...state,
        isLoading: false,
        employeeNameForEmployeeSkillListing: action.payload,
      };
    case actionTypes.CLEAR_GET_FILTER_DATA:
      return {
        ...state,
        filteredData: [],
      };
    default:
      return state;
  }
};
export default reducer;
