import * as actionTypes from "../actions/actionTypes";

const initialState = {
  notificationResponse: null,
  allNotification: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION_RESPONSE:
      return { ...state, notificationResponse: action.notificationResponse };
    case actionTypes.SET_ALL_NOTIFICATION_RESPONSE:
        return { ...state, allNotification: action.allNotification };
    default:
      return state;
  }
};

export default reducer;
