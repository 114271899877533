import React, { useState } from "react";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import { Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import HideAndShow from "../../HideAndShow/HideAndShow";
import AddTalkingPointBox from "./AddTalkingPointBox";
import CommentAnswer from "./CommentAnswer";

function SharedNotes(props) {
  const {
    image,
    handleComment,
    comment,
    sendTalkingPoint,
    isPage,
    sharedNotes,
    deleteSharedNote,
    loggedInUser,
    isEnded,
    disabled,
  } = props;
  const [hide, setHide] = useState(false);
  let noteAvailable = sharedNotes && sharedNotes.length > 0;
  return (
    <div className={["card"].join(" ")}>
      <div className="card_info">
        <div className="card_title flex-wrap">
          <span className="card_title_text">Shared Notes</span>
          <span className="card_title_description">
            You and respective person will be able to see these notes while discussion
          </span>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>

      <Collapse in={!hide}>
        <div className="card_details">
          <div className={style.tp_inner_wrapper}>
            {sharedNotes && (
              <div className={[style.view_comment_box_wrapper_n, "view_comment_box_wrapper"].join(" ")}>
                {sharedNotes.map((point) => (
                  <CommentAnswer
                    isEnded={isEnded}
                    point={point}
                    key={point.noteId}
                    deleteNote={deleteSharedNote}
                    loggedInUser={loggedInUser}
                  />
                ))}
              </div>
            )}
            {(isPage === "create" || !noteAvailable || !(disabled || isEnded)) && (
              <AddTalkingPointBox
                disabled={disabled || isEnded}
                image={image}
                placeholder="Add a talking point"
                comment={comment}
                handleComment={handleComment}
                sendTalkingPoint={sendTalkingPoint}
              />
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

SharedNotes.propTypes = {
  image: PropTypes.string,
  handleComment: PropTypes.func,
  comment: PropTypes.string,
  sendTalkingPoint: PropTypes.func,
  sharedNotes: PropTypes.array,
  deleteSharedNote: PropTypes.func,
  loggedInUser: PropTypes.object,
  isEnded: PropTypes.bool,
  disabled: PropTypes.bool,
  isPage: PropTypes.string,
};

export default SharedNotes;
