import React from "react";
import { Route, Switch } from "react-router-dom";
import HRMetricsDashboard from "pages/HR/HRMetricsDashboard/HRMetricsDashboard";
import CreateCampaign from "components/Campaign/CreateCampaign/CreateCampaign";
import CampaignQuestionView from "components/Campaign/CampaignView/CampaignQuestionView";
import CampaignFilterView from "components/Campaign/CampaignView/CampaignFilterView";
import RestrictedRoute from "components/Route/RestrictedRoute";
import AdminCampaignQuestionView from "components/Campaign/CampaignView/AdminCampaignQuestionView";
import CampaignListForSubAdmin from "components/Campaign/CampaignList/CampaignListForSubAdmin";
import CapabilityTemplateList from "components/Campaign/CampaignList/CapabilityTemplateList";
import CampaignListForAdmin from "components/Campaign/CampaignList/CampaignListForAdmin";
import EditCampaign from "components/Campaign/CreateCampaign/EditCampaign";
import CloneCampaign from "components/Campaign/CreateCampaign/CloneCampaign";
import CustomNotification from "components/CustomNotification/CustomNotification";
import TabUniversityRequest from "components/SkillMatrix/Endrosement/TabUniversityRequest/TabUniversityRequest";
import routePath from "const/routePath";
import HRTimelineView from "pages/HR/TimeLine/HRTimelineView";
import ReporteeDashboard from "pages/ReporteeDashboard/ReporteeDashboard";
import PagenotFound from "components/PagenotFound/PagenotFound";
import NotificationList from "components/NotificationList/NotificationList";
import Objectives from "components/Objectives/index";
import TeamsObjectives from "components/Objectives/TeamsObjectives/TeamsObjectives";
import AssignObjectives from "components/Objectives/AssignObjectives/AssignObjectives";
import AllObjectives from "components/Objectives/AllObjectives/AllObjectives";
import CreateObjective from "components/Objectives/CreateObjective/CreateObjective";
import EditObjective from "components/Objectives/EditObjective/EditObjective";
import CreatedObjective from "components/Objectives/CreatedObjective/CreatedObjective";
import AssignObjective from "components/Objectives/CreatedObjective/AssignObjective";
import ObjectiveDetails from "components/Objectives/MyObjectives/ObjectiveDetails";
import UnivertsityObjective from "components/Objectives/AllObjectives/UnivertsityCreatedObjective/UnivertsityCreatedObjective";
import ReportingMemberProfile from "components/Performance/CycleMemberProfile/ReportingMemberProfile";
import PerformanceDashboard from "components/Performance/PerformanceDashboard/PerformanceDashboard";
import ReporteeAllObjectives from "pages/ReporteeDashboard/ReporteeAllObjectives.js/ReporteeAllObjectives";
import EmployeeOneOnOne from "components/OneOnOne/EmployeeOneOnOne/EmployeeOneOnOne";
import CurrentOneOnOneDetails from "components/OneOnOne/EmployeeOneOnOne/CurrentOneOnOneDetails/CurrentOneOnOneDetails";
import University from "pages/University/University";
import CreateOneOnOne from "components/OneOnOne/CreateOneOnOne/CreateOneOnOne";
import PreviousOneOnOneDetails from "components/OneOnOne/EmployeeOneOnOne/CurrentOneOnOneDetails/PreviousOneOnOneDetails";
import CreatePerformanceCycle from "components/Performance/CreatePerformanceCycle/CreatePerformanceCycle";
import SkillMatrix from "pages/SkillMatrix/SkillMatrix";
import AddSkill from "components/SkillMatrix/AddSkill/AddSkill";
import SkillsRequired from "components/SkillMatrix/SkillsRequired/SkillsRequired";
import QuestionBuilder from "components/QuestionBuilder/QuestionBuilder";
import SkillMaster from "components/SkillMatrix/SkillMaster/SkillMaster";
import AddSkillMaster from "components/SkillMatrix/AddSkillMaster/AddSkillMaster";
import EditPerfomanceCycle from "components/Performance/CreatePerformanceCycle/EditPerfomanceCycle";
import PreviewTemplate from "components/QuestionBuilder/previewTemplate/PreviewTemplate";
import MapCapabilityTemplate from "components/Campaign/CapabilityMap/MapCapabilityTemplate";
import NestLogin from "pages/NestLogin/NestLogin";
import DefineRollBasedSkill from "components/SkillMatrix/DefineRollBasedSkill/DefineRollBasedSkill";
import CycleListEmployee from "components/Performance/CycleListEmployee/CycleListEmployee";
import ReportingManagerList from "components/Performance/CycleListManagerEmp/ReportingManagerList";
import AddDefineRollBasedSkill from "components/SkillMatrix/AddDefineRollBasedSkill/AddDefineRollBasedSkill";
import ViewFeedback from "components/ViewFeedback/ViewFeedback";
import Reviews from "pages/Performance/Reviews/Reviews";
import ReviewCycleListDetail from "components/Reviews/ReviewCycleListDetail/ReviewCycleListDetail";
import EmployeeSkillListing from "components/SkillMatrix/EmployeeSkillListing/EmployeeSkillListing";
import CycleList from "components/Performance/CycleList/CycleList";
import Endrosement from "components/SkillMatrix/Endrosement/Endrosement";
import MmgEndrosement from "components/SkillMatrix/Endrosement/MmgEndrosement/MmgEndrosement";
import SelfObjective from "components/Objectives/SelfObjective/SelfObjective";
import MemberCertification from "components/MemberCertification/MemberCertification";
import CertificationOverview from "components/CertificationOverview/CertificationOverview";
import SelfAssessment from "components/Performance/SelfAssessment/SelfAssessment";
import ReportingCycleList from "components/Performance/ManagerCycleList/ReportingCycleList";
import ReviewingCycleList from "components/Performance/ManagerCycleList/ReviewingCycleList";
import ReportingCycleTeam from "components/Performance/CycleTeam/ReportingCycleTeam";
import ReviewingCycleTeam from "components/Performance/CycleTeam/ReviewingCycleTeam";
import ReviewingMemberProfile from "components/Performance/CycleMemberProfile/ReviewingMemberProfile";
import Hr from "pages/HR/Hr";
import TechnicalRoleChangeRequest from "components/SkillMatrix/TechnicalRoleChangeRequest/TechnicalRoleChangeRequest";
import DeallocationFeedback from "components/HR/DeallocationFeedback/DeallocationFeedback";
import HRDeallocationFeedback from "components/HR/DeallocationFeedback/HRDeallocationFeedbackFilter/HRDeallocationFeedback";
import HRDeallocationMemberDetails from "components/HR/DeallocationFeedback/HRDeallocationFeedbackDetails/HRDeallocationMemberDetails";
import MyRoleChangeRequestsList from "components/SkillMatrix/MyRoleChangeRequestsList/MyRoleChangeRequestsList";
import EmployeesObjectives from "components/HR/EmployeesObjectives/EmployeesObjectives";
import ReviewingManagerList from "components/Performance/CycleListManagerEmp/ReviewingManagerList";
import CycleMemberProfile from "components/Performance/CycleMemberProfile/CycleMemberProfile";
import EmployeesSelfAssessmentList from "components/Performance/EmployeeSelfAssessmentList/EmployeesSelfAssessmentList";
import ManagerTeamMemberList from "components/Performance/DeallocationFeedback/ManagerTeamMemberList/ManagerTeamMemberList";
import DeallocationMemberDetails from "components/Performance/DeallocationFeedback/MemberDetails/DeallocationMemberDetails";
import GlobalFeedBack from "components/OneOnOne/FeedBack/GlobalFeedBack/GlobalFeedBack";
import TeamsFeedBack from "components/OneOnOne/FeedBack/TeamsFeedBack";
import HRGlobalFeedbackView from "components/OneOnOne/FeedBack/HRGlobalFeedback";
import CreateAndReplace from "components/HR/CreateAndReplace";
import RoleWiseUserList from "components/Admin/RoleWiseUserList/RoleWiseUserList";
import CheckinConfig from "components/Admin/CheckinConfig/CheckinConfig";
import AdminCreateRoles from "components/Admin/AdminCreateRoles/AdminCreateRoles";
import AdminDefineRoles from "components/Admin/AdminDefineRoles/AdminDefineRoles";
import SuperAdmin from "components/Admin/SuperAdmin/SuperAdmin";
import PMSDashboard from "components/HR/PMSDashboard/PMSDashboard";
import PMSDashboardForManager from "components/Performance/PMSDashboard/PMSDashboardForManager";
import Home from "pages/home/home";
import checkIns from "pages/CheckIns/CheckIns";
import MyHistory from "pages/CheckIns/my-history/my-history";
import MyTeam from "pages/CheckIns/MyTeam/MyTeam";
import OneonOne from "pages/CheckIns/OneOnOne/OneOnOne";
import TeamHistory from "pages/CheckIns/TeamHistory/TeamHistory";
import OneOnOneForTeams from "pages/CheckIns/OneOnOne/OneOnOneForTeams/OneOnOneForTeams";
import config from "util/config";
import { moduleView, pathValidate } from "util/utils";
import OneOnOne from "pages/HR/OneOnOne/OneOnOne";
import OneOnOneDetails from "pages/HR/OneOnOne/OneOnOneDetails/OneOnOneDetails";
import EmployeeViewQuizDetails from "pages/CheckIns/Quiz/EmployeeViewQuizDetails";
import EmployeeViewStartQuiz from "pages/CheckIns/Quiz/EmployeeViewStartQuiz";
import CampaignQuizEmployeeResult from "components/Campaign/CampaignView/CampaignQuizViewDetails/CampaignQuizEmployeeResult";
import EmployeeQuizOnGoing from "pages/CheckIns/Quiz/EmployeeQuizOnGoing";
import EmployeeViewQuizResult from "pages/CheckIns/Quiz/EmployeeViewQuizResult";
import importPreviewTemplate from "components/QuestionBuilder/previewTemplate/importPreviewTemplate";
import CheckinExtensionDate from "components/Admin/CheckinExtensionDate/CheckinExtensionDate";
import OKRTemplate from "components/Admin/OKRTemplate/OKRTemplate";
import InformationHub from "pages/InformationHub/InformationHub";
import MonthlyCheckIn from "pages/MonthlyCheckIn/MonthlyCheckinEmployee/MonthlyCheckIn";
import TeamsCheckIn from "pages/MonthlyCheckIn/TeamsCheckIn/TeamsCheckIn";
import CheckinFeedbackWrapper from 'components/HR/MonthlyCheckinFeedback/CheckinFeedbackWrapper'

function RouteConfig() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path={routePath.HOME} exact component={Home} />
      <Route path={routePath.CURRENT_CHECKIN} exact component={checkIns} />
      <Route path={routePath.ONE_ON_ONE_IN_TEAMS} exact component={OneOnOneForTeams} />
      <Route path={routePath.MY_HISTORY} exact component={MyHistory} />
      <Route path={routePath.NOTIFICATION} exact component={NotificationList} />
      <Route path={routePath.PERFORMANCE_DASHBOARD} exact component={PerformanceDashboard} />
      <Route path={routePath.QUESTION_BUILDER} exact component={QuestionBuilder} />
      <Route path={routePath.PREVIEW_TEMPLATE_ROUTE} exact component={PreviewTemplate} />
      <Route path={routePath.IMPORT_PREVIEW_TEMPLATE_ROUTE} exact component={importPreviewTemplate} />
      <Route path={routePath.MAP_CAPABILITY_VIEW} exact component={MapCapabilityTemplate} />
      <Route path={routePath.REVIEWS} exact component={Reviews} />
      <Route path={`${routePath.CYCLE_DETAIL}/:id`} exact component={ReviewCycleListDetail} />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.SELF_ASSESSMENT)}
        path={routePath.SELF_ASSESSMENT}
        exact
        component={SelfAssessment}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.REPORTING_ASSESSMENT)}
        path={routePath.REPORTING_ASSESSMENT}
        exact
        component={ReportingCycleList}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.REVIEWING_ASSESSMENT)}
        path={routePath.REVIEWING_ASSESSMENT}
        exact
        component={ReviewingCycleList}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.REPORTING_ASSESSMENT)}
        path={`${routePath.REPORTING_ASSESSMENT}/:id`}
        exact
        component={ReportingCycleTeam}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.REVIEWING_ASSESSMENT)}
        path={`${routePath.REVIEWING_ASSESSMENT}/:id`}
        exact
        component={ReviewingCycleTeam}
      />
      <RestrictedRoute
        restricted={true}
        path={`${routePath.REPORTING_MEMBER}/:id`}
        exact
        component={ReportingMemberProfile}
      />
      <RestrictedRoute
        restricted={true}
        path={`${routePath.REVIEWING_MEMBER}/:id`}
        exact
        component={ReviewingMemberProfile}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.PMS_DASHBOARD)}
        path={routePath.PMS}
        exact
        component={PMSDashboardForManager}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.PMS_CYCLE)}
        path={`${routePath.CYCLE_MEMBER_PROFILE}/:id`}
        exact
        component={CycleMemberProfile}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.PROJECT_DEALLOCATION)}
        path={routePath.PROJECT_DEALLOCATION}
        exact
        component={ManagerTeamMemberList}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.PERFORMANCE)?.includes(config.subModule.PROJECT_DEALLOCATION)}
        path={`${routePath.PROJECT_DEALLOCATION}/:id`}
        exact
        component={DeallocationMemberDetails}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.PMS_CYCLE)}
        path={routePath.CREATE_PMS_CYCLE}
        exact
        component={CreatePerformanceCycle}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.PMS_CYCLE)}
        path={routePath.PMS_CYCLE}
        exact
        component={CycleList}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.PMS_CYCLE)}
        path={`${routePath.EDIT_PMS_CYCLE}/:id`}
        exact
        component={EditPerfomanceCycle}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.PMS_CYCLE)}
        path={`${routePath.PMS_CYCLE_EMPLOYEE_LIST}/:id`}
        exact
        component={CycleListEmployee}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.PMS_CYCLE)}
        path={`${routePath.PMS_CYCLE_EMPLOYEE_ASSESSMENT_LIST}/:id`}
        exact
        component={EmployeesSelfAssessmentList}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.PMS_CYCLE)}
        path={`${routePath.PMS_CYCLE_RPM_LIST}/:id`}
        exact
        component={ReportingManagerList}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.PMS_CYCLE)}
        path={`${routePath.PMS_CYCLE_RVM_LIST}/:id`}
        exact
        component={ReviewingManagerList}
      />
      <Route path={routePath.ONE_ON_ONE} exact component={OneonOne} />
      <Route path={routePath.MY_SKILLS} exact component={SkillMatrix} />
      <Route path={routePath.MY_TECHNICAL_ROLE_CHANGE} exact component={MyRoleChangeRequestsList} />
      <Route path={routePath.MY_SKILL_REQUIRED} exact component={SkillsRequired} />
      <Route path={`${routePath.MY_SKILL_REQUIRED}/:id`} exact component={SkillsRequired} />
      <Route path={routePath.ADD_SKILL} exact component={AddSkill} />
      <Route path={`${routePath.ADDITIONAL_SKILL}/:id`} exact component={AddSkill} />
      <Route path={routePath.SKILLS_ENDORSEMENT} exact component={Endrosement} />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_SKILL_MASTER)}
        path={routePath.MMG_SKILL_MASTER}
        exact
        component={SkillMaster}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_UNIVERSITY_REQUEST)}
        path={routePath.MMG_UNIVERSITY_REQUEST}
        exact
        component={TabUniversityRequest}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_DEFINE_SKILLS)}
        path={routePath.MMG_DEFINE_SKILLS}
        exact
        component={DefineRollBasedSkill}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_SKILL_MASTER)}
        path={routePath.MMG_ADD_SKILL_MASTER}
        exact
        component={AddSkillMaster}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_DEFINE_SKILLS)}
        path={routePath.MMG_ADD_DEFINE_SKILLS}
        exact
        component={AddDefineRollBasedSkill}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_DEFINE_SKILLS)}
        path={`${routePath.MMG_DEFINE_SKILLS}/:id`}
        exact
        component={AddDefineRollBasedSkill}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_EMPLOYEE_SKILL_LISTING)}
        path={routePath.MMG_EMPLOYEE_SKILL_LISTING}
        exact
        component={EmployeeSkillListing}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_ENDORSEMENT)}
        path={routePath.MMG_ENDORSEMENT}
        exact
        component={MmgEndrosement}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_TECHNICAL_ROLE_CHANGE_REQUEST)}
        path={routePath.MMG_TECHNICAL_ROLE_CHANGE_REQUEST}
        exact
        component={TechnicalRoleChangeRequest}
      />
      <Route path={routePath.MY_OBJECTIVES} exact component={Objectives} />
      <Route path={routePath.SELF_OBJECTIVES} exact component={SelfObjective} />
      <Route path={`${routePath.ASSIGN_OBJECTIVE}/:id`} exact component={AssignObjectives} />
      <Route path={`${routePath.MY_OBJECTIVES}/:id`} exact component={ObjectiveDetails} />
      <Route path={`${routePath.ONE_ON_ONE}/:id`} exact component={EmployeeOneOnOne} />
      <Route path={`${routePath.CURRENT_ONE_ON_ONE}/:id`} exact component={CurrentOneOnOneDetails} />
      <Route path={`${routePath.PREVIOUS_ONE_ON_ONE}/:id`} exact component={PreviousOneOnOneDetails} />
      <RestrictedRoute restricted={true} path={routePath.TEAM_FEEDBACK} exact component={TeamsFeedBack} />
      <Route path={`${routePath.CREATE_ONE_ON_ONE}/:id`} exact component={CreateOneOnOne} />
      <Route path={routePath.NEST_LOGIN} exact component={NestLogin} />
      <Route path={`${routePath.UNIVERSITY_OBJECTIVE}/:id`} exact component={UnivertsityObjective} />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.OBJECTIVES)?.includes(config.subModule.TEAMS_OBJECTIVES)}
        path={routePath.TEAMS_OBJECTIVE}
        exact
        component={TeamsObjectives}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.OBJECTIVES)?.includes(config.subModule.ALL_OBJECTIVES)}
        path={`${routePath.ASSIGNED_OBJECTIVE}/:id`}
        exact
        component={AssignObjective}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.OBJECTIVES)?.includes(config.subModule.ALL_OBJECTIVES)}
        path={routePath.ALL_OBJECTIVES}
        exact
        component={AllObjectives}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.OBJECTIVES)?.includes(config.subModule.ALL_OBJECTIVES)}
        path={routePath.CREATE_OBJECTIVES}
        exact
        component={CreateObjective}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.OBJECTIVES)?.includes(config.subModule.ALL_OBJECTIVES)}
        path={`${routePath.CREATED_OBJECTIVES}/:id`}
        exact
        component={CreatedObjective}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.OBJECTIVES)?.includes(config.subModule.ALL_OBJECTIVES)}
        path={`${routePath.EDIT_OBJECTIVES}/:id`}
        exact
        component={EditObjective}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.MY_TEAM)}
        path={routePath.MY_TEAM}
        exact
        component={MyTeam}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.MY_TEAM)}
        path={`${routePath.MEMBER_CHECKIN}/:id`}
        exact
        component={TeamHistory}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.MY_TEAM)}
        path={`${routePath.MEMBER_CHECKIN_DASHBOARD}/:id`}
        exact
        component={ReporteeDashboard}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.OBJECTIVES)?.includes(config.subModule.ALL_OBJECTIVES)}
        path={`${routePath.ALL_OBJECTIVES}/:id`}
        exact
        component={ReporteeAllObjectives}
      />
      <Route path={routePath.MY_CERTIFICATIONS} exact component={University} />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_MEMBER_CERTIFICATION)}
        path={routePath.MMG_MEMBER_CERTIFICATION}
        exact
        component={MemberCertification}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_MEMBER_CERTIFICATION_OVERVIEW)}
        path={routePath.MMG_MEMBER_CERTIFICATION_OVERVIEW}
        exact
        component={CertificationOverview}
      />
      {/* <Route path="/university/bench-employees" exact component={BenchEmployeeListing} />
          <Route path="/university/learning-programs" exact component={LearningProgramListing} />
          <Route path="/university/learning-programs/add" exact component={CreateLearningProgram} />
          <Route path="/university/learning-programs/detail" exact component={LearningProgramDetails} />
          <Route path="/university/goals" exact component={GoalsListing} />
          <Route path="/university/goals/add" exact component={CreateGoal} />
          <Route path="/university/goals/detail" exact component={GoalDetails} />
          <Route path="/university/mentees" exact component={MenteesListing} /> */}
      <RestrictedRoute
        restricted={pathValidate(config.roles.MMG_ROLE, routePath.MMG_DEALLOCATION_FEEDBACK)}
        path={routePath.MMG_DEALLOCATION_FEEDBACK}
        exact
        component={DeallocationFeedback}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_DEALLOCATION_FEEDBACK)}
        path={routePath.HR_DEALLOCATION_FEEDBACK}
        exact
        component={HRDeallocationFeedback}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_DEALLOCATION_FEEDBACK)}
        path={`${routePath.HR_DEALLOCATION_FEEDBACK}/:id`}
        exact
        component={HRDeallocationMemberDetails}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_MODULE_ANALYSIS)}
        path={routePath.HR_MODULE_ANALYSIS}
        exact
        component={Hr}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_EMPLOYEE_OBJECTIVE)}
        path={routePath.HR_EMPLOYEE_OBJECTIVE}
        exact
        component={EmployeesObjectives}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_PMS_DASHBOARD)}
        path={routePath.HR_PMS_DASHBOARD}
        exact
        component={PMSDashboard}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_ASSESSMENT)}
        path={routePath.HR_ASSESSMENT}
        exact
        component={CreateAndReplace}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_EMPLOYEE_FEEDBACK_HISTORY)}
        path={routePath.HR_EMPLOYEE_FEEDBACK_HISTORY}
        exact
        component={HRGlobalFeedbackView}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_METRICS_DASHBOARD)}
        path={routePath.HR_METRICS_DASHBOARD}
        exact
        component={HRMetricsDashboard}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_ONE_ON_ONE)}
        path={routePath.HR_ONE_ON_ONE}
        exact
        component={OneOnOne}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_ONE_ON_ONE)}
        path={`${routePath.HR_ONE_ON_ONE}/:id`}
        exact
        component={OneOnOneDetails}
      />
      <RestrictedRoute 
      restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_CHECKIN_OKR_TEMPLATE)}
      path={routePath.HR_CHECKIN_OKR_TEMPLATE}
      exact
      component={OKRTemplate}
      />
      <RestrictedRoute
        restricted={pathValidate("Super-Admin", routePath.SUPER_ADMIN)}
        path={routePath.SUPER_ADMIN}
        exact
        component={SuperAdmin}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.ADMIN_ROLE, routePath.ADMIN_ROLES)}
        path={routePath.ADMIN_ROLES}
        exact
        component={AdminDefineRoles}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.ADMIN_ROLE, routePath.ADMIN_ROLES)}
        path={`${routePath.ADMIN_CREATE_ROLE}/:id`}
        exact
        component={AdminCreateRoles}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.ADMIN_ROLE, routePath.ADMIN_USER_LIST)}
        path={routePath.ADMIN_USER_LIST}
        exact
        component={RoleWiseUserList}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.ADMIN_ROLE, routePath.ADMIN_CHECKIN_CONFIG)}
        path={routePath.ADMIN_CHECKIN_CONFIG}
        exact
        component={CheckinConfig}
      />
      <RestrictedRoute 
      restricted={pathValidate(config.roles.ADMIN_ROLE,routePath.ADMIN_CHECKIN_OKR_TEMPLATE)}
      path={routePath.ADMIN_CHECKIN_OKR_TEMPLATE}
      exact
      component={OKRTemplate}
      />
       <RestrictedRoute
        restricted={pathValidate(config.roles.ADMIN_ROLE, routePath.ADMIN_CHECKIN_EXTENSION_DATE)}
        path={routePath.ADMIN_CHECKIN_EXTENSION_DATE}
        exact
        component={CheckinExtensionDate}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN)}
        path={routePath.CAMPAIGN}
        exact
        component={CampaignQuestionView}
      />
      <RestrictedRoute restricted={true} path={routePath.QUIZ} exact component={EmployeeViewQuizDetails} />
      <RestrictedRoute restricted={true} path={`${routePath.START_QUIZ}/:id`} exact component={EmployeeViewStartQuiz} />
      <RestrictedRoute restricted={true} path={`${routePath.ONGOING_QUIZ}/:id`} exact component={EmployeeQuizOnGoing} />
      <RestrictedRoute
        restricted={true}
        path={routePath.QUIZ_EMPLOYEE_RESULT_SUB_ADMIN}
        exact
        component={CampaignQuizEmployeeResult}
      />
      <RestrictedRoute
        restricted={true}
        path={`${routePath.QUIZ_RESULT}/:id`}
        exact
        component={EmployeeViewQuizResult}
      />
      <Route path={`${routePath.MMG_CAPABILITY_DEVELOPMENT}/:id`} exact component={CampaignQuestionView} />
      <RestrictedRoute
        // restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN_LIST)}
        restricted={true}
        path={`${routePath.CHECKINS_CAMPAIGN_LIST_QUESTION}/:id`}
        exact
        component={AdminCampaignQuestionView}
      />
      <RestrictedRoute
        // restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN_LIST)}
        restricted={true}
        path={`${routePath.MMG_CAPABILITY_QUESTION}/:id`}
        exact
        component={AdminCampaignQuestionView}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_CAMPAIGN_DETAILS)}
        path={`${routePath.HR_CAMPAIGN_DETAILS_QUESTION}/:id`}
        exact
        component={AdminCampaignQuestionView}
      />
      <RestrictedRoute
        // restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN_LIST)}
        restricted={true}
        path={`${routePath.CHECKINS_CAMPAIGN_LIST_VIEW}/:id`}
        exact
        component={CampaignFilterView}
      />
      <RestrictedRoute
        // restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN_LIST)}
        restricted={true}
        path={`${routePath.MMG_CAMPAIGN_LIST_VIEW}/:id`}
        exact
        component={CampaignFilterView}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_CAMPAIGN_DETAILS)}
        path={`${routePath.HR_CAMPAIGN_DETAILS_VIEW}/:id`}
        exact
        component={CampaignFilterView}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_CAMPAIGN_DETAILS)}
        path={routePath.QUIZ_EMPLOYEE_RESULT}
        exact
        component={CampaignQuizEmployeeResult}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN_LIST)}
        path={routePath.CHECKINS_CAMPAIGN_LIST}
        exact
        component={CampaignListForSubAdmin}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_CAMPAIGN_DETAILS)}
        path={routePath.HR_CAMPAIGN_DETAILS}
        exact
        component={CampaignListForAdmin}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_CAMPAIGN_DETAILS)}
        path={routePath.HR_CAMPAIGN_DETAILS_CREATE}
        component={CreateCampaign}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_CAMPAIGN_DETAILS)}
        path={routePath.HR_CAMPAIGN_DETAILS_EDIT}
        component={EditCampaign}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_CAMPAIGN_DETAILS)}
        path={routePath.HR_CAMPAIGN_DETAILS_CLONE}
        component={CloneCampaign}
      />
      <Route path={routePath.MMG_CAPABILITY_DEVELOPMENT} exact component={CapabilityTemplateList} />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.CUSTOM_NOTIFICATION)}
        path={routePath.CUSTOM_NOTIFICATION}
        component={CustomNotification}
      />
      <RestrictedRoute
        restricted={pathValidate(config.roles.HR_ROLE, routePath.HR_FEEDBACK)}
        path={routePath.HR_FEEDBACK}
        component={CheckinFeedbackWrapper}
      />
      <RestrictedRoute
        // restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN_LIST)}
        restricted={true}
        path={routePath.CHECKINS_CAMPAIGN_LIST_EDIT}
        component={EditCampaign}
      />
      <RestrictedRoute
        // restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN_LIST)}
        restricted={true}
        path={routePath.MMG_CAMPAIGN_LIST_EDIT}
        component={EditCampaign}
      />
      <RestrictedRoute
        restricted={moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.CAMPAIGN_LIST)}
        path={routePath.CHECKINS_CAMPAIGN_LIST_CLONE}
        component={CloneCampaign}
      />
      <RestrictedRoute restricted={true} path={routePath.MMG_CAMPAIGN_LIST_CLONE} component={CloneCampaign} />
      <Route path={routePath.GLOBAL_FEEDBACK} exact component={GlobalFeedBack} />
      <Route path={routePath.PAGE_NOT_FOUND} exact component={PagenotFound} />
      <Route path={routePath.HR_TIMELINE} exact component={HRTimelineView} />
      <Route path={routePath.VIEW_FEEDBACK} exact component={ViewFeedback} />
      <Route path={routePath.INFORMATION_HUB} exact component={InformationHub} />
      <Route path={routePath.MONTHLY_CHECKIN} exact component={MonthlyCheckIn} />
      <Route path={routePath.TEAMS_CHECKIN} exact component={TeamsCheckIn} />
      <Route path="*" exact component={PagenotFound} />
    </Switch>
  );
}

export default RouteConfig;
