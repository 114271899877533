import React from "react";
import Select from "react-select";
import CommentsTop from "../../components/CommentBox/CommentsTop";
import CommentWithAttachment from "../../components/CommentBox/CommentWithAttachment";
import MultipleFile from "../../components/MultipleFileUpload/MultipleFile";
import CommentBox from "../../components/Performance/SelfAssessment/Components/CommentBox";
import { backGroundColor } from "../../const/constValue";
import storage from "../../util/storage";

const withRatingAndComment = (WrappedComponent) => (props) => {
  const loggedInUser = storage.getUser();
  let viewRating = false;
  let ratingName = [];
  let fileBeforeSubmit = [];
  let comment = "";
  let rateTextInRPM = "Rate this question by selecting a reasonable value";
  let rateTextInRVM = "Rate this question by selecting a reasonable value";
  let displayComment = false;
  if (props.isPage === "HR" || props.isPage === "USER") {
    rateTextInRPM = "Rating provided by manager";
    rateTextInRVM = "Rating provided by manager";
    if (
      props.item?.ratingCommentByRpm?.find((v) => v.comment) ||
      props.item?.ratingCommentByRvm?.find((v) => v.comment)
    ) {
      if (props.isPage === "USER" && !props.item.showManagerResponse) {
        displayComment = false;
      } else {
        displayComment = true;
      }
    }
  } else if (props.isPage === "REPORTING" && props.item.notShowScaleToEmployee) {
    if (
      !props.isManagerSubmitted ||
      props.item?.ratingCommentByRpm?.find((v) => v.comment) ||
      props.item?.ratingCommentByRvm?.find((v) => v.comment && v.reporteeEmployeeId === loggedInUser.employeeId)
    ) {
      comment = props.item.ratingCommentByRpm?.find((v) => v.employeeId === loggedInUser?.employeeId)?.comment || "";
      displayComment = true;
    }
    if (props.item?.ratingCommentByRpm?.find((v) => v.file && v.employeeId === loggedInUser.employeeId)) {
      fileBeforeSubmit = props.item?.ratingCommentByRpm?.find(
        (v) => v.file && v.employeeId === loggedInUser.employeeId
      ).file;
    }
  } else if (props.isPage === "REVIEWING" && props.item.notShowScaleToEmployee) {
    rateTextInRPM = "Rating provided by manager";
    displayComment = true;
    if (props.item?.ratingCommentByRvm?.find((v) => v.comment)) {
      comment = props.item.ratingCommentByRvm?.find((v) => v.employeeId === loggedInUser?.employeeId)?.comment || "";
    }
    if (props.item?.ratingCommentByRvm?.find((v) => v.file && v.employeeId === loggedInUser.employeeId)) {
      fileBeforeSubmit = props.item?.ratingCommentByRvm?.find(
        (v) => v.file && v.employeeId === loggedInUser.employeeId
      ).file;
    }
  }
  //if question template is for employee and logged in user manager then and only rating and comment visible
  if (props.item.type === "SCALED" && props.isPage !== "SELF" && props.name === "Self Assessment") {
    if (props.isPage === "USER" && !props.item.showManagerScore) {
      viewRating = false;
    } else {
      viewRating = true;
      displayComment = true;
      if (props.isPage === "REPORTING") {
        if (
          !props.isManagerSubmitted ||
          props.item?.ratingCommentByRpm?.find((v) => v.comment) ||
          props.item?.ratingCommentByRvm?.find((v) => v.comment && v.reporteeEmployeeId === loggedInUser.employeeId)
        ) {
          comment =
            props.item.ratingCommentByRpm?.find((v) => v.employeeId === loggedInUser?.employeeId)?.comment || "";
          displayComment = true;
        }
        if (props.item?.ratingCommentByRpm?.find((v) => v.file && v.employeeId === loggedInUser.employeeId)) {
          fileBeforeSubmit = props.item?.ratingCommentByRpm?.find(
            (v) => v.file && v.employeeId === loggedInUser.employeeId
          ).file;
        }
      } else if (props.isPage === "REVIEWING") {
        if (props.item?.ratingCommentByRvm?.find((v) => v.comment)) {
          comment =
            props.item.ratingCommentByRvm?.find((v) => v.employeeId === loggedInUser?.employeeId)?.comment || "";
        }
        if (props.item?.ratingCommentByRvm?.find((v) => v.file && v.employeeId === loggedInUser.employeeId)) {
          fileBeforeSubmit = props.item?.ratingCommentByRvm?.find(
            (v) => v.file && v.employeeId === loggedInUser.employeeId
          ).file;
        }
      }
    }
    ratingName = props.item.scaleObj && props.item.scaleObj.name.split("/");
  }
  let options = [];
  if (ratingName?.length > 0) {
    options = ratingName.map((v) => {
      return {
        value: v,
        label: v,
      };
    });
  }

  const finalRating = (data) => {
    let rate;
    if (data.scaleValue) {
      rate = {
        value: data.scaleValue,
        label: data.scaleValue,
      };
    }
    return rate;
  };

  const newProps = {
    ...props,
    ratingAndComment: (
      <>
        {viewRating && (
          <>
            {props.isRatingDisable !== true && (
              props.item?.ratingCommentByRpm?.map((data) => (
                <div className="card template-rating" key={data.employeeId}>
                  <div className="card-footer">
                    <div className="card-ft-wrapper">
                      <div className="reporting-wrapper">
                        <span>{rateTextInRPM}</span>
                        {props.isPage !== "REPORTING" && (
                          <span className="reporting-name">{data.userDataAssessment.empName}&nbsp;(RPM)</span>
                        )}
                      </div>
                      <div className="card_action">
                        <Select
                          isDisabled={props.isManagerSubmitted || props.isRatingDisable || props.isPage === "HR" || props.isPage === "REVIEWING"}
                          options={options}
                          className={[
                            "custom-default-dd-box",
                            props.ratingErrors?.find(
                              (value) =>
                                value.queId === props.item.questionId &&
                                value.error &&
                                value.isSubmit &&
                                value.type === "RATING"
                            ) && "error-box",
                          ].join(" ")}
                          classNamePrefix="select"
                          isSearchable={false}
                          defaultValue={finalRating(data)}
                          onChange={(value) => props.sendRating(value, props.item, data.employeeId)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
            
            {props.isRatingDisable !== true && (
              props.item?.ratingCommentByRvm?.map((data) => (
                <div className="card template-rating" key={data.employeeId}>
                  <div className="card-footer">
                    <div className="card-ft-wrapper">
                      <div className="reporting-wrapper">
                        <span>{rateTextInRVM}</span>
                        {(props.isPage === "HR" || props.isPage === "USER") && (
                          <span className="reporting-name">{data.userDataAssessment.empName}&nbsp;(RVM)</span>
                        )}
                      </div>
                      <div className="card_action">
                        <Select
                          isDisabled={props.isManagerSubmitted || props.isRatingDisable || props.isPage === "HR"}
                          options={options}
                          className={[
                            "custom-default-dd-box",
                            props.ratingErrors?.find(
                              (value) =>
                                value.queId === props.item.questionId &&
                                value.error &&
                                value.isSubmit &&
                                value.type === "RATING"
                            ) && "error-box",
                          ].join(" ")}
                          classNamePrefix="select"
                          isSearchable={false}
                          defaultValue={finalRating(data)}
                          onChange={(value) => props.sendRating(value, props.item, data.employeeId)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </>
        )}
        {displayComment && (
          <div className={["view_comment_box_wrapper", props.isManagerSubmitted && "bg-white"].join(" ")}>
            <CommentsTop heading="Share your view(s)" />
            {props.item.ratingCommentByRpm &&
              props.item.ratingCommentByRpm
                .filter(
                  (value) =>
                    value.comment &&
                    (props.isPage === "REVIEWING" ||
                      props.isPage === "USER" ||
                      value.employeeId !== loggedInUser?.employeeId ||
                      props.isManagerSubmitted)
                )
                .map((data) => (
                  <CommentWithAttachment
                    key={data.employeeId}
                    image={data.userDataAssessment.dispArr || data.userDataAssessment.empInitial}
                    selectedColor={backGroundColor[1]}
                    empName={data.userDataAssessment.empName}
                    commentedOn=""
                    comment={data.comment}
                    files={data.file}
                    downloadFile={(fileId) => props.downloadFile(fileId, props.item.questionId)}
                  />
                ))}
            {props.item.ratingCommentByRvm &&
              props.item.ratingCommentByRvm
                .filter(
                  (value) =>
                    value.comment &&
                    (props.isPage === "HR" ||
                      props.isPage === "USER" ||
                      (value.employeeId === loggedInUser?.employeeId && props.isManagerSubmitted) ||
                      (props.isPage === "REPORTING" && value.reporteeEmployeeId === loggedInUser?.employeeId))
                )
                .map((data) => (
                  <CommentWithAttachment
                    key={data.employeeId}
                    image={data.userDataAssessment.dispArr || data.userDataAssessment.empInitial}
                    selectedColor={backGroundColor[1]}
                    empName={data.userDataAssessment.empName}
                    commentedOn=""
                    comment={data.comment}
                    files={data.file}
                    downloadFile={(fileId) => props.downloadFile(fileId, props.item.questionId)}
                  />
                ))}
            {/* For HR and user isManagerSubmitted is always true */}
            {!props.isManagerSubmitted && (
              <>
                <CommentBox
                  selectedColor={backGroundColor[2]}
                  error={props.ratingErrors?.find(
                    (value) =>
                      value.queId === props.item.questionId && value.error && value.isSubmit && value.type === "COMMENT"
                  )}
                  blurHandler={(e) => props.sendManagerComment(e.target.value, props.item)}
                  image={loggedInUser.displayPicture || loggedInUser.empInitial}
                  comment={comment}
                  commentLength={5000}
                  handleMultipleFileUpload={(e) => props.fileUploadByManager(e, props.item.questionId)}
                  isMultipleFile={true}
                />
                {fileBeforeSubmit && fileBeforeSubmit.length > 0 && (
                  <div className="multiple_uploaded_file">
                    <MultipleFile
                      isDownloadable={true}
                      isDeletable={true}
                      downloadFile={(fileId) => props.downloadFile(fileId, props.item.questionId)}
                      files={fileBeforeSubmit}
                      removeFile={(fileId) => props.deleteFile(fileId, props.item.questionId)}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    ),
  };
  return <WrappedComponent {...newProps} />;
};

export default withRatingAndComment;
