import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import style from "./PagenotFound.module.scss";
import { IMG_PAGENOTFOUND } from "const/imgCost";
import routePath from "const/routePath";

class PagenotFound extends Component {
  render() {
    return (
      <Row className={style.pagenotfound_wrapper}>
        <div className="container">
          <Row className={style.pagenotfound_datawrapper}>
            <Col className={style.pagenotfound_data}>
              <div className={style.pagenotfound_img}>
                <ReactSVG src={`${IMG_PAGENOTFOUND}`} title="" alt="" />
              </div>
              <div className={style.pagenotfound_title}>Something went wrong!</div>
              <p className={style.pagenotfound_txt}>This page is missing or you have assembled the link incorrectly</p>
              <Link to={routePath.HOME}>
                <div className={style.pagenotfound_link}>Back to home</div>
              </Link>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
export default PagenotFound;
