import React, { useState, useEffect } from "react";
import style from "./MyObjectives.module.scss";
import { Row, Col, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Pagination from "../../Pagination/Pagination";
import CurrentObjectiveRow from "./CurrentObjectiveRow";
import NoDataFound from "../../NodataFound/NoDataFound";
import { filterArray } from "../../../util/general";
import FilterTeam from "../../FilterTeam/FilterTeam";
import { noData } from "const/constValue";

export default function ObjectivesList(props) {
  const [pageOfItems, setPageOfItems] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState("CURRENT");
  const [filteredData, setFilteredData] = useState(props.objectives);
  const [objectiveType, setObjectiveType] = useState("Current");

  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);
  const pageSize = useSelector((state) => state.myTeam.pageSize);

  useEffect(() => {
    handleStatusChange("CURRENT");
  }, []);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      title: searchFilter ? searchFilter : "",
      type: statusFilter ? statusFilter : "",
    };
    if (props.objectives && props.objectives.length > 0) {
      filteredData = filterArray(props.objectives, filters);
      setFilteredData(filteredData);
      setSearchFilter("");
    }
  };

  const search = (value) => {
    let filteredData = null;
    const filters = {
      title: value,
      type: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(props.objectives, filters);
    setFilteredData(filteredData);
    setSearchFilter(value);
  };

  const handleStatusChange = (value) => {
    if (value === "COMPLETED") setObjectiveType("Completed");
    else if (value === "EXPIRED") setObjectiveType("In-completed");
    else if (value === "CURRENT") setObjectiveType("Current");
    let filteredData = null;
    let filters = {
      title: searchFilter ? searchFilter : "",
      type: value,
    };
    filteredData = filterArray(props.objectives, filters);
    setStatusFilter(value);
    setFilteredData(filteredData);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      title: "",
      type: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(props.objectives, filters);
    setFilteredData(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter("");
  };

  useEffect(() => {
    filteredData.length > 0
      ? setTeamData(filteredData)
      : statusFilter || searchFilter
      ? setTeamData(filteredData)
      : setTeamData(props.objectives);
  }, [filteredData, props.objectives]);

  const filter = {
    value: ["CURRENT", "COMPLETED", "EXPIRED"],
    name: ["Current", "Completed", "In-completed"],
  };

  return (
    <div>
      <div className={style.tab_container_wrapper}>
        <div className={style.objective_heading}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className={style.tab_title}>
                <h2>{objectiveType} Objectives</h2>
                <h3>Tap on an objectives to view more details</h3>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}></Col>
          </Row>
        </div>
        {props.objectives && (
          <FilterTeam
            placeholder="E.g. Java, React JS, UX"
            filter={filter}
            value={searchFilter ? searchFilter : ""}
            teamData={props.objectives}
            onSearch={(e) => search(e.target.value)}
            onRadioChange={(e) => handleStatusChange(e.target.value)}
            onReset={resetData}
            filterValue={statusFilter}
            onResetSearch={resetSearch}
          />
        )}
        <div className="box-light-gradient"></div>
        <div className={style.allobjective_table}>
          <Col xs={12}>
            <div className="custom-table table-theme-1 pt40">
              {teamData && teamData.length > 0 ? (
                <Table className={style.tab_table_responsive}>
                  <thead>
                    <tr>
                      <td className={style.name}>Name</td>
                      <td className={style.objectivesstatus}>Objectives Status</td>
                      <td className={style.assigned_by}>Assigned By</td>
                      {/* <td className={style.key_result}>Key Results</td> */}
                      <td className={style.duration}>Key Value</td>
                      <td className={style.action}>Progress</td>
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItems.map((objective) => (
                      <CurrentObjectiveRow
                        objective={objective}
                        key={objective.userObjectiveId}
                        isPage={props.isPage}
                        employeeId={props.employeeId}
                      />
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div>
                  <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                  <div className="gap80"></div>
                  <div className="gap40"></div>
                </div>
              )}
            </div>
          </Col>
        </div>
      </div>

      <div className="mt-4">
        {teamData && teamData.length > 0 && (
          <Pagination pageSize={pageSize} items={teamData} resetData={resetData} onChangePage={onChangePage} />
        )}
      </div>
    </div>
  );
}
