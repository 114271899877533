import React from "react";
import { ReactSVG } from "react-svg";
import { IC_FILE_ATTACHMENT } from "const/imgCost";
import ActionBox from "./ActionBox/ActionBox";
import SingleComponent from "./SingleComponent";

const Answer = (props) => {
  return (
    <>
      <div className="two_column">
        <div className="box_inner_title">{props.title}</div>
        <ActionBox {...props} />
      </div>
      <div className={props.style.questions_answer_row}>
        <div className={["w-100", props.style.questions_answer].join(" ")}>
          <ul className="common_list_items">
            {props.answer && props.answer.length < 250 ? (
              <li>{props.answer ? props.answer : "N/A"}</li>
            ) : (
              <SingleComponent id={props.question.queId} comment={props.answer} />
            )}
          </ul>
          <ul className="file-listing w-100">
            {props.question.file && (
              <li>
                <a target="_blank" onClick={() => props.downloadFile(props.question.queId, props.surveyCycleId)}>
                  <ReactSVG className="file-attachment-icon" src={`${IC_FILE_ATTACHMENT}`} title="" alt="" />
                  {props.question.file.name}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Answer;
