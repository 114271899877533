/* eslint-disable no-unused-vars */
// const $ = window.$;
import $ from "jquery";
import storage from "./storage";
import { useLocation } from "react-router-dom";
import { ActionType } from "context/authContext";
import * as CryptoJS from "crypto-js";
import { successToast } from "./general";

export function RatingAnimation() {
  /* Rating animation starts*/
  if ($) {
    $(".current-feeling-box .rating li .count").on("click", function () {
      $(document).find(".staticActive").removeClass("staticActive");
      $(document).find(".resizeActive").removeClass("resizeActive");
      $(this).closest("li").toggleClass("active").siblings().removeClass("active");
      $(this).closest("#wrapper").toggleClass("outer-wrapper active");
      //hide question form and visible other siblings
      $(this).parent().parent().children(".outer-panel").find(".question-form").fadeOut(500).prevAll().fadeIn(500);

      /*start translate function */
      var widthAdd = 0;
      var currEle = $(this).closest("li").toggleClass("opened");
      /* var toggleEle = $(currEle).siblings().toggleClass('opacity'); */
      $(currEle).siblings().toggleClass("opacity");
      var prevEle = $(currEle).prevAll().length;
      /* let space = 1; */
      for (let i = 0; i < prevEle; i++) {
        widthAdd = widthAdd + parseInt($(".opened").siblings().eq(i).outerWidth());
      }
      if ($(document).find(".rating li").hasClass("active")) {
        //var positionChange = $(this).parent().parent().css({ 'transform': 'translateX(-' + (widthAdd + 0) + 'px)' });
        /* var box = $('.rating').first(); */
        /* var currentPos = box.position(); */
        /* var leftPos = currentPos.left; */
        var tarGetPos = $(this).closest("li").first();
        var targetCur = tarGetPos.position();
        var targetLeftPos = targetCur.left;
        var ratingWidth = $(currEle).parent().outerWidth();
        var ratingHeight = $(currEle).parent().parent().outerHeight();
        var newPos = targetLeftPos - 10;
        $(this)
          .closest("li")
          .css({ transform: "translateX(-" + newPos + "px)" });
        /* var outerPanelPosition = $(this).parent().parent().find('.outer-panel').css('width', (ratingWidth - 100));
      var outerPanelPositionHeight = $(this).parent().parent().find('.outer-panel').css('height', (ratingHeight)); */
        $(this)
          .parent()
          .parent()
          .find(".outer-panel")
          .css("width", ratingWidth - 100);
        $(this).parent().parent().find(".outer-panel").css("height", ratingHeight);

        let getTheme = $(this).parent().attr("data-title");

        if (getTheme === "one") {
          //alert('1');
          $(".feeling-box").addClass("one").removeClass("two three four five");
        } else if (getTheme === "two") {
          //alert('2');
          $(".feeling-box").addClass("two").removeClass("one three four five");
        } else if (getTheme === "three") {
          //alert('3');
          $(".feeling-box").addClass("three").removeClass("two one four five");
        } else if (getTheme === "four") {
          //alert('4');
          $(".feeling-box").addClass("four").removeClass("two three one five");
        } else if (getTheme === "five") {
          //alert('5');
          $(".feeling-box").addClass("five").removeClass("two three four one");
        }
      } else if ($(document).find(".rating li:not(.active)")) {
        //var positionDefault = $(this).parent().parent().css({ 'transform': 'translateX(0px)' });
        $(this).closest("li").css({ transform: "translateX(0)" });
        let getTheme = $(this).parent().attr("data-title");

        if (getTheme === "one") {
          //alert('1');
          $(".feeling-box").addClass("one").removeClass("two three four five");
        } else if (getTheme === "two") {
          //alert('2');
          $(".feeling-box").addClass("two").removeClass("one three four five");
        } else if (getTheme === "three") {
          //alert('3');
          $(".feeling-box").addClass("three").removeClass("two one four five");
        } else if (getTheme === "four") {
          //alert('4');
          $(".feeling-box").addClass("four").removeClass("two three one five");
        } else if (getTheme === "five") {
          //alert('5');
          $(".feeling-box").addClass("five").removeClass("two three four one");
        }
      }

      //create one function and adding or remove animate class name
      let animateButton = function (e) {
        //e.preventDefault;
        //reset animation
        e.target.classList.remove("animate");
        e.target.classList.add("animate");
        setTimeout(function () {
          e.target.classList.remove("animate");
        }, 10000);
      };

      /* var bubblyButtons = document.getElementsByClassName("count");
    //check the length of the button
    for (let i = 0; i < bubblyButtons.length; i++) {
      bubblyButtons[i].addEventListener('click', animateButton, false);
    } */
    });

    //for question form
    $(".questions").on("click", function () {
      var currElement = $(".questions");
      $(this).fadeOut(500).parent().find(".title").fadeOut(500);
      $(this).parent().find(".question-form").fadeIn(500);

      if (currElement.closest("li").hasClass("staticActive")) {
        var ratingWidthL = $(this).closest("li").parent().outerWidth();
        var ratingHeightL = $(this).closest("li").parent().outerHeight();
        $(this)
          .parent()
          .parent(".outer-panel")
          .css("width", ratingWidthL - 100);
        $(this).parent().parent(".outer-panel").css("height", ratingHeightL);
      }
    });

    //window resize
    $(document).ready(function () {
      $(window).bind("resize", function () {
        var element = $(document).find(".current-feeling-box .rating li");
        if ($(window).width() > 319 && $(window).width() < 1025) {
          var elementActiveFind = $(element).hasClass("active opened");
          // var elementParentFind = $(".rating").addClass("resizeActive");
        }
      });
    });
  }
}

export function ProgressBarAnimation() {
  /* Add class dynamically for different colours in Progress bar Starts*/
  let progressWidth = $(".progress").delay(400).width();
  let progressBarWidth = $(".progress-bar").delay(400).width();
  let cellPercentage = (progressBarWidth / progressWidth) * 100;
  cellPercentage = Math.ceil(cellPercentage);

  if (cellPercentage <= 20) {
    $(".progress-bar").addClass("one");
  } else if (cellPercentage > 20 && cellPercentage <= 40) {
    $(".progress-bar").addClass("two");
  } else if (cellPercentage > 40 && cellPercentage <= 60) {
    $(".progress-bar").addClass("three");
  } else if (cellPercentage > 60 && cellPercentage <= 80) {
    $(".progress-bar").addClass("four");
  } else if (cellPercentage > 80 && cellPercentage <= 100) {
    $(".progress-bar").addClass("five");
  }
}

export function imgToSvg() {
  document.querySelectorAll("img.svg").forEach((el) => {
    const imgID = el.getAttribute("id");
    const imgClass = el.getAttribute("class");
    const imgURL = el.getAttribute("src");

    fetch(imgURL)
      .then((data) => data.text())
      .then((response) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response, "text/html");
        let svg = xmlDoc.querySelector("svg");
        if (svg && el && el.parentNode) {
          if (typeof imgID !== "undefined") {
            svg.setAttribute("id", imgID);
          }

          if (typeof imgClass !== "undefined") {
            svg.setAttribute("class", imgClass + " replaced-svg");
          }

          svg.removeAttribute("xmlns:a");

          el.parentNode.replaceChild(svg, el);
        }
      });
  });
}

export function moduleView(module) {
  //if sub navigation not there then return main menu navigation
  if (storage.getModules()?.find((subModule) => subModule.moduleName === module)?.subModuleAndRights?.length > 0) {
    return storage
      .getModules()
      ?.find((subModule) => subModule.moduleName === module)
      ?.subModuleAndRights?.map((item) => item.subModuleName);
  } else {
    return storage.getModules()?.map((subModule) => subModule.moduleName);
  }
}

export function pathValidate(view, url) {
  let urlList = [];
  let modules = storage.getPageView()?.find((v) => v.pageView === view)?.modulesAndRights;
  if (modules?.length > 0) {
    modules.map((module) => {
      if (module.subModuleAndRights?.length > 0) {
        module.subModuleAndRights.map((v) => urlList.push(v.url));
      } else {
        urlList.push(module.url);
      }
    });
  }
  return urlList.includes(url);
}

export function moduleRights(module, subModule) {
  //if subModule is not available
  if (storage.getModules()?.find((subModule) => subModule.moduleName === module)?.subModuleAndRights?.length > 0) {
    return storage
      .getModules()
      ?.find((subModule) => subModule.moduleName === module)
      ?.subModuleAndRights?.find((item) => item.subModuleName === subModule)?.rights;
  } else {
    return storage.getModules()?.find((subModule) => subModule.moduleName === module)?.rights;
  }
}

export function toFixedNumber(value, number) {
  if (value === 0) return 0;
  if (value) {
    if (isNaN(parseFloat(value).toFixed(number))) return "-";
    else return Number.isInteger(parseFloat(value)) ? parseFloat(value) : parseFloat(value).toFixed(number);
  } else {
    return "-";
  }
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function setDefaultMenu(context) {
  let modules = storage.getDefaultMenu();
  let moduleEncrypted = CryptoJS.AES.encrypt(JSON.stringify(modules), process.env.REACT_APP_CRYPTO_PRIVATE_KEY);
  storage.set("_MO_Vu__", moduleEncrypted.toString());
  context.dispatch({ type: ActionType.CHANGE_PAGE, payload: modules });
}

export function downloadPdfFile(pdfUrl) {
  fetch(pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = window.URL.createObjectURL(new Blob([blob]));
      const fileName = pdfUrl.split("/").pop();
      const link = document.createElement("a");
      link.href = blobURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      successToast("File downloaded Successfully!");
    });
}
