import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import style from "./AddCategoryModal.module.scss";

const AddCategoryModal = (props) => {

  const [category, setCategory] = useState(null);
  const handleCategory = (e) => { setCategory(e.target.value) }

  useEffect(() => {
    if (props.currentCategory) {
      setCategory(props.currentCategory.categoryName)
    }
  }, [props.currentCategory])

  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} size="md" animation={false} className="custom_modal_popup view_certificate_modal">
        <div className="custom_modal_container custom_lg_modal_container">
          <Modal.Header className={style.mHeader}>
            <Modal.Title>
              {props.currentCategory ? 'Update Category' : 'Add New Category'}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="box-light-gradient h38"></div>
            <div className={style.category_add_wrapper}>
              <Form.Group controlId="">
                <Form.Label className={style.dd_title}>Category</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Category Name (E.g. FrontEnd, BackEnd etc…)"
                  value={category? category : ""}
                  onChange={handleCategory}
                  maxLength='100'
                ></Form.Control>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={() => props.hide(category)} disabled={category && category.trim() ? false : true}>
              {props.currentCategory ? 'Update' : 'Add'}
            </Button>
            <Button variant="secondary" className="fs-500 ml-3" onClick={() => props.hideModal()}>Cancel</Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
