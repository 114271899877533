import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";
import * as actions from "store/actions/index";
import { IC_COMMENT, IC_CHAT_BUBBLE, IC_ACHIEVEMENT } from "const/imgCost";
import { CLEAR_ADD_WIN_CHALLENGE_RESPONSE } from "store/actions/actionTypes";
import ModalsPopup from "../../ModalsPopup/ModalsPopup";
import { errorToast, successToast } from "util/general";

const ActionBox = (props) => {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);
  const { addWinChallengeResponse } = useSelector((state) => state.winChallenge);
  const configReviewedDays = process.env.REACT_APP_MANAGER_COMMENTS_DAYS;

  const winChallengeHandler = (surveyCycleId, surveyCycle, questionId, type) => {
    let employeeIds = {
      employeeId: surveyCycle.employeeId,
      parentGroupId: surveyCycle.employeeProfile.parentGroupId,
      subGroupId: surveyCycle.employeeProfile.subGroupId,
    };
    dispatch(actions.addWinChallenge(surveyCycleId, employeeIds, questionId, type));
  };

  useEffect(() => {
    if (addWinChallengeResponse) {
      let { status, message } = addWinChallengeResponse;
      if (status === "SUCCESS") {
        successToast(message);
      } else {
        errorToast(message);
      }
      dispatch({ type: CLEAR_ADD_WIN_CHALLENGE_RESPONSE });
    }
  }, [addWinChallengeResponse, dispatch]);

  let winChallangeType = null;

  if (props.question.winChallengeType === "WIN") winChallangeType = "WIN";
  else if (props.question.winChallengeType === "CHALLENGE") winChallangeType = "CHALLENGE";
  else winChallangeType = "COLLECTIVE";

  let commentToggle,
    dropdownSelection = null;
  if (props.isPage === "myteam" && props.reviewDays <= configReviewedDays) {
    commentToggle = (
      <div onClick={props.isOpen} className="comment_svg_icon">
        <ReactSVG className="svg" src={`${IC_COMMENT}`} alt="comment" />
      </div>
    );
  }
  if (props.status === "REVIEWED" && props.isPage === "myteam" && !props.childEmployee && props.isQuestion !== "mood") {
    dropdownSelection = (
      <div className="action-dropdown-wrapper">
        <DropdownButton
          alignRight
          title={
            <div className="action-dropdown">
              <span className="dots"></span>
              <span className="dots"></span>
              <span className="dots"></span>
            </div>
          }
          variant="a"
        >
          {!(props.oneOnOnecomment && props.oneOnOnecomment.length > 0) && (
            <Dropdown.Item>
              <div className="dropdown_icon one_on_one_icon">
                <ReactSVG src={`${IC_CHAT_BUBBLE}`} alt="" />
              </div>
              <div className="dropdown_item" onClick={() => props.openOneOnOne(true)}>
                Add to 1-on-1 agenda
              </div>
            </Dropdown.Item>
          )}

          {!props.isAdded && props.answer && props.answer !== "N/A" && (
            <Dropdown.Item onClick={() => setDialog(true)}>
              <div className="dropdown_icon">
                <ReactSVG src={`${IC_ACHIEVEMENT}`} alt="" />
              </div>
              <div className="dropdown_item">Add to {winChallangeType && winChallangeType.toLowerCase()}</div>
            </Dropdown.Item>
          )}
        </DropdownButton>
        <ModalsPopup
          show={dialog}
          hide={() => {
            setDialog(false);
          }}
          toType={winChallangeType}
          submit={() => {
            winChallengeHandler(props.surveyCycleId, props.surveyCycle, props.question.queId, winChallangeType);
            setDialog(false);
          }}
        />
      </div>
    );
  }

  if (props.isPage === "myhistory" || props.isPage === "checkin") {
    dropdownSelection = (
      <div className="action-dropdown-wrapper">
        <DropdownButton
          alignRight
          title={
            <div className="action-dropdown">
              <span className="dots"></span>
              <span className="dots"></span>
              <span className="dots"></span>
            </div>
          }
          variant="a"
        >
          <Dropdown.Item>
            <div className="dropdown_icon one_on_one_icon">
              <ReactSVG src={`${IC_CHAT_BUBBLE}`} alt="" />
            </div>
            <div className="dropdown_item" onClick={() => props.openOneOnOne(true)}>
              Add to 1-on-1 agenda
            </div>
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }

  return (
    <div className="items_action_box">
      {commentToggle}
      {dropdownSelection}
    </div>
  );
};

export default ActionBox;
