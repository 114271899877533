import React, { useEffect, useState } from "react";
import MultiSelect from "react-multi-select-component";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { dateFormat2 } from "util/dateUtils";
import { IC_EYE } from "const/imgCost";
import { ReactSVG } from "react-svg";
import DetailsViewPopup from "../DetailsViewPopup/DetailsViewPopup";
import Pagination from "shared/Pagination/Pagination";
import NoDataFound from "components/NodataFound/NoDataFound";
import { noData, MonthlyCheckinTexts } from "const/constValue";
import style from "./PastCheckIns.module.scss";

const monthsOptions = MonthlyCheckinTexts.months.map((month) => {
  return { label: month, value: month };
});

export default function PastCheckIns({
  showYearListData,
  feedbackHistoryEmployee,
  totalCount,
  getFeedbackHistoryData,
  managerList,
  getManagerList,
  historyDetails,
  getHistoryDetails,
}) {
  const currentYear = (new Date()).getFullYear();
  const [selectedYear, setSelectedYear] = useState({label: currentYear, value: currentYear});
  const [selectedMonth, setSelectedMonth] = useState(monthsOptions);
  const [openPopup, setOpenPopup] = useState(false);
  const [period, setPeriod] = useState(null);

  useEffect(()=> {
    handleChangePage(1, 10);
  },[selectedYear, selectedMonth]);

  const yearsOptions = showYearListData?.map((year) => {
    return { label: year, value: year };
  });

  const renderStatus = (status) => {
    switch (status) {
      case "TOTAL_PENDING":
        return <span className={style.pending}>TOTAL PENDING</span>;
      case "PARTIALLY_PENDING":
        return <span className={style.pending}>PARTIALLY PENDING</span>;
      case "COMPLETED":
        return <span className={style.completed}>COMPLETED</span>;
      default:
        return <></>;
    }
  };

  const handleChangePage = (pageNumber, offSet) => {
    const periods = selectedMonth?.map(month => {
      return `${month?.value} ${selectedYear?.value}`
    })
    const body = {
      offSet,
      pageNumber,
      periods,
    };
    getFeedbackHistoryData(body);
  }

  const handleShow = (period, isActionIconDisable) => {
    if(!isActionIconDisable){
      setOpenPopup(!openPopup)
      setPeriod(period);
      getManagerList(period);
    }
  }

  return (
    <>
      <div className="box  past-checkins-box">
        <div className={`${style.heading_wrapper} row`}>
          <div className="col-md-6 col-sm-12">
            <h2>Past check-in's</h2>
          </div>
          <div className={`${style.date_wrapper}`}>
            <div>
              <Select
                data-test='select_year'
                options={yearsOptions}
                classNamePrefix="select_year"
                className={`custom-default-dd-box ${style.select_wrapper}`}
                placeholder="Select year"
                isSearchable={false}
                value={selectedYear}
                onChange={(year) => setSelectedYear(year)}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div>
              <MultiSelect
                data-test='select_month'
                options={monthsOptions}
                value={selectedMonth}
                disableSearch={true}
                onChange={(list) => setSelectedMonth(list)}
                labelledBy={"Select month"}
                hasSelectAll={true}
                overrideStrings={{ "selectSomeItems": "Select month", "allItemsAreSelected": "Select All" }}
              />
            </div>
          </div>
        </div>
        {feedbackHistoryEmployee?.length > 0 ? (
          <div className={style.table_wrapper}>
            <Table hover responsive className={style.tab_table_responsive}>
              <thead>
                <tr>
                  <th>Submitted Date( Employee )</th>
                  <th>Submitted Date( Manager ) </th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {feedbackHistoryEmployee?.map((pastCheckIn, i) => {
                  const {
                    submmittedDateEmployee,
                    submmittedDateMananger,
                    employeeStatus,
                    isActionIconDisable,
                    period,
                    startDate, 
                    endDate,
                  } = pastCheckIn;
                  return (
                    <tr key={`past_checkin_${i}`}>
                      <td>
                        <span>
                          {dateFormat2(submmittedDateEmployee) ?? "-"}
                        </span>
                      </td>
                      <td>
                        <span>
                          {dateFormat2(submmittedDateMananger) ?? "-"}
                        </span>
                      </td>
                      <td>{renderStatus(employeeStatus)}</td>
                      <td>
                        <span 
                          data-test='getManagerList'
                          onClick={() => handleShow(period, isActionIconDisable, startDate, endDate)}
                        >
                          <ReactSVG
                            src={`${IC_EYE}`}
                            className={style.eye_icon}
                            style={{
                              cursor: isActionIconDisable
                                ? "not-allowed"
                                : "pointer",
                            }}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div>
            <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            <div className="gap120"></div>
          </div>
        )}
      </div>
      {totalCount > 0 && (
        <Pagination totalItem={totalCount} onChangePage={handleChangePage} />
      )}
      {openPopup && (
        <DetailsViewPopup
          period={period}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          managerList={managerList}
          historyDetails={historyDetails}
          getHistoryDetails={getHistoryDetails}
        />
      )}
    </>
  );
}