import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import style from "./HRGlobalFeedback.module.scss";
import * as actions from "store/actions/index";
import { filterArray, pleaseSelectDate } from "util/general";
import { getColorByIndex } from "util/color";
import { moduleRights } from "util/utils";
import { IC_COLLAPSE, IC_EXPAND } from "const/imgCost";
import { noData, menuText } from "const/constValue";
import config from "util/config";
import CommonDatePicker from "shared/CommonDatePicker";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";
import NoDataFound from "../../../NodataFound/NoDataFound";
import FilterTeam from "../../../FilterTeam/FilterTeam";
import HRGlobalFeedbackRow from "./HRGlobalFeedbackRow";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

const HRGlobalFeedbackView = () => {
  const { isLoading, globalFeedbackList } = useSelector((state) => state.hrDashboardReport);
  const dispatch = useDispatch();
  const [feedbackData, setFeedbackData] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [open, setOpen] = useState({ flag: false, id: null });
  const [filterData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState("RECEIVED");
  const [toggle, setToggle] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  useEffect(() => {
    handleStatusChange("RECEIVED");
  }, [globalFeedbackList]);

  useEffect(() => {
    if (globalFeedbackList?.length > 0) {
      filterData.length > 0
        ? setFeedbackData(filterData)
        : statusFilter || searchFilter
        ? setFeedbackData(filterData)
        : setFeedbackData(globalFeedbackList);
    }
  }, [filterData, globalFeedbackList]);

  const resetSearch = () => {
    let fData = null;
    const filters = {
      displayName: "",
      type: statusFilter ? statusFilter : "",
    };
    fData = filterArray(globalFeedbackList, filters);
    setFilteredData(fData);
    setSearchFilter("");
  };

  const search = (event) => {
    let fData = null;
    const filters = {
      displayName: event.target.value,
      type: statusFilter ? statusFilter : "",
    };
    fData = filterArray(globalFeedbackList, filters);
    setFilteredData(fData);
    setSearchFilter(event.target.value);
  };

  const resetData = () => {
    let fData = null;
    const filters = {
      displayName: searchFilter ? searchFilter : "",
      type: statusFilter ? statusFilter : "",
    };
    fData = filterArray(globalFeedbackList, filters);

    setFilteredData(fData);
    setSearchFilter(searchFilter);
  };

  const handleStatusChange = (value) => {
    let filteredData = null;
    const filters = {
      displayName: searchFilter ? searchFilter : "",
      type: value,
    };
    filteredData = filterArray(globalFeedbackList, filters);
    setStatusFilter(value);
    setFilteredData(filteredData);
  };

  const applyDateFilter = () => {
    if (!startDate && !endDate) {
      pleaseSelectDate();
      return;
    } else {
      // dispatch action
      const params = {
        startDate: moment(startDate).format("MM-DD-YYYY"),
        endDate: moment(endDate).format("MM-DD-YYYY"),
      };
      dispatch(actions.getGlobalFeedBackListForHR(params));
    }
  };

  const handleExportReport = () => {
    if (!startDate && !endDate) {
      pleaseSelectDate();
      return;
    } else {
      // dispatch action
      const params = {
        startDate: moment(startDate).format("MM-DD-YYYY"),
        endDate: moment(endDate).format("MM-DD-YYYY"),
      };
      dispatch(actions.downloadGlobalFeedBackReport(params));
    }
  };

  const toggleText = (feedbackID) => {
    if (toggle === feedbackID) {
      setToggle("");
    } else {
      setToggle(feedbackID);
    }
  };

  const filter = {
    value: ["RECEIVED", "SENT"],
    name: ["Received", "Sent"],
  };

  return (
    <div className={style.teams_feedback_wrapper}>
      <SubNavigation type="Feedback" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">{menuText.HR}</h1>
            <span className="st-breadcrumb">
              {menuText.FEEDBACK}
              <span>{menuText.EMPLOYEE_FEEDBACK_HISTORY}</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className={["box", style.date_range_wrapper].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Select Date Range</h2>
                </div>
              </div>
              <div className="cl_t_wrapper">
                <div className="calendar_wrapper form-group date_range_calendar_wrapper ">
                  <CommonDatePicker
                    handleStartDate={(date) => setStartDate(date)}
                    handleEndDate={(date) => setEndDate(date)}
                    startDate={startDate && startDate}
                    endDate={endDate && endDate}
                    startDatePlaceHolder="Start Date"
                    endDatePlaceHolderText="End Date"
                  />
                </div>
                <div className={style.button_group}>
                  <button type="button" className="btn btn-secondary date-range-apply-btn" onClick={applyDateFilter}>
                    <strong>Apply</strong>
                  </button>
                  {moduleRights(config.hrModule.FEEDBACK, config.subModule.EMPLOYEE_FEEDBACK_HISTORY)?.includes(
                    "EXPORT"
                  ) && (
                    <button
                      type="button"
                      className="btn btn-primary date-range-apply-btn"
                      onClick={handleExportReport}
                      data-test="export"
                    >
                      <strong>Download Feedback History</strong>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className={[style.my_team_feedback_box, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading w-auto">
                  <h2>Feedback History</h2>
                  <h3>You can view feedback of employees</h3>
                </div>
              </div>
              {globalFeedbackList && globalFeedbackList.length > 0 && (
                <FilterTeam
                  filterValue={statusFilter}
                  placeholder="Search for team members"
                  filter={filter}
                  value={searchFilter ? searchFilter : ""}
                  teamData={feedbackData}
                  onSearch={search}
                  onRadioChange={(e) => handleStatusChange(e.target.value)}
                  onReset={resetData}
                  onResetSearch={resetSearch}
                />
              )}
              <div className="box-light-gradient"></div>
              {isLoading ? (
                <Spinner />
              ) : (
                <div className={style.table_responsive}>
                  <div className={style.table_width}>
                    {pageOfItems && pageOfItems.length > 0 ? (
                      <div className="box-inner-pad">
                        <div className={style.panel_title}>
                          <span className={style.nameTitle}>Name</span>
                          <span className={style.statusTitle}>Feedback Status</span>
                          <span className={style.dateTitle}>Date</span>
                          <span className={style.feedbackTitle}>Feedback</span>
                        </div>
                        <div className={style.listing_wrapper}>
                          {pageOfItems.map((data, index) => {
                            return (
                              <div className={style.item} key={data.employeeId}>
                                <div
                                  className={[
                                    style.item_info_top_wrapper,
                                    open.flag === true && open.id === data.employeeId ? "active" : "",
                                  ].join(" ")}
                                >
                                  <div className={style.item_topbar}>
                                    <div
                                      className={style.member_info}
                                      onClick={() => {
                                        if (data.globalFeedbackList.length) {
                                          if (open.id !== data.employeeId) {
                                            setOpen({
                                              flag: true,
                                              id: data.employeeId,
                                            });
                                          } else {
                                            setOpen({
                                              flag: !open.flag,
                                              id: data.employeeId,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <div className={style.expand_collaspe_Wrapper_Container}>
                                        {data.globalFeedbackList.length ? (
                                          <i className={style.expand_collapse_wrapper}>
                                            {open.id === data.employeeId && open.flag ? (
                                              <img src={IC_COLLAPSE} className={style.collapse} alt="Collapse" />
                                            ) : (
                                              <img src={IC_EXPAND} className={style.expand} alt="Expand" />
                                            )}
                                          </i>
                                        ) : null}
                                      </div>

                                      <span
                                        className={style.user_img}
                                        style={{ backgroundColor: getColorByIndex(index) }}
                                      >
                                        {data.displayPicture && data.displayPicture.length > 2 ? (
                                          <img src={data.displayPicture} alt="pic" />
                                        ) : (
                                          data.displayPicture
                                        )}
                                      </span>
                                      <div className={style.user_info}>
                                        <span className={style.username}>
                                          {data.displayName ? data.displayName : "--"}
                                        </span>
                                        <span className={style.department}>
                                          {data.designation || "--"} | {data.department || "--"}
                                        </span>
                                      </div>
                                    </div>
                                    <span>{data.type === "SENT" ? "Sent" : "Received"}</span>
                                  </div>
                                </div>
                                {open.id === data.employeeId &&
                                  open.flag &&
                                  data.globalFeedbackList.length &&
                                  data.globalFeedbackList.map((userFeedback, userFeedbackIndex) => (
                                    <HRGlobalFeedbackRow
                                      key={
                                        data.type === "SENT"
                                          ? userFeedback.givenTo?.employeeId
                                          : userFeedback.givenBy?.employeeId
                                      }
                                      displayPicture={
                                        data.type === "SENT"
                                          ? userFeedback.givenTo?.displayPicture
                                          : userFeedback.givenBy?.displayPicture
                                      }
                                      empName={
                                        data.type === "SENT"
                                          ? userFeedback.givenTo?.empName
                                          : userFeedback.givenBy?.empName
                                      }
                                      toggle={toggle}
                                      toggleText={toggleText}
                                      employeeId={
                                        data.type === "SENT"
                                          ? userFeedback.givenTo?.employeeId
                                          : userFeedback.givenBy?.employeeId
                                      }
                                      designation={
                                        data.type === "SENT"
                                          ? userFeedback.givenTo?.designation
                                          : userFeedback.givenBy?.designation
                                      }
                                      department={
                                        data.type === "SENT"
                                          ? userFeedback.givenTo?.department
                                          : userFeedback.givenBy?.department
                                      }
                                      feedbackList={userFeedback.feedbackList}
                                      userFeedbackIndex={userFeedbackIndex}
                                    />
                                  ))}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <>
                        <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                        <div className="gap80"></div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        {isLoading ? (
          <Spinner />
        ) : (
          globalFeedbackList &&
          globalFeedbackList.length > 0 && (
            <Pagination pageSize={"10"} items={feedbackData} onChangePage={onChangePage} resetData={resetData} />
          )
        )}
      </Container>
    </div>
  );
};

export default HRGlobalFeedbackView;
