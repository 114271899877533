import React, { useState, useEffect, useRef } from "react";
import { Form, Tooltip, Overlay } from "react-bootstrap";
import { IC_DUSTBIN, IC_CB_OBJ, IC_OBJ_ARROW_UP, IC_OBJ_ARROW_DOWN, IC_OBJ_RANGE } from "../../../const/imgCost";
import { ReactSVG } from "react-svg";
import * as actions from "../../../store/actions/index";
import { useSelector, useDispatch } from "react-redux";
import { keyPointType } from "../../../util/general";
import { IC_ERROR } from "../../../const/imgCost";
import SlideToast from "../../../components/Toast/Toast";
import { toast } from "react-toastify";

const KeyResult = (props) => {
  const dispatch = useDispatch();
  const { keyResultErrors } = useSelector((state) => state.objectives);

  const keyLength = keyPointType(props.keyPoint.type);
  const [SELength, setSELength] = useState(keyLength);

  let intialKeys = { ...props.keyPoint };
  if (props.keyPoint.type === "?") {
    delete intialKeys.start;
    delete intialKeys.end;
  }

  const isEditable = props.isEditable ? false : true;
  const [isOpen, setIsOpen] = useState(isEditable);
  const [keyResult, setkeyResult] = useState(intialKeys);
  const [isValidate, setIsValidate] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const targetQuestion = useRef(null);

  const [isUpdate, setIsUpdate] = useState(props.isUpdate);

  useEffect(() => {
    checkKeyResultValidation();
  }, [keyResult]);

  const checkKeyResultValidation = () => {
    if (isStarted) {
      if (Object.values(keyResult).indexOf("") > -1) {
        setIsValidate(true);
        addError(props.index);
      } else if (
        keyResult.type !== "?" &&
        (+keyResult.start === +keyResult.end || +keyResult.start < 0 || +keyResult.end < 0)
      ) {
        setIsValidate(true);
        addError(props.index);
      } else if (
        keyResult.type !== "?" &&
        keyResult.type === "down" &&
        (+keyResult.start === +keyResult.end || +keyResult.start < 0 || +keyResult.end > keyResult.start)
      ) {
        setIsValidate(true);
        addError(props.index);
      } else if (
        keyResult.type !== "?" &&
        keyResult.type === "up" &&
        (+keyResult.start === +keyResult.end || +keyResult.start < 0 || +keyResult.end < keyResult.start)
      ) {
        setIsValidate(true);
        addError(props.index);
      } else if (keyResult.type !== "?" && !keyResult.start.toString().match(/^\d*\.?\d*$/)) {
        setIsValidate(true);
        addError(props.index);
      } else if (keyResult.type !== "?" && !keyResult.end.toString().match(/^\d*\.?\d*$/)) {
        setIsValidate(true);
        addError(props.index);
      } else if (
        keyResult.weightage === "" ||
        !keyResult.weightage.toString().match(/^\d*\.?\d*$/) ||
        keyResult.weightage < 1 ||
        keyResult.weightage > 100
      ) {
        setIsValidate(true);
        addError(props.index);
      } else {
        removeError(props.index);
        setIsValidate(false);
      }
    }
  };

  const addError = (key) => {
    let errors = [...keyResultErrors];
    let error = { key: key };

    let index = errors.findIndex((x) => x.key === key);
    index === -1 && errors.push(error);
    dispatch(actions.SetKeyResultErrors(errors));
  };

  const removeError = (key) => {
    let errors = null;
    errors = keyResultErrors.filter((item) => item.key !== key);
    dispatch(actions.SetKeyResultErrors(errors));
  };

  const descriptionHandler = (event) => {
    setIsStarted(true);
    setIsUpdate(true);
    setkeyResult({
      ...keyResult,
      description: event.target.value.trim(),
    });
    setIsOpen(true);
  };

  const stretchHandle = (event) => {
    setIsUpdate(true);
    setkeyResult({
      ...keyResult,
      stretch: event.target.value === "true" ? true : false,
    });
  };

  const typeHandler = (event) => {
    let newKeyResult = { ...keyResult };
    setIsStarted(true);
    setIsUpdate(true);
    const typeLength = keyPointType(event.target.value);
    setSELength(typeLength);

    if (event.target.value === "?") {
      delete newKeyResult.start;
      delete newKeyResult.end;
    } else {
      newKeyResult.start = "";
      newKeyResult.end = "";
    }

    newKeyResult.type = event.target.value;

    setkeyResult(newKeyResult);

    document.getElementById(props.index + "_start").value = "";
    document.getElementById(props.index + "_end").value = "";
  };

  const startHandler = (event) => {
    const value = event.target.value.trim();
    setIsStarted(true);
    setIsUpdate(true);
    setkeyResult({
      ...keyResult,
      start: value,
    });
    document.getElementById(props.index + "_start").value = value;
  };

  const endHandler = (event) => {
    const value = event.target.value.trim();
    setIsStarted(true);
    setIsUpdate(true);
    setkeyResult({
      ...keyResult,
      end: value,
    });
    document.getElementById(props.index + "_end").value = value;
  };

  const deleteKeyPoint = (keyId, ObjectiveId, index) => {
    dispatch(actions.DeleteKeyPoint(keyId, ObjectiveId, index, props.isEditObjective && "EDIT"));
  };

  const weightageHandler = (event) => {
    setIsStarted(true);
    setIsUpdate(true);
    setkeyResult({
      ...keyResult,
      weightage: event.target.value.trim(),
    });
    setIsOpen(true);
  };

  const saveKeyResultHandler = (objectiveId) => {
    if (Object.values(keyResult).indexOf("") > -1) {
      addError(props.index);
    } else if (
      keyResult.type !== "?" &&
      (+keyResult.start === +keyResult.end || +keyResult.start < 0 || +keyResult.end < 0)
    ) {
      addError(props.index);
    } else if (keyResult.type !== "?" && !keyResult.start.toString().match(/^\d*\.?\d*$/)) {
      addError(props.index);
    } else if (keyResult.type !== "?" && !keyResult.end.toString().match(/^\d*\.?\d*$/)) {
      addError(props.index);
    } else if (
      keyResult.weightage === "" ||
      !keyResult.weightage.toString().match(/^\d*\.?\d*$/) ||
      keyResult.weightage < 1 ||
      keyResult.weightage > 100
    ) {
      addError(props.index);
    } else {
      setIsOpen(false);
      setIsUpdate(false);
      removeError("create");
      if (!toast.isActive("KEY_RESULT_SUCCESS")) {
        toast.success(<SlideToast title="Awesome!" message="Key Result Saved." error={false} />, {
          toastId: "KEY_RESULT_SUCCESS",
        });
      }

      if (!props.objectiveId) {
        dispatch(actions.AddObjective({ keyPoints: { ...keyResult }, isSelfObjective: true }));
      } else {
        dispatch(
          actions.AddObjective(
            {
              objectiveId: props.objectiveId,
              keyPoints: { ...keyResult },
            },
            props.isEditObjective && "EDIT"
          )
        );
      }

      dispatch(actions.ObjectiveAdded(false));
    }
  };

  return (
    <Form className="pt-3">
      <div className={[props.style.textbox_wrapper, isOpen && props.style.open].join(" ")} ref={targetQuestion}>
        <div className={props.style.co_dustbin}>
          {props.keyPoint && props.keyPoint.keyId && props.objectiveId && props.keyPointLength > 1 && (
            <ReactSVG
              src={IC_DUSTBIN}
              onClick={() => deleteKeyPoint(props.keyPoint.keyId, props.objectiveId, props.index)}
            />
          )}
        </div>
        {/* <div className={props.style.co_setting} onClick={() => handleSetting()}>
                    <ReactSVG src={IC_SETTINGS} />
                </div> */}
        <Form.Control
          type="text"
          name="description"
          onChange={(e) => descriptionHandler(e)}
          // onChange = {(e) => descriptionHandler(e)}
          defaultValue={keyResult.description}
          maxLength="512"
          placeholder="Enter Key result Title"
        />
        <div className={props.style.open_objectives}>
          <div className={props.style.objective_top_bar}>
            <div className="se_bt_wrapper">
              <div className={props.style.objectives_criteria}>
                <div className={props.style.oc}>
                  <input
                    type="radio"
                    id={props.index + "percentage"}
                    name="type"
                    value="%"
                    checked={keyResult.type === "%"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.percentage_bg} htmlFor={props.index + "percentage"}>
                    %
                  </label>
                </div>
                <div className={props.style.oc}>
                  <input
                    type="radio"
                    id={props.index + "hash"}
                    name="type"
                    value="up"
                    checked={keyResult.type === "up"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.hash_bg} htmlFor={props.index + "hash"}>
                    <ReactSVG src={IC_OBJ_ARROW_UP} />
                  </label>
                </div>
                <div className={props.style.oc}>
                  <input
                    type="radio"
                    id={props.index + "dollar"}
                    name="type"
                    value="down"
                    checked={keyResult.type === "down"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.dollar_bg} htmlFor={props.index + "dollar"}>
                    <ReactSVG src={IC_OBJ_ARROW_DOWN} />
                  </label>
                </div>
                <div className={props.style.oc}>
                  <input
                    type="radio"
                    id={props.index + "range"}
                    name="type"
                    value="between"
                    checked={keyResult.type === "between"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.hours_bg} htmlFor={props.index + "range"}>
                    <ReactSVG src={IC_OBJ_RANGE} />
                  </label>
                </div>
                <div className={props.style.oc}>
                  <input
                    type="radio"
                    id={props.index + "ic_cb"}
                    name="type"
                    value="?"
                    checked={keyResult.type === "?"}
                    onChange={(e) => typeHandler(e)}
                  />
                  <label className={props.style.hours_bg} htmlFor={props.index + "ic_cb"}>
                    <ReactSVG src={IC_CB_OBJ} />
                  </label>
                </div>
              </div>
              <div className={props.style.base_target_wrapper}>
                <span className={keyResult.type === "%" ? "" : "d-none"}>Percentage</span>
                <span className={keyResult.type === "down" ? "" : "d-none"}>Down Skills</span>
                <span className={keyResult.type === "up" ? "" : "d-none"}>Up Skills</span>
                <span className={keyResult.type === "between" ? "" : "d-none"}>Range</span>
                <span className={keyResult.type === "?" ? "" : "d-none"}>Complete/Incomplete</span>
              </div>
            </div>
            <div className={props.style.start_end_wrapper}>
              <div className={["expectation_wrapper", keyResult.type !== "?" && "d-none"].join(" ")}>
                <span className="comp-incompn">Complete / Incomplete</span>
              </div>
              <div className={["se_wrapper se_bt_wrapper", keyResult.type === "?" && "d-none"].join(" ")}>
                <div className={["start-wrapper", props.style.sten_wrapper].join(" ")}>
                  <span className={props.style.value}>
                    <Form.Control
                      type="text"
                      name="start"
                      id={props.index + "_start"}
                      maxLength={SELength}
                      defaultValue={keyResult.start}
                      onChange={(e) => startHandler(e)}
                    />
                  </span>
                  <span className={props.style.label}>to</span>
                  <span className={props.style.value}>
                    <Form.Control
                      type="text"
                      name="end"
                      id={props.index + "_end"}
                      maxLength={SELength}
                      defaultValue={keyResult.end}
                      onChange={(e) => endHandler(e)}
                    />
                  </span>
                </div>
                <div
                  className={[
                    props.style.base_target_wrapper,
                    keyResult.type === "between" && props.style.range_wrapper,
                  ].join(" ")}
                >
                  {keyResult.type === "between" ? (
                    <span>Range</span>
                  ) : (
                    <>
                      <span>Base</span> <span>Target</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={["createobj_weightage", props.style.sten_wrapper].join(" ")}>
              <span className={props.style.label}>Weightage&nbsp;(%)</span>
              <span className={props.style.value}>
                <Form.Control
                  type="text"
                  name="weightage"
                  onChange={(e) => weightageHandler(e)}
                  defaultValue={keyResult.weightage}
                  maxLength="5"
                />
              </span>
            </div>
          </div>
          <div className={props.style.objective_bottom_bar}>
            <div className={[props.style.create_objective_wrapper, "radio_1on1_wrapper"].join(" ")}>
              <div className={props.style.create_objective_data_wrapper}>
                <Form.Group controlId="">
                  <Form.Label>Is this a Stretch Key Result?</Form.Label>
                  <ul className={props.style.form_option_list}>
                    <li>
                      <div className="custom-checkbox custom-checkbox-rounded">
                        <input
                          id={props.index + "stretch_yes"}
                          checked={keyResult.stretch === true}
                          onChange={(e) => stretchHandle(e)}
                          type="radio"
                          value={true}
                          name="stretchGoal"
                        />
                        <label htmlFor={props.index + "stretch_yes"}>Yes</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-checkbox custom-checkbox-rounded">
                        <input
                          id={props.index + "stretch_no"}
                          checked={keyResult.stretch === false}
                          onChange={(e) => stretchHandle(e)}
                          type="radio"
                          value={false}
                          name="stretchGoal"
                        />
                        <label htmlFor={props.index + "stretch_no"}>No</label>
                      </div>
                    </li>
                  </ul>
                </Form.Group>
              </div>
            </div>
            <span
              className={["text_link", !isUpdate || isValidate ? "disabled" : ""].join(" ")}
              onClick={() => saveKeyResultHandler(props.objectiveId)}
            >
              Save Key Result
            </span>
          </div>
        </div>
      </div>
      <Overlay target={targetQuestion} show={isValidate} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-error-question" {...props} show={props.show.toString() || "true"}>
            <span className="error-icon">
              <ReactSVG src={IC_ERROR} title="" alt="" />
            </span>
            <span className="error-bubble-txt">Please fill proper details</span>
          </Tooltip>
        )}
      </Overlay>
    </Form>
  );
};

export default KeyResult;
