import React from "react";
import { Modal } from "react-bootstrap";
import style from "./PDFViewerPopup.module.scss";
import { IC_CLOSE } from "const/imgCost";
import VideoContainer from "./InfoVideoContainer";

const InfoViewerPopup = (props) => {
  return (
    <>
      <Modal
        size="xl"
        show={true}
        onHide={props.handleClose}
        animation={false}
        backdrop="static"
        className={style.video_viewer_wrapper}
      >
        <div className={style.modal_header}>
          <div className="row bg-black">
            <div className={`col-12 ${style.modal_header_wrapper}`}>
              <h1>{props.videoTitle}</h1>
              <span onClick={props.handleClose}>
                <img src={IC_CLOSE} alt="close" />
              </span>
            </div>
          </div>
        </div>
        <Modal.Body className={style.video_popup_body}>
            <VideoContainer videoLink={props.videoLink} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InfoViewerPopup;
