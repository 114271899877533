import React from "react";

export default function FilterRadio({ filter, filterValue, onRadioChange }) {
  return (
    <div className="tab_check_in_status">
      <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
        <input
          id="all"
          type="radio"
          name="status-check"
          value={filter.value[0]}
          checked={filter.value[0] === filterValue}
          onChange={onRadioChange}
        />
        <label htmlFor="all">All</label>
      </div>
      <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
        <input
          id="declined"
          type="radio"
          name="status-check"
          value={filter.value[1]}
          checked={filter.value[1] === filterValue}
          onChange={onRadioChange}
        />
        <label htmlFor="declined">Survey</label>
      </div>
      <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
        <input
          id="approved"
          type="radio"
          name="status-check"
          value={filter.value[2]}
          checked={filter.value[2] === filterValue}
          onChange={onRadioChange}
        />
        <label htmlFor="approved">Quiz</label>
      </div>
    </div>
  );
}
