import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Form, Container } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./OneOnOneForTeams.module.scss";
import { IC_DUSTBIN_PLAN } from "const/imgCost";

const baseURL = "https://api-engage.infostretch.com";
// const api = process.env.REACT_APP_API_BASE_URL;
function OneOnOneForTeams(props) {
  const oneOnOneAgendaId = new URLSearchParams(props.location.search).get("id");
  const eventId = new URLSearchParams(props.location.search).get("eventId");
  const textareaRef = useRef();
  const [sharedNotes, setSharedNote] = useState();
  const [updatedDate, setUpdatedDate] = useState();
  const [talkingPoints, setTalkingPoints] = useState();
  const [meetingNotesList, setMeetingNotesList] = useState([{ note: "" }]);
  const getOneOnOneData = () => {
    fetch(`${baseURL}/plan-service/one-on-one-agenda/teams?id=${oneOnOneAgendaId}&eventId=${eventId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data?.data) {
          if (data?.data?.meetingNotes) {
            let meetingNoteArray = [];
            for (let meetingNote in data?.data?.meetingNotes) {
              meetingNoteArray.push({ id: meetingNote, note: data?.data?.meetingNotes[meetingNote] });
            }
            setMeetingNotesList(meetingNoteArray);
          }
          data.data.meetingNotesUpdatedDate && setUpdatedDate(data.data.meetingNotesUpdatedDate);
          data.data.sharedNotes && setSharedNote(data.data.sharedNotes);
          data.data.talkingPoints && setTalkingPoints(data.data.talkingPoints);
        }
      });
  };

  useEffect(() => {
    getOneOnOneData();
  }, []);

  const handleMeetingNoteChange = (e, index) => {
    let changeData = meetingNotesList.map((value, i) => {
      if (i == index)
        return {
          ...value,
          note: e.target.value,
        };
      else return value;
    });
    setMeetingNotesList(changeData);
  };
  const handleComment = (e, id) => {
    if (e.target.value?.trim()) {
      const data = {
        eventId,
        oneOnOneAgendaId,
        meetingNotes: e.target.value,
        key: id ? id : undefined,
      };
      fetch(`${baseURL}/plan-service/one-on-one-agenda/teams/meeting-notes`, {
        method: "PUT", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(() => getOneOnOneData());
    }
  };

  const handleAddMeetingNote = () => {
    setMeetingNotesList([...meetingNotesList, { note: "" }]);
  };

  const deleteMeetingNote = (index, id) => {
    let deletedNote = meetingNotesList;
    meetingNotesList.splice(index, 1);
    setMeetingNotesList([...deletedNote]);
    if (id) {
      const data = {
        eventId,
        oneOnOneAgendaId,
        key: id,
      };
      fetch(`${baseURL}/plan-service/one-on-one-agenda/teams/meeting-notes`, {
        method: "DELETE", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(() => getOneOnOneData());
    }
  };

  return (
    <div className={style.one_on_one_teams_wrapper}>
      <Container>
        <div className={style.meetingNotes_wrapper}>
          <div className={style.title_item}>
            <div className={style.title}>Scheduled One-on-One</div>
            <span>Last Edited: {moment(updatedDate).fromNow()}</span>
          </div>
        </div>
        {talkingPoints && (
          <div className="card_details">
            <div className={style.meetingNotes_wrapper}>
              <div className={style.title1}>Talking Points</div>
            </div>
            <div>
              <ul className="common_list_items pt10 text-break pt-1 pb-1">
                {talkingPoints &&
                  talkingPoints.map((point) => (
                    <li className="fs-500 mb-3" key={point.talkingPointId}>
                      {point.agenda}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}

        {sharedNotes && (
          <div className="card_details">
            <div className={style.meetingNotes_wrapper}>
              <div className={style.title1}>Shared Notes</div>
            </div>
            <div>
              <ul className="common_list_items pt10 text-break pt-1 pb-1">
                {sharedNotes &&
                  sharedNotes.map((point) => (
                    <li className="fs-500 mb-1" key={point.talkingPointId}>
                      {point.comment}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}

        <div className={style.meetingNotes_wrapper}>
          <div className={style.title1}>Meeting Notes</div>
        </div>
        {meetingNotesList.map((note, index) => (
          <div className={style.text_area_wrapper} key={index}>
            <Form.Group className="mb-0">
              <div className="textarea-wrapper">
                <div className="dustbin_icon" onClick={() => deleteMeetingNote(index, note.id)}>
                  <ReactSVG src={`${IC_DUSTBIN_PLAN}`} />
                </div>
                <Form.Control
                  as="textarea"
                  ref={textareaRef}
                  maxLength={500}
                  cols="5"
                  value={note.note}
                  defaultValue={note.note || ""}
                  onChange={(e) => handleMeetingNoteChange(e, index)}
                  onBlur={(e) => handleComment(e, note.id)}
                  placeholder="Meeting Notes"
                  className={style.one_on_one_meeting_notes}
                />
              </div>
            </Form.Group>
          </div>
        ))}
        <div className={style.add_meeting_btn}>
          <div onClick={handleAddMeetingNote}>+ Add a new meeting note here</div>
        </div>
      </Container>
    </div>
  );
}

export default OneOnOneForTeams;
