export const LOGOUT = "LOGOUT";
// checkIn
export const SET_CURRENT_CHECKIN = "SET_CURRENT_CHECKIN";
export const FETCH_CURRENT_CHECKIN_FAILED = "FETCH_CURRENT_CHECKIN_FAILED";
export const FETCH_PAST_CHECKIN_FAILED = "FETCH_PAST_CHECKIN_FAILED";
export const UPDATE_CURRENT_CHECKIN = "UPDATE_CURRENT_CHECKIN";
export const UPDATE_CURRENT_CHECKIN_FAILED = "UPDATE_CURRENT_CHECKIN_FAILED";
export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS";
export const SET_SUBMIT = "SET_SUBMIT";
export const SET_STREAK = "SET_STREAK";
export const SET_REVIEWER = "SET_REVIEWER";
export const HELP_TO_GROW_ANSWER = "HELP_TO_GROW_ANSWER";
export const DELETE_HELP_TO_GROW = "DELETE_HELP_TO_GROW";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const CLEAR_FILE_RESPONSE = "CLEAR_FILE_RESPONSE";
export const CLEAR_FILE_OBJECTIVE_RESPONSE = "CLEAR_FILE_OBJECTIVE_RESPONSE";
export const FILE_DELETE_SUCCESS = "FILE_DELETE_SUCCESS";
export const FILE_DELETE_ERROR = "FILE_DELETE_ERROR";
export const IN_SUBMIT_VALIDATION = "IN_SUBMIT_VALIDATION";
export const CHECKIN_SUBMIT_RESPONSE = "CHECKIN_SUBMIT_RESPONSE";
export const GET_ALL_GROW_COMMENT = "GET_ALL_GROW_COMMENT";

// madeMyDay
export const SET_APPRECIATE_HISTORY = "SET_APPRECIATE_HISTORY";
export const SET_APPRECIATE_COUNT = "SET_APPRECIATE_COUNT";
export const SET_APPRECIATE_RESPONSE = "SET_APPRECIATE_RESPONSE";
export const SET_ALL_APPRECIATE_HISTORY = "SET_ALL_APPRECIATE_HISTORY";
// myHistory
export const SET_MY_HISTORY = "SET_MY_HISTORY";
export const SET_FILTER_HISTORY = "SET_FILTER_HISTORY";
export const MY_HISTORY_FAILURE = "GET_MY_HISTORY_FAILURE";
export const MY_HISTORY_REVIEWER = "MY_HISTORY_REVIEWER";
export const SURVEY_CYCLE_CLICKED = "SURVEY_CYCLE_CLICKED";
// myTeam
export const GET_TEAM_FAILED = "GET_TEAM_FAILED";
export const SET_TEAM = "SET_TEAM";
export const CHANGE_PATTERN = "CHANGE_PATTERN";
export const EXPANDED = "EXPANDED";
export const PAGESIZE = "PAGESIZE";
export const CURRENT_PAGE = "CURRENT_PAGE";
export const SET_USER = "SET_USER";
// teamHistory
export const SET_MEMBER_DATA = "SET_MEMBER_DATA";
export const SET_MEMBER_REVIEWER = "SET_MEMBER_REVIEWER";
export const TEAM_HISTORY_API_FAILURE = "TEAM_HISTORY_API_FAILURE";
export const SET_SURVEY_HISTORY = "SET_SURVEY_HISTORY";
export const SET_IS_REVIEWED = "SET_IS_REVIEWED";
export const SET_CYCLE_DATA = "SET_CYCLE_DATA";
export const SURVEY_CYCLE_CLICK = "SURVEY_CYCLE_CLICK";
// questions
export const REMOVE_SURVEY_CYCLE_ID = "REMOVE_SURVEY_CYCLE_ID";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_EXTEND_BY_ADMIN = "SET_EXTEND_BY_ADMIN";
export const SET_EXTEND_ADMIN_HISTORY = "SET_EXTEND_ADMIN_HISTORY";
export const SET_EXTEND_ADMIN_HISTORY_LOADING = "SET_EXTEND_ADMIN_HISTORY_LOADING";
export const SET_PAST_QUESTIONS = "SET_PAST_QUESTIONS";
export const QUESTIONS_ADD_HELP_TO_GROW = "QUESTIONS_ADD_HELP_TO_GROW";
export const QUESTIONS_DELETE_HELP_TO_GROW = "QUESTIONS_DELETE_HELP_TO_GROW";
// notification
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const SET_NOTIFICATION_RESPONSE = "SET_NOTIFICATION_RESPONSE";
export const SET_ALL_NOTIFICATION_RESPONSE = "SET_ALL_NOTIFICATION_RESPONSE";

export const SET_ADD_WIN_CHALLENGE = "SET_ADD_WIN_CHALLENGE";
export const SET_FETCH_WIN = "SET_FETCH_WIN";
export const SET_FETCH_CHALLENGE = "SET_FETCH_CHALLENGE";
export const SET_FETCH_COLLECTIVE = "SET_FETCH_COLLECTIVE";
export const SET_DRAG_DROP_WIN_CHALLENGE = "SET_DRAG_DROP_WIN_CHALLENGE";
export const DELETE_WIN_CHALLENGE = "DELETE_WIN_CHALLENGE";
export const CLEAR_DRAG_DROP_RESPONSE = "CLEAR_DRAG_DROP_RESPONSE";
export const CLEAR_ADD_WIN_CHALLENGE_RESPONSE = "CLEAR_ADD_WIN_CHALLENGE_RESPONSE";

//plans
export const SET_PLAN = "SET_PLAN";
export const SET_PLAN_ONCHANGE = "SET_PLAN_ONCHANGE";
export const DELETE_PLAN = "DELETE_PLAN";
export const DONE_PLAN = "DONE_PLAN";
export const EDIT_PLAN = "EDIT_PLAN";
export const SET_PREVIOUS_PLANS = "SET_PREVIOUS_PLANS";
export const FETCH_PLAN = "FETCH_PLAN";
export const GET_PLAN = "GET_PLAN"; //for history
export const CARRY_FORWARD = "CARRY_FORWARD";
export const GET_OBJECTIVE = "GET_OBJECTIVE"; //get one user objective
export const GET_OBJECTIVES = "GET_OBJECTIVES"; //get list of user objectives
export const GET_EMPLOYEE_OBJECTIVES = "GET_EMPLOYEE_OBJECTIVES"; //get employee objectives list when manager check employee objectives

//Objectives
export const REMOVE_IS_LOADING_OBJECTIVE = "REMOVE_IS_LOADING_OBJECTIVE";
export const SET_KEY_RESULTS = "SET_KEY_RESULTS";
export const SET_DRAFT_OBJECTIVE_DATA = "SET_DRAFT_OBJECTIVE_DATA";
export const SET_DRAFT_SELF_OBJECTIVE_DATA = "SET_DRAFT_SELF_OBJECTIVE_DATA";
export const ADD_OBJECTIVE = "ADD_OBJECTIVE";
export const GET_PREDEFINED_OBJECTIVE = "GET_PREDEFINED_OBJECTIVE";
export const GET_SELF_PREDEFINED_OBJECTIVE ="GET_SELF_PREDEFINED_OBJECTIVE";
export const OBJECTIVE_ADDED = "OBJECTIVE_ADDED";
export const DELETE_KEY_POINT = "DELETE_KEY_POINT";
export const OBJECTIVE_VALIDATION = "OBJECTIVE_VALIDATION";
export const KEY_RESULT_ERRORS = "KEY_RESULT_ERRORS";
export const SELF_OBJECTIVE_KEY_ERRORS = "SELF_OBJECTIVE_KEY_ERRORS";
export const SUBMIT_OBJECTIVE = "SUBMIT_OBJECTIVE";
export const CLEAR_CREATE_OBJECTIVE = "CLEAR_CREATE_OBJECTIVE";
export const SET_TEAM_OBJECTIVES = "SET_TEAM_OBJECTIVES";
export const SET_ALL_EMPLOYEE_OBJECTIVE = "SET_ALL_EMPLOYEE_OBJECTIVE";
export const SET_MANAGER_TEAM = "SET_MANAGER_TEAM";
export const SET_OBJECTIVE = "SET_OBJECTIVE";
export const SET_ASSIGNEES = "SET_ASSIGNEES";
export const VIEW_ALL_OBJECTIVE = "ALL_OBJECTIVE";
export const SET_OBJECTIVE_ACTIVITY = "SET_OBJECTIVE_ACTIVITY";
export const REMOVE_ASSIGNEES = "REMOVE_ASSIGNEES";
export const ASSIGN_FAIL = "ASSIGN_FAIL";
export const GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR_FAIL = "GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR_FAIL";
export const GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR = "GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR";
export const ASSIGN_SUCCESS = "ASSIGN_SUCCESS";
export const DELETE_USER_OBJECTIVE_SUCCESS = "DELETE_USER_OBJECTIVE_SUCCESS";
// export const UPDATE_DELETED_OBJECTIVE_RESPONSE = "UPDATE_DELETED_OBJECTIVE_RESPONSE";
export const SELECT_OBJECTIVE = "SELECT_OBJECTIVE";
export const GET_USER_HISTORY = "GET_USER_HISTORY";
export const UPDATE_OBJECTIVE_RESPONSE = "UPDATE_OBJECTIVE_RESPONSE";
export const DISABLE = "DISABLE";
export const UN_DISABLE = "UN_DISABLE";
export const SIZE = "SIZE";
export const CLEAR_OBJECTIVES_STATE = "CLEAR_OBJECTIVES_STATE";
export const SET_ERRORS_FLAG = "SET_ERRORS_FLAG";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAIL = "UPLOAD_FAIL";
export const SET_TAGS = "SET_TAGS";

// Performance
export const SET_QUARTERLY_CYCLE_LIST = "SET_QUARTERLY_CYCLE_LIST";
export const SET_TIMELINE_DATA_LIST = "SET_TIMELINE_DATA_LIST";
export const SET_TIMELINE_DATA_LIST_FOR_MANAGER = "SET_TIMELINE_DATA_LIST_FOR_MANAGER";
export const SET_TIMELINE_DATA_LIST_FOR_HR = "SET_TIMELINE_DATA_LIST_FOR_HR";
export const SET_ALL_REPORTEE_ASSESSMENT_LIST = "SET_ALL_REPORTEE_ASSESSMENT_LIST";
export const SET_PRIVATE_ASSESSMENT_DATA = "SET_PRIVATE_ASSESSMENT_DATA";
export const SELECT_SELF_OBJECTIVE = "SELECT_SELF_OBJECTIVE";
export const SET_PREVIOUS_MANAGER = "SET_PREVIOUS_MANAGER";

//One on One
export const SEND_AGENDA_SUCCESS = "SEND_AGENDA_SUCCESS";
export const GET_CURRENT_ONE_ON_ONE_DATA = "GET_CURRENT_ONE_ON_ONE_DATA";
export const GET_PREVIOUS_ONE_ON_ONE_DATA = "GET_PREVIOUS_ONE_ON_ONE_DATA";
export const GET_ONE_ON_ONE_AGENDA_COMMENT = "GET_ONE_ON_ONE_AGENDA_COMMENT";
export const SET_ONE_ON_ONE_USER = "SET_ONE_ON_ONE_USER";
export const GET_ONE_ON_ONE = "GET_ONE_ON_ONE";
export const SET_ONE_ON_ONE_HR_TEAM_LIST = "SET_ONE_ON_ONE_HR_TEAM_LIST";
export const GET_ONE_ON_ONE_FOR_HR = "GET_ONE_ON_ONE_FOR_HR";
export const GET_TIMEZONE = "GET_TIMEZONE";
export const RESPONSE_SCHEDULE = "RESPONSE_SCHEDULE";
export const RESPONSE_END_ONE_ON_ONE = "RESPONSE_END_ONE_ON_ONE";
export const GET_MANAGER_LIST = "GET_MANAGER_LIST";
export const GET_ONE_ON_ONE_MEMBER_LIST = "GET_ONE_ON_ONE_MEMBER_LIST";
export const SET_HR_ONE_ON_ONE_FILTER_OPTIONS_DATA = "SET_HR_ONE_ON_ONE_FILTER_OPTIONS_DATA";
export const CLEAR_HR_ONE_ON_ONE_FILTER_OPTIONS_DATA = "CLEAR_HR_ONE_ON_ONE_FILTER_OPTIONS_DATA";
export const GET_REPORTEE_LIST = "GET_REPORTEE_LIST";
export const GET_TEAM_FEEDBACK_LIST = "GET_TEAM_FEEDBACK_LIST";
export const POST_GLOBAL_FEEDBACK = "POST_GLOBAL_FEEDBACK";
export const GET_GLOBAL_FEEDBACK = "GET_GLOBAL_FEEDBACK";
export const GET_SCHEDULE_TIME = "GET_SCHEDULE_TIME";
export const SHOW_LOADER_GLOBAL_FEEDBACK = "SHOW_LOADER_GLOBAL_FEEDBACK";

// University
export const SUBMIT_CERTIFICATE = "SUBMIT_CERTIFICATE";
export const SAVE_CERTIFICATE = "SAVE_CERTIFICATE";
export const CERTIFICATE_NAME = "CERTIFICATE_NAME";
export const CERTIFICATE_LIST = "CERTIFICATE_LIST";
export const SHOW_LOADER = "SHOW_LOADER";
export const SHOW_HR_LOADER = "SHOW_HR_LOADER";
export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE";
export const DOWNLOAD_CERTIFICATE = "DOWNLOAD_CERTIFICATE";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const DOWNLOAD_CERTIFICATE_ERROR = "DOWNLOAD_CERTIFICATE_ERROR";
export const DOWNLOAD_STARTED = "DOWNLOAD_STARTED";
export const DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS";
export const DELETE_FAIL = "DELETE_FAIL";
export const DOWNLOAD_FAIL = "DOWNLOAD_FAIL";
export const FAILURE_CERTIFICATE = "FAILURE_CERTIFICATE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CERTIFICATE_DELETE_SUCCESS = "CERTIFICATE_DELETE_SUCCESS";
export const CERTIFICATE_DELETE_ERROR = "CERTIFICATE_DELETE_ERROR";
export const SET_OBJECTIVE_REVIEWER = "SET_OBJECTIVE_REVIEWER";
export const SET_TEMPLATE = "SET_TEMPLATE";
export const GET_CERTIFICATE_LIST_FOR_ALL_EMPLOYEE_MANAGER = "GET_CERTIFICATE_LIST_FOR_ALL_EMPLOYEE_MANAGER";
export const GET_CERTIFICATE_OVERVIEW_LIST = "GET_CERTIFICATE_OVERVIEW_LIST";
export const NOT_FOUND = "NOT_FOUND";
export const UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SUCCESS = "UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SUCCESS";
export const UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_ERROR = "UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_ERROR";

// Information hub
export const INFORMATION_HUB_ACTIONS = {
  GET_LIST_DATA: "GET_LIST_DATA",
  LOADING: "LOADING",
};

// Monthly Checkin Feedback
export const MONTHLY_CHECKIN_FEEDBACK_ACTION = {
  GET_CURRENT_FEEDBACK_EMPLOYEE: "GET_CURRENT_FEEDBACK_EMPLOYEE",
  GET_CURRENT_FEEDBACK_MANAGER: "GET_CURRENT_FEEDBACK_MANAGER",
  SET_SHOW_YEAR_LIST: "SET_SHOW_YEAR_LIST",
  SET_FEEDBACK_INTERACTION_EMPLOYEE: "SET_FEEDBACK_INTERACTION_EMPLOYEE",
  SET_FEEDBACK_INTERACTION_MANAGER: "SET_FEEDBACK_INTERACTION_MANAGER",
  SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER: "SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER",
  GET_COUNT_DURATION_STATUS_EMPLOYEE: "GET_COUNT_DURATION_STATUS_EMPLOYEE",
  GET_COUNT_DURATION_STATUS_MANAGER: "GET_COUNT_DURATION_STATUS_MANAGER",
  SET_FEEDBACK_HISTORY_EMPLOYEE: "SET_FEEDBACK_HISTORY_EMPLOYEE",
  SET_FEEDBACK_HISTORY_MANAGER: "SET_FEEDBACK_HISTORY_MANAGER",
  GET_MANAGER_LIST_EMPLOYEE: "GET_MANAGER_LIST_EMPLOYEE",
  GET_HISTORY_DETAILS_EMPLOYEE_AND_MANAGER: "GET_HISTORY_DETAILS_EMPLOYEE_AND_MANAGER",
  SET_CHECKIN_FEEDBACK_EXPORT: "SET_CHECKIN_FEEDBACK_EXPORT",
  START_FILE_DOWNLOAD_IN_BACKGROUND: "START_FILE_DOWNLOAD_IN_BACKGROUND",
  CLEAR_CHECKIN_FEEDBACK_EXPORT: "CLEAR_CHECKIN_FEEDBACK_EXPORT",
  SET_FILTERS_DETAILS_HR: "SET_FILTERS_DETAILS_HR",
  SET_FEEDBACK_FILTER_DATA: "SET_FEEDBACK_FILTER_DATA",
  SET_FEEDBACK_DETAILS_IN_HR_VIEW: "SET_FEEDBACK_DETAILS_IN_HR_VIEW",
  SET_FEEDBACK_DASHBOARD_DATA_HR: "SET_FEEDBACK_DASHBOARD_DATA_HR",
  SET_GRAPH_CHECKIN_FEEDBACK_HR:"SET_GRAPH_CHECKIN_FEEDBACK_HR",
  SET_QUESTION_BASED_EXPORT_EMPLOYEE:"SET_QUESTION_BASED_EXPORT_EMPLOYEE",
  SET_QUESTION_BASED_EXPORT_MANAGER:"SET_QUESTION_BASED_EXPORT_MANAGER",
  CLEAR_FEEDBACK_FILTER_DATA: "CLEAR_FEEDBACK_FILTER_DATA",
  LOADING: "LOADING",
  IS_DASHBOARD_LOADING: "IS_DASHBOARD_LOADING",
  IS_GRAPH_LOADING: "IS_GRAPH_LOADING",
};

// Skill Matrix
export const ADD_Skill = "ADD_SKILL";
export const ADD_SKILL_ERROR = "ADD_SKILL_ERROR";
export const DISPLAY_LOADER = "DISPLAY_LOADER";
export const GET_SKILLS = "GET_SKILLS";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const DRAFT_SKILL = "DRAFT_SKILL";
export const FAILURE_DRAFT_RESPONSE = "FAILURE_DRAFT_RESPONSE";
export const UPDATE_ADDITIONAL_SKILL = "UPDATE_ADDITIONAL_SKILL";
export const UPDATE_DRAFT_SKILL = "UPDATE_DRAFT_SKILL";
export const ADDITIONAL_SKILL_LIST = "ADDITIONAL_SKILL_LIST";
export const GET_EMPLOYEE_RESUME = "GET_EMPLOYEE_RESUME";
export const DOWNLOAD_RESUME = "DOWNLOAD_RESUME";
export const DOWNLOAD_RESUME_ERROR = "DOWNLOAD_RESUME_ERROR";
export const DOWNLOAD_RESUME_TEMPLATE = "DOWNLOAD_RESUME_TEMPLATE";
export const DOWNLOAD_RESUME_TEMPLATE_ERROR = "DOWNLOAD_RESUME_TEMPLATE_ERROR";
export const UPLOAD_MY_RESUME = "UPLOAD_MY_RESUME";
export const ERROR_IN_ADD_Skill = "ERROR_IN_ADD_Skill";
export const CLEAR_DRAFT_SKILL = "CLEAR_DRAFT_SKILL";
export const GET_SKILL = "GET_SKILL";
export const ERROR_GET_SKILLS = "ERROR_GET_SKILLS";
export const SKILL_ADDED_IN_MASTER = "SKILL_ADDED_IN_MASTER";
export const CLEAR_SKILL_ADDED_IN_MASTER = "CLEAR_SKILL_ADDED_IN_MASTER";
export const DELETE_ADDITIONAL_SKILL = "DELETE_ADDITIONAL_SKILL";
export const ADDITIONAL_SKILL_DELETE_ERROR = "ADDITIONAL_SKILL_DELETE_ERROR";
export const FAIL_DELETE_ADDITIONAL_SKILL = "FAIL_DELETE_ADDITIONAL_SKILL";
export const UPDATE_SKILL_MASTER = "UPDATE_SKILL_MASTER";
export const CLEAR_UPDATE_SKILL_MASTER_NOTIFICATION = "CLEAR_UPDATE_SKILL_MASTER_NOTIFICATION";
export const ALREADY_EXIST = "ALREADY_EXIST";
export const ALREADY_EXIST_NOTIFICATION_OFF = "ALREADY_EXIST_NOTIFICATION_OFF";
export const GET_DEPARTMENT_LIST = "GET_DEPARTMENT_LIST";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_ROLE_BY_DEPARTMENT = "GET_ROLE_BY_DEPARTMENT";
export const GET_TECHNICAL_ROLE = "GET_TECHNICAL_ROLE";
export const SAVE_REQUIRED_SKILL = "SAVE_REQUIRED_SKILL";
export const SAVE_REQUIRED_SKILL_ERROR = "SAVE_REQUIRED_SKILL_ERROR";
export const GET_REQUIRED_SKILL_CONFIG = "GET_REQUIRED_SKILL_CONFIG";
export const SAVE_MY_REQUIRED_SKILL = "SAVE_MY_REQUIRED_SKILL";
export const SAVE_MY_REQUIRED_SKILL_ERROR = "SAVE_MY_REQUIRED_SKILL_ERROR";
export const SAVE_MY_REQUIRED_SKILL_SUCCESS = "SAVE_MY_REQUIRED_SKILL_SUCCESS";
export const GET_MY_DEPARTMENT_TECH_ROLE = "GET_MY_DEPARTMENT_TECH_ROLE";
export const LIST_ROLE_WISE_SKILL_REQUIRED_FOR_MMG = "LIST_ROLE_WISE_SKILL_REQUIRED_FOR_MMG";
export const LIST_EMPLOYEE_REQUIRED_SKILL = "LIST_EMPLOYEE_REQUIRED_SKILL";
export const GET_REQUIRED_SKILL_DETAILS = "GET_REQUIRED_SKILL_DETAILS";
export const GET_ENDORSEMENT_TYPE = "GET_ENDORSEMENT_TYPE";
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const GET_TECHNICAL_ROLE_LIST = "GET_TECHNICAL_ROLE_LIST";
export const GET_ENDORSEMENT_SKILL = "GET_ENDORSEMENT_SKILL";
export const GET_BAND_LIST = "GET_BAND_LIST";
export const GET_FILTER_DATA = "GET_FILTER_DATA";
export const GET_CERTIFICATE_NAME = "GET_CERTIFICATE_NAME";
export const ENDORSE_EMPLOYEE = "ENDORSE_EMPLOYEE";
export const ENDORSE_EMPLOYEE_ERROR = "ENDORSE_EMPLOYEE_ERROR";
export const ENDORSE_REQ_ERROR = "ENDORSE_REQ_ERROR";
export const ENDORSE_REQ = "ENDORSE_REQ";
export const GET_ENDORSEMENT_BY_ME = "GET_ENDORSEMENT_BY_ME";
export const GET_ENDORSE_REQ_FOR_EMP = "GET_ENDORSE_REQ_FOR_EMP";
export const GET_MY_ENDORSEMENT = "GET_MY_ENDORSEMENT";
export const GET_PENDING_DECLINE_REQ = "GET_PENDING_DECLINE_REQ";
export const APPROVE_ENDORSE_REQ = "APPROVE_ENDORSE_REQ";
export const APPROVE_ENDORSE_REQ_ERROR = "APPROVE_ENDORSE_REQ_ERROR";
export const GET_DECLINE_ENDORSE_REQ = "GET_DECLINE_ENDORSE_REQ";
export const GET_MMG_ENDORSER_NAME = "GET_MMG_ENDORSER_NAME";
export const SEND_MMG_ENDORSE_REQ = "SEND_MMG_ENDORSE_REQ";
export const SEND_MMG_ENDORSE_REQ_ERROR = "SEND_MMG_ENDORSE_REQ_ERROR";
export const GET_MMG_ENDORSE_REQ = "GET_MMG_ENDORSE_REQ";
export const GET_UNIVERSITY_REQ = "GET_UNIVERSITY_REQ";
export const GET_UNIVERSITY_DECLINE_APPROVE_REQ = "GET_UNIVERSITY_DECLINE_APPROVE_REQ";
export const GET_DEFINE_SKILL_DRAFT = "GET_DEFINE_SKILL_DRAFT";
export const GET_SKILL_MASTER_CATEGORY = "GET_SKILL_MASTER_CATEGORY";
export const ADD_SKILL_MASTER = "ADD_SKILL_MASTER";
export const ADD_SKILL_MASTER_ERROR = "ADD_SKILL_MASTER_ERROR";
export const CATEGORY_WISE_SKILL_MASTER = "CATEGORY_WISE_SKILL_MASTER";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ERROR_IN_ADD_CATEGORY = "ERROR_IN_ADD_CATEGORY";
export const EMPLOYEE_SKILL_LISTING_EXPORT = "EMPLOYEE_SKILL_LISTING_EXPORT";
export const EMPLOYEE_SKILL_LISTING_EXPORT_ERROR = "EMPLOYEE_SKILL_LISTING_EXPORT_ERROR";
export const PAGE_NUMBER = "PAGE_NUMBER";
export const UPLOAD_RESUME_SUCCESS = "UPLOAD_RESUME_SUCCESS";
export const ERROR_IN_UPLOAD_RESUME = "ERROR_IN_UPLOAD_RESUME";
export const RESUME_DOWNLOADING_EMPLOYEE_SKILL_LISTING_SUCCESS = "RESUME_DOWNLOADING_EMPLOYEE_SKILL_LISTING_SUCCESS";
export const RESUME_DOWNLOADING_EMPLOYEE_SKILL_LISTING_ERROR = "RESUME_DOWNLOADING_EMPLOYEE_SKILL_LISTING_ERROR";
export const UPDATE_TECHNICAL_ROLE_SUCCESS = "UPDATE_TECHNICAL_ROLE_SUCCESS";
export const UPDATE_TECHNICAL_ROLE_ERROR = "UPDATE_TECHNICAL_ROLE_ERROR";
export const LIST_CHANGE_REQUEST_FOR_MMG = "LIST_CHANGE_REQUEST_FOR_MMG";
export const RESUME_UPDATE_REMINDER = "RESUME_UPDATE_REMINDER";
export const RESUME_UPDATE_ACKNOWLEDGE_SUCCESS = "RESUME_UPDATE_ACKNOWLEDGE_SUCCESS";
export const RESUME_UPDATE_ACKNOWLEDGE_ERROR = "RESUME_UPDATE_ACKNOWLEDGE_ERROR";
export const MY_TECHNICAL_ROLE_CHANGE_REQUEST_LIST = "MY_TECHNICAL_ROLE_CHANGE_REQUEST_LIST";
export const APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG_SUCCESS =
  "APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG_SUCCESS";
export const APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG_ERROR =
  "APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG_ERROR";
export const GET_EMPLOYEE_NAME_FOR_EMPLOYEE_SKILL_LISTING = "GET_EMPLOYEE_NAME_FOR_EMPLOYEE_SKILL_LISTING";
export const CLEAR_GET_FILTER_DATA = "CLEAR_GET_FILTER_DATA";

//ASSESSMENT
export const CREATE_ASSESSMENT_CYCLE = "CREATE_ASSESSMENT_CYCLE";
export const SET_CREATED_ASSESSMENT_CYCLE = "SET_CREATED_ASSESSMENT_CYCLE";
export const SET_QUARTERLY_CYCLE_DATA = "SET_QUARTERLY_CYCLE_DATA";
export const SET_TEMPLATE_LIST = "SET_TEMPLATE_LIST";
export const SET_LOCATION_LIST = "SET_LOCATION_LIST";
export const SET_HR_TIMELINE_FILTER_OPTIONS = "SET_HR_TIMELINE_FILTER_OPTIONS";
export const SET_HR_TIMELINE_EMPLOYEE_LIST = "SET_HR_TIMELINE_EMPLOYEE_LIST";
export const SET_HR_TIMELINE_EMPLOYEE_FILTER_OPTIONS = "SET_HR_TIMELINE_EMPLOYEE_FILTER_OPTIONS";
export const REOPEN_RESPONSE = "REOPEN_RESPONSE";
export const SET_FEEDBACKS = "SET_FEEDBACKS";
export const SET_REPORTING_MANAGER_LIST = "SET_REPORTING_MANAGER_LIST";
export const SET_REVIEWING_MANAGER_LIST = "SET_REVIEWING_MANAGER_LIST";
export const SET_EMPLOYEE_LIST = "SET_EMPLOYEE_LIST";
export const SET_EMPLOYEE_SELF_ASSESSMENT_LIST = "SET_EMPLOYEE_SELF_ASSESSMENT_LIST";
export const SET_ASSESSMENT_GLOBAL_COMMENT = "SET_ASSESSMENT_GLOBAL_COMMENT";
export const SET_RPM_ASSESSMENT_GLOBAL_COMMENT = "SET_RPM_ASSESSMENT_GLOBAL_COMMENT";
export const SET_SELF_ASSESSMENT_GLOBAL_COMMENT = "SET_SELF_ASSESSMENT_GLOBAL_COMMENT";
export const PRIVATE_ASSESSMENT_FILE_RESPONSE = "PRIVATE_ASSESSMENT_FILE_RESPONSE";
export const GLOBAL_FEEDBACK_FILE_RESPONSE = "GLOBAL_FEEDBACK_FILE_RESPONSE";
export const PRIVATE_ASSESSMENT_SUBMIT_RESPONSE = "PRIVATE_ASSESSMENT_SUBMIT_RESPONSE";
export const SET_USER_PRIVATE_ASSESSMENT_DATA = "SET_USER_PRIVATE_ASSESSMENT_DATA";
export const SET_EMPLOYEE_NAME_LIST = "SET_EMPLOYEE_NAME_LIST";
export const SET_EMPLOYEE_NAME = "SET_EMPLOYEE_NAME";
export const SET_EMPLOYEE_TYPE_LIST = "SET_EMPLOYEE_TYPE_LIST";
export const GET_TEMPLATE_CATEGORY = "GET_TEMPLATE_CATEGORY";
export const TEMPLATE_SCALE = "TEMPLATE_SCALE";
export const GET_HR_DASHBOARD_REPORT = "GET_HR_DASHBOARD_REPORT";
export const GET_HR_DASHBOARD_FILTER_OPTION = "GET_HR_DASHBOARD_FILTER_OPTION";
export const GET_HR_DASHBOARD_EMPLOYEE_TYPE_FILTER_OPTION = "GET_HR_DASHBOARD_EMPLOYEE_TYPE_FILTER_OPTION";
export const SET_HR_DASHBOARD_MENU_COUNT = "SET_HR_DASHBOARD_MENU_COUNT";
export const SET_HR_DASHBOARD_MENU_CHECKED = "SET_HR_DASHBOARD_MENU_CHECKED";
export const ERROR_GET_HR_DASHBOARD_REPORT = "ERROR_GET_HR_DASHBOARD_REPORT";
export const GET_HR_DASHBOARD_EXCEL_REPORT = "GET_HR_DASHBOARD_EXCEL_REPORT";
export const GET_HR_DASHBOARD_EXCEL_REPORT_ERROR = "GET_HR_DASHBOARD_EXCEL_REPORT_ERROR";
export const EMPLOYEE_OBJECTIVE_CHART_DATA = "EMPLOYEE_OBJECTIVE_CHART_DATA";
export const EMPLOYEE_OBJECTIVE_CHART_EXPORT_REPORT = "EMPLOYEE_OBJECTIVE_CHART_EXPORT_REPORT";
export const EMPLOYEE_ASSESSMENT_REPORT = "EMPLOYEE_ASSESSMENT_REPORT";
export const SET_GLOBAL_FEEDBACK_LIST = "SET_GLOBAL_FEEDBACK_LIST";
export const SET_ASSESSMENT_REPORT_CHART_DATA = "SET_ASSESSMENT_REPORT_CHART_DATA";
export const SET_PMS_DROPDOWN_OPTIONS_FOR_MANAGER = "SET_PMS_DROPDOWN_OPTIONS_FOR_MANAGER";
export const SET_CYCLE_LIST_OPTIONS_FOR_MANAGER = "SET_CYCLE_LIST_OPTIONS_FOR_MANAGER";
export const SET_MANAGER_DASHBOARD_ASSESSMENT_TABLE_CONTENT = "SET_MANAGER_DASHBOARD_ASSESSMENT_TABLE_CONTENT";
export const SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_FOR_MANAGER =
  "SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_FOR_MANAGER";
export const SET_PMS_EMPLOYEE_VIEW_IN_POPUP_FOR_MANAGER = "SET_PMS_EMPLOYEE_VIEW_IN_POPUP_FOR_MANAGER";
export const CLOSE_PMS_EMPLOYEE_VIEW_POPUP_FOR_MANAGER = "CLOSE_PMS_EMPLOYEE_VIEW_POPUP_FOR_MANAGER";
export const SET_ACKNOWLEDGEMENT = "SET_ACKNOWLEDGEMENT";
export const SET_MANAGER_TEAM_ASSESSMENT_LIST = "SET_MANAGER_TEAM_ASSESSMENT_LIST";
export const SET_MANAGER_TEAM_ASSESSMENT_GRAPH = "SET_MANAGER_TEAM_ASSESSMENT_GRAPH";
export const SET_ASSESSMENT_TEAM_BY_SEARCH_NAME = "SET_ASSESSMENT_TEAM_BY_SEARCH_NAME";
export const SET_TEAM_MEMBER_LIST_FOR_ASSESSMENT_DASHBOARD = "SET_TEAM_MEMBER_LIST_FOR_ASSESSMENT_DASHBOARD";

//Deallocation
export const DEALLOCATION_LIST_FOR_MANAGER = "DEALLOCATION_LIST_FOR_MANAGER";
export const EMP_DEALLOCATION_DATA = "EMP_DEALLOCATION_DATA";
export const EMP_DEALLOCATION_DATA_FOR_HR = "EMP_DEALLOCATION_DATA_FOR_HR";
export const MAKE_IT_UPDATE_DEALLOCATION = "MAKE_IT_UPDATE_DEALLOCATION";
export const DEALLOCATION_REPORTEE_LIST = "DEALLOCATION_REPORTEE_LIST";
export const DEALLOCATION_FILE_RESPONSE = "DEALLOCATION_FILE_RESPONSE";
export const SET_DEALLOCATION_FILTER_DATA_LIST = "SET_DEALLOCATION_FILTER_DATA_LIST";

//HR
export const SET_HR_QUARTERLY_CYCLE_LIST = "SET_HR_QUARTERLY_CYCLE_LIST";
export const SET_ALL_HR_QUARTERLY_CYCLE_LIST = "SET_ALL_HR_QUARTERLY_CYCLE_LIST";
export const SET_HR_EMPLOYEE_NAME_LIST = " SET_HR_EMPLOYEE_NAME_LIST";
export const SET_DASHBOARD_REPORT_FILTER_DATA = "SET_DASHBOARD_REPORT_FILTER_DATA";
export const SET_DASHBOARD_FILTERS = "SET_DASHBOARD_FILTERS";
export const CLEAR_DASHBOARD_REPORT_FILTER_DATA = "CLEAR_DASHBOARD_REPORT_FILTER_DATA";
export const CLEAR_ALL_DASHBOARD_REPORT_FILTER_DATA = "CLEAR_ALL_DASHBOARD_REPORT_FILTER_DATA";
export const SET_CURRENT_RPM_LIST = "SET_CURRENT_RPM_LIST";
export const SET_CURRENT_RVM_LIST = "SET_CURRENT_RVM_LIST";
export const SET_NEW_MANAGER_LIST = "SET_NEW_MANAGER_LIST";
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const SET_PMS_DROPDOWN_OPTIONS = "SET_PMS_DROPDOWN_OPTIONS";
export const SET_CYCLE_LIST_OPTIONS = "SET_CYCLE_LIST_OPTIONS";
export const SET_HR_DASHBOARD_ASSESSMENT_TABLE_CONTENT = "SET_HR_DASHBOARD_ASSESSMENT_TABLE_CONTENT";
export const SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON = "SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON";
export const SET_PMS_EMPLOYEE_VIEW_IN_POPUP = "SET_PMS_EMPLOYEE_VIEW_IN_POPUP";
export const CLOSE_PMS_EMPLOYEE_VIEW_POPUP = "CLOSE_PMS_EMPLOYEE_VIEW_POPUP";
export const SET_HR_TIMELINE_FILTER_DATA = "SET_HR_TIMELINE_FILTER_DATA";
export const CLEAR_HR_TIMELINE_FILTER_DATA = "CLEAR_HR_TIMELINE_FILTER_DATA";

//ADMIN
export const SET_EMPLOYEE_NAME_FOR_ADMIN = "SET_EMPLOYEE_NAME_FOR_ADMIN";
export const SET_SUBGROUP_NAME = "SET_SUBGROUP_NAME";
export const SET_DROPDOWN_DATA_FOR_ROLE = "SET_DROPDOWN_DATA_FOR_ROLE";
export const SET_ROLE_RIGHTS_LIST = "SET_ROLE_RIGHTS_LIST";
export const SET_USER_ROLES_RIGHTS_LIST = "SET_USER_ROLES_RIGHTS_LIST";
export const SET_LIST_OPTION_FOR_NOTIFICATION = "SET_LIST_OPTION_FOR_NOTIFICATION";
export const SET_NOTIFICATION_FILTER = "SET_NOTIFICATION_FILTER";
export const CLEAR_NOTIFICATION_FILTER = "CLEAR_NOTIFICATION_FILTER";
export const SET_BOT_NOTIFICATION_LIST = "SET_BOT_NOTIFICATION_LIST";
export const SET_SUPER_ADMIN_DATA = "SET_SUPER_ADMIN_DATA";
export const SET_SURVEY_CONFIG_DATA = "SET_SURVEY_CONFIG_DATA";
export const REMOVE_SURVEY_CONFIG_DATA = "REMOVE_SURVEY_CONFIG_DATA";
export const OKR_TEMPLATE_ACTIONS = {
  SET_LIST_DATA: "SET_LIST_DATA",
  LOADING: "LOADING",
  SET_MANAGER_LIST: "SET_MANAGER_LIST",
  SET_TOAST_MESSAGE: "SET_TOAST_MESSAGE"
};

//Manager Dashboard
export const SET_MANAGER_PENDING = "SET_MANAGER_PENDING";
export const SET_EMPLOYEE_PENDING = "SET_EMPLOYEE_PENDING";
export const SET_PENDING_ONE_ON_ONE = "SET_PENDING_ONE_ON_ONE";
export const SET_CURRENT_AND_PREVIOUS_CHECKIN = "SET_CURRENT_AND_PREVIOUS_CHECKIN";
export const SET_TEAMS_CHECKIN = "SET_TEAMS_CHECKIN";
export const SET_UNASSIGNED_OBJECTIVES = "SET_UNASSIGNED_OBJECTIVES";
export const SET_PENDING_OBJECTIVE_APPROVAL = "SET_PENDING_OBJECTIVE_APPROVAL";

//campaign
export const SET_CAMPAIGN_RESPONSE = "SET_CAMPAIGN_RESPONSE";
export const SET_EMPLOYEE_VIEW_QUIZ_QUESTIONS = "SET_EMPLOYEE_VIEW_QUIZ_QUESTIONS";
export const SET_EMPLOYEE_RESUME_VIEW_QUIZ_QUESTIONS = "SET_EMPLOYEE_RESUME_VIEW_QUIZ_QUESTIONS";
export const SET_EMPLOYEE_REFRESH_VIEW_QUIZ_QUESTIONS = "SET_EMPLOYEE_REFRESH_VIEW_QUIZ_QUESTIONS";
export const SET_QUIZ_INFO_INTERVAL = "SET_QUIZ_INFO_INTERVAL";
export const SET_LOCATION_LIST_IN_CAMPAIGN = "SET_LOCATION_LIST_IN_CAMPAIGN";
export const SET_LOCATION_FILTER_FOR_CAMPAIGN = "SET_LOCATION_FILTER_FOR_CAMPAIGN";
export const SET_FILTER_FOR_CAMPAIGN = "SET_FILTER_FOR_CAMPAIGN";
export const SET_EMPLOYEE_NAME_FOR_CAMPAIGN = "SET_EMPLOYEE_NAME_FOR_CAMPAIGN";
export const SET_CAMPAIGN_LIST_FOR_ADMIN = "SET_CAMPAIGN_LIST_FOR_ADMIN";
export const SET_CAPABILITY_LIST_FOR_ADMIN = "SET_CAPABILITY_LIST_FOR_ADMIN";
export const UPDATE_CAMPAIGN_LIST_FOR_ADMIN = "UPDATE_CAMPAIGN_LIST_FOR_ADMIN";
export const SET_CAMPAIGN_LIST_FOR_EMPLOYEE = "SET_CAMPAIGN_LIST_FOR_EMPLOYEE";
export const SET_EMPLOYEE_CAMPAIGN_DATA = "SET_EMPLOYEE_CAMPAIGN_DATA";
export const SET_PREVIEW_OF_CAMPAIGN = "SET_PREVIEW_OF_CAMPAIGN";
export const SET_CREATED_CAMPAIGN = "SET_CREATED_CAMPAIGN";
export const SET_CAMPAIGN_PREVIEW_DATA = "SET_CAMPAIGN_PREVIEW_DATA";
export const REMOVE_CAMPAIGN_PREVIEW_DATA = "REMOVE_CAMPAIGN_PREVIEW_DATA";
export const SET_CAMPAIGN_TEMPLATE_LIST = "SET_CAMPAIGN_TEMPLATE_LIST";
export const SET_EMPLOYEE_LIST_FOR_CAMPAIGN = "SET_EMPLOYEE_LIST_FOR_CAMPAIGN";
export const SET_CAMPAIGN_QUESTION_GRAPH = "SET_CAMPAIGN_QUESTION_GRAPH";
export const SET_CAMPAIGN_QUESTION_POPUP = "SET_CAMPAIGN_QUESTION_POPUP";
export const SET_CAPABILITY_TEMPLATE_LIST = "SET_CAPABILITY_TEMPLATE_LIST";
export const SET_EMPLOYEE_QUIZ_DETAILS = "SET_EMPLOYEE_QUIZ_DETAILS";
export const SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_PAST_DATA = "SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_PAST_DATA";
export const SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_ONGOING_DATA = "SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_ONGOING_DATA";
export const SET_QUIZ_RESPONSE = "SET_QUIZ_RESPONSE";
export const SET_IMPORT_QUIZ_TEMPLATE_RESPONSE = "SET_IMPORT_QUIZ_TEMPLATE_RESPONSE";
export const SET_EMPLOYEE_VIEW_QUIZ_RESULT = "SET_EMPLOYEE_VIEW_QUIZ_RESULT";
export const SET_HR_VIEW_QUIZ_RESULT = "SET_HR_VIEW_QUIZ_RESULT";
export const SET_WRONG_ANSWERS_FOR_EMPLOYEE = "SET_WRONG_ANSWERS_FOR_EMPLOYEE";
export const SET_WRONG_ANSWERS_FOR_HR = "SET_WRONG_ANSWERS_FOR_HR";
export const SET_EMPLOYEE_QUIZ_RESULT_REPORT = "SET_EMPLOYEE_QUIZ_RESULT_REPORT";
export const SET_EMPLOYEE_QUIZ_RESULT_GRAPH_DATA = "SET_EMPLOYEE_QUIZ_RESULT_GRAPH_DATA";
export const SET_EMPLOYEES_DETAILS_ROLE_RIGHTS_INDEPENDENT = "SET_EMPLOYEES_DETAILS_ROLE_RIGHTS_INDEPENDENT";
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const SET_EDIT_CAMPAIGN_DATA = "SET_EDIT_CAMPAIGN_DATA";

// Capability actions type
export const SET_DEPARTMENT_LIST = "SET_DEPARTMENT_LIST";
export const SET_DESIGNATION_LIST = "SET_DESIGNATION_LIST";
export const SET_CAPABILITY_TEMPLATE = "SET_CAPABILITY_TEMPLATE";
export const SET_CAPABILITY_RESPONSE = "SET_CAPABILITY_RESPONSE";
export const SET_DEPARTMENT_CAPABILITY_LIST = "SET_DEPARTMENT_CAPABILITY_LIST";
export const START_CAPABILITY_LOADING = "START_CAPABILITY_LOADING";
export const CLEAR_CAPABILITY_RESPONSE = "CLEAR_CAPABILITY_RESPONSE";
export const SET_CAPABILITY_MAP_READONLY_VIEW = "SET_CAPABILITY_MAP_READONLY_VIEW";
export const SET_CAPABILITY_MAP_EDIT_VIEW = "SET_CAPABILITY_MAP_EDIT_VIEW";

// Pagination actions type
export const PAGINATION_PAGESIZE = "PAGINATION_PAGESIZE";
export const PAGINATION_CURRENT_PAGE = "PAGINATION_CURRENT_PAGE";
