import axios from "axios";
import { history } from "../index";
import storage from "./storage";
import store from "../store/store";
import * as actions from "../store/actions/index";

const url = process.env.REACT_APP_API_BASE_URL;
const user = storage.getUser();

//  initialize interceptors
(() => {
  // Request interceptors
  axios.interceptors.request.use(
    (config) => {
      const token = storage.get("token");
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error) => {
      // eslint-disable-next-line no-undef
      Promise.reject(error);
    }
  );

  // Response interceptors
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        let tempToken = { refreshToken: storage.get("refreshToken") };
        return fetch(url + "/refresh-token", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(tempToken),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.httpStatusCode === 200) {
              storage.set("token", res.data.token);
              storage.set("refreshToken", res.data.refreshToken);
              axios.defaults.headers.common["Authorization"] = "Bearer " + storage.get("token");

              if (user) {
                store.dispatch(actions.FetchNotification(user.employeeId));
              }

              return axios(originalRequest);
            } else {
              storage.clear();
              history.go("/");
            }
          });
      } else if (error.response?.status === 500 && !originalRequest._retry) {
        originalRequest._retry = true;
        history.push("/page-not-found");
        // eslint-disable-next-line no-undef
        return Promise.reject(error);
      } else {
        // eslint-disable-next-line no-undef
        return Promise.reject(error);
      }
    }
  );
})();
