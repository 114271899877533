import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row, Modal, Button, Form, Tooltip, Overlay } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import * as actions from "store/actions/index";
import { IC_ERROR } from "const/imgCost";
import { errorToast } from "util/general";
import { errorMessage } from "const/constValue";
import routePath from "const/routePath";

const AuthoriseFeedbackPopup = (props) => {
  const [pwd, setPwd] = useState(null);
  const [passwordError, setPasswordError] = useState(false);
  const targetPassword = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { authorizedResponse, password } = useSelector((state) => state.feedback);
  const passwordHandler = (event) => {
    setPwd(event.target.value);
    setPasswordError(false);
  };

  useEffect(() => {
    if (authorizedResponse) {
      if (authorizedResponse === "SUCCESS") {
        localStorage.setItem("_Afb+_", password);
        history.push(routePath.VIEW_FEEDBACK);
      } else {
        localStorage.removeItem("_Afb+_");
        errorToast(errorMessage.NOT_AUTHORIZED);
        history.push("/");
      }
    }
  }, [authorizedResponse, history, password]);

  const submitFeedback = () => {
    if (!pwd) {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
      props.submit();
      dispatch(actions.fetchFeedbacks(pwd));
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} className="custom_modal_popup">
        <div className="custom_modal_container">
          <Row>
            <Col md={12}>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="exampleForm.password">
                    <h5>Enter Password</h5>
                    <Form.Control as="input" onBlur={(event) => passwordHandler(event)} ref={targetPassword} />
                    <Overlay target={targetPassword} show={passwordError ? true : false} placement="bottom">
                      {(propsTooltip) => (
                        <Tooltip {...propsTooltip} show={propsTooltip.show.toString() || "true"}>
                          <span className="error-icon">
                            <ReactSVG src={`${IC_ERROR}`} title="" alt="" />
                          </span>
                          <span className="error-bubble-txt">Please fill proper details</span>
                        </Tooltip>
                      )}
                    </Overlay>
                  </Form.Group>
                </Form>
              </Modal.Body>
            </Col>
            <Col className="custom_modal_button clearfix">
              <Button variant="primary" onClick={() => submitFeedback()}>
                Submit
              </Button>

              <Button variant="secondary" className="btn-border" onClick={() => props.hide()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AuthoriseFeedbackPopup;
