export default {
  API_ENDPOINTS: {
    login: "/login",
    loginNest: "/loginNest",
    getAllData: "/get-all-data",
    currentCheckIn: "/employee-service/current-cycle",
    checkinExtendAdmin: "/employee-service/checkin-extend",
    checkinExtendAdminHistory: "/employee-service/get-checkin-extend-history",
    pastCheckIn: "/employee-service/get-cycle-based-on-cycleId?surveyCycleId={surveyCycleId}",
    viewCycle: "/employee-service/view-cycle",
    userProfile: "/employee-service/profile",
    myManager: "/employee-service/manager-detail/{id}",
    managerData: "/employee-service/all-manager-detail",
    updateSurveyCycle: "/employee-service/survey-cycle?surveyCycleId={surveyCycleId}",
    autoSaveSurveyCycleTemplate: "/employee-service/survey-cycle/answer-template-questions",
    updateManagerComment: "/employee-service/manger-comment-in-cycle",
    updateManagerCommentInTemplate: "/employee-service/manger-comment-in-cycle-question-template",
    deleteManagerCommentInTemplate: "/employee-service/delete-comment-question-template",
    submitCycle: "/employee-service/submit",
    surveyCycleHistory: "/employee-service/surveycycle-history",
    teamView: "/employee-service/team-view",
    // directReportees: "/employee-service/team-view?isDirect=true",
    deleteHelpToGrow: "/employee-service/manager/delete-help-to-grow",
    teamViewWithFilters: "/employee-service/team-view/{id}?searchBy={searchBy}&filterBy={filterBy}",
    moodMeter: "/employee-service/mood-o-meter?surveyCycleId={surveyCycleId}",
    reviewed: "/employee-service/reviewed",
    deleteComment: "/employee-service/delete-comment",
    helpToGrow: "/employee-service/manager/help-to-grow?surveyCycleId={surveyCycleId}",
    streak: "/employee-service/streak",
    searchEmployeeName:
      "/employee-service/made-my-day/get-employee?employeeName={employeeName}&parentGroupId={parentGroupId}",
    uploadFile: "/employee-service/upload-file",
    downloadFile: "/employee-service/download-file?questionId={qId}&surveyCycleId={surveyCycleId}",
    deleteFile: "/employee-service/delete-file",
    appreciate: "/employee-service/made-my-day",
    appreciateCount: "/employee-service/made-my-day-count",
    managerSurveyCycleHistoryForTeamHistory: "/employee-service/surveycycle-history-for-manager",
    privateManagerComment: "/employee-service/private-manager-comment",
    privateEmployeeComment: "/employee-service/private-employee-comment",
    deletePrivateEmpComment: "/employee-service/delete-private-message",
    clearAllNotification: "/employee-service/notification/clear-all",
    deleteNotification: "/employee-service/notification/{notificationId}",
    filterSurveyCycleHistory: "/employee-service/surveycycle-history",
    addWinChallenge: "/employee-service/win-challenges/manager/add",
    viewWinChallenge: "/employee-service/win-challenges/view",
    checkWinChallenge: "/employee-service/win-challenges/check-if-exist",
    deleteWinChallenge: "/employee-service/win-challenges/{winChallengeId}",
    dragDropWinChallenge: "/employee-service/win-challenges/drag-and-drop",
    plans: "/plan-service/plan-for-next-survey-cycle",
    getPlans: "/plan-service/next-cycle-plan-by-survey-cycle?surveyCycleId={surveyCycleId}",
    previousPlans: "/plan-service/plan-by-survey-cycle?surveyCycleId={surveyCycleId}",
    addPlan: "/plan-service/plan",
    carryForwardPlan: "/plan-service/carry-forward-plan?planId={planId}&surveyCycleId={surveyCycleId}",
    deletePlan: "/plan-service/plan/{planId}",
    changePlanStatus: "/plan-service/change-plan-status/{planId}",
    updatePlan: "/plan-service/plan",
    draftObjective: "/objective-service/objective/draft",
    addObjective: "/objective-service/objective",
    getPrdefinedObjective: "/objective-service/objective/get-predefined-objectives?podInformation={podInformation}&country={country}",
    getSelfPrdefinedObjective: "/objective-service/objective/get-predefined-objectives?podInformation={podInformation}&country={country}&band={band}&isSelfObjective={isSelfObjective}",
    getUserObjectiveWithManagerEmployeePair: "/objective-service/objective/get-user-objective-details-based-on-manager-employee",
    deleteKeyPoint: "/objective-service/objective/key-point?keyPointId={keyPointId}&objectiveId={objectiveId}",
    deleteEditKeyPoint: "/objective-service/objective/master/key-point-list",
    deleteAssignedKeyPoint:
      "/objective-service/objective/user-objective/key-point?keyPointId={keyPointId}&userObjectiveId={userObjectiveId}",
    deleteEditAssignedKeyPoint: "/objective-service/objective/user-objective/key-point-list",
    deleteAssignedTags: "/objective-service/objective/user-objective/tags",
    deleteTags: "/objective-service/objective/tags",
    submitObjective: "/objective-service/objective/validate-create/{objectiveId}",
    assignSelfObjective: "/objective-service/objective/self-objective",
    approveSelfObjective: "/objective-service/objective/approve-self-objective",
    rejectSelfObjective: "/objective-service/objective/reject-self-objective",
    updateObjective: "/objective-service/objective",
    updateAssignedObjective: "/objective-service/objective/manager/user-objective",
    teamObjective: "/objective-service/objective/team-objective",
    managerTeam: "/objective-service/objective/team-objective?userObjectiveId={userObjectiveId}",
    getObjective: "/objective-service/objective/{objectiveId}",
    assignObjective: "/objective-service/objective/assign",
    allObjective: "/objective-service/objective/view-all?definedBy={definedBy}",
    managerObjectiveActivity: "/objective-service/objective/manager/activity",
    objectiveActivity: "/objective-service/objective/activity",
    deleteObjective: "/objective-service/objective",
    getUserObjectives: "/objective-service/objective/view-all-user-objective",
    linkObjectivePlan:
      "/plan-service/map-objective-to-plan?planId={planId}&objectiveId={objectiveId}&description={description}",
    assignedUserObjective: "/objective-service/objective/user-objective/{userObjectiveId}",
    userObjective: "/objective-service/objective/user-objective/{userObjectiveId}",
    deselectObjective: "/plan-service/deselect-objective-from-plan",
    updateUserObjective: "/objective-service/objective/user-objective",
    updateUserSelfObjective: "/plan-service/private-assessment/auto-save/objective-keypoint?assessmentType=SELF",
    userObjectiveHistory: "/objective-service/objective/user-objective-history?surveyCycleId={surveyCycleId}",
    notifyObjective: "/objective-service/objective/notify-to-manager-for-user-objective",
    acceptOrRejectCompletion:
      "/objective-service/objective/manager/validate-user-objective?objectiveStatus={objectiveStatus}",
    getQuarterlyCycleList: "/plan-service/private-assessment/quarterly-cycle-list",
    getAllReporteeAssessmentList:
      "/plan-service/private-assessment/reportee-list-by-manager-type?managerType={managerType}&cycleId={cycleId}",
    getAllHelpToGrow: "/employee-service/help-to-grow",
    getObjectiveProgressStatus:
      "/objective-service/objective/user-objective/status?userObjectiveId={userObjectiveId}&progressStatus={progressStatus}",
    objectiveComment: "/objective-service/objective/user-objective/comment",
    objectivePrivateReply: "/objective-service/objective/user-objective/reply-comment",
    deleteObjectiveComment: "/objective-service/objective/delete-comment",
    fileUpload: "/objective-service/objective/upload-objective",
    fetchTag: "/objective-service/objective/tags",
    getPrivateAssessmentData: "/plan-service/private-assessment",
    // uploadFilePrivateAssessment: "/employee-service/private-assessment-proxy/upload-file",
    downloadFilePrivateAssessment:
      "/employee-service/private-assessment-proxy/download-file?questionId={questionId}&privateAssessmentId={privateAssessmentId}",
    deleteFilePrivateAssessment: "/employee-service/private-assessment-proxy/delete-file",
    autoSavePrivateAssessment: "/plan-service/private-assessment/auto-save",
    uploadFilePrivateAssessmentTemplate: "/employee-service/private-assessment-proxy/template/upload-file",
    downloadFilePrivateAssessmentTemplate:
      "/employee-service/private-assessment-proxy/template/download-file?questionId={questionId}&privateAssessmentId={privateAssessmentId}&questionTemplateId={questionTemplateId}&assessmentType={assessmentType}&fileId={fileId}",
    deleteFilePrivateAssessmentTemplate: "/employee-service/private-assessment-proxy/template/delete-file",
    autoSavePrivateAssessmentTemplate: "/plan-service/private-assessment/auto-save/template",
    submitPrivateAssessment:
      "/plan-service/private-assessment/submit?privateAssessmentId={privateAssessmentId}&assessmentType={assessmentType}",
    assessmentComment: "/plan-service/private-assessment/objective/assessment-comment",
    previousOneOnOneData: "/plan-service/one-on-one-agenda/previous",
    currentOneOnOneData: "/plan-service/one-on-one-agenda/current",
    getUserOneOnOne: "/plan-service/one-on-one-agenda?id={id}",
    deletePrivateNote: "/plan-service/one-on-one-agenda/private-notes",
    deleteSharedNote: "/plan-service/one-on-one-agenda/shared-notes",
    deleteTalkingPoint: "/plan-service/one-on-one-agenda/talking-point",
    scheduleOneOnOne:
      "/plan-service/one-on-one-agenda/schedule?id={id}&date={date}&from={from}&to={to}&timeZone={timeZone}",
    managerScheduleOneOnOne:
      "/plan-service/one-on-one-agenda/end/schedule?id={id}&date={date}&from={from}&to={to}&timeZone={timeZone}",
    markTalkingPoint: "/plan-service/one-on-one-agenda/talking-point-complete",
    oneOnOneAgenda: "/plan-service/one-on-one-agenda",
    getOneOnOneAgendaComment: "/plan-service/one-on-one-agenda/comments?surveyCycleId={surveyCycleId}",
    timeZone: "/plan-service/one-on-one-agenda/getTimeZones?id={id}",
    getScheduleTime: "/plan-service/one-on-one-agenda/getSchedule?id={id}&date={date}&timeZone={timeZone}",
    endOneOnOne: "/plan-service/one-on-one-agenda/end?id={id}",
    oneOnOneManagers: "/plan-service/one-on-one-agenda/manager",
    oneOnOneMembers: "/plan-service/one-on-one-agenda/manager-and-reportees?monthFilter={month}",
    oneOnOneReportee: "/plan-service/one-on-one-agenda/reportees",
    notification: "/plan-service/one-on-one-agenda/viewAllNotifications", //temporary add plan-service after change to employee-service
    allNotification: "/plan-service/one-on-one-agenda/viewAllNotifications?viewAll=viewAll", //temporary add plan-service after change to employee-service
    markAsReadNotification: "/plan-service/one-on-one-agenda/{notificationId}",
    markAllAsRead: "/plan-service/one-on-one-agenda/mark-all-read",
    submitCertificate: "/objective-service/certificate/upload",
    getCertificateName: "/objective-service/certificate/name",
    getCertificateList: "/objective-service/certificate",
    getCertificateListUniversityManager: "/objective-service/certificates",
    getCertificateOverviewList: "/objective-service/certificate-overview",
    downloadCertificate: "/objective-service/certificate/download/{id}",
    deleteCertificate: "/objective-service/certificate/{id}",
    addSkill: "/objective-service/skill/employee/add-additional-skill ",
    getSkills: "/objective-service/skill/master",
    draftSkill: "/objective-service/skill/draft",
    additionalSkillList: "/objective-service/skill/employee/additional-skills",
    downloadResume: "/objective-service/skill/resume/download",
    downloadResumeTemplate: "/objective-service/skill/resume/template/download",
    uploadMyResume: "/objective-service/skill/resume/Upload",
    getParticularSkill: "/objective-service/skill/employee/get-particular-skill?skillNameId={skillNameId}",
    updateAdditionalSkill: "/objective-service/skill/employee/update-additional-skill",
    updateDraft: "/objective-service/skill/draft/additional-skill-draft",
    updateSkillInMaster: "/objective-service/skill/master/update-skill",
    deleteAdditionalSkill: "/objective-service/skill/employee/delete-additional-skill/{id}",
    template: "/plan-service/private-assessment/template",
    detailTemplate: "/plan-service/private-assessment/template?questionTemplateId={questionTemplateId}",
    deleteTemplateQuestion:
      "/plan-service/private-assessment/template/question?questionTemplateId={questionTemplateId}&questionId={questionId}",
    getDepartmentList: "/objective-service/skill/department",
    getCategoryList: "/objective-service/skill/category",
    getRoleByDepartment: "/objective-service/skill/role/{id}",
    getTechnicalRole: "/objective-service/skill/technical-role?departmentId={departmentId}&roleId={roleId}",
    saveRequiredSkill: "/objective-service/skill/departmentRoleSkillConfiguration",
    getRequiredSkillConfig: "/objective-service/skill/departmentRoleSkillConfiguration/{id}",
    saveMyRequiredSkill: "/objective-service/skill/employeeDepartmentRoleSkill",
    getMyDepartmentTechRole: "/objective-service/skill/employeeDepartmentTechnicalRole",
    createAssessmentCycle: "/plan-service/private-assessment/cycle",
    getCreatedAssessmentCycle: "/plan-service/private-assessment/quarterly-cycle?cycleId={cycleId}",
    getQuarterelyCycle: "/plan-service/private-assessment/get-quarterly-cycle?cycleId={cycleId}",
    templateList: "/plan-service/private-assessment/template-list",
    reOpenAssessment: "/plan-service/private-assessment/re-open",
    getFeedback: "/employee-service/feedback?password={password}",
    postFeedback: "/employee-service/feedback",
    updateFeedback: "/employee-service/feedback?id={id}&status={status}",
    downloadFeedback: "/employee-service/download-feedback/{id}",
    replaceManagerWCycleId:
      "/plan-service/replace-cycle-manager?employeeIds={employees}&oldManagerId={oldManager}&newManagerId={newManager}&quarterlyCycleId={cycleId}",
    replaceManager: "/plan-service/hr/performance/replace-manager",
    createManagerAssessment: "/plan-service/hr/performance/create-with-manager",
    deleteManagerAssessment: "/plan-service/hr/performance/delete-manager-assessment",
    listRoleWiseSkillForMMG: "/objective-service/skill/roleWiseSkillConfiguration",
    employeeListForHR: "/plan-service/private-assessment/hr/employee-assessments",
    listEmployeeRequiredSkill: "/objective-service/skill/employeeDepartmentRoleSkill",
    getRequiredSkillDetails: "/objective-service/skill/singleRoleWiseSkillConfiguration/{id}",
    privateAssessmentRating: "/plan-service/private-assessment/auto-save/objective?assessmentType={assessmentType}",
    privateAssessmentRatingComment: "/plan-service/private-assessment/auto-save/objective",
    getUserPrivateAssessmentData: "/plan-service/private-assessment/user?cycleId={cycleId}",
    downloadAssessment: "/plan-service/private-assessment/report/employee-assessment/{cycleId}/{assessmentType}",
    downloadSelfAssessment:
      "/plan-service/private-assessment/report/self-assessment-ratings/{cycleId}/{assessmentType}",
    downloadAssessmentStatus:
      "/plan-service/private-assessment/report/status-list/{cycleId}?assessmentType={assessmentType}",
    downloadEmployeeList:
      "/plan-service/private-assessment/report/employee-list/{cycleId}?assessmentType={assessmentType}",
    downloadEmployeeObjective:
      "/plan-service/private-assessment/report/employee-objective/{cycleId}?assessmentType={assessmentType}",
    downloadEmployeeSelfAssessment: "/plan-service/private-assessment/report/self-employee-assessment-export/{cycleId}",
    endorsementSkill: "/objective-service/skill/skillOfEmployee",
    employeeList: "/objective-service/skill/employeeList",
    endorsementType: "/objective-service/skill/endorseType",
    getTechnicalRoleList: "/objective-service/skill/getTechnicalRoleName",
    getBand: "/objective-service/skill/employee/band",
    getFilterData: "/objective-service/skill/employeeListForManager",
    endorseEmployee: "/objective-service/skill/endorseByMe",
    endorseReq: "/objective-service/skill/requestForEndorsement ",
    employeeNameList: "/plan-service/private-assessment/hr/employee-list",
    employeeTypeList: "/plan-service/private-assessment/hr/employee-type-list",
    getEndorsementsByMe: "/objective-service/skill/endorsementByMe",
    exportList: "/objective-service/skill/report/employeeListForManager",
    getEndorseReqForEmp: "/objective-service/skill/endorseRequestForEmployee",
    getMyEndorsement: "/objective-service/skill/myEndorsedSkill",
    getPendingDeclineRequest: "/objective-service/skill/nonAcceptedRequest",
    approveEndorseReq: "/objective-service/skill/endorseRequestForEmployee",
    declineEndorseReq: "/objective-service/skill/declineRequestForEmployee ",
    // updateObjectKeyResult: "/objective-service/objective/manager/update-key-result",
    getMMGEndorserName: "/objective-service/skill/endorserNameForWMG",
    sendMMGEndorseReq: "/objective-service/skill/endorseRequestByWMG",
    getMMGEndorseReq: "/objective-service/skill/endorseRequestByWMG",
    getUniversityReq: "/objective-service/skill/universityRequests",
    getUniversityApproveDeclineReq: "/objective-service/skill/universityRequestStatus",
    approveDeclineUniversityReq: "/objective-service/skill/endorseUniversityRequest",
    getDraftForDefineSkill: "/objective-service/skill/departmentRoleSkillConfiguration/draft",
    getSkillMasterCategory: "/objective-service/skill/skillMasterCategory ",
    categoryWiseSkillMaster: "/objective-service/skill/masterCategorySkill",
    addCategoryInSkillMaster: "/objective-service/skill/skillMasterCategory",
    updateObjectKeyResult: "/objective-service/objective/manager/update-key-result?definedBy={definedBy}",
    cancelObjectiveKeyResult: "/objective-service/objective/manager/clear-user-objective?definedBy={definedBy}",
    templateCategory: "/plan-service/private-assessment/templateCat",
    getTemplateCategory: "/plan-service/private-assessment/templateCategory",
    templateScale: "/plan-service/private-assessment/templateScale",
    getFilteredEmployeesObjectives: "/employee-service/hr/employee-objective",
    getHrDashboardReport: "/employee-service/hr/dashboard",
    getHrDashboardExcelReport: "/employee-service/report/checkIns-list?startDate={startDate}&endDate={endDate}",
    getEmployeeObjectiveChartData: "/employee-service/hr/dashboard/employee-objective-graphdata",
    getEmployeeObjectiveExportReport: "/employee-service/hr/dashboard/employee-objective-export",
    updateTechnicalRole: "/objective-service/skill/employeeDepartmentTechnicalRole/changeRequest",
    resumeUpdatedConfirmation: "/objective-service/skill/submitAcknowledgementForSkill ",
    checkResumeUpdateReminder: "/objective-service/skill/checkForSkillUpdateReminder",
    listForMMG: "/objective-service/skill/employeeDepartmentTechnicalRole/changeRequestForWMG",
    approveRejectRequestForTechnicalRoleChangeMMG:
      "/objective-service/skill/employeeDepartmentTechnicalRole/changeRequest/status",
    myTechnicalRoleChangeRequestList: "/objective-service/skill/employeeDepartmentTechnicalRole/changeRequestForUser",
    universityMemberCertificateExport: "/objective-service/certificates-export",
    universityMemberCertificateExportSearch: "/objective-service/certificates-export?search={search}",
    universityMemberCertificateExportFilter: "/objective-service/certificates-export?filter={filter}",
    universityMemberCertificateExportSearchFilter:
      "/objective-service/certificates-export?filter={filter}&search={search}",
    getInformationHubData : "/employee-service/information-hub/get-information-about-system",
    getCurrentfeedbackEmployee : "/employee-service/monthly-checkin-feedback/current-feedback-employee",
    showYearList : "/employee-service/monthly-checkin-feedback/show-year-list",
    getCurrentfeedbackManager : "/employee-service/monthly-checkin-feedback/current-feedback-manager",
    feedbackInteractionEmployeeAndManager : "/employee-service/monthly-checkin-feedback/feedback-interaction-employee-and-manager?role={role}",
    submitFeedbackEmployeeManager : "/employee-service/monthly-checkin-feedback/feedback-submit-employee-and-manager?role={role}",
    feedbackHistoryEmployee: "/employee-service/monthly-checkin-feedback/show-feedback-history-employee-view?parent_group_id={parent_group_id}&sub_group_id={sub_group_id}&userId={userId}",
    feedbackHistoryManager: "/employee-service/monthly-checkin-feedback/show-feedback-history-manager-view?parent_group_id={parent_group_id}&sub_group_id={sub_group_id}&userId={userId}",
    getManagerListEmployee: "/employee-service/monthly-checkin-feedback/get-manager-list?period={period}",
    getHistoryDetailsEmployeeAndManager : "/employee-service/monthly-checkin-feedback/history-details?employeeId={employeeId}&parentGroupId={parentGroupId}&subGroupId={subGroupId}&period={period}&role={role}",
    getCountDurationStatusEmployeeAndManager : "/employee-service/monthly-checkin-feedback/get-count-duration-status-employee-and-manager?role={role}",
    getFiltersDetailsHr: "/employee-service/monthly-checkin-feedback/monthly-feedback-dropdown-with-cascading-hr-view",
    getCheckinFeedbackExport: "/employee-service/monthly-checkin-feedback/checkin-feedback-export",
    showFeedbackDetailsInHRView: "/employee-service/monthly-checkin-feedback/show-employee-manager-feedback-details-hr-view?employeeId={employeeId}&managerId={managerId}&parent_group_id={parent_group_id}&sub_group_id={sub_group_id}&period={period}&userId={userId}&user_role={user_role}",
    getQuestionBasedExportEmployee: "/employee-service/monthly-checkin-feedback/question-based-export-for-employee-hr?questionId={questionID}",
    getQuestionBasedExportManager: "/employee-service/monthly-checkin-feedback/question-based-export-for-manager-hr?questionId={questionID}",
    getDashboardDataInHRView: "/employee-service/monthly-checkin-feedback/feedback-dashboard",
    getGraphCheckinFeedbackHR: "/employee-service/monthly-checkin-feedback/graph-checkin-feedback-hr",
    getEmployeeNameForEmployeeSkillListing: "/objective-service/skill/allEmployeeList",
    getTeamFeedback: "/employee-service/teams-globalfeedback",
    globalFeedback: "/employee-service/globalfeedback",
    assessmentGlobalComment: "/plan-service/private-assessment/global-comment",
    assessmentRPMGlobalComment: "/plan-service/private-assessment/global-comment/rpm",
    assessmentSELFGlobalComment: "/plan-service/private-assessment/global-comment/user",
    deallocationListForManager: "/plan-service/private-assessment/deallocation-reportees",
    uploadObjectiveAssessmentFile: "/plan-service/private-assessment/objective/upload-files",
    downloadObjectiveAssessmentFile:
      "/plan-service/private-assessment/objective/download-file?userObjectiveId={userObjectiveId}&quarterlyCycleId={quarterlyCycleId}&fileId={fileId}",
    deleteObjectiveAssessmentFile: "/plan-service/private-assessment/objective/delete-file",
    deallocationQuestionUploadFile: "/plan-service/private-assessment/deallocation-feedback/upload-file",
    deallocationQuestionDownloadFile:
      "/plan-service/private-assessment/deallocation-feedback/download-file?questionId={questionId}&projectDeAllocationId={projectDeAllocationId}&fileId={fileId}",
    deallocationQuestionDeleteFile: "/plan-service/private-assessment/deallocation-feedback/delete-file",
    deallocationEmployeeDetails: "/plan-service/private-assessment/project-deallocation/{projectDeAllocationId}",
    updateDeallocationFeedback: "/plan-service/private-assessment/deallocation-feedback",
    validateObjectiveForDeallocation: "/objective-service/objective/submit-userobjective-for-deallocation",
    deallocationReporteeList: "/plan-service/private-assessment/deallocation-reportees/filters",
    downloadDeallocationData: "/plan-service/deallocation-reportees/report",
    globalFeedbackListForHR:
      "/employee-service/hr/dashboard/global-feedback-HR?startDate={startDate}&endDate={endDate}",
    globalFeedbackReport:
      "/employee-service/hr/dashboard/global-Feedback-HR-export?startDate={startDate}&endDate={endDate}",
    createSingleAssessment: "/plan-service/hr/performance/create",
    deleteAssessmentForEmployee: "/plan-service/hr/performance/delete/{cycleId}",
    offlineRatingForEmployee:
      "/plan-service/private-assessment/offline-rating-for-employee?quarterlyCycleId={quarterlyCycleId}",
    getHRQuarterlyCycleList: "/plan-service/hr/performance/quarterly-cycle-list",
    getHREmployeeNameList: "/plan-service/hr/performance/employee-list?name={name}",
    getEmployeeNameList: "/plan-service/timeline/search-employee-list?name={name}",
    getCurrentManagersList: "/plan-service/hr/performance/employee-pms-detail",
    getNewManagersList: "/plan-service/hr/performance/manager-list",
    managerTeamAssessmentList: "/plan-service/manager/team-assessment",
    getAllDataForRoleAssign: "/employee-service/role-rights/list-data",
    createRoleRights: "/employee-service/role-rights",
    getRoleRightsList: "/employee-service/role-rights/get-role-list",
    getPredefinedObjectiveAdminView: "/objective-service/admin-configuration/get-predefined-objective-admin-view",
    createPredefinedObjectiveAdminView: "/objective-service/admin-configuration/create-okr-template",
    getManagerListPredefinedObjective: "/objective-service/admin-configuration/get-manager-list-admin-view",
    updatePredefinedObjectiveAdminView: "/objective-service/admin-configuration/edit-predefined-objective-admin-view?bandWiseObjectiveId={bandWiseObjectiveId}",
    deletePredefinedObjectiveAdminView: "/objective-service/admin-configuration/delete-predefined-objective-admin-view?bandWiseObjectiveId={bandWiseObjectiveId}",
    getAllEmployeeNameList: "/employee-service/employee-list?name={name}",
    updateRoleRight: "/employee-service/role-rights/{roleName}",
    userRoleRightsAssign: "/employee-service/role-rights/user",
    deallocationFeedbackDataList: "/plan-service/private-assessment/hr/deallocation-feedback-data-list",
    objectiveGradeRatingCount: "/plan-service/hr/dashboard/objective-count",
    privateAssessmentCount: "/plan-service/hr/dashboard/pa-count",
    objectiveGradeRatingPopup: "/plan-service/hr/dashboard/objective-pop-up",
    privateAssessmentPopup: "/plan-service/hr/dashboard/pa-pop-up",
    dropdownOptionsForManager: "/plan-service/manager/dashboard/get-filter-list",
    getQuarterlyCycleListForManager: "/plan-service/manager/dashboard/get-quarterly-cycle-list",
    privateAssessmentGradeCountForManager: "/plan-service/manager/dashboard/objective-count",
    privateAssessmentCountForManager: "/plan-service/manager/dashboard/pa-count",
    objectiveGradePopupForManager: "/plan-service/manager/dashboard/objective-pop-up",
    privateAssessmentPopupForManager: "/plan-service/manager/dashboard/pa-pop-up",
    employeeViewAssessmentComparisonForManager: "/plan-service/manager/dashboard/employee-view",
    employeeViewAssessmentComparisonExportForManager: "/plan-service/manager/report/dashboard/employee-view",
    privateAssessmentPopupReportForManager: "/plan-service/manager/report/dashboard/private-assessment-pop-up",
    objectiveGradePopupReportForManager: "/plan-service/manager/report/dashboard/objective-pop-up",
    objectiveGradeReportForManager: "/plan-service/manager/report/dashboard/objective-count",
    privateAssessmentCountReportForManager: "/plan-service/manager/report/dashboard/private-assessment-count",
    getAllCycleName: "/plan-service/hr/dashboard/get-quarterly-cycle-list",
    projectList: "/plan-service/hr/dashboard/get-project-list",
    dropdownOptions: "/plan-service/hr/dashboard/get-filter-list",
    gradeComparison: "/plan-service/hr/dashboard/employee-grade-comparison",
    employeeViewAssessmentComparison: "/plan-service/hr/dashboard/employee-view",
    employeeViewAssessmentComparisonExport: "/plan-service/hr/report/dashboard/employee-view",
    privateAssessmentPopupReport: "/plan-service/hr/report/dashboard/private-assessment-pop-up",
    objectiveGradePopupReport: "/plan-service/hr/report/dashboard/objective-pop-up",
    objectiveGradeReport: "/plan-service/hr/report/dashboard/objective-count",
    privateAssessmentCountReport: "/plan-service/hr/report/dashboard/private-assessment-count",
    pendingApprovalAndNotAssignedObjective: "/objective-service/objective/pending-approval-and-not-assigned-objective",
    getAssessmentReportChartData: "/plan-service/hr/dashboard/assessment-count",
    managerPendingToDo: "/plan-service/manager/pending-to-dos",
    pendingObjectiveApproval: "/objective-service/manager/pending-to-dos",
    pendingOneOnOne: "/plan-service/employee/one-on-ones?dateFilter={month}",
    teamsCheckin: "/employee-service/manager/check-in-details?monthFilter={month}",
    currentPreviousCheckin: "/employee-service/manager/current-previous-check-in-details",
    employeePendingToDo: "/employee-service/employee/pending-to-dos",
    unassignedObjectives: "/objective-service/manager/team-unassigned-objectives",
    locationList: "/plan-service/private-assessment/hr/location-list",
    employeeFilterListForHrTimeline: "/plan-service/timeline/employee-filter-list",
    assessmentAcknowledgement: "/plan-service/private-assessment/acknowledge-grade",
    departmentList: "/employee-service/campaign/department-list",
    designationListFromDepartment: "/employee-service/campaign/department-wise-designation-list",
    setCampaign: "/employee-service/campaign",
    importQuestionTemplate: "/employee-service/campaign/import-question-temaplate",
    locationListInCampaign: "/employee-service/location-list",
    createdCampaign: "/employee-service/campaign/{campaignId}",
    deleteCampaign: "/employee-service/campaign/{campaignId}/{type}",
    cloneCampaign: "/employee-service/campaign/clone/{campaignId}",
    campaignListForAdmin: "/employee-service/campaign/campaign-list-for-admin",
    capabilityListForAdmin: "/employee-service/campaign/capability-development-list-for-admin",
    campaignLocationFilter: "/employee-service/campaign/campaign-filter-for-location",
    previewCampaign: "/employee-service/campaign/preview?campaignId={campaignId}",
    campaignListForEmployee: "/employee-service/campaign/my-campaign-list",
    campaignDetail: "/employee-service/campaign/employee/{employeeCampaignId}",
    pendingCampaignDetail: "/employee-service/campaign/get-campaign-template",
    filterDataForCampaign: "/employee-service/campaign/filter-data-list?campaignId={campaignId}",
    employeeCampaignDetailList: "/employee-service/campaign/employee-campaign-details?campaignId={campaignId}",
    campaignFilter: "/employee-service/campaign/campaign-filter?campaignId={campaignId}",
    updateCampaignFilter: "/employee-service/campaign/campaign-filter/{campaignFilterId}?campaignId={campaignId}",
    deleteCampaignFilter: "/employee-service/campaign/campaign-filter/{campaignFilterId}?campaignId={campaignId}",
    campaignTemplateList: "/employee-service/campaign/template-list",
    campaignAnswerUpdate: "/employee-service/campaign/employee/answer-campaign-questions",
    quizAnswerUpdate: "/employee-service/campaign/employee/answer-quiz-questions",
    campaignManagerAnswerUpdate: "/employee-service/campaign/manager/answer-campaign-questions",
    questionTemplateCategory: "/plan-service/private-assessment/question-category",
    submitCampaignTemplate: "/employee-service/campaign/employee/submit?campaignId={campaignId}",
    campaignQuestionStats: "/employee-service/campaign/get-campaign-stats",
    campaignQuestionPopup: "/employee-service/campaign/pop-up",
    exportCampaignQuestion: "/employee-service/campaign/report/download-campaign-stats",
    notificationFilterList: "/plan-service/notification-broadcast/filter-list",
    projectLists: "/plan-service/notification-broadcast/project-list",
    employeeTypeLists: "/plan-service/notification-broadcast/employee-type-list",
    bandLists: "/plan-service/notification-broadcast/band-list",
    departmentLists: "/plan-service/notification-broadcast/department-list",
    locationLists: "/plan-service/notification-broadcast/location-list",
    clientList: "/plan-service/notification-broadcast/client-list",
    employeeLists: "/plan-service/notification-broadcast/employee-list?name={name}",
    sendBotNotification: "/botnotification-service/notification-broadcast/send",
    notificationEmployeeCount: "/botnotification-service/notification-broadcast/count",
    botNotificationList: "/botnotification-service/notification-broadcast",
    surveyCycleConfiguration: "/botnotification-service/survey-cycle/addClientSurveyCycle",
    editSurveyCycleConfiguration: "/botnotification-service/survey-cycle/editClientSurveyCycle",
    deleteSurveyCycleConfiguration: "/botnotification-service/survey-cycle/deleteClientSurveyCycle",
    getSurveyCycleConfiguration: "/botnotification-service/survey-cycle/getClientSurveyCycles",
    getSurveyCycleTemplateList: "/employee-service/survey-cycle/template-list",
    getLocationListFromSubgroup: "/botnotification-service/survey-cycle/location-list",
    botNotificationEmployeeList:
      "/botnotification-service/notification-broadcast/getBroadcast?notificationBroadcastId={notificationBroadcastId}",
    organizationList: "/employee-service/role-rights/super-admin/get-client-list",
    employeeListFromClient:
      "/employee-service/role-rights/super-admin/get-employee-list-as-client?name={name}&parentGroupId={parentGroupId}",
    moduleList: "/employee-service/role-rights/super-admin/get-module-list",
    clientAdminModuleList: "/employee-service/role-rights/super-admin/get-client-admin-module-list",
    createAdminForClient: "/employee-service/role-rights/super-admin/create-admin-for-client",
    updateAdminForClient: "/employee-service/role-rights/super-admin/update-admin-or-module",
    getSubGroupForClient: "/employee-service/get-sub-group-for-client",
    getSubGroupForClientWithoutRole: "/employee-service/get-sub-groups-without-role-rights",
    employeeCapabilityTemplate: "/employee-service/campaign/get-capability-development-template",
    capabilityDevelopmentTemplateList: "/employee-service/campaign/capability-development-template-list",
    managerCapabilityComment: "/employee-service/campaign/manager/campaignComment",
    departmentWiseCapabilityTemplate: "/employee-service/campaign/question-map",
    employeeTimeline: "/plan-service/timeline",
    teamAssessment: "/plan-service/timeline/team-assessment",
    hrDashboardFilterOption: "/employee-service/hr/dashboard/HR-filters",
    oneOnOneTeamListForHR: "/plan-service/one-on-one-agenda/get-one-on-one-agenda-hr-view",
    employeeCampaignQuizDetails: "/employee-service/campaign/employee-campaign-quiz-details?campaignId={campaignId}",
    quizTemplateEmployeeView: "/employee-service/campaign/get-quiz-template-employee-view",
    quizQuestionsEmployeeView: "/employee-service/campaign/get-quiz-questions-employee-view?campaignId={campaignId}",
    quizResumeQuestionsEmployeeView: "/employee-service/campaign/resume-quiz?campaignId={campaignId}",
    quizRefreshQuestionsEmployeeView:
      "/employee-service/campaign/get-quiz-questions?campaignId={campaignId}&timeStamp={timeStamp}",
    submitQuiz: "/employee-service/campaign/submit-quiz?campaignId={campaignId}&attemptNumber={attemptNumber}",
    getQuizResultForEmployee: "/employee-service/campaign/get-quiz_result?campaignId={campaignId}",
    getQuizResultForHr:
      "/employee-service/campaign/get-quiz_result?campaignId={campaignId}&attemptNumber={attemptNumber}&employeeId={employeeId}&subGroupId={subGroupId}&parentGroupId={parentGroupId}",
    wrongAnswersForEmployee:
      "/employee-service/campaign/wrong-answers?campaignId={campaignId}&attemptNumber={attemptNumber}",
    wrongAnswersForHr:
      "/employee-service/campaign/wrong-answers?campaignId={campaignId}&attemptNumber={attemptNumber}&employeeId={employeeId}&subGroupId={subGroupId}&parentGroupId={parentGroupId}",
    viewDetailsExport: "/employee-service/campaign/view-details-export?campaignId={campaignId}",
    employeeQuizGraphData: "/employee-service/campaign/quiz-details-and-graph-api?campaignId={campaignId}",
    getDetailsIndependentOfRoleRights:
      "/employee-service/get-details-independent-of-role-rights?campaignId={campaignId}",
    countryList: "/employee-service/campaign/get-country-list?campaignId={campaignId}",
    editCampaign:
      "/employee-service/campaign/edit-campaign?campaignId={campaignId}&newLocationAdded={newLocationAdded}",
    uploadFileGlobalFeedback: "/plan-service/private-assessment/upload-files-global-feedback",
    downloadFileGlobalFeedback:
      "/plan-service/private-assessment/download-file-global-feedback?&privateAssessmentId={privateAssessmentId}&fileId={fileId}",
    deleteFileGlobalFeedback:
      "/plan-service/private-assessment/delete-files-global-feedback?fileId={fileId}&privateAssessmentId={privateAssessmentId}",
  },

  MOCK_ENDPOINTS: {
    login: "login.json",
    currentCheckIn: "current-checkin.json",
    viewCycle: "view-cycle.json",
    userProfile: "",
    myManager: "manager.json",
    teamView: "",
  },
};

export const USER_CHECKIN = "currentCheckIn";
export const CHEKIN_EXTEND_ADMIN = "checkinExtendAdmin";
export const CHEKIN_EXTEND_ADMIN_HISTORY = "checkinExtendAdminHistory";
export const PAST_CHECKIN = "pastCheckIn";
export const VIEW_CYCLE = "viewCycle";
export const LOGIN = "login";
export const LOGIN_NEST = "loginNest";
export const GET_ALL_DATA = "getAllData";
export const USER_PROFILE = "userProfile";
export const MANAGER_PROFILE = "myManager";
export const UPDATE_SURVEY_CYCLE = "updateSurveyCycle";
export const AUTO_SAVE_SURVEY_CYCLE_TEMPLATE = "autoSaveSurveyCycleTemplate";
export const SUBMIT_CYCLE = "submitCycle";
export const SURVEY_CYCLE_HISTORY = "surveyCycleHistory";
export const TEAM_VIEW = "teamView";
// export const DIRECT_REPORTEES = "directReportees";
export const TEAM_VIEW_FILTERS = "teamViewWithFilters";
export const MOOD_METER = "moodMeter";
export const UPDATE_MANAGER_COMMENT = "updateManagerComment";
export const UPDATE_MANAGER_COMMENT_IN_TEMPLATE = "updateManagerCommentInTemplate";
export const DELETE_MANAGER_COMMENT_IN_TEMPLATE = "deleteManagerCommentInTemplate";
export const REVIEWED = "reviewed";
export const DELETE_COMMENT = "deleteComment";
export const HELP_TO_GROW = "helpToGrow";
export const GET_ALL_HELP_TO_GROW = "getAllHelpToGrow";
export const MANAGER_DATA = "managerData";
export const DELETE_HELP_TO_GROW = "deleteHelpToGrow";
export const STREAK = "streak";
export const SEARCH_NAME = "searchEmployeeName";
export const UPLOAD_FILE = "uploadFile";
export const DOWNLOAD_FILE = "downloadFile";
export const DELETE_FILE = "deleteFile";
export const APPRECIATE = "appreciate";
export const MANAGER_SURVEY_HISTORY_IN_TEAM = "managerSurveyCycleHistoryForTeamHistory";
export const PRIVATE_MANAGER_COMMENT = "privateManagerComment";
export const PRIVATE_EMPLOYEE_COMMENT = "privateEmployeeComment";
export const DELETE_PRIVATE_EMP_COMMENT = "deletePrivateEmpComment";
export const GET_APPRECIATE_COUNT = "appreciateCount";
export const FETCH_NOTIFICATION = "notification";
export const FETCH_ALL_NOTIFICATION = "allNotification";
export const READ_NOTIFICATION = "markAsReadNotification";
export const MARK_ALL_READ_NOTIFICATION = "markAllAsRead";
export const CLEAR_ALL_NOTIFICATION = "clearAllNotification";
export const DELETE_NOTIFICATION = "deleteNotification";

/*
YOU MADE MY DAY
WIN
CHALLENGE
PRIVATE COMMENT
SUBMIT
REVIEWED
*/
export const FILTER_SURVEY_CYCLE_HISTORY = "filterSurveyCycleHistory";

export const ADD_WIN_CHALLENGE = "addWinChallenge";
export const VIEW_WIN_CHALLENGE = "viewWinChallenge";
export const DRAG_DROP_WIN_CHALLENGE = "dragDropWinChallenge";
export const CHECK_WIN_CHALLENGE = "checkWinChallenge";
export const DELETE_WIN_CHALLENGE = "deleteWinChallenge";
export const PLANS = "plans";
export const GET_PLANS = "getPlans";
export const PREVIOUS_PLANS = "previousPlans";

export const ADD_PLAN = "addPlan";
export const CARRY_FORWARD_PLAN = "carryForwardPlan";
export const DELETE_PLAN = "deletePlan";
export const CHANGE_PLAN_STATUS = "changePlanStatus";
export const UPDATE_PLAN = "updatePlan";

export const DRAFT_OBJECTIVE = "draftObjective";
export const ADD_OBJECTIVE = "addObjective";
export const GET_PREDEFINED_OBJECTIVE = "getPrdefinedObjective";
export const GET_SELF_PREDEFINED_OBJECTIVE = "getSelfPrdefinedObjective";
export const GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR = "getUserObjectiveWithManagerEmployeePair";
export const DELETE_KEY_POINT = "deleteKeyPoint";
export const DELETE_EDITED_KEY_POINT = "deleteEditKeyPoint";
export const DELETE_ASSIGNED_KEY_POINT = "deleteAssignedKeyPoint";
export const DELETE_EDITED_ASSIGNED_KEY_POINT = "deleteEditAssignedKeyPoint";
export const DELETE_ASSIGNED_TAGS = "deleteAssignedTags";
export const DELETE_TAGS = "deleteTags";
export const SUBMIT_OBJECTIVE = "submitObjective";
export const ASSIGN_SELF_OBJECTIVE = "assignSelfObjective";
export const APPROVE_SELF_OBJECTIVE = "approveSelfObjective";
export const REJECT_SELF_OBJECTIVE = "rejectSelfObjective";
export const UPDATE_OBJECTIVE = "updateObjective";
export const UPDATE_ASSIGNED_OBJECTIVE = "updateAssignedObjective";
export const TEAM_OBJECTIVE = "teamObjective";
export const MANAGER_TEAM = "managerTeam";
export const GET_OBJECTIVE = "getObjective";
export const ASSIGN_OBJECTIVE = "assignObjective";
export const ALL_OBJECTIVE = "allObjective";
export const OBJECTIVE_ACTIVITY = "objectiveActivity";
export const MANAGER_OBJECTIVE_ACTIVITY = "managerObjectiveActivity";
export const GET_USER_OBJECTIVES = "getUserObjectives";
export const DELETE_OBJECTIVE = "deleteObjective";
export const LINK_OBJECTIVE_PLAN = "linkObjectivePlan";
export const ASSIGNED_USER_OBJECTIVE = "assignedUserObjective";
export const DESELECT_OBJECTIVE = "deselectObjective";
export const UPDATE_USER_OBJECTIVE = "updateUserObjective";
export const USER_OBJECTIVE = "userObjective";
export const USER_OBJECTIVE_HISTORY = "userObjectiveHistory";
export const NOTIFY_OBJECTIVE = "notifyObjective";
export const ACCEPT_OR_REJECT_COMPLETION = "acceptOrRejectCompletion";
export const OBJECTIVE_PROGRESS_STATUS = "getObjectiveProgressStatus";
export const OBJECTIVE_COMMENT = "objectiveComment";
export const OBJECTIVE_PRIVATE_REPLY = "objectivePrivateReply";
export const DELETE_OBJECTIVE_COMMENT = "deleteObjectiveComment";
export const FILE_UPLOAD = "fileUpload";
export const FETCH_TAG = "fetchTag";
export const UPDATE_OBJECTIVE_KEY_RESULT = "updateObjectKeyResult";
export const CANCEL_OBJECTIVE = "cancelObjectiveKeyResult";
export const GET_HR_QUARTERLY_CYCLE_LIST = "getHRQuarterlyCycleList";
export const GET_HR_EMPLOYEE_NAME_LIST = "getHREmployeeNameList";
export const GET_CURRENT_MANAGERS_LIST = "getCurrentManagersList";
export const GET_NEW_MANAGERS_LIST = "getNewManagersList";
export const MANAGER_TEAM_ASSESSMENT_LIST = "managerTeamAssessmentList";
/*
  Performance
*/
export const GET_QUARTERLY_CYCLE_LIST = "getQuarterlyCycleList";
export const GET_EMPLOYEE_NAME_LIST = "getEmployeeNameList";
export const GET_ALL_REPORTEE_ASSESSMENT_LIST = "getAllReporteeAssessmentList";
export const GET_PRIVATE_ASSESSMENT_DATA = "getPrivateAssessmentData";
// export const UPLOAD_FILE_PRIVATE_ASSESSMENT = "uploadFilePrivateAssessment";
export const UPDATE_USER_SELF_OBJECTIVE = "updateUserSelfObjective";
export const DOWNLOAD_FILE_PRIVATE_ASSESSMENT = "downloadFilePrivateAssessment";
export const DELETE_FILE_PRIVATE_ASSESSMENT = "deleteFilePrivateAssessment";
export const AUTO_SAVE_PRIVATE_ASSESSMENT = "autoSavePrivateAssessment";
export const UPLOAD_FILE_PRIVATE_ASSESSMENT_TEMPLATE = "uploadFilePrivateAssessmentTemplate";
export const DOWNLOAD_FILE_PRIVATE_ASSESSMENT_TEMPLATE = "downloadFilePrivateAssessmentTemplate";
export const DELETE_FILE_PRIVATE_ASSESSMENT_TEMPLATE = "deleteFilePrivateAssessmentTemplate";
export const AUTO_SAVE_PRIVATE_ASSESSMENT_TEMPLATE = "autoSavePrivateAssessmentTemplate";
export const SUBMIT_PRIVATE_ASSESSMENT = "submitPrivateAssessment";
export const ASSESSMENT_COMMENT = "assessmentComment";
export const AUTO_SAVE_OBJECTIVE_TRACKING = "autoSaveObjectiveTracking";
export const UPLOAD_OBJECTIVE_ASSESSMENT_FILES = "uploadObjectiveAssessmentFile";
export const UPLOAD_FILE_GLOBAL_FEEDBACK = "uploadFileGlobalFeedback";
export const DOWNLOAD_FILE_GLOBAL_FEEDBACK = "downloadFileGlobalFeedback";
export const DELETE_FILE_GLOBAL_FEEDBACK = "deleteFileGlobalFeedback";
export const DOWNLOAD_OBJECTIVE_ASSESSMENT_FILE = "downloadObjectiveAssessmentFile";
export const DELETE_OBJECTIVE_ASSESSMENT_FILE = "deleteObjectiveAssessmentFile";
export const CREATE_SINGLE_ASSESSMENT = "createSingleAssessment";
export const DELETE_ASSESSMENT_FOR_EMPLOYEE = "deleteAssessmentForEmployee";
export const OFFLINE_RATING_FOR_EMPLOYEE = "offlineRatingForEmployee";

//One on One
export const ONE_ON_ONE_AGENDA = "oneOnOneAgenda";
export const GET_ONE_ON_ONE_AGENDA_COMMENT = "getOneOnOneAgendaComment";
export const CURRENT_ONE_ON_ONE_DATA = "currentOneOnOneData";
export const TIMEZONE = "timeZone";
export const PREVIOUS_ONE_ON_ONE_DATA = "previousOneOnOneData";
export const GET_USER_ONE_ON_ONE = "getUserOneOnOne";
export const DELETE_PRIVATE_NOTE = "deletePrivateNote";
export const DELETE_SHARED_NOTE = "deleteSharedNote";
export const DELETE_TALKING_POINT = "deleteTalkingPoint";
export const SCHEDULE_ONE_ON_ONE = "scheduleOneOnOne";
export const MANAGER_SCHEDULE_ONE_ON_ONE = "managerScheduleOneOnOne";
export const MARK_TALKING_POINT = "markTalkingPoint";
export const END_ONE_ON_ONE = "endOneOnOne";
export const ONE_ON_ONE_REPORTEE = "oneOnOneReportee";
export const ONE_ON_ONE_MANAGERS = "oneOnOneManagers";
export const ONE_ON_ONE_MEMBERS = "oneOnOneMembers";
export const GET_TEAM_FEEDBACK = "getTeamFeedback";
export const GET_SCHEDULE_TIME = "getScheduleTime";

export const GLOBAL_FEEDBACK = "globalFeedback";

export const GET_FEEDBACK = "getFeedback";
export const POST_FEEDBACK = "postFeedback";
export const UPDATE_FEEDBACK = "updateFeedback";
export const DOWNLOAD_FEEDBACK = "downloadFeedback";

// University
export const SUBMIT_CERTIFICATE = "submitCertificate";
export const GET_CERTIFICATE_NAME = "getCertificateName";
export const GET_CERTIFICATE_LIST = "getCertificateList";
export const DOWNLOAD_CERTIFICATE = "downloadCertificate";
export const DELETE_CERTIFICATE = "deleteCertificate";
export const GET_CERTIFICATE_LIST_FOR_ALL_EMPLOYEE_MANAGER = "getCertificateListUniversityManager";
export const GET_CERTIFICATE_OVERVIEW_LIST = "getCertificateOverviewList";
export const UNIVERSITY_MEMBER_CERTIFICATE_EXPORT = "universityMemberCertificateExport";
export const UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SEARCH = "universityMemberCertificateExportSearch";
export const UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_FILTER = "universityMemberCertificateExportFilter";
export const UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SEARCH_FILTER = "universityMemberCertificateExportSearchFilter";

// Information hub
export const INFORMATION_HUB_ENDPOINT = {
  GET_INFORMATION_HUB_DATA: "getInformationHubData",
};

// Monthly checkin feedback
export const MONTHLY_CHECKIN_FEEDBACK_ENDPOINT = {
  GET_CURRENT_FEEDBACK_DATA_EMPLOYEE: "getCurrentfeedbackEmployee",
  SHOW_YEAR_LIST: "showYearList",
  GET_CURRENT_FEEDBACK_DATA_MANAGER: "getCurrentfeedbackManager",
  SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER: "submitFeedbackEmployeeManager",
  GET_COUNT_DURATION_STATUS_EMPLOYEE_AND_MANAGER: "getCountDurationStatusEmployeeAndManager",
  FEEDBACK_INTERACTION_EMPLOYEE_AND_MANAGER: "feedbackInteractionEmployeeAndManager",
  SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER: "submitFeedbackEmployeeManager",
  FEEDBACK_HISTORY_EMPLOYEE: "feedbackHistoryEmployee",
  FEEDBACK_HISTORY_MANAGER: "feedbackHistoryManager",
  GET_MANAGER_LIST_EMPLOYEE : "getManagerListEmployee",
  GET_HISTORY_DETAILS_EMPLOYEE_AND_MANAGER: "getHistoryDetailsEmployeeAndManager",
  GET_FILTERS_DETAILS_HR: "getFiltersDetailsHr",
  GET_CHECKIN_FEEDBACK_EXPORT: "getCheckinFeedbackExport",
  GET_DASHBOARD_DATA_IN_HR_VIEW: "getDashboardDataInHRView",
  GET_GRAPH_CHECKIN_FEEDBACK_HR: "getGraphCheckinFeedbackHR",
  SHOW_FEEDBACK_DETAILS_IN_HR_VIEW: "showFeedbackDetailsInHRView",
  GET_QUESTION_BASED_EXPORT_EMPLOYEE: "getQuestionBasedExportEmployee",
  GET_QUESTION_BASED_EXPORT_MANAGER: "getQuestionBasedExportManager",
};

// Skill Matrix
export const DOWNLOAD_RESUME_SAMPLE = "https://continuous-engagement.s3.amazonaws.com/ResumeTemplate.docx";
// export const DOWNLOAD_OBJECTIVE_SAMPLE = "https://continuous-engagement.s3.amazonaws.com/ObjectiveFormat.xlsx";
export const DOWNLOAD_OBJECTIVE_SAMPLE = "https://stage-engage-logs.s3.ap-south-1.amazonaws.com/objective-templates/Project-Bench+OKR+Sample+File.zip";
export const DOWNLOAD_QUIZ_SAMPLE =
  "https://stage-engage-logs.s3.ap-south-1.amazonaws.com/quiz/QuestionTemplate.xlsx";
export const ADD_SKILL = "addSkill";
export const GET_SKILLS = "getSkills";
export const DRAFT_SKILL = "draftSkill";
export const UPDATE_ADDITIONAL_SKILL = "updateAdditionalSkill";
export const UPDATE_DRAFT_SKILL = "";
export const ADDITIONAL_SKILL_LIST = "additionalSkillList";
export const DOWNLOAD_RESUME_TEMPLATE = "downloadResumeTemplate";
export const DOWNLOAD_RESUME = "downloadResume";
export const UPLOAD_MY_RESUME = "uploadMyResume";
export const GET_SKILL = "getParticularSkill";
export const UPDATE_DRAFT = "updateDraft";
export const UPDATE_SKILL_MASTER = "updateSkillInMaster";
export const DELETE_ADDITIONAL_SKILL = "deleteAdditionalSkill";
export const TEMPLATE = "template";
export const DETAIL_TEMPLATE = "detailTemplate";
export const DELETE_TEMPLATE_QUESTION = "deleteTemplateQuestion";
export const GET_DEPARTMENT_LIST = "getDepartmentList";
export const GET_CATEGORY_LIST = "getCategoryList";
export const GET_ROLE_BY_DEPARTMENT = "getRoleByDepartment";
export const GET_TECHNICAL_ROLE = "getTechnicalRole";
export const SAVE_REQUIRED_SKILL = "saveRequiredSkill";
export const GET_REQUIRED_SKILL_CONFIG = "getRequiredSkillConfig";
export const GET_MY_DEPARTMENT_TECH_ROLE = "getMyDepartmentTechRole";
export const LIST_ROLE_WISE_SKILL_REQUIRED_FOR_MMG = "listRoleWiseSkillForMMG";
export const LIST_EMPLOYEE_REQUIRED_SKILL = "listEmployeeRequiredSkill";
export const GET_REQUIRED_SKILL_DETAILS = "getRequiredSkillDetails";
export const GET_ENDORSEMENT_SKILL = "endorsementSkill";
export const GET_EMPLOYEE_LIST = "employeeList";
export const GET_ENDORSEMENT_TYPE = "endorsementType";
export const GET_TECHNICAL_ROLE_LIST = "getTechnicalRoleList";
export const GET_BAND = "getBand";
export const EXPORT_LIST = "exportList";
export const GET_EMPLOYEE_SKILL_LISTING_FILTER_DATA = "getFilterData";
export const ENDORSE_EMPLOYEE = "endorseEmployee";
export const ENDORSE_REQ = "endorseReq";
export const GET_ENDORSEMENT_BY_ME = "getEndorsementsByMe";
export const GET_ENDORSE_REQ_FOR_EMP = "getEndorseReqForEmp";
export const GET_MY_ENDORSEMENT = "getMyEndorsement";
export const GET_PENDING_DECLINE_REQ = "getPendingDeclineRequest";
export const APPROVE_ENDORSE_REQ = "approveEndorseReq";
export const GET_DECLINE_ENDORSE_REQ = "declineEndorseReq";
export const GET_MMG_ENDORSER_NAME = "getWMGEndorserName";
export const SEND_MMG_ENDORSE_REQ = "sendWMGEndorseReq";
export const GET_MMG_ENDORSE_REQ = "getWMGEndorseReq";
export const GET_UNIVERSITY_REQ = "getUniversityReq";
export const GET_UNIVERSITY_DECLINE_APPROVE_REQ = "getUniversityApproveDeclineReq";
export const APPROVE_DECLINE_UNIVERSITY_REQ = "approveDeclineUniversityReq";
export const GET_DEFINE_SKILL_DRAFT = "getDraftForDefineSkill";
export const GET_SKILL_MASTER_CATEGORY = "getSkillMasterCategory";
export const CATEGORY_WISE_SKILL_MASTER = "categoryWiseSkillMaster";
export const ADD_CATEGORY_IN_SKILL_MASTER = "addCategoryInSkillMaster";
export const UPDATE_TECHNICAL_ROLE = "updateTechnicalRole";
export const RESUME_UPDATED_CONFIRMATION = "resumeUpdatedConfirmation";
export const CHECK_RESUME_UPDATE_REMINDER = "checkResumeUpdateReminder";
export const LIST_TECHNICAL_ROLE_CHANGE_FOR_MMG = "listForMMG";
export const APPROVE_REJECT_REQUEST_FOR_TECHNICAL_ROLE_CHANGE_FOR_MMG = "approveRejectRequestForTechnicalRoleChangeWMG";
export const MY_TECHNICAL_ROLE_CHANGE_REQUEST_LIST = "myTechnicalRoleChangeRequestList";
export const SAVE_MY_REQUIRED_SKILL = "saveMyRequiredSkill";
export const GET_EMPLOYEE_NAME_FOR_EMPLOYEE_SKILL_LISTING = "getEmployeeNameForEmployeeSkillListing";

//Private Assessment
export const CREATE_ASSESSMENT_CYCLE = "createAssessmentCycle";
export const GET_ASSESSMENT_CYCLE = "getCreatedAssessmentCycle";
export const GET_QUARTERELY_CYCLE = "getQuarterelyCycle";
export const TEMPLATE_LIST = "templateList";
export const LOCATION_LIST = "locationList";
export const EMPLOYEE_FILTER_LIST_FOR_HR_TIMELINE = "employeeFilterListForHrTimeline";
export const RE_OPEN_ASSESSMENT = "reOpenAssessment";
export const EMPLOYEE_LIST_FOR_HR = "employeeListForHR";
export const PRIVATE_ASSESSMENT_RATING = "privateAssessmentRating";
export const GET_USER_PRIVATE_ASSESSMENT_DATA = "getUserPrivateAssessmentData";
export const DOWNLOAD_ASSESSMENT = "downloadAssessment";
export const DOWNLOAD_ASSESSMENT_STATUS = "downloadAssessmentStatus";
export const DOWNLOAD_SELF_ASSESSMENT = "downloadSelfAssessment";
export const DOWNLOAD_EMPLOYEE_LIST = "downloadEmployeeList";
export const DOWNLOAD_EMPLOYEE_OBJECTIVE = "downloadEmployeeObjective";
export const DOWNLOAD_EMPLOYEE_SELF_ASSESSMENT_LIST = "downloadEmployeeSelfAssessment";
export const EMPLOYEE_NAME_LIST = "employeeNameList";
export const EMPLOYEE_TYPE_LIST = "employeeTypeList";
export const TEMPLATE_CATEGORY = "templateCategory";
export const GET_TEMPLATE_CATEGORY = "getTemplateCategory";
export const TEMPLATE_SCALE = "templateScale";
export const GET_FILTERED_EMPLOYEES_OBJECTIVES = "getFilteredEmployeesObjectives";
export const GET_HR_DASHBOARD_REPORT = "getHrDashboardReport";
export const HR_DASHBOARD_FILTER_OPTION = "hrDashboardFilterOption";
export const GET_HR_DASHBOARD_EXCEL_REPORT = "getHrDashboardExcelReport";
export const EMPLOYEE_OBJECTIVE_CHART_DATA = "getEmployeeObjectiveChartData";
export const EMPLOYEE_OBJECTIVE_CHART_EXPORT = "getEmployeeObjectiveExportReport";
export const ASSESSMENT_GLOBAL_COMMENT = "assessmentGlobalComment";
export const ASSESSMENT_RPM_GLOBAL_COMMENT = "assessmentRPMGlobalComment";
export const ASSESSMENT_SELF_GLOBAL_COMMENT = "assessmentSELFGlobalComment";
export const REPLACE_MANAGER = "replaceManager";
export const CREATE_MANAGER_ASSESSMENT = "createManagerAssessment";
export const DELETE_MANAGER_ASSESSMENT = "deleteManagerAssessment";
export const REPLACE_MANAGER_W_CYCLE_ID = "replaceManagerWCycleId";
export const GET_ASSESSMENT_REPORT_CHART_DATA = "getAssessmentReportChartData";
export const ASSESSMENT_ACKNOWLEDGEMENT = "assessmentAcknowledgement";

//DeaLlocation
export const DEALLOCATION_LIST_FOR_MANAGER = "deallocationListForManager";
export const DEALLOCATION_QUESTION_UPLOAD_FILE = "deallocationQuestionUploadFile";
export const DEALLOCATION_QUESTION_DOWNLOAD_FILE = "deallocationQuestionDownloadFile";
export const DEALLOCATION_QUESTION_DELETE_FILE = "deallocationQuestionDeleteFile";
export const DEALLOCATION_EMPLOYEE_DETAILS = "deallocationEmployeeDetails";
export const UPDATE_DEALLOCATION_FEEDBACK = "updateDeallocationFeedback";
export const VALIDATE_OBJECTIVE_FOR_DEALLOCATION = "validateObjectiveForDeallocation";
export const DEALLOCATION_REPORTEE_LIST = "deallocationReporteeList";
export const DOWNLOAD_DEALLOCATION_DATA = "downloadDeallocationData";
export const GLOBAL_FEEDBACK_LIST_FOR_HR = "globalFeedbackListForHR";
export const GLOBAL_FEEDBACK_REPORT = "globalFeedbackReport";

//admin
export const GET_ALL_DATA_FOR_ROLE_ASSIGN = "getAllDataForRoleAssign";
export const SET_ROLE_RIGHTS = "createRoleRights";
export const GET_ROLE_RIGHTS_LIST = "getRoleRightsList";
export const GET_ALL_EMPLOYEE_NAME_LIST = "getAllEmployeeNameList";
export const UPDATE_ROLE_RIGHT = "updateRoleRight";
export const USER_ROLE_RIGHTS_ASSIGN = "userRoleRightsAssign";
export const DEALLOCATION_FEEDBACK_DATA_LIST = "deallocationFeedbackDataList";
export const OKR_TEMPLATE_ENDPOINT = {
  GET_PREDEFINED_OBJECTIVE_ADMIN_VIEW: "getPredefinedObjectiveAdminView",
  CREATE_PREDEFINED_OBJECTIVE_ADMIN_VIEW: "createPredefinedObjectiveAdminView",
  DELETE_PREDEFINED_OBJECTIVE_ADMIN_VIEW: "deletePredefinedObjectiveAdminView",
  UPDATE_PREDEFINED_OBJECTIVE_ADMIN_VIEW: "updatePredefinedObjectiveAdminView",
  GET_MANAGER_LIST_PREDEFINED_OBJECTIVE_ADMIN_VIEW: "getManagerListPredefinedObjective",
};

//Manager Dashboard
export const PENDING_ONE_ON_ONE = "pendingOneOnOne";
export const MANAGER_PENDING_TODO = "managerPendingToDo";
export const PENDING_OBJECTIVE_APPROVAL = "pendingObjectiveApproval";
export const EMPLOYEE_PENDING_TODO = "employeePendingToDo";
export const TEAMS_CHECKIN = "teamsCheckin";
export const CURRENT_PREVIOUS_CHECKIN = "currentPreviousCheckin";
export const UNASSIGNED_OBJECTIVES = "unassignedObjectives";

//HR dashboard
export const OBJECTIVE_GRADE_COUNT = "objectiveGradeRatingCount";
export const PRIVATE_ASSESSMENT_COUNT = "privateAssessmentCount";
export const PRIVATE_ASSESSMENT_POPUP = "privateAssessmentPopup";
export const OBJECTIVE_GRADE_POPUP = "objectiveGradeRatingPopup";
export const OBJECTIVE_GRADE_COUNT_FOR_MANAGER = "privateAssessmentGradeCountForManager";
export const PRIVATE_ASSESSMENT_COUNT_FOR_MANAGER = "privateAssessmentCountForManager";
export const PRIVATE_ASSESSMENT_POPUP_FOR_MANAGER = "privateAssessmentPopupForManager";
export const OBJECTIVE_GRADE_POPUP_FOR_MANAGER = "objectiveGradePopupForManager";
export const GET_ALL_CYCLE_NAME = "getAllCycleName";
export const PROJECT_LIST = "projectList";
export const DROPDOWN_OPTIONS = "dropdownOptions";
export const DROPDOWN_OPTIONS_FOR_MANAGER = "dropdownOptionsForManager";
export const GET_QUARTERLY_CYCLE_LIST_FOR_MANAGER = "getQuarterlyCycleListForManager";
export const GRADE_COMPARISON = "gradeComparison";
export const EMPLOYEE_VIEW_ASSESSMENT_COMPARISON = "employeeViewAssessmentComparison";
export const EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_EXPORT = "employeeViewAssessmentComparisonExport";
export const PRIVATE_ASSESSMENT_POPUP_REPORT = "privateAssessmentPopupReport";
export const OBJECTIVE_GRADE_POPUP_REPORT = "objectiveGradePopupReport";
export const PRIVATE_ASSESSMENT_COUNT_REPORT = "privateAssessmentCountReport";
export const OBJECTIVE_GRADE_REPORT = "objectiveGradeReport";
export const EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_FOR_MANAGER = "employeeViewAssessmentComparisonForManager";
export const EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_EXPORT_FOR_MANAGER =
  "employeeViewAssessmentComparisonExportForManager";
export const PRIVATE_ASSESSMENT_POPUP_REPORT_FOR_MANAGER = "privateAssessmentPopupReportForManager";
export const OBJECTIVE_GRADE_POPUP_REPORT_FOR_MANAGER = "objectiveGradePopupReportForManager";
export const PRIVATE_ASSESSMENT_COUNT_REPORT_FOR_MANAGER = "privateAssessmentCountReportForManager";
export const OBJECTIVE_GRADE_REPORT_FOR_MANAGER = "objectiveGradeReportForManager";
export const SET_CAMPAIGN = "setCampaign";
export const IMPORT_QUESTION_TEMPLATE = "importQuestionTemplate";
export const QUIZ_TEMPLATE_EMPLOYEE_VIEW = "quizTemplateEmployeeView";
export const DEPARTMENT_LIST = "departmentList";
export const DESIGNATION_LIST_FROM_DEPARTMENT = "designationListFromDepartment";
export const LOCATION_LIST_IN_CAMPAIGN = "locationListInCampaign";
export const CREATED_CAMPAIGN = "createdCampaign";
export const DELETE_CAMPAIGN = "deleteCampaign";
export const CLONE_CAMPAIGN = "cloneCampaign";
export const CAMPAIGN_LIST_FOR_ADMIN = "campaignListForAdmin";
export const CAPABILITY_LIST_FOR_ADMIN = "capabilityListForAdmin";
export const CAMPAIGN_LOCATION_FILTER = "campaignLocationFilter";
export const PREVIEW_CAMPAIGN = "previewCampaign";
export const CAMPAIGN_LIST_FOR_EMPLOYEE = "campaignListForEmployee";
export const CAMPAIGN_DETAIL = "campaignDetail";
export const PENDING_CAMPAIGN_DETAIL = "pendingCampaignDetail";
export const FILTER_DATA_FOR_CAMPAIGN = "filterDataForCampaign";
export const EMPLOYEE_CAMPAIGN_DETAIL_LIST = "employeeCampaignDetailList";
export const CAMPAIGN_FILTER = "campaignFilter";
export const UPDATE_CAMPAIGN_FILTER = "updateCampaignFilter";
export const DELETE_CAMPAIGN_FILTER = "deleteCampaignFilter";
export const CAMPAIGN_TEMPLATE_LIST = "campaignTemplateList";
export const CAMPAIGN_ANSWER_UPDATE = "campaignAnswerUpdate";
export const QUIZ_ANSWER_UPDATE = "quizAnswerUpdate";
export const QUIZ_QUESTIONS_EMPLOYEE_VIEW = "quizQuestionsEmployeeView";
export const QUIZ_RESUME_QUESTIONS_EMPLOYEE_VIEW = "quizResumeQuestionsEmployeeView";
export const QUIZ_REFRESH_QUESTIONS_EMPLOYEE_VIEW = "quizRefreshQuestionsEmployeeView";
export const SUBMIT_QUIZ = "submitQuiz";
export const GET_QUIZ_RESULT_FOR_EMPLOYEE = "getQuizResultForEmployee";
export const GET_QUIZ_RESULT_FOR_HR = "getQuizResultForHr";
export const WRONG_ANSWERS_FOR_EMPLOYEE = "wrongAnswersForEmployee";
export const WRONG_ANSWERS_FOR_HR = "wrongAnswersForHr";
export const VIEW_DETAILS_EXPORT = "viewDetailsExport";
export const EMPLOYEE_QUIZ_GRAPH_DATA = "employeeQuizGraphData";
export const COUNTRY_LIST = "countryList";
export const EDIT_CAMPAIGN = "editCampaign";
export const GET_DETAILS_INDEPENDENT_OF_ROLE_RIGHTS = "getDetailsIndependentOfRoleRights";
export const CAMPAIGN_MANAGER_ANSWER_UPDATE = "campaignManagerAnswerUpdate";
export const QUESTION_TEMPLATE_CATEGORY = "questionTemplateCategory";
export const SUBMIT_CAMPAIGN_TEMPLATE = "submitCampaignTemplate";
export const CAMPAIGN_QUESTIONS_STATS = "campaignQuestionStats";
export const CAMPAIGN_QUESTION_POPUP = "campaignQuestionPopup";
export const EXPORT_CAMPAIGN_QUESTION = "exportCampaignQuestion";
export const EMPLOYEE_CAMPAIGN_QUIZ_DETAILS = "employeeCampaignQuizDetails";
export const PROJECT_LISTS = "projectLists";
export const NOTIFICATION_FILTER_LIST = "notificationFilterList";
export const DEPARTMENT_LISTS = "departmentLists";
export const EMPLOYEE_LISTS = "employeeLists";
export const EMPLOYEE_TYPE_LISTS = "employeeTypeLists";
export const LOCATION_LISTS = "locationLists";
export const CLIENT_LIST = "clientList";
export const BAND_LISTS = "bandLists";
export const SEND_BOT_NOTIFICATION = "sendBotNotification";
export const NOTIFICATION_EMPLOYEE_COUNT = "notificationEmployeeCount";
export const BOT_NOTIFICATION_LIST = "botNotificationList";
export const SURVEY_CYCLE_CONFIGURATION = "surveyCycleConfiguration";
export const EDIT_SURVEY_CYCLE_CONFIGURATION = "editSurveyCycleConfiguration";
export const DELETE_SURVEY_CYCLE_CONFIGURATION = "deleteSurveyCycleConfiguration";
export const GET_SURVEY_CYCLE_CONFIGURATION = "getSurveyCycleConfiguration";
export const GET_SURVEY_CYCLE_TEMPLATE_LIST = "getSurveyCycleTemplateList";
export const GET_LOCATION_LIST_FROM_SUBGROUP = "getLocationListFromSubgroup";
export const BOT_NOTIFICATION_EMPLOYEE_LIST = "botNotificationEmployeeList";
export const ORGANIZATION_LIST = "organizationList";
export const EMPLOYEE_LIST_FROM_CLIENT = "employeeListFromClient";
export const MODULE_LIST = "moduleList";
export const CLIENT_ADMIN_MODULE_LIST = "clientAdminModuleList";
export const CREATE_ADMIN_FOR_CLIENT = "createAdminForClient";
export const UPDATE_ADMIN_FOR_CLIENT = "updateAdminForClient";
export const GET_SUB_GROUP_FOR_CLIENT = "getSubGroupForClient";
export const GET_SUB_GROUP_FOR_CLIENT_WITHOUT_ROLE_RIGHT = "getSubGroupForClientWithoutRole";
export const EMPLOYEE_CAPABILITY_TEMPLATE = "employeeCapabilityTemplate";
export const CAPABILITY_TEMPLATE_LIST = "capabilityDevelopmentTemplateList";
export const MANAGER_CAPABILITY_COMMENT = "managerCapabilityComment";
export const DEPARTMENT_WISE_CAPABILITY_TEMPLATE = "departmentWiseCapabilityTemplate";
export const EMPLOYEE_TIMELINE = "employeeTimeline";
export const TEAM_ASSESSMENT = "teamAssessment";
export const ONE_ON_ONE_TEAM_LIST_FOR_HR = "oneOnOneTeamListForHR";
