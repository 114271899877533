import React, { useState } from "react";
import style from "../AssignObjectives/AssignObjectives.module.scss";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { keyValue, keyValueAchieved } from "util/general";
import http from "util/http";
import { UPDATE_OBJECTIVE_KEY_RESULT } from "util/endpoints";
import * as actions from "store/actions/index";
import { useDispatch } from "react-redux";
import { status } from "const/constValue";
import { ratingOptions } from "const/options";
import CommentsTop from "../../CommentBox/CommentsTop";

function RatingKeyResultDropDown(props) {
  const objValues = props.objVal;
  const managerData = props.loggedInUser;
  const item = props.rowData;
  let isSubmitted = objValues.isSubmitted;
  const [selectedOption, setSelctedOption] = useState(null);
  const dispatch = useDispatch();

  const saveAcceptComment = (e, keyId, rating, acceptComment) => {
    let keyPointVal = [
      {
        keyId: keyId,
        comment: acceptComment.trim() || item.comment,
        rating: rating || item.rating,
      },
    ];

    if (rating) setSelctedOption(e);

    let detData = {
      userObjectiveId: objValues.userObjectiveId,
      employeeId: objValues.employeeId,
      managerId: objValues.managerData.employeeId,
      isDraft: true,
      keyPoints: keyPointVal,
      subGroupId: objValues.employeeData.subGroupId,
      subGroupName: objValues.employeeData.subGroupName,
      parentGroupId: objValues.employeeData.parentGroupId,
      parentGroupName: objValues.employeeData.parentGroupName,
      managerSubGroupId: objValues.managerData.subGroupId,
      managerSubGroupName: objValues.managerData.subGroupName,
      managerParentGroupId: objValues.managerData.parentGroupId,
      managerParentGroupName: objValues.managerData.parentGroupName,
    };
    http
      .put(UPDATE_OBJECTIVE_KEY_RESULT, { definedBy: objValues.definedBy }, detData)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(
            actions.GetAssignedUserObjective(objValues.userObjectiveId, objValues.employeeData, objValues.managerData)
          );
        }
      })
      .catch((error) => console.warn("ENGAGE", error));
  };

  return (
    <div className={style.key_result_item}>
      <div className={style.key_result_item_topbar}>
        <h5>{item["description"]}</h5>
        <span>{item.grade && "Progress : " + item.grade}</span>
      </div>
      <div className="box-light-gradient h38"></div>
      <div className={style.key_result_item_content}>
        <div className={style.key_stretch_result_info}>
          <div className={style.values}>
            <span className={style.key_result_text}>Key Value Assigned</span>
            <span className={style.keyresult_value}>{keyValue(item)}</span>
          </div>
          <div className={style.values}>
            <span className={style.key_result_text}>Key Value achieved</span>
            <span className={style.keyresult_value}>{keyValueAchieved(item)}</span>
          </div>
          {item.stretch && <span className={style.key_result_text}>This is a Stretch Key Result</span>}
        </div>
        <div className={style.key_result_rate_wrapper}>
          <div className={style.card_ft_wrapper}>
            <span>Rate this progress by selecting a reasonable value</span>
            <div className={style.card_action}>
              <Select
                options={ratingOptions}
                className="custom-default-dd-box"
                classNamePrefix="select"
                value={selectedOption ? selectedOption : item.rating ? ratingOptions[item.rating - 1] : ""}
                isDisabled={isSubmitted}
                onChange={(value) => saveAcceptComment(value, item.keyId, value.value, "")}
              />
            </div>
          </div>
        </div>
        {isSubmitted && item.comment == null ? null : (
          <div className={["view_comment_box_wrapper", isSubmitted && "bg-white"].join(" ")}>
            <CommentsTop heading={isSubmitted ? "Reviewer's Comment(s) " : "Share your view(s)"} />
            <div className="view_comment_box_row">
              <div className="comment_box_wrapper fg-comment-wrapper">
                <div className="user_img" style={{ backgroundColor: "#CC54FF" }}>
                  {isSubmitted === false
                    ? managerData &&
                      (managerData.displayPicture ? (
                        <img src={managerData.displayPicture} alt="" />
                      ) : (
                        managerData.empInitial
                      ))
                    : objValues.managerData &&
                      (objValues.managerData.displayPicture.length > 2 ? (
                        <img src={objValues.managerData.displayPicture} alt="" />
                      ) : (
                        objValues.managerData.displayPicture
                      ))}
                </div>
                {isSubmitted ? (
                  <div className="user_comment_details">
                    <div className="user_name">{objValues.managerData.empName}</div>
                    <div className="user_comment text-break">{item.comment || ""}</div>
                  </div>
                ) : (
                  <div className="comment_area_wrapper">
                    <Form.Group className="form_group_comment mb-0">
                      <div className="textarea-wrapper">
                        <Form.Control
                          as="textarea"
                          disabled={isSubmitted}
                          maxLength="200"
                          placeholder="Add a comment"
                          className="manager_comments"
                          defaultValue={item.comment ? item.comment : ""}
                          onBlur={(value) => saveAcceptComment(value, item.keyId, "", value.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default RatingKeyResultDropDown;
