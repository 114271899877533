import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  quarterlyCycleList: [],
  QuarterlyCycleListForEmployee: [],
  employeeListForReviewing: null,
  employeeListForReporting: null,
  isReportingTeamMemberLoading: true,
  getPrivateAssessmentData: [],
  isPerformanceLoading: false,
  managerListForHR: [],
  employeeListForHR: [],
  allManagerStatus: [],
  createSuccessResponse: "",
  cycleData: null,
  isCycleLoading: true,
  templateList: [],
  locationList: [],
  questionTemplate: {},
  reopenResponse: null,
  employeeSelfAssessmentData: null,
  reportingManagerData: [],
  reviewingManagerData: [],
  employeeData: [],
  isTemplateLoading: true,
  cycleName: null,
  period: null,
  pendingPercentage: null,
  submittedPercentage: null,
  isPrivateAssessmentLoading: true,
  questionTemplateErrors: {},
  privateAssessmentObjectiveErrors: [],
  fileResponse: null,
  globalFeedbackFileResponse: null,
  globalFeedbackUpdatedDate: null,
  paSubmitResponse: null,
  reopenLoading: false,
  isSubmitted: null,
  updatedDate: null,
  submittedDate: null,
  isUserPrivateAssessmentLoading: true,
  isPerformanceResponseLoading: false,
  getUserPrivateAssessmentData: null,
  isFileLoading: false,
  createCycleData: {
    selectQuestion: { SELF: "", REPORTING: "", REVIEWING: "" },
    excludeObjective: false,
    gradeOrRatingOrBoth: "GRADE",
  },
  employeeNameList: [],
  employeeNames: [],
  employeeTypeList: [],
  objectives: [],
  allManagerDetails: [],
  managerList: [],
  globalRVMComment: [],
  globalRPMComment: [],
  globalSELFComment: [],
  globalSELFCommentUserData: [],
  managerWiseGrade: [],
  isEmployeeListLoading: true,
  isTeamLoading: false,
  bandOption: [],
  locationOption: [],
  indiaDDOption: [],
  managerViewOption: null,
  employeeCount: 0,
  departmentOption: [],
  employeeNameOption: [],
  projectList: [],
  cycleListOption: [],
  employeesViewComparison: [],
  filteredViewComparison: [],
  tableData: [],
  tableHeader: [],
  employeeViewInPopup: [],
  openPopup: [],
  popupBody: null,
  isSelfAssessmentAvailable: false,
  filterLoading: false,
  acknowledgementResponse: null,
  filterValue: null,
  assessmentCount: 0,
  managerTeamMemberList: [],
  timelineGraph: [],
  currentProjectLineGraph: [],
  projectLineVisible: false,
  assessmentAreaVisible: false,
  isPageLoading: false,
  employeesTimeline: [],
  employeeTimelineForHr: [],
  gradeTeamAssessmentGraph: [],
  ratingTeamAssessmentGraph: [],
  ratingTeamAssessmentData: [],
  gradeTeamAssessmentData: [],
  assessmentTeam: [],
  timelineFilterValue: {
    filterEmployeeList: [],
    filterSubGroups: null,
    filterLocation: [],
  },
  hrTimelineFilterOptions: {
    locationList: [],
    employeeList: [],
    displayEmployeesList: [],
    subGroupList: [],
  },
  isHrTimelineDisplayLoading: false,
  hrTimelineEmployeeList: [],
  isHrTimelineLoading: true,
  hrTimelineEmployeeFilterOptions: {
    displayEmployeesList: [],
    designationList: [],
    subGroupList: [],
    locationList: [],
  },
  globalFeedbackFileResponse: null,
  globalFeedbackUpdatedDate: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_QUARTERLY_CYCLE_LIST:
      if (action.role === "USER") {
        return {
          ...state,
          isLoading: false,
          QuarterlyCycleListForEmployee: action.data,
          employeeListForReviewing: null,
          employeeListForReporting: null,
          isUserPrivateAssessmentLoading: true,
          getUserPrivateAssessmentData: null,
          managerList: [],
          globalSELFComment: [],
        };
      } else {
        return {
          ...state,
          isLoading: false,
          quarterlyCycleList: action.data,
          employeeListForReviewing: null,
          employeeListForReporting: null,
          getPrivateAssessmentData: [],
          isPrivateAssessmentLoading: true,
          globalRVMComment: [],
          globalRPMComment: [],
          isEmployeeListLoading: true,
        };
      }
    case "START_EMPLOYEE_LIST_LOADING":
      return {
        ...state,
        isTeamLoading: true,
      };
    case "START_IS_PAGE_LOADING_IN_ASSESSMENT":
      return {
        ...state,
        isPageLoading: true,
      };
    case "REPORTING_TEAM_MEMBER_LOADING":
      return {
        ...state,
        isReportingTeamMemberLoading: true,
      };
    case actionTypes.SET_EMPLOYEE_NAME:
      return {
        ...state,
        employeeNames: action.data,
      };
    case actionTypes.SET_ALL_REPORTEE_ASSESSMENT_LIST:
      if (action.payload.type === "reviewing") {
        return {
          ...state,
          isLoading: false,
          employeeListForReviewing: action.payload.data,
          isReportingTeamMemberLoading: false,
          pendingPercentage: action.payload.pending,
          submittedPercentage: action.payload.submitted,
          period: action.payload.period,
          cycleName: action.payload.cycleName,
          gradeOrRatingOrBoth: action.payload.gradeOrRatingOrBoth,
          totalCount: action.payload.totalCount,
          isBenchFilter: action.payload.isBenchFilter,
        };
      } else if (action.payload.type === "reporting") {
        return {
          ...state,
          isLoading: false,
          employeeListForReporting: action.payload.data,
          isReportingTeamMemberLoading: false,
          pendingPercentage: action.payload.pending,
          submittedPercentage: action.payload.submitted,
          period: action.payload.period,
          cycleName: action.payload.cycleName,
          gradeOrRatingOrBoth: action.payload.gradeOrRatingOrBoth,
          totalCount: action.payload.totalCount,
          isBenchFilter: action.payload.isBenchFilter,
        };
      } else return { ...state };
    case actionTypes.SET_PRIVATE_ASSESSMENT_DATA:
      return {
        ...state,
        isPrivateAssessmentLoading: action.isPrivateAssessmentLoading,
        isPerformanceResponseLoading: false,
        getPrivateAssessmentData: action.data,
        objectives: action.data.objectiveTracking,
        isSubmitted: action.isSubmitted,
        submittedDate: action.submittedDate,
        updatedDate: action.updatedDate,
        allManagerStatus: action.allManagerStatus,
        allManagerDetails: action.allManagerDetails,
        managerList: action.managerList,
        managerWiseGrade: action.managerWiseGrade,
      };
    case actionTypes.SELECT_SELF_OBJECTIVE: {
      let objectives = [...state.objectives];
      let newObj = objectives.map((obj) => {
        if (action.data.userObjectiveId === obj.userObjectiveId) {
          obj.keyPoints.map((keyPoint) => {
            if (action.data.keyPoints.keyId === keyPoint.keyId) {
              keyPoint.keyId = action.data.keyPoints.keyId;
              keyPoint.description = action.data.keyPoints.description;
              keyPoint.type = action.data.keyPoints.type;
              keyPoint.start = action.data.keyPoints.start;
              keyPoint.end = action.data.keyPoints.end;
              keyPoint.progress = action.data.keyPoints.progress;
              keyPoint.isCompleted = action.data.keyPoints.isCompleted;
              keyPoint.weightage = action.data.keyPoints.weightage;
              keyPoint.mark = action.data.keyPoints.mark;
            }
            return keyPoint;
          });
        }
        return obj;
      });

      return {
        ...state,
        objectives: newObj,
      };
    }
    case actionTypes.SET_HR_TIMELINE_FILTER_DATA:
      return {
        ...state,
        timelineFilterValue: {
          ...state.timelineFilterValue,
          ...action.payload,
        },
      };
    case actionTypes.GLOBAL_FEEDBACK_FILE_RESPONSE:
      return {
        ...state,
        globalFeedbackFileResponse: { success: action.success, message: action.message },
        globalFeedbackUpdatedDate: action.globalFeedbackUpdatedDate,
        isFileLoading:false,
      };
    case actionTypes.CLEAR_HR_TIMELINE_FILTER_DATA:
      return {
        ...state,
        timelineFilterValue: {
          filterEmployeeList: [],
          filterSubGroups: null,
          filterLocation: [],
        },
        hrTimelineEmployeeList: [],
      };
    case "REMOVE_PRIVATE_ASSESSMENT_DATA":
      return {
        ...state,
        getPrivateAssessmentData: null,
        isPrivateAssessmentLoading: true,
      };
    case "CREATE_PERFORMANCE_CYCLE_LOADING":
      return {
        ...state,
        isPerformanceLoading: true,
      };
    case actionTypes.CREATE_ASSESSMENT_CYCLE:
      return {
        ...state,
        createSuccessResponse: { success: action.success, message: action.message },
        isPerformanceLoading: false,
      };
    case "CLEAR_PERFORMANCE_RESPONSE":
      return {
        ...state,
        createSuccessResponse: null,
        getAllReviewerAssessmentList: null,
        getAllReporterAssessmentList: null,
        // isPerformanceLoading: true
      };
    case actionTypes.SET_CREATED_ASSESSMENT_CYCLE:
      return {
        ...state,
        cycleData: action.cycleData,
        isCycleLoading: false,
        isPerformanceLoading: false,
      };
    case actionTypes.SET_QUARTERLY_CYCLE_DATA:
      return {
        ...state,
        quaterlyCycleData: action.quaterlyCycleData,
      };
    case actionTypes.SET_TEMPLATE_LIST:
      return {
        ...state,
        templateList: action.templateList,
        isTemplateLoading: action.isTemplateLoading,
      };
    case actionTypes.SET_LOCATION_LIST:
      return {
        ...state,
        locationList: action.locationList,
      };
    case actionTypes.SET_HR_TIMELINE_EMPLOYEE_FILTER_OPTIONS:
      return {
        ...state,
        hrTimelineEmployeeFilterOptions: action.payload,
      };
    case actionTypes.SET_HR_TIMELINE_FILTER_OPTIONS:
      return {
        ...state,
        hrTimelineFilterOptions: action.payload,
        isHrTimelineLoading: false,
      };
    case actionTypes.SET_HR_TIMELINE_EMPLOYEE_LIST:
      return {
        ...state,
        hrTimelineEmployeeList: action.payload,
        isHrTimelineDisplayLoading: false,
      };
    case "START_DISPLAY_LOADING_IN_HR_TIMELINE":
      return {
        ...state,
        isHrTimelineDisplayLoading: true,
      };
    case actionTypes.SET_TEMPLATE:
      return {
        ...state,
        questionTemplate: action.data,
      };
    case actionTypes.REOPEN_RESPONSE:
      return {
        ...state,
        reopenResponse: { success: action.success, message: action.message },
      };
    case "CLEAR_REOPEN_RESPONSE":
      return {
        ...state,
        reopenResponse: null,
      };
    // case "START_REOPEN_LOADING":
    //   return {
    //     ...state,
    //     reopenLoading: true
    //   }
    case actionTypes.SET_REPORTING_MANAGER_LIST:
      return {
        ...state,
        ...action,
      };
    case actionTypes.SET_REVIEWING_MANAGER_LIST:
      return {
        ...state,
        ...action,
      };
    case actionTypes.SET_EMPLOYEE_LIST:
      return {
        ...state,
        ...action,
      };
    case actionTypes.SET_EMPLOYEE_SELF_ASSESSMENT_LIST:
      return {
        ...state,
        ...action,
      };
    case "START_FILTER_LOADING":
      return {
        ...state,
        filterLoading: true,
      };
    case "HR_PERFORMANCE_SCREEN":
      return {
        ...state,
        reportingManagerData: [],
        reviewingManagerData: [],
        employeeData: [],
        employeeSelfAssessmentData: null,
        cycleName: null,
        period: null,
        isTemplateLoading: true,
        isCycleLoading: true,
        createCycleData: {},
        filterValue: null,
      };
    case "TOGGLE_ALL_CHANGE":
      return {
        ...state,
        reportingManagerData: action.data,
      };
    case "TOGGLE_REPORTEE_CHECK":
      return {
        ...state,
        reportingManagerData: action.data,
      };
    case "CHECKED_EMPLOYEE":
      return {
        ...state,
        ...action.data,
      };
    case "SET_QUESTION_TEMPLATE_ERROR":
      return {
        ...state,
        questionTemplateErrors: { ...state.questionTemplateErrors, ...action.error },
      };
    case "SET_QUESTION_TEMPLATE_VALIDATION":
      return {
        ...state,
        questionTemplateErrors: {
          ...action.data,
          errors: action.data.errors?.map((value) => Object.assign({}, value, { isSubmit: true })),
        },
      };
    case "SET_PRIVATE_ASSESSMENT_OBJECTIVE_ERROR":
      return {
        ...state,
        privateAssessmentObjectiveErrors: action.error,
      };
    case "SET_PRIVATE_ASSESSMENT_OBJECTIVE_VALIDATION":
      return {
        ...state,
        privateAssessmentObjectiveErrors: action.data.map((value) => Object.assign({}, value, { isSubmit: true })),
      };
    case "SET_SUBGROUP_AND_LOCATION_FILTER":
      return {
        ...state,
        filterValue: action.payload,
      };
    case actionTypes.PRIVATE_ASSESSMENT_FILE_RESPONSE:
      return {
        ...state,
        fileResponse: { success: action.success, message: action.message },
        updatedDate: action.updatedDate,
        isFileLoading: false,
      };
    case actionTypes.GLOBAL_FEEDBACK_FILE_RESPONSE:
      return {
        ...state,
        globalFeedbackFileResponse: { success: action.success, message: action.message },
        globalFeedbackUpdatedDate: action.globalFeedbackUpdatedDate,
      };
    case actionTypes.SET_ACKNOWLEDGEMENT:
      return {
        ...state,
        acknowledgementResponse: { success: action.success, message: action.message },
        getUserPrivateAssessmentData: {
          ...state.getUserPrivateAssessmentData,
          gradeAcknowledgement: action.data,
        },
      };
    case actionTypes.SET_MANAGER_TEAM_ASSESSMENT_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_MANAGER_TEAM_ASSESSMENT_GRAPH:
      return {
        ...state,
        ...action.payload,
        isPageLoading: false,
        assessmentTeam: [],
      };
    case actionTypes.SET_ASSESSMENT_TEAM_BY_SEARCH_NAME:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_TEAM_MEMBER_LIST_FOR_ASSESSMENT_DASHBOARD: {
      let teamAssessment = [];
      if (action.payload.graphType === "GRADE") {
        teamAssessment =
          state.gradeTeamAssessmentData?.find((value) => value?.cycleId === action?.payload?.cycleId)
            ?.quarterlyCycleHistoryTimelineMap[action.payload?.type] || [];
      } else if (action.payload.graphType === "MASK") {
        const collectiveData = [...state.gradeTeamAssessmentData, ...state.ratingTeamAssessmentData]
        teamAssessment =
          collectiveData?.find((value) => value?.cycleId === action?.payload?.cycleId)
            ?.employees || [];
      } else {
        teamAssessment =
          state.ratingTeamAssessmentData?.find((value) => value?.cycleId === action?.payload?.cycleId)
            ?.quarterlyCycleHistoryTimelineMap[action.payload?.type] || [];
      }
      return {
        ...state,
        assessmentTeam: teamAssessment,
      };
    }
    case "START_FILE_DOWN_UP_DELETE_LOADING":
      return {
        ...state,
        isFileLoading: true,
      };
    case "START_FILE_DOWNLOAD_IN_BACKGROUND":
      return {
        ...state,
        fileResponse: { success: true, message: "Your files would be downloaded in background" },
        isFileLoading: false,
      };
    case "START_PERFORMANCE_SUBMIT_LOADING":
      return {
        ...state,
        isPerformanceResponseLoading: true,
      };
    case actionTypes.PRIVATE_ASSESSMENT_SUBMIT_RESPONSE:
      return {
        ...state,
        paSubmitResponse: { success: action.success, message: action.message },
        updatedDate: action.updatedDate,
        isSubmitted: action.isSubmitted,
        isPerformanceResponseLoading: false,
      };
    case actionTypes.SET_ASSESSMENT_GLOBAL_COMMENT:
      return {
        ...state,
        globalRVMComment: action.payload,
        isFileLoading: false,
      };
    case actionTypes.SET_RPM_ASSESSMENT_GLOBAL_COMMENT:
      return {
        ...state,
        globalRPMComment: action.payload,
        isFileLoading: false,
      };
    case actionTypes.SET_SELF_ASSESSMENT_GLOBAL_COMMENT:
      return {
        ...state,
        globalSELFComment: action.payload.globalSELFComment,
        globalSELFCommentUserData: action.payload.globalSELFCommentUserData,
        isFileLoading: false,
      };
    case "SET_UPDATED_DATE":
      return {
        ...state,
        updatedDate: action.updatedDate,
      };
    case "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE":
      return {
        ...state,
        fileResponse: null,
        paSubmitResponse: null,
        acknowledgementResponse: null,
      };
    case "EXPANDED":
      return {
        ...state,
        expanded: action.expanded,
      };
    case actionTypes.SET_USER_PRIVATE_ASSESSMENT_DATA:
      return {
        ...state,
        getUserPrivateAssessmentData: action.data,
        managerList: action.managerList,
        isUserPrivateAssessmentLoading: action.isUserPrivateAssessmentLoading,
        managerWiseGrade: action.managerWiseGrade,
      };
    case "SET_CREATE_CYCLE_DATA":
      return {
        ...state,
        createCycleData: {
          ...state.createCycleData,
          ...action.payload,
        },
      };
    case actionTypes.SET_EMPLOYEE_NAME_LIST:
      return {
        ...state,
        employeeNameList: action.data,
      };
    case actionTypes.SET_EMPLOYEE_TYPE_LIST:
      return {
        ...state,
        employeeTypeList: action.data,
      };
    case actionTypes.GET_TEMPLATE_CATEGORY:
      return {
        ...state,
        templateCategoryList: action.data,
      };
    case actionTypes.TEMPLATE_SCALE:
      return {
        ...state,
        templateScaleList: action.data,
      };
    case "SET_ONCHANGE":
      return {
        ...state,
        onChangeFlag: action.data,
      };

    case actionTypes.SET_PMS_DROPDOWN_OPTIONS_FOR_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CYCLE_LIST_OPTIONS_FOR_MANAGER:
      return {
        ...state,
        cycleListOption: action.payload,
        isLoading: false,
      };
    case actionTypes.SET_MANAGER_DASHBOARD_ASSESSMENT_TABLE_CONTENT:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        employeesViewComparison: [],
        filteredViewComparison: [],
      };
    case actionTypes.SET_EMPLOYEE_VIEW_ASSESSMENT_COMPARISON_FOR_MANAGER:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        tableData: [],
        tableHeader: [],
      };
    case actionTypes.SET_PMS_EMPLOYEE_VIEW_IN_POPUP_FOR_MANAGER:
      return {
        ...state,
        ...action.payload,
        openPopup: true,
        isLoading: false,
      };
    case actionTypes.CLOSE_PMS_EMPLOYEE_VIEW_POPUP_FOR_MANAGER:
      return {
        ...state,
        employeeViewInPopup: [],
        openPopup: false,
      };
    case actionTypes.SET_TIMELINE_DATA_LIST:
      return {
        ...state,
        timelineGraph: action.timelineGraph.reverse(),
        currentProjectLineGraph: action.currentProjectLineGraph,
        projectLineVisible: action.projectLineVisible || false,
        assessmentAreaVisible: action.assessmentAreaVisible || false,
        isLoading: false,
        isPageLoading: false,
      };
    case actionTypes.SET_TIMELINE_DATA_LIST_FOR_MANAGER:
      if (
        state.employeesTimeline?.length > 0 &&
        state.employeesTimeline?.find((value) => value.employeeId === action.employeeId)
      ) {
        return {
          ...state,
          employeesTimeline: state.employeesTimeline.map((value) => {
            if (value.employeeId === action.employeeId) {
              return {
                employeeId: action.employeeId,
                timelineGraph: action.timelineGraph.reverse(),
                currentProjectLineGraph: action.currentProjectLineGraph,
                projectLineVisible: action.projectLineVisible || false,
                assessmentAreaVisible: action.assessmentAreaVisible || false,
              };
            } else return value;
          }),
          isLoading: false,
          isPageLoading: false,
        };
      } else {
        return {
          ...state,
          employeesTimeline: [
            ...state.employeesTimeline,
            {
              employeeId: action.employeeId,
              timelineGraph: action.timelineGraph.reverse(),
              currentProjectLineGraph: action.currentProjectLineGraph,
              projectLineVisible: action.projectLineVisible || false,
              assessmentAreaVisible: action.assessmentAreaVisible || false,
            },
          ],
          isLoading: false,
          isPageLoading: false,
        };
      }
    case actionTypes.SET_TIMELINE_DATA_LIST_FOR_HR:
      if (
        state.employeeTimelineForHr?.length > 0 &&
        state.employeeTimelineForHr?.find((value) => value.employeeId === action.employeeId)
      ) {
        return {
          ...state,
          employeeTimelineForHr: state.employeeTimelineForHr.map((value) => {
            if (value.employeeId === action.employeeId) {
              return {
                employeeId: action.employeeId,
                timelineGraph: action.timelineGraph.reverse(),
                currentProjectLineGraph: action.currentProjectLineGraph,
                projectLineVisible: action.projectLineVisible || false,
                assessmentAreaVisible: action.assessmentAreaVisible || false,
              };
            } else return value;
          }),
          isLoading: false,
          isPageLoading: false,
        };
      } else {
        return {
          ...state,
          employeeTimelineForHr: [
            ...state.employeeTimelineForHr,
            {
              employeeId: action.employeeId,
              timelineGraph: action.timelineGraph.reverse(),
              currentProjectLineGraph: action.currentProjectLineGraph,
              projectLineVisible: action.projectLineVisible || false,
              assessmentAreaVisible: action.assessmentAreaVisible || false,
            },
          ],
          isLoading: false,
          isPageLoading: false,
        };
      }
    case "START_MANAGER_PMS_DASHBOARD_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "CLEAR_MANAGER_DASHBOARD_ASSESSMENT_FILTER_DATA":
      return {
        ...state,
        assessmentFilterData: {},
        tableHeader: [],
        tableData: [],
        employeesViewComparison: [],
        filteredViewComparison: [],
        employeeViewInPopup: [],
      };
    default:
      return state;
  }
};

export default reducer;
