import * as actionTypes from "../actions/actionTypes";

const initialState = {
    successResponse: null,
    certificate: [],
    certificateList: [],
    isLoading: false,
    deleteFileResponse: null,
    errorResponse: null,
    downloadFileResponse: null,
    size: 10,
    certificateListForUniversityManager: [],
    certificateOverviewList: null,
    universityMemberCertificateExport:{},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_CERTIFICATE:
            return {
                ...state,
                isLoading: false,
                successResponse: { success: true, message: action.payload }
            }

        case actionTypes.CERTIFICATE_NAME:
            return {
                ...state,
                isLoading: false,
                certificate: action.payload ? action.payload : []
            }

        case actionTypes.SHOW_LOADER:
            return {
                ...state,
                isLoading: true,
                deleteFileResponse: null,
                downloadFileResponse: null,
                successResponse: null,
                errorResponse: null
            }

        case actionTypes.CERTIFICATE_LIST:
            return {
                ...state,
                isLoading: false,
                certificateList: action.payload ? action.payload : []
            }
        case actionTypes.CERTIFICATE_DELETE_SUCCESS:{
            let totalCertifaicate = [...state.certificateList];
            if (totalCertifaicate) {
                totalCertifaicate = totalCertifaicate.filter(cr => cr.id !== action.payload.id);
            }
            return {
                ...state,
                isLoading: false,
                certificateList: totalCertifaicate.length > 0 ? totalCertifaicate : [],
                deleteFileResponse: { error: false, message: action.payload.message }
            }
        }    
        case actionTypes.UPLOAD_ERROR:
            return {
                ...state,
                errorResponse: { error: false, message: action.payload },
                isLoading: false
            }

        case actionTypes.CERTIFICATE_DELETE_ERROR:

            return {
                ...state,
                isLoading: false,
                deleteFileResponse: { error: true, message: action.payload }
            }

        case actionTypes.DOWNLOAD_STARTED:
            return {
                ...state,
                isLoading: true
            }

        case actionTypes.DOWNLOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                downloadFileResponse: { error: false, message: action.payload }
            }

        case actionTypes.DOWNLOAD_CERTIFICATE_ERROR:
            return {
                ...state,
                downloadFileResponse: { error: true, message: action.payload },
                isLoading: false
            }

        case actionTypes.DELETE_FAIL: {
            return {
                ...state,
                isLoading: false,
                deleteFileResponse: { error: true, message: action.payload }
            }
        }

        case actionTypes.DOWNLOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                downloadFileResponse: { error: true, message: action.payload }
            }
        }
        case actionTypes.FAILURE_CERTIFICATE: {
            return {
                ...state,
                isLoading: false,
                errorResponse: { error: true, message: action.payload }
            }
        }

        case actionTypes.CLEAR_MESSAGE: {
            return {
                ...state,
                deleteFileResponse: null,
                downloadFileResponse: null,
                successResponse: null,
                errorResponse: null,
                universityMemberCertificateExport:{}
            }
        }

        case actionTypes.GET_CERTIFICATE_LIST_FOR_ALL_EMPLOYEE_MANAGER: {
            return {
                ...state,
                isLoading: false,
                certificateListForUniversityManager: action.payload ? action.payload : []
            }
        }

        case actionTypes.GET_CERTIFICATE_OVERVIEW_LIST: {
            return {
                ...state,
                isLoading: false,
                certificateOverviewList: action.payload ? action.payload : null
            }
        }

        case actionTypes.NOT_FOUND: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case actionTypes.UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                universityMemberCertificateExport : { success: true , message: action.payload }
            }
        }

        case actionTypes.UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_ERROR: {
            return {
                ...state,
                isLoading: false,
                universityMemberCertificateExport : { error: true , message: action.payload }
            }
        }

        default:
            return state;
    }

}

export default reducer;