import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import * as actions from "store/actions/index";
import { useQuery } from "util/utils";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import CurrentOneOnOneTable from "./CurrentOneOnOneTable";
import PreviousOneOnOneTable from "./PreviousOneOnOneTable";
import CreateOneOnOneButton from "./CreateOneOnOneButton";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

export default function EmployeeOneOnOne(props) {
  const loggedInUser = storage.getUser();
  const dispatch = useDispatch();
  const isManager = props.match.params.id === "manager" ? true : false;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const { currentOneOnOneData, previousOneOnOneData, user, isPreviousLoading, isCurrentLoading } = useSelector(
    (state) => state.oneOnOne
  );
  const { reviewer } = useSelector((state) => state.checkIn);

  useEffect(() => {
    dispatch({ type: "REMOVE_ONE_ON_ONE_DATA" });
    dispatch(actions.GetOneOnOneUser(employeeIds));
    dispatch(actions.GetCurrentOneOnOneData(employeeIds));
    dispatch(actions.GetReviewer(loggedInUser));
    dispatch(actions.GetPreviousOneOnOneData(employeeIds));
  }, [dispatch]);

  if (isCurrentLoading || isPreviousLoading) return <Spinner />;

  let isCurrentContinue = currentOneOnOneData && currentOneOnOneData.filter((value) => !value.scheduleOn);

  let path = null;
  if (isCurrentContinue && isCurrentContinue.length > 0)
    path = `/okrprogress/one-on-one/current/${isCurrentContinue[0].oneOnOnAgendaId}`;
  else {
    if (isManager) {
      path = `/okrprogress/one-on-one/create/manager?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`;
    } else {
      path = `/okrprogress/one-on-one/create/team?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`;
    }
  }

  return (
    <div className={style.one_on_one_cm_wrapper}>
      <SubNavigation type="OKR Progress" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">OKR Progress</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.ONE_ON_ONE}>1—on-1&apos;s</Link>
              <span>{user && user.displayName}</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <div className={["box", "one-on-one-box-wrapper"].join(" ")}>
              <div className={style.user_info}>
                <div className={style.user_img} style={{ backgroundColor: "#5C59E3" }}>
                  {user && (user.dispArr ? <img src={user.dispArr} alt="Display" /> : user.employeeInitial)}
                </div>
                <div className={style.user_details}>
                  <div className={style.user_details_left}>
                    <h2 className={style.user_name}>{user && user.displayName}</h2>
                    <h2 className={style.user_designation}>
                      {user && user.designation} | {user && user.department}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            {currentOneOnOneData ? (
              <CurrentOneOnOneTable currentOneOnOneData={currentOneOnOneData} />
            ) : (
              reviewer?.managersList?.length > 0 &&
              (reviewer?.managersList.map((manager) => manager.employeeId).includes(employeeId) || !isManager) && (
                <CreateOneOnOneButton path={path} />
              )
            )}
            {previousOneOnOneData && <PreviousOneOnOneTable previousOneOnOneData={previousOneOnOneData} />}
            {currentOneOnOneData &&
              reviewer?.managersList?.length > 0 &&
              (reviewer?.managersList.map((manager) => manager.employeeId).includes(employeeId) || !isManager) && (
                <div className={["box box-flex-center", style.saveBox].join(" ")}>
                  <div className={style.save_box_left}>
                    <Link className="btn btn-primary" to={path}>
                      Create New
                    </Link>
                  </div>
                </div>
              )}
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </div>
  );
}
