import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useSelector, useDispatch } from "react-redux";
import storage from "../../util/storage";
import * as actions from "../../store/actions/index";
import moment from "moment";
import style from "./employeeObjectives.module.scss";
import { toast } from "react-toastify";
import SlideToast from "../../components/Toast/Toast";
import EmployeeObjectiveHTML from "./employeeObjectiveHTML";
import NoObjetivesFound from "../Objectives/NoObjectivesFound/NoObjectiveFound";
import { objectiveStatusValue } from "const/constValue";

function EmployeeObjectives(props) {
  const editable = props.page === "checkin" ? (props.submitedDate ? true : false) : true;

  const dispatch = useDispatch();
  const [eventValue, setEventValue] = useState(props.page === "checkin" ? 0 : "");
  const [currentInputText, setCurrentInputText] = useState({});
  const { objectives, errorFlag } = useSelector((state) => state.checkIn);
  const { userObjectiveHistory } = useSelector((state) => state.objectives);
  const { surveyCycle } = useSelector((state) => state.questions);
  let notifyDisable = false;
  let user = storage.getUser();
  useEffect(() => {
    if (props.page === "checkin") {
      if (editable) {
        if (props.surveyCycleId) {
          dispatch(actions.GetObjectivesBySurveyCycle(props.surveyCycleId));
          dispatch(actions.GetObjectiveReviewer(surveyCycle.employeeProfile));
        }
      } else {
        dispatch(actions.GetUserObjectives(user));
        dispatch(actions.GetObjectiveReviewer(user));
      }
    } else {
      if (props.surveyCycleId) {
        dispatch(actions.GetObjectivesBySurveyCycle(props?.surveyCycleId));
        dispatch(actions.GetObjectiveReviewer(surveyCycle?.employeeProfile));
      }
    }
  }, [props.page, props.surveyCycleId]);

  useEffect(() => {
    if (JSON.stringify(currentInputText) !== JSON.stringify({})) {
      let _obj = objectives.find((obj) => obj.userObjectiveId === currentInputText.objId);

      _obj &&
        _obj.keyPoints.forEach((keypoint) => {
          if (parseInt(currentInputText.id) === keypoint.keyId) {
            let data = {
              userObjectiveId: _obj.userObjectiveId,
              employeeId: user.employeeId,
              employeeData: _obj.employeeData,
              subGroupId: _obj.subGroupId,
              subGroupName: _obj.subGroupName,
              parentGroupId: _obj.parentGroupId,
              parentGroupName: _obj.parentGroupName,
              keyPoints: {
                keyId: keypoint.keyId,
                description: keypoint.description,
                type: keypoint.type,
                start: keypoint.start,
                end: keypoint.end,
                progress: keypoint.progress,
                isCompleted: keypoint.isCompleted,
                hours: keypoint.hours,
                weightage: keypoint.weightage,
                mark: keypoint.mark,
              },
            };
            dispatch(actions.userObjective(data, { cycleEndDate: surveyCycle.endDate }));
          }
        });
    }
  }, [currentInputText]);

  const selectRadio = (data) => {
    dispatch(actions.userObjective(data, { cycleEndDate: surveyCycle.endDate }));
  };
  const userInputData = (data) => {
    dispatch(actions.objectiveManipulate(data));
  };
  const buttonEvent = (data) => {
    toast.success(<SlideToast title="Awesome!" message={"Notify has submitted successfully"} error={false} />, {
      toastId: "ASSIGN_SUCCESS",
    });
    dispatch(actions.notifyObjective(data));
  };

  let objectiveData = [];
  if (props.page === "checkin") {
    if (editable && userObjectiveHistory && userObjectiveHistory.length !== 0) {
      objectiveData = userObjectiveHistory
        .filter((obj) => obj.status !== objectiveStatusValue.DRAFTED && obj.status !== objectiveStatusValue.REJECTED)
        .sort((a, b) => moment(b.auditCreatedDate) - moment(a.auditCreatedDate));
    } else {
      objectiveData = objectives
        .filter((obj) => obj.status !== objectiveStatusValue.DRAFTED && obj.status !== objectiveStatusValue.REJECTED)
        .filter((obj) => {
          if (surveyCycle) {
            if (obj.percentage === 100 && obj.completionDate !== null) {
              if (obj.status === objectiveStatusValue.COMPLETE) {
                return false;
              }
              if (
                moment(obj.completionDate) <= moment(surveyCycle.endDate) &&
                moment(obj.completionDate) >= moment(surveyCycle.startDate)
              ) {
                if (moment(obj.completionDate) <= moment(surveyCycle.startDate)) {
                  return false;
                } else {
                  return true;
                }
              } else {
                if (moment(obj.completionDate) <= moment(surveyCycle.startDate)) {
                  return false;
                } else {
                  return true;
                }
              }
            } else {
              if (moment(surveyCycle.startDate) <= moment(obj.endDate)) {
                return true;
              } else {
                return false;
              }
            }
          }
        });
    }
    if (props.submitedDate) {
      notifyDisable = true;
    }
  } else if (userObjectiveHistory) {
    objectiveData = userObjectiveHistory
      .filter((obj) => obj.status !== objectiveStatusValue.DRAFTED)
      .sort((a, b) => moment(b.auditCreatedDate) - moment(a.auditCreatedDate));
    notifyDisable = true;
  }

  return (
    <div>
      <div className={["box", style.employeeObjectives_box].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Objectives</h2>
            <h3>Mark the status of each objectives. Update progress on key results, if any.</h3>
          </div>
        </div>
        {objectiveData.length === 0 ? (
          <NoObjetivesFound />
        ) : (
          <Accordion defaultActiveKey={props.page === "checkin" ? "0" : ""} className="custom-accordion">
            <EmployeeObjectiveHTML
              user={user}
              objectiveData={objectiveData}
              notifyDisable={notifyDisable}
              selectRadio={selectRadio}
              userInputData={userInputData}
              buttonEvent={buttonEvent}
              setEventValue={setEventValue}
              eventValue={eventValue}
              setCurrentInputText={setCurrentInputText}
              editable={editable}
              page={props.page}
              surveyCycle={surveyCycle}
              errorFlag={errorFlag}
              managerView={props.managerView}
              managerRole={props.managerRole}
            />
          </Accordion>
        )}
      </div>
    </div>
  );
}

export default EmployeeObjectives;
