import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import * as actions from "store/actions/index";
import * as actionTypes from "store/actions/actionTypes";
import storage from "util/storage";
import { successToast, errorToast } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import CustomSwitch from "shared/CustomSwitch/CustomSwitch";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import FiltersSection from "./FiltersSection/FiltersSection";
import CheckInDetailsTable from "./CheckInDetailsTable/CheckInDetailsTable";
import CheckInDetailsCharts from "./CheckInDetailsCharts/CheckInDetailsCharts";
import { IC_GRAPH, IC_LIST } from "const/imgCost";

export default function FeedbackWrapper() {
  const dispatch = useDispatch();
  const loggedInUser = storage.getUser();
  const [detailView, setDetailView] = useState("LEFT");

  const {
    bandOptions,
    cycleAndPeriodOptions,
    groupOptions,
    employeeTypeOptions,
    locationOptions,
    managerOptions,
    statusOptions,
    subGroupOptions,
    isLoading,
    feedbackFilterData,
    filtersDetailsHr,
    fileResponse,
    feedbackDashboardData,
    feedbackGraphData,
    isDashboardDataLoding,
    isGraphDataLoding,
    feedbackDetailsInHRView,
    totalCount,
    latestCycleDasboard,
    feedbackDashboardPeriod, } = useSelector(
      (state) => state.monthlyCheckinFeedback
    );

  const { filterSubGroup, filterLocationWise } = feedbackFilterData;
  useEffect(() => {
    const body = {
      location: filterLocationWise?.length > 0 ? filterLocationWise.map((v) => v.label) : undefined,
      parentGroupId: loggedInUser?.parentGroupId,
      subGroupId: filterSubGroup?.length > 0 ? filterSubGroup.map((v) => v.subGroupId) : undefined,
    }
    dispatch(actions.getFiltersDetailsHr(body));
  }, [dispatch, filterSubGroup, filterLocationWise]);

  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);
      dispatch({ type: actionTypes.MONTHLY_CHECKIN_FEEDBACK_ACTION.CLEAR_CHECKIN_FEEDBACK_EXPORT, });
    }
  }, [fileResponse, dispatch]);

  const handleFilter = () => {
    if (feedbackFilterData && Object.keys(feedbackFilterData)?.length > 0 && feedbackFilterData?.filterCheckInPeriod?.cyclePeriodShort.length > 0) {
      detailView === "LEFT" && dispatch(actions.getDashboardDataHR(latestCycleDasboard, 1, 10, "", feedbackFilterData));
      detailView === "RIGHT" && dispatch(actions.graphCheckinFeedbackHR(latestCycleDasboard,feedbackFilterData));
    } else {
      errorToast("Please select Values from Filter");
    }

  }

  const getHistoryDetails = (empId, managerId, period) => {
    dispatch(
      actions.showFeedbackDetailsInHRView(
        empId,
        managerId,
        loggedInUser?.parentGroupId,
        period,
        loggedInUser?.subGroupId,
        loggedInUser?.employeeId,
        "HR_ROLE_NEW"
      )
    );
  }

  const exportQuestionWise = (questionID,isEmployee) => {
    if(isEmployee){
      dispatch(actions.questionBasedExportEmployee(questionID, latestCycleDasboard, feedbackFilterData));
    } else {
      dispatch(actions.getQuestionBasedExportManager(questionID, latestCycleDasboard, feedbackFilterData));
    }
  }

  const handleSwitch = (data) => {
    data === "RIGHT" && dispatch(actions.graphCheckinFeedbackHR(latestCycleDasboard,feedbackFilterData));
    setDetailView(data);
  }

  const handleClear = () => {
    dispatch(actions.getDashboardDataHR(latestCycleDasboard, 1, 10, ""));
    dispatch(actions.graphCheckinFeedbackHR(latestCycleDasboard));
    dispatch({
      type: actionTypes.MONTHLY_CHECKIN_FEEDBACK_ACTION.CLEAR_FEEDBACK_FILTER_DATA,
    });
  };

  return (
    <React.Fragment>
      <SubNavigation type="Feedback" />
      {isLoading && <Spinner />}
      <Container>
        {filtersDetailsHr &&
          <FiltersSection
            bandOptions={bandOptions}
            cycleAndPeriodOptions={cycleAndPeriodOptions}
            groupOptions={groupOptions}
            employeeTypeOptions={employeeTypeOptions}
            locationOptions={locationOptions}
            managerOptions={managerOptions}
            statusOptions={statusOptions}
            subGroupOptions={subGroupOptions}
            feedbackFilterData={feedbackFilterData}
            handleFilter={handleFilter}
            handleClear={handleClear}
          />}
        {latestCycleDasboard && <Row>
          <Col md={12}>
            <CustomSwitch
              img1={IC_LIST}
              img2={IC_GRAPH}
              checked={detailView}
              handleChecked={(data) => handleSwitch(data)}
            />
          </Col>
        </Row>}
        {detailView === "LEFT" && latestCycleDasboard &&
          <CheckInDetailsTable
            feedbackDetailsInHRView={feedbackDetailsInHRView}
            feedbackDashboardData={feedbackDashboardData}
            feedbackFilterData={feedbackFilterData}
            totalCount={totalCount}
            latestCycleDasboard={latestCycleDasboard}
            feedbackDashboardPeriod={feedbackDashboardPeriod}
            getHistoryDetails={getHistoryDetails}
            isDashboardDataLoding={isDashboardDataLoding}
          />
        }
        {detailView === "RIGHT" && latestCycleDasboard &&
          <CheckInDetailsCharts
            feedbackGraphData={feedbackGraphData}
            isGraphDataLoding={isGraphDataLoding}
            exportQuestionWise={exportQuestionWise} />}
      </Container>
    </React.Fragment>
  );
}
