import React, { useState, useEffect } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Label, Tooltip } from "recharts";
import _ from "lodash";
import style from "./PieChartCard.module.scss";

export default function PieChartCard({
    index,
    questions,
    isEmployee,
    exportQuestionWise
}) {
    const [pieChartData, setPieChartData] = useState([]);

    useEffect(() => {
        if (questions !== undefined) {
            let pieData = questions?.optionDetailsGraphDTO === undefined || questions?.optionDetailsGraphDTO === null ? [] : questions?.optionDetailsGraphDTO;
            pieData = pieData?.map(item => {
                return {
                    name: item?.option?.toUpperCase(),
                    value: item?.optionCount,
                    percentageValue: item?.optionPercentage
                };
            });
            setPieChartData(_.orderBy(pieData, ["name"], ["desc"]));
        }
    }, [questions]);

    const CustomTooltipForPieChart = (props) => {
        const { active, payload } = props;
        let totalVal = 0;
        let pr;
        if (active) {
            pieChartData && pieChartData.forEach((data) => {
                totalVal += data?.value;
            });
            pr = (payload[0]["value"] / totalVal) * 100;
            return (
                <>
                    <div className="recharts-tooltip-custom-wrapper">
                        <p className="recharts-tooltip-label">{payload[0]["name"]}</p>
                        {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%${" ( "}${payload[0]["value"]}${" ) "}`}</span>}
                        {/* <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Option Count"}${": "}${payload[0]["value"]}`}</span> */}
                    </div>
                </>
            );
        }

        return null;
    };

    function CustomLabel({ viewBox }) {
        const { cx, cy } = viewBox;
        let totalValue = 0;
        pieChartData && pieChartData.forEach((dt) => {
            totalValue += dt.value;
        });
        const total = `${totalValue}`;
        return (
            <>
                <text
                    x={cx}
                    y={cy - 12}
                    fill="#3d405c"
                    className="recharts-text recharts-label inner-recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                >
                    <tspan alignmentBaseline="middle" fontSize="20">
                        Total
                    </tspan>
                </text>
                <text
                    x={cx}
                    y={cy + 15}
                    fill="#3d405c"
                    className="recharts-text recharts-label inner-recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                >
                    <tspan alignmentBaseline="middle" fontSize="20">
                        {questions?.total}
                    </tspan>
                </text>
            </>
        );
    }
    const COLORS = {
        YES: "#7033FF",
        NO: "#22C1AE",
        "NO CHALLENGES RAISED": "#FDC861"
    };

    return (
        <div className={style.card_wrapper}>
            <div className={`box-inner-pad pb-0 ${style.card_container}`}>
                {pieChartData && pieChartData?.length > 0 ? (
                    <>
                        <div className="graph-item">
                            <div className={style.export}><span data-test='exportQuestionWiseBtn' onClick={() => exportQuestionWise(questions?.questionId, isEmployee)}>Export</span></div>
                            <h4 className={`semi-bold ${style.card_title}`}>Q{index}: {questions?.questionTitle}</h4>
                            <div style={{ width: "100%", height: 300 }}>
                                <ResponsiveContainer>
                                    <PieChart>
                                        <Pie
                                            data={pieChartData}
                                            cx="50%"
                                            cy="50%"
                                            cursor="pointer"
                                            innerRadius={85}
                                            outerRadius={105}
                                            fill="#8884d8"
                                            paddingAngle={0}
                                            dataKey="value"
                                        >
                                            <Label width={30} position="center" content={<CustomLabel value1={pieChartData} />}></Label>
                                            {pieChartData.map((entry, index) => {
                                                return (
                                                    <Cell key={index} fill={COLORS[entry["name"]]} />
                                                );
                                            })}
                                        </Pie>
                                        <Tooltip content={<CustomTooltipForPieChart />} />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={style.legent_wrapper}>
                            <ul className={style.lenegd_list}>
                                {pieChartData.map((entry, index) => {
                                    return (
                                        <li className={style.legend_item} key={index}>
                                            <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                                            {entry["name"]}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                ) : (
                    <div className="no-record-found-wrpr mt-3">No Data Available</div>
                )}
            </div>
        </div>
    );
}
