import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table } from "react-bootstrap";
import style from "../CycleList/CycleList.module.scss";
import * as actions from "store/actions/index";
import { dateFormat2 } from "util/dateUtils";
import { filterArray } from "util/general";
import { noData } from "const/constValue";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

export default function ReviewingCycleList() {
  const dispatch = useDispatch();
  const { quarterlyCycleList, isLoading } = useSelector((state) => state.performance);
  const [cycleData, setCycleData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [isBlinked, setBlinked] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setBlinked(false);
    }, 5000);
  }, []);

  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    filteredData = filterArray(quarterlyCycleList, filters);
    setFilteredData(filteredData);
    setSearchFilter("");
  };

  useEffect(() => {
    dispatch(actions.GetQuarterlyCycleList("REVIEWING-ASSESSMENT"));
  }, [dispatch]);

  useEffect(() => {
    filteredData.length > 0
      ? setCycleData(filteredData)
      : searchFilter
      ? setCycleData(filteredData)
      : setCycleData(quarterlyCycleList.length > 0 && quarterlyCycleList);
  }, [quarterlyCycleList, filteredData, searchFilter]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.cycleListWrapper}>
      <SubNavigation type="Performance" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Performance</h1>
            <span className="st-breadcrumb">
              Assessment<span>Reviewing Assessment</span>
            </span>
          </Col>
          <Col className="col-12">
            <div className="box pb-0 mb-0">
              <div className="box-heading-wrapper">
                <div className="box-heading justify-content-between d-flex align-items-center">
                  <h2>Cycle List</h2>
                </div>
              </div>
            </div>
            <div className="outer-table-wrapper">
              <div className="box-light-gradient"></div>
              <div className={[style.custom_table, "table-theme-1"].join(" ")}>
                {cycleData && cycleData.length > 0 ? (
                  <Table hover responsive className={style.tab_table_responsive}>
                    <thead>
                      <tr>
                        <th>Period</th>
                        <th>Cycle</th>
                        <th>Starts On</th>
                        <th>Ends On</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageOfItems &&
                        pageOfItems.length > 0 &&
                        pageOfItems.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className={isBlinked ? "blinked" : ""}>
                                {item.status !== "In Active" ? (
                                  <Link to={routePath.REVIEWING_ASSESSMENT + "/" + item.cycleId}>{item.period}</Link>
                                ) : (
                                  <Link>{item.period}</Link>
                                )}
                              </td>
                              <td>
                                <span>{item.cycle}</span>
                              </td>
                              <td>
                                <span>{dateFormat2(item.rvmAssessment?.assessmentStartOn)}</span>
                              </td>
                              <td>
                                <span>{dateFormat2(item.rvmAssessment?.assessmentEndOn)}</span>
                              </td>
                              <td>
                                <span className={item.status === "In Progress" ? style.inprogress : style.ended}>
                                  {item.status === "Completed" ? "Ended" : item.status}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    <div className="gap80"></div>
                  </div>
                )}
              </div>
            </div>
            {cycleData && cycleData.length > 0 && (
              <Pagination pageSize={10} items={cycleData} resetData={resetData} onChangePage={onChangePage} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
