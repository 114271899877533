import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import moment from "moment";
import style from "./SelectCertificate.module.scss";
import * as actions from "store/actions/index";
import { IC_FILE_ATTACHMENT, IC_SEARCH_RESET, IC_INFO } from "const/imgCost";
import { dateFormatYMD } from "util/dateUtils";
import CommonDatePicker from "shared/CommonDatePicker";
import TooltipInfoLi from "shared/Tooltip/TooltipInfoLi";
import Spinner from "shared/Spinner/Spinner";
import { errorToast, successToast } from "util/general";

export default function SelectCertificate() {
  const targetInput = useRef(null);
  const targetButton = useRef(null);
  const { successResponse, certificate, isLoading, errorResponse } = useSelector((state) => state.university);
  let inputElement = React.createRef();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [displayFileNameField, setFileNameField] = useState(false);
  const [displayFileUploadBtn, setFileUploadBtn] = useState(true);
  const [certificateName, SelectCertificateName] = useState(null);
  const [isDisable, setDisable] = useState(true);
  const [ownCertificateName, setOwnCertificate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputError, setInputError] = useState(false);
  const [uploadFileError, setUploadError] = useState({ error: false, message: "" });
  const [options, setOption] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setToggle] = useState("yes");
  const [isDisableSubmitBtn, setDisableSubmitBtn] = useState(true);
  let buttonClass = "";
  const [certiFicateLabel, selectCertificateLabel] = useState("Select Certificate File");
  let dateFlag = false;
  let a = moment(startDate);
  let b,
    duration = null;
  if (endDate) {
    dateFlag = true;
    b = moment(endDate);
    let diffDuration = moment.duration(b.diff(a));

    let years = diffDuration.years();
    let months = diffDuration.months();
    let days = diffDuration.days();

    if (years > 0) {
      duration = years + " Year(s) " + months + " Month(s) " + days + " ";
    } else if (months > 0) {
      duration = months + " Month(s) " + days + " ";
    } else if (days >= 0) {
      duration = days + " ";
    }
  }

  // display successfully submitted certificate notification
  useEffect(() => {
    if (successResponse) {
      if (successResponse.success) {
        successToast(successResponse.message);
        setFile();
        setOwnCertificate(null);
        setFileUploadBtn(true);
        setDisable(true);
        setInputError(false);
        setUploadError({ error: false, message: "" });
        SelectCertificateName(null);
        setFileNameField(false);
        setSelectedOption(null);
        setToggle("yes");
        setStartDate(null);
        setEndDate(null);
        selectCertificateLabel("Select Certificate File");
      }
    }
    if (errorResponse) {
      if (errorResponse.error) errorToast(errorResponse.message);
    }
  }, [errorResponse, successResponse]);

  // get certification list
  useEffect(() => {
    const certificateList = () => {
      dispatch(actions.getCertificateList);
    };
    certificateList();
  }, [dispatch]);

  // set certificate
  useEffect(() => {
    const certificateArr = [];
    certificateArr.push({ value: "0", label: "I’ll add new certification" });
    if (certificate.length) {
      certificate.map((cr) => {
        const obj = {
          value: cr.id,
          label: cr.name,
        };
        certificateArr.push(obj);
        return cr;
      });
    }
    setOption(certificateArr);
  }, [certificate]);

  useEffect(() => {
    if (file && !uploadFileError.error) {
      setFileNameField(true);
      setFileUploadBtn(false);
      setFileName(file.name);
      setUploadError({ error: false, message: "" });
      selectCertificateLabel("Selected Certificate File");
    }
  }, [file, uploadFileError.error]);

  /**Disabled the form */

  // useEffect(() => {
  //   if (certificateName) {
  //     setDisable(false);
  //   } else {
  //     setDisable(true);
  //   }
  // }, [certificateName]);

  useEffect(() => {
    if (showDatePicker === "yes" && certificateName && certificateName.value !== "0" && startDate && file) {
      setDisableSubmitBtn(false);
    } else if (showDatePicker !== "yes" && certificateName && certificateName.value !== "0" && file) {
      setDisableSubmitBtn(false);
    } else if (showDatePicker === "yes" && ownCertificateName && startDate && file) {
      setDisableSubmitBtn(false);
    } else if (showDatePicker !== "yes" && ownCertificateName && file) {
      setDisableSubmitBtn(false);
    } else {
      setDisableSubmitBtn(true);
    }
  }, [certificateName, endDate, file, ownCertificateName, showDatePicker, startDate]);

  const uploadFileAPI = (files) => {
    inputElement.value = "";
    setFile(files);
    setUploadError({ error: false, message: "" });
    const fileTypeArr = [
      ".xlsx",
      ".pdf",
      ".png",
      ".jpg",
      ".jpeg",
      ".doc",
      ".docx",
      ".zip",
      ".txt",
      ".xls",
      ".ppt",
      ".pptx",
      ".docx",
    ];
    if (files) {
      let includeFileType = false;
      fileTypeArr.forEach((type) => {
        if (files.name.includes(type)) {
          includeFileType = true;
          return;
        }
        return;
      });

      if (!includeFileType) {
        setFile(null);
        inputElement.value = "";
        errorToast("File Type not allowed.");
        return;
      }

      if (files.size > 2097152) {
        setFile(null);
        inputElement.value = "";
        errorToast("Maximum File Size is 2 MB.");
        return;
      }
    }
  };

  const deleteFile = () => {
    inputElement.value = "";
    setFile(null);
    setFileNameField(false);
    setFileUploadBtn(true);
    setFileName();
    setUploadError({ error: false, message: "" });
    selectCertificateLabel("Select Certificate File");
  };

  const handleChange = (select) => {
    SelectCertificateName(select);
    setSelectedOption(select);
    setDisable(false);
    setInputError(false);
  };

  const handleInputChange = (e) => {
    setOwnCertificate(e.target.value.trim());
    setInputError(false);
  };

  const submitData = () => {
    if (!inputError && !uploadFileError.error) {
      setUploadError({ error: false, message: "" });
      setInputError(false);
      const formData = new FormData();
      formData.append("file", file);
      if (certificateName && certificateName.value === "0") {
        formData.append("name", ownCertificateName);
        formData.append("id", "0");
      } else {
        formData.append("id", certificateName.value);
      }

      if (showDatePicker === "yes") {
        formData.append("startDate", dateFormatYMD(startDate));
        if (endDate) {
          formData.append("endDate", dateFormatYMD(endDate));
        }
        // formData.append('itHasValidity', 'Yes');
      }
      dispatch(actions.submitCertificateData(formData));
    }
  };

  return (
    <div className={["box", style.idp_box].join(" ")}>
      {isLoading && <Spinner />}
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Select Certification</h2>
          <h3>Please select the certificate from below or you can also upload a new certificate as well</h3>
        </div>
      </div>
      <div className="box-light-gradient"></div>
      <div className="box-inner-pad position-relative d-flex align-items-start">
        <div className={style.certificte_box}>
          <span>Choose Certification Name</span>
          <div className="card_action">
            {options && (
              <Select
                options={options}
                className="custom-default-dd-box"
                classNamePrefix="select"
                isSearchable={true}
                isClearable={true}
                onChange={(select) => handleChange(select)}
                placeholder="Select Certification"
                value={selectedOption}
                isDisabled={true}
              />
            )}
          </div>
          {/* below form group visible, when you select the option of "I’ll add new certification" */}
          {certificateName && certificateName.value === "0" && (
            <Form.Group className="form_group_comment form_group_comment_2options mt-2">
              <Form.Control
                type="text"
                placeholder="Enter Certification Name"
                onChange={handleInputChange}
                maxLength="200"
                ref={targetInput}
                disabled
              />
            </Form.Group>
          )}
        </div>
        <div className={[style.certificte_box, "flex-row", "flex-wrap"].join(" ")}>
          <span className={style.cf_title}>{certiFicateLabel}</span>
          <div className={[style.file_info_tooltip, "custom-tooltip"].join(" ")}>
            {certiFicateLabel === "Select Certificate File" && (
              <div className="box-info">
                <ReactSVG src={`${IC_INFO}`} title="" alt="info" />
              </div>
            )}
            <TooltipInfoLi
              heading="Attachments"
              listItem={[
                "You can upload a single file only",
                "Maximum file size is 2MB",
                "Supported files 'pdf, png, jpg, doc, docx, ppt, pptx, xls, xlsx, zip'",
              ]}
            />
          </div>
          <div className={style.selected_file}>
            <input
              id="fileid"
              accept=".xlsx,.pdf,.png,.jpg,.jpeg,.doc,.docx,.zip,.txt,.xls,.ppt,.pptx,.docx"
              type="file"
              hidden
              ref={(input) => (inputElement = input)}
              onChange={() => {
                uploadFileAPI(inputElement.files[0]);
              }}
            />
            {displayFileUploadBtn && (
              <button
                onClick={() => {
                  inputElement.click();
                }}
                type="button"
                className={["btn-round save_box_btn btn btn-secondary mr-3", style.create_new_btn, buttonClass].join(
                  " "
                )}
                disabled={isDisable}
                ref={targetButton}
              >
                <strong>Upload File</strong>
              </button>
            )}
          </div>
          {/* when you upload file button will be remove and name will be displayed */}
          {displayFileNameField && (
            <ul className="file-listing w-100">
              <li>
                <div>
                  <ReactSVG className="file-attachment-icon" src={`${IC_FILE_ATTACHMENT}`} title="" alt="" />
                  {fileName}
                </div>
                <span className="delete-item" onClick={deleteFile}>
                  <ReactSVG src={`${IC_SEARCH_RESET}`} title="" alt="" />
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="box-inner-pad">
        <div className="date_picker_time_wrapper p-0">
          <h5 className={style.date_title}>Does it have any validity?</h5>
          <div className="certificate-date-wrapper">
            <div className={style.cl_t_wrapper}>
              {showDatePicker === "yes" && (
                <CommonDatePicker
                  handleStartDate={setStartDate}
                  handleEndDate={setEndDate}
                  duration={duration}
                  startDate={startDate}
                  endDate={endDate}
                  startDatePlaceHolder="Issued On"
                  endDatePlaceHolderText="Expires On"
                  showDayDiff={dateFlag}
                  isDisabled={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="box-inner-pad pb-0">
        <div className={style.save_box_btn}>
          <button type="submit" className="btn btn-primary" onClick={submitData} disabled={isDisableSubmitBtn}>
            <strong>Add Certification</strong>
          </button>
        </div>
      </div>
    </div>
  );
}
