import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import ReactDatePicker from "react-datepicker";
import MultiSelect from "react-multi-select-component";
import moment from "moment";
import style from "./CreatePerformanceCycle.module.scss";
import * as actions from "store/actions/index";
import { requiredFieldMissing, pleaseSelectDate, successToast, errorToast, gradeValuePerfomance } from "util/general";
import { ExcelRenderer } from "react-excel-renderer";
import { endOfDay, toDate } from "util/dateUtils";
import { IC_SEARCH_RESET } from "const/imgCost";
import routePath from "const/routePath";
import storage from "util/storage";
import QuestionTemplateModal from "./QuestionTemplateModal/QuestionTemplateModal";
import StartAndEndDatePicker from "./StartAndEndDatePicker";
import InfoTooltip from "../../InfoTooltip/InfoTooltip";
import InfoTooltipWithLi from "../../InfoTooltip/InfoTooltipWithLi";
import Spinner from "shared/Spinner/Spinner";
import config from "util/config";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { status, successMessage } from "const/constValue";
import ComfirmUploadPopup from "components/Objectives/AllObjectives/comfirmUploadPopup";
import http from "util/http";
import { OFFLINE_RATING_FOR_EMPLOYEE } from "util/endpoints";
import { moduleRights } from "util/utils";

export default function EditPerfomanceCycle(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = storage.getUser();
  const [quarterStartDate, setQuarterStartDate] = useState();
  const [quarterEndDate, setQuarterEndDate] = useState();
  const [reviewingStartDate, setReviewingStartDate] = useState();
  const [reviewingEndDate, setReviewingEndDate] = useState();
  const [selfStartDate, setSelfStartDate] = useState();
  const [selfEndDate, setSelfEndDate] = useState();
  const [cutOffDate, setCutOffDate] = useState();
  const [selected, setSelected] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [reportingStartDate, setReportingStartDate] = useState();
  const [ratingVisibleDate, setRatingVisibleDate] = useState();
  const [acknowledgementEndDate, setAcknowledgementEndDate] = useState();
  const [reportingEndDate, setReportingEndDate] = useState();
  const [isActive, setIsActive] = useState(false);
  const [gradeOrRatingOrBoth, setGradeOrRatingOrBoth] = useState("GRADE");
  const [activateDisabled, setActivateDisabled] = useState(false);
  const [cycle, setCycle] = useState("");
  const [activeDisabled, setActiveDisabled] = useState(false);
  const [selectQuestion, setSelectQuestion] = useState({ SELF: "", REPORTING: "", REVIEWING: "" });
  const [isSelectModal, setIsSelectModal] = useState("");
  const [errors, setErrors] = useState({});
  const [submitClick, setSubmitClick] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [subGroups, setSubGroups] = useState([]);
  const [isRMTemplateVisibleToRVM, setIsRMTemplateVisibleToRVM] = useState();
  const [file, setFile] = useState(null);
  const [ratingData, setRatingData] = useState([]);
  const [confirmUploadPopup, setConfirmUploadPopup] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [isRatingPermit, setIsRatingPermit] = useState(false);
  let inputElement = React.createRef();
  const [uploadFileLoader, setUploadLoader] = useState(false);
  const { subGroupClient } = useSelector((state) => state.admin);

  const {
    isPerformanceLoading,
    employeeNameList,
    createSuccessResponse,
    isTemplateLoading,
    templateList,
    locationList,
    isCycleLoading,
    employeeTypeList,
    cycleData,
  } = useSelector((state) => state.performance);
  let templates = templateList;
  if (isSelectModal === "SELF") templates = templates.filter((temp) => temp.category === "SELF_ASSESSMENT");
  else if (isSelectModal === "REPORTING" || isSelectModal === "REVIEWING")
    templates = templates.filter((temp) => temp.category === "MANAGER_ASSESSMENT");

  const cycleId = props.match.params.id;
  storage.set("cycle", `edit/${cycleId}`);
  storage.remove("cId");

  useEffect(()=>{
    setIsRatingPermit(cycleData?.showRatingDropDown ? cycleData?.showRatingDropDown : false);
   },[cycleData]);
   
  useEffect(() => {
    dispatch(actions.GetCreatedAssessmentCycle(cycleId));
    dispatch(actions.GetTemplateList());
    dispatch(actions.getSubGroupClient());
    dispatch(actions.GetLocationList());
  }, [dispatch, cycleId]);

  useEffect(() => {
    if (cycleData) {
      setCycle(cycleData.cycle);
      setIsRMTemplateVisibleToRVM(cycleData.isRMTemplateVisibleToRVM ? true : false);
      setIsActive(cycleData.isActive ? "EDIT_YES" : "EDIT_NO");
      setGradeOrRatingOrBoth(cycleData.gradeOrRatingOrBoth);
      setActiveDisabled(cycleData.isActive ? true : false);
      setQuarterStartDate(toDate(cycleData.quarterStartDate));
      setQuarterEndDate(toDate(cycleData.quarterEndDate));
      setActivateDisabled(cycleData.isActive);
      if (cycleData.locations) {
        let c = [];
        cycleData.locations.map((v) => {
          c.push({ value: v, label: v });
          return v;
        });
        setLocations(c);
      } else setLocations([]);
      if (cycleData.excludeGroup) {
        setSelected(
          cycleData.excludeGroup.map((value) => {
            return {
              value: value,
              label: value,
            };
          })
        );
      } else setSelected([]);
      if (cycleData.subGroups) {
        setSubGroups(
          cycleData.subGroups.map((value) => {
            return {
              value: value.subGroupId,
              label: value.subGroupDisplayName,
            };
          })
        );
      }
      if (cycleData.cutOffDate) setCutOffDate(toDate(cycleData.cutOffDate));
      else setCutOffDate("");
      if (cycleData.ratingVisibleDate) setRatingVisibleDate(toDate(cycleData.ratingVisibleDate));
      else setRatingVisibleDate("");
      if (cycleData.acknowledgementEndDate) setAcknowledgementEndDate(toDate(cycleData.acknowledgementEndDate));
      else setAcknowledgementEndDate("");
      if (cycleData.rpmAssessment) {
        setReportingStartDate(toDate(cycleData.rpmAssessment.assessmentStartOn));
        setReportingEndDate(toDate(cycleData.rpmAssessment.assessmentEndOn));
      } else {
        setReportingStartDate();
        setReportingEndDate();
      }
      if (cycleData.rvmAssessment) {
        setReviewingStartDate(toDate(cycleData.rvmAssessment.assessmentStartOn));
        setReviewingEndDate(toDate(cycleData.rvmAssessment.assessmentEndOn));
      } else {
        setReviewingStartDate();
        setReviewingEndDate();
      }
      if (cycleData.selfAssessment) {
        setSelfStartDate(toDate(cycleData.selfAssessment.assessmentStartOn));
        setSelfEndDate(toDate(cycleData.selfAssessment.assessmentEndOn));
      } else {
        setSelfStartDate();
        setSelfEndDate();
      }
      if (cycleData.selfAssessment || cycleData.rpmAssessment || cycleData.rvmAssessment)
        setSelectQuestion({
          SELF: {
            id: cycleData.selfAssessment && cycleData.selfAssessment.questionTemplateId,
            name: cycleData.selfAssessment && cycleData.selfAssessment.questionTemplateName,
          },
          REPORTING: {
            id: cycleData.rpmAssessment && cycleData.rpmAssessment.questionTemplateId,
            name: cycleData.rpmAssessment && cycleData.rpmAssessment.questionTemplateName,
          },
          REVIEWING: {
            id: cycleData.rvmAssessment && cycleData.rvmAssessment.questionTemplateId,
            name: cycleData.rvmAssessment && cycleData.rvmAssessment.questionTemplateName,
          },
        });
    }
  }, [cycleData]);

  useEffect(() => {
    if (cycleData && employeeNameList && employeeNameList.length > 0) {
      if (cycleData.employeeIds) {
        setEmployeeList(
          cycleData.employeeIds.map((v) => {
            return {
              value: v.employeeId + config.employeeIdSplit + v.parentGroupId + config.employeeIdSplit + v.subGroupId,
              label: v.displayName,
            };
          })
        );
      } else setEmployeeList([]);
    }
  }, [cycleData, employeeNameList]);

  useEffect(() => {
    if (createSuccessResponse) {
      if (createSuccessResponse.success) {
        successToast(successMessage.CYCLE_EDITED);
        history.push(routePath.PMS_CYCLE);
      } else {
        errorToast(createSuccessResponse.message);
      }
      dispatch({ type: "CLEAR_PERFORMANCE_RESPONSE" });
    }
  }, [createSuccessResponse, dispatch, history]);

  const savePerformance = () => {
    setSubmitClick(true);
    let error = errors;
    if (!cycle.trim()) error.cycle = true;

    setErrors({ ...error });
    if (errors.cycle || errors.groups) {
      requiredFieldMissing();
      return;
    }
    if (!locations || locations?.length === 0) {
      setErrors({ ...errors, locations: true });
      errorToast("Please select locations");
      return;
    }
    if (!subGroups || subGroups?.length === 0) {
      setErrors({ ...errors, subGroups: true });
      requiredFieldMissing();
      return;
    }
    if (!quarterStartDate || !quarterEndDate) {
      pleaseSelectDate();
      return;
    }
    if (ratingVisibleDate && !acknowledgementEndDate) {
      pleaseSelectDate();
      return;
    }
    if (selfStartDate || selfEndDate)
      if (!selfEndDate || !selfStartDate) {
        setErrors({ ...errors, selfDate: true });
        pleaseSelectDate();
        return;
      }
    if (reportingStartDate || reportingEndDate)
      if (!reportingStartDate || !reportingEndDate) {
        setErrors({ ...errors, reporteeDate: true });
        pleaseSelectDate();
        return;
      }
    if (reviewingStartDate || reviewingEndDate)
      if (!reviewingStartDate || !reviewingEndDate) {
        setErrors({ ...errors, reviewerDate: true });
        pleaseSelectDate();
        return;
      }
    let data = {
      quarterStartDate: quarterStartDate,
      quarterEndDate: quarterEndDate,
      reviewingStartDate,
      reviewingEndDate,
      selfStartDate,
      selfEndDate,
      reportingStartDate,
      reportingEndDate,
      ratingVisibleDate,
      acknowledgementEndDate: acknowledgementEndDate ? endOfDay(acknowledgementEndDate) : undefined,
      isRMTemplateVisibleToRVM: isRMTemplateVisibleToRVM,
      showRatingDropDown: isRatingPermit,
      // selectedOption,
      cutOffDate,
      locations: locations?.length > 0 ? locations.map((v) => v.label) : [],
      isActive,
      gradeOrRatingOrBoth,
      cycle,
      excludeGroup: selected.length > 0 ? selected.map((value) => value.label) : null,
      employeeIds:
        employeeList.length > 0
          ? employeeList.map((value) => {
            return {
              employeeId: value.value?.split(config.employeeIdSplit)[0],
              parentGroupId: value.value?.split(config.employeeIdSplit)[1],
              subGroupId: value.value?.split(config.employeeIdSplit)[2],
              displayName: value.label,
            };
          })
          : null,
      selectQuestion,
      status: cycleData && cycleData.status,
      subGroups: subGroups?.length > 0 ? subGroups.map((v) => v.value) : [],
      createdBy: {
        empName: user.displayName,
        employeeId: user.employeeId,
        dispArr: user.displayPicture || user.empInitial,
        designation: user.designation,
        department: user.department,
        country: user.country,
        parentGroupId: user.parentGroupId,
        subGroupId: user.subGroupId,
      },
      period: moment(quarterEndDate).format("MMM YYYY"),
      cycleId: props.match.params.id,
    };
    dispatch(actions.CreatePerformanceCycle(data));
  };

  //for modal closing and opening
  const hide = (value) => {
    setIsSelectModal(value);
    setIsModal(!isModal);
    if (value === "SELF") storage.set("templateType", "SELF_ASSESSMENT");
    else if (value === "REPORTING") storage.set("templateType", "MANAGER_ASSESSMENT");
    else if (value === "REVIEWING") storage.set("templateType", "MANAGER_ASSESSMENT");
  };

  const cancelHandler = () => {
    history.push(routePath.PMS_CYCLE);
  };

  const selectTemplate = (id, value, select) => {
    hide();
    switch (select) {
      case "SELF":
        setSelectQuestion({
          ...selectQuestion,
          SELF: {
            id: id,
            name: value,
          },
        });
        return;
      case "REPORTING":
        setSelectQuestion({
          ...selectQuestion,
          REPORTING: {
            id: id,
            name: value,
          },
        });
        return;
      case "REVIEWING":
        setSelectQuestion({
          ...selectQuestion,
          REVIEWING: {
            id: id,
            name: value,
          },
        });
        return;
      default:
        return;
    }
  };

  const subGroupNameWithId = (subGroupName) => {
    let data;
    if (subGroupName) {
      data = cycleData.subGroups.find((item) => item.subGroupDisplayName.toLowerCase() === subGroupName.toLowerCase());
    }
    if (data) {
      return data.subGroupId;
    } else {
      if (subGroupName?.toLowerCase() === "saggezza") {
        return "3";
      } else {
        return subGroupName;
      }
    }
  };

  const subGroupNames = cycleData?.subGroups?.map((item) => item.subGroupDisplayName);
  const subGroupNamesLowerCase = cycleData?.subGroups?.map((item) => item.subGroupDisplayName.toLowerCase());
  if (subGroupNamesLowerCase) {
    subGroupNamesLowerCase.includes("sagezza") && subGroupNamesLowerCase.push("saggezza");
  }

  const uploadFileAPI = (files) => {
    inputElement.value = "";
    ExcelRenderer(files, (err, resp) => {
      if (err) {
        console.warn(err);
      } else {
        let obj = {
          cols: resp.cols || [],
          rows: resp.rows || [],
        };

        let ratings = [];
        obj.rows.map((temp, i) => {
          if (i !== 0 && temp.length !== 0) {
            if (temp[0] || temp[1] || temp[2]) {
              let _obj = {
                employeeId: temp[0] === "" ? null : typeof temp[0] === 'string' ? temp[0]?.trim() : temp[0],
                subGroupName: temp[1] === "" ? null : temp[1]?.trim(),
                rating: temp[2] === "" ? null : /^[A-Za-z+]*$/.test(temp[2]) ? gradeValuePerfomance(temp[2]) : temp[2],
                subGroupId: temp[1] === "" ? null : subGroupNameWithId(temp[1]),
              };
              ratings.push(_obj);
            }
          }
          return temp;
        });
        let ratingTotalFlag = false;
        let _tempList = [];
        ratings.forEach((item) => {
          if (!item.employeeId || item.employeeId === undefined) {
            ratingTotalFlag = true;
            let list = [..._tempList];
            let obj = "EmployeeId cannot be empty.";
            if (!list.includes(obj)) {
              list.push(obj);
            }
            _tempList = [...list];
          }
          if (!item.subGroupName || item.subGroupName === undefined) {
            ratingTotalFlag = true;
            let list = [..._tempList];
            let obj = "Entity cannot be empty.";
            if (!list.includes(obj)) {
              list.push(obj);
            }
            _tempList = [...list];
          }
          if (!item.rating || item.rating === undefined) {
            ratingTotalFlag = true;
            let list = [..._tempList];
            let obj = "Rating/Grade cannot be empty.";
            if (!list.includes(obj)) {
              list.push(obj);
            }
            _tempList = [...list];
          }
          if (item.subGroupName || item.subGroupName !== undefined) {
            if (!subGroupNamesLowerCase.includes(item.subGroupName.toLowerCase())) {
              ratingTotalFlag = true;
              let list = [..._tempList];
              let obj = "Entity name should be " + subGroupNames?.join(", ") + ".";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
          }
          if (item.rating || item.rating !== undefined) {
            if (cycleData?.gradeOrRatingOrBoth === "GRADE") {
              const gradeArr = [5.0, 4.49, 3.99, 2.99, 1.99];
              if (!gradeArr.includes(item.rating)) {
                ratingTotalFlag = true;
                let list = [..._tempList];
                let obj = "Grade should be A+, A, B+, B or C.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
            } else if (cycleData?.gradeOrRatingOrBoth === "RATING") {
              // const ratingArr = [1, 2, 3, 4, 5]; old rating structure
              // const ratingArr = [1, 2, 3]; previous rating structure
              const ratingArr = [1, 2, 3, 4];
              if (!ratingArr.includes(item.rating)) {
                ratingTotalFlag = true;
                let list = [..._tempList];
                // let obj = "Rating should be 1, 2, 3, 4 or 5."; old error message
                // let obj = "Rating should be 1, 2, or 3."; previous error message
                let obj = "Rating should be 1, 2, 3 or 4.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
            }
          }
        });
        setErrorList(_tempList);
        setRatingData(ratings);
        if (ratingTotalFlag) {
          setConfirmUploadPopup(true);
          setErrorFlag(ratingTotalFlag);
        } else {
          setConfirmUploadPopup(true);
          setErrorFlag(false);
        }
      }
    });
  };

  const uploadRatingFile = (body) => {
    setUploadLoader(true);
    http
      .post(OFFLINE_RATING_FOR_EMPLOYEE, { quarterlyCycleId: cycleId }, body)
      .then((response) => response.data)
      .then((response) => {
        setUploadLoader(false);
        if (response.status === status.SUCCESS) {
          successToast("Successfully uploaded");
        } else {
          errorToast(response.message.message);
        }
      })
      .catch(() => {
        setUploadLoader(false);
        errorToast("Something has incorrect in added data. Please verify and re-upload.");
      });
  };

  if (isCycleLoading || isTemplateLoading || uploadFileLoader) {
    return <Spinner />;
  }

  return (
    <div className={style.cycle_per_wrapper}>
      <SubNavigation type="Performance" />
      {isPerformanceLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Performance</h1>
            <span className="st-breadcrumb">
              Performance
              <span>
                <Link to={routePath.PMS_CYCLE}>HR View</Link>
              </span>
              <span>Edit Cycle</span>
            </span>
          </Col>
          <Col md={12}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Edit Cycle</h2>
                </div>
              </div>
              <Form name="Cycle Form">
                <div className={[style.custom_form_ctrl_wrapper, "box-inner-pad"].join(" ")}>
                  <Form.Group>
                    <Form.Label>
                      Cycle Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={[errors && errors.cycle && "limit-reached"].join(" ")}
                      placeholder="Cycle Name"
                      value={cycle}
                      onChange={(e) => {
                        setCycle(e.target.value);
                        if (e.target.value.trim()) {
                          setErrors({ ...errors, cycle: false });
                        } else setErrors({ ...errors, cycle: true });
                      }}
                    />
                  </Form.Group>

                  <div className="radio-wrapper d-flex dropdown-multiselect">
                    <Form.Group controlId="">
                      <Form.Label className={style.dd_title}>
                        Sub Group<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          disabled={activeDisabled}
                          options={subGroupClient}
                          className={errors?.subGroups ? "limit-reached" : "multi-select"}
                          value={subGroups || ""}
                          onChange={(value) => {
                            setErrors({ ...errors, locations: false });
                            setSubGroups(value);
                            dispatch(
                              actions.GetEmployeeNameList(
                                locations?.map((v) => v.value),
                                selected?.map((v) => v.value),
                                value?.map((v) => v.value)
                              )
                            );
                            dispatch(actions.GetEmployeeTypeList(value?.map((v) => v.value)));
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="" className="mr-5">
                      <Form.Label>
                        Location
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          disabled={activeDisabled}
                          className={errors?.locations ? "limit-reached" : "multi-select"}
                          options={locationList}
                          value={locations}
                          onChange={(e) => {
                            setErrors({ ...errors, locations: false });
                            setLocations(e);
                            dispatch(
                              actions.GetEmployeeNameList(
                                e?.map((v) => v.value),
                                selected?.map((v) => v.value),
                                subGroups?.map((v) => v.value)
                              )
                            );
                          }}
                          labelledBy={"name"}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="radio-wrapper  d-flex dropdown-multiselect">
                    <Form.Group controlId="" className="mr-5">
                      <Form.Label>Exclude Groups</Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          disabled={activeDisabled}
                          options={employeeTypeList}
                          value={selected}
                          onChange={(e) => {
                            setSelected(e);
                            dispatch(
                              actions.GetEmployeeNameList(
                                locations?.map((v) => v.value),
                                e.map((v) => v.value),
                                subGroups.map((v) => v.value)
                              )
                            );
                          }}
                          labelledBy={"name"}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="">
                      <Form.Label>Exclude Employees</Form.Label>
                      <div className="tags_wrapper">
                        <MultiSelect
                          disabled={activeDisabled}
                          options={employeeNameList}
                          value={employeeList}
                          onChange={setEmployeeList}
                          labelledBy={"name"}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className={[style.custom_form_ctrl_wrapper, style.active_cycle_wrapper].join(" ")}>
                    <div className={[style.cycle_calendars, "date_picker_time_wrapper", "p-0"].join(" ")}>
                      <Form.Group className="calendar_outer_wrapper">
                        <Form.Label>Cut Off Date</Form.Label>
                        <div className="calendar_wrapper">
                          <ReactDatePicker
                            disabled={activeDisabled}
                            className={["form-control"].join(" ")}
                            dateFormat="MMMM d, yyyy"
                            selected={cutOffDate}
                            onChange={(date) => setCutOffDate(date)}
                            placeholderText="Select Date"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className={[style.custom_form_ctrl_wrapper, "box-inner-pad"].join(" ")}>
                  <div className={[style.cycle_calendars, "date_picker_time_wrapper", "p-0"].join(" ")}>
                    <Form.Group className="calendar_outer_wrapper">
                      <Form.Label>
                        Cycle Assessment Start Date &amp; End Date<span className="text-danger">*</span>
                      </Form.Label>
                      <StartAndEndDatePicker
                        disabled={activateDisabled}
                        click={submitClick}
                        startDate={quarterStartDate}
                        endDate={quarterEndDate}
                        handleStartDate={(date) => setQuarterStartDate(date)}
                        handleEndDate={(date) => setQuarterEndDate(date)}
                      />
                    </Form.Group>
                    <Form.Group className="calendar_outer_wrapper">
                      <Form.Label>Self Assessment - Please Select Start Date &amp; End Date</Form.Label>
                      <StartAndEndDatePicker
                        // click={submitClick}
                        startDate={selfStartDate}
                        endDate={selfEndDate}
                        minEndDate={new Date(selfStartDate) < new Date() ? new Date() : null}
                        minDate={quarterStartDate}
                        handleStartDate={(date) => setSelfStartDate(date)}
                        handleEndDate={(date) => setSelfEndDate(date)}
                      />
                      {selectQuestion.SELF && selectQuestion.SELF.name ? (
                        <span className={style.file_info}>
                          {selectQuestion.SELF.name}
                          {!activeDisabled && (
                            <span
                              className={style.delete_item}
                              onClick={() => setSelectQuestion({ ...selectQuestion, SELF: "" })}
                            >
                              <ReactSVG src={IC_SEARCH_RESET} title="" alt="" />
                            </span>
                          )}
                        </span>
                      ) : (
                        <button
                          type="button"
                          disabled={activeDisabled}
                          onClick={() => hide("SELF")}
                          className={["btn-round btn btn-secondary", style.select_que_btn].join(" ")}
                        >
                          <strong> Select Question Template</strong>
                        </button>
                      )}
                    </Form.Group>
                    <Form.Group className="calendar_outer_wrapper">
                      <Form.Label>Reporting Manager Assessment - Please Select Start Date &amp; End Date</Form.Label>
                      <StartAndEndDatePicker
                        click={submitClick}
                        error={errors.reporteeDate}
                        minDate={quarterStartDate}
                        minEndDate={new Date(reportingStartDate) < new Date() ? new Date() : null}
                        startDate={reportingStartDate}
                        endDate={reportingEndDate}
                        handleStartDate={(date) => setReportingStartDate(date)}
                        handleEndDate={(date) => setReportingEndDate(date)}
                      />
                      {selectQuestion.REPORTING && selectQuestion.REPORTING.name ? (
                        <span className={style.file_info}>
                          {selectQuestion.REPORTING.name}
                          {!activeDisabled && (
                            <span
                              className={style.delete_item}
                              onClick={() => setSelectQuestion({ ...selectQuestion, REPORTING: "" })}
                            >
                              <ReactSVG src={IC_SEARCH_RESET} title="" alt="" />
                            </span>
                          )}
                        </span>
                      ) : (
                        <button
                          type="button"
                          disabled={activeDisabled}
                          onClick={() => hide("REPORTING")}
                          className={["btn-round btn btn-secondary", style.select_que_btn].join(" ")}
                        >
                          <strong> Select Question Template</strong>
                        </button>
                      )}
                      <div className="custom-checkbox custom-checkbox-sm mt-2">
                        <input
                          id="isRMTemplateVisibleToRVM"
                          type="checkbox"
                          name="mandatoryAssessment"
                          checked={!!isRMTemplateVisibleToRVM}
                          onChange={(e) => setIsRMTemplateVisibleToRVM(e.target.checked)}
                        />
                        <label htmlFor="isRMTemplateVisibleToRVM">
                          Provide visibility of RM Question Template to RVM
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className="calendar_outer_wrapper">
                      <Form.Label>Reviewing Manager Assessment - Please Select Start Date &amp; End Date</Form.Label>
                      <StartAndEndDatePicker
                        // click={submitClick}
                        startDate={reviewingStartDate}
                        endDate={reviewingEndDate}
                        minDate={quarterStartDate}
                        minEndDate={new Date(reviewingStartDate) < new Date() ? new Date() : null}
                        handleStartDate={(date) => setReviewingStartDate(date)}
                        handleEndDate={(date) => setReviewingEndDate(date)}
                      />

                      {selectQuestion.REVIEWING && selectQuestion.REVIEWING.name ? (
                        <span className={style.file_info}>
                          {selectQuestion.REVIEWING.name}
                          {!activeDisabled && (
                            <span
                              className={style.delete_item}
                              onClick={() => setSelectQuestion({ ...selectQuestion, REVIEWING: "" })}
                            >
                              <ReactSVG src={IC_SEARCH_RESET} title="" alt="" />
                            </span>
                          )}
                        </span>
                      ) : (
                        <button
                          type="button"
                          disabled={activeDisabled}
                          onClick={() => hide("REVIEWING")}
                          className={["btn-round btn btn-secondary", style.select_que_btn].join(" ")}
                        >
                          <strong> Select Question Template</strong>
                        </button>
                      )}
                    </Form.Group>

                      <Form.Group className="calendar_outer_wrapper">
                        <div className="custom-checkbox custom-checkbox-sm mt-2">
                          <input
                            id="isPermitRating"
                            type="checkbox"
                            name="mandatoryRating"
                            checked={isRatingPermit}
                            disabled={cycleData?.status !== "In Active"} 
                            onChange={(e) => isRatingPermit ? setIsRatingPermit(false) : setIsRatingPermit(true)}
                          />
                          <label htmlFor="isPermitRating">
                            Would you like to permit rating?
                          </label>
                        </div>
                      </Form.Group>

                    <div className={["radio-wrapper", style.aggregate_response].join(" ")}>
                      <Form.Group controlId="">
                        <Form.Label>
                          Show Aggregate response for the following
                          <InfoTooltipWithLi
                            heading="Selection Criteria"
                            listItem={[
                              "If you select Grade it follows following System: A+, A, B+, B, C",
                              "If you select Rating it follows following System: 5, 4, 3.2, 2.5, 1.2 etc",
                              "Selecting Both will enable Grading and Rating system for the cycle",
                            ]}
                          />
                        </Form.Label>
                        <ul className={style.form_option_list}>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded">
                              <input
                                disabled={activateDisabled}
                                id="question_33"
                                type="radio"
                                name="question_3"
                                value="GRADE"
                                checked={gradeOrRatingOrBoth === "GRADE"}
                                onChange={(e) => setGradeOrRatingOrBoth(e.target.value)}
                              />
                              <label htmlFor="question_33">Grade</label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded">
                              <input
                                disabled={activateDisabled}
                                id="question_34"
                                type="radio"
                                name="question_3"
                                value="RATING"
                                checked={gradeOrRatingOrBoth === "RATING"}
                                onChange={(e) => setGradeOrRatingOrBoth(e.target.value)}
                              />
                              <label htmlFor="question_34">Rating</label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded">
                              <input
                                disabled={activateDisabled}
                                id="question_35"
                                type="radio"
                                name="question_3"
                                value="BOTH"
                                checked={gradeOrRatingOrBoth === "BOTH"}
                                onChange={(e) => setGradeOrRatingOrBoth(e.target.value)}
                              />
                              <label htmlFor="question_35">Both</label>
                            </div>
                          </li>
                        </ul>
                      </Form.Group>
                    </div>
                    {moduleRights(config.hrModule.PERFORMANCE, config.subModule.PERFORMANCE_CYCLE)?.includes(
                      "UPLOAD"
                    ) && (
                        <div className={style.offline_upload_wrapper}>
                          <h6 className={style.offline_header}>Would you like to upload Offline Rating</h6>
                          <span className={["text-danger d-flex", style.upload_note].join(" ")}>
                            Note: Please upload a file only after completion of manager's assessment thoroughly.
                          </span>
                          <input
                            id="fileid"
                            type="file"
                            accept=".xlsx"
                            hidden
                            ref={(input) => (inputElement = input)}
                            onChange={() => {
                              setFile(inputElement.files[0]);
                              uploadFileAPI(inputElement.files[0]);
                            }}
                          />
                          <button
                            onClick={() => {
                              inputElement.click();
                            }}
                            type="button"
                            className={["btn-round btn btn-primary", style.upload_btn].join(" ")}
                            disabled={
                              (cycleData.status === "In Active" ? true : false) ||
                              (new Date(cycleData?.rvmAssessment?.assessmentEndOn) < new Date() ? false : true)
                            }
                          >
                            <strong>Upload</strong>
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className={[style.custom_form_ctrl_wrapper, "box-inner-pad", style.active_cycle_wrapper].join(" ")}
                >
                  <div
                    className={[
                      style.cycle_calendars,
                      "date_picker_time_wrapper",
                      "p-0",
                      ratingVisibleDate && "mb-0",
                    ].join(" ")}
                  >
                    <div className="radio-wrapper mb-2">
                      <Form.Group controlId="">
                        <Form.Label>Do you want to activate this cycle?</Form.Label>
                        <span className="text-danger d-flex">
                          Note: Cycle will be active for all participant once you select option &quot;Yes&quot;
                        </span>
                        <ul className={style.form_option_list}>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded">
                              <input
                                disabled={activateDisabled}
                                id={3}
                                type="radio"
                                name="question_2"
                                value="YES"
                                checked={isActive === "YES" || isActive === "EDIT_YES"}
                                onChange={(e) => setIsActive(e.target.value)}
                              />
                              <label htmlFor={3}>Yes</label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded">
                              <input
                                disabled={activateDisabled}
                                id={4}
                                type="radio"
                                name="question_2"
                                value="NO"
                                checked={isActive === "NO" || isActive === "EDIT_NO"}
                                onChange={(e) => {
                                  setErrors({ ...errors, selectedOption: false });
                                  setIsActive(e.target.value);
                                  setRatingVisibleDate();
                                  setAcknowledgementEndDate();
                                }}
                              />
                              <label htmlFor={4}>No</label>
                            </div>
                          </li>
                        </ul>
                      </Form.Group>
                    </div>

                    {(isActive === "YES" || isActive === "EDIT_YES") && (
                      <Form.Group className="calendar_outer_wrapper">
                        <Form.Label className="mb-0">Date when Rating will be Publish</Form.Label>
                        <span className="text-danger d-flex mb-2">
                          On selected Date, cycle will be visible to a User in Review tab and cycle status will change
                          to &apos;Completed&apos;.
                        </span>
                        <div className="calendar_wrapper">
                          <ReactDatePicker
                            className={["form-control"].join(" ")}
                            dateFormat="MMMM d, yyyy"
                            minDate={reviewingEndDate || reportingEndDate || selfEndDate || new Date()}
                            selected={ratingVisibleDate}
                            onChange={(date) => setRatingVisibleDate(date)}
                            placeholderText="Select Date"
                          />
                        </div>
                      </Form.Group>
                    )}
                  </div>
                  {ratingVisibleDate && (
                    <div className={[style.cycle_calendars, "date_picker_time_wrapper", "p-0"].join(" ")}>
                      <Form.Group className="calendar_outer_wrapper">
                        <Form.Label>
                          Reminder notification of acknowledgement till <span className="text-danger">*</span>
                          <InfoTooltip heading="Employee will receive a reminder notification everyday till the date selected in Date picker for submitting acknowledgement" />
                        </Form.Label>
                        <div className="calendar_wrapper">
                          <ReactDatePicker
                            className={["form-control"].join(" ")}
                            dateFormat="MMMM d, yyyy"
                            minDate={moment(ratingVisibleDate).add("days", 1)?._d}
                            selected={acknowledgementEndDate}
                            onChange={(date) => setAcknowledgementEndDate(date)}
                            placeholderText="Select Date"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  )}

                  <div className="d-flex">
                    <button
                      onClick={savePerformance}
                      type="button"
                      className={["btn-round btn btn-primary", style.cycle_btn_submit].join(" ")}
                    >
                      <strong>Save</strong>
                    </button>
                    <button
                      onClick={cancelHandler}
                      type="button"
                      className={["btn-round btn btn-secondary ml-3", style.cycle_btn_submit].join(" ")}
                    >
                      <strong>Cancel</strong>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <QuestionTemplateModal
        hide={hide}
        previewPathname={routePath.PREVIEW_TEMPLATE_ROUTE}
        show={isModal}
        isSelectModal={isSelectModal}
        list={templates}
        selectTemplate={selectTemplate}
      />
      <ComfirmUploadPopup
        show={confirmUploadPopup}
        fileName={file && file.name}
        setFile={setFile}
        uploadFileAPI={uploadFileAPI}
        errorList={errorList}
        submit={() => {
          setConfirmUploadPopup(false);
          uploadRatingFile(ratingData);
        }}
        errorFlag={errorFlag}
        hide={() => setConfirmUploadPopup(false)}
        isFromPerformance={true}
      />
    </div>
  );
}
