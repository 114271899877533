import React from "react";
import PropTypes from "prop-types";
import style from "./Badge.module.scss";

Badge.propTypes = {
  status: PropTypes.string,
};

Badge.defaultProps = {
  status: "pending",
};

function Badge({ status }) {
  let st = status;
  if (status === "Submit" || status === "submit") {
    st = "Submitted";
  } else if (status === "SAVED" || status === "saved") {
    st = "Saved";
  }
  return <span className={[style.badge, status?.toLowerCase()].join(" ")}>{st}</span>;
}

export default Badge;
