import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_SUBMIT_BTN } from "const/imgCost";
import { errorToast } from "util/general";

const CommentBox = (props) => {
  const { user, reviewer } = useSelector((state) => state.questions);
  const [isPrivate, togglePrivate] = useState(false);
  const [whoCanView, setWhoCanView] = useState(null);
  useEffect(() => {
    if (user && props.privateThread) {
      setWhoCanView([
        {
          employeeId: user.id,
          empName: user.name,
          displayPicture: user.image || user.employeeInitial,
          subGroupId: user.subGroupId,
          parentGroupId: user.parentGroupId,
        },
      ]);
    }
  }, [user, props.privateThread]);
  const onselectWhoCanView = async () => {
    let selectedReviewer = JSON.parse(document.getElementById("reviewer_select_" + props.id).value);
    await user;
    if (selectedReviewer) {
      setWhoCanView([
        {
          employeeId: selectedReviewer.employeeId,
          empName: selectedReviewer.empName,
          displayPicture: selectedReviewer.displayPicture || selectedReviewer.empInitial,
          subGroupId: selectedReviewer.subGroupId,
          parentGroupId: selectedReviewer.parentGroupId,
        },
      ]);
    }
  };

  return (
    <div className="view_comment_box_row">
      <div className={["comment_box_wrapper", "fg-comment-wrapper", props.style.idp_info_comment].join(" ")}>
        <div className="user_pics" style={{ backgroundColor: props.selectedColor }}>
          {props.employee ? (
            props.employee.image ? (
              <img src={props.employee.image} alt="" />
            ) : (
              props.employee.empInitial
            )
          ) : (
            ""
          )}
        </div>
        <div className="comment_area_wrapper">
          <Form.Group className="form_group_comment">
            <div className="textarea-wrapper">
              <Form.Control
                as="textarea"
                value={props.comment || ""}
                maxLength="200"
                onChange={props.changeHandle}
                // onBlur={props.managerCommentHandle}
                placeholder="Add a comment"
                className="manager_comments"
              />
            </div>
            <Button
              onClick={() => {
                if (props.privateThread) {
                  if (whoCanView.length < 1) {
                    errorToast("Unable to get user data from server");
                    return;
                  }
                  props.managerPrivateCommentHandle(props.comment, whoCanView, true);
                } else {
                  if (isPrivate) {
                    if (whoCanView.length < 1) {
                      errorToast("Please select option");
                      return;
                    }
                    props.managerPrivateCommentHandle(props.comment, whoCanView, false);
                  } else {
                    props.managerCommentHandle();
                  }
                  togglePrivate(false);
                  setWhoCanView([]);
                }
              }}
            >
              <ReactSVG className="svg" src={`${IC_SUBMIT_BTN}`} alt="info icon" />
            </Button>
          </Form.Group>
          {!props.privateThread && props.managerPrivateCommentHandle && (
            <div className="manage_comment_view mb-0">
              <div className="custom-checkbox custom-checkbox-theme-1 custom-checkbox-sm no_mar">
                <input
                  id={"checkBox" + props.id}
                  type="checkbox"
                  checked={isPrivate}
                  onChange={() => {
                    setWhoCanView([]);
                    togglePrivate(!isPrivate);
                  }}
                />
                <label htmlFor={"checkBox" + props.id}>Private comment </label>
              </div>
              <div className="manage_comment_visible_to">
                {isPrivate && reviewer && (
                  <Form.Group className="no_mar">
                    <Form.Control
                      id={"reviewer_select_" + props.id}
                      as="select"
                      defaultValue=""
                      size="md"
                      onChange={onselectWhoCanView}
                    >
                      <option key="rev-opt_none" value="" disabled>
                        Choose option
                      </option>
                      {reviewer &&
                        reviewer.managersList &&
                        reviewer.managersList
                          .filter((item) => item.employeeId !== props.employee.id)
                          .map((item) => (
                            <option key={"rev_opt_" + item.employeeId} value={JSON.stringify(item)}>
                              {item.empName}
                            </option>
                          ))}
                      <option
                        value={JSON.stringify({
                          employeeId: user.id,
                          empName: user.name,
                          displayPicture: user.image || user.employeeInitial,
                          parentGroupId: user.parentGroupId,
                          subGroupId: user.subGroupId,
                        })}
                      >
                        {user.name}
                      </option>
                    </Form.Control>
                  </Form.Group>
                )}
              </div>
              {isPrivate && whoCanView && whoCanView.length > 0 && (
                <span className="manage_comment_help">
                  only you and {whoCanView[0] && whoCanView[0].empName} can see the message
                </span>
              )}
            </div>
          )}
          {/* <div className="checkin-question-info mt-2"> {props.chars} Characters Left</div> */}
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
