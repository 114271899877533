import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "../CycleMemberProfile.module.scss";
import { Form } from "react-bootstrap";
import InputRange from "react-input-range";
import OptionalComment from "../QuestionAnswer/OptionalComment";

function SliderQuestion(props) {
  const {
    isSubmitted,
    item,
    uploadFile,
    ratingCommentHandlerTemplate,
    errors,
    ratingAnswerHandlerTemplate,
    downloadFile,
    deleteFile,
    name,
    isSurvey,
  } = props;

  let commentError = errors?.find((v) => v.qId === item.questionId && v.commentBoxError && v.isSubmit);
  let getRating,
    middleValue = null;
  if (item.options.findIndex((value) => value.isSelected) !== -1) {
    getRating = item.options.findIndex((value) => value.isSelected) + 1;
  }
  middleValue = Math.ceil(item.options.length / 2);

  const [rating, setRating] = useState(getRating || middleValue);

  const label = (id) => {
    let printValue = "";
    item.options.map((value) => {
      if (value.optionId === id) {
        printValue = value.option;
      }
      return value;
    });
    return printValue;
  };

  function clickHandler(value) {
    setRating(value);
    if (value !== rating) {
      ratingAnswerHandlerTemplate(value, item);
    }
  }
  return (
    <Form.Group controlId="">
      <div className={["two_column", style.workload_rating_guide].join(" ")}></div>
      <div className="custom-range-slider">
        <InputRange
          disabled={isSubmitted}
          maxValue={item && item.options && item.options.length}
          minValue={1}
          id={item.questionId + name}
          value={rating}
          formatLabel={(value) => label(value)}
          onChange={(value) => clickHandler(value)}
        />
        <ul className="rating-number">
          {item.options.map((value) => (
            <li key={value.optionId}>{value.optionId}</li>
          ))}
        </ul>
      </div>
      {item.optionalCommentBox && (
        <OptionalComment
          item={item}
          uploadFile={(e) => uploadFile(e, item.questionId)}
          commentError={commentError ? true : false}
          handleComment={(e) => ratingCommentHandlerTemplate(e, item)}
          isSubmitted={isSubmitted}
          downloadFile={downloadFile}
          commentPlaceHolder={
            item.commentResponse === "Required" ? "Add a new answer (Required)" : "Add a new answer (Optional)"
          }
          deleteFile={deleteFile}
          isSurvey={isSurvey}
        />
      )}
    </Form.Group>
  );
}

SliderQuestion.propTypes = {
  isSubmitted: PropTypes.bool,
  item: PropTypes.object,
  uploadFile: PropTypes.func,
  ratingCommentHandlerTemplate: PropTypes.func,
  ratingAnswerHandlerTemplate: PropTypes.func,
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
};

export default SliderQuestion;
