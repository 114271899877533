import React, { useState } from "react";
import { Collapse, Row, Col } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./GradeInfoCard.module.scss";
import { IC_INFO } from "const/imgCost";
import HideAndShow from "../HideAndShow/HideAndShow";

const GradeInfoCard = (props) => {
  const [hide, setHide] = useState(false);
  return (
    <div className={style.grade_info_card}>
      <Row>
        <Col lg={12}>
          <div className="card">
            <div className="card_info">
              <div className="card_title">
                <div className="card_title_text">{props.title}</div>
                <div className={[style.info_tooltip, "custom-tooltip", "custom-tooltip-top"].join(" ")}>
                  <div className="box-info">
                    <ReactSVG src={`${IC_INFO}`} title="" alt="info" />
                  </div>
                  <div className="info-attach-file">On hover, grade icon you can view information regarding grade</div>
                </div>
              </div>
              <HideAndShow hide={hide} setHide={setHide} />
            </div>
            <Collapse in={!hide}>
              <div className="card_details">
                <div className={["box-light-gradient", style.gradient_size].join(" ")}></div>
                <div className={style.grade_collection}>
                  <div className={style.grade_card}>
                    <div className={style.grade_a_plus}></div>
                    A+
                    <div className={style.grade_text}>Outstanding</div>
                  </div>
                  <div className={style.grade_card}>
                    <div className={style.grade_a}></div>A<div className={style.grade_text}>Exceeds Expectations</div>
                  </div>
                  <div className={style.grade_card}>
                    <div className={style.grade_b_plus}></div>
                    B+
                    <div className={style.grade_text}>Meets Expectation</div>
                  </div>
                  <div className={style.grade_card}>
                    <div className={style.grade_b}></div>B
                    <div className={style.grade_text}>Partially Meets Expectations and Needs Improvement in Some</div>
                  </div>
                  <div className={style.grade_card}>
                    <div className={style.grade_c}></div>C
                    <div className={style.grade_text}>Is not delivering at the role played</div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GradeInfoCard;
