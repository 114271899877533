export default {
  url: {
    DASHBOARD_BASE_URL: "https://nest-engage.infostretch.com",
    // DASHBOARD_BASE_URL: "http://10.12.42.138:3001",
    // DASHBOARD_BASE_URL: "http://192.168.0.63:3001",
  },
  dates: {
    primary: "dddd, MMMM YY",
    secondary: "MM-DD-YYYY",
    shortMonthNameAndDate: "MMM D",
    shortMonthNameDateAndDay: "MMM D, ddd",
    custom: "YYYY-MM-DD",
    tertiary: "ddd DD MMM YYYY h:mm a"
  },
  roles: {
    ROLE_USER: "ROLE_USER",
    ROLE_MANAGER: "ROLE_MANAGER",
    ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    ROLE_ADMIN: "ROLE_ADMIN",
    HR_ROLE: "HR",
    ADMIN_ROLE: "Admin",
    MMG_ROLE: "WMG",
  },
  mainModule: {
    CHECK_IN: "OKR Progress",
    ONE_ON_ONE: "1-on-1s",
    OBJECTIVES: "Objectives",
    PERFORMANCE: "Performance",
    UNIVERSITY: "University",
    SKILL_MATRIX: "Skill Matrix",
    HR: "HR",
    ADMIN: "Admin",
    SUPER_ADMIN: "Super-Admin",
    CAMPAIGN_DETAILS: "Campaign Details",
  },
  hrModule: {
    ANALYSIS: "Analysis",
    FEEDBACK: "Feedback",
    PERFORMANCE: "Performance",
    NOTIFICATION: "Notification",
    EMPLOYEE_OBJECTIVE: "Employee Objective",
    CAMPAIGN_DETAILS: "Campaign Details",
  },
  mmgModule: {
    UNIVERSITY: "University",
    SKILL_MATRIX: "Skill Matrix",
    ROLE_CHANGE: "Role Change",
    ENDORSEMENT: "Endorsement",
    MMG_FEEDBACK: "WMG Feedback",
  },
  adminModule: {
    ROLES: "Roles",
    USER_LIST: "User List",
    CHECKIN_CONFIG: "CheckIn Configuration",
  },
  subModule: {
    CURRENT_CHECKIN: "Current OKR Progress",
    MY_HISTORY: "My History",
    MY_TEAM: "My Team",
    MY_OBJECTIVES: "My Objectives",
    TEAMS_OBJECTIVES: "Team's Objectives",
    ALL_OBJECTIVES: "All Objectives",
    SELF_ASSESSMENT: "Self Assessment",
    REPORTING_ASSESSMENT: "Reporting Assessment",
    REVIEWING_ASSESSMENT: "Reviewing Assessment",
    REVIEWS: "Reviews",
    PMS_DASHBOARD: "PMS Dashboard",
    DEALLOCATION_FEEDBACK: "Deallocation Feedback",
    ONE_ON_ONE: "1-on-1s",
    GLOBAL_FEEDBACK: "Global Feedback",
    TEAMS_FEEDBACK: "Team's Feedback",
    MY_CERTIFICATIONS: "My Certifications",
    MEMBER_CERTIFICATIONS: "Member Certification",
    CERTIFICATION_OVERVIEW: "Certification Overview",
    MY_SKILLS: "My Skills",
    SKILLS_ENDORSEMENT: "Skills Endorsement",
    MY_TECHNICAL_ROLE_CHANGE: "My Technical Role Change",
    METRICS_DASHBOARD: "Metrics Dashboard",
    MMG_DEALLOCATION_FEEDBACK: "MMG Deallocation Feedback",
    MMG_ENDORSEMENT: "MMG Endorsement",
    SKILL_MASTER: "Skill Master",
    EMPLOYEE_SKILL_LISTING: "Employee Skill Listing",
    TECHNICAL_ROLE_CHANGE_REQUESTS: "Technical Role Change Requests",
    DEFINE_SKILLS: "Define Skills",
    ANALYSIS: "Analysis",
    PERFORMANCE_CYCLE: "Performance Cycle",
    HR_PMS_DASHBOARD: "Dashboard",
    EMPLOYEES_OBJECTIVES: "Employees Objectives",
    HR_DEALLOCATION_FEEDBACK: "Deallocation Feedback",
    ASSESSMENT: "Assessment",
    EMPLOYEE_FEEDBACK_HISTORY: "Global Feedback History",
    ROLES: "Roles",
    USER_LISTS: "User List",
    CAMPAIGN_LIST: "Campaign List",
    ADMIN: "Admin",
    CAMPAIGN: "Campaign",
    NOTIFICATION: "Notification",
    MODULE_ANALYSIS: "Module Analysis",
    PROJECT_DEALLOCATION: "Project Deallocation",
  },
  employeeIdSplit: "&",
  idSplit: "@$",
};
