import React from "react";
import { Col, Dropdown } from "react-bootstrap";
import { getPager } from "util/general";
import style from "./Pagination.module.scss";

function PaginationComponent({ pageSize, setPerPage, setPage, totalItem, currentPage }) {
  let pager = getPager(totalItem, currentPage, pageSize);

  const showingPage = "Showing " + (pager?.startIndex + 1) + "-" + (pager?.endIndex + 1) + " of " + totalItem;

  return (
    <Col className="pagination-col inside-box-pagination">
      <div className={["box box-flex-center", style.paginationBox].join(" ")}>
        <div className="mr-auto pagination-info">
          <span>{showingPage}</span>
          <Dropdown>
            <Dropdown.Toggle className="custom-btn" variant="secondary" id="dropdown-basic">
              {pageSize + " Results"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setPerPage(5)}>5 Results</Dropdown.Item>
              <Dropdown.Item onClick={() => setPerPage(10)}>10 Results</Dropdown.Item>
              <Dropdown.Item onClick={() => setPerPage(15)}>15 Results</Dropdown.Item>
              <Dropdown.Item onClick={() => setPerPage(20)}>20 Results</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {pager.totalItems > 0 && (
          <div className="ml-auto pagination-full-number">
            <span
              onClick={() => (pager.currentPage === 1 ? {} : setPage(1))}
              className={pager.currentPage === 1 ? "pagination-btn first-btn disabled" : "pagination-btn first-btn"}
            >
              First
            </span>
            <span
              onClick={() => (pager.currentPage === 1 ? {} : setPage(pager.currentPage - 1))}
              className={pager.currentPage === 1 ? "pagination-btn prev-btn disabled" : "pagination-btn prev-btn"}
            >
              Prev
            </span>
            <span className="pagination-no">
              {pager.pages.map((page, index) => (
                <span
                  key={index}
                  className={pager.currentPage === page ? "pagination-btn current-btn" : "pagination-btn"}
                  onClick={() => setPage(page)}
                >
                  {page}
                </span>
              ))}
            </span>
            <span
              onClick={() =>
                pager.currentPage === pager.totalPages
                  ? {}
                  : setPage(pager.currentPage === pager.totalPages ? pager.currentPage : pager.currentPage + 1)
              }
              className={
                pager.currentPage === pager.totalPages ? "pagination-btn next-btn disabled" : "pagination-btn next-btn"
              }
            >
              Next
            </span>
            <span
              onClick={() => (pager.currentPage === pager.totalPages ? {} : setPage(pager.totalPages))}
              className={
                pager.currentPage === pager.totalPages ? "pagination-btn last-btn disabled" : "pagination-btn last-btn"
              }
            >
              Last
            </span>
          </div>
        )}
      </div>
    </Col>
  );
}

export default PaginationComponent;
