import React from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";

const ConfirmationPopup = ({ show, hide, submit, headerText, subText, listItem, submitBtnText, instructions }) => {
  return (
    <>
      <Modal show={show} onHide={hide} animation={false} className="custom_modal_popup">
        <div className="custom_modal_container">
          <Row>
            <Col md={12}>
              <Modal.Body>
                <h1>{headerText}</h1>
                <p>{subText}</p>
                {instructions && (
                  <>
                    <h1>Instructions :</h1>
                    <div className="custom_modal_instruction">{instructions}</div>
                  </>
                )}
                {listItem && (
                  <ul>
                    {listItem.map((text, index) => {
                      return <li key={index + "LIST"}>{text}</li>;
                    })}
                  </ul>
                )}
              </Modal.Body>
            </Col>
            <Col className="custom_modal_button">
              <Button variant="primary" onClick={submit}>
                {submitBtnText}
              </Button>
              <Button variant="secondary" className="btn-border" onClick={hide}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationPopup;

ConfirmationPopup.defaultProps = {
  submitBtnText: "Submit",
};
