import { status } from "const/constValue";
import { INFORMATION_HUB_ENDPOINT } from "util/endpoints";
import { INFORMATION_HUB_ACTIONS } from "./actionTypes";
import http from "util/http";
import { errorToast, successToast } from "util/general";

export const getInformationHubData = () => (dispatch) => {
  dispatch({
    type: INFORMATION_HUB_ACTIONS.LOADING,
    payload: {
      isLoading: true,
    },
  });
  http
    .get(INFORMATION_HUB_ENDPOINT.GET_INFORMATION_HUB_DATA, {}, {},{})
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data && response.data.length > 0) {
          dispatch({
            type: INFORMATION_HUB_ACTIONS.GET_LIST_DATA,
            payload: {
              informationHubResponse: response.data,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: INFORMATION_HUB_ACTIONS.GET_LIST_DATA,
            payload: {
              informationHubResponse: [],
              isLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      errorToast("Issue getting information hub data")
    })
    .finally(() => {
      dispatch({
        type: INFORMATION_HUB_ACTIONS.GET_LIST_DATA,
        payload: {
          isLoading: false,
        },
      });
    });
};