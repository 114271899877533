import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";

function OneOnOneCommentDisplay(props) {
  const { name, date, comment, image } = props;
  return (
    <li>
      <div className={style.user_img}>{image.length === 2 ? image : <img src={image} alt="" />}</div>
      <div className={style.user_info}>
        <h5>{name}</h5>
        <span>{moment(date).fromNow()}</span>
        <p>{comment}</p>
      </div>
    </li>
  );
}

OneOnOneCommentDisplay.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  comment: PropTypes.string,
  image: PropTypes.string,
};

export default OneOnOneCommentDisplay;
