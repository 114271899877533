import React from "react";
import { Link } from "react-router-dom";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import PropTypes from "prop-types";

function CreateOneOnOneButton({ path }) {
  return (
    <div className="box">
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Current 1-on-1</h2>
        </div>
      </div>
      <div className="box-light-gradient"></div>
      <div className="box-inner-pad pt-0 pb-0">
        <div className={style.one_on_one_cm_table}>
          <div className="custom-table table-theme-1 pt40 position-relative">
            <div className={style.save_box_center}>
              <Link className="btn btn-primary" to={path}>
                Create New
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CreateOneOnOneButton.propTypes = {
  employeeId: PropTypes.string,
  isManager: PropTypes.bool,
};

export default CreateOneOnOneButton;
