import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

export default function InlineDropdown({
  labelLeft,
  labelRight,
  optionsLeft,
  valueLeft,
  handleChangeLeft,
  optionsRight,
  valueRight,
  handleChangeRight,
}) {
  return (
    <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
      <Form.Group controlId="">
        <Form.Label>{labelLeft}</Form.Label>
        <div className="tags_wrapper">
          <Select
            className="custom-default-dd-box"
            classNamePrefix="select"
            isSearchable={true}
            options={optionsLeft}
            value={valueLeft}
            onChange={handleChangeLeft}
          />
        </div>
      </Form.Group>
      <Form.Group controlId="">
        <Form.Label>{labelRight}</Form.Label>
        <div className="tags_wrapper">
          <Select
            className="custom-default-dd-box"
            classNamePrefix="select"
            isSearchable={true}
            options={optionsRight}
            value={valueRight}
            onChange={handleChangeRight}
          />
        </div>
      </Form.Group>
    </div>
  );
}
