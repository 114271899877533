import React from "react";
import PropTypes from "prop-types";
import style from "./KeyResultTable.module.scss";
import Select from "react-select";
import { IC_COMMENT } from "const/imgCost";
import { newRatingOptions, ratingOptions, oneToFourRatingOptions } from "const/options";
import { keyValueAchieved, keyValueAchievedTooltip, newRatingValue, ratingValue, oneToFourRatingValue } from "util/general";

function KeyResultRow({ handleKeyRating, point, edit, clickCommentModal, error, isDisabled, isHideRatingDropDown, isOldRatingOption, isOneToFourRatings }) {  
  return (
    <tr>
      <td>
        <span className={style.key_result_title}>{point.description}</span>
        {point.stretch && <span className={style.stretch_key_result}>This is the stretch key result</span>}
      </td>
      <td>{point.weightage}</td>
      <td>{point.percentage}</td>
      <td>
        <div className={style.key_achived_tooltip}>
          <span>{keyValueAchieved(point)}</span>
          <div className={style.key_achived_info}>{keyValueAchievedTooltip(point)}</div>
        </div>
      </td>
      {edit && (
        <td>
          {!isHideRatingDropDown && (
            <div className={style.react_select_wrapper}>
              <Select
                isDisabled={isDisabled}
                options={isOldRatingOption ? ratingOptions : isOneToFourRatings ? oneToFourRatingOptions : newRatingOptions}
                className={["custom-default-dd-box", error && "error-box"].join(" ")}
                classNamePrefix="select"
                placeholder="Select"
                onChange={(value) => handleKeyRating(value, point.keyId)}
                defaultValue={isOldRatingOption ? ratingValue(point.rating || point.ratingRemarks) : isOneToFourRatings ? oneToFourRatingValue(point.rating || point.ratingRemarks) : newRatingValue(point.rating || point.ratingRemarks)}
              />
            </div>
          )}
          <span className={style.comment_icon} onClick={clickCommentModal}>
            <img src={IC_COMMENT} alt="View Comment" />
          </span>
        </td>
      )}
    </tr>
  );
}

KeyResultRow.propTypes = {
  rating: PropTypes.object,
  handleKeyRating: PropTypes.func,
};

export default KeyResultRow;
