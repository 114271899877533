import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import style from "components/Objectives/MyObjectives/MyObjectives.module.scss";
import * as actions from "store/actions/index";
import { scrollToTop } from "util/general";
import UserProfile from "components/user_profile/user_profile";
import ObjectivesList from "components/Objectives/MyObjectives/ObjectivesList";
import Spinner from "shared/Spinner/Spinner";
import withLink from "hoc/withLink/withLink";
import { useQuery } from "util/utils";
import routePath from "const/routePath";

export default function ReporteeAllObjectives() {
  const dispatch = useDispatch();
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const user = useSelector((state) => state.myTeam.user);
  const { isLoadingObjective, employeeObjectives } = useSelector((state) => state.checkIn);
  const UserProfileWithLink = withLink(UserProfile);
  useEffect(() => {
    scrollToTop(500);
    if (employeeIds) {
      dispatch(actions.GetUserObjectives(employeeIds, "TAB_HIGHLIGHTS"));
      dispatch(actions.GetUser(employeeIds));
    }
  }, [dispatch]);

  if (isLoadingObjective) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="gap30"></div>
      <Container>
        <div className={style.myobjectives_wrapper}>
          <Row>
            <Col md={12} className="col-12">
              <h1 className="page-title pb-0">Objectives</h1>
              <span className="st-breadcrumb">
                <Link to={routePath.TEAMS_OBJECTIVE}>Objectives</Link>
                <span>{user && user.displayName}</span>
              </span>
            </Col>
            <Col lg={12}>{user && <UserProfileWithLink user={user} />}</Col>
            <Col className="col-12">
              <div
                className={["custom-tabs custom-tabs-full-width tab-content-dashboard", style.allobjectives_tabs].join(
                  " "
                )}
              >
                <ObjectivesList objectives={employeeObjectives} isPage="MANAGER" employeeId={employeeIds.employeeId} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
