import React, { useState } from "react";
import InputRange from "react-input-range";
import { Form } from "react-bootstrap";
import style from "./PreviewTemplate.module.scss";
import UploadFile from "../../Performance/CycleMemberProfile/QuestionAnswer/UploadFile";

export default function SliderType(props) {
  let options = props.question.options;
  const [rating, setRating] = useState(2);

  function clickHandler(value) {
    setRating(value);
  }

  const label = (value) => {
    return options.filter((obj, i) => value === i + 1)[0].option;
  };
  let responseText = "";
  if (props.question.respondToAll) {
    responseText = "Everyone can respond to this question";
  } else if (props.question.respondToManager && props.question.respondToEmployee) {
    responseText = "Only Managers and Employees can respond to this question";
  } else if (props.question.respondToManager) {
    responseText = "Only Managers can respond to this question";
  } else if (props.question.respondToEmployee) {
    responseText = "Only Employees can respond to this question";
  }
  return (
    <div className={[style.question_list, "box-inner-pad", style.disabledClick].join(" ")}>
      <span className={style.question_sr_no}>Q{props.no + 1}</span>
      <div className={style.que_ans_wrapper}>
        <div className={style.question_name}>
          <h4>
            {props.question.questionName}
            {props.question.isRequired && <span className="text-danger">*</span>}
          </h4>
        </div>
        {props.question.descriptionFlag && (
          <p className={[style.answer_description, "mt-3"].join(" ")}>{props.question.description}</p>
        )}
        <p className={[style.answer_description, "mt-3"].join(" ")}>{responseText}</p>
        <div className={style.ans_wrapper}>
          <div className="ratingBarBox">
            <Form.Group controlId="">
              <div className={["two_column", style.workload_rating_guide].join(" ")}>
                <span>{options[0].option}</span>
                <span>{options[options.length - 1].option}</span>
              </div>
              <div className="custom-range-slider">
                <InputRange
                  maxValue={options.length}
                  minValue={1}
                  value={+rating}
                  formatLabel={(value) => label(value)}
                  onChange={(value) => {
                    clickHandler(value, props.id);
                  }}
                />
                <ul className="rating-number">
                  {options.map((obj, i) => {
                    return <li key={i}>{i + 1}</li>;
                  })}
                </ul>
              </div>
              {props.question.optionalCommentBox && (
                <div className="textarea-wrapper mt-3">
                  <UploadFile questionId="QUESTION_ID" uploadFile={() => {}} />
                  <Form.Control
                    as="textarea"
                    maxLength="5000"
                    placeholder={`Add a new answer (${props.question.commentResponse})`}
                  />
                </div>
              )}
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
