import React from "react";
import { Modal, Button } from "react-bootstrap";
import style from "./InformationPopup.module.scss";

const InformationPopup = (props) => {
    return (
        <>
            <Modal
                size="lg"
                show={props.openPopup}
                onHide={() => props.setOpenPopup(true)}
                animation={false}
                className={style.assigneepopup_wrapper}
            >
                <div className={style.modal_header}>
                    <div className="row">
                        <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                            <h1>OKR Template Details</h1>
                        </div>
                    </div>
                </div>
                <Modal.Body className={style.mBody}>
                    <div className="box-light-gradient"></div>
                    <div className={style.feedback_wrapper}>
                        <div className="pb-3">
                            <span className="pr-2"><b className={style.label}>Objective Type :</b></span>
                            <span>{props?.item?.objectiveType}</span>
                        </div>
                        <div>
                            <span className="pb-3">
                                <b className={style.label}>Objective Description</b>
                            </span>
                            <ul>
                                <li>
                                    <pre>
                                        {props?.item?.description}
                                    </pre>
                                </li>
                            </ul>
                        </div>
                        {props?.item?.managerListDTO && <div>
                            <span>
                                <b className={style.label}>Eligible Maneger's List</b>
                            </span>
                            <ul className={style.managerUL}>
                                {props?.item?.managerListDTO.map((list) => (<li>
                                    <b>{list.managerName}</b>

                                    <span className={style.empCode}>Emp Code : {list.managerId}</span>
                                </li>))}
                            </ul>
                        </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="custom_modal_button justify-content-start mt-2">
                    <Button
                        type="button"
                        variant={"secondary"}
                        className={["btn-round", style.btn_cancel].join(" ")}
                        onClick={() => {
                            props.setOpenPopup(false);
                        }}
                    >
                        <strong>Close</strong>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default InformationPopup;
