import routePath from "const/routePath";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { IC_TODO } from "../../const/imgCost";
import HideAndShow from "../HideAndShow/HideAndShow";
import ClickableNameList from "./ClickableNameList";
import style from "./PendingApprovals.module.scss";

function TodoLists({
  pendingRpmAssessment,
  pendingSelfAssessment,
  pendingRvmAssessment,
  pendingDeallocationFeeedBack,
  pendingWeeklyCheckin,
  pendingObjectiveApproval,
  displayAll,
  handleEmployeeClick,
  totalTodoCount,
}) {
  const [hide, setHide] = useState(false);
  let noData = false;
  if (
    pendingSelfAssessment ||
    pendingWeeklyCheckin?.label ||
    pendingRpmAssessment ||
    pendingRvmAssessment ||
    pendingDeallocationFeeedBack ||
    pendingObjectiveApproval
  )
    noData = false;
  else noData = true;
  return (
    <div className={["card", style.to_do_wrapper].join(" ")}>
      <div className="card_info">
        <div className="card_title">
          <div className="d-flex">
            <ReactSVG src={IC_TODO} className={style.todo_svg} />
            <div className="card_title_text">
              To do’s <span>({totalTodoCount})</span>
            </div>
          </div>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>
      {!hide && (
        <div>
          <div className="box-light-gradient"></div>
          {pendingSelfAssessment && (
            <div className={style.to_do_card_wrapper}>
              <div className={style.to_do_heading}>
                {pendingSelfAssessment.label}
              </div>
              <div className={style.highlight_to_do_subheading}>
                Self Assessment for {pendingSelfAssessment.period} is Pending.{" "}
                <Link to={routePath.SELF_ASSESSMENT}>
                  Go to Self Assessment
                </Link>
              </div>
            </div>
          )}
          {pendingWeeklyCheckin?.label && (
            <div className={style.to_do_card_wrapper}>
              <div className={style.to_do_heading}>
                {pendingWeeklyCheckin.label}
              </div>
              <div className={style.highlight_to_do_subheading}>
                Submit your OKR Progress activity and objective progress.
                <Link to={routePath.CURRENT_CHECKIN}> Go to OKR Progress</Link>
              </div>
            </div>
          )}
          {pendingRpmAssessment && (
            <div className={style.to_do_card_wrapper}>
              <div
                className={style.to_do_heading}
              >{`${pendingRpmAssessment.label} (${pendingRpmAssessment.count})`}</div>
              <div className={style.highlight_to_do_subheading}>
                Assessment is pending for{" "}
                <ClickableNameList
                  data={pendingRpmAssessment.rpmAssessment.map(
                    (v) => v.employeeData
                  )}
                  displayAll={() => displayAll("REPORTING")}
                  handleEmployeeClick={(employeeData) =>
                    handleEmployeeClick(
                      employeeData,
                      "REPORTING",
                      pendingRpmAssessment.cycleId
                    )
                  }
                />
              </div>
            </div>
          )}
          {pendingRvmAssessment && (
            <div className={style.to_do_card_wrapper}>
              <div
                className={style.to_do_heading}
              >{`${pendingRvmAssessment.label} (${pendingRvmAssessment.count})`}</div>
              <div className={style.highlight_to_do_subheading}>
                Assessment is pending for{" "}
                <ClickableNameList
                  data={pendingRvmAssessment.rvmAssessment.map(
                    (v) => v.employeeData
                  )}
                  displayAll={() => displayAll("REVIEWING")}
                  handleEmployeeClick={(employeeData) =>
                    handleEmployeeClick(
                      employeeData,
                      "REVIEWING",
                      pendingRvmAssessment.cycleId
                    )
                  }
                />
              </div>
            </div>
          )}
          {pendingDeallocationFeeedBack && (
            <div className={style.to_do_card_wrapper}>
              <div
                className={style.to_do_heading}
              >{`${pendingDeallocationFeeedBack.label} (${pendingDeallocationFeeedBack.count})`}</div>
              <div className={style.highlight_to_do_subheading}>
                Deallocation Feedback is pending for{" "}
                <ClickableNameList
                  data={pendingDeallocationFeeedBack.projectDeallocation.map(
                    (v) => {
                      return {
                        ...v.employee,
                        id: v.projectDeAllocationId,
                      };
                    }
                  )}
                  displayAll={() => displayAll("DEALLOCATION")}
                  handleEmployeeClick={(employeeData, id) =>
                    handleEmployeeClick(employeeData, "DEALLOCATION", id)
                  }
                />
              </div>
            </div>
          )}
          {pendingObjectiveApproval && (
            <div className={style.to_do_card_wrapper}>
              <div
                className={style.to_do_heading}
              >{`${pendingObjectiveApproval.label} (${pendingObjectiveApproval.count})`}</div>
              <div className={style.highlight_to_do_subheading}>
                Approve the objectives completed by{" "}
                <ClickableNameList
                  data={pendingObjectiveApproval.employeeObjectives.map((v) => {
                    return {
                      ...v.employeeData,
                      id: v.userObjectiveId,
                    };
                  })}
                  displayAll={() => displayAll("OBJECTIVE_APPROVAL")}
                  handleEmployeeClick={(employeeData, id) =>
                    handleEmployeeClick(employeeData, "OBJECTIVE_APPROVAL", id)
                  }
                />
              </div>
            </div>
          )}
          {noData && (
            <div className="box-light-gradient h38">
              <p className="no-sent-receive-wrapper">No data found</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TodoLists;
