import React from "react";
import MultiSelect from "react-multi-select-component";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { IC_SEARCH_RESET } from "const/imgCost";
import { monthOptions4 } from "const/options";
import style from "./CreateCampaign.module.scss";

function LocationFilter({
  locationList,
  deleteRow,
  campaignFilter,
  handleLocationFilterChange,
  isDeleteBtnDisplay,
  disabled,
  isFilterDropdownDisabled,
  handleSaveFilter,
  isSaveFilterDisabled,
  isStartDateDisabled,
}) {
  return (
    <div className={style.filter_top_bar}>
      <div className={style.filter_box}>
        <span className={style.dd_title}>
          Location<span className="text-danger">*</span>
        </span>
        <div className="tags_wrapper">
          <MultiSelect
            disabled={disabled}
            options={locationList || []}
            name="location"
            value={campaignFilter?.location}
            onChange={(data) => handleLocationFilterChange("location", data)}
          />
        </div>
      </div>
      <div className={style.filter_box}>
        <span className={style.dd_title}>Start Date</span>
        <div className="date_picker_time_wrapper">
          <div className="calendar_wrapper form-group">
            <ReactDatePicker
              disabled={isStartDateDisabled}
              className="form-control"
              dateFormat="MMMM d, yyyy"
              placeholderText="Select Start Date"
              selected={campaignFilter?.startDate}
              minDate={new Date()}
              // maxDate={endDate}
              onChange={(date) => handleLocationFilterChange("startDate", date)}
            />
          </div>
        </div>
      </div>
      <div className={style.filter_box}>
        <span className={style.dd_title}>End Date</span>
        <div className="date_picker_time_wrapper">
          <div className="calendar_wrapper form-group">
            <ReactDatePicker
              disabled={campaignFilter?.location ? false : true}
              className="form-control"
              dateFormat="MMMM d, yyyy"
              placeholderText="Select End Date"
              selected={campaignFilter?.endDate}
              minDate={campaignFilter?.startDate}
              // maxDate={endDate}
              onChange={(date) => handleLocationFilterChange("endDate", date)}
            />
          </div>
        </div>
      </div>
      <div className={style.filter_box}>
        <span className={style.dd_title}>Group</span>
        <div className="tags_wrapper">
          <MultiSelect
            disabled={isFilterDropdownDisabled}
            options={campaignFilter?.departmentList || []}
            name="department"
            value={campaignFilter.department}
            onChange={(data) => handleLocationFilterChange("department", data)}
          />
        </div>
      </div>
      <div className={style.filter_box}>
        <span className={style.dd_title}>Member Type</span>
        <div className="tags_wrapper">
          <MultiSelect
            disabled={isFilterDropdownDisabled}
            options={campaignFilter?.employeeGroupList || []}
            value={campaignFilter?.employeeType}
            onChange={(data) => handleLocationFilterChange("employeeType", data)}
          />
        </div>
      </div>
      <div className={style.filter_box}>
        <span className={style.dd_title}>Designation</span>
        <div className="tags_wrapper">
          <MultiSelect
            disabled={isFilterDropdownDisabled}
            className="multi-select"
            options={campaignFilter?.designationList || []}
            value={campaignFilter?.designation}
            onChange={(data) => handleLocationFilterChange("designation", data)}
          />
        </div>
      </div>
      <div className={style.filter_box}>
        <span className={style.dd_title}>Employee</span>
        <div className="tags_wrapper">
          <MultiSelect
            disabled={isFilterDropdownDisabled}
            options={campaignFilter?.employeesListForLocation || []}
            value={campaignFilter?.employeeIds}
            onChange={(data) => handleLocationFilterChange("employeeIds", data)}
          />
        </div>
      </div>
      <div className={style.filter_box}>
        <span className={style.dd_title}>Tenure</span>
        <div className="tags_wrapper">
          <Select
            isDisabled={isFilterDropdownDisabled}
            options={monthOptions4}
            className="custom-default-dd-box"
            classNamePrefix="select"
            placeholder="Select..."
            value={campaignFilter?.cutOffMonth || ""}
            onChange={(e) => {
              handleLocationFilterChange("cutOffMonth", e);
            }}
          />
        </div>
      </div>
      <div className={[style.save_filter].join(" ")}>
        <span className={["text_link", isSaveFilterDisabled ? "disabled" : ""].join(" ")} onClick={handleSaveFilter}>
          Save
        </span>
      </div>

      {isDeleteBtnDisplay && (
        <span className={style.delete}>
          <img src={IC_SEARCH_RESET} alt="delete" onClick={deleteRow} />
        </span>
      )}
    </div>
  );
}

export default LocationFilter;
