import React from "react"
import DatePicker from "react-datepicker";
import PropTypes from "prop-types"

export default function DurationDateSelector(props){
  const { handleStartDate, handleEndDate, duration, startDate, endDate, minDate } = props
  return (
    <div className="box-inner-pad">
      <div className="date_picker_time_wrapper p-0">
          <h5>Please Select Start Date &amp; End Date</h5>
          <div className="cl_t_wrapper">
              <div className="calendar_wrapper form-group">
                  <DatePicker
                      className="form-control"
                      placeholderText="Select Start Date"
                      selected={startDate}
                      maxDate={endDate}
                      onChange={handleStartDate}
                      dateFormat="MMMM d, yyyy" />
              </div>
              <div className="calendar_wrapper form-group">
                  <DatePicker
                      placeholderText="Select End Date"
                      className="form-control"
                      selected={endDate}
                      minDate={minDate}
                      onChange={handleEndDate}
                      dateFormat="MMMM d, yyyy" />
              </div>
              <div className={["form-group", "duration_txt"].join(" ")}>
                  Duration is {duration ? duration : 0} Days
              </div>
          </div>
      </div>
  </div>        
  )
}

DurationDateSelector.propTypes = {
  handleStartDate : PropTypes.func, 
  handleEndDate : PropTypes.func, 
  duration : PropTypes.number,
  startDate : PropTypes.instanceOf(Date), 
  endDate : PropTypes.instanceOf(Date)
}