import React, { useState, useEffect } from "react";
import { Col, Table, Form } from "react-bootstrap";
import style from "./Feedbacks.module.scss";
import Filter from "./Filter";
import Pagination from "../Pagination/Pagination";
import { filterArray } from "../../util/general";
import { useSelector, useDispatch } from "react-redux";
import NoDataFound from "../NodataFound/NoDataFound";
import moment from "moment";
import { b64toBlob } from "../../util/general";
import { DOWNLOAD_FEEDBACK, UPDATE_FEEDBACK } from "../../util/endpoints";
import http from "../../util/http";
import { toast } from "react-toastify";
import SlideToast from "../Toast/Toast";
import * as actions from "../../store/actions/index";
import { noData } from "const/constValue";

export default function Feedbacks({ feedbackResponse }) {
  const [pageOfItems, setPageOfItems] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const pageSize = useSelector((state) => state.myTeam.pageSize);
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  const dispatch = useDispatch();
  const { password } = useSelector((state) => state.feedback);

  const statusHandler = (feedbackId, event) => {
    const params = {
      id: feedbackId,
      status: event.target.value,
    };
    http
      .put(UPDATE_FEEDBACK, params)
      .then((response) => response.data.data)
      .then(() => {
        setStatusFilter("");
        if (!toast.isActive("STATUS_UPDATE")) {
          toast.success(<SlideToast title="Awesome!" message="Status has been updated." error={false} />, {
            toastId: "STATUS_UPDATE",
          });
          if (password) dispatch(actions.fetchFeedbacks(password));
        }
      })
      .catch(() => {});
  };

  const downloadFeedback = (feedbackId) => {
    http
      .get(DOWNLOAD_FEEDBACK, { id: feedbackId })
      .then((response) => response.data.data)
      .then((response) => {
        if (response.status !== "FAILURE") {
          const blob = b64toBlob(response.binary.data, response.file.contentType);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", response.file.name);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    setFeedbackData(feedbackResponse);
    setStatusFilter("");
  }, [feedbackResponse]);

  const handleStatusChange = (event) => {
    let filteredData = null;
    const filters = {
      status: event.target.value,
    };
    filteredData = filterArray(feedbackResponse, filters);
    setStatusFilter(event.target.value);
    setFilteredData(filteredData);
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      status: statusFilter ? statusFilter : "",
    };
    filteredData = filterArray(feedbackResponse, filters);

    setFilteredData(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
  };

  useEffect(() => {
    filteredData.length > 0
      ? setFeedbackData(filteredData)
      : statusFilter
      ? setFeedbackData(filteredData)
      : setFeedbackData(feedbackResponse);
  }, [filteredData]);

  const filter = {
    value: ["", "CLOSED", "OPEN", "DEFFERED"],
    name: ["All", "Closed", "Open", "Differed"],
  };

  return (
    <>
      <div className="outer-table-wrapper">
        {feedbackResponse && (
          <Filter
            statusFilter={statusFilter}
            filter={filter}
            style={style}
            feedbackData={feedbackResponse}
            onRadioChange={handleStatusChange}
            onReset={resetData}
          />
        )}

        <div className="">
          <div className="box-light-gradient"></div>
          <Col xs={12}>
            <div className="custom-table table-theme-1 pt40">
              {pageOfItems && pageOfItems.length > 0 ? (
                <Table hover responsive className={style.tab_table_responsive}>
                  <thead>
                    <tr>
                      <th width="50px">Sr. No</th>
                      <th width="150px">Category</th>
                      <th width="550px">Description</th>
                      <th width="200px">File</th>
                      <th width="200px">Submitted By</th>
                      <th width="140px">Submit Date</th>
                      <th width="180px">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItems &&
                      pageOfItems.length > 0 &&
                      pageOfItems.map((item) => (
                        <tr key={item.feedbackId}>
                          <td>{item.paginationId}</td>
                          <td>{item.categoryName}</td>
                          <td className="text-break">{item.description}</td>
                          <td className="text-break">
                            <span
                              onClick={() => {
                                downloadFeedback(item.feedbackId);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {item.file && item.file.name}
                            </span>
                          </td>
                          <td className="text-break">{item.postedBy.email}</td>
                          <td>{item.createdDate ? moment(item.createdDate).format("MMM DD, YYYY") : "- -"}</td>
                          <td>
                            <Form.Control as="select" onChange={(event) => statusHandler(item.feedbackId, event)}>
                              <option value="CLOSED" selected={item.status === "CLOSED" && true}>
                                CLOSED
                              </option>
                              <option value="OPEN" selected={item.status === "OPEN" && true}>
                                OPEN
                              </option>
                              <option value="DEFFERED" selected={item.status === "DEFFERED" && true}>
                                DEFERRED
                              </option>
                            </Form.Control>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              )}
            </div>
          </Col>
        </div>
      </div>

      {password && (
        <Pagination pageSize={pageSize} items={feedbackData} resetData={resetData} onChangePage={onChangePage} />
      )}
    </>
  );
}
