import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Collapse, Button, Form } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import style from "./TabMyEndrosement.module.scss";
import * as actions from "store/actions/index";
import {
  IMG_ENDORSEMENT_1,
  IMG_PM,
  IMG_SME,
  IMG_UNI,
  IC_COLLAPSE,
  IC_EXPAND,
  IC_INPUT_SEARCH,
  IC_INFO,
} from "const/imgCost";
import { errorToast, filterArray, successToast } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";
import AuthContext from "context/authContext";
import MyEndorsementRequest from "./MyEndorsementRequest/MyEndorsementRequest";
import NoDataFound from "../../../NodataFound/NoDataFound";
import { competencyLevel, noData } from "const/constValue";

export default function TabMyEndrosement() {
  const [employees, setEmployeeList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [employee, selectEmployee] = useState(null);
  const [skill, selectSkill] = useState(null);
  const dispatch = useDispatch();
  const context = useContext(AuthContext);
  const employeeIds = {
    employeeId: context.state.user.employeeId,
    parentGroupId: context.state.user.parentGroupId,
    subGroupId: context.state.user.subGroupId,
  };
  const [disable, setDisable] = useState(true);
  const {
    isLoading,
    endorsementSkill,
    employeeList,
    endorseReqErrorResponse,
    endorseReqResponse,
    getMyEndorsementList,
    size,
  } = useSelector((state) => state.skillMatrix);

  const [pageOfItems, setPageOfItems] = useState([]);
  const [typeArr, setFilterType] = useState(["ALL"]);
  const [filteredData, setFilteredData] = useState([]);
  const [myEndorseData, setMyEndorseData] = useState([]);
  const [searchText, setSearchText] = useState("");
  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }
  const [open, setOpen] = useState({
    flag: false,
    id: null,
  });
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useEffect(() => {
    if (getMyEndorsementList && getMyEndorsementList.length > 0) {
      setMyEndorseData(getMyEndorsementList);
    }
  }, [getMyEndorsementList]);

  useEffect(() => {
    if (skill && employee) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [skill, employee]);

  useEffect(() => {
    if (endorsementSkill && endorsementSkill.length > 0) {
      const array = [];
      endorsementSkill.forEach((sk) => {
        const obj = {};
        obj["value"] = sk.skillIdNameId;
        obj["label"] = sk.skillIdName;
        array.push(obj);
      });
      setSkills(array);
    }

    if (employeeList && employeeList.length > 0) {
      const array = [];
      employeeList.forEach((emp) => {
        const obj = {};
        obj["value"] = emp.employeeId;
        obj["label"] = emp.displayName;
        obj["parentGroupId"] = emp.parentGroupId;
        obj["subGroupId"] = emp.subGroupId;
        array.push(obj);
      });
      setEmployeeList(array);
    }
  }, [endorsementSkill, employeeList]);

  useEffect(() => {
    const endorsementSkill = () => dispatch(actions.getEndorsementSkill(employeeIds));
    const getMyEndorsement = () => dispatch(actions.getMyEndorsement);
    const getEmployeeList = () => dispatch(actions.employeeList);
    endorsementSkill();
    getMyEndorsement();
    getEmployeeList();
  }, [dispatch]);

  function toggleItem(elem) {
    for (var i = 0; i < elem.length; i++) {
      elem[i].addEventListener("click", function () {
        var current = this;
        for (var i = 0; i < elem.length; i++) {
          if (current !== elem[i]) {
            elem[i].parentNode.parentNode.parentNode.parentNode.classList.remove("active");
          } else if (current.parentNode.parentNode.parentNode.parentNode.classList.contains("active") === true) {
            current.parentNode.parentNode.parentNode.parentNode.classList.remove("active");
          } else {
            current.parentNode.parentNode.parentNode.parentNode.classList.add("active");
          }
        }
      });
    }
  }

  useEffect(() => {
    toggleItem(document.querySelectorAll(".skill_handle1"));
  });

  const setSkill = (select) => {
    if (select) {
      selectSkill(select);
    } else {
      selectSkill(null);
    }
  };

  const setEmployee = (select) => {
    if (select) {
      selectEmployee(select);
    } else {
      selectEmployee(null);
    }
  };

  useEffect(() => {
    if (endorseReqResponse && !endorseReqResponse.error) {
      successToast(endorseReqResponse.message);
      selectSkill(null);
      selectEmployee(null);
      setFilterType(["ALL"]);
    }
    if (endorseReqErrorResponse?.error) errorToast(endorseReqErrorResponse.message);
  }, [endorseReqErrorResponse, endorseReqResponse]);

  const submit = () => {
    const data = {
      endorsedById: employee.value,
      endorsedByName: employee.label,
      endorsedBySubGroupId: employee.subGroupId,
      endorsedByParentGroupId: employee.parentGroupId,
      endorsedSkill: {
        skillIdName: skill.label,
        skillIdNameId: skill.value,
      },
    };
    dispatch(actions.endorseReq(data));
  };

  useEffect(() => {
    filteredData.length > 0
      ? setMyEndorseData(filteredData)
      : // eslint-disable-next-line no-constant-condition
      null
      ? setMyEndorseData(filteredData)
      : setMyEndorseData(getMyEndorsementList);
  }, [filteredData, getMyEndorsementList]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (myEndorseData && myEndorseData.length > 0) {
      filteredData = filterArray(myEndorseData, filters);
      setFilteredData(filteredData);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
    if (!e.target.value) {
      setFilterType(["ALL"]);
    }
  };

  const reset = () => {
    setSearchText("");
  };

  useEffect(() => {
    let array = [];
    if (searchText.length > 0) {
      array = getMyEndorsementList.filter((data) =>
        data.endorsedSkill.skillIdName.toLowerCase().includes(searchText.trim().toLowerCase())
      );
      setMyEndorseData(array);
    } else {
      setMyEndorseData(getMyEndorsementList);
    }
  }, [getMyEndorsementList, searchText, searchText.length, typeArr]);

  const filterByEndorseType = (e) => {
    setOpen({
      flag: false,
      id: null,
    });
    const value = e.target.value;
    setFilterType((prevState) => prevState.filter((type) => type !== "ALL"));
    if (e.target.checked) {
      if (value === "ALL") {
        setFilterType(["ALL"]);
      } else {
        setFilterType((prevState) => [...prevState, value]);
      }
    } else {
      setFilterType((prevState) => prevState.filter((type) => type !== value));
    }
  };

  useEffect(() => {
    let value;
    let endorsementList = JSON.parse(JSON.stringify(getMyEndorsementList));
    let array = [];
    if (endorsementList) {
      typeArr.map((type) => {
        value = type === "UNIVERSITY" ? 1 : type === "PEER" ? 4 : type === "MANAGER" ? 3 : type === "SME" ? 2 : 0;
        if (value === 0) {
          array = endorsementList;
        } else {
          endorsementList.map((data) => {
            if (data.endorsementCount[value] > 0) {
              array.push(data);
            }
            return data;
          });
        }
        return type;
      });
    }
    array = array.filter((data) =>
      data.endorsedSkill.skillIdName.toLowerCase().includes(searchText.trim().toLowerCase())
    );
    // eslint-disable-next-line no-undef
    setMyEndorseData([...new Set(array)]);
  }, [getMyEndorsementList, searchText, typeArr]);

  useEffect(() => {
    if (myEndorseData && myEndorseData.length === 0) {
      setPageOfItems([]);
    }
  }, [myEndorseData]);

  return (
    <>
      <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
        {isLoading && <Spinner />}
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Request Endorsement</h2>
            <h3>Request your colleague to Endorse you</h3>
          </div>
        </div>
        <div className="box-inner-pad">
          <Row>
            <Col md={6} lg={4}>
              <span className={style.dd_title}>Skill</span>
              <div className="card_action">
                <Select
                  options={skills}
                  className="custom-default-dd-box"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable={true}
                  placeholder="Select Skill"
                  onChange={(select) => setSkill(select)}
                  value={skill}
                />
              </div>
            </Col>
            <Col md={6} lg={4}>
              <span className={style.dd_title}>Endorse By</span>
              <div className="card_action">
                <Select
                  options={employees}
                  className="custom-default-dd-box"
                  classNamePrefix="select"
                  isSearchable={true}
                  placeholder="Select Endorse by"
                  isClearable={true}
                  onChange={(select) => setEmployee(select)}
                  value={employee}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="box-inner-pad">
          <button
            type="button"
            className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
            disabled={disable}
            onClick={submit}
          >
            <strong>Request</strong>
          </button>
        </div>
      </div>
      <MyEndorsementRequest />

      <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>My Endorsements</h2>
            <h3>List of Skills Endorsed by your colleagues</h3>
          </div>
        </div>
        <div className="tab_filter">
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="check_in_search_bar">
                <Form>
                  <Form.Group controlId="" className="form-postfix mb-0">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Search by Skill"
                      name="searchBox"
                      className={className}
                      onChange={handleSearchInputChange}
                      value={searchText}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <Button className="close-button" type="reset" onClick={reset}>
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={8} xl={8}>
              <div className="tab_check_in_status">
                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="all1"
                    type="checkbox"
                    name="status-check"
                    value="ALL"
                    checked={typeArr.includes("ALL")}
                    onChange={(e) => filterByEndorseType(e)}
                  />
                  <label htmlFor="all1">All</label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g11"
                    type="checkbox"
                    name="status-check"
                    value="UNIVERSITY"
                    onChange={(e) => filterByEndorseType(e)}
                    checked={typeArr.includes("UNIVERSITY")}
                  />
                  <label htmlFor="g11">University</label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g22"
                    type="checkbox"
                    name="status-check"
                    value="MANAGER"
                    onChange={(e) => filterByEndorseType(e)}
                    checked={typeArr.includes("MANAGER")}
                  />
                  <label htmlFor="g22">Manager</label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g33"
                    type="checkbox"
                    name="status-check"
                    value="PEER"
                    onChange={(e) => filterByEndorseType(e)}
                    checked={typeArr.includes("PEER")}
                  />
                  <label htmlFor="g33">Peer</label>
                </div>

                <div className="custom-checkbox custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g44"
                    type="checkbox"
                    name="status-check"
                    value="SME"
                    onChange={(e) => filterByEndorseType(e)}
                    checked={typeArr.includes("SME")}
                  />
                  <label htmlFor="g44">SME</label>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="box-light-gradient h38"></div>

        {pageOfItems && pageOfItems.length > 0 ? (
          <div className={[style.skill_table, "custom-table", "table-theme-1"].join(" ")}>
            <div className={[style.endorsement_skill_table, "position-relative"].join(" ")}>
              <table className="table">
                <thead>
                  <tr>
                    <th className={style.skill_name}>Skill Name</th>
                    <th className={style.endorsement}>Endorsement</th>
                    <th className={[style.cmp_lvl, style.current].join(" ")}>Current Competency Level</th>
                    <th className={style.relevant_exp}>Rel. Experience</th>
                    <th className={[style.cmp_lvl, style.current].join(" ")}>Expected Competency Level</th>
                    <th className={style.last_used}>Last Used On</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItems &&
                    pageOfItems.length > 0 &&
                    pageOfItems.map((data) => {
                      const relExp = data.endorsedSkill.experienceInYears
                        ? data.endorsedSkill.experienceInYears + "." + data.endorsedSkill.experienceInMonth + " Years"
                        : "-";
                      const lastUsed = data.endorsedSkill.lastUsedYear
                        ? data.endorsedSkill.lastUsedMonth + " " + data.endorsedSkill.lastUsedYear
                        : "-";
                      const count =
                        data.endorsementCount["4"] +
                        data.endorsementCount["3"] +
                        data.endorsementCount["2"] +
                        data.endorsementCount["1"];
                      return (
                        <tr key={data.endorsedSkill.skillIdNameId}>
                          <td colSpan="6">
                            <ul className={style.skills_body}>
                              <li>
                                <span
                                  className={[style.skill_name, "skill_handle"].join(" ")}
                                  onClick={() => {
                                    if (open.id !== data.endorsedSkill.skillIdNameId) {
                                      setOpen({ flag: true, id: data.endorsedSkill.skillIdNameId });
                                    } else {
                                      setOpen({ flag: !open.flag, id: data.endorsedSkill.skillIdNameId });
                                    }
                                  }}
                                  aria-controls={open.id}
                                  data-title="Skill Name :-"
                                >
                                  {data.endorsedSkill.skillIdName} <i className={style.skillname_count}>({count})</i>
                                  <i className={style.expand_collapse_wrapper}>
                                    <img
                                      src={
                                        open.id === data.endorsedSkill.skillIdNameId && open.flag
                                          ? IC_COLLAPSE
                                          : IC_EXPAND
                                      }
                                      className={
                                        open.id === data.endorsedSkill.skillIdNameId ? style.collapse : style.expand
                                      }
                                      alt="Collapse"
                                    />
                                  </i>
                                </span>
                                <div data-title="Endorsement :-" className={style.endorsement}>
                                  {data.endorsementCount["4"] > 0 && (
                                    <span className={style.endorse_tooltip}>
                                      <img src={IMG_ENDORSEMENT_1} alt="PEER" />
                                      <span className={style.tool_tip_info}>Peer ({data.endorsementCount["4"]})</span>
                                    </span>
                                  )}

                                  {data.endorsementCount["3"] > 0 && (
                                    <span className={style.endorse_tooltip}>
                                      <img src={IMG_PM} alt="PM" />
                                      <span className={style.tool_tip_info}>
                                        Manager ({data.endorsementCount["3"]})
                                      </span>
                                    </span>
                                  )}

                                  {data.endorsementCount["2"] > 0 && (
                                    <span className={style.endorse_tooltip}>
                                      <img src={IMG_SME} alt="SME" />
                                      <span className={style.tool_tip_info}>SME ({data.endorsementCount["2"]})</span>
                                    </span>
                                  )}

                                  {data.endorsementCount["1"] > 0 && (
                                    <span className={style.endorse_tooltip}>
                                      <img src={IMG_UNI} alt="University" />
                                      <span className={style.tool_tip_info}>
                                        University ({data.endorsementCount["1"]})
                                      </span>
                                    </span>
                                  )}
                                </div>
                                <div data-title="Current :-" className={[style.cmp_lvl, style.current].join(" ")}>
                                  <span
                                    className={[
                                      style.req_comp_level,
                                      competencyLevel[data.endorsedSkill?.currentCompetencyLevel],
                                    ].join(" ")}
                                  >
                                    {competencyLevel[data.endorsedSkill?.currentCompetencyLevel]}
                                  </span>
                                </div>
                                <span data-title="Rel. Experience :-" className={style.relevant_exp}>
                                  {relExp}
                                </span>
                                <div data-title="Current :-" className={[style.cmp_lvl, style.current].join(" ")}>
                                  <span
                                    className={[
                                      style.req_comp_level,
                                      competencyLevel[data.endorsedSkill?.competencyLevel],
                                    ].join(" ")}
                                  >
                                    {competencyLevel[data.endorsedSkill?.competencyLevel]}
                                  </span>
                                </div>
                                <span data-title="Last Used On :-" className={style.last_used}>
                                  {lastUsed}
                                </span>
                              </li>
                            </ul>
                            <Collapse in={open.id === data.endorsedSkill.skillIdNameId && open.flag ? true : false}>
                              <div id={open.id} className={style.endrosement_info}>
                                <div className="box-light-gradient h38"></div>
                                <ul className={style.skills_head}>
                                  <li>
                                    <span className={style.employeename}>Endorse By</span>
                                    <span className={style.endorsement_type}>Endorsement Type</span>
                                    <span className={style.cmp_lvl}>Endorsed Skill Competency</span>
                                    <span className={style.last_used_on}>Endorsement date</span>
                                  </li>
                                </ul>
                                <ul className={style.skills_body}>
                                  {data.employeeDetails.map((detail) => {
                                    return (
                                      <li key={detail.id}>
                                        <div className={style.employeename}>
                                          <span className={"skill_handle"} data-title="Skill Name :-">
                                            {detail.endorsedByName}
                                          </span>
                                          {data.employeeDetails.endorseRequestByDepartmentName && (
                                            <div
                                              className={[
                                                style.mmg_tooltip,
                                                "custom-tooltip",
                                                "mmg_table_tooltip",
                                              ].join(" ")}
                                            >
                                              <div className="box-info">
                                                <span>
                                                  <img className="svg" src={IC_INFO} alt="box-info" />
                                                </span>
                                              </div>
                                              <div className="info-attach-file">
                                                <p>
                                                  Requested by <b>WMG ({data.employeeDetails.endorseRequestByName})</b>
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div
                                          data-title="Endorsement :-"
                                          className={[style.endorsement, style.endorsement_type].join(" ")}
                                        >
                                          {detail.endorsedTypeId === "4" && (
                                            <span className={style.endorse_tooltip}>
                                              <img src={IMG_ENDORSEMENT_1} alt="PEER" />
                                              <span className={style.tool_tip_info}>Peer</span>
                                            </span>
                                          )}
                                          {detail.endorsedTypeId === "3" && (
                                            <span className={style.endorse_tooltip}>
                                              <img src={IMG_PM} alt="PM" />
                                              <span className={style.tool_tip_info}>Manager</span>
                                            </span>
                                          )}
                                          {detail.endorsedTypeId === "2" && (
                                            <span className={style.endorse_tooltip}>
                                              <img src={IMG_SME} alt="SME" />
                                              <span className={style.tool_tip_info}>SME</span>
                                            </span>
                                          )}
                                          {detail.endorsedTypeId === "1" && (
                                            <span className={style.endorse_tooltip}>
                                              <img src={IMG_UNI} alt="University" />
                                              <span className={style.tool_tip_info}>University</span>
                                            </span>
                                          )}
                                        </div>
                                        <div
                                          data-title="Current :-"
                                          className={[style.cmp_lvl, style.current].join(" ")}
                                        >
                                          <span
                                            className={[
                                              style.req_comp_level,
                                              competencyLevel[detail.endorsedCompetencyLevel],
                                            ].join(" ")}
                                          >
                                            {competencyLevel[detail.endorsedCompetencyLevel]}
                                          </span>
                                        </div>
                                        <span data-title="Last Used On :-" className={style.last_used_on}>
                                          {moment(detail.endorsedDate).format("MMMM D, YYYY")}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            <div className="gap120"></div>
          </div>
        )}
      </div>
      {myEndorseData && myEndorseData.length !== 0 && (
        <Pagination pageSize={size} items={myEndorseData} onChangePage={onChangePage} resetData={resetData} />
      )}
    </>
  );
}
