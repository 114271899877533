import React from "react";
import PropTypes from "prop-types";
import { Col, Dropdown } from "react-bootstrap";
import style from "./pagination.module.scss";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import store from "../../store/store";
import { getPager } from "util/general";

const propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
};

const defaultProps = {
  initialPage: 1,
  pageSize: 10,
  size: 10,
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  UNSAFE_componentWillMount() {
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setPage(this.props.initialPage, true);
    }
  }

  setPerPage = (page) => {
    if (this.props.onChangeObjectivePage) this.props.onChangeSize(page);
    else this.props.onChangePageSize(page);

    this.props.resetData && this.props.resetData();
  };

  setPage(page, isCall = false) {
    let { items, pageSize, size } = this.props;
    let pager = this.state.pager;
    let pageOfItems = null;
    if (this.props.onChangeObjectivePage) {
      pager = getPager(items.length, page, size);
      pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
      this.setState({ pager: pager });
      this.props.onChangeObjectivePage(pageOfItems);
    } else {
      if (this.props.customPage && isCall) {
        pager = getPager(items.length, store.getState().skillMatrix.getPage, pageSize);
      } else {
        pager = getPager(items.length, page, pageSize);
      }
      pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
      this.setState({ pager: pager });
      this.props.onChangePage(pageOfItems);
    }
    if (this.props.customPage) {
      store.dispatch(actions.getPageNumber(pager));
    }
  }

  render() {
    const pager = this.state.pager;
    let showingPage = null;
    const objectiveType = this.props.onChangeObjectivePage ? true : false;

    if (pager) {
      const startPage = pager.startIndex + 1;
      const endPage = pager.endIndex + 1;
      const totalPages = pager.totalItems;

      showingPage = "Showing " + startPage + "-" + endPage + " of " + totalPages;
    }

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return (
        <Col className="pagination-col">
          <div className={["box box-flex-center", style.paginationBox].join(" ")}>
            <div className="mr-auto pagination-info">
              <span>{"Showing " + 1 + "-" + (pager.totalItems || 0) + " of " + (pager.totalItems || 0)}</span>
              <Dropdown>
                <Dropdown.Toggle className="custom-btn" variant="secondary" id="dropdown-basic">
                  {objectiveType ? this.props.size + " Results" : this.props.pageSize + " Results"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.setPerPage(10)}>10 Results</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.setPerPage(20)}>20 Results</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.setPerPage(30)}>30 Results</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.setPerPage(50)}>50 Results</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Col>
      );
    }

    return (
      <>
        <Col className="pagination-col">
          <div className={["box box-flex-center", style.paginationBox].join(" ")}>
            <div className="mr-auto pagination-info">
              <span>{showingPage}</span>
              <Dropdown>
                <Dropdown.Toggle className="custom-btn" variant="secondary" id="dropdown-basic">
                  {objectiveType ? this.props.size + " Results" : this.props.pageSize + " Results"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.setPerPage(10)}>10 Results</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.setPerPage(20)}>20 Results</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.setPerPage(30)}>30 Results</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.setPerPage(50)}>50 Results</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="ml-auto pagination-full-number">
              <span
                onClick={() => this.setPage(1)}
                className={pager.currentPage === 1 ? "pagination-btn first-btn disabled" : "pagination-btn first-btn"}
              >
                First
              </span>
              <span
                onClick={() => this.setPage(pager.currentPage - 1)}
                className={pager.currentPage === 1 ? "pagination-btn prev-btn disabled" : "pagination-btn prev-btn"}
              >
                Prev
              </span>
              <span className="pagination-no">
                {pager.pages.map((page, index) => (
                  <span
                    key={index}
                    className={pager.currentPage === page ? "pagination-btn current-btn" : "pagination-btn"}
                    onClick={() => this.setPage(page)}
                  >
                    {page}
                  </span>
                ))}
              </span>
              <span
                onClick={() =>
                  this.setPage(pager.currentPage === pager.totalPages ? pager.currentPage : pager.currentPage + 1)
                }
                className={
                  pager.currentPage === pager.totalPages
                    ? "pagination-btn next-btn disabled"
                    : "pagination-btn next-btn"
                }
              >
                Next
              </span>
              <span
                onClick={() => this.setPage(pager.totalPages)}
                className={
                  pager.currentPage === pager.totalPages
                    ? "pagination-btn last-btn disabled"
                    : "pagination-btn last-btn"
                }
              >
                Last
              </span>
            </div>
          </div>
        </Col>
      </>
    );
  }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    pageSize: state.myTeam.pageSize,
    size: state.objectives.size,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePageSize: (size) => dispatch(actions.ChangePageSize(size)),
    onChangeSize: (size) => dispatch(actions.ChangeSize(size)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
