import React from "react";
import { Form } from "react-bootstrap";
import UploadFile from "../../Performance/CycleMemberProfile/QuestionAnswer/UploadFile";
import style from "./PreviewTemplate.module.scss";

export default function ScaledType(props) {
  let options =
    props.question.scaleObj &&
    props.question.scaleObj.name.split("/").map((obj, i) => {
      return (
        <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm no_mar" key={i}>
          <input id={props.question.questionId + "QUESTION_ID" + i} type="radio" />
          <label htmlFor={props.question.questionId + "QUESTION_ID" + i}>{obj}</label>
        </div>
      );
    });
  let responseText = "";
  return (
    <div className={[style.question_list, "box-inner-pad"].join(" ")}>
      <span className={style.question_sr_no}>Q{props.no + 1}</span>
      <div className={style.que_ans_wrapper}>
        <div className={style.question_name}>
          <h4>
            {props.question.questionName}
            {props.question.isRequired && <span className="text-danger">*</span>}
          </h4>
        </div>
        {props.question.descriptionFlag && (
          <p className={[style.answer_description, "mt-1"].join(" ")}>{props.question.description}</p>
        )}
        <p className={[style.answer_description, "mt-3"].join(" ")}>{responseText}</p>
        <div className={style.ans_wrapper}>
          {!props.question.notShowScaleToEmployee && options}
          {props.question.optionalCommentBox && (
            <div className={style.txt_box_wrapper}>
              <Form.Group controlId="">
                <div className="textarea-wrapper mt-3">
                  <UploadFile questionId="QUESTION_ID" uploadFile={() => {}} />
                  <Form.Control
                    as="textarea"
                    maxLength="5000"
                    placeholder={`Add a new answer (${props.question.commentResponse})`}
                  />
                </div>
              </Form.Group>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
