import React, { useReducer } from "react";
/* import IdleTimer from 'react-idle-timer'; */
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "base.scss";
import "react-toastify/dist/ReactToastify.min.css";
import AuthContext, { authReducer } from "context/authContext";
import storage from "util/storage";
import Footer from "components/footer/footer";
import Header from "components/Header/Header";
import Login from "components/Login/Login";
import Timer from "components/Timer/Timer";
import OneOnOneForTeams from "pages/CheckIns/OneOnOne/OneOnOneForTeams/OneOnOneForTeams";
import RouteConfig from "components/Route/RouteConfig";

export default function App() {
  const user = storage.getUser();
  const navigation = storage.getModules();
  const pageView = storage.getPageView();
  const isLoggedIn = user ? true : false;
  let initialState = { user, isLoggedIn, navigation, pageView };

  const [state, dispatch] = useReducer(authReducer, initialState);
  if (!state.isLoggedIn) {
    return (
      <AuthContext.Provider value={{ state, dispatch }}>
        <Switch>
          <Route path="/one-on-one-agenda/teams" exact component={OneOnOneForTeams} />
          <Route path="/" component={Login} />
        </Switch>
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <Timer />
      <Header />
      <div className="contentArea">
        <ToastContainer autoClose={3000} hideProgressBar toastClassName="toastMain" />
        <RouteConfig />
      </div>
      <Footer />
    </AuthContext.Provider>
  );
}
