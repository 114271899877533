import React from "react";
import style from "./AssignObjectives.module.scss";
import PropTypes from "prop-types";
import { backGroundColor } from "../../../const/constValue";

export default function ObjectiveOwner({ owner }) {
  const { designation, department, displayPicture, empName } = owner;
  return (
    <div className="box ">
      <div className="box-heading-wrapper">
        <div className={["box-heading", style.header_wrapper].join(" ")}>
          <h2>Owner</h2>
        </div>
      </div>
      <div className="box-light-gradient"></div>
      <div className="box-inner-pad pb-0">
        <div className="col-lg-12 position-relative">
          <div className="form-group">
            <div className={style.owner_img} style={{ backgroundColor: backGroundColor[0] }}>
              {displayPicture && displayPicture.length === 2 ? displayPicture : <img src={displayPicture} alt="pic" />}
            </div>
            <div className={style.owner_detail}>
              <h1>{empName}</h1>
              <h2>{department}</h2>
              <h3>{designation}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ObjectiveOwner.propTypes = {
  owner: PropTypes.shape({
    designation: PropTypes.string,
    department: PropTypes.string,
    displayPicture: PropTypes.string,
    empName: PropTypes.string,
  }),
};

ObjectiveOwner.defaultProps = {
  owner: PropTypes.shape({
    designation: "",
    department: "",
    displayPicture: "",
    empName: "",
  }),
};
