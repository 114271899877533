import React from "react";
import { ReactSVG } from "react-svg";
import { IC_COMMENT } from "const/imgCost";

const CommentsTop = ({ heading }) => {
  return (
    <div className="view_comment_box_overview justify-content-between">
      <div className="d-flex">
        <div className="view_comment_box_overview_icon">
          <ReactSVG className="svg" src={`${IC_COMMENT}`} alt="" />
        </div>
        <div className="view_comment_box_heading">
          <strong className="semi-bold">{heading}</strong>
        </div>
      </div>
    </div>
  );
};

export default CommentsTop;
