import React from "react";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import { Table } from "react-bootstrap";
import CurrentOneOnOneRow from "./CurrentOneOnOneRow";

export default function CurrentOneOnOneTable({ currentOneOnOneData }) {
  return (
    <div className="box pb-0">
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Current 1-on-1</h2>
        </div>
      </div>
      <div className="box-light-gradient"></div>
      <div className="box-inner-pad pt-0 pb-0">
        <div className={style.one_on_one_cm_table}>
          <div className="custom-table table-theme-1 pt40 position-relative">
            <Table hover responsive className={style.tab_table_responsive}>
              <thead>
                <tr>
                  <th className={style.w_50}>Created On</th>
                  <th className={style.w_25}>Scheduled On</th>
                  <th className={style.w_25}>Created By</th>
                </tr>
              </thead>
              <tbody>
                {currentOneOnOneData &&
                  currentOneOnOneData.map((value, index) => (
                    <CurrentOneOnOneRow
                      id={value.oneOnOnAgendaId}
                      key={value.oneOnOnAgendaId}
                      createOn={value.createOn ? value.createOn : ""}
                      scheduleOn={value.scheduleOn}
                      createdBy={value.createdBy}
                    />
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
