import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import moment from "moment";
import style from "./AssignObjectives.module.scss";
import * as actions from "store/actions/index";
import { endOfDay } from "util/dateUtils";
import { errorToast, scrollToTop, successToast } from "util/general";
import { IMG_NO_INFONEER_SELECTED } from "const/imgCost";
import { useQuery } from "util/utils";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import ObjectiveHeading from "./ObjectiveHeading";
import ObjectiveKeyResults from "./ObjectiveKeyResults";
import SelectedInfo from "./SelectedInfo";
import ObjectiveOwner from "./ObjectiveOwner";
import AssigneePopup from "./AssigneePopup/AssigneePopup";
import AssignBackBox from "./AssignBackBox";
import ObjectiveAssignee from "./ObjectiveAssignee";
import KeyAccordion from "../AllObjectives/UnivertsityCreatedObjective/Key_Result_Accordion";
import ObjectiveDescription from "../CreatedObjective/ObjectiveDescription";
import SideBarTags from "../../Sidebar-tags/SidebarTags";
import DurationDateSelector from "../../DurationDateSelector/DurationDateSelector";
import EmployeeSelectAssignDate from "./EmployeeSelectAssignDate";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { errorMessage } from "const/constValue";
import routePath from "const/routePath";
import PendingAssigneePopUp from "./PendingAssigneePopUp";
import { filterArray } from "../../../util/general";

export default function AssignObjectives(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = storage.getUser();
  const objectiveId = props.match.params.id;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const [startDate, setStartDate] = useState();
  const [display, setDisplay] = useState(false);
  const [endDate, setEndDate] = useState();
  const [minDate, setMinDate] = useState(new Date());
  const [openPopup, setOpenPopup] = useState(false);
  const [hideInfoneer, setHideInfoneer] = useState(false);
  let [selectedBand, setSelectedBand] = useState("");
  let [searchFilter, setSearchFilter] = useState("");
  let [filterTeamData, setFilterTeam] = useState([]);
  let [checkBoxClick, setCheckBoxClick] = useState(0);
  const { assignees, objective, isLoading, team, assignObjectiveResponse, allBands, predefinedObjective, selfObjectiveData } = useSelector((state) => state.objectives);
  const [teamData, setTeamData] = useState(team);
  const [pendingAssgneeMembers, setPendingAssgneeMembers] = useState([]);
  const [showPendingPopUp, setShowPendingPopUp] = useState(false);
  const [selectAllEmployees, setSelectAllEmployees] = useState(false);
  const [selectAllDisable, setSelectAllDisable] = useState(false);
  const [projectStatusFilter, setProjectStatusFilter] = useState("");
  const employee = useSelector((state) => state.myTeam.user);
  const universityObjective = props?.location?.state?.universityObjective;
  const teamObjective = props?.location?.state?.teamObjective;
  let a = moment(startDate);
  let b,
    duration = null;
  if (endDate) {
    b = moment(endDate);
    duration = b.diff(a, "days") + 1;
  }

  const predefinedObjectiveNames = predefinedObjective?.map(function (i) {
    return i.label;
  });

  const user = storage.getUser();
  useEffect(() => {
    if (
      user?.employeeId &&
      objective?.createdBy?.employeeId &&
      user?.employeeId !== objective?.createdBy?.employeeId
    ) {
      history.push(routePath.HOME);
    }
  }, [user?.employeeId, objective?.createdBy?.employeeId]);

  useEffect(() => {
    if (assignees?.length !== 0 && teamData?.length !== 0 && assignees?.length <= teamData?.length) {
      const pendingMembers = [];
      teamData.forEach((member) => {
        if (!member.objectiveAssigned && !member.isChecked) {
          pendingMembers.push(member);
        }
      })
      setPendingAssgneeMembers(pendingMembers);
    }
  }, [openPopup]);

  useEffect(() => {
    dispatch(actions.getPrdefinedObjective(loggedInUser?.podInformation, loggedInUser?.country));
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: "CLEAR_OBJECTIVES_STATE" });
    scrollToTop(500);
    dispatch(actions.GetManagerTeam(objectiveId));
    dispatch(actions.RemoveAssignees());
    dispatch(actions.GetObjective(objectiveId));
    if (employeeId) dispatch(actions.GetUser(employeeIds));
  }, [objectiveId, dispatch]);

  useEffect(() => {
    let filterTeam;
    let filterTeamWeightage;
    if (objective?.isOther) {
      filterTeam = team?.filter((value) => value?.country === "United Kingdom" || value?.band < 3 || value?.band > "CEO" || value?.deliveryGroup === "Non Delivery");
      filterTeamWeightage = filterTeam?.filter((employee) =>100 - employee.totalWeightageOfManager >= objective?.weightage);
    } else {
      filterTeam = team?.filter((value) => value.band === objective?.band);
      filterTeamWeightage = filterTeam?.filter((employee) =>100 - employee.totalWeightageOfManager >= objective?.weightage);
    }
    setTeamData(filterTeamWeightage);
  }, [team, objective]);

  useEffect(() => {
    if (assignObjectiveResponse) {
      if (assignObjectiveResponse.success) {
        successToast(assignObjectiveResponse.message);
        if (universityObjective) {
          history.push(routePath.TEAMS_OBJECTIVE);
        } else {
          if (teamObjective) {
            history.push({
              pathname: routePath.ALL_OBJECTIVES,
              query: { tab: `MyCreatedObjective` },
            });
          } else {
            history.push(routePath.TEAMS_OBJECTIVE);
          }
        }
      } else {
        errorToast(assignObjectiveResponse.message);
      }
      dispatch({ type: "CLEAR_FILE_OBJECTIVE_RESPONSE" });
    }
  }, [dispatch, history, teamObjective, universityObjective, assignObjectiveResponse]);

  useEffect(() => {
    let requestBody = {
      employee: {
        employeeId: employee?.employeeId,
        subGroupId: employee?.subGroupId,
        parentGroupId: employee?.parentGroupId
      },
      manager: {
        employeeId: user?.employeeId,
        subGroupId: user?.subGroupId,
        parentGroupId: user?.parentGroupId,
      },
    }
    employee && dispatch(actions.getUserObjectiveWithManagerEmployeePair(requestBody));
  }, [employee]);

  useEffect(() => {
    if (filterTeamData?.length > 0 && filterTeamData?.filter((data) => data.objectiveAssigned === true)?.length === filterTeamData.length) {
      setSelectAllEmployees(true);
      setSelectAllDisable(true);
    } else if(filterTeamData?.length > 0 && filterTeamData?.filter((data) => data.isChecked === true)?.length !== filterTeamData.length){
      setSelectAllEmployees(false);
      setSelectAllDisable(false);
    }
  }, [openPopup]);

  useEffect(() => {
    var merge = (a, b, p) => a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b);
    if (teamData && filterTeamData) {
      const mergedArray = merge(teamData, filterTeamData, "employeeId");
      setTeamData(mergedArray);
    }
  }, [selectAllEmployees]);

  const selectAll = (e) => {
    setSelectAllEmployees(e.target.checked);
    const selectedOption = storage.get("selectedOption");
    if (selectedOption === "false") {
      const projectTeam = filterTeamData.map((member) => {
        if (!member.isOnBench) {
          return {
            ...member,
            isChecked: e.target.checked,
          };
        } else {
          return member;
        }
      });
      setFilterTeam(projectTeam);
    } else if (selectedOption === "true") {
      const benchTeam = filterTeamData.map((member) => {
        if (member.isOnBench) {
          return {
            ...member,
            isChecked: e.target.checked,
          };
        } else {
          return member;
        }
      });
      setFilterTeam(benchTeam);
    } else {
      const updatedTeam = filterTeamData.map((member) => ({
        ...member,
        isChecked: e.target.checked,
      }));
      setFilterTeam(updatedTeam);
    }
  };

  const filterEmployees = () => {
    if (selectedBand !== "ALL" && selectedBand !== "") {
      return teamData?.filter(
        (employee) =>
          100 - employee.totalWeightageOfManager >= objective?.weightage &&
          employee.band === selectedBand
      );
    }
    return teamData?.filter(
      (employee) =>
        100 - employee.totalWeightageOfManager >= objective?.weightage
    );
  };

  useEffect(() => {
    const filteredTeamData = filterEmployees();
    setFilterTeam(filteredTeamData);
  }, [teamData]);

  useEffect(() =>{
  if (filterTeamData?.length > 0 && filterTeamData?.filter((data) => data.isChecked === true)?.length === filterTeamData?.length && searchFilter?.length === 0) {
      setSelectAllEmployees(true);
    } else {
      setSelectAllEmployees(false);
    }
  }, [checkBoxClick]);

  useEffect(() => {
    if (filterTeamData?.length > 0 && filterTeamData?.filter((data) => data.isChecked === true)?.length === filterTeamData?.length && searchFilter?.length === 0) {
      setSelectAllEmployees(true);
    } else {
      setSelectAllEmployees(false);
    }
  }, [searchFilter]);
 /* select all issue fix starts*/
  useEffect(() => {
    if (filterTeamData?.length > 0 && filterTeamData?.filter((data) => data.isChecked === true)?.length === filterTeamData?.length && searchFilter?.length === 0) {
      setSelectAllEmployees(true);
    } else {
      setSelectAllEmployees(false);
    }
  }, [projectStatusFilter]);

  useEffect(() => {
    const selectedOption = storage.get("selectedOption") || undefined;
    const dropdownOptions1 = [
      { value: selectedOption === "undefined" || selectedOption === undefined ? "" : selectedOption === "false" ? "false" : "true", label: selectedOption === "undefined" || selectedOption === undefined ? "ALL" : selectedOption === "false" ? "Project" : "OnBench" },
    ];
    setProjectStatusFilter(dropdownOptions1[0]);
  }, [openPopup]);
  /* select all issue fix ends*/

  useEffect(() => {
    return () => {
      storage.remove("selectedOption");
    };
  }, []);

  const onProjectStatusChange = (data) => {
    if(data.value !== ""){
      // setSelectAllDisable(true); /* select all issue fix */
    } else {
      setSelectAllDisable(false);
    }
    /* select all issue fix */
    storage.set("selectedOption", data?.value === "" ? "undefined" : data?.value);
    const ProjectStatusFilter = data; 
    const StatusFilters = {
      empName: searchFilter ? searchFilter : "",
      isOnBench: ProjectStatusFilter.value,
    };
    const newFilteredData = filterArray(filterEmployees(), StatusFilters);
    setProjectStatusFilter(ProjectStatusFilter);
    setFilterTeam(newFilteredData);
  };

  const search = (e) => {
    if(e.target.value.length > 0){
      setSelectAllEmployees(false);
      setSelectAllDisable(true);
    } else{
      setSelectAllDisable(false);
    }
    let filteredData = null;
    let updatedTeam = null;
    const filters = {
      empName: e.target.value,
      isOnBench:projectStatusFilter?.value || "",
    };
    filteredData = filterArray(filterEmployees(), filters);
    if(selectAllEmployees){
       updatedTeam = filteredData.map(member => ({
        ...member,
        isChecked: true
      }));
    } else{
      updatedTeam = filteredData
    }
    setFilterTeam(updatedTeam);
    setSearchFilter(e.target.value);
  };

  const resetSearch = () => {
    setSelectAllDisable(false);
    let filteredData = null;
    let updatedTeam = null;
    filteredData = filterEmployees();
    if(selectAllEmployees){
      updatedTeam = filteredData.map(member => ({
       ...member,
       isChecked: true
     }));
   } else{
     updatedTeam = filteredData
   }
    setFilterTeam(updatedTeam);
    setSearchFilter("");
    /* select all issue fix */
    // setProjectStatusFilter("");
  };

  const assignObjective = (pendingMembersData) => {
    if (pendingMembersData.length > 0 && !employeeId) {
      setShowPendingPopUp(true);
      return;
    }

    let _startDate = startDate;
    let _endDate = endOfDay(endDate);
    if (!employeeId) {
      if (!assignees || !assignees.length > 0) {
        errorToast(errorMessage.SELECT_ASSIGNEE);
      } else if (startDate && endDate) {
        let array = [];
        let data = null;
        let body = null;
        assignees.forEach((value) => {
          data = {
            employeeId: value?.employeeId,
            empName: value?.empName,
            designation: value?.designation,
            department: value?.dept,
            subGroupId: value?.subGroupId,
            subGroupName: value?.subGroupName,
            parentGroupId: value?.parentGroupId,
            parentGroupName: value?.parentGroupName,
          };
          array.push(data);
        });
        body = {
          objectiveId: objectiveId,
          managerData: {
            employeeId: user.employeeId,
            empName: user.displayName,
            designation: user.designation,
            department: user.department,
            displayPicture: user.displayPicture || user.empInitial,
            subGroupId: user.subGroupId,
            subGroupName: user.subGroupName,
            parentGroupId: user.parentGroupId,
            parentGroupName: user.parentGroupName,
          },
          assignees: array,
          startDate: _startDate,
          endDate: _endDate,
        };
        if ((!predefinedObjectiveNames?.includes(objective?.title)) || objective?.weightage === null) {
          if (objective?.isOther && objective?.weightage !== null) {
            dispatch(actions.AssignObjective(body));
            setShowPendingPopUp(false);
            setPendingAssgneeMembers([]);
          }
          else {
            errorToast("Correct Title & Weightage should be aligned with OKR");
            return;
          }
        }
        else {
          dispatch(actions.AssignObjective(body));
          setShowPendingPopUp(false);
          setPendingAssgneeMembers([]);
        }
      } else if (assignees) {
        errorToast(errorMessage.SELECT_DATE);
      }
    } else if (startDate && endDate) {
      let body = null;
      body = {
        objectiveId: objectiveId,
        managerData: {
          employeeId: user.employeeId,
          empName: user.displayName,
          designation: user.designation,
          department: user.department,
          displayPicture: user.displayPicture || user.empInitial,
          subGroupId: user.subGroupId,
          subGroupName: user.subGroupName,
          parentGroupId: user.parentGroupId,
          parentGroupName: user.parentGroupName,
        },
        assignees: [
          {
            employeeId: employee?.employeeId,
            empName: employee?.displayName,
            designation: employee?.designation,
            department: employee?.department,
            subGroupId: employee?.subGroupId,
            subGroupName: employee?.subGroupName,
            parentGroupId: employee?.parentGroupId,
            parentGroupName: employee?.parentGroupName,
          },
        ],
        startDate: _startDate,
        endDate: _endDate,
      };
      if (selfObjectiveData?.totalWeightagae == 100) {
        errorToast("You are already assigned with 100% weightage OKR");
        return;
      } else if ((100 - selfObjectiveData?.totalWeightagae) < objective?.weightage) {
        errorToast("Please check total weightage, as it is exceeding 100%");
        return;
      } else if (!objective?.isOther && employee?.band !== objective?.band) {
        errorToast("OKR band and selected employee band is different");
        return;
      } else if (!objective?.isOther && objective?.weightage === null) {
        errorToast("Correct Weightage should be aligned with OKR");
        return;
      } else {
        if (objective?.isOther) {
          const validBands = ['0', '1', '2', 'CEO'];
          if (employee?.deliveryGroup === "Non Delivery" || employee?.country === "United Kingdom" || validBands.includes(employee?.band)) {
            dispatch(actions.AssignObjective(body));
            setShowPendingPopUp(false);
            setPendingAssgneeMembers([]);
          } else {
            errorToast("Selected reportee is not eligible for Other OKR");
            return;
          }
        } else {
          dispatch(actions.AssignObjective(body));
          setShowPendingPopUp(false);
          setPendingAssgneeMembers([]);
        }
      }
    } else {
      errorToast(errorMessage.SELECT_DATE);
    }
  };

  const handleBack = () => {
    history.push({
      pathname: routePath.ALL_OBJECTIVES,
      state: {
        type:
          props?.location?.state?.universityObjective ? "UNIVERSITY_OBJECTIVE" : "TEAM_OBJECTIVE",
      },
    });
  };
  const displayAll = () => setDisplay(true);

  const length = assignees?.length || 0;

  const handleStartDate = (date) => {
    if (date > new Date()) {
      setMinDate(date);
    }
    setStartDate(date);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
  };

  if (isLoading) {
    return <Spinner />;
  }

  const handleContinue = () => {
    assignObjective([]);
  }

  const handlePendingPopupClose = () => {
    setShowPendingPopUp(false);
  }

  const handleOpenUpdateList = () => {
    setShowPendingPopUp(false);
    setOpenPopup(true);
  }

  return (
    <div className={style.assignobjectives_wrapper}>
      <SubNavigation type="Objectives" />
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">Objectives</h1>
            <span className="st-breadcrumb">
              Objectives
              <span>
                <Link to={routePath.ALL_OBJECTIVES}>All Objectives</Link>
              </span>
              <span>
                {objective && objective.definedBy === "UNIVERSITY" ? (
                  <Link to={routePath.ALL_OBJECTIVES}>University Programs</Link>
                ) : (
                  <Link
                    to={{
                      pathname: routePath.ALL_OBJECTIVES,
                      query: {
                        tab: `MyCreatedObjective`,
                      },
                    }}
                  >
                    Team Objectives
                  </Link>
                )}
              </span>
              <span>Assign Objective</span>
            </span>
          </Col>
          {objective && <ObjectiveHeading objective={objective} />}
          <Col xl={8}>
            {objective?.description && <ObjectiveDescription description={objective.description} />}
            {universityObjective
              ? objective && <KeyAccordion objective={objective} />
              : objective && <ObjectiveKeyResults keyPoints={objective.keyPoints} />}
            {employeeId ? (
              <EmployeeSelectAssignDate
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                duration={duration}
                endDate={endDate}
                startDate={startDate}
                minDate={minDate}
                employee={employee}
              />
            ) : (
              <div className="box pb-0">
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2>Align Apexon Associates</h2>
                    {/* <h3>Some text goes here, will be updated soon</h3> */}
                  </div>
                  <div className="box-info ">
                    <span>
                      <span onClick={() => setHideInfoneer(!hideInfoneer)}>{hideInfoneer ? "Show" : "Hide"}</span>
                    </span>
                  </div>
                </div>
                {!hideInfoneer && !assignees && <div className="box-light-gradient"></div>}
                {!hideInfoneer &&
                  (assignees && assignees.length > 0 ? (
                    <>
                      <div className="box-inner-pad">
                        <Row>
                          <div className="col-xl-12">
                            <div className="form-group">
                              <p className={style.align_list}>{length} Apexon Associates are aligned with this objective</p>
                            </div>
                          </div>
                          {assignees && (
                            <div className="col-xl-9">
                              <div className={style.avatar_list}>
                                <div className={style.avatar_container}>
                                  {assignees
                                    .filter((value, index) => (display ? true : index < 6))
                                    .map((assignee, index) => (
                                      <SelectedInfo
                                        assignee={assignee}
                                        displayAll={displayAll}
                                        display={display}
                                        index={index}
                                        key={"ASSIGNEE_" + assignee.employeeId}
                                      />
                                    ))}
                                  {length > 6 && !display && (
                                    <div className={style.avatar} onClick={() => displayAll()}>
                                      <div className={style.avtar_item}>+{length - 6}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-xl-3 text-right">
                            <Button
                              type="button"
                              variant={"secondary"}
                              className={["btn-round", style.btn_cancel].join(" ")}
                              onClick={() => setOpenPopup(true)}
                            >
                              <strong>Update List</strong>
                            </Button>
                          </div>
                        </Row>
                      </div>
                      <DurationDateSelector
                        handleStartDate={handleStartDate}
                        handleEndDate={handleEndDate}
                        duration={duration}
                        startDate={startDate}
                        minDate={minDate}
                        endDate={endDate}
                      />
                    </>
                  ) : (
                    <div className="box-inner-pad">
                      <Row>
                        <div className="col-xl-12">
                          <div className={style.no_infoneer_selected_wrapper}>
                            <ReactSVG src={IMG_NO_INFONEER_SELECTED} />
                            <h1>No Apexon Associates Selected Yet!</h1>
                            <h2>Tap “Add New” below to select one or multiple Apexon Associates</h2>
                            <Button
                              type="button"
                              variant={"secondary"}
                              className={["btn-round mt-3 ", style.btn_cancel].join(" ")}
                              onClick={() => setOpenPopup(true)}
                            >
                              <strong>Add Now</strong>
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </div>
                  ))}
                {team && team.length > 0 && (
                  <AssigneePopup 
                    openPopup={openPopup} 
                    setOpenPopup={setOpenPopup} 
                    selectAllDisable={selectAllDisable}
                    selectAllEmployees={selectAllEmployees} 
                    setSelectAllEmployees={setSelectAllEmployees} 
                    selectAll={selectAll} 
                    objectiveWeightag={objective?.weightage} 
                    teamData={teamData} 
                    team={filterTeamData} 
                    setTeam={setFilterTeam}
                    searchFilter={searchFilter}
                    search={search}
                    resetSearch={resetSearch}
                    checkBoxClick={checkBoxClick}
                    setCheckBoxClick={setCheckBoxClick}
                    projectStatusFilter={projectStatusFilter}
                    onProjectStatusChange={onProjectStatusChange}
                    band={allBands?.filter((value, index, self) => index === self.findIndex((t) => (t.label === value.label)))} />
                )}
              </div>
            )}
            <AssignBackBox handleAssign={() => assignObjective(pendingAssgneeMembers)} handleBack={handleBack} />
          </Col>
          <PendingAssigneePopUp show={showPendingPopUp} pendingEmployees={pendingAssgneeMembers} onContinue={handleContinue} onClose={handlePendingPopupClose} openUpdateList={handleOpenUpdateList} />
          <Col xl={4}>
            {objective?.createdBy && <ObjectiveOwner owner={objective.createdBy} />}
            {objective && employee && employeeId && <ObjectiveAssignee user={employee} />}
            {objective?.tags && objective.tags.length > 0 && <SideBarTags tags={objective.tags} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
