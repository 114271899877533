import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Select from "react-select";
import InfoTooltip from "../../InfoTooltip/InfoTooltip";
import style from "./DateAndTimeSelection.module.scss";
import { toDate } from "../../../util/dateUtils";
import DateAndTimePicker from "../../../shared/DateAndTimePicker/DateAndTimePicker";

function DateAndTimeSelection(props) {
  const {
    handleStartDate,
    startDate,
    handleStartTime,
    isPage,
    handleEndTime,
    oneOnOne,
    startTime,
    endTime,
    selectQuestion,
    timezoneList,
    timezone,
    setTimezone,
    blockTimes,
  } = props;
  let selectedDate =
    selectQuestion === "discuss" && (oneOnOne && oneOnOne.scheduledDate ? toDate(oneOnOne.scheduledDate) : "");
  let selectedStartTime = (selectQuestion === "discuss" && oneOnOne && oneOnOne.fromTime) || "";
  let selectedEndTime = (selectQuestion === "discuss" && oneOnOne && oneOnOne.toTime) || "";
  let maxDate = selectQuestion === "close" && new Date();
  let d = new Date();

  d.setHours(d.getHours(), d.getMinutes());
  let startMinTime,
    startMaxTime,
    endMinTime,
    endMaxTime = null;
  if (selectQuestion === "discuss") {
    if (startDate) {
      startMinTime =
        moment(startDate).format("DD MM YYYY") === moment().format("DD MM YYYY") ? new Date() : d.setHours(0, 0);
      startMaxTime = d.setHours(23, 55);
      endMinTime = startTime ? startTime : d.setHours(23, 55);
      endMaxTime = d.setHours(23, 55);
    } else {
      startMinTime = d.setHours(23, 55);
      startMaxTime = d.setHours(23, 55);
      endMinTime = d.setHours(23, 55);
      endMaxTime = d.setHours(23, 55);
    }
  } else if (selectQuestion === "close") {
    if (startDate) {
      startMinTime = d.setHours(0, 0);
      startMaxTime =
        moment(startDate).format("DD MM YYYY") === moment().format("DD MM YYYY") ? new Date() : d.setHours(23, 55);
      endMinTime = startTime ? startTime : d.setHours(23, 55);
      endMaxTime = startTime
        ? moment(startDate).format("DD MM YYYY") === moment().format("DD MM YYYY")
          ? new Date()
          : d.setHours(23, 55)
        : d.setHours(23, 55);
    } else {
      startMinTime = d.setHours(23, 55);
      startMaxTime = d.setHours(23, 55);
    }
  }
  return (
    <>
      <div className={["date_picker_time_wrapper", style.date_time_selection_wrapper].join(" ")}>
        {(selectQuestion === "discuss" || isPage === "current") && isPage !== "previous" && (
          <h5 className="d-flex">
            When would you like to discuss it?<span className="text-danger">*</span>
            <InfoTooltip heading="Schedule 1-on-1 from Engage will be reflect in Microsoft Team's calendar" />
          </h5>
        )}
        {selectQuestion === "close" && (
          <h5>
            When you have discussed this?<span className="text-danger">*</span>
          </h5>
        )}
        {isPage === "previous" && (
          <h5 className="d-flex">
            When would you like to re-schedule it?<span className="text-danger">*</span>
            <InfoTooltip heading="Schedule 1-on-1 from Engage will be reflect in Microsoft Team's calendar" />
          </h5>
        )}
        {timezoneList && timezoneList.length > 0 && (
          <div className="time_zone_wrapper">
            <Select
              options={timezoneList}
              className="custom-default-dd-box"
              classNamePrefix="select"
              placeholder="Timezone"
              value={timezone}
              onChange={setTimezone}
            />
          </div>
        )}
        <DateAndTimePicker
          date={startDate || selectedDate}
          maxDate={maxDate && maxDate}
          minDate={selectQuestion === "close" ? new Date(toDate(oneOnOne?.createdDate)) : new Date()}
          handleDate={(date) => handleStartDate(date)}
          startTime={
            (startTime && moment(startTime).toDate()) ||
            (selectedStartTime && new Date().setHours(selectedStartTime.split(":")[0], selectedStartTime.split(":")[1]))
          }
          blockTimes={blockTimes}
          startMinTime={startMinTime}
          startMaxTime={startMaxTime}
          handleEndTime={(date) => handleEndTime(date)}
          handleStartTime={(date) => handleStartTime(date)}
          endTime={
            (endTime && moment(endTime).toDate()) ||
            new Date().setHours(selectedEndTime.split(":")[0], selectedEndTime.split(":")[1])
          }
          endMaxTime={endMinTime}
          endMinTime={endMaxTime}
        />
      </div>
    </>
  );
}

DateAndTimeSelection.propTypes = {
  handleStartDate: PropTypes.func,
  handleStartTime: PropTypes.func,
  isPage: PropTypes.string,
  handleEndTime: PropTypes.func,
  oneOnOne: PropTypes.object,
  selectQuestion: PropTypes.string,
};

export default DateAndTimeSelection;
