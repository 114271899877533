import React, { useContext, useState } from "react";
import IdleTimer from "react-idle-timer";
import storage from "../../util/storage";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthContext, { ActionType } from "../../context/authContext";
 
const Timer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [idleTimerOver, setIdleTimerOver] = useState(false);
  const context = useContext(AuthContext);
 
  function onIdle() {
    storage.clear();
    dispatch({ type: "USER_LOGOUT" });
    context.dispatch({ type: ActionType.LOGOUT });
    history.push("/");
    setIdleTimerOver(true);
  }
 
  function onAction() {
    if (idleTimerOver) {
      context.dispatch({ type: ActionType.LOGOUT });
    }
  }

  return (
    <IdleTimer
      element={document}
      onIdle={onIdle}
      onAction={onAction}
      debounce={250}
      timeout={1000 * 60 * 120} //2 hour timeout
    />
  );
};
 
export default Timer;