import React, { useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import { Col, Row, Modal, Button, Form, Tooltip, Overlay } from "react-bootstrap";
import { POST_FEEDBACK } from "util/endpoints";
import http from "util/http";
import { IC_FILEUPLOAD, IC_FILE_ATTACHMENT, IC_SEARCH_RESET, IC_ERROR } from "const/imgCost";
import { btnName, errorMessage, status, successMessage } from "const/constValue";
import { errorToast, successToast } from "util/general";
import { feedbackCategory } from "const/options";

const FeedbackPopup = (props) => {
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState(null);
  const [categoryError, setCategoryError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [file, setFile] = useState(null);
  const targetDescription = useRef(null);
  const targetCategory = useRef(null);

  const categoryHandler = (event) => {
    setCategory(event.target.value);
    setCategoryError(false);
  };

  const descriptionHandler = (event) => {
    setDescription(event.target.value);
    setDescriptionError(false);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file.size > 2097152) {
      setFile();
      errorToast(errorMessage.MAXIMUM_FILE_SIZE);
    } else if (file.type === "video/mp4") {
      setFile();
      errorToast(errorMessage.FILE_TYPE);
    } else {
      setFile(file);
    }
  };

  const deleteFile = () => {
    setFile();
  };

  const submitFeedback = () => {
    const formData = new FormData();
    formData.append("category", category);
    formData.append("description", description);
    formData.append("file", file);

    if (!category) {
      setCategoryError(true);
      return;
    } else if (!description) {
      setDescriptionError(true);
      return;
    } else {
      http
        .post(POST_FEEDBACK, null, formData)
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            successToast(successMessage.FEEDBACK_SUBMITTED);
            props.submit();
            setFile();
          }
        })
        .catch(() => {
          errorToast(errorMessage.FEEDBACK_SUBMIT);
        });
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} className="custom_modal_popup">
        <Modal.Header>
          <Modal.Title>Post Feedback</Modal.Title>
        </Modal.Header>
        <div className="custom_modal_container">
          <Row>
            <Col md={12}>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="exampleForm.category">
                    <Form.Label>Category</Form.Label>
                    <Form.Control as="select" onChange={(event) => categoryHandler(event)} ref={targetCategory}>
                      {feedbackCategory.map((value) => (
                        <option value={value.value} key={value.value}>
                          {value.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Overlay target={targetCategory} show={categoryError ? true : false} placement="bottom">
                      {(propsTooltip) => (
                        <Tooltip {...propsTooltip} show={propsTooltip.show.toString() || "true"}>
                          <span className="error-icon">
                            <ReactSVG src={IC_ERROR} title="" alt="" />
                          </span>
                          <span className="error-bubble-txt">{errorMessage.FILL_PROPER}</span>
                        </Tooltip>
                      )}
                    </Overlay>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.description">
                    <h5>Description</h5>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      onBlur={(event) => descriptionHandler(event)}
                      ref={targetDescription}
                    />
                    <Overlay target={targetDescription} show={descriptionError ? true : false} placement="bottom">
                      {(propsTooltip) => (
                        <Tooltip {...propsTooltip} show={propsTooltip.show.toString() || "true"}>
                          <span className="error-icon">
                            <ReactSVG src={IC_ERROR} title="" alt="" />
                          </span>
                          <span className="error-bubble-txt">{errorMessage.FILL_PROPER}</span>
                        </Tooltip>
                      )}
                    </Overlay>
                  </Form.Group>
                  {!file && (
                    <label className="custom-file-upload mb-0 mt-1">
                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={(e) => uploadFile(e)}
                        accept=".xlsx,.pdf,.png,.jpg,.jpeg,.doc,.docx,.zip,.txt,.xls,.ppt,.pptx,.docx"
                      />
                      <span>
                        <ReactSVG src={IC_FILEUPLOAD} title="" alt="" /> Choose File
                      </span>
                    </label>
                  )}
                  {file && (
                    <ul className="file-listing w-100">
                      <li>
                        <span>
                          <ReactSVG className="file-attachment-icon" src={IC_FILE_ATTACHMENT} title="" alt="" />
                          {file.name}
                        </span>
                        <span className="delete-item" onClick={deleteFile}>
                          <ReactSVG src={IC_SEARCH_RESET} title="" alt="" />
                        </span>
                      </li>
                    </ul>
                  )}
                </Form>
              </Modal.Body>
            </Col>
            <Col className="custom_modal_button">
              <Button variant="primary" onClick={() => submitFeedback()}>
                {btnName.SUBMIT}
              </Button>

              <Button variant="secondary" className="btn-border" onClick={() => props.hide()}>
                {btnName.CANCEL}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default FeedbackPopup;
