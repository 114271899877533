import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Collapse, DropdownButton } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import moment from "moment";
import style from "./employeeObjectives.module.scss";
import * as actions from "store/actions/index";
import { backGroundColor, status, checkInStatus } from "const/constValue";
import { IC_CHAT_BUBBLE } from "const/imgCost";
import { OBJECTIVE_COMMENT, DELETE_OBJECTIVE_COMMENT } from "util/endpoints";
import http from "util/http";
import PrivateCommentSection from "./comment/privateCommentSection";
import ReplyThread from "./comment/replyThread";
import OneOnOneCommentBox from "../OneOnOne/OneOnOneCommentBox/OneOnOneCommentBox";
import OneOnOneCommentAnswer from "../OneOnOne/OneOnOneCommentBox/OneOnOneCommentAnswer";
import SlideToast from "../Toast/Toast";
import ObjectiveCommentBox from "./comment/objectiveCommentBox";
import Comment from "../CommentBox/Comment";
import CommentsTop from "../CommentBox/CommentsTop";

export default function EmployeeObjectiveComment(props) {
  const { objective, surveyCycle, user } = props;
  const [isOpenOneOnOne, setIsOpenOneOnOne] = useState(false);
  const [chars, setChars] = useState(200);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const { reviewer } = useSelector((state) => state.checkIn);
  const { questionComment, sendAgendaResponse } = useSelector((state) => state.oneOnOne);
  const [viewMoreFlag, setViewMoreFlag] = useState(false);

  const reviewDays = surveyCycle.reviewedDate != null ? Math.abs(moment(surveyCycle.reviewedDate).diff({}, "days")) : 1;
  const configReviewedDays = process.env.REACT_APP_MANAGER_COMMENTS_DAYS;

  useEffect(() => {
    if (sendAgendaResponse) {
      if (sendAgendaResponse.success) {
        if (!toast.isActive("AGENDA_SUCCESS_SEND"))
          toast.success(<SlideToast title="Awesome!" message={sendAgendaResponse.message} error={false} />, {
            toastId: "AGENDA_SUCCESS_SEND",
          });
        dispatch({ type: "CLEAR_SEND_AGENDA_RESPONSE" });
      }
    }
    setIsOpenOneOnOne(false);
  }, [sendAgendaResponse, dispatch]);

  const changeHandle = (comment) => {
    setChars(200 - comment.length);
    setComment(comment);
  };

  const managerPrivateCommentHandle = (val, whoCanView, reply) => {
    if (val.trim().length < 1) return true;
    let data = {};
    if (props.managerView) {
      data = {
        userObjectiveId: objective.userObjectiveId,
        objectiveComment: {
          comment: comment,
          commentedBy: {
            employeeId: user.employeeId,
            empName: user.displayName,
            displayPicture: user.displayPicture ? user.displayPicture : user.empInitial,
            subGroupId: user.subGroupId,
            subGroupName: user.subGroupName,
            parentGroupId: user.parentGroupId,
            parentGroupName: user.parentGroupName,
          },
          whoCanView: whoCanView,
          private: reply,
        },
        surveyCycleId: surveyCycle.surveyCyclesId,
        employeeId: surveyCycle.employeeProfile.employeeId,
        subGroupId: surveyCycle.employeeProfile.subGroupId,
        subGroupName: surveyCycle.employeeProfile.subGroupName,
        parentGroupId: surveyCycle.employeeProfile.parentGroupId,
        parentGroupName: surveyCycle.employeeProfile.parentGroupName,
      };
    } else {
      data = {
        userObjectiveId: objective.userObjectiveId,
        objectiveComment: {
          comment: comment,
          commentedBy: {
            employeeId: user.employeeId,
            empName: user.displayName,
            displayPicture: user.displayPicture ? user.displayPicture : user.empInitial,
            subGroupId: user.subGroupId,
            subGroupName: user.subGroupName,
            parentGroupId: user.parentGroupId,
            parentGroupName: user.parentGroupName,
          },
          whoCanView: whoCanView,
          private: reply,
        },
        employeeId: surveyCycle.employeeProfile.employeeId,
        subGroupId: surveyCycle.employeeProfile.subGroupId,
        subGroupName: surveyCycle.employeeProfile.subGroupName,
        parentGroupId: surveyCycle.employeeProfile.parentGroupId,
        parentGroupName: surveyCycle.employeeProfile.parentGroupName,
      };
    }

    http
      .post(OBJECTIVE_COMMENT, {}, data)
      .then((response) => response.data)
      .then(() => {
        if (props.page === "checkin") {
          dispatch(actions.GetUserObjectives(user));
        } else {
          dispatch(actions.GetObjectivesBySurveyCycle(surveyCycle.surveyCyclesId));
        }
        setChars(200);
        setComment("");
      })
      .catch(console.error);

    if (props.managerView && !reply) {
      let _data = {
        userObjectiveId: objective.userObjectiveId,
        objectiveComment: {
          comment: comment,
          commentedBy: {
            employeeId: user.employeeId,
            empName: user.displayName,
            displayPicture: user.displayPicture ? user.displayPicture : user.empInitial,
            subGroupId: user.subGroupId,
            parentGroupId: user.parentGroupId,
            subGroupName: user.subGroupName,
            parentGroupName: user.parentGroupName,
          },
          whoCanView: whoCanView,
          private: reply,
        },
        surveyCycleId: null,
        employeeId: surveyCycle.employeeProfile.employeeId,
        subGroupId: surveyCycle.employeeProfile.subGroupId,
        parentGroupId: surveyCycle.employeeProfile.parentGroupId,
        subGroupName: surveyCycle.employeeProfile.subGroupName,
        parentGroupName: surveyCycle.employeeProfile.parentGroupName,
      };
      http
        .post(OBJECTIVE_COMMENT, {}, _data)
        .then((response) => response.data)
        .then(() => {
          if (props.page === "checkin") {
            dispatch(actions.GetUserObjectives(user));
          } else {
            dispatch(actions.GetObjectivesBySurveyCycle(surveyCycle.surveyCyclesId));
          }
          setChars(200);
          setComment("");
        })
        .catch(console.error);
    }
  };

  const deleteComment = (commentId, isReply) => {
    let comment = {};
    if (isReply) {
      comment = {
        commentType: props.page === "myteam" && props.managerView ? "MANAGER" : "EMPLOYEE",
        employeeId: user.employeeId,
        userObjectiveId: objective.userObjectiveId,
        commentId: commentId,
        surveyCycleId: surveyCycle.surveyCyclesId,
      };
    } else {
      comment = {
        commentType: props.page === "myteam" && props.managerView ? "MANAGER" : "EMPLOYEE",
        employeeId: user.employeeId,
        userObjectiveId: objective.userObjectiveId,
        commentId: commentId,
        surveyCycleId: surveyCycle.surveyCyclesId,
      };
    }

    http
      .delete(DELETE_OBJECTIVE_COMMENT, { data: comment })
      .then((response) => response.data.message)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(actions.GetUserObjectives(user));
          dispatch(actions.GetObjectivesBySurveyCycle(surveyCycle.surveyCyclesId));
        }
      })
      .catch(() => {});
  };
  const managerCommentHandle = () => {
    if (comment.trim().length < 1) return true;
    setChars(200);
    setComment("");
  };

  let commentFlag = true;
  if (props.managerView) {
    if (reviewDays <= configReviewedDays) {
      commentFlag = true;
    } else {
      commentFlag = false;
    }
  } else {
    if (props.editable) {
      commentFlag = false;
    } else {
      commentFlag = true;
    }
  }

  const handleDropDown = (id) => {
    document.getElementById(`Dropdown-button${id}`).parentNode.classList.remove("show");
    document.getElementById(`Dropdown-item${id}`).parentNode.classList.remove("show");
    setIsOpenOneOnOne(true);
  };

  const oneOnOnecomment =
    questionComment &&
    questionComment.filter((value) => !value.questionId && value.talkingPoints.title === objective.title);
  const commentObj =
    objective.comment &&
    objective.comment.length > 0 &&
    objective.comment
      .filter((answer) => {
        if (answer.whoCanView && answer.whoCanView.length === 0) {
          return true;
        } else {
          if (answer.commentedBy.employeeId === user?.employeeId) {
            return true;
          } else if (
            props.managerView &&
            answer.whoCanView &&
            (answer.whoCanView[0].employeeId === user?.employeeId ||
              answer.whoCanView[0].employeeId !== user?.employeeId)
          ) {
            return false;
          } else {
            return true;
          }
        }
      })
      .sort((a, b) => {
        let aTemp = null;
        let bTemp = null;
        let arr1 = [],
          arr2 = [];
        if (a.private) {
          if (a.replyComment) {
            arr1 = [...a.replyComment];
            aTemp = arr1.sort((a, b) => moment(b.commentedOn) - moment(a.commentedOn))[0];
          }
        }

        if (b.private) {
          if (b.replyComment) {
            arr2 = [...b.replyComment];
            bTemp = arr2.sort((a, b) => moment(b.commentedOn) - moment(a.commentedOn))[0];
          }
        }
        if (aTemp || bTemp) {
          return moment(bTemp ? bTemp.commentedOn : b.commentedOn) - moment(aTemp ? aTemp.commentedOn : a.commentedOn);
        }
        return moment(b.commentedOn) - moment(a.commentedOn);
      })
      .filter((answer, i) => {
        if (!viewMoreFlag) {
          if (i === 0) {
            return true && !answer.reply;
          } else {
            return false;
          }
        } else {
          return !answer.reply;
        }
      });


      useEffect(()=>{
        setComment(objective.comment === null || Array.isArray(objective.comment) ? "" : objective.comment)
      },[props]);

  return (
    <div className={props.style.obj_manager_comments}>
      {(props.page === "myhistory" ||
        (props.page === "myteam" &&
          props.surveyCycle.status === checkInStatus.REVIEWED &&
          !(oneOnOnecomment && oneOnOnecomment.length > 0)) ||
        (props.page === "checkin" && props.surveyCycle.status !== checkInStatus.PENDING_FROM_MEMBER)) && (
        <div className={"two_column justify-content-end"}>
          <div className="action-dropdown-wrapper justify-content-end">
            <DropdownButton
              id={"Dropdown-button" + objective.userObjectiveId}
              alignRight
              title={
                <div className="action-dropdown">
                  <span className="dots"></span>
                  <span className="dots"></span>
                  <span className="dots"></span>
                </div>
              }
            >
              <span className="dropdown-item" id={"Dropdown-item" + objective.userObjectiveId}>
                <div className="dropdown_icon one_on_one_icon">
                  <ReactSVG src={`${IC_CHAT_BUBBLE}`} alt="" />
                </div>
                <div className="dropdown_item" onClick={() => handleDropDown(objective.userObjectiveId)}>
                  Add to 1-on-1 agenda
                </div>
              </span>
            </DropdownButton>
          </div>
        </div>
      )}
      {props.managerView && (
        <PrivateCommentSection
          {...props}
          objective={objective}
          surveyCycle={surveyCycle}
          selectedColor={backGroundColor[1]}
          changeHandle={changeHandle}
          managerPrivateCommentHandle={managerPrivateCommentHandle}
          page={props.page}
          isReviewed={reviewDays <= configReviewedDays}
        />
      )}
      <Collapse in={true}>
        <div className="view_comment_box_wrapper">
          <CommentsTop heading="Share your views" />
          {objective.comment && objective.comment.length > 0 ? (
            commentObj.map((answer, index) => {
              return (
                <React.Fragment key={index}>
                  <Comment
                    selectedColor={backGroundColor[2]}
                    deleteComment={() => deleteComment(answer.commentId, answer.reply)}
                    deletable={
                      props.managerView
                        ? reviewDays <= configReviewedDays && answer.commentedBy.employeeId === user?.employeeId
                        : !props.editable &&
                          reviewDays <= configReviewedDays &&
                          answer.commentedBy.employeeId === user?.employeeId
                    }
                    image={answer.commentedBy && answer.commentedBy.displayPicture}
                    empName={answer.commentedBy && answer.commentedBy.empName}
                    commentedOn={answer.commentedOn}
                    comment={answer.comment}
                    isPrivate={answer.private}
                    privateMsg={`Private message, only visible to you and
                                              ${
                                                user && user.employeeId === answer.commentedBy.employeeId
                                                  ? ` ${
                                                      answer.whoCanView &&
                                                      answer.whoCanView.length &&
                                                      answer.whoCanView[0].empName
                                                    }`
                                                  : ` ${answer.commentedBy.empName}`
                                              }`}
                  />
                  <ReplyThread answer={answer} selectedColor={backGroundColor[3]} />
                </React.Fragment>
              );
            })
          ) : (
            <div>
              {props.managerView ? (
                !(reviewDays <= configReviewedDays) ? (
                  <div className="view_comment_box_row">No Comment</div>
                ) : null
              ) : (
                props.editable && <div className="view_comment_box_row">No Comment</div>
              )}{" "}
            </div>
          )}
          {commentFlag && reviewDays <= configReviewedDays && (
            <ObjectiveCommentBox
              id={objective.userObjectiveId}
              employee={user}
              style={props.style}
              selectedColor={backGroundColor[4]}
              managerCommentHandle={managerCommentHandle}
              managerPrivateCommentHandle={managerPrivateCommentHandle}
              changeHandle={(event) => changeHandle(event.target.value)}
              comment={comment}
              chars={chars}
              managerView={props.managerView}
              viewMoreFlag={viewMoreFlag}
            />
          )}
          <div className={style.view_all_objective_comment}>
            <span
              className={(commentObj && commentObj.length < 1) || commentObj === null ? style.disabled : ""}
              onClick={() => {
                if ((commentObj && commentObj.length < 1) || commentObj === null) return false;
                setViewMoreFlag(!viewMoreFlag);
              }}
            >
              {viewMoreFlag ? "View Less" : "View More"}
            </span>
          </div>
        </div>
      </Collapse>
      {oneOnOnecomment &&
        oneOnOnecomment.map((value) => <OneOnOneCommentAnswer comment={value} key={value.oneOnOneAgendaId} />)}
      {isOpenOneOnOne && (
        <OneOnOneCommentBox
          employeeProfile={surveyCycle && surveyCycle.employeeProfile}
          page={props.page}
          surveyCycleStartDate={surveyCycle && surveyCycle.startDate}
          surveyCycleEndDate={surveyCycle && surveyCycle.endDate}
          oneOnOnecomment={oneOnOnecomment}
          setCloseOneOnOne={() => setIsOpenOneOnOne(false)}
          employee={user}
          reviewer={reviewer}
          surveyCycleId={props.surveyCycle.surveyCyclesId}
          objective={props.objective}
          commentFrom="Objective"
        />
      )}
    </div>
  );
}
