import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, DropdownButton } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import moment from "moment";
import style from "./SkillMaster.module.scss";
import * as actions from "store/actions/index";
import { errorToast, filterArray, successToast } from "util/general";
import { IC_INPUT_SEARCH, IC_PENCIL, IC_INFO, IC_COLLAPSE, IC_EXPAND, IC_SEARCH_RESET } from "const/imgCost";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";
import AddCategoryModal from "./AddCategoryModal/AddCategoryModal";
import AddSkillModal from "./AddSkillModal/AddSkillModal";
import { noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function SkillMaster() {
  const dispatch = useDispatch();
  const {
    isLoading,
    size,
    skilltoggleSuccess,
    errorAddCategory,
    successAddCategory,
    categoryWiseSkillList,
    skillmasterupdated,
    skilladdedinmaster,
    alreadyexist,
  } = useSelector((state) => state.skillMatrix);
  const [skillList, setSkillList] = useState([]);
  const [typeArr, setFilterType] = useState("ALL");
  const [searchText, setSearchText] = useState("");
  const [viewSkill, setViewSkill] = useState(false);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [updatedName, setUpdatedName] = useState(null);
  const [viewCategory, setViewCategory] = useState(false);
  const [edit, setEdit] = useState({ flag: false, id: null });
  const [open, setOpen] = useState({ flag: false, id: null });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [status, setStatus] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const handleCategory = (category) => {
    setViewCategory(true);
    setCurrentCategory(category);
  };
  const [show, setshow] = useState("");

  const hideCategory = (category) => {
    if (category) {
      const param = {
        skillCategoryName: category.trim(),
      };
      if (currentCategory) {
        param["categoryId"] = currentCategory.categoryId;
      }
      setViewCategory(false);
      dispatch(actions.addCategorySkillMaster(param));
      setCurrentCategory(null);
    } else {
      setViewCategory(false);
    }
  };

  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  useEffect(() => {
    if (categoryWiseSkillList && categoryWiseSkillList.length > 0) {
      setSkillList(categoryWiseSkillList);
      setSearchText("");
      if (show === "ACTIVE" || show === "INACTIVE") setFilterType(show);
      else setFilterType("ALL");
    }
  }, [categoryWiseSkillList, show]);

  useEffect(() => {
    const getskillMasterList = () => {
      dispatch(actions.getSkillMaster(true));
    };
    getskillMasterList();
  }, [dispatch]);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const reset = () => {
    setSearchText("");
  };

  const hideModal = () => {
    setViewCategory(false);
    setViewSkill(false);
  };

  useEffect(() => {
    filteredData.length > 0
      ? setSkillList(filteredData)
      : null
      ? setSkillList(filteredData)
      : setSkillList(categoryWiseSkillList);
  }, [filteredData, categoryWiseSkillList]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (skillList && skillList.length > 0) {
      filteredData = filterArray(skillList, filters);
      setFilteredData(filteredData);
    }
  };

  const onChangePage = (skillList) => {
    setPageOfItems(skillList);
  };

  useEffect(() => {
    if (skilladdedinmaster.success) successToast(skilladdedinmaster.message);
    if (alreadyexist.error) errorToast(alreadyexist.message);
    if (errorAddCategory.error) errorToast(errorAddCategory.message);
    if (successAddCategory.success) successToast(successAddCategory.message);
    if (skillmasterupdated.success) successToast(skillmasterupdated.message);
  }, [skilltoggleSuccess, skilladdedinmaster, alreadyexist, errorAddCategory, successAddCategory, skillmasterupdated]);

  const handleSkill = (category) => {
    setSelectedCategory(category);
    setViewSkill(true);
  };
  const hideSkill = (skill) => {
    if (selectedCategory.categoryId && selectedCategory.categoryName && skill) {
      let data = {
        skillName: skill.trim(),
        categoryId: selectedCategory.categoryId,
        categoryName: selectedCategory.categoryName,
        isActive: true,
      };
      dispatch(actions.addSkillInMaster(data, false));
    }
    setViewSkill(false);
  };

  const filterByRequest = (e) => {
    setOpen({
      flag: false,
      id: null,
    });
    const value = e.target.value;
    if (value === "ACTIVE") setFilterType("ACTIVE");
    if (value === "INACTIVE") setFilterType("INACTIVE");
    if (value === "ALL") setFilterType("ALL");
  };

  const handleToggleChange = (active, id, name, mode) => {
    if (typeArr === "ALL") {
      setshow("ALL");
    } else {
      if (!active) setshow("ACTIVE");
      else setshow("INACTIVE");
    }
    if (mode === "VIEW") {
      setEdit({
        flag: false,
        id: null,
      });
    }
    let data = {
      skillId: id,
      skillName: name,
      isActive: active,
    };
    setStatus(active);
    setUpdatedName(null);
    dispatch(actions.updateSkillMaster(data));
  };

  const handleSkillNameChange = (e) => {
    if (e.target.value) {
      setUpdatedName(e.target.value);
    } else {
      setUpdatedName(null);
    }
  };

  const getResultArray = (array_to_filter, skill_name, boolean_value) => {
    let result_arr = [];
    if (array_to_filter) {
      array_to_filter.map((data) => {
        if (data && data.skill.length > 0) {
          let skill_array = [];
          data.skill.filter((item) => {
            if (item.isActive === boolean_value) {
              if (item.skillName.toLowerCase().startsWith(skill_name.toLowerCase().trim())) {
                skill_array.push(item);
              } else {
                skill_array.push(item);
              }
            }
            return item;
          });
          if (skill_array.length > 0) {
            let obj = {
              categoryId: data.categoryId,
              categoryName: data.categoryName,
              skill: skill_array,
            };
            result_arr.push(obj);
          }
        }
        return data;
      });
    }
    return result_arr;
  };

  useEffect(() => {
    let initial_array = [];
    let masterSkillArray = [];
    let queryStr = "";
    if (searchText.length > 0) {
      setOpen({
        flag: false,
        id: null,
      });
      if (searchText.startsWith("#")) {
        queryStr = searchText.substring(1).trim();
        initial_array = categoryWiseSkillList.filter((data) =>
          data.categoryName.toLowerCase().includes(queryStr.toLowerCase().trim())
        );
      } else {
        categoryWiseSkillList.map((data) => {
          if (data && data.skill.length > 0) {
            let arr = data.skill.filter((item) =>
              item.skillName.toLowerCase().includes(searchText.toLowerCase().trim())
            );
            if (arr.length > 0) {
              initial_array.push(data);
            }
          }
          return data;
        });
      }
    } else {
      initial_array = categoryWiseSkillList;
    }
    let skill_name = "";
    if (!searchText.startsWith("#")) {
      skill_name = searchText.toLowerCase();
    }
    if (typeArr === "ACTIVE") {
      masterSkillArray = getResultArray(initial_array, skill_name, true);
    } else if (typeArr === "INACTIVE") {
      masterSkillArray = getResultArray(initial_array, skill_name, false);
    } else {
      masterSkillArray = initial_array;
    }
    setSkillList(masterSkillArray);
  }, [categoryWiseSkillList, searchText, typeArr]);

  return (
    <div className={style.skill_master_wrapper}>
      <SubNavigation type="Skill Matrix" />
      <Container>
        <Row>
          <Col md={6}>
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">
              Skill Matrix <span>Skill Master</span>
            </span>
          </Col>
          <Col lg={6} md={6} sm={6} className={[style.res_title_btn, "text-right"].join(" ")}>
            <button
              type="button"
              onClick={() => handleCategory(null)}
              className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
            >
              <strong>Create New Category</strong>
            </button>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className={[style.skm_wrapper, "box pb-0 mb-0"].join(" ")}>
          <div className="box-heading-wrapper justify-content-between d-flex align-items-center">
            <div className="check_in_search_bar">
              <Form>
                <Form.Group controlId="" className="form-postfix mb-0">
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="E.g. Java, React JS, UX"
                    name="searchBox"
                    className={className}
                    onChange={handleSearchInputChange}
                    value={searchText}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                  />
                  <Button className="close-button" type="reset" onClick={reset}>
                    <span className="close-icon"></span>
                  </Button>
                  <div className="form-postfix-icon">
                    <img src={IC_INPUT_SEARCH} title="" alt="" />
                  </div>
                </Form.Group>
              </Form>
              <div className={[style.filter_info_tooltip, "custom-tooltip"].join(" ")}>
                <div className="box-info">
                  <ReactSVG src={`${IC_INFO}`} title="" alt="info" />
                </div>
                <div className="info-attach-file">
                  <span className="attachment-title">Search Criteria</span>
                  <ul>
                    <li>If you want to search skill then search through Skill Name. </li>
                    <li>If you want to filter category use #.E.g. #category</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab_check_in_status">
              <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                <input
                  id="all"
                  type="radio"
                  name="status-check"
                  value="ALL"
                  checked={typeArr === "ALL"}
                  onChange={(e) => filterByRequest(e)}
                />
                <label htmlFor="all">All</label>
              </div>

              <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                <input
                  id="g1"
                  type="radio"
                  name="status-check"
                  value={"ACTIVE"}
                  checked={typeArr === "ACTIVE"}
                  onChange={(e) => filterByRequest(e)}
                />
                <label htmlFor="g1">Active</label>
              </div>

              <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                <input
                  id="g2"
                  type="radio"
                  name="status-check"
                  value={"INACTIVE"}
                  checked={typeArr === "INACTIVE"}
                  onChange={(e) => filterByRequest(e)}
                />
                <label htmlFor="g2">Inactive</label>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {pageOfItems && pageOfItems.length !== 0 ? (
              <div className={[style.skm_outer_table_wrapper, "outer-table-wrapper"].join(" ")}>
                <div className="box-light-gradient h38"></div>
                <div className={style.category_wrapper}>
                  {pageOfItems.map((category) => {
                    return (
                      <div className={style.category_item} key={category.categoryId}>
                        <div
                          className={[
                            style.category_topbar,
                            open.flag === true && open.id === category.categoryId ? "active" : "",
                          ].join(" ")}
                        >
                          <div
                            className={style.cetegory_info}
                            onClick={() => {
                              if (open.id !== category.categoryId) {
                                setOpen({ flag: true, id: category.categoryId });
                              } else {
                                setOpen({ flag: !open.flag, id: category.categoryId });
                              }
                            }}
                          >
                            <i className={style.expand_collapse_wrapper}>
                              <img
                                src={open.id === category.categoryId && open.flag ? IC_COLLAPSE : IC_EXPAND}
                                className={open.id === category.categoryId ? style.collapse : style.expand}
                                alt="Collapse"
                              />
                            </i>
                            <span className={style.category_name}>{category.categoryName}</span>
                          </div>
                          <div className="action-dropdown-wrapper">
                            <DropdownButton
                              alignRight
                              title={
                                <div className="action-dropdown">
                                  <span className="dots"></span>
                                  <span className="dots"></span>
                                  <span className="dots"></span>
                                </div>
                              }
                              variant="a"
                            >
                              <div onClick={() => handleSkill(category)} className="dropdown-item">
                                <div className="dropdown_icon">
                                  <ReactSVG src={`${IC_EXPAND}`} alt="" />
                                </div>
                                <div className="dropdown_item">Add Skill</div>
                              </div>
                              <div onClick={() => handleCategory(category)} className="dropdown-item">
                                <div className="dropdown_icon">
                                  <ReactSVG src={`${IC_PENCIL}`} alt="" />
                                </div>
                                <div className="dropdown_item">Edit Category</div>
                              </div>
                            </DropdownButton>
                          </div>
                        </div>
                        {open.id === category.categoryId && open.flag && (
                          <>
                            {category.skill.length > 0 ? (
                              <div className={[style.skill_table, "custom-table", "table-theme-1"].join(" ")}>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Created On</th>
                                      <th>Modified On</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {category.skill.map((skill) => {
                                      let formatted_createdDate, formatted_modifiedDate;
                                      if (skill.createdDate)
                                        formatted_createdDate = moment(skill.createdDate.split(".")[0]).format(
                                          "MMMM D, YYYY"
                                        );
                                      if (skill.modifiedDate)
                                        formatted_modifiedDate = moment(skill.modifiedDate.split(".")[0]).format(
                                          "MMMM D, YYYY"
                                        );
                                      return (
                                        <tr key={skill.skillId}>
                                          <td>
                                            {edit.id === skill.skillId && edit.flag === true ? (
                                              <Form.Group>
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Update Skill"
                                                  defaultValue={updatedName || skill.skillName}
                                                  onChange={handleSkillNameChange}
                                                />
                                              </Form.Group>
                                            ) : (
                                              <span>{skill.skillName}</span>
                                            )}
                                          </td>
                                          <td>
                                            <span>{formatted_createdDate ? formatted_createdDate : "-"}</span>
                                          </td>
                                          <td>
                                            <span>{formatted_modifiedDate ? formatted_modifiedDate : "-"}</span>
                                          </td>
                                          <td>
                                            <div className={style.switches}>
                                              <Form>
                                                <Form.Check
                                                  type="switch"
                                                  id={skill.skillId}
                                                  label="Active"
                                                  checked={edit.id === skill.skillId ? status : skill.isActive}
                                                  onChange={() => {
                                                    edit.flag
                                                      ? setStatus(!status)
                                                      : handleToggleChange(
                                                          !skill.isActive,
                                                          skill.skillId,
                                                          skill.skillName,
                                                          "VIEW"
                                                        );
                                                  }}
                                                />
                                              </Form>
                                            </div>
                                          </td>
                                          <td>
                                            <span className={style.add_action}>
                                              {edit.id === skill.skillId && edit.flag === true ? (
                                                <div className={style.edit_wrapper}>
                                                  <span
                                                    className={[style.save, !updatedName && "disable-ele"].join(" ")}
                                                  >
                                                    <i
                                                      className={style.icon_edit}
                                                      onClick={() => {
                                                        setEdit({ flag: false, id: skill.skillId });
                                                        handleToggleChange(status, skill.skillId, updatedName, "EDIT");
                                                      }}
                                                    ></i>
                                                  </span>
                                                  <span className={style.cancel}>
                                                    <ReactSVG
                                                      src={`${IC_SEARCH_RESET}`}
                                                      alt="Edit"
                                                      onClick={() => {
                                                        setEdit({ flag: false, id: null });
                                                        setUpdatedName(skill.skillName);
                                                        setStatus(skill.isActive);
                                                      }}
                                                    ></ReactSVG>
                                                  </span>
                                                </div>
                                              ) : (
                                                <span className={style.edit}>
                                                  <ReactSVG
                                                    src={`${IC_PENCIL}`}
                                                    alt="Edit"
                                                    onClick={() => {
                                                      setEdit({ flag: true, id: skill.skillId });
                                                      setUpdatedName(skill.skillName);
                                                      setStatus(skill.isActive);
                                                    }}
                                                  />
                                                </span>
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <div className="no-record-found-wrpr mt-3">No Record Found</div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>
                <div className={[style.skm_outer_table_wrapper, "outer-table-wrapper"].join(" ")}>
                  <div className="gap30"></div>
                  <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                  <div className="gap30"></div>
                  <div className="gap40"></div>
                </div>
              </div>
            )}
          </>
        )}
        {categoryWiseSkillList && categoryWiseSkillList.length > 0 ? (
          <Pagination pageSize={size} items={skillList} onChangePage={onChangePage} resetData={resetData} />
        ) : null}
      </Container>
      {viewCategory && (
        <AddCategoryModal
          show={viewCategory}
          hide={hideCategory}
          currentCategory={currentCategory}
          hideModal={hideModal}
        />
      )}
      {viewSkill && (
        <AddSkillModal
          show={viewSkill}
          hide={hideSkill}
          category={selectedCategory && selectedCategory.categoryName}
          hideModal={hideModal}
        />
      )}
    </div>
  );
}
