import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { backGroundColor } from "const/constValue";
import { ratingOptions } from "const/options";
import { IC_ACC_ARROW_DOWN, IC_EDIT_OBJECTIVE, CANCEL_ROUND, SUBMIT_ROUND, IC_INFO } from "const/imgCost";
import { errorToast, objectiveStatus, ratingValue } from "util/general";
import CommentsTop from "../../../CommentBox/CommentsTop";
import ObjectiveUserStatus from "../../../employeeObjectives/objectiveUserStatus";
import KeyResultTable from "../../../KeyResultTable/KeyResultTable";
import StartAndEndDatePicker from "../../CreatePerformanceCycle/StartAndEndDatePicker";
import CommentBox from "../../SelfAssessment/Components/CommentBox";
import InitiativeModal from "../../SelfAssessment/Components/InitiativeModal";
import style from "./DeallocationMemberDetails.module.scss";
import * as actions from "store/actions/index";
import {
  SetValueInUserObjective,
  SetObjectiveInUserObjective,
  SetKeyPointRatingAndComment,
} from "store/actions/deAllocation";
import KeyResultCommentModal from "../../CycleMemberProfile/MemberProfileObjecive/ObjeciveModal/KeyResultCommentModal";
import UniversityKeyResult from "../../../UniversityKeyResult/UniversityKeyResult";
import Comment from "../../../CommentBox/Comment";
import { endOfDay, dateFormat1, toDate } from "util/dateUtils";

function DeallocationObjectiveTracking({
  objectives,
  loggedInUser,
  managersList,
  isSubmitted,
  employeeId,
  employeeIds,
  objectiveLevelError,
}) {
  const dispatch = useDispatch();
  const [initiativeObject, setInitiativeObject] = useState();
  const [isModal, setIsModel] = useState(false);
  const [keyPoint, setkeyPoint] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [modalObjective, setModalObjective] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const [viewMoreFlag, setViewMoreFlag] = useState(false);
  //update key result wise rating

  const handleKeyRating = (data, keyId, userObjectiveId) => {
    dispatch(SetKeyPointRatingAndComment("rating", data.value, objectives, keyId, userObjectiveId));
    let keyPointVal = [
      {
        keyId: keyId,
        rating: data.value,
      },
    ];
    let body = {
      userObjectiveId: userObjectiveId,
      employeeId: employeeId,
      managerId: loggedInUser?.employeeId,
      keyPoints: keyPointVal,
      isDraft: true,
      objfeedbackStatus: "COMPLETED",
      subGroupId: employeeIds?.subGroupId,
      subGroupName: employeeIds?.subGroupName,
      parentGroupId: employeeIds?.parentGroupId,
      parentGroupName: employeeIds?.parentGroupName,
      managerSubGroupId: loggedInUser?.subGroupId,
      managerSubGroupName: loggedInUser?.subGroupName,
      managerParentGroupId: loggedInUser?.parentGroupId,
      managerParentGroupName: loggedInUser?.parentGroupName,
    };
    dispatch(actions.UpdateObjectiveRatingAndComment("MANAGER", body));
  };

  //save key result comment
  const saveKeyResultComment = (comment) => {
    if (comment.trim()) {
      setCommentModal(false);
      let keyPointVal = [
        {
          keyId: keyPoint?.keyId,
          rating: keyPoint.rating,
          comment: comment,
        },
      ];
      let body = {
        userObjectiveId: modalObjective?.userObjectiveId,
        employeeId: employeeId,
        managerId: loggedInUser?.employeeId,
        keyPoints: keyPointVal,
        isDraft: true,
        objfeedbackStatus: "COMPLETED",
        subGroupId: employeeIds?.subGroupId,
        subGroupName: employeeIds?.subGroupName,
        parentGroupId: employeeIds?.parentGroupId,
        parentGroupName: employeeIds?.parentGroupName,
        managerSubGroupId: loggedInUser?.subGroupId,
        managerSubGroupName: loggedInUser?.subGroupName,
        managerParentGroupId: loggedInUser?.parentGroupId,
        managerParentGroupName: loggedInUser?.parentGroupName,
      };
      dispatch(actions.UpdateObjectiveRatingAndComment("MANAGER", body));
      dispatch(
        SetKeyPointRatingAndComment("comment", comment, objectives, keyPoint?.keyId, modalObjective?.userObjectiveId)
      );
    } else return null;
  };

  //set value in objective redux store
  const handleSetValue = (value, userObjectiveId, key) => {
    dispatch(SetValueInUserObjective(value, userObjectiveId, objectives, key));
  };

  //change rating and comment
  const ratingAndCommentUpdate = (value, userObjectiveId, key, definedBy, objfeedbackStatus) => {
    handleSetValue(value, userObjectiveId, key);
    let body = {
      userObjectiveId: userObjectiveId,
      employeeId: employeeId,
      managerId: loggedInUser.employeeId,
      isDraft: true,
      [key]: value,
      objfeedbackStatus,
      subGroupId: employeeIds.subGroupId,
      subGroupName: employeeIds.subGroupName,
      parentGroupId: employeeIds.parentGroupId,
      parentGroupName: employeeIds.parentGroupName,
      managerSubGroupId: loggedInUser.subGroupId,
      managerSubGroupName: loggedInUser.subGroupName,
      managerParentGroupId: loggedInUser.parentGroupId,
      managerParentGroupName: loggedInUser.parentGroupName,
    };
    dispatch(actions.UpdateObjectiveRatingAndComment(definedBy, body));
  };

  //change timeline of objective with validation
  const handleSubmitTimeline = (obj) => {
    let objStartDate = startDate || obj.startDate;
    let objEndDate = endDate || obj.endDate;
    const qStartDate = getStartDate().toISOString(); //quarter start date
    const qEndDate = getEndDate().toISOString(); //quarter end date
    if ((objStartDate < qStartDate && objEndDate < qStartDate) || objStartDate > qEndDate) {
      errorToast("Date range should fall in current quarter.");
    } else {
      let updatedObjective = {
        ...obj,
        startDate: startDate ? startDate.toISOString() : obj.startDate,
        endDate: endDate ? endDate.toISOString() : obj.endDate,
        isEditTimeline: false,
      };
      dispatch(SetObjectiveInUserObjective(updatedObjective, obj.userObjectiveId, objectives));
      let body = {
        userObjectiveId: obj.userObjectiveId,
        employeeId: employeeId,
        managerId: loggedInUser?.employeeId,
        isDraft: true,
        startDate: startDate || undefined,
        endDate: endOfDay(obj.endDate),
        subGroupId: employeeIds?.subGroupId,
        subGroupName: employeeIds?.subGroupName,
        parentGroupId: employeeIds?.parentGroupId,
        parentGroupName: employeeIds?.parentGroupName,
        managerSubGroupId: loggedInUser?.subGroupId,
        managerSubGroupName: loggedInUser?.subGroupName,
        managerParentGroupId: loggedInUser?.parentGroupId,
        managerParentGroupName: loggedInUser?.parentGroupName,
      };
      dispatch(actions.UpdateObjectiveRatingAndComment(obj.definedBy, body));
      setStartDate();
      setEndDate();
    }
  };

  const handleCancelTimeline = (userObjectiveId) => {
    setStartDate();
    setEndDate();
    handleSetValue(false, userObjectiveId, "isEditTimeline");
  };

  //change objfeedbackStatus
  const handleObjectiveStatusChange = (value, userObjectiveId, key, definedBy) => {
    handleSetValue(value, userObjectiveId, key);
    let body = {
      userObjectiveId: userObjectiveId,
      employeeId: employeeId,
      managerId: loggedInUser?.employeeId,
      isDraft: true,
      objfeedbackStatus: value,
      subGroupId: employeeIds?.subGroupId,
      subGroupName: employeeIds?.subGroupName,
      parentGroupId: employeeIds?.parentGroupId,
      parentGroupName: employeeIds?.parentGroupName,
      managerSubGroupId: loggedInUser?.subGroupId,
      managerSubGroupName: loggedInUser?.subGroupName,
      managerParentGroupId: loggedInUser?.parentGroupId,
      managerParentGroupName: loggedInUser?.parentGroupName,
    };
    if (value !== "EVALUATE") dispatch(actions.UpdateObjectiveRatingAndComment(definedBy, body));
  };

  //get current Quarter start and end date from current date for validation
  const getStartDate = () => {
    var now = new Date();
    var quarter = Math.floor(now.getMonth() / 3);
    var startDate = new Date(now.getFullYear(), quarter * 3, 1);
    return startDate;
  };
  const getEndDate = () => {
    var startDate = getStartDate();
    var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
    return endDate;
  };

  let optionsManagerList = [];
  if (managersList?.length > 0) {
    managersList.forEach((v) => {
      if (v.employeeId !== loggedInUser?.employeeId) {
        optionsManagerList.push({ value: v.employeeId, label: v.empName });
      }
      return v;
    });
    optionsManagerList.push({ value: loggedInUser.employeeId, label: loggedInUser.displayName });
  } else optionsManagerList.push({ value: loggedInUser.employeeId, label: loggedInUser.displayName });

  return (
    <div className={["box", style.project_box].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-light-gradient h38"></div>
        <div className="box-heading">
          <h2>Objective Tracking</h2>
          <h3>Objective status and progress helps tracking down things in easy way</h3>
        </div>
      </div>
      <div className="box-inner-pad p-0">
        <div className={style.objective_tracking_note_wrapper}>
          <span>Note:</span> In every Objective, it is mandatory to select any one option provided below
        </div>
        <Accordion defaultActiveKey={""} className={["custom-accordion", style.member_obj].join(" ")}>
          {objectives &&
            objectives.map((objective, index) => (
              <Card key={objective.userObjectiveId} className="overflow-visible">
                <Card.Header>
                  <div className={style.employeeObjectives_process_indicate}>
                    <ObjectiveUserStatus objective={objective} page="{props.page}" editable="{props.editable}" />
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    disabled={
                      (objective.definedBy === "MANAGER" || objective.definedBy === "SERVICE_LINE") &&
                      objective.objfeedbackStatus === "COMPLETED"
                    }
                    variant="link"
                    eventKey={`${index}`}
                    onClick={(e) => {
                      if (
                        !(
                          (objective.definedBy === "MANAGER" || objective.defineBy === "SERVICE_LINE") &&
                          objective.objfeedbackStatus === "COMPLETED"
                        )
                      ) {
                        if (e.currentTarget.classList.contains("show")) {
                          e.currentTarget.classList.remove("show");
                        } else {
                          e.currentTarget.classList.add("show");
                        }
                      }
                    }}
                    className={[
                      (objective.definedBy === "MANAGER" || objective.defineBy === "SERVICE_LINE") &&
                      objective.objfeedbackStatus === "COMPLETED"
                        ? "show"
                        : "",
                      "indicator_manager_view",
                    ].join(" ")}
                  >
                    <div className="accordion-heading">
                      <div className="accordion-heading-left">
                        <div className={style.employeeObjectives_heading_wrapper}>
                          <h3>{objective.title}</h3>
                        </div>
                      </div>
                      <div className="accordion-heading-right position-relative">
                        <div className={style.employeeObjectives_process_complete}>{objective.percentage}%</div>
                        <div className={style.emp_obj_end_days}>{objectiveStatus(objective.status)}</div>
                      </div>
                    </div>

                    <div className="accordion-icon">
                      <ReactSVG src={IC_ACC_ARROW_DOWN} />
                    </div>
                  </Accordion.Toggle>
                  <div className={style.key_initiative_wrapper}>
                    <span>{objective.keyPoints.length} Key Results are aligned</span>
                    <span
                      className={style.initiative_wrapper}
                      onClick={() => {
                        if (objective.plans) {
                          setIsModel(!isModal);
                          setInitiativeObject(objective);
                        }
                      }}
                    >
                      {(objective.plans && objective.plans.length) || 0} Initiatives Founds
                    </span>
                  </div>
                  <div className={style.timeframe_wrapper}>
                    {!objective.isEditTimeline && (
                      <div className={style.timeframe_info}>
                        <span>Timeframe</span>
                        <>
                          &nbsp;:&nbsp;
                          {objective.startDate &&
                            objective.endDate &&
                            dateFormat1(objective.startDate) + " to " + dateFormat1(objective.endDate)}
                        </>
                        {!(objective.status === "COMPLETED" || isSubmitted) && (
                          <div
                            className={style.edit_button}
                            onClick={() =>
                              handleSetValue(
                                objective.isEditTimeline ? !objective.isEditTimeline : true,
                                objective.userObjectiveId,
                                "isEditTimeline"
                              )
                            }
                          >
                            <ReactSVG src={IC_EDIT_OBJECTIVE} alt="" />
                          </div>
                        )}
                      </div>
                    )}
                    {objective.isEditTimeline && (
                      <div className={style.timeframe_edit_box}>
                        <div className={style.timeframe}>Timeframe :</div>
                        <div className={style.date_picker_wrapper}>
                          <div className="calendar_outer_wrapper form-group">
                            <StartAndEndDatePicker
                              startDate={startDate || toDate(objective.startDate)}
                              endDate={endDate || toDate(objective.endDate)}
                              handleStartDate={(date) => {
                                setStartDate(date);
                              }}
                              handleEndDate={(date) => {
                                setEndDate(date);
                              }}
                            />
                          </div>
                          <div className={style.btn_round} onClick={() => handleSubmitTimeline(objective)}>
                            <ReactSVG src={SUBMIT_ROUND} alt="" />
                          </div>
                          <div
                            className={style.btn_round}
                            onClick={() => handleCancelTimeline(objective.userObjectiveId)}
                          >
                            <ReactSVG src={CANCEL_ROUND} alt="" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {objective.status !== "COMPLETED" && (
                    <div
                      className={[
                        objective.isSubmitError &&
                        (!objective.objfeedbackStatus || objective.objfeedbackStatus === "EVALUATE")
                          ? style.error
                          : "",
                      ].join(" ")}
                    >
                      <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm mt-3">
                        <input
                          id={"CHANGE_OWNERSHIP" + objective.userObjectiveId}
                          type="radio"
                          disabled={isSubmitted}
                          name={"OBJECTIVE_FILL_" + objective.userObjectiveId}
                          value={"CONTINUE"}
                          checked={objective.objfeedbackStatus === "CONTINUE"}
                          onChange={() => {
                            handleObjectiveStatusChange(
                              "CONTINUE",
                              objective.userObjectiveId,
                              "objfeedbackStatus",
                              objective.definedBy
                            );
                          }}
                        />
                        <label htmlFor={"CHANGE_OWNERSHIP" + objective.userObjectiveId}>
                          <div className={style.text_label}>
                            <div>Continue Objective</div>
                            <div className={[style.info_tooltip, "custom-tooltip", "custom-tooltip-top"].join(" ")}>
                              <div className="box-info">
                                <ReactSVG src={IC_INFO} title="" alt="info" />
                              </div>
                              <div className="info-attach-file">
                                Objective will be continued and current manager will be able to assess it
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="custom-checkbox  custom-checkbox-rounded custom-checkbox-sm mt-1">
                        <input
                          id={"EVALUATE" + objective.userObjectiveId}
                          type="radio"
                          disabled={isSubmitted}
                          name={"OBJECTIVE_FILL_" + objective.userObjectiveId}
                          value={"EVALUATE"}
                          checked={
                            objective.objfeedbackStatus === "EVALUATE" ||
                            objective.objfeedbackStatus === "INVALID" ||
                            objective.objfeedbackStatus === "COMPLETED"
                          }
                          onChange={() =>
                            handleObjectiveStatusChange(
                              "EVALUATE",
                              objective.userObjectiveId,
                              "objfeedbackStatus",
                              objective.definedBy
                            )
                          }
                        />
                        <label htmlFor={"EVALUATE" + objective.userObjectiveId}>
                          <div className={style.text_label}>
                            <div>Evaluate Objective</div>
                            <div className={[style.info_tooltip2, "custom-tooltip", "custom-tooltip-top"].join(" ")}>
                              <div className="box-info">
                                <ReactSVG src={IC_INFO} title="" alt="info" />
                              </div>
                              <div className="info-attach-file">
                                <ul>
                                  <li>
                                    By Assessing Objective, it will be marked as completed Objective where manager has
                                    to rate.
                                  </li>
                                  <li>By Invalidating Objective, it will be considered as in-active.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      {(objective.objfeedbackStatus === "EVALUATE" ||
                        objective.objfeedbackStatus === "INVALID" ||
                        objective.objfeedbackStatus === "COMPLETED") && (
                        <ul className={style.form_option_list}>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm">
                              <input
                                id={"COMPLETE_OBJECTIVE" + objective.userObjectiveId}
                                type="radio"
                                disabled={isSubmitted}
                                name={"EVALUATE" + objective.userObjectiveId}
                                value={"COMPLETED"}
                                checked={objective.objfeedbackStatus === "COMPLETED"}
                                onChange={() => {
                                  handleObjectiveStatusChange(
                                    "COMPLETED",
                                    objective.userObjectiveId,
                                    "objfeedbackStatus",
                                    objective.definedBy
                                  );
                                }}
                              />
                              <label htmlFor={"COMPLETE_OBJECTIVE" + objective.userObjectiveId}>Assess Objective</label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm">
                              <input
                                id={"MARK_AS_INVALID" + objective.userObjectiveId}
                                type="radio"
                                disabled={isSubmitted}
                                name={"EVALUATE" + objective.userObjectiveId}
                                value={"INVALID"}
                                checked={objective.objfeedbackStatus === "INVALID"}
                                onChange={() =>
                                  handleObjectiveStatusChange(
                                    "INVALID",
                                    objective.userObjectiveId,
                                    "objfeedbackStatus",
                                    objective.definedBy
                                  )
                                }
                              />
                              <label htmlFor={"MARK_AS_INVALID" + objective.userObjectiveId}>
                                Invalidate Objective
                              </label>
                            </div>
                          </li>
                        </ul>
                      )}
                      <div className={style.commentbox_wrapper}>
                        {objective.objfeedbackStatus === "INVALID" && (
                          <div className={["view_comment_box_wrapper", isSubmitted && "bg-white"].join(" ")}>
                            <CommentsTop heading={"Give reason behind In-Validating this Objective"} />
                            {isSubmitted ? (
                              <Comment
                                selectedColor={backGroundColor[0]}
                                deletable={false}
                                image={objective.managerData?.displayPicture || objective.managerData?.empInitial}
                                empName={objective.managerData?.empName}
                                comment={objective.inValidateComment}
                              />
                            ) : (
                              <CommentBox
                                image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                                blurHandler={(e) =>
                                  ratingAndCommentUpdate(
                                    e.target.value,
                                    objective.userObjectiveId,
                                    "inValidateComment",
                                    objective.definedBy,
                                    "INVALID"
                                  )
                                }
                                comment={objective.inValidateComment}
                                selectedColor={backGroundColor[0]}
                                commentLength={1000}
                                error={objective.isSubmitError && !objective.inValidateComment?.trim()}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Card.Header>
                <Accordion.Collapse
                  eventKey={`${index}`}
                  className={
                    (objective.definedBy === "MANAGER" || objective.definedBy === "SERVICE_LINE") &&
                    objective.objfeedbackStatus === "COMPLETED"
                      ? "show"
                      : ""
                  }
                >
                  <Card.Body className="p-0 mt-4">
                    <div className={style.idp_info_list}>
                      {(objective.definedBy === "MANAGER" || objective.definedBy === "SERVICE_LINE") && (
                        <KeyResultTable
                          keypoints={objective.keyPoints}
                          edit={
                            objective.objfeedbackStatus === "COMPLETED" ||
                            (objective.status === "COMPLETED" && !objective?.objfeedbackStatus)
                          }
                          objectiveError={objectiveLevelError.filter((v) => v.type === "RATING")}
                          commentView={false}
                          handleKeyRating={(data, keyId) => handleKeyRating(data, keyId, objective.userObjectiveId)}
                          clickCommentModal={(point) => {
                            setCommentModal(true);
                            setkeyPoint(point);
                            setModalObjective(objective);
                          }}
                          isDisabled={
                            (objective.status === "COMPLETED" && !objective?.objfeedbackStatus) || isSubmitted
                          }
                        />
                      )}
                      {objective.definedBy === "UNIVERSITY" && <UniversityKeyResult keyPoints={objective.keyPoints} />}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
                {objective.definedBy === "UNIVERSITY" &&
                  (objective.objfeedbackStatus === "COMPLETED" ||
                    (objective.status === "COMPLETED" && !objective?.objfeedbackStatus)) && (
                    <Card.Footer>
                      <div className="card-ft-wrapper">
                        <span>Rate this progress by selecting a reasonable value</span>
                        <div className="card_action">
                          <Select
                            options={ratingOptions}
                            className={[
                              "custom-default-dd-box",
                              objectiveLevelError?.length > 0 &&
                                objectiveLevelError.find(
                                  (v) => v.type === "RATING" && v.id === objective.userObjectiveId
                                )?.error &&
                                "error-box",
                            ].join(" ")} //props.objectiveError && "error-box"
                            classNamePrefix="select"
                            isSearchable={false}
                            value={ratingValue(objective.rating || objective.ratingRemarks)}
                            isDisabled={isSubmitted || objective.status === "COMPLETED"}
                            onChange={(value) => {
                              ratingAndCommentUpdate(
                                value.value,
                                objective.userObjectiveId,
                                "rating",
                                "UNIVERSITY",
                                "COMPLETED"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Card.Footer>
                  )}
                {objective.objfeedbackStatus === "COMPLETED" && (
                  <div className={["view_comment_box_wrapper", isSubmitted && "bg-white"].join(" ")}>
                    <CommentsTop heading="Share your view(s)" />
                    {isSubmitted && (
                      <Comment
                        selectedColor={backGroundColor[1]}
                        deletable={false}
                        image={objective.managerData?.displayPicture || objective.managerData?.empInitial}
                        empName={objective.managerData?.empName}
                        comment={objective.objectiveComment}
                        isPrivate={false}
                      />
                    )}
                    {objective.comment && viewMoreFlag && (
                      <div className="assess_prev_comment_wrapper">
                        <h5>Previous Comment(s)</h5>
                        {objective.comment
                          .filter((answer) => {
                            if (answer.whoCanView && answer.whoCanView.length === 0) {
                              return true;
                            } else {
                              if (answer.private) {
                                if (answer.commentedBy.employeeId === loggedInUser?.employeeId) {
                                  return true;
                                } else if (
                                  answer.whoCanView &&
                                  answer.whoCanView[0].employeeId === loggedInUser?.employeeId
                                ) {
                                  return true;
                                } else {
                                  return false;
                                }
                              } else {
                                return true;
                              }
                            }
                          })
                          .filter(() => viewMoreFlag)
                          .map((answer, index) => {
                            return (
                              <Comment
                                selectedColor={backGroundColor[0]}
                                key={index}
                                deletable={false}
                                image={answer.commentedBy?.displayPicture}
                                empName={answer.commentedBy?.empName}
                                commentedOn={answer.commentedOn}
                                comment={answer.comment}
                                isPrivate={answer.private}
                                privateMsg={`Private message, only visible to you and
                                ${
                                  loggedInUser?.employeeId === answer.commentedBy.employeeId
                                    ? ` ${answer.whoCanView?.length > 0 && answer.whoCanView[0].empName}`
                                    : ` ${answer.commentedBy?.empName}`
                                }`}
                              />
                            );
                          })}
                      </div>
                    )}
                    {/* Previous Comment(s) End */}
                    {!isSubmitted && (
                      <CommentBox
                        selectedColor={backGroundColor[0]}
                        error={objective.isSubmitError && !objective.objectiveComment}
                        blurHandler={(e) =>
                          ratingAndCommentUpdate(
                            e.target.value,
                            objective.userObjectiveId,
                            "objectiveComment",
                            objective.definedBy,
                            "COMPLETED"
                          )
                        }
                        image={loggedInUser.displayPicture || loggedInUser.empInitial}
                        comment={objective.objectiveComment || ""}
                        commentLength={1000}
                      />
                    )}
                    {objective.comment && objective.comment.length > 0 && (
                      <div className={style.view_all_objective_comment}>
                        <span onClick={() => setViewMoreFlag(!viewMoreFlag)}>
                          {viewMoreFlag ? "View Less" : "View More"}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </Card>
            ))}
        </Accordion>
      </div>
      {isModal && (
        <InitiativeModal
          empName={initiativeObject.managerData?.empName}
          show={true}
          hide={() => setIsModel(!isModal)}
          plans={initiativeObject.plans}
          percentage={initiativeObject.percentage}
          status={initiativeObject.status}
          title={initiativeObject.title}
        />
      )}
      {commentModal && (
        <KeyResultCommentModal
          loggedInUser={loggedInUser}
          objective={modalObjective}
          keyPoint={keyPoint}
          isSubmitted={isSubmitted || (modalObjective?.status === "COMPLETED" && !modalObjective?.objfeedbackStatus)}
          saveComment={saveKeyResultComment}
          hide={() => setCommentModal(false)}
          show={commentModal}
        />
      )}
    </div>
  );
}

export default DeallocationObjectiveTracking;
