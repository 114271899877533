import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button, Form } from "react-bootstrap";
import style from "./QuestionTemplateModal.module.scss";
import { useHistory } from "react-router-dom";
import { IC_INPUT_SEARCH } from "../../../../const/imgCost";
import { filterArray } from "../../../../util/general";
import routePath from "const/routePath";
import storage from "util/storage";

const QuestionTemplateModal = ({
  list,
  show,
  hide,
  previewPathname,
  isSelectModal,
  selectTemplate,
  type,
  numberOfQuestions,
}) => {
  type === "Quiz" || type === "Survey" ? storage.set("campaignType", type) : storage.remove("campaignType");
  type === "Quiz" ? storage.set("numberOfQuestions", numberOfQuestions) : storage.remove("numberOfQuestions");

  const history = useHistory();
  const [filteredData, setFilteredData] = useState(list);
  let [searchFilter, setSearchFilter] = useState("");
  useEffect(() => {
    setFilteredData(list);
    return () => {
      setFilteredData([]);
    };
  }, [list]);

  let className = "form-control-rounded form-search-item";
  if (searchFilter.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  const handleCreateClick = () => {
    history.push(routePath.QUESTION_BUILDER);
  };

  const search = (e) => {
    let filteredData = null;
    const filters = {
      templateName: e.target.value,
    };
    filteredData = filterArray(list, filters);
    setFilteredData(filteredData);
    setSearchFilter(e.target.value);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      templateName: "",
    };
    filteredData = filterArray(list, filters);
    setFilteredData(filteredData);
    setSearchFilter("");
  };

  return (
    <Modal
      show={show}
      onHide={() => hide(isSelectModal)}
      size="lg"
      animation={false}
      className="custom_modal_popup que_template_modal"
    >
      <div className="custom_modal_container custom_lg_modal_container">
        <Modal.Header className={style.mHeader}>
          <Row>
            <Col lg={6}>
              <Modal.Title>
                Question Template
                <span>Click on a question template to add or tap create to add new</span>
              </Modal.Title>
            </Col>
            <Col lg={6}>
              <div className={style.search_bar}>
                <Form className={style.allobjective_searchbar}>
                  <Form.Group controlId="" className="form-postfix mb-0 w-100">
                    <Form.Control
                      disabled={list && list.length > 0 ? false : true}
                      size="sm"
                      type="text"
                      placeholder="Search template"
                      name="searchBox"
                      className={className}
                      value={searchFilter}
                      onChange={search}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <Button className="close-button" onClick={resetSearch} type="reset">
                      <span className="close-icon"></span>
                    </Button>
                    <div className="form-postfix-icon">
                      <img src={IC_INPUT_SEARCH} title="" alt="" />
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Modal.Header>

        <Modal.Body className={style.mBody}>
          <div className="box-light-gradient"></div>
          <div className="custom-table table-theme-1">
            <div className="table-responsive">
              {filteredData && filteredData.length > 0 ? (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Template Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((value) => (
                      <tr key={value.questionTemplateId}>
                        <td onClick={() => selectTemplate(value.questionTemplateId, value.templateName, isSelectModal)}>
                          {value.templateName}
                        </td>
                        <td className={style.preview_heading}>
                          <span
                            className={style.templatePreview}
                            onClick={() => {
                              history.push({
                                pathname: previewPathname,
                                state: { template: value, hideButton: false },
                              });
                            }}
                          >
                            Preview
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className={style.no_templates}>
                  <span>No Templates found!</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="custom_modal_button justify-content-start">
          <Button variant="primary" onClick={handleCreateClick}>
            Create New Template
          </Button>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default QuestionTemplateModal;
