import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Button, Form, Col, Collapse } from "react-bootstrap";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import * as actions from "store/actions/index";
import storage from "util/storage";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import ThisOneOnOne from "components/OneOnOne/Components/ThisOneOnOne";
import { menuText } from "const/constValue";
import HideAndShow from "components/HideAndShow/HideAndShow";
import TalkingPointLists from "components/OneOnOne/EmployeeOneOnOne/CurrentOneOnOneDetails/TalkingPointLists";
import SharedNotes from "components/OneOnOne/Components/SharedNotes";
import MeetingNotes from "components/OneOnOne/Components/MeetingNotes";
import Spinner from "shared/Spinner/Spinner";
import { isEmpty } from "lodash";

export default function OneOnOneDetails(props) {
  const dispatch = useDispatch();
  const [hide, setHide] = useState(false);
  const loggedInUser = storage.getUser();
  const { oneOnOneData, oneOnOneWithUser, isOneOnOneLoading } = useSelector((state) => state.oneOnOne);
  const oneOnOneAgendaId = props.match.params.id;

  useEffect(() => {
    dispatch(actions.GetOneOnOneDataForHR(oneOnOneAgendaId, "HR"));
  }, []);

  if (isEmpty(oneOnOneData) || isOneOnOneLoading) {
    return <Spinner />;
  }

  const buttonText = oneOnOneData && oneOnOneData.isEnded ? "Ended" : "Lapsed";

  return (
    <div className={style.one_on_one_tp_wrapper}>
      <SubNavigation type="OKR Progress" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">{menuText.HR}</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.HR_ONE_ON_ONE}>{menuText.ONE_ON_ONE}</Link>
              <span>{oneOnOneWithUser.empName}</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={8}>
            <Form>
              <div className={["card"].join(" ")}>
                <div className="card_info">
                  <div className="card_title">
                    <span className="card_title_text">Talking Points</span>
                  </div>
                  <HideAndShow hide={hide} setHide={setHide} />
                </div>

                <Collapse in={!hide}>
                  <div className="card_details">
                    <div className={style.tp_inner_wrapper}>
                      {oneOnOneData &&
                        oneOnOneData.talkingPoints.map((value, index) => (
                          <TalkingPointLists
                            allSelect={false}
                            isEnded={oneOnOneData && oneOnOneData.isEnded}
                            deletable={oneOnOneData && oneOnOneData.scheduledDate ? false : true}
                            userId={"userId"}
                            disabled={true}
                            managerId={"managerId"}
                            markTalkingPoint={() => {}}
                            loggedInUser={loggedInUser}
                            deletePoint={() => {}}
                            key={value.talkingPointId}
                            style={style}
                            point={value}
                            index={index}
                          />
                        ))}
                    </div>
                  </div>
                </Collapse>
              </div>
              <SharedNotes
                isEnded={oneOnOneData && oneOnOneData.isEnded}
                disabled={true}
                loggedInUser={loggedInUser}
                image={loggedInUser && (loggedInUser.displayPicture || loggedInUser.empInitial)}
                handleComment={() => {}}
                sharedNotes={oneOnOneData && oneOnOneData.sharedNotes}
                comment={""}
                deleteSharedNote={() => {}}
                sendTalkingPoint={() => {}}
              />
              {oneOnOneData?.meetingNotes && <MeetingNotes meetingNotes={oneOnOneData.meetingNotes} />}
              <div className={["box box-flex-center", style.saveBox].join(" ")}>
                <div className={style.save_box_left}>
                  <Button className="btn btn-primary btn-disabled">{buttonText}</Button>
                </div>
              </div>
            </Form>
          </Col>
          <Col md={12} lg={4}>
            <ThisOneOnOne oneOnOne={oneOnOneData} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
