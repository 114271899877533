import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Container, Button, Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import moment from "moment";
import style from "./MmgEndrosement.module.scss";
import * as actions from "store/actions/index";
import { IMG_ENDORSEMENT_1, IMG_PM, IMG_SME, IMG_UNI, IC_INPUT_SEARCH, IC_INFO } from "const/imgCost";
import { errorToast, filterArray, successToast } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";
import NoDataFound from "../../../NodataFound/NoDataFound";
import { noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function MmgEndrosement() {
  const options = [
    { value: 1, label: "UNIVERSITY" },
    { value: 2, label: "SME" },
    { value: 3, label: "MANAGER" },
    { value: 4, label: "PEER" },
  ];
  const [employees, setEmployeeList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [employee, selectEmployee] = useState(null);
  const [skill, selectSkill] = useState(null);
  const [disableSkillDropdown, setDisableSkillDropdown] = useState(true);
  const [disableEndorserDropdown, setDisableEndorserDropdown] = useState(true);
  const [endorseType, selectEndorseType] = useState(null);
  const [endorserList, setEndorserList] = useState([]);
  const [endorser, selectEndorser] = useState(null);
  const [isDisable, setDisable] = useState(true);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [typeArr, setFilterType] = useState("ALL");
  const [totalReq, setTotalReq] = useState([]);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const {
    isLoading,
    endorsementSkill,
    employeeList,
    getMMGEndorseReq,
    size,
    getMMGEndorserList,
    sendMMGEndorseReqErrorResponse,
    sendMMGEndorseReqResponse,
  } = useSelector((state) => state.skillMatrix);

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (totalReq && totalReq.length > 0) {
      filteredData = filterArray(totalReq, filters);
      setFilteredData(filteredData);
    }
  };

  useEffect(() => {
    filteredData.length > 0
      ? setTotalReq(filteredData)
      : null
      ? setTotalReq(filteredData)
      : setTotalReq(getMMGEndorseReq);
  }, [filteredData, getMMGEndorseReq]);

  useEffect(() => {
    if (sendMMGEndorseReqResponse && !sendMMGEndorseReqResponse.error) {
      successToast(sendMMGEndorseReqResponse.message);
      selectEndorseType(null);
      selectEndorser(null);
      selectSkill(null);
      selectEmployee(null);
      setDisableSkillDropdown(true);
      setDisableEndorserDropdown(true);
      setSearchText("");
      setFilterType("ALL");
    }
    if (sendMMGEndorseReqErrorResponse?.error) errorToast(sendMMGEndorseReqErrorResponse.message);
  }, [sendMMGEndorseReqErrorResponse, sendMMGEndorseReqResponse]);

  useEffect(() => {
    const getMMGEndorseReqList = () => dispatch(actions.getMMGEndorseRequest);
    const getEmployeeList = () => dispatch(actions.employeeList);
    getMMGEndorseReqList();
    getEmployeeList();
  }, [dispatch]);

  useEffect(() => {
    const flag = endorseType ? (endorseType.value === 1 ? true : endorser ? true : false) : false;
    if (skill && employee && endorseType && flag) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [employee, endorseType, endorser, skill]);

  useEffect(() => {
    if (employeeList && employeeList.length > 0) {
      const array = [];
      employeeList.forEach((emp) => {
        const obj = {};
        obj["value"] = emp.employeeId;
        obj["label"] = emp.displayName;
        obj["parentGroupId"] = emp.parentGroupId;
        obj["subGroupId"] = emp.subGroupId;
        array.push(obj);
      });
      setEmployeeList(array);
    }
    if (getMMGEndorseReq && getMMGEndorseReq.length > 0) {
      setTotalReq(getMMGEndorseReq);
    }
  }, [employeeList, getMMGEndorseReq]);

  useEffect(() => {
    if (endorsementSkill && endorsementSkill.length > 0) {
      setDisableSkillDropdown(false);
      const array = [];
      endorsementSkill.forEach((sk) => {
        const obj = {};
        obj["value"] = sk.skillIdNameId;
        obj["label"] = sk.skillIdName;
        array.push(obj);
      });
      setSkills(array);
    }
  }, [endorsementSkill]);

  useEffect(() => {
    if (getMMGEndorserList && getMMGEndorserList.length > 0) {
      setDisableEndorserDropdown(false);
      const array = [];
      getMMGEndorserList.forEach((sk) => {
        const obj = {};
        obj["value"] = sk.employeeId;
        obj["label"] = sk.displayName;
        obj["parentGroupId"] = sk.parentGroupId;
        obj["subGroupId"] = sk.subGroupId;
        array.push(obj);
      });
      setEndorserList(array);
    }
  }, [getMMGEndorserList]);

  const setEmployee = (select) => {
    if (select) {
      selectEmployee(select);
      selectEndorser(null);
      setDisableEndorserDropdown(true);
      selectSkill(null);
      setDisableSkillDropdown(true);
      dispatch(
        actions.getEndorsementSkill({
          employeeId: select.value,
          parentGroupId: select.parentGroupId,
          subGroupId: select.subGroupId,
        })
      );
    } else {
      setTimeout(() => {
        setDisableSkillDropdown(true);
      }, 1000);
      setTimeout(() => {
        setDisableEndorserDropdown(true);
      }, 1000);
      selectEmployee(null);
      selectSkill(null);
      selectEndorser(null);
    }
  };

  const setSkill = (select) => {
    if (select) {
      selectSkill(select);
      selectEndorser(null);
      setDisableEndorserDropdown(true);
    } else {
      setTimeout(() => {
        setDisableEndorserDropdown(true);
      }, 1000);
      selectSkill(null);
      selectEndorser(null);
    }
  };

  const setEndorseType = (select) => {
    if (select) {
      selectEndorseType(select);
      selectEndorser(null);
      setDisableEndorserDropdown(true);
    } else {
      setTimeout(() => {
        setDisableEndorserDropdown(true);
      }, 1000);
      selectEndorseType(null);
      selectEndorser(null);
    }
  };

  useEffect(() => {
    if (employee && skill && endorseType && endorseType.value !== 1) {
      dispatch(
        actions.getMMGEndorserName({
          endorseToId: employee.value,
          skillIdNameId: skill.value,
          endorseTypeId: endorseType.value,
          endorsedToSubGroupId: employee.subGroupId,
          endorsedToParentGroupId: employee.parentGroupId,
        })
      );
    }
  }, [dispatch, employee, endorseType, skill]);

  const onSelectEndorser = (select) => {
    if (select) {
      selectEndorser(select);
    } else {
      selectEndorser(null);
    }
  };

  const endorseReq = () => {
    const reqObj = {
      endorsedToId: employee.value,
      endorsedToName: employee.label,
      endorsedToSubGroupId: employee.subGroupId,
      endorsedToParentGroupId: employee.parentGroupId,
      // endorseRequestBySubGroupId : employee.subGroupId,
      // endorseRequestByParentGroupId : employee.parentGroupId,
      endorsedBySubGroupId: endorser?.subGroupId,
      endorsedByParentGroupId: endorser?.parentGroupId,
      endorsedTypeId: endorseType.value.toString(),
      endorsedTypeName: endorseType.label,
      endorsedById: endorser ? endorser.value : null,
      endorsedByName: endorser ? endorser.label : null,
      endorsedSkill: {
        skillIdName: skill.label,
        skillIdNameId: skill.value,
      },
    };
    dispatch(actions.sendMMGEndorseReq(reqObj));
  };
  const filterByRequest = (e) => {
    const value = e.target.value;
    let array = [];
    if (e.target.checked) {
      setFilterType(value);
      if (value === "PENDING") {
        array = getMMGEndorseReq.filter((req) => req.status.toLowerCase() === "pending");
      } else if (value === "ENDORSE") {
        array = getMMGEndorseReq.filter((req) => req.status.toLowerCase() === "endorsed");
      } else if (value === "DECLINE") {
        array = getMMGEndorseReq.filter((req) => req.status.toLowerCase() === "decline");
      } else {
        array = getMMGEndorseReq;
      }
    }
    setTotalReq(array);
  };

  useEffect(() => {
    if (totalReq && totalReq.length === 0) {
      setPageOfItems([]);
    }
  }, [totalReq]);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
    if (!e.target.value) {
      setFilterType("ALL");
    }
  };

  const reset = () => {
    setSearchText("");
    setFilterType("ALL");
  };

  useEffect(() => {
    let array = [];
    if (searchText.length > 0) {
      if (searchText.startsWith("#")) {
        const queryStr = searchText.substring(1).trim();
        array = getMMGEndorseReq.filter((data) =>
          data.endorsedSkill.skillIdName.toLowerCase().includes(queryStr.toLowerCase())
        );
      } else {
        array = getMMGEndorseReq.filter(
          (data) =>
            data.endorsedByName?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
            data.endorsedToName?.toLowerCase().includes(searchText.toLowerCase().trim())
        );
      }
    } else {
      array = getMMGEndorseReq;
    }

    if (typeArr === "PENDING") {
      array = array.filter((req) => req.status.toLowerCase() === "pending");
    } else if (typeArr === "ENDORSE") {
      array = array.filter((req) => req.status.toLowerCase() === "endorsed");
    } else if (typeArr === "DECLINE") {
      array = array.filter((req) => req.status.toLowerCase() === "decline");
    }
    setTotalReq(array);
  }, [getMMGEndorseReq, searchText, typeArr]);

  return (
    <>
      <SubNavigation type="Endorsement" />
      {isLoading && <Spinner />}
      <div className={style.mmg_endorsement_wrapper}>
        <Container>
          <Row>
            <Col md={12} className="col-12">
              <h1 className="page-title pb-0">WMG</h1>
              <span className="st-breadcrumb">
                WMG Endorsement<span>Endorse the skills</span>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>Endorsement</h2>
              <h3>Endorse the Members from here</h3>
            </div>
          </div>
          <div className="box-inner-pad">
            <Row>
              <Col md={6} lg={3}>
                <span className={style.dd_title}>Endorse To</span>
                <div className="card_action">
                  <Select
                    options={employees}
                    className="custom-default-dd-box"
                    classNamePrefix="select"
                    isSearchable={true}
                    isClearable={true}
                    placeholder="Select Endorse to"
                    onChange={(select) => setEmployee(select)}
                    value={employee}
                  />
                </div>
              </Col>
              <Col md={6} lg={3}>
                <span className={style.dd_title}>Skill</span>
                <div className="card_action">
                  <Select
                    options={skills}
                    className="custom-default-dd-box"
                    classNamePrefix="select"
                    isSearchable={true}
                    isClearable={true}
                    placeholder="Select Skill"
                    isDisabled={disableSkillDropdown}
                    onChange={(select) => setSkill(select)}
                    value={skill}
                  />
                </div>
              </Col>
              <Col md={6} lg={3}>
                <span className={style.dd_title}>Endorsement Type</span>
                <div className="card_action">
                  <Select
                    options={options}
                    className="custom-default-dd-box"
                    classNamePrefix="select"
                    isSearchable={true}
                    isClearable={true}
                    placeholder="Select Endorsement Type"
                    value={endorseType}
                    onChange={(select) => setEndorseType(select)}
                  />
                </div>
              </Col>
              <Col md={6} lg={3}>
                <span className={style.dd_title}>Endorse by</span>
                <div className="card_action">
                  <Select
                    options={endorserList}
                    className="custom-default-dd-box"
                    classNamePrefix="select"
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={disableEndorserDropdown}
                    placeholder="Select Endorse by"
                    value={endorser}
                    onChange={(select) => onSelectEndorser(select)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="box-inner-pad">
            <button
              type="button"
              className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
              disabled={isDisable}
              onClick={endorseReq}
            >
              <strong>Endorse Request</strong>
            </button>
          </div>
        </div>
        <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>Endorsement</h2>
              <h3>WMG Requests for Endorsement</h3>
            </div>
          </div>
          <div className="tab_filter">
            <Row>
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className={[style.mmg_search_wrapper, "check_in_search_bar"].join(" ")}>
                  <Form>
                    <Form.Group controlId="" className="form-postfix mb-0">
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Search by Employee/skills"
                        name="searchBox"
                        className={className}
                        onChange={handleSearchInputChange}
                        value={searchText}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      <Button className="close-button" type="reset" onClick={reset}>
                        <span className="close-icon"></span>
                      </Button>
                      <div className="form-postfix-icon">
                        <img src={IC_INPUT_SEARCH} title="" alt="" />
                      </div>
                    </Form.Group>
                  </Form>
                  <div className={[style.filter_info_tooltip, "custom-tooltip"].join(" ")}>
                    <div className="box-info">
                      <ReactSVG src={IC_INFO} title="" alt="info" />
                    </div>
                    <div className="info-attach-file">
                      <span className="attachment-title">Search Criteria</span>
                      <ul>
                        <li>If you want to search for Skills, type #skillname. </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={8} xl={8}>
                <div className="tab_check_in_status">
                  <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                    <input
                      id="all"
                      type="radio"
                      name="status-check"
                      value="ALL"
                      checked={typeArr === "ALL"}
                      onChange={(e) => filterByRequest(e)}
                    />
                    <label htmlFor="all">All</label>
                  </div>

                  <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                    <input
                      id="g1"
                      type="radio"
                      name="status-check"
                      value="PENDING"
                      checked={typeArr === "PENDING"}
                      onChange={(e) => filterByRequest(e)}
                    />
                    <label htmlFor="g1">Pending</label>
                  </div>

                  <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                    <input
                      id="g2"
                      type="radio"
                      name="status-check"
                      value="ENDORSE"
                      checked={typeArr === "ENDORSE"}
                      onChange={(e) => filterByRequest(e)}
                    />
                    <label htmlFor="g2">Endorsed</label>
                  </div>

                  <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
                    <input
                      id="g23"
                      type="radio"
                      name="status-check"
                      value="DECLINE"
                      checked={typeArr === "DECLINE"}
                      onChange={(e) => filterByRequest(e)}
                    />
                    <label htmlFor="g23">Declined</label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="box-light-gradient h38"></div>
          {pageOfItems && pageOfItems.length > 0 ? (
            <div className={[style.skill_table, "custom-table", "table-theme-1"].join(" ")}>
              <div className={[style.endorsement_skill_table, "position-relative"].join(" ")}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Skill Name</th>
                      <th>Endorse by</th>
                      <th>Endorse to</th>
                      <th>Endorse Type</th>
                      <th>Status</th>
                      <th>Endorsement Date</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItems &&
                      pageOfItems.length > 0 &&
                      pageOfItems.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <span className={style.skill_name}>{data.endorsedSkill.skillIdName}</span>
                            </td>
                            <td>
                              <span className={style.endorse_by}>
                                {data.endorsedByName ? data.endorsedByName : data.endorsedTypeName}
                              </span>
                            </td>
                            <td>
                              <span className={style.endorse_to}>{data.endorsedToName}</span>
                            </td>
                            <td>
                              <div className={style.endorsement_type}>
                                {data.endorsedTypeId === "4" && (
                                  <span className={style.endorse_tooltip}>
                                    <img src={IMG_ENDORSEMENT_1} alt="PEER" />
                                    <span className={style.tool_tip_info}>Peer</span>
                                  </span>
                                )}
                                {data.endorsedTypeId === "3" && (
                                  <span className={style.endorse_tooltip}>
                                    <img src={IMG_PM} alt="PM" />
                                    <span className={style.tool_tip_info}>Manager</span>
                                  </span>
                                )}
                                {data.endorsedTypeId === "2" && (
                                  <span className={style.endorse_tooltip}>
                                    <img src={IMG_SME} alt="SME" />
                                    <span className={style.tool_tip_info}>SME</span>
                                  </span>
                                )}
                                {data.endorsedTypeId === "1" && (
                                  <span className={style.endorse_tooltip}>
                                    <img src={IMG_UNI} alt="University" />
                                    <span className={style.tool_tip_info}>University</span>
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              {data.status.toLowerCase() === "pending" && (
                                <div className={[style.cmp_lvl, style.current].join(" ")}>
                                  <span className={[style.req_comp_level, "orange"].join(" ")}>{data.status}</span>
                                </div>
                              )}
                              {data.status.toLowerCase() === "decline" && (
                                <div className={[style.cmp_lvl, style.current].join(" ")}>
                                  <span className={[style.req_comp_level, "red"].join(" ")}>Declined</span>
                                </div>
                              )}
                              {data.status.toLowerCase() === "endorsed" && (
                                <span className={[style.req_comp_level, "green"].join(" ")}>{data.status}</span>
                              )}
                            </td>
                            <td>
                              {data.status.toLowerCase() === "pending" && (
                                <span className={style.last_used}>
                                  {moment(data.endorsedReqDate).format("MMMM D, YYYY")}
                                </span>
                              )}
                              {data.status.toLowerCase() === "decline" && (
                                <span className={style.last_used}>
                                  {moment(data.declineDate).format("MMMM D, YYYY")}
                                </span>
                              )}
                              {data.status.toLowerCase() === "endorsed" && (
                                <span className={style.last_used}>
                                  {moment(data.endorsedDate).format("MMMM D, YYYY")}
                                </span>
                              )}
                            </td>
                            <td>
                              <div className={style.comments}>
                                <div className={style.comment_txt}>
                                  {data.comment ? data.comment.slice(0, 10) : "-"}
                                  <div className="d-inline-block position-relative">
                                    {data.comment && data.comment.trim().length > 10 && (
                                      <span className={style.more}>...More</span>
                                    )}
                                    <span className={style.description_tooltip}>{data.comment}</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div>
              <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              <div className="gap80"></div>
              <div className="gap40"></div>
            </div>
          )}
        </div>
        {totalReq && totalReq.length !== 0 && (
          <Pagination pageSize={size} items={totalReq} onChangePage={onChangePage} resetData={resetData} />
        )}
      </Container>
    </>
  );
}
