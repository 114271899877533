import React from "react"
import style from "../TabHighlights.module.scss";
import moment from "moment"
import PropTypes from "prop-types"
import { backGroundColor } from "../../../const/constValue";

export default function TransformingComment({ data }){
  const { comment, commentedBy, commentedOn } = data
  return (
    <div className={style.commentStack}>
      <div className={style.commentStackList}>
        <div className={style.user_img} style={{backgroundColor : backGroundColor[1]}}>
          {commentedBy.displayPicture.length === 2 
            ? commentedBy.displayPicture 
            : <img src={commentedBy.displayPicture} title="" alt="display pic" />}
        </div>
        <div className={style.detailArea}>
          <div className={style.headArea}>
            <div className={style.namespace}>
              <div className={style.nameinfotext}>
                <div className={style.name}>{commentedBy.empName}</div>
                <div className={style.time}>{moment(commentedOn).format("ddd, MMMM DD h:mm A")}</div>
              </div>
            </div>
            <p className={style.received_info}>
              {comment}
            </p>
          </div>
        </div>
      </div>
    </div>
    
  )
}

TransformingComment.propTypes = {
  data: PropTypes.shape({
    comment: PropTypes.string.isRequired,
    commentedOn: PropTypes.string.isRequired,
    commentedBy: PropTypes.shape({
      empName: PropTypes.string.isRequired,
      displayPicture: PropTypes.string
    })
  })
}

TransformingComment.defaultProps = {
  data: {
    comment: "",
    commentedOn: "",
    commentedBy: {
      empName: "",
      displayPicture: "AB"
    }
  }
}