import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Multiselect } from "multiselect-react-dropdown";
import style from "./CycleListEmployee.module.scss";
import { IC_FLAG } from "../../../const/imgCost";
import { toFixedNumber } from "../../../util/utils";
import { dateFormat2 } from "../../../util/dateUtils";

function EmployeeRow({
  data,
  toggleCheckboxChange,
  onRemove,
  onSelect,
  selectReopen,
  backgroundColor,
  isSelfAssessmentAvailable,
  path,
}) {
  const { empName, displayPicture, department, designation, empInitial } = data.employeeData;
  const multiselectRef = useRef("");
  let managerInfo = [];
  if (data.allRVM?.length > 0) {
    data.allRVM.map((v) => {
      managerInfo.push({ privateAssessmentId: v.privateAssessmentId, ...v.rvm, groupBy: "RVM" });
      return v;
    });
  }
  if (data.allRPM?.length > 0) {
    data.allRPM.map((v) => {
      managerInfo.push({ privateAssessmentId: v.privateAssessmentId, ...v.rpm, groupBy: "RPM" });
      return v;
    });
  }

  let styles = "disabled";
  if (selectReopen.length > 0 && selectReopen.filter((value) => value.id === data.employeeId)[0]) {
    styles = "";
  }
  const handleRemove = (list, item, employeeId) => {
    if (list?.length > 0) {
      multiselectRef.current.searchWrapper.current.classList.add("multiselect-wrapper-added");
    } else {
      multiselectRef.current.searchWrapper.current.classList.remove("multiselect-wrapper-added");
    }
    onRemove(list, item, employeeId);
  };

  const handleSelect = (list, item, employeeId) => {
    multiselectRef.current.searchWrapper.current.classList.add("multiselect-wrapper-added");
    onSelect(list, item, employeeId);
  };

  return (
    <tr>
      <td className={style.cb_box}>
        <div className="custom-checkbox custom-checkbox-sm no_mar">
          <input
            id={data.employeeId}
            type="checkbox"
            value={data.employeeId}
            checked={data.isChecked}
            onChange={(e) => toggleCheckboxChange(e, data.employeeId)}
          />
          <label htmlFor={data.employeeId}></label>
        </div>
      </td>
      <td className={style.emp_id}>{data.employeeId}</td>
      <td className={[style.emp_name, style.emp_flag].join(" ")}>
        {data.flagged && (
          <span className={style.flag_icon}>
            <ReactSVG src={IC_FLAG} alt="flag" />
          </span>
        )}
        <div className={style.tab_team_member_info}>
          <div className={style.team_member_info}>
            <span className={style.team_member_img} style={{ backgroundColor }}>
              {displayPicture && displayPicture.length > 2 ? <img src={displayPicture} alt="" /> : empInitial}
            </span>

            <div className={style.team_member_details}>
              <span className={style.team_member_name}>
                <Link to={path}>{empName}</Link>
              </span>

              <span className={style.team_member_designation}>
                {department} | {designation}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td className={[style.manager_list, styles].join(" ")}>
        <Multiselect
          groupBy="groupBy"
          id={"multi-select-dd-box-with-placeholder" + data.employeeId}
          options={managerInfo}
          onRemove={(list, item) => handleRemove(list, item, data.employeeId)}
          onSelect={(list, item) => handleSelect(list, item, data.employeeId)}
          displayValue="empName"
          showCheckbox={true}
          placeholder="Select..."
          ref={multiselectRef}
          hidePlaceholder={true}
          closeOnSelect={false}
        />
      </td>
      {/* <td className={style.private_assessment}>{toFixedNumber(privateAssessmentRating, 2)}</td> */}
      <td className={style.private_assessment}>{toFixedNumber(data.privateAssessmentAggregateRating, 2) || "-"}</td>
      {isSelfAssessmentAvailable && (
        <>
          <td className={style.private_assessment}>{toFixedNumber(data.selfAssessmentAvgRating, 2)}</td>
          <td className={style.private_assessment}>{data.selfAssessmentGrade || "-"}</td>
        </>
      )}

      {/* <td className={style.rating}>{toFixedNumber(objectiveRating, 2)}</td>
      <td className={style.grades}>{gradeRating || "-"}</td>
      <td className={style.submitted_on}>{dateFormat2(data.submittedDate)}</td> */}
      <td className={style.rating}>{toFixedNumber(data.objectiveRatings, 2)}</td>
      <td className={style.grades}>{data.grade || "-"}</td>
      <td className={style.submitted_on}>{dateFormat2(data.submittedDate)}</td>
      <td className={style.submitted_on}>
        {data.acknowledgementStatus === true ? "Yes" : data.acknowledgementStatus === false ? "No" : "-"}
      </td>
      <td className={style.rating}>{data?.offlineRatings ? `${data?.offlineRatings} / ${data?.offlineGrade === null ? "NA" : data?.offlineGrade }` : "-"}</td>
      <td className={style.rating}>{data?.offlineRatingStatus ? data?.offlineRatingStatus : "-"}</td>
    </tr>
  );
}

EmployeeRow.propTypes = {
  onRemove: PropTypes.func,
};

export default EmployeeRow;
