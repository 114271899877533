import React from "react";
import { ReactSVG } from "react-svg";
import { IC_BEST_STREAK, IC_COMPLETED, IC_CURRENT_STREAK, IC_ORG_BEST_STREAK } from "const/imgCost";

export default function CheckInStreak({ currentStreak, yourBestStreak, completionRate, orgBestStreak }) {
  return (
    <div className="check_in_streak_box">
      <div className="check_in_streak_container">
        <div className="check_in_streak_ic">
          <ReactSVG src={`${IC_CURRENT_STREAK}`} className="svg" />
        </div>
        <div>
          <p className="checking_streak_number">{currentStreak}</p>
          <p className="checking_streak_caption">Current Streak</p>
        </div>
      </div>
      <div className="check_in_streak_container">
        <div className="check_in_streak_ic">
          <ReactSVG src={`${IC_BEST_STREAK}`} />
        </div>
        <div>
          <p className="checking_streak_number">{yourBestStreak}</p>
          <p className="checking_streak_caption">Best Streak</p>
        </div>
      </div>
      <div className="check_in_streak_container">
        <div className="check_in_streak_ic">
          <ReactSVG src={`${IC_COMPLETED}`} />
        </div>
        <div>
          <p className="checking_streak_number">{completionRate}%</p>
          <p className="checking_streak_caption">Completion Rate</p>
        </div>
      </div>
      <div className="check_in_streak_container">
        <div className="check_in_streak_ic">
          <ReactSVG src={`${IC_ORG_BEST_STREAK}`} />
        </div>
        <div>
          <p className="checking_streak_number">{orgBestStreak}</p>
          <p className="checking_streak_caption">Organization’s Best</p>
        </div>
      </div>
      {/* <div className="check_in_streak_container" >
                  <div className="style.checkins_submit_ic"><ReactSVG src={IC_ON_TIME} /></div>
                  <div>
                      <p className="checking_streak_number">14</p>
                      <p className="checking_streak_caption">On Time</p>
                  </div>
              </div> */}
    </div>
  );
}

CheckInStreak.defaultProps = {
  currentStreak: 0,
  yourBestStreak: 0,
  orgBestStreak: 0,
  completionRate: 0,
};
