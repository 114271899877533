import React, { useState, useEffect } from "react";
import style from "../Dashboard.module.scss";
import { moodOrder, locationOrder } from "../../../../const/constValue";
import { PieChart, Pie, ResponsiveContainer, Cell, Label, BarChart, XAxis, YAxis, Bar, Tooltip } from "recharts";
import _ from "lodash";

export default function MoodOMeterCharts(props) {
  const [currentVal, setCurrentVal] = useState(null);
  const [pieChartDataMood, setPieChartDataMood] = useState([]);
  const [barChartDataMood, setBarChartDataMood] = useState([]);

  useEffect(() => {
    const PieChartsArray = [];
    const BarChartArray = [];

    if (props.moodOMeterData) {
      props.moodOMeterData.pieChartDataList.map((checkins) => {
        PieChartsArray.push({
          name: checkins.status,
          value: checkins.count,
          order: moodOrder[checkins.status],
        });
        return checkins;
      });
      setPieChartDataMood(_.sortBy(PieChartsArray, ["order"], ["asc"]));
      props.moodOMeterData.barChartList.map((barCheckins) => {
        BarChartArray.push({
          name: barCheckins.location,
          "Awesome!": barCheckins.awesome,
          "Pretty Good!": barCheckins.preetyGood,
          "Completely Okay!": barCheckins.completelyOkay,
          "Somewhat Bad!": barCheckins.somewhatBad,
          "Really Terrible!": barCheckins.reallyTerrible,
          order: locationOrder[barCheckins.location],
        });
        return barCheckins;
      });
      setBarChartDataMood(_.sortBy(BarChartArray, ["order"], ["asc"]));
    } else {
      setPieChartDataMood([]);
      setBarChartDataMood([]);
    }
  }, [props]);

  const COLORS = {
    "Awesome!": "#00D7A1",
    "Pretty Good!": "#71DCC9",
    "Completely Okay!": "#FFD653",
    "Somewhat Bad!": "#FFAE72",
    "Really Terrible!": "#FF678C",
  };

  function CustomLabel({ viewBox }) {
    const { cx, cy } = viewBox;
    let totalValue = 0;
    pieChartDataMood.forEach((dt) => {
      totalValue += dt.value;
    });
    const total = `${totalValue}`;
    return (
      <>
        <text
          x={cx}
          y={cy - 12}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            Total
          </tspan>
        </text>
        <text
          x={cx}
          y={cy + 15}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            {total}
          </tspan>
        </text>
      </>
    );
  }

  /** end pie chart */

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    let countStatusLocationWise;

    payload.forEach((data) => {
      if (currentVal && currentVal === data.dataKey) {
        Object.keys(data.payload).map((key) => {
          if (key !== "name" && key !== "order") {
            totalVal += data.payload[key];
          }
          return key;
        });
        pr = (data.value / totalVal) * 100;
        countStatusLocationWise = data.value;
      }
    });
    if (active) {
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper">
              <p className="recharts-tooltip-label">{currentVal}</p>
              {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
              <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${countStatusLocationWise}`}</span>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomTooltipForPieChart = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    if (active) {
      pieChartDataMood.forEach((data) => {
        totalVal += data.value;
      });
      pr = (payload[0]["value"] / totalVal) * 100;
      return (
        <>
          <div className="recharts-tooltip-custom-wrapper">
            <p className="recharts-tooltip-label">{payload[0]["name"]}</p>
            {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
            <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${
              payload[0]["value"]
            }`}</span>
          </div>
        </>
      );
    }

    return null;
  };

  /** end bar chart*/

  return (
    <div className={["box", style.graph_wrapper].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Mood-o-meter Status</h2>
        </div>
      </div>
      <div className="box-inner-pad pb-0">
        {pieChartDataMood && pieChartDataMood.length > 0 ? (
          <>
            <div className="d-flex">
              <div className="graph-item width50">
                <h5 data-test="aggregate"> Aggregate MooD-o-meter Report</h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={pieChartDataMood}
                        cx="45%"
                        cy="50%"
                        innerRadius={100}
                        outerRadius={125}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                      >
                        <Label width={30} position="center" content={<CustomLabel value1={pieChartDataMood} />}></Label>
                        {pieChartDataMood.map((entry, index) => (
                          <Cell key={index} fill={COLORS[entry["name"]]} />
                        ))}
                      </Pie>
                      <Tooltip content={CustomTooltipForPieChart} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="graph-item width70">
                <h5 className={["pl-4", style.right_text_title].join(" ")} data-test="location">
                  Location wise MooD-o-meter Report
                </h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={barChartDataMood}
                      margin={{ top: 70, right: 0, left: 0, bottom: 0 }}
                      barSize={25}
                      barGap={1}
                    >
                      <XAxis dataKey="name" axisLine={false} />
                      <YAxis axisLine={false} />
                      <Bar
                        dataKey="Really Terrible!"
                        stackId="a"
                        fill={COLORS["Really Terrible!"]}
                        onMouseOver={() => {
                          setCurrentVal("Really Terrible!");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />
                      <Bar
                        dataKey="Somewhat Bad!"
                        stackId="a"
                        fill={COLORS["Somewhat Bad!"]}
                        onMouseOver={() => {
                          setCurrentVal("Somewhat Bad!");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />
                      <Bar
                        dataKey="Completely Okay!"
                        stackId="a"
                        fill={COLORS["Completely Okay!"]}
                        onMouseOver={() => {
                          setCurrentVal("Completely Okay!");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />
                      <Bar
                        dataKey="Pretty Good!"
                        stackId="a"
                        fill={COLORS["Pretty Good!"]}
                        onMouseOver={() => {
                          setCurrentVal("Pretty Good!");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />
                      <Bar
                        dataKey="Awesome!"
                        stackId="a"
                        fill={COLORS["Awesome!"]}
                        onMouseOver={() => {
                          setCurrentVal("Awesome!");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />
                      <Tooltip content={<CustomTooltip />} cursor={true} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className={style.legent_wrapper}>
              <ul className={style.lenegd_list} data-test="Dashboard_lenegd_list">
                {pieChartDataMood.map((entry, index) => {
                  return (
                    <li className={style.legend_item} key={index}>
                      <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                      {entry["name"]}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        ) : (
          <div className="no-record-found-wrpr mt-3" data-test="no-record">
            No Data Available
          </div>
        )}
      </div>
    </div>
  );
}
