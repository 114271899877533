import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import OneOnOneTabs from "components/OneOnOne/OneOnOneTabs/OneOnOneTabs";
import style from "./OneOnOne.module.scss";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { menuText } from "const/constValue";

export default function OneonOne(props) {
  const filterValue = props.location.state?.filterValue || "";
  return (
    <Fragment>
      <SubNavigation type="OKR Progress" />
      <div className={style.one_on_one_wrapper}>
        <Container>
          <Row>
            <Col md={6} className="col-12">
              <h1 className="page-title pb-0">{menuText.CHECK_IN}</h1>
              <span className="st-breadcrumb">{menuText.ONE_ON_ONE}</span>
            </Col>
            <Col className="col-12">
              <OneOnOneTabs filterValue={filterValue} />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
