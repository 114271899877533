import React from "react";
import { backGroundColor } from "../../../const/constValue";
import Comment from "../../CommentBox/Comment";
import CommentsTop from "../../CommentBox/CommentsTop";
import CommentBox from "../SelfAssessment/Components/CommentBox";
import MultipleFile from "components/MultipleFileUpload/MultipleFile";

export default function GlobalComment({
  heading,
  isMultipleFile,
  description,
  optional,
  sendGlobalComment,
  comments,
  employeeComment,
  loggedInUser,
  UploadFileGlobalFeedback,
  downloadFileGlobalFeedback,
  deleteFileGlobalFeedback,
  globalRPMCommentFiles,
  obj,
  isRPM,
  isPageSelf,
  isPageRVM
}) {

  return (
    <>
      <div className="box pb-0">
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2 style={{ display: "inline-block" }}>{heading}</h2>
            {optional && <span className="optional-text">(Optional)</span>}
            <h3>{description}</h3>
          </div>
        </div>
        <div className="box-inner-pad">
          <div className="view_comment_box_wrapper collapse show mt-0 mb-0">
            <CommentsTop heading="Share your view(s)" />
            {comments?.length > 0 &&
              comments.map((value, index) => (
                <>
                  <Comment
                    key={index}
                    selectedColor={backGroundColor[1]}
                    image={value.user?.dispArr || value.user?.displayPicture || value.user?.empInitial}
                    placeholder="Add a comment"
                    empName={value.user?.empName}
                    commentedOn={value.commentDate}
                    comment={value.comment}
                  />
                  <div className="multiple_uploaded_file sd">
                    <MultipleFile
                      files={value?.files}
                      // removeFile={(id) => deleteFileGlobalFeedback(id, value.privateAssessmentId)}
                      isDeletable={false}
                      isDownloadable={true}
                      downloadFile={(id) => downloadFileGlobalFeedback(id, value.privateAssessmentId)}
                    />
                  </div>
                </>
              ))}
            <CommentBox
              selectedColor={backGroundColor[1]}
              blurHandler={sendGlobalComment}
              image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
              comment={employeeComment?.comment || ""}
              commentLength={5000}
              isMultipleFile={isMultipleFile}
              handleMultipleFileUpload={UploadFileGlobalFeedback}
            />
            {isPageSelf && (
              <div className="multiple_uploaded_file">
                <MultipleFile
                  files={obj[0]?.files}
                  removeFile={deleteFileGlobalFeedback}
                  isDeletable={true}
                  isDownloadable={true}
                  downloadFile={downloadFileGlobalFeedback}
                />
              </div>
            )}

            {isRPM && (
              <div className="multiple_uploaded_file">
                <MultipleFile
                  files={obj[0]?.files}
                  removeFile={deleteFileGlobalFeedback}
                  isDeletable={true}
                  isDownloadable={true}
                  downloadFile={downloadFileGlobalFeedback}
                />
              </div>
            )}

            {isPageRVM && (
              <div className="multiple_uploaded_file">
                <MultipleFile
                  files={obj[0]?.files}
                  removeFile={deleteFileGlobalFeedback}
                  isDeletable={true}
                  isDownloadable={true}
                  downloadFile={downloadFileGlobalFeedback}
                />
              </div>
            )}

          </div>
        </div>
      </div>
    </>
  );
}
