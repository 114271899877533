import { MONTHLY_CHECKIN_FEEDBACK_ACTION } from "store/actions/actionTypes";

const initialState = {
  isLoading: false,
  cycleCheckIn: null,
  currentCheckIn: null,
  showYearListData: null,
  currentFeedbackDataEmployee: [],
  reportingManagerList: [],
  setFeedbackInteractionEmployee: [],
  countDurationStatusEmployee: null,
  countDurationStatusManager: null,
  currentFeedbackDataEmployeeMessage: null,
  employeeManagerCheckinFeedbackDTO: [],
  managerData: [],
  setFeedbackInteractionManager: [],
  currentFeedbackDataManagerMessage: null,
  feedbackHistoryEmployee: [],
  feedbackHistoryManager: [],
  totalCount: null,
  managerList: [],
  historyDetails: null,
  filtersDetailsHr: null,
  exportDetails: null,
  feedbackDetailsInHRView: null,
  feedbackDashboardData: null,
  feedbackGraphData: null,
  isDashboardDataLoding: false,
  isGraphDataLoding: false,
  feedbackDashboardPeriod: null,
  feedbackFilterData: {},
  fileResponse: null,
  latestCycleDasboard: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.IS_DASHBOARD_LOADING:
      return {
        ...state,
        isDashboardDataLoding: action.payload.isDashboardDataLoding,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.IS_GRAPH_LOADING:
      return {
        ...state,
        isGraphDataLoding: action.payload.isGraphDataLoding,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_CURRENT_FEEDBACK_EMPLOYEE:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_INTERACTION_EMPLOYEE:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SUBMIT_FEEDBACK_EMPLOYEE_AND_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_CURRENT_FEEDBACK_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_INTERACTION_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_COUNT_DURATION_STATUS_EMPLOYEE:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_COUNT_DURATION_STATUS_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_EMPLOYEE:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_HISTORY_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_MANAGER_LIST_EMPLOYEE:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.GET_HISTORY_DETAILS_EMPLOYEE_AND_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.START_FILE_DOWNLOAD_IN_BACKGROUND:
      return {
        ...state,
        fileResponse: { success: true, message: "Your files would be downloaded in background" },
        isLoading: false,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_CHECKIN_FEEDBACK_EXPORT:
      return {
        ...state,
        fileResponse: { success: action.success, message: action.message },
        updatedDate: action.updatedDate,
        isLoading: false,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.CLEAR_CHECKIN_FEEDBACK_EXPORT:
      return { ...state, fileResponse: null };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_SHOW_YEAR_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FILTERS_DETAILS_HR:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_DASHBOARD_DATA_HR:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_GRAPH_CHECKIN_FEEDBACK_HR:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_QUESTION_BASED_EXPORT_EMPLOYEE:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_QUESTION_BASED_EXPORT_MANAGER:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_DETAILS_IN_HR_VIEW:
      return {
        ...state,
        ...action.payload,
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.SET_FEEDBACK_FILTER_DATA:
      return {
        ...state,
        feedbackFilterData: {
          ...state.feedbackFilterData,
          ...action.payload,
        },
      };
    case MONTHLY_CHECKIN_FEEDBACK_ACTION.CLEAR_FEEDBACK_FILTER_DATA:
      return {
        ...state,
        feedbackFilterData: {},
      };
    default:
      return state;
  }
};

export default reducer;