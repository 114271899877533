import React from "react";
import style from "./SidebarTags.module.scss";
  
export default function SideBarTags({ tags }){
  return (
    <div className={["box tag-wrapper-box", style.tag_box].join(" ")}>
      <div className="box-heading-wrapper mb10 noborder no_pad">
        <div className={["box-heading", style.tag_heading].join(" ")}>
            <h2>Tags</h2>
            <h3>Tags can help you find out things very quickly.</h3>
        </div>
      </div>
      <div className="box-inner-pad p-0">
        <ul className={style.tag_list} >
          {tags?.map((value,index) => <li key={"TAGS_" + index}>{value.name}</li>)}
        </ul>
      </div>
    </div>
  )
}