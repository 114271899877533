import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./Rewards.module.scss";
import Select from "react-select";
import { IMG_RECEIVED_REWARDS, IMG_SENT_REWARDS } from "const/imgCost";
import { monthOptions } from "const/options";

class Rewards extends Component {
  render() {
    return (
      <div className={["box reviewer-profile-box", style.rewards_box].join(" ")}>
        <div className="box-heading-wrapper mb10 noborder no_pad">
          <div className={["box-heading", style.reward_heading].join(" ")}>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h2>Rewards</h2>
                <h3 className="box-heading-light">Have a look on “You Made My Day” </h3>
              </Col>
            </Row>
          </div>
        </div>
        <div className={style.reward_data_wrapper}>
          <div className="mb-2">
            <Row>
              <Col xs={4} sm={4} md={4}></Col>
              <Col xs={8} sm={8} md={8}>
                <Select
                  onChange={(select) => this.props.handleAppreciateDuration(select)}
                  options={monthOptions}
                  className="select_dropdown select_dropdown_text"
                  classNamePrefix="select"
                  isSearchable={false}
                  defaultValue={{
                    value: 6,
                    label: "Last 6 Months",
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className={style.ymmd_stat}>
            <div className={style.ymmd_sent}>
              <div className={style.ymmd_sent_count}>
                <ReactSVG src={IMG_SENT_REWARDS} title="" alt="" />
              </div>
              <div className={style.reward_value}>{this.props.count.noOfSent || 0}</div>
              <div className={style.caption}>Sent</div>
            </div>
            <div className={style.ymmd_rece}>
              <div className={style.ymmd_rece_count}>
                <ReactSVG src={IMG_RECEIVED_REWARDS} title="" alt="" />
              </div>
              <div className={style.reward_value}>{this.props.count.noOfReceived || 0}</div>
              <div className={style.caption}> Received </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Rewards;
