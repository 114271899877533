import * as actionTypes from "../actions/actionTypes";

const initialState = {
  deallocationEmpListForManager: [],
  empDeallocationData: null,
  objectives: [],
  isListViewLoading: true,
  isLoadingDetailscreen: true,
  isLoadingHRDetailScreen: true,
  isSubmitted: false,
  updatedDate: new Date(),
  submitFeedbackLoader: false,
  deallocationResponse: {},
  deallocationReportee: [],
  filterReporteeListLoader: false,
  isFileLoading: false,
  fileResponse: null,
  deallocationFilterData: {},
  locationList: [],
  departmentList: [],
  employeeNameList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DEALLOCATION_LIST_FOR_MANAGER:
      return { ...state, deallocationEmpListForManager: action.payload, isListViewLoading: false };
    case actionTypes.EMP_DEALLOCATION_DATA:
      return { ...state, ...action.payload };
    case actionTypes.EMP_DEALLOCATION_DATA_FOR_HR:
      return { ...state, ...action.payload, isLoadingHRDetailScreen: false };
    case actionTypes.MAKE_IT_UPDATE_DEALLOCATION:
      return { ...state, ...action.payload };
    case actionTypes.SET_DEALLOCATION_FILTER_DATA_LIST:
      return { ...state, ...action.payload };
    case actionTypes.DEALLOCATION_REPORTEE_LIST:
      return { ...state, deallocationReportee: action.payload, filterReporteeListLoader: false };
    case "START_DEALLOCATION_FEEDBACK_LOADER":
      return { ...state, submitFeedbackLoader: true };
    case "START_LOADING_DEALLOCATION_REPORTEE_LIST":
      return { ...state, filterReporteeListLoader: true };
    case "START_FILE_LOADING_IN_DEALLOCATION":
      return { ...state, isFileLoading: true };
    case actionTypes.DEALLOCATION_FILE_RESPONSE:
      return {
        ...state,
        fileResponse: { success: action.success, message: action.message },
        updatedDate: action.updatedDate,
        isFileLoading: false,
      };
    case "CLEAR_DEALLOCATION_FILE_RESPONSE":
      return { ...state, fileResponse: null };
    case "REMOVE_DEALLOCATION_FEEDBACK_DETAILS":
      return {
        ...state,
        empDeallocationData: null,
        isLoadingDetailscreen: true,
        objectives: [],
        deallocationResponse: {},
        isLoadingHRDetailScreen: true,
      };
    case "SET_DEALLOCATION_FILTER_DATA":
      return {
        ...state,
        deallocationFilterData: {
          ...state.deallocationFilterData,
          ...action.payload,
        },
      };
    case "CLEAR_DEALLOCATION_FILTER_DATA":
      return {
        ...state,
        deallocationFilterData: {},
      };
    default:
      return state;
  }
};

export default reducer;
