import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import {
  FETCH_NOTIFICATION,
  READ_NOTIFICATION,
  MARK_ALL_READ_NOTIFICATION,
  DELETE_NOTIFICATION,
  CLEAR_ALL_NOTIFICATION,
  FETCH_ALL_NOTIFICATION,
} from "../../util/endpoints";

export const FetchNotification = () => {
  return (dispatch) => {
    http
      .get(FETCH_NOTIFICATION)
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.SET_NOTIFICATION_RESPONSE,
            notificationResponse: response,
          });
        } else {
          dispatch({
            type: actionTypes.SET_NOTIFICATION_RESPONSE,
            notificationResponse: [],
          });
        }
      })
      .catch(() => {
        // if (error.data && error.data.message) {
        //   dispatch({
        //     type: actionTypes.SET_NOTIFICATION_RESPONSE,
        //     notificationResponse: []
        //   });
        // }
      });
  };
};

export const FetchAllNotification = () => {
  return (dispatch) => {
    http
      .get(FETCH_ALL_NOTIFICATION)
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.SET_ALL_NOTIFICATION_RESPONSE,
            allNotification: response,
          });
        } else {
          dispatch({
            type: actionTypes.SET_ALL_NOTIFICATION_RESPONSE,
            allNotification: [],
          });
        }
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          // dispatch({
          //   type: actionTypes.SET_NOTIFICATION_RESPONSE,
          //   notificationResponse: []
          // });
        }
      });
  };
};

export const OnReadNotification = (notificationId, employeeIds) => {
  return (dispatch) => {
    http
      .put(READ_NOTIFICATION, { notificationId })
      .then((response) => {
        if (response) {
          dispatch(FetchAllNotification(employeeIds));
          dispatch(FetchNotification(employeeIds));
        }
      })
      .catch(console.error);
  };
};

export const MarkAllReadNotification = (employeeIds) => {
  return (dispatch) => {
    http
      .put(MARK_ALL_READ_NOTIFICATION)
      .then((response) => {
        if (response) {
          dispatch(FetchNotification(employeeIds));
          dispatch(FetchAllNotification(employeeIds));
        }
      })
      .catch(console.error);
  };
};

export const DeleteNotification = (notificationId, employeeIds) => {
  return (dispatch) => {
    http
      .delete(DELETE_NOTIFICATION, {}, {}, { notificationId })
      .then((response) => {
        if (response) {
          dispatch(FetchNotification(employeeIds));
          dispatch(FetchAllNotification(employeeIds));
        }
      })
      .catch(console.error);
  };
};

export const ClearAllNotification = (employeeIds) => {
  return (dispatch) => {
    http
      .delete(CLEAR_ALL_NOTIFICATION)
      .then((response) => {
        if (response) {
          dispatch(FetchNotification(employeeIds));
          dispatch(FetchAllNotification(employeeIds));
        }
      })
      .catch(console.error);
  };
};
