import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Container, Form } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import style from "./GlobalFeedBack.module.scss";
import * as actions from "store/actions/index";
import { errorToast, filterArray, successToast } from "util/general";
import { IC_COLLAPSE, IC_EXPAND } from "const/imgCost";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";
import NoDataFound from "../../../NodataFound/NoDataFound";
import ViewMoreText from "../../../ViewMoreText/ViewMoreText";
import FilterTeam from "../../../FilterTeam/FilterTeam";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function GlobalFeedBack() {
  const user = storage.getUser();
  const [typeArr, setTypeArr] = useState("Received");
  const currentUser = user?.employeeId;
  const [open, setOpen] = useState({ flag: false, id: null });
  const dispatch = useDispatch();
  const { employeeList, isLoading } = useSelector((state) => state.skillMatrix);
  const { globalFeedBack, myGlobalFeedBack, size, globalFeedbackLoading } = useSelector((state) => state.oneOnOne);

  const [certificateData, setCertificateData] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [feedBack, setFeedBack] = useState("");
  const [allEmployeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [toggle, setToggle] = useState({ flag: "close", id: null });

  useEffect(() => {
    if (globalFeedBack) {
      if (globalFeedBack.success) successToast(globalFeedBack.message);
      if (globalFeedBack.error) errorToast(globalFeedBack.message);
    }
  }, [globalFeedBack]);

  useEffect(() => {
    if (myGlobalFeedBack && myGlobalFeedBack.length > 0) {
      setCertificateData(myGlobalFeedBack);
    }
  }, [myGlobalFeedBack]);

  useEffect(() => {
    filteredData.length > 0
      ? setCertificateData(filteredData)
      : searchFilter
      ? setCertificateData(filteredData)
      : setCertificateData(myGlobalFeedBack);
  }, [filteredData, myGlobalFeedBack, searchFilter]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    if (certificateData && certificateData.length > 0) {
      filteredData = filterArray(certificateData, filters);
      setFilteredData(filteredData);
    }
  };

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
    setOpen({
      flag: false,
      id: null,
    });
  };

  const filterByRequest = (e) => {
    setTypeArr(e.target.value);
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    let result_array = [];
    if (myGlobalFeedBack && myGlobalFeedBack.length > 0) {
      if (typeArr === "Received") {
        if (searchText && searchText.length > 0) {
          for (let i = 0; i < myGlobalFeedBack.length; i++) {
            if (
              myGlobalFeedBack[i].givenTo.employeeId === currentUser &&
              myGlobalFeedBack[i].givenBy.empName.toLowerCase().includes(searchText.toLowerCase().trim())
            ) {
              result_array.push(myGlobalFeedBack[i]);
            }
          }
        } else {
          for (let i = 0; i < myGlobalFeedBack.length; i++) {
            if (myGlobalFeedBack[i].givenTo.employeeId === currentUser) {
              result_array.push(myGlobalFeedBack[i]);
            }
          }
        }
      } else {
        if (searchText && searchText.length > 0) {
          for (let i = 0; i < myGlobalFeedBack.length; i++) {
            if (
              myGlobalFeedBack[i].givenBy.employeeId === currentUser &&
              myGlobalFeedBack[i].givenTo.empName.toLowerCase().includes(searchText.toLowerCase().trim())
            ) {
              result_array.push(myGlobalFeedBack[i]);
            }
          }
        } else {
          for (let i = 0; i < myGlobalFeedBack.length; i++) {
            if (myGlobalFeedBack[i].givenBy.employeeId === currentUser) {
              result_array.push(myGlobalFeedBack[i]);
            }
          }
        }
      }
    } else {
      result_array = [];
    }
    setPageOfItems([]);
    setCertificateData(result_array);
  }, [myGlobalFeedBack, currentUser, searchText, typeArr]);

  useEffect(() => {
    const getEmployee = () => {
      dispatch(actions.employeeList);
    };
    getEmployee();

    const getFeedBackList = () => {
      dispatch(actions.getGlobalFeedBack);
    };
    getFeedBackList();
  }, [dispatch]);

  useEffect(() => {
    if (employeeList.length > 0) {
      let options_array = employeeList.map((item) => {
        return {
          value: item.employeeId,
          label: item.displayName,
          employeeId: item.employeeId,
          parentGroupId: item.parentGroupId,
          subGroupId: item.subGroupId,
        };
      });
      setEmployeeList(options_array);
    }
  }, [employeeList]);

  const handleSelectedEmployee = (input) => {
    setSelectedEmployee(input);
  };

  const handleSubmitButton = () => {
    let trimmed_value = feedBack.trim();
    let data = {
      feedback: trimmed_value,
      employeeId: {
        employeeId: selectedEmployee.employeeId,
        parentGroupId: selectedEmployee.parentGroupId,
        subGroupId: selectedEmployee.subGroupId,
      },
    };
    dispatch(actions.postGlobalFeedBack(data));
    setFeedBack("");
    setSelectedEmployee("");
  };

  const handleFeedBackChange = (value) => {
    setFeedBack(value);
  };

  useEffect(() => {
    let trimmed_value = feedBack.trim();
    if (selectedEmployee && trimmed_value && trimmed_value.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [buttonDisabled, feedBack, selectedEmployee]);

  const toggleText = (feedback_id) => {
    toggle.flag === "close" ? setToggle({ flag: "open", id: feedback_id }) : setToggle({ flag: "close", id: null });
  };

  const radio_button_name = {
    value: ["Received", "Sent"],
    name: ["Received", "Sent"],
  };

  const resetSearch = () => {
    setSearchFilter("");
    setSearchText("");
  };

  return (
    <>
      <div>
        <SubNavigation type="OKR Progress" />
        <Container>
          <Row>
            <Col md={6} className="col-12">
              <h1 className="page-title pb-0">OKR Progress</h1>
              <span className="st-breadcrumb">Global Feedback</span>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col lg={12}>
              {(isLoading || globalFeedbackLoading) && <Spinner />}
              <div className="box">
                <div className="box-heading-wrapper box-heading">
                  <div className="box-heading">
                    <h2>Global Feedback</h2>
                    <h3>You can give feedback to any employee of the company globally</h3>
                  </div>
                </div>
                <div className="box-light-gradient h38"></div>
                <div className={[style.filter_box, "form-group"].join(" ")}>
                  <span className={style.dd_title}>Select Employee Name</span>
                  <div>
                    <Select
                      options={allEmployeeList}
                      className="custom-default-dd-box"
                      classNamePrefix="select"
                      isSearchable={true}
                      isClearable={true}
                      placeholder="Select..."
                      onChange={(select) => handleSelectedEmployee(select)}
                      value={selectedEmployee}
                    />
                  </div>
                  <span className={style.dd_title}>Feedback</span>
                  <Form.Control
                    as="textarea"
                    className={[style.desc_box, "form-control"].join(" ")}
                    value={feedBack}
                    maxLength="2000"
                    placeholder="Your Feedback"
                    onChange={(e) => handleFeedBackChange(e.target.value)}
                  />
                </div>
                <div className={style.filter_btn_group}>
                  <button
                    type="button"
                    className={["btn-round save_box_btn btn btn-primary", style.search_btn].join(" ")}
                    onClick={handleSubmitButton}
                    disabled={buttonDisabled}
                  >
                    <strong>Submit</strong>
                  </button>
                </div>
                <div className="gap30"></div>
              </div>

              <div className="box">
                <div className="box-heading-wrapper box-heading">
                  <div className="box-heading">
                    <h2>Feedback History</h2>
                  </div>
                </div>
                <div className={style.search_Filter_wrapper}>
                  <FilterTeam
                    filterValue={typeArr}
                    placeholder="Search by Employee Name"
                    filter={radio_button_name}
                    value={searchText}
                    teamData={certificateData}
                    onSearch={handleSearchInputChange}
                    onRadioChange={filterByRequest}
                    onReset={resetData}
                    onResetSearch={resetSearch}
                  />
                </div>
                <div className={style.table_responsive}>
                  <div className={style.table_width}>
                    <div className="box-light-gradient h38"></div>
                    <div className="gap30"></div>

                    {pageOfItems && pageOfItems.length !== 0 ? (
                      <div className={style.table_div}>
                        <div className="custom-table table-theme-1">
                          <div className={[style.feedback_table, "position-relative"].join(" ")}>
                            <div className={style.thead}>
                              <span className={style.heading_employeeName}>Employee Name</span>
                              <span className={style.heading_feedbackDate}>Date</span>
                              <span className={style.heading_feedback}>Feedback</span>
                            </div>
                            <div className={[style.tbody].join(" ")}>
                              {pageOfItems.map((fb) => (
                                <li key={fb.feedbackId}>
                                  <div
                                    className={[
                                      style.item_info_top_wrapper,
                                      open.flag === true && open.id === fb.feedbackId ? "active" : "",
                                    ].join(" ")}
                                  >
                                    <div className={style.member_info}>
                                      <div
                                        className={style.fixed_name}
                                        onClick={() => {
                                          if (open.id !== fb.feedbackId) {
                                            setOpen({ flag: true, id: fb.feedbackId });
                                            setToggle({ flag: "close", id: null });
                                          } else {
                                            setOpen({ flag: !open.flag, id: fb.feedbackId });
                                          }
                                        }}
                                      >
                                        <i className={style.expand_collapse_wrapper}>
                                          {open.id === fb.feedbackId && open.flag ? (
                                            <img src={IC_COLLAPSE} className={style.collapse} alt="Collapse" />
                                          ) : (
                                            <img src={IC_EXPAND} className={style.expand} alt="Expand" />
                                          )}
                                        </i>
                                        <div className={style.feedback_name}>
                                          <span>
                                            {typeArr === "Received" ? fb.givenBy.empName : fb.givenTo.empName}
                                          </span>
                                        </div>
                                      </div>
                                      {open.id === fb.feedbackId && open.flag === true && (
                                        <div style={{ width: "68%" }}>
                                          <ul className="common_list_items">
                                            {fb.feedbackList.map((lt, index) => {
                                              let dt = moment(lt.createdDate).format("MMM DD, YYYY");
                                              let show = false;
                                              if (toggle.flag === "open") {
                                                show = true;
                                              } else {
                                                if (index < 2) {
                                                  show = true;
                                                }
                                              }
                                              if (show && fb.feedbackId) {
                                                return (
                                                  <div className={style.feedback_div} key={index}>
                                                    <div className={style.feedback_date}>{dt}</div>
                                                    <div className={style.comment_txt}>
                                                      <span className={style.dot}>
                                                        <li></li>
                                                      </span>
                                                      <div className={style.feedback_area}>
                                                        <ViewMoreText
                                                          text={lt.feedback ? lt.feedback : "-"}
                                                          length={100}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              } else {
                                                return <></>;
                                              }
                                            })}
                                            {fb.feedbackList.length > 2 && (
                                              <div className={style.viewFeedbackContainer}>
                                                <span onClick={() => toggleText(fb.feedbackId)}>
                                                  {toggle.flag === "open" ? "View Less" : "View all feedback"}
                                                </span>
                                              </div>
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <NoDataFound />
                        <div className="gap120"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isLoading ? (
                <Spinner />
              ) : certificateData && certificateData.length > 0 ? (
                <Pagination pageSize={size} items={certificateData} onChangePage={onChangePage} resetData={resetData} />
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
