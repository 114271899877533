import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import style from "./DeallocationFeedback.module.scss";
import * as actions from "store/actions/index";
import { statusWiseOptions } from "const/options";
import { moduleRights } from "util/utils";
import { errorToast, filterOptions, pleaseSelectValue, successToast } from "util/general";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../Pagination/Pagination";
import NoDataFound from "../../NodataFound/NoDataFound";
import DeallocationMemberRow from "./DeallocationMemberRow";
import DeallocationQuestionModal from "./DeallocationQuestionModal/DeallocationQuestionModal";
import NoRecordFoundModal from "../../NodataFound/NoRecordFoundModal";
import SearchAndClearFilter from "../../SearchAndClearFilter/SearchAndClearFilter";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function DeallocationFeedback() {
  const dispatch = useDispatch();
  const [pageOfItems, setPageOfItems] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [isOneEmployee, setIsOneEmployee] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState();
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showNoRecordModal, setShowNoRecordModal] = useState(false);
  const {
    deallocationReportee,
    filterReporteeListLoader,
    isFileLoading,
    deallocationFilterData,
    fileResponse,
    locationList,
    employeeNameList,
    departmentList,
  } = useSelector((state) => state.deAllocation);
  const { subGroupClient } = useSelector((state) => state.admin);
  const { filterLocation, filterEmployee, filterDepartment, filterStatus, startDate, endDate, filterSubGroups } =
    deallocationFilterData;
  const pageSize = useSelector((state) => state.myTeam.pageSize);
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);

  useEffect(() => {
    dispatch(actions.GetDeallocationFilterDataList());
    dispatch(actions.getSubGroupClient());
  }, [dispatch]);

  useEffect(() => {
    setTeamData(deallocationReportee);
  }, [deallocationReportee]);

  useEffect(() => {
    if (fileResponse) {
      if (fileResponse.success) successToast(fileResponse.message);
      else errorToast(fileResponse.message);
      dispatch({ type: "CLEAR_DEALLOCATION_FILE_RESPONSE" });
    }
  }, [fileResponse, dispatch]);

  const resetData = () => {
    setTeamData(
      deallocationReportee.map((v) => {
        return {
          ...v,
          modifiedData: true,
        };
      })
    );
  };

  const handleView = (empInfo) => {
    setEmployeeInfo(empInfo);
    if (empInfo.status === "PENDING") {
      setShowNoRecordModal(true);
    } else if (empInfo.status === "SUBMIT") {
      setShowQuestionModal(true);
    } else if (empInfo.status === "INVALIDATE") {
      setShowQuestionModal(true);
    }
  };

  const handleClear = () => {
    setTeamData([]);
    dispatch({
      type: "CLEAR_DEALLOCATION_FILTER_DATA",
    });
  };

  const handleFilter = () => {
    setIsOneEmployee(filterEmployee && filterEmployee.length === 1);
    let statusParams;
    if (filterStatus) {
      statusParams = filterStatus?.value === "ALL" ? ["SUBMIT", "PENDING", "INVALIDATE"] : [filterStatus?.value];
    }
    let body = {
      compositeEmployeeIds:
        filterEmployee?.length > 0
          ? filterEmployee.map((emp) => {
              return {
                employeeId: emp.value,
                subGroupId: emp.subGroupId,
                parentGroupId: emp.parentGroupId,
              };
            })
          : undefined,
      department: filterDepartment?.length > 0 ? filterDepartment.map((v) => v.label) : undefined,
      location: filterLocation?.length > 0 ? filterLocation.map((v) => v.label) : undefined,
      startDate: startDate ? moment(startDate).format(config.dates.secondary) : undefined,
      endDate: endDate ? moment(endDate).format(config.dates.secondary) : undefined,
      status: filterStatus ? statusParams : undefined,
      subGroupId: filterSubGroups ? filterSubGroups.value : undefined,
    };
    if (
      filterDepartment?.length > 0 ||
      filterEmployee?.length > 0 ||
      filterLocation?.length > 0 ||
      startDate ||
      endDate ||
      filterStatus ||
      filterSubGroups
    )
      dispatch(actions.GetDeallocationReporteeList(body, filterEmployee?.length === 1));
    else {
      pleaseSelectValue();
    }
  };

  const downloadFile = (fileId, questionId, projectDeAllocationId) => {
    let params = {
      fileId,
      questionId,
      projectDeAllocationId,
    };
    dispatch(actions.DownloadFileInDeallocation(params));
  };

  const handleFilterData = (variable, e) => {
    dispatch({
      type: "SET_DEALLOCATION_FILTER_DATA",
      payload: {
        [variable]: e,
      },
    });
  };

  const isOneEmpFromMultipleProject = isOneEmployee && deallocationReportee?.length > 1;

  return (
    <div className={style.feedback_wrapper}>
      <SubNavigation type="WMG Feedback" />
      {(filterReporteeListLoader || isFileLoading) && <Spinner />}
      <div className={style.feedback_inner_wrapper}>
        <Container>
          <Row>
            <Col md={6} lg={4} sm={6} className="col-12">
              <h1 className="page-title pb-0">WMG</h1>
              <span className="st-breadcrumb">
                Feedback<span>WMG Deallocation Feedback</span>
              </span>
            </Col>
            {moduleRights(config.mainModule.PERFORMANCE, config.subModule.MMG_DEALLOCATION_FEEDBACK)?.includes(
              "EXPORT"
            ) && (
              <Col lg={8} md={6} sm={6} className="text-right col-12">
                <button
                  onClick={() => dispatch(actions.DownloadDeallocationData())}
                  type="button"
                  className={["btn-round save_box_btn btn btn-primary", style.export_btn].join(" ")}
                >
                  <strong>Export</strong>
                </button>
              </Col>
            )}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={12}>
              <SearchAndClearFilter
                statusWiseOptions={statusWiseOptions}
                description={"Select appropriate values of employees to view feedback"}
                filterLocation={filterLocation}
                filterEmployee={filterEmployee}
                filterDepartment={filterDepartment}
                filterStatus={filterStatus}
                startDate={startDate}
                endDate={endDate}
                departmentList={departmentList}
                locationList={locationList}
                employeeNameList={employeeNameList}
                handleClear={handleClear}
                handleFilter={handleFilter}
                filterOptions={filterOptions}
                handleFilterData={handleFilterData}
                isDisplayStartDate={true}
                isDisplayEndDate={true}
                displayStatus={true}
                maxStartDate={endDate}
                isDisplaySubGroup={true}
                subGroupClient={subGroupClient}
                filterSubGroups={filterSubGroups}
                isSubGroupSingleSelect={true}
                displayLocation={true}
                displayDepartment={true}
              />
            </Col>
            <Col md={12}>
              <div className={[style.table_layout, "box pt-0"].join(" ")}>
                <div className={style.deallocation_employee_list_heading}>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className={style.tab_title}>
                        <h2> Deallocation Feedback</h2>
                        {/* <h3>Some Text goes here</h3> */}
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}></Col>
                  </Row>
                </div>
                <div className="box-light-gradient"></div>
                {teamData.length > 0 ? (
                  <div className="box-inner-pad pb-0">
                    <div className={style.table_wrapper}>
                      <ul className={style.employee_list_header}>
                        <li className={style.name}>Name</li>
                        <li className={style.manager}>Manager</li>
                        <li className={style.project_name}>Project/Duration</li>
                        <li className={style.location}>Location</li>
                        <li className={style.deallocation_date}>
                          Deallocation
                          <br /> Date
                        </li>
                        <li className={style.status}>Status</li>
                        {!isOneEmployee && <li className={style.action}>Action</li>}
                      </ul>
                      <ul className={style.employee_list}>
                        {pageOfItems.map((empInfo, index) => (
                          <React.Fragment key={empInfo.projectDeAllocationId}>
                            <DeallocationMemberRow
                              downloadFile={downloadFile}
                              isOneEmployee={isOneEmployee}
                              empInfo={empInfo}
                              index={index}
                              isOneEmpFromMultipleProject={isOneEmpFromMultipleProject}
                              handleView={handleView}
                            />
                          </React.Fragment>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div>
                    <NoDataFound description="The data is not yet found" />
                    <div className="gap80"></div>
                  </div>
                )}
              </div>
            </Col>
            <Col>
              {teamData && deallocationReportee?.length > 0 && (
                <Pagination pageSize={pageSize} items={teamData} onChangePage={onChangePage} resetData={resetData} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {employeeInfo && (
        <DeallocationQuestionModal
          hide={() => {
            setShowQuestionModal(false);
            setEmployeeInfo();
          }}
          employeeInfo={employeeInfo}
          show={showQuestionModal}
          downloadFile={(fileId, questionId) => downloadFile(fileId, questionId, employeeInfo.projectDeAllocationId)}
        />
      )}
      {employeeInfo && (
        <NoRecordFoundModal
          hide={() => setShowNoRecordModal(false)}
          show={showNoRecordModal}
          title={`Project Feedback - ${
            employeeInfo.projectName?.length > 0 && employeeInfo.projectName.map((v) => v.projectName).join(", ")
          }`}
        />
      )}
    </div>
  );
}
