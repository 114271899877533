import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import style from "./QuestionBuilder.module.scss";
import Select from "react-select";
import QuestionCheckbox from "./questionType/questionCheckbox";
import QuestionDropdown from "./questionType/questionDropdown";
import QuestionRadio from "./questionType/questionRadio";
import QuestionSlider from "./questionType/questionSlider";
import QuestionTextbox from "./questionType/questionTextbox";
import QuestionScaled from "./questionType/questionScaled";
import QuestionRank from "./questionType/questionRank";
import QuestionNPS from "./questionType/questionNPS";
import { useDispatch } from "react-redux";
import storage from "util/storage";

export default function CreateQuestion(props) {
  const dispatch = useDispatch();
  const campaignType = storage.get("campaignType") ? storage.get("campaignType") : null;

  const options_builder =
    campaignType === "Quiz"
      ? [
          { value: "SELECT", label: "Select type" },
          { value: "CHECKBOX", label: "Multi Choice" },
          { value: "RADIOBUTTON", label: "Single Choice" },
        ]
      : [
          { value: "SELECT", label: "Select type" },
          { value: "CHECKBOX", label: "Multi Choice" },
          { value: "RADIOBUTTON", label: "Single Choice" },
          { value: "TEXTBOX", label: "Open Ended" },
          { value: "DROPDOWN", label: "Dropdown" },
          { value: "SLIDER", label: "Slider" },
          { value: "SCALED", label: "Scaled" },
          { value: "NPS", label: "NPS" },
          { value: "RANK", label: "Rank" },
        ];

  const [questionType, setQuestionType] = useState(null);
  let obj = options_builder[0];
  if (props.questionDetail.type === "SELECT") {
    obj = options_builder[0];
  } else if (props.questionDetail.type === "CHECKBOX") {
    obj = options_builder[1];
  } else if (props.questionDetail.type === "RADIOBUTTON") {
    obj = options_builder[2];
  } else if (props.questionDetail.type === "TEXTBOX") {
    obj = options_builder[3];
  } else if (props.questionDetail.type === "DROPDOWN") {
    obj = options_builder[4];
  } else if (props.questionDetail.type === "SLIDER") {
    obj = options_builder[5];
  } else if (props.questionDetail.type === "SCALED") {
    obj = options_builder[5];
  } else if (props.questionDetail.type === "NPS") {
    obj = options_builder[5];
  } else if (props.questionDetail.type === "RANK") {
    obj = options_builder[5];
  }

  const [_value, setValue] = useState(obj);
  const [questionDetail, setQuestionDetail] = useState(props.questionDetail);
  const [qName, setQName] = useState(props.questionDetail.questionName);
  useEffect(() => {
    // let data = {
    //     type: "qname",
    //     questionTitle: props.questionDetail.questionTitle,
    //     questionName: qName,
    //     dropdown: _value.value
    // }
    // props.setTemplateData(data)
  }, [qName]);

  useEffect(() => {
    let type = null;
    setQName(props.questionDetail.questionName);
    if (props.questionDetail.type === "CHECKBOX") {
      type = (
        <QuestionCheckbox
          respondOptionVisible={props.respondOptionVisible}
          setTemplate={props.setTemplate}
          questionDetail={props.questionDetail}
          setValue={setValue}
          setQuestionType={setQuestionType}
          setTemplateData={props.setTemplateData}
          removeQuestion={props.removeQuestion}
          saveQuestion={props.saveQuestion}
          template={props.template}
        />
      );
      setValue(options_builder[1]);
      setQuestionType(type);
    }
    if (props.questionDetail.type === "RADIOBUTTON") {
      type = (
        <QuestionRadio
          respondOptionVisible={props.respondOptionVisible}
          setTemplate={props.setTemplate}
          questionDetail={props.questionDetail}
          setValue={setValue}
          setQuestionType={setQuestionType}
          setTemplateData={props.setTemplateData}
          removeQuestion={props.removeQuestion}
          saveQuestion={props.saveQuestion}
          template={props.template}
        />
      );
      setValue(options_builder[2]);
      setQuestionType(type);
    }
    if (props.questionDetail.type === "TEXTBOX") {
      type = (
        <QuestionTextbox
          respondOptionVisible={props.respondOptionVisible}
          setTemplate={props.setTemplate}
          questionDetail={props.questionDetail}
          setValue={setValue}
          setQuestionType={setQuestionType}
          setTemplateData={props.setTemplateData}
          removeQuestion={props.removeQuestion}
          saveQuestion={props.saveQuestion}
          template={props.template}
        />
      );
      setQuestionType(type);
      setValue(options_builder[3]);
    }
    if (props.questionDetail.type === "DROPDOWN") {
      type = (
        <QuestionDropdown
          respondOptionVisible={props.respondOptionVisible}
          setTemplate={props.setTemplate}
          questionDetail={props.questionDetail}
          setValue={setValue}
          setQuestionType={setQuestionType}
          setTemplateData={props.setTemplateData}
          removeQuestion={props.removeQuestion}
          saveQuestion={props.saveQuestion}
          template={props.template}
        />
      );
      setQuestionType(type);
      setValue(options_builder[4]);
    }
    if (props.questionDetail.type === "SLIDER") {
      type = (
        <QuestionSlider
          respondOptionVisible={props.respondOptionVisible}
          setTemplate={props.setTemplate}
          questionDetail={props.questionDetail}
          setValue={setValue}
          setQuestionType={setQuestionType}
          setTemplateData={props.setTemplateData}
          removeQuestion={props.removeQuestion}
          saveQuestion={props.saveQuestion}
          template={props.template}
        />
      );
      setQuestionType(type);
      setValue(options_builder[5]);
    }
    if (props.questionDetail.type === "SCALED") {
      type = (
        <QuestionScaled
          respondOptionVisible={props.respondOptionVisible}
          setTemplate={props.setTemplate}
          questionDetail={props.questionDetail}
          setValue={setValue}
          setQuestionType={setQuestionType}
          setTemplateData={props.setTemplateData}
          removeQuestion={props.removeQuestion}
          saveQuestion={props.saveQuestion}
          template={props.template}
        />
      );
      setQuestionType(type);
      setValue(options_builder[6]);
    }
    if (props.questionDetail.type === "NPS") {
      type = (
        <QuestionNPS
          respondOptionVisible={props.respondOptionVisible}
          setTemplate={props.setTemplate}
          questionDetail={props.questionDetail}
          setValue={setValue}
          setQuestionType={setQuestionType}
          setTemplateData={props.setTemplateData}
          removeQuestion={props.removeQuestion}
          saveQuestion={props.saveQuestion}
          template={props.template}
        />
      );
      setQuestionType(type);
      setValue(options_builder[7]);
    }
    if (props.questionDetail.type === "RANK") {
      type = (
        <QuestionRank
          respondOptionVisible={props.respondOptionVisible}
          setTemplate={props.setTemplate}
          questionDetail={props.questionDetail}
          setValue={setValue}
          setQuestionType={setQuestionType}
          setTemplateData={props.setTemplateData}
          removeQuestion={props.removeQuestion}
          saveQuestion={props.saveQuestion}
          template={props.template}
        />
      );
      setQuestionType(type);
      setValue(options_builder[8]);
    }
  }, [props.questionDetail]);
  return (
    <div className="box-inner-pad" id={questionDetail.questionTitle}>
      <div className={style.quesion_l_wrapper}>
        <Form.Group className={style.quesion_textbox} controlId="">
          <span className={style.quesion_counter}>Q{props.no}</span>
          <Form.Control
            type="text"
            placeholder="Enter your question"
            value={qName}
            disabled={!props.template.questionTemplateId ? true : false}
            onChange={(e) => {
              setQName(e.target.value);
              let data = {
                type: "qname",
                questionTitle: props.questionDetail.questionTitle,
                questionName: e.target.value,
                dropdown: _value.value,
              };
              props.setTemplateData(data);
            }}
          ></Form.Control>
        </Form.Group>
        <Form.Group className={style.quesion_textbox_dd}>
          <Select
            options={options_builder}
            className="custom-default-dd-box question-dropdown-icon"
            classNamePrefix="select"
            isSearchable={false}
            defaultValue={questionDetail.type}
            value={_value}
            isDisabled={!props.template.questionTemplateId ? true : props.questionDetail.questionId ? true : false}
            onFocus={() => {
              dispatch({
                type: "SET_ONCHANGE",
                data: true,
              });
            }}
            onChange={(e) => {
              let data;
              let _data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                otherOption: {
                  isOther: false,
                  otherText: "",
                  isRequired: false,
                  optionalCommentBox: false,
                  description: "",
                  descriptionFlag: "",
                },
                dropdown: "SELECT",
              };
              props.setTemplateData(_data);
              data = {
                type: "options",
                questionTitle: props.questionDetail.questionTitle,
                options: [
                  {
                    optionId: 1,
                    option: "",
                    isCorrect: false,
                  },
                ],
                dropdown: "SELECT",
              };
              props.setTemplateData(data);
              let type = null;
              setValue(e);
              switch (e.value) {
                case "SELECT":
                  type = null;
                  setQuestionType(type);
                  break;
                case "CHECKBOX":
                  data = {
                    type: "options",
                    questionTitle: props.questionDetail.questionTitle,
                    options: [
                      {
                        optionId: Math.floor(100000 + Math.random() * 900000),
                        option: "",
                        isCorrect: false,
                      },
                      {
                        optionId: Math.floor(100000 + Math.random() * 900000) + 1,
                        option: "",
                        isCorrect: false,
                      },
                    ],
                    dropdown: e.value,
                  };
                  props.setTemplateData(data);
                  type = (
                    <QuestionCheckbox
                      respondOptionVisible={props.respondOptionVisible}
                      setTemplate={props.setTemplate}
                      questionDetail={questionDetail}
                      setValue={setValue}
                      setQuestionType={setQuestionType}
                      setTemplateData={props.setTemplateData}
                      removeQuestion={props.removeQuestion}
                      saveQuestion={props.saveQuestion}
                      template={props.template}
                    />
                  );
                  setQuestionType(type);
                  break;
                case "RADIOBUTTON":
                  data = {
                    type: "options",
                    questionTitle: props.questionDetail.questionTitle,
                    options: [
                      {
                        optionId: Math.floor(100000 + Math.random() * 900000),
                        option: "",
                        isCorrect: false,
                      },
                      {
                        optionId: Math.floor(100000 + Math.random() * 900000) + 1,
                        option: "",
                        isCorrect: false,
                      },
                    ],
                    dropdown: e.value,
                  };
                  props.setTemplateData(data);
                  type = (
                    <QuestionRadio
                      respondOptionVisible={props.respondOptionVisible}
                      setTemplate={props.setTemplate}
                      questionDetail={questionDetail}
                      setValue={setValue}
                      setQuestionType={setQuestionType}
                      setTemplateData={props.setTemplateData}
                      removeQuestion={props.removeQuestion}
                      saveQuestion={props.saveQuestion}
                      template={props.template}
                    />
                  );
                  setQuestionType(type);
                  break;
                case "TEXTBOX":
                  data = {
                    type: "options",
                    questionTitle: props.questionDetail.questionTitle,
                    dropdown: e.value,
                    options: [],
                  };
                  props.setTemplateData(data);
                  type = (
                    <QuestionTextbox
                      respondOptionVisible={props.respondOptionVisible}
                      setTemplate={props.setTemplate}
                      questionDetail={questionDetail}
                      setValue={setValue}
                      setQuestionType={setQuestionType}
                      setTemplateData={props.setTemplateData}
                      removeQuestion={props.removeQuestion}
                      saveQuestion={props.saveQuestion}
                      template={props.template}
                    />
                  );
                  setQuestionType(type);
                  break;
                case "DROPDOWN":
                  data = {
                    type: "options",
                    questionTitle: props.questionDetail.questionTitle,
                    options: [
                      {
                        optionId: 1,
                        option: "",
                        isCorrect: false,
                      },
                      {
                        optionId: 2,
                        option: "",
                        isCorrect: false,
                      },
                    ],
                    dropdown: e.value,
                  };
                  props.setTemplateData(data);
                  type = (
                    <QuestionDropdown
                      respondOptionVisible={props.respondOptionVisible}
                      setTemplate={props.setTemplate}
                      questionDetail={questionDetail}
                      setValue={setValue}
                      setQuestionType={setQuestionType}
                      setTemplateData={props.setTemplateData}
                      removeQuestion={props.removeQuestion}
                      saveQuestion={props.saveQuestion}
                      template={props.template}
                    />
                  );
                  setQuestionType(type);
                  break;
                case "SLIDER":
                  data = {
                    type: "options",
                    questionTitle: props.questionDetail.questionTitle,
                    options: [
                      {
                        optionId: 1,
                        option: "",
                        optionValue: "",
                        isCorrect: false,
                      },
                      {
                        optionId: 2,
                        option: "",
                        optionValue: "",
                        isCorrect: false,
                      },
                    ],
                    dropdown: e.value,
                  };
                  props.setTemplateData(data);
                  type = (
                    <QuestionSlider
                      respondOptionVisible={props.respondOptionVisible}
                      setTemplate={props.setTemplate}
                      questionDetail={questionDetail}
                      setValue={setValue}
                      setQuestionType={setQuestionType}
                      setTemplateData={props.setTemplateData}
                      removeQuestion={props.removeQuestion}
                      saveQuestion={props.saveQuestion}
                      template={props.template}
                    />
                  );
                  setQuestionType(type);
                  break;
                case "SCALED":
                  data = {
                    type: "options",
                    questionTitle: props.questionDetail.questionTitle,
                    dropdown: e.value,
                    options: [],
                  };
                  props.setTemplateData(data);
                  type = (
                    <QuestionScaled
                      respondOptionVisible={props.respondOptionVisible}
                      setTemplate={props.setTemplate}
                      questionDetail={questionDetail}
                      setValue={setValue}
                      setQuestionType={setQuestionType}
                      setTemplateData={props.setTemplateData}
                      removeQuestion={props.removeQuestion}
                      saveQuestion={props.saveQuestion}
                      template={props.template}
                    />
                  );
                  setQuestionType(type);
                  break;
                case "NPS":
                  data = {
                    type: "options",
                    questionTitle: props.questionDetail.questionTitle,
                    dropdown: e.value,
                    options: [],
                  };
                  props.setTemplateData(data);
                  type = (
                    <QuestionNPS
                      respondOptionVisible={props.respondOptionVisible}
                      setTemplate={props.setTemplate}
                      questionDetail={questionDetail}
                      setValue={setValue}
                      setQuestionType={setQuestionType}
                      setTemplateData={props.setTemplateData}
                      removeQuestion={props.removeQuestion}
                      saveQuestion={props.saveQuestion}
                      template={props.template}
                    />
                  );
                  setQuestionType(type);
                  break;
                case "RANK":
                  data = {
                    type: "options",
                    questionTitle: props.questionDetail.questionTitle,
                    options: [
                      {
                        optionId: 1,
                        option: "",
                        optionValue: "",
                        isCorrect: false,
                      },
                      {
                        optionId: 2,
                        option: "",
                        optionValue: "",
                        isCorrect: false,
                      },
                    ],
                    dropdown: e.value,
                  };
                  props.setTemplateData(data);
                  type = (
                    <QuestionRank
                      respondOptionVisible={props.respondOptionVisible}
                      setTemplate={props.setTemplate}
                      questionDetail={questionDetail}
                      setValue={setValue}
                      setQuestionType={setQuestionType}
                      setTemplateData={props.setTemplateData}
                      removeQuestion={props.removeQuestion}
                      saveQuestion={props.saveQuestion}
                      template={props.template}
                    />
                  );
                  setQuestionType(type);
                  break;
                default:
                  type = null;
                  setQuestionType(type);
                  break;
              }
            }}
          />
        </Form.Group>
      </div>
      {questionType}
    </div>
  );
}
