import React from "react";
import { Container, Alert } from "react-bootstrap";
import style from "./InformationHub.module.scss";
import { IMG_RESOURCES } from "const/imgCost";
import InformationWrapper from "../../components/InformationHub/InformationHubWrapper";

export default function Resources() {

  return (
    <div>
      <Container>
        <Alert
          className={`alert_engage_introduction ${style.banner_wrapper}`}
          variant="primary"
          show={true}
        >
          <div className="welcome_wrapper">
            <div className={style.content_wrapper}>
              <p>
                This section functions as a centralized and accessible information repository on the Engage Platform, meticulously crafted to streamline knowledge access. It offers individuals the convenience of locating answers, gaining insights, and accessing specific details on their chosen section.
              </p>
            </div>
            <span className={style.right_image}>
              <img src={IMG_RESOURCES} alt="Resources Banner" />
            </span>
          </div>
        </Alert>
        <div className="box">
          <InformationWrapper />
        </div>
      </Container>
    </div>
  );
}
