import React from "react";
import style from "./NestLogin.module.scss";
import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import Axios from "axios";
import useForm from "react-hook-form";

function NestLogin() {
  // const unsubscribe = new Subject();
  // const [token, setToken] = useState("");
  const schema = yup.object().shape({
    userName: yup.string().required("username is a required field"),
    password: yup.string().required("password is a required field"),
  });
  const { handleSubmit, register } = useForm({
    validationSchema: schema,
  });

  const onSubmit = (data) => {
    // const jasonData = JSON.stringify(data)
    Axios.post("https://api-engage.infostretch.com/nest-service/nest-login", data)
      .then((response) => {
        console.warn("NEST RESPONSE", response);
      })
      .catch((error) => console.warn("catch error: ", error));
  };

  return (
    <div className={style.simple_container}>
      <p className={style.p}> Login</p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label className="label">Username</label>
          <input type="text" name="userName" ref={register} className={style.input} />
        </div>
        <div>
          <label className="label">password</label>
          <input type="password" name="password" ref={register} className={style.input} />
          <Button variant="primary" type="submit" className={style.nestLoginButton}>
            Login
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default NestLogin;
