import React, { useEffect, useState } from "react";
import SingleManagerWrapper from "./SingleManagerCheckin/SingleManagerWrapper";
import MultiManagerWrapper from "./MultiManagerCheckin/MultiManagerWrapper";
import PastCheckIns from "./PastCheckIns/PastCheckIns";
import NoRecordFound from "components/NodataFound/NoRecordFound";
import { useDispatch, useSelector } from "react-redux";
import { MonthlyCheckinTexts } from "const/constValue";
import storage from "util/storage";
import * as actions from "store/actions/index";
import moment from "moment";
import config from "../../../util/config";
import Spinner from "shared/Spinner/Spinner";
import { IMG_WAITING_FOR_SUBMISSION } from "const/imgCost";

export default function MonthlyCheckInWrapper() {
  const loggedInUser = storage.getUser();
  const dispatch = useDispatch();
  const [currentCheckIn,setCurrentCheckIn] = useState();
  const [isCleared,setIsCleared] = useState(false);

  const { currentFeedbackDataEmployee,
    submitFeedbackEmployeeManager,
    currentFeedbackDataEmployeeMessage,
    questionIndex,
    feedbackHistoryEmployee,
    totalCount,
    managerList,
    historyDetails,
    isLoading,
    showYearListData,
  } = useSelector((state) => state.monthlyCheckinFeedback);
    
  useEffect(() => {
    dispatch(actions.getCurrentfeedbackEmployee());
    dispatch(actions.showYearList());
  }, []);

  useEffect(() => {
    const currentCheckIn = moment(currentFeedbackDataEmployee?.employeeStartDate).format(config?.dates?.shortMonthNameAndDate) + " - " + moment(currentFeedbackDataEmployee?.employeeEndDate).format(config?.dates?.shortMonthNameAndDate) + ", " + moment(currentFeedbackDataEmployee?.employeeEndDate).format("YYYY");
    setCurrentCheckIn(currentCheckIn);
  }, [currentFeedbackDataEmployee]);

  const handleChangeResult = (managerData,questionId,answer) => {
    let body = {
      questionId,
      answer,
      managerCompositeId : {
        employeeId : managerData.employeeId,
        parentGroupId : managerData.parentGroupId,
        subGroupId : managerData.subGroupId
      }
    }
    dispatch(actions.feedbackInteractionEmployeeAndManager(body,"employee"));
  }

  const handleSubmitComment = (managerData,message) => {
    let body = {
      message,
      managerCompositeId : {
        employeeId : managerData.employeeId,
        parentGroupId : managerData.parentGroupId,
        subGroupId : managerData.subGroupId
      }
    }
    dispatch(actions.feedbackInteractionEmployeeAndManager(body,"employee"));
  }

  const handleSubmit = (managerData,index) => {
    let body = {
      compositeIds : [{
        employeeId : managerData.employeeId,
        parentGroupId : managerData.parentGroupId,
        subGroupId : managerData.subGroupId
      }]
    }
    dispatch(actions.submitFeedbackForEmployeeandManager(body,"employee",index));
  }

  const handleClear = (managerData) => {
    let body = {
      questionId : null,
      message : null,
      managerCompositeId : {
        employeeId : managerData.employeeId,
        parentGroupId : managerData.parentGroupId,
        subGroupId : managerData.subGroupId
      }
    }
    dispatch(actions.feedbackInteractionEmployeeAndManager(body, "employee", true));
    setIsCleared(true);
  }

  const getFeedbackHistoryData = (body) => {
    dispatch(actions.getFeedbackHistoryEmployee(body, loggedInUser?.parentGroupId, loggedInUser?.subGroupId, loggedInUser?.employeeId));
  }

  const getManagerList = (period) => {
    dispatch(actions.getManagerListEmployee(period));
  }

  const getHistoryDetails = (period, managerData) => {
    dispatch(
      actions.getHistoryDetailsEmployeeAndManager(
        managerData?.employeeId,
        managerData?.parentGroupId,
        managerData?.subGroupId,
        period,
        "employee"
      )
    );
  }
  
  return (
    <>
      {isLoading && <Spinner/>}
      {currentFeedbackDataEmployee?.length === 0 && currentFeedbackDataEmployeeMessage == "Record Not Found" ?
        <div className="box">
          <NoRecordFound
            image={IMG_WAITING_FOR_SUBMISSION}
            title={MonthlyCheckinTexts.MONTHLY_CHECK_IN_COMPLETED_TITLE} description={MonthlyCheckinTexts.MONTHLY_CHECK_IN_COMPLETED_NOTE}
            folderWrapper={true}
          />
          <div className="gap80"></div>
        </div> :
        currentFeedbackDataEmployee?.reportingManager?.length === 1 ?
          <SingleManagerWrapper
            reportingManagerList={currentFeedbackDataEmployee.reportingManager}
            period={currentFeedbackDataEmployee?.period}
            currentCheckIn={currentCheckIn}
            isCleared={isCleared}
            isMandatory={submitFeedbackEmployeeManager?.message?.code === 6002 ? true : false}
            handleChangeResult={handleChangeResult}
            handleSubmitMessage={handleSubmitComment}
            handleSubmit={handleSubmit}
            handleClear={handleClear}
          /> :
          currentFeedbackDataEmployee?.reportingManager?.length > 1 ?
            <MultiManagerWrapper
              reportingManagerList={currentFeedbackDataEmployee.reportingManager}
              period={currentFeedbackDataEmployee?.period}
              currentCheckIn={currentCheckIn}
              isCleared={isCleared}
              isMandatory={submitFeedbackEmployeeManager?.message?.code === 6002 ? true : false}
              questionTitleIndex={questionIndex}
              handleChangeResult={handleChangeResult}
              handleSubmitMessage={handleSubmitComment}
              handleSubmit={handleSubmit}
              handleClear={handleClear} 
            /> : <></>}
      <PastCheckIns
        getFeedbackHistoryData={getFeedbackHistoryData}
        feedbackHistoryEmployee={feedbackHistoryEmployee}
        managerList={managerList}
        getManagerList={getManagerList}
        totalCount={totalCount}
        showYearListData={showYearListData}
        getHistoryDetails={getHistoryDetails}
        historyDetails={historyDetails}
      />
    </>
  );
}