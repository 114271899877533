import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./footer.module.scss";

export default function Footer() {
  return (
    <div className="footer-outer-wrapper">
      <footer className={styles.footer_wrapper}>
        <Container>
          <Row>
            <Col className="footer-column">
              {/* <ul className="nav nav-left">
                <li className="nav-item">
                  <span className={styles.nav_link}>Privacy Polices</span>
                </li>
                <li className="nav-item">
                  <span className={styles.nav_link}>Get Help</span>
                </li>
                <li className="nav-item">
                  <span className={styles.nav_link}>Product Updates</span>
                </li>
              </ul> */}

              <ul className="nav nav-right">
                <li className="nav-item">
                  <p className={styles.nav_link}>
                    © {new Date().getFullYear()} Apexon. All rights reserved.
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}
