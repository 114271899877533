import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import style from "../components/TabCurrentCheckIn/TabCurrentCheckIn.module.scss";
import { IC_MINUS, IC_PLUS } from "../const/imgCost";
import { formatCycleData, getStatusInformation } from "../util/general";
import Patterns from "../components/Patterns/Patterns";
import { getRandomColor } from "../util/color";
import { useDispatch, useSelector } from "react-redux";
import { backGroundColor } from "../const/constValue";
import config from "util/config";

export default function TeamTableRow(props) {
  const [selectedColor, setSelectedColor] = useState(null);
  const { expanded, activePattern } = useSelector((state) => state.myTeam);
  const dispatch = useDispatch();

  let getCurrentCycleInfo,
    statusInfo = null;
  if (props.data && props.data.lastFiveSurveyCycles) {
    getCurrentCycleInfo = formatCycleData(props.data.lastFiveSurveyCycles, true);
  }

  if (getCurrentCycleInfo) {
    statusInfo = getStatusInformation(props.data.currentCycleStatus, style);
    //changes here for displaying proper status after recieved current backend data remove
    // currentCycleStatus and add getCurrentCycleInfo.status
  }

  useEffect(() => {
    setSelectedColor(getRandomColor());
  }, []);

  function toggleExpander(empId) {
    expanded === empId
      ? dispatch({ type: "EXPANDED", expanded: null })
      : dispatch({ type: "EXPANDED", expanded: empId });
    dispatch({ type: "CHANGE_PATTERN", activePattern: null });
  }

  function getMembers() {
    return props.data.reportees.map((member, index) => {
      const memberCurrentCheckin = formatCycleData(member.lastFiveSurveyCycles, true);
      let memberStatusInfo = null;

      if (memberCurrentCheckin) {
        memberStatusInfo = getStatusInformation(memberCurrentCheckin.status, style);
      }

      return (
        <tr className="expandable" key={member.employeeId}>
          <td colSpan={5}>
            <Table className={["expandable-table", style.team_member_table].join(" ")} hover responsive>
              <tbody>
                <tr>
                  <td width="330px">
                    <div className={style.tab_team_member_info}>
                      <div className={style.team_member_info}>
                        <div
                          className={style.team_member_img}
                          style={{
                            backgroundColor: backGroundColor[index % backGroundColor.length],
                          }}
                        >
                          {member.displayPicture && member.displayPicture.length > 2 ? (
                            <img src={member.displayPicture} alt="" />
                          ) : (
                            member.empInitial
                          )}
                        </div>

                        <div className={style.team_member_details}>
                          <div className={style.team_member_name}>
                            <Link
                              to={{
                                pathname:
                                  "/okrprogress/my-team/member-okrprogress/" +
                                  member.employeeId +
                                  config.employeeIdSplit +
                                  member.parentGroupId +
                                  config.employeeIdSplit +
                                  member.subGroupId,
                                state: {
                                  managerId: member.managerId,
                                },
                              }}
                            >
                              {member.empName}
                            </Link>
                          </div>

                          <div className={style.team_member_designation}>
                            {member.designation} | {member.department}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td width="120px">{memberCurrentCheckin && memberCurrentCheckin.due_date}</td>
                  <td width="135px">{memberCurrentCheckin && memberCurrentCheckin.submit_date}</td>
                  <td width="165px">
                    <div className={style.tab_checkin_patterns}>
                      {member?.lastFiveSurveyCycles
                        ?.filter((i, index) => index < 5)
                        .map((item) => {
                          let active = null;

                          if (activePattern) {
                            active =
                              activePattern.empId === member.employeeId && activePattern.cycleId === item.surveyCyclesId
                                ? style.active
                                : "";
                          }

                          const checkins = formatCycleData(item, false);

                          return (
                            <Patterns
                              key={item.surveyCyclesId}
                              item={item}
                              checkins={checkins}
                              style={style}
                              active={active}
                            />
                          );
                        })}
                    </div>
                  </td>
                  <td width="180px">
                    <div className={[style.team_checkin_status, memberStatusInfo && memberStatusInfo.style].join(" ")}>
                      {memberStatusInfo && memberStatusInfo.status}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      {props.data && (
        <tr key={props.id}>
          <td width="333px">
            <div className={style.tab_team_member_info}>
              <div className={style.team_member_info}>
                <div className={style.team_member_img} style={{ backgroundColor: selectedColor }}>
                  {props.data.displayPicture && props.data.displayPicture.length > 2 ? (
                    <img src={`${props.data.displayPicture}`} alt="" />
                  ) : (
                    props.data.empInitial
                  )}
                </div>
                {/* <div className={style.private_notification}></div> */}
                <div className={style.team_member_details}>
                  <div className={style.team_member_name}>
                    {props.data.currentCycleStatus ? (
                      <Link
                        to={
                          "/okrprogress/my-team/member-okrprogress/" +
                          props.data.employeeId +
                          config.employeeIdSplit +
                          props.data.parentGroupId +
                          config.employeeIdSplit +
                          props.data.subGroupId
                        }
                      >
                        {props.data.empName}
                      </Link>
                    ) : (
                      props.data.empName
                    )}
                  </div>
                  <div className={style.team_member_designation}>
                    {props.data.designation} | {props.data.department}
                  </div>
                </div>
                <div
                  onClick={() => toggleExpander(props.data.employeeId)}
                  className={
                    props.data.reportees && props.data.reportees.length > 0
                      ? expanded !== props.data.employeeId
                        ? style.team_member_info_collapse
                        : [style.team_member_info_collapse, style.collapseClose].join(" ")
                      : null
                  }
                >
                  {props.data.reportees && props.data.reportees.length > 0 ? (
                    expanded === props.data.employeeId ? (
                      <img src={IC_MINUS} alt="" className={style.minus} />
                    ) : (
                      <img src={IC_PLUS} alt="" className={style.plus} />
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </td>
          <td width="120px">{getCurrentCycleInfo && getCurrentCycleInfo.due_date}</td>
          <td width="135px">{getCurrentCycleInfo && getCurrentCycleInfo.submit_date}</td>
          <td width="165px">
            <div className={style.tab_checkin_patterns}>
              {props.data.lastFiveSurveyCycles &&
                props.data.lastFiveSurveyCycles
                  .filter((i, index) => index < 5)
                  .map((item) => {
                    let active = null;
                    if (activePattern) {
                      active =
                        activePattern.empId === props.data.employeeId && activePattern.cycleId === item.surveyCyclesId
                          ? style.active
                          : "";
                    }

                    const checkins = formatCycleData(item, false);

                    return (
                      <Patterns
                        key={item.surveyCyclesId}
                        item={item}
                        checkins={checkins}
                        style={style}
                        active={active}
                        container={item.current}
                      />
                    );
                  })}
            </div>
          </td>
          <td width="180px">
            <div className={[style.team_checkin_status, statusInfo && statusInfo.style].join(" ")}>
              {statusInfo && statusInfo.status}
            </div>
          </td>
        </tr>
      )}
      {expanded === props.data.employeeId &&
        props.data.reportees &&
        props.data.reportees.length > 0 &&
        getMembers(props.data.employeeId)}
    </>
  );
}
