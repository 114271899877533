import React, { useState, useEffect } from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";
import style from "./TechnicalRoleChangePopup.module.scss";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../store/actions/index";

const TechnicalRoleChangePopup = (props) => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setselectedValue] = useState(null);
  const [requestAlreadyPending, setRequestAlreadyPending] = useState(false);

  const dispatch = useDispatch();
  const { employeeRequiredSkillList, myTechnicalRoleChangeRequest } = useSelector((state) => state.skillMatrix);

  const [currentTechnicalRoleId, setCurrentTechnicalRoleId] = useState("");
  useEffect(() => {
    if (employeeRequiredSkillList && employeeRequiredSkillList.length > 0) {
      setCurrentTechnicalRoleId(employeeRequiredSkillList[0].technicalRoleId);
    }
  }, [employeeRequiredSkillList]);

  useEffect(() => {
    const roleBaseSkillList = () => dispatch(actions.employeeRequiredSkill);
    roleBaseSkillList();
    const myRoleChangeRequest = () => {
      dispatch(actions.myTechnicalRoleChangeRequestList());
    };
    myRoleChangeRequest();
  }, [dispatch]);

  useEffect(() => {
    if (myTechnicalRoleChangeRequest) {
      myTechnicalRoleChangeRequest.forEach((item) => {
        if (item.status === "PENDING") {
          setRequestAlreadyPending(true);
        }
      });
    }
  }, [myTechnicalRoleChangeRequest]);

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      if (currentTechnicalRoleId) {
        var newArray = props.data.filter((role) => {
          return role.value !== currentTechnicalRoleId;
        });
        setOptions(newArray);
      } else {
        setOptions(props.data);
      }
    }
  }, [props.data, currentTechnicalRoleId]);

  return (
    <>
      <Modal
        size="md"
        show={props.show}
        onHide={() => {
          props.hide();
          setselectedValue(null);
        }}
        animation={false}
        className={style.assigneepopup_wrapper}
      >
        <div className={style.modal_header}>
          <Row className="align-items-center">
            <Col md={12}>
              <h1>Technical Role Change Request</h1>
            </Col>
          </Row>
        </div>

        <Modal.Body>
          <div className="box-light-gradient h38"></div>
          <div className={style.modal_header_filter}>
            <Row>
              <Col md={12}>
                <span className={style.dd_title}>Select New Technical Role </span>
                <div className="card_action">
                  <Select
                    options={options}
                    className="custom-default-dd-box"
                    classNamePrefix="select"
                    placeholder="Add/Select technical role (eg. iOS)"
                    value={selectedValue}
                    onChange={(select) => setselectedValue(select)}
                  />
                </div>
              </Col>
            </Row>
            {requestAlreadyPending && (
              <div className={style.modal_alert}>
                <Row>
                  <Col md={12}>
                    <span>A request is already pending , please wait for the request to be Accepted or Rejected .</span>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className={style.assigned_modal_footer}>
          <Button
            type="button"
            variant={"primary"}
            className={["btn-round", style.btn_save].join(" ")}
            onClick={() => {
              props.hide(selectedValue);
              setselectedValue(null);
            }}
            disabled={options.length === 0 || requestAlreadyPending}
          >
            <strong>Request</strong>
          </Button>
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_cancel].join(" ")}
            onClick={() => {
              props.hide();
              setselectedValue(null);
            }}
          >
            <strong>Cancel</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TechnicalRoleChangePopup;
