import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  teamObjectiveLoading: true,
  isLoadingObjectiveHistory: true,
  draftObjective: {},
  draftSelfObjective: {},
  selfObjectiveWeightage: null,
  objectiveId: null,
  objectiveAdded: false,
  predefinedObjective: null,
  predefinedSelfObjective: null,
  objectiveValidation: [
    { key: "title", showError: false },
    { key: "keyPoints", showError: false },
  ],
  keyWeightage: null,
  keyResultErrors: [],
  selfObjectiveKeyErrors: [],
  objectiveSubmitted: false,
  teamObjectiveData: [],
  serviceLineObjectives: [],
  selfObjectiveData: {},
  team: [],
  objective: null,
  assignees: null,
  activities: [],
  universityObjectiveResponse: [],
  createObjectiveResponse: [],
  assignObjectiveResponse: null,
  deleteUserObjectiveResponse: null,
  updateObjectiveResponse: null,
  disable: false,
  size: 10,
  allObjectiveLoader: true,
  suggestionTags: null,
  uploadResponse: null,
  userObjectiveHistory: null,
  teamObjectiveDeleteLoading: false,
  resourceNotFound: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TAGS:
      return {
        ...state,
        suggestionTags: action.tagsData,
      };
    case actionTypes.SET_DRAFT_OBJECTIVE_DATA:
      return {
        ...state,
        draftObjective: action.draftObjectiveData,
        keyWeightage: action.keyWeightage,
        isLoading: false,
      };
    case actionTypes.SET_DRAFT_SELF_OBJECTIVE_DATA:
      return {
        ...state,
        draftSelfObjective: action.data,
        selfObjectiveWeightage: action.weightage,
        isLoading: false,
      };
    case actionTypes.OBJECTIVE_ADDED:
      return {
        ...state,
        objectiveAdded: action.objectiveAdded,
        isLoading: false,
      };
    case actionTypes.ADD_OBJECTIVE:
      return {
        ...state,
        objectiveId: action.AddObjectiveResponse.objectiveId,
        isLoading: false,
      };
    case actionTypes.GET_PREDEFINED_OBJECTIVE:
      return {
        ...state,
        predefinedObjective: action.data,
        isLoading: false,
      };
      case actionTypes.GET_SELF_PREDEFINED_OBJECTIVE:
      return {
        ...state,
        predefinedSelfObjective: action.data,
        isLoading: false,
      };
      case actionTypes.GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR:
        return {
          ...state,
          managerEmployeePair: action.managerEmployeePair,
          isLoading: false,
        };
    case actionTypes.DELETE_KEY_POINT: {
      let deleteKeyPoint = [...state.draftObjective.keyPoints];
      deleteKeyPoint.splice(action.index, 1);
      return {
        ...state,
        draftObjective: { ...state.draftObjective, keyPoints: deleteKeyPoint },
        isLoading: false,
      };
    }
    case actionTypes.OBJECTIVE_VALIDATION:
      return {
        ...state,
        objectiveValidation: action.validationErrors,
      };
    case actionTypes.KEY_RESULT_ERRORS:
      return {
        ...state,
        keyResultErrors: action.keyResultErrors,
      };
    case actionTypes.SELF_OBJECTIVE_KEY_ERRORS:
      return {
        ...state,
        selfObjectiveKeyErrors: action.errors,
      };
    case actionTypes.SUBMIT_OBJECTIVE:
      return {
        ...state,
        objectiveSubmitted: action.objectiveSubmitted,
      };
    case actionTypes.CLEAR_CREATE_OBJECTIVE:
      return {
        ...state,
        draftObjective: {},
        objectiveId: null,
        objectiveAdded: false,
        objectiveValidation: [],
        objectiveSubmitted: false,
      };
    case actionTypes.SET_TEAM_OBJECTIVES:
      return { ...state, ...action.team };
    case actionTypes.SET_MANAGER_TEAM:
      return { ...state, ...action.team };
    case actionTypes.SET_OBJECTIVE:
      return { ...state, ...action.data };
    case actionTypes.SET_ASSIGNEES:
      return { ...state, assignees: action.assignees };
    case actionTypes.VIEW_ALL_OBJECTIVE:
      return {
        ...state,
        ...action.data,
      };
    case actionTypes.EXPANDED:
      return {
        ...state,
        expanded: action.expanded,
      };
    case actionTypes.PAGESIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case actionTypes.SET_OBJECTIVE_ACTIVITY:
      return {
        ...state,
        activities: action.activities,
      };
    case actionTypes.REMOVE_ASSIGNEES:
      return {
        ...state,
        assignees: action.assignees,
      };
    case actionTypes.ASSIGN_FAIL:
      return {
        ...state,
        assignObjectiveResponse: { success: false, message: action.message },
      };
      case actionTypes.GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR_FAIL:
      return {
        ...state,
        managerEmployeePairFailResponse: { success: false, message: action.message },
      };
    case actionTypes.ASSIGN_SUCCESS:
      return {
        ...state,
        assignObjectiveResponse: { success: true, message: action.message },
        draftSelfObjective: {},
        selfObjectiveWeightage: null,
        isLoading: true,
        selfObjectiveData: {},
      };
    case actionTypes.DELETE_USER_OBJECTIVE_SUCCESS:
      return {
        ...state,
        deleteUserObjectiveResponse: { success: true, message: action.message },
      };
    case actionTypes.UPDATE_OBJECTIVE_RESPONSE:
      return {
        ...state,
        updateObjectiveResponse: {
          success: action.success,
          message: action.message,
          redirectTo: action.redirectTo,
        },
      };
    // case actionTypes.UPDATE_DELETED_OBJECTIVE_RESPONSE:
    //   return {
    //     ...state,
    //     updateObjectiveResponse: { success: true, message: action.message, redirectTo: action.redirectTo }
    //   };

    case actionTypes.CLEAR_FILE_OBJECTIVE_RESPONSE:
      return {
        ...state,
        assignObjectiveResponse: null,
        deleteUserObjectiveResponse: null,
        updateObjectiveResponse: null,
      };
    case actionTypes.GET_USER_HISTORY:
      return {
        ...state,
        ...action.data,
      };
    case actionTypes.DISABLE:
      return {
        ...state,
        disable: true,
      };
    case actionTypes.UN_DISABLE:
      return {
        ...state,
        disable: false,
      };
    case actionTypes.SIZE:
      return {
        ...state,
        size: action.size,
      };
    case "CLEAR_OBJECTIVES_STATE":
      return initialState;
    case "REMOVE_IS_LOADING_OBJECTIVE":
      return {
        ...state,
        userObjectiveHistory: null,
        isLoadingObjectiveHistory: true,
      };
    case "SET_SELF_OBJECTIVE_DATA":
      return {
        ...state,
        selfObjectiveData: {
          ...state.selfObjectiveData,
          ...action.payload,
        },
      };
    case "TEAM_OBJECTIVE_LOADING":
      return {
        ...state,
        teamObjectiveDeleteLoading: true,
      };
    case actionTypes.UPLOAD_FAIL:
      return {
        ...state,
        uploadResponse: { success: false, message: action.message },
      };
    case actionTypes.UPLOAD_SUCCESS:
      return {
        ...state,
        uploadResponse: { success: true, message: action.message },
      };
    default:
      return state;
  }
};

export default reducer;
