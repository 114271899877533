import React from "react";
import style from "./CreateObjective.module.scss";
import moment from "moment";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import routePath from "const/routePath";

export default function PreviouslyCreateObjective({ previouslyCreateObjective, isSelf }) {
  return (
    <div>
      <div className={["box", "p_b_n", style.objective_right_box].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Objectives</h2>
            <h3 className="box-heading-light fs-15">
              Objectives are short-term and medium-term goals that an organization seeks to accomplish. If measured
              regularly results into a great growing Apexon Associates.
            </h3>
          </div>
        </div>

        <div className={["box-inner-pad", "box-light-gradient", style.made_my_day_box].join(" ")}>
          {/* <h5 className="semi-bold">Previously Created Objectives</h5> */} 
          {/* change title as it confusing in employee view because it showing latest two okr only. either it is from manager assigned or self assigned */}
          <h5 className="semi-bold">Latest Two Objectives</h5>
          {previouslyCreateObjective.length === 0 ? (
            <div className={"box-heading-light fs-15"}>No data found.</div>
          ) : (
            previouslyCreateObjective
              .filter((value, index) => index < 2)
              .map((data, index) => (
                <div className={style.pre_created_objectives} key={"PREVIOUSLY" + index}>
                  <h1>{data.title}</h1>
                  <h2>{moment(data.createdDate).format("ddd D MMM h:mm a")}</h2>
                </div>
              ))
          )}
          {previouslyCreateObjective.length !== 0 && (
            <div className="mt-3 text-right fs-500">
              <Link
                to={
                  isSelf? routePath.MY_OBJECTIVES :{
                  pathname: routePath.ALL_OBJECTIVES,
                  query: {
                     tab: `MyCreatedObjective`,
                    },
                }
              }
              >
                View All
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

PreviouslyCreateObjective.propTypes = {
  previouslyCreateObjective: PropTypes.array,
};

PreviouslyCreateObjective.defaultProps = {
  previouslyCreateObjective: [],
};
