import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Col, Container, Row, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/index";
import style from "./CheckinExtensionDate.module.scss";
import { buttonText, noData } from "const/constValue";
import config from "util/config";
import CustomDateRangePicker from "shared/DateRangePicker/DateRangePickerExtension";
import ReactDatePicker from "react-datepicker";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "components/NodataFound/NoDataFound";

export default function CheckinExtensionDate() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [extensionDate, setExtensionDate] = useState(null);
  const [errors, setErrors] = useState();
  const { checkinExtendedHistory, isHistoryLoading } = useSelector(
    (state) => state.checkIn
  );

  const getDateRange = (startDate, endDate) => {
    let selectedStartDate = moment(startDate, "YYYY-MM-DD").add(-1, "days");
    let formatedStartDate =
      moment(selectedStartDate).format("YYYY-MM-DD") + "T18:30:00.000Z";
    let selectedEndDate = moment(endDate, "YYYY-MM-DD").add(1, "days");
    let formatedEndDate =
      moment(selectedEndDate).format("YYYY-MM-DD") + "T18:30:00.000Z";
    setStartDate(formatedStartDate);
    setEndDate(formatedEndDate);
  };

  const handleExtend = () => {
    extensionDate ? setErrors(false) : setErrors(true);
    const params = {
      startDate: startDate,
      endDate: endDate,
      extendedDate:
        moment(extensionDate).format("YYYY-MM-DD") + "T23:59:00.000Z",
    };
    dispatch(actions.checkinExtendByAdmin(params));
  };
  useEffect(() => {
    dispatch(actions.checkinExtendAdminHistory());
  }, []);

  return (
    <>
      {isHistoryLoading && <Spinner />}
      <SubNavigation type="OKR Progress" />
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0 mt-3">Admin</h1>
            <span className="st-breadcrumb">
            OKR Progress
              <span>Extend OKR Progress</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className={[style.checkin_extension_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Extend OKR Progress</h2>
                  <h3>Select appropriate value to Extend OKR Progress</h3>
                </div>
              </div>
              <div className="box-light-gradient"></div>
              <div className="box-inner-pad">
                <div
                  className={[
                    style.form_group_wrapper,
                    "radio-wrapper dropdown-multiselect",
                  ].join(" ")}
                >
                  <Form.Group>
                    <div className={["tags_wrapper"].join(" ")}>
                      <Form.Label className={style.dd_title}>
                        Select Date Range<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="calendar_wrapper form-group date_range_calendar_wrapper">
                        <CustomDateRangePicker getDateRange={getDateRange} />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className={["tags_wrapper"].join(" ")}>
                      <Form.Label className={style.dd_title}>
                        Extended Date<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="date_picker_time_wrapper p-0">
                        <div className="calendar_wrapper form-group">
                          <ReactDatePicker
                            className={[
                              errors
                                ? "limit-reached form-control"
                                : "form-control",
                            ].join(" ")}
                            dateFormat="MMMM d, yyyy"
                            minDate={
                              moment(endDate, "YYYY-MM-DD") >
                              moment(new Date(), "YYYY-MM-DD")
                                ? moment(endDate, "YYYY-MM-DD")?._d
                                : moment(new Date(), "YYYY-MM-DD")?._d
                            }
                            selected={extensionDate ? extensionDate : null}
                            onChange={(date) => setExtensionDate(date)}
                            placeholderText="Select Date"
                          />
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className={style.save_box_left}>
                  <Button
                    type="button"
                    variant={"primary"}
                    onClick={handleExtend}
                  >
                    <strong>{"Reopen"}</strong>
                  </Button>
                  <Button
                    type="button"
                    variant={"secondary"}
                    className="ml-3"
                    onClick={() => setExtensionDate(null)}
                  >
                    <strong>{buttonText.CLEAR}</strong>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div
              className={[style.checkin_extension_wrapper_table, "box"].join(
                " "
              )}
            >
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Extended OKR Progress History</h2>
                  <h3>View extended OKR Progress history</h3>
                </div>
              </div>

              {checkinExtendedHistory?.length > 0 ? (
                <div className="box-light-gradient">
                  <Table
                    hover
                    responsive
                    className={style.tab_table_responsive}
                  >
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Extended Date</th>
                        <th>Extended By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkinExtendedHistory &&
                        checkinExtendedHistory.map((list) => (
                          <tr key={list.checkInExtendHistoryId}>
                            <td>
                              <span>
                                {moment(list.startDate)
                                  .add(1, "days")
                                  .utc()
                                  .format("MMM DD YYYY")}
                              </span>
                            </td>
                            <td>
                              <span>
                                {moment(list.endDate)
                                  .add(-1, "days")
                                  .utc()
                                  .format("MMM DD YYYY")}
                              </span>
                            </td>
                            <td>
                              <span>
                                {moment(list.extendedDate)
                                  .utc()
                                  .format("MMM DD YYYY")}
                              </span>
                            </td>
                            <td>
                              <span>{list.extendedBy.displayName}</span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
