import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { RatingAnimation } from "util/utils";
import * as actions from "store/actions/index";
import RatingBubble from "shared/RatingBubble";
import "./feelingbox.scss";

const ratings = {
  one: {
    count: 1,
    text: "Really Terrible!",
    question1: "Happens to all of us. Anything you need?",
  },
  two: {
    count: 2,
    text: "Somewhat Bad!",
    question1: "Got it. Anything that would have you feel better? ",
  },
  three: {
    count: 3,
    text: "Completely Okay!",
    question1: "Care to elaborate?",
  },
  four: {
    count: 4,
    text: "Pretty Good!",
    question1: "Anything that would kick it up a notch?",
  },
  five: {
    count: 5,
    text: "Awesome!",
    question1: "That IS amazing! What’s your secret?",
  },
};

export default function FeelingBox(props) {
  const dispatch = useDispatch();
  const [currentMood, setCurrentMood] = useState(null);
  const [currentError, setCurrentError] = useState(null);
  const { moodoMeter, surveyCyclesId, validationErrors } = useSelector((state) => state.checkIn);
  const [prevMood, setPrevMood] = useState(null);

  useEffect(() => RatingAnimation(), []);

  useEffect(() => {
    if (moodoMeter && moodoMeter.length > 0) setCurrentMood(moodoMeter && moodoMeter.length && moodoMeter[0].answer);
  }, [moodoMeter]);

  useEffect(() => {
    if (moodoMeter && moodoMeter.length > 0)
      setCurrentError(validationErrors.filter((err) => err.qId === moodoMeter[0].queId)[0]);
  }, [validationErrors, moodoMeter]);

  const onReviewComment = (e) => {
    const questionsAnswer = {
      queId: moodoMeter && moodoMeter[0]?.queId,
      employeeComment: e.target.value.trim(),
      employeeId: {
        employeeId: props.user?.employeeId,
        parentGroupId: props.user?.parentGroupId,
        subGroupId: props.user?.subGroupId,
      },
    };
    const params = { surveyCycleId: surveyCyclesId };
    dispatch(actions.HandleMoodChange(params, questionsAnswer, moodoMeter));
  };

  const handlerMoodClick = (selectedRating) => {
    if (moodoMeter && moodoMeter.length > 0) {
      let errors = validationErrors.filter((item) => item.qId !== moodoMeter[0].queId);
      dispatch(actions.SetValidationErrors(errors));
      const params = { surveyCycleId: surveyCyclesId };
      let questionsAnswer;
      if ((!prevMood || +prevMood === +selectedRating) && currentMood) {
        setPrevMood(selectedRating);
        questionsAnswer = {
          queId: moodoMeter[0].queId,
          answer: selectedRating,
        };
      } else {
        setPrevMood(selectedRating);
        questionsAnswer = {
          queId: moodoMeter[0].queId,
          answer: selectedRating,
          employeeComment: "",
          employeeId: {
            employeeId: props.user?.employeeId,
            parentGroupId: props.user?.parentGroupId,
            subGroupId: props.user?.subGroupId,
          },
        };
        Object.keys(ratings).map((key) => (document.getElementById("rate_comment_" + key).value = ""));
        dispatch(actions.HandleMoodChange(params, questionsAnswer, moodoMeter));
      }
    }
  };

  return (
    <div className="feeling-box-wrapper current-feeling-box">
      {currentError && currentError.showError ? (
        <div className="feeling-error-box">
          <span>Action Required!</span>
        </div>
      ) : null}
      <div className="box feeling-box">
        <h2 className="main_title">Hey {props.user && props.user.firstName}! How are you feeling?</h2>
        <div>
          <Form className="ratingform">
            <ul className="rating">
              {Object.keys(ratings).map((key) => {
                let activeClass = "";
                if (currentMood)
                  ratings[key].count === +currentMood
                    ? (activeClass = "active opened staticActive")
                    : (activeClass = "opacity");
                return (
                  <RatingBubble
                    key={key}
                    mood={moodoMeter && moodoMeter[0]}
                    rating={ratings[key]}
                    title={key}
                    currentClass={activeClass}
                    onCountClick={handlerMoodClick}
                    onReviewComment={onReviewComment}
                  />
                );
              })}
            </ul>
          </Form>
        </div>
      </div>
    </div>
  );
}
