import routePath from "const/routePath";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { backGroundColor } from "../../const/constValue";
import { IC_TREE_VIEW2 } from "../../const/imgCost";
import HideAndShow from "../HideAndShow/HideAndShow";
import RoundEmployeeList from "../RoundEmployeeList/RoundEmployeeList";
import style from "./PendingApprovals.module.scss";

function TeamUnassignedObjective({ employees, displayAll, handleEmployeeClick }) {
  const [hide, setHide] = useState(false);
  return (
    <div className={["card", style.to_do_wrapper].join(" ")}>
      <div className="card_info">
        <div className="card_title">
          <div className="d-flex">
            <ReactSVG src={IC_TREE_VIEW2} className={style.todo_svg} />
            <div className="card_title_text">Team’s Unassigned Objective</div>
          </div>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>
      {!hide && (
        <div>
          <div className="box-light-gradient"></div>
          <div className="card_details">
            <div className={style.to_do_card_wrapper}>
              <div className={style.to_do_item_wrapper}>
                <div className={style.to_do_subheading}>Please assign the Objectives to following team members:</div>

                <div className={style.avatar_list}>
                  <div className={style.avatar_container}>
                    {employees
                      .filter((value, index) => index < 13)
                      .map((employee, index) => (
                        <RoundEmployeeList
                          handleEmployeeClick={() => handleEmployeeClick(employee, "UNASSIGNED")}
                          empName={employee.empName}
                          image={employee.displayPicture || employee.empInitial}
                          selectedColor={backGroundColor[index % backGroundColor.length]}
                          key={"ASSIGNEE_" + employee.employeeId}
                        />
                      ))}
                    {employees.length > 13 && (
                      <div className={style.avatar} onClick={displayAll}>
                        <div className={style.avtar_item}>+{employees.length - 13}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Link to={routePath.ALL_OBJECTIVES}>Go to All objectives</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamUnassignedObjective;
