import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import style from "../CycleMemberProfile.module.scss";
import OptionalComment from "../QuestionAnswer/OptionalComment";
import { ReactSVG } from "react-svg";
import { IC_CORRECT_ANSWER, IC_WRONG_ANSWER } from "const/imgCost";

function CheckBoxQuestion(props) {
  const {
    errors,
    item,
    isSubmitted,
    checkboxHandlerTemplate,
    checkBoxOtherHandlerTemplate,
    checkBoxCommentHandlerTemplate,
    uploadFile,
    downloadFile,
    deleteFile,
    name,
    isSurvey,
  } = props;

  let error = errors?.find((v) => v.qId === item.questionId && v.error && v.isSubmit);
  let commentError = errors?.find((v) => v.qId === item.questionId && v.commentBoxError && v.isSubmit);

  const [open, setOpen] = useState(item.otherTextAnswer ? true : false);
  const checkboxHandlerOtherTemplate = () => setOpen(!open);
  return (
    <div className="radio-wrapper ">
      <Form.Group className={[style.cb_pv_wrapper, error && "limit-reached"].join(" ")} controlId="">
        {item["options"] &&
          item["options"].length > 0 &&
          item["options"].map((optionsData) => (
            <div className="custom-checkbox custom-checkbox-sm mb-1 custom-checkbox-theme-2" key={optionsData.optionId}>
              <input
                id={optionsData["optionId"] + "12" + item.questionId + name}
                disabled={isSubmitted}
                checked={optionsData.isSelected}
                type="checkbox"
                name="question_23"
                onChange={(e) => checkboxHandlerTemplate(e, item, optionsData)}
              />
              <label htmlFor={optionsData["optionId"] + "12" + item.questionId + name}>{optionsData["option"]}</label>
              {item?.correctOptionList?.length > 0 && (
                <ReactSVG
                  src={
                    optionsData.isSelected === true
                      ? optionsData.isCorrect === true
                        ? IC_CORRECT_ANSWER
                        : IC_WRONG_ANSWER
                      : ""
                  }
                  alt=""
                  className={style.correct_wrong_answer}
                />
              )}
            </div>
          ))}
        {item?.correctOptionList?.length > 0 && (
          <ul className="common_list_items">
            <li className="mb-0 mt-2">{`Option ${item?.correctOptionList?.join(", ")}`}</li>
          </ul>
        )}
        {item.isOther && (
          <div className="custom-checkbox custom-checkbox-sm mb-1 custom-checkbox-theme-2">
            <input
              id={"OTHER_CHECKBOX" + item.questionId + name}
              type="checkbox"
              name="question_23"
              disabled={isSubmitted}
              checked={item.otherTextAnswer ? true : open}
              onChange={(e) => checkboxHandlerOtherTemplate(e, item)}
            />
            <label htmlFor={"OTHER_CHECKBOX" + item.questionId + name} className="word-break">
              Others ({item.otherText ? item.otherText : "please specify"})
            </label>
            {open &&
              (isSubmitted ? (
                <span className="w-100 d-block mt-2 word-break">{item.otherTextAnswer}</span>
              ) : (
                <div className="textarea-wrapper mt-2">
                  <Form.Control
                    as="textarea"
                    placeholder="Add a new answer"
                    maxLength="500"
                    defaultValue={item.otherTextAnswer}
                    onBlur={(e) => checkBoxOtherHandlerTemplate(e, item)}
                  />
                </div>
              ))}
          </div>
        )}
        {item.optionalCommentBox && (
          <OptionalComment
            item={item}
            uploadFile={(e) => uploadFile(e, item.questionId)}
            commentError={commentError ? true : false}
            handleComment={(e) => checkBoxCommentHandlerTemplate(e, item)}
            isSubmitted={isSubmitted}
            downloadFile={downloadFile}
            commentPlaceHolder={
              item.commentResponse === "Required" ? "Add a new answer (Required)" : "Add a new answer (Optional)"
            }
            deleteFile={deleteFile}
            isSurvey={isSurvey}
          />
        )}
      </Form.Group>
    </div>
  );
}

CheckBoxQuestion.propTypes = {
  item: PropTypes.object,
  isSubmitted: PropTypes.bool,
  checkboxHandlerTemplate: PropTypes.func,
  checkBoxOtherHandlerTemplate: PropTypes.func,
  checkBoxCommentHandlerTemplate: PropTypes.func,
  uploadFile: PropTypes.func,
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
};

export default CheckBoxQuestion;
