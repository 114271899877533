import React from "react";
import style from "./PreviewTemplate.module.scss";
import { Form } from "react-bootstrap";
import UploadFile from "../../Performance/CycleMemberProfile/QuestionAnswer/UploadFile";

export default function RankType(props) {
  const { isManagerView, question } = props;
  let options = question.options;
  let responseText = "There would be employee’s comment over here";
  return (
    <div className={[style.question_list, "box-inner-pad", style.disabledClick].join(" ")}>
      <span className={style.question_sr_no}>Q{props.no + 1}</span>
      <div className={style.que_ans_wrapper}>
        <div className={style.question_name}>
          <h4>
            {question.questionName}
            {question.isRequired && <span className="text-danger">*</span>}
          </h4>
        </div>
        {question.descriptionFlag && (
          <p className={[style.answer_description, "mt-1"].join(" ")}>{question.description}</p>
        )}
        <div className={style.ans_wrapper}>
          <div className="ratingBarBox">
            <Form.Group controlId="">
              <div className="radio-wrapper">
                <Form.Group controlId="" className={style.cb_pv_wrapper}>
                  {options.map((value) => (
                    <div key={value.optionId} className={style.rank_value_wrapper}>
                      <input
                        id={value.optionId}
                        type="number"
                        min="0"
                        max="100"
                        maxLength={2}
                        defaultValue=""
                        onChange={(e) => console.warn(e.target.value, value.optionId)}
                      />
                      <span className={style.rank_value}>{value.option}</span>
                    </div>
                  ))}
                </Form.Group>
              </div>
              {isManagerView ? (
                <p className={[style.answer_description, "mt-3"].join(" ")}>{responseText}</p>
              ) : question.optionalCommentBox ? (
                <div className="textarea-wrapper mt-3">
                  <UploadFile questionId="QUESTION_ID" uploadFile={() => {}} />
                  <Form.Control
                    as="textarea"
                    maxLength="5000"
                    placeholder={`Add a new answer (${question.commentResponse})`}
                  />
                </div>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
