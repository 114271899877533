import React from "react";
import { Tooltip, Overlay } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_ERROR } from "../../../const/imgCost";

const OverlayError = (props) => {
    const showOverlay = props.objectiveValidation && props.objectiveValidation.filter((item) => item.key === props.errorFor && item.showError).length > 0;

    return (
        <Overlay 
            target={props.target} 
            show={showOverlay}
            placement={props.placement ? props.placement : "bottom"}>
            {(propsTooltip) => (
                <Tooltip {...propsTooltip} show={propsTooltip.show.toString() || "true"}>
                    <span className="error-icon">
                        <ReactSVG src={IC_ERROR} title="" alt="" />
                    </span>
                    <span className="error-bubble-txt">Please fill proper details</span>
                </Tooltip>
            )}
        </Overlay>
    )
    
}

export default OverlayError;