import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./UniversityRequest.module.scss";
import * as actions from "store/actions/index";
import { IC_INFO } from "const/imgCost";
import { dateFormat1 } from "util/dateUtils";
import { filterArray } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../../../Pagination/Pagination";
import NoDataFound from "../../../../NodataFound/NoDataFound";
import { competencyLevel, noData } from "const/constValue";

export default function UniversityRequest() {
  const dispatch = useDispatch();
  const [totalReq, setTotalReq] = useState([]);
  const { isLoading, size, getUniversityApproveDeclineReq } = useSelector((state) => state.skillMatrix);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [typeArr, setFilterType] = useState("ALL");
  const [filteredData, setFilteredData] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  useEffect(() => {
    const getReq = () => dispatch(actions.getUniversityDeclineApproveRequest);
    getReq();
  }, [dispatch]);

  useEffect(() => {
    if (getUniversityApproveDeclineReq && getUniversityApproveDeclineReq.length > 0) {
      setTotalReq(getUniversityApproveDeclineReq);
      setFilterType("ALL");
    } else {
      setTotalReq([]);
      setFilterType("ALL");
    }
  }, [getUniversityApproveDeclineReq]);

  useEffect(() => {
    filteredData.length > 0
      ? setTotalReq(filteredData)
      : undefined
      ? setTotalReq(filteredData)
      : setTotalReq(getUniversityApproveDeclineReq);
  }, [filteredData, getUniversityApproveDeclineReq]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (totalReq && totalReq.length > 0) {
      filteredData = filterArray(totalReq, filters);
      setFilteredData(filteredData);
    }
  };

  const filterByRequest = (e) => {
    const value = e.target.value;
    let array = [];
    if (e.target.checked) {
      setFilterType(value);
      if (value === "DECLINE") {
        array = getUniversityApproveDeclineReq.filter((req) => req.status.toLowerCase() === "decline");
      } else if (value === "APPROVE") {
        array = getUniversityApproveDeclineReq.filter((req) => req.status.toLowerCase() === "endorsed");
      } else {
        array = getUniversityApproveDeclineReq;
      }
    }
    setTotalReq(array);
  };

  useEffect(() => {
    if (totalReq && totalReq.length === 0) {
      setPageOfItems([]);
    }
  }, [totalReq]);

  return (
    <>
      {isLoading && <Spinner />}
      <div className={[style.uni_req_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>University Requests</h2>
            <h3>University Requests for Endorsement</h3>
          </div>
          <div className="tab_check_in_status">
            <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="all-1"
                type="radio"
                name="status-check"
                value="ALL"
                checked={typeArr === "ALL"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="all-1">All</label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="g23"
                type="radio"
                name="status-check"
                value="DECLINE"
                checked={typeArr === "DECLINE"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="g23">Declined</label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 custom-checkbox-light">
              <input
                id="g12"
                type="radio"
                name="status-check"
                value="APPROVE"
                checked={typeArr === "APPROVE"}
                onChange={(e) => filterByRequest(e)}
              />
              <label htmlFor="g12">Approved</label>
            </div>
          </div>
        </div>
        <div className="box-light-gradient h38"></div>
        {pageOfItems && pageOfItems.length > 0 ? (
          <div className="box-inner-pad pb-0">
            <div className={[style.skill_table, "custom-table", "table-theme-1"].join(" ")}>
              <div className={[style.endorsement_skill_table, "position-relative"].join(" ")}>
                <ul className={style.thead}>
                  <li>
                    <span className={style.endorser_name}>Endorse To</span>
                    <span className={style.skill_name}>Skill Name</span>
                    <span className={[style.cmp_lvl, style.current].join(" ")}>
                      Current <br /> Competency Level
                    </span>
                    <span className={[style.cmp_lvl, style.current].join(" ")}>Endorsed Competency Level</span>
                    <span className={style.req_date}>Request Date</span>
                    <span className={style.dec_date}>Response Date</span>
                    <span className={style.status}>Status</span>
                    <span className={style.comments}>Comment</span>
                  </li>
                </ul>
                <ul className={style.tbody}>
                  {pageOfItems &&
                    pageOfItems.length > 0 &&
                    pageOfItems.map((data) => {
                      return (
                        <li key={data.id}>
                          <div className={style.endorser_name}>
                            <span>{data.endorsedToName}</span>
                            {data.endorseRequestByDepartmentName && (
                              <div className={[style.mmg_tooltip, "custom-tooltip", "mmg_table_tooltip"].join(" ")}>
                                <div className="box-info">
                                  <span>
                                    <img className="svg" src={IC_INFO} alt="box-info" />
                                  </span>
                                </div>
                                <div className="info-attach-file">
                                  <p>
                                    Requested by <b>WMG ({data.endorseRequestByName})</b>
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={style.skill_name}>
                            <span>{data.endorsedSkill.skillIdName}</span>
                          </div>
                          <div className={[style.cmp_lvl, style.current].join(" ")}>
                            <span
                              className={[
                                style.req_comp_level,
                                competencyLevel[data.endorsedSkill?.currentCompetencyLevel],
                              ].join(" ")}
                            >
                              {competencyLevel[data.endorsedSkill?.currentCompetencyLevel] || "-"}
                            </span>
                          </div>
                          <div className={[style.cmp_lvl, style.current].join(" ")}>
                            <span
                              className={[style.req_comp_level, competencyLevel[data.endorsedCompetencyLevel]].join(
                                " "
                              )}
                            >
                              {competencyLevel[data.endorsedSkill?.currentCompetencyLevel] || "-"}
                            </span>
                          </div>
                          <div className={style.req_date}>
                            <span>{dateFormat1(data?.endorsedReqDate)}</span>
                          </div>
                          <div className={style.dec_date}>
                            <span>
                              {data.declineDate
                                ? dateFormat1(data.declineDate)
                                : data.endorsedDate
                                ? dateFormat1(data.endorsedDate)
                                : "-"}
                            </span>
                          </div>
                          <div className={style.status}>
                            <div className={[style.cmp_lvl, style.current].join(" ")}>
                              {data.status.toLowerCase() === "decline" && (
                                <span className={[style.req_comp_level, "orange"].join(" ")}>Declined</span>
                              )}
                              {data.status.toLowerCase() === "endorsed" && (
                                <span className={[style.req_comp_level, "green"].join(" ")}>Approved</span>
                              )}
                            </div>
                          </div>
                          <div className={style.comments}>
                            <div className={style.comment_txt}>
                              {data.comment ? data.comment.slice(0, 15) : "-"}
                              <div className="d-inline-block position-relative">
                                {data.comment && data.comment.trim().length > 15 && (
                                  <span className={style.more}>...More</span>
                                )}
                                <span className={style.description_tooltip}>{data.comment}</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            <div className="gap120"></div>
          </div>
        )}
      </div>
      {totalReq && totalReq.length !== 0 && (
        <Pagination pageSize={size} items={totalReq} onChangePage={onChangePage} resetData={resetData} />
      )}
    </>
  );
}
