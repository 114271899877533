import React, { useState } from "react";
import style from "./ResumeAcknowledgeBox.module.scss";
import { IC_NOTIFY } from "../../../../const/imgCost";
import { Form, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import * as actions from "../../../../store/actions/index";
import { useDispatch } from "react-redux";
import moment from "moment";

export default function ResumeAcknowledgeBox(props) {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(actions.resumeAcknowledgementConfirmation());
  };

  return (
    <div className={["box", style.reume_acknowledge_wrapper].join(" ")}>
      <div className="box-inner-pad">
        <span>
          <ReactSVG src={IC_NOTIFY} />
        </span>
        <p>
          Hi {props.user.displayName}, We have observed that your skill matrix is not updated in this quarter. Please
          note that it is very important for WMG to have your updated Skills readily available all the time in order to
          consider you for any upcoming opportunities.
        </p>
        <Form>
          <div className="custom-checkbox custom-checkbox-theme-1 custom-checkbox-sm no_mar">
            <input id={"acknowledge_checkbox"} type="checkbox" value={checked} onChange={() => setChecked(!checked)} />
            <label htmlFor={"acknowledge_checkbox"}>
              Please Acknowledge by {moment(props.date).format("dddd, MMMM DD YYYY")} EOD, if there are no skills to
              update.
            </label>
          </div>
          <Button
            type="button"
            variant={"primary"}
            className="btn-round btn_save"
            onClick={handleSubmit}
            disabled={!checked}
          >
            <strong>Submit</strong>
          </Button>
        </Form>
      </div>
    </div>
  );
}
