import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Table } from "react-bootstrap";
import style from "./CycleTeam.module.scss";
import * as actions from "store/actions/index";
import { getColorByIndex } from "util/color";
import { noData } from "const/constValue";
import Spinner from "shared/Spinner/Spinner";
import FilterTeam from "../../FilterTeam/FilterTeam";
import NoDataFound from "../../NodataFound/NoDataFound";
import EmployeeRow from "./EmployeeRow";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import Pagination from "shared/Pagination/Pagination";
import storage from "util/storage";

export default function ReportingCycleTeam(props) {
  const cycleID = props.match.params.id;
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const history = useHistory();
  const {
    employeeListForReporting,
    cycleName,
    period,
    isReportingTeamMemberLoading,
    pendingPercentage,
    submittedPercentage,
    gradeOrRatingOrBoth,
    totalCount,
    isBenchFilter,
  } = useSelector((state) => state.performance);

  const pageSize = useSelector((state) => state.myTeam.pageSize);
  const [projectStatusFIlter, setProjectStatusFilter] = useState();
  const [projectStatusFIlterValue, setProjectStatusFilterValue] = useState();

  let ratingText = "Grade";
  if (gradeOrRatingOrBoth === "BOTH") {
    ratingText = "Grade & Rating";
  } else if (gradeOrRatingOrBoth === "RATING") {
    ratingText = "Rating";
  }

  // let showRatingDropDown = useLocation();
  useEffect(() => {
    const previousState = history?.location?.state?.data;
    setSearchFilter(previousState?.name || "");
    setStatusFilter(previousState?.status || "");
    dispatch(actions.ChangePageSize(previousState?.offset || 10));
    dispatch(actions.ChangeCurrentPage(previousState?.pageNumber || 1));
    getReporteeAssessmentListAndCount(previousState || {});
    dispatch({ type: "REMOVE_PRIVATE_ASSESSMENT_DATA" });
  }, [cycleID]);

  const getReporteeAssessmentListAndCount = (data) => {
    storage.set("assesmentCurrentData", JSON.stringify({ ...data }));
    dispatch(
      actions.GetAllReporteeAssessmentList(cycleID, "reporting", { ...data })
    );
  };

  const onChangePage = (currentPage, pageSize) => {
    getReporteeAssessmentListAndCount({
      pageNumber: currentPage.toString(),
      offset: pageSize.toString(),
      name: searchFilter,
      status: statusFilter,
      ...projectStatusFIlterValue,
    });
  };

  const search = (e) => {
    setSearchFilter(e.target.value);
  };

  const searchMember = (event) => {
    dispatch(actions.ChangeCurrentPage(1));
    getReporteeAssessmentListAndCount({
      name: event.target.value,
      pageNumber: 1,
      status: statusFilter,
      ...projectStatusFIlterValue,
    });
  };

  const handleStatusChange = (value) => {
    dispatch(actions.ChangeCurrentPage(1));
    setStatusFilter(value);
    getReporteeAssessmentListAndCount({
      name: searchFilter,
      pageNumber: 1,
      status: value,
      ...projectStatusFIlterValue,
    });
  };

  const resetSearch = () => {
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter("");
    dispatch(actions.ChangeCurrentPage(1));
    getReporteeAssessmentListAndCount({
      name: "",
      pageNumber: 1,
      status: statusFilter,
      ...projectStatusFIlterValue,
    });
  };

  const filter = {
    value: ["", "Pending", "Submit", "Self_Assesment_Submit"],
    name: ["All", "Pending", "Submitted", "Submitted by reportees"],
  };

  const handleProjectStatus = (data) => {
    let projectStatus = {};
    if (data.value === "false") {
      projectStatus = { allocation: "project" };
    } else if (data.value === "true") {
      projectStatus = { allocation: "Bench" };
    }
    setProjectStatusFilter(data);
    setProjectStatusFilterValue(projectStatus);
    getReporteeAssessmentListAndCount({
      pageNumber: 1,
      offset: pageSize,
      name: searchFilter,
      status: statusFilter,
      ...projectStatus,
    });
  };

  return (
    <div className={style.cycleTeamWrapper}>
      <SubNavigation type="Performance" />
      {isReportingTeamMemberLoading ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col md={12} className="col-12">
              <h1 className="page-title pb-0">Performance</h1>
              <span className="st-breadcrumb">
                Assessment
                <span>
                  <Link to={routePath.REPORTING_ASSESSMENT}>
                    Reporting Assessment
                  </Link>
                </span>
                <span>{cycleName}</span>
              </span>
            </Col>
            <Col className="col-12">
              <div className={[style.box_2, "box"].join(" ")}>
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2>{cycleName}</h2>
                    <h3>{period}</h3>
                  </div>
                  <div className={style.pending_submit_info}>
                    <span className={style.pending}>
                      Pending
                      <small>{pendingPercentage}%</small>
                    </span>
                    <span className={style.submitted}>
                      Submitted
                      <small>{submittedPercentage}%</small>
                    </span>
                  </div>
                </div>
              </div>
              <div className="outer-table-wrapper">
                {employeeListForReporting && (
                  <FilterTeam
                    placeholder="Search for team members"
                    filter={filter}
                    filterValue={statusFilter}
                    value={searchFilter ? searchFilter : ""}
                    teamData={employeeListForReporting}
                    onSearch={search}
                    onRadioChange={(e) => handleStatusChange(e.target.value)}
                    onResetSearch={resetSearch}
                    searchMember={searchMember}
                    onProjectStatusChange={handleProjectStatus}
                    projectStatusFilter={projectStatusFIlter}
                    showBenchFilter={isBenchFilter}
                    showCheckinStatusOnNewRow={true}
                  />
                )}
                <div className="box-light-gradient"></div>
                <div
                  className={[style.custom_table, "table-theme-1"].join(" ")}
                >
                  {employeeListForReporting &&
                  employeeListForReporting?.length > 0 ? (
                    <Table
                      hover
                      responsive
                      className={style.tab_table_responsive}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          {/* <th>Private Assessment</th> */}
                          <th>{ratingText}</th>
                          <th>Submitted On</th>
                          <th>Employee SA Status</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeListForReporting &&
                          employeeListForReporting?.length > 0 &&
                          employeeListForReporting?.map((item, index) => (
                            <EmployeeRow
                              key={item.employeeId + index}
                              backgroundColor={getColorByIndex(index)}
                              employeeId={item.employeeId}
                              image={
                                item.displayPicture &&
                                item.displayPicture.length > 2
                                  ? item.displayPicture
                                  : item.displayPicture || item.employeeInitial
                              }
                              pathName={`/performance/reporting-assessment/reporting-member/${cycleID}?empId=${item.employeeId}&pgId=${item.parentGroupId}&sgId=${item.subGroupId}`}
                              empName={item.empName}
                              designation={item.designation}
                              department={item.department}
                              grade={item.grade}
                              submittedDate={item.submittedDate}
                              selfAssessmentStatus={item.selfAssessmentStatus}
                              status={item.status}
                              // showRatingDropDown={showRatingDropDown.state}
                            />
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div>
                      <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                      <div className="gap80"></div>
                      <div className="gap80"></div>
                    </div>
                  )}
                </div>
              </div>
              {totalCount > 0 && (
                <Pagination
                  totalItem={totalCount}
                  onChangePage={onChangePage}
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
