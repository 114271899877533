import * as actionTypes from "./actionTypes";
import { uniqBy } from "lodash";
import http from "../../util/http";
import {
  DRAFT_OBJECTIVE,
  ADD_OBJECTIVE,
  GET_PREDEFINED_OBJECTIVE,
  DELETE_KEY_POINT,
  SUBMIT_OBJECTIVE,
  TEAM_OBJECTIVE,
  GET_OBJECTIVE,
  ASSIGN_OBJECTIVE,
  ALL_OBJECTIVE,
  OBJECTIVE_ACTIVITY,
  ASSIGNED_USER_OBJECTIVE,
  MANAGER_OBJECTIVE_ACTIVITY,
  DELETE_OBJECTIVE,
  UPDATE_OBJECTIVE,
  MANAGER_TEAM,
  UPDATE_ASSIGNED_OBJECTIVE,
  DELETE_ASSIGNED_KEY_POINT,
  USER_OBJECTIVE_HISTORY,
  DELETE_EDITED_ASSIGNED_KEY_POINT,
  DELETE_EDITED_KEY_POINT,
  FILE_UPLOAD,
  FETCH_TAG,
  DELETE_ASSIGNED_TAGS,
  DELETE_TAGS,
  ASSIGN_SELF_OBJECTIVE,
  UPDATE_OBJECTIVE_KEY_RESULT,
  GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR,
  GET_SELF_PREDEFINED_OBJECTIVE,
} from "../../util/endpoints";
import { status } from "const/constValue";
import { errorToast, successToast } from "util/general";

export const fetchTag = () => {
  return (dispatch) => {
    http
      .get(FETCH_TAG)
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          dispatch({ type: actionTypes.SET_TAGS, tagsData: response });
        } else {
          dispatch({ type: actionTypes.SET_TAGS, tagsData: [] });
        }
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          dispatch({ type: actionTypes.SET_TAGS, tagsData: [] });
        }
      });
  };
};

export const DraftObjective = () => {
  return (dispatch) => {
    http
      .get(DRAFT_OBJECTIVE)
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          let selfObjective,
            objective = null;
          selfObjective = response.filter((value) => value.isSelfObjective)[0];
          objective = response.filter((value) => !value.isSelfObjective)[0];
          let weightageSum = 0;
          let selfWeightageSum = 0;

          //change code for self objective weightage and master objective weightage
          if (selfObjective)
            for (let el in selfObjective.keyPoints) {
              weightageSum += parseFloat(selfObjective.keyPoints[el].weightage);
            }
          if (objective)
            for (let el in objective.keyPoints) {
              selfWeightageSum += parseFloat(objective.keyPoints[el].weightage);
            }
          //above
          if (selfObjective)
            // data: response
            dispatch({
              type: actionTypes.SET_DRAFT_SELF_OBJECTIVE_DATA,
              data: selfObjective,
              weightage: weightageSum,
            });
          else
            dispatch({
              type: actionTypes.SET_DRAFT_SELF_OBJECTIVE_DATA,
              data: {},
              weightage: 0,
            });
          if (objective)
            dispatch({
              type: actionTypes.SET_DRAFT_OBJECTIVE_DATA,
              draftObjectiveData: objective,
              keyWeightage: selfWeightageSum,
            });
          else
            dispatch({
              type: actionTypes.SET_DRAFT_OBJECTIVE_DATA,
              draftObjectiveData: {},
              keyWeightage: 0,
            });
        } else {
          dispatch({
            type: actionTypes.SET_DRAFT_SELF_OBJECTIVE_DATA,
            data: {},
            weightage: 0,
          });
          dispatch({
            type: actionTypes.SET_DRAFT_OBJECTIVE_DATA,
            draftObjectiveData: {},
            keyWeightage: 0,
          });
        }
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          dispatch({
            type: actionTypes.SET_DRAFT_OBJECTIVE_DATA,
            draftObjectiveData: {},
            keyWeightage: 0,
          });
        }
      });
  };
};

export const getPrdefinedObjective = (podInformation,countryName) => (dispatch) => {
  // dispatch({ type: "SET_ROLE_ASSIGN_LOADING" }); add loader for this
  http
    .get(GET_PREDEFINED_OBJECTIVE, { podInformation: podInformation,country: countryName })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let predefinedObjective = [];
        if (response.data) {
          predefinedObjective = response.data?.map((value) => {
            return {
              weightage: value.weightage,
              value: value.title,
              label: value.title,
              bandWiseObjectiveId: value.bandWiseObjectiveId,
              band: value.band,
              description: value.description
            };
          });
        }
        dispatch({
          type: actionTypes.GET_PREDEFINED_OBJECTIVE,
          data: predefinedObjective,
        });
      } else {
        dispatch({
          type: actionTypes.GET_PREDEFINED_OBJECTIVE,
          data: [],
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.GET_PREDEFINED_OBJECTIVE,
        data: [],
      });
    });
};

export const getSelfPrdefinedObjective = (podInformation, countryName,userBand) => (dispatch) => {
  let body = {
    podInformation : podInformation,
    country : countryName,
    band: userBand,
    isSelfObjective:true
  }
  // dispatch({ type: "SET_ROLE_ASSIGN_LOADING" }); add loader for this
  http
    .get(GET_SELF_PREDEFINED_OBJECTIVE,  body )
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let predefinedSelfObjective = [];
        if (response.data) {
          predefinedSelfObjective = response.data?.map((value) => {
            return {
              weightage: value.weightage,
              value: value.title,
              label: value.title,
              bandWiseObjectiveId: value.bandWiseObjectiveId,
              band: value.band,
              description:value.description
            };
          });
        }
        dispatch({
          type: actionTypes.GET_SELF_PREDEFINED_OBJECTIVE,
          data: predefinedSelfObjective,
        });
      } else {
        dispatch({
          type: actionTypes.GET_SELF_PREDEFINED_OBJECTIVE,
          data: [],
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.GET_SELF_PREDEFINED_OBJECTIVE,
        data: [],
      });
    });
};

export const getUserObjectiveWithManagerEmployeePair = (body) => {
  return (dispatch) => {
    http
      .post(GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR,
            managerEmployeePair: response,
          });
          dispatch({
            type: "SET_SELF_OBJECTIVE_DATA",
            payload: {
              totalWeightagae: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_OBJECTIVE_WITH_MANAGER_EMPLOYEE_PAIR_FAIL,
            message: response.message.message,
          });
        }
      })
      .catch(() => { });
  };
};

export const AddObjective = (data, type) => {
  return (dispatch) => {
    http
      .post(ADD_OBJECTIVE, {}, data)
      .then((response) => response.data.data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_OBJECTIVE,
          AddObjectiveResponse: response,
        });

        if (type === "EDIT") {
          dispatch(GetObjective(data.objectiveId));
        } else {
          dispatch(DraftObjective());
        }
      })
      .catch(() => {});
  };
};

export const AllObjective = (type) => {
  return (dispatch) => {
    http
      .get(ALL_OBJECTIVE, { definedBy: type })
      .then((response) => response.data)
      .then((response) => {
        if (response.data) {
          let universitydata = [],
            createdData = [];
          if (type === "UNIVERSITY") {
            universitydata = response.data;
            dispatch({
              type: actionTypes.VIEW_ALL_OBJECTIVE,
              data: {
                universityObjectiveResponse: universitydata,
                allObjectiveLoader: false,
                definedBy: type,
              },
            });
          }
          if (type === "MANAGER") {
            createdData = response.data;
            dispatch({
              type: actionTypes.VIEW_ALL_OBJECTIVE,
              data: {
                createObjectiveResponse: createdData,
                allObjectiveLoader: false,
                definedBy: type,
              },
            });
          }
          if (type === "SERVICE_LINE") {
            dispatch({
              type: actionTypes.VIEW_ALL_OBJECTIVE,
              data: {
                serviceLineObjectives: response.data,
                allObjectiveLoader: false,
                definedBy: type,
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.VIEW_ALL_OBJECTIVE,
            data: {
              universityObjectiveResponse: [],
              createObjectiveResponse: [],
              allObjectiveLoader: false,
            },
          });
        }
      })
      .catch(() => {});
  };
};
export const GetTeamObjectiveData = () => {
  return (dispatch) =>
    http
      .get(TEAM_OBJECTIVE)
      .then((response) => response.data)
      .then((data) => {
        if (data.message.code === 4001) {
          dispatch({
            type: actionTypes.SET_TEAM_OBJECTIVES,
            team: {
              teamError: true,
              teamObjectiveLoading: false,
              teamObjectiveDeleteLoading: false,
            },
          });
        } else if (data.data) {
          dispatch({
            type: actionTypes.SET_TEAM_OBJECTIVES,
            team: {
              teamObjectiveData: data.data,
              teamObjectiveLoading: false,
              teamObjectiveDeleteLoading: false,
            },
          });
        }
      })
      .catch(() => dispatch({ type: actionTypes.GET_TEAM_FAILED }));
};

export const GetManagerTeam = (id) => {
  return (dispatch) =>
    http
      .get(MANAGER_TEAM, { userObjectiveId: id })
      .then((response) => response.data)
      .then((data) => {
        if (data.message.code === 4001) {
          dispatch({
            type: actionTypes.SET_MANAGER_TEAM,
            team: {
              teamError: true,
              isLoading: false,
            },
          });
        } else if (data.data) {
          let allBands = [];
          if (data.data) {
            allBands = data.data?.map((value, i) => {
              return {
                value: value.band,
                label: value.band,
                id: i+1,
              };
            });
          }
          dispatch({
            type: actionTypes.SET_MANAGER_TEAM,
            team: {
              team: data.data,
              allBands: allBands,
              isLoading: false,
            },
          });
        }
      })
      .catch(() => dispatch({ type: actionTypes.GET_TEAM_FAILED }));
};

export const ObjectiveAdded = (flag) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OBJECTIVE_ADDED, objectiveAdded: flag });
  };
};

export const DeleteEditedKeyPoint = (id, type, obj, KeyPoints, objId) => {
  let body = {
    keyPointId: id.keyId,
    objectiveId: id.objectiveId,
  };
  const pass =
    KeyPoints.length > 0
      ? {
          ...obj,
          objectiveId: objId,
          keyPoints: KeyPoints,
        }
      : {
          ...obj,
          objectiveId: objId,
        };
  return (dispatch) => {
    http
      .delete(DELETE_EDITED_KEY_POINT, { data: body }, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          http
            .put(UPDATE_OBJECTIVE, {}, pass)
            .then((response) => response.data)
            .then((response) => {
              if (response.status === status.SUCCESS) {
                dispatch({
                  type: actionTypes.UPDATE_OBJECTIVE_RESPONSE,
                  message: "Objective has been updated.",
                  success: true,
                  redirectTo: type,
                });
                dispatch({
                  type: actionTypes.SUBMIT_OBJECTIVE,
                  objectiveSubmitted: response,
                });
                dispatch(ClearObjective());
              } else {
                dispatch({
                  type: actionTypes.UPDATE_OBJECTIVE_RESPONSE,
                  message: response.message.message,
                  success: false,
                });
              }
            })
            .catch(() => {});
        }
      })
      .catch(() => {});
  };
};

export const DeleteKeyPoint = (keyPointId, objectiveId, index, type) => {
  let params = {
    keyPointId: keyPointId,
    objectiveId: objectiveId,
    managerId: 14600200,
  };
  return (dispatch) => {
    http
      .delete(DELETE_KEY_POINT, {}, {}, params)
      .then((response) => response.data.data)
      .then(() => {
        if (type === "EDIT") {
          dispatch(GetObjective(objectiveId));
        } else {
          dispatch(DraftObjective());
        }

        dispatch({
          type: actionTypes.DELETE_KEY_POINT,
          index: index,
        });
      })
      .catch(() => {});
  };
};

export const DeleteAssignedTags = (tagId, objectiveId) => {
  let body = {
    tagId: tagId,
    objectiveId: objectiveId,
  };
  return () => {
    http
      .delete(DELETE_ASSIGNED_TAGS, { data: body }, {}, {})
      .then((response) => response.data)
      .then(() => {})
      .catch(() => {});
  };
};

export const DeleteTags = (tagId, objectiveId, isDraft) => {
  let body = {
    tagId,
    objectiveId,
  };

  if (isDraft) {
    body = {
      tagId,
      objectiveId,
      master: "DRAFT",
    };
  }

  return (dispatch) => {
    http
      .delete(DELETE_TAGS, { data: body }, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(DraftObjective());
        }
      })
      .catch(() => {});
  };
};

export const DeleteEditedAssignedKeyPoint = (id, type, obj, keyPoints, objId, empId, logInempId) => {
  let body = {
    keyPointId: id.keyId,
    objectiveId: id.objectiveId,
  };
  let pass = {
    userObjectiveId: objId,
    ...empId,
    ...logInempId,
    objectiveDescription: obj && obj.description,
    keyPoints: keyPoints.length > 0 ? keyPoints : undefined,
    hours: obj && obj.hours,
    minutes: obj && obj.minutes,
    startDate: obj && obj.startDate,
    endDate: obj && obj.endDate,
    tags: obj && obj.tags,
    objectiveTitle: obj && obj.title,
  };

  return (dispatch) => {
    http
      .delete(DELETE_EDITED_ASSIGNED_KEY_POINT, { data: body }, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          http
            .put(UPDATE_ASSIGNED_OBJECTIVE, {}, pass)
            .then((response) => response.data)
            .then((response) => {
              if (response.status === status.SUCCESS) {
                dispatch({
                  type: actionTypes.UPDATE_OBJECTIVE_RESPONSE,
                  success: true,
                  message: "Objective has been updated.",
                  redirectTo: type,
                });
                dispatch({
                  type: actionTypes.SUBMIT_OBJECTIVE,
                  objectiveSubmitted: response,
                });
                dispatch(ClearObjective());
              } else {
                dispatch({
                  type: actionTypes.UPDATE_OBJECTIVE_RESPONSE,
                  message: response.message.message,
                  success: false,
                });
              }
            })
            .catch(() => {});
        }
      })
      .catch(() => {});
  };
};

export const DeleteAssignedKeyPoint = (keyPointId, objectiveId, index, type, employeeId, managerId) => {
  let params = { keyPointId: keyPointId, userObjectiveId: objectiveId };
  return (dispatch) => {
    http
      .delete(DELETE_ASSIGNED_KEY_POINT, {}, {}, params)
      .then((response) => response.data.data)
      .then(() => {
        if (type === "EDIT") {
          dispatch(GetAssignedUserObjective(objectiveId, employeeId, managerId));
        } else {
          dispatch(DraftObjective());
        }

        dispatch({
          type: actionTypes.DELETE_KEY_POINT,
          index: index,
        });
      })
      .catch(() => {});
  };
};

export const SetObjectiveValidation = (errors) => (dispatch) =>
  dispatch({
    type: actionTypes.OBJECTIVE_VALIDATION,
    validationErrors: errors,
  });

export const SetKeyResultErrors = (errors) => (dispatch) =>
  dispatch({
    type: actionTypes.KEY_RESULT_ERRORS,
    keyResultErrors: errors,
  });
export const SetSelfObjectiveKeyErrors = (errors) => (dispatch) =>
  dispatch({
    type: actionTypes.SELF_OBJECTIVE_KEY_ERRORS,
    errors: errors,
  });

export const SubmitObjective = (objectiveId) => {
  return (dispatch) => {
    http
      .post(SUBMIT_OBJECTIVE, { objectiveId: objectiveId })
      .then((response) => response.data)
      .then((response) => {
        dispatch(AllObjective("MANAGER"));
        dispatch({
          type: actionTypes.SUBMIT_OBJECTIVE,
          objectiveSubmitted: response,
        });
        dispatch(ClearObjective());
      })
      .catch(() => {});
  };
};

export const AssignSelfObjective = (objectiveId, body) => {
  return (dispatch) => {
    http
      .post(SUBMIT_OBJECTIVE, { objectiveId: objectiveId })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          http
            .post(ASSIGN_SELF_OBJECTIVE, {}, body)
            .then((response) => response.data)
            .then((response) => {
              if (response.status === status.SUCCESS) {
                dispatch({
                  type: actionTypes.ASSIGN_SUCCESS,
                  message: "Assign objective successfully",
                });
              } else if (response.status === "FAILURE") {
                dispatch({
                  type: actionTypes.ASSIGN_FAIL,
                  message: response.message.message,
                });
              }
            });
        }
        dispatch(ClearObjective());
      })
      .catch(() => {});
  };
};

export const UpdateObjective = (objective, keyPoints, type, objectiveId, tagId) => {
  const body =
    keyPoints.length > 0
      ? {
          ...objective,
          objectiveId,
          keyPoints,
        }
      : {
          ...objective,
          objectiveId,
        };
  return (dispatch) => {
    http
      .put(UPDATE_OBJECTIVE, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (tagId && tagId.length > 0) {
            dispatch(DeleteTags(tagId, objectiveId));
          }
          dispatch({
            type: actionTypes.UPDATE_OBJECTIVE_RESPONSE,
            success: true,
            message: "Objective has been updated.",
            redirectTo: type,
          });
          dispatch({
            type: actionTypes.SUBMIT_OBJECTIVE,
            objectiveSubmitted: response,
          });
          dispatch(ClearObjective());
        } else {
          dispatch({
            type: actionTypes.UPDATE_OBJECTIVE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        }
      })
      .catch(() => {});
  };
};

export const UpdateAssignedObjective = (
  objective,
  keyPoints,
  userObjectiveId,
  employeeIds,
  managerIds,
  type,
  tagId
) => {
  let body = {
    userObjectiveId,
    ...employeeIds,
    ...managerIds,
    objectiveDescription: objective && objective.description,
    keyPoints: keyPoints.length > 0 ? keyPoints : undefined,
    hours: objective && objective.hours,
    minutes: objective && objective.minutes,
    startDate: objective && objective.startDate,
    endDate: objective && objective.endDate,
    tags: objective && objective.tags,
    objectiveTitle: objective && objective.title,
    weightage: objective && objective.weightage,
    isOther: objective && objective.isOther,
    stretchGoal: objective && objective.stretchGoal,
  };
  return (dispatch) => {
    http
      .put(UPDATE_ASSIGNED_OBJECTIVE, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (tagId && tagId.length > 0) {
            dispatch(DeleteAssignedTags(tagId, userObjectiveId));
          }
          dispatch({
            type: actionTypes.UPDATE_OBJECTIVE_RESPONSE,
            message: "Objective has been updated.",
            success: true,
            redirectTo: type,
          });
          dispatch({
            type: actionTypes.SUBMIT_OBJECTIVE,
            objectiveSubmitted: response,
          });
          dispatch(ClearObjective());
        } else {
          dispatch({
            type: actionTypes.UPDATE_OBJECTIVE_RESPONSE,
            message: response.message.message,
            success: false,
          });
        }
      })
      .catch(() => {});
  };
};

export const ClearObjective = () => (dispatch) =>
  dispatch({
    type: actionTypes.CLEAR_CREATE_OBJECTIVE,
  });

export const GetObjective = (id) => {
  return (dispatch) =>
    http
      .get(GET_OBJECTIVE, { objectiveId: id })
      .then((response) => response.data)
      .then((data) => {
        if (data.message.code === 4001) {
          dispatch({
            type: actionTypes.SET_OBJECTIVE,
            data: {
              isLoading: false,
              resourceNotFound: true,
            },
          });
        } else if (data.data) {
          dispatch({
            type: actionTypes.SET_OBJECTIVE,
            data: {
              objective: data.data,
              isLoading: false,
              resourceNotFound: false,
            },
          });
        }
      })
      .catch(() => dispatch({ type: actionTypes.GET_TEAM_FAILED }));
};

export const AssignObjective = (body) => {
  return (dispatch) => {
    http
      .post(ASSIGN_OBJECTIVE, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch({
            type: actionTypes.ASSIGN_SUCCESS,
            message: "Assign objective successfully",
          });
        } else {
          dispatch({
            type: actionTypes.ASSIGN_FAIL,
            message: response.message.message,
          });
        }
      })
      .catch(() => {});
  };
};

export const SetAssigneeTeam = (assignees) => {
  return (dispatch) => {
    let array = assignees.filter((value) => value.isChecked);
    array = uniqBy(array, (assignee) => assignee.employeeId);
    dispatch({
      type: actionTypes.SET_ASSIGNEES,
      assignees: array,
    });
  };
};

export const GetObjectiveActivity = (objectiveId, employeeIds) => {
  let config = {
    ...employeeIds,
    userObjectiveId: objectiveId,
  };
  return (dispatch) => {
    http
      .get(OBJECTIVE_ACTIVITY, {}, { params: config })
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_OBJECTIVE_ACTIVITY,
          activities: data.data,
        });
      })
      .catch((error) => console.warn(error));
  };
};

export const GetManagerObjectiveActivity = (objectiveId, managerIds) => {
  let params = {
    objectiveId,
    managerId: managerIds.employeeId,
    parentGroupId: managerIds.parentGroupId,
    subGroupId: managerIds.subGroupId,
  };
  return (dispatch) => {
    http
      .get(MANAGER_OBJECTIVE_ACTIVITY, {}, { params })
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_OBJECTIVE_ACTIVITY,
          activities: data.data,
        });
      })
      .catch((error) => console.warn(error));
  };
};

export const RemoveAssignees = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_ASSIGNEES,
      assignees: null,
    });
  };
};

export const DeleteUserObjective = (objectiveId, employeeIds, type) => {
  let params = { objectiveId: objectiveId, ...employeeIds };
  return (dispatch) => {
    dispatch({ type: "TEAM_OBJECTIVE_LOADING" });
    http
      .delete(UPDATE_OBJECTIVE, { data: params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          successToast("Delete successfully!")
          if (type === "TEAMS_OBJECTIVE") {
            dispatch(GetTeamObjectiveData());
          } else {
            dispatch({
              type: actionTypes.DELETE_USER_OBJECTIVE_SUCCESS,
              message: "Delete successfully!",
            });
          }
        }

        // dispatch(DraftObjective())
      })
      .catch(() => {
        errorToast("Something wrong!")
      });
  };
};

export const DeleteObjective = (objectiveId) => {
  return (dispatch) => {
    http
      .delete(DELETE_OBJECTIVE, { data: { objectiveId: objectiveId } }, {})
      .then((response) => response.data)
      .then((response) => {
        if (response?.status === "SUCCESS") {
          dispatch(AllObjective("MANAGER"));
        }
        dispatch({
          type: actionTypes.DELETE_USER_OBJECTIVE_SUCCESS,
          message: response?.message?.message,
        });
      })
      .catch(() => {});
  };
};

export const GetAssignedUserObjective = (userObjectiveId, employeeIds, managerIds) => {
  let params = {
    employeeId: employeeIds.employeeId,
    employeeSubGroupId: employeeIds.subGroupId,
    employeeParentGroupId: employeeIds.parentGroupId,
    managerId: managerIds?.employeeId,
    managerSubGroupId: managerIds?.subGroupId,
    managerParentGroupId: managerIds?.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(ASSIGNED_USER_OBJECTIVE, { userObjectiveId }, { params: params })
      .then((response) => response.data)
      .then((data) => {
        if (data.message.code === 4001) {
          dispatch({
            type: actionTypes.SET_OBJECTIVE,
            data: {
              isLoading: false,
              resourceNotFound: true,
            },
          });
        } else if (data.data) {
          dispatch({
            type: actionTypes.SET_OBJECTIVE,
            data: {
              objective: data.data,
              isLoading: false,
              resourceNotFound: false,
            },
          });
        }
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          dispatch({
            type: actionTypes.SET_DRAFT_OBJECTIVE_DATA,
            draftObjectiveData: [],
          });
        }
      });
  };
};

export const GetObjectivesBySurveyCycle = (id) => {
  let params = { surveyCycleId: id };
  return (dispatch) => {
    http
      .get(USER_OBJECTIVE_HISTORY, params)
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.GET_USER_HISTORY,
            data: {
              userObjectiveHistory: response || [], //need to for fetching data pass response
              // isLoading: false,
              isLoadingObjectiveHistory: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_USER_HISTORY,
            data: {
              userObjectiveHistory: response,
              isLoadingObjectiveHistory: false,
            },
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const ChangeSize = (size) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SIZE, size: size });
  };
};

export const setWeightage = (flag) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OBJECTIVE_ADDED, objectiveAdded: flag });
  };
};

export const uploadObjectiveFile = (body) => {
  return (dispatch) => {
    http
      .post(FILE_UPLOAD, {}, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === "FAILURE") {
          dispatch({
            type: actionTypes.UPLOAD_FAIL,
            message: "Please try again later",
          });
        } else {
          dispatch({
            type: actionTypes.UPLOAD_SUCCESS,
            message: "Objective file uploaded successfully",
          });
          dispatch(AllObjective("MANAGER"));
        }
      })
      .catch(() => {});
  };
};

export const UpdateObjectiveRatingAndComment = (definedBy, body) => {
  return () => {
    http
      .put(UPDATE_OBJECTIVE_KEY_RESULT, { definedBy }, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          console.warn("SUCCESS UPDATE");
        }
      })
      .catch((error) => console.warn("ENGAGE", error));
  };
};
