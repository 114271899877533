import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import PropTypes from "prop-types";
import HideAndShow from "../../HideAndShow/HideAndShow";
import AddTalkingPointBox from "./AddTalkingPointBox";
import CommentAnswer from "./CommentAnswer";

function PrivateNotes(props) {
  const { image, handleComment, comment, sendTalkingPoint, privateNotes, deletePrivateNote, loggedInUser, disabled } =
    props;
  const [hide, setHide] = useState(false);

  return (
    <div className={["card"].join(" ")}>
      <div className="card_info">
        <div className="card_title flex-wrap">
          <span className="card_title_text">Private Notes</span>
          <span className="card_title_description">Only you can see these notes while having 1-on-1</span>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>

      <Collapse in={!hide}>
        <div className="card_details">
          <div className={style.tp_inner_wrapper}>
            {privateNotes && (
              <div className={[style.view_comment_box_wrapper_n, "view_comment_box_wrapper"].join(" ")}>
                {privateNotes.map((point) => (
                  <CommentAnswer
                    point={point}
                    key={point.noteId}
                    deleteNote={deletePrivateNote}
                    loggedInUser={loggedInUser}
                  />
                ))}
              </div>
            )}
            <AddTalkingPointBox
              image={image}
              disabled={disabled}
              placeholder="Add a Private Note"
              comment={comment}
              handleComment={handleComment}
              sendTalkingPoint={sendTalkingPoint}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}

PrivateNotes.propTypes = {
  image: PropTypes.string,
  handleComment: PropTypes.func,
  comment: PropTypes.string,
  sendTalkingPoint: PropTypes.func,
  privateNotes: PropTypes.array,
  deletePrivateNote: PropTypes.func,
  loggedInUser: PropTypes.object,
  disabled: PropTypes.bool,
  isPage: PropTypes.string,
};

export default PrivateNotes;
