import React, { useEffect, useState } from "react";
import { DropdownButton } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import * as actions from "store/actions/index";
import style from "./RoleWiseUserList.module.scss";
import { errorToast, requiredFieldMissing, successToast } from "util/general";
import { IC_DELETE_2, IC_EDIT_OBJECTIVE } from "const/imgCost";
import Spinner from "shared/Spinner/Spinner";
import EditUserRolesmodal from "./EditUserRolesmodal";
import ConfirmationPopup from "../../Objectives/ConfirmationPopup";
import routePath from "const/routePath";

function RoleWiseUserList() {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [viewMoreFlag, setViewMoreFlag] = useState(false);
  const [assignee, setAssignee] = useState();
  const [roles, setRoles] = useState();
  const { userList, roleOptions, apiResponse, isLoading } = useSelector((state) => state.admin);
  const [display, setDisplay] = useState(false);
  const [employeeIds, setEmployeeIds] = useState();
  const headerText = "Sure you want to delete this user?";
  const subText = "We recommend editing the user. Once deleted, you won't be able to undo it.";

  useEffect(() => {
    dispatch(actions.getUserRoleRightsList());
    dispatch(actions.getRoleRightsList());
  }, [dispatch]);

  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.success) successToast(apiResponse.message);
      else errorToast(apiResponse.message);
      dispatch({ type: "CLEAR_ROLE_ASSIGN_RESPONSE" });
    }
  }, [apiResponse, dispatch]);

  const handleEditRole = (name, userRoles) => {
    if (userRoles?.length > 0 && name) {
      setEditModal(false);
      let body = {
        roleName: userRoles.map((v) => v.label),
        employeeId: [
          {
            employeeId: name.value,
            parentGroupId: name.parentGroupId,
            subGroupId: name.subGroupId,
          },
        ],
      };
      dispatch(actions.updateUserRoleRights(body));
    } else {
      requiredFieldMissing();
    }
  };

  const handleViewMore = () => {
    setViewMoreFlag(!viewMoreFlag);
  };
  const deleteUser = (item) => {
    setDisplay(true);
    setEmployeeIds({ employeeId: item.employeeId, parentGroupId: item.parentGroupId, subGroupId: item.subGroupId });
  };

  const ConfirmDeleteUser = () => {
    dispatch(actions.deleteUserRoleRights(employeeIds));
    setDisplay(false);
  };

  return (
    <div className={style.roll_wise_user_list_wrapper}>
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6}>
            <h1 className="page-title pb-0 mt-3">Admin</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.ADMIN_ROLES}>Admin</Link>
              <span>Users List</span>
            </span>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <div className={[style.define_admin_roles_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Roles and Responsibilities</h2>
                  <h3>Here is the list of assignee&rsquo;s and their roles.</h3>
                </div>
              </div>
              <div className="box-light-gradient"></div>
              <div className={[style.role_assignee_table, "custom-table", "table-theme-1"].join(" ")}>
                <table className="table">
                  <thead>
                    <tr>
                      <th className={style.role} width="30%">
                        Assignee
                      </th>
                      <th className={style.assignee} width="60%">
                        Roles
                      </th>
                      <th className={style.action} width="10%">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList?.length > 0 &&
                      userList
                        .filter((item, index) => viewMoreFlag || index < 10)
                        .map((item, index) => (
                          <tr key={item.employeeId + index}>
                            <td>{item.displayName}</td>
                            <td>
                              <div className="radio-wrapper d-flex dropdown-multiselect">
                                <div className="tags_wrapper">
                                  <Select
                                    options={item.roles.map((v) => {
                                      return {
                                        value: v,
                                        label: v,
                                      };
                                    })}
                                    isSearchable={false}
                                    className="custom-default-dd-box"
                                    classNamePrefix="select"
                                    placeholder="Roles Assigned"
                                    value={""}
                                    onChange={() => {}}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="action-dropdown-wrapper">
                                <DropdownButton
                                  alignRight
                                  title={
                                    <div className="action-dropdown">
                                      <span className="dots"></span>
                                      <span className="dots"></span>
                                      <span className="dots"></span>
                                    </div>
                                  }
                                  variant="a"
                                >
                                  <div
                                    className="dropdown_item dropdown-item"
                                    onClick={() => {
                                      setAssignee({
                                        value: item.employeeId,
                                        label: item.displayName,
                                        parentGroupId: item.parentGroupId,
                                        subGroupId: item.subGroupId,
                                      });
                                      setRoles(
                                        item.roles.map((v) => {
                                          return {
                                            value: v,
                                            label: v,
                                          };
                                        })
                                      );
                                      setEditModal(true);
                                    }}
                                  >
                                    <div className="dropdown_icon">
                                      <ReactSVG src={`${IC_EDIT_OBJECTIVE}`} alt="" />
                                    </div>

                                    <div className="dropdown_item">Edit Role</div>
                                  </div>
                                  <div className="dropdown_item dropdown-item" onClick={() => deleteUser(item)}>
                                    <div className="dropdown_icon">
                                      <ReactSVG src={`${IC_DELETE_2}`} alt="" />
                                    </div>
                                    <div className={style.dropdown_delete}>Delete</div>
                                  </div>
                                </DropdownButton>
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                {userList && userList.length > 10 && (
                  <div className="a-tag mt-4" onClick={handleViewMore}>
                    {viewMoreFlag ? "View Less" : "View More"}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {roles && (
        <EditUserRolesmodal
          show={editModal}
          hide={() => setEditModal(false)}
          assignee={assignee}
          roleOptions={roleOptions}
          handleEdit={handleEditRole}
          roles={roles}
          setRoles={setRoles}
        />
      )}
      <ConfirmationPopup
        headerText={headerText}
        subText={subText}
        show={display}
        submit={() => ConfirmDeleteUser()}
        hide={() => setDisplay(false)}
      />
    </div>
  );
}

export default RoleWiseUserList;
