import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import style from "./PreviewTemplate.module.scss";
import * as actions from "store/actions/index";
import { TEMPLATE, DETAIL_TEMPLATE } from "util/endpoints";
import { moduleView } from "util/utils";
import http from "util/http";
import storage from "util/storage";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import RadioType from "./radioType";
import CheckboxType from "./checkboxType";
import SliderType from "./SliderType";
import TextboxType from "./textboxType";
import DropDownType from "./dropDownType";
import ScaledType from "./ScaledType";
import ScaleType from "./ManagerQuestions/ScaleType";
import RankType from "./RankType";
import NPSType from "./NPSType";
import { status } from "const/constValue";

const TabEventKeys = {
  EMPLOYEE_VIEW: "Employee View",
  MANAGER_VIEW: "Manager View",
};

export default function PreviewTemplate(props) {
  const dispatch = useDispatch();
  const { templateCategoryList } = useSelector((state) => state.performance);
  const [template, setPreviewTemplate] = useState(props.location.state?.template);
  const [employeeQuestions, setEmployeeQuestions] = useState();
  const [managerQuestions, setManagerQuestions] = useState();
  const [extraQuestions, setExtraQuestions] = useState();
  const [templateName, setTemplateName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const cycleType = storage.get("cycle") || "";
  const redirectionId = storage.get("cId") || "";
  const isCampaignAdmin = moduleView(config.hrModule.CAMPAIGN_DETAILS)?.includes(config.hrModule.CAMPAIGN_DETAILS);
  const campaignType = storage.get("campaignType") ? storage.get("campaignType") : null;
  const numberOfQuestions = storage.get("numberOfQuestions") ? JSON.parse(storage.get("numberOfQuestions")) : null;
  const questionTemplateLength = storage.get("questionTemplateLength")
    ? JSON.parse(storage.get("questionTemplateLength"))
    : null;

  const backPathname =
    cycleType === "campaign"
      ? isCampaignAdmin
        ? `/hr/campaign-details/create?id=${redirectionId}`
        : `/okrprogress/campaign-list/edit?id=${redirectionId}`
      : cycleType === "configuration"
      ? "/admin/okrprogress-config"
      : `/hr/performance/performance-cycle/${cycleType}`;
  const [defaultKey, setDefaultKey] = useState(TabEventKeys.EMPLOYEE_VIEW);

  useEffect(() => {
    dispatch(actions.getTemplateCategory(redirectionId ? { params: { campaignId: redirectionId } } : {}));
  }, []);

  useEffect(() => {
    let config = redirectionId && { params: { campaignId: redirectionId } };
    http
      .get(DETAIL_TEMPLATE, { questionTemplateId: template.questionTemplateId }, config)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data.questions?.length) {
            setIsLoading(false);
            let employee = response.data.questions.filter((que) => que.respondToEmployee || que.respondToAll);
            let manager = response.data.questions.filter((que) => que.respondToManager || que.respondToAll);
            let extra = response.data.questions.filter(
              (que) => !que.respondToManager && !que.respondToAll && !que.respondToEmployee
            );
            setEmployeeQuestions(employee);
            setManagerQuestions(manager);
            setExtraQuestions(extra);
            setPreviewTemplate(response.data);
            setTemplateName(response.data.templateName);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [props.location.state && props.location.state.template]);

  const employeeQuestionList =
    employeeQuestions?.length > 0 &&
    employeeQuestions
      .filter((quest) => quest.questionCategory === null || quest.questionCategory.name === null)
      .map((quest, i) => {
        return setQuestion(quest, i);
      });
  const extraQuestionList =
    extraQuestions?.length > 0 &&
    extraQuestions
      .filter((quest) => quest.questionCategory === null)
      .map((quest, i) => {
        return setQuestion(quest, i);
      });
  let employeeCategoryArray = [];
  templateCategoryList &&
    templateCategoryList.forEach((category) => {
      let arr =
        employeeQuestions?.length > 0 &&
        employeeQuestions
          .filter((quest) => quest.questionCategory && quest.questionCategory.name === category.name)
          .map((quest) => {
            return quest;
          });
      arr?.length > 0 && employeeCategoryArray.push(arr);
    });

  let extraCategoryArray = [];
  templateCategoryList &&
    templateCategoryList.forEach((category) => {
      let arr =
        extraQuestions?.length > 0 &&
        extraQuestions
          .filter((quest) => quest.questionCategory && quest.questionCategory.name === category.name)
          .map((quest) => {
            return quest;
          });
      arr?.length > 0 && extraCategoryArray.push(arr);
    });

  const managerQuestionList =
    managerQuestions?.length > 0 &&
    managerQuestions
      .filter((quest) => quest.questionCategory === null)
      .map((quest, i) => {
        if (quest.notShowScaleToEmployee && quest.respondToAll) {
          return setQuestion(quest, i, "MANAGER");
        } else return setQuestion(quest, i, "EMPLOYEE");
      });

  let managerCategoryArray = [];
  templateCategoryList &&
    templateCategoryList.forEach((category) => {
      let arr =
        managerQuestions?.length > 0 &&
        managerQuestions
          .filter((quest) => quest.questionCategory && quest.questionCategory.name === category.name)
          .map((quest) => {
            return quest;
          });
      arr?.length > 0 && managerCategoryArray.push(arr);
    });

  function setQuestion(quest, i, view) {
    switch (quest.type) {
      case "CHECKBOX":
        return <CheckboxType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "RADIOBUTTON":
        return <RadioType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "TEXTBOX":
        return <TextboxType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "DROPDOWN":
        return <DropDownType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "SLIDER":
        return <SliderType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "SCALED":
        if (view === "MANAGER") return <ScaleType question={quest} no={i} />;
        else return <ScaledType question={quest} no={i} />;
      case "RANK":
        return <RankType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      case "NPS":
        return <NPSType style={style} question={quest} no={i} isManagerView={view === "MANAGER"} />;
      default:
        return <></>;
    }
  }
  function publishQuestionTemplate() {
    if (redirectionId && campaignType) {
      if (campaignType === "Quiz" && questionTemplateLength) {
        let config = {
          params: {
            campaignId: redirectionId,
            type: campaignType,
            numberOfQuestions: questionTemplateLength,
          },
        };
        dispatch(actions.getCampaignTemplateList(config));
      } else if (campaignType === "Survey") {
        let config = {
          params: {
            campaignId: redirectionId,
            type: campaignType,
          },
        };
        dispatch(actions.getCampaignTemplateList(config));
      }
    }

    let data;

    if (campaignType === "Quiz" && questionTemplateLength) {
      data = {
        questionTemplateId: template.questionTemplateId,
        type: campaignType,
        numberOfQuestions: questionTemplateLength,
        templateName: templateName,
        isPublished: true,
      };
    } else {
      data = {
        questionTemplateId: template.questionTemplateId,
        isPublished: true,
      };
    }
    let params = redirectionId
      ? {
          campaignId: redirectionId && redirectionId,
        }
      : {};
    http
      .post(TEMPLATE, {}, data, { params })
      .then((response) => response.data)
      .then(() => {
        //if User publish from campaign then below API would store question templateId
        if (redirectionId) {
          let body = {
            questionTemplateId: template.questionTemplateId,
            campaignId: redirectionId,
          };
          dispatch(actions.updateCampaign(body));
        }
        history.push(backPathname);
      })
      .catch(console.error);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.preview_template_wrapper}>
      <div className="gap30"></div>
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Question Builder</h1>
            <span className="st-breadcrumb">Preview Template</span>
          </Col>
          <Col className={["col-md-12"]}>
            <div
              className={["custom-tabs", "custom-tabs-full-width", "tab-content-dashboard", style.preview_tabs].join(
                " "
              )}
            >
              <Tabs
                activeKey={employeeQuestions?.length > 0 ? defaultKey : TabEventKeys.MANAGER_VIEW}
                onSelect={(key) => setDefaultKey(key)}
                id="Tabs-navigation"
              >
                {employeeQuestions?.length > 0 && (
                  <Tab eventKey={TabEventKeys.EMPLOYEE_VIEW} title="Employee View">
                    <div className="box">
                      <div className="box-heading-wrapper">
                        <div className="box-heading">
                          <h2>{templateName}</h2>
                          <h3>Preview this Question Template for Employees</h3>
                        </div>
                      </div>

                      {employeeQuestionList}
                      {employeeCategoryArray?.length > 0 &&
                        employeeCategoryArray.map((cat) => {
                          if (cat.length === 0) return null;
                          return (
                            <>
                              <div className="box-light-gradient h38"></div>
                              <h5 className={style.categoryTitle}>
                                {cat[0] && cat[0].questionCategory && cat[0].questionCategory.name}
                              </h5>
                              {cat.map((quest, i) => {
                                return setQuestion(quest, i);
                              })}
                            </>
                          );
                        })}
                      {props.location.state.hideButton && (
                        <div className={style.save_btn}>
                          <Link
                            to={{
                              pathname: `/question-builder/`,
                              state: { questionTemplateId: template && template.questionTemplateId },
                            }}
                          >
                            <Button variant="secondary">Edit</Button>
                          </Link>
                          <Button
                            variant="primary"
                            onClick={() => {
                              publishQuestionTemplate();
                            }}
                            disabled={
                              campaignType === "Quiz"
                                ? questionTemplateLength < numberOfQuestions
                                  ? true
                                  : false
                                : false
                            }
                          >
                            Publish
                          </Button>
                        </div>
                      )}
                      {!props.location.state.hideButton && (
                        <div className={style.save_btn}>
                          <Button variant="secondary" onClick={() => history.goBack()}>
                            Back
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>
                )}
                {managerQuestions?.length > 0 && (
                  <Tab eventKey={TabEventKeys.MANAGER_VIEW} title="Manager View">
                    <div className="box">
                      <div className="box-heading-wrapper">
                        <div className="box-heading">
                          <h2>{templateName}</h2>
                          <h3>Preview this Question Template for Managers</h3>
                        </div>
                      </div>

                      {managerQuestionList}
                      {managerCategoryArray?.length > 0 &&
                        managerCategoryArray.map((cat) => {
                          if (cat.length === 0) return null;
                          return (
                            <>
                              <div className="box-light-gradient h38"></div>
                              <h5 className={style.categoryTitle}>
                                {cat[0] && cat[0].questionCategory && cat[0].questionCategory.name}
                              </h5>
                              {cat.map((quest, i) => {
                                if (quest.notShowScaleToEmployee && quest.respondToAll) {
                                  return setQuestion(quest, i, "MANAGER");
                                } else return setQuestion(quest, i, "EMPLOYEE");
                              })}
                            </>
                          );
                        })}
                      {props.location.state.hideButton && (
                        <div className={style.save_btn}>
                          <Link
                            to={{
                              pathname: `/question-builder/`,
                              state: { questionTemplateId: template && template.questionTemplateId },
                            }}
                          >
                            <Button variant="secondary">Edit</Button>
                          </Link>
                          <Button
                            variant="primary"
                            onClick={() => {
                              publishQuestionTemplate();
                            }}
                          >
                            Publish
                          </Button>
                        </div>
                      )}
                      {!props.location.state.hideButton && (
                        <div className={style.save_btn}>
                          <Button variant="secondary" onClick={() => history.goBack()}>
                            Back
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>
                )}
              </Tabs>
              {extraQuestions?.length > 0 && (
                <div className="box">
                  <div className="box-heading-wrapper">
                    <div className="box-heading">
                      <h2>{templateName}</h2>
                      <h3>Preview this Question Template for Managers</h3>
                    </div>
                  </div>
                  {extraQuestionList}
                  {extraCategoryArray?.length > 0 &&
                    extraCategoryArray.map((cat) => {
                      if (cat.length === 0) return null;
                      return (
                        <>
                          <div className="box-light-gradient h38"></div>
                          <h5 className={style.categoryTitle}>
                            {cat[0] && cat[0].questionCategory && cat[0].questionCategory.name}
                          </h5>
                          {cat.map((quest, i) => {
                            if (quest.notShowScaleToEmployee && quest.respondToAll) {
                              return setQuestion(quest, i, "MANAGER");
                            } else return setQuestion(quest, i, "EMPLOYEE");
                          })}
                        </>
                      );
                    })}
                  {props.location.state.hideButton && (
                    <div className={style.save_btn}>
                      <Link
                        to={{
                          pathname: `/question-builder/`,
                          state: { questionTemplateId: template && template.questionTemplateId },
                        }}
                      >
                        <Button variant="secondary">Edit</Button>
                      </Link>
                      <Button
                        variant="primary"
                        onClick={() => {
                          publishQuestionTemplate();
                        }}
                      >
                        Publish
                      </Button>
                    </div>
                  )}
                  {!props.location.state.hideButton && (
                    <div className={style.save_btn}>
                      <Button variant="secondary" onClick={() => history.goBack()}>
                        Back
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
