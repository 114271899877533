import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import {
  SUBMIT_CERTIFICATE,
  GET_CERTIFICATE_NAME,
  GET_CERTIFICATE_LIST,
  DOWNLOAD_CERTIFICATE,
  DELETE_CERTIFICATE,
  GET_CERTIFICATE_LIST_FOR_ALL_EMPLOYEE_MANAGER,
  GET_CERTIFICATE_OVERVIEW_LIST,
  UNIVERSITY_MEMBER_CERTIFICATE_EXPORT,
  UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SEARCH,
  UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_FILTER,
  UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SEARCH_FILTER,
} from "../../util/endpoints";
import { b64toBlob } from "./checkIn";
import * as actions from "./index";
import { saveAs } from "file-saver";

export const submitCertificateData = (formGroup) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_LOADER,
      isLoading: true,
    });

    http
      .post(SUBMIT_CERTIFICATE, {}, formGroup)
      .then((response) => response.data)
      .then((response) => {
        if (response && response.status === "FAILURE") {
          dispatch({
            type: actionTypes.FAILURE_CERTIFICATE,
            payload: response.message.message,
          });
        } else {
          dispatch({
            type: actionTypes.SAVE_CERTIFICATE,
            payload: response.message.message,
          });
          dispatch(actions.getSubmittedCertificate);
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.UPLOAD_ERROR,
          payload: "Issue in upload certificate.",
        });
      });
  };
};

export const getSubmittedCertificate = (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_LOADER,
  });
  http
    .get(GET_CERTIFICATE_LIST)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.CERTIFICATE_LIST,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const deleteCertificate = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_LOADER,
    });
    http
      .delete(DELETE_CERTIFICATE, null, null, { id: params.id })
      .then((response) => response.data)
      .then(
        (response) => {
          if (response.status === "FAILURE") {
            dispatch({
              type: actionTypes.DELETE_FAIL,
              payload: response.message.message,
            });
          } else {
            dispatch({
              type: actionTypes.CERTIFICATE_DELETE_SUCCESS,
              payload: {
                message: response.message.message,
                id: params.id,
              },
            });
            dispatch({
              type: actionTypes.CLEAR_MESSAGE,
            });
          }
        },
        () => {
          dispatch({
            type: actionTypes.CERTIFICATE_DELETE_ERROR,
            payload: "Issue in deleting file",
          });
        }
      );
  };
};

export const downloadCertificate = (params, id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_LOADER,
    });
    http
      .get(DOWNLOAD_CERTIFICATE, { id }, { params })
      .then((response) => response.data.data)
      .then((response) => {
        if (response.status === "FAILURE") {
          dispatch({
            type: actionTypes.DOWNLOAD_FAIL,
            payload: response.message.message,
          });
        } else {
          const blob = b64toBlob(response.binary.data, response.file.contentType);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", response.file.name);
          document.body.appendChild(link);
          link.click();
          dispatch({
            type: actionTypes.DOWNLOAD_SUCCESS,
            payload: "File downloaded successfully",
          });
        }
        dispatch({
          type: actionTypes.CLEAR_MESSAGE,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.DOWNLOAD_CERTIFICATE_ERROR,
          payload: "Issue in downloading file",
        });
        dispatch({
          type: actionTypes.CLEAR_MESSAGE,
        });
      });
  };
};

export const getCertificateList = (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_LOADER,
  });
  http
    .get(GET_CERTIFICATE_NAME)
    .then((response) => response.data.data)
    .then((response) => {
      dispatch({
        type: actionTypes.CERTIFICATE_NAME,
        payload: response,
      });
    })
    .catch(() => {});
};

export const clearNotification = (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_MESSAGE,
  });
};

export const getCertificateListForAllEmployee = (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_LOADER,
  });
  http
    .get(GET_CERTIFICATE_LIST_FOR_ALL_EMPLOYEE_MANAGER)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_CERTIFICATE_LIST_FOR_ALL_EMPLOYEE_MANAGER,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getCertificateOverviewList = (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_LOADER,
  });
  http
    .get(GET_CERTIFICATE_OVERVIEW_LIST)
    .then((response) => response.data)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_CERTIFICATE_OVERVIEW_LIST,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.NOT_FOUND,
      });
    });
};

export const getUniversityMemberCertificateExport = (param) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_LOADER,
    });
    if (param) {
      let URL = "";
      if (param && param.search && param.filter) {
        URL = UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SEARCH_FILTER;
      } else if (param && param.search) {
        URL = UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SEARCH;
      } else if (param && param.filter) {
        URL = UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_FILTER;
      }
      http
        .get(URL, param, { responseType: "arraybuffer" })
        .then((response) => response.data)
        .then((response) => {
          if (response) {
            // eslint-disable-next-line no-undef
            var byteArray = new Uint8Array(response);
            var blob = new Blob([byteArray], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            let filename = "University_Member_Certificate.xlsx";
            saveAs(blob, filename);
            dispatch({
              type: actionTypes.UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SUCCESS,
              payload: "Downloading File.",
            });
            dispatch({
              type: actionTypes.CLEAR_MESSAGE,
            });
          } else {
            dispatch({
              type: actionTypes.UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_ERROR,
              payload: "Error Downloading File.",
            });
            dispatch({
              type: actionTypes.CLEAR_MESSAGE,
            });
          }
        })
        .catch(() => {});
    } else {
      http
        .get(UNIVERSITY_MEMBER_CERTIFICATE_EXPORT, {}, { responseType: "arraybuffer" })
        .then((response) => response.data)
        .then((response) => {
          if (response) {
            // eslint-disable-next-line no-undef
            var byteArray = new Uint8Array(response);
            var blob = new Blob([byteArray], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            let filename = "University_Member_Certificate.xlsx";
            saveAs(blob, filename);
            dispatch({
              type: actionTypes.UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_SUCCESS,
              payload: "Downloading File.",
            });
            dispatch({
              type: actionTypes.CLEAR_MESSAGE,
            });
          } else {
            dispatch({
              type: actionTypes.UNIVERSITY_MEMBER_CERTIFICATE_EXPORT_ERROR,
              payload: "Error Downloading File.",
            });
            dispatch({
              type: actionTypes.CLEAR_MESSAGE,
            });
          }
        })
        .catch(() => {});
    }
  };
};
