import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import style from "./MyAdditionalSkill.module.scss";
import * as actions from "store/actions/index";
import { IC_DELETE, IC_PENCIL, IMG_ENDORSEMENT_1, IMG_SME, IMG_UNI, IMG_PM } from "const/imgCost";
import { errorToast, filterArray, successToast } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "../../NodataFound/NoDataFound";
import Pagination from "../../Pagination/Pagination";
import CertificateDeleteModal from "../../MyCertificate/CertificateDeleteModal/CertificateDeleteModal";
import { competencyLevel, noData } from "const/constValue";

export default function MyAdditionalSkill() {
  const dispatch = useDispatch();
  const { isLoading, additionalSkillList, size, deleteAdditionalSkillResponse } = useSelector(
    (state) => state.skillMatrix
  );
  const [skillList, setSkillList] = useState([]);
  const [pageItem, setPageItem] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [skillId, setId] = useState(null);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const history = useHistory();

  useEffect(() => {
    const getAdditionalSkill = () => dispatch(actions.getAdditionalSkillList);
    getAdditionalSkill();
  }, [dispatch]);

  useEffect(() => {
    if (deleteAdditionalSkillResponse && !deleteAdditionalSkillResponse.error)
      successToast(deleteAdditionalSkillResponse.message);
    if (deleteAdditionalSkillResponse?.error) errorToast(deleteAdditionalSkillResponse.message);
  }, [deleteAdditionalSkillResponse]);

  useEffect(() => {
    if (additionalSkillList && additionalSkillList.length > 0) {
      setPageItem(additionalSkillList);
    } else {
      setPageItem([]);
    }
  }, [additionalSkillList]);

  useEffect(() => {
    if (additionalSkillList && additionalSkillList.length > 0) {
      setSkillList(additionalSkillList);
    } else {
      setSkillList([]);
    }
  }, [additionalSkillList]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    if (additionalSkillList && additionalSkillList.length > 0) {
      filteredData = filterArray(additionalSkillList, filters);
      setFilteredData(filteredData);
      setSearchFilter("");
    }
  };

  useEffect(() => {
    filteredData.length > 0
      ? setSkillList(filteredData)
      : searchFilter
      ? setSkillList(filteredData)
      : setSkillList(additionalSkillList.length > 0 && additionalSkillList);
  }, [additionalSkillList, filteredData, searchFilter]);

  const onChangeCertificatePage = (item) => {
    setPageItem(item);
  };

  const editSkill = (id) => {
    history.push("/wmg/my-skills/additional-skill/" + id);
  };

  const showPopup = (id) => {
    handleShow();
    setId(id);
  };

  const submitHandler = () => {
    setShow(false);
    dispatch(actions.deleteAdditionalSkill(skillId));
    return;
  };

  const onHide = () => {
    setShow(false);
  };
  const handleCreateClick = () => {
    history.push("/wmg/my-skills/add-skill");
  };
  return (
    <>
      {/* start skill gaps found section */}
      <div>
        <div className={[style.additional_skills_wrapper, "box"].join(" ")}>
          {isLoading && <Spinner />}
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>My Additional Skills</h2>
              {/* <h3>Now you can also add skills apart from MMG defined for you</h3> */}
            </div>
            <button
              type="button"
              onClick={() => handleCreateClick()}
              className={["btn-round save_box_btn btn btn-secondary", style.create_new_btn].join(" ")}
            >
              <strong>Additional Skills</strong>
            </button>
          </div>
          <div className="box-inner-pad box-light-gradient p-0">
            <div className={[style.additional_skill_table, "custom-table", "table-theme-1"].join(" ")}>
              <table className="table">
                <thead>
                  {pageItem && pageItem.length !== 0 && (
                    <tr>
                      <th>Skill Name</th>
                      <th>Endorsement</th>
                      <th>Rel. Experience</th>
                      <th>Competency Level</th>
                      <th>Last Used On</th>
                      <th>Action</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {pageItem &&
                    pageItem.length > 0 &&
                    pageItem.map((skill, index) => {
                      const skillName = skill.skillName;
                      const relExperience = skill.experienceInYears + "." + skill.experienceInMonth;
                      const lastUsed = skill.lastUsedMonth + " " + skill.lastUsedYear;

                      skill.endorsementList = skill.endorsementList.filter((ed) => ed.count > 0);
                      return (
                        <tr key={index}>
                          <td>{skillName}</td>
                          <td>
                            {skill.endorsementList && skill.endorsementList.length > 0
                              ? skill.endorsementList.map((ed, index) => {
                                  let imgSrc =
                                    ed.endorsedTypeId === "4"
                                      ? IMG_ENDORSEMENT_1
                                      : ed.endorsedTypeId === "3"
                                      ? IMG_PM
                                      : ed.endorsedTypeId === "2"
                                      ? IMG_SME
                                      : IMG_UNI;

                                  let endorseType =
                                    ed.endorsedTypeId === "4"
                                      ? "Peer"
                                      : ed.endorsedTypeId === "3"
                                      ? "PM"
                                      : ed.endorsedTypeId === "2"
                                      ? "SME"
                                      : "University";
                                  if (ed.count > 0) {
                                    return (
                                      <div className={style.endorse_tooltip} key={index}>
                                        <img src={imgSrc} alt="PEER" />
                                        <div className={style.tool_tip_info}>
                                          <h6>{endorseType}</h6>
                                          <span>Beginner ({ed["beginner"]})</span>
                                          <span>Intermediate ({ed["intermediate"]})</span>
                                          <span>Advanced ({ed["advance"]})</span>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                })
                              : "-"}
                          </td>
                          <td>
                            {relExperience}
                            {" Years"}
                          </td>
                          <td>
                            <span className={[style.req_comp_level, competencyLevel[skill.competencyLevel]].join(" ")}>
                              {competencyLevel[skill.competencyLevel]}
                            </span>
                          </td>
                          <td>{lastUsed}</td>
                          <td>
                            <span className={style.add_action}>
                              <span className={style.edit} onClick={() => editSkill(skill.skillNameId)}>
                                <ReactSVG src={IC_PENCIL} alt="Edit" />
                              </span>
                              <span className={style.delete} onClick={() => showPopup(skill.skillId)}>
                                <ReactSVG src={IC_DELETE} alt="Delete" />
                              </span>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {pageItem && pageItem.length === 0 && (
              <div>
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap120"></div>
              </div>
            )}
          </div>
          {show && (
            <CertificateDeleteModal
              show={show}
              hide={onHide}
              title={"Sure you want to delete this skill?"}
              description={"Once deleted, you won't be able to undo it."}
              accept_button_name={"Delete"}
              reject_button_name={"Cancel"}
              submit={submitHandler}
            />
          )}
        </div>
        {pageItem && pageItem.length !== 0 && (
          <Pagination pageSize={size} items={skillList} onChangePage={onChangeCertificatePage} resetData={resetData} />
        )}
      </div>
    </>
  );
}
