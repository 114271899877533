import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import MultipleFileUpload from "../../../MultipleFileUpload/MulitpleFileUpload";
import SingleFileView from "../../../MultipleFileUpload/SingleFileView";

function OptionalComment({
  item,
  uploadFile,
  commentError,
  handleComment,
  isSubmitted,
  downloadFile,
  commentPlaceHolder,
  deleteFile,
  isSurvey,
}) {
  return (
    <div>
      {isSubmitted ? (
        <ul className="common_list_items">
          <li className="mb-0 mt-2">{item.answer || "N/A"}</li>
        </ul>
      ) : (
        <div className="textarea-wrapper mt-3">
          {!isSurvey && (!item.file || item.file?.length < 5) && uploadFile && (
            <MultipleFileUpload handleMultipleFileUpload={uploadFile} />
          )}
          <Form.Control
            as="textarea"
            maxLength={5000}
            defaultValue={item.answer}
            className={[commentError && "limit-reached", "with-file-attachment"].join(" ")}
            placeholder={commentPlaceHolder}
            onBlur={handleComment}
          />
        </div>
      )}
      <div className="d-flex ">
        {item.file && Array.isArray(item.file) && (
          <div className="question_multiple_uploaded_file">
            <ul className="file-listing w-100">
              {item.file &&
                item.file.map((value, index) => (
                  <SingleFileView
                    removeFile={() => deleteFile(value.id, item.questionId)}
                    name={value.name}
                    isDownloadable={true}
                    isDeletable={!isSubmitted}
                    key={index}
                    downloadFile={() => downloadFile(value.id, item.questionId)}
                    id={value.id}
                  />
                ))}
            </ul>
          </div>
        )}
        {/* <div className="checkin-question-info mt-1">500 Characters Left</div> */}
      </div>
    </div>
  );
}

OptionalComment.propTypes = {
  item: PropTypes.object,
  uploadFile: PropTypes.func,
  commentError: PropTypes.bool,
  handleComment: PropTypes.func,
  isSubmitted: PropTypes.bool,
  downloadFile: PropTypes.func,
  commentPlaceHolder: PropTypes.string,
  deleteFile: PropTypes.func,
};

export default OptionalComment;
