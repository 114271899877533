import React, { useState } from "react"
import KeyResult from "./KeyResult";
import { Row } from "react-bootstrap";

export default function ObjectiveKeyResults(props){
  const [hideKeyResults, setHideKeyResults] = useState(true)
  return (
      <div className="box pb-0">
          <div className="box-heading-wrapper">
              <div className="box-heading">
                  <h2>{props?.keyPoints?.length} Key Results Aligned</h2>
                  {/* <h3>Some text goes here, will be updated soon</h3> */}
              </div>
              <div className="box-info ">
                <span>
                  <span onClick={() => setHideKeyResults(!hideKeyResults) }> {hideKeyResults ? "Hide" :  "Show"}</span>
                </span>
              </div>
          </div>
          {hideKeyResults && <div className="box-light-gradient"></div>}
         {hideKeyResults && <div className="box-inner-pad">
            <Row>
              <div className="col-xl-12">
                  <div className="form-group">
                    {props.keyPoints 
                      && props.keyPoints.map(keyPoint =>   
                        <KeyResult keyPoint={keyPoint} key={"KEYPOINT_" + keyPoint.keyId} assign={props.assign}/> ) }
                </div>
              </div>
            </Row>
          </div>}
      </div>
  )
}