import React from "react";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";

function TitleAndDropdown({ options, defaultValue, handleChange, title, subTitle }) {
  return (
    <Row>
      <Col xs={12} md={6} sm={6}>
        {title && <div className="title">{title}</div>}
        {subTitle && <div className="subTitle">{subTitle}</div>}
      </Col>
      <Col xs={12} md={6} sm={6}>
        <Select
          onChange={handleChange}
          options={options}
          className="select_dropdown select_dropdown_text float-right"
          classNamePrefix="select"
          isSearchable={false}
          value={defaultValue}
        />
      </Col>
    </Row>
  );
}

export default TitleAndDropdown;
