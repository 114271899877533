import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import style from "./ManagerTeamMemberList.module.scss";
import * as actions from "store/actions/index";
import { filterArray } from "util/general";
import { noData } from "const/constValue";
import Spinner from "shared/Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";
import FilterTeam from "../../../FilterTeam/FilterTeam";
import NoDataFound from "../../../NodataFound/NoDataFound";
import MemberRow from "./MemberRow";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function ManagerTeamMemberList(props) {
  const [pageOfItems, setPageOfItems] = useState([]);
  const [myTeamData, setMyTeamData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const { deallocationEmpListForManager, isListViewLoading } = useSelector((state) => state.deAllocation);
  const pageSize = useSelector((state) => state.myTeam.pageSize);
  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);
  const filterValue = props.location.state?.filterValue || "";

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getDeallocationListForManager());
  }, [dispatch]);

  useEffect(() => {
    if (deallocationEmpListForManager.length > 0) {
      filteredData.length > 0
        ? setMyTeamData(filteredData)
        : statusFilter || searchFilter
        ? setMyTeamData(filteredData)
        : setMyTeamData(deallocationEmpListForManager);
    }
  }, [filteredData, deallocationEmpListForManager, statusFilter, searchFilter]);

  useEffect(() => {
    setStatusFilter(filterValue);
    if (statusFilter) {
      handleStatusChange(statusFilter);
    }
  }, [deallocationEmpListForManager]);

  const search = (event) => {
    let updatedData = null;
    const filters = {
      empName: event.target.value,
      status: statusFilter ? statusFilter : "",
    };
    updatedData = filterArray(deallocationEmpListForManager, filters);
    setFilteredData(updatedData);
    setSearchFilter(event.target.value);
  };

  const handleStatusChange = (value) => {
    let updatedData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      status: value,
    };
    updatedData = filterArray(deallocationEmpListForManager, filters);
    setStatusFilter(value);
    setFilteredData(updatedData);
  };

  const resetData = () => {
    let updatedData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      status: statusFilter ? statusFilter : "",
    };
    updatedData = filterArray(deallocationEmpListForManager, filters);
    setFilteredData(updatedData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter(searchFilter);
  };

  const resetSearch = () => {
    let updatedData = null;
    const filters = {
      empName: "",
      status: statusFilter ? statusFilter : "",
    };
    updatedData = filterArray(deallocationEmpListForManager, filters);
    setFilteredData(updatedData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter("");
  };

  const filter = {
    value: ["", "PENDING", "SUBMIT", "INVALIDATE"],
    name: ["All", "Pending", "Submitted", "Submitted(N/A)"],
  };

  if (isListViewLoading) {
    return <Spinner />;
  }
  return (
    <div className={style.feedback_wrapper}>
      <SubNavigation type="Performance" />
      <div className={style.feedback_inner_wrapper}>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="page-title pb-0">Performance</h1>
              <span className="st-breadcrumb">
                Feedback <span>Deallocation Feedback</span>
              </span>
            </Col>
            <Col md={12}>
              <div className={[style.table_layout, "box pt-0"].join(" ")}>
                <FilterTeam
                  placeholder="Search for team members"
                  filter={filter}
                  filterValue={filterValue}
                  value={searchFilter ? searchFilter : ""}
                  style={style}
                  deallocationEmpListForManager={deallocationEmpListForManager}
                  onSearch={search}
                  onRadioChange={(e) => handleStatusChange(e.target.value)}
                  onReset={resetData}
                  onResetSearch={resetSearch}
                />
                <div className="box-light-gradient"></div>
                <div className="box-inner-pad pb-0">
                  {pageOfItems && pageOfItems.length > 0 ? (
                    <div className={style.employee_list_wrapper}>
                      <ul className={style.employee_list_header}>
                        <li>
                          <span className={style.name}>Name</span>
                          <span className={style.project_name}>Project / Duration</span>
                          <span className={style.deallocation_date}>Deallocation Date</span>
                          <span className={style.status}>Status</span>
                        </li>
                      </ul>
                      <ul className={style.employee_list}>
                        {pageOfItems.map((empInfo, index) => (
                          <MemberRow
                            key={empInfo.employee?.employeeId + "_" + index}
                            pathName={`/performance/project-deallocation/${empInfo.projectDeAllocationId}?empId=${empInfo.employee?.employeeId}&pgId=${empInfo.employee?.parentGroupId}&sgId=${empInfo.employee?.subGroupId}`}
                            index={index}
                            projectName={
                              empInfo?.projectName?.length > 0 &&
                              empInfo.projectName.map((v) => v.projectName).join(", ")
                            }
                            date={empInfo?.projectName?.length > 0 && empInfo.projectName[0].deallocationDate}
                            projectDay={
                              empInfo?.projectName?.length > 0 &&
                              empInfo.projectName.map((v) => v.projectWorkingDays).join(", ")
                            }
                            image={empInfo.employee?.displayPicture || empInfo.employee?.empInitial}
                            empName={empInfo.employee?.empName}
                            designation={empInfo.employee?.designation}
                            department={empInfo.employee?.department}
                            status={empInfo.status}
                          />
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div>
                      <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                      <div className="gap80"></div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              {deallocationEmpListForManager?.length > 0 && myTeamData && (
                <Pagination pageSize={pageSize} items={myTeamData} onChangePage={onChangePage} resetData={resetData} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
