import React from "react";
import ActionBox from "./ActionBox/ActionBox";

const MoodAnswer = props => {
  return (
    <>
      <div
        className={[
          "two_column mt-2",
          props.style.current_week_feels_wrapper,
          props.ratingClass
        ].join(" ")}
      >
        <div className={props.style.current_week_feels_info}>
          Feelings
          <span
            className={[
              props.style.current_week_value,
              props.ratingClass,
              "cursor_default"
            ].join(" ")}
          >
            {props.question.answer ? props.question.answer : "N/A"}
          </span>
        </div>
        <ActionBox {...props} isQuestion="mood" />
      </div>
      <div className="box_inner_title mt-3">{props.question.queTitle}</div>
      {/* <div className={props.style.questions_answer_row}>
        <div className={[props.style.questions_answer, "mb-2"].join(" ")}>
          <ul className="common_list_items">
            <li>{props.employeeComment ? props.employeeComment : "N/A"}</li>
          </ul>
        </div>
      </div> */}
      <div className={props.style.questions_answer_row}>
        <div className={props.style.questions_answer}>
          <ul className="common_list_items">
            <li>{props.question.employeeComment ? props.question.employeeComment : "N/A"}</li>
          </ul>
          {/* privacy lock content will come here */}
        </div>
      </div>
    </>
  );
};

export default MoodAnswer;
