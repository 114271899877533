import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import styles from "./SidebarCertificate.module.scss";
import { IC_CERTIFICATION_1 } from "const/imgCost";

export default function SidebarCertificate() {
  const { certificateList } = useSelector((state) => state.university);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (certificateList && certificateList.length > 0) {
      setCount(certificateList.length);
    } else {
      setCount(0);
    }
  }, [certificateList]);

  return (
    <div className={["box reviewer-profile-box", styles.reviewer_profile_box].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Professional Certification</h2>
          <h3 className="box-heading-light">
            Certifications helps you build Professional Credibility, Expand Knowledge and Skills and Increase
            Efficiency. It gives recognition of competency, shows commitment to the profession and helps with job
            advancement.
          </h3>
        </div>
      </div>
      <div className="box-inner-pad box-light-gradient no-border">
        <div className={styles.cerfication_info}>
          <span className={styles.cerfication_img}>
            <ReactSVG src={`${IC_CERTIFICATION_1}`} />
          </span>
          <div className={styles.cerfication_text}>
            <h3>{count}</h3>
            <span>Certifications Done</span>
          </div>
        </div>
      </div>
    </div>
  );
}
