import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_ACC_ARROW_DOWN } from "../../../../const/imgCost";
import GradeCard from "../../../GradeCard/GradeCard";
import InfoTooltip from "../../../InfoTooltip/InfoTooltip";
import style from "./SelfAssessmentRating.module.scss";

function SelfAssessmentProgress({ overAllAggregateText, selfAssessmentAvgGrade, selfAssessmentManagerViseGrade }) {
  const [eventValue, setEventValue] = useState(false);
  return (
    <Accordion defaultActiveKey="PROGRESS" className="custom-accordion">
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="PROGRESS"
            onClick={(e) => {
              setEventValue(!eventValue);
              if (e.currentTarget.classList.contains("show")) {
                e.currentTarget.classList.remove("show");
              } else {
                e.currentTarget.classList.add("show");
              }
            }}
            className={!eventValue && "show"}
          >
            <div className="accordion-icon">
              <ReactSVG src={IC_ACC_ARROW_DOWN} />
            </div>
            <div className={style.accordion_title}>
              <div className={style.title_left}>
                Assessment Progress
                <InfoTooltip
                  heading="Self assessment progress has been displayed below
              where Grade and Ratings are shown given by Manager accordingly"
                />
              </div>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="PROGRESS">
          <Card.Body>
            <div className="box-light-gradient"></div>
            <GradeCard
              overAllAggregateText={overAllAggregateText}
              aggregateGrade={selfAssessmentAvgGrade}
              grades={selfAssessmentManagerViseGrade}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default SelfAssessmentProgress;
