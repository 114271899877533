import React from "react";
import { Modal, Button } from "react-bootstrap";
import style from "../../CycleMemberProfile/MemberProfileObjecive/ObjeciveModal/ObjeciveModal.module.scss";

const InitiativeModal = ({ empName, show, hide, plans, percentage, status, title }) => {
  let _class = "";
  if (percentage >= 66) {
    _class = style.step1;
  } else if (percentage < 66 && percentage > 33) {
    _class = style.step2;
  } else if (percentage <= 33) {
    _class = style.step3;
  }
  let objectiveStatus = "";
  if (status === "INPROGRESS") {
    objectiveStatus = "In progress";
  } else if (status === "COMPLETED") {
    objectiveStatus = "Completed";
  } else if (status === "INCOMPLETED") {
    objectiveStatus = "Incompleted";
  }
  return (
    <>
      <Modal show={show} onHide={hide} size="lg" animation={false} className="custom_modal_popup">
        <div className="custom_modal_container custom_lg_modal_container">
          <Modal.Header className={style.mHeader}>
            <Modal.Title>
              Initiatives Taken by {empName}
              <span>{plans.length} Initiatives Found</span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="box-light-gradient"></div>
            <div className={style.heading}>
              <div className={style.heading_left}>
                <div className={style.idp_process_indicate}>
                  <div className={style.idp_step}>
                    <div className="box-info">
                      <div className={[style.step, _class, style.active].join(" ")}></div>
                    </div>
                  </div>
                </div>
                <div className={style.idp_heading_wrapper}>
                  <h3>{title}</h3>
                </div>
              </div>
              <div className={style.heading_right}>
                <div className={style.idp_process_complete}>{percentage}%</div>
                <div className={style.idp_end_days}>{objectiveStatus}</div>
              </div>
            </div>
            <div className={style.mObjective_wpr}>
              {plans.map((plan) => {
                return (
                  <div className={style.mObjective_list} key={plan.planId}>
                    <div className="custom-checkbox custom-checkbox-rounded">
                      <input id="111" type="checkbox" checked disabled />
                      <label htmlFor="111">{plan.planDescription}</label>
                    </div>
                    <ul className="list-of-objective text-break">
                      <li className="pb-0">
                        <span>{plan.objectiveDescription}</span>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </Modal.Body>

          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={hide}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default InitiativeModal;
