import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./../TabEngagement.module.scss";
import Select from "react-select";
import { monthOptions2 } from "const/options";
import TwoLevelPieChart from "../../TwoLevelPieChart/TwoLevelPieChart";
import SliderText from "../../SliderText/SliderText";
import TeamsWeeklyCheckInChart from "./TeamsWeeklyCheckInChart";

function TeamsCheckInActivity(props) {
  const [checkinGraphData, setCheckinGraphData] = useState();

  const swipeChange = (oldIndex, newIndex) => {
    setCheckinGraphData(props.currentAndPreviousCheckin[newIndex]?.graphData);
  };

  return (
    <div className="card_details">
      <div className="box-light-gradient"></div>
      <div className={style.to_do_row}>
        <Row>
          <Col xs={12} md={12}>
            <div className={style.subtitle}>Team Members Pending OKR Progress Review/Status</div>
          </Col>
        </Row>
        <div className={style.details}>
          <Row>
            <Col xs={12} md={5} className={style.meter_wrapper}>
              <Select
                onChange={(select) => props.handleTeamsChartOptions(select)}
                options={monthOptions2}
                className="select_dropdown select_dropdown_text float-right"
                classNamePrefix="select"
                isSearchable={false}
                defaultValue={{
                  value: 1,
                  label: "Last Month",
                }}
              />
              <TwoLevelPieChart
                legendData={[
                  { name: "Lapsed", order: 1 },
                  { name: "Submitted", order: 2 },
                  { name: "Pending for Review", order: 3 },
                ]}
                height="265px"
                noDataText="Team Members Not Found"
                insideData={props.checkinPendingFromEmployees}
                outsideData={props.pendingCheckinReview}
              />
            </Col>
            <Col xs={12} md={7} className={style.teams_weekly_checkin_wrapper}>
              <div className={style.team_checkin_submit_wrapper}>
                <div className={style.checkin_slider_wrapper}>
                  <SliderText currentAndPreviousCheckin={props.currentAndPreviousCheckin} swipeChange={swipeChange} />
                </div>
                <div className={style.teams_checkin_chart_wrapper}>
                  <TeamsWeeklyCheckInChart checkinGraphData={checkinGraphData} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default TeamsCheckInActivity;
