import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { toFixedNumber } from "../../util/utils";
import style from "./LineGraph.module.scss";

function LineGraph({ data, handleLineChart, lineValue, xAxis, yAxis, type }) {
  const CustomTooltip = (props) => {
    if (props.active) {
      let subText;
      if (props.payload[0]?.payload?.message) {
        subText = props.payload[0]?.payload?.message;
      } else {
        if (type === "Grade") {
          subText =
            props.payload[0]?.payload?.grade === "0"
              ? "-"
              : props.payload[0]?.payload?.grade +
                (props.payload[0]?.payload?.rating?.toFixed(2)
                  ? ` (${props.payload[0]?.payload?.rating?.toFixed(2)})`
                  : "");
        } else {
          subText = props.payload[0]?.payload?.rating ? toFixedNumber(props.payload[0]?.payload?.rating, 2) : "-";
        }
      }
      return (
        <div className={style.tooltip_wrapper}>
          <div className={style.name}>{props.payload[0]?.payload?.cycle}</div>
          <div className={style.rating}>{subText}</div>
        </div>
      );
    }
    return "";
  };
  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    const display = (rating) => {
      if (type === "Grade") {
        if (rating === 1) {
          return "C";
        } else if (rating === 2) {
          return "B";
        } else if (rating === 3) {
          return "B+";
        } else if (rating === 4) {
          return "A";
        } else if (rating === 5) {
          return "A+";
        } else return "0";
      } else {
        return rating;
      }
    };
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={5} fill="#666" className="customized-axis-tick-text" textAnchor="end">
          {display(payload.value)}
        </text>
      </g>
    );
  };
  return (
    <>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          width={500}
          height={200}
          data={data}
          syncId="anyId"
          cursor="pointer"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          onClick={(e) =>
            e?.activePayload[0]?.payload.cycleId &&
            e?.activePayload[0]?.payload.canRedirect &&
            handleLineChart(e?.activePayload[0]?.payload.cycleId)
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxis} />
          <YAxis
            dataKey={yAxis}
            type={"number"}
            ticks={[0, 1, 2, 3, 4, 5]}
            domain={[0, 5]}
            tick={<CustomizedAxisTick />}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line type="monotone" dataKey={lineValue} stroke="#8884d8" fill="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

export default LineGraph;
