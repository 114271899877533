import React, { useState, useEffect } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "store/actions/index";
import { bannerNewText, bannerUrl, datesText, placeHolder } from "const/constValue";
import { moduleView } from "util/utils";
import storage from "util/storage";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import ReviewerProfile from "components/reviewer-profile/reviewer-profile";
import MonthlyCheckInPending from "components/MonthlyCheckInPending/MonthlyCheckInPending";
import TabEngagement from "components/TabEngagement/TabEngagement";
import UserProfile from "components/user_profile/user_profile";
import WinChallenges from "components/WinsChallenges/WinsChallenges";
import InfoBannerContact from "components/InfoContactBanner/InfoBannerInfo";
import TabHighlights from "components/TabHighlights/TabHighlights";
import EmployeeListModal from "components/EmployeeListPopup/EmployeeListModal";
import style from "./home.module.scss";

const TabEventKeys = {
  ENGAGEMENTS: "Engagements",
  WIN_CHALLENGE: "Wins & Challenges",
  HIGHLIGHTS: "Highlights",
  PENDING_ACTIONS: "Pending Actions",
};

export default function Home(props) {
  const loggedInUser = storage.getUser();
  const employeeId = loggedInUser?.employeeId;
  const employeeIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  const isManager = moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.MY_TEAM);
  const urlHistory = useHistory();
  const dispatch = useDispatch();
  const [filterSurveyDataMonth, SetFilterSurveyDataMonth] = useState(6);
  const [showPopup, setShowPopup] = useState(false);
  const [modalData, setModalData] = useState();
  const [defaultKey, setDefaultKey] = useState(TabEventKeys.ENGAGEMENTS);

  const history = useSelector((state) => state.madeMyDay.allAppreciateHistory);
  const { filterSurveyData, checkinStatusForEmployee, managerCheckinStatusForEmployee, isLoading } = useSelector(
    (state) => state.myHistory
    );
  const { reviewer, streak, helpToGrowCommentList } = useSelector((state) => state.checkIn);
  const { countDurationStatusEmployee, countDurationStatusManager } = useSelector((state) => state.monthlyCheckinFeedback);
  const {
    unassignedObjectiveEmployees,
    pendingCheckinReview,
    checkinPendingFromEmployees,
    currentAndPreviousCheckin,
    pendingOneOnOnes,
    previousOneOnOnes,
    upcomingOneOnOnes,
    missedOneOnOnes,
    pendingRpmAssessment,
    pendingSelfAssessment,
    pendingRvmAssessment,
    pendingDeallocationFeeedBack,
    pendingObjectiveApproval,
    pendingWeeklyCheckin,
  } = useSelector((state) => state.managerDashboard);

  const user = loggedInUser
    ? {
        employeeId: loggedInUser?.employeeId,
        employeeInitial: loggedInUser?.empInitial,
        displayName: loggedInUser?.displayName,
        designation: loggedInUser?.designation,
        department: loggedInUser?.department,
        dispArr: loggedInUser?.displayPicture,
        band: loggedInUser?.band,
        firstName: loggedInUser?.fname,
        totalExp: loggedInUser?.totalExp,
        parentGroupName: loggedInUser?.parentGroupName,
        expBeforeJoining: loggedInUser?.expBeforeJoining,
        isExp: loggedInUser?.isExp,
        email: loggedInUser?.email,
        location: loggedInUser?.location,
        country: loggedInUser?.country,
      }
    : null;

  useEffect(() => {
    if (isManager) {
      dispatch(actions.getTeamsCheckin(1));
      dispatch(actions.getPendingApprovalObjective());
      dispatch(actions.getUnassignedObjective());
      dispatch(actions.getCurrentAndPreviousCheckin());
      dispatch({ type: "CLEAR_ONE_ON_ONE_FILTER_DATA" });
      dispatch(actions.getCountDurationStatusEmployeeAndManager("manager"));
    }
    dispatch(actions.getCountDurationStatusEmployeeAndManager("employee"));
    dispatch(actions.getManagerPendingTodo());
    dispatch(actions.getPendingOneOnOne(1));
    dispatch(actions.getEmployeePendingTodo());
    dispatch(actions.GetReviewer(employeeIds));
    dispatch(actions.GetAllGrowComments());
    setDefaultKey(TabEventKeys.ENGAGEMENTS);
    dispatch(actions.GetFilterSurveyHistory({ ...employeeIds, filterByMonth: 6 }));
    dispatch(actions.GetStreak(employeeIds));
    dispatch(
      actions.ViewAllHistory({
        ...employeeIds,
        viewAll: "viewAll",
        filterByMonth: 6,
      }) //by default show last 6 months
    );
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (filterSurveyData) {
      if (props.location.state) {
        switch (props.location.state.type) {
          case "APPRECIATE":
            setDefaultKey(TabEventKeys.ENGAGEMENTS);
            document.getElementById("hideYouMadeMyDay").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            break;
          case "WIN":
            setDefaultKey(TabEventKeys.WIN_CHALLENGE);
            document.getElementById("WIN_BUCKET").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            break;
          case "CHALLENGE":
            setDefaultKey(TabEventKeys.WIN_CHALLENGE);
            document.getElementById("CHALLENGE_BUCKET").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            break;
          case "COLLECTIVE":
            setDefaultKey(TabEventKeys.WIN_CHALLENGE);
            document.getElementById("COLLECTIVE_BUCKET").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            break;
          default:
            break;
        }
        props.location.state = "";
      }
    }
  }, [props.location.state]);

  const handleSurveyDataFilter = (select) => {
    SetFilterSurveyDataMonth(select.value);
    dispatch(
      actions.GetFilterSurveyHistory({
        ...employeeIds,
        filterByMonth: select.value,
      })
    );
  };

  const handleAppreciateFilter = (select) => {
    dispatch(
      actions.ViewAllHistory({
        ...employeeIds,
        viewAll: "viewAll",
        filterByMonth: select.value,
      })
    );
  };

  const displayAll = (type) => {
    setShowPopup(true);
    if (type === "UNASSIGNED") {
      setModalData({
        modalHeading: "Team’s Unassigned Objectives",
        modalDescription: "Assign objectives to following team members",
        employees: unassignedObjectiveEmployees.filter((v, i) => i >= 13),
        type: "UNASSIGNED",
      });
    } else if (type === "PREVIOUS_ONE_ON_ONE") {
      setModalData({
        modalHeading: "Previous 1-on-1s",
        modalDescription: "Click on a member name to go to 1-on-1",
        employees: previousOneOnOnes.oneOnOneAgenda
          .map((v) => {
            return {
              ...v.employee,
              id: v.oneOnOneAgendaId,
            };
          })
          .filter((v, i) => i > 13),
        type: "PREVIOUS_ONE_ON_ONE",
      });
    } else if (type === "PENDING_ONE_ON_ONE") {
      setModalData({
        modalHeading: "Pending 1-on-1s",
        modalDescription: "Click on a member name to go to 1-on-1",
        employees: pendingOneOnOnes.employees.filter((v, i) => i >= 13),
        type: "PENDING_ONE_ON_ONE",
      });
    } else if (type === "UPCOMING_ONE_ON_ONE") {
      setModalData({
        modalHeading: "Upcoming 1-on-1s",
        modalDescription: "Click on a member name to go to 1-on-1",
        employees: upcomingOneOnOnes.oneOnOneAgenda
          .map((v) => {
            return {
              ...v.employee,
              id: v.oneOnOneAgendaId,
            };
          })
          .filter((v, i) => i >= 13),
        type: "UPCOMING_ONE_ON_ONE",
      });
    } else if (type === "MISSED_ONE_ON_ONE") {
      setModalData({
        modalHeading: "Upcoming 1-on-1s",
        modalDescription: "Click on a member name to go to 1-on-1",
        employees: missedOneOnOnes.oneOnOneAgenda
          .map((v) => {
            return {
              ...v.employee,
              id: v.oneOnOneAgendaId,
            };
          })
          .filter((v, i) => i >= 13),
        type: "MISSED_ONE_ON_ONE",
      });
    } else if (type === "REPORTING") {
      setModalData({
        modalHeading: "Reporting Assessment",
        modalDescription: "Click on the member name to access pending Assessment",
        employees: pendingRpmAssessment?.rpmAssessment
          .map((v) => {
            return {
              ...v.employeeData,
              id: pendingRpmAssessment.cycleId,
            };
          })
          .filter((v, i) => i >= 2),
        type: "REPORTING",
      });
    } else if (type === "REVIEWING") {
      setModalData({
        modalHeading: "Reviewing Assessment",
        modalDescription: "Click on the member name to access pending Assessment",
        employees: pendingRvmAssessment?.rvmAssessment
          .map((v) => {
            return {
              ...v.employeeData,
              id: pendingRvmAssessment.cycleId,
            };
          })
          .filter((v, i) => i >= 2),
        type: "REVIEWING",
      });
    } else if (type === "DEALLOCATION") {
      setModalData({
        modalHeading: "Deallocation Feedback",
        modalDescription: "Click on the member name for giving feedback which are pending",
        employees: pendingDeallocationFeeedBack?.projectDeallocation
          .map((v) => {
            return {
              ...v.employee,
              id: v.projectDeAllocationId,
            };
          })
          .filter((v, i) => i >= 2),
        type: "DEALLOCATION",
      });
    } else if (type === "OBJECTIVE_APPROVAL") {
      setModalData({
        modalHeading: "Objective Approval",
        modalDescription: "Click on the member name for approve Objective",
        employees: pendingObjectiveApproval?.employeeObjectives
          .map((v) => {
            return {
              ...v.employeeData,
              id: v.userObjectiveId,
            };
          })
          .filter((v, i) => i >= 2),
        type: "OBJECTIVE_APPROVAL",
      });
    }
  };

  const handleEmployeeClick = (employeeIds, type, id) => {
    if (type === "UNASSIGNED") {
      urlHistory.push({
        pathname: `/objectives/all-objectives`,
        state: {
          employeeId: employeeIds?.employeeId,
        },
      });
    } else if (type === "PENDING_ONE_ON_ONE") {
      if (employeeIds.isManager) {
        urlHistory.push(
          `/okrprogress/one-on-one/create/manager?empId=${employeeIds?.employeeId}&pgId=${employeeIds?.parentGroupId}&sgId=${employeeIds?.subGroupId}`
        );
      } else {
        urlHistory.push(
          `/okrprogress/one-on-one/create/team?empId=${employeeIds?.employeeId}&pgId=${employeeIds?.parentGroupId}&sgId=${employeeIds?.subGroupId}`
        );
      }
    } else if (type === "UPCOMING_ONE_ON_ONE") {
      urlHistory.push({
        pathname: `/okrprogress/one-on-one/current/${id}`,
        state: { isManager: false },
      });
    } else if (type === "MISSED_ONE_ON_ONE") {
      urlHistory.push({
        pathname: `/okrprogress/one-on-one/previous/${id}`,
        state: { isManager: false },
      });
    } else if (type === "PREVIOUS_ONE_ON_ONE") {
      urlHistory.push({
        pathname: `/okrprogress/one-on-one/previous/${id}`,
        state: { isManager: false },
      });
    } else if (type === "REPORTING") {
      urlHistory.push(
        `/performance/reporting-assessment/reporting-member/${id}?empId=${employeeIds?.employeeId}&pgId=${employeeIds?.parentGroupId}&sgId=${employeeIds?.subGroupId}`
      );
    } else if (type === "REVIEWING") {
      urlHistory.push(
        `/performance/reviewing-assessment/reviewing-member/${id}?empId=${employeeIds?.employeeId}&pgId=${employeeIds?.parentGroupId}&sgId=${employeeIds?.subGroupId}`
      );
    } else if (type === "DEALLOCATION") {
      urlHistory.push(
        `performance/deallocation-feedback/${id}?empId=${employeeIds?.employeeId}&pgId=${employeeIds?.parentGroupId}&sgId=${employeeIds?.subGroupId}`
      );
    } else if (type === "OBJECTIVE_APPROVAL") {
      urlHistory.push(
        `objectives/all-objectives/assigned-objective/${id}?empId=${employeeIds?.employeeId}&pgId=${employeeIds?.parentGroupId}&sgId=${employeeIds?.subGroupId}`
      );
    }
  };

  const handleTeamsChartOptions = (option) => {
    dispatch(actions.getTeamsCheckin(option.value));
  };

  const handleOneOnOneMonths = (month) => {
    dispatch(actions.getPendingOneOnOne(month));
  };

  // let bannerText = storage.get("supportContactDetails") ? [storage.get("supportContactDetails")] : null;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <InfoBannerContact heading={bannerUrl} text={bannerNewText} />
      <div className="gap30"></div>

      <Container>
        <Row>
          <Col lg={8}>
            {user && <UserProfile user={user} />}
            <div
              className={["custom-tabs", "custom-tabs-full-width", "tab-content-dashboard", style.home_tabs].join(" ")}
            >
              <Tabs activeKey={defaultKey} onSelect={(key) => setDefaultKey(key)} id="Tabs-navigation">
                <Tab eventKey={TabEventKeys.ENGAGEMENTS} className="p30" title="Engagements">
                  <TabEngagement
                    history={history}
                    user={user}
                    helpToGrowCommentList={helpToGrowCommentList}
                    surveyData={filterSurveyData}
                    streak={streak}
                    handleSurveyDataFilter={handleSurveyDataFilter}
                    filterSurveyDataMonth={filterSurveyDataMonth}
                    handleAppreciateFilter={handleAppreciateFilter}
                    unassignedObjectiveEmployees={unassignedObjectiveEmployees}
                    pendingCheckinReview={pendingCheckinReview}
                    checkinPendingFromEmployees={checkinPendingFromEmployees}
                    displayAll={displayAll}
                    handleEmployeeClick={handleEmployeeClick}
                    currentAndPreviousCheckin={currentAndPreviousCheckin}
                    pendingOneOnOnes={pendingOneOnOnes}
                    previousOneOnOnes={previousOneOnOnes}
                    upcomingOneOnOnes={upcomingOneOnOnes}
                    missedOneOnOnes={missedOneOnOnes}
                    handleTeamsChartOptions={handleTeamsChartOptions}
                    checkinStatusForEmployee={checkinStatusForEmployee}
                    managerCheckinStatusForEmployee={managerCheckinStatusForEmployee}
                    handleOneOnOneMonths={handleOneOnOneMonths}
                  />
                </Tab>
                <Tab eventKey={TabEventKeys.WIN_CHALLENGE} title="Wins &amp; Challenges">
                  <WinChallenges employeeIds={employeeIds} />
                </Tab>
                <Tab eventKey={TabEventKeys.HIGHLIGHTS} className="p30" title="Highlights">
                  <TabHighlights
                    pendingRpmAssessment={pendingRpmAssessment}
                    pendingSelfAssessment={pendingSelfAssessment}
                    pendingRvmAssessment={pendingRvmAssessment}
                    pendingDeallocationFeeedBack={pendingDeallocationFeeedBack}
                    pendingObjectiveApproval={pendingObjectiveApproval}
                    history={history}
                    pendingWeeklyCheckin={pendingWeeklyCheckin}
                    employeeId={loggedInUser?.employeeId}
                    employeeIds={loggedInUser}
                    displayAll={displayAll}
                    handleEmployeeClick={handleEmployeeClick}
                    isPage="Home"
                  />
                </Tab>
              </Tabs>
            </div>
            {showPopup && (
              <EmployeeListModal
                isExpandVisible={false}
                searchPlaceholder={placeHolder.SEARCH_TEAM}
                employees={modalData.employees}
                openPopup={showPopup}
                closePopup={() => {
                  setShowPopup(false);
                  setModalData();
                }}
                modalHeading={modalData.modalHeading}
                modalDescription={modalData.modalDescription}
                departmentShow={false}
                handleEmployeeClick={(employeeData, id) => handleEmployeeClick(employeeData, modalData.type, id)}
                plusIcon={false}
              />
            )}
          </Col>
          <Col lg={4}>
            {countDurationStatusManager !== null && isManager && <MonthlyCheckInPending isEmployee={false} countDurationStatusData={countDurationStatusManager} />}
            {countDurationStatusEmployee !== null && <MonthlyCheckInPending isEmployee={true} countDurationStatusData={countDurationStatusEmployee} />}
            {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
