import React, { useState, useEffect } from "react";
import style from "./AssignObjectives.module.scss";
import { getRandomColor } from "../../../util/color";

export default function SelectedInfo(props) {
  const [selectedColor, setSelectedColor] = useState(null);

  useEffect(() => {
    setSelectedColor(getRandomColor());
  }, []);

  const { dispArr, empInitial, empName } = props.assignee;
  return (
    <>
      <div className={style.avatar}>
        <div className={style.avtar_item} style={{ backgroundColor: selectedColor }}>
          {dispArr ? <img src={dispArr} alt="displayPicture" /> : empInitial}
        </div>
        <div className={style.tooltip}>{empName}</div>
      </div>
    </>
  );
}
