import React from "react";
import { ReactSVG } from "react-svg";
import { backGroundColor } from "../../const/constValue";
import style from "./EmployeeList.module.scss";

function EmployeeListRow({
  displayPicture,
  index,
  empName,
  department,
  designation,
  secondColum,
  employeeData,
  thirdColum,
  handleEmployeeClick,
  id,
  toggleExpander,
  toggleIcon,
}) {
  return (
    <tr className={style.employee_list}>
      <td>
        <div className={style.user_profile}>
          {toggleIcon && (
            <div className={style.plus_space} onClick={toggleExpander}>
              <ReactSVG src={toggleIcon} />
            </div>
          )}
          <div className={style.user_img} style={{ backgroundColor: backGroundColor[index % backGroundColor.length] }}>
            {displayPicture?.length > 2 ? <img src={displayPicture} alt="pic" /> : displayPicture}
          </div>
          <div className={style.user_name}>
            <h1 onClick={() => handleEmployeeClick(employeeData, id)}>{empName}</h1>
            <h2>
              {department} {department ? "|" : ""} {designation}
            </h2>
          </div>
        </div>
      </td>
      {secondColum && <td className={style.table_data}>{secondColum}</td>}
      {thirdColum && <td className={style.table_data}>{thirdColum}</td>}
    </tr>
  );
}

export default EmployeeListRow;
