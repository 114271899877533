import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import * as actions from "store/actions/index";
import { scrollToTop, mergeArray, getEmployeeIds, errorToast, successToast } from "util/general";
import { getRandomColor } from "util/color";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import Questions from "components/questions/questions";
import ReviewerProfile from "components/reviewer-profile/reviewer-profile";
import Savebox from "components/save-box/savebox";
import Userbox from "components/UserBox/UserBox";
import AuthContext from "context/authContext";
import PreviousCheckIns from "shared/PreviousCheckIns";
import Waitingforsubmition from "components/WaitingforSubmition/Waitingforsubmition";
import config from "util/config";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import { menuText } from "const/constValue";

const updatedDate = (date) => {
  const updatedDate = new Date(date);
  return updatedDate.toDateString() + " " + updatedDate.toLocaleTimeString();
};

export default function TeamHistory(props) {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const loggedInUser = storage.getUser();
  const { user, isLoading, data, selectedRating, moodoMeter, isReviewed, surveyData, employee } = useSelector(
    (state) => state.teamHistory
  );

  const { submitable } = useSelector((state) => state.checkIn);

  const [selectedColor, setSelectedColor] = useState("");
  const [currentSurveyCycle, setCurrentSurveyCycle] = useState(0);

  const surveyCycleId = useSelector((state) => state.questions.surveyCycleId);
  const [surveyCyclesIdAfterReviewed, setSurveyCycleIdAfterReviewed] = useState(null);
  const [prevSurveyCycle, setPrevSurveyCycle] = useState(surveyCycleId);
  const employeeIds = getEmployeeIds(props.match.params.id);

  useEffect(() => setSelectedColor(getRandomColor()), []);

  // Component Did Mount
  useEffect(() => {
    getReviewer();
    getSurveyHistory();
    getCurrentCycle();
  }, []);

  useEffect(() => {
    if (props.location.state && props.location.state.cycleId) {
      let surveyCycleId = props.location.state.cycleId;
      setCurrentSurveyCycle(surveyCycleId);
      setPrevSurveyCycle(surveyCycleId);
      dispatch(actions.ViewCycle({ ...employeeIds, surveyCycleId }));
      scrollToTop(500);
    }
  }, [props.location.state]);

  useEffect(() => {
    if (props.location.state)
      if (props.location.state.surveyCycleId) {
        let surveyCycleId = props.location.state.surveyCycleId;
        surveyClick(surveyCycleId, "");
        setCurrentSurveyCycle(surveyCycleId);
        props.location.state = "";
      }
  }, [props.location.state]);

  const surveyClick = (surveyCycleId) => {
    if (surveyCycleId !== prevSurveyCycle) {
      dispatch(actions.ViewCycle({ ...employeeIds, surveyCycleId }));
      setPrevSurveyCycle(surveyCycleId);
    }
  };

  //display reviewer slider from current check-in data
  let initialManagerList = [];
  let allocationManagers = [];
  let managersUnique = [];
  let reviewers = {
    managersList: [],
  };

  if (data && data.reviewerList?.managersList?.length > 0) {
    reviewers = data.reviewerList;
  } else {
    if (data) {
      initialManagerList = mergeArray(data.rpm, data.rvm, "employeeId");
      if (data && data.projectAllocation) {
        data.projectAllocation.map((value) => {
          if (value.projectManager) {
            allocationManagers.push(...value.projectManager);
          }

          if (value.projectDeliveryManager) {
            allocationManagers.push(...value.projectDeliveryManager);
          }

          if (value.projectReportingManager) {
            allocationManagers.push(...value.projectReportingManager);
          }

          if (value.projectReviewingManager) {
            allocationManagers.push(...value.projectReviewingManager);
          }
        });
      }

      // eslint-disable-next-line no-undef
      managersUnique = allocationManagers.filter(
        (
          (set) => (f) =>
            !set.has(f.employeeId) && set.add(f.employeeId)
        )(
          // eslint-disable-next-line no-undef
          new Set()
        )
      );

      reviewers.managersList = mergeArray(initialManagerList, managersUnique, "employeeId");
    }
  }

  const onReviewed = (e, surveyCyclesId) => {
    if (submitable && submitable.length) {
      errorToast("Please fill unfinished boxes.");
      return;
    }

    setSurveyCycleIdAfterReviewed(surveyCyclesId);
    const response = {
      employeeId: {
        employeeId: user.id,
        parentGroupId: user.parentGroupId,
        subGroupId: user.subGroupId,
      },
      reviewedBy: {
        employeeId: employee.id,
        empName: employee.name,
        displayPicture: employee.image || employee.empInitial,
        designation: employee.designation,
        parentGroupId: employee.parentGroupId,
        subGroupId: employee.subGroupId,
      },
      surveyCycleId,
    };
    dispatch(actions.GetReviewed(response));
    scrollToTop(500);
    successToast("Mark as reviewed successfully!");
  };

  const getReviewer = () => {
    dispatch(actions.GetMemberReviewer(employeeIds));
  };

  const getSurveyHistory = () => {
    let params = {
      employeeId: employeeIds?.employeeId,
      employeeParentGroupId: employeeIds?.parentGroupId,
      employeeSubGroupId: employeeIds?.subGroupId,
      managerId: loggedInUser?.employeeId,
      managerParentGroupId: loggedInUser?.parentGroupId,
      managerSubGroupId: loggedInUser?.subGroupId,
    };
    dispatch(actions.GetSurveyHistory(context.state.user, params));
  };

  const getCurrentCycle = () => {
    let params = {
      employeeId: employeeIds?.employeeId,
      employeeParentGroupId: employeeIds?.parentGroupId,
      employeeSubGroupId: employeeIds?.subGroupId,
      managerId: loggedInUser?.employeeId,
      managerParentGroupId: loggedInUser?.parentGroupId,
      managerSubGroupId: loggedInUser?.subGroupId,
    };
    dispatch(actions.GetCurrentCycle(params));
  };

  const managerCommentHandle = (comment, questionData, questionTemplateId) => {
    if (comment.trim().length < 1) return true;
    const commentToSend = {
      surveyCycleId: surveyCycleId,
      questionTemplateId: questionTemplateId,
      questions: {
        managerComments: {
          comment: comment.trim(),
          commentedBy: {
            employeeId: employee.id,
            empName: employee.name,
            parentGroupId: employee.parentGroupId,
            subGroupId: employee.subGroupId,
            displayPicture: employee.image ? employee.image : employee.empInitial,
          },
        },
        questionId: questionData.questionId,
      },
    };
    dispatch(actions.updateCommentInCheckinTemplate(commentToSend, employeeIds));
  };

  const deleteComment = (questionData, commentId, isPrivate, isReply) => {
    const comment = {
      questionId: questionData.questionId,
      commentId: commentId,
      surveyCycleId: surveyCycleId,
      private: isPrivate,
      reply: isReply,
    };
    dispatch(actions.deleteCommentInCheckinTemplate(comment, employeeIds));
  };
  // const reviewDays = reviewedDate != null ? Math.abs(moment(reviewedDate).diff({}, "days")) : 1;

  const checkinQuestionData = {
    managerCommentHandle: managerCommentHandle,
    employee,
    readOnly: false,
    defaultOpen: false,
    viewCommentIcon: true,
  };

  if (!user) {
    return <Spinner />;
  }
  let teamBox = null;

  if (data) {
    data.status === "PENDING_FROM_MEMBER"
      ? (teamBox = (
          <Col lg={8}>
            <Waitingforsubmition
              pathName={
                "/okrprogress/my-team/member-okrprogress/dashboard/" +
                user.id +
                config.employeeIdSplit +
                user.parentGroupId +
                config.employeeIdSplit +
                user.subGroupId
              }
              image={user?.image || user.empInitial || user.employeeInitial}
              name={user.name}
              designation={user.designation}
              department={user.department}
              date={data.endDate}
            />
          </Col>
        ))
      : (teamBox = (
          <Col lg={8}>
            <Userbox
              isPage="myteam"
              managerView={true}
              user={user}
              employee={employee}
              reviewer={reviewers}
              data={data}
              displayDraft={data.updatedDate ? true : false}
              updatedDate={updatedDate(data.updatedDate)}
              selectedRating={selectedRating}
              moodoMeter={moodoMeter}
              isReviewed={isReviewed}
              childEmployee={props.location.state && props.location.state.managerId}
              selectedColor={selectedColor}
            />
            {/* <EmployeeObjectives  employeeId={props.match.params.id} edit={true}/> */}
            <Questions
            isPastHistory={true}
              isPage="myteam"
              selectedColor={selectedColor}
              managerView={true}
              childEmployee={props.location.state && props.location.state.managerId}
              checkinQuestionData={checkinQuestionData}
              managerCommentHandle={managerCommentHandle}
              deleteComment={deleteComment}
            />
            <Savebox
              managerView={true}
              draftSavedDate={context.draftSavedDate}
              updatedDate={data.updatedDate}
              submittedDate={data.submitedDate}
              reviewedDate={data.reviewedDate}
              surveyCycleId={surveyCycleId}
              status={data.status}
              onReviewed={onReviewed}
              childEmployee={props.location.state && props.location.state.managerId}
              reviewedBy={data.reviewedBy}
            />
          </Col>
        ));
  }

  return (
    <div>
      <SubNavigation type="OKR Progress" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">{menuText.CHECK_IN}</h1>
            <span className="st-breadcrumb">
              {menuText.CHECK_IN}
              <span>
                <Link to={routePath.MY_TEAM}>{menuText.MY_TEAM}</Link>
              </span>
              <span>{user.name}</span>
            </span>
          </Col>
          <Col>
            {surveyData && (
              <PreviousCheckIns
               isPastHistory={false}
                currentSurveyCycle={currentSurveyCycle}
                isReviewed={isReviewed}
                surveyClick={surveyClick}
                data={surveyData}
                surveyCyclesIdAfterReviewed={surveyCyclesIdAfterReviewed}
              />
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        {isLoading ? <Spinner /> : null}
        <Row>
          {teamBox}
          <Col lg={4}>
            {reviewers?.managersList?.length > 0 && <ReviewerProfile managerList={reviewers.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
