import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import style from "./ReviewCycleListDetail.module.scss";
import * as actions from "store/actions/index";
import { errorToast, requiredFieldMissing, successToast } from "util/general";
import { toFixedNumber } from "util/utils";
import { noData } from "const/constValue";
import storage from "util/storage";
import MemberProfileObjective from "../../Performance/CycleMemberProfile/MemberProfileObjecive/MemberProfileObjecive";
import ReviewerProfile from "components/reviewer-profile/reviewer-profile";
import NoDataFound from "../../NodataFound/NoDataFound";
import GlobalReadOnlyComment from "../../Performance/GlobalComment/GlobalReadOnlyComment";
import GradeInfoCard from "../../GradeInfoCard/GradeInfoCard";
import Assessment from "../../Performance/CycleMemberProfile/SelfAssessmentRating/Assessment";
import Acknowledgement from "../../Acknowledgement/Acknowledgement";
import BoxHeading from "shared/Box/BoxHeading";
import Spinner from "shared/Spinner/Spinner";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function ReviewCycleListDetail(props) {
  const loggedInUser = storage.getUser();
  const [comment, setComment] = useState();
  const [acknowledgementChecked, setAcknowledgementChecked] = useState(false);
  const employeeIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  const cycleId = props.match.params.id;
  const dispatch = useDispatch();
  const {
    managerList,
    getUserPrivateAssessmentData,
    isUserPrivateAssessmentLoading,
    globalRVMComment,
    globalRPMComment,
    globalSELFComment,
    managerWiseGrade,
    isFileLoading,
    acknowledgementResponse,
    quaterlyCycleData
  } = useSelector((state) => state.performance);
  const { reviewer } = useSelector((state) => state.checkIn);

  useEffect(() => {
    dispatch(actions.GetUserPrivateAssessmentData(cycleId));
    dispatch(actions.GetAssessmentGlobalComment(employeeIds, cycleId));
    dispatch(actions.GetRPMAssessmentGlobalComment(employeeIds, cycleId));
    dispatch(actions.GetSelfAssessmentGlobalComment(employeeIds, cycleId));
    dispatch(actions.GetReviewer(employeeIds));
    dispatch(actions.getQuarterlyCycle(cycleId));
  }, [dispatch, cycleId]);

  useEffect(() => {
    if (acknowledgementResponse) {
      if (acknowledgementResponse.success) successToast(acknowledgementResponse.message);
      else errorToast(acknowledgementResponse.message);

      dispatch({ type: "CLEAR_PRIVATE_ASSESSMENT_FILE_RESPONSE" });
    }
  }, [acknowledgementResponse]);
  const gradeOrRatingOrBoth = getUserPrivateAssessmentData?.gradeOrRatingOrBoth;
  const selfQuestionTemplate = getUserPrivateAssessmentData?.selfQuestionTemplate;
  const selfPrivateAssessmentId = getUserPrivateAssessmentData?.selfPrivateAssessmentId;
  const isHideGradeInfoCard = quaterlyCycleData?.showRatingDropDown;
  let selfAssessmentGrade = getUserPrivateAssessmentData?.selfAssessmentGrade;
  const acknowledgement = getUserPrivateAssessmentData?.gradeAcknowledgement;
  let selfAssessmentAvgRating =
    getUserPrivateAssessmentData?.selfAssessmentAvgRating ??
    toFixedNumber(getUserPrivateAssessmentData?.selfAssessmentAvgRating);
  let overAllAggregateText = "Aggregate Grade";
  if (gradeOrRatingOrBoth === "BOTH") {
    selfAssessmentGrade = getUserPrivateAssessmentData?.selfAssessmentGrade
      ? getUserPrivateAssessmentData?.selfAssessmentGrade + "/" + toFixedNumber(selfAssessmentAvgRating, 2)
      : "";
    selfAssessmentAvgRating = selfAssessmentGrade;
    overAllAggregateText = "Aggregate Progress";
  } else if (gradeOrRatingOrBoth === "RATING") {
    selfAssessmentGrade = toFixedNumber(getUserPrivateAssessmentData.selfAssessmentAvgRating, 2);
    overAllAggregateText = "Aggregate Rating";
  }
  const selAssessmentRPMGrade =
    getUserPrivateAssessmentData?.rpmRatings?.map((manager) => {
      return {
        ...manager,
        type: "RPM",
      };
    }) || [];
  const selAssessmentRVMGrade =
    getUserPrivateAssessmentData?.rvmRatings?.map((manager) => {
      return {
        ...manager,
        type: "RVM",
      };
    }) || [];

  let questionTemplates = [];
  if (selfQuestionTemplate) {
    selfQuestionTemplate.name = "Self Assessment";
    selfQuestionTemplate && questionTemplates.push(selfQuestionTemplate);
    selfQuestionTemplate.privateAssessmentId = selfPrivateAssessmentId;
    selfQuestionTemplate.type = "SELF";
    selfQuestionTemplate.nameDOM = <div>Self Assessment</div>;
  }

  const handleAcknowledgement = () => {
    let body = {
      comment: comment,
      // comment: acknowledgeComment,
      isAcknowledged: acknowledgementChecked,
      quarterlyCycleId: cycleId,
    };
    if (comment) {
      dispatch(actions.sendAcknowledgement(body));
    } else requiredFieldMissing();
  };

  const downloadFile = (fileId, questionId, questionTemplateId, privateAssessmentId, type) => {
    let params = {
      fileId,
      questionId,
      privateAssessmentId,
      questionTemplateId,
      assessmentType: type,
    };
    dispatch(actions.DownloadFilePrivateAssessment(params));
  };

    const downloadFileGlobalFeedback = (id, privateAssessment_Id) => {
    const params = {
      fileId: id,
      privateAssessmentId: privateAssessment_Id !== undefined && typeof privateAssessment_Id !== 'object' ? privateAssessment_Id : selfPrivateAssessmentId ,
      // privateAssessmentId: privateAssessmentId,
    };
    dispatch(actions.DownloadFileGlobalFeedback(params));
  };

  if (isUserPrivateAssessmentLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.cycleListWrapper}>
      <SubNavigation type="Performance" />
      {isFileLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0">Performance</h1>
            <span className="st-breadcrumb">
              <Link to="/performance/dashboard">Dashboard</Link>
              <span>Review</span>
              <span>{getUserPrivateAssessmentData.cycleName}</span>
            </span>
          </Col>
          {getUserPrivateAssessmentData?.cycleName && (
            <Col className="col-12">
              <BoxHeading
                heading={getUserPrivateAssessmentData.cycleName}
                description={getUserPrivateAssessmentData.period}
              />
            </Col>
          )}
        </Row>
        {getUserPrivateAssessmentData.objectiveList && gradeOrRatingOrBoth !== "RATING" && isHideGradeInfoCard && (
          <GradeInfoCard title="Performance Grade" />
        )}
        {getUserPrivateAssessmentData?.objectiveList === null && selfQuestionTemplate === null && (
          <div className="box pb-0">
            <div className="box-heading-wrapper border-bottom-0">
              <div className="box-heading">
                <h2>
                  Aggregate Grade/Rating -{" "}
                  {gradeOrRatingOrBoth === "BOTH"
                    ? selfAssessmentGrade / selfAssessmentAvgRating
                    : gradeOrRatingOrBoth === "GRADE"
                    ? selfAssessmentGrade
                    : selfAssessmentAvgRating}{" "}
                </h2>
              </div>
            </div>
          </div>
        )}
        <Row>
          <Col lg={8}>
            {getUserPrivateAssessmentData?.objectiveList ||
            globalRVMComment?.length > 0 ||
            globalRPMComment?.length > 0 ||
            globalSELFComment?.length > 0 ||
            questionTemplates?.length > 0 ? (
              <>
                {getUserPrivateAssessmentData.objectiveList && (
                  <MemberProfileObjective
                    questionTemplateErrors={[]}
                    allManagerDetails={[]}
                    isSubmitted={true}
                    isPage="EMPLOYEE"
                    rowData={getUserPrivateAssessmentData}
                    managerList={managerList}
                    managerWiseGrade={managerWiseGrade}
                    loggedInUser={loggedInUser}
                  />
                )}
                {questionTemplates?.length > 0 && (
                  <Assessment
                    isPage="USER"
                    selfAssessmentAvgGrade={selfAssessmentGrade}
                    overAllAggregateText={overAllAggregateText}
                    selfAssessmentManagerViseGrade={selAssessmentRPMGrade.concat(selAssessmentRVMGrade)}
                    selfAssessmentAvgRating={selfAssessmentAvgRating}
                    isRatingDisable={!isHideGradeInfoCard}
                    saveQuestionAnswer={() => {}}
                    downloadFile={downloadFile}
                    questionTemplateErrors={{}}
                    isSubmitted={true}
                    uploadFile={() => {}}
                    deleteFile={() => {}}
                    questionTemplates={questionTemplates}
                    sendManagerComment={() => {}}
                  />
                )}
                <GlobalReadOnlyComment
                  heading="Global Feedback"
                  description="Here is what you want to say to your manager"
                  comment={globalSELFComment}
                  message="Employee haven't shared any view"
                  downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                    downloadFileGlobalFeedback(id, privateAssessmentId)
                  }
                />
                <GlobalReadOnlyComment
                  heading="Reporting Manager's Global Feedback"
                  description="Here is what Reporting manager has given their views"
                  comment={globalRPMComment?.length > 0 ? globalRPMComment.filter((v) => v.status === "Submit") : []}
                  message="Manager haven't shared any view"
                  downloadFileGlobalFeedback={(id, privateAssessmentId) =>
                    downloadFileGlobalFeedback(id, privateAssessmentId)
                  }
                />
                <GlobalReadOnlyComment
                  heading="Reviewing Manager's Global Feedback"
                  description="Here is what Reviewing manager has given their views"
                  comment={globalRVMComment?.length > 0 ? globalRVMComment.filter((v) => v.status === "Submit") : []}
                  message="Manager haven't shared any view"
                  downloadFileGlobalFeedback={(id, privateAssessment_Id) =>
                    downloadFileGlobalFeedback(id, privateAssessment_Id)
                  }
                />
                <Acknowledgement
                  setCheck={(e) => setAcknowledgementChecked(e.target.checked)}
                  check={acknowledgement?.isSubmit ? acknowledgement.isAcknowledged : acknowledgementChecked}
                  handleSubmit={handleAcknowledgement}
                  setComment={(e) => setComment(e.target.value)}
                  comment={acknowledgement?.isSubmit ? acknowledgement.comment : comment}
                  isSubmitted={acknowledgement?.isSubmit}
                  image={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                  empName={loggedInUser?.displayName}
                  commentDate={acknowledgement?.submittedDate}
                />
              </>
            ) : (
              <div className="box">
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap80"></div>
              </div>
            )}
          </Col>
          <Col lg={4}>
            {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
