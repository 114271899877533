import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Button, Col, Accordion, Row, Container, Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./DefineRollBasedSkill.module.scss";
import * as actions from "store/actions/index";
import { IC_INPUT_SEARCH, IC_PENCIL, IC_CLONE, IC_INFO } from "const/imgCost";
import { filterArray } from "util/general";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "../../NodataFound/NoDataFound";
import Pagination from "../../Pagination/Pagination";
import { competencyLevel, noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

export default function DefineRollBasedSkill(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { rolewiseskill, isLoading, size } = useSelector((state) => state.skillMatrix);
  const [roleData, setRoleData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageOfItems, setPageOfItems] = useState([]);
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };
  const [filteredData, setFilteredData] = useState([]);

  // function for inner accordion
  function toggleItem(id) {
    var elem = document.querySelectorAll(".inner-accordion-title");

    for (var i = 0; i < elem.length; i++) {
      if (id === elem[i].parentNode.id) {
        if (elem[i].parentNode.classList.contains("active") === true) {
          elem[i].parentNode.classList.remove("active");
        } else {
          elem[i].parentNode.classList.add("active");
        }
      }
    }
  }

  const navigate = () => {
    history.push(routePath.MMG_ADD_DEFINE_SKILLS);
  };

  useEffect(() => {
    const getRoleBasedSkillList = () => {
      dispatch(actions.getRoleWiseSkillList);
    };
    getRoleBasedSkillList();
  }, [dispatch]);

  useEffect(() => {
    setRoleData(rolewiseskill);
  }, [rolewiseskill]);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  const reset = () => {
    setSearchText("");
  };

  useEffect(() => {
    let skillArray = [];
    if (searchText && searchText.length > 0) {
      if (searchText.startsWith("#")) {
        const queryStr = searchText.substring(1).trim();
        skillArray = rolewiseskill.filter((data) =>
          data.departmentName.toLowerCase().includes(queryStr.toLowerCase().trim())
        );
      } else {
        skillArray = rolewiseskill.filter(
          (data) =>
            data.technicalRoleName.toLowerCase().includes(searchText.toLowerCase().trim()) ||
            (data.roleName && data.roleName.toLowerCase().includes(searchText.toLowerCase().trim()))
        );
      }
    } else {
      skillArray = rolewiseskill;
    }
    setRoleData(skillArray);
  }, [rolewiseskill, searchText]);

  useEffect(() => {
    if (props.location.state != null) {
      setSearchText(props.history.location.state.queryStr);
    }
  }, [props, props.location.state]);

  const edit = (id, param) => {
    if (param === "EDIT") {
      history.push(routePath.MMG_DEFINE_SKILLS + "/" + id, { queryStr: searchText });
    }
    if (param === "CLONE") {
      history.push(routePath.MMG_DEFINE_SKILLS + "/" + id, {
        queryStr: searchText,
        detail: "clone",
      });
    }
  };

  useEffect(() => {
    filteredData.length > 0 ? setRoleData(filteredData) : null ? setRoleData(filteredData) : setRoleData(rolewiseskill);
  }, [filteredData, rolewiseskill]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: "",
    };
    if (roleData && roleData.length > 0) {
      filteredData = filterArray(roleData, filters);
      setFilteredData(filteredData);
    }
    let skillArray = [];
    if (searchText.length > 0) {
      if (searchText.startsWith("#")) {
        const queryStr = searchText.substring(1).trim();
        skillArray = rolewiseskill.filter((data) =>
          data.departmentName.toLowerCase().includes(queryStr.toLowerCase().trim())
        );
      } else {
        skillArray = rolewiseskill.filter(
          (data) =>
            (data.roleName && data.roleName.toLowerCase().includes(searchText.toLowerCase().trim())) ||
            data.technicalRoleName.toLowerCase().includes(searchText.toLowerCase().trim())
        );
      }
    } else {
      skillArray = rolewiseskill;
    }
    setRoleData(skillArray);
  };

  return (
    <div className={style.roll_based_skill_wrapper}>
      <SubNavigation type="Skill Matrix" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6} className="col-6">
            <h1 className="page-title pb-0">WMG</h1>
            <span className="st-breadcrumb">
              Skill Matrix
              <span>Define Skills</span>
            </span>
          </Col>
          <Col lg={6} md={6} sm={6} className={[style.res_title_btn, "text-right"].join(" ")}>
            <button
              type="button"
              className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
              onClick={navigate}
            >
              <strong>Define Skills</strong>
            </button>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className={[style.role_based_skills_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper justify-content-between d-flex align-items-center">
                <div className="box-heading">
                  <h2>{rolewiseskill.length ? rolewiseskill.length : "0"} Roles Available</h2>
                  <h3>Skills defined for technical roles</h3>
                </div>
                <div className="check_in_search_bar">
                  <Form>
                    <Form.Group controlId="1" className="form-postfix mb-0">
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Search By Role/Technical Role"
                        name="searchBox"
                        className={className}
                        onChange={handleSearchInputChange}
                        value={searchText}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      <Button className="close-button" type="reset" onClick={reset}>
                        <span className="close-icon"></span>
                      </Button>
                      <div className="form-postfix-icon">
                        <img src={IC_INPUT_SEARCH} title="" alt="" />
                      </div>
                    </Form.Group>
                  </Form>
                  <div className={[style.filter_info_tooltip, "custom-tooltip"].join(" ")}>
                    <div className="box-info">
                      <ReactSVG src={`${IC_INFO}`} title="" alt="info" />
                    </div>
                    <div className="info-attach-file">
                      <span className="attachment-title">Search Criteria</span>
                      <ul>
                        <li>If you want to search for Department, type #department. </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-light-gradient h38"></div>
              <div className="box-inner-pad">
                <Accordion className="custom-accordion-2">
                  {pageOfItems && pageOfItems.length > 0 ? (
                    pageOfItems.map((data, index) => {
                      return (
                        <Card key={index}>
                          <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={data.departmentRoleSkillConfigId}>
                              {data.technicalRoleName}
                              <span className={style.department}>
                                {data.departmentName} {data.roleName ? "| " + data.roleName : null}
                              </span>
                            </Accordion.Toggle>
                            <div className="d-flex">
                              <span
                                className={[style.edit, style.clone].join(" ")}
                                onClick={() => edit(data.departmentRoleSkillConfigId, "CLONE")}
                                data-title="Clone"
                                title="Copy"
                              >
                                <ReactSVG src={`${IC_CLONE}`} alt="Clone" />
                              </span>
                              <span className={style.edit}>
                                <ReactSVG
                                  src={`${IC_PENCIL}`}
                                  alt="Edit"
                                  onClick={() => edit(data.departmentRoleSkillConfigId, "EDIT")}
                                  data-title="Edit"
                                  title="Edit"
                                />
                              </span>
                            </div>
                          </Card.Header>

                          <Accordion.Collapse eventKey={data.departmentRoleSkillConfigId}>
                            <Card.Body>
                              {data.category.map((category) => (
                                <div
                                  key={category.categoryId}
                                  className="inner-accordion"
                                  id={`${category.categoryId}${data.departmentRoleSkillConfigId}`}
                                >
                                  <span
                                    className="inner-accordion-title"
                                    onClick={() =>
                                      toggleItem(`${category.categoryId}${data.departmentRoleSkillConfigId}`)
                                    }
                                  >
                                    {category.categoryName}
                                    <i className="arrow-up-down"></i>
                                  </span>
                                  {category.requiredSkill.skillset.map((required_skill, index) => (
                                    <div className={style.skill_gaps_table} key={index}>
                                      <h5>
                                        Required Skill Set(*
                                        {required_skill.shouldKnowAtleast === 0 ? 1 : required_skill.shouldKnowAtleast})
                                      </h5>
                                      <div className="box-light-gradient h38"></div>
                                      <ul className={style.skills_head}>
                                        <li>
                                          <span className={style.skill_name}>Skills Name</span>
                                          <span className={style.skillcompetency}>Skill Competency</span>
                                        </li>
                                      </ul>
                                      <ul className={style.skills_body}>
                                        {required_skill.skill.map((skill, i) => (
                                          <li key={i}>
                                            <span data-title="Skill Name :-" className={style.skill_name}>
                                              {skill.skillIdName}
                                            </span>
                                            <div
                                              data-title="Skill Competency :-"
                                              className={[style.skillcompetency, style.current].join(" ")}
                                            >
                                              {skill.competencyLevel === 1 ? (
                                                <span className={[style.req_comp_level, "orange"].join(" ")}>
                                                  {competencyLevel[skill.competencyLevel]}
                                                </span>
                                              ) : null}
                                              {skill.competencyLevel === 2 ? (
                                                <span className={[style.req_comp_level, "green"].join(" ")}>
                                                  {competencyLevel[skill.competencyLevel]}
                                                </span>
                                              ) : null}
                                              {skill.competencyLevel === 3 ? (
                                                <span className={[style.req_comp_level, "purple"].join(" ")}>
                                                  {competencyLevel[skill.competencyLevel]}
                                                </span>
                                              ) : null}
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  ))}
                                  {category.niceToHaveSkill.skill.length > 0 ? (
                                    <div className={style.skill_gaps_table}>
                                      <h5>Nice to have skill set</h5>
                                      <div className="box-light-gradient h38"></div>
                                      <ul className={style.skills_head}>
                                        <li>
                                          <span className={style.skill_name}>Skills Name</span>
                                          <span className={style.skillcompetency}>Skill Competency</span>
                                        </li>
                                      </ul>
                                      <ul className={style.skills_body}>
                                        {category.niceToHaveSkill.skill.map((nicetohave_skill) => (
                                          <li key={nicetohave_skill.skillIdNameId}>
                                            <span data-title="Skill Name :-" className={style.skill_name}>
                                              {nicetohave_skill.skillIdName}
                                            </span>
                                            <div
                                              data-title="Skill Competency :-"
                                              className={[style.skillcompetency, style.current].join(" ")}
                                            >
                                              {nicetohave_skill.competencyLevel === 1 ? (
                                                <span className={[style.req_comp_level, "orange"].join(" ")}>
                                                  {competencyLevel[nicetohave_skill.competencyLevel]}
                                                </span>
                                              ) : null}
                                              {nicetohave_skill.competencyLevel === 2 ? (
                                                <span className={[style.req_comp_level, "green"].join(" ")}>
                                                  {competencyLevel[nicetohave_skill.competencyLevel]}
                                                </span>
                                              ) : null}
                                              {nicetohave_skill.competencyLevel === 3 ? (
                                                <span className={[style.req_comp_level, "purple"].join(" ")}>
                                                  {competencyLevel[nicetohave_skill.competencyLevel]}
                                                </span>
                                              ) : null}
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })
                  ) : (
                    <div>
                      <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                      <div className="gap120"></div>
                    </div>
                  )}
                </Accordion>
              </div>
            </div>
          </Col>
        </Row>

        <Pagination pageSize={size} items={roleData} onChangePage={onChangePage} resetData={resetData} />
      </Container>
    </div>
  );
}
