import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import * as actions from "store/actions/index";
import style from "../AssignObjectives/AssignObjectives.module.scss";
import { scrollToTop } from "util/general";
import { dateFormat1 } from "util/dateUtils";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import ObjectiveOwner from "../AssignObjectives/ObjectiveOwner";
import ObjectiveActivities from "../CreatedObjective/ObjectiveActivities";
import SideBarTags from "../../Sidebar-tags/SidebarTags";
import ObjectiveDescription from "../CreatedObjective/ObjectiveDescription";
import RejectMessageModal from "../RejectMessageModal/RejectMessageModal";
import KeyResultPoint from "../../KeyResultPoint/KeyResultPoint";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function ObjectiveDetails(props) {
  const loggedInUser = storage.getUser();
  const userObjectiveId = props.match.params.id;
  const employeeIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  const [rejectModel, setRejectModel] = useState(false);
  const [activityData, setActivityData] = useState("");
  const dispatch = useDispatch();
  const { activities } = useSelector((state) => state.objectives);
  const { objective } = useSelector((state) => state.checkIn);

  useEffect(() => {
    scrollToTop(500);
    dispatch(actions.GetUserObjective(userObjectiveId, employeeIds));
    dispatch(actions.GetObjectiveActivity(userObjectiveId, employeeIds));
  }, [userObjectiveId, dispatch]);

  if (!objective) {
    return <Spinner />;
  }

  return (
    <div className={style.assignobjectives_wrapper}>
      <SubNavigation type="Objectives" />
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Objectives</h1>
            <span className="st-breadcrumb">
              Objectives
              <span>
                <Link to="/objectives/my-objectives">My Objectives</Link>
              </span>
              <span>Objective Details</span>
            </span>
          </Col>
          <Col className="col-12">
            <div
              className={["custom-tabs custom-tabs-full-width tab-content-dashboard", style.allobjectives_tabs].join(
                " "
              )}
            >
              <div className="box ">
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2>{objective.title}</h2>
                  </div>
                </div>
                <div className="box-inner-pad pb-0">
                  <div className="form-group">
                    <Row>
                      <Col xl={2} md={3} className={style.assign_objective_duration}>
                        <span>OKR Weightage</span>
                        {objective?.weightage? objective?.weightage + "%" : "-" }
                      </Col>
                      <Col xl={2} md={3} className={style.assign_objective_duration}>
                        <span>Assigned On</span>
                        {dateFormat1(objective.createdDate)}
                      </Col>
                      <Col xl={2} md={3} className={style.assign_objective_duration}>
                        <span>Completed On</span>
                        {dateFormat1(objective.completionDate)}
                      </Col>
                      <Col xl={3} md={6} className={style.assign_objective_duration}>
                        <span>Timeframe</span>
                        {dateFormat1(objective.startDate) + " to " + dateFormat1(objective.endDate)}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={8}>
            {objective && objective.description && <ObjectiveDescription description={objective.description} />}
            <div className="box pb-0">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>{objective.keyPoints && objective.keyPoints.length} Key Results Aligned</h2>
                  {/* <h3>Some text goes here, will be updated soon</h3> */}
                </div>
              </div>
              <div className="box-light-gradient"></div>

              <div className="box-inner-pad">
                <Row>
                  <div className="col-xl-12">
                    <div className="form-group">
                      {objective.keyPoints &&
                        objective.keyPoints.map((keypoint) => (
                          <KeyResultPoint keypoint={keypoint} key={"KEY_" + keypoint.keyId} />
                        ))}
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            {activities && (
              <ObjectiveActivities
                activities={activities}
                handleRejectMessagePopup={(data) => {
                  setRejectModel(true);
                  setActivityData(data);
                }}
              />
            )}
          </Col>
          <Col xl={4}>
            {objective && <ObjectiveOwner owner={objective.managerData} />}
            {objective && objective.tags && objective.tags.length > 0 && <SideBarTags tags={objective.tags} />}
          </Col>
        </Row>
      </Container>
      {rejectModel && (
        <RejectMessageModal
          activityData={activityData}
          reasonOfComment={
            activityData.whatChanged === "OBJ_FEEDBACK_STATUS"
              ? 'has marked objective as "INVALID"'
              : `has rejected your objective "${objective && objective.title}"`
          }
          objective={objective}
          hide={() => setRejectModel(false)}
          show={rejectModel}
        />
      )}
    </div>
  );
}
