import React from "react";
import { backGroundColor } from "../../const/constValue";
import { MY_LOGO } from "../../const/imgCost";
import NotificationItem from "./NotificationItem";

export default function NotificationListItems(props) {
  if (props.notifications && props.notifications.length > 0)
    return (
      <div className="notification_list ">
        <div className="title"> {props.title} </div>
        <ul>
          {props.onDeleteNotification
            ? props.notifications.map((item, index) => (
                <NotificationItem
                  notificationType="viewall"
                  key={item.id}
                  item={item}
                  displayPic={item.sender ? item.sender.displayPicture : MY_LOGO}
                  backgroundColor={item.sender ? backGroundColor[index % backGroundColor.length] : "white"}
                  onClick={() => props.onClick(item)}
                  onDelete={(e) => {
                    e.stopPropagation();
                    props.onDeleteNotification(item.id);
                  }}
                />
              ))
            : props.notifications.map((item, index) => (
                <NotificationItem
                  notificationType="panel"
                  key={item.id}
                  displayPic={item.sender ? item.sender.displayPicture : MY_LOGO}
                  backgroundColor={item.sender ? backGroundColor[index % backGroundColor.length] : "white"}
                  item={item}
                  onClick={() => props.onClick(item)}
                />
              ))}
        </ul>
      </div>
    );
  return null;
}
