import React from "react";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { IC_CLOSE_ROUNDED, IC_LOCK_PRIVACY_1 } from "const/imgCost";

const ObjectiveComments = (props) => {
  let name = "";
  if (props.answer.private) {
    if (props.user && props.user.employeeId === props.answer.commentedBy.employeeId) {
      name = props.answer.whoCanView && props.answer.whoCanView[0].empName;
    } else {
      name = props.answer.commentedBy.empName;
    }
  }

  if (!props.answer) return null;

  return (
    <div className="view_comment_box_row" key={"comment_" + props.index + 1}>
      <div className="user_pics" style={{ backgroundColor: props.selectedColor }}>
        {props.answer.commentedBy.displayPicture && props.answer.commentedBy.displayPicture.length > 2 ? (
          <img alt="DP" src={props.answer.commentedBy.displayPicture} />
        ) : (
          props.answer.commentedBy.displayPicture
        )}
      </div>
      <div className="user_comment_details">
        <div className="user_name">
          {props.answer.commentedBy.empName}
          <span className="comment_time">{moment(props.answer.commentedOn).fromNow()}</span>
        </div>
        <div className="user_comment text-break">{props.answer.comment}</div>
        {!props.reply && props.answer.private && !props.privateThread && (
          <div className="private-comment-selected fill-svg-icon">
            <ReactSVG src={`priority_${IC_LOCK_PRIVACY_1}`} alt="lock" />
            <span>Private message, only visible to you and {name}</span>
          </div>
        )}
        {props.deletable && (
          <div className="remove_user_comment" onClick={() => props.deleteComment(props.answer.commentId)}>
            <img className="svg" src={`priority_${IC_CLOSE_ROUNDED}`} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ObjectiveComments;
