import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import MonthlyCheckInWrapper from "../../../components/MonthlyCheckIn/EmpViewMonthlyCheckin/MonthlyCheckInWrapper";
import style from "./MonthlyCheckIn.module.scss";

export default function MonthlyCheckIn() {
  return (
    <Fragment>
      <SubNavigation type="Monthly Checkin" />
      <Container>
        <div className={style.container_wrapper}>
          <MonthlyCheckInWrapper />
        </div>
      </Container>
    </Fragment>
  );
}
