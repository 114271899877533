import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import MultiSelect from "react-multi-select-component";
import { ReactSVG } from "react-svg";
import * as actions from "store/actions/index";
import { IC_SEARCH_RESET } from "const/imgCost";
import { moduleView, useQuery } from "util/utils";
import { endOfDay } from "util/dateUtils";
import { errorToast, getEmployeeIdsArray, getStringArray, requiredFieldMissing, successToast } from "util/general";
import storage from "util/storage";
import config from "util/config";
import LocationFilter from "./LocationFilter";
import QuestionTemplateModal from "../../Performance/CreatePerformanceCycle/QuestionTemplateModal/QuestionTemplateModal";
import Spinner from "shared/Spinner/Spinner";
import SubmitBox from "../../SubmitBox/SubmitBox";
import style from "./CreateCampaign.module.scss";
import ConfirmationPopup from "components/Objectives/ConfirmationPopup";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { btnName, errorMessage, menuText, successMessage } from "const/constValue";
import routePath from "const/routePath";
import { campaignTypeOptions } from "const/options";
import Select from "react-select";
import { DOWNLOAD_QUIZ_SAMPLE } from "util/endpoints";
import { ExcelRenderer } from "react-excel-renderer";
import ComfirmUploadPopup from "components/Objectives/AllObjectives/comfirmUploadPopup";

function EditCampaign() {
  const dispatch = useDispatch();
  const history = useHistory();
  const campaignId = useQuery().get("id");
  const [isModal, setIsModal] = useState(false);
  const [confirmationData, setConfirmationData] = useState({});
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [confirmUploadPopup, setConfirmUploadPopup] = useState(false);
  const [questionData, setQuestionData] = useState();
  const [errorList, setErrorList] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);

  let inputElement = React.createRef();

  const isCampaignAdmin = moduleView(config.hrModule.CAMPAIGN_DETAILS)?.includes(config.hrModule.CAMPAIGN_DETAILS);
  const isCapability = history.location.pathname.includes("capability-development");

  storage.set("cycle", "campaign");
  storage.set("cId", campaignId);
  const {
    locationList,
    employeesList,
    campaignData,
    apiResponse,
    popupLoading,
    isTemplateLoading,
    isCreateLoading,
    templateList,
    isPublished,
  } = useSelector((state) => state.campaign);
  const { subGroupClient } = useSelector((state) => state.admin);
  let isAddNewRowBtn = true;
  let isPreviewBtnDisplay = true;
  let isAddNewRowBtnDisabled = false;
  if (campaignData.campaignFilters.find((value) => typeof value.id === "string")) {
    isPreviewBtnDisplay = false;
    isAddNewRowBtnDisabled = true;
  }
  let subNavType = "OKR Progress";
  let PAGE_NAME = "Campaign";
  let backURL = "/okrprogress/campaign-list";
  let breadCrumbText = menuText.CAMPAIGN;
  let subMenuText = "Edit Campaign";
  if (isCapability) {
    subNavType = "WMG";
    PAGE_NAME = "Capability Development";
    backURL = "/wmg/capability-development";
    breadCrumbText = menuText.MMG;
    subMenuText = "Edit Capability Development";
  } else if (isCampaignAdmin) {
    subNavType = "Campaign Details";
    backURL = "/hr/campaign-details";
  }

  const user = storage.getUser();
  
  useEffect(() => {
    if (user?.employeeId && campaignData?.campaignAdmins?.length > 0) {
      const adminEmployeeIds = campaignData?.campaignAdmins.map(admin => admin.value);

      if(!adminEmployeeIds.includes(user.employeeId)) {
        history.push(routePath.HOME);
      }
      
    }
  }, [campaignData?.campaignId, user?.employeeId]);

  useEffect(() => {
    dispatch(actions.getSubGroupClientWithoutRole());
    if (campaignId) {
      dispatch(actions.getRefreshedCampaign(campaignId));
    }
  }, []);

  useEffect(() => {
    if (campaignId) {
      dispatch(actions.getCreatedCampaign(campaignId));
      dispatch(actions.getRefreshedCampaign(campaignId));
    }
  }, [campaignId]);

  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.success) {
        successToast(apiResponse.message);
        if (apiResponse.isCreated) {
          history.push(backURL);
        }
      } else if (apiResponse.isPublished) {
        history.push(backURL);
        successToast(successMessage.PUBLISHED);
      } else if (apiResponse.id) {
        isCampaignAdmin
          ? history.push(`${routePath.HR_CAMPAIGN_DETAILS}/hr-edit?id=${apiResponse.id}`)
          : history.push(`${routePath.CHECKINS_CAMPAIGN_LIST}/edit?id=${apiResponse.id}`);
      } else {
        errorToast(apiResponse.message);
      }
      dispatch({ type: "CLEAR_CAMPAIGN_RESPONSE" });
    }
  }, [apiResponse, dispatch]);

  const getCampaignTemplateListSurvey = (campaignId, type) => {
    let config = {
      params: {
        campaignId: campaignId,
        type: type,
      },
    };
    dispatch(actions.getCampaignTemplateList(config));
  };

  const getCampaignTemplateListQuiz = (campaignId, type, numberOfQuestions) => {
    let config = {
      params: {
        campaignId: campaignId,
        type: type,
        numberOfQuestions: numberOfQuestions,
      },
    };
    dispatch(actions.getCampaignTemplateList(config));
  };

  const handleCreateCampaign = () => {
    let body;
    if (campaignData?.category?.value === "Quiz") {
      if (campaignData?.total_Marks <= campaignData?.passing_Marks) {
        errorToast("Your total marks should be greater than passing marks");
        return;
      }
      body = {
        questionTemplateId: campaignData?.questionTemplateId ? campaignData?.questionTemplateId : "",
        campaignName: campaignData?.campaignName,
        campaignId: campaignId,
        campaignAdmins: campaignData?.campaignAdmins?.map((value) => {
          return {
            employeeId: value.value,
            parentGroupId: value.parentGroupId,
            subGroupId: value.subGroupId,
          };
        }),
        notify: campaignData.notify,
        isEdit: isPublished ? true : false,
        isCapabilityDevelopmentFramework: campaignData.isCapabilityDevelopmentFramework,
        isCreated: true,
        subGroupId: campaignData.subGroups?.map((group) => group.value),
        category: campaignData?.category?.value,
        number_Of_Questions: campaignData?.number_Of_Questions,
        total_Marks: campaignData?.total_Marks,
        passing_Marks: campaignData?.passing_Marks,
        total_Time: campaignData?.total_Time,
        number_Of_Attemptes: campaignData?.number_Of_Attemptes,
        instructions: campaignData?.instructions,
      };
    } else {
      body = {
        questionTemplateId: campaignData?.questionTemplateId ? campaignData?.questionTemplateId : "",
        campaignName: campaignData?.campaignName,
        campaignId: campaignId,
        campaignAdmins: campaignData?.campaignAdmins?.map((value) => {
          return {
            employeeId: value.value,
            parentGroupId: value.parentGroupId,
            subGroupId: value.subGroupId,
          };
        }),
        notify: campaignData.notify,
        isCapabilityDevelopmentFramework: campaignData.isCapabilityDevelopmentFramework,
        isCreated: true,
        subGroupId: campaignData.subGroups?.map((group) => group.value),
      };
    }
    if (campaignData.subGroups?.length > 0) {
      dispatch(actions.createCampaign(body));
    } else {
      setErrors({ ...errors, subGroups: true });
      requiredFieldMissing();
    }
  };

  const addNewRow = () => {
    dispatch({
      type: "ADD_NEW_FILTER_ROW",
      payload: {
        id: new Date().getTime() + "CAMPAIGN",
        location: null,
        department: [],
        designation: [],
        indiaDD: [],
        startDate: null,
        endDate: null,
        cutOffMonth: "",
        employeeType: [],
        employeeIds: [],
        departmentList: [],
        designationList: [],
        employeesListForLocation: [],
        indiaDDList: [],
        employeeGroupList: [],
      },
    });
  };

  const deleteRow = (id) => {
    if (typeof id === "number") {
      dispatch(actions.deleteCampaignFilter(campaignId, id));
    }
    dispatch({ type: "REMOVE_FILTER_ROW", id });
  };

  const handleSetCampaign = (name, value) => {
    dispatch({ type: "SET_CAMPAIGN_DATA", payload: { [name]: value } });
  };

  const handleCampaignAdmin = (value) => {
    handleSetCampaign("campaignAdmins", value);
    dispatch(
      actions.updateCampaign({
        campaignId: campaignData?.campaignId,
        campaignAdmins: value?.map((value) => {
          return {
            employeeId: value.value,
            parentGroupId: value.parentGroupId,
            subGroupId: value.subGroupId,
          };
        }),
      })
    );
  };

  const updateCampaignData = (name, value) => {
    if (name === "instructions" && isPublished) {
      dispatch(
        actions.updateCampaign({
          campaignId: campaignData?.campaignId,
          [name]: value,
          isEdit: true,
        })
      );
    } else {
      dispatch(
        actions.updateCampaign({
          campaignId: campaignData?.campaignId,
          [name]: value,
        })
      );
    }

    // if (name === "number_Of_Questions") {
    //   handleRemoveTemplate();
    // }
  };

  const handleCampaignCategory = (name, value) => {
    handleSetCampaign(name, value);
    dispatch(
      actions.updateCampaign({
        campaignId: campaignData?.campaignId,
        category: value.value,
      })
    );
    if (campaignId && value.value && value.value === "Survey") {
      getCampaignTemplateListSurvey(campaignId, value.value);
    }
    if (campaignId && value.value && value.value === "Quiz") {
      if (
        campaignData?.number_Of_Questions !== null &&
        campaignData?.number_Of_Questions !== "" &&
        campaignData?.number_Of_Questions !== 0
      ) {
        getCampaignTemplateListQuiz(campaignId, value.value, campaignData?.number_Of_Questions);
      }
    }
    if (campaignData?.questionTemplateId) {
      handleRemoveTemplate();
    }
  };

  const handleNumberOfQuestions = (name, value) => {
    handleSetCampaign(name, value);
    if (campaignId && campaignData?.category?.value && campaignData?.category?.value === "Quiz") {
      if (value !== null && value !== "" && value !== 0) {
        getCampaignTemplateListQuiz(campaignId, campaignData?.category?.value, value);
      }
    }
  };

  const handleTotalMarks = (name, value) => {
    if (value <= campaignData?.passing_Marks) {
      dispatch({ type: "SET_CAMPAIGN_DATA", payload: { [name]: value } });
      errorToast("Your total marks should be greater than passing marks");
    } else {
      dispatch({ type: "SET_CAMPAIGN_DATA", payload: { [name]: value } });
      dispatch(
        actions.updateCampaign({
          campaignId: campaignData?.campaignId,
          total_Marks: value,
        })
      );
    }
  };

  const handlePassingMarks = (name, value) => {
    if (value >= campaignData?.total_Marks) {
      dispatch({ type: "SET_CAMPAIGN_DATA", payload: { [name]: value } });
      errorToast("Your passing marks should be less than total marks");
    } else {
      dispatch({ type: "SET_CAMPAIGN_DATA", payload: { [name]: value } });
      dispatch(
        actions.updateCampaign({
          campaignId: campaignData?.campaignId,
          passing_Marks: value,
        })
      );
    }
  };

  const handleTotalTime = (name, value) => {
    handleSetCampaign(name, value);
  };

  const handleNumberOfAttempts = (name, value) => {
    handleSetCampaign(name, value);
  };

  const handleInstructions = (name, value) => {
    handleSetCampaign(name, value);
  };

  const handleNotifyCampaign = (value) => {
    handleSetCampaign("notify", value);
    dispatch(actions.updateCampaign({ campaignId: campaignData?.campaignId, notify: value }));
  };

  const handleCapabilityDevelopment = (value) => {
    handleSetCampaign("isCapabilityDevelopmentFramework", value);
    dispatch(
      actions.updateCampaign(
        { campaignId: campaignData?.campaignId, isCapabilityDevelopmentFramework: value },
        false,
        campaignData?.campaignId
      )
    );
  };

  const handleChangeSubGroups = (value, type) => {
    if (
      campaignData.subGroups?.length > value?.length &&
      campaignData.campaignFilters?.find((v) => typeof v.id === "number") &&
      type !== "CONFIRM"
    ) {
      setConfirmationData({
        open: true,
        subGroupId: value,
        campaignId,
      });
    } else {
      setConfirmationData({});
      handleSetCampaign("subGroups", value);
      dispatch(actions.getLocationListForCampaign({ subGroupId: value?.map((v) => v.value) }));
      dispatch(actions.updateCampaign({ campaignId, subGroupId: value?.map((v) => v.value) }));
    }
  };

  const handleLocationFilterChange = (name, value, id) => {
    let locationList = value?.length ? value.map((location) => location.value) : [];
    dispatch({ type: "SET_LOCATION_FILTER_FOR_CAMPAIGN", payload: { [name]: value }, id: id });
    if (name === "location") {
      let subGroups = campaignData.subGroups;
      let subGroupId = subGroups.map((subGroup) => subGroup.value);
      dispatch(actions.getLocationFilter(locationList, id, campaignId, subGroupId));
    }
  };

  const handleSaveFilter = (id) => {
    let data = campaignData.campaignFilters.find((value) => value.id === id);
    if (!data?.endDate) {
      errorToast("Please Select Start Date And End Date");
      return;
    } else if (new Date(data?.startDate) > new Date(data?.endDate)) {
      errorToast("Start Date Should Be Smaller Than End Date");
      return;
    }
    let body = {
      designation: getStringArray(data?.designation, "value") || [],
      location: getStringArray(data.location, "value") || [],
      department: getStringArray(data.department, "value") || [],
      employeeType: getStringArray(data.employeeType, "value") || [],
      startDate: data.startDate || undefined,
      endDate: endOfDay(data.endDate),
      cutOffMonth: data.cutOffMonth && data.cutOffMonth.value,
      employeeIds: getEmployeeIdsArray(data.employeeIds) || [],
    };
    if (typeof id === "number") {
      dispatch(actions.updateCampaignFilter(body, campaignId, id, true, false, isPublished));
    } else {
      dispatch(actions.createCampaignFilter(body, campaignId, true, true, isPublished));
    }
  };

  const getEmployeeNameList = (value) => {
    if (value?.length > 2) {
      dispatch(actions.getEmployeeNameListForCampaign(value));
    }
  };

  const handlePublishCampaign = () => {
    if (campaignData?.questionTemplateId) {
      let body;
      if (campaignData?.campaignFilters?.find((value) => !value.startDate && !value.endDate)) {
        errorToast(errorMessage.PLEASE_SELECT_DATE);
        return;
      } else if (campaignData?.category?.value === null) {
        errorToast("Please Select Category");
        return;
      } else if (campaignData?.number_Of_Attemptes > 5) {
        errorToast("Maximum five number of attempts are allowed");
        return;
      } else if (
        campaignData?.category?.value === "Quiz" &&
        (campaignData?.number_Of_Questions <= 0 ||
          campaignData?.total_Marks <= 0 ||
          campaignData?.number_Of_Attemptes <= 0 ||
          campaignData?.total_Time <= 0 ||
          campaignData?.passing_Marks <= 0)
      ) {
        errorToast("Enter valid number and number should not be zero");
        return;
      } else if (campaignData?.category?.value === "Quiz" && campaignData?.total_Marks <= campaignData?.passing_Marks) {
        errorToast("Your total marks should be greater than passing marks");
        return;
      } else if (campaignData?.category?.value === "Quiz") {
        body = {
          isCreated: true,
          isPublished: true,
          campaignId,
          questionTemplateId: campaignData?.questionTemplateId,
          notify: campaignData.notify,
          isCapabilityDevelopmentFramework: campaignData.isCapabilityDevelopmentFramework,
          category: campaignData?.category?.value,
          number_Of_Questions: campaignData?.number_Of_Questions,
          total_Marks: campaignData?.total_Marks,
          passing_Marks: campaignData?.passing_Marks,
          total_Time: campaignData?.total_Time,
          number_Of_Attemptes: campaignData?.number_Of_Attemptes,
          instructions: campaignData?.instructions,
        };
      } else {
        body = {
          isCreated: true,
          isPublished: true,
          campaignId,
          questionTemplateId: campaignData?.questionTemplateId,
          notify: campaignData.notify,
          isCapabilityDevelopmentFramework: campaignData.isCapabilityDevelopmentFramework,
        };
      }
      dispatch(actions.updateCampaign(body, true));
    } else {
      errorToast(errorMessage.PLEASE_SELECT_TEMPLATE_BEFORE_PUBLISH);
      dispatch({ type: "REMOVE_CAMPAIGN_PREVIEW_DATA" });
    }
  };

  const handleClose = () => {
    history.push(backURL);
  };
  const getCampaignId = (value) => {
    dispatch(actions.updateCampaign({ campaignName: value, campaignId: campaignId ? campaignId : undefined }));
  };

  const handleRemoveTemplate = () => {
    let body = {
      campaignId,
      questionTemplateId: "",
      isDeleteQuestionMap: campaignData?.isCapabilityDevelopmentFramework ? true : undefined,
    };
    dispatch(actions.updateCampaign(body, "REMOVE_TEMPLATE"));
    dispatch({ type: "SET_CAMPAIGN_DATA", payload: { questionTemplateId: "", questionTemplateName: "" } });
  };

  const selectTemplate = (id, value) => {
    dispatch({ type: "SET_CAMPAIGN_DATA", payload: { questionTemplateName: value, questionTemplateId: id } });
    let body = {
      campaignId,
      questionTemplateId: id,
    };
    campaignId && dispatch(actions.updateCampaign(body, "TEMPLATE_UPDATED"));
    setIsModal(!isModal);
  };

  const handleTemplatePopup = () => {
    storage.set("templateType", "CAMPAIGN");
    setIsModal(true);
  };

  const handleCreateTemplate = () => {
    storage.set("templateType", "CAMPAIGN");
    history.push(routePath.QUESTION_BUILDER);
  };

  // let loading = true;
  // loading = campaignId ? isCampaignLoading : isTemplateLoading;

  const handleOnKeyPress = (e) => {
    e = e || window.event;
    let charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    let charStr = String.fromCharCode(charCode);
    if (e.charCode < 48 || !charStr.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69) {
      e.preventDefault();
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: !isPublished ? "#ffffff" : "#fbfbfb",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: !isPublished ? "#322e46" : "#928fa0",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#928fa0",
    }),
  };

  const uploadFileAPI = (files) => {
    inputElement.value = "";
    ExcelRenderer(files, (err, resp) => {
      let fileNameData = files?.name?.split(".")?.pop();
      if (fileNameData !== "xlsx") {
        setErrorList(["Please upload files with extension xlsx only."]);
        setQuestionData([]);
        setConfirmUploadPopup(true);
        setErrorFlag(true);
      }
      if (err) {
        console.warn(err);
      } else {
        let obj = {
          cols: resp.cols || [],
          rows: resp.rows || [],
        };
        let index = {
          count: 0,
          objectIndex: [],
        };
        let questionTotalFlag = false;
        let _tempList = [];
        let header = [
          "question name",
          "options",
          "iscorrect",
          "question category",
          "question type",
          "help text/description to the question",
        ];
        if (obj?.rows[0]?.length === 0) {
          questionTotalFlag = true;
          let list = [..._tempList];
          let obj = `Please check added columns name. It should be "Question Name", "Options", "IsCorrect", "Question Category", "Question Type", "Help text/description to the question" or Column headings can not be empty.`;
          if (!list.includes(obj)) {
            list.push(obj);
          }
          _tempList = [...list];
        }
        if (obj?.rows[0]?.length > 0) {
          obj.rows[0].forEach((item) => {
            if (typeof item === "string" && !header.includes(item.toLowerCase())) {
              questionTotalFlag = true;
              let list = [..._tempList];
              let obj = `Please check added columns name. It should be "Question Name", "Options", "IsCorrect", "Question Category", "Question Type", "Help text/description to the question" or Column headings can not be empty.`;
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
          });
        }
        if (
          typeof obj?.rows[0][0] === "string" &&
          obj?.rows[0][0]?.toLowerCase().trim() === "question name" &&
          typeof obj?.rows[0][1] === "string" &&
          obj?.rows[0][1]?.toLowerCase().trim() === "options" &&
          typeof obj?.rows[0][2] === "string" &&
          obj?.rows[0][2]?.toLowerCase().trim() === "iscorrect" &&
          typeof obj?.rows[0][3] === "string" &&
          obj?.rows[0][3]?.toLowerCase().trim() === "question category" &&
          typeof obj?.rows[0][4] === "string" &&
          obj?.rows[0][4]?.toLowerCase().trim() === "question type" &&
          typeof obj?.rows[0][5] === "string" &&
          obj?.rows[0][5]?.toLowerCase().trim() === "help text/description to the question"
        ) {
          let questions = [];
          let questionTypeArr = [];
          obj.rows.map((temp, i) => {
            if (i !== 0 && temp.length !== 0) {
              if (temp[0]) {
                let categoryName;
                if (typeof temp[3] === "string" && temp[3]?.trim() !== "") {
                  const arr = temp[3].split(" ");
                  for (let i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                  }
                  categoryName = arr.join(" ");
                } else {
                  categoryName = temp[3];
                }
                index.count++;
                index.objectIndex.push(i);
                let _obj = {
                  questionTitle: temp[0] === "" ? null : `Q${index.count}`,
                  questionName: temp[0] === "" ? null : temp[0],
                  type:
                    temp[4] !== undefined && typeof temp[4] === "string"
                      ? temp[4]?.toLowerCase()?.trim() === "single choice"
                        ? "RADIOBUTTON"
                        : temp[4]?.toLowerCase()?.trim() === "multi choice"
                        ? "CHECKBOX"
                        : temp[4]
                      : temp[4],
                  isRequired: false,
                  answer: "",
                  isOther: false,
                  otherText: "",
                  optionalCommentBox: false,
                  options: [],
                  description: temp[5] !== undefined && typeof temp[5] === "string" ? temp[5]?.trim() : temp[5],
                  descriptionFlag:
                    temp[5] === undefined || temp[5] === null
                      ? ""
                      : typeof temp[5] === "string"
                      ? temp[5].trim() === ""
                        ? ""
                        : true
                      : true,
                  commentResponse: "Optional",
                  overAllScore: false,
                  questionCategory: { name: categoryName },
                  respondToManager: false,
                  respondToEmployee: true,
                  respondToAll: false,
                  notShowScaleToEmployee: false,
                  showManagerResponse: false,
                  showManagerScore: false,
                };
                let quesType = {
                  quesArr: [],
                };
                questions.push(_obj);
                questionTypeArr.push(quesType);
              }
            }
            return temp;
          });
          for (let i = 0; i < index.objectIndex.length; i++) {
            let start = index.objectIndex[i];
            let end = index.objectIndex[i + 1] === undefined ? obj.rows.length : index.objectIndex[i + 1];

            for (let j = start; j < end; j++) {
              let temp = {
                optionId: new Date().getTime() + j,
                option: obj.rows[j][1] === undefined ? null : obj.rows[j][1],
                isCorrect:
                  obj.rows[j][2] !== undefined
                    ? typeof obj.rows[j][2] === "string"
                      ? obj.rows[j][2]?.toLowerCase()?.trim() === "true"
                        ? true
                        : obj.rows[j][2]?.toLowerCase()?.trim() === "false"
                        ? false
                        : obj.rows[j][2]
                      : obj.rows[j][2]
                    : null,
              };
              if (obj.rows[j][4] !== undefined && obj.rows[j][4] !== null) {
                questionTypeArr[i].quesArr.push(obj.rows[j][4]);
              }
              questions[i].options.push(temp);
            }
          }
          if (questions.length === 0) {
            questionTotalFlag = true;
            let list = [..._tempList];
            let obj = "Question Name Can not be empty.";
            if (!list.includes(obj)) {
              list.push(obj);
            }
            _tempList = [...list];
          }
          questionTypeArr.forEach((item) => {
            if (item.quesArr.length > 1) {
              questionTotalFlag = true;
              let list = [..._tempList];
              let obj = "Question Type Can not be more than one for each question.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
          });
          questions.forEach((item) => {
            item.options.map((value) => {
              if (value.option === null) {
                questionTotalFlag = true;
                let list = [..._tempList];
                let obj = "Options cannot be empty.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
              if (value.isCorrect === null) {
                questionTotalFlag = true;
                let list = [..._tempList];
                let obj = "IsCorrect cannot be empty.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
              if (value.isCorrect !== true && value.isCorrect !== false && value.isCorrect !== null) {
                questionTotalFlag = true;
                let list = [..._tempList];
                let obj = "Please check value added in 'IsCorrect' filed, it should be either TRUE or FALSE.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
            });
            if (item.type === null || item.type === undefined) {
              questionTotalFlag = true;
              let list = [..._tempList];
              let obj = "Question Type cannot be empty.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
            if (
              item.type !== null &&
              item.type !== undefined &&
              item.type !== "CHECKBOX" &&
              item.type !== "RADIOBUTTON"
            ) {
              questionTotalFlag = true;
              let list = [..._tempList];
              let obj =
                "Please check value added in 'Question Type' filed, it should be either Single Choice or Multi Choice.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
            if (item?.options?.length < 2) {
              questionTotalFlag = true;
              let list = [..._tempList];
              let obj = "Please add minimum two options for added question.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
            if (campaignData?.number_Of_Questions === null || questions?.length < campaignData?.number_Of_Questions) {
              questionTotalFlag = true;
              let list = [..._tempList];
              let obj = "Questions count should be match with 'Number of Question' or greater";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
            if ((item?.options?.length > 1 && item.type === "RADIOBUTTON") || item.type === "CHECKBOX") {
              let checkTrue = item.options.filter((value) => value.isCorrect === true);
              if (checkTrue.length === 0) {
                questionTotalFlag = true;
                let list = [..._tempList];
                let obj =
                  "Please check column 'IsCorrect', for a added question at least one option value should be 'True'.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
            }
            if (item?.options?.length > 1 && item.type === "RADIOBUTTON") {
              let checkRadio = item.options.filter((value) => value.isCorrect === true);
              if (checkRadio.length > 1) {
                questionTotalFlag = true;
                let list = [..._tempList];
                let obj =
                  "Please check column 'IsCorrect', Only single 'True' option should be there for Single Choice Question.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
            }
          });
          setErrorList(_tempList);
          let questionObj = {
            templateName: files.name.split(".")[0],
            questions: questions,
            isPublished: true,
            category: "CAMPAIGN",
            campaignType: campaignData?.category?.value,
            numberOfQuestions: questions?.length,
          };
          setQuestionData(questionObj);
          if (questionTotalFlag) {
            setConfirmUploadPopup(true);
            setErrorFlag(questionTotalFlag);
          } else {
            setConfirmUploadPopup(true);
            setErrorFlag(false);
          }
        } else {
          let obj = `Please check added columns name. It should be "Question Name", "Options", "IsCorrect", "Question Category", "Question Type", "Help text/description to the question" or Column headings can not be empty.`;
          if (!_tempList.includes(obj)) {
            _tempList.push(obj);
          }
          setConfirmUploadPopup(true);
          setErrorFlag(true);
          setErrorList(_tempList);
        }
      }
    });
  };

  const handlePreview = () => {
    history.push({
      pathname: routePath.IMPORT_PREVIEW_TEMPLATE_ROUTE,
      state: {
        questionData: questionData,
      },
    });
  };

  const uploadRatingFile = (questionData) => {
    dispatch(actions.importQuizQuestion(questionData));
  };

  return (
    <div className={style.cycle_per_wrapper}>
      <SubNavigation type={subNavType} />
      {(popupLoading || isCreateLoading) && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">{breadCrumbText}</h1>
            <span className="st-breadcrumb">
              <Link to={backURL}>Admin</Link>
              <span>{subMenuText}</span>
            </span>
          </Col>
          <Col md={12}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>{subMenuText}</h2>
                </div>
              </div>
              <Form name="Campaign Form">
                <div className={[style.custom_form_ctrl_wrapper, "box-inner-pad"].join(" ")}>
                  <Form.Group>
                    <Form.Label>
                      {PAGE_NAME} Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={isPublished}
                      maxLength="200"
                      placeholder="Campaign Name"
                      name="campaignName"
                      className={[errors && errors.campaignName && "limit-reached"].join(" ")}
                      value={campaignData?.campaignName ?? ""}
                      onBlur={(e) => getCampaignId(e.target.value)}
                      onChange={(e) => {
                        handleSetCampaign(e.target.name, e.target.value);
                        if (e.target.value.trim()) {
                          setErrors({ ...errors, campaignName: false });
                        } else setErrors({ ...errors, campaignName: true });
                      }}
                    />
                  </Form.Group>
                  <div className={style.filter_top_bar}>
                    <div className="radio-wrapper d-flex dropdown-multiselect">
                      <Form.Group controlId="" className="mr-5">
                        <Form.Label>
                          Select {PAGE_NAME} Admin<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          <Multiselect
                            disable={isPublished || (campaignId ? false : true)}
                            id={isPublished ? "multi-select-search-dd-box-disabled" : "multi-select-search-dd-box"}
                            options={employeesList || []} // Options to display in the dropdown
                            selectedValues={campaignData?.campaignAdmins} // Preselected value to persist in dropdown
                            onSelect={(list) => handleCampaignAdmin(list)} // Function will trigger on select event
                            onRemove={(list) => handleCampaignAdmin(list)} // Function will trigger on remove event
                            displayValue="label" // Property name to display in the dropdown options
                            onSearch={(e) => getEmployeeNameList(e)}
                            closeOnSelect={false}
                            showCheckbox={true}
                            placeholder="Search"
                          />
                        </div>
                      </Form.Group>

                      <Form.Group controlId="" className="mr-5">
                        <Form.Label>
                          Sub Group<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          <MultiSelect
                            options={subGroupClient || []}
                            className={
                              isPublished ? "sub-disabled" : errors?.subGroups ? "limit-reached" : "multi-select"
                            }
                            name="subGroups"
                            disabled={isPublished || (campaignId ? false : true)}
                            value={campaignData?.subGroups || []}
                            onChange={(e) => {
                              handleChangeSubGroups(e);
                              if (e) {
                                setErrors({ ...errors, subGroups: false });
                              } else setErrors({ ...errors, subGroups: true });
                            }}
                          />
                        </div>
                      </Form.Group>

                      <Form.Group controlId="" className="mr-5">
                        <Form.Label>
                          Select Campaign Category<span className="text-danger">*</span>
                        </Form.Label>
                        <div className="tags_wrapper">
                          <Select
                            styles={customStyles}
                            isDisabled={isPublished || (campaignId ? false : true)}
                            options={campaignTypeOptions}
                            className="custom-default-dd-box"
                            name="category"
                            classNamePrefix="select"
                            placeholder="Select..."
                            value={campaignData?.category}
                            onChange={(e) => {
                              handleCampaignCategory("category", e);
                              if (e) {
                                setErrors({ ...errors, category: false });
                              } else setErrors({ ...errors, category: true });
                            }}
                          />
                        </div>
                      </Form.Group>

                      {campaignData?.category?.value === "Quiz" && (
                        <>
                          <Form.Group controlId="" className="mr-5">
                            <Form.Label>
                              Number Of Questions<span className="text-danger">*</span>
                            </Form.Label>
                            <div className="tags_wrapper">
                              <Form.Control
                                type="number"
                                disabled={isPublished}
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={handleOnKeyPress}
                                onKeyDown={handleOnKeyDown}
                                maxLength="200"
                                placeholder="Enter number of questions"
                                name="number_Of_Questions"
                                className={[errors && errors.number_Of_Questions && "limit-reached"].join(" ")}
                                value={campaignData?.number_Of_Questions ?? ""}
                                onBlur={(e) => updateCampaignData(e.target.name, +e.target.value)}
                                onChange={(e) => {
                                  handleNumberOfQuestions(e.target.name, +e.target.value);
                                  if (e.target.value.trim()) {
                                    setErrors({ ...errors, number_Of_Questions: false });
                                  } else {
                                    handleSetCampaign(e.target.name, "");
                                    setErrors({ ...errors, number_Of_Questions: true });
                                  }
                                }}
                              />
                            </div>
                          </Form.Group>

                          <Form.Group controlId="" className="mr-5">
                            <Form.Label>
                              Total Marks<span className="text-danger">*</span>
                            </Form.Label>
                            <div className="tags_wrapper">
                              <Form.Control
                                type="number"
                                disabled={isPublished}
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={handleOnKeyPress}
                                onKeyDown={handleOnKeyDown}
                                maxLength="200"
                                placeholder="Enter total marks"
                                name="total_Marks"
                                className={[errors && errors.total_Marks && "limit-reached"].join(" ")}
                                value={campaignData?.total_Marks ?? ""}
                                onChange={(e) => {
                                  handleTotalMarks(e.target.name, +e.target.value);
                                  if (e.target.value.trim()) {
                                    setErrors({ ...errors, total_Marks: false });
                                  } else {
                                    handleSetCampaign(e.target.name, "");
                                    setErrors({ ...errors, total_Marks: true });
                                  }
                                }}
                              />
                            </div>
                          </Form.Group>

                          <Form.Group controlId="" className="mr-5">
                            <Form.Label>
                              Passing Marks<span className="text-danger">*</span>
                            </Form.Label>
                            <div className="tags_wrapper">
                              <Form.Control
                                type="number"
                                disabled={isPublished}
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={handleOnKeyPress}
                                onKeyDown={handleOnKeyDown}
                                maxLength="200"
                                placeholder="Enter passing marks"
                                name="passing_Marks"
                                className={[errors && errors.passing_Marks && "limit-reached"].join(" ")}
                                value={campaignData?.passing_Marks ?? ""}
                                onChange={(e) => {
                                  handlePassingMarks(e.target.name, +e.target.value);
                                  if (e.target.value.trim()) {
                                    setErrors({ ...errors, passing_Marks: false });
                                  } else {
                                    handleSetCampaign(e.target.name, "");
                                    setErrors({ ...errors, passing_Marks: true });
                                  }
                                }}
                              />
                            </div>
                          </Form.Group>

                          <Form.Group controlId="" className="mr-5">
                            <Form.Label>
                              Total Time<span className="text-danger">*</span>
                            </Form.Label>
                            <div className="tags_wrapper">
                              <Form.Control
                                type="number"
                                disabled={isPublished}
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={handleOnKeyPress}
                                onKeyDown={handleOnKeyDown}
                                maxLength="200"
                                placeholder="Enter time"
                                name="total_Time"
                                className={[errors && errors.total_Time && "limit-reached"].join(" ")}
                                value={campaignData?.total_Time ?? ""}
                                onBlur={(e) => updateCampaignData(e.target.name, +e.target.value)}
                                onChange={(e) => {
                                  handleTotalTime(e.target.name, +e.target.value);
                                  if (e.target.value.trim()) {
                                    setErrors({ ...errors, total_Time: false });
                                  } else {
                                    handleSetCampaign(e.target.name, "");
                                    setErrors({ ...errors, total_Time: true });
                                  }
                                }}
                              />
                            </div>
                          </Form.Group>

                          <Form.Group controlId="" className="mr-5">
                            <Form.Label>
                              Number Of Attempts<span className="text-danger">*</span>
                            </Form.Label>
                            <div className="tags_wrapper">
                              <Form.Control
                                type="number"
                                disabled={isPublished}
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={handleOnKeyPress}
                                onKeyDown={handleOnKeyDown}
                                maxLength="200"
                                placeholder="Enter number of attempts"
                                name="number_Of_Attemptes"
                                className={[errors && errors.number_Of_Attemptes && "limit-reached"].join(" ")}
                                value={campaignData?.number_Of_Attemptes ?? ""}
                                onBlur={(e) => updateCampaignData(e.target.name, +e.target.value)}
                                onChange={(e) => {
                                  handleNumberOfAttempts(e.target.name, +e.target.value);
                                  if (e.target.value.trim()) {
                                    setErrors({ ...errors, number_Of_Attemptes: false });
                                  } else {
                                    handleSetCampaign(e.target.name, "");
                                    setErrors({ ...errors, number_Of_Attemptes: true });
                                  }
                                }}
                              />
                            </div>
                          </Form.Group>
                        </>
                      )}
                    </div>
                  </div>
                  {/* import note for quiz */}
                  {campaignData?.category?.value === "Quiz" && (
                    <div className={["box", style.noteBox].join(" ")}>
                      <span>Note: &nbsp; </span>
                      <span>
                        The imported file name will be considered as question template name. Once the file has been
                        uploaded, name changes won&apos;t be possible. Please check the name before uploading.
                      </span>
                    </div>
                  )}
                  {campaignData?.questionTemplateId ? (
                    <div className={isPublished ? style.file_info_published : style.file_info}>
                      <span
                        onClick={() =>
                          history.push({
                            pathname: routePath.PREVIEW_TEMPLATE_ROUTE,
                            state: {
                              template: { questionTemplateId: campaignData.questionTemplateId },
                              hideButton: false,
                            },
                          })
                        }
                      >
                        {campaignData?.questionTemplateName?.length > 22
                          ? campaignData?.questionTemplateName?.substring(0, 18) + "..."
                          : campaignData?.questionTemplateName}
                      </span>
                      {!isPublished && (
                        <span className={style.delete_item} onClick={handleRemoveTemplate}>
                          <ReactSVG src={`${IC_SEARCH_RESET}`} title="" alt="" />
                        </span>
                      )}
                    </div>
                  ) : templateList?.length ? (
                    <button
                      type="button"
                      onClick={handleTemplatePopup}
                      className={["btn-round save_box_btn btn btn-secondary", style.select_que_btn].join(" ")}
                      disabled={
                        campaignData?.category?.value === "Quiz"
                          ? campaignData?.number_Of_Questions !== null &&
                            campaignData?.number_Of_Questions !== "" &&
                            campaignData?.number_Of_Questions !== 0
                            ? false
                            : true
                          : campaignData?.category?.value === "Survey"
                          ? false
                          : true
                      }
                    >
                      <strong>{btnName.SELECT_TEMPLATE}</strong>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleCreateTemplate}
                      className={["btn-round save_box_btn btn btn-secondary", style.select_que_btn].join(" ")}
                      disabled={
                        campaignData?.category?.value === "Quiz"
                          ? campaignData?.number_Of_Questions !== null &&
                            campaignData?.number_Of_Questions !== "" &&
                            campaignData?.number_Of_Questions !== 0
                            ? false
                            : true
                          : campaignData?.category?.value === "Survey"
                          ? false
                          : true
                      }
                    >
                      <strong>{btnName.CREATE_NEW_QUESTION}</strong>
                    </button>
                  )}
                  {campaignData?.category?.value === "Quiz" && !isPublished && (
                    <a href={DOWNLOAD_QUIZ_SAMPLE} download>
                      {" "}
                      <button
                        type="button"
                        className={["btn-round save_box_btn btn btn-secondary", style.sample_que_btn].join(" ")}
                      >
                        {" "}
                        <strong>Sample Question Template</strong>{" "}
                      </button>{" "}
                    </a>
                  )}
                  {campaignData?.category?.value === "Quiz" && !isPublished && (
                    <>
                      <input
                        id="fileid"
                        type="file"
                        accept=".xlsx"
                        hidden
                        ref={(input) => (inputElement = input)}
                        onChange={() => {
                          setFile(inputElement.files[0]);
                          uploadFileAPI(inputElement.files[0]);
                        }}
                      />
                      <button
                        onClick={() => {
                          inputElement.click();
                          // window.open("http://icpl13962:3000/question-builder/preview-template", "_blank");
                        }}
                        type="button"
                        className={["btn-round save_box_btn btn btn-secondary", style.import_que_btn].join(" ")}
                      >
                        <strong>Import Question Template</strong>
                      </button>
                    </>
                  )}
                </div>

                {campaignId && campaignData.subGroups?.length > 0 && (
                  <>
                    <div className="box-light-gradient"></div>
                    <div className={style.location_wise_filter_wrapper}>
                      <div className="box-heading-wrapper pl-0 pb-0 border-bottom-0">
                        <div className="box-heading">
                          <h2>Filter</h2>
                          <h3>Please add new row for different location </h3>
                        </div>
                      </div>
                      {campaignData &&
                        campaignData.campaignFilters.map((campaignFilter, index) => (
                          <LocationFilter
                            isDeleteBtnDisplay={!isPublished && campaignData.campaignFilters.length !== 1}
                            isStartDateDisabled={
                              campaignFilter?.location ? campaignFilter?.isFilterDropdownDisabled : true
                            }
                            isFilterDropdownDisabled={
                              campaignFilter?.location ? campaignFilter?.isFilterDropdownDisabled : true
                            }
                            isSaveFilterDisabled={!campaignFilter.isChange}
                            key={index}
                            locationList={locationList || []}
                            campaignFilter={campaignFilter}
                            handleLocationFilterChange={(name, value) =>
                              handleLocationFilterChange(name, value, campaignFilter.id)
                            }
                            disabled={campaignFilter?.isFilterDropdownDisabled}
                            handleSaveFilter={() => handleSaveFilter(campaignFilter.id)}
                            deleteRow={() => deleteRow(campaignFilter.id)}
                          />
                        ))}
                      {isAddNewRowBtn && (
                        <>
                          <span
                            className={[
                              style.add_location_set_text,
                              isAddNewRowBtnDisabled && "text_link disabled",
                            ].join(" ")}
                            onClick={addNewRow}
                          >
                            Add new row
                          </span>

                          <div className="custom-checkbox custom-checkbox-sm mt-4 mb-0">
                            <input
                              id={"CHECKED"}
                              type="checkbox"
                              checked={campaignData?.notify ?? false}
                              onChange={(e) => handleNotifyCampaign(e.target.checked)}
                            />
                            <label htmlFor={"CHECKED"}>
                              Would you like to send reminder notification till end date?
                            </label>
                          </div>
                          {campaignData?.questionTemplateId && campaignData?.category?.value !== "Quiz" && (
                            <div className="custom-checkbox custom-checkbox-sm mt-4 mb-0">
                              <input
                                id={"CHECKED_CAPABILITY"}
                                type="checkbox"
                                checked={campaignData?.isCapabilityDevelopmentFramework ?? false}
                                onChange={(e) => handleCapabilityDevelopment(e.target.checked)}
                                disabled={isPublished}
                              />
                              <label htmlFor={"CHECKED_CAPABILITY"}>Create for Capability Development</label>
                            </div>
                          )}
                          {campaignData?.isCapabilityDevelopmentFramework && (
                            <div className={[style.file_info, "ml-0 mt-3"].join(" ")}>
                              <span
                                onClick={() =>
                                  history.push(
                                    routePath.MAP_CAPABILITY_VIEW +
                                      `?id=${campaignData?.questionTemplateId}&campaignId=${campaignId}`
                                  )
                                }
                              >
                                Map Capability Template
                              </span>
                            </div>
                          )}
                          {campaignData?.eligibleEmployeeCount !== 0 && (
                            <div className="vd-obj-description mt-4 mb-0">
                              Total eligible employees for this {PAGE_NAME}:{" "}
                              <label>{campaignData?.eligibleEmployeeCount}</label>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
                {campaignData?.category?.value === "Quiz" && (
                  <div className={style.campaign_quiz_instructions}>
                    <span className={style.dd_title}>Instructions</span>
                    <Form.Control
                      as="textarea"
                      className={[style.desc_box, "form-control"].join(" ")}
                      name="instructions"
                      value={campaignData?.instructions}
                      maxLength="2000"
                      placeholder="Add new points"
                      onBlur={(e) => updateCampaignData(e.target.name, e.target.value)}
                      onChange={(e) => handleInstructions(e.target.name, e.target.value)}
                    />
                  </div>
                )}
              </Form>
            </div>
          </Col>
        </Row>
        <SubmitBox
          handleSubmit={handleCreateCampaign}
          updatedDate={null}
          name="Save"
          handleSecond={isPublished ? handleClose : handlePublishCampaign}
          secondBtnName={isPublished ? "Close" : isPreviewBtnDisplay ? "Publish" : ""}
        />
      </Container>
      <ConfirmationPopup
        show={confirmationData?.open}
        hide={() => setConfirmationData({})}
        submit={() => handleChangeSubGroups(confirmationData?.subGroupId, "CONFIRM")}
        headerText="Sure you want to de-select subgroup?"
        subText="If you de-select subGroup then below added location filter data will be remove"
        submitBtnText="Yes"
      />
      <QuestionTemplateModal
        hide={() => setIsModal(false)}
        previewPathname={routePath.PREVIEW_TEMPLATE_ROUTE}
        isSelectModal={campaignData.questionTemplateName}
        list={templateList}
        selectTemplate={selectTemplate}
        show={isModal}
        type={campaignData?.category?.value}
        numberOfQuestions={campaignData?.number_Of_Questions}
      />
      <ComfirmUploadPopup
        show={confirmUploadPopup}
        fileName={file && file.name}
        setFile={setFile}
        uploadFileAPI={uploadFileAPI}
        errorList={errorList}
        submit={() => {
          setConfirmUploadPopup(false);
          uploadRatingFile(questionData);
        }}
        handlePreview={handlePreview}
        errorFlag={errorFlag}
        hide={() => setConfirmUploadPopup(false)}
        isFromQuiz={true}
      />
    </div>
  );
}

export default EditCampaign;
