import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import style from "./DeclineRequests.module.scss";
import * as actions from "../../../../../store/actions/index";
import { filterArray } from "../../../../../util/general";
import { IC_INFO } from "../../../../../const/imgCost";
import Pagination from "../../../../Pagination/Pagination";
import NoDataFound from "../../../../NodataFound/NoDataFound";
import Spinner from "../../../../../shared/Spinner/Spinner";
import { competencyLevel, noData } from "const/constValue";

export default function DeclineRequests() {
  const { isLoading, size, getDeclineReqList } = useSelector((state) => state.skillMatrix);
  const [list, setReqList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const getDeclineReq = () => dispatch(actions.getDeclineReq);
    getDeclineReq();
  }, [dispatch]);

  useEffect(() => {
    if (getDeclineReqList && getDeclineReqList.length > 0) {
      setReqList(getDeclineReqList);
    }
  }, [getDeclineReqList]);

  useEffect(() => {
    filteredData.length > 0
      ? setReqList(filteredData)
      : searchFilter
      ? setReqList(filteredData)
      : setReqList(getDeclineReqList);
  }, [filteredData, getDeclineReqList, searchFilter]);

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    if (getDeclineReqList && getDeclineReqList.length > 0) {
      filteredData = filterArray(getDeclineReqList, filters);
      setFilteredData(filteredData);
      setSearchFilter("");
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className={[style.decline_req_wrapper, "box"].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Declined Requests</h2>
            <h3>Declined Requests for Endorsement</h3>
          </div>
        </div>
        <div className="box-light-gradient h38"></div>
        {getDeclineReqList && getDeclineReqList.length > 0 ? (
          <div className="box-inner-pad pb-0">
            <div className={[style.skill_table, "custom-table", "table-theme-1"].join(" ")}>
              <div className={[style.endorsement_skill_table, "position-relative"].join(" ")}>
                <ul className={style.thead}>
                  <li>
                    <span className={style.endorser_name}>Endorse To</span>
                    <span className={style.skill_name}>Skill Name</span>
                    <span className={[style.cmp_lvl, style.current].join(" ")}>Competency Level</span>
                    <span className={style.req_date}>Request Date</span>
                    <span className={style.dec_date}>Decline Date</span>
                    <span className={style.comments}>Comment</span>
                  </li>
                </ul>
                <ul className={style.tbody}>
                  {pageOfItems.map((data) => {
                    return (
                      <li key={data.id}>
                        <div className={style.endorser_name}>
                          <span>{data.endorsedToName}</span>
                          {data.endorseRequestByDepartmentName && (
                            <div className={[style.mmg_tooltip, "custom-tooltip", "mmg_table_tooltip"].join(" ")}>
                              <div className="box-info">
                                <span>
                                  <img className="svg" src={IC_INFO} alt="box-info" />
                                </span>
                              </div>
                              <div className="info-attach-file">
                                <p>
                                  Requested by{" "}
                                  <b>
                                    {data.endorseRequestByDepartmentName} ({data.endorseRequestByName})
                                  </b>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={style.skill_name}>
                          <span>{data.endorsedSkill.skillIdName}</span>
                        </div>
                        <div className={[style.cmp_lvl, style.current].join(" ")}>
                          <span
                            className={[
                              style.req_comp_level,
                              competencyLevel[data.endorsedSkill?.currentCompetencyLevelName],
                            ].join(" ")}
                          >
                            {competencyLevel[data.endorsedSkill?.currentCompetencyLevelName]}
                          </span>
                        </div>
                        <div className={style.req_date}>
                          <span>{moment(data.endorsedReqDate).format("MMMM D, YYYY")}</span>
                        </div>
                        <div className={style.dec_date}>
                          <span>{moment(data.declineDate).format("MMMM D, YYYY")}</span>
                        </div>
                        <div className={style.comments}>
                          <div className={style.comment_txt}>
                            {data.comment ? data.comment.slice(0, 20) : "-"}
                            <div className="d-inline-block position-relative">
                              {data.comment && data.comment.trim().length > 20 && (
                                <span className={style.more}>...More</span>
                              )}
                              <span className={style.description_tooltip}>{data.comment}</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            <div className="gap120"></div>
          </div>
        )}
      </div>
      {list && list.length !== 0 && (
        <Pagination pageSize={size} items={list} onChangePage={onChangePage} resetData={resetData} />
      )}
    </>
  );
}
