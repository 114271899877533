import React from "react";
import SingleFileView from "./SingleFileView";

function MultipleFile({ files, removeFile, isDeletable, isDownloadable, downloadFile }) {
  return (
    <ul className="file-listing w-100">
      {files &&
        files.map((value, index) => (
          <SingleFileView
            removeFile={removeFile}
            name={value.name}
            isDownloadable={isDownloadable}
            isDeletable={isDeletable}
            key={index}
            downloadFile={downloadFile}
            id={value.id}
          />
        ))}
    </ul>
  );
}

export default MultipleFile;
