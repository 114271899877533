import React from "react";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import { Link } from "react-router-dom";
import { dateFormat1 } from "util/dateUtils";
import { backGroundColor } from "const/constValue";

export default function MyTeamRow({
  team,
  index,
  previous,
  upComing,
  lapsed,
  pending,
  lapsedOneOnOne,
  previousOneOnOne,
  upcomingOneOnOne,
  isPendingOneOnOne,
}) {
  const { employeeId, empName, empInitial, displayPicture, parentGroupId, subGroupId } = team;

  return (
    <tr>
      <td>
        <Link to={`/okrprogress/one-on-one/team?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`}>
          <div className={style.tab_team_member_info}>
            <div className={style.team_member_info}>
              <div
                className={style.team_member_img}
                style={{ backgroundColor: backGroundColor[index % backGroundColor.length] }}
              >
                {displayPicture && displayPicture.length > 2 ? (
                  <img src={displayPicture} alt="pic" />
                ) : (
                  displayPicture || empInitial
                )}
              </div>
              <div className={style.team_member_details}>
                <span className={style.team_member_name}>{empName}</span>
              </div>
            </div>
          </div>
        </Link>
      </td>
      {previous && (
        <td className={style.center}>
          <span>{dateFormat1(previousOneOnOne)}</span>
        </td>
      )}
      {upComing && (
        <td className={style.center}>
          <span>{dateFormat1(upcomingOneOnOne)}</span>
        </td>
      )}
      {lapsed && (
        <td className={style.center}>
          <span>{dateFormat1(lapsedOneOnOne)}</span>
        </td>
      )}
      {pending && <td className={style.center}>{isPendingOneOnOne && <div className={style.pending}>Pending</div>}</td>}
    </tr>
  );
}
