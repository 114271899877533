import React from "react";
import { ReactSVG } from "react-svg";
import { IC_FILE_ATTACHMENT, IC_SEARCH_RESET } from "../../const/imgCost";
import PropTypes from "prop-types";

SingleFileView.propTypes = {
  name: PropTypes.string.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  isDownloadable: PropTypes.bool.isRequired,
  removeFile: PropTypes.func,
  downloadFile: PropTypes.func,
  id: PropTypes.number,
};

function SingleFileView({ name, isDeletable, removeFile, downloadFile, isDownloadable, id }) {
  return (
    <li>
      <span onClick={isDownloadable ? () => downloadFile(id) : () => {}}>
        <ReactSVG className="file-attachment-icon" src={IC_FILE_ATTACHMENT} title="" alt="" />
        {name}
      </span>
      {isDeletable && (
        <span className="delete-item" onClick={() => removeFile(id)}>
          <ReactSVG src={IC_SEARCH_RESET} title="" alt="" />
        </span>
      )}
    </li>
  );
}

export default SingleFileView;
