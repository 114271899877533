import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import style from "../QuestionBuilder.module.scss";
import { useSelector } from "react-redux";
import Select from "react-select";
import AddCategoryModal from "./AddCategoryModal/AddCategoryModal";
import AddScaleModal from "./AddScaleModal/AddScaleModal";
import AddPreviewModal from "./AddScaleModal/AddPreviewModal";
import { useDispatch } from "react-redux";
import storage from "util/storage";

export default function QuestionScaled(props) {
  const campaignType = storage.get("campaignType") ? storage.get("campaignType") : null;
  const dispatch = useDispatch();
  const { questionTemplate, templateCategoryList, templateScaleList } = useSelector((state) => state.performance);
  const [saveFlag, setSaveFlag] = useState(false);
  const [disabledDelete, setDisabledDelete] = useState(true);
  const [otherOption, setOtherOption] = useState({
    // "isOther": props.questionDetail.isOther,
    // "otherText": props.questionDetail.otherText,
    isRequired: props.questionDetail.isRequired,
    description: props.questionDetail.description,
    descriptionFlag: props.questionDetail.descriptionFlag,
    optionalCommentBox: props.questionDetail.optionalCommentBox,
    commentResponse: props.questionDetail.commentResponse,
    questionCategory: props.questionDetail.questionCategory,
    reverseScale: props.questionDetail.reverseScale,
    overAllScore: props.questionDetail.overAllScore,
    respondToManager: props.questionDetail.respondToManager,
    respondToEmployee: props.questionDetail.respondToEmployee,
    respondToAll: props.questionDetail.respondToAll,
    scaleObj: props.questionDetail.scaleObj,
    notShowScaleToEmployee: props.questionDetail.notShowScaleToEmployee,
    showManagerResponse: props.questionDetail.showManagerResponse,
    showManagerScore: props.questionDetail.showManagerScore,
  });

  const getCategoryList =
    templateCategoryList &&
    templateCategoryList.map((obj) => {
      return {
        value: obj.name,
        label: obj.name,
        name: obj.name,
        categoryId: obj.categoryId,
      };
    });
  /*for category modal */
  const [scale, setScale] = useState(false);
  const [isModalCategory, setIsModalCategory] = useState(false);
  const hideCategoryModal = () => {
    setIsModalCategory(!isModalCategory);
  };
  /*for scale modal */
  const [isModalScale, setIsModalScale] = useState(false);
  const hideScaleModal = () => {
    setIsModalScale(!isModalScale);
  };

  /*for preview-scale modal */
  const [isModalPreviewScale, setIsModalPreviewScale] = useState(false);
  const [currentSelectedScale, setCurrentSelectedScale] = useState(null);
  const hidePreviewScaleModal = () => {
    setIsModalPreviewScale(!isModalPreviewScale);
  };

  useEffect(() => {
    let flag = false;
    if (props.questionDetail.questionName === "") {
      flag = true;
    }
    if (questionTemplate.templateName === "") {
      flag = true;
    }
    if (props.questionDetail.descriptionFlag) {
      if (props.questionDetail.description === "") {
        flag = true;
      }
    }
    if (
      props.respondOptionVisible &&
      !(otherOption.respondToAll || otherOption.respondToManager || otherOption.respondToEmployee)
    ) {
      flag = true;
    }
    if (!otherOption.scaleObj) {
      flag = true;
    }
    flag ? setSaveFlag(true) : setSaveFlag(false);
    if (questionTemplate.questions.length === 1) {
      setDisabledDelete(true);
    } else {
      if (questionTemplate.questions[1].questionId === undefined) {
        setDisabledDelete(true);
      } else {
        setDisabledDelete(false);
      }
    }
  }, [questionTemplate]);

  const getSelectedValue = (e, id) => {
    let arr = templateScaleList.find((obj) => obj.scaleId === id);
    setOtherOption({ ...otherOption, scaleObj: arr });
    let oP = {
      ...otherOption,
      scaleObj: arr,
    };
    let data = {
      type: "otherOption",
      questionTitle: props.questionDetail.questionTitle,
      questionId: props.questionDetail.questionId,
      otherOption: oP,
      dropdown: "SCALED",
    };
    props.setTemplateData(data);
    setScale(false);
  };

  return (
    <div className={style.answer_l_wrapper} onClick={() => scale && setScale(false)}>
      <div className={style.scale_field_wrapper}>
        <div className={style.category_title_wrapper}>
          <span className={style.dd_title}>Scale</span>
          <span className={style.modal_text} onClick={() => hideScaleModal()}>
            Add New Scale
          </span>
        </div>
        <div className={style.scale_dropdown_wrapper}>
          <div
            className={style.scale_dd_topbar}
            onClick={() => {
              dispatch({
                type: "SET_ONCHANGE",
                data: false,
              });
              setScale(!scale);
            }}
          >
            <input type="hidden" id="hiddenTitleDoprdown" />
            <h5>
              {otherOption.scaleObj
                ? (props.questionDetail.scaleObj && props.questionDetail.scaleObj.scaleDesc) ||
                  (otherOption.scaleObj && otherOption.scaleObj.scaleDesc)
                : "Select Scale"}
            </h5>
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
          {scale && (
            <div className={style.scale_dd_menu}>
              <ul className={style.scale_dd_list}>
                {templateScaleList &&
                  templateScaleList
                    .filter((v) => v.name)
                    .map((scale) => {
                      let nameArray = scale.name?.split("/");
                      return (
                        <li key={scale.scaleId} id={scale.scaleId}>
                          <div
                            onClick={(e) => getSelectedValue(e, scale.scaleId)}
                            className={[style.item_left_content].join(" ")}
                          >
                            <h5>{scale.scaleDesc}</h5>
                            <span>
                              {nameArray[0]} - {nameArray[nameArray.length - 1]}
                            </span>
                          </div>
                          <span
                            className={style.item_right_content}
                            onClick={() => {
                              hidePreviewScaleModal();
                              setCurrentSelectedScale(scale);
                            }}
                          >
                            Preview
                          </span>
                        </li>
                      );
                    })}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className={style.quesion_category_wrapper}>
        <div className={style.category_title_wrapper}>
          <span className={style.dd_title}>Category</span>
          <span className={style.modal_text} onClick={() => hideCategoryModal()}>
            Add New Category
          </span>
        </div>
        <div className="card_action">
          <Select
            options={getCategoryList}
            className="custom-default-dd-box"
            classNamePrefix="select"
            isSearchable={true}
            isClearable={true}
            placeholder="Select the Category"
            value={
              otherOption.questionCategory === null || otherOption.questionCategory === ""
                ? null
                : {
                    value: otherOption.questionCategory.name,
                    label: otherOption.questionCategory.name,
                    name: otherOption.questionCategory.name,
                  }
            }
            onChange={(e) => {
              setOtherOption({
                ...otherOption,
                questionCategory:
                  e === null
                    ? null
                    : {
                        value: e.value,
                        label: e.value,
                        name: e.value,
                      },
              });
              let oP = {
                ...otherOption,
                questionCategory:
                  e === null
                    ? null
                    : {
                        value: e.value,
                        label: e.value,
                        name: e.value,
                      },
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                questionId: props.questionDetail.questionId,
                otherOption: oP,
                dropdown: "SCALED",
              };
              props.setTemplateData(data);
            }}
          />
        </div>
      </div>
      <div className={style.other_field_wrapper}>
        <div className="custom-checkbox no_mar">
          <input
            id={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 3143
                : props.questionDetail.questionId + "_checkbox" + 3143
            }
            type="checkbox"
            checked={otherOption.descriptionFlag}
            onChange={(e) => {
              setOtherOption({
                ...otherOption,
                descriptionFlag: e.target.checked,
                description: e.target.checked ? otherOption.description : "",
              });
              let oP = {
                ...otherOption,
                descriptionFlag: e.target.checked,
                description: e.target.checked ? otherOption.description : "",
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                questionId: props.questionDetail.questionId,
                otherOption: oP,
                dropdown: "SCALED",
              };
              props.setTemplateData(data);
            }}
          />
          <label
            htmlFor={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 3143
                : props.questionDetail.questionId + "_checkbox" + 3143
            }
          >
            Add help text/description to the question
          </label>
        </div>
        {otherOption.descriptionFlag && (
          <Form.Group className={style.other_text_box} controlId="">
            <div className="textarea-wrapper">
              <Form.Control
                as="textarea"
                maxLength="1000"
                placeholder="Add a comment"
                value={otherOption.description}
                className={otherOption.descriptionFlag ? (otherOption.description === "" ? "error" : "") : ""}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, description: e.target.value });
                  let oP = {
                    ...otherOption,
                    description: e.target.value,
                  };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    questionId: props.questionDetail.questionId,
                    otherOption: oP,
                    dropdown: "SCALED",
                  };
                  props.setTemplateData(data);
                }}
              ></Form.Control>
            </div>
          </Form.Group>
        )}
      </div>
      <div className={style.mandtory_field_wrapper}>
        <div className="custom-checkbox no_mar">
          <input
            id={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 41
                : props.questionDetail.questionId + "_checkbox" + 41
            }
            type="checkbox"
            checked={otherOption.isRequired}
            onChange={(e) => {
              setOtherOption({ ...otherOption, isRequired: e.target.checked });
              let oP = {
                ...otherOption,
                isRequired: e.target.checked,
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                questionId: props.questionDetail.questionId,
                otherOption: oP,
                dropdown: "SCALED",
              };
              props.setTemplateData(data);
            }}
          />
          <label
            htmlFor={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 41
                : props.questionDetail.questionId + "_checkbox" + 41
            }
          >
            Mark the field as required(<span className="text-danger">*</span>)
          </label>
        </div>
      </div>
      <div className={style.cmt_with_attach_field_wrapper}>
        <div className="custom-checkbox no_mar">
          <input
            id={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 51
                : props.questionDetail.questionId + "_checkbox" + 51
            }
            type="checkbox"
            checked={otherOption.optionalCommentBox}
            onChange={(e) => {
              setOtherOption({
                ...otherOption,
                optionalCommentBox: e.target.checked,
                notShowScaleToEmployee: e.target.checked === false && false,
                showManagerResponse: e.target.checked === false && false,
                showManagerScore: e.target.checked === false && false,
              });
              let oP = {
                ...otherOption,
                notShowScaleToEmployee: e.target.checked === false && false,
                showManagerResponse: e.target.checked === false && false,
                showManagerScore: e.target.checked === false && false,
                optionalCommentBox: e.target.checked,
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                questionId: props.questionDetail.questionId,
                otherOption: oP,
                dropdown: "SCALED",
              };
              props.setTemplateData(data);
            }}
          />
          <label
            htmlFor={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 51
                : props.questionDetail.questionId + "_checkbox" + 51
            }
          >
            {campaignType === "Survey" ? "Add comment box" : "Add comment box where we can also attach files"}
          </label>
        </div>
        {otherOption.optionalCommentBox && (
          <div className={style.attchment_wrapper}>
            <h5>Comment on response is</h5>
            <div className={style.cb_box_wrapper}>
              <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1">
                <input
                  id={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_checkbox_optional"
                      : props.questionDetail.questionId + "_checkbox_optional"
                  }
                  type="radio"
                  value="Optional"
                  checked={otherOption.commentResponse === "Optional" ? true : false}
                  onClick={() => {
                    setOtherOption({ ...otherOption, commentResponse: "Optional" });
                    let oP = {
                      ...otherOption,
                      commentResponse: "Optional",
                    };
                    let data = {
                      type: "otherOption",
                      questionTitle: props.questionDetail.questionTitle,
                      questionId: props.questionDetail.questionId,
                      otherOption: oP,
                      dropdown: "SCALED",
                    };
                    props.setTemplateData(data);
                  }}
                />
                <label
                  htmlFor={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_checkbox_optional"
                      : props.questionDetail.questionId + "_checkbox_optional"
                  }
                >
                  Optional
                </label>
              </div>

              <div className="custom-checkbox custom-checkbox-sm custom-checkbox-rounded custom-checkbox-theme-1 ">
                <input
                  id={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_checkbox_required"
                      : props.questionDetail.questionId + "_checkbox_required"
                  }
                  type="radio"
                  value="Required"
                  checked={otherOption.commentResponse === "Required" ? true : false}
                  onClick={() => {
                    setOtherOption({ ...otherOption, commentResponse: "Required" });
                    let oP = {
                      ...otherOption,
                      commentResponse: "Required",
                    };
                    let data = {
                      type: "otherOption",
                      questionTitle: props.questionDetail.questionTitle,
                      questionId: props.questionDetail.questionId,
                      otherOption: oP,
                      dropdown: "SCALED",
                    };
                    props.setTemplateData(data);
                  }}
                />
                <label
                  htmlFor={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_checkbox_required"
                      : props.questionDetail.questionId + "_checkbox_required"
                  }
                >
                  Required
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={style.mandtory_field_wrapper}>
        <div className="custom-checkbox no_mar">
          <input
            id={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox_score"
                : props.questionDetail.questionId + "_checkbox_score"
            }
            type="checkbox"
            checked={otherOption.overAllScore}
            onChange={(e) => {
              setOtherOption({ ...otherOption, overAllScore: e.target.checked });
              let oP = {
                ...otherOption,
                overAllScore: e.target.checked,
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                questionId: props.questionDetail.questionId,
                otherOption: oP,
                dropdown: "SCALED",
              };
              props.setTemplateData(data);
            }}
          />
          <label
            htmlFor={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox_score"
                : props.questionDetail.questionId + "_checkbox_score"
            }
          >
            Include in overall score in reporting
          </label>
        </div>
      </div>
      {props.respondOptionVisible && (
        <div className={style.multi_cb_wrapper}>
          <h5>
            Who do you want to respond to this Question?<span className="text-danger">*</span>
          </h5>
          <div className={style.cb_box_wrapper}>
            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_manager"
                    : props.questionDetail.questionId + "_checkbox_manager"
                }
                type="checkbox"
                name="respond-question"
                value="Managers"
                checked={otherOption.respondToManager}
                onChange={(e) => {
                  setOtherOption({
                    ...otherOption,
                    respondToManager: e.target.checked,
                    showManagerResponse: e.target.checked === false && false,
                    showManagerScore: e.target.checked === false && false,
                  });
                  let oP = {
                    ...otherOption,
                    respondToManager: e.target.checked,
                    showManagerResponse: e.target.checked === false && false,
                    showManagerScore: e.target.checked === false && false,
                  };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    questionId: props.questionDetail.questionId,
                    otherOption: oP,
                    dropdown: "SCALED",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_manager"
                    : props.questionDetail.questionId + "_checkbox_manager"
                }
              >
                Managers
              </label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_employee"
                    : props.questionDetail.questionId + "_checkbox_employee"
                }
                type="checkbox"
                name="respond-question"
                value="Employee"
                checked={otherOption.respondToEmployee}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, respondToEmployee: e.target.checked });
                  let oP = { ...otherOption, respondToEmployee: e.target.checked };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    questionId: props.questionDetail.questionId,
                    otherOption: oP,
                    dropdown: "SCALED",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_employee"
                    : props.questionDetail.questionId + "_checkbox_employee"
                }
              >
                Employee
              </label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_all"
                    : props.questionDetail.questionId + "_checkbox_all"
                }
                type="checkbox"
                name="respond-question"
                value="All"
                checked={otherOption.respondToAll}
                onChange={(e) => {
                  setOtherOption({
                    ...otherOption,
                    respondToAll: e.target.checked,
                    notShowScaleToEmployee: e.target.checked === false ? false : false,
                    showManagerResponse: e.target.checked === false && false,
                    showManagerScore: e.target.checked === false && false,
                  });
                  let oP = {
                    ...otherOption,
                    respondToAll: e.target.checked,
                    notShowScaleToEmployee: e.target.checked === false ? false : false,
                    showManagerResponse: e.target.checked === false && false,
                    showManagerScore: e.target.checked === false && false,
                  };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    questionId: props.questionDetail.questionId,
                    otherOption: oP,
                    dropdown: "SCALED",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_all"
                    : props.questionDetail.questionId + "_checkbox_all"
                }
              >
                Both
              </label>
            </div>
          </div>
          {otherOption.optionalCommentBox && otherOption.commentResponse === "Required" && otherOption.respondToAll && (
            <div className="custom-checkbox custom-checkbox-sm ml-4">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_DO_NOT_SHOW"
                    : props.questionDetail.questionId + "_DO_NOT_SHOW"
                }
                type="checkbox"
                name="respond-question"
                value="don't show"
                checked={otherOption.notShowScaleToEmployee}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, notShowScaleToEmployee: e.target.checked });
                  let oP = { ...otherOption, notShowScaleToEmployee: e.target.checked };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    questionId: props.questionDetail.questionId,
                    otherOption: oP,
                    dropdown: "SCALED",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_DO_NOT_SHOW"
                    : props.questionDetail.questionId + "_DO_NOT_SHOW"
                }
              >
                Do not show scale to Employees
              </label>
            </div>
          )}
          {otherOption.respondToAll && (
            <>
              <div className="custom-checkbox custom-checkbox-sm ml-4">
                <input
                  id={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_SHARE_MANAGER_RESPONSE"
                      : props.questionDetail.questionId + "_SHARE_MANAGER_RESPONSE"
                  }
                  type="checkbox"
                  checked={otherOption.showManagerResponse}
                  onChange={(e) => {
                    setOtherOption({ ...otherOption, showManagerResponse: e.target.checked });
                    let oP = { ...otherOption, showManagerResponse: e.target.checked };
                    let data = {
                      type: "otherOption",
                      questionTitle: props.questionDetail.questionTitle,
                      questionId: props.questionDetail.questionId,
                      otherOption: oP,
                      dropdown: "SCALED",
                    };
                    props.setTemplateData(data);
                  }}
                />
                <label
                  htmlFor={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_SHARE_MANAGER_RESPONSE"
                      : props.questionDetail.questionId + "_SHARE_MANAGER_RESPONSE"
                  }
                >
                  On publishing, share Manager response with Employee
                </label>
              </div>
              <div className="custom-checkbox custom-checkbox-sm ml-4">
                <input
                  id={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_SHARE_MANAGER_SCORE"
                      : props.questionDetail.questionId + "_SHARE_MANAGER_SCORE"
                  }
                  type="checkbox"
                  checked={otherOption.showManagerScore}
                  onChange={(e) => {
                    setOtherOption({ ...otherOption, showManagerScore: e.target.checked });
                    let oP = { ...otherOption, showManagerScore: e.target.checked };
                    let data = {
                      type: "otherOption",
                      questionTitle: props.questionDetail.questionTitle,
                      questionId: props.questionDetail.questionId,
                      otherOption: oP,
                      dropdown: "SCALED",
                    };
                    props.setTemplateData(data);
                  }}
                />
                <label
                  htmlFor={
                    props.questionDetail.questionTitle
                      ? props.questionDetail.questionTitle + "_SHARE_MANAGER_SCORE"
                      : props.questionDetail.questionId + "_SHARE_MANAGER_SCORE"
                  }
                >
                  On publishing, share Manager score with Employee
                </label>
              </div>
            </>
          )}
        </div>
      )}
      <div className={style.que_btn_group}>
        <Button
          variant="primary"
          disabled={saveFlag}
          onClick={() => {
            props.saveQuestion(props.questionDetail);
          }}
        >
          Save
        </Button>
        <Button
          variant="secondary"
          disabled={disabledDelete}
          onClick={() => {
            props.removeQuestion(props.questionDetail);
          }}
        >
          Delete
        </Button>
      </div>
      <AddScaleModal show={isModalScale} hide={hideScaleModal} />
      <AddPreviewModal
        show={isModalPreviewScale}
        hide={hidePreviewScaleModal}
        currentSelectedScale={currentSelectedScale}
      />
      <AddCategoryModal show={isModalCategory} hide={hideCategoryModal} />
    </div>
  );
}
