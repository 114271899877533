import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { IC_EXPAND, IC_COLLAPSE, IC_ASSIGN_OBJECTIVE } from "const/imgCost";
import CustomDropdown from "shared/Dropdown/CustomDropdown";
import ObjectiveKeyPoints from "./ObjectiveKeyPoints";
import { useQuery } from "util/utils";
import style from "./AllObjectives.module.scss"

const MyCreatedRows = (props) => {
  const dispatch = useDispatch();
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const { expanded } = useSelector((state) => state.objectives);
  const data = props.teamRow;
  const toggleExpander = (id) => {
    expanded === id
      ? dispatch({ type: "EXPANDED", expanded: null })
      : dispatch({ type: "EXPANDED", expanded: id });
  };

  const tags = data?.tags?.map((tag) => tag.name);

  return (
    <tbody>
      <tr className={expanded === data.objectiveId ? "tr_expanded" : ""}>
        <td style={{ fontWeight: "bold" }} className="text-break">
          <span>
            <ReactSVG
              src={
                expanded === data.objectiveId
                  ? `${IC_COLLAPSE}`
                  : `${IC_EXPAND}`
              }
              onClick={() => {
                toggleExpander(data.objectiveId);
              }}
            />
          </span>
          {data.title}
        </td>
        <td>{data.keyPoints.length}</td>
        <td></td>
        <td>
          {tags?.join(", ").length > 25 ? (
            <div
              className={[
                style.filter_info_tooltip,
                style.filter_info_tooltip_pending,
                "custom-tooltip",
              ].join(" ")}
            >
              <div className="box-info">
                <div className={style.tagsWrapper}>{tags?.join(", ")}</div>
              </div>
              <div className="info-attach-file">{tags?.join(", ")}</div>
            </div>
          ) : (
            <div>{tags?.join(", ")}</div>
          )}
        </td>
        <td className="action-dropdown-wrapper">
          <CustomDropdown
            customText="Assign Objective"
            customPath={{
              pathname: `/objectives/all-objectives/assign-objective/${data.objectiveId}`,
              search: employeeId
                ? `?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`
                : "",
              state: { teamObjective: true },
            }}
            customSVG={IC_ASSIGN_OBJECTIVE}
            editPath={`/objectives/edit-objective/${data.objectiveId}`}
            handleDelete={() => {
              props.getObjective(data.objectiveId);
            }}
            viewPath={{
              pathname: `/objectives/all-objectives/created-objective/${data.objectiveId}`,
              state: { from: "MyCreatedObjective" },
            }}
            deleteText="Delete"
            viewText="View Details"
            editText="Edit Objective"
          />
        </td>
      </tr>
      {expanded === data.objectiveId && (
        <ObjectiveKeyPoints keyPoints={data.keyPoints} />
      )}
    </tbody>
  );
};
export default MyCreatedRows;
