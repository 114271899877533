import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import config from "util/config";
import storage from "util/storage";

function HRMetricsDashboard() {
  const token = storage.get("token");
  const DASHBOARD_BASE_URL = process.env.DASHBOARD_BASE_URL || config.url.DASHBOARD_BASE_URL;
  const sendData = () => {
    let ifr = document.getElementById("f").contentWindow;
    ifr.postMessage({ "engage-token": `${token}${process.env.REACT_APP_TOKEN_LINK_KEY}` }, "*");
  };
  return (
    <div>
      <SubNavigation type="Analysis" />
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0">HR</h1>
            <span className="st-breadcrumb">
              Analysis <span>Metrics Dashboard</span>
            </span>
          </Col>
        </Row>
      </Container>
      <iframe
        onLoad={sendData}
        style={{ border: "none" }}
        src={`${DASHBOARD_BASE_URL}/?token=${token}${process.env.REACT_APP_TOKEN_LINK_KEY}`}
        id="f"
        width="100%"
        height="600px"
      ></iframe>
    </div>
  );
}

export default HRMetricsDashboard;
