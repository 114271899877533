import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Link, useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import AnimateHeight from "react-animate-height";
import AuthContext, { ActionType } from "context/authContext";
import style from "./mobileNavigation.module.scss";
import * as CryptoJS from "crypto-js";
import { IC_LOG_OUT, IC_MENU_HAM_BURGER, IC_NOTIFICATION_CLOSE, MAIN_LOGO } from "const/imgCost";
import { joinSpace } from "util/general";
import storage from "util/storage";
import routePath from "const/routePath";

/**
 * PROPS : reviewer
 */

export default function MobileNavigation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const context = useContext(AuthContext);
  const pageView = storage.getPageView();
  const { navigation } = context.state;
  const [showNavigation, setShowNavigation] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [height, setHeight] = useState({
    checkins: 0,
    objectives: 0,
    performance: 0,
    mmg: 0,
    university: 0,
    "skill-matrix": 0,
    "role-change": 0,
    endorsement: 0,
    "mmg-feedback": 0,
    analysis: 0,
    feedback: 0,
    "employee-objective": 0,
    "1-on-1's": 0,
  });

  const defaultHeight = {
    checkins: 0,
    objectives: 0,
    performance: 0,
    mmg: 0,
    university: 0,
    "skill-matrix": 0,
    "role-change": 0,
    endorsement: 0,
    "mmg-feedback": 0,
    analysis: 0,
    feedback: 0,
    "employee-objective": 0,
    "1-on-1's": 0,
  };

  const notificationToggle = () => {
    if (!showNavigation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    setShowNavigation(!showNavigation);
    document.body.style.overflow = !showNavigation ? "hidden" : "";
  };
  const notifyClass = showNavigation ? "show_mobile_nav" : "";

  const onLogout = () => {
    storage.clear();
    dispatch({ type: "USER_LOGOUT" });
    context.dispatch({ type: ActionType.LOGOUT });
    history.push("/");
    notificationToggle();
  };

  const onMenuExpand = () => {
    setMenuOpen((state) => !state);
  };

  const handleNavClick = (nav) => {
    if (nav.subModuleAndRights?.length > 0) {
      setHeight({
        ...height,
        ...defaultHeight,
        [joinSpace(nav.moduleName)]: height[joinSpace(nav.moduleName)] === 0 ? "auto" : 0,
      });
    } else {
      history.push(nav.url || "/");
      notificationToggle();
    }
  };

  const handlePageView = (data) => {
    let modules;
    if (data === "DEFAULT") {
      history.push(routePath.HOME);
      modules = storage.getDefaultMenu();
    } else {
      history.push(data.url || "/");
      modules = pageView.find((page) => page.pageView === data.pageView)?.modulesAndRights;
    }
    let moduleEncrypted = CryptoJS.AES.encrypt(JSON.stringify(modules), process.env.REACT_APP_CRYPTO_PRIVATE_KEY);
    if (modules?.length > 0) {
      storage.set("_MO_Vu__", moduleEncrypted.toString());
      context.dispatch({ type: ActionType.CHANGE_PAGE, payload: modules });
      notificationToggle();
      setMenuOpen(false);
    }
  };

  return (
    <>
      <span className={style.hamburger_menu} onClick={notificationToggle}>
        <i className={style.line}></i>
      </span>
      <div className={["mobile_nav_wrapper", notifyClass].join(" ")}>
        <div className="mobile_nav_overlay" onClick={notificationToggle}></div>
        <div className="mobile_nav_slider">
          <div className="header">
            <div className="header-top">
              <div className="logo">
                <img src={MAIN_LOGO} title="" alt="" />
              </div>
              <div className="mobile_nav_close" onClick={notificationToggle}>
                <ReactSVG src={`${IC_NOTIFICATION_CLOSE}`} title="" alt="" />
              </div>
            </div>
            <div className={["profile-detail-wrapper", menuOpen && "active"].join(" ")}>
              <div className="profile-detail">
                <Link to={routePath.HOME} onClick={notificationToggle}>
                  <span className="user_icon">
                    {context.state.user.displayPicture ? (
                      <img src={context.state.user.displayPicture} title="" alt="" />
                    ) : (
                      context.state.user.empInitial
                    )}
                  </span>
                  <div className="emp-info">
                    <h5>{context.state.user.displayName}</h5>
                    <span>{context.state.user.designation}</span>
                  </div>
                </Link>
                <span className="logout" onClick={pageView?.length ? onMenuExpand : onLogout}>
                  {pageView?.length > 0 ? (
                    <ReactSVG src={`${IC_MENU_HAM_BURGER}`} alt="icon" />
                  ) : (
                    <ReactSVG src={`${IC_LOG_OUT}`} alt="icon" />
                  )}
                </span>
              </div>
              {menuOpen && (
                <div className="mobile-admin-nav-menu">
                  <NavLink to="/" onClick={() => handlePageView("DEFAULT")}>
                    My View
                  </NavLink>
                  {pageView.map((page, index) => (
                    <NavLink
                      to={page.url || "/"}
                      key={index + page.url}
                      className={index === pageView?.length - 1 ? "last-menu" : ""}
                      onClick={() => handlePageView(page)}
                    >
                      {page.pageView}
                    </NavLink>
                  ))}
                  <div className="divide-line"></div>
                  <NavLink to="/" onClick={onLogout}>
                    Sign Out
                  </NavLink>
                </div>
              )}
            </div>
          </div>
          <div className="mobile_nav_list_wrapper">
            <ul className="nav-link">
              {navigation &&
                navigation.map((nav, index) => (
                  <li
                    key={index}
                    className={[
                      "nav-item",
                      height[joinSpace(nav.moduleName)] === "auto" && "open",
                      nav.subModuleAndRights?.length > 0 && "isSubmenu",
                    ].join(" ")}
                  >
                    <NavLink
                      to={nav.subModuleAndRights?.length > 0 ? "#" : nav.url || "/"}
                      onClick={() => handleNavClick(nav)}
                    >
                      {nav.moduleName}
                    </NavLink>
                    {nav?.subModuleAndRights?.length > 0 && (
                      <AnimateHeight duration={500} height={height[joinSpace(nav.moduleName)] || 0}>
                        <ul className="sub-nav-links">
                          {nav.subModuleAndRights.map((subNav, index) => (
                            <li className="nav-item" key={index}>
                              <NavLink
                                to={subNav.url || "/"}
                                exact
                                activeClassName="selected"
                                onClick={notificationToggle}
                              >
                                {subNav.subModuleName}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </AnimateHeight>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
