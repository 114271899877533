import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import * as actions from "store/actions/index";
import { noData, tooltipText } from "const/constValue";
import {
  GREEN_UP_ARROW,
  IC_COLLAPSE,
  IC_EXPAND,
  IC_INFO,
  MEMBER_NOT_FOUND,
  RED_DOWN_ARROW,
  YELLOW_STABLE_ARROW,
} from "const/imgCost";
import style from "./TeamMemberTable.module.scss";
import EmployeeListRow from "components/EmployeeListPopup/EmployeeListRow";
import NoDataFound from "components/NodataFound/NoDataFound";
import TeamMemberTimelineGraph from "./TeamMemberTimelineGraph";
import NoRecordFound from "components/NodataFound/NoRecordFound";

function TeamMemberTable({ teamMemberList, handleAssessmentClick, graphType ,selectedCycle}) {
  const dispatch = useDispatch();
  const { multiExpanded } = useSelector((state) => state.managerDashboard);
  const { employeesTimeline } = useSelector((state) => state.performance);
  const [viewAll, setViewAll] = useState(false);

  const toggleExpander = (employeeIds) => {
    if (!employeesTimeline.find((value) => value.employeeId === employeeIds?.employeeId)) {
      dispatch(actions.getEmployeeTimelineForManager("currentDate", employeeIds));
    }
    multiExpanded.includes(employeeIds?.employeeId)
      ? dispatch({ type: "REMOVE_MULTI_EXPAND", payload: employeeIds?.employeeId })
      : dispatch({ type: "MULTI_EXPAND", payload: employeeIds?.employeeId });
  };

  const handleMonthDropdown = (data, employeeIds) => {
    dispatch(actions.getEmployeeTimelineForManager(data.value, employeeIds));
  };

  const handleDeallocationGraphClick = (data, index, employeeIds) => {
    const win = window.open(
      `/performance/project-deallocation/${data["projectDeAllocationId" + index]}?empId=${
        employeeIds?.employeeId
      }&pgId=${employeeIds?.employeeParentGroupId}&sgId=${employeeIds?.employeeSubGroupId}`,
      "_blank"
    );
    win.focus();
  };

  const secondColumns = (data) => {
    if (!graphType) {
      return null;
    }
    let image = <ReactSVG src={YELLOW_STABLE_ARROW} title="" alt="info" />;
    let rating;
    if (data.gradeOrRatingOrBoth === "RATING") {
      rating = data.rating;
    } else if (data.gradeOrRatingOrBoth === "BOTH") {
      rating = data.grade ? `${data.grade} (${data.rating})` : null;
    } else {
      rating = data.grade;
    }
    if (data.upOrDownorStable === "UP") {
      image = <ReactSVG src={GREEN_UP_ARROW} title="" alt="info" />;
    } else if (data.upOrDownorStable === "DOWN") {
      image = <ReactSVG src={RED_DOWN_ARROW} title="" alt="info" />;
    }
    return (
      <div className="rating-with-svg">
        {rating ? (
          <>
            {rating}
            <span>{image}</span>
          </>
        ) : (
          "-"
        )}
      </div>
    );
  };

  return (
    <div className={style.team_member_table_wrapper}>
      <Table>
        <thead>
          <tr>
            <td className={style.name}>Name</td>
            {graphType && (
              <td className={style.count}>
                {graphType}
                <div className={style.show_cycleName_tooltip}>
                  <div className={style.info_icon}>
                    <ReactSVG src={`${IC_INFO}`} title="" alt="info" />
                  </div>
                  <div className={style.show_cycleName_tooltip_info}>
                    <span>{tooltipText.UP_DOWN_STABLE}</span>
                  </div>
                </div>
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {teamMemberList?.length > 0 ? (
            teamMemberList
              .filter((member, index) => index < 5 || viewAll)
              .map((member, index) => {
                let employeeGraphData = employeesTimeline.find((value) => value.employeeId === member?.employeeId);
                let employeeIds = {
                  employeeId: member.employeeId,
                  employeeParentGroupId: member?.parentGroupId,
                  employeeSubGroupId: member?.subGroupId,
                };
                let toggleExpand =
                  employeeGraphData && multiExpanded.includes(member?.employeeId) ? IC_COLLAPSE : IC_EXPAND;
                return (
                  <React.Fragment key={index}>
                    <EmployeeListRow
                      key={index}
                      toggleExpander={() => toggleExpander(employeeIds)}
                      toggleIcon={toggleExpand}
                      index={index}
                      displayPicture={member?.displayPicture || member?.empInitial}
                      secondColum={secondColumns(member)}
                      handleEmployeeClick={() => {}}
                      department={member?.department}
                      designation={member?.designation}
                      empName={member?.empName}
                      id={member?.id}
                    />
                    {employeeGraphData && multiExpanded.includes(member?.employeeId) && (
                      <tr className={style.inside_row}>
                        <td colSpan="3">
                          {employeeGraphData && employeeGraphData?.timelineGraph?.length > 0 ? (
                            <TeamMemberTimelineGraph
                              handleTeamCycleDropdown={(data) => handleMonthDropdown(data, employeeIds)}
                              employeeGraphData={employeeGraphData}
                              handleGraphClick={handleAssessmentClick}
                              handleDeallocationGraphClick={(data, index) =>
                                handleDeallocationGraphClick(data, index, employeeIds)
                              }
                              selectedCycle={selectedCycle}
                            />
                          ) : (
                            <>
                              <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                              <div className="gap120"></div>
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
          ) : (
            <tr className={style.inside_row}>
              <td colSpan="3">
                <NoRecordFound
                  image={`${MEMBER_NOT_FOUND}`}
                  title={noData.MEMBER_NOT_FOUND}
                  description={noData.MEMBER_NOT_FOUND_DESCRIPTION}
                />

                <div className="gap120"></div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!viewAll && teamMemberList.length > 5 && (
        <div className={style.view_all_team}>
          <span onClick={() => setViewAll(true)}>View All</span>
        </div>
      )}
    </div>
  );
}

export default TeamMemberTable;
