import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import * as actions from "store/actions/index";
import { GetReviewer, GetUser } from "store/actions";
import { requiredFieldMissing, successToast, errorToast } from "util/general";
import { IMG_CHECKINS_LAPSED } from "const/imgCost";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import BoxHeading from "shared/Box/BoxHeading";
import UserProfile from "../../user_profile/user_profile";
import QuestionTemplate from "../../Performance/CycleMemberProfile/QuestionTemplate/QuestionTemplate";
import ReviewerProfile from "../../reviewer-profile/reviewer-profile";
import SubmitBox from "../../SubmitBox/SubmitBox";
import SubmittedDateBox from "../../SubmitBox/SubmittedDateBox";
import NoRecordFound from "../../NodataFound/NoRecordFound";
import SubNavigation from "../../Header/SubNavigation/SubNavigation";
import CommentsTop from "components/CommentBox/CommentsTop";
import Comment from "components/CommentBox/Comment";
import { backGroundColor, text } from "const/constValue";

function CampaignQuestionView(props) {
  const employeeIds = storage.getEmployeeIds();
  const capabilityId = props.match.params.id;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.myTeam);
  const { reviewer } = useSelector((state) => state.checkIn);
  const {
    employeeCampaignData,
    isManagerSubmitted,
    isCampaignLoading,
    apiResponse,
    isSubmitted,
    isLoading,
    submittedDate,
  } = useSelector((state) => state.campaign);
  const { questionTemplateErrors } = useSelector((state) => state.performance);

  useEffect(() => {
    if (employeeIds) {
      dispatch(GetUser(employeeIds));
      dispatch(GetReviewer(employeeIds));
    }
    dispatch(capabilityId ? actions.employeeCapabilityDetail(capabilityId) : actions.getEmployeeCampaignDetail());
  }, [dispatch, props.location?.state?.update]);

  // useEffect(() => {
  //   if (apiResponse) {
  //     apiResponse.success ? successToast(apiResponse.message) : errorToast(apiResponse.message);
  //     dispatch({ type: "CLEAR_CAMPAIGN_RESPONSE" });
  //   }
  // }, [apiResponse, dispatch]);

  const saveQuestionAnswer = (data) => {
    dispatch({ type: "AUTO_SAVE_CAMPAIGN_TEMPLATE", data: data });
    dispatch(
      actions.AutoSaveCampaignTemplate(data, employeeCampaignData?.campaignId, employeeCampaignData?.questionTemplateId)
    );
  };

  const handleSubmitCampaign = () => {
    dispatch({
      type: "SET_QUESTION_TEMPLATE_VALIDATION",
      data: questionTemplateErrors,
    });
    if (
      questionTemplateErrors?.errors?.length > 0 &&
      questionTemplateErrors.errors.filter((value) => value.error || value.commentBoxError).length > 0
    ) {
      requiredFieldMissing();
      return;
    } else {
      if (employeeCampaignData?.isCapabilityDevelopmentFramework) {
        let filterQuestion = employeeCampaignData?.questionTemplate?.questions.filter(
          (que) => que.type === "RADIOBUTTON" && que.options?.length === 2
        );
        let yesAnswerQuestion =
          filterQuestion?.length > 0 &&
          filterQuestion?.filter((q) => q.options?.some((v) => v.option === "Yes" && v.isSelected));
        if (yesAnswerQuestion && yesAnswerQuestion?.some((que) => !que.answer)) {
          let errors = yesAnswerQuestion.map((que) => {
            return {
              qId: que.questionId,
              commentBoxError: true,
              isSubmit: true,
            };
          });
          dispatch({ type: "SET_QUESTION_TEMPLATE_ERROR", error: { errors } });
          requiredFieldMissing();
          return;
        } else {
          dispatch(actions.submitCampaignTemplate(employeeCampaignData.campaignId));
          return;
        }
      }
      dispatch(actions.submitCampaignTemplate(employeeCampaignData.campaignId));
    }
  };

  if (isCampaignLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <SubNavigation type={capabilityId ? "WMG" : "OKR Progress"} />
      {isLoading && <Spinner />}
      <Container>
        {!capabilityId && (
          <Row>
            <Col md={12} className="col-12">
              <h1 className="page-title pb-0">Campaign</h1>
              <span className="st-breadcrumb">Campaign View</span>
            </Col>
          </Row>
        )}
        <Row>
          {employeeCampaignData?.campaignName && (
            <Col className="col-12">
              <BoxHeading heading={employeeCampaignData?.campaignName} />
            </Col>
          )}
          <Col lg={8}>
            {user && <UserProfile user={user} />}
            {employeeCampaignData ? (
              <QuestionTemplate
                isPage="SELF"
                handleTextBox={saveQuestionAnswer}
                handleRating={saveQuestionAnswer}
                handleRatingComment={saveQuestionAnswer}
                handleRadioComment={saveQuestionAnswer}
                handleRadio={saveQuestionAnswer}
                handleRadioOther={saveQuestionAnswer}
                handleCheckBox={saveQuestionAnswer}
                handleCheckBoxOther={saveQuestionAnswer}
                handleCheckBoxComment={saveQuestionAnswer}
                handleDropDown={saveQuestionAnswer}
                handleDropDownComment={saveQuestionAnswer}
                handleDropDownOther={saveQuestionAnswer}
                handleScale={saveQuestionAnswer}
                handleScaleComment={saveQuestionAnswer}
                handleRankComment={saveQuestionAnswer}
                handleRank={saveQuestionAnswer}
                handleNps={saveQuestionAnswer}
                handleNpsComment={saveQuestionAnswer}
                questionTemplateErrors={questionTemplateErrors}
                isSubmitted={isSubmitted}
                questionsAnswerTemplate={employeeCampaignData?.questionTemplate}
                uploadFile={() => {}}
                downloadFile={() => {}}
                viewTemplateName={true}
                deleteFile={() => {}}
                canManagerResponse={isManagerSubmitted ? employeeCampaignData?.isCapabilityDevelopmentFramework : false}
                isCapability={employeeCampaignData?.isCapabilityDevelopmentFramework}
                isManagerSubmitted={true}
                isSurvey={true}
              />
            ) : (
              <div className="box">
                <div className="no_record_wrapper">
                  <NoRecordFound title="Oops! No records found" description="" image={IMG_CHECKINS_LAPSED} />
                  <div className="gap80"></div>
                </div>
              </div>
            )}

            {employeeCampaignData?.managerComments?.length > 0 && (
              <div className="box pb-0">
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2 style={{ display: "inline-block" }}>{text.MANAGER_RESPONSE_HEADING}</h2>
                    <span className="optional-text">(Optional)</span>
                    <h3>{text.MANAGER_RESPONSE_SUB_TEXT_IN_EMPLOYEE}</h3>
                  </div>
                </div>
                <div className="box-inner-pad">
                  <div className="view_comment_box_wrapper collapse show mt-0 mb-4">
                    <CommentsTop heading="Share your view(s)" />
                    {employeeCampaignData?.managerComments.map((managerComment) => (
                      <Comment
                        selectedColor={backGroundColor[1]}
                        image={managerComment?.commentedBy?.displayPicture}
                        empName={managerComment?.commentedBy?.empName}
                        commentedOn={managerComment.commentedDate}
                        comment={managerComment.comment}
                        isPrivate={false}
                        deletable={false}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {employeeCampaignData &&
              (isSubmitted ? (
                <SubmittedDateBox submittedDate={submittedDate} />
              ) : (
                <SubmitBox handleSubmit={handleSubmitCampaign} updatedDate={null} name="Submit" />
              ))}
          </Col>
          <Col lg={4}>
            {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CampaignQuestionView;
