import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import * as actions from "store/actions/index";
import { GetReviewer, GetUser } from "store/actions";
import { IMG_CHECKINS_LAPSED } from "const/imgCost";
import { errorToast, requiredFieldMissing, scrollToTop, successToast } from "util//general";
import { moduleView, useQuery } from "util/utils";
import config from "util/config";
import Spinner from "shared/Spinner/Spinner";
import BoxHeading from "shared/Box/BoxHeading";
import UserProfile from "../../user_profile/user_profile";
import QuestionTemplate from "../../Performance/CycleMemberProfile/QuestionTemplate/QuestionTemplate";
import ReviewerProfile from "../../reviewer-profile/reviewer-profile";
import SubmittedDateBox from "../../SubmitBox/SubmittedDateBox";
import NoRecordFound from "../../NodataFound/NoRecordFound";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import SubmitBox from "components/SubmitBox/SubmitBox";
import CommentTextArea from "components/CommentBox/CommentTextArea";
import CommentsTop from "components/CommentBox/CommentsTop";
import storage from "util/storage";
import Comment from "components/CommentBox/Comment";
import { backGroundColor, menuText, text } from "const/constValue";

function AdminCampaignQuestionView(props) {
  const loggedInUser = storage.getUser();
  const campaignId = props.match.params.id;
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const { user } = useSelector((state) => state.myTeam);
  const { reviewer } = useSelector((state) => state.checkIn);
  const {
    employeeCampaignData,
    isCreateLoading,
    isCampaignLoading,
    apiResponse,
    capabilityData,
    isLoading,
    isManagerSubmitted,
    managerError,
    managerSubmittedDate,
    submittedDate,
  } = useSelector((state) => state.campaign);
  const { questionTemplateErrors } = useSelector((state) => state.performance);

  const isCampaignAdmin = moduleView(config.hrModule.CAMPAIGN_DETAILS)?.includes(config.hrModule.CAMPAIGN_DETAILS);
  const isCapability = history.location.pathname.includes("capability-development");

  //componentDidMount
  useEffect(() => {
    scrollToTop(500);
    if (employeeIds) {
      dispatch(GetUser(employeeIds));
      dispatch(GetReviewer(employeeIds));
    }
    dispatch(actions.getCampaignDetailForAdmin(campaignId));
  }, []);

  //componentDidUpdate (API response)
  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.success) successToast(apiResponse.message);
      else errorToast(apiResponse.message);

      dispatch({ type: "CLEAR_CAMPAIGN_RESPONSE" });
    }
  }, [apiResponse, dispatch]);

  const handleManagerRadioResponse = (e, item, optionId) => {
    let options = item.options.map((value) => {
      if (value.optionId === optionId) value.isManagerSelected = e.target.checked;
      else value.isManagerSelected = null;
      return value;
    });
    let data = { questionId: item.questionId, otherTextAnswer: null, options };
    let error = managerError.errors.map((er) => {
      if (er.queId === item.questionId) {
        er.error = false;
        return er;
      } else return er;
    });
    dispatch({ type: "AUTO_SAVE_CAPABILITY_BY_MANAGER", payload: data });
    dispatch({ type: "SET_CAPABILITY_MANAGER_ERRORS", payload: { errors: error } });
  };

  const saveAnswersHandler = () => {
    dispatch({ type: "SET_CAPABILITY_MANAGER_ERRORS", payload: { submit: true } });
    if (Object.keys(capabilityData)?.length === employeeCampaignData?.questionTemplate?.questions?.length) {
      dispatch(
        actions.submitCapabilityByManager(
          capabilityData,
          employeeCampaignData?.campaignId,
          employeeCampaignData?.questionTemplate.questionTemplateId,
          employeeIds
        )
      );
    } else {
      requiredFieldMissing();
    }
  };

  const handleSubmitManagerComment = () => {
    if (comment?.trim().length > 0)
      dispatch(actions.managerCapabilityComment(employeeCampaignData?.employeeCampaignId, comment));
  };

  const displayTextArea =
    employeeCampaignData?.managerComments?.length > 0 &&
    employeeCampaignData?.managerComments.find((v) => v.commentedBy.employeeId === loggedInUser?.employeeId)
      ? false
      : true;

  let subNavType = "OKR Progress";
  let backURL = "/okrprogress/campaign-list";
  let breadCrumbText = menuText.CAMPAIGN;
  let subMenuText = menuText.CAMPAIGN_DETAILS;
  if (isCapability) {
    subNavType = "WMG";
    backURL = "/wmg/capability-development";
    breadCrumbText = menuText.MMG;
    subMenuText = menuText.CAPABILITY_DEVELOPMENT;
  } else if (isCampaignAdmin) {
    subNavType = "Campaign Details";
    backURL = "/hr/campaign-details";
  }

  if (isCampaignLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <SubNavigation type={subNavType} />
      {(isCreateLoading || isLoading) && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">{breadCrumbText}</h1>
            <span className="st-breadcrumb">
              <Link to={backURL}>Admin</Link>
              <span onClick={() => history.goBack()} className="cursor-pointer">
                {subMenuText}
              </span>
              <span>View Employee Detail</span>
            </span>
          </Col>
        </Row>
        <Row>
          {employeeCampaignData?.campaignName && (
            <Col className="col-12">
              <BoxHeading heading={employeeCampaignData?.campaignName} />
            </Col>
          )}
          <Col lg={8}>
            {user && <UserProfile user={user} />}
            {employeeCampaignData ? (
              <QuestionTemplate
                isPage="SELF"
                handleTextBox={() => {}}
                handleRating={() => {}}
                handleRatingComment={() => {}}
                handleRadioComment={() => {}}
                handleRadio={() => {}}
                handleRadioOther={() => {}}
                handleCheckBox={() => {}}
                handleCheckBoxOther={() => {}}
                handleCheckBoxComment={() => {}}
                handleDropDown={() => {}}
                handleDropDownComment={() => {}}
                handleDropDownOther={() => {}}
                handleScale={() => {}}
                handleScaleComment={() => {}}
                handleRankComment={() => {}}
                handleRank={() => {}}
                handleNps={() => {}}
                handleNpsComment={() => {}}
                questionTemplateErrors={questionTemplateErrors}
                isSubmitted={true}
                questionsAnswerTemplate={employeeCampaignData?.questionTemplate}
                uploadFile={() => {}}
                downloadFile={() => {}}
                viewTemplateName={true}
                deleteFile={() => {}}
                canManagerResponse={employeeCampaignData?.isCapabilityDevelopmentFramework}
                handleManagerRadioResponse={handleManagerRadioResponse}
                isManagerSubmitted={isManagerSubmitted}
                managerAnswerError={managerError}
              />
            ) : (
              <div className="box">
                <div className="no_record_wrapper">
                  <NoRecordFound
                    title="Oops! No records found"
                    description="Employee has not submitted the Campaign."
                    image={IMG_CHECKINS_LAPSED}
                  />
                  <div className="gap80"></div>
                </div>
              </div>
            )}

            {employeeCampaignData.isCapabilityDevelopmentFramework ? (
              isManagerSubmitted ? (
                <SubmittedDateBox
                  submittedDate={managerSubmittedDate}
                  submittedText="Reviewed On"
                  reviewedBy={employeeCampaignData?.managerDetails?.empName}
                />
              ) : (
                <SubmitBox handleSubmit={saveAnswersHandler} name="Submit" />
              )
            ) : (
              <SubmittedDateBox submittedDate={submittedDate} />
            )}
            {employeeCampaignData?.isCapabilityDevelopmentFramework && (
              <div className="box pb-0">
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h2 style={{ display: "inline-block" }}>{text.MANAGER_RESPONSE_HEADING}</h2>
                    <span className="optional-text">(Optional)</span>
                    <h3>{text.MANAGER_RESPONSE_SUB_TEXT}</h3>
                  </div>
                </div>
                <div className="box-inner-pad">
                  <div className="view_comment_box_wrapper collapse show mt-0 mb-0">
                    <CommentsTop heading="Share your view(s)" />
                    {employeeCampaignData?.managerComments &&
                      employeeCampaignData?.managerComments.map((managerComment) => (
                        <Comment
                          selectedColor={backGroundColor[1]}
                          image={managerComment?.commentedBy?.displayPicture}
                          empName={managerComment?.commentedBy?.empName}
                          commentedOn={managerComment.commentedDate}
                          comment={managerComment.comment}
                          isPrivate={false}
                          deletable={false}
                        />
                      ))}
                    {displayTextArea && (
                      <CommentTextArea
                        value={comment}
                        maxLength={500}
                        changeHandle={(e) => setComment(e.target.value)}
                        image={loggedInUser?.displayPicture}
                        submitButton={true}
                        submitComment={handleSubmitManagerComment}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Col>
          <Col lg={4}>
            {reviewer?.managersList?.length > 0 && <ReviewerProfile managerList={reviewer.managersList} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AdminCampaignQuestionView;
