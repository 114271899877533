import React from "react";

const VideoContainer = (props) => {

  return (
    <video
      controls
      preload="auto"
      autoplay="autoplay"
      src={props.videoLink}
      width="100%"
      height="100%"
    // poster={props.videosInfo.thumbnailLinkLarge}
    ></video>
  );
};

export default VideoContainer;
