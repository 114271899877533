import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import * as actions from "store/actions/index";
import { monthOptions3 } from "const/options";
import { oneOnOneStageOptions } from "const/options";
import { requiredFieldMissing, setOneOnOneCol } from "util/general";
import { moduleView } from "util/utils";
import Spinner from "shared/Spinner/Spinner";
import config from "util/config";
import HideAndShow from "../../HideAndShow/HideAndShow";
import MyManagerTab from "./MyManagerTab";
import MyTeamTab from "./MyTeamTab";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";

export default function OneOnOneTabs({ filterValue }) {
  const { managerList, isLoading, filteredTeamData } = useSelector((state) => state.oneOnOne);
  const { filterStage, month } = filteredTeamData;
  const [column, setColumn] = useState();
  const [loader, setLoader] = useState(false);
  const [hide, setHide] = useState();
  const [apiCall, setAPICall] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (filteredTeamData?.length > 0) {
      dispatch(actions.GetOneOnOneMember(month?.value));
      if (filterStage) {
        let col = setOneOnOneCol(filterStage?.value);
        setColumn(col);
      }
    } else {
      dispatch(actions.GetOneOnOneMember(1));
    }
  }, [dispatch]);

  const handleFilter = () => {
    let col;
    if (filterStage) {
      col = setOneOnOneCol(filterStage?.value);
      setColumn(col);
      if (apiCall) {
        dispatch(actions.GetOneOnOneMember(month?.value));
      } else {
        setLoader(true);
        setTimeout(() => setLoader(false), 500);
      }
      setAPICall(false);
    } else {
      requiredFieldMissing();
    }
  };

  const handleClear = () => {
    setColumn();
    setAPICall(false);
    dispatch({ type: "CLEAR_ONE_ON_ONE_FILTER_DATA" });
  };

  const checkColumn = (type) => {
    if (column === type || column === "ALL") {
      return true;
    } else return false;
  };

  if (!managerList) return <Spinner />;

  return (
    <div
      className={["custom-tabs", "custom-tabs-full-width", "tab-content-dashboard", style.one_on_one_tabs].join(" ")}
    >
      {(isLoading || loader) && <Spinner />}
      <div className={style.feedback_wrapper}>
        <div className={style.feedback_inner_wrapper}>
          <div className={[style.employee_list_filter_wrapper, "box"].join(" ")}>
            <div className="box-heading-wrapper">
              <div className="box-heading">
                <h2>Filter(s)</h2>
                <h3>Select appropriate values</h3>
              </div>
            </div>
            <div className="box-light-gradient"></div>
            <div className={style.filter_info}>
              <div className={style.filter_top_bar}>
                <div className={style.filter_box}>
                  <span className={style.dd_title}>
                    Stage
                    <span className={style.dd_title_light}>
                      <span className="text-danger">*</span> (Show the state of 1-on-1&apos;s)
                    </span>
                  </span>
                  <div className="tags_wrapper">
                    <Select
                      options={oneOnOneStageOptions}
                      className="custom-default-dd-box"
                      classNamePrefix="select"
                      placeholder="Select..."
                      value={filterStage || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_ONE_ON_ONE_FILTER_DATA",
                          payload: {
                            filterStage: e,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className={style.filter_box}>
                  <span className={style.dd_title}>Duration</span>
                  <div className="tags_wrapper">
                    <Select
                      options={monthOptions3}
                      className="custom-default-dd-box"
                      classNamePrefix="select"
                      placeholder="Select..."
                      value={month || ""}
                      onChange={(v) => {
                        dispatch({
                          type: "SET_ONE_ON_ONE_FILTER_DATA",
                          payload: {
                            month: v,
                          },
                        });
                        setAPICall(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={style.filter_btn_group}>
                <button
                  type="button"
                  className={["btn-round save_box_btn btn btn-primary mr-3", style.create_new_btn].join(" ")}
                  onClick={handleFilter}
                >
                  <strong>Search</strong>
                </button>
                <button
                  type="button"
                  className={["btn-round save_box_btn btn btn-secondary", style.create_new_btn].join(" ")}
                  onClick={handleClear}
                >
                  <strong>Clear</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className={[style.employee_list_filter_wrapper, "box", hide ? "pb-0" : ""].join(" ")}>
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>1-on-1&apos;s</h2>
              <h3>Take appropriate actions for following details</h3>
            </div>
            <HideAndShow hide={hide} setHide={setHide} />
          </div>
          {!hide && (
            <>
              <div className="box-light-gradient"></div>
              <div className={style.inside_team_wrapper}>
                <MyManagerTab
                  managerList={managerList}
                  previous={checkColumn("previousOneOnOne")}
                  upComing={checkColumn("upcomingOneOnOne")}
                  lapsed={checkColumn("lapsedOneOnOne")}
                  pending={checkColumn("isPendingOneOnOne")}
                  filter={column}
                />
              </div>
              {moduleView(config.mainModule.CHECK_IN)?.includes(config.subModule.MY_TEAM) && (
                <div className={style.inside_team_wrapper}>
                  <MyTeamTab
                    filterValue={filterValue}
                    previous={checkColumn("previousOneOnOne")}
                    upComing={checkColumn("upcomingOneOnOne")}
                    lapsed={checkColumn("lapsedOneOnOne")}
                    pending={checkColumn("isPendingOneOnOne")}
                    filter={column}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
