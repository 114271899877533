import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import MultiSelect from "react-multi-select-component";
import Select from "react-select";
import * as actions from "store/actions/index";
import * as actionTypes from "store/actions/actionTypes";
import { IC_DELETE, IC_EDIT_OBJECTIVE } from "const/imgCost";
import routePath from "const/routePath";
import { errorToast, requiredFieldMissing, scrollToTop, successToast } from "util/general";
import { buttonText, noData, popupText } from "const/constValue";
import { timeFrameOptions } from "const/options";
import Spinner from "shared/Spinner/Spinner";
import TemplateSelectionButton from "shared/Button/TemplateSelectionButton";
import NoDataFound from "components/NodataFound/NoDataFound";
import TooltipText from "components/TooltipText/TooltipText";
import ConfirmationPopup from "components/Objectives/ConfirmationPopup";
import QuestionTemplateModal from "components/Performance/CreatePerformanceCycle/QuestionTemplateModal/QuestionTemplateModal";
import storage from "util/storage";
import style from "./CheckinConfig.module.scss";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

function CheckinConfig() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPopup, setShowPopup] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [error, setError] = useState(false);
  const [getOneConfig, setOneConfig] = useState();
  storage.set("cycle", "configuration");
  const { isLoading, apiResponse, subGroupClient, surveyConfigData } = useSelector((state) => state.admin);
  const {
    surveyCycleTemplateList,
    surveyConfigList,
    locationList,
    locations,
    timeFrame,
    subGroupId,
    questionTemplateId,
    questionTemplateName,
  } = surveyConfigData;

  useEffect(() => {
    dispatch(actions.getSubGroupClient());
    dispatch(actions.getSurveyCycleConfig());
    dispatch(actions.getSurveyCycleTemplate());
  }, [dispatch]);

  useEffect(() => {
    scrollToTop(500);
  }, [isEdit]);

  //Component did update
  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.success) {
        successToast(apiResponse.message);
        dispatch({ type: actionTypes.REMOVE_SURVEY_CONFIG_DATA });
        setIsEdit(false);
      } else {
        errorToast(apiResponse.message);
      }
      dispatch({ type: "CLEAR_ROLE_ASSIGN_RESPONSE" });
    }
  }, [dispatch, apiResponse]);

  const handleConfig = (type) => {
    if (timeFrame && subGroupId && locations?.length > 0) {
      let removedLocations = getOneConfig?.locations.filter((v) => !locations.map((v) => v.value).includes(v));
      let addedLocations = locations.filter((v) => !getOneConfig?.locations.includes(v.value));
      let body = {
        subGroupId: subGroupId.value,
        type: timeFrame.value,
        questionTemplateId: questionTemplateId,
        locations: locations.map((v) => v.value),
      };
      setError(false);
      if (type === "EDIT") {
        let editBody = {
          ...body,
          isTypeChanged: getOneConfig.type !== timeFrame.value,
          removedLocations: removedLocations || [],
          addedLocations: addedLocations?.length > 0 ? addedLocations?.map((v) => v.value) : [],
          locations: getOneConfig?.locations,
          questionTemplateId: questionTemplateId || null,
          isQuestionTemplateChanged: getOneConfig.questionTemplateId !== questionTemplateId,
        };
        dispatch(actions.updateSurveyCycleConfig(editBody));
        setIsEdit(false);
      } else dispatch(actions.createSurveyCycleConfig(body));
    } else {
      setError(true);
      requiredFieldMissing();
    }
  };

  const handleEdit = (data) => {
    let location = [...data.subGroup.locations, ...data.locations];
    dispatch({
      type: actionTypes.SET_SURVEY_CONFIG_DATA,
      payload: {
        locationList: location.map((l) => ({ value: l, label: l })),
        subGroupId: { value: data.subGroupId, label: data.subGroup?.subGroupDisplayName },
        timeFrame: timeFrameOptions.find((v) => v.value === data.type),
        locations: data.locations.map((v) => {
          return {
            value: v,
            label: v,
          };
        }),
        questionTemplateId: data.questionTemplateId || null,
        questionTemplateName: data.questionTemplateName,
      },
    });
    setIsEdit(true);
    setOneConfig(data);
  };

  const handleSetConfigurationData = (name, value) => {
    dispatch({ type: actionTypes.SET_SURVEY_CONFIG_DATA, payload: { [name]: value } });
  };

  const handleDelete = (data) => {
    setShowPopup(true);
    setOneConfig(data);
  };

  const handleDeleteConfirm = () => {
    setOneConfig();
    let deleteData = {
      locations: getOneConfig.locations,
      subGroupId: getOneConfig.subGroupId,
      type: getOneConfig.type,
    };
    dispatch(actions.deleteSurveyCycleConfig(deleteData));
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
    setIsEdit(false);
    setOneConfig();
  };

  const handleSubGroup = (data) => {
    handleSetConfigurationData("subGroupId", data);
    dispatch(actions.getLocationListFromSubGroup(data.value));
  };

  //Question template handle
  const handleRemoveTemplate = () => {
    dispatch({
      type: actionTypes.SET_SURVEY_CONFIG_DATA,
      payload: { questionTemplateId: "", questionTemplateName: "" },
    });
  };

  const handleTemplatePopup = () => {
    storage.set("templateType", "CHECKIN");
    setIsModal(true);
  };

  const handleCreateTemplate = () => {
    storage.set("templateType", "CHECKIN");
    history.push(routePath.QUESTION_BUILDER);
  };

  const handleTemplatePreview = () => {
    history.push({
      pathname: routePath.PREVIEW_TEMPLATE_ROUTE,
      state: {
        template: { questionTemplateId: questionTemplateId },
        hideButton: false,
      },
    });
  };

  const selectTemplate = (id, value) => {
    dispatch({
      type: actionTypes.SET_SURVEY_CONFIG_DATA,
      payload: { questionTemplateName: value, questionTemplateId: id },
    });
    setIsModal(!isModal);
  };

  return (
    <>
    <SubNavigation type="OKR Progress" />
    <div className={style.checkin_config_wrapper}>
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0 mt-3">Admin</h1>
            <span className="st-breadcrumb">
            OKR Progress 
              <span>OKR Progress Configuration</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="box pb-2">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Create OKR Progress Configuration</h2>
                  <h3>
                    Create OKR Progress cycle as per your preferences by selecting Subgroup, Location &amp; Timeframe.
                  </h3>
                </div>
              </div>
              <div className="box-inner-pad">
                <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
                  <Form.Group>
                    <Form.Label className={style.dd_title}>
                      Sub Group<span className="text-danger">*</span>
                    </Form.Label>
                    <div className={["tags_wrapper", style.role_dropdown].join(" ")}>
                      <Select
                        options={subGroupClient}
                        isDisabled={isEdit}
                        className={["custom-default-dd-box", error && !subGroupId && "error-box"].join(" ")}
                        classNamePrefix="select"
                        placeholder="Select..."
                        value={subGroupId || ""}
                        onChange={handleSubGroup}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className={style.dd_title}>
                      Locations<span className="text-danger">*</span>
                    </Form.Label>
                    <div className="tags_wrapper">
                      <MultiSelect
                        disabled={locationList?.length > 0 ? false : true}
                        className={error && !(locations && locations.length > 0) ? "error-box" : ""}
                        options={locationList}
                        value={locations}
                        onChange={(value) => handleSetConfigurationData("locations", value)}
                        labelledBy="Select"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className={style.dd_title}>
                      Time frame<span className="text-danger">*</span>
                    </Form.Label>
                    <div className={["tags_wrapper", style.role_dropdown].join(" ")}>
                      <Select
                        options={timeFrameOptions}
                        className={["custom-default-dd-box", error && !timeFrame && "error-box"].join(" ")}
                        classNamePrefix="select"
                        placeholder="Select..."
                        value={timeFrame || ""}
                        onChange={(value) => handleSetConfigurationData("timeFrame", value)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <TemplateSelectionButton
                      name={questionTemplateName}
                      isDelete={true}
                      handleRemove={handleRemoveTemplate}
                      disabled={false}
                      handleClick={handleTemplatePreview}
                      handleSelect={surveyCycleTemplateList?.length ? handleTemplatePopup : handleCreateTemplate}
                      btnText={surveyCycleTemplateList?.length ? "Select Question Template" : "Create New Questions"}
                    />
                  </Form.Group>
                </div>
                <div className={style.save_box_left}>
                  <Button type="button" variant={"primary"} onClick={() => handleConfig(isEdit ? "EDIT" : "CREATE")}>
                    <strong>{isEdit ? buttonText.EDIT : buttonText.CREATE}</strong>
                  </Button>
                  {isEdit && (
                    <Button type="button" variant={"secondary"} onClick={handleCancel} className="ml-3">
                      <strong>{buttonText.CANCEL}</strong>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className={[style.organization_history_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>OKR Progress Configuration List</h2>
                </div>
              </div>
              <div className="box-light-gradient"></div>
              <div className="box-inner-pad p-0">
                <div className={[style.defined_roles_table, "custom-table", "table-theme-1"].join(" ")}>
                  {surveyConfigList && surveyConfigList.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className={style.subGroup_name}>Sub Group</th>
                          <th className={style.location_name}>Locations</th>
                          <th className={style.time_frame}>Time frame</th>
                          <th className={style.question_name}>Template</th>
                          <th className={style.action}>Edit</th>
                          <th className={style.action}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {surveyConfigList.map((item) => (
                          <tr key={item.type + item.subGroupId}>
                            <td>{item.subGroup.subGroupDisplayName}</td>
                            <td>
                              <TooltipText len={35} array={item.locations} />
                            </td>
                            <td>{timeFrameOptions.find((v) => v.value === item.type)?.label || "-"}</td>
                            <td>{item.questionTemplateName ? item.questionTemplateName : "-"}</td>
                            <td className={style.action}>
                              <span onClick={() => handleEdit(item)}>
                                <ReactSVG src={`${IC_EDIT_OBJECTIVE}`} alt="" title="edit" />
                              </span>
                            </td>
                            <td className={style.action}>
                              <span onClick={() => handleDelete(item)}>
                                <ReactSVG src={`${IC_DELETE}`} alt="" title="delete" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                      <div className="gap80"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmationPopup
        show={showPopup}
        hide={handleCancel}
        submit={handleDeleteConfirm}
        headerText={popupText.DELETE_CONFIG}
        subText={popupText.DELETE_CONFIG_SUBTEXT}
        submitBtnText={buttonText.CONFIRM}
      />
      <QuestionTemplateModal
        hide={() => setIsModal(false)}
        previewPathname={routePath.PREVIEW_TEMPLATE_ROUTE}
        isSelectModal={questionTemplateName}
        list={surveyCycleTemplateList}
        selectTemplate={selectTemplate}
        show={isModal}
      />
    </div>
    </>
  );
}

export default CheckinConfig;
