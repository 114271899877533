import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEmpty } from "lodash";
import { menuText } from "const/constValue";
import { oneOnOneStatusOptions } from "const/options";
import * as actionTypes from "store/actions/actionTypes";
import SearchAndClearFilter from "components/SearchAndClearFilter/SearchAndClearFilter";
import OneOnOneMemberTable from "./OneOnOneMemberTable";
import * as actions from "store/actions/index";
import Spinner from "shared/Spinner/Spinner";
import style from "./OneOnOne.module.scss";
import { errorToast } from "util/general";

function OneOnOne() {
  const dispatch = useDispatch();
  const { hrOneOnOneFilter, isLoading } = useSelector((state) => state.oneOnOne);
  const { subGroupClient } = useSelector((state) => state.admin);
  const { hrTimelineFilterOptions } = useSelector((state) => state.performance);
  const { dashboardOption } = useSelector((state) => state.hrDashboardReport);

  useEffect(() => {
    dispatch(actions.getSubGroupClient());
    dispatch(actions.getHrDashboardFilterOptions([], "HR_ONE_ON_ONE"));
    dispatch(actions.GetOptionsListForHRTimeline());
  }, [dispatch]);

  const handleFilterData = (variable, e) => {
    dispatch({
      type: actionTypes.SET_HR_ONE_ON_ONE_FILTER_OPTIONS_DATA,
      payload: {
        [variable]: e,
      },
    });
  };

  const handleFilter = () => {
    if (isEmpty(hrOneOnOneFilter)) {
      errorToast("Please select any filter");
    } else {
      dispatch(actions.GetEmployeeListForHROneOnOne({ ...hrOneOnOneFilter }));
      dispatch({ type: "CLEAR_PAGINATION_DATA" });
    }
  };

  const handleClear = () => {
    dispatch({
      type: actionTypes.CLEAR_HR_ONE_ON_ONE_FILTER_OPTIONS_DATA,
    });
    dispatch(actions.GetEmployeeListForHROneOnOne({}));
    dispatch({ type: "CLEAR_PAGINATION_DATA" });
  };

  return (
    <div className={style.one_on_one_wrapper}>
      <div className="gap30"></div>
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="page-title pb-0">{menuText.HR}</h1>
            <span className="st-breadcrumb">{menuText.ONE_ON_ONE}</span>
          </Col>
        </Row>
        <SearchAndClearFilter
          isDisplayStartDate={true}
          startDate={hrOneOnOneFilter?.startDate}
          endDate={hrOneOnOneFilter?.endDate}
          isDisplayEndDate={true}
          subGroupClient={subGroupClient}
          isDisplaySubGroup={true}
          displayDepartment={true}
          handleFilterData={handleFilterData}
          handleFilter={handleFilter}
          departmentList={dashboardOption?.departmentList}
          displayLocation={true}
          handleClear={handleClear}
          locationList={dashboardOption?.locationOption}
          employeeNameList={hrTimelineFilterOptions?.displayEmployeesList}
          filterEmployee={hrOneOnOneFilter?.filterEmployee}
          filterSubGroups={hrOneOnOneFilter?.filterSubGroups}
          filterLocation={hrOneOnOneFilter?.filterLocation}
          filterDepartment={hrOneOnOneFilter?.filterDepartment}
          statusWiseOptions={oneOnOneStatusOptions}
          filterStatus={hrOneOnOneFilter?.filterStatus}
          displayStatus={true}
          description="Select appropriate values for reviewing employees 1-on-1's"
        />
        <OneOnOneMemberTable hrOneOnOneFilter={hrOneOnOneFilter} />
      </Container>
    </div>
  );
}

export default OneOnOne;
