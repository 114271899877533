import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Button, Form, Col, Collapse } from "react-bootstrap";
import { toast } from "react-toastify";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import * as actions from "store/actions/index";
import { dateFormatYMD, toDate } from "util/dateUtils";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import PreviouslyHeldOneOnOne from "../../Components/PreviouslyHeldOneOnOne";
import ThisOneOnOne from "../../Components/ThisOneOnOne";
import HideAndShow from "../../../HideAndShow/HideAndShow";
import AddTalkingPointBox from "../../Components/AddTalkingPointBox";
import TalkingPointLists from "./TalkingPointLists";
import PrivateNotes from "../../Components/PrivateNotes";
import SharedNotes from "../../Components/SharedNotes";
import SlideToast from "../../../Toast/Toast";
import ManagerTakeAction from "./ManagerTakeAction";
import UserTakeAction from "./UserTakeAction";
import MeetingNotes from "../../Components/MeetingNotes";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

export default function PreviousOneOnOneDetails(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = storage.getUser();
  const { reviewer } = useSelector((state) => state.checkIn);
  const {
    user,
    isLoading,
    oneOnOne,
    previousOneOnOneData,
    scheduleResponse,
    currentTimezone,
    timezoneList,
    blockTimes,
  } = useSelector((state) => state.oneOnOne);
  const [selectQuestion, setSelectQuestion] = useState("");
  const [hide, setHide] = useState(false);
  const [comment, setComment] = useState("");
  const [startTime, setStartTime] = useState("");
  const [privateNote, setPrivateNote] = useState("");
  const [sharedNote, setSharedNote] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [timezone, setTimezone] = useState();

  const oneOnOneAgendaId = props.match.params.id;

  let managerId,
    createBy,
    userId = null;
  if (oneOnOne && oneOnOne.createdBy.isManager) {
    managerId = oneOnOne.createdBy.employeeId;
    userId = oneOnOne && oneOnOne.addOneOnOnewith.employeeId;
    createBy = "MANAGER";
  } else {
    managerId = oneOnOne && oneOnOne.addOneOnOnewith.employeeId;
    userId = oneOnOne && oneOnOne.createdBy.employeeId;
    createBy = "EMPLOYEE";
  }

  useEffect(() => {
    dispatch(actions.GetOneOnOne(oneOnOneAgendaId, loggedInUser.employeeId));
    dispatch(actions.GetReviewer(loggedInUser));
    dispatch(actions.GetTimezoneList(oneOnOneAgendaId));
  }, []);

  useEffect(() => {
    if (oneOnOne && !oneOnOne.addOneOnOnewith.isManager) dispatch(actions.GetReviewer(oneOnOne.addOneOnOnewith));
    if (
      oneOnOne &&
      oneOnOne.talkingPoints &&
      oneOnOne.talkingPoints.filter((value) => value.isCompleted).length === oneOnOne.talkingPoints.length
    )
      setSelectQuestion("");
    else if (oneOnOne && oneOnOne.scheduledDate) setSelectQuestion("discuss");
    if (oneOnOne && oneOnOne.fromTime && oneOnOne.toTime && oneOnOne.scheduledDate) {
      setStartDate(toDate(oneOnOne.scheduledDate));
      setStartTime(new Date().setHours(oneOnOne.fromTime.split(":")[0], oneOnOne.fromTime.split(":")[1]));
      setEndTime(new Date().setHours(oneOnOne.toTime.split(":")[0], oneOnOne.toTime.split(":")[1]));
    }
  }, [oneOnOne]);

  useEffect(() => {
    if (scheduleResponse) {
      if (scheduleResponse.success) {
        if (!toast.isActive("SCHEDULE_ONE_ON_ONE"))
          toast.success(<SlideToast title="Awesome!" message="1-on-1 Re-Schedule successfully" error={false} />, {
            toastId: "SCHEDULE_ONE_ON_ONE",
          });
        dispatch({ type: "CLEAR_SEND_AGENDA_RESPONSE" });
        history.push(`/okrprogress/one-on-one`);
      }
    }
  }, [scheduleResponse]);

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    dispatch(actions.getScheduleTime(dateFormatYMD(date), oneOnOneAgendaId, timezone?.value || currentTimezone));
    setStartTime();
    setEndTime();
  };

  const handleTimezone = (e) => {
    setTimezone(e);
    setStartTime();
    setEndTime();
    if (startDate) {
      dispatch(actions.getScheduleTime(dateFormatYMD(startDate), oneOnOneAgendaId, e.value));
    }
  };

  const sendTalkingPoint = () => {
    const data = {
      oneOnOneAgendaId: oneOnOneAgendaId,
      agenda: comment.trim(),
      addedBy: {
        employeeId: loggedInUser?.employeeId,
        empName: loggedInUser?.displayName,
        displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
        department: loggedInUser?.department,
        parentGroupId: loggedInUser?.parentGroupId,
        subGroupId: loggedInUser?.subGroupId,
        subGroupName: loggedInUser?.subGroupName,
        parentGroupName: loggedInUser?.parentGroupName,
      },
    };
    setComment("");
    if (comment.trim()) dispatch(actions.UpdateOneOnOne(data, oneOnOneAgendaId));
  };

  const handleQuestion = (e) => {
    setSelectQuestion(e.target.value);
    if (e.target.value === "close") {
      setStartDate("");
      setStartTime("");
      setEndTime("");
    } else if (oneOnOne?.fromTime && oneOnOne?.toTime && oneOnOne?.scheduledDate) {
      setStartDate(toDate(oneOnOne.scheduledDate));
      setStartTime(new Date().setHours(oneOnOne.fromTime.split(":")[0], oneOnOne.fromTime.split(":")[1]));
      setEndTime(new Date().setHours(oneOnOne.toTime.split(":")[0], oneOnOne.toTime.split(":")[1]));
    }
  };

  const deletePoint = (id) => {
    let data = {
      oneOnOneAgendaId: oneOnOneAgendaId,
      talkingPointId: id,
    };
    dispatch(actions.DeleteTalkingPoint(data, oneOnOneAgendaId));
  };

  const handleStartTime = (date) => {
    let min = new Date(date).getMinutes();
    setStartTime(date);
    setEndTime(new Date(date).setMinutes(min + 15));
  };

  const handleEndTime = (date) => setEndTime(date);

  const handlePrivateNote = (e) => setPrivateNote(e.target.value);

  const sendPrivateNote = () => {
    let body = {
      oneOnOneAgendaId: oneOnOneAgendaId,
      privateNotes: {
        comment: privateNote.trim(),
        commentedBy: {
          employeeId: loggedInUser?.employeeId,
          empName: loggedInUser?.displayName,
          displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
          department: loggedInUser?.department,
          designation: loggedInUser?.designation,
          parentGroupId: loggedInUser?.parentGroupId,
          subGroupId: loggedInUser?.subGroupId,
          subGroupName: loggedInUser?.subGroupName,
          parentGroupName: loggedInUser?.parentGroupName,
        },
      },
    };
    setPrivateNote("");
    if (privateNote.trim()) dispatch(actions.UpdateOneOnOne(body, oneOnOneAgendaId));
  };

  const sendSharedNote = () => {
    let body = {
      oneOnOneAgendaId: oneOnOneAgendaId,
      sharedNotes: {
        comment: sharedNote.trim(),
        commentedBy: {
          employeeId: loggedInUser?.employeeId,
          empName: loggedInUser?.displayName,
          displayPicture: loggedInUser?.displayPicture || loggedInUser?.empInitial,
          department: loggedInUser?.department,
          designation: loggedInUser?.designation,
          parentGroupId: loggedInUser?.parentGroupId,
          subGroupId: loggedInUser?.subGroupId,
          subGroupName: loggedInUser?.subGroupName,
          parentGroupName: loggedInUser?.parentGroupName,
        },
      },
    };
    setSharedNote("");
    if (sharedNote.trim()) dispatch(actions.UpdateOneOnOne(body, oneOnOneAgendaId));
  };

  const handleSharedNote = (e) => setSharedNote(e.target.value);

  const deletePrivateNote = (id) => dispatch(actions.DeletePrivateNote(id, oneOnOneAgendaId));

  const deleteSharedNote = (id) => dispatch(actions.DeleteSharedNote(id, oneOnOneAgendaId));

  let disabled = oneOnOne && oneOnOne.thirdperson && oneOnOne.thirdperson.employeeId === loggedInUser.employeeId;

  const markTalkingPoint = (e, id) => {
    let isCompleted = e.target.value === "true" ? true : false;
    if (!disabled && oneOnOne && !oneOnOne.isEnded)
      dispatch(actions.MarkTalkingPoint(id, oneOnOneAgendaId, isCompleted));
  };

  const scheduleOneOnOne = () => {
    let d = new Date();
    if (startTime && endTime && startDate) {
      if (startDate < d.setDate(d.getDate() - 1)) {
        if (!toast.isActive("END_ONE_ON_ONE_ERROR"))
          toast.success(<SlideToast title="Error!" message="Please change the date" error={true} />, {
            toastId: "END_ONE_ON_ONE_ERROR",
          });
        return;
      }
      let isMarked = oneOnOne.talkingPoints.filter((value) => value.isCompleted);
      let date = dateFormatYMD(startDate);
      if (managerId === loggedInUser.employeeId && isMarked && isMarked.length > 0) {
        if (oneOnOne.sharedNotes && oneOnOne.sharedNotes.length > 0) {
          dispatch(
            actions.ManagerScheduleOneOnOne(
              oneOnOneAgendaId,
              date,
              startTime,
              endTime,
              timezone?.value || currentTimezone
            )
          );
        } else {
          if (!toast.isActive("END_ONE_ON_ONE_ERROR"))
            toast.success(<SlideToast title="Error!" message="Please Submit Shared Notes" error={true} />, {
              toastId: "END_ONE_ON_ONE_ERROR",
            });
        }
      } else
        dispatch(
          actions.ScheduleOneOnOne(oneOnOneAgendaId, date, startTime, endTime, timezone?.value || currentTimezone)
        );
    } else {
      if (!toast.isActive("SCHEDULE_ONE_ON_ONE_ERROR"))
        toast.success(<SlideToast title="Error!" message="Please fill date and time" error={true} />, {
          toastId: "SCHEDULE_ONE_ON_ONE_ERROR",
        });
    }
  };

  const endOneOnOne = () => {
    // if flag false then manager/ user schedule a one-on-one else not schedule
    if (startTime && endTime && startDate && oneOnOne.sharedNotes && oneOnOne.sharedNotes.length > 0) {
      let date = dateFormatYMD(startDate);
      dispatch(actions.EndOneOnOne(oneOnOneAgendaId, date, startTime, endTime, "", timezone?.value || currentTimezone));
    } else if (!startTime || !endTime || !startDate) {
      if (!toast.isActive("SCHEDULE_ONE_ON_ONE_ERROR"))
        toast.success(<SlideToast title="Error!" message="Please fill date and time" error={true} />, {
          toastId: "SCHEDULE_ONE_ON_ONE_ERROR",
        });
    } else {
      if (!toast.isActive("END_ONE_ON_ONE_ERROR"))
        toast.success(<SlideToast title="Error!" message="Please Submit Shared Notes" error={true} />, {
          toastId: "END_ONE_ON_ONE_ERROR",
        });
    }
  };

  let userPath;
  if (managerId === user?.employeeId) {
    userPath = `/okrprogress/one-on-one/manager?empId=${user?.employeeId}&pgId=${user?.parentGroupId}&sgId=${user?.subGroupId}`;
  } else {
    userPath = `/okrprogress/one-on-one/team?empId=${user?.employeeId}&pgId=${user?.parentGroupId}&sgId=${user?.subGroupId}`;
  }

  if (!oneOnOne) {
    return <Spinner />;
  }

  return (
    <div className={style.one_on_one_tp_wrapper}>
      <SubNavigation type="OKR Progress" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">OKR Progress</h1>
            <span className="st-breadcrumb">
              <Link to={routePath.ONE_ON_ONE}>1—on-1&apos;s</Link>
              <span>
                <Link to={userPath}>{user && user.displayName}</Link>
              </span>
              <span>Previous 1-on-1s</span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={8}>
            <Form>
              <div className={["card"].join(" ")}>
                <div className="card_info">
                  <div className="card_title">
                    <span className="card_title_text">Talking Points</span>
                  </div>
                  <HideAndShow hide={hide} setHide={setHide} />
                </div>

                <Collapse in={!hide}>
                  <div className="card_details">
                    <div className={style.tp_inner_wrapper}>
                      {oneOnOne &&
                        oneOnOne.talkingPoints.map((value, index) => (
                          <TalkingPointLists
                            allSelect={selectQuestion === "close" && true}
                            isEnded={oneOnOne && oneOnOne.isEnded}
                            deletable={oneOnOne && oneOnOne.scheduledDate ? false : true}
                            userId={userId}
                            disabled={disabled}
                            managerId={managerId}
                            markTalkingPoint={markTalkingPoint}
                            loggedInUser={loggedInUser}
                            deletePoint={deletePoint}
                            key={value.talkingPointId}
                            style={style}
                            point={value}
                            index={index}
                          />
                        ))}
                      {oneOnOne && !oneOnOne.scheduledDate && (
                        <AddTalkingPointBox
                          image={loggedInUser && (loggedInUser.displayPicture || loggedInUser.empInitial)}
                          placeholder="Add a talking point"
                          comment={comment}
                          handleComment={handleComment}
                          sendTalkingPoint={sendTalkingPoint}
                        />
                      )}
                    </div>
                  </div>
                </Collapse>
              </div>
              <PrivateNotes
                loggedInUser={loggedInUser}
                disabled={disabled}
                image={loggedInUser && (loggedInUser.displayPicture || loggedInUser.empInitial)}
                handleComment={handlePrivateNote}
                privateNotes={oneOnOne && oneOnOne.privateNotes}
                comment={privateNote}
                sendTalkingPoint={sendPrivateNote}
                deletePrivateNote={deletePrivateNote}
              />
              <SharedNotes
                isEnded={oneOnOne && oneOnOne.isEnded}
                disabled={disabled}
                loggedInUser={loggedInUser}
                image={loggedInUser && (loggedInUser.displayPicture || loggedInUser.empInitial)}
                handleComment={handleSharedNote}
                sharedNotes={oneOnOne && oneOnOne.sharedNotes}
                comment={sharedNote}
                deleteSharedNote={deleteSharedNote}
                sendTalkingPoint={sendSharedNote}
              />
              {oneOnOne?.meetingNotes && <MeetingNotes meetingNotes={oneOnOne.meetingNotes} />}
              {!disabled &&
                (oneOnOne.isEnded ? (
                  <div className={["box box-flex-center", style.saveBox].join(" ")}>
                    <div className={style.save_box_left}>
                      <Button className="btn btn-primary btn-disabled">Ended</Button>
                    </div>
                  </div>
                ) : (
                  <>
                    {oneOnOne &&
                    (createBy === "MANAGER"
                      ? oneOnOne.createdBy.employeeId === loggedInUser.employeeId
                      : oneOnOne.addOneOnOnewith.employeeId === loggedInUser.employeeId) ? (
                      <ManagerTakeAction
                        blockTimes={blockTimes}
                        isRegisterInTeams={true}
                        timezoneList={timezoneList}
                        timezone={timezone || timezoneList.find((v) => v.value === currentTimezone)}
                        setTimezone={handleTimezone}
                        isPage="previous"
                        loggedInUser={loggedInUser}
                        oneOnOne={oneOnOne && oneOnOne}
                        handleQuestion={handleQuestion}
                        selectQuestion={selectQuestion}
                        scheduledDate={oneOnOne && oneOnOne.scheduledDate}
                        isEdit={oneOnOne && oneOnOne.scheduledDate ? false : true}
                        handleStartTime={handleStartTime}
                        handleEndTime={handleEndTime}
                        startTime={startTime}
                        endTime={endTime}
                        handleStartDate={handleStartDate}
                        startDate={startDate}
                        selectedThirdPerson={oneOnOne && oneOnOne.thirdperson}
                        oneOnOneAgendaId={oneOnOneAgendaId}
                        style={style}
                        scheduleOneOnOne={scheduleOneOnOne}
                        endOneOnOne={endOneOnOne}
                        managerList={
                          reviewer &&
                          reviewer.managersList
                            ?.filter((obj) => {
                              if (user) {
                                return user.employeeId !== obj.employeeId;
                              }
                              return true;
                            })
                            .filter((emp) => loggedInUser.employeeId !== emp.employeeId)
                        }
                      />
                    ) : (
                      <UserTakeAction
                        blockTimes={blockTimes}
                        timezoneList={timezoneList}
                        isRegisterInTeams={true}
                        timezone={timezone || timezoneList.find((v) => v.value === currentTimezone)}
                        setTimezone={handleTimezone}
                        isPage="previous"
                        isEdit={oneOnOne && oneOnOne.scheduledDate ? false : true}
                        handleStartTime={handleStartTime}
                        handleEndTime={handleEndTime}
                        startTime={startTime}
                        endTime={endTime}
                        handleStartDate={handleStartDate}
                        startDate={startDate}
                        selectedThirdPerson={oneOnOne && oneOnOne.thirdperson}
                        loggedInUser={loggedInUser}
                        oneOnOneAgendaId={oneOnOneAgendaId}
                        style={style}
                        scheduleOneOnOne={scheduleOneOnOne}
                        managerList={
                          reviewer &&
                          reviewer.managersList
                            ?.filter((obj) => {
                              if (user) {
                                return user.employeeId !== obj.employeeId;
                              }
                              return true;
                            })
                            .filter((emp) => loggedInUser.employeeId !== emp.employeeId)
                        }
                      />
                    )}
                  </>
                ))}
            </Form>
          </Col>
          <Col md={12} lg={4}>
            <ThisOneOnOne oneOnOne={oneOnOne} />
            {user && <PreviouslyHeldOneOnOne data={previousOneOnOneData} employeeId={user.employeeId} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
