import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  Scatter,
  Line,
  ResponsiveContainer,
} from "recharts";
import style from "./ComposeChart.module.scss";
import { stringToDateFormat1 } from "util/dateUtils";
import Switch from "shared/CustomSwitch/Switch";
import { displayStatus } from "util/general";

const switchValues = ["Projects", "Assessment", "Promotion", "Rewards"];

const COLORS = {
  PROMOTION: "#25A400",
  REWARDS: "#FFCB00",
  PROJECTS: "#FF895B",
  ASSESSMENT: "#71DCC9",
};
function ComposeChart({
  type = "Grade",
  data,
  handleAssessmentClick,
  projects,
  projectLineVisible,
  assessmentAreaVisible,
  handleDeallocationGraphClick,
  isPage = "EMPLOYEE",
  isCurrentDate,
}) {
  const [currentVal, setCurrentVal] = useState(null);
  const [multiGraph, setMultiGraph] = useState({ rewards: true, promotion: true, projects: true, assessment: true });
  const [ticksRange, setTicksRange] = useState(isCurrentDate === "startDate" ? [0, 1, 2, 3] : isCurrentDate === "currentDate" ? [0, 1, 2, 3, 4] : [0, 1, 2, 3, 4, 5])
  const [domain, setDomain] = useState(isCurrentDate === "startDate" ? [0, 3] : isCurrentDate === "currentDate" ? [0, 4] : [0, 5])
  useEffect(() => {
    setTicksRange(isCurrentDate === "startDate" ? [0, 1, 2, 3] : isCurrentDate === "currentDate" ? [0, 1, 2, 3, 4] : [0, 1, 2, 3, 4, 5])
    setDomain(isCurrentDate === "startDate" ? [0, 3] : isCurrentDate === "currentDate" ? [0, 4] : [0, 5])
  }, [isCurrentDate])
  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    const display = (rating) => {
      if (type === "Grade") {
        if (rating === 1) {
          return "C";
        } else if (rating === 2) {
          return "B";
        } else if (rating === 3) {
          return "B+";
        } else if (rating === 4) {
          return "A";
        } else if (rating === 5) {
          return "A+";
        } else return "N/A";
      } else {
        if(rating > 0){
          return rating;
        }
        else return "N/A";
       
      }
    };
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={5} fill="#666" className="customized-axis-tick-text" textAnchor="end">
          {display(payload.value)}
        </text>
      </g>
    );
  };

  const renderScatter = (props) => {
    const { x, y, node } = props;
    if (node.y === 2) {
      return (
        <g transform={`translate(${x},${y - 30})`}>
          <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Performance" stroke="none" fill="none">
              <g
                id="Profile_Manager_Checking_Employee_Engagement-Copy-4"
                transform="translate(-459.000000, -1885.000000)"
                fill={COLORS.REWARDS}
              >
                <path
                  d="M467.5,1885 C462.789157,1885 459,1888.80531 459,1893.49949 C459,1898.19469 462.789157,1902 467.5,1902 C472.108434,1902 476,1898.19469 476,1893.49949 C476,1888.80531 472.108434,1885 467.5,1885 Z M460.638554,1893.49949 C460.638554,1889.74435 463.710843,1886.6999 467.5,1886.6999 C471.186747,1886.6999 474.259036,1889.74435 474.259036,1893.49949 C474.259036,1897.25565 471.186747,1900.3001 467.5,1900.3001 C463.710843,1900.3001 460.638554,1897.25565 460.638554,1893.49949 Z M466.680723,1889.51292 C466.885542,1888.72953 468.012048,1888.72953 468.216867,1889.51292 L468.933735,1891.49852 L470.981928,1891.49852 C471.801205,1891.49852 472.210843,1892.55226 471.493976,1893.03662 L469.855422,1894.26444 L470.46988,1896.25005 C470.674699,1897.03343 469.855422,1897.68472 469.138554,1897.20035 L467.5,1895.97356 L465.759036,1897.20035 C465.144578,1897.68472 464.222892,1897.03343 464.427711,1896.25005 L465.144578,1894.26444 L463.403614,1893.03662 C462.789157,1892.55226 463.096386,1891.49852 463.915663,1891.49852 L465.963855,1891.49852 L466.680723,1889.51292 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </svg>
        </g>
      );
    }
    if (node.y === 1)
      return (
        <g transform={`translate(${x},${y - 30})`}>
          <svg width="15px" height="17px" viewBox="0 0 15 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Performance" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                id="Profile_Manager_Checking_Employee_Engagement-Copy-4"
                transform="translate(-641.000000, -1699.000000)"
                fill={COLORS.PROMOTION}
              >
                <path
                  d="M644.92255,1699.53958 C644.822204,1699.26719 644.621512,1699.06852 644.320473,1699.01527 C644.019435,1698.961 643.718396,1699.05828 643.517703,1699.27333 L641.209741,1701.82319 C641.009048,1702.07203 640.908702,1702.43147 641.109394,1702.74073 L644.521166,1710.66987 C644.420819,1711.01497 644.320473,1711.37646 644.320473,1711.75023 C644.320473,1714.09733 646.227051,1716 648.535014,1716 C650.842977,1716 652.649209,1714.09733 652.649209,1711.75023 C652.649209,1711.33652 652.649209,1710.93715 652.548863,1710.55928 C652.548863,1710.5091 652.548863,1710.45482 652.649209,1710.39543 L655.960634,1702.74483 C656.06098,1702.4243 655.960634,1702.04746 655.759941,1701.79861 L653.251286,1699.24875 C653.050594,1699.04702 652.749555,1698.95998 652.548863,1699.01732 C652.247824,1699.07467 652.047131,1699.26924 651.946785,1699.53446 L650.943323,1701.97475 L646.026359,1701.97475 C645.926013,1701.97475 645.926013,1701.97885 645.825666,1701.98704 L644.92255,1699.53958 Z M646.52809,1703.67466 L648.033283,1707.53119 C648.133629,1707.51071 648.334322,1707.50047 648.535014,1707.50047 C648.63536,1707.50047 648.63536,1707.50252 648.735706,1707.50866 L650.2409,1703.67466 L646.52809,1703.67466 Z M646.427744,1708.07188 C646.126705,1708.27362 645.72532,1708.52246 645.524628,1708.81022 L642.81528,1702.56869 L643.818742,1701.42382 L646.427744,1708.07188 Z M654.154402,1702.58815 L651.445054,1708.77642 C651.144016,1708.44668 650.842977,1708.16917 650.341246,1707.95821 L652.950247,1701.36237 L654.154402,1702.58815 Z M646.026359,1711.75023 C646.026359,1713.15829 647.130167,1714.30009 648.535014,1714.30009 C649.839515,1714.30009 651.043669,1713.15829 651.043669,1711.75023 C651.043669,1710.34116 649.839515,1709.20037 648.535014,1709.20037 C647.130167,1709.20037 646.026359,1710.34116 646.026359,1711.75023 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </svg>
        </g>
      );
  };

  const customTooltip = (props) => {
    if (props.active && currentVal) {
      if (currentVal?.key === "assessment") {
        let subText;
        let assessmentValue = props.payload.find((value) => value.dataKey === "assessment")?.payload;
        let rating = assessmentValue?.assessment;
        if (assessmentValue?.gradeOrRatingOrBoth === "RATING") {
          subText = (
            <div>
              Rating - <span>{ rating === 0 ? ' ' : rating }</span>
            </div>
          );
        } else {
          let grade = assessmentValue?.grade;
          subText = (
            <div>
              Grade - <span>{grade}</span>
            </div>
          );
        }
        return (
          <div className="recharts-tooltip-custom-wrapper">
            <span className="recharts-tooltip-value">{assessmentValue?.cycle}</span>
            <div className="recharts-tooltip-label"> {subText}</div>
          </div>
        );
      } else if (currentVal?.key?.includes("project")) {
        let projectValue = props.payload.find((value) => value.dataKey === `project${currentVal.index}`)?.payload[
          `projects${currentVal.index}`
        ];
        return (
          <div className="recharts-tooltip-custom-wrapper">
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Allocation:</span>
              <span className="recharts-tooltip-label">
                {stringToDateFormat1(projectValue?.startDate, "DD MMM, YYYY")} -{" "}
                {stringToDateFormat1(projectValue?.endDate, "DD MMM, YYYY")}
              </span>
            </div>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Project:</span>
              <span className="recharts-tooltip-label">{projectValue?.projectName}</span>
            </div>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Client Name:</span>
              <span className="recharts-tooltip-label">{projectValue?.clientName}</span>
            </div>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Billing Category:</span>
              <span className="recharts-tooltip-label">Billable</span>
            </div>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Allocation %:</span>
              <span className="recharts-tooltip-label">{projectValue?.allocationPercentage}%</span>
            </div>
          </div>
        );
      } else if (currentVal?.key === "reward") {
        let rewardsObj = {};
        let rewards = props.payload.find((value) => value.dataKey === "reward")?.payload?.rewards;
        for (let value of rewards) {
          if (rewardsObj[value.rewardName]) {
            rewardsObj[value.rewardName] += 1;
          } else {
            rewardsObj[value.rewardName] = 1;
          }
        }
        return (
          <div className="recharts-tooltip-custom-wrapper">
            <span className="recharts-tooltip-value">Rewarded : </span>
            {Object.entries(rewardsObj) &&
              Object.entries(rewardsObj).length > 0 &&
              Object.entries(rewardsObj).map(([name, value]) => (
                <p className="recharts-tooltip-label" key={name}>
                  {`${name} (${value})`}
                </p>
              ))}
          </div>
        );
      } else if (currentVal?.key === "promotion") {
        let promotionValue = props.payload.find((value) => value.dataKey === "promotion")?.payload?.promotionValue;
        return (
          <div className="recharts-tooltip-custom-wrapper">
            <span className="recharts-tooltip-value">Promoted to:</span>
            <p className="recharts-tooltip-label">{promotionValue}</p>
          </div>
        );
      } else if (currentVal?.key?.includes("deallocation")) {
        let projectValue = props.payload.find((value) => value.dataKey === `deallocation${currentVal.index}`)?.payload[
          `deallocations${currentVal.index}`
        ];
        // let projectDeAllocationId = projectValue.projectDeAllocationId;
        return projectValue.projectName.map((v) => (
          <div className="recharts-tooltip-custom-wrapper" key={v.projectName}>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Allocation:</span>
              <span className="recharts-tooltip-label">
                {stringToDateFormat1(v?.startDate, "DD MMM, YYYY")} - {stringToDateFormat1(v?.endDate, "DD MMM, YYYY")}
              </span>
            </div>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Project:</span>
              <span className="recharts-tooltip-label">{v?.projectName}</span>
            </div>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Client Name:</span>
              <span className="recharts-tooltip-label">{v?.clientName}</span>
            </div>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Deallocation date:</span>
              <span className="recharts-tooltip-label">{stringToDateFormat1(v?.deallocationDate, "DD MMM, YYYY")}</span>
            </div>
            <div className="pb-1">
              <span className="recharts-tooltip-value mr-2">Allocation %:</span>
              <span className="recharts-tooltip-label">{v?.allocationPercentage || "-"}%</span>
            </div>
            {isPage !== "EMPLOYEE" && (
              <div className="pb-1">
                <span className="recharts-tooltip-value mr-2">Status:</span>
                <span className="recharts-tooltip-label">{displayStatus(projectValue?.status)}</span>
              </div>
            )}
          </div>
        ));
      }
    }
    return "";
  };

  return (
    <div className={style.compose_chart_wrapper}>
      <ResponsiveContainer height={300}>
        <ComposedChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          // onClick={handleAssessmentClick}
        >
          <XAxis dataKey="name" axisLine={false} tickLine={false} padding={{ left: 10 }} tickMargin={10} />
          <CartesianGrid vertical={false} strokeOpacity={0.3} />
          <YAxis
            axisLine={false}
            tickLine={false}
            type={"number"}
            ticks={ticksRange}
            domain={domain}
            tick={<CustomizedAxisTick />}
          />
           {multiGraph.projects &&
            projects &&
            projects.length > 0 &&
            projects.map((value, index) => {
              if (projectLineVisible?.length > 0 && projectLineVisible?.includes(value)) {
                return (
                  <Line
                    key={value}
                    type="monotone"
                    dataKey={value}
                    stroke={COLORS.PROJECTS}
                    isAnimationActive={false}
                    dot={{ stroke: COLORS.PROJECTS, strokeWidth: 2 }}
                    connectNulls={true}
                    strokeWidth={3}
                    onMouseOver={(e) => setCurrentVal({ key: value, name: e, index: index + 1 })}
                    onMouseLeave={() => setCurrentVal(null)}
                  />
                );
              } else {
                return (
                  <Scatter
                    key={value}
                    dataKey={value}
                    fill={COLORS.PROJECTS}
                    isAnimationActive={false}
                    onMouseOver={(e) => setCurrentVal({ key: value, name: e.name, index: index + 1 })}
                    onMouseLeave={() => setCurrentVal(null)}
                  />
                );
              }
            })}
          {multiGraph.projects && (
            <Scatter
              dataKey="deallocation1"
              shape={"wye"}
              fill={COLORS.PROJECTS}
              isAnimationActive={false}
              onClick={(data) => handleDeallocationGraphClick(data, 1)}
              onMouseOver={(e) => setCurrentVal({ key: "deallocation1", name: e.name, index: 1 })}
              onMouseLeave={() => setCurrentVal(null)}
            />
          )}
          {multiGraph.projects && (
            <Scatter
              dataKey="deallocation2"
              shape={"wye"}
              fill={COLORS.PROJECTS}
              isAnimationActive={false}
              onClick={(data) => handleDeallocationGraphClick(data, 2)}
              onMouseOver={(e) => setCurrentVal({ key: "deallocation2", name: e.name, index: 2 })}
              onMouseLeave={() => setCurrentVal(null)}
            />
          )}
          {multiGraph.projects && (
            <Scatter
              dataKey="deallocation3"
              shape={"wye"}
              fill={COLORS.PROJECTS}
              isAnimationActive={false}
              onClick={(data) => handleDeallocationGraphClick(data, 3)}
              onMouseOver={(e) => setCurrentVal({ key: "deallocation3", name: e.name, index: 3 })}
              onMouseLeave={() => setCurrentVal(null)}
            />
          )}
          {multiGraph.assessment &&
            (assessmentAreaVisible ? (
              <Area
                type="monotone"
                dataKey="assessment"
                stroke="#00c4bc"
                fillOpacity={1}
                fill="url(#colorUv)"
                connectNulls={true}
                isAnimationActive={false}
              />
            ) : (
              <Scatter
                key="assessment"
                dataKey="assessment"
                isAnimationActive={false}
                fill={COLORS.ASSESSMENT}
                onMouseOver={(e) => setCurrentVal({ key: "assessment", name: e.name })}
                onMouseLeave={() => setCurrentVal(null)}
                onClick={(e) => handleAssessmentClick(e)}
              />
            ))}
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00c4bc" stopOpacity={0.3} />
              <stop offset="95%" stopColor="#00c4bc" stopOpacity={0} />
            </linearGradient>
          </defs>
          {assessmentAreaVisible && multiGraph.assessment && (
            <Scatter
              key="assessment"
              dataKey="assessment"
              fill={COLORS.ASSESSMENT}
              isAnimationActive={false}
              onMouseOver={(e) => setCurrentVal({ key: "assessment", name: e.name })}
              onMouseLeave={() => setCurrentVal(null)}
              onClick={(e) => handleAssessmentClick(e)}
            />
          )}
          {multiGraph.rewards && (
            <Scatter
              dataKey="reward"
              shape={renderScatter}
              onMouseOver={(e) => setCurrentVal({ key: "reward", name: e.name })}
              onMouseLeave={() => setCurrentVal(null)}
            />
          )}
          {multiGraph.promotion && (
            <Scatter
              dataKey="promotion"
              shape={renderScatter}
              onMouseOver={(e) => setCurrentVal({ key: "promotion", name: e.name })}
              onMouseLeave={() => setCurrentVal(null)}
            />
          )}
          <Tooltip content={customTooltip} cursor={false} />
        </ComposedChart>
      </ResponsiveContainer>
      {/* commented because this requirement given by hr  */}
      {/* <div className={style.switch_wrapper}>
        {switchValues &&
          switchValues.map((value) => (
            <div key={value} className={style.switch_item}>
              <Switch
                title={value}
                handleChange={(e) =>
                  setMultiGraph({
                    ...multiGraph,
                    [e.target.name?.toLowerCase()]: e.target.checked,
                  })
                }
                colorType={value?.toLocaleLowerCase()}
              />
            </div>
          ))}
      </div> */}
    </div>
  );
}

ComposeChart.defaultProps = {
  handleDeallocationGraphClick: () => {},
};

export default ComposeChart;
