import * as actionTypes from "../actions/actionTypes";

const initialState = {
  pageSize: 5,
  currentPage: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAGINATION_PAGESIZE:
      return {
        ...state,
        pageSize: action.pageSize || 5,
      };
    case actionTypes.PAGINATION_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage || 1,
      };
    default:
      return state;
  }
};

export default reducer;
