import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

export default function CommonDatePicker(props) {
  const {
    handleStartDate,
    handleEndDate,
    duration,
    startDate,
    endDate,
    startDatePlaceHolder,
    endDatePlaceHolderText,
    isDisabled = false,
  } = props;
  let todayDate = new Date();
  const maxDate = new Date(todayDate.getFullYear() + 100, todayDate.getMonth(), todayDate.getDate());

  const range = (start, end) => {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  };

  const years = range(1990, new Date().getFullYear() + 100);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    // <div className="box-inner-pad">
    <div className="date_picker_time_wrapper p-0">
      {/* <h5>Please Select Start Date & End Date</h5> */}
      <div className="calendar_wrapper form-group">
        <DatePicker
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div className="date-picker-dropdown">
              <select value={new Date(date).getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[new Date(date).getMonth()]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          className="form-control"
          placeholderText={startDatePlaceHolder}
          selected={startDate}
          maxDate={endDate || maxDate}
          onChange={handleStartDate}
          dateFormat="MMMM d, yyyy"
          disabled={isDisabled}
        />
      </div>
      <div className="calendar_wrapper form-group">
        <DatePicker
          disabled={isDisabled}
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div className="date-picker-dropdown">
              <select value={new Date(date).getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[new Date(date).getMonth()]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          placeholderText={endDatePlaceHolderText}
          className="form-control"
          selected={endDate}
          minDate={startDate}
          maxDate={maxDate}
          onChange={handleEndDate}
          dateFormat="MMMM d, yyyy"
        />
      </div>
      {props.showDayDiff && (
        <div className={["form-group", "duration_txt"].join(" ")}>{duration ? duration : 0} Days</div>
      )}
    </div>
    // </div>
  );
}

CommonDatePicker.propTypes = {
  handleStartDate: PropTypes.func,
  handleEndDate: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};
