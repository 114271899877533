const IC_LIST_VIEW = require("../assets/images/ic-list-view.svg");
const IC_TREE_VIEW = require("../assets/images/ic-tree-view.svg");
const IC_INPUT_SEARCH = require("../assets/images/ic-input-search.svg");
const IMG_USER_PIC_1 = require("../assets/images/user01.png");
const IMG_USER_PIC_2 = require("../assets/images/user02.png");
const IMG_USER_PIC_4 = require("../assets/images/user04.png");
const IMG_USER_PIC_5 = require("../assets/images/user05.png");
const IMG_USER_PIC_6 = require("../assets/images/user06.png");
const IMG_USER_PIC_8 = require("../assets/images/user08.png");
const IMG_USER_PIC_9 = require("../assets/images/user09.png");
const IMG_USER_PIC_10 = require("../assets/images/user10.png");
const IMG_USER_PIC_11 = require("../assets/images/user11.png");
const IMG_USER_PIC_12 = require("../assets/images/user12.png");
const IMG_USER_PROFILE = require("../assets/images/profile.svg");
const IC_PLUS = require("../assets/images/ic-plus.svg");
const IC_MINUS = require("../assets/images/ic-minus.svg");
const IC_INFO = require("../assets/images/ic-info.svg");
const IC_NOTIFICATION_1 = require("../assets/images/ic-notification-1.svg");
const IC_RELOAD = require("../assets/images/ic-reload.svg");
const IC_COMMENT = require("../assets/images/ic-comment.svg");
const IC_FILEUPLOAD = require("../assets/images/ic-fileupload.svg");
const IC_FILE_UPLOAD_1 = require("../assets/images/ic-fileupload-1.svg");
const IC_ACC_ARROW_DOWN = require("../assets/images/ic-accordion-arrow-down.svg");
const IC_ACC_ARROW_UP = require("../assets/images/ic-accordion-arrow-up.svg");
const IC_UNLOCK = require("../assets/images/ic-unlock.svg");
const IC_DELETE = require("../assets/images/ic-delete.svg");
const IC_NAV_ACTION = require("../assets/images/ic-nav-action.svg");
const IC_GLOBAL = require("../assets/images/ic-global.svg");
const IC_ARROW_DOWN_SM = require("../assets/images/ic-down-arrow-sm.svg");
const MAIN_LOGO = require("../assets/images/logo.svg");
const IC_BELL_WHITE = require("../assets/images/ic_bell_white.svg");
const IMG_USER01 = require("../assets/images/user01.png");
const IC_CHECKBOX_DISABLED = require("../assets/images/ic_checkbox_disabled.svg");
const IC_CHAT_BUBBLE = require("../assets/images/ic-chat-bubble.svg");
const IC_ACHIEVEMENT = require("../assets/images/ic-archivment.svg");
const IC_CALENDAR = require("../assets/images/ic_calendar.svg");
const IC_PLUS_ROUNDED = require("../assets/images/ic_plus_rounded.svg");
const IC_CLOSE_ROUNDED = require("../assets/images/ic_close_rounded.svg");
const IC_LOCK_PRIVACY = require("../assets/images/ic_lock_privacy-1.svg");
const IC_LOCK_PRIVACY_1 = require("../assets/images/ic_lock_privacy.svg");
const IC_LOCK_PRIVACY_2 = require("../assets/images/ic_lock_privacy-2.svg");
const IC_TODO = require("../assets/images/ic_todo.svg");
const IC_SELECT_ARROW = require("../assets/images/ic_select_arrow.svg");
const LOADER = require("../assets/images/loader.svg");
const IC_LINKEDIN = require("../assets/images/ic_linkedin.svg");
const IC_TWITTER = require("../assets/images/ic_twitter.svg");
const IC_DOWNLOAD = require("../assets/images/ic_download.svg");
const IC_PDF = require("../assets/images/ic_pdf.svg");
const IC_EMAIL = require("../assets/images/ic_email.svg");
const IC_PENCIL = require("../assets/images/ic_pencil.svg");
const IC_ANALYTICS_LIKE = require("../assets/images/ic_analytics_like.svg");
const IC_ANALYTICS_BADGE = require("../assets/images/ic_analytics_badge.svg");
const IC_ANALYTICS_REWARD = require("../assets/images/ic_analytics_reward.svg");
const IC_ANALYTICS_AWARD = require("../assets/images/ic_analytics_awards.svg");
const IC_BULLET = require("../assets/images/ic_bullet.svg");
const IC_LOGINVIZ = require("../assets/images/ic_login_viz.svg");
const IC_LOGIN_USER = require("../assets/images/ic_login_user.svg");
const IC_LOGIN_PASS = require("../assets/images/ic_login_pass.svg");
const IC_OBJECTIVES = require("../assets/images/ic_objectives.svg");
const IC_LOGIN_FOOTER = require("../assets/images/login_footer_bg.svg");
const IC_CHECKINS_ACTIVITIES = require("../assets/images/ic_checkins_activities.svg");
const IC_CLOCK = require("../assets/images/ic_clock.svg");
const IC_SM_LIKE = require("../assets/images/ic_sm_like.svg");
const IC_CAREER_VISION = require("../assets/images/ic_career_vision.svg");
const IC_CAREER_TRAJECTORY = require("../assets/images/ic_careertrajectory.svg");
const IC_BEST_STREAK = require("../assets/images/ic_best_streak.svg");
const IC_CURRENT_STREAK = require("../assets/images/ic_current_streak.svg");
const IC_ORG_BEST_STREAK = require("../assets/images/ic_org_best_streak.svg");
const IC_COMPLETED = require("../assets/images/ic_completed.svg");
const IC_ON_TIME = require("../assets/images/ic_on_time.svg");
const IMG_LOGIN_ENGAGE = require("../assets/images/logo_engage.svg");
const MY_LOGO = require("../assets/images/my_logo.svg");
const IMG_POWERED_BY_IS = require("../assets/images/powered_by_is.svg");
const IMG_POWERED_BY_APEXON = require("../assets/images/powered_by_apexon.svg");
const IC_DOWN_ARROW_BLACK = require("../assets/images/ic_down_arrow_black.svg");
const IC_ERROR = require("../assets/images/ic_error.svg");
const IC_TEXTAREA = require("../assets/images/ic_textarea.svg");
const IMG_NODATAFOUND = require("../assets/images/img_no_data_found.svg");
const IMG_TOAST_SUCCESS = require("../assets/images/img_toast_success.svg");
const IMG_TOAST_OOPS = require("../assets/images/img_toast_oops.svg");
const IMG_TOAST_WAIT = require("../assets/images/img_toast_wait.svg");
const IMG_TOAST_CLOSE = require("../assets/images/ic_toast_close.svg");
const IMG_WAITING_FOR_SUBMISSION = require("../assets/images/img_waithingforsubmition.svg");
const IMG_CHECKINS_LAPSED = require("../assets/images/img_check-in_not_submitted.svg");
const IC_SEARCH_RESET = require("../assets/images/ic_search_reset.svg");
const IC_FILE_ATTACHMENT = require("../assets/images/ic_file_attachment.svg");
const IMG_PAGENOTFOUND = require("../assets/images/img_pagenotfound.svg");
const IMG_FOLDER_INNER = require("../assets/images/folder-inner.svg");
const IMG_FOLDER_OUTER = require("../assets/images/Folder-outer.svg");
const IMG_FOLDER_PAPER = require("../assets/images/folder-paper.svg");
const IMG_FOLDER_BACKGROUND = require("../assets/images/folder-background.svg");
const IMG_FOLDER_LINE = require("../assets/images/folder-line.svg");
const IMG_USERDEMO = require("../assets/images/user_img.jpg");
const IMG_PRIVATE_COMMENTS = require("../assets/images/ic_private_comments.svg");
const IMG_RECEIVED_REWARDS = require("../assets/images/img_received_rewards.svg");
const IMG_SENT_REWARDS = require("../assets/images/img_sent_reward.svg");
const IC_SUBMIT_BTN = require("../assets/images/ic_submit_btn.svg");
const IMG_NO_NOTIFICATION = require("../assets/images/img_no_notifications.svg");
const IC_NOTIFICATION_CLOSE = require("../assets/images/ic_notification_close.svg");
const IC_MOVE = require("../assets/images/ic_move.svg");
const IC_CHALLENGES = require("../assets/images/ic_challenges.svg");
const IC_DUSTBIN = require("../assets/images/ic_dustbin.svg");
const IC_LINK = require("../assets/images/ic_link.svg");
const IC_DUSTBIN_PLAN = require("../assets/images/ic_dustbin.svg");
const IC_SETTINGS = require("../assets/images/ic_setting.svg");
const IC_CALENDAR_2 = require("../assets/images/ic_calendar_2.svg");
const IC_SYNC_PURPLE = require("../assets/images/ic_sync_purple.svg");
const IC_EXPAND = require("../assets/images/ic_expand.svg");
const IC_COLLAPSE = require("../assets/images/ic_expand_dash.svg");
const IC_KEYRESULT_BULLET = require("../assets/images/ic_keyresult_bullet.svg");
const IMG_NO_INFONEER_SELECTED = require("../assets/images/img_no_infoneer_selected.svg");
const IC_VIEW_DETAILS = require("../assets/images/ic_view_details.svg");
const IC_EDIT_OBJECTIVE = require("../assets/images/ic_edit_objective.svg");
const SUBMIT_ROUND = require("../assets/images/submit_round_green.svg");
const CANCEL_ROUND = require("../assets/images/Cancel_round_red.svg");
const CANCEL_CROSS = require("../assets/images/cancel_cross.svg");
const IC_DELETE_2 = require("../assets/images/ic_delete.svg");
const IC_ASSIGN_OBJECTIVE = require("../assets/images/ic_assign_objective.svg");
const IC_NOTIFY = require("../assets/images/ic_notify.svg");
const IMG_NO_OBJECTIVE = require("../assets/images/no_objectives_found.svg");
const IC_CERTIFICATION = require("../assets/images/ic_certification.svg");
const IC_CB_OBJ = require("../assets/images/ic_cb_obj.svg");
const IC_CSV_MODAL = require("../assets/images/ic_csv_modal.svg");
const IMG_UNIVERSITY = require("../assets/images/university-banner.svg");
const IC_DOWNLOAD_1 = require("../assets/images/ic_download1.svg");
const IC_CERTIFICATION_1 = require("../assets/images/certificate.svg");
const IMG_SKILLMATRIX = require("../assets/images/skill-matrix.svg");
const IMG_ENDORSEMENT_1 = require("../assets/images/img_endrosement_1.svg");
const IMG_PM = require("../assets/images/PM.svg");
const IMG_SME = require("../assets/images/SME.svg");
const IMG_UNI = require("../assets/images/University.svg");
const IC_EMP_DETAIL = require("../assets/images/ic_emp_detail.svg");
const IC_FLAG = require("../assets/images/ic_flag.svg");
const IC_RIGHT_TICKMARK = require("../assets/images/ic_cb_right.svg");
const IC_FEEDBACK = require("../assets/images/ic_feedback.svg");
const FEEDBACK_ICON = require("../assets/images/feedback_icon_white.svg");
const FEEDBACK_ICON_2 = require("../assets/images/feedback_icon_white_2.svg");
const IC_ENDORSEMENT_APPROVE = require("../assets/images/ic_approve_end.svg");
const IC_ENDORSEMENT_DECLINE = require("../assets/images/ic_end_decline.svg");
const IC_STAR_PURPLE = require("../assets/images/ic_star_purple.svg");
const IC_OBJ_ARROW_UP = require("../assets/images/ic_obj_arrow_up.svg");
const IC_OBJ_ARROW_DOWN = require("../assets/images/ic_obj_arrow_down.svg");
const IC_OBJ_RANGE = require("../assets/images/ic_obj_range.svg");
const IC_CLONE = require("../assets/images/ic_clone.svg");
const IC_CLONE_2 = require("../assets/images/ic_clone_2.svg");
const IC_EYE = require("../assets/images/ic_eye_view.svg");
const IC_STAR_CIRCLE = require("../assets/images/ic_star_circle.svg");
const IC_SORT_UP_DOWN = require("../assets/images/ic_sort_updown.svg");
const IC_SORT_UP = require("../assets/images/ic_sort_up.svg");
const IC_SORT_DOWN = require("../assets/images/ic_sort_down.svg");
const IMG_HR_BANNER = require("../assets/images/hr-banner.svg");
const IC_SMILE = require("../assets/images/smile.svg");
const IC_TREE_VIEW2 = require("../assets/images/tree_view_icon.svg");
const IC_COMMENT_ROUND = require("../assets/images/ic_comment_round.svg");
const IC_CHECKIN = require("../assets/images/check_in_icon.svg");
const IC_LIGHT = require("../assets/images/ic_light.svg");
const IC_CHALLENGE = require("../assets/images/ic_challenge.svg");
const IC_COLLECTIVE = require("../assets/images/ic_collective.svg");
const IC_WINS = require("../assets/images/ic_wins.svg");
const IC_LIST = require("../assets/images/IC_LIST.svg");
const IC_GRAPH = require("../assets/images/IC_GRAPH.svg");
const GREEN_UP_ARROW = require("../assets/images/green_up_arrow.svg");
const RED_DOWN_ARROW = require("../assets/images/red_down_arrow.svg");
const YELLOW_STABLE_ARROW = require("../assets/images/yellow_stable_arrow.svg");
const IC_MENU_HAM_BURGER = require("../assets/images/ic_menu_hamburger.svg");
const IC_LOG_OUT = require("../assets/images/ic_log_off.svg");
const IC_REWARDS_SHAPE = require("../assets/images/Ic_reward.svg");
const IC_PROMOTION = require("../assets/images/Ic_promotion.svg");
const MEMBER_NOT_FOUND = require("../assets/images/member_not_found.svg");
const SIDE_DROPDOWN_ARROW = require("../assets/images/ic_side_dropdown_arrow.svg");
const IC_FILTER = require("../assets/images/ic_filter.svg");
const IC_EXPORT = require("../assets/images/ic_export.svg");
const EXPORT_BUTTON = require("../assets/images/export_button.svg");
const IC_PASS = require("../assets/images/ic_pass.svg");
const IC_FAIL = require("../assets/images/ic_fail.svg");
const IC_QUIZ_TIMER = require("../assets/images/quiz_timer.svg");
const IC_CORRECT_ANSWER = require("../assets/images/ic_correct_answer.svg");
const IC_WRONG_ANSWER = require("../assets/images/ic_wrong_answer.svg");
const threeDotIcon = require("../assets/images/threeDotIcon.svg");
const IMG_RESOURCES = require("../assets/images/resources-banner.svg");
const IMG_ASSIGN_OKR = require("../assets/images/assign-okr.png");
const IC_CLOSE = require("../assets/images/ic_close.svg");
const IC_VIDEO = require("../assets/images/ic_video.svg");
const IC_DOCUMENT = require("../assets/images/ic_document.svg");
const IC_CLOSE_1 = require("../assets/images/ic_close_1.svg");
const IC_ZOOM_OUT = require("../assets/images/ic_zoomout.svg");
const IC_ZOOM_IN = require("../assets/images/ic_zoomin.svg");

export {
  IC_LIST_VIEW,
  IC_TREE_VIEW,
  IC_INPUT_SEARCH,
  IMG_USER_PIC_1,
  IMG_USER_PIC_2,
  IMG_USER_PIC_5,
  IMG_USER_PIC_6,
  IMG_USER_PIC_8,
  IMG_USER_PIC_9,
  IMG_USER_PIC_10,
  IMG_USER_PIC_11,
  IMG_USER_PIC_12,
  IMG_USER_PROFILE,
  IC_PLUS,
  IC_MINUS,
  IC_INFO,
  IC_NOTIFICATION_1,
  IC_RELOAD,
  IC_COMMENT,
  IC_FILEUPLOAD,
  IC_FILE_UPLOAD_1,
  IC_ACC_ARROW_DOWN,
  IC_ACC_ARROW_UP,
  IMG_USER_PIC_4,
  IC_UNLOCK,
  IC_DELETE,
  IC_NAV_ACTION,
  IC_GLOBAL,
  IC_ARROW_DOWN_SM,
  MAIN_LOGO,
  IC_BELL_WHITE,
  IMG_USER01,
  IC_CHECKBOX_DISABLED,
  IC_CHAT_BUBBLE,
  IC_ACHIEVEMENT,
  IC_CALENDAR,
  IC_PLUS_ROUNDED,
  IC_CLOSE_ROUNDED,
  IC_LOCK_PRIVACY,
  IC_TODO,
  IC_SELECT_ARROW,
  LOADER,
  IC_LINKEDIN,
  IC_TWITTER,
  IC_DOWNLOAD,
  IC_PDF,
  IC_EMAIL,
  IC_PENCIL,
  IC_ANALYTICS_LIKE,
  IC_ANALYTICS_BADGE,
  IC_ANALYTICS_REWARD,
  IC_ANALYTICS_AWARD,
  IC_BULLET,
  IC_LOGINVIZ,
  IC_LOGIN_USER,
  IC_LOGIN_PASS,
  IC_OBJECTIVES,
  IC_LOGIN_FOOTER,
  IC_CLOCK,
  IC_SM_LIKE,
  IC_CHECKINS_ACTIVITIES,
  IC_CAREER_VISION,
  IC_CAREER_TRAJECTORY,
  IC_BEST_STREAK,
  IC_CURRENT_STREAK,
  IC_ORG_BEST_STREAK,
  IC_COMPLETED,
  IC_ON_TIME,
  IMG_LOGIN_ENGAGE,
  MY_LOGO,
  IMG_POWERED_BY_IS,
  IMG_POWERED_BY_APEXON,
  IC_DOWN_ARROW_BLACK,
  IC_ERROR,
  IC_TEXTAREA,
  IMG_NODATAFOUND,
  IMG_TOAST_SUCCESS,
  IMG_TOAST_CLOSE,
  IMG_TOAST_OOPS,
  IMG_TOAST_WAIT,
  IMG_WAITING_FOR_SUBMISSION,
  IMG_CHECKINS_LAPSED,
  IC_SEARCH_RESET,
  IC_FILE_ATTACHMENT,
  IMG_PAGENOTFOUND,
  IMG_USERDEMO,
  IMG_PRIVATE_COMMENTS,
  IMG_FOLDER_INNER,
  IMG_FOLDER_OUTER,
  IMG_FOLDER_BACKGROUND,
  IMG_FOLDER_LINE,
  IMG_FOLDER_PAPER,
  IMG_RECEIVED_REWARDS,
  IMG_SENT_REWARDS,
  IC_NOTIFICATION_CLOSE,
  IC_SUBMIT_BTN,
  IC_LOCK_PRIVACY_1,
  IC_LOCK_PRIVACY_2,
  IMG_NO_NOTIFICATION,
  IC_MOVE,
  IC_CHALLENGES,
  IC_DUSTBIN,
  IC_LINK,
  IC_DUSTBIN_PLAN,
  IC_SETTINGS,
  IC_CALENDAR_2,
  IC_SYNC_PURPLE,
  IC_EXPAND,
  IC_KEYRESULT_BULLET,
  IC_COLLAPSE,
  IMG_NO_INFONEER_SELECTED,
  IC_ASSIGN_OBJECTIVE,
  IC_DELETE_2,
  IC_EDIT_OBJECTIVE,
  SUBMIT_ROUND,
  CANCEL_CROSS,
  CANCEL_ROUND,
  IC_VIEW_DETAILS,
  IC_NOTIFY,
  IMG_NO_OBJECTIVE,
  IC_CERTIFICATION,
  IC_CB_OBJ,
  IC_CSV_MODAL,
  IMG_UNIVERSITY,
  IC_DOWNLOAD_1,
  IC_CERTIFICATION_1,
  IMG_SKILLMATRIX,
  IMG_ENDORSEMENT_1,
  IMG_PM,
  IMG_SME,
  IMG_UNI,
  IC_EMP_DETAIL,
  IC_FLAG,
  IC_RIGHT_TICKMARK,
  IC_FEEDBACK,
  FEEDBACK_ICON,
  FEEDBACK_ICON_2,
  IC_ENDORSEMENT_APPROVE,
  IC_ENDORSEMENT_DECLINE,
  IC_STAR_PURPLE,
  IC_OBJ_ARROW_UP,
  IC_OBJ_ARROW_DOWN,
  IC_OBJ_RANGE,
  IC_CLONE,
  IC_CLONE_2,
  IC_EYE,
  IC_STAR_CIRCLE,
  IC_SORT_UP_DOWN,
  IC_SORT_UP,
  IC_SORT_DOWN,
  IMG_HR_BANNER,
  IC_SMILE,
  IC_TREE_VIEW2,
  IC_COMMENT_ROUND,
  IC_CHECKIN,
  IC_LIGHT,
  IC_CHALLENGE,
  IC_COLLECTIVE,
  IC_WINS,
  IC_LIST,
  IC_GRAPH,
  GREEN_UP_ARROW,
  RED_DOWN_ARROW,
  YELLOW_STABLE_ARROW,
  IC_MENU_HAM_BURGER,
  IC_LOG_OUT,
  IC_REWARDS_SHAPE,
  IC_PROMOTION,
  MEMBER_NOT_FOUND,
  SIDE_DROPDOWN_ARROW,
  IC_FILTER,
  IC_EXPORT,
  EXPORT_BUTTON,
  IC_PASS,
  IC_FAIL,
  IC_QUIZ_TIMER,
  IC_WRONG_ANSWER,
  IC_CORRECT_ANSWER,
  threeDotIcon,
  IMG_RESOURCES,
  IMG_ASSIGN_OKR,
  IC_CLOSE,
  IC_VIDEO,
  IC_DOCUMENT,
  IC_CLOSE_1,
  IC_ZOOM_IN,
  IC_ZOOM_OUT,
};
