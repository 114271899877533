import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import style from "./Notification.module.scss";
import NoNotification from "../NoNotification/NoNotification";
import { IC_BELL_WHITE, IC_NOTIFICATION_CLOSE } from "../.././const/imgCost";
import { useSelector, useDispatch } from "react-redux";
import { history } from "../../index";
import * as dateFilter from "../../util/dateUtils";
import * as actions from "../../store/actions/index";
import NotificationListItems from "./NotificationListItem";
import { redirectNotification } from "./notificationUtils";
import storage from "../../util/storage";
import { setDefaultMenu } from "util/utils";
import AuthContext from "context/authContext";

const Notification = () => {
  const context = useContext(AuthContext);
  const loggedInUser = storage.getUser();
  const employeeId = loggedInUser?.employeeId;
  const employeeIds = {
    employeeId: loggedInUser?.employeeId,
    parentGroupId: loggedInUser?.parentGroupId,
    subGroupId: loggedInUser?.subGroupId,
  };
  const allNotifications = useSelector((state) => state.notification.notificationResponse);
  const _allNotification = useSelector((state) => state.notification.allNotification);
  const [showNotification, setShowNotification] = useState(false);
  const [todaysNotification, setTodaysNotification] = useState([]);
  const [yesterdaysNotification, setYesterdaysNotification] = useState([]);
  const [lastWeekNotification, setLastWeeksNotification] = useState([]);
  const [markAllReadStyle, setMarkAllReadStyle] = useState(" ");
  const [otherNotification, setOtherNotification] = useState([]);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  let notificationScroll = React.createRef();
  const notificationToggle = () => {
    if (!showNotification) {
      if (document.getElementsByClassName("notification_list ").length !== 0) {
        document.getElementsByClassName("notification_list ")[0].scrollIntoView({
          block: "start",
        });
      }
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    setShowNotification(!showNotification);
    document.body.style.overflow = !showNotification ? "hidden" : "";
  };
  const notifyClass = showNotification ? "show_notification" : "";

  /******************************
   * NOTIFICATIONS SET INTERVAL *
   ******************************/
  useEffect(() => {
    dispatch(actions.FetchNotification(employeeIds));
    dispatch(actions.FetchAllNotification(employeeIds));
  }, [dispatch]);

  useEffect(() => {
    if (allNotifications && allNotifications.length !== 0) {
      let notifications = allNotifications.slice(0, 20);

      setTodaysNotification(notifications.filter((item) => dateFilter.isToday(item.createdDate)));
      setYesterdaysNotification(
        notifications.filter(
          (item) => !dateFilter.isToday(item.createdDate) && dateFilter.isYesterday(item.createdDate)
        )
      );
      setLastWeeksNotification(
        notifications.filter(
          (item) =>
            !dateFilter.isToday(item.createdDate) &&
            !dateFilter.isYesterday(item.createdDate) &&
            dateFilter.isWithinAWeek(item.createdDate)
        )
      );
      setOtherNotification(
        notifications.filter(
          (item) =>
            !dateFilter.isToday(item.createdDate) &&
            !dateFilter.isYesterday(item.createdDate) &&
            !dateFilter.isWithinAWeek(item.createdDate)
        )
      );
      if (_allNotification && _allNotification.length) {
        setCount(_allNotification.filter((item) => !item.isRead).length);
      }
      if (count === 0) {
        setMarkAllReadStyle("d-none");
      } else {
        setMarkAllReadStyle("");
      }
    } else if (allNotifications && allNotifications.length === 0) {
      setTodaysNotification([]);
      setYesterdaysNotification([]);
      setLastWeeksNotification([]);
      setCount(0);
      setOtherNotification([]);
    }
  }, [allNotifications, _allNotification, count]);

  const onNotificationClick = (notification) => {
    if (!showNotification) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    if (!notification.isRead) {
      dispatch(actions.OnReadNotification(notification.id, employeeIds));
    }
    setDefaultMenu(context);
    redirectNotification(notification, history);
    setShowNotification(!showNotification);
  };

  return (
    <>
      <span className={style.btn_notification} onClick={notificationToggle}>
        {count > 0 && <Badge variant="primary">{count < 99 ? count : "99+"}</Badge>}
        <ReactSVG src={IC_BELL_WHITE} title="" alt="" />
      </span>
      <div className={["notification_wrapper", notifyClass].join(" ")}>
        <div className="notification_overlay" onClick={notificationToggle}></div>
        <div className="notification_slider">
          <div className="header">
            <Row>
              <Col xs={8} sm={8} md={8}>
                <div className="title">Notifications {count > 0 && <span>{count < 99 ? count : "99+"}</span>}</div>
              </Col>
              <Col xs={4} sm={4} md={4}>
                <div className="notification_close" onClick={notificationToggle}>
                  <ReactSVG src={IC_NOTIFICATION_CLOSE} title="" alt="" />
                </div>
              </Col>
            </Row>
          </div>
          {allNotifications && allNotifications.length > 0 && (
            <div className="sub-header">
              <Row>
                <Col xs={6} sm={6} md={6}>
                  <Link
                    to="/notifications"
                    className="title"
                    onClick={() => {
                      setShowNotification();
                      document.body.style.overflow = "";
                    }}
                  >
                    View All
                  </Link>
                </Col>
                <Col xs={6} sm={6} md={6} className="text-right">
                  <span
                    className={["mark_read", markAllReadStyle].join(" ")}
                    onClick={() => dispatch(actions.MarkAllReadNotification(employeeId))}
                  >
                    Mark all read
                  </span>
                </Col>
              </Row>
            </div>
          )}
          {allNotifications && allNotifications.length === 0 && <NoNotification />}
          <div className="notification_list_wrapper" ref={notificationScroll}>
            <NotificationListItems title={"Today"} notifications={todaysNotification} onClick={onNotificationClick} />
            <NotificationListItems
              title={"Yesterday"}
              notifications={yesterdaysNotification}
              onClick={onNotificationClick}
            />
            <NotificationListItems
              title={"Last week"}
              notifications={lastWeekNotification}
              onClick={onNotificationClick}
            />
            <NotificationListItems title={"Older"} notifications={otherNotification} onClick={onNotificationClick} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
