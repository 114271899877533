import React from "react";
import { Form } from "react-bootstrap";
import style from "pages/CheckIns/OneOnOne/OneOnOne.module.scss";
import PropTypes from "prop-types";

function TakeActionOfQuestion(props) {
  const { selectQuestion, handleQuestion, oneOnOne } = props;
  let isAllMarked =
    oneOnOne &&
    oneOnOne.talkingPoints &&
    oneOnOne.talkingPoints.filter((value) => value.isCompleted).length === oneOnOne.talkingPoints.length;

  return (
    <div className="box-inner-pad">
      <Form.Group controlId="">
        <Form.Label>
          Take appropriate action<span className="text-danger">*</span>
        </Form.Label>
        <ul className={style.form_option_list}>
          <li>
            <div className="custom-checkbox custom-checkbox-rounded">
              <input
                disabled={!oneOnOne || isAllMarked}
                type="radio"
                id="discuss"
                value="discuss"
                checked={selectQuestion === "discuss"}
                onChange={handleQuestion}
              />
              <label htmlFor="discuss">Need to discuss new/remaining points</label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox custom-checkbox-rounded">
              <input
                disabled={!oneOnOne}
                type="radio"
                value="close"
                id="close"
                checked={selectQuestion === "close"}
                onChange={handleQuestion}
              />
              <label htmlFor="close">All points has been covered up, close this 1-on-1</label>
            </div>
          </li>
        </ul>
      </Form.Group>
    </div>
  );
}

TakeActionOfQuestion.propTypes = {
  selectQuestion: PropTypes.string,
  handleQuestion: PropTypes.func,
  oneOnOne: PropTypes.object,
};

export default TakeActionOfQuestion;
