import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import style from "./QuestionBuilder.module.scss";
import * as actionTypes from "store/actions/actionTypes";
import * as actions from "store/actions/index";
import { toast } from "react-toastify";
import { TEMPLATE, DETAIL_TEMPLATE, DELETE_TEMPLATE_QUESTION } from "util/endpoints";
import routePath from "const/routePath";
import storage from "util/storage";
import http from "util/http";
import CreateQuestion from "./createQuestion";
import SlideToast from "components/Toast/Toast";
import ConfirmationPopup from "./confirmationPopup";

export default function QuestionBuilder(props) {
  const campaignId = storage.get("cId");
  const templateType = storage.get("templateType");
  const campaignType = storage.get("campaignType") ? storage.get("campaignType") : null;
  const numberOfQuestions = storage.get("numberOfQuestions") ? +storage.get("numberOfQuestions") : null;
  const defaultTemplate = {
    templateName: "",
    questionTemplateId: "",
    questions: [
      {
        questionTitle: "Q1",
        questionName: "",
        type: "SELECT",
        isRequired: false,
        answer: "",
        isOther: false,
        otherText: "",
        optionalCommentBox: false,
        options: [
          {
            optionId: 1,
            option: "",
            isCorrect: false,
          },
        ],
        description: "",
        descriptionFlag: "",
        commentResponse: "Optional",
        questionCategory: null,
        overAllScore: false,
        respondToManager: templateType === "MANAGER_ASSESSMENT",
        respondToEmployee: templateType === "CAMPAIGN",
        respondToAll: false,
        notShowScaleToEmployee: false,
        showManagerResponse: false,
        showManagerScore: false,
      },
    ],
  };

  const [template, setTemplate] = useState(defaultTemplate);
  const [addNewFlag, setAddNewFlag] = useState(true);
  const [confirm, setConfirmPopup] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getTemplateCategory());
    dispatch(actions.getTemplateScale());
  }, []);

  useEffect(() => {
    if (props.location.state && props.location.state.questionTemplateId) {
      let config = campaignId && {
        params: {
          campaignId,
        },
      };
      http
        .get(DETAIL_TEMPLATE, { questionTemplateId: props.location.state.questionTemplateId }, config)
        .then((response) => response.data)
        .then((response) => {
          setTemplate(response.data);
          dispatch({
            type: actionTypes.SET_TEMPLATE,
            data: response.data,
          });
          setAddNewFlag(false);
          props.location.state = "";
        })
        .catch(console.error);
    }
  }, [props.location.state, dispatch]);

  function setTemplateData(data) {
    let temp = {
      ...template,
    };
    let newArr =
      temp.questions &&
      temp.questions.map((obj) => {
        if (data.type === "qname") {
          if (obj.questionTitle === data.questionTitle) {
            obj.questionName = data.questionName;
            obj.type = data.dropdown;
          }
        }
        if (data.dropdown === "CHECKBOX") {
          if (data.type === "options") {
            if (obj.questionTitle === data.questionTitle) {
              obj.options = null;
              obj.options = data.options;
              obj.type = data.dropdown;
            } else {
              if (data.options) {
                obj.options = [
                  { optionId: 1, option: "", isCorrect: false },
                  { optionId: 2, option: "", isCorrect: false },
                ];
              }
            }
          } else {
            if (data.options) {
              obj.options = [
                { optionId: 1, option: "", isCorrect: false },
                { optionId: 2, option: "", isCorrect: false },
              ];
            }
          }
          if (data.type === "otherOption") {
            if (obj.questionTitle === data.questionTitle) {
              obj.isOther = data.otherOption.isOther;
              obj.otherText = data.otherOption.otherText;
              obj.isRequired = data.otherOption.isRequired;
              obj.optionalCommentBox = data.otherOption.optionalCommentBox;
              obj.type = data.dropdown;
              obj.description = data.otherOption.description;
              obj.descriptionFlag = data.otherOption.descriptionFlag;
              obj.commentResponse = data.otherOption.commentResponse;
              obj.questionCategory = data.otherOption.questionCategory;
              obj.respondToManager = data.otherOption.respondToManager;
              obj.respondToEmployee = data.otherOption.respondToEmployee;
              obj.respondToAll = data.otherOption.respondToAll;
              // obj.overAllScore = data.otherOption.overAllScore
            }
          }
        }
        if (data.dropdown === "RADIOBUTTON") {
          if (data.type === "options") {
            if (obj.questionTitle === data.questionTitle) {
              obj.options = null;
              obj.options = data.options;
              obj.type = data.dropdown;
            } else {
              if (data.options) {
                obj.options = [
                  { optionId: 1, option: "", isCorrect: false },
                  { optionId: 2, option: "", isCorrect: false },
                ];
              }
            }
          } else {
            if (data.options) {
              obj.options = [
                { optionId: 1, option: "", isCorrect: false },
                { optionId: 2, option: "", isCorrect: false },
              ];
            }
          }
          if (data.type === "otherOption") {
            if (obj.questionTitle === data.questionTitle) {
              obj.isOther = data.otherOption.isOther;
              obj.otherText = data.otherOption.otherText;
              obj.isRequired = data.otherOption.isRequired;
              obj.optionalCommentBox = data.otherOption.optionalCommentBox;
              obj.type = data.dropdown;
              obj.description = data.otherOption.description;
              obj.descriptionFlag = data.otherOption.descriptionFlag;
              obj.commentResponse = data.otherOption.commentResponse;
              obj.questionCategory = data.otherOption.questionCategory;
              obj.respondToManager = data.otherOption.respondToManager;
              obj.respondToEmployee = data.otherOption.respondToEmployee;
              obj.respondToAll = data.otherOption.respondToAll;
              // obj.overAllScore = data.otherOption.overAllScore
            }
          }
        }
        if (data.dropdown === "TEXTBOX") {
          if (data.type === "options") {
            if (obj.questionTitle === data.questionTitle) {
              obj.options = null;
              obj.type = data.dropdown;
            } else {
              if (data.options) {
                obj.options = [{ optionId: 1, option: "", isCorrect: false }];
              }
            }
          } else {
            if (data.options) {
              obj.options = [{ optionId: 1, option: "", isCorrect: false }];
            }
          }
          if (data.type === "otherOption") {
            if (obj.questionTitle === data.questionTitle) {
              obj.isRequired = data.otherOption.isRequired;
              obj.description = data.otherOption.description;
              obj.descriptionFlag = data.otherOption.descriptionFlag;
              obj.questionCategory = data.otherOption.questionCategory;
              obj.respondToManager = data.otherOption.respondToManager;
              obj.respondToEmployee = data.otherOption.respondToEmployee;
              obj.respondToAll = data.otherOption.respondToAll;
              // obj.overAllScore = data.otherOption.overAllScore
              obj.type = data.dropdown;
            }
          }
        }
        if (data.dropdown === "DROPDOWN") {
          if (data.type === "options") {
            if (obj.questionTitle === data.questionTitle) {
              obj.options = null;
              obj.options = data.options;
              obj.type = data.dropdown;
            } else {
              if (data.options) {
                obj.options = [
                  { optionId: 1, option: "", isCorrect: false },
                  { optionId: 2, option: "", isCorrect: false },
                ];
              }
            }
          } else {
            if (data.options) {
              obj.options = [
                { optionId: 1, option: "", isCorrect: false },
                { optionId: 2, option: "", isCorrect: false },
              ];
            }
          }
          if (data.type === "otherOption") {
            if (obj.questionTitle === data.questionTitle) {
              obj.isOther = data.otherOption.isOther;
              obj.isRequired = data.otherOption.isRequired;
              obj.optionalCommentBox = data.otherOption.optionalCommentBox;
              obj.type = data.dropdown;
              obj.description = data.otherOption.description;
              obj.descriptionFlag = data.otherOption.descriptionFlag;
              obj.commentResponse = data.otherOption.commentResponse;
              obj.questionCategory = data.otherOption.questionCategory;
              obj.respondToManager = data.otherOption.respondToManager;
              obj.respondToEmployee = data.otherOption.respondToEmployee;
              obj.respondToAll = data.otherOption.respondToAll;
              // obj.overAllScore = data.otherOption.overAllScore
            }
          }
        }
        if (data.dropdown === "SLIDER") {
          if (data.type === "options") {
            if (obj.questionTitle === data.questionTitle) {
              obj.options = null;
              obj.options = data.options;
              obj.type = data.dropdown;
            } else {
              if (data.options) {
                obj.options = [
                  { optionId: 1, option: "", isCorrect: false },
                  { optionId: 2, option: "", isCorrect: false },
                ];
              }
            }
          } else {
            if (data.options) {
              obj.options = [
                { optionId: 1, option: "", isCorrect: false },
                { optionId: 2, option: "", isCorrect: false },
              ];
            }
          }
          if (data.type === "otherOption") {
            if (obj.questionTitle === data.questionTitle) {
              obj.isRequired = data.otherOption.isRequired;
              obj.optionalCommentBox = data.otherOption.optionalCommentBox;
              obj.type = data.dropdown;
              obj.description = data.otherOption.description;
              obj.descriptionFlag = data.otherOption.descriptionFlag;
              obj.commentResponse = data.otherOption.commentResponse;
              obj.questionCategory = data.otherOption.questionCategory;
              obj.respondToManager = data.otherOption.respondToManager;
              obj.respondToEmployee = data.otherOption.respondToEmployee;
              obj.respondToAll = data.otherOption.respondToAll;
              // obj.overAllScore = data.otherOption.overAllScore
            }
          }
        }
        if (data.dropdown === "SCALED") {
          if (data.type === "options") {
            if (obj.questionTitle === data.questionTitle) {
              obj.options = null;
              obj.type = data.dropdown;
            } else {
              if (data.options) {
                obj.options = [{ optionId: 1, option: "", isCorrect: false }];
              }
            }
          } else {
            if (data.options) {
              obj.options = [{ optionId: 1, option: "", isCorrect: false }];
            }
          }
          if (data.type === "otherOption") {
            if (obj.questionId === data.questionId) {
              // obj.isOther= data.otherOption.isOther
              // obj.otherText= data.otherOption.otherText
              obj.isRequired = data.otherOption.isRequired;
              obj.optionalCommentBox = data.otherOption.optionalCommentBox;
              obj.description = data.otherOption.description;
              obj.descriptionFlag = data.otherOption.descriptionFlag;
              obj.commentResponse = data.otherOption.commentResponse;
              obj.questionCategory = data.otherOption.questionCategory;
              obj.respondToManager = data.otherOption.respondToManager;
              obj.respondToEmployee = data.otherOption.respondToEmployee;
              obj.respondToAll = data.otherOption.respondToAll;
              obj.scaleObj = data.otherOption.scaleObj;
              obj.reverseScale = data.otherOption.reverseScale;
              obj.overAllScore = data.otherOption.overAllScore;
              obj.notShowScaleToEmployee = data.otherOption.notShowScaleToEmployee;
              obj.showManagerResponse = data.otherOption.showManagerResponse;
              obj.showManagerScore = data.otherOption.showManagerScore;
              obj.type = data.dropdown;
            }
          }
        }

        if (data.dropdown === "NPS") {
          if (data.type === "options") {
            if (obj.questionTitle === data.questionTitle) {
              obj.options = null;
              obj.type = data.dropdown;
            } else {
              if (data.options) {
                obj.options = [{ optionId: 1, option: "", isCorrect: false }];
              }
            }
          } else {
            if (data.options) {
              obj.options = [{ optionId: 1, option: "", isCorrect: false }];
            }
          }
          if (data.type === "otherOption") {
            if (obj.questionTitle === data.questionTitle) {
              obj.isRequired = data.otherOption.isRequired;
              obj.description = data.otherOption.description;
              obj.descriptionFlag = data.otherOption.descriptionFlag;
              obj.commentResponse = data.otherOption.commentResponse;
              obj.questionCategory = data.otherOption.questionCategory;
              obj.respondToManager = data.otherOption.respondToManager;
              obj.respondToEmployee = data.otherOption.respondToEmployee;
              obj.respondToAll = data.otherOption.respondToAll;
              obj.scaleValue = 5;
              // obj.overAllScore = data.otherOption.overAllScore
              obj.type = data.dropdown;
              obj.optionalCommentBox = data.otherOption.optionalCommentBox;
            }
          }
        }
        if (data.dropdown === "RANK") {
          if (data.type === "options") {
            if (obj.questionTitle === data.questionTitle) {
              obj.options = null;
              obj.options = data.options;
              obj.type = data.dropdown;
            } else {
              if (data.options) {
                obj.options = [
                  { optionId: 1, option: "", isCorrect: false },
                  { optionId: 2, option: "", isCorrect: false },
                ];
              }
            }
          } else {
            if (data.options) {
              obj.options = [
                { optionId: 1, option: "", isCorrect: false },
                { optionId: 2, option: "", isCorrect: false },
              ];
            }
          }
          if (data.type === "otherOption") {
            if (obj.questionTitle === data.questionTitle) {
              // obj.isOther= data.otherOption.isOther
              // obj.otherText= data.otherOption.otherText
              obj.isRequired = data.otherOption.isRequired;
              obj.optionalCommentBox = data.otherOption.optionalCommentBox;
              obj.type = data.dropdown;
              obj.description = data.otherOption.description;
              obj.descriptionFlag = data.otherOption.descriptionFlag;
              obj.commentResponse = data.otherOption.commentResponse;
              obj.questionCategory = data.otherOption.questionCategory;
              obj.respondToManager = data.otherOption.respondToManager;
              obj.respondToEmployee = data.otherOption.respondToEmployee;
              obj.respondToAll = data.otherOption.respondToAll;
              // obj.overAllScore = data.otherOption.overAllScore
            }
          }
        }
        if (data.type === "templateName") {
          temp.templateName = data.templateName;
        }

        let a = false;
        if (obj.questionName === "") {
          a = true;
        }
        if (temp.templateName === "") {
          a = true;
        }
        if (data.dropdown !== "TEXTBOX") {
          obj.options && obj.options.length === 0 && (a = true);
          obj.options &&
            obj.options.map((o) => {
              if (o.option === "" || obj.questionName === "" || temp.templateName === "") {
                a = true;
              }
              return o;
            });
        }
        a ? setAddNewFlag(true) : setAddNewFlag(false);
        return obj;
      });
    if (temp.templateName.trim() === "") {
      setAddNewFlag(true);
    }
    newArr && newArr[0].questionId ? setAddNewFlag(false) : setAddNewFlag(true);
    let _template = {
      ...template,
      templateName: temp.templateName,
      questions: newArr,
    };
    dispatch({
      type: actionTypes.SET_TEMPLATE,
      data: _template,
    });
    setTemplate(_template);
  }

  function removeQuestion(question) {
    let params = { questionTemplateId: template.questionTemplateId, questionId: question.questionId };
    http
      .delete(DELETE_TEMPLATE_QUESTION, null, null, params)
      .then((response) => response.data)
      .then(() => {
        toast.success(<SlideToast title="Awesome!" message={"Question deleted successfully"} error={false} />, {
          toastId: "QUESTION_DELETED",
        });
        getTemplate();
      })
      .catch(() => {
        getTemplate();
      });
  }

  function getTemplate() {
    let config = campaignId && {
      params: { campaignId },
    };
    http
      .get(DETAIL_TEMPLATE, { questionTemplateId: template.questionTemplateId }, config)
      .then((response) => response.data)
      .then((response) => {
        setTemplate(response.data);
        setAddNewFlag(false);
        dispatch({
          type: actionTypes.SET_TEMPLATE,
          data: response.data,
        });
      })
      .catch(console.error);
  }

  function saveQuestion(question) {
    let data = {
      questionTemplateId: template.questionTemplateId,
      questions: question,
      questionId: question.questionId ? question.questionId : "",
      category: campaignId ? "CAMPAIGN" : templateType,
    };
    let params = campaignId && {
      campaignId,
    };
    //For backend validation we passed campaignID
    http
      .post(TEMPLATE, {}, data, { params })
      .then((response) => response.data)
      .then(() => {
        getTemplate();
        toast.success(<SlideToast title="Awesome!" message={"Question saved successfully"} error={false} />, {
          toastId: "QUESTION_SAVE",
        });
      })
      .catch(console.error);
  }

  function getTemplateId(templateName) {
    if (templateName.trim() === "") {
      return false;
    }

    let data;
    if (campaignType === "Quiz") {
      data = {
        templateName: templateName,
        questionTemplateId: template.questionTemplateId ? template.questionTemplateId : null,
        campaignType,
        numberOfQuestions,
      };
    } else if (campaignType === "Survey") {
      data = {
        templateName: templateName,
        questionTemplateId: template.questionTemplateId ? template.questionTemplateId : null,
        campaignType,
      };
    } else {
      data = {
        templateName: templateName,
        questionTemplateId: template.questionTemplateId ? template.questionTemplateId : null,
      };
    }
    let params = campaignId && {
      campaignId,
    };

    //For backend validation we passed campaignID
    http
      .post(TEMPLATE, {}, data, { params })
      .then((response) => response.data)
      .then((response) => {
        setTemplate({ ...template, questionTemplateId: response.data });
        dispatch({
          type: actionTypes.SET_TEMPLATE,
          data: { ...template, questionTemplateId: response.data },
        });
      })
      .catch(console.error);
  }

  const getQuestionTemplateLength = () => {
    let questionTemplateLengthValue = 0;
    template.questions.forEach((item) => item.questionId && questionTemplateLengthValue++);
    storage.set("questionTemplateLength", questionTemplateLengthValue);
  };

  return (
    <div className={style.quesion_builder_wrapper}>
      <div className="gap30"></div>
      <Container>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Question Builder</h1>
            <span className="st-breadcrumb">Question Builder</span>
          </Col>
          <Col className={["col-md-12"]}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>{campaignType && campaignType} Question Builder</h2>
                </div>
              </div>
              <div className="box-inner-pad">
                <Form.Group controlId="">
                  <Form.Label>Template Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Template Name"
                    value={template.templateName}
                    onBlur={(e) => getTemplateId(e.target.value)}
                    onChange={(e) => {
                      setTemplateData({ templateName: e.target.value, type: "templateName" });
                      setTemplate({ ...template, templateName: e.target.value });
                    }}
                  ></Form.Control>
                </Form.Group>
                {/* <div className={style.filter_box}>
                  <span className={style.dd_title}>Template Category</span>
                  <div className="tags_wrapper">
                    <Select
                      options={categoryOptions}
                      className="custom-default-dd-box"
                      classNamePrefix="select"
                      placeholder="Select..."
                      value={category}
                      onChange={(list) => setCategory(list)}
                    />
                  </div>
                </div>*/}
              </div>
              <Form.Group className={style.custom_labels} controlId="">
                <Form.Label>Question</Form.Label>
              </Form.Group>
              <div className={style.quesion_inner_wrapper}>
                {template.questions
                  ? template.questions.map((obj, i) => (
                      <CreateQuestion
                        respondOptionVisible={templateType === "SELF_ASSESSMENT"}
                        key={i}
                        no={i + 1}
                        saveQuestion={saveQuestion}
                        setTemplate={setTemplate}
                        questionDetail={obj}
                        setTemplateData={setTemplateData}
                        removeQuestion={removeQuestion}
                        template={template}
                      />
                    ))
                  : defaultTemplate.questions.map((obj, i) => (
                      <CreateQuestion
                        respondOptionVisible={templateType === "SELF_ASSESSMENT"}
                        key={i}
                        no={i + 1}
                        saveQuestion={saveQuestion}
                        setTemplate={setTemplate}
                        questionDetail={obj}
                        setTemplateData={setTemplateData}
                        removeQuestion={removeQuestion}
                        template={template}
                      />
                    ))}
                <span
                  className={[style.add_new_question, "text_link", addNewFlag ? "disabled" : ""].join(" ")}
                  onClick={() => {
                    let arr = [...template.questions];
                    let obj = {
                      ...defaultTemplate.questions[0],
                      questionTitle: `Q${template.questions.length + 1}`,
                    };
                    arr.push(obj);
                    let _template = {
                      ...template,
                      questions: arr,
                    };
                    setTemplate(_template);
                  }}
                >
                  Add New Question
                </span>
                <div className={style.save_btn}>
                  <Button
                    variant="primary"
                    disabled={addNewFlag}
                    onClick={() => {
                      setConfirmPopup(true);
                    }}
                  >
                    Preview Template
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <ConfirmationPopup
          show={confirm}
          submit={() => {
            history.push({
              pathname: routePath.PREVIEW_TEMPLATE_ROUTE,
              state: { template: template, hideButton: true },
            });
            setConfirmPopup(false);
            getQuestionTemplateLength();
          }}
          hide={() => setConfirmPopup(false)}
        />
      </Container>
    </div>
  );
}
