import React from "react";
import { Form } from "react-bootstrap";
import style from "./RadioButton.module.scss";

function RadioButtons({ options, ulClass, name, isDisabled, questionId, questionName, defaultChecked, handleRadio }) {
  return (
    <div className="radio-wrapper">
      <Form.Group controlId="">
        <ul className={[style.form_option_list, ulClass].join(" ")}>
          {name && <li className="font-weight-bold w-25">{name} : </li>}
          {options?.length > 0 &&
            options.map((optionsData) => (
              <li key={"RADIO" + optionsData.optionId + name}>
                <div className="custom-checkbox custom-checkbox-rounded">
                  <input
                    disabled={isDisabled}
                    id={`${questionId}_${optionsData?.optionId}_${name}`}
                    type="radio"
                    name={questionName + "_" + name}
                    defaultChecked={defaultChecked === optionsData.optionId}
                    // checked={optionsData.isSelected}
                    onChange={() => {
                      handleRadio(optionsData.optionId);
                    }}
                  />
                  <label htmlFor={`${questionId}_${optionsData?.optionId}_${name}`}>{optionsData.option}</label>
                </div>
              </li>
            ))}
        </ul>
      </Form.Group>
    </div>
  );
}

export default RadioButtons;

RadioButtons.defaultProps = {
  options: [],
  ulClass: "",
  name: null,
  isDisabled: false,
  questionId: "",
  questionName: "",
  defaultChecked: "",
  handleRadio: () => {},
};
