import React from "react";
import { Row } from "react-bootstrap";
import { toFixedNumber } from "../../../../util/utils";
import AccordionRow from "./AccordionRow";
import SelfAssessmentProgress from "./SelfAssessmentProgress";
import style from "./SelfAssessmentRating.module.scss";

export default function Assessment({
  saveQuestionAnswer,
  questionTemplateErrors,
  selfAssessmentManagerViseGrade,
  overAllAggregateText,
  selfAssessmentAvgGrade,
  questionTemplates,
  uploadFile,
  downloadFile,
  deleteFile,
  sendManagerComment,
  sendRating,
  isSubmitted,
  isRatingDisable,
  isPage,
  ratingErrors,
  ratingCommentErrorsInSelf,
  selfAssessmentAvgRating,
  fileUploadByManager,
}) {
  let aggregateRpmAssessmentRating;
  if (isPage === "HR") {
    if (questionTemplates?.find((v) => v.rating && v.type === "REPORTING")) {
      let totalRating = 0;
      questionTemplates.forEach((value) => {
        if (value.type === "REPORTING" && value.rating) {
          totalRating = totalRating + parseFloat(value.rating);
        }
      });
      aggregateRpmAssessmentRating =
        totalRating / questionTemplates?.filter((v) => v.type === "REPORTING" && v.rating)?.length;
    }
  }
  const checkSubmitted = (value) => {
    if (value.name === "Self Assessment" || isPage === "HR") return true;
    else if (value.type === "REPORTING" && isPage === "REVIEWING") return true;
    else return isSubmitted;
  };

  return (
    <div className={style.keyresult_accordion_wrapper}>
      <div className="box pb-0">
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Assessment(s)</h2>
            {(isPage === "HR" || isPage === "USER") && (
              <div className="self-private-rating">
                <span>
                  {selfAssessmentAvgRating ? "Performance Assessment Progress: " + selfAssessmentAvgRating : ""}
                </span>
                <span>
                  {aggregateRpmAssessmentRating
                    ? "Private Assessment Rating: " + toFixedNumber(aggregateRpmAssessmentRating, 2)
                    : ""}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="box-light-gradient"></div>
        <div className="box-inner-pad">
          <Row>
            <div className="col-xl-12">
              {selfAssessmentAvgGrade !== 0 && selfAssessmentManagerViseGrade?.length > 0 && (
                <SelfAssessmentProgress
                  overAllAggregateText={overAllAggregateText}
                  selfAssessmentAvgGrade={selfAssessmentAvgGrade}
                  selfAssessmentManagerViseGrade={selfAssessmentManagerViseGrade}
                />
              )}

              {questionTemplates &&
                questionTemplates.map((value, index) => {
                  return (
                    <AccordionRow
                      selfAssessmentManagerViseGrade={selfAssessmentManagerViseGrade}
                      overAllAggregateText={overAllAggregateText}
                      selfAssessmentAvgGrade={selfAssessmentAvgGrade}
                      ratingErrors={ratingErrors}
                      ratingCommentErrorsInSelf={ratingCommentErrorsInSelf}
                      isPage={isPage}
                      questionTemplates={questionTemplates}
                      questionTemplateErrors={questionTemplateErrors}
                      saveQuestionAnswer={(data) =>
                        saveQuestionAnswer(data, value.type, value.privateAssessmentId, value.questionTemplateId)
                      }
                      sendManagerComment={sendManagerComment}
                      sendRating={sendRating}
                      isRatingDisable={isRatingDisable}
                      index={index}
                      isManagerSubmitted={isPage === "HR" || isPage === "USER" ? true : isSubmitted}
                      isSubmitted={checkSubmitted(value)}
                      uploadFile={uploadFile}
                      downloadFile={(fileId, queId) =>
                        downloadFile(fileId, queId, value.questionTemplateId, value.privateAssessmentId, value.type)
                      }
                      deleteFile={(fileId, queId) =>
                        deleteFile(fileId, queId, value.questionTemplateId, value.privateAssessmentId, value.type)
                      }
                      template={value}
                      fileUploadByManager={(e, queId) =>
                        fileUploadByManager(e, queId, value.questionTemplateId, value.privateAssessmentId, value.type)
                      }
                      key={value.questionTemplateId + value.name}
                    />
                  );
                })}
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
