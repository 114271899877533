import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Accordion, Button } from "react-bootstrap";
import { IC_ACC_ARROW_DOWN } from "../../const/imgCost";
import style from "./UniversityKeyResult.module.scss";
import { ReactSVG } from "react-svg";

function UniversityKeyResultRow({ keypoint, index }) {
  const [eventValue, setEventValue] = useState(0);
  let details = keypoint.details && keypoint.details.split("/n");
  let subPoints = details
    ? details.map((obj, i) => {
        let text = "";
        let href = "";
        if (obj.indexOf("URL") !== -1) {
          if (obj.indexOf("URL>[") !== -1) {
            href = obj.split(">[")[1].split("]<")[0];
          }
          if (obj.indexOf("URL> [") !== -1 && obj.indexOf("] <URL") !== -1) {
            href = obj.split("> [")[1].split("] <")[0];
          }
          if (obj.indexOf("URL> [") !== -1) {
            href = obj.split("> [")[1].split("]<")[0];
          }
          if (obj.indexOf("] <URL") !== -1) {
            href = obj.split(">[")[1].split("] <")[0];
          }
          text = obj
            .split("<URL>")
            .join("<a target='_blank' href='" + href + "'>")
            .split("</URL>")
            .join("</a>")
            .trim();
        } else {
          text = obj;
        }
        return (
          <li
            key={i}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        );
      })
    : null;
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={`${index}`}
          onClick={(e) => {
            setEventValue(index);
            if (e.currentTarget.classList.contains("show")) {
              e.currentTarget.classList.remove("show");
            } else {
              e.currentTarget.classList.add("show");
            }
          }}
          className={eventValue === index && "show"}
        >
          <div className="accordion-icon">
            <ReactSVG src={IC_ACC_ARROW_DOWN} />
          </div>
          <div className={style.accordion_title}>
            <div className={style.title_left}>{keypoint.description}</div>
            <div className={[style.title_right, "ml-4"].join(" ")}>
              Progress : <span>{keypoint.progress}</span>
            </div>
            <div className={style.title_right}>{keypoint.hours} h</div>
          </div>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={`${index}`}>
        <Card.Body>
          <div className="box-light-gradient"></div>
          <ul>{subPoints}</ul>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

UniversityKeyResultRow.propTypes = {
  keypoint: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
};

export default UniversityKeyResultRow;
