import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import style from "../QuestionBuilder.module.scss";
import { useSelector } from "react-redux";
import Select from "react-select";
import AddCategoryModal from "./AddCategoryModal/AddCategoryModal";

export default function QuestionTextbox(props) {
  const { questionTemplate, templateCategoryList } = useSelector((state) => state.performance);
  const [saveFlag, setSaveFlag] = useState(false);
  const [disabledDelete, setDisabledDelete] = useState(true);
  const [otherOption, setOtherOption] = useState({
    isRequired: props.questionDetail.isRequired,
    description: props.questionDetail.description,
    descriptionFlag: props.questionDetail.descriptionFlag,
    // "overAllScore": props.questionDetail.overAllScore,
    questionCategory: props.questionDetail.questionCategory,
    respondToManager: props.questionDetail.respondToManager,
    respondToEmployee: props.questionDetail.respondToEmployee,
    respondToAll: props.questionDetail.respondToAll,
  });
  const getCategoryList =
    templateCategoryList &&
    templateCategoryList.map((obj) => {
      return {
        value: obj.name,
        label: obj.name,
        categoryId: obj.categoryId,
      };
    });
  /*for category modal */
  const [isModalCategory, setIsModalCategory] = useState(false);
  const hideCategoryModal = () => {
    setIsModalCategory(!isModalCategory);
  };

  useEffect(() => {
    let flag = false;
    if (props.questionDetail.questionName === "") {
      flag = true;
    }
    if (questionTemplate.templateName === "") {
      flag = true;
    }
    if (
      props.respondOptionVisible &&
      !(otherOption.respondToAll || otherOption.respondToManager || otherOption.respondToEmployee)
    ) {
      flag = true;
    }
    if (props.questionDetail.descriptionFlag) {
      if (props.questionDetail.description === "") {
        flag = true;
      }
    }
    flag ? setSaveFlag(true) : setSaveFlag(false);
    if (questionTemplate.questions.length === 1) {
      setDisabledDelete(true);
    } else {
      if (questionTemplate.questions[1].questionId === undefined) {
        setDisabledDelete(true);
      } else {
        setDisabledDelete(false);
      }
    }
  }, [questionTemplate]);
  return (
    <div className={style.answer_l_wrapper}>
      <div className={style.textbox_wrapper}>
        <Form.Group className={style.answer_textbox} controlId="">
          <Form.Control as="textarea" placeholder="Answer Name"></Form.Control>
        </Form.Group>
      </div>
      <div className={style.quesion_category_wrapper}>
        <div className={style.category_title_wrapper}>
          <span className={style.dd_title}>Category</span>
          <span className={style.modal_text} onClick={() => hideCategoryModal()}>
            Add New Category
          </span>
        </div>
        <div className="card_action">
          <Select
            options={getCategoryList}
            className="custom-default-dd-box"
            classNamePrefix="select"
            isSearchable={true}
            isClearable={true}
            placeholder="Select the Category"
            value={
              otherOption.questionCategory === null || otherOption.questionCategory === ""
                ? null
                : {
                    value: otherOption.questionCategory.name,
                    label: otherOption.questionCategory.name,
                    name: otherOption.questionCategory.name,
                  }
            }
            onChange={(e) => {
              setOtherOption({
                ...otherOption,
                questionCategory:
                  e === null
                    ? null
                    : {
                        value: e.value,
                        label: e.value,
                        name: e.value,
                      },
              });
              let oP = {
                ...otherOption,
                questionCategory:
                  e === null
                    ? null
                    : {
                        value: e.value,
                        label: e.value,
                        name: e.value,
                      },
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                otherOption: oP,
                dropdown: "TEXTBOX",
              };
              props.setTemplateData(data);
            }}
          />
        </div>
      </div>
      <div className={style.other_field_wrapper}>
        <div className="custom-checkbox no_mar">
          <input
            id={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 3142
                : props.questionDetail.questionId + "_checkbox" + 3142
            }
            type="checkbox"
            checked={otherOption.descriptionFlag}
            onChange={(e) => {
              setOtherOption({
                ...otherOption,
                descriptionFlag: e.target.checked,
                description: e.target.checked ? otherOption.description : "",
              });
              let oP = {
                ...otherOption,
                descriptionFlag: e.target.checked,
                description: e.target.checked ? otherOption.description : "",
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                otherOption: oP,
                dropdown: "TEXTBOX",
              };
              props.setTemplateData(data);
            }}
          />
          <label
            htmlFor={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 3142
                : props.questionDetail.questionId + "_checkbox" + 3142
            }
          >
            Add help text/description to the question
          </label>
        </div>
        {otherOption.descriptionFlag && (
          <Form.Group className={style.other_text_box} controlId="">
            <div className="textarea-wrapper">
              <Form.Control
                as="textarea"
                maxLength="1000"
                placeholder="Add a comment"
                value={otherOption.description}
                className={otherOption.descriptionFlag ? (otherOption.description === "" ? "error" : "") : ""}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, description: e.target.value });
                  let oP = {
                    ...otherOption,
                    description: e.target.value,
                  };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "TEXTBOX",
                  };
                  props.setTemplateData(data);
                }}
              ></Form.Control>
            </div>
          </Form.Group>
        )}
      </div>

      <div className={style.mandtory_field_wrapper}>
        <div className="custom-checkbox no_mar">
          <input
            id={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 41
                : props.questionDetail.questionId + "_checkbox" + 41
            }
            type="checkbox"
            checked={otherOption.isRequired}
            onChange={(e) => {
              setOtherOption({ ...otherOption, isRequired: e.target.checked });
              let oP = {
                ...otherOption,
                isRequired: e.target.checked,
              };
              let data = {
                type: "otherOption",
                questionTitle: props.questionDetail.questionTitle,
                otherOption: oP,
                dropdown: "TEXTBOX",
              };
              props.setTemplateData(data);
            }}
          />
          <label
            htmlFor={
              props.questionDetail.questionTitle
                ? props.questionDetail.questionTitle + "_checkbox" + 41
                : props.questionDetail.questionId + "_checkbox" + 41
            }
          >
            Mark the field as required(<span className="text-danger">*</span>)
          </label>
        </div>
      </div>
      {props.respondOptionVisible && (
        <div className={style.multi_cb_wrapper}>
          <h5>
            Who do you want to respond to this Question?<span className="text-danger">*</span>
          </h5>
          <div className={style.cb_box_wrapper}>
            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_manager"
                    : props.questionDetail.questionId + "_checkbox_manager"
                }
                type="checkbox"
                name="respond-question"
                value="Managers"
                checked={otherOption.respondToManager}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, respondToManager: e.target.checked });
                  let oP = { ...otherOption, respondToManager: e.target.checked };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "TEXTBOX",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_manager"
                    : props.questionDetail.questionId + "_checkbox_manager"
                }
              >
                Managers
              </label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_employee"
                    : props.questionDetail.questionId + "_checkbox_employee"
                }
                type="checkbox"
                name="respond-question"
                value="Employee"
                checked={otherOption.respondToEmployee}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, respondToEmployee: e.target.checked });
                  let oP = { ...otherOption, respondToEmployee: e.target.checked };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "TEXTBOX",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_employee"
                    : props.questionDetail.questionId + "_checkbox_employee"
                }
              >
                Employee
              </label>
            </div>

            <div className="custom-checkbox custom-checkbox-sm">
              <input
                id={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_all"
                    : props.questionDetail.questionId + "_checkbox_all"
                }
                type="checkbox"
                name="respond-question"
                value="All"
                checked={otherOption.respondToAll}
                onChange={(e) => {
                  setOtherOption({ ...otherOption, respondToAll: e.target.checked });
                  let oP = { ...otherOption, respondToAll: e.target.checked };
                  let data = {
                    type: "otherOption",
                    questionTitle: props.questionDetail.questionTitle,
                    otherOption: oP,
                    dropdown: "TEXTBOX",
                  };
                  props.setTemplateData(data);
                }}
              />
              <label
                htmlFor={
                  props.questionDetail.questionTitle
                    ? props.questionDetail.questionTitle + "_checkbox_all"
                    : props.questionDetail.questionId + "_checkbox_all"
                }
              >
                Both
              </label>
            </div>
          </div>
        </div>
      )}
      <div className={style.que_btn_group}>
        <Button
          variant="primary"
          disabled={saveFlag}
          onClick={() => {
            props.saveQuestion(props.questionDetail);
          }}
        >
          Save
        </Button>
        <Button
          variant="secondary"
          disabled={disabledDelete}
          onClick={() => {
            props.removeQuestion(props.questionDetail);
          }}
        >
          Delete
        </Button>
      </div>
      <AddCategoryModal show={isModalCategory} hide={hideCategoryModal} />
    </div>
  );
}
