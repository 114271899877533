import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import { SEARCH_NAME, APPRECIATE, GET_APPRECIATE_COUNT } from "../../util/endpoints";

export const SearchEmployee = (query, callback, empId, parentGroupId) => {
  if (query.length > 0) {
    http
      .get(SEARCH_NAME, { employeeName: query, parentGroupId })
      .then((response) => response.data.data)
      .then(
        (response) => {
          if (response) {
            callback(response.filter((item) => !(item.employeeId === empId)));
          }
        },
        () => {}
      );
  }
};

export const AppreciateHistory = (data) => {
  let params = {
    employeeId: data.employeeId,
    subGroupId: data.subGroupId,
    parentGroupId: data.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(APPRECIATE, {}, { params: params })
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.SET_APPRECIATE_HISTORY,
            appreciateHistory: response,
          });
        }
      })
      .catch((error) => {
        if (!error.message) {
          // handle error message
        }
      });
  };
};

export const GetAppreciateDurationCount = (empIds, value) => {
  let params = {
    ...empIds,
    filterByMonth: value,
  };
  return (dispatch) => {
    http
      .get(GET_APPRECIATE_COUNT, {}, { params })
      .then((response) => response.data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.SET_APPRECIATE_COUNT, //set appreciate count
            appreciateCount: response,
          });
        }
      })
      .catch((error) => {
        if (!error.message) {
          // handle error message
        }
      });
  };
};

export const ViewAllHistory = (data) => (dispatch) => {
  let params = {
    employeeId: data.employeeId,
    subGroupId: data.subGroupId,
    parentGroupId: data.parentGroupId,
    viewAll: data.viewAll,
    filterByMonth: data.filterByMonth,
  };
  http
    .get(APPRECIATE, {}, { params })
    .then((response) => response.data.data)
    .then((response) => {
      if (response) {
        dispatch({
          type: actionTypes.SET_ALL_APPRECIATE_HISTORY,
          allAppreciateHistory: response,
        });
      }
    })
    .catch(() => {});
};

export const Appreciate = (data) => {
  return (dispatch) => {
    http
      .post(APPRECIATE, {}, data)
      .then((response) => response.data)
      .then((response) =>
        dispatch({
          type: actionTypes.SET_APPRECIATE_RESPONSE,
          appreciateResponse: response,
        })
      )
      .catch(() => {});
  };
};
