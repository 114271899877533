import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Button, Col, Row, Container } from "react-bootstrap";
import { merge } from "lodash";
import Select from "react-select";
import moment from "moment";
import style from "./AddDefineRollBasedSkill.module.scss";
import * as actions from "store/actions/index";
import { IC_SEARCH_RESET } from "const/imgCost";
import Spinner from "shared/Spinner/Spinner";
import Autocomplete from "shared/AutoCompleateTextBox/AutoCompleateInput";
import DeleteSkillCategoryModal from "./DeleteSkillCategoryModal/DeleteSkillCategoryModal";
import AddSkillMasterModal from "./AddSkillMasterModal/AddSkillMasterModal";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { competency_level_options } from "const/options";
import { errorToast, successToast } from "util/general";
import routePath from "const/routePath";

export default function AddDefineRollBasedSkill(props) {
  const [isModal, setIsModal] = useState(false);
  const [catIndex, setIndex] = useState(null);
  const [skillList, setSkills] = useState([]);
  const [isModalSkill, setIsModalSkill] = useState(false);
  const [addCategory, setAddCategory] = useState({
    departmentId: "",
    departmentName: "",
    roleId: "",
    roleName: "",
    technicalRoleId: "",
    technicalRoleName: "",
    category: [
      {
        categoryId: "",
        categoryName: "",
        requiredSkill: {
          skillset: [
            {
              skill: [
                {
                  skillIdName: "",
                  skillIdNameId: "",
                  competencyLevel: "",
                  competencyLevelName: "",
                },
              ],
              shouldKnowAtleast: 0,
            },
          ],
        },
        niceToHaveSkill: {
          skill: [],
        },
      },
    ],
    draftedDate: null,
  });
  const [getDepartmentList, setDepartmentList] = useState([]);
  const [getCategoryList, setCategoryList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [technicalRoleList, setTechnicalRole] = useState([]);
  const dispatch = useDispatch();
  const [disableDropdown, setDisableDropdown] = useState(true);
  const [disable, setDisable] = useState(true);
  const {
    departmentList,
    categoryList,
    roleByDepartment,
    technicalRole,
    skills,
    saveRequiredSkillResponse,
    saveRequiredSkillErrorResponse,
    isLoading,
    getRequiredSkillRecord,
    getDefineSkillDraft,
  } = useSelector((state) => state.skillMatrix);
  const [shouldHaveSkill, seTshouldHaveSkill] = useState([]);
  const history = useHistory();

  //for modal closing and opening
  const hide = () => {
    setIsModal(!isModal);
  };
  const hideModal = () => {
    setIsModalSkill(!isModalSkill);
  };

  useEffect(() => {
    if (saveRequiredSkillResponse && !saveRequiredSkillResponse.error) {
      successToast(saveRequiredSkillResponse.message);
      history.push(routePath.MMG_DEFINE_SKILLS, { queryStr: props.location.state?.queryStr });
      addCategory.departmentName = "";
      addCategory.technicalRoleName = "";
      addCategory.departmentId = "";
      addCategory.technicalRoleId = "";
      addCategory.roleId = "";
      addCategory.roleName = "";
      addCategory.draftedDate = null;
      addCategory.category.forEach((cat) => {
        cat.categoryName = "";
        cat.requiredSkill.skillset.forEach((skillset) => {
          skillset.skill.forEach((skill) => {
            skill.skillIdName = "";
            skill.skillIdNameId = "";
            skill.competencyLevel = "";
            skill.competencyLevelName = "";
          });
          if (skillset.shouldKnowAtleast) {
            skillset.shouldKnowAtleast = 0;
          }
        });
        if (cat.niceToHaveSkill.skill.length > 0) {
          cat.niceToHaveSkill.skill.forEach((nth) => {
            nth.skillIdName = "";
            nth.skillIdNameId = "";
            nth.competencyLevel = "";
            nth.competencyLevelName = "";
          });
        }
      });
    }

    if (saveRequiredSkillErrorResponse && saveRequiredSkillErrorResponse.error) {
      errorToast(saveRequiredSkillErrorResponse.message);
    }
  }, [
    saveRequiredSkillResponse,
    saveRequiredSkillErrorResponse,
    addCategory.departmentName,
    addCategory.technicalRoleName,
    addCategory.departmentId,
    addCategory.technicalRoleId,
    addCategory.roleId,
    addCategory.roleName,
    addCategory.category,
    props.match.params,
    history,
    addCategory.draftedDate,
    props,
  ]);

  useEffect(() => {
    if (props.match.params && props.match.params.id) {
      const getRecord = () => dispatch(actions.getRequiredSkillDetail(props.match.params.id));
      getRecord();
    } else {
      const getDraftForDefineSkill = () => dispatch(actions.getDraftForDefineSkill(true));
      getDraftForDefineSkill();
    }

    const getDepartmentList = () => dispatch(actions.getDepartmentList);
    const getCategoryList = () => dispatch(actions.getCategoryList);

    getDepartmentList();
    getCategoryList();
  }, [dispatch, props]);

  useEffect(() => {
    const array = [];
    if (props.match.params && props.match.params.id && getRequiredSkillRecord) {
      if (getRequiredSkillRecord && getRequiredSkillRecord.roleId) {
        dispatch(actions.getRoleByDepartment(getRequiredSkillRecord.departmentId));
        setDisableDropdown(false);
      }
      getRequiredSkillRecord.category.map((cat) => {
        cat.requiredSkill.skillset.map((skillset) => {
          if (skillset.skill.length > 2) {
            for (let i = 1; i <= skillset.skill.length; i++) {
              const obj = {
                value: i,
                label: i,
              };
              array.push(obj);
            }
          }
          return skillset;
        });
        return cat;
      });
      seTshouldHaveSkill(array);
      setAddCategory(getRequiredSkillRecord);
    }
  }, [props.match.params, getRequiredSkillRecord, dispatch]);

  useEffect(() => {
    let requiredSkillValue = true;
    let nthValue = true;
    let shouldKnowSkill = true;
    let skillMissing = false;
    let nthSkillMissing = false;
    let shouldHaveSkillMissing = false;

    addCategory.category.forEach((cat) => {
      cat.requiredSkill.skillset.forEach((skillset) => {
        shouldKnowSkill = true;
        if (skillset.skill.length > 2) {
          if (skillset.shouldKnowAtleast !== 0) {
            shouldKnowSkill = false;
          }
          if (shouldKnowSkill) {
            shouldHaveSkillMissing = true;
          }
        } else {
          shouldKnowSkill = false;
        }
        skillset.skill.forEach((skill) => {
          requiredSkillValue = true;
          if (
            addCategory.departmentName !== "" &&
            addCategory.technicalRoleName.trim().length > 0 &&
            skill.skillIdName !== "" &&
            skill.skillIdNameId !== "" &&
            skill.competencyLevel !== "" &&
            skill.competencyLevelName !== "" &&
            cat.categoryName.trim().length > 0
          ) {
            requiredSkillValue = false;
          }
          if (requiredSkillValue) {
            skillMissing = true;
          }
        });
      });
      if (cat.niceToHaveSkill.skill.length > 0) {
        cat.niceToHaveSkill.skill.forEach((nth) => {
          nthValue = true;
          if (
            nth.skillIdName !== "" &&
            nth.skillIdNameId !== "" &&
            nth.competencyLevel !== "" &&
            nth.competencyLevelName !== ""
          ) {
            nthValue = false;
          }
          if (nthValue) {
            nthSkillMissing = true;
          }
        });
      } else {
        nthValue = false;
      }
    });

    if (skillMissing || nthSkillMissing || shouldHaveSkillMissing) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [addCategory.departmentName, addCategory.technicalRoleName, addCategory.category, addCategory, shouldHaveSkill]);

  useEffect(() => {
    if (skills.length > 0) {
      const skillArray = [];
      let masterSkillArr = [];
      masterSkillArr = skills;
      masterSkillArr = masterSkillArr.filter((sk) => sk.isActive);
      masterSkillArr.map((skill) => {
        const obj = {
          value: skill.skillId,
          label: skill.skillName,
        };
        skillArray.push(obj);
        return skill;
      });
      setSkills(skillArray);
    } else {
      setSkills([]);
    }
  }, [skills]);

  useEffect(() => {
    const getSkills = () => dispatch(actions.getSkills);
    getSkills();
  }, [dispatch]);

  useEffect(() => {
    if (departmentList && departmentList.length > 0) {
      const departments = [];
      departmentList.forEach((dept) => {
        const obj = {
          value: dept.departmentId,
          label: dept.departmentName,
        };
        departments.push(obj);
      });
      setDepartmentList(departments);
    } else {
      setDepartmentList([]);
    }

    if (categoryList && categoryList.length > 0) {
      const category = [];
      categoryList.forEach((cat) => {
        const obj = {
          value: cat.categoryId,
          label: cat.skillCategoryName,
        };
        category.push(obj);
      });
      setCategoryList(category);
    } else {
      setCategoryList([]);
    }

    if (technicalRole && technicalRole.length > 0) {
      const techRole = [];
      technicalRole.forEach((tec) => {
        const obj = {
          value: tec.technicalRoleId,
          label: tec.technicalRoleName,
        };
        techRole.push(obj);
      });
      setTechnicalRole(techRole);
    } else {
      setTechnicalRole([]);
    }
  }, [categoryList, departmentList, technicalRole]);

  useEffect(() => {
    if (roleByDepartment && roleByDepartment.length > 0) {
      const role = [];
      setDisableDropdown(false);
      roleByDepartment.forEach((cat) => {
        const obj = {
          value: cat.roleId,
          label: cat.roleName,
        };
        role.push(obj);
      });
      setRoleList(role);
    } else {
      setRoleList([]);
    }
  }, [roleByDepartment, technicalRoleList]);

  useEffect(() => {
    if (addCategory.departmentId && addCategory.roleId) {
      dispatch(actions.getTechnicalRole({ departmentId: addCategory.departmentId, roleId: addCategory.roleId }));
    }

    if (addCategory.departmentId && !addCategory.roleId) {
      dispatch(actions.getTechnicalRole({ departmentId: addCategory.departmentId, roleId: null }));
    }
  }, [addCategory.departmentId, addCategory.roleId, dispatch]);

  useEffect(() => {
    let shouldKnowSkillArray = [];
    if (!(props.match.params && props.match.params.id) && getDefineSkillDraft) {
      const obj = addCategory;
      setAddCategory(merge(obj, getDefineSkillDraft));
      getDefineSkillDraft.category.map((cat) => {
        cat.requiredSkill.skillset.map((skillset) => {
          shouldKnowSkillArray = [];
          if (skillset.skill.length > 2) {
            for (let i = 1; i <= skillset.skill.length; i++) {
              const fillObj = {
                value: i,
                label: i,
              };
              shouldKnowSkillArray.push(fillObj);
            }
          }
          return skillset;
        });
        return cat;
      });
      seTshouldHaveSkill(shouldKnowSkillArray);
    }
    if (!(props.match.params && props.match.params.id) && getDefineSkillDraft && getDefineSkillDraft.departmentId) {
      dispatch(actions.getRoleByDepartment(getDefineSkillDraft.departmentId));
      setDisableDropdown(false);
    }
  }, [dispatch, getDefineSkillDraft, props.match.params]);

  const deleteSkill = (index, skillsetIndex, skillIndex) => {
    const arr = addCategory;
    arr.category[index].requiredSkill.skillset[skillsetIndex].skill.splice(skillIndex, 1);

    arr.category[index].requiredSkill.skillset[skillsetIndex].shouldKnowAtleast = 0;

    if (arr.category[index].requiredSkill.skillset[skillsetIndex].skill.length === 0) {
      arr.category[index].requiredSkill.skillset.splice(skillsetIndex, 1);
    }

    if (arr.category[index].requiredSkill.skillset[skillsetIndex]?.skill.length > 2) {
      const array = [];
      for (let i = 1; i <= arr.category[index].requiredSkill.skillset[skillsetIndex].skill.length; i++) {
        const obj = {
          value: i,
          label: i,
        };
        array.push(obj);
      }
      seTshouldHaveSkill(array);
    }

    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));

    updateHandler();
  };

  const deleteCategory = (index) => {
    setIsModal(false);
    const arr = addCategory;
    arr.category.splice(index, 1);
    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));
    updateHandler();
  };

  const addSkill = (index, skillsetIndex) => {
    seTshouldHaveSkill([]);
    const Obj = {
      skillIdName: "",
      skillIdNameId: "",
      competencyLevel: "",
      competencyLevelName: "",
    };
    const arr = addCategory;
    arr.category[index].requiredSkill.skillset[skillsetIndex].shouldKnowAtleast = 0;
    arr.category[index].requiredSkill.skillset[skillsetIndex].skill.push(Obj);
    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));

    if (arr.category[index].requiredSkill.skillset[skillsetIndex].skill.length > 2) {
      const array = [];
      for (let i = 1; i <= arr.category[index].requiredSkill.skillset[skillsetIndex].skill.length; i++) {
        const obj = {
          value: i,
          label: i,
        };
        array.push(obj);
      }
      seTshouldHaveSkill(array);
    }
  };

  const getValueOfArray = (len) => {
    const fillArray = [];
    for (let i = 1; i <= len; i++) {
      const obj = {
        value: i,
        label: i,
      };
      fillArray.push(obj);
    }
    return fillArray;
  };

  const addSkillSet = (index) => {
    const obj = {
      skill: [
        {
          skillIdName: "",
          skillIdNameId: "",
          competencyLevel: "",
          competencyLevelName: "",
        },
      ],
      shouldKnowAtleast: 0,
    };
    const arr = addCategory;
    arr.category[index].requiredSkill.skillset.push(obj);
    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));
  };

  const addCategories = () => {
    const obj = {
      categoryId: "",
      categoryName: "",
      requiredSkill: {
        skillset: [
          {
            skill: [
              {
                skillIdName: "",
                skillIdNameId: "",
                competencyLevel: "",
                competencyLevelName: "",
              },
            ],
            shouldKnowAtleast: 0,
          },
        ],
      },
      niceToHaveSkill: {
        skill: [],
      },
    };
    const arr = addCategory;
    arr.category.push(obj);
    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));
    setTimeout(() => {
      findPos("category-container");
    }, 1000);
  };

  const addNTHSkill = (index) => {
    const Obj = {
      skillIdName: "",
      skillIdNameId: "",
      competencyLevel: "",
      competencyLevelName: "",
    };
    const arr = addCategory;
    arr.category[index].niceToHaveSkill.skill.push(Obj);
    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));
    updateHandler();
  };

  const deleteNTHSkill = (nthIndex, index) => {
    const arr = addCategory;
    arr.category[index].niceToHaveSkill.skill.splice(nthIndex, 1);
    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));
    updateHandler();
  };

  const selectDepartment = (select) => {
    if (select) {
      dispatch(actions.getRoleByDepartment(select.value));

      setDisableDropdown(true);
    } else {
      setTimeout(() => {
        setDisableDropdown(true);
      }, 1000);
    }
    addCategory.roleId = "";
    addCategory.roleName = "";
    addCategory.technicalRoleName = "";
    addCategory.technicalRoleId = "";
    setTechnicalRole([]);

    const arr = addCategory;
    arr.departmentId = select && select.label ? select.value : "";
    arr.departmentName = select && select.value ? select.label : "";
    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));
    updateHandler();
  };

  const selectRoleByDepartment = (select) => {
    const arr = addCategory;
    arr.roleId = select && select.value ? select.value : "";
    arr.roleName = select && select.label ? select.label : "";
    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));
    updateHandler();
  };

  const selectSkill = (select, skillIndex, index, skillsetIndex, param) => {
    const arr = addCategory;
    if (select) {
      if (param === "SKILL") {
        arr.category[index].requiredSkill.skillset[skillsetIndex].skill[skillIndex].skillIdName =
          select && select.label ? select.label : null;
        arr.category[index].requiredSkill.skillset[skillsetIndex].skill[skillIndex].skillIdNameId =
          select && select.value ? select.value : null;
        setAddCategory((prevState) => ({
          ...prevState,
          category: arr.category,
        }));
      }
    } else {
      arr.category[index].requiredSkill.skillset[skillsetIndex].skill[skillIndex].skillIdName = "";
      arr.category[index].requiredSkill.skillset[skillsetIndex].skill[skillIndex].skillIdNameId = "";
      setAddCategory((prevState) => ({
        ...prevState,
        category: arr.category,
      }));
    }
    if (param === "LEVEL") {
      arr.category[index].requiredSkill.skillset[skillsetIndex].skill[skillIndex].competencyLevelName =
        select && select.label ? select.label : null;
      arr.category[index].requiredSkill.skillset[skillsetIndex].skill[skillIndex].competencyLevel =
        select && select.value ? select.value : null;
      setAddCategory((prevState) => ({
        ...prevState,
        category: arr.category,
      }));
    }
    updateHandler();
  };

  const selectNiceToHaveSkill = (index, nthIndex, select, param) => {
    const arr = addCategory;
    if (param === "SKILL") {
      arr.category[index].niceToHaveSkill.skill[nthIndex].skillIdName = select && select.label ? select.label : "";
      arr.category[index].niceToHaveSkill.skill[nthIndex].skillIdNameId = select && select.value ? select.value : "";
      setAddCategory((prevState) => ({
        ...prevState,
        category: arr.category,
      }));
    }
    if (param === "LEVEL") {
      arr.category[index].niceToHaveSkill.skill[nthIndex].competencyLevelName =
        select && select.label ? select.label : null;
      arr.category[index].niceToHaveSkill.skill[nthIndex].competencyLevel =
        select && select.value ? select.value : null;
      setAddCategory((prevState) => ({
        ...prevState,
        category: arr.category,
      }));
    }
    updateHandler();
  };

  const selectCategory = (index, value) => {
    if ((typeof value === "object" && value.constructor === Object) || typeof value === "string") {
      const arr = addCategory;
      arr.category[index].categoryName = value && value.label ? value.label : value;
      arr.category[index].categoryId = value && value.value ? value.value : "";
      setAddCategory((prevState) => ({
        ...prevState,
        category: arr.category,
      }));
    }
    updateHandler();
  };

  const selectTechRole = (value) => {
    if ((typeof value === "object" && value.constructor === Object) || typeof value === "string") {
      const arr = addCategory;
      arr.technicalRoleId = value && value.value ? value.value : "";
      arr.technicalRoleName = value && value.label ? value.label : value;
      setAddCategory((prevState) => ({
        ...prevState,
        category: arr.category,
      }));
    }
    updateHandler();
  };

  const selectNumberOfSkill = (index, skillsetIndex, select) => {
    const arr = addCategory;
    arr.category[index].requiredSkill.skillset[skillsetIndex].shouldKnowAtleast =
      select && select.value ? select.value : null;

    setAddCategory((prevState) => ({
      ...prevState,
      category: arr.category,
    }));
    updateHandler();
  };

  const submit = () => {
    let catName = [];
    let isExist = false;
    addCategory.category.forEach((cat) => {
      cat.categoryName = cat.categoryName.trim();
      if (catName.includes(cat.categoryName.trim())) {
        isExist = true;
        errorToast("Category should be unique");
      }
      catName.push(cat.categoryName);
      return;
    });
    let param;
    if (props.match.params && props.match.params.id && !props.location.state.detail) {
      param = "UPDATE";
    } else {
      if (props.match.params && props.match.params.id && props.location.state.detail) {
        delete addCategory.departmentRoleSkillConfigId;
      }
      param = "SAVE";
    }

    addCategory.technicalRoleName = addCategory.technicalRoleName.trim();
    if (!isExist) {
      addCategory["isCreated"] = true;
      addCategory.roleId = addCategory.roleId !== "" ? addCategory.roleId : null;
      addCategory.roleName = addCategory.roleId && addCategory.roleName !== "" ? addCategory.roleName : null;
      dispatch(actions.saveRequiredSkill(addCategory, param));
    }
  };

  const updateHandler = () => {
    addCategory["isCreated"] = false;
    addCategory["departmentRoleSkillId"] =
      getDefineSkillDraft && getDefineSkillDraft.departmentRoleSkillId
        ? getDefineSkillDraft.departmentRoleSkillId
        : null;
    if (!(props.match.params && props.match.params.id)) {
      dispatch(actions.updateReqSkill(addCategory));
    }
  };

  //find element position
  function findPos(id) {
    var node = document.getElementById(id).lastElementChild;
    var curtop = 0;
    var curtopscroll = 0;
    if (node.offsetParent) {
      do {
        curtop += node.offsetTop;
        curtopscroll += node.offsetParent ? node.offsetParent.scrollTop : 0;
      } while ((node = node.offsetParent));
      window.scrollTo({ top: curtop - curtopscroll - 10, behavior: "smooth" });
    }
  }

  return (
    <div className={style.roll_based_skill_wrapper}>
      <SubNavigation type="Skill Matrix" />
      {isLoading && <Spinner />}
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">Skill Matrix</h1>
            <span className="st-breadcrumb">
              Skill Matrix
              <span>
                <Link to={routePath.MMG_DEFINE_SKILLS}> Define Skills</Link>
              </span>
              <span>
                {props.match.params && props.match.params.id && !props.location.state.detail
                  ? "Edit Skills"
                  : "Add Skills"}
              </span>
            </span>
          </Col>
          <Col md={6} className={[style.res_title_btn, "text-right"].join(" ")}>
            <button
              type="button"
              onClick={() => hideModal()}
              className={["btn-round save_box_btn btn btn-primary", style.create_new_btn].join(" ")}
            >
              <strong>Add Skill Master</strong>
            </button>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <div className={[style.define_add_skills_wrapper, "box"].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Define Skills</h2>
                  <h3>Define Skills for particular Role</h3>
                </div>
              </div>
              <div className="box-inner-pad">
                <Row>
                  <Col md={4}>
                    <span className={style.dd_title}>Department</span>
                    <div className="card_action">
                      <Select
                        options={getDepartmentList}
                        className="custom-default-dd-box"
                        classNamePrefix="select"
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Select the Department"
                        onChange={(select) => selectDepartment(select)}
                        value={
                          addCategory.departmentId !== ""
                            ? {
                                value: addCategory.departmentId,
                                label: addCategory.departmentName,
                              }
                            : null
                        }
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span className={style.dd_title}>Role(Optional)</span>
                    <div className="card_action">
                      <Select
                        options={roleList}
                        className="custom-default-dd-box"
                        classNamePrefix="select"
                        isSearchable={true}
                        isClearable={true}
                        isDisabled={disableDropdown}
                        placeholder={"Select the Role"}
                        value={
                          addCategory.roleId
                            ? {
                                value: addCategory.roleId || null,
                                label: addCategory.roleName || null,
                              }
                            : null
                        }
                        onChange={(select) => selectRoleByDepartment(select)}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span className={style.dd_title}>Technical Role</span>
                    <Autocomplete
                      suggestions={technicalRoleList}
                      noValueText={"(New Technical Role)"}
                      selectTechRole={selectTechRole}
                      placeholderText={"Add/Select technical role"}
                      inputVal={addCategory.technicalRoleName}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div id="category-container">
              {/* Add Category */}
              {addCategory &&
                addCategory.category.map((cat, index) => {
                  return (
                    <div className={[style.define_add_skills_wrapper, "box"].join(" ")} key={index}>
                      <div className="box-inner-pad pt-0">
                        <Row>
                          <Col md={4}>
                            <span className={style.dd_title}>Category of Skills</span>
                            <Autocomplete
                              suggestions={getCategoryList}
                              noValueText={"(New Category)"}
                              catIndex={index}
                              selectCategory={selectCategory}
                              placeholderText={"Add/Select the Category of Skills "}
                              inputVal={cat.categoryName}
                            />
                          </Col>
                          <Col md={8} className={style.delete_category}>
                            {addCategory.category.length > 1 && (
                              <span
                                className={style.dc}
                                onClick={() => {
                                  setIsModal(true);
                                  setIndex(index);
                                }}
                              >
                                <img src={IC_SEARCH_RESET} alt="Delete Category" />
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className={style.skills_outer_wrapper}>
                        <div className="box-light-gradient"></div>
                        <div className="box-inner-pad">
                          <div className="box-heading-wrapper pl-0 pb-0 border-bottom-0">
                            <div className="box-heading">
                              <h2>Required Skills</h2>
                              <h3>Please add/select skills which are required</h3>
                            </div>
                          </div>

                          <div className={style.skill_set_wrapper}>
                            {/* Add skill set */}
                            {cat.requiredSkill.skillset.map((skills, skillsetIndex) => {
                              if (skills.skill.length > 0) {
                                return (
                                  <div className={style.skill_set_list} key={skillsetIndex}>
                                    <span className={style.andtxt}>
                                      <span>And</span>
                                    </span>
                                    <div className={style.skill_topbar}>
                                      {/* Add Skill */}
                                      <div className={style.skill_topbar_list}>
                                        {skills.skill.map((skill, skillIndex) => {
                                          return (
                                            <div className={style.skill_item} key={skill.skillIdNameId}>
                                              <div className={style.dd_wrapper}>
                                                <span className={style.dd_title}>Skill Name</span>
                                                <div className="card_action">
                                                  <Select
                                                    options={skillList}
                                                    className="custom-default-dd-box"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder={"Select Skill Name"}
                                                    onChange={(select) =>
                                                      selectSkill(select, skillIndex, index, skillsetIndex, "SKILL")
                                                    }
                                                    value={
                                                      addCategory.category[index].requiredSkill.skillset[skillsetIndex]
                                                        .skill[skillIndex].skillIdName != ""
                                                        ? {
                                                            value:
                                                              addCategory.category[index].requiredSkill.skillset[
                                                                skillsetIndex
                                                              ].skill[skillIndex].skillIdNameId,
                                                            label:
                                                              addCategory.category[index].requiredSkill.skillset[
                                                                skillsetIndex
                                                              ].skill[skillIndex].skillIdName,
                                                          }
                                                        : null
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className={style.dd_wrapper}>
                                                <span className={style.dd_title}>Skill Competency Level</span>
                                                <div className="card_action">
                                                  <Select
                                                    options={competency_level_options}
                                                    className="custom-default-dd-box"
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={"Select Level"}
                                                    onChange={(select) =>
                                                      selectSkill(select, skillIndex, index, skillsetIndex, "LEVEL")
                                                    }
                                                    value={
                                                      addCategory.category[index].requiredSkill.skillset[skillsetIndex]
                                                        .skill[skillIndex].competencyLevel != ""
                                                        ? {
                                                            value:
                                                              addCategory.category[index].requiredSkill.skillset[
                                                                skillsetIndex
                                                              ].skill[skillIndex].competencyLevel || null,
                                                            label:
                                                              addCategory.category[index].requiredSkill.skillset[
                                                                skillsetIndex
                                                              ].skill[skillIndex].competencyLevelName || null,
                                                          }
                                                        : null
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {skills.skill.length > 1 && skillsetIndex === 0 && (
                                                <span className={style.delete}>
                                                  <img
                                                    src={IC_SEARCH_RESET}
                                                    alt="delete"
                                                    onClick={() => deleteSkill(index, skillsetIndex, skillIndex)}
                                                  />
                                                </span>
                                              )}
                                              {skillsetIndex > 0 && (
                                                <span className={style.delete}>
                                                  <img
                                                    src={IC_SEARCH_RESET}
                                                    alt="delete"
                                                    onClick={() => deleteSkill(index, skillsetIndex, skillIndex)}
                                                  />
                                                </span>
                                              )}
                                              <span className={style.ortxt}>OR</span>
                                            </div>
                                          );
                                        })}
                                      </div>

                                      <span
                                        className={style.add_set_skill}
                                        onClick={() => addSkill(index, skillsetIndex)}
                                      >
                                        Add SKILL
                                      </span>
                                    </div>
                                    {skills.skill && skills.skill.length > 2 && (
                                      <div className={style.skill_bottombar}>
                                        <Row>
                                          <Col md={4}>
                                            <span className={style.dd_title}>Should have atleast*</span>
                                            <div className="card_action">
                                              <Select
                                                options={getValueOfArray(skills.skill.length)}
                                                className="custom-default-dd-box"
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                onChange={(select) => selectNumberOfSkill(index, skillsetIndex, select)}
                                                value={
                                                  addCategory.category[index].requiredSkill.skillset[skillsetIndex]
                                                    .shouldKnowAtleast !== 0
                                                    ? {
                                                        value:
                                                          addCategory.category[index].requiredSkill.skillset[
                                                            skillsetIndex
                                                          ].shouldKnowAtleast || null,
                                                        label:
                                                          addCategory.category[index].requiredSkill.skillset[
                                                            skillsetIndex
                                                          ].shouldKnowAtleast || null,
                                                      }
                                                    : null
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}
                          </div>
                          <span
                            className={style.add_set_skill}
                            onClick={() => {
                              addSkillSet(index);
                            }}
                          >
                            Add New Set
                          </span>
                        </div>
                      </div>

                      <div className={style.skills_outer_wrapper}>
                        <div className="box-light-gradient"></div>
                        <div className="box-inner-pad">
                          <div className="box-heading-wrapper pl-0 pb-0 border-bottom-0">
                            <div className="box-heading">
                              <h2>Nice to Have Skills</h2>
                              <h3>Please add/select skills which are NICE to have</h3>
                            </div>
                          </div>
                          <div className={[style.skill_set_wrapper, "pl-0", "mb-0"].join(" ")}>
                            <div className={[style.skill_topbar, "p-0"].join(" ")}>
                              <div className={style.skill_topbar_list}>
                                {cat.niceToHaveSkill.skill.map((nsk, nthIndex) => {
                                  return (
                                    <div className={style.skill_item} key={nthIndex}>
                                      <div className={style.dd_wrapper}>
                                        <span className={style.dd_title}>Select Skill Name</span>
                                        <div className="card_action">
                                          <Select
                                            options={skillList}
                                            className="custom-default-dd-box"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={(select) =>
                                              selectNiceToHaveSkill(index, nthIndex, select, "SKILL")
                                            }
                                            placeholder={"Select Skill Name"}
                                            value={
                                              addCategory.category[index].niceToHaveSkill.skill[nthIndex]
                                                .skillIdNameId != ""
                                                ? {
                                                    value:
                                                      addCategory.category[index].niceToHaveSkill.skill[nthIndex]
                                                        .skillIdNameId || null,
                                                    label:
                                                      addCategory.category[index].niceToHaveSkill.skill[nthIndex]
                                                        .skillIdName || null,
                                                  }
                                                : null
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className={style.dd_wrapper}>
                                        <span className={style.dd_title}>Skill Competency Level</span>
                                        <div className="card_action">
                                          <Select
                                            options={competency_level_options}
                                            className="custom-default-dd-box"
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={"Select Level"}
                                            onChange={(select) =>
                                              selectNiceToHaveSkill(index, nthIndex, select, "LEVEL")
                                            }
                                            value={
                                              addCategory.category[index].niceToHaveSkill.skill[nthIndex]
                                                .competencyLevelName != ""
                                                ? {
                                                    value:
                                                      addCategory.category[index].niceToHaveSkill.skill[nthIndex]
                                                        .competencyLevel || null,
                                                    label:
                                                      addCategory.category[index].niceToHaveSkill.skill[nthIndex]
                                                        .competencyLevelName || null,
                                                  }
                                                : null
                                            }
                                          />
                                        </div>
                                      </div>
                                      <span className={style.delete} onClick={() => deleteNTHSkill(nthIndex, index)}>
                                        <img src={IC_SEARCH_RESET} alt="delete" />
                                      </span>
                                      <span className={style.ortxt}>OR</span>
                                    </div>
                                  );
                                })}
                              </div>
                              <span className={style.add_set_skill} onClick={() => addNTHSkill(index)}>
                                Add SKILL
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className={["box-flex-center box", style.saveBox].join(" ")}>
              <div className={style.save_box_left}>
                <Button type="button" variant={"secondary"} onClick={addCategories}>
                  <strong>Add Category</strong>
                </Button>
              </div>
            </div>

            <div className={["box-flex-center box", style.saveBox].join(" ")}>
              <div className={style.save_box_left}>
                <Button type="button" variant={"primary"} disabled={disable} onClick={submit}>
                  <strong>{props.match.params.id && !props.location.state.detail ? "Update" : "Save"}</strong>
                </Button>
              </div>
              {!(props.match.params && props.match.params.id) && addCategory.draftedDate && (
                <div className={style.save_box_right}>
                  Draft Saved:
                  <span> {moment(addCategory.draftedDate.draftedDate).format("ddd D MMM h:mm a")} </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <DeleteSkillCategoryModal show={isModal} hide={hide} index={catIndex} deleteCategory={deleteCategory} />

      <AddSkillMasterModal show={isModalSkill} hide={hideModal} />
    </div>
  );
}
