import { getPager } from "util/general";
import * as actionTypes from "../actions/actionTypes";
const initialState = {
  campaignList: [],
  employeeCampaignData: null,
  employeeQuizData: null,
  isLoading: true,
  apiResponse: null,
  locationList: [],
  bandList: [],
  departmentList: [],
  designationList: [],
  employeeGroupList: [],
  campaignData: {
    campaignFilters: [
      {
        id: new Date().getTime() + "CAMPAIGN",
        location: [],
        department: [],
        designation: [],
        indiaDD: [],
        startDate: null,
        endDate: null,
        employeeType: [],
        employeeIds: [],
        departmentList: [],
        designationList: [],
        employeesListForLocation: [],
        indiaDDList: [],
        employeeGroupList: [],
        isChange: false,
      },
    ],
  },
  campaignListForAdmin: [],
  campaignListForEmployee: [],
  employeesList: [],
  previewCampaign: null,
  previewPopup: false,
  popupLoading: false,
  isCreateLoading: false,
  isTemplateLoading: true,
  isCampaignLoading: true,
  isFilterViewLoading: true,
  isPublished: false,
  isSubmitted: false,
  employeeList: [],
  campaignFilterSearch: {},
  campaignTabSwitch: { detailView: "LEFT" },
  questionsGraph: [],
  questionsPopup: [],
  capabilityListForAdmin: {
    data: [],
    filterList: [],
    currentPage: 1,
    pageSize: 5,
    totalItem: 0,
  },
  pendingPercentage: 0,
  submittedPercentage: 0,
  submittedCount: 0,
  pendingCount: 0,
  capabilityTemplateList: {
    data: [],
    filterList: [],
    currentPage: 1,
    pageSize: 5,
    totalItem: 0,
  },
  capabilityListLoader: true,
  capabilityData: {},
  isManagerSubmitted: false,
  managerSubmittedDate: null,
  submittedDate: null,
  managerError: { submit: false, errors: [] },
  employeeCampaignQuizList: [],
  attemptedPercentage: 0,
  notattemptedPercentage: 0,
  attemptedCount: 0,
  notattemptedCount: 0,
  totalCount: 0,
  viewQuizListPastData: [],
  viewQuizListOngoingData: [],
  viewQuizListOngoingCount: 0,
  viewQuizListPastCount: 0,
  isEmployeeQuizDetailsLoading: true,
  questionIds: null,
  quizStartDate: null,
  isFinish: false,
  isQuizStarted: false,
  isRedirectPopup: false,
  isQuizStartButtonLoading: false,
  isQuizResume: false,
  isBackDisable: false,
  isHrPublishViewLoading: false,
  employeeViewQuizResult: null,
  hrViewQuizResult: null,
  wrongAnswersForEmployee: null,
  wrongAnswersForHr: null,
  isHrViewEmployeeQuizResultLoading: true,
  isEmployeeViewQuizResultLoading: true,
  quizResultGraphData: null,
  employeesDetailsRoleRightsIndependent: {
    departmentList: [],
    employeesList: [],
    designationList: [],
    employeeTypeList: [],
    subGroupList: [],
    locationList: [],
  },
  countryList: [],
  editCampaignData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADDITIONAL_SKILL_DELETE_ERROR:
      return {
        ...state,
      };
    case "CREATE_CAMPAIGN_LOADING":
      return {
        ...state,
        isCreateLoading: true,
      };
    case "ISFINISH":
      return {
        ...state,
        isFinish: action.payload,
      };
    case "ISQUIZSTARTED":
      return {
        ...state,
        isQuizStarted: action.payload,
      };
    case "IS_REDIRECT_POPUP":
      return {
        ...state,
        isRedirectPopup: action.payload,
      };
      case "IS_QUIZ_START_BUTTON_LOADING":
      return {
        ...state,
        isQuizStartButtonLoading: action.payload,
      };
    case "IS_QUIZ_RESUME":
      return {
        ...state,
        isQuizResume: action.payload,
      };
    case "IS_BACK_DISABLE":
      return {
        ...state,
        isBackDisable: action.payload,
      };
    case "QUIZ_DETAILS_RESUME":
      return {
        ...state,
        quizDetailsResume: action.payload,
      };
    case actionTypes.SET_CAMPAIGN_RESPONSE:
      return {
        ...state,
        ...action.payload,
        isCreateLoading: false,
        employeeList: state.employeeList?.map((value) => {
          if (value.employeeId === action.employeeId) {
            return {
              ...value,
              managerSubmittedDate: new Date(),
            };
          } else return value;
        }),
      };
    case "QUIZ_DETAILS_RESUME":
      return {
        ...state,
        editCampaignData: action.payload,
      };
    case actionTypes.SET_DEPARTMENT_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_DESIGNATION_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CAPABILITY_TEMPLATE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CAMPAIGN_QUESTION_GRAPH:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CAMPAIGN_QUESTION_POPUP:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.SET_LOCATION_LIST_IN_CAMPAIGN:
      return {
        ...state,
        locationList: action.data,
      };
    case actionTypes.SET_CREATED_CAMPAIGN: {
      let data = { ...action.campaignData };
      let isPublished = action.campaignData.status === "PUBLISHED";
      data.campaignFilters =
        data.campaignFilters.length > 0 ? data.campaignFilters : initialState.campaignData.campaignFilters;
      return {
        ...state,
        campaignData: data,
        isCampaignLoading: false,
        popupLoading: false,
        isCreateLoading: false,
        isPublished: isPublished,
      };
    }
    case actionTypes.SET_CAMPAIGN_TEMPLATE_LIST:
      return {
        ...state,
        templateList: action.templateList,
        isTemplateLoading: action.isTemplateLoading,
      };
    case actionTypes.SET_LOCATION_FILTER_FOR_CAMPAIGN: {
      let data = state.campaignData.campaignFilters.map((value) => {
        if (value.id === action.id) {
          return {
            ...value,
            ...action.payload,
            isChange: true,
          };
        } else return value;
      });
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          campaignFilters: data,
        },
      };
    }
    case actionTypes.SET_FILTER_FOR_CAMPAIGN:
      return {
        ...state,
        ...action.payload,
      };
    case "AUTO_SAVE_CAMPAIGN_TEMPLATE": {
      let updatedQuestion = [...state.employeeCampaignData.questionTemplate.questions];
      updatedQuestion = updatedQuestion.map((que) => {
        if (que.questionId === action.data.questionId) {
          return {
            ...que,
            ...action.data,
          };
        } else return que;
      });
      return {
        ...state,
        employeeCampaignData: {
          ...state.employeeCampaignData,
          questionTemplate: {
            ...state.employeeCampaignData.questionTemplate,
            questions: updatedQuestion,
          },
        },
      };
    }
    case "AUTO_SAVE_CAPABILITY_BY_MANAGER": {
      return {
        ...state,
        capabilityData: {
          ...state.capabilityData,
          [action.payload.questionId]: action.payload,
        },
      };
    }
    case "SET_CAMPAIGN_DATA":
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          ...action.payload,
        },
      };
    case "ADD_NEW_FILTER_ROW":
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          campaignFilters: [...state.campaignData.campaignFilters, action.payload],
        },
      };
    case "REMOVE_FILTER_ROW": {
      let updatedRow = { ...state.campaignData };
      updatedRow.campaignFilters = updatedRow.campaignFilters.filter((value) => value.id !== action.id);
      return {
        ...state,
        campaignData: updatedRow,
      };
    }
    case actionTypes.SET_EMPLOYEE_NAME_FOR_CAMPAIGN:
      return {
        ...state,
        employeesList: action.data,
      };
    case actionTypes.SET_EMPLOYEE_CAMPAIGN_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_PREVIEW_OF_CAMPAIGN:
      return {
        ...state,
        ...action.payload,
      };
    case "START_CAMPAIGN_TEMPLATE_VIEW_LOADING":
      return {
        ...state,
        isCampaignLoading: true,
      };
    case "EMPLOYEE_VIEW_QUIZ_DETAILS_LOADING":
      return {
        ...state,
        isEmployeeQuizDetailsLoading: true,
      };
    case actionTypes.SET_CAMPAIGN_LIST_FOR_ADMIN:
      return {
        ...state,
        ...action.payload,
        campaignData: initialState.campaignData,
        previewCampaign: null,
        previewPopup: false,
        isCampaignLoading: true,
        isPublished: false,
        isTemplateLoading: true,
      };
    case actionTypes.SET_CAPABILITY_LIST_FOR_ADMIN:
      return {
        ...state,
        ...action.payload,
        campaignData: initialState.campaignData,
        previewCampaign: null,
        previewPopup: false,
        isCampaignLoading: true,
        isPublished: false,
        isTemplateLoading: true,
      };
    case actionTypes.SET_EMPLOYEE_VIEW_QUIZ_QUESTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_EMPLOYEE_RESUME_VIEW_QUIZ_QUESTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_EMPLOYEE_REFRESH_VIEW_QUIZ_QUESTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_QUIZ_INFO_INTERVAL:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_QUIZ_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_IMPORT_QUIZ_TEMPLATE_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_EMPLOYEE_VIEW_QUIZ_RESULT:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_HR_VIEW_QUIZ_RESULT:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_WRONG_ANSWERS_FOR_EMPLOYEE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_WRONG_ANSWERS_FOR_HR:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_EMPLOYEES_DETAILS_ROLE_RIGHTS_INDEPENDENT:
      return {
        ...state,
        employeesDetailsRoleRightsIndependent: action.payload,
      };
    case "HR_VIEW_EMPLOYEE_QUIZ_RESULT_LOADING":
      return {
        ...state,
        isHrViewEmployeeQuizResultLoading: true,
      };
    case "EMPLOYEE_VIEW_QUIZ_RESULT_LOADING":
      return {
        ...state,
        isEmployeeViewQuizResultLoading: true,
      };
    case actionTypes.UPDATE_CAMPAIGN_LIST_FOR_ADMIN:
      return {
        ...state,
        ...action.payload,
        campaignListForAdmin: state.campaignListForAdmin.filter((v) => v.campaignId !== action.campaignId),
      };
    case actionTypes.SET_CAMPAIGN_LIST_FOR_EMPLOYEE:
      return {
        ...state,
        ...action.payload,
      };
    case "CLEAR_CAMPAIGN_RESPONSE":
      return {
        ...state,
        apiResponse: null,
      };
    case actionTypes.SET_EMPLOYEE_QUIZ_RESULT_GRAPH_DATA:
      return {
        ...state,
        quizResultGraphData: action.payload,
      };
    case actionTypes.SET_CAMPAIGN_PREVIEW_DATA:
      return {
        ...state,
        ...action.payload,
        previewPopup: true,
        isCreateLoading: false,
      };
    case actionTypes.SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_PAST_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_QUIZ_TEMPLATE_EMPLOYEE_VIEW_ONGOING_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.REMOVE_CAMPAIGN_PREVIEW_DATA:
      return {
        ...state,
        previewCampaign: null,
        questionsPopup: [],
        previewPopup: false,
      };
    case actionTypes.SET_EMPLOYEE_LIST_FOR_CAMPAIGN:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_COUNTRY_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_EMPLOYEE_QUIZ_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case "START_CAMPAIGN_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "IMPORT_QUIZ_LOADING":
      return {
        ...state,
        isImportLoading: true,
      };
    case "START_CAMPAIGN_POPUP_LOADING":
      return {
        ...state,
        popupLoading: true,
      };
    case "START_CAMPAIGN_EMPLOYEE_VIEW_LOADING":
      return {
        ...state,
        isFilterViewLoading: true,
      };
    case "PUBLISH_CAMPAIGN_HR_VIEW_LOADING":
      return {
        ...state,
        isHrPublishViewLoading: action.payload,
      };
    case "SET_CAMPAIGN_VIEW_FILTER_DATA":
      return {
        ...state,
        campaignFilterSearch: {
          ...state.campaignFilterSearch,
          ...action.payload,
        },
      };
    case "SET_CAMPAIGN_TAB_SWITCH_DATA":
      return {
        ...state,
        campaignTabSwitch: {
          ...action.payload,
        },
      };
    case "CLEAR_CAMPAIGN_VIEW_FILTER_DATA":
      return {
        ...state,
        campaignFilterSearch: { detailView: "LEFT" },
      };
    case "STATUS_AND_PAGINATION_QUIZ_DATA":
      return {
        ...state,
        statusAndPaginationData: action.payload,
      };
    case "SET_CAPABILITY_MANAGER_ERRORS":
      return {
        ...state,
        managerError: {
          ...state.managerError,
          ...action.payload,
        },
      };
    case "SET_CAMPAIGN_PAGINATION_CURRENT_PAGE": {
      const pager = getPager(
        state[action.payload.name].totalItem,
        action.payload.currentPage,
        state[action.payload.name].pageSize
      );
      const startIndex = pager?.startIndex;
      const endIndex = pager?.endIndex + 1;
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          filterList: state[action.payload.name].data.slice(startIndex, endIndex),
          currentPage: action.payload.currentPage,
        },
      };
    }
    case "SET_CAMPAIGN_PAGINATION_PAGE_SIZE": {
      const pager = getPager(state[action.payload.name].totalItem, 1, action.payload.pageSize);
      const startIndex = pager?.startIndex;
      const endIndex = pager?.endIndex + 1;
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          filterList: state[action.payload.name].data.slice(startIndex, endIndex),
          pageSize: action.payload.pageSize,
          currentPage: 1,
        },
      };
    }
    default:
      return state;
  }
};
export default reducer;
