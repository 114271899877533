import React, { useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ReactSVG } from "react-svg";
import { IC_CALENDAR } from "const/imgCost";
import { dateFormat1 } from "util/dateUtils";

export default function Patterns(props) {
  const ref = useRef(null);

  return (
    <div className="custom-tooltip custom-tooltip-top" container={ref.current}>
      <div
        className={[
          "box-info",
          props.active,
          props.style.dot,
          props.item.status === "PENDING_FROM_MEMBER" && props.style.tab_checkin_delay,
        ].join(" ")}
      >
        <span></span>
      </div>
      <div className="info-attach-file">
        <Row>
          <Col md={12}>
            <span className="checkin-cycle">
            OKR Progress : {dateFormat1(props.item.startDate)} – {dateFormat1(props.item.endDate)}
            </span>
          </Col>
          <Col>
            <div className="tTitle">Submission&nbsp;Date</div>
            <div className="tDateBox">
              <div className="tCalendar">
                <ReactSVG src={`${IC_CALENDAR}`} title="" alt="Calendar Icon" />
              </div>
              <div className="tDate">{props.checkins.submit_date}</div>
            </div>
          </Col>
          <Col>
            <div className="tTitle">Reviewed&nbsp;Date</div>
            <div className="tDateBox">
              <div className="tCalendar">
                <ReactSVG src={`${IC_CALENDAR}`} title="" alt="Calendar Icon" />
              </div>
              <div className="tDate">{props.checkins.review_date}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
