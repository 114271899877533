import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "./CustomSwitch.module.scss";

Switch.propTypes = {
  title: PropTypes.string,
  handleChange: PropTypes.func,
  colorType: PropTypes.string,
};

Switch.defaultProps = {
  title: "",
  handleChange: () => {},
  colorType: "assessment",
};

function Switch({ title, handleChange, colorType }) {
  const [checked, setChecked] = useState(true);
  const handleChecked = (e) => {
    setChecked(e.target.checked);
    handleChange(e);
  };
  return (
    <div className={style.round_switch}>
      <span className={style.switch_label}>{title}</span>
      <label className={style.switch}>
        <input type="checkbox" onChange={handleChecked} defaultChecked={true} name={title} />
        <span className={[style.slider, checked ? colorType : style.disabled].join(" ")}></span>
      </label>
    </div>
  );
}

export default Switch;
