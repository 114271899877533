import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { IC_PDF } from "const/imgCost";
import style from "./InformationHubWrapper.module.scss";
import AccordianContent from "./AccordianContent";
import PDFViewerPopup from "./InfoViewerPopup/PDFViewerPopup";
import { useDispatch, useSelector } from "react-redux";
import { getInformationHubData } from "store/actions";
import Spinner from "shared/Spinner/Spinner";

export default function AccordianPanel() {
  const dispatch = useDispatch();
  const [activePopupName, setActivePopUpName] = useState(null);
  const [show, setShow] = useState(null);
  const { informationHubResponse, isLoading } = useSelector(
    (state) => state.informationHub
  );

  useEffect(() => {
    dispatch(getInformationHubData());
  }, []);
  useEffect(() => {
    // first accordian should open
    const accordianData = { 0: true };
    for (let i = 1; i < informationHubResponse.length; i++) {
      accordianData[i] = false;
    }
    setShow(accordianData);
  }, []);

  const handleShow = (id) => {
    setShow((prev) => {
      return { ...prev, [id]: !prev[id] };
    });
  };

  return (
    <>
      {isLoading && <Spinner />}
      {show &&
        informationHubResponse.map((item, index) => {
          const {
            informationHubId,
            moduleTitle,
            moduleSubTitle,
            pdfTitle,
            pdfLink,
            videosInfo,
          } = item;
          return (
            <React.Fragment key={informationHubId}>
              <div className={`card ${style.resource_item_wrapper}`}>
                <div className={`card_info ${style.card_info_wrapper}`}>
                  <div className="card_title">
                    <div className={`d-flex ${style.text_content_wrapper}`}>
                      <div className="card_title_text">{moduleTitle}</div>
                      {show[index] && (
                        <div className={style.text_content}>
                          <p>{moduleSubTitle}</p>
                          {/* {pdfLink && pdfTitle && (
                            <span
                              className={style.pdf_text_wrapper}
                              onClick={() => setActivePopUpName(pdfTitle)}
                            >
                              <span className={style.icon} type="button">
                                <img src={IC_PDF} alt="download" />
                              </span>
                              <span>{pdfTitle}.pdf</span>
                            </span>
                          )} */}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card_action">
                    <span onClick={() => handleShow(index)}>
                      {!show[index] ? "Show" : "Hide"}
                    </span>
                  </div>
                </div>
                <Collapse in={show[index]}>
                  <div>
                    <AccordianContent videosInfo={videosInfo} />
                  </div>
                </Collapse>
              </div>
              {activePopupName === pdfTitle && (
                <PDFViewerPopup
                  pdfTitle={pdfTitle}
                  pdfUrl={pdfLink}
                  handleClose={() => setActivePopUpName(null)}
                />
              )}
            </React.Fragment>
          );
        })}
    </>
  );
}
