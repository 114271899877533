import React from "react";
import style from "./AllObjectives.module.scss"
import { keyValue } from "../../../util/general"

const ObjectiveKeyPoints = (props) => {
    return (
        <tr>
            {/* <td colSpan="2">
                <ul>
                {
                    props.keyPoints.map((element) => {
                        let text = ""
                            if(element.description.length >= 80 && element.description.indexOf(' ') === -1) {
                                if(element.description.split(" ").length === 1) {
                                    text = element.description.replace(/(.{60})/g, '$1 ');
                                }
                            } else {
                                text = element.description
                            }
                        return (
                            <li>{text}</li>
                        )
                    })
                }
                </ul>
            </td> */}
            <td className={style.expand_duration} colSpan="5">
            { props.keyPoints && props.keyPoints.map(keyPoint => 
              <ul key={keyPoint.keyId}> 
                <li className="text-break">{keyPoint.description}</li>
                <li className ={style.key_value}> {keyValue(keyPoint)}</li>
              </ul> )
            }
          </td>
        </tr>
    )
}
export default ObjectiveKeyPoints;