import React from "react";

function TooltipInfoLi({ heading, listItem }) {
  return (
    <div className="info-attach-file">
      <span className="attachment-title">{heading}</span>
      <ul>
        {listItem?.length > 0 &&
          listItem.map((text, index) => {
            return <li key={index + "LIST"}>{text}</li>;
          })}
      </ul>
    </div>
  );
}

export default TooltipInfoLi;
