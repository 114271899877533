import React from "react";
import { Form, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_SUBMIT_BTN } from "const/imgCost";
import { backGroundColor } from "const/constValue";

const CommentTextArea = ({
  selectedColor,
  image,
  value,
  changeHandle,
  placeholder,
  maxLength,
  submitButton,
  submitComment,
  chars,
  showChars,
  messageText,
}) => {
  return (
    <div className="view_comment_box_row">
      <div className="comment_box_wrapper fg-comment-wrapper">
        <div className="user_img" style={{ backgroundColor: selectedColor || backGroundColor[0] }}>
          {image && image.length > 2 ? <img alt="DP" src={image} /> : image}
        </div>
        <div className="comment_area_wrapper">
          <Form.Group className="form_group_comment">
            <div className="textarea-wrapper">
              <Form.Control
                as="textarea"
                value={value}
                maxLength={maxLength}
                onChange={changeHandle}
                placeholder={placeholder}
                className="manager_comments"
              />
            </div>
            {submitButton && (
              <Button onClick={submitComment}>
                <ReactSVG className="svg" src={`${IC_SUBMIT_BTN}`} alt="info icon" />
              </Button>
            )}
            {showChars && <div className="checkin-question-info mt-2"> {chars} Characters Left</div>}
          </Form.Group>
          {messageText && <p className="comment_message_text">{messageText}</p>}
        </div>
      </div>
    </div>
  );
};

export default CommentTextArea;
