import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { CANCEL_CROSS } from "const/imgCost";
import config from "util/config";
import style from "./DashboardFilterSelection.module.scss";

function FilterSelectionWrapper({ filterOptions, labelName, handleRemove }) {
  const [viewMore, setViewMore] = useState(false);
  const optionsLength = Object.entries(filterOptions)?.filter(([, filterValue]) => filterValue)?.length;

  return (
    <div className="filter-wrapper box">
      <span>{labelName}</span>
      <div className="filter-wrapper-render">
        {Object.entries(filterOptions)
          .filter(([, filterValue]) => filterValue && typeof filterValue === "boolean")
          .filter(([, filterValue], index) => (viewMore ? true : index < 2))
          .map(([filterKey], index) => {
            return (
              <div
                className={[
                  style.badge_selection_wrapper,
                  viewMore ? "w-45" : optionsLength > 2 ? "w-38 " : "w-45",
                ].join(" ")}
                key={filterKey + index}
              >
                <div className={style.selection_text}>{filterKey && filterKey.split(config.idSplit)[1]}</div>
                <div className={style.svg}>
                  <ReactSVG src={`${CANCEL_CROSS}`} onClick={() => handleRemove(filterKey)} />
                </div>
              </div>
            );
          })}
        {!viewMore && optionsLength > 2 && (
          <div className={style.count} onClick={() => setViewMore(true)}>
            +{optionsLength - 2}
          </div>
        )}
        {viewMore && optionsLength > 2 && (
          <div className={style.view_less} onClick={() => setViewMore(false)}>
            View Less
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterSelectionWrapper;
