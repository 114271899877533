import React, { useState, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { Card, Form, Button, Row } from "react-bootstrap";
import style from "../../employeeObjectives/employeeObjectives.module.scss";
import { IC_ACC_ARROW_DOWN, IC_COLLAPSE, IC_EXPAND } from "../../../const/imgCost";
import { keyValueAchievedTooltip, objectiveStatus } from "../../../util/general";
import { dateFormat1 } from "../../../util/dateUtils";
import * as actions from "../../../store/actions/index";
import NoObjetivesFound from "../../Objectives/NoObjectivesFound/NoObjectiveFound";
import ObjectiveUserStatus from "../../employeeObjectives/objectiveUserStatus";
import InitiativeModal from "./Components/InitiativeModal";
import SelfAssessmentComment from "./Components/SelfAssessmentComment";
import KeyResultPoint from "../../KeyResultPoint/KeyResultPoint";
import storage from "../../../util/storage";

function SelfAssessmentObjective(props) {
  const loggedInUser = storage.getUser();
  const loggedInUserEmpId = loggedInUser?.employeeId;
  const { objectives, cycleId, privateAssessmentId, expanded, objectivePrivateAssessmentId } = props;
  const [isModal, setIsModel] = useState(false);
  const [initiativeObject, setInitiativeObject] = useState(false);
  const dispatch = useDispatch();
  const [eventValue, setEventValue] = useState(props.page === "checkin" ? 0 : "");

  const handleTextInputKeypoint = (data) => {
    if (JSON.stringify(data) !== JSON.stringify({})) {
      let _obj = objectives.find((obj) => obj.userObjectiveId === data.objId);
      if (_obj) {
        _obj.keyPoints.forEach((keypoint) => {
          if (parseInt(data.id) === keypoint.keyId) {
            let data = {
              userObjectiveId: _obj.userObjectiveId,
              employeeId: loggedInUserEmpId,
              subGroupId: _obj.subGroupId,
              subGroupName: _obj.subGroupName,
              parentGroupId: _obj.parentGroupId,
              parentGroupName: _obj.parentGroupName,
              employeeData: _obj.employeeData,
              keyPoints: {
                keyId: keypoint.keyId,
                description: keypoint.description,
                type: keypoint.type,
                start: keypoint.start,
                end: keypoint.end,
                progress: keypoint.progress,
                isCompleted: keypoint.isCompleted,
                hours: keypoint.hours,
                weightage: keypoint.weightage,
                mark: keypoint.mark,
              },
              privateAssessmentId: privateAssessmentId,
              type: "SELF",
              cycleId: cycleId,
            };
            dispatch(actions.SetSelfAssessmentObjective(data));
            dispatch(actions.userObjective(data));
          }
        });
      }
    }
  };

  const selectRadio = (data) => {
    data.privateAssessmentId = privateAssessmentId;
    data.type = "SELF";
    data.cycleId = cycleId;
    dispatch(actions.SetSelfAssessmentObjective(data));

    dispatch(actions.userObjective(data));
  };

  //OBJECTIVE COMMENT START
  const handleComment = (e, obj) => {
    let comment = e.target.value.trim();
    dispatch(
      actions.SetAssessmentComment(
        comment,
        loggedInUser,
        privateAssessmentId,
        obj.userObjectiveId,
        "SELF",
        cycleId,
        loggedInUser
      )
    );
    let newObjective = props.selfAssessmentError.map((value) => {
      if (value.commentId === obj.userObjectiveId) {
        value.comment = e.target.value.trim();
      }
      return value;
    });
    props.setSelfAssessmentError(newObjective);
  };
  //OBJECTIVE COMMENT END

  const targetUserName = useRef(null);

  function addCharacter(keypoint) {
    let inputValue = "";
    if (keypoint.type === "%") {
      inputValue =
        keypoint.progress === ""
          ? "%"
          : keypoint.progress === 0
            ? keypoint.end === 0
              ? 0
              : keypoint.start + "%"
            : keypoint.progress + "%";
    } else if (keypoint.type === "$") {
      inputValue =
        keypoint.progress === ""
          ? "$"
          : keypoint.progress === 0
            ? keypoint.end === 0
              ? 0
              : "$" + keypoint.start
            : "$" + keypoint.progress;
    } else if (keypoint.type === ":") {
      inputValue =
        keypoint.progress === ""
          ? "h"
          : keypoint.progress === 0
            ? keypoint.end === 0
              ? 0
              : "0h"
            : keypoint.progress + "h";
    } else if (keypoint.type === "down") {
      inputValue = keypoint.progress === "" ? 0 : keypoint.progress === 0 ? 0 : keypoint.progress;
    } else if (keypoint.type === "between") {
      inputValue = keypoint.progress === "" ? 0 : keypoint.progress === 0 ? 0 : keypoint.progress;
    } else {
      inputValue =
        keypoint.progress === ""
          ? ""
          : keypoint.progress === 0
            ? keypoint.end === 0
              ? 0
              : keypoint.start
            : keypoint.progress;
    }
    return inputValue;
  }
  function removeCharacter(text) {
    let value;
    if (text.indexOf("$") === 0) {
      value = text.replace(/\$/g, "");
    } else if (text.indexOf("%") !== -1) {
      value = text.replace(/%/g, "");
    } else if (text.indexOf("h") !== -1) {
      value = text.replace(/h/g, "");
    } else {
      value = text;
    }
    return value;
  }
  function setObj(event, value, obj, keypoint) {
    let isCompleted;
    if (keypoint.type === ":") {
      isCompleted = parseFloat(value) === keypoint.hours ? true : false;
    } else if (keypoint.type === "between") {
      isCompleted =
        parseFloat(value) === 0
          ? false
          : parseFloat(value) <= keypoint.end && parseFloat(value) >= keypoint.start
            ? true
            : false;
    } else if (keypoint.type === "up") {
      isCompleted = parseFloat(value) >= keypoint.end ? true : false;
    } else if (keypoint.type === "down") {
      isCompleted = parseFloat(value) === 0 ? true : parseFloat(value) <= keypoint.end ? true : false;
    } else {
      isCompleted = parseFloat(value) === keypoint.end ? true : false;
    }
    let val = "";
    if (keypoint.type === "%") {
      val = value.split(".")[1] === "" ? value : parseFloat(value);
    } else if (keypoint.type === "?") {
      val = value;
      isCompleted = value;
    } else {
      if (value.indexOf(".") !== -1) {
        val = value.split(".")[1] === "" ? value : parseFloat(value);
      } else {
        val = parseFloat(value);
      }
    }
    let progress =
      event.target.value === "h" ||
        event.target.value === "%" ||
        event.target.value === "$" ||
        event.target.value === "NaN" ||
        event.target.value === ""
        ? ""
        : val;
    let data = {
      userObjectiveId: obj.userObjectiveId,
      employeeId: loggedInUser?.employeeId,
      keyPoints: {
        keyId: keypoint.keyId,
        description: keypoint.description,
        type: keypoint.type,
        start: keypoint.start,
        end: keypoint.end,
        progress: progress,
        isCompleted: isCompleted,
        hours: keypoint.hours,
        weightage: keypoint.weightage,
        mark: keypoint.type === "?" ? val : null,
      },
    };
    dispatch(actions.SelfObjectiveManipulate(data));
  }

  let temp = objectives?.length > 0 && objectives?.map((value) => value.managerData);
  let managerList = [],
    lookupObject = {};
  for (var i in temp) {
    lookupObject[temp[i]["employeeId"]] = temp[i];
  }
  for (i in lookupObject) {
    managerList.push(lookupObject[i]);
  }
  managerList.forEach((obj) => {
    let arr = [];
    objectives.forEach((data) => {
      if (obj.employeeId === data.managerData.employeeId) {
        arr.push(data);
      }
    });
    obj.objectives = arr;
  });

  const toggleExpander = (id) => {
    expanded === id ? dispatch({ type: "EXPANDED", expanded: null }) : dispatch({ type: "EXPANDED", expanded: id });
  };

  // EMPLOYEE OBJECTIVE HTML
  const objectiveComponent = (manager) => {
    return manager.objectives
      .filter((value) => value.status !== "DRAFTED")
      .map((obj, index) => {
        //when user forgot to fill asessemnt before time line then user don't see comment box
        let isCommentBoxVisible = true;
        if (props.isSubmit && !obj.selfAssessmentComment?.comment && !obj.comment) {
          isCommentBoxVisible = false;
        }
        const innerHTML =
          obj.keyPoints.length > 0 ? (
            obj.keyPoints.map((keypoint) => {
              let error = "";
              if (keypoint.isCompleted) {
                error = "form-control";
              }
              if (keypoint.start < keypoint.end) {
                if (keypoint.progress <= keypoint.start || keypoint.progress >= keypoint.end) {
                  error = "form-control";
                } else if (keypoint.type === "up") {
                  if (keypoint.progress >= keypoint.end) {
                    error = "form-control";
                  }
                } else if (keypoint.type === "down") {
                  if (keypoint.progress >= keypoint.end || keypoint.progress === 0) {
                    error = "form-control";
                  }
                }
              } else if (keypoint.start > keypoint.end) {
                if (keypoint.progress >= keypoint.start || keypoint.progress <= keypoint.end) {
                  error = "form-control";
                } else if (keypoint.type === "up") {
                  if (keypoint.progress >= keypoint.end) {
                    error = "form-control";
                  }
                } else if (keypoint.type === "down") {
                  if (keypoint.progress >= keypoint.end || keypoint.progress === 0) {
                    error = "form-control";
                  }
                }
              } else {
                if (keypoint.type === ":") {
                  if (keypoint.progress === 0) {
                    error = "form-control";
                  }
                } else if (keypoint.type === "up") {
                  if (keypoint.progress >= keypoint.end) {
                    error = "form-control";
                  }
                } else if (keypoint.type === "down") {
                  if (keypoint.progress >= keypoint.end || keypoint.progress === 0) {
                    error = "form-control";
                  }
                }
              }
              return (
                <div className={style.employeeObjectives_info_block} key={keypoint.keyId}>
                  <div className={style.employeeObjectives_info_title}>
                    <div className="custom-checkbox custom-checkbox-rounded">
                      <input
                        id={keypoint.keyId + "KEYPOINT" + obj.userObjectiveId}
                        type="checkbox"
                        checked={keypoint.progress === keypoint.end ? true : keypoint.isCompleted}
                        disabled={obj.completionDate}
                        onChange={(e) => {
                          if (props.editable) {
                            return false;
                          }
                          let progress;
                          if (keypoint.type === ":") {
                            progress =
                              e.target.checked === false
                                ? 0
                                : !keypoint.isCompleted
                                  ? keypoint.hours
                                  : keypoint.progress;
                          } else if (keypoint.type === "between") {
                            progress =
                              e.target.checked === false
                                ? 0
                                : keypoint.progress === 0
                                  ? keypoint.end
                                  : e.target.checked
                                    ? keypoint.end
                                    : keypoint.progress;
                          } else {
                            progress =
                              e.target.checked === false
                                ? keypoint.start
                                : !keypoint.isCompleted
                                  ? keypoint.end
                                  : keypoint.progress;
                          }

                          let isCompleted;
                          isCompleted =
                            keypoint.type === ":"
                              ? keypoint.progress === keypoint.hours
                                ? e.target.checked === false
                                  ? false
                                  : true
                                : !keypoint.isCompleted
                              : keypoint.progress === keypoint.end
                                ? e.target.checked === false
                                  ? false
                                  : true
                                : !keypoint.isCompleted;

                          let data = {
                            userObjectiveId: obj.userObjectiveId,
                            employeeId: loggedInUser?.employeeId,
                            employeeData: obj.employeeData,
                            subGroupId: obj.subGroupId,
                            subGroupName: obj.subGroupName,
                            parentGroupId: obj.parentGroupId,
                            parentGroupName: obj.parentGroupName,
                            keyPoints: {
                              keyId: keypoint.keyId,
                              description: keypoint.description,
                              type: keypoint.type,
                              start: keypoint.start,
                              end: keypoint.end,
                              progress: progress,
                              isCompleted: isCompleted,
                              hours: keypoint.hours,
                              weightage: keypoint.weightage,
                              mark: keypoint.type === "?" ? isCompleted : null,
                            },
                          };
                          let elements = document.getElementsByClassName("form-control error");
                          elements.forEach((ele) => {
                            if (ele.getAttribute("id") === keypoint.keyId) {
                              ele.classList.remove("error");
                            }
                          });
                          selectRadio(data);
                        }}
                      />
                      <label htmlFor={keypoint.keyId + "KEYPOINT" + obj.userObjectiveId}>{keypoint.description}</label>
                    </div>
                    <div className={["d-flex", "employeeObjectives_info_action"].join(" ")}>
                      <Form.Group>
                        {keypoint.type === "?" ? (
                          <div className="cmp-in-comp-info">
                            Completed?
                            <div className="custom-checkbox custom-checkbox-sm">
                              <input
                                id={obj.userObjectiveId + "Checked" + keypoint.keyId}
                                type="checkbox"
                                disabled={obj.completionDate}
                                checked={keypoint.mark ? keypoint.mark : false}
                                label=""
                                onChange={(event) => {
                                  let data = {
                                    userObjectiveId: obj.userObjectiveId,
                                    employeeId: loggedInUser?.employeeId,
                                    employeeData: obj.employeeData,
                                    subGroupId: obj.subGroupId,
                                    subGroupName: obj.subGroupName,
                                    parentGroupId: obj.parentGroupId,
                                    parentGroupName: obj.parentGroupName,
                                    keyPoints: {
                                      keyId: keypoint.keyId,
                                      description: keypoint.description,
                                      type: keypoint.type,
                                      start: keypoint.start,
                                      end: keypoint.end,
                                      progress: null,
                                      isCompleted: event.target.checked,
                                      hours: keypoint.hours,
                                      weightage: keypoint.weightage,
                                      mark: keypoint.type === "?" ? event.target.checked : null,
                                    },
                                  };
                                  selectRadio(data);
                                }}
                              />
                              <label htmlFor={obj.userObjectiveId + "Checked" + keypoint.keyId}></label>
                            </div>
                          </div>
                        ) : (
                          <Form.Control
                            ref={targetUserName}
                            type="text"
                            id={keypoint.keyId}
                            objid={obj.userObjectiveId}
                            value={addCharacter(keypoint)}
                            disabled={obj.completionDate}
                            onFocus={(event) => {
                              if (event.currentTarget) {
                                event.currentTarget.removeAttribute("readonly");
                              }
                            }}
                            className={error}
                            onBlur={(event) => {
                              if (props.editable) {
                                return false;
                              }
                              let value = removeCharacter(event.target.value);
                              if (keypoint.type === ":") {
                                if (parseFloat(value) > keypoint.hours) {
                                  event.currentTarget.classList.add("error");
                                } else if (parseFloat(value) < 0) {
                                  event.currentTarget.classList.add("error");
                                } else {
                                  event.currentTarget.classList.remove("error");
                                }
                              } else if (keypoint.type === "up") {
                                if (parseFloat(value) < keypoint.start) {
                                  event.currentTarget.classList.add("error");
                                } else {
                                  event.currentTarget.classList.remove("error");
                                }
                              } else if (keypoint.type === "down") {
                                if (parseFloat(value) > keypoint.start) {
                                  event.currentTarget.classList.add("error");
                                } else {
                                  event.currentTarget.classList.remove("error");
                                }
                              } else if (keypoint.type === "between") {
                                if (keypoint.start < keypoint.end) {
                                  if (parseFloat(value) < keypoint.start || parseFloat(value) > keypoint.end) {
                                    event.currentTarget.classList.add("error");
                                  } else {
                                    event.currentTarget.classList.remove("error");
                                  }
                                } else {
                                  if (parseFloat(value) > keypoint.start || parseFloat(value) < keypoint.end) {
                                    event.currentTarget.classList.add("error");
                                  } else {
                                    event.currentTarget.classList.remove("error");
                                  }
                                }
                              } else {
                                if (keypoint.start < keypoint.end) {
                                  if (parseFloat(value) < keypoint.start) {
                                    event.currentTarget.classList.add("error");
                                  } else if (parseFloat(value) > keypoint.end) {
                                    event.currentTarget.classList.add("error");
                                  } else {
                                    event.currentTarget.classList.remove("error");
                                  }
                                } else {
                                  if (parseFloat(value) > keypoint.start) {
                                    event.currentTarget.classList.add("error");
                                  } else if (parseFloat(value) < keypoint.end) {
                                    event.currentTarget.classList.add("error");
                                  } else {
                                    event.currentTarget.classList.remove("error");
                                  }
                                }
                              }
                              let data = {
                                objId: event.currentTarget.getAttribute("objid"),
                                id: event.currentTarget.id,
                              };
                              handleTextInputKeypoint(data);
                              // setCurrentInputText(data);
                              if (event.currentTarget) {
                                event.currentTarget.setAttribute("readonly", "");
                              }
                            }}
                            onKeyPress={(event) => {
                              var charC = event.which ? event.which : event.keyCode;
                              if (charC === 46) {
                                if (event.currentTarget.value.indexOf(".") === -1) {
                                  return true;
                                } else {
                                  return false;
                                }
                              } else {
                                if (charC > 31 && (charC < 48 || charC > 57)) {
                                  return false;
                                } else {
                                  return true;
                                }
                              }
                            }}
                            onChange={(event) => {
                              if (props.editable) {
                                return false;
                              }
                              let value = removeCharacter(event.target.value);
                              if (keypoint.type === ":") {
                                if (parseFloat(value) > keypoint.hours) {
                                  event.currentTarget.classList.add("error");
                                } else if (parseFloat(value) < 0) {
                                  event.currentTarget.classList.add("error");
                                } else {
                                  event.currentTarget.classList.remove("error");
                                }
                              } else if (keypoint.type === "up") {
                                if (parseFloat(value) < keypoint.start) {
                                  event.currentTarget.classList.add("error");
                                } else {
                                  event.currentTarget.classList.remove("error");
                                }
                              } else if (keypoint.type === "down") {
                                if (parseFloat(value) > keypoint.start) {
                                  event.currentTarget.classList.add("error");
                                } else {
                                  event.currentTarget.classList.remove("error");
                                }
                              } else if (keypoint.type === "between") {
                                if (keypoint.start < keypoint.end) {
                                  if (parseFloat(value) < keypoint.start && parseFloat(value) > keypoint.end) {
                                    event.currentTarget.classList.add("error");
                                  } else {
                                    event.currentTarget.classList.remove("error");
                                  }
                                } else {
                                  if (parseFloat(value) > keypoint.start && parseFloat(value) < keypoint.end) {
                                    event.currentTarget.classList.add("error");
                                  } else {
                                    event.currentTarget.classList.remove("error");
                                  }
                                }
                              } else {
                                if (keypoint.start < keypoint.end) {
                                  if (parseFloat(value) < keypoint.start) {
                                    event.currentTarget.classList.add("error");
                                  } else if (parseFloat(value) > keypoint.end) {
                                    event.currentTarget.classList.add("error");
                                  } else {
                                    event.currentTarget.classList.remove("error");
                                  }
                                } else {
                                  if (parseFloat(value) > keypoint.start) {
                                    event.currentTarget.classList.add("error");
                                  } else if (parseFloat(value) < keypoint.end) {
                                    event.currentTarget.classList.add("error");
                                  } else {
                                    event.currentTarget.classList.remove("error");
                                  }
                                }
                              }

                              if (keypoint.type === "%") {
                                if (parseFloat(value) >= 0 || value === "") {
                                  if (value.length > 5) {
                                    event.currentTarget.classList.remove("error");
                                    return false;
                                  }
                                  setObj(event, value, obj, keypoint);
                                } else {
                                  event.preventDefault();
                                }
                              } else if (
                                keypoint.type === "up" ||
                                keypoint.type === "down" ||
                                keypoint.type === "between"
                              ) {
                                if (parseFloat(value) >= -1 || value === "") {
                                  if (value.length > 11) {
                                    event.currentTarget.classList.remove("error");
                                    return false;
                                  }
                                  setObj(event, value, obj, keypoint);
                                } else {
                                  event.preventDefault();
                                }
                              } else if (keypoint.type === "$") {
                                if (parseFloat(value) >= 0 || value === "") {
                                  if (value.length > 9) {
                                    event.currentTarget.classList.remove("error");
                                    return false;
                                  }
                                  setObj(event, value, obj, keypoint);
                                } else {
                                  event.preventDefault();
                                }
                              } else if (keypoint.type === ":") {
                                if (parseFloat(value) >= 0 || value === "") {
                                  if (value.length > 5) {
                                    event.currentTarget.classList.remove("error");
                                    return false;
                                  }
                                  setObj(event, value, obj, keypoint);
                                } else {
                                  event.preventDefault();
                                }
                              }
                            }}
                          ></Form.Control>
                        )}
                        <div className="info-attach-file">
                          <h5>{keyValueAchievedTooltip(keypoint)}</h5>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  {keypoint.weightage && (
                    <div className={[style.weightage_detail, "d-inline-block"].join(" ")}>
                      Weightage : {keypoint.weightage}
                    </div>
                  )}
                  {keypoint.stretch && (
                    <div className={[style.weightage_detail, "d-inline-block ml-3"].join(" ")}>
                      This is the stretch key result
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <h3>No data found.</h3>
          );
        return (
          <Card key={obj.userObjectiveId} className="overflow-visible">
            <Card.Header>
              <div className={style.employeeObjectives_process_indicate}>
                <ObjectiveUserStatus objective={obj} page={props.page} editable={props.editable} />
              </div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={`${index}`}
                onClick={(e) => {
                  setEventValue(index);
                  if (e.currentTarget.classList.contains("show")) {
                    e.currentTarget.classList.remove("show");
                  } else {
                    e.currentTarget.classList.add("show");
                  }
                }}
                className={[eventValue === index && "show", "indicator_manager_view"].join(" ")}
              >
                <div className="accordion-heading">
                  <div className="accordion-heading-left">
                    <div className={style.employeeObjectives_heading_wrapper}>
                      <h3>{obj.title}</h3>
                    </div>
                  </div>
                  <div className="accordion-heading-right position-relative">
                    <div className={style.employeeObjectives_process_complete}>{obj.percentage}%</div>
                    <div className={style.emp_obj_end_days}>{objectiveStatus(obj.status)}</div>
                  </div>
                </div>

                <div className="accordion-icon">
                  <ReactSVG src={IC_ACC_ARROW_DOWN} />
                </div>
              </Accordion.Toggle>
              <div className={style.key_initiative_wrapper}>
                <span>{obj.keyPoints.length} Key Results are aligned</span>
                <span
                  className={style.initiative_wrapper}
                  onClick={() => {
                    if (obj.plans) {
                      setIsModel(!isModal);
                      setInitiativeObject(obj);
                    }
                  }}
                >
                  {(obj.plans && obj.plans.length) || 0} Initiatives Founds
                </span>
              </div>
              <p className={style.time_frame_info}>
                <span>Timeframe : </span>
                {dateFormat1(obj.startDate) + " to " + dateFormat1(obj.endDate)}
              </p>
            </Card.Header>
            <Accordion.Collapse eventKey={`${index}`}>
              <Card.Body>
                {!props.isSubmit && <div className={style.employeeObjectives_info_list}>{innerHTML}</div>}
                {props.isSubmit && (
                  <Row>
                    <div className="col-xl-12">
                      <div className="form-group">
                        {obj.keyPoints &&
                          obj.keyPoints.map((keypoint) => <KeyResultPoint keypoint={keypoint} key={keypoint.keyId} />)}
                      </div>
                    </div>
                  </Row>
                )}
              </Card.Body>
            </Accordion.Collapse>
            {isCommentBoxVisible && (
              <SelfAssessmentComment
                uploadFileObjective={(data) => props.uploadFileObjective(data, obj.files, obj.userObjectiveId)}
                downloadFileObjective={(data) => props.downloadFileObjective(data, obj.userObjectiveId)}
                removeFileObjective={(data) => props.removeFileObjective(data, obj.userObjectiveId)}
                obj={obj}
                isSubmit={props.isSubmit}
                cycleId={cycleId}
                objectivePrivateAssessmentId={objectivePrivateAssessmentId}
                style={style}
                loggedInUser={loggedInUser}
                error={props.selfAssessmentError.find(
                  (value) => value.commentId === obj.userObjectiveId && value.isSubmit
                )}
                blurHandler={(e) => handleComment(e, obj)}
              />
            )}
          </Card>
        );
      });
  };

  //ObjectiveHTML complete
  return (
    <div>
      <div className={["box", style.employeeObjectives_box].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>Objective Tracking</h2>
            <h3>Objective status and progress helps tracking down things in easy way</h3>
          </div>
        </div>
        {objectives &&
          (objectives.length === 0 ? (
            <NoObjetivesFound />
          ) : (
            <div className={style.member_manager_wrapper}>
              {managerList.map((manager) => {
                return (
                  <div className={style.manager_list} key={manager.employeeId}>
                    <div className={style.topbar}>
                      <span className={style.collapse_img}>
                        <span>
                          <ReactSVG
                            src={expanded === manager.employeeId ? IC_COLLAPSE : IC_EXPAND}
                            onClick={() => {
                              toggleExpander(manager.employeeId);
                              setEventValue("");
                            }}
                          />
                        </span>
                      </span>
                      <div className={style.manager_info}>
                        <h4>
                          Objective Assigned By : <strong>{manager.empName}</strong>
                        </h4>
                      </div>
                    </div>
                    {expanded === manager.employeeId && (
                      <Accordion defaultActiveKey={""} className="custom-accordion">
                        {objectiveComponent(manager)}
                      </Accordion>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
      </div>
      {isModal && (
        <InitiativeModal
          empName={loggedInUser && loggedInUser.displayName}
          show={true}
          hide={() => setIsModel(!isModal)}
          plans={initiativeObject.plans}
          percentage={initiativeObject.percentage}
          status={initiativeObject.status}
          title={initiativeObject.title}
        />
      )}
    </div>
  );
}

export default SelfAssessmentObjective;
