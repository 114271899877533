import React, { useState, useRef } from "react"
import style from './Plans.module.scss'
import { Form, Tooltip, Overlay } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import {
  IC_ERROR,
  IC_LINK,
  IC_DUSTBIN_PLAN,
  IC_SEARCH_RESET
} from "../../const/imgCost";
import { useDispatch } from "react-redux";
import { SetPlan } from "../../store/actions";

const PlanBox = (props) => {
  const [myPlan, setMyPlan] = useState(props.plan.planDescription);
  const [handleObjective, setHandleObjective] = useState(false);
  const dispatch = useDispatch()
  const targetPlanBox = useRef(null);

  return (
    <div className={style.plan_textarea_container}>
    <Form.Group className={style.planWrapper}>
      <div className="textarea-wrapper ">
        {(props.length > 1) &&  
        !props.plan.objectiveDescription && 
        !props.plan.isCarryForward &&
        <div
          className="dustbin_icon"
          onClick={()=> props.deletePlan(props.plan.planId, props.index)}
        >
          <ReactSVG src={IC_DUSTBIN_PLAN} />
        </div>}
        {!handleObjective && !props.plan.objectiveDescription && <div className="link_icon"
        onClick={()=> setHandleObjective(true)}>
          <ReactSVG src={IC_LINK} />
        </div>}  
        <Form.Control
          disabled={props.plan.isCarryForward}
          as="textarea"
          maxLength="200"
          className={[myPlan === "" && "limit-reached",style.plan_textarea].join(" ")}
          value={myPlan}
          ref={targetPlanBox}
          onChange={(e)=>{
            setMyPlan(e.target.value)
            dispatch(SetPlan(e.target.value, "ONCHANGE"))
          }}
          onBlur={(e)=> props.editPlan(e.target.value, props.plan.planId, props.index)}
        />
      </div>
      {myPlan === "" && (
              <Overlay target={targetPlanBox} show={true} placement="bottom">
                {(props) => (
                  <Tooltip id="overlay-error-plan" {...props} >
                    <span className="error-icon">
                      <ReactSVG src={IC_ERROR} title="" alt="" />
                    </span>
                    <span className="error-bubble-txt">This field is required</span>
                  </Tooltip>
                )}
              </Overlay>
            )}
    </Form.Group>
    {props.plan.objectiveDescription && <ul className="list-of-objective">
      <li>
        {props.plan.objectiveDescription} 
        {!props.plan.isCarryForward && <span className="close-icon" onClick = {() => props.deleteObjective(props.plan.planId, props.plan.userObjectiveId)}><img src={IC_SEARCH_RESET} alt="Reset"/></span>}
      </li>
    </ul>}
    {handleObjective  && !props.plan.objectiveDescription && <div className={["box-inner-pad", "available-objective"].join(" ")}>
      <div className={["box-heading-wrapper", "box-light-gradient"].join(" ")}>
        <div className="box-heading">
            <h2>Available Objective to Link <span className="optional">(tap on objective to link with plan)</span></h2>
            <span className="close-obj" onClick={()=> setHandleObjective(false)}>Close</span>
        </div>
      </div>
      <ul className="list-of-objective">
        {(props.objectives 
        && props.objectives.filter(value => value.status !== "DRAFTED").filter(value => new Date(value.endDate) >= new Date()).length > 0) 
        ? props.objectives.filter(value => value.status !== "DRAFTED").filter(value => new Date(value.endDate) >= new Date()).map((value, i) => (
          <li key={i} onClick = {() => props.linkObjective(props.plan.planId, value.objectiveId, value.title)}><span className="text-break">{value.title}</span></li>
        )) : <li className="no-objective">Objective not assigned</li>}
      </ul>
    </div>}
  </div>
  )
}
export default PlanBox;