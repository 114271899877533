import React from "react";
import { Col, Row } from "react-bootstrap";
import PieChartCard from "./PieChartCard/PieChartCard";
import style from "./CheckInDetailsCharts.module.scss";

export default function DetailsChartView({
    isEmployee,
    feedbackGraphData,
    exportQuestionWise,
}) {
    return (
        <React.Fragment>
            <div className={`box ${style.box_section_container}`}>
                <div className="box-heading-wrapper">
                    <div className={`box-heading ${style.inner_header}`}>
                        <h2>{isEmployee ? "Employee" : "Manager"}</h2>
                        <h6>
                            <span className={style.greyText}>
                                Graphical analysis as per questions
                            </span>
                        </h6>
                    </div>
                </div>
                <div className="box-light-gradient"></div>
                <div className="box-inner-pad no-border">
                    <Row>
                        <Col md="12" lg="12" sm="12">
                            <h4 className="semi-bold">Total percentage of {isEmployee ? "Employee" : "Manager"} who have submitted their Check-in and who are left to</h4>
                            <ul className={style.statusList}>
                                <li><span className={`circle ${style.green}`}></span>Submitted<div className={style.percentage}>{isEmployee ? feedbackGraphData?.totalEmployeeSubmittedPercentage?.toFixed(2) : feedbackGraphData?.totalManangerSubmittedPercentage?.toFixed(2)}%</div></li>
                                <li><span className={`circle ${style.red}`}></span>Pending<div className={style.percentage}>{isEmployee ? feedbackGraphData?.totalEmployeePendingPercentage?.toFixed(2) : feedbackGraphData?.totalManagerPendingPercentage?.toFixed(2)}%</div></li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row>
                {isEmployee ?
                    feedbackGraphData?.employeeQuestionsDetails?.length > 0 ?
                        feedbackGraphData?.employeeQuestionsDetails?.map((item, index) => {
                            return (
                                <Col sm="12" md="6" lg="6" key={index}>
                                    <PieChartCard
                                        index={index + 1}
                                        questions={item}
                                        isEmployee={isEmployee}
                                        exportQuestionWise={exportQuestionWise}
                                    />
                                </Col>
                            );
                        }) : <div className="no-record-found-wrpr mt-3">No Data Available</div> :
                    feedbackGraphData?.managerQuestionsDetails?.length > 0 ?
                        feedbackGraphData?.managerQuestionsDetails?.map((item, index) => {
                            return (
                                <Col sm="12" md="6" lg="6" key={index}>
                                    <PieChartCard
                                        index={index + 1}
                                        questions={item}
                                        isEmployee={isEmployee}
                                        exportQuestionWise={exportQuestionWise}
                                    />
                                </Col>
                            );
                        }) : <div className="no-record-found-wrpr mt-3">No Data Available</div>
                }
            </Row>
        </React.Fragment>
    );
}
