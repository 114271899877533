import React from "react";
import PropTypes from "prop-types";
import style from "../CycleMemberProfile.module.scss";
import { Form } from "react-bootstrap";
import OptionalComment from "../QuestionAnswer/OptionalComment";

function RankQuestion({
  isSubmitted,
  item,
  RankHandlerTemplate,
  RankCommentHandlerTemplate,
  errors,
  downloadFile,
  deleteFile,
  uploadFile,
  isSurvey,
}) {
  let error = errors?.find((v) => v.qId === item.questionId && v.error && v.isSubmit);
  let commentError = errors?.find((v) => v.qId === item.questionId && v.commentBoxError && v.isSubmit);
  return (
    <>
      <div className="radio-wrapper">
        <Form.Group className={[style.cb_pv_wrapper].join(" ")} controlId="">
          {item.options &&
            item.options.map((optionData) => (
              <div key={optionData.optionId} className={style.rank_value_wrapper}>
                <input
                  disabled={isSubmitted}
                  id={optionData.optionId}
                  className={error && !optionData.optionRating ? "limit-reached" : undefined}
                  type="number"
                  min="0"
                  max="100"
                  defaultValue={optionData.optionRating}
                  onChange={(e) => RankHandlerTemplate(e, item, optionData)}
                />
                <span className={style.rank_value}>{optionData.option}</span>
              </div>
            ))}
          {item.optionalCommentBox && (
            <OptionalComment
              item={item}
              uploadFile={(e) => uploadFile(e, item.questionId)}
              commentError={commentError ? true : false}
              handleComment={(e) => RankCommentHandlerTemplate(e, item)}
              isSubmitted={isSubmitted}
              downloadFile={downloadFile}
              commentPlaceHolder={
                item.commentResponse === "Required" ? "Add a new answer (Required)" : "Add a new answer (Optional)"
              }
              deleteFile={deleteFile}
              isSurvey={isSurvey}
            />
          )}
        </Form.Group>
      </div>
    </>
  );
}

RankQuestion.propTypes = {
  item: PropTypes.object,
  RankCommentHandlerTemplate: PropTypes.func,
};

export default RankQuestion;
