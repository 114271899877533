import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import { backGroundColor } from "const/constValue";
import { monthOptions } from "const/options";
import { IC_SMILE } from "const/imgCost";
import FilteredMadeMyDay from "./FilteredMadeMyDay";
import HideAndShow from "../../HideAndShow/HideAndShow";

const options1 = [
  { value: "Received", label: "Received" },
  { value: "Sent", label: "Sent" },
];

export default function YouMadeMyDayActivity(props) {
  const [showScroll, setShowScroll] = useState(false);
  const showScrollClass = showScroll ? "show-scroll" : "";
  const [sendReceive, setSendReceive] = useState(false);
  const [hide, setHide] = useState(false);

  const sentCount = props.history?.filter((item) => item.send).length;
  const receiveCount = props.history?.filter((item) => !item.send).length;
  const currentCount = props.history?.filter((item) => item.send === sendReceive).length;

  let message = null;
  if (sentCount || receiveCount) {
    if (!sentCount) message = "You have not sent any made my day";
    else if (!receiveCount) message = "You have not received any made my day";
  } else {
    sendReceive ? (message = "You have not sent any made my day") : (message = "You have not received any made my day");
  }

  return (
    <div className="card recentlyReceived" id="hideYouMadeMyDay">
      <div className="card_info">
        <div className="card_title">
          <div className="d-flex">
            <ReactSVG src={`${IC_SMILE}`} className="icon-svg" />
            <div className="card_title_text">You Made My Day Activities</div>
          </div>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>
      <Collapse in={!hide}>
        <div className="card_details p20">
          <div className="box-light-gradient"></div>
          <div className="made-my-d-h-info">
            <div className="mmd-info-title">
              <div className="card_action">
                <Select
                  onChange={() => setSendReceive(!sendReceive)}
                  options={options1}
                  className="select_dropdown select_dropdown_text"
                  classNamePrefix="select"
                  isSearchable={false}
                  defaultValue={{
                    value: "Received",
                    label: "Received",
                  }}
                />
              </div>
              <div className="card_action">
                <Select
                  onChange={(select) => props.handleAppreciateFilter(select)}
                  options={monthOptions}
                  className="select_dropdown select_dropdown_text"
                  classNamePrefix="select"
                  isSearchable={false}
                  defaultValue={{
                    value: 6,
                    label: "Last 6 Months",
                  }}
                />
              </div>
            </div>
            <div className="mmd-info-profile">
              <div className="mmd-profile-detail">
                <div className="user_img" style={{ backgroundColor: backGroundColor[1] }}>
                  {props.user && props.user.dispArr ? (
                    <img src={props.user.dispArr} alt="" />
                  ) : (
                    props.user && props.user.employeeInitial
                  )}
                </div>
                <div className="user_info">
                  <span className="user_name">{props.user && props.user.displayName}</span>
                  <span className="user_designation">{props.user && props.user.designation}</span>
                </div>
              </div>
              <div className="send-received-detail">
                <h5 className="sr-info">
                  <span>Sent</span>
                  {sentCount}
                </h5>
                <h5 className="sr-info">
                  <span>Received</span>
                  {receiveCount}
                </h5>
              </div>
            </div>
          </div>
          <div className={["made-my-d-h-list", showScrollClass].join(" ")}>
            {props.history &&
              (props.history.filter((item) => item.send === sendReceive).length > 0 ? (
                props.history
                  .filter((item) => item.send === sendReceive)
                  .filter((item, index) => showScroll || index < 4)
                  .map((item, index) => <FilteredMadeMyDay index={index} item={item} key={index} />)
              ) : (
                <div className="box-light-gradient h38">
                  <p className="no-sent-receive-wrapper mb-2">{message}</p>
                </div>
              ))}
          </div>
          {currentCount > 4 ? (
            <span className="viewall mb-2" onClick={() => setShowScroll(!showScroll)}>
              {showScroll
                ? "Show Less"
                : sendReceive
                ? `Show More(${sentCount - 4})`
                : `Show More(${receiveCount - 4})`}
            </span>
          ) : (
            ""
          )}
        </div>
      </Collapse>
    </div>
  );
}
