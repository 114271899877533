import { noData } from "const/constValue";
import React from "react";
import { Table } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IC_INFO } from "../../../../const/imgCost";
import { gradeValue } from "../../../../util/general";
import EmployeeList from "../../../EmployeeListPopup/EmployeeList";
import NoDataFound from "../../../NodataFound/NoDataFound";
import style from "./AssessmentEmployeeView.module.scss";

function EmployeeViewTable({ filteredViewComparison, current, previous, handleLineChart }) {
  return (
    <div className={style.data_container}>
      {filteredViewComparison && filteredViewComparison.length > 0 ? (
        <div>
          <Table>
            <thead>
              <tr>
                <td className={style.name}>Name</td>
                <td className={style.count}>
                  {previous?.label}
                  <div className={style.show_cycleName_tooltip}>
                    <div className={style.info_icon}>
                      <ReactSVG src={IC_INFO} title="" alt="info" />
                    </div>
                    <div className={style.show_cycleName_tooltip_info}>
                      {previous?.cycleName}
                      <span>{previous?.period}</span>
                    </div>
                  </div>
                </td>
                <td className={style.count}>
                  {current?.label}
                  <div className={style.show_cycleName_tooltip}>
                    <div className={style.info_icon}>
                      <ReactSVG src={IC_INFO} title="" alt="info" />
                    </div>
                    <div className={style.show_cycleName_tooltip_info}>
                      {current?.cycleName}
                      <span>{current?.period}</span>
                    </div>
                  </div>
                </td>
                <td className={style.location}>Location</td>
              </tr>
            </thead>
            <tbody>
              {filteredViewComparison.map((value, index) => {
                let graphData = [];
                if (current?.label === "Current Grade") {
                  if (value.gradeHistory?.length > 0) {
                    graphData = value.gradeHistory.map((v) => {
                      return {
                        ...v,
                        grade: v.grade || "0",
                        GradeRating: gradeValue(v.grade),
                      };
                    });
                  }
                } else {
                  if (value.gradeHistory?.length > 0) {
                    graphData = value.gradeHistory.map((v) => {
                      return {
                        ...v,
                        rating: v.rating || "0",
                      };
                    });
                  }
                }
                return (
                  <EmployeeList
                    handleLineChart={handleLineChart}
                    gradeHistory={graphData?.reverse()}
                    employee={value.employee}
                    current={
                      current?.label === "Current Grade"
                        ? value.current?.key
                          ? value.current?.key +
                            (value.current.value?.toFixed(2) ? ` (${value.current.value?.toFixed(2)})` : "")
                          : "-"
                        : value.current?.value?.toFixed(2) || "-"
                    }
                    previous={
                      previous?.label === "Previous Grade"
                        ? value.previous?.key
                          ? value.previous?.key +
                            (value.previous.value?.toFixed(2) ? ` (${value.previous.value?.toFixed(2)})` : "")
                          : "-"
                        : value.previous?.value?.toFixed(2) || "-"
                    }
                    index={index}
                    key={index}
                    secondColum={true}
                    graphYAxis={"rating"}
                    graphLineValue={current?.label === "Current Grade" ? "GradeRating" : "rating"}
                    graphXAxis={"period"}
                    graphType={current?.label === "Current Grade" ? "Grade" : "number"}
                  />
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <>
          <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
          <div className="gap80"></div>
          <div className="gap40"></div>
        </>
      )}
    </div>
  );
}

export default EmployeeViewTable;
