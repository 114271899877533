import React from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";

const ModalsPopup = (props) => {
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} animation={false} className="custom_modal_popup">
        {/* <Modal.Header closeButton>
            <Modal.Title> sds</Modal.Title>
          </Modal.Header> */}
        <div className="custom_modal_container">
          <Row>
            <Col md={12}>
              <Modal.Body>
                <h1>
                  Sure you want to move this {props.fromType ? ` ${props.fromType.toLowerCase()} into ` : " to "}{" "}
                  {props.toType && props.toType.toLowerCase()}?
                </h1>
                <p>We’ll notify employee about this change, if you are sure, tap the submit button else cancel.</p>
              </Modal.Body>
            </Col>
            <Col className="custom_modal_button">
              <Button variant="primary" onClick={() => props.submit()}>
                Submit
              </Button>

              <Button variant="secondary" className="btn-border" onClick={() => props.hide()}>
                Cancel
              </Button>
            </Col>
            {/* <Modal.Footer></Modal.Footer> */}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ModalsPopup;
