import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import style from "./CycleListManagerEmp.module.scss";
import { IC_EXPAND, IC_COLLAPSE } from "const/imgCost";
import ReporteeCycleListRow from "./ReporteeCycleListRow";
import { getColorByIndex } from "util/color";

function ManagerCycleListRow({
  data,
  toggleCheckboxChange,
  cycleID,
  toggleHandleReportee,
  isPage,
  backgroundColor,
  isSelfAssessmentAvailable,
}) {
  const dispatch = useDispatch();
  const { expanded } = useSelector((state) => state.myTeam);

  const toggleExpander = (empId) => {
    expanded === empId
      ? dispatch({ type: "EXPANDED", expanded: null })
      : dispatch({ type: "EXPANDED", expanded: empId });
    dispatch({ type: "CHANGE_PATTERN", activePattern: null });
  };

  return (
    <>
      <tr className={style.manager_row}>
        <td className={style.cb_box}>
          <div className="custom-checkbox custom-checkbox-sm no_mar">
            <input
              id={data.managerId}
              type="checkbox"
              value={data.managerId}
              onChange={(e) => toggleCheckboxChange(e)}
              checked={data.isChecked}
            />
            <label htmlFor={data.managerId}></label>
          </div>
        </td>
        <td className={style.emp_id}>{data.managerId}</td>
        <td className={style.emp_name}>
          <span className={style.team_toggle} onClick={() => toggleExpander(data.managerId)}>
            {data.employees && data.employees.length > 0 ? (
              expanded === data.managerId ? (
                <img src={IC_COLLAPSE} alt="" className={style.minus} />
              ) : (
                <img src={IC_EXPAND} alt="" className={style.plus} />
              )
            ) : null}
          </span>
          <div className={style.tab_team_member_info}>
            <div className={style.team_member_info}>
              <span className={style.team_member_img} style={{ backgroundColor }}>
                {data.managerData.dispArr && data.managerData.dispArr.length > 2 ? (
                  <img src={data.managerData.dispArr} alt="" />
                ) : (
                  data.managerData.dispArr || data.managerData.empInitial
                )}
              </span>

              <div className={style.team_member_details}>
                <span className={style.team_member_name}>{data.managerData.empName}</span>

                <span className={style.team_member_designation}>
                  {data.managerData.department} | {data.managerData.designation}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td className={style.private_assessment}></td>
        {isSelfAssessmentAvailable && (
          <>
            <td className={style.private_assessment}></td>
            <td className={style.private_assessment}></td>
          </>
        )}
        <td className={style.rating}></td>
        <td className={style.grades}></td>
        <td className={style.submitted_on}></td>
      </tr>
      {expanded === data.managerId &&
        data.employees?.length > 0 &&
        data.employees.map((value, index) => (
          <ReporteeCycleListRow
            backgroundColor={getColorByIndex(index)}
            data={value}
            key={value.employeeData.employeeId}
            toggleHandleReportee={toggleHandleReportee}
            managerId={data.managerId}
            cycleID={cycleID}
            isPage={isPage}
            isSelfAssessmentAvailable={isSelfAssessmentAvailable}
          />
        ))}
    </>
  );
}

ManagerCycleListRow.propTypes = {
  data: PropTypes.object,
  isPage: PropTypes.string,
  cycleID: PropTypes.string,
};

export default ManagerCycleListRow;
