import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import moment from "moment";
import style from "./MyCertificate.module.scss";
import * as actions from "store/actions/index";
import { IC_DOWNLOAD_1, IC_DELETE, IC_INFO } from "const/imgCost";
import { errorToast, filterArray, successToast } from "util/general";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import CertificateDeleteModal from "./CertificateDeleteModal/CertificateDeleteModal";
import Pagination from "../Pagination/Pagination";
import NoDataFound from "../NodataFound/NoDataFound";
import { noData } from "const/constValue";

export default function MyCertificate() {
  //for modal closing and opening
  const loggedInUser = storage.getUser();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { certificateList, downloadFileResponse, isLoading, deleteFileResponse, size } = useSelector(
    (state) => state.university
  );
  const [submittedCertificate, setCertificate] = useState([]);
  const [certificateId, setId] = useState(null);
  const [pageItem, setPageItem] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);

  // call getCertificate API
  useEffect(() => {
    const submittedCertificate = () => {
      dispatch(actions.getSubmittedCertificate);
    };
    submittedCertificate();
  }, [dispatch]);

  useEffect(() => {
    if (deleteFileResponse) {
      if (!deleteFileResponse.error) successToast(deleteFileResponse.message);
      else errorToast(deleteFileResponse.message);
    }

    if (downloadFileResponse) {
      if (downloadFileResponse.error) errorToast(downloadFileResponse.message);
      else successToast(downloadFileResponse.message);
    }
  }, [deleteFileResponse, downloadFileResponse]);

  const onHide = () => {
    setShow(false);
  };

  const submitHandler = () => {
    setShow(false);
    const params = {
      id: certificateId,
    };
    dispatch(actions.deleteCertificate(params));
    return;
  };

  const showPopup = (id) => {
    handleShow();
    setId(id);
  };

  const downloadCertificate = (id) => {
    const params = {
      employeeId: loggedInUser?.employeeId,
      subGroupId: loggedInUser?.subGroupId,
      parentGroupId: loggedInUser?.parentGroupId,
    };
    dispatch(actions.downloadCertificate(params, id));
  };

  const onChangeCertificatePage = (item) => {
    setPageItem(item);
  };

  useEffect(() => {
    if (certificateList && certificateList.length > 0) {
      setFilteredData([]);
      setCertificate(certificateList);
    } else {
      setCertificate([]);
      setPageItem([]);
    }
  }, [certificateList]);

  const resetData = () => {
    let filteredData = null;
    const filters = {
      description: searchFilter ? searchFilter : "",
    };
    if (certificateList && certificateList.length > 0) {
      filteredData = filterArray(certificateList, filters);
      setFilteredData(filteredData);
      setSearchFilter("");
    }
  };

  useEffect(() => {
    filteredData.length > 0
      ? setCertificate(filteredData)
      : searchFilter
      ? setCertificate(filteredData)
      : setCertificate(certificateList);
  }, [certificateList, filteredData, searchFilter]);

  return (
    <div>
      <div className={["box", style.idp_box].join(" ")}>
        {isLoading && <Spinner />}
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>My Certifications</h2>
            <h3>It helps you expand knowledge and skills, we recommend all Apexon Associates for certifications</h3>
          </div>
        </div>
        <div className="box-light-gradient h38"></div>
        <div className="box-inner-pad position-relative pt-0 pb-0">
          <div className={[style.my_certificate_table, "custom-table", "table-theme-1"].join(" ")}>
            <table className="table">
              <thead>
                {pageItem && pageItem.length !== 0 && (
                  <tr>
                    <th>Name</th>
                    <th>Validity</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {pageItem &&
                  pageItem.length !== 0 &&
                  pageItem.map((certificate, index) => {
                    var formatted_start, formatted_end;
                    var diffDays;

                    if (certificate.startDate && certificate.endDate) {
                      formatted_start = moment(certificate.startDate?.split(".")[0]).format("MMM D, YYYY");

                      formatted_end = moment(certificate.endDate?.split(".")[0]).format("MMM D, YYYY");
                      const today = moment(new Date()).format("MMM D, YYYY");

                      const b = moment(formatted_end);
                      diffDays = b.diff(moment(today), "days");

                      let diffDuration = 0;

                      if (diffDays > 0) {
                        diffDuration = moment.duration(b.diff(today));
                      } else {
                        diffDuration = moment.duration(moment(new Date()).diff(certificate.endDate));
                      }

                      let years = diffDuration.years();
                      let months = diffDuration.months();
                      let days = diffDuration.days();
                      var duration = null;
                      if (years > 0) {
                        duration = years + " Year(s) " + months + " Month(s) " + days + " ";
                      } else if (months > 0) {
                        duration = months + " Month(s) " + days + " ";
                      } else if (days >= 0) {
                        duration = days + " ";
                      }
                    }

                    return (
                      <tr key={index}>
                        <td>{certificate.name}</td>

                        {certificate.endDate && certificate.startDate ? (
                          <td>
                            {formatted_start} - {formatted_end}
                            <div
                              className={[style.certificate_info_tooltip, "custom-tooltip custom-tooltip-top"].join(
                                " "
                              )}
                            >
                              <div className="box-info">
                                <ReactSVG src={IC_INFO} title="" alt="info" />
                              </div>
                              {diffDays > 0 || diffDays === 0 ? (
                                <div className="info-attach-file">
                                  <h5>Expires in</h5>
                                  <span className="expired-days">{duration} Day(s)</span>
                                </div>
                              ) : (
                                <div className="info-attach-file">
                                  <h5>Expired Before</h5>
                                  <span className="expired-days">{duration} Day(s)</span>
                                </div>
                              )}
                            </div>
                          </td>
                        ) : (
                          <td>
                            {certificate.startDate
                              ? `${moment(certificate.startDate).format("MMM D, YYYY")}${" - "}${"Lifetime"}`
                              : "Lifetime"}
                          </td>
                        )}

                        <td>
                          {certificate.status === "Valid" ? (
                            <span className={style.valid}>Valid</span>
                          ) : (
                            <span className={style.expired}>Expired</span>
                          )}
                        </td>

                        <td>
                          <span className="fill-svg-icon-h" onClick={() => downloadCertificate(certificate.id)}>
                            <ReactSVG src={IC_DOWNLOAD_1} />
                          </span>
                          <span className="fill-svg-icon-h">
                            <ReactSVG src={IC_DELETE} onClick={() => showPopup(certificate.id)} />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {pageItem.length === 0 && (
              <div>
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap120"></div>
              </div>
            )}
          </div>
        </div>
        {show && (
          <CertificateDeleteModal
            show={show}
            hide={onHide}
            title={"Sure you want to delete this certificate?"}
            description={"Once deleted, you won't be able to undo it."}
            accept_button_name={"Delete"}
            reject_button_name={"Cancel"}
            submit={submitHandler}
          />
        )}
      </div>
      {certificateList && certificateList.length !== 0 && (
        <Pagination
          pageSize={size}
          items={submittedCertificate}
          onChangePage={onChangeCertificatePage}
          resetData={resetData}
          customPage={true}
        />
      )}
    </div>
  );
}
