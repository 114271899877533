import React from "react";
import { Button, Form } from "react-bootstrap";
import { backGroundColor } from "../../const/constValue";
import Comment from "../CommentBox/Comment";
import style from "./Acknowledgement.module.scss";

function Acknowledgement({
  setCheck,
  check,
  handleSubmit,
  setComment,
  comment,
  isSubmitted,
  image,
  empName,
  commentDate,
}) {
  return (
    <div className={[style.acknowledgement_wrapper, "box"].join(" ")}>
      <div className={style.title}>Employee Acknowledgement</div>
      {isSubmitted ? (
        <div className={["view_comment_box_wrapper", style.comment_box].join(" ")}>
          <Comment
            selectedColor={backGroundColor[1]}
            image={image}
            placeholder="Add a comment"
            empName={empName}
            commentedOn={commentDate}
            comment={comment}
          />
        </div>
      ) : (
        <div className={style.txt_box_wrapper}>
          <Form.Group controlId="">
            <div className="textarea-wrapper mt-3">
              <Form.Control
                as="textarea"
                maxLength="500"
                placeholder="Add Comment"
                onChange={setComment}
                value={comment}
              />
            </div>
          </Form.Group>
        </div>
      )}
      <div className="custom-checkbox custom-checkbox-sm no_mar">
        <input id={"CHECKED"} type="checkbox" checked={check} onChange={setCheck} disabled={isSubmitted} />
        <label htmlFor={"CHECKED"}>I here by acknowledge the process and discussion.</label>
      </div>
      {!isSubmitted && (
        <Button type="button" variant={"primary"} className={["btn-round", style.btn].join(" ")} onClick={handleSubmit}>
          <strong>Submit</strong>
        </Button>
      )}
    </div>
  );
}

export default Acknowledgement;
