export const setError = (questionsAnswerTemplate) => {
  let errors = [];
  if (questionsAnswerTemplate) {
    if (questionsAnswerTemplate.questions) {
      questionsAnswerTemplate.questions.map((value) => {
        if (value.isRequired || value.commentResponse === "Required") {
          let error = null;
          switch (value.type) {
            case "CHECKBOX":
              if (
                (value.options.filter((v) => v.isSelected).length <= 0 && !value.otherTextAnswer) ||
                value.commentResponse === "Required"
              ) {
                error = {
                  qId: value.questionId,
                  error:
                    value.isRequired && value.options.filter((v) => v.isSelected).length <= 0 && !value.otherTextAnswer
                      ? true
                      : false,
                  commentBoxError: value.commentResponse === "Required" && !value.answer ? true : false,
                  isSubmit: false,
                };
                errors.push(error);
              }
              return errors;
            case "RADIOBUTTON":
              if (
                (value.options.filter((v) => v.isSelected).length <= 0 && !value.otherTextAnswer) ||
                value.commentResponse === "Required"
              ) {
                error = {
                  qId: value.questionId,
                  error:
                    value.isRequired && value.options.filter((v) => v.isSelected).length <= 0 && !value.otherTextAnswer
                      ? true
                      : false,
                  commentBoxError: value.commentResponse === "Required" && !value.answer ? true : false,
                  isSubmit: false,
                };
                errors.push(error);
              }
              return errors;
            case "TEXTBOX":
              if (!value.answer || value.answer.length === 0) {
                error = {
                  qId: value.questionId,
                  error: true,
                  isSubmit: false,
                };
                errors.push(error);
              }
              return errors;
            case "DROPDOWN":
              if (
                (value.options.filter((v) => v.isSelected).length <= 0 && !value.otherTextAnswer) ||
                value.commentResponse === "Required"
              ) {
                error = {
                  qId: value.questionId,
                  error:
                    value.isRequired && value.options.filter((v) => v.isSelected).length <= 0 && !value.otherTextAnswer
                      ? true
                      : false,
                  commentBoxError: value.commentResponse === "Required" && !value.answer ? true : false,
                  isSubmit: false,
                };
                errors.push(error);
              }
              return errors;
            case "SLIDER":
              if (value.commentResponse === "Required") {
                error = {
                  qId: value.questionId,
                  commentBoxError: value.commentResponse === "Required" && !value.answer ? true : false,
                  error: false,
                  isSubmit: false,
                };
                errors.push(error);
              }
              return errors;
            case "NPS":
              if (value.commentResponse === "Required") {
                error = {
                  qId: value.questionId,
                  commentBoxError: false,
                  // value.commentResponse === "Required" && !value.answer
                  //   ? true
                  //   : false,
                  error: false,
                  isSubmit: false,
                };
                errors.push(error);
              }
              return errors;
            case "SCALED":
              if (!value.scaleValue || value.commentResponse === "Required") {
                error = {
                  qId: value.questionId,
                  commentBoxError: value.commentResponse === "Required" && !value.answer ? true : false,
                  error: value.isRequired && !value.scaleValue && value.scaleObj ? true : false,
                  isSubmit: false,
                };
                errors.push(error);
              }
              return errors;
            case "RANK":
              if (value.options.filter((v) => !v.optionRating).length > 0 || value.commentResponse === "Required") {
                error = {
                  qId: value.questionId,
                  commentBoxError: value.commentResponse === "Required" && !value.answer ? true : false,
                  error: value.isRequired && value.options.filter((v) => !v.optionRating).length > 0 ? true : false,
                  isSubmit: false,
                };
                errors.push(error);
              }
              return errors;
            default:
              return null;
          }
        }
        return value;
      });
    }
  }
  return errors;
};
