import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import style from "../../AssignObjectives/AssignObjectives.module.scss";
import { dateFormat1 } from "util/dateUtils";

export default function ObjectiveHeading(props) {
  const { createdDate, hours, title, minutes, startDate, endDate } = props.objective;
  return (
    <Col className="col-12">
      <div className={["custom-tabs custom-tabs-full-width tab-content-dashboard", style.allobjectives_tabs].join(" ")}>
        <div className="box ">
          <div className="box-heading-wrapper">
            <div className={["box-heading", "text-break"].join(" ")}>
              <h2>{title}</h2>
            </div>
          </div>
          <div className="box-inner-pad pb-0">
            <div className="form-group">
              <Row>
                <Col xl={2} md={3} className={style.assign_objective_duration}>
                  <span>Created On</span>
                  {createdDate && dateFormat1(createdDate)}
                </Col>
                <Col xl={2} md={3} className={style.assign_objective_duration}>
                  <span>Duration</span>
                  {hours} Hours {minutes} Mins
                </Col>
                {startDate && endDate && (
                  <Col xl={3} md={6} lg={4} className={style.assign_objective_duration}>
                    <span>Timeframe</span>
                    {dateFormat1(startDate) + " to " + dateFormat1(endDate)}
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

ObjectiveHeading.propTypes = {
  objective: PropTypes.shape({
    description: PropTypes.string,
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
};

ObjectiveHeading.defaultProps = {
  objective: {
    createdDate: "",
    startDate: "",
    endDate: "",
    description: "",
    hours: 0,
    minutes: 0,
  },
};
