import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  pendingObjectiveApproval: null,
  pendingEmployeeDeallocation: null,
  unassignedObjectiveEmployees: [],
  previousOneOnOnes: null,
  pendingOneOnOnes: null,
  upcomingOneOnOnes: null,
  missedOneOnOnes: null,
  pendingReportingAssessment: null,
  pendingReviewingAssessment: null,
  multiExpanded: [],
  pendingCheckinReview: [],
  checkinPendingFromEmployees: [],
  currentAndPreviousCheckin: null,
  pendingRpmAssessment: null,
  pendingSelfAssessment: null,
  pendingRvmAssessment: null,
  pendingDeallocationFeeedBack: null,
  pendingWeeklyCheckin: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PENDING_ONE_ON_ONE:
      return {
        ...state,
        pendingOneOnOnes: action.pendingOneOnOnes,
        previousOneOnOnes: action.previousOneOnOnes,
        upcomingOneOnOnes: action.upcomingOneOnOnes,
        missedOneOnOnes: action.missedOneOnOnes,
      };
    case actionTypes.SET_CURRENT_AND_PREVIOUS_CHECKIN:
      return {
        ...state,
        currentAndPreviousCheckin: action.currentAndPreviousCheckin,
      };
    case actionTypes.SET_TEAMS_CHECKIN:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_MANAGER_PENDING:
      return { ...state, ...action.payload };
    case actionTypes.SET_EMPLOYEE_PENDING:
      return { ...state, pendingWeeklyCheckin: action.pendingWeeklyCheckin };
    case actionTypes.SET_UNASSIGNED_OBJECTIVES:
      return { ...state, unassignedObjectiveEmployees: action.unassignedObjectiveEmployees };
    case actionTypes.SET_PENDING_OBJECTIVE_APPROVAL:
      return { ...state, pendingObjectiveApproval: action.pendingObjectiveApproval };
    case "MULTI_EXPAND":
      return { ...state, multiExpanded: [...state.multiExpanded, action.payload] };
    case "REMOVE_MULTI_EXPAND":
      return { ...state, multiExpanded: state.multiExpanded.filter((value) => action.payload !== value) };
    default:
      return state;
  }
};

export default reducer;
