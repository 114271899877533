import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import style from "../Objectives/AssignObjectives/AssignObjectives.module.scss";
import { IC_NOTIFY } from "const/imgCost";
import CommentsTop from "../CommentBox/CommentsTop";

function CommentSubmit(props) {
  const {
    displayPicture,
    textHeading,
    commentBoxHeadingText,
    submitComment,
    cancelHandler,
    placeholder,
    textLength,
    submitDisabled,
  } = props;
  const [comment, setComment] = useState("");
  return (
    <div className={style.mng_obj_notification}>
      <span className={style.notify_icon}>
        <ReactSVG src={`${IC_NOTIFY}`} />
      </span>
      <p>{textHeading}</p>
      <div className={[style.reject_comment_wrapper, "view_comment_box_wrapper"].join(" ")}>
        <CommentsTop heading={commentBoxHeadingText} />
        <div className={[style.reject_cb_box, "view_comment_box_row"].join(" ")}>
          <div className="comment_box_wrapper fg-comment-wrapper">
            <div className="user_img" style={{ backgroundColor: "#CC54FF" }}>
              {displayPicture.length > 2 ? <img src={displayPicture} alt="pic" /> : displayPicture}
            </div>
            <div className="comment_area_wrapper">
              <Form.Group className="form_group_comment mb-0">
                <div className="textarea-wrapper">
                  <Form.Control
                    as="textarea"
                    maxLength={textLength}
                    placeholder={placeholder}
                    className="manager_comments"
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
      <div className={style.btn_group}>
        <Button
          type="button"
          disabled={submitDisabled}
          variant={"primary"}
          className={["btn-round", style.btn_save].join(" ")}
          onClick={() => submitComment(comment)}
        >
          <strong>Submit</strong>
        </Button>
        <Button
          type="button"
          variant={"secondary"}
          className={["btn-round", style.btn_cancel].join(" ")}
          onClick={cancelHandler}
        >
          <strong>Cancel</strong>
        </Button>
      </div>
    </div>
  );
}

CommentSubmit.propTypes = {
  displayPicture: PropTypes.string.isRequired,
  textHeading: PropTypes.string.isRequired,
  commentBoxHeadingText: PropTypes.string,
  submitComment: PropTypes.func,
  cancelHandler: PropTypes.func,
  placeholder: PropTypes.string,
  textLength: PropTypes.number,
};

CommentSubmit.defaultProps = {
  textHeading: "",
};

export default CommentSubmit;
