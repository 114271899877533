import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { GetTeam } from "store/actions/myTeam";
import Spinner from "shared/Spinner/Spinner";
import TabCurrentCheckIn from "components/TabCurrentCheckIn/TabCurrentCheckIn";
import NoDataFound from "components/NodataFound/NoDataFound";
import style from "./MyTeam.module.scss";
import { noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";

export default function MyTeam(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { teamData, isLoadingTeam } = useSelector((state) => state.myTeam);

  useEffect(() => {
    dispatch({ type: "CLEAR_MY_TEAM_DATA" });
    dispatch({ type: "REMOVE_SURVEY_CYCLE_ID" });
    dispatch({ type: "REMOVE_IS_LOADING_OBJECTIVE" });
    dispatch(GetTeam());
  }, [dispatch]);

  useEffect(() => {
    if (teamData) setData(teamData);
  }, [teamData]);

  return (
    <div>
      <SubNavigation type="OKR Progress" />
      {isLoadingTeam ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col md={12} className="col-12">
              <h1 className="page-title pb-0">OKR Progress</h1>
              <span className="st-breadcrumb">
              OKR Progress
                <span>My Team</span>
              </span>
            </Col>
            <div className="col-md-6 col-xs-12 d-none">
              <div className={style.pendingReviewNote}>
                <div className={style.line}>
                  <span className={style.note}>4 Ready to review from current OKR Progress</span>
                  <span className={style.btn}>
                    <Link to="">View</Link>
                  </span>
                </div>
                <div className={style.line}>
                  <span className={style.note}>4 Ready to review from current OKR Progress</span>
                  <span className={style.btn}>
                    <Link to="">View</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="my-team-check-in box pb-0 mb-0">
                <div className="box-heading-wrapper">
                  <div className="box-heading justify-content-between d-flex align-items-center">
                    <h2>Current OKR Progress</h2>
                  </div>
                </div>
              </div>
              {data ? (
                <TabCurrentCheckIn teamResponse={data} filterValue={props.location.state?.filterValue || ""} />
              ) : (
                <div className="outer-table-wrapper">
                  <div className="box-light-gradient">
                    <div className="custom-table table-theme-1 pt40">
                      <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Row>
        </Container>
      )}
    </div>
  );
}
