import React from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { EXPORT_BUTTON, IC_INPUT_SEARCH } from "../../const/imgCost";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import Select from "react-select";
import styles from "./FIlterTeam.module.scss";

export default function FilterTeam({
  value,
  placeholder,
  onRadioChange,
  onResetSearch,
  filter,
  filterValue,
  onSearch,
  searchMember,
  isExport,
  handleExport,
  showBenchFilter,
  projectStatusFilter,
  onProjectStatusChange,
  showCheckinStatusOnNewRow = false,
}) {
  let className = "form-control-rounded form-search-item";
  if (value.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }
  const onEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      searchMember && searchMember(event);
    }
  };

  const dropdownOptions = [
    { value: "", label: "All" },
    { value: "false", label: "Project" },
    { value: "true", label: "OnBench" },
  ];
 
  const isShowNewRow = showBenchFilter && showCheckinStatusOnNewRow;
  
  return (
    <div className="tab_filter">
      <Row>
        <Col xs={12} sm={12} md={showBenchFilter ? 3: 6} lg={showBenchFilter ? 3: 4} xl={showBenchFilter ? 3: 4}>
          <div className="check_in_search_bar">
            <Form>
              <Form.Group controlId="" className="form-postfix mb-0">
                <Form.Control
                  size="sm"
                  value={value}
                  type="text"
                  placeholder={placeholder}
                  name="searchBox"
                  className={`${className} ${styles.searchWrapper}` }
                  onChange={onSearch}
                  onKeyPress={onEnter}
                />
                <Button
                  className="close-button"
                  type="reset"
                  onClick={onResetSearch}
                >
                  <span className="close-icon"></span>
                </Button>
                <div className="form-postfix-icon">
                  <img src={IC_INPUT_SEARCH} title="" alt="" />
                </div>
              </Form.Group>
            </Form>
            {searchMember && (
              <InfoTooltip heading="After entering member name press enter" />
            )}
          </div>
        </Col>
        {showBenchFilter && ( 
          <Col xs={12} sm={12} md={showCheckinStatusOnNewRow ? 9 : 3} lg={showCheckinStatusOnNewRow ? 9 : 3}>
            <div className={styles.allocationFilter}>
              <label className={styles.label}>Allocation Status</label>
              <Select
                options={dropdownOptions}
                className={`${styles.options} custom-default-dd-box`}
                classNamePrefix="select"
                placeholder="Allocation Status"
                defaultValue={projectStatusFilter || dropdownOptions[0]}
                onChange={onProjectStatusChange}
              />
            </div>
          </Col>
        )}
        <Col xs={12} sm={12} md={isShowNewRow ? 12 : 6 } lg={isShowNewRow ? 12 : 6} xl={isShowNewRow ? 12 : showBenchFilter ? 6: 8}>
          {filter?.value?.length > 0 && (
            <div className={`tab_check_in_status ${isShowNewRow ? styles.checkinStatusWrapper : ''}`}>
              <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                <input
                  id="all"
                  type="radio"
                  name="status-check"
                  value={filter.value[0]}
                  onChange={onRadioChange}
                  defaultChecked={filter.value[0] === filterValue}
                />
                <label htmlFor="all">{filter.name[0]}</label>
              </div>
              <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                <input
                  id="g1"
                  type="radio"
                  name="status-check"
                  value={filter.value[1]}
                  onChange={onRadioChange}
                  defaultChecked={filter.value[1] === filterValue}
                />
                <label htmlFor="g1">{filter.name[1]}</label>
              </div>
              {filter.name[2] && (
                <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g2"
                    type="radio"
                    name="status-check"
                    value={filter.value[2]}
                    onChange={onRadioChange}
                    defaultChecked={filter.value[2] === filterValue}
                  />
                  <label htmlFor="g2">{filter.name[2]}</label>
                </div>
              )}
              {filter.name[3] && (
                <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g3"
                    type="radio"
                    name="status-check"
                    value={filter.value[3]}
                    onChange={onRadioChange}
                    defaultChecked={filter.value[3] === filterValue}
                  />
                  <label htmlFor="g3">{filter.name[3]}</label>
                </div>
              )}
              {filter.name[4] && (
                <div className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light">
                  <input
                    id="g4"
                    type="radio"
                    name="status-check"
                    value={filter.value[4]}
                    onChange={onRadioChange}
                    defaultChecked={filter.value[4] === filterValue}
                  />
                  <label htmlFor="g4">{filter.name[4]}</label>
                </div>
              )}
              {isExport && (
                <div
                  className="custom-checkbox custom-checkbox-rounded custom-checkbox-sm custom-checkbox-theme-1 custom-checkbox-light"
                  onClick={handleExport}
                >
                  <ReactSVG
                    src={`${EXPORT_BUTTON}`}
                    className="export_button"
                  />
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
