import React, { useState } from "react";
import { Col, Row, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import moment from "moment";
import style from "./../TabEngagement.module.scss";
import "react-circular-progressbar/dist/styles.css";
import { dateFormat1 } from "util/dateUtils";
import { getStatusInformation } from "util/general";
import { monthOptions } from "const/options";
import { IC_CHECKIN } from "const/imgCost";
import HideAndShow from "../../HideAndShow/HideAndShow";
import CheckInStreak from "../../CheckInStreak/CheckInStreak";
import TwoLevelPieChart from "../../TwoLevelPieChart/TwoLevelPieChart";
import TeamsCheckInActivity from "./TeamsCheckInActivity";
import config from "util/config";

export default function CheckInActivity(props) {
  const { hide: propshide, streak } = props;
  const [hide, setHide] = useState(propshide || false);

  return (
    <div className={["card", style.checkin_activites].join(" ")}>
      <div className="card_info">
        <div className="card_title">
          <div className="d-flex">
            <ReactSVG src={`${IC_CHECKIN}`} className="icon-svg" />
            <div className="card_title_text">OKR Progress Activities</div>
          </div>
        </div>
        <HideAndShow hide={hide} setHide={setHide} />
      </div>
      <Collapse in={!hide}>
        <div>
          <div className="card_details">
            <div className="box-light-gradient"></div>
            <div className={style.checkin_streak_wrapper}>
              <div className={style.check_in_steak_heading}>Accuracy</div>
              <CheckInStreak
                currentStreak={streak?.currentStreak}
                yourBestStreak={streak?.yourBestStreak}
                completionRate={streak?.completionRate}
                orgBestStreak={streak?.orgBestStreak}
              />
            </div>
            <div className={style.to_do_row}>
              <Row>
                <Col xs={12} md={6}>
                  <div className={style.subtitle}>OKR Progress submitted</div>
                  <div className={style.under_subtitle}>Showing last {props.filterSurveyDataMonth} months history</div>
                </Col>
                <Col xs={12} md={6}>
                  <Select
                    onChange={(select) => props.handleSurveyDataFilter(select)}
                    options={monthOptions}
                    className="select_dropdown select_dropdown_text float-right"
                    classNamePrefix="select"
                    isSearchable={false}
                    defaultValue={{
                      value: 6,
                      label: "Last 6 Months",
                    }}
                  />
                </Col>
              </Row>
              <div className={style.details}>
                <Row className="align-items-center">
                  <Col xs={12} md={5} className={style.meter_wrapper}>
                    <TwoLevelPieChart
                      legendData={[
                        { name: "Submitted", order: 1 },
                        { name: "Lapsed", order: 2 },
                        { name: "Reviewed by Manager", order: 3 },
                      ]}
                      noDataText="You haven't submitted any OKR Progress yet."
                      height="250px"
                      insideData={props.checkinStatusForEmployee}
                      outsideData={props.managerCheckinStatusForEmployee}
                    />
                  </Col>
                  <Col xs={12} md={7}>
                    <div className={style.checkin_submit_wrapper}>
                      <table className={style.checkin_submit}>
                        <thead>
                          <tr>
                            <th>Reporting Period</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.surveyData && props.surveyData.length > 0 ? (
                            props.surveyData
                              .filter((i, index) => index < 6)
                              .map((value) => (
                                <tr key={value.surveyCyclesId}>
                                  <td className={style.date_between}>
                                    <Link
                                      to={
                                        props.isPage === "Home"
                                          ? {
                                              pathname: `/okrprogress/my-history`,
                                              state: value.surveyCyclesId,
                                            }
                                          : {
                                              pathname: `/okrprogress/my-team/member-okrprogress/${
                                                props.employeeId +
                                                config.employeeIdSplit +
                                                props.parentGroupId +
                                                config.employeeIdSplit +
                                                props.subGroupId
                                              }`,
                                              state: value.surveyCyclesId,
                                            }
                                      }
                                    >
                                      {moment(value.startDate).format("MMM DD")} –{" "}
                                      {moment(value.endDate).format("MMM DD")}
                                    </Link>
                                  </td>
                                  <td>
                                    <div
                                      className={[
                                        style.tooltip_wrapper,
                                        style.team_checkin_status,
                                        getStatusInformation(value.status, style).style,
                                      ].join(" ")}
                                    >
                                      <span>{getStatusInformation(value.status, style).status}</span>
                                      {value.submitedDate && (
                                        <div className={style.tooltip_info}>
                                          {value.submitedDate && (
                                            <div>
                                              Submitted date: <span>{dateFormat1(value.submitedDate)}</span>
                                            </div>
                                          )}
                                          {value.reviewedBy && (
                                            <div>
                                              Reviewed by: <span>{value.reviewedBy?.empName}</span>
                                            </div>
                                          )}
                                          {value.reviewedDate && (
                                            <div>
                                              Reviewed date: <span>{dateFormat1(value.reviewedDate)}</span>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="2" className="no_checkin_activity">
                                You haven&apos;t submitted any OKR Progress yet.
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {props.surveyData && props.surveyData.length > 0 && (
                          <tfoot>
                            <tr>
                              <td colSpan="2">
                                <Link
                                  to={
                                    props.isPage === "Home"
                                      ? "/okrprogress/my-history"
                                      : `/okrprogress/my-team/member-okrprogress/${
                                          props.employeeId +
                                          config.employeeIdSplit +
                                          props.parentGroupId +
                                          config.employeeIdSplit +
                                          props.subGroupId
                                        }`
                                  }
                                >
                                  <span>View All</span>
                                </Link>
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {props.currentAndPreviousCheckin && (
            <TeamsCheckInActivity
              pendingCheckinReview={props.pendingCheckinReview}
              checkinPendingFromEmployees={props.checkinPendingFromEmployees}
              currentAndPreviousCheckin={props.currentAndPreviousCheckin}
              handleTeamsChartOptions={props.handleTeamsChartOptions}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
}
