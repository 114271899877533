import React from "react";
import PropTypes from "prop-types";
import style from "./SidebarProjectDetails.module.scss";

function SidebarProjectDetails({ previousProject, currentProject, isLargeName, handleViewMore }) {
  return (
    <div className={["box pb-0", style.project_details_wrapper].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Project Details</h2>
          <h3 className="box-heading-light">Have a look on projects</h3>
        </div>
      </div>
      <div className="box-inner-pad box-light-gradient pb-0">
        <ul className="list-item-2info">
          <li>
            <h4>Previous Project</h4>
            <span>{previousProject}</span>
          </li>
          <li>
            <h4>Current Project</h4>
            <span>{currentProject}{isLargeName && <span onClick={handleViewMore} className={style.viewMore}>View More</span>}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

SidebarProjectDetails.propTypes = {
  previousProject: PropTypes.string,
  currentProject: PropTypes.string,
};

export default SidebarProjectDetails;
