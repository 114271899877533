import React, { useState, useEffect } from "react";
import style from "../Dashboard.module.scss";
import { workLoadOrder, locationOrder } from "../../../../const/constValue";
import { PieChart, Pie, ResponsiveContainer, Cell, Label, BarChart, XAxis, YAxis, Bar, Tooltip } from "recharts";
import _ from "lodash";

export default function WorkLoadCharts(props) {
  const [currentVal, setCurrentVal] = useState(null);
  const [pieChartDataWorkload, setPieChartDataWorkload] = useState([]);
  const [barChartDataWorkload, setBarChartDataWorkload] = useState([]);

  useEffect(() => {
    const PieChartsArray = [];
    const BarChartArray = [];

    if (props.workLoadData) {
      props.workLoadData.pieChartDateList.map((workload) => {
        PieChartsArray.push({
          name: workload.status,
          value: workload.count,
          order: workLoadOrder[workload.status],
        });
        return workload;
      });
      setPieChartDataWorkload(_.sortBy(PieChartsArray, ["order"], ["asc"]));
      props.workLoadData.barChartList.map((barCheckins) => {
        BarChartArray.push({
          name: barCheckins.location,
          Difficult: barCheckins.difficult,
          Demanding: barCheckins.demanding,
          "Bit Tough": barCheckins.bitTough,
          Hard: barCheckins.hard,
          Exhausted: barCheckins.exhausted,
          "Needs Attention": barCheckins.needsAttention,
          "Nearly Exhausted": barCheckins.nearlyExhausted,
          Smooth: barCheckins.smooth,
          Reasonable: barCheckins.reasonable,
          Severe: barCheckins.severe,
          order: locationOrder[barCheckins.location],
        });
        return barCheckins;
      });
      setBarChartDataWorkload(_.sortBy(BarChartArray, ["order"], ["asc"]));
    } else {
      setPieChartDataWorkload([]);
      setBarChartDataWorkload([]);
    }
  }, [props]);

  const COLORS = {
    Difficult: "#FD6E3B",
    Demanding: "#FFFF00",
    "Bit Tough": "#FFD700",
    Hard: "#FFA500",
    Exhausted: "#CC0000",
    "Needs Attention": "#60B000",
    "Nearly Exhausted": "#FF0000",
    Smooth: "#008B8B",
    Reasonable: "#3CB371",
    Severe: "#FD9F59",
  };

  function CustomLabel({ viewBox }) {
    const { cx, cy } = viewBox;
    let totalValue = 0;
    pieChartDataWorkload.forEach((dt) => {
      totalValue += dt.value;
    });
    const total = `${totalValue}`;
    return (
      <>
        <text
          x={cx}
          y={cy - 12}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            Total
          </tspan>
        </text>
        <text
          x={cx}
          y={cy + 15}
          fill="#3d405c"
          className="recharts-text recharts-label inner-recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="20">
            {total}
          </tspan>
        </text>
      </>
    );
  }

  /** end pie chart */

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    let countStatusLocationWise;

    payload.forEach((data) => {
      if (currentVal && currentVal === data.dataKey) {
        Object.keys(data.payload).map((key) => {
          if (key !== "name" && key !== "order") {
            totalVal += data.payload[key];
          }
          return key;
        });
        pr = (data.value / totalVal) * 100;
        countStatusLocationWise = data.value;
      }
    });
    if (active) {
      return (
        <>
          {currentVal && (
            <div className="recharts-tooltip-custom-wrapper">
              <p className="recharts-tooltip-label">{currentVal}</p>
              {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
              <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${countStatusLocationWise}`}</span>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomTooltipForPieChart = (props) => {
    const { active, payload } = props;
    let totalVal = 0;
    let pr;
    if (active) {
      pieChartDataWorkload.forEach((data) => {
        totalVal += data.value;
      });
      pr = (payload[0]["value"] / totalVal) * 100;
      return (
        <>
          <div className="recharts-tooltip-custom-wrapper">
            <p className="recharts-tooltip-label">{payload[0]["name"]}</p>
            {pr && <span className="recharts-tooltip-value">{`${pr.toFixed(2)}%`}</span>}
            <span className="recharts-tooltip-value w-100 d-inline-block">{`${"Count"}${": "}${
              payload[0]["value"]
            }`}</span>
          </div>
        </>
      );
    }

    return null;
  };
  /** end bar chart*/

  return (
    <div className={["box", style.graph_wrapper].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Workload Status</h2>
        </div>
      </div>
      <div className="box-inner-pad pb-0">
        {pieChartDataWorkload && pieChartDataWorkload.length > 0 ? (
          <>
            <div className="d-flex">
              <div className="graph-item width50">
                <h5 data-test="aggregate">Aggregate Workload report </h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={pieChartDataWorkload}
                        cx="45%"
                        cy="50%"
                        innerRadius={100}
                        outerRadius={125}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                      >
                        <Label
                          width={30}
                          position="center"
                          content={<CustomLabel value1={pieChartDataWorkload} />}
                        ></Label>
                        {pieChartDataWorkload.map((entry, index) => (
                          <Cell key={index} fill={COLORS[entry["name"]]} />
                        ))}
                      </Pie>
                      <Tooltip content={CustomTooltipForPieChart} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="graph-item width70">
                <h5 className={["pl-4", style.right_text_title].join(" ")} data-test="location">
                  Location wise Workload Report
                </h5>
                <div style={{ width: "100%", height: 350 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={barChartDataWorkload}
                      margin={{ top: 70, right: 0, left: 0, bottom: 0 }}
                      barSize={25}
                      barGap={1}
                    >
                      <XAxis dataKey="name" axisLine={false} />
                      <YAxis axisLine={false} />
                      <Bar
                        dataKey="Smooth"
                        stackId="a"
                        fill={COLORS["Smooth"]}
                        onMouseOver={() => {
                          setCurrentVal("Smooth");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Reasonable"
                        stackId="a"
                        fill={COLORS["Reasonable"]}
                        onMouseOver={() => {
                          setCurrentVal("Reasonable");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Needs Attention"
                        stackId="a"
                        fill={COLORS["Needs Attention"]}
                        onMouseOver={() => {
                          setCurrentVal("Needs Attention");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Demanding"
                        stackId="a"
                        fill={COLORS["Demanding"]}
                        onMouseOver={() => {
                          setCurrentVal("Demanding");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Bit Tough"
                        stackId="a"
                        fill={COLORS["Bit Tough"]}
                        onMouseOver={() => {
                          setCurrentVal("Bit Tough");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Hard"
                        stackId="a"
                        fill={COLORS["Hard"]}
                        onMouseOver={() => {
                          setCurrentVal("Hard");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Severe"
                        stackId="a"
                        fill={COLORS["Severe"]}
                        onMouseOver={() => {
                          setCurrentVal("Severe");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Difficult"
                        stackId="a"
                        fill={COLORS["Difficult"]}
                        onMouseOver={() => {
                          setCurrentVal("Difficult");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Nearly Exhausted"
                        stackId="a"
                        fill={COLORS["Nearly Exhausted"]}
                        onMouseOver={() => {
                          setCurrentVal("Nearly Exhausted");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Bar
                        dataKey="Exhausted"
                        stackId="a"
                        fill={COLORS["Exhausted"]}
                        onMouseOver={() => {
                          setCurrentVal("Exhausted");
                        }}
                        onMouseLeave={() => setCurrentVal(null)}
                      />

                      <Tooltip content={CustomTooltip} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className={style.legent_wrapper}>
              <ul className={style.lenegd_list} data-test="lenegd_list">
                {pieChartDataWorkload.map((entry, index) => {
                  return (
                    <li className={style.legend_item} key={index}>
                      <span className={style.legend_color} style={{ backgroundColor: COLORS[entry["name"]] }}></span>
                      {entry["name"]}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        ) : (
          <div className="no-record-found-wrpr mt-3" data-test="no-record">
            No Data Available
          </div>
        )}
      </div>
    </div>
  );
}
