import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import style from "./Navigation.module.scss";
import AuthContext from "context/authContext";
import { joinSpace } from "util/general";

export default function Navigation() {
  const context = useContext(AuthContext);
  const { navigation } = context.state;

  return (
    <div className={style.navigation_wrapper}>
      <ul className={style.nav_links}>
        {navigation?.map((nav, index) => (
          <li key={nav.url + index}>
            <NavLink
              to={nav.url || "/"}
              activeClassName="active"
              isActive={(match, location) => {
                if (
                  location.pathname === nav.url ||
                  nav?.subModuleAndRights?.findIndex(
                    (subModule) => subModule.url === location.pathname
                  ) > 0
                ) {
                  return true;
                }
                if (
                  location.pathname.split("/")[1] === joinSpace(nav.moduleName)
                ) {
                  return true;
                }
                if (location.pathname.split("/")[1] === "wmg-view" || location.pathname.split("/")[1] === "hr") {
                  if (location.pathname.split("/")[2] === joinSpace(nav.moduleName)) {
                    return true;
                  }
                }
                if (location.pathname === "/" && nav.moduleName === "Home") {
                  return true;
                }
              }}
            >
              {nav.moduleName}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
