import * as actionTypes from "./actionTypes";
import http from "util/http";
import {
  USER_CHECKIN,
  PAST_CHECKIN,
  CHEKIN_EXTEND_ADMIN,
  UPDATE_SURVEY_CYCLE,
  SUBMIT_CYCLE,
  STREAK,
  MOOD_METER,
  HELP_TO_GROW,
  DELETE_HELP_TO_GROW,
  DOWNLOAD_FILE,
  UPLOAD_FILE,
  DELETE_FILE,
  PRIVATE_EMPLOYEE_COMMENT,
  DELETE_PRIVATE_EMP_COMMENT,
  ADD_PLAN,
  UPDATE_PLAN,
  DELETE_PLAN,
  PLANS,
  PREVIOUS_PLANS,
  CARRY_FORWARD_PLAN,
  CHANGE_PLAN_STATUS,
  GET_PLANS,
  GET_USER_OBJECTIVES,
  LINK_OBJECTIVE_PLAN,
  DESELECT_OBJECTIVE,
  UPDATE_USER_OBJECTIVE,
  USER_OBJECTIVE,
  NOTIFY_OBJECTIVE,
  GET_ALL_HELP_TO_GROW,
  OBJECTIVE_PROGRESS_STATUS,
  MANAGER_DATA,
  AUTO_SAVE_SURVEY_CYCLE_TEMPLATE,
  CHEKIN_EXTEND_ADMIN_HISTORY,
} from "util/endpoints";
import moment from "moment";
import config from "util/config";
import { errorToast, scrollToTop, successToast } from "util/general";
import { checkInStatus, status } from "const/constValue";
import * as actions from "./index";
export const setCurrentCheckIn = (response, currentCheckIn, rating, moodoMeter) => {
  return {
    type: actionTypes.SET_CURRENT_CHECKIN,
    payload: {
      currentCheckIn: currentCheckIn,
      data: response,
      rating,
      moodoMeter,
    },
  };
};

export const fetchCurrentCheckInFailed = (errCode = null) => {
  // errCode === 7002 ? { type: actionTypes.LOGOUT } :
  return { type: actionTypes.FETCH_CURRENT_CHECKIN_FAILED, errorCode: errCode };
};

export const updateCurrentCheckIn = (updateCheckin) => ({
  type: actionTypes.UPDATE_CURRENT_CHECKIN,
  updateCheckin: updateCheckin,
});
export const updateCurrentCheckInFailed = () => ({
  type: actionTypes.UPDATE_CURRENT_CHECKIN_FAILED,
});
export const setValidation = (errors) => ({
  type: actionTypes.SET_VALIDATION_ERRORS,
  validationErrors: errors,
});
export const saveAnswers = (submitedDate) => ({
  type: actionTypes.SET_SUBMIT,
  submitedDate: submitedDate,
});

export const GetStreak = (data) => (dispatch) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
  };
  http
    .get(STREAK, {}, { params })
    .then((response) => response.data.data)
    .then((response) => dispatch({ type: actionTypes.SET_STREAK, streak: response }))
    .catch(() => {
      dispatch(updateCurrentCheckInFailed());
    });
};

export const GetReviewer = (data) => (dispatch) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
  };
  http
    .get(MANAGER_DATA, {}, { params })
    .then((response) => response.data.data)
    .then((response) => {
      if (response)
        dispatch({
          type: actionTypes.SET_REVIEWER,
          reviewer: response,
        });
      else dispatch({ type: actionTypes.SET_REVIEWER, reviewer: null });
    })
    .catch(() => {
      dispatch(updateCurrentCheckInFailed());
    });
};

export const FetchCurrentCheckIn = (data) => (dispatch) => {
  let params = {
    employeeId: data.employeeId,
    employeeSubGroupId: data.subGroupId,
    employeeParentGroupId: data.parentGroupId,
  };
  http
    .get(USER_CHECKIN, {}, { params })
    .then((response) => response.data.data)
    .then((response) => {
      const currentCheckIn = { start: "", end: "" };
      currentCheckIn["start"] = moment(response.startDate).format(config.dates.shortMonthNameAndDate);
      currentCheckIn["end"] = moment(response.endDate).format(config.dates.shortMonthNameAndDate);
      const rating = response.questionsAnswer.filter((rating) => rating.type === "RATING");
      const moodoMeter = response.questionsAnswer.filter((rating) => rating.type === "MOODOMETER");
      dispatch(setCurrentCheckIn(response, currentCheckIn, rating, moodoMeter));
      dispatch({
        type: actionTypes.SET_QUESTIONS,
        payload: { data: response, currentCheckIn, rating, moodoMeter },
      });
    })
    .catch((error) => {
      error.response ? dispatch(fetchCurrentCheckInFailed()) : dispatch(fetchCurrentCheckInFailed());
    });
};

export const checkinExtendByAdmin = (data) => (dispatch) => {
  let params = {
    startDate: data.startDate,
    endDate: data.endDate,
    extendedDate: data.extendedDate,
  };
  http
    .post(CHEKIN_EXTEND_ADMIN, {}, params)
    .then((response) => response.data.data)
    .then((response) => {
      dispatch({
        type: actionTypes.SET_EXTEND_BY_ADMIN,
        payload: { data: response },
      });
      dispatch(actions.checkinExtendAdminHistory());
      successToast("Checkin extended successfully!");
    })
    .catch(() => {
      errorToast("Required field missing!");
    });
};

export const checkinExtendAdminHistory = () => (dispatch) => {
  dispatch({ type: actionTypes.SET_EXTEND_ADMIN_HISTORY_LOADING})
  http
    .get(CHEKIN_EXTEND_ADMIN_HISTORY, {}, {})
    .then((response) => response.data.data)
    .then((response) => dispatch({ type: actionTypes.SET_EXTEND_ADMIN_HISTORY,  payload: { data: response }, }))
    .catch(() => {});
};

export const UpdateCurrentCheckIn = (questionAnswer, questionId, employeeIds, surveyCycleId, isComment) => {
  let params = { surveyCycleId };
  let qna = {};
  isComment
    ? (qna = {
        queId: questionId,
        employeeComment: questionAnswer,
        surveyCycleId: surveyCycleId,
        employeeId: employeeIds,
      })
    : (qna = { queId: questionId, answer: questionAnswer, surveyCycleId: surveyCycleId, employeeId: employeeIds });

  return (dispatch) => {
    http
      .put(UPDATE_SURVEY_CYCLE, params, qna)
      .then((response) => response.data.data)
      .then((response) => {
        dispatch(updateCurrentCheckIn(response));
        dispatch({ type: actionTypes.SET_QUESTIONS, updatedDate: response });
      })
      .catch(() => {
        dispatch(updateCurrentCheckInFailed());
      });
  };
};

export const AutoSaveCheckinTemplate = (data, surveyCycleId, questionTemplateId) => {
  let qna = {
    questions: data,
    surveyCycleId,
    questionTemplateId,
  };
  return (dispatch) => {
    http
      .put(AUTO_SAVE_SURVEY_CYCLE_TEMPLATE, {}, qna)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          dispatch(updateCurrentCheckIn(response));
          dispatch({ type: actionTypes.SET_QUESTIONS, updatedDate: response });
        }
      })
      .catch(() => {});
  };
};

export const SetValidationErrors = (errors) => (dispatch) => dispatch(setValidation(errors));

export const SaveCheckInAnswers = (surveyCycleId, employeeIds) => {
  const response = { submit: "true", employeeId: { ...employeeIds }, surveyCycleId };
  let params = {
    employeeId: employeeIds.employeeId,
    employeeSubGroupId: employeeIds.subGroupId,
    employeeParentGroupId: employeeIds.parentGroupId,
  };
  return (dispatch) => {
    dispatch({ type: "CHECKIN_LOADER" });
    http
      .post(SUBMIT_CYCLE, {}, response)
      .then((response) => {
        if (response) {
          if (response.data.status === status.SUCCESS) {
            //after submit call current checkin API
            dispatch(saveAnswers(response.data.data.submitedDate));
            dispatch(actions.GetObjectivesBySurveyCycle(surveyCycleId));
            dispatch(GetUserObjectives(employeeIds));
            GetStreak(employeeIds);
            scrollToTop(500);
            http
              .get(USER_CHECKIN, {}, { params })
              .then((response) => response.data.data)
              .then((response) => {
                const currentCheckIn = { start: "", end: "" };
                currentCheckIn["start"] = moment(response.startDate).format(config.dates.shortMonthNameAndDate);
                currentCheckIn["end"] = moment(response.endDate).format(config.dates.shortMonthNameAndDate);
                const rating = response.questionsAnswer.filter((rating) => rating.type === "RATING");
                const moodoMeter = response.questionsAnswer.filter((rating) => rating.type === "MOODOMETER");
                dispatch(setCurrentCheckIn(response, currentCheckIn, rating, moodoMeter));
                dispatch({
                  type: actionTypes.SET_QUESTIONS,
                  payload: { data: response, currentCheckIn, rating, moodoMeter },
                });
              })
              .catch((error) => {
                error.response
                  ? dispatch(fetchCurrentCheckInFailed(error.response.data.message.code))
                  : dispatch(fetchCurrentCheckInFailed());
              });
            dispatch({
              type: actionTypes.SET_QUESTIONS,
              payload: { submitedDate: response.data.data.submitedDate },
            });
            dispatch({
              type: actionTypes.CHECKIN_SUBMIT_RESPONSE,
              message: "Data has been submitted.",
              success: true,
            });
          } else {
            dispatch({
              type: actionTypes.CHECKIN_SUBMIT_RESPONSE,
              message: "Something went wrong!",
              success: false,
            });
          }
        }
      })
      .catch(() => {
        // Handle Error Here
      });
  };
};

export const SaveCheckInAnswersForHistory = (surveyCycleId, employeeIds) => {
  const response = { submit: "true", employeeId: { ...employeeIds }, surveyCycleId };
  // let params = {
  //   employeeId: employeeIds.employeeId,
  //   employeeSubGroupId: employeeIds.subGroupId,
  //   employeeParentGroupId: employeeIds.parentGroupId,
  // };
  let params = {
    surveyCycleId: surveyCycleId
  };
  return (dispatch) => {
    dispatch({ type: "CHECKIN_LOADER" });
    http
      .post(SUBMIT_CYCLE, {}, response)
      .then((response) => {
        if (response) {
          if (response.data.status === status.SUCCESS) {
            //after submit call current checkin API
            dispatch(saveAnswers(response.data.data.submitedDate));
            dispatch(actions.GetObjectivesBySurveyCycle(surveyCycleId));
            dispatch(GetUserObjectives(employeeIds));
            GetStreak(employeeIds);
            scrollToTop(500);
            http
              .get(PAST_CHECKIN, params)
              .then((response) => response.data.data)
              .then((response) => {
                const currentCheckIn = { start: "", end: "" };
                currentCheckIn["start"] = moment(response.startDate).format(config.dates.shortMonthNameAndDate);
                currentCheckIn["end"] = moment(response.endDate).format(config.dates.shortMonthNameAndDate);
                const rating = response.questionsAnswer.filter((rating) => rating.type === "RATING");
                const moodoMeter = response.questionsAnswer.filter((rating) => rating.type === "MOODOMETER");
                dispatch(setCurrentCheckIn(response, currentCheckIn, rating, moodoMeter));
                dispatch({
                  type: actionTypes.SET_QUESTIONS,
                  payload: { data: response, currentCheckIn, rating, moodoMeter },
                });
              })
              .catch((error) => {
                error.response
                  ? dispatch(fetchCurrentCheckInFailed(error.response.data.message.code))
                  : dispatch(fetchCurrentCheckInFailed());
              });
            dispatch({
              type: actionTypes.SET_QUESTIONS,
              payload: { submitedDate: response.data.data.submitedDate },
            });
            dispatch({
              type: actionTypes.CHECKIN_SUBMIT_RESPONSE,
              message: "Data has been submitted.",
              success: true,
            });
          } else {
            dispatch({
              type: actionTypes.CHECKIN_SUBMIT_RESPONSE,
              message: "Something went wrong!",
              success: false,
            });
          }
        }
      })
      .catch(() => {
        // Handle Error Here
      });
  };
};

export const HandleMoodChange = (params, questionsAnswer, moodoMeter) => {
  return (dispatch) => {
    http
      .put(MOOD_METER, params, questionsAnswer)
      .then((response) => response.data)
      .then((data) => {
        let newMoodoMeter = [...moodoMeter];
        newMoodoMeter[0].employeeComment = questionsAnswer.employeeComment;
        dispatch({
          type: actionTypes.SET_CURRENT_CHECKIN,
          payload: { updatedDate: data.data, moodoMeter: newMoodoMeter },
        });
      })
      .catch(() => {});
  };
};

export const HelpToGrowAnswer = (params, data) => {
  return (dispatch) => {
    http
      .post(HELP_TO_GROW, params, data)
      .then((response) => response.data.data)
      .then((response) => {
        dispatch({
          type: actionTypes.HELP_TO_GROW_ANSWER,
          helpToGrow: response,
        });
        dispatch({
          type: actionTypes.QUESTIONS_ADD_HELP_TO_GROW,
          data: response,
        });
      })
      .catch(() => {});
  };
};

export const DeleteHelpToGrow = (data, index) => (dispatch) => {
  http
    .delete(DELETE_HELP_TO_GROW, data)
    .then((response) => response.data.data)
    .then((response) => {
      dispatch({
        type: actionTypes.SET_CURRENT_CHECKIN,
        payload: { updatedDate: response },
      });
      dispatch({
        type: actionTypes.DELETE_HELP_TO_GROW,
        deleteHelpToGrow: index,
      });
      dispatch({
        type: actionTypes.QUESTIONS_DELETE_HELP_TO_GROW,
        data: index,
      });
    })
    .catch(() => {});
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    // eslint-disable-next-line no-undef
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const DownloadFile = (params) => () => {
  http
    .get(DOWNLOAD_FILE, params)
    .then((response) => response.data.data)
    .then((response) => {
      const blob = b64toBlob(response.binary.data, response.file.contentType);
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", response.file.name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(() => {});
};

export const UploadFile = (formData) => (dispatch) => {
  http
    .post(UPLOAD_FILE, null, formData)
    .then((response) => response.data)
    .then(
      (response) => {
        if (response.message.code === 14) {
          dispatch({
            type: actionTypes.UPLOAD_FILE_ERROR,
            message: response.message.message,
          });
          errorToast(response.message.message);
        } else {
          dispatch({
            type: actionTypes.SET_CURRENT_CHECKIN,
            payload: { updatedDate: response.data },
          });
          dispatch({
            type: actionTypes.UPLOAD_FILE_SUCCESS,
            message: "File uploaded successfully!",
          });
          successToast("File uploaded successfully!");
        }
      },
      () => {
        dispatch({
          type: actionTypes.UPLOAD_FILE_ERROR,
          message: "OTHER_FILE_ERROR",
        });
        errorToast("Select valid file")
      }
    );
};

export const DeleteFile = (params) => (dispatch) => {
  http
    .delete(DELETE_FILE, params)
    .then((response) => response)
    .then(
      () => {
        dispatch({
          type: actionTypes.FILE_DELETE_SUCCESS,
          message: "File deleted successfully!",
        });
        successToast("File deleted successfully!")
      },
      () => {
        dispatch({
          type: actionTypes.FILE_DELETE_ERROR,
          message: "Issue deleting file",
        });
        errorToast("Issue deleting file")
      }
    );
};

export const SendPrivateMessage = (data) => (dispatch) => {
  http
    .put(PRIVATE_EMPLOYEE_COMMENT, {}, data)
    .then((response) => response.data)
    .then((data) => {
      if (data.data.commentedOn) {
        dispatch({
          type: actionTypes.SET_CURRENT_CHECKIN,
          payload: { updatedDate: data.data.commentedOn },
        });
        dispatch({
          type: actionTypes.SET_QUESTIONS,
          payload: { updatedDate: data.data.commentedOn },
        });
      }
    })
    .catch(console.error);
};

export const DeletePrivateEmpMessage = (data) => () => {
  http
    .delete(DELETE_PRIVATE_EMP_COMMENT, { data })
    .then((response) => response.data)
    .then(() => {})
    .catch(console.error);
};

export const SetPlan = (plan, surveyCycleId) => {
  const data = { createdSurveyCycleId: surveyCycleId, planDescription: plan };
  return (dispatch) => {
    if (surveyCycleId === "ONCHANGE") {
      //when plan is edit in onChange then pass ONCHANGE type in surveyCycleId for set onChang value in store
      dispatch({
        type: actionTypes.SET_PLAN_ONCHANGE,
        planOnChangeError: plan.trim() ? false : true,
      });
    } else {
      http
        .post(ADD_PLAN, {}, data)
        .then((response) => response.data.data)
        .then((data) => {
          if (data) {
            dispatch({
              type: actionTypes.SET_PLAN,
              priority: data,
              modifiedDate: data.modifiedDate,
            });
          }
        })
        .catch((error) => console.warn("ENGAGE", error));
    }
  };
};

export const CarryForwardPlan = (id, surveyCycleId, index) => {
  return (dispatch) => {
    http
      .post(CARRY_FORWARD_PLAN, { planId: id, surveyCycleId: surveyCycleId })
      .then((response) => response.data.data)
      .then((data) => {
        dispatch({
          type: actionTypes.CARRY_FORWARD,
          index: index,
          modifiedDate: data.modifiedDate,
        });
        dispatch(FetchPlans());
      })
      .catch(() => {
        // Handle Error Here
      });
  };
};

export const EditPlan = (plan, id, index) => {
  return (dispatch) => {
    const params = "";
    const body = {
      planId: id,
      planDescription: plan,
    };
    http
      .put(UPDATE_PLAN, params, body)
      .then((response) => response.data.data)
      .then((data) => {
        dispatch({
          type: actionTypes.EDIT_PLAN,
          priority: data,
          index: index,
          modifiedDate: data.modifiedDate,
        });
      })
      .catch(() => {});
  };
};

export const DeletePlan = (id, index) => {
  let params = { planId: id };
  return (dispatch) => {
    http
      .delete(DELETE_PLAN, {}, {}, params)
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: actionTypes.DELETE_PLAN,
          index: index,
          modifiedDate: response.data,
        });
      })
      .catch(() => {});
  };
};

export const FetchPreviousPlans = (id) => {
  return (dispatch) => {
    http
      .get(PREVIOUS_PLANS, { surveyCycleId: id })
      .then((response) => response.data.data)
      .then((response) => {
        // dispatch(setCurrentCheckIn(response, currentCheckIn, rating, moodoMeter));
        dispatch({
          type: actionTypes.SET_PREVIOUS_PLANS,
          data: response || [], //need to for fetching data pass response
        });
      })
      .catch(() => {});
  };
};
export const FetchPlans = () => {
  return (dispatch) => {
    http
      .get(PLANS)
      .then((response) => response.data.data)
      .then((response) => {
        // dispatch(setCurrentCheckIn(response, currentCheckIn, rating, moodoMeter));
        if (response) {
          dispatch({
            type: actionTypes.FETCH_PLAN,
            data: response.plans || [], //need to for fetching data pass response
            modifiedDate: response.latestModifiedDate,
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_PLAN,
            data: [],
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const DonePlan = (id) => {
  return (dispatch) => {
    http
      .put(CHANGE_PLAN_STATUS, { planId: id })
      .then((response) => response.data.data)
      .then((data) => {
        dispatch({
          type: actionTypes.DONE_PLAN,
          modifiedDate: data.modifiedDate,
        });
      })
      .catch(() => {});
  };
};

export const GetPlans = (id) => {
  let params = { surveyCycleId: id };
  return (dispatch) => {
    http
      .get(GET_PLANS, params)
      .then((response) => response.data.data)
      .then((response) => {
        // dispatch(setCurrentCheckIn(response, currentCheckIn, rating, moodoMeter));
        if (response) {
          dispatch({
            type: actionTypes.GET_PLAN,
            data: response || [], //need to for fetching data pass response
          });
        } else {
          dispatch({
            type: actionTypes.GET_PLAN,
            data: [], //need to for fetching data pass response
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const GetUserObjectives = (paramsData, type) => {
  let params = {
    employeeId: paramsData?.employeeId,
    employeeSubGroupId: paramsData?.subGroupId,
    employeeParentGroupId: paramsData?.parentGroupId,
  };
  return (dispatch) => {
    http
      .get(GET_USER_OBJECTIVES, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data.length > 0) {
            let Array = response.data
              .map((v) => {
                let type = null;
                if (v.status === checkInStatus.EXPIRED || v.status === checkInStatus.INVALID)
                  type = checkInStatus.EXPIRED;
                else if (v.status === checkInStatus.COMPLETED) type = checkInStatus.COMPLETED;
                else if (
                  v.status === checkInStatus.PENDING_APPROVAL ||
                  v.status === checkInStatus.DRAFTED ||
                  v.status === checkInStatus.ASSIGNED ||
                  v.status === checkInStatus.INPROGRESS ||
                  v.status === checkInStatus.REJECTED
                )
                  type = "CURRENT";
                return {
                  ...v,
                  type,
                };
              })
              .reverse();
            if (type === "TAB_HIGHLIGHTS") {
              dispatch({
                type: actionTypes.GET_EMPLOYEE_OBJECTIVES,
                data: Array, //need to for fetching data pass response
                isLoadingObjective: false,
              });
            } else {
              dispatch({
                type: actionTypes.GET_OBJECTIVES,
                data: Array, //need to for fetching data pass response
                isLoadingObjective: false,
              });
            }
          } else {
            dispatch({
              type: actionTypes.GET_OBJECTIVES,
              data: [], //need to for fetching data pass response
              isLoadingObjective: false,
            });
          }
        } else {
          dispatch({
            type: actionTypes.GET_OBJECTIVES,
            data: [],
            isLoadingObjective: false,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.GET_OBJECTIVES,
          data: [],
          isLoadingObjective: false,
        });
      });
  };
};

export const GetUserObjective = (userObjectiveId, employeeIds) => {
  let config = {
    employeeId: employeeIds.employeeId,
    employeeSubGroupId: employeeIds.subGroupId,
    employeeParentGroupId: employeeIds.parentGroupId,
  };
  let params = {
    userObjectiveId,
  };
  return (dispatch) => {
    http
      .get(USER_OBJECTIVE, params, { params: config })
      .then((response) => response.data.data)
      .then((response) => {
        // dispatch(setCurrentCheckIn(response, currentCheckIn, rating, moodoMeter));
        if (response) {
          dispatch({
            type: actionTypes.GET_OBJECTIVE,
            data: response, //need to for fetching data pass response
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const LinkObjective = (planId, objectiveId, description) => {
  let params = {
    planId,
    objectiveId,
    description,
  };
  return (dispatch) => {
    http
      .put(LINK_OBJECTIVE_PLAN, params)
      .then((response) => response.data.data)
      .then(() => {
        dispatch(FetchPlans());
      })
      .catch(() => {});
  };
};

export const DeselectObjective = (employeeIds, objectiveId, planId) => {
  let params = {
    ...employeeIds,
    objectiveId,
    planId,
  };
  return (dispatch) => {
    http
      .put(DESELECT_OBJECTIVE, null, null, { params })
      .then((response) => response.data.data)
      .then(() => {
        dispatch(FetchPlans());
      })
      .catch(() => {});
  };
};

export const userObjective = (obj, date) => {
  let _data = null;
  if (obj.type === "SELF") {
    _data = {
      userObjectiveId: obj.userObjectiveId,
      employeeId: obj.employeeId,
      subGroupId: obj.employeeData?.subGroupId || obj.subGroupId,
      subGroupName: obj.employeeData?.subGroupName || obj.subGroupName,
      parentGroupId: obj.employeeData?.parentGroupId || obj.parentGroupId,
      parentGroupName: obj.employeeData?.parentGroupName || obj.parentGroupName,
      keyPoints: obj.keyPoints,
      type: obj.type,
    };
  } else {
    _data = {
      userObjectiveId: obj.userObjectiveId,
      employeeId: obj.employeeId,
      subGroupId: obj.employeeData?.subGroupId || obj.subGroupId,
      subGroupName: obj.employeeData?.subGroupName || obj.subGroupName,
      parentGroupId: obj.employeeData?.parentGroupId || obj.parentGroupId,
      parentGroupName: obj.employeeData?.parentGroupName || obj.parentGroupName,
      keyPoints: obj.keyPoints,
      cycleEndDate: date && date.cycleEndDate,
      type: "CHECKIN",
    };
  }

  return (dispatch) => {
    http
      .put(UPDATE_USER_OBJECTIVE, {}, _data)
      .then((response) => response.data.data)
      .then(() => {
        dispatch({ type: actionTypes.SELECT_OBJECTIVE, data: obj });
        if (obj.type !== "SELF") dispatch(actions.GetUserObjectives(obj.employeeData));
      })
      .catch(() => {
        dispatch(actions.GetUserObjectives(obj.employeeData));
      });
  };
};

export const objectiveManipulate = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SELECT_OBJECTIVE, data: data });
  };
};

export const validationInSubmit = (submitable) => ({
  type: actionTypes.IN_SUBMIT_VALIDATION,
  submitable: submitable,
});

export const notifyObjective = (obj) => {
  let _data = {
    userObjectiveId: obj.userObjectiveId,
    receiverId: obj.managerData.employeeId,
    description: obj.title,
  };
  return (dispatch) => {
    http
      .post(NOTIFY_OBJECTIVE, {}, _data)
      .then((response) => response.data.data)
      .then(() => {
        dispatch(actions.GetUserObjectives(obj.employeeData));
      })
      .catch(() => {});
  };
};

export const GetAllGrowComments = (data) => {
  let params = data
    ? {
        employeeId: data?.employeeId,
        employeeSubGroupId: data?.subGroupId,
        employeeParentGroupId: data?.parentGroupId,
      }
    : undefined;
  return (dispatch) => {
    http
      .get(GET_ALL_HELP_TO_GROW, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          const data = response.data.reverse();
          dispatch({
            type: actionTypes.GET_ALL_GROW_COMMENT,
            data: data,
            isLoading: false,
          });
        } else {
          dispatch({
            type: actionTypes.GET_ALL_GROW_COMMENT,
            data: [],
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};
export const objectiveProgressStatus = (obj) => {
  let _data = {
    userObjectiveId: obj.userObjectiveId,
    progressStatus: obj.progressStatus,
  };
  return (dispatch) => {
    http
      .post(OBJECTIVE_PROGRESS_STATUS, _data)
      .then((response) => response.data.data)
      .then(() => {
        dispatch(actions.GetUserObjectives(obj.employeeIds));
      })
      .catch(() => {});
  };
};

export const SetObjectiveErrors = (flag) => (dispatch) => dispatch(setValidationFlag(flag));
export const setValidationFlag = (flag) => ({
  type: actionTypes.SET_ERRORS_FLAG,
  errorFlag: flag,
});

export const GetObjectiveReviewer = (data) => (dispatch) => {
  let params = {
    employeeId: data?.employeeId,
    employeeSubGroupId: data?.subGroupId,
    employeeParentGroupId: data?.parentGroupId,
  };
  http
    .get(MANAGER_DATA, {}, { params: params })
    .then((response) => response.data.data)
    .then((response) => {
      if (response)
        dispatch({
          type: actionTypes.SET_OBJECTIVE_REVIEWER,
          objectiveReviewer: response,
        });
    })
    .catch(() => {
      dispatch(updateCurrentCheckInFailed());
    });
};
