import * as actionTypes from "../actions/actionTypes";

const initialState = {
  activePattern: null,
  expanded: null,
  errors: [],
  pageSize: 10,
  currentPage: 1,
  teamData: null,
  directReporteeData: null,
  teamError: false,
  isLoading: true,
  isLoadingTeam: true,
  user: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TEAM:
      return { ...state, ...action.team };
    case actionTypes.GET_TEAM_FAILED:
      return state;
    case actionTypes.CHANGE_PATTERN:
      return {
        ...state,
        activePattern: action.activePattern,
      };
    case actionTypes.EXPANDED:
      return {
        ...state,
        expanded: action.expanded,
      };
    case actionTypes.PAGESIZE:
      return {
        ...state,
        pageSize: action.pageSize || 10,
      };
    case actionTypes.CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage || 1,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
        isLoading: action.isLoading,
      };
    case "START_MY_TEAM_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "CLEAR_MY_TEAM_DATA":
      return {
        ...state,
        activePattern: null,
        expanded: null,
        errors: [],
        teamData: null,
        directReporteeData: null,
        teamError: false,
        isLoading: true,
        isLoadingTeam: true,
        user: null,
      };
    case "CLEAR_PAGINATION_DATA":
      return {
        ...state,
        currentPage: 1,
        pageSize: 10,
      };

    default:
      return state;
  }
};

export default reducer;
