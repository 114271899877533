import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as actions from "store/actions/index";
import { useSelector, useDispatch } from "react-redux";
import storage from "util/storage";
import Spinner from "shared/Spinner/Spinner";
import Feedbacks from "components/ViewFeedback/Feedbacks";
import NoDataFound from "../NodataFound/NoDataFound";
import { noData } from "const/constValue";

const ViewFeedback = () => {
  const dispatch = useDispatch();
  const { feedbacks, isLoading } = useSelector((state) => state.feedback);
  const [data, setData] = useState([]);
  const authorizeFeedback = storage.get("_Afb+_");

  useEffect(() => {
    if (authorizeFeedback) dispatch(actions.fetchFeedbacks(authorizeFeedback));
  }, [authorizeFeedback, dispatch]);

  useEffect(() => {
    if (feedbacks) {
      setData(feedbacks);
    }
  }, [feedbacks]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Container>
        <div className="gap30"></div>
        <Row>
          <Col md={12} className="col-12">
            <h1 className="page-title pb-0">Feedbacks</h1>
            <span className="st-breadcrumb">Feedbacks</span>
          </Col>
          <div className="col-12">
            <div className="my-team-check-in box pb-0 mb-0">
              <div className="box-heading-wrapper">
                <div className="box-heading justify-content-between d-flex align-items-center">
                  <h2>Feedbacks</h2>
                </div>
              </div>
            </div>
            {data ? (
              <Feedbacks feedbackResponse={data} />
            ) : (
              <div className="outer-table-wrapper">
                <div className="box-light-gradient">
                  <div className="custom-table table-theme-1 pt40">
                    <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewFeedback;
