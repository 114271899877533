import React, { Component } from "react";
import CommentsTop from "../CommentBox/CommentsTop";
import Comment from "../CommentBox/Comment";
import { getRatingClass } from "../../util/general";
import style from "./pulse.module.scss";
import moodoMeterStyle from "../UserBox/UserBox.module.scss";

class Pulse extends Component {
  render() {
    let ratingClass;
    if (this.props.question && this.props.question.answer) {
      ratingClass = getRatingClass(this.props.question.answer, moodoMeterStyle);
    }

    return (
      <div>
        <div className={["box", style.idp_box].join(" ")}>
          <div className="box-heading-wrapper">
            <div className="box-heading">
              <h2>Pulse</h2>
              <h3>Your feelings while submitting this OKR Progress</h3>
            </div>
            <div className="box-info"></div>
          </div>
          <div className="box-inner-pad pb-0">
            <div
              className={[
                moodoMeterStyle.current_week_feels_wrapper,
                ratingClass,
                "mt-0"
              ].join(" ")}
            >
              <div className={moodoMeterStyle.current_week_feels_info}>
                Feelings
                <span
                  className={[
                    moodoMeterStyle.current_week_value,
                    ratingClass,
                    "cursor_default"
                  ].join(" ")}
                >
                  {this.props.question?.answer
                    ? this.props.question?.answer
                    : "N/A"}
                </span>
              </div>
            </div>

            <div className="questions_conversion_box">
              <div className="box_inner_title">
                {this.props.question &&
                  this.props.question.employeeMoodQuestion}
              </div>
              <div className="questions_questions_answer_row__3Kqkn">
                <ul className="common_list_items">
                  <li>{this.props.question?.employeeComment || "N/A"}</li>
                </ul>
              </div>
            </div>
            {this.props.status === "REVIEWED" &&
              this.props.question.managerComments &&
              this.props.question.managerComments.length !== 0 && (
                <div className="view_comment_box_wrapper bg-white collapse show">
                  <CommentsTop heading="Reviewer’s Comment(s)" />
                  {this.props.question.managerComments &&
                    this.props.question.managerComments.map((answer, index) => {
                      return (
                        <Comment
                          selectedColor={this.props.selectedColor}
                          key={index}
                          image={answer.commentedBy && answer.commentedBy.displayPicture}
                          empName={answer.commentedBy && answer.commentedBy.empName}
                          commentedOn={answer.commentedOn} 
                          comment={answer.comment}
                          isPrivate={answer.private}
                          privateMsg={`Private message, only visible to you and
                                      ${(answer.private) ? ` ${answer.whoCanView[0].empName}` : ` ${answer.commentedBy.empName}`}`}
                          deletable={false}
                        />
                      );
                    })}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
export default Pulse;
