import React, { useState } from "react";
import style from "../AssignObjectives/AssignObjectives.module.scss";
import { Row, Col, Button } from "react-bootstrap";
import { requiredFieldMissing, submittedSuccessfully } from "../../../util/general";
import http from "../../../util/http";
import { UPDATE_OBJECTIVE_KEY_RESULT } from "../../../util/endpoints";
import * as actions from "../../../store/actions/index";
import { useDispatch } from "react-redux";
import RatingKeyResultDropDown from "./RatingKeyResultDropDown";
import { status } from "const/constValue";

function RatingKeyResults(props) {
  const objValues = props.rowData;
  const keyPoints = objValues && objValues.keyPoints;
  const [hideActivities, setHideActivities] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  let keyPointArr = [];

  const submitAcceptComment = () => {
    keyPoints.forEach((item) => {
      keyPointArr.push({
        keyId: item.keyId,
        rating: item.rating,
        comment: item.comment,
      });
    });

    let detData = {
      userObjectiveId: objValues.userObjectiveId,
      employeeId: objValues.employeeId,
      managerId: objValues.managerData.employeeId,
      isDraft: false,
      keyPoints: keyPointArr,
      subGroupId: objValues.employeeData.subGroupId,
      subGroupName: objValues.employeeData.subGroupName,
      parentGroupId: objValues.employeeData.parentGroupId,
      parentGroupName: objValues.employeeData.parentGroupName,
      managerSubGroupId: objValues.managerData.subGroupId,
      managerSubGroupName: objValues.managerData.subGroupName,
      managerParentGroupId: objValues.managerData.parentGroupId,
      managerParentGroupName: objValues.managerData.parentGroupName,
    };

    if (keyPoints.filter((value) => !value.rating).length > 0) {
      requiredFieldMissing();
    } else {
      setDisabled(true);
      http
        .put(UPDATE_OBJECTIVE_KEY_RESULT, { definedBy: objValues.definedBy }, detData)
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            submittedSuccessfully();
            dispatch(
              actions.GetAssignedUserObjective(objValues.userObjectiveId, objValues.employeeData, objValues.managerData)
            );
            dispatch(actions.GetObjectiveActivity(objValues.userObjectiveId, objValues.employeeData));
          } else {
            setDisabled(false);
          }
        })
        .catch(() => {
          setDisabled(false);
        });
    }
  };

  return (
    <div className="box pb-0">
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>{keyPoints && keyPoints.length} Key Results Aligned</h2>
          {/* <h3>Some text goes here, will be updated soon</h3> */}
        </div>
        <div className="box-info ">
          <span onClick={() => setHideActivities(!hideActivities)}>{hideActivities ? "Show" : "Hide"}</span>
        </div>
      </div>
      {!hideActivities && <div className="box-light-gradient h38"></div>}
      {!hideActivities && (
        <div className="box-inner-pad">
          <Row>
            <Col md={12}>
              <h3 className={style.agrregate_rating}>{objValues?.grade && "Aggregate Rating : " + objValues?.grade}</h3>
              <div className={style.key_result_list_wrapper}>
                {keyPoints &&
                  keyPoints.map((item, index) => (
                    <RatingKeyResultDropDown
                      key={index}
                      rowData={item}
                      objVal={objValues}
                      loggedInUser={props.loggedInUser}
                    />
                  ))}
                {!objValues?.isSubmitted && (
                  <div className={style.btn_group}>
                    <Button
                      type="button"
                      variant={"primary"}
                      disabled={disabled}
                      className={["btn-round", style.btn_save].join(" ")}
                      onClick={(e) => submitAcceptComment(e)}
                    >
                      <strong>Submit</strong>
                    </Button>
                    <Button
                      type="button"
                      variant={"secondary"}
                      className={["btn-round", style.btn_cancel].join(" ")}
                      onClick={props.cancelHandler}
                    >
                      <strong>Cancel</strong>
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

RatingKeyResults.propTypes = {};

export default RatingKeyResults;
