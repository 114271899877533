import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import moment from "moment";
import style from "./Waitingforsubmition.module.scss";
import { IMG_WAITING_FOR_SUBMISSION, IMG_CHECKINS_LAPSED } from "const/imgCost";
import { backGroundColor } from "const/constValue";

class Waitingforsubmition extends Component {
  render() {
    let { pathName, image, name, designation, department, date } = this.props;
    return (
      <div className={["box", style.user_box, "myPageView"].join(" ")}>
        <div className={style.user_info}>
          <div className={style.user_img} style={{ backgroundColor: backGroundColor[1] }}>
            {image?.length > 2 ? <img src={image} alt="" /> : image}
          </div>
          <div className={style.user_details}>
            <div className={style.user_details_left}>
              {pathName ? (
                <Link to={pathName}>
                  <h2 className={style.user_name}>{name}</h2>
                </Link>
              ) : (
                <h2 className={style.user_name}>{name}</h2>
              )}
              <h2 className={style.user_designation}>
                {designation} | {department}
              </h2>
            </div>
            <div className={style.user_details_right}>
              <div className={style.user_date_of_submission}>
                {pathName ? "OKR Progress lapsed" : moment(date) > moment() ? "Yet to submit" : "OKR Progress lapsed"}
              </div>
              {<div className={[style.user_checkin_status, style.pending].join(" ")}>Pending from Member</div>}
            </div>
          </div>
        </div>
        <Row className={style.waitingsubmitionwrapper}>
          <Col xs={12} className={style.waitingsubmitioncont}>
            <div className={style.nodatafoundimg}>
              <div className="folder-wrapper-overlay-1"></div>
              <ReactSVG src={pathName ? IMG_CHECKINS_LAPSED : IMG_WAITING_FOR_SUBMISSION} title="" alt="" />
            </div>
            <div className={style.waitingsubmitiontitle}>
              {pathName
                ? "Oops! You have missed this OKR Progress"
                : moment(date) > moment()
                ? `Waiting for ${name} to submit`
                : `Oops! ${name} has missed this OKR Progress`}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Waitingforsubmition;
