import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import ConfirmationPopup from "components/Objectives/ConfirmationPopup";
import RedirectionConfirmationPopup from "components/Objectives/ConfirmationPopup";
import QuestionTemplate from "components/Performance/CycleMemberProfile/QuestionTemplate/QuestionQuizTemplate";
import TimeOverPopup from "components/TimeOverPopup/TimeOverPopup";
import NoDataPopup from "components/TimeOverPopup/TimeOverPopup";
import { IC_QUIZ_TIMER, IMG_WAITING_FOR_SUBMISSION } from "const/imgCost";
import routePath from "const/routePath";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import * as actions from "store/actions/index";
import style from "./EmployeeQuizOnGoing.module.scss";
import useNetworkStatus from "../../../custom_hooks/useNetworkStatus";
import useInterval from "@use-it/interval";

export default function EmployeeQuizOnGoing(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isOnline } = useNetworkStatus();
  const {
    employeeQuizData,
    employeeRefreshQuizData,
    questionsIds,
    quizStartDate,
    isFinish,
    isQuizStarted,
    isBackDisable,
    isRedirectPopup,
    isQuizResume,
    employeeResumeQuizData,
  } = useSelector((state) => state.campaign);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [redirectionConfirmPopup, setredirectionConfirmPopup] = useState(false);
  const [timeOverPopup, setTimeOverPopup] = useState(false);
  const [noDataPopup, setNoDataPopup] = useState(false);
  const [showFinishPopUp, setShowFinishPopUp] = useState(false);
  const [prevClick, setPrevClick] = useState(false);
  const [nextClick, setNextClick] = useState(false);
  const btnNumber = employeeQuizData ? 1 : employeeRefreshQuizData?.pageNumber;
  const [buttonNumber, setButtonNumber] = useState(btnNumber);
  const [redirectionLocation, setRedirectionLocation] = useState();
  const [globalCampaignId, setGlobalCampaignId] = useState();
  const [gQuestionTemplateId, setGQuestionTemplateId] = useState();
  const [gQuestionTemplateName, setGQuestionTemplateName] = useState();
  const [gNumber_Of_Attemptes_done, setGNumber_Of_Attemptes_done] = useState();
  const [gQuestionId, setGQuestionId] = useState();
  const [delay, setDelay] = useState();

  const prevLocation = useLocation();
  const numberofTotalquestion = employeeQuizData
    ? employeeQuizData?.number_Of_Questions
    : employeeRefreshQuizData?.number_Of_Questions;
  const [offSet, setOffSet] = useState(numberofTotalquestion > 10 ? 2 : 1);
  const [progressBarValue, setProgressBarValue] = useState(
    employeeRefreshQuizData?.progressBarPercentage
      ? employeeRefreshQuizData?.progressBarPercentage
      : numberofTotalquestion <= 10
        ? Math.floor(((buttonNumber * offSet) / numberofTotalquestion) * 100)
        : numberofTotalquestion % 2 === 0
          ? Math.floor(((buttonNumber * offSet) / numberofTotalquestion) * 100)
          : Math.floor(((buttonNumber * offSet - 1) / numberofTotalquestion) * 100)
  );
  const headerText = "Are you sure you want to complete the quiz?";
  const subText = "Your are unable to edit the quiz once it has been marked as finished";
  let campId = props.match.params.id;

  useEffect(() => {
    if (document.referrer === "") {
      dispatch(actions.getQuizRefreshQuestionsEmployeeView(campId, -1, null, null, null, null, null, null));
    }
  }, []);

  /** Auto redirection when user clicked on back button of quiz result */
  useEffect(() => {
    if (isFinish && !isQuizStarted && !isBackDisable && !isRedirectPopup) {
      history.push(`${routePath.QUIZ}`);
    }
    dispatch({ type: "IS_BACK_DISABLE", payload: true });
    dispatch({ type: "ISQUIZSTARTED", payload: true });
    if (
      (employeeQuizData === null && employeeRefreshQuizData === null) ||
      employeeRefreshQuizData?.questions === null
    ) {
      setNoDataPopup(true);
    }

    return () => {
      dispatch({ type: "IS_BACK_DISABLE", payload: false });
    };
  }, []);

  useEffect(() => {
    /** resume redirection when focus loss like clicked on teams , new tab */

    const redirection = () => {
      if (document.hidden) {
        history.push({
          pathname: `${routePath.START_QUIZ}/${campId}`,
          state: { isResume: true },
        });
        dispatch({ type: "IS_QUIZ_RESUME", payload: true });
      }
    };
    document.addEventListener("visibilitychange", redirection);
    return () => {
      document.removeEventListener("visibilitychange", redirection);
    };
  }, []);

  useEffect(()=>{
    /** resume redirection when network loss */
    if (!isOnline && isQuizStarted) {
      history.push({
        pathname: `${routePath.START_QUIZ}/${campId}`,
        state: { isResume: true },
      });
      dispatch({ type: "IS_QUIZ_RESUME", payload: true });
    }
  })

  useEffect(() => {
    /** back button disable when quiz is going on*/
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === "POP" && isQuizStarted) {
        history.replace(history.location.pathname /* the new state */);
      }
    };
  }, [history]);

  useEffect(() => {
    /** collecting data from two different selectors*/

    let globalCampaignId = employeeQuizData ? employeeQuizData?.campaignId : employeeRefreshQuizData?.campaignId;
    setGlobalCampaignId(globalCampaignId);
    let gQuestionTemplateId = employeeQuizData
      ? employeeQuizData?.questionTemplateId
      : employeeRefreshQuizData?.questionTemplateId;
    setGQuestionTemplateId(gQuestionTemplateId);
    let gQuestionTemplateName = employeeQuizData
      ? employeeQuizData?.questionTemplateName
      : employeeRefreshQuizData?.questionTemplateName;
    setGQuestionTemplateName(gQuestionTemplateName);
    let gNumber_Of_Attemptes_done = employeeQuizData
      ? employeeQuizData?.number_Of_Attemptes_done + 1
      : employeeRefreshQuizData?.attemptNumber;
    setGNumber_Of_Attemptes_done(gNumber_Of_Attemptes_done);
    let total_time = employeeRefreshQuizData ? employeeRefreshQuizData?.timeStamp : employeeQuizData?.total_Time * 60;
    setDelay(total_time);
    setButtonNumber(employeeRefreshQuizData?.pageNumber);
    setOffSet(employeeRefreshQuizData?.offSet);
    let progressbarValue = employeeRefreshQuizData?.progressBarPercentage;
    if (employeeQuizData === null) {
      setProgressBarValue(progressbarValue);
    }

    if (
      (employeeQuizData === null && employeeRefreshQuizData === null) ||
      employeeRefreshQuizData?.questions === null
    ) {
      setNoDataPopup(true);
    }
  }, [employeeQuizData, employeeRefreshQuizData]);

  useEffect(()=>{
    let question_ids = questionsIds ? questionsIds : employeeRefreshQuizData?.questionsIds
    setGQuestionId(question_ids);
  },[questionsIds,employeeRefreshQuizData])

  useEffect(() => {
    window.sessionStorage.setItem("started", true);
    window.sessionStorage.setItem("isRefreshed", true);
    let isRefreshed = JSON.parse(window.sessionStorage.getItem("isRefreshed"));

    /** detecting refresh case and dispatching action to get latest data*/
    if (window.performance && isRefreshed) {
      if (performance.navigation.type == 1) {
        dispatch({ type: "IS_REDIRECT_POPUP", payload: false });
        dispatch(actions.getQuizRefreshQuestionsEmployeeView(campId, -1, null, null, null, null, null, null));
      }
    }

    return () => {
      window.sessionStorage.removeItem("isRefreshed");
    };
  }, []);

  useEffect(() => {
    /** detecting when user redirecting on tabs provided by application*/
    const unlisten = history.listen((location) => {
      if (location.pathname !== prevLocation.pathname && !redirectionConfirmPopup && !confirmPopup) {
        history.push(prevLocation.pathname);
        setRedirectionLocation(location.pathname);
        if (
          (employeeQuizData !== null && employeeRefreshQuizData !== null) ||
          employeeRefreshQuizData?.questions !== null
        ) {
          setredirectionConfirmPopup(true);
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [prevLocation, redirectionConfirmPopup, confirmPopup]);

  /** timer logic started */
  let minutes = Math.floor(delay / 60);
  let seconds = Math.floor(delay % 60);
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelay(delay - 1);
      if (delay == 1) {
        setShowFinishPopUp(true);
        setTimeOverPopup(true);
      }
    }, 1000);
    if (delay === 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [delay]);
  useEffect(() => {
    /** auto submit when time is finished */
    const timer = setTimeout(() => {
      setShowFinishPopUp(false);
      if (showFinishPopUp) {
        dispatch(
          actions.submitEmployeeQuiz(globalCampaignId, gNumber_Of_Attemptes_done, history, routePath.QUIZ_RESULT, {
            gQuestionId,
            quizStartDate,
          })
        );
        window.sessionStorage.removeItem("started");
        dispatch({ type: "ISFINISH", payload: true });
        dispatch({ type: "ISQUIZSTARTED", payload: false });
      }
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [showFinishPopUp]);

  useEffect(() => {
    /** auto redirect when no data found */
    const timer1 = setTimeout(() => {
      setNoDataPopup(false);
      if (noDataPopup) {
        history.push({
          pathname: routePath.QUIZ,
        });
        dispatch({ type: "ISFINISH", payload: true });
        dispatch({ type: "ISQUIZSTARTED", payload: false });
        window.sessionStorage.removeItem("started");
      }
    }, 5000);
    return () => {
      clearTimeout(timer1);
    };
  }, [noDataPopup]);

  /** timer logic end */

  /** save answer api while selecting ansers */
  const saveQuestionAnswer = (data) => {
    dispatch(actions.AutoSaveQuizTemplate(data, globalCampaignId, gQuestionTemplateId, gNumber_Of_Attemptes_done));
  };

  const previousHandler = () => {
    setPrevClick(true);
    setNextClick(false);
    if (buttonNumber === 1) {
      setButtonNumber(1);
    } else {
      let previousButtonCount = buttonNumber - 1;
      setButtonNumber(previousButtonCount);
      let progressBarPercentage =
        numberofTotalquestion <= 10
          ? Math.floor(((previousButtonCount * offSet) / numberofTotalquestion) * 100)
          : numberofTotalquestion % 2 === 0
            ? Math.floor(((previousButtonCount * offSet) / numberofTotalquestion) * 100)
            : Math.floor(((previousButtonCount * offSet - 1) / numberofTotalquestion) * 100);
      setProgressBarValue(progressBarPercentage);
      dispatch(
        actions.getQuizRefreshQuestionsEmployeeView(
          globalCampaignId,
          delay,
          buttonNumber - 1,
          offSet,
          gNumber_Of_Attemptes_done,
          progressBarPercentage,
          null,
          null
        )
      );
    }
  };
  const NextHandler = () => {
    setPrevClick(false);
    setNextClick(true);
    if (buttonNumber <= numberofTotalquestion) {
      let nextButtonCount = buttonNumber + 1;
      setButtonNumber(nextButtonCount);
      let progressBarPercentage =
        numberofTotalquestion <= 10
          ? Math.floor(((nextButtonCount * offSet) / numberofTotalquestion) * 100)
          : numberofTotalquestion % 2 === 0
            ? Math.floor(((nextButtonCount * offSet) / numberofTotalquestion) * 100)
            : Math.floor(((nextButtonCount * offSet - 1) / numberofTotalquestion) * 100);
      setProgressBarValue(progressBarPercentage);
      dispatch(
        actions.getQuizRefreshQuestionsEmployeeView(
          globalCampaignId,
          delay,
          buttonNumber + 1,
          offSet,
          gNumber_Of_Attemptes_done,
          progressBarPercentage,
          null,
          null
        )
      );
    }
  };

  /** handle collecting data after every one minute when user started quiz */
  const startInterval = useInterval(() => {
    if (!prevClick && !nextClick) {
      startIntervalAPI();
    }
  }, 60000);

  /** handle collecting data after every one minute when user clicked on previous button */
  const prevInterval = useInterval(() => {
    if (prevClick && !nextClick) {
      prevIntervalAPI();
    }
  }, 60000);
  /** handle collecting data after every one minute when user clicked on next button */
  const nextInterval = useInterval(() => {
    if (!prevClick && nextClick) {
      nextIntervalAPI();
    }
  }, 60000);

  useEffect(() => {
    /** clearing prev button interval when user clicked on next button*/
    if (nextClick) {
      clearInterval(startInterval);
      clearInterval(prevInterval);
    }
    /** clearing next button interval when user clicked on previous button*/
    if (prevClick) {
      clearInterval(startInterval);
      clearInterval(nextInterval);
    }
  }, [nextClick, prevClick]);

  const startIntervalAPI = () => {
    dispatch(
      actions.setQuizInformationInterval(
        globalCampaignId,
        delay,
        buttonNumber,
        offSet,
        gNumber_Of_Attemptes_done,
        progressBarValue
      )
    );
  };

  const nextIntervalAPI = () => {
    if (buttonNumber <= numberofTotalquestion) {
      dispatch(
        actions.setQuizInformationInterval(
          globalCampaignId,
          delay,
          buttonNumber,
          offSet,
          gNumber_Of_Attemptes_done,
          progressBarValue
        )
      );
    }
  };

  const prevIntervalAPI = () => {
    dispatch(
      actions.setQuizInformationInterval(
        globalCampaignId,
        delay,
        buttonNumber,
        offSet,
        gNumber_Of_Attemptes_done,
        progressBarValue
      )
    );
  };

  const submitHandler = () => {
    if (globalCampaignId && gQuestionId && quizStartDate) {
      dispatch(
        actions.submitEmployeeQuiz(globalCampaignId, gNumber_Of_Attemptes_done, history, routePath.QUIZ_RESULT, {
          gQuestionId,
          quizStartDate,
        })
      );
      dispatch({ type: "ISFINISH", payload: true });
      dispatch({ type: "ISQUIZSTARTED", payload: false });
      window.sessionStorage.removeItem("started");
    }
  };
  const redirectSubmitHandler = () => {
    if (globalCampaignId && gQuestionId && quizStartDate) {
      dispatch(
        actions.submitEmployeeQuiz(globalCampaignId, gNumber_Of_Attemptes_done,null,null, {
          gQuestionId,
          quizStartDate,
        })
      );
      history.push(`${redirectionLocation}`);
      dispatch({ type: "ISFINISH", payload: true });
      window.sessionStorage.removeItem("started");
      dispatch({ type: "ISQUIZSTARTED", payload: false });
    }
  };
  const hideRedirectionPopup = () => {
    setConfirmPopup(false);
    dispatch({ type: "IS_REDIRECT_POPUP", payload: false });
    dispatch({ type: "ISQUIZSTARTED", payload: true });
  };

  return (
    <div>
      <SubNavigation type="OKR Progress" />
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">OKR Progress</h1>
            <span className="st-breadcrumb">
            OKR Progress
              <span>Quiz</span>
            </span>
          </Col>
          <Col lg={12}>
            <progress id="file" value={progressBarValue} max="100" className={style.progressBar}>
              {" "}
            </progress>
          </Col>
          <Col lg={12}>
            <div className="box">
              <div className="mt-30">
                <span className={style.currentQuestionsHeading}>
                  Questions:{" "}
                  <span className={style.questionNumber}>
                    {buttonNumber * offSet > numberofTotalquestion ? numberofTotalquestion : buttonNumber * offSet}/
                    {numberofTotalquestion}
                  </span>
                </span>
                <span className={style.timeTracking}>
                  Time Tracking
                  <span className={style.timeTrackingIcon}>
                    <ReactSVG src={`${IC_QUIZ_TIMER}`} /> <span className={style.timeNumber} id="time"></span>
                    {minutes}:{seconds} Mins
                  </span>
                </span>
              </div>
              <span className={style.QuestionTemplate}>
                <QuestionTemplate
                  handleTextBox={() => {}}
                  handleRating={() => {}}
                  handleRatingComment={() => {}}
                  handleRadioComment={saveQuestionAnswer}
                  handleRadio={saveQuestionAnswer}
                  handleRadioOther={saveQuestionAnswer}
                  handleCheckBox={saveQuestionAnswer}
                  handleCheckBoxOther={saveQuestionAnswer}
                  handleCheckBoxComment={() => {}}
                  handleDropDown={() => {}}
                  handleDropDownComment={() => {}}
                  handleDropDownOther={() => {}}
                  handleScale={() => {}}
                  handleScaleComment={() => {}}
                  handleRankComment={() => {}}
                  handleRank={() => {}}
                  handleNps={() => {}}
                  handleNpsComment={() => {}}
                  // employeeId={employeeIds.employeeId}
                  // questionTemplateErrors={questionTemplateErrors}
                  isSubmitted={false}
                  questionsAnswerTemplate={employeeRefreshQuizData?.questions}
                  templateName={gQuestionTemplateName}
                  uploadFile={() => {}}
                  viewTemplateName={true}
                  deleteFile={() => {}}
                  isQuiz={true}
                />
              </span>
            </div>
          </Col>
          {buttonNumber === 1 ? ( numberofTotalquestion === 1 ? (
            <Col>
              <button type="button" className={style.firstPageButton} onClick={() => setConfirmPopup(true)}>
                <strong>Finish</strong>
              </button>
            </Col>) : (
            <Col>
              <button type="button" className={style.firstPageButton} onClick={NextHandler}>
                <strong>Next</strong>
              </button>
            </Col>
          )
          ) : (buttonNumber * offSet > numberofTotalquestion ? numberofTotalquestion : buttonNumber * offSet) ===
            numberofTotalquestion ? (
            <Col>
              <button type="button" className={style.backButton} onClick={previousHandler}>
                <strong>Previous</strong>
              </button>
              <button type="button" className={style.nextButton} onClick={() => setConfirmPopup(true)}>
                <strong>Finish</strong>
              </button>
            </Col>
          ) : (
            <Col>
              <button type="button" className={style.backButton} onClick={previousHandler}>
                <strong>Previous</strong>
              </button>
              <button type="button" className={style.nextButton} onClick={NextHandler}>
                <strong>Next</strong>
              </button>
            </Col>
          )}
        </Row>
        <ConfirmationPopup
          show={confirmPopup || isRedirectPopup && !isQuizResume}
          submit={submitHandler}
          hide={hideRedirectionPopup}
          headerText={headerText}
          subText={subText}
        />
        <RedirectionConfirmationPopup
          show={redirectionConfirmPopup}
          submit={redirectSubmitHandler}
          hide={() => setredirectionConfirmPopup(false)}
          headerText={headerText}
          subText={subText}
        />
        <TimeOverPopup
          show={timeOverPopup}
          submit={() => history.push(`${routePath.QUIZ_RESULT}`)}
          hide={() => setTimeOverPopup(false)}
          subText={"Sorry, your Quiz time is up. Better luck next time!"}
          path={IMG_WAITING_FOR_SUBMISSION}
        />
        <NoDataPopup
          show={noDataPopup}
          submit={() => history.push(`${routePath.QUIZ}`)}
          hide={() => setNoDataPopup(false)}
          subText={"Sorry, Some issue found in the data. You have to start quiz again"}
          path={IMG_WAITING_FOR_SUBMISSION}
        />
      </Container>
    </div>
  );
}
