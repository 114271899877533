import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTags from "react-tag-autocomplete";
import moment from "moment";
import style from "./CreateObjective.module.scss";
import * as actions from "../../../store/actions/index";
import KeyResult from "../KeyResult/KeyResult";
import OverlayError from "../OverlayError/OverlayError";
import PreviouslyCreateObjective from "./PreviouslyCreateObjective";
import SubmitBox from "../../SubmitBox/SubmitBox";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";
import { errorToast, requiredFieldMissing, successToast } from "util/general";
import storage from "util/storage";
import { successMessage } from "const/constValue";
import Select from "react-select";
import Spinner from "shared/Spinner/Spinner";
const CreateObjective = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = storage.getUser();
  const {
    suggestionTags,
    draftObjective,
    objectiveAdded,
    objectiveValidation,
    keyResultErrors,
    createObjectiveResponse,
    keyWeightage,
    isLoading,
    predefinedObjective,
  } = useSelector((state) => state.objectives);
  const targetTitle = useRef(null);
  const [keyCount, setKeyCount] = useState(0);
  const [isOther, setIsOther] = useState(false);
  const [errors,setErrors] = useState({});
  const defaultKeyPoint = {
    description: "",
    type: "",
    start: "",
    end: "",
    weightage: 1,
    stretch: false,
  };

  useEffect(() => {
    dispatch(actions.fetchTag());
    dispatch(actions.DraftObjective());
    dispatch(actions.getPrdefinedObjective(loggedInUser?.podInformation,loggedInUser?.country));
  }, [dispatch, objectiveAdded]);

  useEffect(() => {
    if (Object.keys(draftObjective).length > 0 || draftObjective) {
      let errorsArr = [...objectiveValidation];

      if (document.getElementById("objective") == "" || draftObjective.title === null) {
        let index = errorsArr.findIndex((x) => x.key === "title");
        index === -1 && errorsArr.push({ key: "title", showError: false });
      } else {
        errorsArr = errorsArr.filter((item) => item.key !== "title");
      }

      if (draftObjective.keyPoints == null || keyResultErrors.length > 0) {
        let index = errorsArr.findIndex((x) => x.key === "keyPoints");
        index === -1 && errorsArr.push({ key: "keyPoints", showError: false });
      } else {
        errorsArr = errorsArr.filter((item) => item.key !== "keyPoints");
        setKeyCount(draftObjective.keyPoints.length);
      }
      dispatch(actions.SetObjectiveValidation(errorsArr));
    }
  }, [draftObjective, keyResultErrors, dispatch]);

  const addError = (key) => {
    let errors = [...objectiveValidation];
    let error = { key: key, showError: true };

    let index = errors.findIndex((x) => x.key === key);
    index === -1 ? errors.push(error) : (errors[index].showError = true);
    dispatch(actions.SetObjectiveValidation(errors));
  };

  const removeError = (key) => {
    let errors = null;
    errors = objectiveValidation.filter((item) => item.key !== key);
    dispatch(actions.SetObjectiveValidation(errors));
  };

  const objectiveHandler = (item) => {
    setErrors({ ...errors, OKR_title: false });
    if(item?.value === "Other"){
      setIsOther(true);
    } else {
      setIsOther(false);
    }
    if (draftObjective.objectiveId && item.value.trim() && item?.value !== "Other") {
      dispatch(
        actions.AddObjective({
          objectiveId: draftObjective.objectiveId,
          title: item.value.trim(),
          weightage: item.weightage,
          bandWiseObjectiveId: item.bandWiseObjectiveId,
          band: item.band,
          isOther: false,
          description:item.description
        })
      );
      removeError("title");
    } else if (!draftObjective.objectiveId && item.value && item?.value !== "Other") {
      dispatch(actions.AddObjective({ title: item.value, weightage: item.weightage,description: item.description,bandWiseObjectiveId: item.bandWiseObjectiveId,band: item.band,isOther: false }));
      removeError("title");
    } else if (draftObjective.objectiveId && item?.value === "Other"){
      dispatch(actions.AddObjective({ objectiveId: draftObjective.objectiveId, isOther: true }));
      removeError("title");
    } else if (!draftObjective.objectiveId && item?.value === "Other"){
      dispatch(actions.AddObjective({ isOther: true }));
      removeError("title");
    } else {
      addError("title");
    }
  };

  const otherObjectiveHandler = (event, objectiveId) => {
    if (objectiveId && event.target.value.trim()) {
      dispatch(
        actions.AddObjective({
          title: event.target.value.trim(),
          objectiveId: draftObjective.objectiveId,
        })
      );
      removeError("title");
    } else if (!objectiveId && event.target.value) {
      dispatch(actions.AddObjective({ title: event.target.value }));
      removeError("title");
    } else {
      addError("title");
    }
  }

  const weightageHandler = (event, objectiveId) => {
    if (objectiveId && event.target.value.trim()) {
      dispatch(
        actions.AddObjective({
          weightage: event.target.value.trim(),
          objectiveId: draftObjective.objectiveId,
        })
      );
      removeError("weightage");
    } else if (!objectiveId && event.target.value) {
      dispatch(actions.AddObjective({ weightage: event.target.value }));
      removeError("weightage");
    } else {
      addError("weightage");
    }
  }

  const descriptionHandler = (event, objectiveId) => {
    if (objectiveId) {
      // && event.target.value.trim()
      dispatch(
        actions.AddObjective({
          objectiveId: objectiveId,
          description: event.target.value.trim(),
        })
      );
    } else {
      dispatch(actions.AddObjective({ description: event.target.value }));
    }
  };

  const objectiveAddedHandler = (flag) => {
    dispatch(actions.ObjectiveAdded(flag));
  };
  
  const saveObjectiveHandler = (objectiveId) => {
    if(draftObjective?.title === null){
      setErrors({ ...errors, OKR_title: true })
      requiredFieldMissing();
      return;
    }
    if (document.getElementById("otherTitle")?.value === "") {
      setErrors({ ...errors, OKR_otherTitle: true })
      requiredFieldMissing();
      return;
    }
    if (draftObjective?.weightage === "") {
      setErrors({ ...errors, ORK_weightage: true })
      requiredFieldMissing();
      return;
    }
    if (draftObjective?.weightage > 100 || draftObjective?.weightage < 1) {
      errorToast("weightage should not be less than 1 and more than 100");
      return;
    }

    if (keyWeightage < 100) {
      errorToast(`Add more ${100 - keyWeightage}% weightage`);
    } else if (keyWeightage > 100) {
      errorToast(`Delete ${keyWeightage - 100}% weightage`);
    }

    if (objectiveValidation.length > 0) {
      const submittedErrors = objectiveValidation.map((err) => ({
        ...err,
        showError: true,
      }));

      dispatch(actions.SetObjectiveValidation(submittedErrors));
      requiredFieldMissing();

      return;
    } else {
      if (keyWeightage < 100) {
        errorToast(`Add more ${100 - keyWeightage}% weightage`);
      } else if (keyWeightage > 100) {
        errorToast(`Delete ${keyWeightage - 100}% weightage`);
      } else {
        successToast(successMessage.OBJECTIVE_CREATED);
        dispatch(actions.SubmitObjective(objectiveId));
        history.push({
          pathname: routePath.ALL_OBJECTIVES,
          query: { tab: "MyCreatedObjective" },
        });
      }
    }
  };

  const previouslyCreateObjective = createObjectiveResponse.sort(
    (a, b) => moment(b.createdDate) - moment(a.createdDate)
  );

  const handleDelete = (i) => {
    //draftObjective.tags.length > 0
    if (i !== -1) {
      const tagId = draftObjective.tags[i].id;
      draftObjective.tags.splice(i, 1);
      dispatch(actions.DeleteTags([tagId], draftObjective.objectiveId, true));
    }
  };

  const handleAddition = (tag) => {
    let index = draftObjective.tags && draftObjective.tags.findIndex((x) => x.name === tag.name);
    if (index === -1 || index === undefined || index === null) {
      if (!draftObjective.objectiveId) {
        dispatch(actions.AddObjective({ tags: { ...tag } }));
      } else {
        dispatch(
          actions.AddObjective({
            objectiveId: draftObjective.objectiveId,
            tags: { ...tag },
          })
        );
      }
    }
  };

  const handleOnKeyPress = (e) => {
    e = e || window.event;
    let charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    let charStr = String.fromCharCode(charCode);
    if (e.charCode < 48 || !charStr.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69) {
      e.preventDefault();
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: errors.OKR_title && '1px solid #ff8b67',
    }),
  };
  return (
    <div>
      {isLoading && <Spinner />}
      <SubNavigation type="Objectives" />
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">Objectives</h1>
            <span className="st-breadcrumb">
              Objectives
              <span>Create Objective</span>
            </span>
          </Col>
          <Col md={6} className="text-right">
            <div className="draftSaved">
              <span className="label">Draft Saved:</span>
              {draftObjective && (
                <span className="data">{moment(draftObjective.draftSavedDate).format("ddd D MMM h:mm a")}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <div className={style.boxWrapper}>
            <div className="box">
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <h2>Create New Objective</h2>
                  <h3>Objectives should be qualitative and aspirational</h3>
                </div>
                <div className="box-info d-none">
                  <span></span>
                </div>
              </div>
              <div className="box-inner-pad">
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                    <div className={[style.create_objective_title, "form-group"].join(" ")} >
                      <label className="form-label">
                        What is your objective?<span className="text-danger"> * </span>
                      </label>
                      </div>
                      <div className="tags_wrapper">
                       <Select
                          options={predefinedObjective}
                          // className={errors?.subGroups ? "limit-reached" : "multi-select"}
                          className="multi-select"
                          styles={customStyles}
                          classNamePrefix="select"
                          // selectedValues={{label : draftObjective?.title} || ""}
                          value = {{label : draftObjective?.isOther ? "Other" : draftObjective?.title} || ""}
                          onChange={objectiveHandler}
                        />
                      </div>
                      <OverlayError target={targetTitle} objectiveValidation={objectiveValidation} errorFor="title" />
                  </Col>
                </Row>
                {draftObjective?.isOther  &&
                  <Row className={style.create_objective_wrapper}>
                    <Col className={style.create_objective_data_wrapper}>
                      <Form.Group className={style.formGroup}>
                        <Form.Label>
                          Add Objective Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="otherTitle"
                          maxLength="200"
                          className={[(errors && errors.OKR_otherTitle ? style.errorBox : "" ), style.weightageBox ].join(" ")}
                          defaultValue={draftObjective?.isOther ? isOther ? "" : draftObjective?.title : draftObjective?.title || ""}
                          // defaultValue={draftObjective?.title || ""}
                          onBlur={(e) => otherObjectiveHandler(e, draftObjective?.objectiveId)}
                          onChange={(e) => {
                            if (e.target.value.trim()) {
                              setErrors({ ...errors, OKR_otherTitle: false });
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                }
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                  <Form.Group className={style.formGroup}>
                    <Form.Label>
                    Weightage<span className="text-danger">*</span>
                    </Form.Label>
                        <Form.Control
                          type="number"
                          id="weightage"
                          maxLength="200"
                          disabled={draftObjective?.isOther ? false : true}
                          onWheel={(e) => e.target.blur()} // stopping changing numbers with mouse scrolling 
                          className={[(errors && errors.ORK_weightage ? style.errorBox : "" ), style.weightageBox ].join(" ")}
                          defaultValue={draftObjective?.isOther ? isOther ? "" : draftObjective?.weightage : draftObjective?.weightage || ""}
                          onBlur={(e) => weightageHandler(e, draftObjective?.objectiveId)}
                          onChange={(e) => {
                            if (e.target.value.trim()) {
                              setErrors({ ...errors, ORK_weightage: false });
                            }
                          }}
                          onKeyPress={handleOnKeyPress}
                          onKeyDown={handleOnKeyDown}
                        />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                    <Form.Group className={style.formGroup}>
                    <Form.Label>Description</Form.Label>
                      <div className={[style.textbox_wrapper, "border-0"].join(" ")}>
                        <div className="textarea-wrapper ">
                          <Form.Control
                            as="textarea"
                            maxLength="512"
                            placeholder="Add Description"
                            onBlur={(e) => descriptionHandler(e, draftObjective.objectiveId)}
                            defaultValue={draftObjective?.isOther ? isOther ? "" : draftObjective?.description : draftObjective?.description || ""}
                          />
                        </div>
                      </div>
                      </Form.Group>
                  </Col>
                </Row>
                <Row className={[style.create_objective_wrapper, "tags_wrapper"].join(" ")}>
                  <Col className={style.create_objective_data_wrapper}>
                    <div className="form-group">
                      <label className="form-label"> Tags </label>
                      <div className={style.textbox_wrapper}>
                        <ReactTags
                          noSuggestionsText="Click enter to add new tag"
                          placeholder="Add new tag"
                          tags={draftObjective.tags ? draftObjective.tags : []}
                          allowNew={true}
                          suggestions={suggestionTags || []}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          classNames={
                            { searchInput: 'react-tags__search-input customPlaceholder' }
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="box-inner-pad">
                <Row className={style.create_objective_wrapper}>
                  <Col className={style.create_objective_data_wrapper}>
                    <div className="form-group">
                      <label className="form-label">Define Key Results</label>
                      {draftObjective &&
                        draftObjective.keyPoints &&
                        draftObjective.keyPoints.map((item) => (
                            <KeyResult
                              isEditable={true}
                              keyPointLength={draftObjective.keyPoints.length}
                              style={style}
                              key={item.keyId}
                              index={item.keyId}
                              keyPoint={ {
                                keyId: item.keyId,
                                description: item.description,
                                type: item.type,
                                start: item.start,
                                end: item.end,
                                weightage: item.weightage,
                                stretch: item.stretch,
                              }}
                              objectiveId={draftObjective.objectiveId}
                              isUpdate={false}
                            />
                          ))}
                      {(objectiveAdded || keyCount < 1) && (
                        <KeyResult
                          isEditable={false}
                          style={style}
                          index={"create"}
                          keyPoint={defaultKeyPoint}
                          objectiveId={draftObjective.objectiveId}
                          isUpdate={true}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    <span // || keyError
                      className={["text_link", keyCount < 1 || objectiveAdded ? "disabled" : ""].join(" ")}
                      onClick={() => objectiveAddedHandler(true)}
                    >
                      Add New Key Result
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
            </div>
            <SubmitBox
              handleSubmit={() => saveObjectiveHandler(draftObjective.objectiveId)}
              name="Create"
              updatedDate={draftObjective.draftSavedDate}
            />
          </Col>
          <Col lg={4}>
            <PreviouslyCreateObjective previouslyCreateObjective={previouslyCreateObjective} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateObjective;
