import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import style from "./RejectMessageModal.module.scss";
import moment from "moment";
import CommentsTop from "../../CommentBox/CommentsTop";
import Comment from "../../CommentBox/Comment";
import { backGroundColor } from "../../../const/constValue";

function RejectMessageModal(props) {
  let { objective, reasonOfComment } = props;
  let { createdBy, createdDate, rejectedComment, whatChanged } = props.activityData;
  return (
    <>
      <Modal show={props.show} onHide={() => props.hide()} size="lg" animation={false} className="custom_modal_popup">
        <div className="custom_modal_container custom_lg_modal_container">
          <Modal.Header className={style.mHeader}>
            <Modal.Title>Objective by {objective && objective.employeeData.displayName}</Modal.Title>
          </Modal.Header>

          <Modal.Body className={style.mBody}>
            <div className="box-light-gradient"></div>
            <div className={style.activites_item}>
              <div className={style.user_icon} style={{ backgroundColor: backGroundColor[1] }}>
                {createdBy.displayPicture && createdBy.displayPicture.length > 2 ? (
                  <img src={createdBy.displayPicture} alt="pic" />
                ) : (
                  createdBy.displayPicture
                )}
              </div>
              <div className={style.activites_data}>
                <span>{createdBy.empName + " "}</span>
                {reasonOfComment}
                <div className={style.activites_hours}>{createdDate && moment(createdDate).fromNow()}</div>
              </div>
              <div className={[style.reject_comment_wrapper, "view_comment_box_wrapper", "bg-white"].join(" ")}>
                <CommentsTop heading="Reviewer's Comment(s)" />
                <Comment
                  selectedColor={backGroundColor[1]}
                  image={createdBy && createdBy.displayPicture}
                  empName={createdBy && createdBy.empName}
                  commentedOn={createdDate}
                  comment={
                    whatChanged === "OBJ_FEEDBACK_STATUS"
                      ? objective.inValidateComment
                      : whatChanged === "SELF_OBJECTIVE_REJECTED"
                      ? objective.rejectedComment
                      : rejectedComment
                  }
                  isPrivate={false}
                  deletable={false}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="custom_modal_button justify-content-start">
            <Button variant="primary" className="fs-500" onClick={() => props.hide()}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

RejectMessageModal.propTypes = {
  objective: PropTypes.object,
};

export default RejectMessageModal;
