import React, { useState, useEffect, useRef } from "react";
import styles from "./reviewer-profile.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { backGroundColor } from "../../const/constValue";
/**
 * props: reviewer
 */
export default function ReviewerProfile({ managerList }) {
  const length = managerList && managerList.length;
  /*start slider code*/
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  let slider1 = useRef(null);
  let slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, []);

  const createUpperSlider = () => {
    let slider = [];
    if (managerList?.length) {
      managerList.map((manager, index) => {
        slider.push(
          <div key={index}>
            <div className={styles.reviewer_profile_name}>{manager.empName}</div>
            <div className={styles.reviewer_profile_deg}>{manager.designation}</div>
          </div>
        );
        return manager;
      });
    }
    return slider;
  };

  const createLowerSlider = () => {
    let slider = [];
    if (managerList?.length) {
      managerList.map((manager, index) => {
        slider.push(
          <div className={styles.reviewer_profile_img} key={index}>
            {manager.dispArr?.length > 2 ? (
              <img src={manager.dispArr} alt="pic" />
            ) : (
              <div style={{ backgroundColor: backGroundColor[index % backGroundColor.length] }}>
                {manager.empInitial}
              </div>
            )}
          </div>
        );
        return manager;
      });
    }
    return slider;
  };

  return (
    <div>
      <div className={["box reviewer-profile-box", styles.reviewer_profile_box].join(" ")}>
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>My Reviewers</h2>
            {length > 1 && (
              <h3 className="box-heading-light">Just Swipe left or right to navigate through different managers</h3>
            )}
          </div>
        </div>
        <div className="box-inner-pad box-light-gradient no-border">
          <div className="box-profile-info-slider">
            {length > 0 && (
              <div>
                <Slider
                  asNavFor={nav1}
                  ref={(slider) => (slider2 = slider)}
                  slidesToShow={length <= 3 ? 1 : 3}
                  // slidesToShow={3}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  // infinite={false}
                  infinite={length <= 2 ? false : true}
                  centerMode={true}
                  centerPadding={length <= 3 ? "64px" : "0px"}
                  className="box-upper-slider"
                >
                  {createLowerSlider()}
                </Slider>
                <Slider
                  asNavFor={nav2}
                  ref={(slider) => (slider1 = slider)}
                  arrows={false}
                  infinite={length <= 2 ? false : true}
                  className="box-lower-slider"
                >
                  {createUpperSlider()}
                </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
