import React from "react";
import InfoTooltipWithLi from "../InfoTooltip/InfoTooltipWithLi";
import style from "./PMSFilterBox.module.scss";
import MultiSelect from "react-multi-select-component";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import { paRatingOptions, gradeOptions } from "const/options";

function PMSFilterBox({
  setCycleName,
  handleFilter,
  handleClear,
  handleEmployeeView,
  filterType,
  filterGrade,
  setFilterGrade,
  filterPARating,
  setFilterPARating,
  departmentOption,
  filterDepartment,
  handleFilterData,
  bandOption,
  filterBand,
  indiaDDOption,
  filterIndiaDD,
  projectList,
  filterProject,
  locationOption,
  filterLocation,
  managerViewOption,
  cycleListOption,
  criteriaOption,
  cycleName,
  criteria,
  setCriteria,
  setFilterType,
  HREmployeeNameList,
  employeeNameOption,
  rateTypeOption,
  filterEmployeeList,
  getEmployeeNameList,
  ddOption,
  pmOption,
  dmOption,
  filterDD,
  filterDM,
  filterPM,
  filterManagerView,
  subGroupOption,
  filterSubGroup,
}) {
  let displayOtherRender = false;
  if (
    departmentOption?.length > 0 ||
    bandOption?.length > 0 ||
    projectList?.length > 0 ||
    locationOption?.length > 0 ||
    indiaDDOption?.length > 0 ||
    pmOption?.length > 0 ||
    dmOption?.length > 0 ||
    ddOption?.length > 0 ||
    managerViewOption?.length > 0
  ) {
    displayOtherRender = true;
  }
  const renderOthersFilter = () => {
    return (
      <div className={style.otherFilter}>
        <div className={style.heading}>Additional Filters</div>

        {subGroupOption?.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Sub Group</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={subGroupOption}
                value={filterSubGroup}
                onChange={(e) => {
                  handleFilterData("filterSubGroup", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {departmentOption?.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Department</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={departmentOption}
                value={filterDepartment}
                onChange={(e) => {
                  handleFilterData("filterDepartment", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {bandOption?.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Band</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={bandOption}
                value={filterBand}
                onChange={(e) => {
                  handleFilterData("filterBand", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {projectList?.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Project</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={projectList}
                value={filterProject}
                onChange={(e) => {
                  handleFilterData("filterProject", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {locationOption?.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Location</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={locationOption}
                value={filterLocation}
                onChange={(e) => {
                  handleFilterData("filterLocation", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {indiaDDOption && indiaDDOption.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>India DD</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={indiaDDOption}
                value={filterIndiaDD}
                onChange={(e) => {
                  handleFilterData("filterIndiaDD", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}

        {pmOption && pmOption.length > 0 && criteria?.value == "ProjectManager" && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Project Manager</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={pmOption}
                value={filterPM}
                onChange={(e) => {
                  handleFilterData("filterPM", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {dmOption && dmOption.length > 0 && criteria?.value == "ProjectDeliveryManager" && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Delivery Manager</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={dmOption}
                value={filterDM}
                onChange={(e) => {
                  handleFilterData("filterDM", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {ddOption && ddOption.length > 0 && criteria?.value == "ProjectDeliveryDirector" && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Delivery Director</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={ddOption}
                value={filterDD}
                onChange={(e) => {
                  handleFilterData("filterDD", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {managerViewOption && managerViewOption.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Manager Type</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={managerViewOption}
                value={filterManagerView}
                onChange={(e) => {
                  handleFilterData("filterManagerView", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={[style.pms_filter_box_wrapper, "box"].join(" ")}>
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Filter(s)</h2>
          <h3>Select appropriate values for reviewing employees performance</h3>
        </div>
      </div>
      <div className="box-light-gradient"></div>
      <div className={style.filter_info}>
        <div className={style.filter_box}>
          <span className={style.dd_title}>
            Cycle<span className="text-danger">*</span>
          </span>
          <div className="tags_wrapper">
            <MultiSelect
              options={cycleListOption}
              value={cycleName}
              onChange={(e) => {
                setCycleName(e);
              }}
              labelledBy={"Select"}
            />
          </div>
        </div>
        {criteriaOption && criteriaOption.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>
              Criteria<span className="text-danger">*</span>
            </span>
            <div className="tags_wrapper">
              <Select
                options={criteriaOption}
                className="custom-default-dd-box"
                classNamePrefix="select"
                placeholder="Select..."
                value={criteria || ""}
                onChange={(e) => {
                  setCriteria(e);
                }}
              />
            </div>
          </div>
        )}
        {rateTypeOption?.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>
              Type<span className="text-danger">*</span>
            </span>
            <div className="tags_wrapper">
              <Select
                options={rateTypeOption}
                className="custom-default-dd-box"
                classNamePrefix="select"
                placeholder="Select..."
                value={filterType}
                onChange={(e) => {
                  setFilterType(e);
                }}
              />
            </div>
          </div>
        )}
        {filterType && filterType.value.endsWith("Grade") && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>{filterType.value}</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={gradeOptions}
                value={filterGrade}
                onChange={(e) => {
                  setFilterGrade(e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {filterType && filterType.value.endsWith("Rating") && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>{filterType.value}</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={paRatingOptions}
                value={filterPARating}
                onChange={(e) => {
                  setFilterPARating(e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        <br />
        {displayOtherRender && renderOthersFilter()}
        <br />
        {(HREmployeeNameList || employeeNameOption?.length > 0) && (
          <div className={style.heading}>
            Performance Deviation
            <InfoTooltipWithLi
              heading={"Search Criteria"}
              listItem={[
                "Select employee(s) name to compare their performance.",
                "Click on Performance deviation button.",
              ]}
            />
          </div>
        )}

        {employeeNameOption?.length > 0 && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Employee Name</span>
            <div className="tags_wrapper">
              <MultiSelect
                options={employeeNameOption}
                value={filterEmployeeList}
                onChange={(e) => {
                  handleFilterData("filterEmployeeList", e);
                }}
                labelledBy={"Select"}
              />
            </div>
          </div>
        )}
        {HREmployeeNameList && (
          <div className={style.filter_box}>
            <span className={style.dd_title}>Employee Name</span>
            <div className="tags_wrapper">
              <Multiselect
                id={"multi-select-search-dd-box"}
                options={HREmployeeNameList} // Options to display in the dropdown
                selectedValues={filterEmployeeList} // Preselected value to persist in dropdown
                onSelect={(list) => handleFilterData("filterEmployeeList", list)} // Function will trigger on select event
                onRemove={(list) => handleFilterData("filterEmployeeList", list)} // Function will trigger on remove event
                displayValue="label" // Property name to display in the dropdown options
                onSearch={(e) => getEmployeeNameList(e)}
                closeOnSelect={false}
                showCheckbox={true}
                placeholder="Search"
                loading={HREmployeeNameList?.length > 0 ? false : true}
              />
            </div>
          </div>
        )}
        <div className={style.filter_btn_group}>
          <button
            type="button"
            className={["btn-round save_box_btn btn btn-primary mr-3"].join(" ")}
            onClick={handleFilter}
          >
            <strong>Search</strong>
          </button>
          <button
            type="button"
            className={["btn-round save_box_btn btn btn-secondary mr-3"].join(" ")}
            onClick={handleClear}
          >
            <strong>Clear</strong>
          </button>
          <button
            type="button"
            className={["btn-round save_box_btn btn btn-secondary", style.performance_deviation_btn].join(" ")}
            onClick={handleEmployeeView}
          >
            <strong>Performance Deviation</strong>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PMSFilterBox;
