import React, { useContext, useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import style from "./NotificationList.module.scss";
import AuthContext from "../../context/authContext";
import { history } from "../../index";
import { useSelector, useDispatch } from "react-redux";
import * as dateFilter from "../../util/dateUtils";
import * as actions from "../../store/actions/index";
import NotificationListItems from "../Notification/NotificationListItem";
import NoNotification from "../NoNotification/NoNotification";
import { redirectNotification } from "../Notification/notificationUtils";
import { setDefaultMenu } from "util/utils";

export default function NotificationList() {
  const context = useContext(AuthContext);
  const employeeId = context.state.user?.employeeId;
  const employeeIds = {
    employeeId: context.state.user?.employeeId,
    subGroupId: context.state.user?.subGroupId,
    parentGroupId: context.state.user?.parentGroupId,
  };
  const allNotification = useSelector((state) => state.notification.allNotification);
  const [todaysNotification, setTodaysNotification] = useState(false);
  const [clearAllStyle, setClearAllStyle] = useState(" ");
  const [markAllReadStyle, setMarkAllReadStyle] = useState(" ");

  const [yesterdaysNotification, setYesterdaysNotification] = useState(false);
  const [lastWeekNotification, setLastWeeksNotification] = useState(false);
  const [otherNotification, setOtherNotification] = useState(false);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.FetchAllNotification(employeeIds));
  }, [dispatch]);

  useEffect(() => {
    if (allNotification && allNotification.length > 0) {
      setClearAllStyle("");
    } else {
      setClearAllStyle("d-none");
    }
    if (allNotification && allNotification.length) {
      let notifications = [...allNotification];
      setTodaysNotification(notifications.filter((item) => dateFilter.isToday(item.createdDate)));
      setYesterdaysNotification(
        notifications.filter(
          (item) => !dateFilter.isToday(item.createdDate) && dateFilter.isYesterday(item.createdDate)
        )
      );
      setLastWeeksNotification(
        notifications.filter(
          (item) =>
            !dateFilter.isToday(item.createdDate) &&
            !dateFilter.isYesterday(item.createdDate) &&
            dateFilter.isWithinAWeek(item.createdDate)
        )
      );
      setOtherNotification(
        notifications.filter(
          (item) =>
            !dateFilter.isToday(item.createdDate) &&
            !dateFilter.isYesterday(item.createdDate) &&
            !dateFilter.isWithinAWeek(item.createdDate)
        )
      );
      setCount(notifications.filter((item) => !item.isRead).length);
      if (count === 0) {
        setMarkAllReadStyle("d-none");
      } else {
        setMarkAllReadStyle("");
      }
    } else if (allNotification && allNotification.length === 0) {
      setTodaysNotification([]);
      setYesterdaysNotification([]);
      setLastWeeksNotification([]);
      setOtherNotification([]);
      setCount(0);
    }
  }, [allNotification, count]);

  const onNotificationClick = (notification) => {
    setDefaultMenu(context);
    if (!notification.isRead) dispatch(actions.OnReadNotification(notification.id, employeeId));
    redirectNotification(notification, history);
  };

  const onDeleteNotification = (id) => {
    let parent = document.getElementById(id);
    parent.classList.add("removed-item", "fadeout");
    setTimeout(function () {
      parent.classList.add("slideup");
      setTimeout(function () {
        // if (parent && parent.parentNode) parent.parentNode.removeChild(parent);
      }, 750);
    }, 750);
    setTimeout(() => {
      dispatch(actions.DeleteNotification(id, employeeId));
    }, 750);
  };

  return (
    <div>
      <div className="gap30"></div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className={["box", style.notificationlist].join(" ")}>
              <div className="box-heading-wrapper">
                <div className="box-heading">
                  <Row className="align-items-center">
                    <Col lg={8} className="col-5">
                      <div className={style.header_caption}>
                        Notifications
                        {count > 0 && <span className={style.notification_badge}>{count}</span>}
                      </div>
                    </Col>
                    <Col lg={4} className={["text-right col-7", clearAllStyle].join(" ")}>
                      <span
                        className={[style.markallread, markAllReadStyle].join(" ")}
                        onClick={() => dispatch(actions.MarkAllReadNotification(employeeId))}
                      >
                        Mark all read
                      </span>
                      <span
                        onClick={() => {
                          dispatch(actions.ClearAllNotification(employeeId));
                        }}
                      >
                        Clear All
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
              {!allNotification && <NoNotification />}
              {allNotification && allNotification.length === 0 && <NoNotification />}
              <div>
                <Row>
                  <Col lg={12}>
                    <div className="notification_list_wrapper">
                      <NotificationListItems
                        title={"Today"}
                        notifications={todaysNotification}
                        onClick={onNotificationClick}
                        onDeleteNotification={onDeleteNotification}
                      />
                      <NotificationListItems
                        title={"Yesterday"}
                        notifications={yesterdaysNotification}
                        onClick={onNotificationClick}
                        onDeleteNotification={onDeleteNotification}
                      />
                      <NotificationListItems
                        title={"Last week"}
                        notifications={lastWeekNotification}
                        onClick={onNotificationClick}
                        onDeleteNotification={onDeleteNotification}
                      />
                      <NotificationListItems
                        title={"Older"}
                        notifications={otherNotification}
                        onClick={onNotificationClick}
                        onDeleteNotification={onDeleteNotification}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
