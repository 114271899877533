import React, { useState } from "react";
import style from "./InformationHubWrapper.module.scss";
import { IC_VIDEO } from "const/imgCost";
import InfoViewerPopup from "./InfoViewerPopup/InfoViewerPopup";

export default function AccordianContent(props) {
  const [activePopupName, setActivePopUpName] = useState(null);
  return (
    <div className="card_details">
      <div className={`box-light-gradient ${style.objective_items_wrapper}`}>
        {props.videosInfo.map((videosInfo, index) => {
          const { videoTitle, thumbnailLinkSmall, videoLink } = videosInfo;
          return (
            <React.Fragment key={index}>
              <div className={`box ${style.objective_wrapper}`}>
                <div className="box-heading-wrapper">
                  <div className="box-heading">
                    <h3>{videoTitle}</h3>
                  </div>
                </div>
                <div
                  className={`box-inner-pad box-light-gradient no-border cursor-pointer ${style.thumbnail_wrapper}`}
                  onClick={() => setActivePopUpName(videoTitle)}
                >
                  <img src={thumbnailLinkSmall} alt={videoTitle} />
                  <div className={style.image_mask}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <img src={IC_VIDEO} alt={"video icon"} />
                    </div>
                  </div>
                </div>
              </div>
              {activePopupName === videoTitle && (
                <InfoViewerPopup
                  videoTitle={videoTitle}
                  videoLink={videoLink}
                  handleClose={() => setActivePopUpName(null)}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
